import { httpAjax } from '@/helpers';

export const profileService = {
  create,
  list,
  update,
  remove,
  share,
  unshare,
  listAll,
  get,
  nodeList
};

/**
 * Create a new profile 
 * by passing necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function create(data, categoryKey, folderKey, userId) {
  const url = `/api/profile/${categoryKey}/${folderKey}/${userId}/add`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of profile info
 */
function list(categoryKey, nodeKey, nodeId) {
  const url = `/api/profile/${categoryKey}/${nodeKey}/${nodeId}/list`;
  const config = {
    
  }
  return httpAjax.get(url, config);
}

/**
 * Update profile details 
 * by passing  necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function update(data, categoryKey, folderKey, userId) {
  const url = `/api/profile/${categoryKey}/${folderKey}/${userId}/update`;
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of profiles
 * by passing in an array list of uuIds
 * @param {Array} data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data, categoryKey, folderKey, userId, force=false) {
  const url = `/api/profile/${categoryKey}/${folderKey}/${userId}/delete${force === true?'?clean=true':''}`;
  const config = {
      params: {
        clean: true,
      },
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Share a of profile
 * by passing in a uuId
 * @param {String} profileId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 * @param {String} shareToId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function share(categoryKey, folderKey, profileId, shareToId) {
  const url = `/api/profile/${categoryKey}/${folderKey}/${shareToId}/link/add/${profileId}`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, null, config);
}

/**
 * Unshare a of profile
 * by passing in a uuId
 * @param {String} profileId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 * @param {String} shareToId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function unshare(categoryKey, folderKey, profileId, shareToId) {
  const url = `/api/profile/${categoryKey}/${folderKey}/${shareToId}/link/delete/${profileId}`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, null, config);
}

/**
 * List and consolidate multiple nodeKeyPairs. Order in NodeKeyPairs matters: First come first serve.
 * 
 * @param {*} categoryKey e.g.: 'view', 'taskgroup'
 * @param {*} nodeKeyPairs e.g.: [{ key: 'company', value: 'xxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx' }, { key: 'user', value: 'xxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx' }]
 */
 function listAll(categoryKey, nodeKeyPairs) {
  const requests = [];
  for (const keyPair of nodeKeyPairs) {
    requests.push(list(categoryKey, keyPair.key, keyPair.value));
  }

  return Promise.allSettled(requests).then(results => {
    const list = [];
    let hasError = false;
    for (const [i, result] of results.entries()) {
      if (result.status == 'fulfilled') {
        const response = result.value;
        const l = response.data[response.data.jobCase];
        for (const item of l) {
          if (list.findIndex(v => v.uuId == item.uuId) > -1) {
            continue;
          }
          list.push(item);
        }
      } else {
        hasError = true;
      }
    }
    return { list, hasError };
  });
}

/**
 * Get specific profile
 * by passing in a profile uuId
 * @param {String} profileId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 * @param {String} shareToId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
 function get(profileId) {
  const url = `/api/profile/get`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, [{ uuId: profileId }], config);
}

 /**
  * Get specific profile
  * by passing in a profile uuId
  * @param {String} profileId 
  * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
  * @param {String} shareToId 
  * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
  */
  function nodeList(profileId) {
   const url = `/api/profile/${profileId}/node/list`;
   const config = {
     headers: getHeaders()
   };
   return httpAjax.get(url, config);
 }

// Private Methods
function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}

