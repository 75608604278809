var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "priority-nav",
      class: [_vm.hasDropdown ? "priority-nav-has-dropdown" : ""],
    },
    [
      _c(
        "ul",
        { class: [_vm.ulClassName ? _vm.ulClassName : ""] },
        [_vm._t("default")],
        2
      ),
      _c(
        "span",
        {
          staticClass: "nav__dropdown-wrapper priority-nav__wrapper",
          attrs: { "aria-haspopup": _vm.hasDropdown },
        },
        [
          _c(
            "button",
            {
              staticClass: "nav__dropdown-toggle priority-nav__dropdown-toggle",
              class: [
                _vm.isDropdownToggleVisible
                  ? "priority-nav-is-visible"
                  : "priority-nav-is-hidden",
              ],
              attrs: { "aria-controls": "menu", type: "button" },
              on: { click: _vm.navDropdownToggleClicked },
            },
            [_vm._v(_vm._s(_vm.dropdownBtnLabel))]
          ),
          _c("ul", {
            staticClass: "nav__dropdown priority-nav__dropdown",
            class: [_vm.isOpened ? "show" : ""],
            attrs: { "aria-hidden": !_vm.isOpened },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }