var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c(
        "AppHeader",
        { attrs: { fixed: "" } },
        [
          _c("SidebarToggler", {
            staticClass: "d-lg-none",
            attrs: { display: "md", mobile: "", defaultOpen: false },
          }),
          _c(
            "router-link",
            { staticClass: "navbar-brand", attrs: { to: "/dashboard" } },
            [
              _c("span", {
                staticClass: "navbar-brand-full",
                attrs: { width: "178", height: "50", alt: "Projectal Logo" },
              }),
              _c("img", {
                staticClass: "navbar-brand-minimized",
                attrs: {
                  src: _vm.baseUrl + "img/brand/logo-icon.svg",
                  width: "45",
                  height: "30",
                  alt: "Projectal Logo",
                },
              }),
            ]
          ),
          _c("b-navbar-nav", [_vm._t("headerCompany")], 2),
          _c(
            "b-navbar-nav",
            { staticClass: "mr-1" },
            [_vm._t("headerDropdownAccount")],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "app-body" },
        [
          _c(
            "AppSidebar",
            { attrs: { fixed: "" } },
            [
              _c("SidebarHeader"),
              _c("SidebarForm"),
              _c("SidebarNav", {
                key: _vm.currentPathId(),
                attrs: { navItems: _vm.allowedNavList },
              }),
              _c("SidebarFooter"),
              _c("SidebarMinimizer"),
            ],
            1
          ),
          _c(
            "main",
            { staticClass: "main" },
            [
              _c("Breadcrumb", {
                staticStyle: { position: "fixed", width: "100%" },
                attrs: { list: _vm.list, "url-params": _vm.urlParams },
              }),
              _c(
                "div",
                {
                  staticClass: "container-fluid",
                  staticStyle: { "margin-top": "70px" },
                },
                [_vm._t("default")],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DataviewModal", {
        attrs: {
          show: _vm.addDataviewShow,
          data: _vm.data,
          userId: _vm.userId,
          folders: _vm.dataviewParams.isPublic
            ? _vm.publicFolders
            : _vm.privateFolders,
          folderUuid: _vm.dataviewParams.isPublic ? _vm.publicGroup : _vm.group,
          isPublic: _vm.dataviewParams.isPublic,
        },
        on: {
          "update:show": function ($event) {
            _vm.addDataviewShow = $event
          },
          success: _vm.dataviewCreated,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "dataview-to-import-modal",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            size: "md",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        size: "sm",
                        variant: "success",
                        disabled: _vm.file === null,
                      },
                      on: { click: _vm.importModalOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.importDataviewShow,
            callback: function ($$v) {
              _vm.importDataviewShow = $$v
            },
            expression: "importDataviewShow",
          },
        },
        [
          _c("div", {
            staticClass: "w-100",
            attrs: { slot: "modal-title" },
            domProps: { innerHTML: _vm._s(_vm.$t("dataview.import")) },
            slot: "modal-title",
          }),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "field-file",
                label: _vm.$t("task.import.file"),
                "label-for": "file-import",
              },
            },
            [
              _c("b-form-file", {
                attrs: {
                  id: "file-import",
                  state: Boolean(_vm.file),
                  placeholder: _vm.$t("task.import.choose"),
                  "drop-placeholder": "Drop file here...",
                  "browse-text": _vm.$t("task.import.browse"),
                },
                model: {
                  value: _vm.file,
                  callback: function ($$v) {
                    _vm.file = $$v
                  },
                  expression: "file",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("DataviewFolderModal", {
        attrs: {
          id: _vm.folderUuid,
          folderName: _vm.folderName,
          title:
            _vm.folderUuid !== null &&
            _vm.folderUuid.indexOf("DATAVIEW_FOLDER_NEW_") !== 0
              ? _vm.$t("dataview.edit_folder")
              : _vm.$t("dataview.create_folder"),
          parentData: _vm.folderParentData,
          visibility: _vm.dataviewParams.isPublic ? "public" : "private",
          show: _vm.createFolderDataviewShow,
        },
        on: {
          "update:show": function ($event) {
            _vm.createFolderDataviewShow = $event
          },
          success: _vm.createFolderModalOk,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.deleteFolderModalOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.deleteFolderModalOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.deleteFolderDataviewShow,
            callback: function ($$v) {
              _vm.deleteFolderDataviewShow = $$v
            },
            expression: "deleteFolderDataviewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("dataview.confirmation.delete_folder")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.deleteDataviewModalOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.deleteDataviewModalOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.deleteDataviewShow,
            callback: function ($$v) {
              _vm.deleteDataviewShow = $$v
            },
            expression: "deleteDataviewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("dataview.confirmation.delete")) + " "),
          ]),
        ]
      ),
      _c("DataviewFolderSelectorModal", {
        attrs: {
          title: _vm.dataviewParams.isPublic
            ? _vm.$t("dataview.public_dataview_folders")
            : _vm.$t("dataview.dataview_folders"),
          show: _vm.dataviewFolderSelectorShow,
          visibility: _vm.dataviewParams.isPublic ? "public" : "private",
          mode: "MANAGE",
        },
        on: {
          "update:show": function ($event) {
            _vm.dataviewFolderSelectorShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }