const state = {
  contextName: null,
  viewName: null
};

const actions = {
  update({ commit }, name) {
    commit('update', name);
  },
  updateView({ commit }, name) {
    commit('updateView', name);
  },
  clear({ commit }) {
    commit('clear');
  },
  clearView({ commit }) {
    commit('clearView');
  },
};

const mutations = {
  update(_state, name) {
    _state.contextName = name;
  },
  updateView(_state, name) {
    _state.viewName = name;
  },
  clear(_state) {
    _state.contextName = null;
  },
  clearView(_state) {
    _state.viewName = null;
  },
};

const breadcrumb = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default breadcrumb;
