
import { httpAjax, urlParams } from '@/helpers';

export const webhookService = {
  get,
  clone,
  create,
  list,
  update,
  remove,
  getEntityOptions,
};


/**
 * Retrieving a list of webhook details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function get(data) {
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(urlParams('/api/webhook/get'), data, config);
}

/**
 * Create a new webhook 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', fullName: 'John Doe' ... }]
 */
function clone(selected, data) {
  const url = '/api/webhook/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, [data], config);
}

/**
 * Create a new webhook 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', fullName: 'John Doe' ... }]
 */
function create(data) {
  const url = '/api/webhook/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of webhook info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='fullName', order: 'incr' }}
 */
function list(params) {
  params['limit'] = 10000
  const url = '/api/webhook/list';
  let filter = params.searchFilter
  delete params.searchFilter
  const config = {
    params: params,
  };

  // removing the prefix and suffix used for regex query
  if (filter) {
    filter = filter.slice(7, -2)
  }

  return httpAjax.get(url, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const filteredData = rawData.filter(item => {
      return !filter
      || item.entity.toLowerCase().includes(filter.toLowerCase())
      || item.action.toLowerCase().includes(filter.toLowerCase())
      || item.url.toLowerCase().includes(filter.toLowerCase());
    });
    const filteredTotal = filteredData.length;
    return { 
      arg_total: filteredTotal,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: filteredData
    }
  });
}

/**
 * Update webhook details 
 * by passing  necessary information
 * @param Array data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', fullName: 'John Doe' ... }]
 */
function update(data) {
  const url = '/api/webhook/update';
  const config = {
    headers: getHeaders()
  }
  data['holder'] = data.uuId;
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of webhooks
 * by passing in an array list of uuIds
 * @param Array data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = '/api/webhook/delete';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a list of webhook details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function getEntityOptions(data) {
  const url = urlParams('/api/webhook/get');
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}
