
import { httpAjax, urlParams } from '@/helpers';
import { getKeysWithoutRedactedFields } from './common';
import { getPermissionDenyProperties } from '@/helpers/permission'
import { countryCodes } from "@/selectOptions";

export const customerService = {
  create,
  list,
  query,
  update,
  remove,
  clone,
  listv2,
  listNames,
  listUniqueValuesOfProperty
};

/**
 * Create a new customer 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', fullName: 'John Doe' ... }]
 */
function create(data) {
  const url = '/api/customer/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of customer info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
function list(params) {
  const fields = {
    uuId: 'CUSTOMER.uuId',
    name: 'CUSTOMER.name',
    nickName: 'CUSTOMER.nickName',
    description: 'CUSTOMER.description',
    locationCountry: 'CUSTOMER.LOCATION.countryCode',
    locationName: 'CUSTOMER.LOCATION.name',
    locationColor: 'CUSTOMER.LOCATION.color',
    projectNames: 'CUSTOMER.PROJECT.name',
    socials: 'CUSTOMER.socials',
    websites: 'CUSTOMER.websites',
    identifier: 'CUSTOMER.identifier',
    color: 'CUSTOMER.color',
    contactFirstName: 'CUSTOMER.CONTACT.firstName',
    contactLastName: 'CUSTOMER.CONTACT.lastName',
    contactPosition: 'CUSTOMER.CONTACT.position',
    contactCompany: 'CUSTOMER.CONTACT.company',
    contactPhonePot: 'CUSTOMER.CONTACT.phones',
    contactSocialNetPot: 'CUSTOMER.CONTACT.socials',
    contactEmailLot: 'CUSTOMER.CONTACT.emails',
    contactWebsiteLot: 'CUSTOMER.CONTACT.websites',
    tag: 'CUSTOMER.TAG.name'
  }
  

  let data = {
    'name'  : 'Customer List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'CUSTOMER'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if(params.filter && params.filter.length > 0) {
    data['filter'] = [
      '_or_', [
        ['CUSTOMER.name', 'regex', params.filter],
        ['CUSTOMER.nickName', 'regex', params.filter],
        ['CUSTOMER.description', 'regex', params.filter],
        ['CUSTOMER.identifier', 'regex', params.filter],
      ]
    ]
  }

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i], '', 'lowerCase']);
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order, '', 'lowerCase']);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          if (keys[j] === 'locationCountry') {
            result[keys[j]] = countryCodes.filter(c => i[j].some(arr => arr === c.value)).map(v => { return v.text }).join(", ");
          }
          else if (keys[j] === 'locationName' && i[j].length > 0) {
            result[keys[j]] = i[j][0];
          }
          else if (keys[j] === 'projectNames') {
            result[keys[j]] = i[j].join(", ");
          }
          else {
            result[keys[j]] = i[j];
          }
        }

        result.contacts = [];
        if (result.contactFirstName) {
          for (let c = 0; c < result.contactFirstName.length; c++) {
            result.contacts.push({
              firstName: result.contactFirstName[c],
              lastName: result.contactLastName ? result.contactLastName[c] : null,
              position: result.contactPosition[c],
              company: result.contactCompany[c],
              phone: result.contactPhonePot[c],
              socials: result.contactSocialNetPot[c],
              websites: result.contactWebsiteLot[c],
              emails: result.contactEmailLot[c]
              
            });
          }
        }
        
        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

/**
 * Retrieving a list of customer details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
 function query(data, links=null) {
  const url = urlParams('/api/customer/get', links);
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Update customer details 
 * by passing  necessary information
 * @param Array data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', fullName: 'John Doe' ... }]
 */
function update(data) {
  const url = '/api/customer/update';
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of customers
 * by passing in an array list of uuIds
 * @param Array data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = '/api/customer/delete';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}

/**
 * Clone a customer with given new name
 * @param String refId Target customer UuId
 * @param Object data Object contains name for cloned customer.
 */
function clone(refId, data) {
  const url = '/api/customer/clone';
  const config = {
    data: data,
    headers: getHeaders(),
    params: { reference: refId }
  };
  return httpAjax.post(url, data, config);
}


/**
 * Used by GenericSelectorModalForAdmin.vue
 * Retrieving a concise list of customer info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
function listv2(params, customFields=[]) {
  const fields = {
    uuId: ['CUSTOMER.uuId']
    , name: ['CUSTOMER.name']
    , nickName: ['CUSTOMER.nickName']
    , description: ['CUSTOMER.description']
    , locationUuId: ['CUSTOMER.LOCATION.uuId']
    , locationCountry: ['CUSTOMER.LOCATION.countryCode']
    , locationName: ['CUSTOMER.LOCATION.name']
    , locationColor: ['CUSTOMER.LOCATION.color']
    , projectUuId: ['CUSTOMER.PROJECT.uuId']
    , projectName: ['CUSTOMER.PROJECT.name']
    , socials: ['CUSTOMER.socials']
    , websites: ['CUSTOMER.websites']
    , identifier: ['CUSTOMER.identifier']
    , color: ['CUSTOMER.color']
    , contactUuId: ['CUSTOMER.CONTACT.uuId']
    , contactFirstName: ['CUSTOMER.CONTACT.firstName']
    , contactLastName: ['CUSTOMER.CONTACT.lastName']
    , contactPosition: ['CUSTOMER.CONTACT.position']
    , contactCompany: ['CUSTOMER.CONTACT.company']
    , contactPhonePot: ['CUSTOMER.CONTACT.phones']
    , contactSocialNetPot: ['CUSTOMER.CONTACT.socials']
    , contactEmailLot: ['CUSTOMER.CONTACT.emails']
    , contactWebsiteLot: ['CUSTOMER.CONTACT.websites']
    , tag: ['CUSTOMER.TAG.name']
    , readOnly: ['CUSTOMER.readOnly']
    , avatarRef: ['CUSTOMER.avatarRef']
  }
  if (Array.isArray(customFields) && customFields.length > 0) {
    for(const cField of customFields) {
      fields[cField.name] = [`CUSTOMER.${cField.name}`];
    }
  }
  

  let data = {
    'name'  : 'Customer List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'CUSTOMER'
    ,'select': Object.keys(fields).map(i => fields[i])
  }

  if (params.holders) {
    data.holder = params.holders;
  }
  
  if(params.filter && params.filter.length > 0) {
    const matchColumns = [
      ['CUSTOMER.name', 'has', params.filter]
    ]

    const customerDeniedProperties = getPermissionDenyProperties('CUSTOMER','VIEW')
    if (projectDeniedProperties) {
      if (!projectDeniedProperties.includes('nickName')) {
        matchColumns.push(['CUSTOMER.nickName', 'has', params.filter])
      }
      if (!customerDeniedProperties.includes('description')) {
        matchColumns.push(['CUSTOMER.description', 'has', params.filter])
      }
      if (!customerDeniedProperties.includes('identifier')) {
        matchColumns.push(['CUSTOMER.identifier', 'has', params.filter])
      }
      if (!customerDeniedProperties.includes('socials')) {
        matchColumns.push(['CUSTOMER.socials', 'has', params.filter])
      }
      if (!customerDeniedProperties.includes('websites')) {
        matchColumns.push(['CUSTOMER.websites', 'has', params.filter])
      }
    }

    if (Array.isArray(customFields) && customFields.length > 0) {
      for (const c of customFields) {
        if ((c.type == 'String' || c.type == 'Enum<String>') && !customerDeniedProperties.includes(c.name)) {
          matchColumns.push([`CUSTOMER.${c.name}`, 'has', params.filter])
        }
      }
    }
    
    const projectDeniedProperties = getPermissionDenyProperties('PROJECT', 'VIEW')
    if (!customerDeniedProperties.includes('PROJECT') && 
        !customerDeniedProperties.includes('PROJECT.name') && 
        !projectDeniedProperties.includes('name') ) {
      matchColumns.push(['CUSTOMER.PROJECT.name', 'has', params.filter])
    }

    const locationDeniedProperties = getPermissionDenyProperties('LOCATION', 'VIEW')
    if (!customerDeniedProperties.includes('LOCATION') && 
        !customerDeniedProperties.includes('LOCATION.name') && 
        !locationDeniedProperties.includes('name') ) {
      matchColumns.push(['CUSTOMER.LOCATION.name', 'regex', params.filter])
    }

    const contactDeniedProperties = getPermissionDenyProperties('CONTACT', 'VIEW')
    if (!customerDeniedProperties.includes('CONTACT')) {
      if (!customerDeniedProperties.includes('CONTACT.firstName') && 
        !contactDeniedProperties.includes('firstName')) {
          matchColumns.push(['CUSTOMER.CONTACT.firstName', 'has', params.filter])
      }

      if (!customerDeniedProperties.includes('CONTACT.lastName') && 
        !contactDeniedProperties.includes('lastName')) {
          matchColumns.push(['CUSTOMER.CONTACT.lastName', 'has', params.filter])
      }
    } 
    
    const tagDeniedProperties = getPermissionDenyProperties('TAG', 'VIEW')
    if (!customerDeniedProperties.includes('TAG') && 
        !customerDeniedProperties.includes('TAG.name') && 
        !tagDeniedProperties.includes('name') ) {
      matchColumns.push(['CUSTOMER.TAG.name', 'has', params.filter])
    }


    data['filter'] = ['_or_', matchColumns]
  }

  //BadgeFilter related
  if (Array.isArray(params.badgeFilters) && params.badgeFilters.length > 0) {
    const badgeFilterList = [];
    for (const f of params.badgeFilters) {
      if (f.field == null || !Array.isArray(f.value) || f.value.length == 0) {
        continue;
      }
      
      let field = null;
      if (f.field == 'tagName') {
        field = 'CUSTOMER.TAG.name';
      } else if (f.field == 'locationCountry') {
        field = 'CUSTOMER.LOCATION.countryCode';
      } else if (f.field == 'locationName') {
        field = 'CUSTOMER.LOCATION.name';
      } else if (f.field == 'projectName') {
        field = 'CUSTOMER.PROJECT.name';
      } else if (f.field == 'contactFirstName') {
        field = 'CUSTOMER.CONTACT.firstName';
      } else if (f.field == 'contactLastName') {
        field = 'CUSTOMER.CONTACT.lastName';
      } else {
        field = `CUSTOMER.${f.field}`;
      } 

      const isEqual = !f.operator || f.operator === 'is';
      let valueList = [];
      const value = f.value;
      for (const v of value) {
        if (v.text != null && (typeof v.text === 'number' || (typeof v.text === 'string' && v.text&& v.text.length > 0 && v.text !== '(Empty)'))) {
          //use value when exists. Otherwise use text
          //Reason: When value property exists, it means the text is localized and can't be used in filter.
          valueList.push([field, 'eq', Object.hasOwn(v, 'value')? v.value : v.text]);
        }
        //Start processing (EMPTY) field value 
        else if (isEqual) {
          valueList.push('_not_', [[field]])
        } else {
          //Special handling: when is not empty, put it into badgeFilterList directly to act as 'field must exist'. 
          badgeFilterList.push([field])
        }
      }
      if (valueList.length > 0) {
        if (valueList.length > 1) {
          valueList = ['_or_', valueList];
        }
        if (!isEqual) {
          badgeFilterList.push('_not_', valueList);
        } else {
          badgeFilterList.push(...valueList);
        }
      }
    }
    if (badgeFilterList.length > 0) {
      if (Array.isArray(data.filter) && data.filter.length > 0) {
        data.filter = [...data.filter, ...badgeFilterList]
      } else {
        data.filter = [...badgeFilterList]
      }
    }
  }

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey][0], params.order[i], '', 'lowerCase']);
      }
    } else {
      data['sort'].push([fields[params.ksort][0], params.order, '', 'lowerCase']);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.badgeFilters;
  delete params.holders;
  
  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    const getArrayItemAtIndex = (arr, idx, defaultValue) => {
      return Array.isArray(arr) && arr.length >= idx? arr[idx] : defaultValue
    }
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        
        result.contacts = [];
        if (result.contactFirstName) {
          for (let c = 0; c < result.contactFirstName.length; c++) {
            result.contacts.push({
              firstName: result.contactFirstName[c],
              lastName: getArrayItemAtIndex(result.contactLastName, c, null),
              position: getArrayItemAtIndex(result.contactPosition, c, null),
              company: getArrayItemAtIndex(result.contactCompany, c, null),
              phone: getArrayItemAtIndex(result.contactPhonePot, c, null),
              socials: getArrayItemAtIndex(result.contactSocialNetPot, c, null),
              websites: getArrayItemAtIndex(result.contactWebsiteLot, c, null),
              emails: getArrayItemAtIndex(result.contactEmailLot, c, null)
            });
          }
        }
        delete result.contactFirstName;
        delete result.contactLastName;
        delete result.contactPosition;
        delete result.contactCompany;
        delete result.contactPhonePot;
        delete result.contactSocialNetPot;
        delete result.contactWebsiteLot;
        delete result.contactEmailLot;

        result.locations = [];
        if (result.locationUuId) {
          for (let l = 0; l < result.locationUuId.length; l++) {
            result.locations.push({
              uuId: result.locationUuId[l]
              , name: getArrayItemAtIndex(result.locationName, l, '')
              , country: getArrayItemAtIndex(result.locationCountry, l, '')
              , color: getArrayItemAtIndex(result.locationColor, l, '')
            })
          }
        }
        result.locationCountry = ''
        if (result.locations.length > 0) {
          result.locationCountry 
            = countryCodes.filter(c => result.locations.some(l => l.country? l.country === c.value : false))
                          .map(c => { return c.text }).join(", ")
        }
        
        delete result.locationUuId;
        delete result.locationName;
        if (Array.isArray(result.locationColor) && result.locationColor.length > 0) {
          result.locationColor = result.locationColor[0]
        } else {
          result.locationColor = ''
        }

        result.projects = [];
        if (result.projectUuId) {
          for (let i = 0; i < result.projectUuId.length; i++) {
            result.projects.push({
              uuId: result.projectUuId[i]
              , name: getArrayItemAtIndex(result.projectName, i, '')
            })
          }
        }
        delete result.projectUuId;
        delete result.projectName;
        
        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}


/**
 * Retrieving a concise list of CUSTOMER info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
function listNames(params) {
  const fields = {
    uuId: 'CUSTOMER.uuId',
    name: 'CUSTOMER.name'
  }
  

  let data = {
    'name'  : 'CUSTOMER Name List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'CUSTOMER'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

function listUniqueValuesOfProperty(field) {
  const fieldSelector = [`CUSTOMER.${field}`]
  
  const data = {
    'name'  : `Unique CUSTOMER property: ${field}`
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'select': [
      fieldSelector
    ]
    , 'dedup': true
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    let rawData = response.data[listName] || [];
    rawData = rawData.map(i => i[0]);
    rawData.sort((a, b) => {
      if (typeof a === 'string') {
        return a.localeCompare(b, undefined, { sensitivity: 'base'})
      } else {
        if (a < b) {
          return -1
        } else if (a > b) {
          return 1
        }
        return 0
      }
    });
    return rawData;
  });
}