
import { httpAjax } from '@/helpers';

export const noteService = {
  create,
  get,
  update,
  remove,
  list,
};

/**
 * Create a new note 
 * by passing necessary information
 * @param FormData formData 
 * Note: Possible properties for formData: 
 *  1. note: String; Mandatory field.
 */
function create(formData, holder) {
  const url = '/api/note/add';
  const config = {
    params: {
      holder: holder,
    },
    headers: getHeaders(),
  };
  return httpAjax.post(url, formData, config);
}

/**
 * Retrieving a list of note details by 
 * passing in an array list of uuIds as arguments
 * @param uuId uuId of note
 */
function get(data) {
  const url = '/api/note/get';
  const config = {
    
  };
  return httpAjax.post(url, data, config);
}

/**
 * update an  existing note 
 * by passing necessary information
 * @param FormData formData 
 * Note: Possible properties for formData: 
 *  1. name: String; Mandatory field.
 *  2. note: Blob/File Object; Mandatory field.
 *  3. notes: String; Optional field.
 *  4. checksum: md5 hash. Optional field. If supply, backend validation will kick in.
 *  5. parent: uuId. Optinal field. serve as note/directory for upload note.
 *  6. uuId: target uuId; Mandatory field.
 */
function update(data) {
  const url = '/api/note/update';
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a note
 * by passing in uuId
 * @param String uuId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function remove(data) {
  const url = `/api/note/delete`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}

/**
 * Retrieving a list of note info by
 * passing in pagination, sorting and filtering parameters
 * @param Object params
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
 function list(params, entityName, entityUuId) {
   if(entityName == null || entityUuId == null) {
     return [];
   }
  const fields = {
    entityUuId: `${entityName}.uuId`
    , uuId: `${entityName}.NOTE.uuId`
    , text: `${entityName}.NOTE.text`
    , identifier: `${entityName}.NOTE.identifier`
    , modified: `${entityName}.NOTE.modified`
    , firstName: `${entityName}.NOTE.USER.firstName`
    , lastName: `${entityName}.NOTE.USER.lastName`
    , authorRef: `${entityName}.NOTE.USER.uuId`
  };

  let data = {
    name: `${entityName}'s Note List`,
    type: 'msql',
    holder: entityUuId,
    start: params.start,
    limit: params.limit,
    select: Object.keys(fields).map((i) => [fields[i]]),
  };

  if (params.filter && params.filter.length > 0) {
    data['filter'] = [[`${entityName}.NOTE.name`, 'regex', params.filter]];
  }

  if (params.ksort && params.ksort.length > 0) {
    data['sort'] = [];
    if (Array.isArray(params.ksort)) {
      for (let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId' ? 'name' : params.ksort[i];
        if(sortKey == 'modified') {
          data['sort'].push([fields[sortKey], params.order[i], 0]);
        } else {
          data['sort'].push([fields[sortKey], params.order[i], '', 'lowerCase']);
        }
      }
    } else {
      if(params.ksort == 'modified') {
        data['sort'].push([fields[params.ksort], params.order, 0]);
      } else {
        data['sort'].push([fields[params.ksort], params.order, '', 'lowerCase']);
      }
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params,
  };
  return httpAjax.post(url, data, config).then((response) => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return {
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort ? params.ksort : '',
      arg_order: params.order ? params.arg_order : null,
      data: rawData.map((i) => {
        const result = {};
        for (let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        result.author = `${result.firstName?result.firstName:''}${result.firstName && result.lastName?' ':''}${result.lastName?result.lastName:''}`;
        delete result.firstName;
        delete result.lastName;

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      }),
    };
  });
}