<template>
  <span>{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'ConstraintCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    this.value = this.params.value != null? this.params.value : { type: null, time: 0 };
  },
  computed: {
    label() {
      if (this.value.type == null) {
        return '';
      }
      const type = this.params.context.componentParent.$t(`constraint_type_abbreviation.${this.value.type}`);
      return this.value.time == null || this.value.time == 0 
             || this.value.type == 'As_soon_as_possible' || this.value.type == 'As_late_as_possible'
             ? type : `${type} ${moment(this.value.time).format('L')}`;      
    }
  }
})
</script>
