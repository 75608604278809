var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.hideMobile,
          expression: "hideMobile",
        },
      ],
      staticClass: "sidebar",
    },
    [
      _vm._t("default", function () {
        return [_vm._v("Sidebar")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }