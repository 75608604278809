var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "div-outer-div" }, [
    _c("span", { staticClass: "div-percent-value" }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    !_vm.params.noFill
      ? _c("span", { staticClass: "percent-bar-holder" }, [
          _c("span", {
            staticClass: "div-percent-bar",
            style: { width: _vm.width + "%", backgroundColor: _vm.bgColor },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }