import { webhookService } from '@/services'
import { webhookActions } from '@/selectOptions/webhookActions'
import { objectClone } from '@/helpers'
import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly, lackOfMandatoryField } from './common'

function entityOptions(self) {
  let options = null
  if (self.modelInfo != null) {
    options = self.modelInfo.map(i => ({ value: i, text: i }));
  }
  if (options != null) {
    return options
  }
  return []
}

export const webhookUtil = {
  list: webhookService.list
  , remove: webhookService.remove
  , clone: webhookService.clone
  , buildParams: ({ request: { endRow, startRow, sortModel} }, { exportData=false, searchFilter='' }={}) => {
    const params = {
      start: !exportData ? startRow : 0
      , limit: !exportData ? endRow - startRow + 1 : 10000
      , searchFilter: searchFilter
    }
    
    for(let i = 0, len = sortModel.length; i < len; i++) {
      params.ksort = sortModel[i].colId === 'uuId'? 'entity': sortModel[i].colId;
      params.order = sortModel[i].sort === 'asc'? 'asc' : 'desc';
    }

    return params
  }
  , getColumnDefs: (self) => {
    const colDefs = [
      {
        headerName: self.$t('webhook.field.entity')
        , field: 'uuId'
        , cellRenderer: 'detailLinkCellRenderer'
        , cellRendererParams: {
          label: 'entity'
          , tabName: 'list'
        }
        , hide: false
        , cellEditor: 'listEditor'
        , cellEditorParams: {
          options: entityOptions(self)
          , customProp: 'entity'
        }
        , pinned: 'left'
        , lockPosition: 'left'
        , minWidth: 150
        , lockVisible: true
        , editable: true
        , sort: 'asc'
        , valueSetter: function(params) {
          const newValue = params.newValue.trim()
          const oldValue = objectClone(params.data.entity)
          if (newValue !== '' && newValue != oldValue) {
            self.$set(params.data, 'oldEntity', oldValue)
            params.data.entity = newValue
            return true
          }
          return false
        }
      },
      {
        headerName: self.$t('webhook.field.action')
        , field: 'action'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'listEditor'
        , cellEditorParams: {
          options: webhookActions(self)
        }
        , hide: false
        , editable: true
      },
      {
        headerName: self.$t('webhook.field.url')
        , field: 'url'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , cellEditorParams: {
          isOptional: false
        }
        , hide: false
        , editable: true
      }
    ]
    //VIEW permission: Remove column from display list
    filterOutViewDenyProperties(colDefs, 'WEBHOOK', [])
    if (self.isEntityEditable) {
      //EDIT permission: set column to be read only.
      setEditDenyPropertiesReadOnly(colDefs, 'WEBHOOK', [])
    } else {
      for (let i = 0, len = colDefs.length; i < len; i++) {
        colDefs[i].editable = false;
      }
    }
    return colDefs
  }
  , entityUpdateApiUrl: '/api/webhook/update'
  , entityDeleteApiUrl: '/api/webhook/delete'
  , getCustomName(data) {
    return data.entity + ' ' + data.action + ' ' + data.url
  }
  , lackOfMandatoryField: () => {
    return lackOfMandatoryField([{ entity: 'WEBHOOK', action: 'VIEW' }])
  }
  , getMandatoryFields() {
      return [
        'entity', 'action', 'url'
      ]
  }
  , handleAjaxError: (self, feedbackList) => {

    let alertMsg = null;
    if (feedbackList.find(i => i.clue === 'Not_available') != null) {
      alertMsg = self.$t('webhook.error.service_unavailable');
    } else {
      const foundResult = feedbackList.find(i => ['Missing_argument', 'Invalid_value', 'Failed_api'].includes(i.clue) 
                                                  && Array.isArray(i.args) && i.args.length > 0 && ['entity','action','url'].includes(i.args[0]))
      if (foundResult != null) {
        if (foundResult.clue == 'Missing_argument') {
          alertMsg = self.$t('error.missing_argument', [self.$t(`webhook.field.${foundResult.args[0]}`)]);
        } else if (foundResult.clue == 'Invalid_value') {
          alertMsg = self.$t('error.invalid_value_with_arg', [self.$t(`webhook.field.${foundResult.args[0]}`)]);
        } else if (foundResult.hint != null && foundResult.hint.includes('is conflicted with other existed webhook definition')) {
          alertMsg = self.$t('webhook.error.failed_to_update_conflict_definition');
        }
      } 
    }
    
    if (alertMsg == null) {
      alertMsg = self.$t('webhook.error.failed_api');
    }
    
    return {
      msg: alertMsg
    }
  }
  , uuIdCellValueChanged: (self, data) => {
    return {
      colId: 'entity'
      , newValue: data.entity
      , oldValue: data.oldEntity //oldEntity is added in colDef (field: 'uuId')'s valueSetter.
    }
  } 
}