import store from '@/store'

/**
 * Get logon user's respective permission's deny properties
 * @param {*} permissionName entity name in upper case format
 * @param {*} action e.g 'VIEW', 'EDIT', 'ADD'
 * @returns 
 */
export function getPermissionDenyProperties(permissionName, action) {
  const permissionList = store != null && store.state != null && store.state.authentication != null
    && store.state.authentication.user != null 
    && store.state.authentication.user.permissionList != null? store.state.authentication.user.permissionList : null
  if (!Array.isArray(permissionList) || permissionList.length < 1) {
    return []
  }
  const name = `${permissionName}__${action}`
  const found = permissionList.find(p => p.name == name)
  if (found == null || found.permissionLink == null || !Array.isArray(found.permissionLink.denyRules) || found.permissionLink.denyRules.length < 1) {
    return []
  }
  return JSON.parse(JSON.stringify(found.permissionLink.denyRules))
}

/**
 * Check if the user has the requested permission
 * @param {*} name a single entity name in upper case format
 * @param {*} action e.g 'VIEW', 'EDIT', 'ADD'
 * @returns 
 */
export function hasPermission(name, action) {
  if (store != null && store.state != null 
      && store.state.authentication != null && store.state.authentication.user != null 
      && store.state.authentication.user.permissionList != null) {
    const permissionName = `${name}__${action}`
    const found = store.state.authentication.user.permissionList.find(p => p.name == permissionName);
    return found != null
  }
  return false
}