const state = { 
  uuId: null
  , name: null
  , type: null
  , avatarRef: null
  , bannerRef: null
  , locationList: null
  , identifier: null
  , color: null
};

const actions = {
  update({ commit }, payload) {
    commit('update', payload);
  },
  clear({ commit }) {
    commit('clear');
  },
};

const mutations = {
  update(_state, { uuId, name, type, avatarRef, bannerRef, locationList, identifier, color } = {}) {
    if (uuId !== undefined) {
      _state.uuId = uuId;
    }
    if (name !== undefined) {
      _state.name = name;
    }
    if (type !== undefined) {
      _state.type = type;
    }
    if (avatarRef !== undefined) {
      _state.avatarRef = avatarRef;
    }
    if (bannerRef !== undefined) {
      _state.bannerRef = bannerRef;
    }
    if (locationList !== undefined) {
      _state.locationList = locationList;
    }
    if (identifier !== undefined) {
      _state.identifier = identifier;
    }
    if (color !== undefined) {
      _state.color = color;
    }
  },
  clear(_state) {
    _state.uuId = null;
    _state.name = null;
    _state.type = null;
    _state.avatarRef = null;
    _state.bannerRef = null;
    _state.locationList = null;
    _state.identifier = null;
    _state.color = null;
  },
};

const company = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default company;
