import { getPermissionDenyProperties, hasPermission } from '@/helpers/permission'
import store from '@/store'


function __isTopDownNavigation(schemaAPI, leftName, rightName) {
  //Note: The provided entityName can not be edge link (e.g. TASK-SKILL, TASK-RESOURCE)
  if (!Object.hasOwn(schemaAPI, leftName) || !Object.hasOwn(schemaAPI, rightName)) {
    //Return skip:true if any one of the entities can not be found
    return { skip: true }
  }

  if (Array.isArray(schemaAPI[leftName].connections) && schemaAPI[leftName].connections.includes(rightName)) {
    return { value: true }
  } else if (Array.isArray(schemaAPI[rightName].connections) && schemaAPI[rightName].connections.includes(leftName)) {
    return { value: false }
  } else {
    //return skip:true when both entities don't have connections to each other.
    //Theorectically, this process should not enter to this flow. The selector is not a valid entity chain.
    return { skip: true } 
  }
}

function __setColEditable(columnDefs, prop, newValue) {
  let foundIdx = columnDefs.findIndex(i => i.field == prop || i.colId == prop)
  if (foundIdx > -1) {
    const field = columnDefs[foundIdx].field
    //Filter out other columns which have the same field name
    const candidates = columnDefs.filter(i => i.field == field)
    for(const c of candidates) {
      c.editable = newValue
    }
  }
}

/**
 * Remove the matched column(s) from columnDefs based the provided prop
 * @param {*} columnDefs Array
 * @param {*} prop (Array or String) 
 * @returns 
 */
function __removeCols(columnDefs, prop) {
  const propArray = Array.isArray(prop)? prop : [prop]
  if (propArray.length < 1) {
    return
  }
  for (const p of propArray) {
    let foundIdx = columnDefs.findIndex(i => i.field == p || i.colId == p)
    if (foundIdx > -1) {
      const field = columnDefs[foundIdx].field
      columnDefs.splice(foundIdx, 1)
      //Filter out other columns which have the same field name
      foundIdx = columnDefs.findIndex(i => i.field == field)
      while(foundIdx != -1) {
        columnDefs.splice(foundIdx, 1)
        foundIdx = columnDefs.findIndex(i => i.field == field)
      }
    }
  }
  
}

function __getDenyRules(denyRulesMap, permissionName, action='VIEW') {
  if (Object.hasOwn(denyRulesMap, permissionName)) {
    return denyRulesMap[permissionName]
  } else {
    denyRulesMap[permissionName] = getPermissionDenyProperties(permissionName, action)
    return denyRulesMap[permissionName]
  }
}

function __isEditDeniedIfEdgeLinkedDetected(name, properties, denyRulesMap, schemaAPI) {
  const dashPositionIndex = name.indexOf('-')
  const edgeLinkedDetected = dashPositionIndex > -1
  if (!edgeLinkedDetected) {
    return false
  }
  
  const leftName = name.substring(0, dashPositionIndex)
  const rightName = name.substring(dashPositionIndex+1)

  let topName = null
  let downName = null
  const result = __isTopDownNavigation(schemaAPI, leftName, rightName)
  if (result.skip == true) {
    return true
  }
  if (result.value) {
    topName = leftName
    downName = rightName
  } else {
    topName = rightName
    downName = leftName
  }
  const topPermissionName = __convertToPermissionName(topName)
  if (!hasPermission(topPermissionName, 'EDIT')) {
    return true
  }

  let isDenied = false
  const curDenyRules = __getDenyRules(denyRulesMap, topPermissionName, 'EDIT')
  for (const prop of properties) {
    if (curDenyRules.includes(`${downName}.${prop}`)) {
      isDenied = true
      break
    }
  }
  return isDenied
}

function __isEditDeniedIfNonEdgeLinkedDetected(name, properties, denyRulesMap) {
  const permissionName = __convertToPermissionName(name)
  if (!hasPermission(permissionName, 'EDIT')) {
    return true
  }
  let curDenyRules = __getDenyRules(denyRulesMap, permissionName, 'EDIT')
  const curEntityProperies = properties
  let isDenied = false
  for (const prop of curEntityProperies) {
    if (curDenyRules.includes(prop)) {
      isDenied = true
      break
    }
  }
  return isDenied
}

function __getPermissionNameFromEntityName(v) {
  if (v == 'PROJECT_TEMPLATE') {
    return 'TEMPLATE__PROJECT'
  } else if (v == 'TASK_TEMPLATE') {
    return 'TEMPLATE__TASK'
  } else if (v == 'PARENT_TASK') {
    return 'TASK'
  } else if (v == 'PARENT_TASK_TEMPLATE') {
    return 'TEMPLATE__TASK'
  } else if (v == 'STAGE_LIST') {
    return 'STAGE'
  } else {
    return v
  }
}

function __convertToPermissionName(entityName) {
  if (entityName == null) {
    return entityName
  }

  if (entityName.indexOf('.')) {
    const tokens = entityName.split('.')
    const newValues = []
    for (const t of tokens) {
      newValues.push(__getPermissionNameFromEntityName(t))
    }
    return newValues.join('.')
  }

  return __getPermissionNameFromEntityName(entityName)
}

/**
 * Defensive code: strip all the columns except rowSelector and mainColumn('uuId') when user has insufficient permission to view data
 * @param {*} colDefs 
 */
function __defensiveCodeStripColumns(colDefs) {
  let reserveIndexCount = 0
  const rowSelectorIndex = colDefs.findIndex(i => i.colId == 'rowSelector')
  if (rowSelectorIndex != -1) {
    if (rowSelectorIndex != 0) {
      colDefs.splice(0, 0, colDefs.splice(rowSelectorIndex, 1)[0])
    }
    reserveIndexCount++
  }
  const mainColumnIndex = colDefs.findIndex(i => i.field == 'uuId')
  if (mainColumnIndex != -1) {
    if (mainColumnIndex != 0) {
      colDefs.splice(reserveIndexCount, 0, colDefs.splice(mainColumnIndex, 1)[0])
    }
    reserveIndexCount++
  }
  colDefs.splice(reserveIndexCount, colDefs.length)
}

/**
 * Find out the permissionName
 * Usually is the last /far right end entity name. e.g. STAFF from PROJECT.TASK.STAFF
 * Special case: Choose the parent (Top) entity of edge link if Edge-link found. e.g TASK from TASK-SKILL from PROJECT.TASK.TASK-SKILL
 * @param {*} schemaAPI 
 * @param {*} nominatedEntityName 
 * @returns 
 * Note: When return string is NULL and the provided nominatedEntityName is not NULL, it means an error happens.
 */
function __findActualPermissionName(schemaAPI, nominatedEntityName) {
  if (nominatedEntityName == null) {
    return null
  }
  let permissionName = null
  if (nominatedEntityName.indexOf('.') > -1) {
    const tokens = nominatedEntityName.split('.')
    const found = tokens.find(i => i.indexOf('-') > -1)

    //Edge link detected, identify parent entity of edge link
    if (found != null) {
      const parts = found.split('-')
      const result = __isTopDownNavigation(schemaAPI, parts[0], parts[1])
      
      if (result.skip == true) {
        //Can't identify which is top, which is down.
        //Theorectically, process flow should never hit here.
        //Return null to signal an error happens.
        return null
      }

      const topName = result.value? parts[0] : parts[1]
      // const downName = result.value? parts[1] : parts[0]
      permissionName = __convertToPermissionName(topName)
    } else {
      permissionName = __convertToPermissionName(tokens[tokens.length-1])
    }
  } else {
    //Expect entity only (No edge-link)
    permissionName = __convertToPermissionName(nominatedEntityName)
  }
  return permissionName
}

/**
 * 
 * @param {*} colDefs 
 * @param {*} nominatedEntityName 
 * @param {*} linkedEntities 
 * 
 * Details of linkedEntities' properties:
 * 1) selector: (String) used to determine the chaining entities and their respective permissions/denyRules. Property is excluded.
 * Examples:
 *   - PROJECT.TASK.TASK-SKILL
 *   - PROJECT.TASK.SKILL
 *   - TASK.PROJECT
 * 
 * 2) field: (String or Array) the grid column fields / colIds. Will be used to filter out the restricted columns.
 * Examples:
 *   - Pass in string: 'tag' when single field
 *   - Pass in array: [tag, locations, skills] when multiple fields
 * 
 * 3) properties: (Array) used to indicate properties being referred in the last chaining entities. No prefix, just the properties.
 * Exmaples: 'PROJECT.TASK.SKILL.name', 'PROJECT.TASK.SKILL.uuId'
      - Keep ['name', 'uuId'] in properties
 * 
 * 
 * Examples of linkedEntities:
 * { selector: 'TASK.TAG', field: 'tag', properties: ['uuId', 'name'] }
 * { selector: 'TASK.SKILL', field: 'skills', properties: ['uuId', 'name'] }
 * { selector: 'TASK.TASK-SKILL', field: 'skills', properties: ['level'] }
 * 
 * 
 */
export function filterOutViewDenyProperties(colDefs, nominatedEntityName, linkedEntities) {
  // const permissionName = __convertToPermissionName(nominatedEntityName)
  //VIEW permission: Remove column from display list
  //Defensive code: strip all the columns except rowSelector and mainColumn('uuId') when user has insufficient permission to view data
  if (!hasPermissionAdv(nominatedEntityName, 'VIEW') || store == null 
      || store.state == null || store.state.data == null 
      || store.state.data.schemaAPI == null || store.state.data.schemaAPI.value == null) {
    __defensiveCodeStripColumns(colDefs)
    return
  }

  const schemaAPI = __getSchemaApi()

  //Find the permissionName
  let permissionName = __findActualPermissionName(schemaAPI, nominatedEntityName)
  if (permissionName == null) {
    //Defensive code: strip all the columns except rowSelector and mainColumn('uuId') when user has insufficient permission to view data
    __defensiveCodeStripColumns(colDefs)
    return
  }

  const viewDenyProperties = getPermissionDenyProperties(permissionName, 'VIEW')
  //Check the main entity's properties against view denyRules
  for (const prop of viewDenyProperties) {
    __removeCols(colDefs, prop)
  }

  if (!Array.isArray(linkedEntities) || linkedEntities.length == 0) {
    return
  }

  let _linkedEntities = JSON.parse(JSON.stringify(linkedEntities))
  if (!Array.isArray(_linkedEntities)) {
    return
  }
  const denyRulesMap = {}
  denyRulesMap[permissionName] = viewDenyProperties
  
  //Check linked entities against their respective VIEW permission and denyRules
  while(_linkedEntities.length > 0) {
    const entity = _linkedEntities.pop()
    if (entity == null) {
      continue
    }
    if (!(Array.isArray(entity.field) || typeof entity.field == 'string')
        || (Array.isArray(entity.field) && entity.field.length < 1)
        || (typeof entity.field == 'string' && entity.field.trim().length == 0)) {
      continue
    }
    if (colDefs.findIndex(i => i.field == entity.field || i.colId == entity.field) < 0) {
      continue
    }
    if (typeof entity.selector != 'string' || entity.selector.trim().length == 0) {
      continue
    }
    //Break down the selector into tokens
    const tokens = entity.selector.split('.')

    if (tokens.length < 1) {
      continue
    }

    //When only one token
    if (tokens.length == 1) {
      const entityName = tokens[0]
      const entityPermissionName = __convertToPermissionName(entityName)

      if (!hasPermission(entityPermissionName, 'VIEW')) {
        __removeCols(colDefs, entity.field)
        continue
      }

      let curDenyRules = __getDenyRules(denyRulesMap, entityPermissionName)
      
      const curEntityProperies = entity.properties
      let isDenied = false
      for (const prop of curEntityProperies) {
        if (curDenyRules.includes(prop)) {
          isDenied = true
          break
        }
      }
      if (isDenied) {
        const foundIdx = colDefs.findIndex(i => i.field == entity.field || i.colId == entity.field)
        if (foundIdx > -1) {
          colDefs.splice(foundIdx, 1)
        }
      }
      continue
    }
    
    //When there is more than 1 token
    //Process two consecutive entities as a pair.
    //e.g. PROJECT.TASK.SKILL: [PROJECT, TASK], [TASK, SKILL]
    const len = tokens.length - 1
    for (let i = 0; i < len; i++) {
      const leftName = tokens[i]
      let rightName = tokens[i+1]
      const isLastLoop = i+1 >= len

      const dashPositionIndex = rightName.indexOf('-')
      const edgeLinkedDetected = dashPositionIndex > -1
      if (edgeLinkedDetected) {
        //Extract the right-handside entity
        rightName = rightName.substring(dashPositionIndex+1)
      }

      //Check if have both entities's view permission
      if (!hasPermission(__convertToPermissionName(leftName), 'VIEW') || !hasPermission(__convertToPermissionName(rightName), 'VIEW')) {
        __removeCols(colDefs, entity.field)
        break
      }

      //Check top-down relation between left and right entities.
      const result = __isTopDownNavigation(schemaAPI, leftName, rightName)
      
      if (result.skip == true) {
        __removeCols(colDefs, entity.field)
        break
      }

      const topName = result.value? leftName : rightName
      const downName = result.value? rightName : leftName

      //Process edge linked entity if exists.
      if (edgeLinkedDetected) {
        //Theorectically, there should be no more suffix entity after edge link(e.g. TASK-SKILL) appears.
        //Check properties against denyRules
        let curDenyRules = __getDenyRules(denyRulesMap, __convertToPermissionName(topName))
        if (curDenyRules == null) {
          break
        }
        for (const prop of entity.properties) {
          if (curDenyRules.includes(`${downName}.${prop}`)) {
            __removeCols(colDefs, entity.field)
            break
          }
        }
        break
      }

      //Process non edge linked entity when it is last loop
      if (isLastLoop) {
        //last loop
        //Check downName against TopEntity's VIEW denyRules
        let curDenyRules = __getDenyRules(denyRulesMap, __convertToPermissionName(topName))
        if (curDenyRules != null && curDenyRules.includes(downName)) {
          __removeCols(colDefs, entity.field)
          break
        }

        //Check properties against DownEntity's VIEW denyRules
        curDenyRules = __getDenyRules(denyRulesMap, __convertToPermissionName(downName))
        if (curDenyRules != null) {
          for (const prop of entity.properties) {
            if (curDenyRules.includes(prop)) {
              __removeCols(colDefs, entity.field)
              break
            }
          }
        }
      }
    }
  }
}

/**
 * 
 * @param {*} colDefs 
 * @param {*} nominatedEntityName 
 * @param {*} linkedEntities 
 * @returns 
 * 
 * 1) selector: (String) used to determine the related entity and it's respective permission/denyRules. Property is excluded.
 * Examples
 *   - TASK PROJECT.TASK.TASK-SKILL
 *   - PROJECT.TASK.SKILL
 *   - TASK.PROJECT
 * 
 * 2) field: (String) the grid column field / colId. Will be used to filter out the restricted column.
 *   - tag
 *   - locations
 *   - skills
 * 
 * 3) properties: (Array) used to indicate properties being referred in the last chaining entities. No prefix, just the properties.
 * Exmaples: 'PROJECT.TASK.SKILL.name', 'PROJECT.TASK.SKILL.uuId'
      - Keep ['name', 'uuId'] in properties
 * 
 * 
 * Examples of linkedEntities:
 * { selector: 'TASK.TAG', field: 'tag', properties: ['uuId', 'name'] }
 * { selector: 'TASK.SKILL', field: 'skills', properties: ['uuId', 'name'] }
 * { selector: 'TASK.TASK-SKILL', field: 'skills', properties: ['level'] }
 */
export function setEditDenyPropertiesReadOnly(colDefs, nominatedEntityName, linkedEntities) {
  const schemaAPI = __getSchemaApi()

  //Find the permissionName
  let permissionName = __findActualPermissionName(schemaAPI, nominatedEntityName)
  if (permissionName == null) {
    return
  }

  if (!hasPermission(permissionName, 'EDIT')) {
    for (const c of colDefs) {
      c.editable = false
    }
  }
  //EDIT permission: set column to be read only.
  const editDenyProperties = getPermissionDenyProperties(permissionName, 'EDIT')
  for (const prop of editDenyProperties) {
    const found = colDefs.find(i => i.field == prop || i.colId == prop)
    if (found != null) {
      //find out all columnDefs with same field value
      const candidates = colDefs.filter(i => i.field == found.field)
      for (const c of candidates) {
        c.editable = false
      }
    }
  }

  //EDIT permission: Check if linked entities are in denyRules
  let _linkedEntities = JSON.parse(JSON.stringify(linkedEntities))
  if (linkedEntities.length > 0) {
    for (const lEntity of linkedEntities) {
      if (lEntity.selector == null) {
        continue
      }
      const tokens = lEntity.selector.split('.')
      const entityName = tokens[tokens.length - 1]
      let found = editDenyProperties.includes(entityName)
      if (found) {
        found = colDefs.find(i => i.field == lEntity.field || i.colId == lEntity.field)
        if (found != null) {
          //find out all columnDefs with same field value
          const candidates = colDefs.filter(i => i.field == found.field)
          for (const c of candidates) {
            c.editable = false
          }
        }
      }
    }
  }
  
  const denyRulesMap = {}
  denyRulesMap[permissionName] = editDenyProperties

  //Check linked entities against their respective EDIT permission and denyRules
  while (_linkedEntities.length > 0) {
    const entity = _linkedEntities.pop()
    if (typeof entity.field != 'string' || entity.field.trim().length == 0) {
      continue
    }
    if (colDefs.findIndex(i => i.field == entity.field || i.colId == entity.field) < 0) {
      continue
    }
    if (typeof entity.selector != 'string' || entity.selector.trim().length == 0) {
      continue
    }
    //Break down the selector into tokens
    const tokens = entity.selector.split('.')

    let name = null
    if (tokens.length == 1) {
      name = tokens[0]
    } else {
      name = tokens[tokens.length - 1]
    }

    const isEdgeLinked = name.indexOf('-') > -1
    if (isEdgeLinked && __isEditDeniedIfEdgeLinkedDetected(name, entity.properties, denyRulesMap, schemaAPI) || 
        !isEdgeLinked && __isEditDeniedIfNonEdgeLinkedDetected(name, entity.properties, denyRulesMap)) {
      __setColEditable(colDefs, entity.field, false)
    }
  }
}

export function lackOfMandatoryField(permissions=[], mandatoryFields=['uuId', 'name']) {
  let showNoData = false
  for (const permission of permissions) {
    const denyProperties = getPermissionDenyProperties(permission.entity, permission.action)
    
    for (const field of mandatoryFields) {
      if (denyProperties.includes(field)) {
        showNoData = true
        break
      }
    }

    if (showNoData) {
      break
    }
  }
  return showNoData
}

function __getSchemaApi() {
  if (store == null || store.state == null 
      || store.state.data == null || store.state.data.schemaAPI == null
      || store.state.data.schemaAPI.value == null) {
    return {}
  }
  return store.state.data.schemaAPI.value
}

/**
 * Check if the user has the requested permission
 * @param {*} name chaining entity name in upper case format. e.g. 'PROJECT.TASK', 'COMPANY.PROJECT', 'PROJECT.TASK.TASK-SKILL'
 * @param {*} action e.g 'VIEW', 'EDIT', 'ADD'
 * @returns 
 */
export function hasPermissionAdv(name, action) {
  if (name == null || action == null) {
    return false
  }
  if (store == null || store.state == null 
      || store.state.authentication == null 
      || store.state.authentication.user == null 
      || store.state.authentication.user.permissionList == null) {
    return false
  }

  const permissionList = store.state.authentication.user.permissionList

  //Break down the name into tokens
  const tokens = name.split('.')
  if (tokens.length < 1) {
    return false
  }

  //When only one token
  if (tokens.length == 1) {
    const entityName = tokens[0]
    const entityPermissionName = __convertToPermissionName(entityName)
    const permissionName = `${entityPermissionName}__${action}`
    const found = permissionList.find(p => p.name == permissionName);
    if (found != null) {
      return true
    }
    return false 
  }

  const schemaAPI = __getSchemaApi()
  const denyRulesMap = {}

  //When there is more than 1 token
  //Process two consecutive entities as a pair.
  //e.g. PROJECT.TASK.SKILL: [PROJECT, TASK], [TASK, SKILL]
  const len = tokens.length - 1
  let lackPermission = false
  for (let i = 0; i < len; i++) {
    const leftName = tokens[i]
    let rightName = tokens[i+1]
    
    const dashPositionIndex = rightName.indexOf('-')
    const edgeLinkedDetected = dashPositionIndex > -1
    if (edgeLinkedDetected) {
      //Extract the right-handside entity
      rightName = rightName.substring(dashPositionIndex+1)
    } 

    //Check top-down relation between left and right entities.
    const result = __isTopDownNavigation(schemaAPI, leftName, rightName)
      
    if (result.skip == true) {
      lackPermission = true
      break
    }

    const topName = result.value? leftName : rightName
    const downName = result.value? rightName : leftName
    
    if (!hasPermission(__convertToPermissionName(topName), 'VIEW') || !hasPermission(__convertToPermissionName(downName), 'VIEW')) {
      lackPermission = true
      break
    }
    
    if (edgeLinkedDetected) {
      //Theorectically, there should be no more suffix entity after edge link(e.g. TASK-SKILL) appears.
      //Break loop as business ends here for edge link
      break
    }
   
    let curDenyRules = __getDenyRules(denyRulesMap, __convertToPermissionName(topName))
    if (curDenyRules != null && curDenyRules.includes(downName)) {
      lackPermission = true
      break
    }
  }
  return !lackPermission
}


export function removeDeniedProperties(permissionName, data, action, customList=[]) {
  const denyProperties = getPermissionDenyProperties(permissionName, action)
  const dataKeys = Object.keys(data)

  let prop = null
  for (const key of dataKeys) {
    prop = key
    if (denyProperties.includes(prop)) {
      delete data[key]
    }
  }

  if (Array.isArray(customList) && customList.length > 0) {
    for (const c of customList) {
      if (c.prop == null || c.denyProp == null) {
        continue
      }
      if (!Object.hasOwn(data, c.prop)) {
        continue
      }
      if (denyProperties.includes(c.denyProp)) {
        delete data[c.prop]
      }
    }
  }
}

export function columnDefSortFunc(a, b) {
  const nameA = a.headerName != null? a.headerName.toLowerCase() : a.headerName;
  const nameB = b.headerName != null? b.headerName.toLowerCase() : b.headerName;
  const hideA = a.hide;
  const hideB = b.hide;
  const pinnedA = a.pinned == 'left';
  const pinnedB = b.pinned == 'left';

  if (pinnedA == true && pinnedB != true) {
    return -1;
  } else if (pinnedB == true && pinnedA != true) {
    return 1;
  } else if (pinnedA == true && pinnedB == true) {
    return 0; //don't sort when both are pinned. use the natural sort order.
  }

  if (hideA != true && hideB == true) {
    return -1;
  } else if (hideB != true && hideA == true) {
    return 1;
  } else if (hideA != true && hideB != true) {
    return 0; //don't sort when both are visible
  }

  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  } else {
    return 0;
  }
}

// export function columnDefSortFunc(a, b) {
//   const nameA = a.headerName != null? a.headerName.toLowerCase() : a.headerName;
//   const nameB = b.headerName != null? b.headerName.toLowerCase() : b.headerName;
//   if (nameA < nameB) {
//     return -1;
//   } else if (nameA > nameB) {
//     return 1;
//   } else {
//     return 0;
//   }
// }


const dataGroup = {
  stringGroup: ['name', 'identifier']
}
export function isPropertyCompatible(src, tgt) {
  if (src === tgt) {
    return { status: true }
  }

  const keys = Object.keys(dataGroup)
  for (const key of keys) {
    if (dataGroup[key].includes(src) && dataGroup[key].includes(tgt)) {
      return { status: true }
    }
  }
  return { status: false, colId: tgt }
}
