/* 
  import of DHTMLX Gantt is required.
*/
export const ganttLayoutUtil = {
  create: _create
}

function _create(g) {
  const rows = [
    {
      cols: [
        { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
        { view: 'scrollbar', id: 'scrollVer', group: 'vertical' }
      ],
      gravity: 2
    }
  ];
  rows.push({ view: 'scrollbar', id: 'scrollHor' });

  g.config.layout = {
    css: 'gantt_container',
    rows: rows
  };
}
