var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      style: { height: _vm.containerHeight },
      attrs: { id: "pt-" + _vm.id },
    },
    [
      _c("div", {
        staticClass: "color-top",
        style: {
          "background-color": _vm.getColor(_vm.projectData.progress * 100),
        },
      }),
      _vm.display.avatar || _vm.display.banner
        ? _c(
            "div",
            {
              staticClass: "avatar-container",
              style: {
                "background-image":
                  "url(" + (_vm.display.banner ? _vm.bannerUrl : null) + ")",
              },
            },
            [
              _vm.display.avatar && _vm.avatarUrl != null
                ? _c("b-img-lazy", {
                    staticClass: "image-avatar",
                    staticStyle: { "object-fit": "cover" },
                    attrs: { id: "avatar-avatar", src: _vm.avatarUrl },
                    nativeOn: {
                      error: function ($event) {
                        return _vm.handleAvatarError.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("div", { staticClass: "banner-placeholder" }),
      _c(
        "span",
        {
          class: _vm.favourite
            ? "favourite-button favourite-button-display"
            : "favourite-button",
        },
        [
          _c("div", { staticClass: "favourite-circle" }),
          _c("b-popover", {
            attrs: {
              target: "FAVORITE_" + _vm.id,
              placement: "top",
              triggers: "hover",
              content: _vm.favourite
                ? _vm.$t("button.remove_favourite")
                : _vm.$t("button.add_favourite"),
            },
          }),
          _c(
            "b-btn",
            {
              attrs: { id: "FAVORITE_" + _vm.id },
              on: { mousedown: _vm.toggleFavourite },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: [_vm.favourite ? "fas" : "far", "star"] },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.display.progress
        ? _c(
            "div",
            { staticClass: "pt-2" },
            [
              _c("canvas", {
                staticClass: "chart-size pt-3 pr-3 pl-3",
                attrs: { id: _vm.id },
              }),
              _c("b-popover", {
                attrs: {
                  target: _vm.id,
                  placement: "top",
                  triggers: "hover",
                  content: _vm.$t("project.field.progress"),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.noDisplay ? _c("div", { staticClass: "placeholder" }) : _vm._e(),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "18px", "margin-top": "15px" } },
        [
          _vm.dataLoaded
            ? [
                _vm.display.dates
                  ? [
                      _c("div", { staticClass: "d-flex flex-row line-row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "project-tile-line line-icon tile-line-icon",
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "rowicon-color fa-lg",
                              attrs: { icon: ["far", "calendar-days"] },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "project-tile-line line-startDate",
                            attrs: { id: "SCHEDULE_START_" + _vm.id },
                          },
                          [
                            _c("b-popover", {
                              attrs: {
                                target: "SCHEDULE_START_" + _vm.id,
                                placement: "top",
                                triggers: "hover",
                                content: _vm.$t("project.field.scheduleStart"),
                              },
                            }),
                            _c("div", [_vm._v(_vm._s(_vm.startTime))]),
                          ],
                          1
                        ),
                        _vm._m(0),
                        _c(
                          "div",
                          {
                            staticClass: "project-tile-line line-closeDate",
                            attrs: { id: "SCHEDULE_FINISH_" + _vm.id },
                          },
                          [
                            _c("b-popover", {
                              attrs: {
                                target: "SCHEDULE_FINISH_" + _vm.id,
                                placement: "top",
                                triggers: "hover",
                                content: _vm.$t("project.field.scheduleFinish"),
                              },
                            }),
                            _c("div", [_vm._v(_vm._s(_vm.closeTime))]),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm.display.cost || _vm.display.duration
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row text-center line-header",
                        },
                        [
                          _c("div", {
                            staticClass:
                              "project-tile-line line-icon tile-line-icon",
                          }),
                          _c(
                            "div",
                            { staticClass: "project-tile-line line-budget" },
                            [_c("div", [_vm._v(_vm._s(_vm.labelBudget))])]
                          ),
                          _c(
                            "div",
                            { staticClass: "project-tile-line line-actual" },
                            [_c("div", [_vm._v(_vm._s(_vm.labelActual))])]
                          ),
                          _c(
                            "div",
                            { staticClass: "project-tile-line line-plusminus" },
                            [_c("div", [_vm._v(_vm._s(_vm.labelPlusMinus))])]
                          ),
                        ]
                      ),
                      _vm._l(_vm.items, function (item, key) {
                        return [
                          (_vm.display.cost && key == 0) ||
                          (_vm.display.duration && key == 1)
                            ? _c(
                                "div",
                                {
                                  key: key,
                                  staticClass:
                                    "d-flex flex-row text-center line-row",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "project-tile-line line-icon tile-line-icon",
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "rowicon-color fa-lg",
                                        attrs: { icon: ["far", item.rowicon] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "project-tile-line line-budget",
                                      attrs: {
                                        id: "BUDGET_" + _vm.id + "_" + key,
                                      },
                                    },
                                    [
                                      _c("b-popover", {
                                        attrs: {
                                          target:
                                            "BUDGET_" + _vm.id + "_" + key,
                                          placement: "top",
                                          triggers: "hover",
                                          content: item.budget_tooltip,
                                        },
                                      }),
                                      _c("div", [_vm._v(_vm._s(item.budget))]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "project-tile-line line-actual",
                                      attrs: {
                                        id: "ACTUAL_" + _vm.id + "_" + key,
                                      },
                                    },
                                    [
                                      _c("b-popover", {
                                        attrs: {
                                          target:
                                            "ACTUAL_" + _vm.id + "_" + key,
                                          placement: "top",
                                          triggers: "hover",
                                          content: item.actual_tooltip,
                                        },
                                      }),
                                      _c("div", [_vm._v(_vm._s(item.actual))]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "project-tile-line line-plusminus",
                                      class:
                                        typeof item.diff_plus_minus ===
                                          "undefined" ||
                                        item.diff_plus_minus >= 0
                                          ? "value-positive"
                                          : "value-negative",
                                      attrs: {
                                        id: "DIFF_" + _vm.id + "_" + key,
                                      },
                                    },
                                    [
                                      _c("b-popover", {
                                        attrs: {
                                          target: "DIFF_" + _vm.id + "_" + key,
                                          placement: "top",
                                          triggers: "hover",
                                          content: item.diff_tooltip,
                                        },
                                      }),
                                      _c("div", [
                                        _vm._v(_vm._s(item.plus_minus)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ]
                  : _vm._e(),
                _vm.display.status
                  ? [
                      _c("div", { staticClass: "d-flex flex-row line-row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "project-tile-line line-icon tile-line-icon",
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "rowicon-color fa-lg",
                              attrs: { icon: ["far", "square-info"] },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "project-tile-line line-status",
                            attrs: { id: "STATUS_" + _vm.id },
                          },
                          [
                            _c("b-popover", {
                              attrs: {
                                target: "STATUS_" + _vm.id,
                                placement: "top",
                                triggers: "hover",
                                content: _vm.$t("project.field.status"),
                              },
                            }),
                            _c("div", { staticClass: "line-status-value" }, [
                              _vm._v(_vm._s(_vm.projectData.status)),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "project-tile-line line-dash" }, [
      _c("div", [_vm._v("-")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }