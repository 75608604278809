var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "sidebar-minimizer",
      attrs: { id: "sidebar_minimize", type: "button" },
      on: {
        click: function ($event) {
          return _vm.onClick()
        },
      },
    },
    [
      _c("b-popover", {
        attrs: {
          target: "sidebar_minimize",
          placement: "top",
          triggers: "hover",
          content: _vm.isMinimized
            ? _vm.$t("button.expand_sidebar")
            : _vm.$t("button.collapse_sidebar"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }