
import Vue from 'vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { 
  faBuilding as fasBuilding
  , faCalendarDays as fasCalendarDays
  , faCheck as fasCheck
  , faCircle as fasCircle
  , faCircleCheck as fasCircleCheck
  , faDiamond as fasDiamond
  , faEllipsisVertical as fasEllipsisVertical
  , faExclamationTriangle as fasExclamationTriangle
  , faStar as fasStar
  , faCog as fasCog
  , faHeadSideBrain as fasHeadSideBrain
  , faMapMarkedAlt as fasMapMarkedAlt
  , faShareNodes as fasShareNodes
  , faSitemap as fasSitemap
  , faThList as fasThList
  , faTimes as fasTimes
  , faUserHardHat as fasUserHardHat
  , faUsers as fasUsers
} from '@fortawesome/pro-solid-svg-icons';

import {
  faAlarmExclamation as farAlarmExclamation
  , faAnalytics as farAnalytics
  , faApartment as farApartment
  , faArrowDown as farArrowDown
  , faArrowDownToBracket as farArrowDownToBracket
  , faArrowsLeftRight as farArrowsLeftRight
  , faArrowRightArrowLeft as farArrowRightArrowLeft
  , faArrowsRotate as farArrowsRotate
  , faArrowsUpDown as farArrowsUpDown
  , faArrowUp as farArrowUp
  , faArrowUpFromBracket as farArrowUpFromBracket
  , faBadgeDollar as farBadgeDollar
  , faBackwardStep as farBackwardStep
  , faBarsStaggered as farBarsStaggered
  , faBinary as farBinary
  , faBinarySlash as farBinarySlash
  , faBolt as farBolt
  , faBooksMedical as farBooksMedical
  , faBorderAll as farBorderAll
  , faBorderNone as farBorderNone
  , faBuilding as farBuilding
  , faCalendarCheck as farCalendarCheck
  , faCalendarDay as farCalendarDay
  , faCalendarDays as farCalendarDays
  , faCalendarExclamation as farCalendarExclamation
  , faCaretDown as farCaretDown
  , faCartFlatbedBoxes as farCartFlatbedBoxes
  , faChartBar as farChartBar
  , faChartLine as farChartLine  
  , faChartNetwork as farChartNetwork
  , faChartSimple as farChartSimple
  , faCheck as farCheck
  , faChevronDown as farChevronDown
  , faChevronRight as farChevronRight
  , faChevronLeft as farChevronLeft
  , faChevronUp as farChevronUp
  , faClock as farClock
  , faCircle as farCircle
  , faCircleCheck as farCircleCheck
  , faCircleChevronLeft as farCircleChevronLeft
  , faCircleChevronRight as farCircleChevronRight
  , faCircleExclamation as farCircleExclamation
  , faCircleInfo as farCircleInfo
  , faCircleMinus as farCircleMinus
  , faCirclePlus as farCirclePlus
  , faCircleQuestion as farCircleQuestion
  , faClockRotateLeft as farClockRotateLeft
  , faCodeBranch as farCodeBranch
  , faClone as farClone
  , faCopy as farCopy
  , faDesktop as farDesktop
  , faDownload as farDownload
  , faDownToBracket as farDownToBracket
  , faEllipsisVertical as farEllipsisVertical
  , faEnvelope as farEnvelope
  , faEye as farEye
  , faEyeSlash as farEyeSlash
  , faFile as farFile
  , faFileArchive as farFileArchive
  , faFileArrowUp as farFileArrowUp
  , faFileAudio as farFileAudio
  , faFileExcel as farFileExcel
  , faFileExport as farFileExport
  , faFileImage as farFileImage
  , faFileImport as farFileImport
  , faFileMagnifyingGlass as farFileMagnifyingGlass
  , faFilePdf as farFilePdf
  , faFileSpreadsheet as farFileSpreadsheet
  , faFileVideo as farFileVideo
  , faFileWord as farFileWord
  , faFilter as farFilter
  , faFilterCircleXmark as farFilterCircleXmark
  , faFilters as farFilters
  , faFolder as farFolder
  , faFolderPlus as farFolderPlus
  , faForwardStep as farForwardStep
  , faFloppyDisk as farFloppyDisk
  , faGaugeHigh as farGaugeHigh
  , faGavel as farGavel
  , faGear as farGear
  , faGears as farGears
  , faGrid as farGrid
  , faHeadSideBrain as farHeadSideBrain
  , faHistory as farHistory
  , faHouse as farHouse
  , faImage as farImage
  , faInboxIn as farInboxIn
  , faInboxOut as farInboxOut
  , faIndent as farIndent
  , faInfo as farInfo
  , faLayerGroup as farLayerGroup
  , faLayerPlus as farLayerPlus
  , faLink as farLink
  , faList as farList
  , faListTree as farListTree
  , faLock as farLock
  , faMagnifyingGlass as farMagnifyingGlass
  , faMagnifyingGlassMinus as farMagnifyingGlassMinus
  , faMagnifyingGlassPlus as farMagnifyingGlassPlus
  , faMapMarkedAlt as farMapMarkedAlt
  , faMerge as farMerge
  , faMinus as farMinus
  , faMoonStars as farMoonStars
  , faOutdent as farOutdent
  , faPalette as farPalette
  , faPaste as farPaste
  , faPen as farPen
  , faPenField as farPenField
  , faPenToSquare as farPenToSquare
  , faPersonDigging as farPersonDigging
  , faPlay as farPlay
  , faPlus as farPlus
  , faPrint as farPrint
  , faRectangleList as farRectangleList
  , faRightFromBracket as farRightFromBracket
  , faRotate as farRotate
  , faShareNodes as farShareNodes
  , faSitemap as farSitemap
  , faSlash as farSlash
  , faSquare as farSquare
  , faSquareCheck as farSquareCheck
  , faSquareChevronDown as farSquareChevronDown
  , faSquareMinus as farSquareMinus
  , faStar as farStar
  , faShield as farShield
  , faSigma as farSigma
  , faSquareInfo as farSquareInfo
  , faSignsPost as farSignsPost
  , faTableCells as farTableCells
  , faTableCellsLarge as farTableCellsLarge
  , faTableList as farTableList
  , faTrashCan as farTrashCan
  , faTriangleExclamation as farTriangleExclamation
  , faThList as farThList
  , faThumbtack as farThumbtack
  , faUnlock as farUnlock
  , faUpload as farUpload
  , faUpRightFromSquare as farUpRightFromSquare
  , faUser as farUser
  , faUserGroup as farUserGroup
  , faUserHelmetSafety as farUserHelmetSafety
  , faUserShield as farUserShield
  , faUsers as farUsers
  , faUsersGear as farUsersGear
  , faWavePulse as farWavePulse
  , faXmark as farXmark
} from '@fortawesome/pro-regular-svg-icons';

import {
  faApartment as fadApartment
  , faBadgePercent as fadBadgePercent
  , faCalendar as fadCalendar
  , faCalendarDays as fadCalendarDays
  , faCartFlatbedBoxes as fadCartFlatbedBoxes
  , faChartNetwork as fadChartNetwork
  , faClockRotateLeft as fadClockRotateLeft
  , faFolders as fadFolders
  , faHeadSideBrain as fadHeadSideBrain
  , faLayerGroup as fadLayerGroup
  , faLanguage as fadLanguage
  , faMapLocationDot as fadMapLocationDot
  , faPenField as fadPenField
  , faShieldBlank as fadShieldBlank
  , faSignsPost as fadSignsPost
  , faSitemap as fadSitemap
  , faSquareList as fadSquareList
  , faTags as fadTags
  , faUserHelmetSafety as fadUserHelmetSafety
  , faUserLock as fadUserLock
  , faUserShield as fadUserShield
  , faUserTie as fadUserTie
  , faWebhook as fadWebhook
 } from '@fortawesome/pro-duotone-svg-icons';

// import {
  
// } from '@fortawesome/pro-light-svg-icons';


library.add(
//fad
  fadBadgePercent
  , fadApartment
  , fadCalendar
  , fadCalendarDays
  , fadCartFlatbedBoxes
  , fadChartNetwork
  , fadClockRotateLeft
  , fadFolders
  , fadHeadSideBrain
  , fadLanguage
  , fadLayerGroup
  , fadMapLocationDot
  , fadPenField
  , fadShieldBlank
  , fadSignsPost
  , fadSitemap
  , fadSquareList
  , fadTags
  , fadUserHelmetSafety
  , fadUserLock
  , fadUserShield
  , fadUserTie
  , fadWebhook
//fal
  
//far
  , farAlarmExclamation
  , farAnalytics
  , farApartment
  , farArrowDown
  , farArrowDownToBracket
  , farArrowsLeftRight
  , farArrowRightArrowLeft
  , farArrowsRotate
  , farArrowsUpDown
  , farArrowUp
  , farArrowUpFromBracket
  , farBadgeDollar
  , farBackwardStep
  , farBarsStaggered
  , farBinary
  , farBinarySlash
  , farBolt
  , farBooksMedical
  , farBorderAll
  , farBorderNone
  , farBuilding
  , farCalendarCheck
  , farCalendarDay
  , farCalendarDays
  , farCalendarExclamation
  , farCaretDown
  , farCartFlatbedBoxes
  , farChartBar
  , farChartLine
  , farChartNetwork
  , farChartSimple
  , farCheck
  , farChevronDown
  , farChevronRight
  , farChevronLeft
  , farChevronUp
  , farCircle
  , farCircleCheck
  , farCircleChevronLeft
  , farCircleChevronRight
  , farCircleExclamation
  , farCircleInfo
  , farCircleMinus
  , farCirclePlus
  , farCircleQuestion
  , farClock
  , farClockRotateLeft
  , farClone
  , farCodeBranch
  , farCopy
  , farDesktop
  , farDownload
  , farDownToBracket
  , farEllipsisVertical
  , farEnvelope
  , farEye
  , farEyeSlash
  , farFile
  , farFileArchive
  , farFileArrowUp
  , farFileAudio
  , farFileExport
  , farFileExcel
  , farFileImage
  , farFileImport
  , farFileMagnifyingGlass
  , farFilePdf
  , farFileSpreadsheet
  , farFileVideo
  , farFileWord
  , farFilter
  , farFilterCircleXmark
  , farFilters
  , farFolder
  , farFolderPlus
  , farForwardStep
  , farFloppyDisk
  , farGaugeHigh
  , farGavel
  , farGear
  , farGears
  , farGrid
  , farHeadSideBrain
  , farHistory
  , farHouse
  , farImage
  , farInboxIn
  , farInboxOut
  , farIndent
  , farInfo
  , farLayerGroup
  , farLayerPlus
  , farLink
  , farList
  , farListTree
  , farLock
  , farMagnifyingGlass
  , farMagnifyingGlassMinus
  , farMagnifyingGlassPlus
  , farMapMarkedAlt
  , farMerge
  , farMinus
  , farMoonStars
  , farOutdent
  , farPalette
  , farPaste
  , farPen
  , farPenField
  , farPenToSquare
  , farPersonDigging
  , farPlay
  , farPlus
  , farPrint
  , farRectangleList
  , farRightFromBracket
  , farRotate
  , farSigma
  , farSquareInfo
  , farSignsPost
  , farShareNodes
  , farShield
  , farSitemap
  , farSlash
  , farSquare
  , farSquareCheck
  , farSquareChevronDown
  , farSquareMinus
  , farStar
  , farTableCells
  , farTableCellsLarge
  , farTableList
  , farThList
  , farThumbtack
  , farTrashCan
  , farTriangleExclamation
  , farUnlock
  , farUpload
  , farUpRightFromSquare
  , farUser
  , farUserGroup
  , farUserHelmetSafety
  , farUserShield
  , farUsers
  , farUsersGear
  , farWavePulse
  , farXmark  
  
//fas
  , fasBuilding  
  , fasCalendarDays
  , fasCheck
  , fasCircle
  , fasCircleCheck
  , fasCog
  , fasDiamond
  , fasEllipsisVertical //Used to fix a fontawesome bug: when icon is hidden (visibility: hidden) when loaded. The 'prefix attribute of svg is always 'fas'.
  , fasExclamationTriangle
  , fasHeadSideBrain
  , fasMapMarkedAlt
  , fasShareNodes
  , fasSitemap
  , fasStar
  , fasThList
  , fasTimes
  , fasUserHardHat
  , fasUsers
)
dom.watch();

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
