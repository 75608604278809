var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "taskCompact",
      staticClass: "task-compact ag-header-group-cell-label",
    },
    [
      _c(
        "div",
        {
          ref: "menuButton",
          staticClass: "ag-cell-label-container ag-header-cell-sorted-none",
        },
        [
          _c("div", { staticClass: "ag-header-cell-label" }, [
            _c(
              "div",
              {
                staticClass: "g-header-cell-menu",
                on: _vm._d({}, [
                  _vm.clickEvent,
                  function ($event) {
                    return _vm.onMenuClicked($event)
                  },
                ]),
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "fa-regular fa-gear" },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: { display: "inline-block", "margin-left": "20px" },
              },
              [
                _vm.totalMode
                  ? _c("font-awesome-icon", {
                      staticStyle: {
                        "margin-right": "6px",
                        "vertical-align": "-3px",
                      },
                      attrs: { icon: "fa-regular fa-sigma" },
                    })
                  : _vm._e(),
                _c("span", { staticClass: "ag-header-cell-text" }, [
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v(" " + _vm._s(_vm.groupHeaderLabel) + " "),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                ref: "task-compact-multiselect-wrapper",
                staticClass: "ml-1 task-compact-multiselect-wrapper",
              },
              [
                _c("multiselect", {
                  ref: "task-compact-multiselect",
                  staticClass:
                    "custom-dropdown-options task-compact-multiselect-cell-header enable-option-icon fit-label-fix fit-content-fix",
                  class: _vm.multiselectId,
                  attrs: {
                    "max-height": 1000,
                    options: _vm.propertyOpts.map((i) => i.value),
                    "custom-label": _vm.getPropertyOptionLabel,
                    placeholder: "",
                    searchable: false,
                    "allow-empty": false,
                    showLabels: false,
                  },
                  on: { open: _vm.onSelectOpened },
                  scopedSlots: _vm._u([
                    {
                      key: "option",
                      fn: function (props) {
                        return [
                          _vm.property == props.option
                            ? _c("font-awesome-icon", {
                                staticClass: "selected-option-icon",
                                attrs: { icon: ["far", "check"] },
                              })
                            : _vm._e(),
                          _c("span", { staticClass: "option__title" }, [
                            _vm._v(
                              _vm._s(_vm.getPropertyOptionLabel(props.option))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.property,
                    callback: function ($$v) {
                      _vm.property = $$v
                    },
                    expression: "property",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }