
import { httpAjax, urlParams } from '@/helpers';
import { getPermissionDenyProperties } from '@/helpers/permission'
import { getKeysWithoutRedactedFields } from './common';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

export const staffService = {
  create,
  list,
  query,
  update,
  remove,
  listDepartments,
  calendar,
  staffToTask,
  usage,
  allocation,
  projectAll,
  projectActive,
  projectRemaining,
  projectAllBooking,
  projectActiveBooking,
  projectRemainingBooking,
  clone,
  getGenericStaffState,
  allStaff,
  plannerList,
  listv2,
  email,
  getCompanyByStaffIds,
  listUniqueValuesOfProperty
};

/**
 * Create a new staff 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', fullName: 'John Doe' ... }]
 */
function create(data) {
  const url = '/api/staff/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of staff info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='fullName', order: 'incr' }}
 * @param Boolean isGenericStaff: Flag to get generic staff list if true
 */
function list(params, isGenericStaff=false, allStaff=false) {
  const fields = {
    uuId: 'STAFF.uuId',
    firstName: 'STAFF.firstName',
    lastName: 'STAFF.lastName',
    department: 'STAFF.DEPARTMENT.name',
    departmentUuId: 'STAFF.DEPARTMENT.uuId',
    userEmail: 'STAFF.USER.email',
    email: 'STAFF.email',
    position: 'STAFF.position',
    location: 'STAFF.LOCATION.name',
    resourceNames: 'STAFF.RESOURCE.name',
    resourceUnits: 'STAFF.STAFF-RESOURCE.quantity',
    payAmount: 'STAFF.payAmount',
    payCurrency: 'STAFF.payCurrency',
    payFrequency: 'STAFF.payFrequency',
    skillName: 'STAFF.SKILL.name',
    skillLevel: 'STAFF.STAFF-SKILL.level',
    genericStaff: 'STAFF.genericStaff',
    company: 'STAFF.COMPANY.name',
    staffType: 'STAFF.staffType',
    identifier: 'STAFF.identifier',
    phone: 'STAFF.phones',
    socials: 'STAFF.socials',
    websites: 'STAFF.websites',
    startDate: 'STAFF.startDate',
    endDate: 'STAFF.endDate',
    color: 'STAFF.color',
    companyColor: 'STAFF.COMPANY.color',
    departmentColor: 'STAFF.DEPARTMENT.color',
    locationColor: 'STAFF.LOCATION.color',
    skillColor: 'STAFF.SKILL.color',
    resourceColor: 'STAFF.RESOURCE.color',
    tag: 'STAFF.TAG.name',
    locationRebateName: 'STAFF.LOCATION.REBATE.name',
    locationRebateUuId: 'STAFF.LOCATION.REBATE.uuId',
    locationRebateRebate: 'STAFF.LOCATION.REBATE.rebate'
  }

  if(isGenericStaff) {
    delete fields.firstName;
    delete fields.lastName;
    delete fields.userEmail;
    delete fields.position;
    delete fields.phone;
    delete fields.socials;
    delete fields.websites;
    fields.name = 'STAFF.firstName'; //Use 'firstName' for 'name' till backend corrects the name property later.
  }

  let data = {
    'name': isGenericStaff? 'Generic Staff List' : 'Staff List'
    , 'type': 'msql'
    , 'nominate': 'STAFF'
    , 'start': params.start
    , 'limit': params.limit
    , 'select': Object.keys(fields).map(i => [fields[i]])
  }

  if (params.holders) {
    data.holder = params.holders;
  }
  
  let genericStaffCondition;
  if (isGenericStaff) {
    genericStaffCondition = [
        ['STAFF.genericStaff', 'eq', isGenericStaff]
    ];
  } else if (!allStaff) {
    genericStaffCondition = [
      "_or_", [
        ['STAFF.genericStaff', 'eq', isGenericStaff],
        "_not_" ,[ ["STAFF.genericStaff"] ]
      ]
    ];
  }

  const dateActive = moment();
  dateActive.set({hour:0,minute:0,second:0,millisecond:0}); // for an end date we need it to be midnight
  const activeCondition = [
    '_and_', [
      ['STAFF.startDate', 'lte', dateActive.unix() * 1000],
      ['STAFF.endDate', 'gte', dateActive.unix() * 1000]
    ]
  ];
  const inactiveCondition = [
    '_or_', [
      ['STAFF.endDate', 'lt', dateActive.unix() * 1000],
      ['STAFF.startDate', 'gt', dateActive.unix() * 1000]
    ]
  ];
  const neverCondition = [
    ['STAFF.uuId', 'eq', '00000000-0000-0000-0000-000000000000'],
  ]

  const noDepartmentCondition = [
    "_not_" ,[ ["STAFF.DEPARTMENT.uuId"] ]
  ]

  let activeInactiveCondition = null;
  if (typeof(params.active) !== 'undefined' && typeof(params.inactive) !== 'undefined') {
    if (params.active && !params.inactive) {
      activeInactiveCondition = activeCondition;
    } else if (!params.active && params.inactive) {
      activeInactiveCondition = inactiveCondition;
    } else if (!params.active && !params.inactive) {
      activeInactiveCondition = neverCondition;
    } else {
      // No need to filter if we want them all
    }
  }

  if (genericStaffCondition) {
    if (params.filter && params.filter.length > 0) {
      if (typeof params.filter === 'string') {
        const conditionGroup = [
          ...genericStaffCondition
          , '_or_', [
              ['STAFF.name', 'has', params.filter]
            , ['STAFF.identifier', 'has', params.filter]
            , ['STAFF.COMPANY.name', 'has', params.filter]
            , ['STAFF.DEPARTMENT.name', 'has', params.filter]
            , ['STAFF.email', 'has', params.filter]
            , ['STAFF.position', 'has', params.filter]
            , ['STAFF.LOCATION.name', 'has', params.filter]
            , ['STAFF.TAG.name', 'has', params.filter]
          ]
        ]
        data['filter'] = [
          '_and_', conditionGroup
        ]
      }
      else if(Array.isArray(params.filter)) {
        if (params.filter[0] === '_and_') {
          if (params.filter.length === 3) {
            data['filter'] = ['_and_', [...genericStaffCondition, [...params.filter[1]], [...params.filter[2]]]];
          }
          else {
            data['filter'] = ['_and_', [...genericStaffCondition, ...params.filter[1]]];
          }
        }
        else {
          data['filter'] = ['_and_', [...genericStaffCondition, ...params.filter]];
        }
      } else {
        data['filter'] = genericStaffCondition;
      }
    }
    else {
      data['filter'] = genericStaffCondition;
    }
  }
  else if (params.filter && params.filter.length > 0) {
    data['filter'] = params.filter;
  }
  
  if (activeInactiveCondition) {
    data['filter'].push(...activeInactiveCondition);
  }

  if (params.noDepartment) {
    data['filter'].push(...noDepartmentCondition);
  }

  // Can't run lowerCase on numeric fields
  let numeric = ['STAFF.payAmount', 'STAFF.startDate', 'STAFF.endDate'];

  if (params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if (Array.isArray(params.ksort)) {
      for (let i = 0, len = params.ksort.length; i < len; i++) {
        if (typeof fields[params.ksort[i]] !== 'undefined') {
          data['sort'].push([fields[params.ksort[i]], params.order[i], '', numeric.includes(fields[params.ksort[i]]) ? '' : 'lowerCase']);
        }
      }
    } else if (typeof fields[params.ksort] !== 'undefined'){
      data['sort'].push([fields[params.ksort], params.order, '', numeric.includes(fields[params.ksort]) ? '' : 'lowerCase']);
    }
  }

  if (params.holder) {
    data['holder'] = params.holder;
  }
  
  //Delete un-needed keys from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;
  delete params.active;
  delete params.holder;
  delete params.holders;
  
  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    
    return {
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort ? params.ksort : '',
      arg_order: params.order ? params.arg_order : null,
      data: rawData.map(i => {
        const result = {}
        for (let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
          if (keys[j] === 'department') {
            if (typeof i[j] === 'string') {
              result[keys[j]] = i[j];
            }
            else {
              result[keys[j]] = i[j].join(', ');
            }
          }
        }
        if(result.skillName && result.skillName.length > 0) {
          const skillNames = result.skillName;
          const skillLevels = result.skillLevel;
          let name = null;
          let level = null;
          const skills = [];
          for(let i = 0, iLen = skillNames.length; i < iLen; i++) {
            name = skillNames[i];
            level = skillLevels[i];
            if(name == null) {
              continue;
            }
            skills.push(`${name}${level? ' ('+level+')':''}`)
          }
          result.skills = skills.join(', ');
          delete result.skillName;
          delete result.skillLevel;
        }

        // preprocess rebates
        result.rebates = [];
        if (Array.isArray(result.locationRebateName) && Array.isArray(result.locationRebateUuId)) {
          for (let i = 0; i < result.locationRebateName.length; i++) {
            result.rebates.push({
              name: result.locationRebateName[i],
              uuId: result.locationRebateUuId[i],
              rebate: result.locationRebateRebate[i]
            });
          }
        }
        
        //Preprocess Resources details
        result.resources = [];
        if(result.resourceNames && result.resourceNames.length > 0) {
          for(let i = 0, len = result.resourceNames.length; i < len; i++) {
            result.resources.push({
              name: result.resourceNames[i],
              unit: result.resourceUnits[i]
            })
          }
        }
        delete result.resourceNames;
        delete result.resourceUnits;

        // Set 'Active' or 'Inactive' from start/end dates
        // Results always have a date, even when not set, with max/min value.
        let now = moment();
        var start = moment(result.startDate);
        var end = moment(result.endDate);
        if (start.isSameOrBefore(now) && end.isSameOrAfter(now)) {
          result.status = 'Active';
        } else {
          result.status = 'Inactive';
        }

        //Prepare for DetailLinkCellRenderer
        result.label = `${result.firstName ? result.firstName : ''}${result.lastName ? ' ' + result.lastName : ''}`;
        return result;
      })
    }
  });
}


/**
 * Retrieving a list of staff email addresses
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='fullName', order: 'incr' }}
 * @param Boolean isGenericStaff: Flag to get generic staff list if true
 */
function email(params) {
  const fields = {
    uuId: 'STAFF.uuId',
    email: 'STAFF.email'
  }

  let data = {
    'name': 'Staff Email List'
    , 'type': 'msql'
    , 'nominate': 'STAFF'
    , 'start': params.start
    , 'limit': params.limit
    , 'select': Object.keys(fields).map(i => [fields[i]])
  }

  //Delete un-needed keys from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;
  delete params.active;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    
    return {
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort ? params.ksort : '',
      arg_order: params.order ? params.arg_order : null,
      data: rawData.map(i => {
        const result = {}
        for (let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        
        return result;
      })
    }
  });
}

/**
 * Special tailored to be used in StaffSelectorModalFOrAdmin.vue
 * @param {*} params 
 * @param {*} isGenericStaff 
 * @returns 
 */
function listv2(params, isGenericStaff=false, customFields=[], { skillCustomFields=[], resourceCustomFields=[] }={}) {
  const fields = {
    uuId: 'STAFF.uuId'
    , firstName: 'STAFF.firstName'
    , lastName: 'STAFF.lastName'
    , departmentUuId: 'STAFF.DEPARTMENT.uuId'
    , departmentName: 'STAFF.DEPARTMENT.name'
    , departmentColor: 'STAFF.DEPARTMENT.color'
    , userEmail: 'STAFF.USER.email'
    , email: 'STAFF.email'
    , position: 'STAFF.position'
    , locationUuId: 'STAFF.LOCATION.uuId'
    , locationName: 'STAFF.LOCATION.name'
    , locationColor: 'STAFF.LOCATION.color'
    , resourceUuId: 'STAFF.RESOURCE.uuId'
    , resourceName: 'STAFF.RESOURCE.name'
    , resourceColor: 'STAFF.RESOURCE.color'
    , resourceUnit: 'STAFF.STAFF-RESOURCE.quantity'
    , resourceUtilization: 'STAFF.STAFF-RESOURCE.utilization'
    , payAmount: 'STAFF.payAmount'
    , payCurrency: 'STAFF.payCurrency'
    , payFrequency: 'STAFF.payFrequency'
    , skillUuId: 'STAFF.SKILL.uuId'
    , skillName: 'STAFF.SKILL.name'
    , skillColor: 'STAFF.SKILL.color'
    , skillLevel: 'STAFF.STAFF-SKILL.level'
    , genericStaff: 'STAFF.genericStaff'
    , companyUuId: 'STAFF.COMPANY.uuId'
    , companyName: 'STAFF.COMPANY.name'
    , companyColor: 'STAFF.COMPANY.color'
    , staffType: 'STAFF.staffType'
    , identifier: 'STAFF.identifier'
    , phones: 'STAFF.phones'
    , socials: 'STAFF.socials'
    , websites: 'STAFF.websites'
    , startDate: 'STAFF.startDate'
    , endDate: 'STAFF.endDate'
    , color: 'STAFF.color'
    , tag: 'STAFF.TAG.name'
    , resourceQuota: 'STAFF.resourceQuota'
    , readOnly: 'STAFF.readOnly'
  }

  if(isGenericStaff) {
    delete fields.lastName;
    delete fields.userEmail;
    delete fields.position;
    delete fields.phones;
    delete fields.socials;
    delete fields.websites;
  }

  if (Array.isArray(customFields) && customFields.length > 0) {
    for(const cField of customFields) {
      fields[cField.name] = `STAFF.${cField.name}`;
    }
  }

  if (Array.isArray(skillCustomFields) && skillCustomFields.length > 0) {
    for(const cField of skillCustomFields) {
      fields[`skill_${cField.name}`] = `STAFF.STAFF-SKILL.${cField.name}`;
    }
  }

  if (Array.isArray(resourceCustomFields) && resourceCustomFields.length > 0) {
    for(const cField of resourceCustomFields) {
      fields[`resource_${cField.name}`] = `STAFF.STAFF-RESOURCE.${cField.name}`;
    }
  }

  //When params.fields are provided, clean up the selectors which don't appear in the param.fields.
  if (Array.isArray(params.fields) && params.fields.length > 0) {
    let index = params.fields.findIndex(i => i == 'companies');
    if (index != -1) {
      params.fields.splice(index, 1, ...['companyUuId', 'companyName', 'companyColor'])
    }
    index = params.fields.findIndex(i => i == 'locations');
    if (index != -1) {
      params.fields.splice(index, 1, ...['locationUuId', 'locationName', 'locationColor'])
    }
    index = params.fields.findIndex(i => i == 'departments');
    if (index != -1) {
      params.fields.splice(index, 1, ...['departmentUuId', 'departmentName', 'departmentColor'])
    }
    index = params.fields.findIndex(i => i == 'skills');
    if (index != -1) {
      params.fields.splice(index, 1, ...['skillUuId', 'skillName', 'skillLevel', 'skillColor'])
    }
    index = params.fields.findIndex(i => i == 'resources');
    if (index != -1) {
      params.fields.splice(index, 1, ...['resourceUuId', 'resourceName', 'resourceUnit', 'resourceUtilization', 'resourceColor'])
    }
    const fieldProps = Object.keys(fields);
    for (const prop of fieldProps) {
      if (prop == 'uuId') {
        continue;
      }
      if (!params.fields.includes(prop)) {
        delete fields[prop];
      }
    }
  }
  delete params.fields;

  let data = {
    'name': isGenericStaff? 'Generic Staff Listv2' : 'Staff Listv2'
    , 'type': 'msql'
    , 'nominate': 'STAFF'
    , 'start': params.start
    , 'limit': params.limit
    , 'timeout': 300 // prevent large datasets timeout for search
    , 'select': Object.keys(fields).map(i => [fields[i]])
  }

  if (!params.singleContracts) {
    data["select"].push(["STAFF.CONTRACT.uuId","<RANDFLOAT>"]);
    data["dedup"] = "STAFF.CONTRACT.uuId";
  }
  delete params.singleContracts;
  
  if (Array.isArray(params.holders) && params.holders.length > 0) {
    data.holder = params.holders;
  }
  delete params.holders;

  let genericStaffCondition;
  if (isGenericStaff) {
    genericStaffCondition = [
        ['STAFF.genericStaff', 'eq', isGenericStaff]
    ];
  } else {
    genericStaffCondition = [
      "_or_", [
        ['STAFF.genericStaff', 'eq', isGenericStaff],
        "_not_" ,[ ["STAFF.genericStaff"] ]
      ]
    ];
  }

  const datesCondition = [
    '_or_', [
      '_and_', [
        ['STAFF.endDate', 'lte', params.endDate],
        ['STAFF.endDate', 'gte', params.startDate]
      ],
      '_and_', [
        ['STAFF.startDate', 'lte', params.endDate],
        ['STAFF.startDate', 'gte', params.startDate]
      ],
      '_and_', [
        ['STAFF.startDate', 'lte', params.endDate],
        ['STAFF.endDate', 'gte', params.startDate]
      ]
    ]
  ];
  
  if (params.filter && params.filter.length > 0) {
    if (typeof params.filter === 'string') {

      const conditionGroup = [
        ...genericStaffCondition
      ]

      const matchColumns = [
        ['STAFF.name', 'has', params.filter]
      ]

      const staffDeniedProperties = getPermissionDenyProperties('STAFF','VIEW')
      if (!staffDeniedProperties.includes('identifier')) {
        matchColumns.push(['STAFF.identifier', 'has', params.filter])
      }
      if (!staffDeniedProperties.includes('email')) {
        matchColumns.push(['STAFF.email', 'has', params.filter])
      }
      if (!staffDeniedProperties.includes('position')) {
        matchColumns.push(['STAFF.position', 'has', params.filter])
      }
      if (!staffDeniedProperties.includes('socials')) {
        matchColumns.push(['STAFF.socials', 'has', params.filter])
      }
      if (!staffDeniedProperties.includes('websites')) {
        matchColumns.push(['STAFF.websites', 'has', params.filter])
      }

      if (Array.isArray(customFields) && customFields.length > 0) {
        for (const c of customFields) {
          if ((c.type == 'String' || c.type == 'Enum<String>') && !staffDeniedProperties.includes(c.name)) {
            matchColumns.push([`STAFF.${c.name}`, 'has', params.filter])
          }
        }
      }

      const companyDeniedProperties = getPermissionDenyProperties('COMPANY', 'VIEW')
      if (!staffDeniedProperties.includes('COMPANY') && 
          !staffDeniedProperties.includes('COMPANY.name') && 
          !companyDeniedProperties.includes('name') ) {
        matchColumns.push(['STAFF.COMPANY.name', 'has', params.filter])
      }

      const departmentDeniedProperties = getPermissionDenyProperties('DEPARTMENT', 'VIEW')
      if (!staffDeniedProperties.includes('DEPARTMENT') && 
          !staffDeniedProperties.includes('DEPARTMENT.name') && 
          !departmentDeniedProperties.includes('name') ) {
        matchColumns.push(['STAFF.DEPARTMENT.name', 'has', params.filter])
      }

      const locationDeniedProperties = getPermissionDenyProperties('LOCATION', 'VIEW')
      if (!staffDeniedProperties.includes('LOCATION') && 
          !staffDeniedProperties.includes('LOCATION.name') && 
          !locationDeniedProperties.includes('name') ) {
        matchColumns.push(['STAFF.LOCATION.name', 'has', params.filter])
      }

      const tagDeniedProperties = getPermissionDenyProperties('TAG', 'VIEW')
      if (!staffDeniedProperties.includes('TAG') && 
          !staffDeniedProperties.includes('TAG.name') && 
          !tagDeniedProperties.includes('name') ) {
        matchColumns.push(['STAFF.TAG.name', 'has', params.filter])
      }

      const skillDeniedProperties = getPermissionDenyProperties('SKILL', 'VIEW')
      if (!staffDeniedProperties.includes('SKILL') && 
          !staffDeniedProperties.includes('SKILL.name') && 
          !skillDeniedProperties.includes('name') ) {
        matchColumns.push(['STAFF.SKILL.name', 'has', params.filter])
      }

      const resourceDeniedProperties = getPermissionDenyProperties('RESOURCE', 'VIEW')
      if (!staffDeniedProperties.includes('RESOURCE') && 
          !staffDeniedProperties.includes('RESOURCE.name') && 
          !skillDeniedProperties.includes('name') ) {
            resourceDeniedProperties.push(['STAFF.RESOURCE.name', 'has', params.filter])
      }


      if (matchColumns.length > 0) {
        conditionGroup.push('_or_')
        conditionGroup.push(matchColumns)
      }
     
      data['filter'] = [
        '_and_', conditionGroup
      ]
    }
    else if(Array.isArray(params.filter)) {
      if (params.filter[0] === '_and_') {
        data['filter'] = ['_and_', [...genericStaffCondition, ...params.filter[1]]];
      }
      else {
        data['filter'] = ['_and_', [...genericStaffCondition, ...params.filter]];
      }
    } else {
      data['filter'] = genericStaffCondition;
    }
  }
  else {
    data['filter'] = genericStaffCondition;
  }
  
  if (!isGenericStaff && params.startDate && params.endDate) {
    data['filter'].unshift(...datesCondition);
  }
  
  //BadgeFilter related
  if (Array.isArray(params.badgeFilters) && params.badgeFilters.length > 0) {
    const badgeFilterList = [];
    for (const f of params.badgeFilters) {
      if (f.field == null || !Array.isArray(f.value) || f.value.length == 0) {
        continue;
      }
      
      let field = null;
      if (f.field == 'tagName') {
        field = 'STAFF.TAG.name';
      } else if (f.field == 'companyName') {
          field = 'STAFF.COMPANY.name';
      } else if (f.field == 'departmentName') {
        field = 'STAFF.DEPARTMENT.name';
      } else if (f.field == 'skillName') {
        field = 'STAFF.SKILL.name';
      } else if (f.field == 'skillLevel') {
        field = 'STAFF.STAFF-SKILL.level';
      } else if (f.field == 'locationName') {
        field = 'STAFF.LOCATION.name';
      } else if (f.field == 'resourceName') {
        field = 'STAFF.RESOURCE.name';
      } else {
        field = `STAFF.${f.field}`;
      } 

      const isEqual = !f.operator || f.operator === 'is';
      let valueList = [];
      const value = f.value;
      for (const v of value) {
        if (v.text != null && (typeof v.text === 'number' || (typeof v.text === 'string' && v.text&& v.text.length > 0 && v.text !== '(Empty)'))) {
          //use value when exists. Otherwise use text
          //Reason: When value property exists, it means the text is localized and can't be used in filter.
          valueList.push([field, 'eq', Object.hasOwn(v, 'value')? v.value : v.text]);
        }
        //Start processing (EMPTY) field value 
        else if (isEqual) {
          valueList.push('_not_', [[field]])
        } else {
          //Special handling: when is not empty, put it into badgeFilterList directly to act as 'field must exist'. 
          badgeFilterList.push([field])
        }
      }
      if (valueList.length > 0) {
        if (valueList.length > 1) {
          valueList = ['_or_', valueList];
        }
        if (!isEqual) {
          badgeFilterList.push('_not_', valueList);
        } else {
          badgeFilterList.push(...valueList);
        }
      }
    }
    if (badgeFilterList.length > 0) {
      if (Array.isArray(data.filter) && data.filter.length > 0) {
        data.filter = [ ...badgeFilterList, ...data.filter]
      } else {
        data.filter = [...badgeFilterList]
      }
    }
  }
  
  // Can't run lowerCase on numeric fields
  let numeric = ['STAFF.payAmount', 'STAFF.startDate', 'STAFF.endDate'];

  if (params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if (Array.isArray(params.ksort)) {
      for (let i = 0, len = params.ksort.length; i < len; i++) {
        if (typeof fields[params.ksort[i]] !== 'undefined') {
          data['sort'].push([fields[params.ksort[i]], params.order[i], '', numeric.includes(fields[params.ksort[i]]) ? '' : 'lowerCase']);
        }
      }
    } else if (typeof fields[params.ksort] !== 'undefined'){
      data['sort'].push([fields[params.ksort], params.order, '', numeric.includes(fields[params.ksort]) ? '' : 'lowerCase']);
    }
  }

  // Comment Statement Reason: data.filter is still needed even when holders are supplied
  // Use case: In dataview, StaffSelectorModalForAdmin need to show the shortlisted staff in list and generic tabs
  //           Without filter, generic tab will list the real staff (wrong result).
  // StaffSelectorModalForAdmin can be toggled when column is COUNT(TASK.STAFF.name)
  //
  // if we use holders we don't use filter
  // if (data.holder) {
  //   delete data['filter'];
  // }
  
  //Delete un-needed keys from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;
  delete params.active;
  delete params.badgeFilters;
  delete params.startDate;
  delete params.endDate;
  
  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    const getFirstItemOfArray = (arr, defaultValue) => {
      return Array.isArray(arr) && arr.length > 0? arr[0] : defaultValue
    }
    const getArrayItemAtIndex = (arr, idx, defaultValue) => {
      return Array.isArray(arr) && arr.length >= idx? arr[idx] : defaultValue
    }
    return {
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort ? params.ksort : '',
      arg_order: params.order ? params.arg_order : null,
      data: rawData.map(i => {
        const result = {}
        for (let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Companies
        result.companies = []
        if(Array.isArray(result.companyUuId) && result.companyUuId.length > 0) {
          result.companyColor = getFirstItemOfArray(result.companyColor, '')
          for(let i = 0, iLen = result.companyUuId.length; i < iLen; i++) {
            result.companies.push({
              uuId: result.companyUuId[i]
              , name: getArrayItemAtIndex(result.companyName, i, '')
            })
          }
        } else {
          result.companyColor = ''
        }
        delete result.companyUuId
        delete result.companyName
        
        //Skills
        result.skills = []
        if(Array.isArray(result.skillUuId) && result.skillUuId.length > 0) {
          result.skillColor = getFirstItemOfArray(result.skillColor, '')
          for(let i = 0, iLen = result.skillUuId.length; i < iLen; i++) {
            const skillObj = {
              uuId: result.skillUuId[i]
              , name: getArrayItemAtIndex(result.skillName, i, '')
              , level: getArrayItemAtIndex(result.skillLevel, i, '')
            }
            for (const f of skillCustomFields) {
              skillObj[f.name] = getArrayItemAtIndex(result[`skill_${f.name}`], i, null)
            }
            result.skills.push(skillObj);
          }
        } else {
          result.skillColor = ''
        }
        delete result.skillUuId
        delete result.skillName
        
        //Preprocess Resources details
        result.resources = []
        if(Array.isArray(result.resourceUuId) && result.resourceUuId.length > 0) {
          result.resourceColor = getFirstItemOfArray(result.resourceColor, '')
          for(let i = 0, len = result.resourceUuId.length; i < len; i++) {

            const resourceObj = {
              uuId: result.resourceUuId[i]
              , name: getArrayItemAtIndex(result.resourceName, i, '')
              , unit: getArrayItemAtIndex(result.resourceUnit, i, '')
              , utilization: getArrayItemAtIndex(result.resourceUtilization, i, '')
            }
            for (const f of resourceCustomFields) {
              resourceObj[f.name] = getArrayItemAtIndex(result[`resource_${f.name}`], i, null)
            }
            result.resources.push(resourceObj);
          }
        } else {
          result.resourceColor = ''
        }
        delete result.resourceUuId
        delete result.resourceName
        delete result.resourceUnit
        delete result.resourceUtilization

        //Locations
        result.locations = []
        if(Array.isArray(result.locationUuId) && result.locationUuId.length > 0) {
          result.locationColor = getFirstItemOfArray(result.locationColor, '')
          for(let i = 0, len = result.locationUuId.length; i < len; i++) {
            result.locations.push({
              uuId: result.locationUuId[i]
              , name: result.locationName[i]
            })
          }
        } else {
          result.locationColor = ''
        }
        delete result.locationUuId
        delete result.locationName

        //Departments
        result.departments = []
        if(Array.isArray(result.departmentUuId) && result.departmentUuId.length > 0) {
          result.departmentColor = getFirstItemOfArray(result.departmentColor, '')
          for(let i = 0, len = result.departmentUuId.length; i < len; i++) {
            result.departments.push({
              uuId: result.departmentUuId[i]
              , name: result.departmentName[i]
            })
          }
        } else {
          result.departmentColor = ''
        }
        delete result.departmentUuId
        delete result.departmentName

        // Set 'Active' or 'Inactive' from start/end dates
        // Results always have a date, even when not set, with max/min value.
        let now = moment();
        now.set({hour:0,minute:0,second:0,millisecond:0}); // for an end date we need it to be midnight
        var start = moment(result.startDate);
        var end = moment(result.endDate);
        if (start.isSameOrBefore(now) && end.isSameOrAfter(now)) {
          result.status = 'Active';
        } else {
          result.status = 'Inactive';
        }

        //Prepare for DetailLinkCellRenderer
        result.label = `${result.firstName ? result.firstName : ''}${result.lastName ? ' ' + result.lastName : ''}`;
        return result;
      })
    }
  });
}

/**
 * Retrieving a list of staff info for the planner
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25 }}
 */
function plannerList(params, additionalFields = []) {
  const fields = {
    uuId: 'STAFF.uuId',
    name: 'STAFF.name',
    payAmount: 'STAFF.payAmount',
    payFrequency: 'STAFF.payFrequency',
    payCurrency: 'STAFF.payCurrency',
    contractUuId: [
        "STAFF.CONTRACT.uuId",
        "<RANDFLOAT>"
    ]
  }

  for (const field of additionalFields) {
    fields[field] = `STAFF.${field}`;
  }
  let data = {
    'name': 'Planner Staff List'
    , 'type': 'msql'
    , 'nominate': 'STAFF'
    , 'start': params.start
    , 'limit': params.limit
    , 'select': Object.keys(fields).map(i => Array.isArray(fields[i]) ? fields[i] : [fields[i]])
  }

  //Delete un-needed keys from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;
  delete params.active;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return {
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort ? params.ksort : '',
      arg_order: params.order ? params.arg_order : null,
      data: rawData.map(i => {
        const result = {}
        for (let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        
        //Prepare for DetailLinkCellRenderer
        result.label = `${result.firstName ? result.firstName : ''}${result.lastName ? ' ' + result.lastName : ''}`;
        return result;
      })
    }
  });
}

function staffToTask(taskPool, staffList, settings) {
  let data = {
    'type': 'Recommend',
    'overAllocateStaff': settings.allow_over_alloc,
    'includeAssignedTask': true,
    'includeStartedTask': true,
    'skillMatchList': [],
    'taskList': taskPool,
    'staffList': staffList
  };

  if (settings.match_staff_based_on_skills) {
    data.skillMatchList.push({ 'level': 'Yes' });
  }
  else {
    data.skillMatchList.push({ 'level': 'No' });
  }
  if (settings.include_staff_exact_skill) {
    data.skillMatchList.push({ 'level': 'Exact' });
  }
  if (settings.include_staff_lower_skill) {
    data.skillMatchList.push({ 'level': 'Low', 'changeDuration': settings.adjust_task_duration_lower });
  }
  if (settings.include_staff_higher_skill) {
    data.skillMatchList.push({ 'level': 'High', 'changeDuration': settings.adjust_task_duration_higher });
  }
  const url = '/api/allocation/staff';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}
/**
 * Retrieving a list of staff details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
 function query(data, links=null) {
  const url = urlParams('/api/staff/get', links);
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a list of staff calendars
 */
function calendar(uuId) {
  const url = `/api/staff/${uuId}/calendar`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config);
}

/**
 * Retrieving a list of staff usage
 */
function usage(params, holder, staffList) {
  const url = `/api/staff/usage`;
  var data = null;
  const config = {
    params: params,
    headers: getHeaders()
  };

  if (!holder) {
    data = staffList;
    delete params['holder'];
  }
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a list of staff usage
 */
function allocation(params, data) {
  const url = `/api/allocation/staff`;
  const config = {
    params: params,
    headers: getHeaders()
  };

  return httpAjax.post(url, data, config);
}

/**
 * Update staff details 
 * by passing  necessary information
 * @param Array data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', fullName: 'John Doe' ... }]
 */
function update(data) {
  const url = '/api/staff/update';
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of staffs
 * by passing in an array list of uuIds
 * @param Array data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = '/api/staff/delete';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}


/**
 * Retrieving a list of department linked to the given staff (uuId)
 * @param String Staff UUID 
 * 
 */
function listDepartments(staffId) {
  const fields = {
    uuId: 'DEPARTMENT.uuId',
    name: 'DEPARTMENT.name',
    staffId: 'DEPARTMENT.STAFF.uuId'
  }

  let data = {
    'name': 'Staff Department List'
    , 'type': 'msql'
    , 'start': 0
    , 'limit': 5000
    , 'select': Object.keys(fields).map(i => [fields[i]])
  }

  data['filter'] = [
    ['DEPARTMENT.STAFF.uuId', 'is', staffId]
  ]

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return {
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for (let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        return result;
      })
    }
  });
}

// Get the staff details, per task, in a project
// Need to dedupe staff after, if needed
function projectList(filter, projectId) {
  const fields = {
    uuId: 'STAFF.uuId',
    firstName: 'STAFF.firstName', 
    lastName: 'STAFF.lastName', 
    position: 'STAFF.position',
    progress: 'STAFF.TASK.progress'
  }

  let data = {
    'name'    : 'List staff in project'
    ,'type'   : 'msql'
    ,'start'  : 0
    ,'limit'  : -1
    ,'group' : 'STAFF'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }
  
  data['filter'] = filter;

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const obj = {}
        for(let j = 0, len = i.length; j < len; j++) {
          obj[keys[j]] = i[j];
        }
        return obj;
      })
    }
  });
}

//Get the staff details, per task, in a project
//Need to dedupe staff after, if needed
function projectListBooking(filter, projectId) {
const fields = {
 uuId: 'PROJECT.BOOKING.STAFF.uuId',
 firstName: 'PROJECT.BOOKING.STAFF.firstName', 
 lastName: 'PROJECT.BOOKING.STAFF.lastName', 
 position: 'PROJECT.BOOKING.STAFF.position',
 untilDate: 'PROJECT.BOOKING.untilDate'
}

let data = {
 'name'    : 'List staff in project'
 ,'type'   : 'msql'
 ,'start'  : 0
 ,'limit'  : -1
 ,'group' : 'PROJECT'
 ,'holder': projectId
 ,'select': Object.keys(fields).map(i => [fields[i]])
}

data['filter'] = filter;

const url = '/api/query/match';
const config = {
 
}
return httpAjax.post(url, data, config).then(response => {
 const listName = response.data.jobCase;
 const rawData = response.data[listName] || [];
 const keys = Object.keys(fields);
 
 const list = [];
 for (let i = 0; i < rawData.length; i++) {
   for(let j = 0, len = rawData[i].length; j < len; j++) {
     for (let k = 0, len = rawData[i][j].length; k < len; k++) {
       if (j === 0) {
         let obj = {};
         obj[keys[j]] = rawData[i][j][k];
         list.push(obj);
       }
       else {
         let obj = list[k];
         obj[keys[j]] = rawData[i][j][k];
       }
     }
   }
 }
 return { 
   arg_total: response.data.arg_total,
   data: list
 }
});
}

//Get the staff details, per task, in a project
//Need to dedupe staff after, if needed
function allStaff() {
  const fields = {
   uuId: 'STAFF.uuId'
  }
  
  let data = {
   'name'    : 'List all staff'
   ,'type'   : 'msql'
   ,'start'  : 0
   ,'limit'  : -1
   ,'select': Object.keys(fields).map(i => [fields[i]])
  }
  
  const url = '/api/query/match';
  const config = {
   
  }
  return httpAjax.post(url, data, config).then(response => {
   const listName = response.data.jobCase;
   const rawData = response.data[listName] || [];
   const keys = Object.keys(fields);
   
   return { 
     arg_total: response.data.arg_total,
     data: rawData.map(i => {
       const obj = {}
       for(let j = 0, len = i.length; j < len; j++) {
         obj[keys[j]] = i[j];
       }
       return obj;
     })
   }
  });
}

function projectAll(projectId, bookings, tasks) {
  const filter = [
    ['STAFF.genericStaff', 'eq', false]
  ];
  filter.push(['STAFF.TASK.PROJECT.uuId', 'eq', projectId]);
  return projectList(filter, projectId);
}

function projectActive(projectId, bookings, tasks) {
  const filter = [
      ['STAFF.genericStaff', 'eq', false]
  ];
  filter.push(...[
    ['STAFF.TASK.PROJECT.uuId', 'eq', projectId],
    ['STAFF.TASK.progress', 'gt', 0.0],
    ['STAFF.TASK.progress', 'lt', 1.0]
  ]);
  return projectList(filter, projectId);
}

function projectRemaining(projectId) {
  const filter = [
    ['STAFF.TASK.STAFF.genericStaff', 'eq', false]
  ];

  filter.push(...[
    ['STAFF.TASK.PROJECT.uuId', 'eq', projectId],
    ['STAFF.TASK.progress', 'gte', 0.0],
    ['STAFF.TASK.progress', 'lt', 1.0]
  ]);
  return projectList(filter, projectId);
}

function projectAllBooking(projectId) {
  const filter = [
    ['STAFF.genericStaff', 'eq', false]
  ];
  
  return projectListBooking(filter, projectId);
}

function projectActiveBooking(projectId) {
  const filter = [
      ['STAFF.genericStaff', 'eq', false]
  ];

  filter.push(...[
    ['PROJECT.BOOKING.beginDate', 'lt', '<NOW>'],
    ['PROJECT.BOOKING.untilDate', 'gt', '<NOW>']
  ]);

  return projectListBooking(filter, projectId);
}

function projectRemainingBooking(projectId) {
  const filter = [
    ['STAFF.TASK.STAFF.genericStaff', 'eq', false]
  ];

  filter.push(...[
    ['PROJECT.BOOKING.untilDate', 'gt', '<NOW>']
    ]);
 
  return projectListBooking(filter, projectId);
}

/**
 * Clone a staff with given new name
 * @param String refId Target staff UuId
 * @param Object data Object contains name for cloned staff.
 */
function clone(refId, data, { asContract=false }={}) {
  const url = '/api/staff/clone';
  const params = { reference: refId }
  if (asContract) {
    params.as_contract = true;
  }
  const config = {
    data: data,
    headers: getHeaders(),
    params
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a list of staff 'genericStaff' property by 
 * passing in an array list of staff uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
 function getGenericStaffState(uuIds) {
  if (uuIds == null || uuIds.length < 0) {
    return Promise.resolve({ arg_total: 0, data: [] });
  }

  const fields = {
    uuId: 'STAFF.uuId',
    genericStaff: 'STAFF.genericStaff'
  }

  let data = {
    'name': 'Get genericStaff properties by staff UuIds'
    , 'type': 'msql'
    , 'nominate': 'STAFF'
    , 'start': 0
    , 'limit': -1
    , 'select': Object.keys(fields).map(i => [fields[i]])
  }

  data['holder'] = uuIds;

  const url = '/api/query/match';
  return httpAjax.post(url, data, {}).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return {
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for (let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        return result;
      })
    }
  });
}

// Get the staff details, per task, in a project
// Need to dedupe staff after, if needed
function getCompanyByStaffIds(staffIds) {
  const fields = {
    uuId: 'STAFF.uuId',
    companyId: 'STAFF.COMPANY.uuId',
    companyName: 'STAFF.COMPANY.name'
  }

  let data = {
    'name'    : 'List staff in project'
    ,'type'   : 'msql'
    ,'start'  : 0
    ,'limit'  : -1
    ,'nominate' : 'STAFF'
    ,'holder': staffIds
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }
  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const obj = {}
        for(let j = 0, len = i.length; j < len; j++) {
          obj[keys[j]] = i[j];
        }

        obj.companies = [];
        if (Array.isArray(obj.companyId) && obj.companyId.length > 0) {
          for (let j = 0, len = obj.companyId.length; j < len; j++) {
            obj.companies.push({
              uuId: obj.companyId[j]
              , name: obj.companyName[j] 
            });
          }
        }
        delete obj.companyId;
        delete obj.companyName;
        return obj;
      })
    }
  });
}

function listUniqueValuesOfProperty(field, { enableGenericFilter=false, isGeneric=false, startDate=null, endDate=null }={}) {
  const fieldSelector = [`STAFF.${field}`]
  
  const data = {
    'name'  : `Unique STAFF property: ${field}`
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'select': [
      fieldSelector
    ]
    , 'dedup': true
  }

  if (enableGenericFilter == true) {
    if (isGeneric == true) {
      data.filter = [
        [
          'STAFF.genericStaff',
          'eq',
          true
        ]
        
      ]
    } else {
      data.filter = [
        '_or_',
        [
          [
            'STAFF.genericStaff',
            'eq',
            false
          ],
          '_not_',
          [
            [
              'STAFF.genericStaff'
            ]
          ]
        ]
      ]
    }
  }

  const datesCondition = [
    '_or_', [
      '_and_', [
        ['STAFF.endDate', 'lte', endDate],
        ['STAFF.endDate', 'gte', startDate]
      ],
      '_and_', [
        ['STAFF.startDate', 'lte', endDate],
        ['STAFF.startDate', 'gte', startDate]
      ],
      '_and_', [
        ['STAFF.startDate', 'lte', endDate],
        ['STAFF.endDate', 'gte', endDate]
      ]
    ]
  ];
  const neverCondition = [
    ['STAFF.uuId', 'eq', '00000000-0000-0000-0000-000000000000'],
  ]

  if (startDate !== null && endDate !== null) {
    if (Array.isArray(data.filter)) {
      data.filter.push(...datesCondition);
    } else {
      data.filter = datesCondition;
    }
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    let rawData = response.data[listName] || [];
    rawData = rawData.map(i => i[0]);

    //Special case: when getting unique staff skill names, the value is not a string but is an array
    //collect the values in each array and produce the unique set
    if (rawData.length > 0 && Array.isArray(rawData[0])) {
      const valueSet = []
      for (const rData of rawData) {
        for (const val of rData) {
          valueSet.add(val);
        }
      }
      rawData = Array.from(valueSet);
    }
    
    rawData.sort((a, b) => {
      if (typeof a === 'string') {
        return a.localeCompare(b, undefined, { sensitivity: 'base'})
      } else {
        if (a < b) {
          return -1
        } else if (a > b) {
          return 1
        }
        return 0
      }
    });
    return rawData;
  });
}

// function getStaffUniqueCustomFieldName(staffId, fieldName) {
//   return __getUniqueName(staffId, 'STAFF', [`STAFF.${fieldName}`])
// }

// function __getUniqueName(staffId, field, fieldSelector) {
//   const data = {
//     'name'  : `Unique ${field} Names`
//     ,'type' : 'msql'
//     ,'start' : 0
//     ,'limit' : -1
//     ,'select': [
//       fieldSelector
//     ]
//     , 'dedup': true
//     , 'holder': [projectId]
//   }

//   return httpAjax.post('/api/query/match', data, {}).then(response => {
//     const listName = response.data.jobCase;
//     let rawData = response.data[listName] || [];
//     rawData = rawData.map(i => i[0]);
//     rawData.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base'}));
//     return rawData;
//   });
// }