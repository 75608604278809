var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.actions.length === 0
    ? _c("router-link", {
        attrs: { to: _vm.url, custom: "" },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var isActive = ref.isActive
                return [
                  _c(
                    "li",
                    {
                      staticClass: "nav-item nav-dropdown",
                      class: [
                        _vm.keepopen ? "open" : "",
                        isActive && "side-link-active",
                      ],
                      attrs: { disabled: "" },
                    },
                    [
                      _c(
                        "div",
                        { on: { click: _vm.handleClick } },
                        [
                          _c(
                            "router-link",
                            _vm._b(
                              { class: _vm.classList, attrs: { to: _vm.url } },
                              "router-link",
                              _vm.attributes,
                              false
                            ),
                            [
                              _c("font-awesome-icon", {
                                staticClass: "nav-icon",
                                attrs: { icon: _vm.icon },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.name))]),
                              _vm.badge && _vm.badge.text
                                ? _c(
                                    "b-badge",
                                    { attrs: { variant: _vm.badge.variant } },
                                    [_vm._v(_vm._s(_vm.badge.text))]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.showSubmenus()
                        ? _c(
                            "ul",
                            { staticClass: "nav-dropdown-items" },
                            [_vm._t("default", null, { id: _vm.currentId })],
                            2
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      })
    : _c(
        "li",
        {
          staticClass: "nav-item nav-dropdown",
          class: _vm.keepopen ? "bg-open open" : "closed",
          on: { mouseleave: _vm.onMenuLeave },
        },
        [
          _c(
            "div",
            {
              staticClass: "toggle-expand d-inline-block nav-link",
              on: { click: _vm.toggleExpand },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "toggle-icon nav-icon",
                attrs: {
                  id: "COLLAPSE_EXPAND_" + _vm.name.replace(/\W/g, "_"),
                  icon: _vm.keepopen
                    ? ["far", "chevron-down"]
                    : ["far", "chevron-right"],
                },
              }),
              _c("b-popover", {
                attrs: {
                  target: "COLLAPSE_EXPAND_" + _vm.name.replace(/\W/g, "_"),
                  placement: "top",
                  triggers: "hover",
                  content: _vm.keepopen
                    ? _vm.$t("button.collapse_list")
                    : _vm.$t("button.expand_list"),
                },
              }),
              _c("span", [_vm._v(_vm._s(_vm.name))]),
              _c(
                "span",
                { staticClass: "action-container-class" },
                _vm._l(_vm.actions, function (action, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "action-class" },
                    [
                      action.emitevent
                        ? _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleActionClick(action)
                                },
                              },
                            },
                            [
                              _vm.hasPermission(action.permission)
                                ? _c("font-awesome-icon", {
                                    staticClass: "text-white",
                                    attrs: {
                                      id: "ACTION_" + action.id,
                                      icon: action.icon,
                                    },
                                  })
                                : _vm._e(),
                              _c("b-popover", {
                                attrs: {
                                  target: "ACTION_" + action.id,
                                  placement: "top",
                                  triggers: "hover",
                                  content: _vm.$t(action.name),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.hasPermission(action.permission)
                        ? _c(
                            "b-dropdown",
                            {
                              ref: "menu",
                              refInFor: true,
                              staticClass: "action-menu",
                              attrs: { variant: "link", "no-caret": "" },
                            },
                            [
                              _c("template", { slot: "button-content" }, [
                                _c(
                                  "div",
                                  { staticClass: "text" },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: action.icon },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._l(action.items, function (item, itemindex) {
                                return _c(
                                  "b-dropdown-item",
                                  {
                                    key: itemindex,
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleActionClick(item)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t(item.name)))]
                                )
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm.showSubmenus()
            ? _c(
                "ul",
                {
                  staticClass:
                    "nav-dropdown-items nav-dropdown-items-togglelist",
                },
                [_vm._t("default", null, { id: _vm.currentId })],
                2
              )
            : _vm._e(),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }