const scheduleMode = function(parentComponent) {
  const mode = [
    { value: 'ASAP', text: 'Project Start Date' },
    { value: 'ALAP', text: 'Project Finish Date' },
  ]
  if(parentComponent != null) {
    for(let m of mode) {
      m.text = parentComponent.$t(`scheduleMode.${m.value}`);
    }
  }
  return mode;
}

export default scheduleMode;