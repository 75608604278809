<template>
  <span>{{ value }}</span>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'BooleanCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  created() {
    if (!Object.hasOwn(this.params,'value') || this.params.value == null) {
      this.value = '';
    } else {
      this.value = this.params.value == true? this.$t('boolean_true') : this.$t('boolean_false');
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
  },
  methods: {
  }
});
</script>