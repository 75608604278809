import { randomString } from '@/helpers/random-string';

export const TaskViewRequestGenerator = {
  updateProjectAutoScheduling
  , invokeProjectSchedule
  , outdentTask
  , updateTag
  , removeTaskTemplate
  , applyTaskTemplate
  , updateNote
  , updateStage
  , updateSkill
  , updateRebate
  , updateResource
  , updateStaff
}

function updateProjectAutoScheduling(projectId, autoSchedulingState, { isTemplate=false } = {}) {
  const _isTemplate = isTemplate == true;
  return {
    method: 'PUT',
    invoke: `/api${_isTemplate? '/template' :'' }/project/update`,
    body: [{
      uuId: projectId,
      autoScheduling: autoSchedulingState
    }],
    vars: [],
    note: `${_isTemplate? 'templateProject' : 'project'}AutoSchedulingUpdate__${autoSchedulingState}__${projectId}`
  }
}

function invokeProjectSchedule(projectId, { isTemplate=false } = {}) {
  const _isTemplate = isTemplate == true;
  return {
    method: 'POST',
    invoke: `/api${_isTemplate? '/template' :'' }/project/schedule`,
    body: [{
      uuId: projectId
    }],
    vars: [],
    note: `${_isTemplate? 'templateProject' : 'project'}Schedule__${projectId}`
  }
}

function outdentTask(projectId, taskList, { isTemplate=false } = {}) {
  const _isTemplate = isTemplate == true;
  const updateTaskReqTemplate = function(refId, task) {
    return {
      method: 'PUT',
      invoke: `/api${_isTemplate? '/template' : '' }/task/update?holder=${projectId}&order-at=${task.orderAt}&order-as=${task.orderAs}`,
      body: [{ 
        uuId: task.uuId,
        parent: task.parent
      }],
      vars: [],
      note: `${_isTemplate? 'templateTask' : 'task' }UpdateParentNOrder__${refId}`
    }
  }

  const requests = [];
  for (const [index, t] of taskList.entries())  {
    requests.push(updateTaskReqTemplate(`${index}_${t.parent}__${t.uuId}`, t));
  }
  return requests;
}

function updateTag(taskId, oldTagList, updatedTagList, { isTemplate=false } = {}) {
  const _isTemplate = isTemplate == true;
  const originTagList = oldTagList != null && Array.isArray(oldTagList)? oldTagList.map(i => { return { uuId: i.uuId, name: i.name } }) : [];
  const tagList = updatedTagList != null && Array.isArray(updatedTagList)? updatedTagList.map(i => { return { uuId: i.uuId, name: i.name } }) : [];
  const toAdd = [];
  const toUpdate = [];
  const unchangedIds = [];
  for(const tag of tagList) {
    const index = originTagList.findIndex(j => j.uuId === tag.uuId);
    if(index == -1) {
      toAdd.push(tag);
    } else {
      unchangedIds.push(tag.uuId);
    }
  }

  const toAddIds = toAdd.map(i => i.uuId);
  const toUpdateIds = toUpdate.map(i => i.uuId);
  const toRemove = originTagList.filter(i => !toAddIds.includes(i.uuId) && !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId));
  const requests = [];

  if(toAdd.length > 0) {
    const addTagReqTemplate = function(refId, tagName) {
      const list = [];
      tagList.forEach(i => {
        list.push( {
          uuId: i.uuId
        });
      });
      return {
        method: 'POST',
        invoke: '/api/tag/add',
        body: [{ 
         name: tagName
        }],
        vars: [{ 
          name: refId,
          path: '$.feedbackList.uuId'
        }],
        note: `addTag__${refId}`
      }
    }
    const addTagLinkReqTemplate = function(refId, taskId, tagList) {
      const list = [];
      tagList.forEach(i => {
        list.push( {
          uuId: i.uuId
          , name: i.name
        });
      });
      return {
        method: 'POST',
        invoke: `/api${_isTemplate? '/template' : ''}/task/link/tag/add`,
        body: { 
          uuId: taskId,
          tagList: list
        },
        vars: [],
        note: `${_isTemplate? 'templateTask' : 'task'}AddTagLink__${refId}`
      }
    }
    
    for (const [index, tag] of toAdd.entries()) {
      let refId = randomString(8);
      if (tag.uuId == null) {
        refId = `tagUuId_${refId}`;
        tag.uuId = `@{${refId}}`;
        requests.push(addTagReqTemplate(refId, tag.name))
      }
      requests.push(addTagLinkReqTemplate(`${taskId}_${refId}`, taskId, [tag]));
    }
  }

  if(toRemove.length > 0) {
    const removeTagLinkReqTemplate = function(refId, taskId, tagList) {
      const list = [];
      tagList.forEach(i => {
        list.push( {
          uuId: i.uuId
        });
      });
      return {
        method: 'POST',
        invoke: `/api${_isTemplate? '/template' : ''}/task/link/tag/delete`,
        body: { 
          uuId: taskId,
          tagList: list
        },
        vars: [],
        note: `${_isTemplate? 'templateTask' : 'task'}RemoveTagLink__${refId}`
      }
    }
    for (const [index, tag] of toRemove.entries()) {
      requests.push(removeTagLinkReqTemplate(`${index}_${taskId}`, taskId, [tag]));
    }
  }

  return requests;
}

function removeTaskTemplate(targetId, templateIds) {
  const removeTaskTemplateReqTemplate = function(refId, templateId) {
    return {
      method: 'POST',
      invoke: `/api/task/task_template/delete`,
      body: { 
        uuId: targetId,
        templateList: [{ uuId: templateId }]
      },
      vars: [],
      note: `taskRemoveTaskTemplate__${refId}`
    }
  }

  const requests = [];
  for (const [index, templateId] of templateIds.entries()) {
    requests.push(removeTaskTemplateReqTemplate(`${index}_${targetId}__${templateId}`, templateId));
  }
  return requests;
}

function applyTaskTemplate(targetId, templateIds, { override=false, group=false } = {}) {
  const addTaskTemplateReqTemplate = function(refId, templateId, over, grp) {
    return {
      method: 'POST',
      invoke: `/api/task/task_template/add?override=${over}&group=${grp}`,
      body: { 
        uuId: targetId,
        templateList: [{ uuId: templateId }]
      },
      vars: [],
      note: `taskApplyTaskTemplate__${refId}`
    }
  }
  
  const requests = [];
  let _override = override != null? override : false;
  let _group = group != null? group : false;
  for (const [index, templateId] of templateIds.entries())  {
    if (_override && index > 0) {
      _override = false; 
    }
    requests.push(addTaskTemplateReqTemplate(`${index}_${targetId}__${templateId}`, templateId, _override, _group));
  }
  return requests;
}

function updateNote(taskId, oldNoteList, updatedNoteList, { customFields=[] }={}) {
  const toAddList = [];
  const toUpdateList = [];

  const orgNotes = oldNoteList;
  const notes = updatedNoteList;
  const unchangedIds = [];
  for(let i = 0, len = notes.length; i < len; i++) {
    //Keep only the needed properties.
    const note = { text: notes[i].text };
    if (notes[i].identifier != null) {
      note.identifier = notes[i].identifier;
    }
    for (const f of customFields) {
      if (Object.hasOwn(notes[i], f.name)) {
        note[f.name] = notes[i][f.name];
      }
    }
    if (notes[i].uuId != null) {
      note.uuId = notes[i].uuId;
    }
    
    const index = orgNotes.findIndex(j => j.uuId === note.uuId);
    if(index == -1) {
      toAddList.push(note);
    } else {
      const originNote = orgNotes[index];
      let hasChanged = originNote.text !== note.text || originNote.identifier != note.identifier;
      if (!hasChanged) {
        for (const f of customFields) {
          if (Object.hasOwn(originNote, f.name) && Object.hasOwn(note, f.name)) {
            if (originNote[f.name] == null && note[f.name] == null) {
              continue;
            } else if ((originNote[f.name] != null && note[f.name] == null) || (originNote[f.name] == null && note[f.name] != null)) {
              hasChanged = true;
              break;
            } else if (!f.type.startsWith('Enum') &&  originNote[f.name] != note[f.name]) {
              hasChanged = true;
              break;
            } else if (f.type.startsWith('Enum')) {
              if (originNote[f.name].length != note[f.name].length) {
                hasChanged = true;
                break;
              }
              for (const v of originNote[f.name]) {
                if (!note[f.name].includes(v)) {
                  hasChanged = true;
                  break;
                }
              }
              if (hasChanged) {
                break;
              }
            }
          } else if (Object.hasOwn(originNote, f.name) || Object.hasOwn(note, f.name)) {
            hasChanged = true;
            break;
          }
        }
      }
      
      if(hasChanged) {
        toUpdateList.push(note);
      } else {
        unchangedIds.push(note.uuId);
      }
    }

  }

  const toUpdateIds = toUpdateList.map(i => i.uuId);
  const toRemoveList = orgNotes.filter(i => !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId));

  const requests = [];
  if(toAddList.length > 0) {
    toAddList.reverse(); //Reverse list so that the new notes are created in right order
    const addNoteReqTemplate = function(refId, taskId, note) {
      return {
        method: 'POST',
        invoke: `/api/note/add?holder=${taskId}`,
        // body: [{ 
        //   text: note.text,
        //   identifier: note.identifier,
        //   // replyRef: note.replyRef         
        // }],
        body: [note],
        vars: [],
        note: `taskAddNote__${refId}`
      }
    }
    for (const [index, note] of toAddList.entries()) {
      requests.push(addNoteReqTemplate(`${index}_${taskId}`, taskId, note));
    }
  }

  //To update existing notes if there is any.
  if(toUpdateList.length > 0) {
    const updateNoteReqTemplate = function(refId, note) {
      return {
        method: 'PUT',
        invoke: `/api/note/update`,
        // body: [{ 
        //   uuId: note.uuId,
        //   text: note.text,
        //   identifier: note.identifier,
        //   // replyRef: note.replyRef         
        // }],
        body: [note],
        vars: [],
        note: `taskUpdateNote__${refId}`
      }
    }
    for (const [index, note] of toUpdateList.entries()) {
      requests.push(updateNoteReqTemplate(`${index}_${taskId}__${note.uuId}`, note));
    }
  }

  //Try remove notes which user wants to remove if there is any
  if(toRemoveList.length > 0) {
    const removeNoteReqTemplate = function(refId, note) {
      return {
        method: 'POST',
        invoke: `/api/note/delete`,
        body: [{ 
          uuId: note.uuId
        }],
        vars: [],
        note: `taskRemoveNote__${refId}`
      }
    }
    for (const [index, note] of toRemoveList.entries()) {
      requests.push(removeNoteReqTemplate(`${index}_${taskId}__${note.uuId}`, note));
    }
  }

  return requests;
}

function updateStage(taskId, oldStage, updatedStage) {
  const originStageId = oldStage != null && oldStage.uuId != null? oldStage.uuId : null;
  const stageId = updatedStage != null && updatedStage.uuId != null? updatedStage.uuId : null;
  const toAdd = [];
  const toRemove = [];
  if (originStageId == stageId) {
    return [];
  }
  if (originStageId != null) {
    toRemove.push({ uuId: originStageId });
  }
  if (stageId != null) {
    toAdd.push({ uuId: stageId });
  }
  
  const requests = [];

  if(toRemove.length > 0) {
    const removeStageLinkReqTemplate = function(refId, taskId, stage) {
      return {
        method: 'POST',
        invoke: '/api/task/link/stage/delete',
        body: { 
          uuId: taskId,
          stage: {
            uuId: stage.uuId
          }
        },
        vars: [],
        note: `taskRemoveStageLink__${refId}__${stage.uuId}`
      }
    }
    for (const [index, stage] of toRemove.entries()) {
      requests.push(removeStageLinkReqTemplate(`${index}_${taskId}`, taskId, stage));
    }
  }

  if(toAdd.length > 0) {
    const addStageLinkReqTemplate = function(refId, taskId, stage) {
      return {
        method: 'POST',
        invoke: '/api/task/link/stage/add',
        body: { 
          uuId: taskId,
          stage: {
            uuId: stage.uuId
          }
        },
        vars: [],
        note: `taskAddStageLink__${refId}__${stage.uuId}`
      }
    }
    for (const [index, stage] of toAdd.entries()) {
      requests.push(addStageLinkReqTemplate(`${index}_${taskId}`, taskId, stage));
    }
  }

  return requests;
}

function updateSkill(taskId, oldSkillList, updatedSkillList, { isTemplate=false, customFields=[] } = {}) {
  const _isTemplate = isTemplate == true;
  const originSkillList = oldSkillList != null && Array.isArray(oldSkillList)
    ? oldSkillList.map(i => { 
      const s =  { uuId: i.uuId, name: i.name, level: i.level } 
      for (const f of customFields) {
        if (Object.hasOwn(i, f.name)) {
          s[f.name] = i[f.name];
        }
      }
      return s
    }) 
    : [];
  const skillList = updatedSkillList != null && Array.isArray(updatedSkillList)
    ? updatedSkillList.map(i => { 
      const s =  { uuId: i.uuId, name: i.name, level: i.level } 
      for (const f of customFields) {
        if (Object.hasOwn(i, f.name)) {
          s[f.name] = i[f.name];
        }
      }
      return s
    }) 
    : [];
  const toAdd = [];
  const toUpdate = [];
  const unchangedIds = [];
  for(const skill of skillList) {
    const index = originSkillList.findIndex(j => j.uuId === skill.uuId);
    if(index == -1) {
      toAdd.push(skill);
    } else {
      const originSkill = originSkillList[index];
      let hasChanged = originSkill.level !== skill.level;
      if (!hasChanged) {
        for (const f of customFields) {
          if (Object.hasOwn(originSkill, f.name) && Object.hasOwn(skill, f.name)) {
            if (originSkill[f.name] == null && skill[f.name] == null) {
              continue;
            } else if ((originSkill[f.name] != null && skill[f.name] == null) || (originSkill[f.name] == null && skill[f.name] != null)) {
              hasChanged = true;
              break;
            } else if (!f.type.startsWith('Enum') &&  originSkill[f.name] != skill[f.name]) {
              hasChanged = true;
              break;
            } else if (f.type.startsWith('Enum')) {
              if (originSkill[f.name].length != skill[f.name].length) {
                hasChanged = true;
                break;
              }
              for (const v of originSkill[f.name]) {
                if (!skill[f.name].includes(v)) {
                  hasChanged = true;
                  break;
                }
              }
              if (hasChanged) {
                break;
              }
            }
          } else if (Object.hasOwn(originSkill, f.name) || Object.hasOwn(skill, f.name)) {
            hasChanged = true;
            break;
          }
        }
      }
      if(hasChanged) {
        toUpdate.push(skill);
      } else {
        unchangedIds.push(skill.uuId);
      }
    }
  }

  const toAddIds = toAdd.map(i => i.uuId);
  const toUpdateIds = toUpdate.map(i => i.uuId);
  const toRemove = originSkillList.filter(i => !toAddIds.includes(i.uuId) && !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId));
  const requests = [];

  if(toAdd.length > 0) {
    const addSkillLinkReqTemplate = function(refId, taskId, skillList) {
      const list = [];
      skillList.forEach(i => {
        const skillLink = {
          level: i.level
        }
        for (const f of customFields) {
          if (Object.hasOwn(i, f.name)) {
            skillLink[f.name] = i[f.name]
          }
        }
        list.push( {
          uuId: i.uuId,
          skillLink
        });
      });
      return {
        method: 'POST',
        invoke: `/api${_isTemplate? '/template' : '' }/task/link/skill/add`,
        body: { 
          uuId: taskId,
          skillList: list
        },
        vars: [],
        note: `${_isTemplate? 'templateTask' : 'task' }AddSkillLink__${refId}__${skillList[0].uuId}`
      }
    }
    for (const [index, skill] of toAdd.entries()) {
      requests.push(addSkillLinkReqTemplate(`${index}_${taskId}`, taskId, [skill]));
    }
  }

  if(toUpdate.length > 0) {
    const updateSkillLinkReqTemplate = function(refId, taskId, skillList) {
      const list = [];
      skillList.forEach(i => {
        const skillLink = {
          level: i.level
        }
        for (const f of customFields) {
          if (Object.hasOwn(i, f.name)) {
            skillLink[f.name] = i[f.name]
          }
        }
        list.push( {
          uuId: i.uuId,
          skillLink
        });
      });
      return {
        method: 'POST',
        invoke: `/api${_isTemplate? '/template' : '' }/task/link/skill/update`,
        body: { 
          uuId: taskId,
          skillList: list
        },
        vars: [],
        note: `${_isTemplate? 'templateTask' : 'task'}UpdateSkillLink__${refId}__${skillList[0].uuId}`
      }
    }
    for (const [index, skill] of toUpdate.entries()) {
      requests.push(updateSkillLinkReqTemplate(`${index}_${taskId}`, taskId, [skill]));
    }
  }

  if(toRemove.length > 0) {
    const removeSkillLinkReqTemplate = function(refId, taskId, skillList) {
      const list = [];
      skillList.forEach(i => {
        list.push( {
          uuId: i.uuId
        });
      });
      return {
        method: 'POST',
        invoke: `/api${_isTemplate? '/template' : '' }/task/link/skill/delete`,
        body: { 
          uuId: taskId,
          skillList: list
        },
        vars: [],
        note: `${_isTemplate? 'templateTask' : 'task'}RemoveSkillLink__${refId}__${skillList[0].uuId}`
      }
    }
    for (const [index, skill] of toRemove.entries()) {
      requests.push(removeSkillLinkReqTemplate(`${index}_${taskId}`, taskId, [skill]));
    }
  }

  return requests;
}

function updateRebate(taskId, oldRebateList, updatedRebateList, { isTemplate=false } = {}) {
  const _isTemplate = isTemplate == true;
  const originRebateList = oldRebateList != null && Array.isArray(oldRebateList)? oldRebateList.map(i => { return { uuId: i.uuId, name: i.name } }) : [];
  const rebateList = updatedRebateList != null && Array.isArray(updatedRebateList)? updatedRebateList.map(i => { return { uuId: i.uuId, name: i.name } }) : [];
  const toAdd = [];
  const unchangedIds = [];
  for(const rebate of rebateList) {
    const index = originRebateList.findIndex(j => j.uuId === rebate.uuId);
    if(index == -1) {
      toAdd.push(rebate);
    } else {
      unchangedIds.push(rebate.uuId);
    }
  }

  const toAddIds = toAdd.map(i => i.uuId);
  const toRemove = originRebateList.filter(i => !toAddIds.includes(i.uuId) && !unchangedIds.includes(i.uuId));
  const requests = [];

  if(toAdd.length > 0) {
    const addRebateLinkReqTemplate = function(refId, taskId, rebateList) {
      const list = [];
      rebateList.forEach(i => {
        list.push( {
          uuId: i.uuId
        });
      });
      return {
        method: 'POST',
        invoke: `/api${_isTemplate? '/template' : '' }/task/link/rebate/add`,
        body: { 
          uuId: taskId,
          rebateList: list
        },
        vars: [],
        note: `${_isTemplate? 'templateTask' : 'task' }AddRebateLink__${refId}__${rebateList[0].uuId}`
      }
    }
    for (const [index, rebate] of toAdd.entries()) {
      requests.push(addRebateLinkReqTemplate(`${index}_${taskId}`, taskId, [rebate]));
    }
  }

  if(toRemove.length > 0) {
    const removeRebateLinkReqTemplate = function(refId, taskId, rebateList) {
      const list = [];
      rebateList.forEach(i => {
        list.push( {
          uuId: i.uuId
        });
      });
      return {
        method: 'POST',
        invoke: `/api${_isTemplate? '/template' : '' }/task/link/rebate/delete`,
        body: { 
          uuId: taskId,
          rebateList: list
        },
        vars: [],
        note: `${_isTemplate? 'templateTask' : 'task' }RemoveRebateLink__${refId}__${rebateList[0].uuId}`
      }
    }
    for (const [index, rebate] of toRemove.entries()) {
      requests.push(removeRebateLinkReqTemplate(`${index}_${taskId}`, taskId, [rebate]));
    }
  }

  return requests;
}

function updateResource(taskId, oldResourceList, updatedResourceList, { isTemplate=false, customFields=[] } = {}) {
  const _isTemplate = isTemplate == true;
  const originResourceList = oldResourceList != null && Array.isArray(oldResourceList)
    ? oldResourceList.map(i => {
        const r = { 
          uuId: i.uuId, 
          name: i.name, 
          utilization: i.utilization, 
          quantity: Object.hasOwn(i, 'unit') ? i.unit : i.quantity 
        }
        for (const f of customFields) {
          if (Object.hasOwn(i, f.name)) {
            r[f.name] = i[f.name];
          }
        }
        return r;
      }) 
    : [];
  const resourceList = updatedResourceList != null && Array.isArray(updatedResourceList)
    ? updatedResourceList.map(i => {
        const r = { 
          uuId: i.uuId, 
          name: i.name, 
          utilization: i.utilization, 
          quantity: Object.hasOwn(i, 'unit') ? i.unit : i.quantity 
        }
        for (const f of customFields) {
          if (Object.hasOwn(i, f.name)) {
            r[f.name] = i[f.name];
          }
        }
        return r;
      }) 
    : [];
  const toAdd = [];
  const toUpdate = [];
  const unchangedIds = [];
  for(const resource of resourceList) {
    const index = originResourceList.findIndex(j => j.uuId === resource.uuId);
    if(index == -1) {
      toAdd.push(resource);
    } else {
      const originResource = originResourceList[index];
      let hasChanged = originResource.quantity != resource.quantity || originResource.utilization != resource.utilization;
      if (!hasChanged) {
        for (const f of customFields) {
          if (Object.hasOwn(originResource, f.name) && Object.hasOwn(resource, f.name)) {
            if (originResource[f.name] == null && resource[f.name] == null) {
              continue;
            } else if ((originResource[f.name] != null && resource[f.name] == null) || (originResource[f.name] == null && resource[f.name] != null)) {
              hasChanged = true;
              break;
            } else if (!f.type.startsWith('Enum') &&  originResource[f.name] != resource[f.name]) {
              hasChanged = true;
              break;
            } else if (f.type.startsWith('Enum')) {
              if (originResource[f.name].length != resource[f.name].length) {
                hasChanged = true;
                break;
              }
              for (const v of originResource[f.name]) {
                if (!resource[f.name].includes(v)) {
                  hasChanged = true;
                  break;
                }
              }
              if (hasChanged) {
                break;
              }
            }
          } else if (Object.hasOwn(originResource, f.name) || Object.hasOwn(resource, f.name)) {
            hasChanged = true;
            break;
          }
        }
      }
      if(hasChanged) {
        toUpdate.push(resource);
      } else {
        unchangedIds.push(resource.uuId);
      }
    }
  }

  const toAddIds = toAdd.map(i => i.uuId);
  const toUpdateIds = toUpdate.map(i => i.uuId);
  const toRemove = originResourceList.filter(i => !toAddIds.includes(i.uuId) && !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId));
  const requests = [];

  if(toAdd.length > 0) {
    const addResourceLinkReqTemplate = function(refId, taskId, resourceList) {
      const list = [];
      resourceList.forEach(i => {
        const resourceLink = {
          utilization: i.utilization, 
          quantity: i.quantity
        }
        for (const f of customFields) {
          if (Object.hasOwn(i, f.name)) {
            resourceLink[f.name] = i[f.name];
          }
        }
        list.push( {
          uuId: i.uuId, 
          resourceLink 
        });
      });
      return {
        method: 'POST',
        invoke: `/api${_isTemplate? '/template' : '' }/task/link/resource/add`,
        body: { 
          uuId: taskId,
          resourceList: list
        },
        vars: [],
        note: `${_isTemplate? 'templateTask' : 'task' }AddResourceLink__${refId}__${resourceList[0].uuId}`
      }
    }
    for (const [index, resource] of toAdd.entries()) {
      requests.push(addResourceLinkReqTemplate(`${index}_${taskId}`, taskId, [resource]));
    }
  }

  if(toUpdate.length > 0) {
    const updateResourceLinkReqTemplate = function(refId, taskId, resourceList) {
      const list = [];
      resourceList.forEach(i => {
        const resourceLink = {
          utilization: i.utilization, 
          quantity: i.quantity
        }
        for (const f of customFields) {
          if (Object.hasOwn(i, f.name)) {
            resourceLink[f.name] = i[f.name];
          }
        }
        list.push( {
          uuId: i.uuId, 
          resourceLink
        });
      });
      return {
        method: 'POST',
        invoke: `/api${_isTemplate? '/template' : '' }/task/link/resource/update`,
        body: { 
          uuId: taskId,
          resourceList: list
        },
        vars: [],
        note: `${_isTemplate? 'templateTask' : 'task' }UpdateResourceLink__${refId}__${resourceList[0].uuId}`
      }
    }
    for (const [index, resource] of toUpdate.entries()) {
      requests.push(updateResourceLinkReqTemplate(`${index}_${taskId}`, taskId, [resource]));
    }
  }

  if(toRemove.length > 0) {
    const removeResourceLinkReqTemplate = function(refId, taskId, resourceList) {
      const list = [];
      resourceList.forEach(i => {
        list.push( {
          uuId: i.uuId
        });
      });
      return {
        method: 'POST',
        invoke: `/api${_isTemplate? '/template' : '' }/task/link/resource/delete`,
        body: { 
          uuId: taskId,
          resourceList: list
        },
        vars: [],
        note: `${_isTemplate? 'templateTask' : 'task' }RemoveResourceLink__${refId}__${resourceList[0].uuId}`
      }
    }
    for (const [index, resource] of toRemove.entries()) {
      requests.push(removeResourceLinkReqTemplate(`${index}_${taskId}`, taskId, [resource]));
    }
  }

  return requests;
}

function updateStaff(taskId, oldStaffList, updatedStaffList, { isTemplate=false } = {}) {
  const _isTemplate = isTemplate == true;
  const originStaffList = oldStaffList != null && Array.isArray(oldStaffList)? oldStaffList.map(i => { return { uuId: i.uuId, name: i.name, utilization: i.utilization, unit: i.unit, duration: i.duration, durationAUM: i.durationAUM } }) : [];
  const staffList = updatedStaffList != null && Array.isArray(updatedStaffList)? updatedStaffList.map(i => { return { uuId: i.uuId, name: i.name, utilization: i.utilization, unit: i.unit, duration: i.duration, durationAUM: i.durationAUM } }) : [];
  const toAdd = [];
  const toUpdate = [];
  const unchangedIds = [];
  // for(let i = 0, len = staffList.length; i < len; i++) {
  for(const staff of staffList) {
    // const staff = staffList[i];
    const index = originStaffList.findIndex(j => j.uuId === staff.uuId);
    if(index == -1) {
      toAdd.push(staff);
    } else {
      const originStaff = originStaffList[index];
      if(originStaff.duration != staff.duration || originStaff.utilization != staff.utilization || originStaff.unit != staff.unit) {
        toUpdate.push(staff);
      } else {
        unchangedIds.push(staff.uuId);
      }
    }
  }

  const toAddIds = toAdd.map(i => i.uuId);
  const toUpdateIds = toUpdate.map(i => i.uuId);
  const toRemove = originStaffList.filter(i => !toAddIds.includes(i.uuId) && !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId));
  const requests = [];

  if(toAdd.length > 0) {
    const addStaffLinkReqTemplate = function(refId, taskId, staffList) {
      const list = [];
      staffList.forEach(i => {
        list.push( {
          uuId: i.uuId, 
          resourceLink: {
            utilization: i.utilization, 
            quantity: i.unit,
            duration: i.duration, 
            durationAUM: i.durationAUM
          }
        });
      });
      return {
        method: 'POST',
        invoke: `/api${_isTemplate? '/template' : '' }/task/link/staff/add`,
        body: { 
          uuId: taskId,
          staffList: list
        },
        vars: [],
        note: `${_isTemplate? 'templateTask' : 'task' }AddStaffLink__${refId}__${staffList[0].uuId}`
      }
    }
    for (const [index, staff] of toAdd.entries()) {
      requests.push(addStaffLinkReqTemplate(`${index}_${taskId}`, taskId, [staff]));
    }
  }

  if(toUpdate.length > 0) {
    const updateStaffLinkReqTemplate = function(refId, taskId, staffList) {
      const list = [];
      staffList.forEach(i => {
        list.push( {
          uuId: i.uuId, 
          resourceLink: {
            utilization: i.utilization, 
            quantity: i.unit,
            duration: i.duration, 
            durationAUM: i.durationAUM
          }
        });
      });
      return {
        method: 'POST',
        invoke: `/api${_isTemplate? '/template' : '' }/task/link/staff/update`,
        body: { 
          uuId: taskId,
          staffList: list
        },
        vars: [],
        note: `${_isTemplate? 'templateTask' : 'task' }UpdateStaffLink__${refId}__${staffList[0].uuId}`
      }
    }
    for (const [index, staff] of toUpdate.entries()) {
      requests.push(updateStaffLinkReqTemplate(`${index}_${taskId}`, taskId, [staff]));
    }
  }

  if(toRemove.length > 0) {
    const removeStaffLinkReqTemplate = function(refId, taskId, staffList) {
      const list = [];
      staffList.forEach(i => {
        list.push( {
          uuId: i.uuId
        });
      });
      return {
        method: 'POST',
        invoke: `/api${_isTemplate? '/template' : '' }/task/link/staff/delete`,
        body: { 
          uuId: taskId,
          staffList: list
        },
        vars: [],
        note: `${_isTemplate? 'templateTask' : 'task' }RemoveStaffLink__${refId}__${staffList[0].uuId}`
      }
    }
    for (const [index, staff] of toRemove.entries()) {
      requests.push(removeStaffLinkReqTemplate(`${index}_${taskId}`, taskId, [staff]));
    }
  }

  return requests;
}
