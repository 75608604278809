<template>
  <BlankCenterContainer>
    <b-col md="5">
      <div class="standard-logo-wrapper mx-auto">
          <div id="logo" class="standard-logo" alt="Projectal Logo" border="0" name="logo"> </div>
      </div>
      <b-card class="mx-auto">
        <div>
            <b-alert dismissible fade
                :show="alertShow"
                :variant="alert.type"
                @dismissed="dismissAlert">
                
                <font-awesome-icon :icon="['far', 'circle-exclamation']" v-if="alert.type==='danger'" />
                <font-awesome-icon :icon="['far', 'check']" v-if="alert.type==='success'" />
                &nbsp;&nbsp;{{ alert.message }}
            </b-alert>
            <div class="sign-in-text">{{ $t('login.sign_in') }}</div>
            <div class="sso-group">
              <template v-for="(provider, key) in ssoProviders">
                <button :key="key" class="btn btn-secondary btn-block position-relative" @click="ssoLogin(provider.login)"><div class="sso-provider-icon" :style="{ backgroundImage: 'url(' + provider.icon + ')' }"></div>{{ provider.name }}</button>
              </template>
            </div>
        </div>
        <div class="mt-3" v-if="projectalLogin">            
          <label v-if="ssoProviders.length !== 0" class="text-n-line" :class="loginExpand ? null : 'collapsed'"
            :aria-expanded="loginExpand ? 'true' : 'false'"
            aria-controls="collapse-4"
            @click="loginExpand = !loginExpand">
            <span class="or-sign-in-text">{{ $t('login.or_login_with') }}
              <font-awesome-icon :icon="['far', 'chevron-up']" class="collapsable-icon" v-if="loginExpand"/>
              <font-awesome-icon :icon="['far', 'chevron-down']" class="collapsable-icon" v-else />
            </span>
            
          </label>
          
          <b-collapse v-model="loginExpand">
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text>
                    <font-awesome-icon :icon="['far', 'user']" />
                  </b-input-group-text>
                </b-input-group-prepend>
                <input type="email" class="last-child form-control" id="username" :placeholder="$t('login.placeholder_email')"
                  :class="{ 'is-invalid': showUsernameError }" 
                  :data-vv-as="$t('login.field.email_address')"
                  data-vv-name="username"
                  data-vv-delay="500"
                  v-model="username"
                  v-validate="{ required: validate }"
                  />
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showUsernameError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('username') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text>
                    <font-awesome-icon :icon="['far', 'lock']" />
                  </b-input-group-text>
                </b-input-group-prepend>
                <input type="password" class="last-child form-control" :class="{ 'is-invalid': showPasswordError }" id="password" :placeholder="$t('login.placeholder_password')"
                  :data-vv-as="$t('login.field.password')"
                  data-vv-name="password"
                  data-vv-delay="500"
                  v-model="password"
                  v-validate="{ required: validate }"
                  />
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showPasswordError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;&nbsp;&nbsp;{{ errors.first('password') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <button class="btn btn-primary btn-block" :disabled="isSubmitDisabled || submitting" @click.prevent="handleSubmit">{{ $t(submitting? 'button.logging_in': 'button.login') }}</button>
            <div class="text-center">
              <router-link to="/forgot/password" class="d-block small mt-3">{{ $t('link.forgot_password') }}</router-link>
            </div>
          </b-collapse>
          
        </div>
      </b-card>
      <div class="auth-footer mt-3 text-center">{{ `© ${(new Date()).getFullYear()} JanusKS Pty. Ltd.  All rights reserved. Version ${versionNumber}.` }}</div>

    </b-col>
  </BlankCenterContainer>
</template>

<script>
// Vuex
import { mapActions } from 'vuex';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { managementService } from '@/services';
import BlankCenterContainer from '@/views/layout/BlankCenterContainer';

export default {
  name: "Login",
  components: {
    BlankCenterContainer,
  },
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      username: '',
      password: '',
      nextUrl: null,
      alertShow: false,
      submitting: false,
      alert: {
        message: null,
        type: 'danger',
      },
      versionNumber: null,
      validate: false,
      projectalLogin: false,
      ssoProviders: [
        //Expected object format
        // {
        //   name: '',
        //   login: '',
        //   icon: ''
        // }
      ],
      loginExpand: false
    }
  },
  created () {
    this.fieldValidateUtil = fieldValidateUtil;
    this.validate = true;
    this.nextUrl = this.$route.query.RelayState;
    const errorCode = this.$route.query.ErrorCode;
    if (localStorage.loginExpand === 'true') {
      this.loginExpand = true;
    }
    
    if (errorCode != null) {
      if (errorCode === 'f') {
        this.alert.message = this.$t(`login.error.${401}`);
        this.alert.type = 'danger';
        this.alertShow = true;
      }
      else if (errorCode === 'l') {
        this.alert.message = this.$t(`login.error.license_limit_exceeded`);
        this.alert.type = 'danger';
        this.alertShow = true;
      }
      else if (errorCode === 'e') { 
        this.alert.message = this.$t(`login.error.invalid_license`);
        this.alert.type = 'danger';
        this.alertShow = true;
      }
      else if (errorCode === 'd') { 
        this.alert.message = this.$t(`login.error.user_disabled`);
        this.alert.type = 'danger';
        this.alertShow = true;
      }
    }
    
    // Get version number for footer
    const self = this;
    this.$store.dispatch('data/status').then(value => {
      self.versionNumber = value.version;
      document.title = `Projectal - ${value.companyName}`;
    })
    .catch(e => {
      this.versionNumber = "N/A";
      document.title = 'Projectal';
    });
    
    this.prepareSSOProviders();
  },
  beforeUnmount() {
    this.validate = false;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
  },
  watch: {
    alertMessage: function(newValue) {
      if(newValue) {
        this.alertShow = true;
        this.alert.message = this.alertMessage;
        this.alert.type = this.$store.state.alert.type;
        this.clearAlert();
        this.submitting = false;
      }
    },
    loginExpand: function(newValue) {
      localStorage.loginExpand = this.loginExpand;
    }
  },
  computed: {
    alertMessage() {
      return this.$store.state.alert.message;
    },
    isSubmitDisabled() {
      return !!this.errors.first('username') || !!this.errors.first('password');
    },
    showUsernameError() {
      return fieldValidateUtil.hasError(this.errors, 'username');
    },
    showPasswordError() {
      return fieldValidateUtil.hasError(this.errors, 'password');
    }
  },
  methods: {
    forceProjectalLogin() {
      const urlParams = new URLSearchParams(window.location.search);
      const showProjectalLogin = urlParams.get('showProjectalLogin');
      return showProjectalLogin;
    },
    async prepareSSOProviders() {
      let isError = false;
      this.ssoProviders = await managementService.ssoProviders().then((response) => { 
        const data = response.data[response.data.jobCase];
        return data;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        isError = true;
        return [];
      });
      if (isError) {
        this.loginExpand = true;
        this.projectalLogin = true;
      } else if (this.ssoProviders != null && this.ssoProviders.length > 0) {
        this.projectalLogin = this.ssoProviders.filter(i => i.type === 'projectal').length !== 0 || this.forceProjectalLogin();
        this.ssoProviders = this.ssoProviders.filter(i => i.type !== 'projectal');
        if (this.ssoProviders.length === 0) {
          this.loginExpand = true;
        }
        
        if (this.nextUrl != null) {
          for (let i = 0, len = this.ssoProviders.length; i < len; i++) {
            if (this.ssoProviders[i].login != null) {
              const index = this.ssoProviders[i].login.indexOf('?');
              if (index == -1) {
                this.ssoProviders[i].login = `${this.ssoProviders[i].login}?RelayState=${this.nextUrl}`;
              } else {
                this.ssoProviders[i].login = `${this.ssoProviders[i].login}&RelayState=${this.nextUrl}`;
              }
            }
          }
        }
      } else {
        this.loginExpand = true;
        this.projectalLogin = true;
      }
    },
    ssoLogin(actionURL) {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = actionURL;
      document.body.appendChild(form);
      form.submit();
    },
    handleSubmit() {
      this.submitting = true;
      this.alertShow = false;
      const { username, password, nextUrl } = this;
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.$store.dispatch('authentication/login', { username, password, nextUrl });
        } else {          
          this.submitting = false;
        }
      });
    },
    dismissAlert() {
      this.alertShow = false;
    },
    ...mapActions({
      clearAlert: 'alert/clear' 
    }),
  }
};
</script>
<style lang="scss" scoped>
.sso-container {
  margin-top: 20px;
}
.sso-only-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.horizontal-divider {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  & div { 
    flex: 0 0 auto;
    margin: 0 15px;
    font-size: 11px;
    
  }

  &:before, &:after {
    border-bottom: 1px solid var(--dropdown-divider);
    content: "";
    display: block;
    flex: 1 1 auto;
  }
}

.sso-group .btn:not(:first-child) {
  margin-top: 1rem;
}

.sso-provider-icon {
  position: absolute;
  background: no-repeat;
  background-size: 24px 24px;
  top: 4px;
  left: 6px;
  width: 24px;
  height: 24px;
}

.sso-group .btn:hover {
  border-color: var(--bs-secondary-border);
}

.sso-group .btn {
  border-color: var(--bs-secondary-border);
}

.sign-in-text {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.or-sign-in-text {
  font-size: 0.8rem;
}
</style>