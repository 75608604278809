var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-relative" },
    [
      _vm.canView("STORAGE_FILE") && _vm.showHeader
        ? _c(
            "div",
            { staticClass: "dashboard-banner" },
            [
              _c("AvatarBanner", {
                attrs: { baseAvatarIcon: ["fad", "user-tie"], readOnly: true },
                model: {
                  value: _vm.avatarBanner,
                  callback: function ($$v) {
                    _vm.avatarBanner = $$v
                  },
                  expression: "avatarBanner",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "dashboard-buttons" },
        [
          !_vm.disableLocker
            ? _c(
                "div",
                { staticClass: "dashboard-locker" },
                [
                  _c("span", { staticClass: "mr-1" }, [
                    _vm._v(_vm._s(_vm.$t("lock"))),
                  ]),
                  _c("b-form-checkbox", {
                    attrs: {
                      switch: "",
                      "data-vv-name": "lockWidgets",
                      "data-vv-delay": "500",
                    },
                    model: {
                      value: _vm.lockWidgets,
                      callback: function ($$v) {
                        _vm.lockWidgets = $$v
                      },
                      expression: "lockWidgets",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dashboard-settings",
              attrs: { id: "DASHBOARD_SHOW_SETTINGS" },
              on: {
                click: function ($event) {
                  return _vm.showDashboardSettings()
                },
              },
            },
            [_c("font-awesome-icon", { attrs: { icon: ["far", "gear"] } })],
            1
          ),
          _c(
            "b-popover",
            {
              attrs: {
                target: "DASHBOARD_SHOW_SETTINGS",
                triggers: "hover",
                placement: "top",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("dashboard.title")) + " ")]
          ),
          _c(
            "div",
            {
              staticClass: "view",
              on: _vm._d({ mouseleave: _vm.onViewLeave }, [
                _vm.viewMouseEnterEvent,
                _vm.onViewOver,
              ]),
            },
            [
              _c(
                "b-dropdown",
                {
                  ref: "view",
                  staticClass: "action-bar-dropdown view-btn",
                  attrs: {
                    id: `DASHBOARD_BTN_VIEW`,
                    "toggle-class": "text-decoration-none",
                    "no-caret": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "desktop"] },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "b-dropdown-item",
                    { attrs: { href: "#" }, on: { click: _vm.savePreset } },
                    [
                      _c("span", { staticClass: "action-item-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              _vm.contextKey === "home"
                                ? "dashboard.button.save"
                                : "dashboard.button.save_project"
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c("b-dropdown-divider"),
                  _vm._l(_vm.dashboardViews, function (item, index) {
                    return [
                      _c(
                        "b-dropdown-item",
                        {
                          key: index,
                          staticClass: "action-item",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.loadViewSettings(item)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "action-item-label-with-icon",
                              attrs: { id: `viewname${index}` },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _vm.isEllipsisActive(item.name)
                            ? _c("b-popover", {
                                attrs: {
                                  target: `viewname${index}`,
                                  placement: "top",
                                  boundary: "viewport",
                                  "custom-class": "popover-margin",
                                  triggers: "hover",
                                  offset: "-100",
                                  content: item.name,
                                },
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            [
                              !_vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "action-item-icon",
                                      attrs: { id: `BTN_COPY_${index}` },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.copyColumnSettings(
                                            index,
                                            item.name,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "copy"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.editPermission(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: `BTN_COPY_${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t(
                                        _vm.contextKey === "home"
                                          ? "dashboard.button.copy"
                                          : "dashboard.button.copy_project"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              !_vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-icon position-third",
                                      attrs: { id: `BTN_INFO_${index}` },
                                      on: _vm._d(
                                        {
                                          mouseleave: function ($event) {
                                            return _vm.onInfoLeave(index)
                                          },
                                        },
                                        [
                                          _vm.infoMouseEnterEvent,
                                          function ($event) {
                                            return _vm.onInfoOver(index)
                                          },
                                        ]
                                      ),
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "circle-info"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.editPermission(item)
                                ? _c("b-popover", {
                                    ref: `BTN_INFO_${index}`,
                                    refInFor: true,
                                    attrs: {
                                      target: `BTN_INFO_${index}`,
                                      show: _vm.showInfo[index],
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "manual",
                                      content: _vm.$t("owner", [
                                        _vm.dashboardViews[index].owner,
                                      ]),
                                    },
                                    on: {
                                      "update:show": function ($event) {
                                        return _vm.$set(
                                          _vm.showInfo,
                                          index,
                                          $event
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-icon position-third",
                                      attrs: { id: `BTN_SHARE_${index}` },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.shareColumnSettings(
                                            index,
                                            item.name,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: [
                                            item.defaultView ? "fas" : "far",
                                            "share-nodes",
                                          ],
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: `BTN_SHARE_${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t(
                                        _vm.contextKey === "home"
                                          ? "dashboard.button.share"
                                          : "dashboard.button.share_project"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-icon position-second",
                                      attrs: { id: `BTN_UPDATE_${index}` },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.updateColumnSettings(
                                            index,
                                            item.name,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "save"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: `BTN_UPDATE_${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t(
                                        _vm.contextKey === "home"
                                          ? "dashboard.button.update"
                                          : "dashboard.button.update_project"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "action-item-icon",
                                      attrs: { id: `BTN_DELETE_${index}` },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.removeColumnSettings(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "trash-alt"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: `BTN_DELETE_${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t(
                                        _vm.contextKey === "home"
                                          ? "dashboard.button.delete"
                                          : "dashboard.button.delete_project"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "grid-stack",
          attrs: { id: "grid-stack", "data-gs-animate": "yes" },
        },
        [
          _vm._l(_vm.layout, function (item) {
            return [
              _c(
                "div",
                {
                  key: item.uuId,
                  ref: item.uuId,
                  refInFor: true,
                  staticClass: "grid-stack-item",
                  style: { cursor: !_vm.lockWidgets ? " move " : "initial" },
                  attrs: {
                    "gs-x": item.x,
                    "gs-y": item.y,
                    "gs-w": item.w,
                    "gs-h": item.h,
                    "gs-min-w": item.minw,
                    "gs-min-h": item.minh,
                    "gs-max-h": item.maxh,
                    "gs-max-w": item.maxw,
                    "data-id": item.uuId,
                    i: item.i,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "grid-stack-item-content" },
                    [
                      _vm.debug
                        ? _c("div", { staticClass: "debug-coords" }, [
                            _vm._v(
                              " x" +
                                _vm._s(item.x) +
                                " y" +
                                _vm._s(item.y) +
                                " w" +
                                _vm._s(item.w) +
                                " h" +
                                _vm._s(item.h)
                            ),
                          ])
                        : _vm._e(),
                      !_vm.lockWidgets
                        ? [_c("div", { staticClass: "movable" })]
                        : _vm._e(),
                      item.type === "launchpad"
                        ? _c("LaunchpadWidget", {
                            attrs: { widget: item, project: _vm.project },
                            on: { launchpadUpdated: _vm.launchpadUpdated },
                          })
                        : _vm._e(),
                      item.type === "dataview"
                        ? _c("DataviewWidget", {
                            attrs: { widget: item, project: _vm.project },
                            on: { dataviewUpdated: _vm.dataviewUpdated },
                          })
                        : _vm._e(),
                      item.type === "gauge"
                        ? _c("ProjectProgressWidget", {
                            attrs: {
                              projects: _vm.projects,
                              uuId: item.i,
                              isHome: true,
                            },
                          })
                        : _vm._e(),
                      item.type === "mytasks"
                        ? _c("HomeMyTasksWidget", {
                            attrs: { profile: item, userEmail: _vm.userEmail },
                            on: {
                              editTask: _vm.editTask,
                              saveWidget: _vm.saveWidget,
                            },
                          })
                        : _vm._e(),
                      item.type === "staff"
                        ? _c("HomeStaffUtilizationWidget")
                        : _vm._e(),
                      item.type === "myschedule"
                        ? _c("HomeMyScheduleWidget", {
                            attrs: { userEmail: _vm.userEmail },
                          })
                        : _vm._e(),
                      item.type === "departmentstaff"
                        ? _c("HomeDepartmentStaffWidget", {
                            attrs: { profile: item },
                            on: { saveWidget: _vm.saveWidget },
                          })
                        : _vm._e(),
                      item.type === "activetasks"
                        ? _c("HomeActiveTasksWidget", {
                            attrs: { profile: item },
                            on: {
                              saveWidget: _vm.saveWidget,
                              editTask: _vm.editTask,
                            },
                          })
                        : _vm._e(),
                      item.type === "activeprojects"
                        ? _c("HomeActiveProjectsWidget", {
                            attrs: {
                              profile: item,
                              refresh: _vm.refreshProjects,
                            },
                            on: {
                              refreshed: _vm.refreshedProjects,
                              editProject: _vm.editProjectId,
                              saveWidget: _vm.saveWidget,
                            },
                          })
                        : _vm._e(),
                      item.type === "hskills"
                        ? _c("HomeStaffSkillsWidget", {
                            attrs: { profile: item },
                            on: { saveWidget: _vm.saveWidget },
                          })
                        : _vm._e(),
                      item.type === "timeline"
                        ? _c("HomeTimelineWidget", {
                            staticClass: "no-clipping",
                            attrs: {
                              refresh: _vm.refreshProjects,
                              profile: item,
                            },
                            on: {
                              refreshed: _vm.refreshedProjects,
                              editProject: _vm.editProjectId,
                              saveWidget: _vm.saveWidget,
                            },
                          })
                        : _vm._e(),
                      item.type === "info"
                        ? _c("ProjectInfoWidget", {
                            attrs: { project: _vm.project },
                            on: { editProject: _vm.editProject },
                          })
                        : _vm._e(),
                      item.type === "progress"
                        ? _c("ProjectProgressWidget", {
                            attrs: { project: _vm.project },
                          })
                        : _vm._e(),
                      item.type === "pstaff"
                        ? _c("ProjectStaffWidget", {
                            attrs: {
                              refresh: _vm.refreshStaff,
                              project: _vm.project,
                              profile: item,
                            },
                            on: {
                              saveWidget: _vm.saveWidget,
                              openStaff: _vm.onOpenStaff,
                              refreshed: _vm.refreshedStaff,
                            },
                          })
                        : _vm._e(),
                      item.type === "pbudget"
                        ? _c("ProjectBudgetActualWidget", {
                            attrs: { project: _vm.project, profile: item },
                            on: { saveWidget: _vm.saveWidget },
                          })
                        : _vm._e(),
                      item.type === "tasks"
                        ? _c("ProjectTasksWidget", {
                            attrs: {
                              profile: item,
                              project: _vm.project,
                              taskCount: _vm.taskCount,
                            },
                            on: { saveWidget: _vm.saveWidget },
                          })
                        : _vm._e(),
                      item.type === "kanban"
                        ? _c("ProjectKanbanBoardWidget", {
                            attrs: {
                              project: _vm.project,
                              tasks: _vm.tasks,
                              refresh: _vm.refresh,
                              taskCount: _vm.taskCount,
                            },
                            on: { refreshed: _vm.refreshed },
                          })
                        : _vm._e(),
                      item.type === "talerts"
                        ? _c("ProjectTaskAlertsWidget", {
                            attrs: {
                              project: _vm.project,
                              profile: item,
                              refresh: _vm.refresh,
                            },
                            on: {
                              saveWidget: _vm.saveWidget,
                              refreshed: _vm.refreshed,
                              editTask: _vm.editTask,
                            },
                          })
                        : _vm._e(),
                      item.type === "skills"
                        ? _c("ProjectSkillsWidget", {
                            attrs: { project: _vm.project, profile: item },
                            on: { saveWidget: _vm.saveWidget },
                          })
                        : _vm._e(),
                      item.type === "departments"
                        ? _c("ProjectDepartmentsWidget", {
                            attrs: { project: _vm.project, profile: item },
                            on: { saveWidget: _vm.saveWidget },
                          })
                        : _vm._e(),
                      item.type === "milestones"
                        ? _c("ProjectMilestonesWidget", {
                            attrs: { profile: item, project: _vm.project },
                            on: {
                              editTask: _vm.editTask,
                              saveWidget: _vm.saveWidget,
                            },
                          })
                        : _vm._e(),
                      item.type === "pactivetasks"
                        ? _c("ProjectActiveTasksWidget", {
                            attrs: {
                              project: _vm.project,
                              profile: item,
                              refresh: _vm.refresh,
                            },
                            on: {
                              refreshed: _vm.refreshed,
                              editTask: _vm.editTask,
                              saveWidget: _vm.saveWidget,
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c("DashboardWidgetSelectorModal", {
        attrs: {
          standardWidgets: _vm.allowedStandardWidgets,
          progressWidgets: _vm.allowedProgressWidgets,
          launchpadWidgets: _vm.allowedLaunchpadWidgets,
          dataviewWidgets: _vm.allowedDataviewWidgets,
          show: _vm.dashboardSettingsShow,
          title: _vm.settingsTitle,
          origin: _vm.originName,
          mode: _vm.contextKey,
          projects: _vm.projects,
        },
        on: {
          "update:show": function ($event) {
            _vm.dashboardSettingsShow = $event
          },
          launchpadAdded: _vm.launchpadAdded,
          launchpadUpdated: _vm.launchpadUpdated,
          launchpadRemoved: _vm.launchpadRemoved,
          launchpadDuplicated: _vm.launchpadDuplicated,
          dataviewAdded: _vm.dataviewAdded,
          dataviewUpdated: _vm.dataviewUpdated,
          dataviewRemoved: _vm.dataviewRemoved,
          toggleWidget: _vm.toggleWidget,
          restoreDefaults: _vm.restoreDefaults,
          cancel: _vm.onSettingsClosed,
        },
      }),
      _c("TaskModal", {
        attrs: {
          show: _vm.taskEditShow,
          id: _vm.taskEdit.uuId,
          projectId: _vm.taskEdit.projectId,
        },
        on: {
          "update:show": function ($event) {
            _vm.taskEditShow = $event
          },
          success: _vm.taskEditSuccess,
        },
      }),
      _c("StaffModal", {
        attrs: { show: _vm.staffEditShow, id: _vm.staffEdit.uuId },
        on: {
          "update:show": function ($event) {
            _vm.staffEditShow = $event
          },
          success: _vm.staffEditSuccess,
        },
      }),
      _c("ProjectModal", {
        attrs: {
          show: _vm.projectEditShow,
          id: _vm.projectEdit.uuId,
          title: _vm.projectTitle,
        },
        on: {
          "update:show": function ($event) {
            _vm.projectEditShow = $event
          },
          success: _vm.projectEditSuccess,
        },
      }),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptSaveShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.save"),
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptSaveShow = $event
          },
          ok: function ($event) {
            return _vm.confirmSaveOk(true, ...arguments)
          },
        },
      }),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptShareShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.share"),
          sharing: true,
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptShareShow = $event
          },
          ok: function ($event) {
            return _vm.confirmSaveOk(false, ...arguments)
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteViewOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteViewShow,
            callback: function ($$v) {
              _vm.confirmDeleteViewShow = $$v
            },
            expression: "confirmDeleteViewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("task.confirmation.delete_view")) + " "),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dashboard.error.sharing"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.memberAlertShow,
            callback: function ($$v) {
              _vm.memberAlertShow = $$v
            },
            expression: "memberAlertShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("dashboard.error.sharing_message", [
                    _vm.memberAlertName,
                  ])
                ) +
                " "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }