
import { httpAjax, urlParams } from '@/helpers';

export const contactService = {
  create,
  get,
  update,
  remove,
  
};

/**
 * Create a new contact 
 * by passing necessary information
 * @param FormData formData 
 * Contact: Possible properties for formData: 
*   1. lastName: String; Mandatory field.
 *  Etc...
 * @param holder uuId of parent e.g. Customer, File and Project.
 */
function create(formData, holder) {
  const url = '/api/contact/add';
  const config = {
    params: {
      holder: holder
    },
    headers: getHeaders()
  };
  return httpAjax.post(url, formData, config);
}

/**
 * Retrieving a list of contact details by 
 * passing in an array list of uuIds as arguments
 * @param uuId uuId of contact
 */
 function get(data, links=null) {
  const url = urlParams('/api/contact/get', links);
  const config = {
    
  };
  return httpAjax.post(url, data, config);
}

/**
 * update an  existing contact 
 * by passing necessary information
 * @param FormData formData 
 * Contact: Possible properties for formData: 
 *  0. uuId: target uuId; Mandatory field.
 *  1. lastName: String; Mandatory field.
 *  Etc..
 */
function update(data) {
  const url = '/api/contact/update';
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a contact
 * by passing in uuId
 * @param String uuId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function remove(data) {
  const url = `/api/contact/delete`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}