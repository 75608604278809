var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BlankCenterContainer",
    [
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [
          _c("div", { staticClass: "standard-logo-wrapper mx-auto" }, [
            _c("div", {
              staticClass: "standard-logo",
              attrs: {
                id: "logo",
                alt: "Projectal Logo",
                border: "0",
                name: "logo",
              },
            }),
          ]),
          _c(
            "b-card",
            { staticClass: "mx-auto" },
            [
              _c("div", { staticClass: "text-center mb-4" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("complete_registration.title"))),
                ]),
                _c(
                  "p",
                  [
                    _vm._v(
                      _vm._s(_vm.$t("complete_registration.title_detail")) + " "
                    ),
                    _c(
                      "b-link",
                      { attrs: { id: "LINK_PASS_INFO" } },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-info"] },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target: "LINK_PASS_INFO",
                          triggers: "click blur",
                          placement: "top",
                        },
                      },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.passwordInfo) },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm.CompleteRegistrationFormState.LOADING === _vm.formState
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [_c("b-spinner", { attrs: { label: "Spinning" } })],
                    1
                  )
                : _vm.CompleteRegistrationFormState.HAS_VALID_TOKEN ===
                  _vm.formState
                ? _c(
                    "b-form",
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "lock"] },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.password,
                                    expression: "password",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: { required: _vm.validate },
                                    expression: "{ required: validate }",
                                  },
                                ],
                                ref: "password",
                                staticClass: "last-child form-control",
                                class: { "is-invalid": _vm.showPasswordError },
                                attrs: {
                                  type: "password",
                                  id: "inputPassword",
                                  placeholder: "Enter Password",
                                  "data-vv-as": _vm.$t(
                                    "complete_registration.field.password"
                                  ),
                                  "data-vv-name": "password",
                                  "data-vv-delay": "500",
                                  state: _vm.fieldValidateUtil.stateValidate(
                                    false,
                                    _vm.veeFields,
                                    _vm.errors,
                                    "password"
                                  ),
                                },
                                domProps: { value: _vm.password },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.password = $event.target.value
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: { "d-block": _vm.showPasswordError },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " +
                                  _vm._s(_vm.errors.first("password")) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "lock"] },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.confirmPass,
                                    expression: "confirmPass",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      required: _vm.validate,
                                      confirmed: "password",
                                    },
                                    expression:
                                      "{ required: validate, confirmed: 'password' }",
                                  },
                                ],
                                staticClass: "last-child form-control",
                                class: {
                                  "is-invalid": _vm.showConfirmPasswordError,
                                },
                                attrs: {
                                  type: "password",
                                  id: "confirmPass",
                                  placeholder: "Confirm Password",
                                  "data-vv-as": _vm.$t(
                                    "complete_registration.field.password_confirm"
                                  ),
                                  "data-vv-name": "confirmPass",
                                  "data-vv-delay": "500",
                                },
                                domProps: { value: _vm.confirmPass },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.confirmPass = $event.target.value
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: {
                                "d-block": _vm.showConfirmPasswordError,
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " +
                                  _vm._s(_vm.errors.first("confirmPass")) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-btn",
                        {
                          staticClass: "btn-primary btn-block",
                          attrs: { disabled: _vm.isSubmitDisabled },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.changePassword.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.submit")))]
                      ),
                    ],
                    1
                  )
                : _vm.CompleteRegistrationFormState.HAS_INVALID_TOKEN ===
                  _vm.formState
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-danger text-center",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "triangle-exclamation"] },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "complete_registration.error.invalid_token"
                              )
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "d-block small mt-3",
                            attrs: { to: "/login" },
                          },
                          [_vm._v(_vm._s(_vm.$t("link.login_page")))]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm.CompleteRegistrationFormState.UPDATE_SUCCESS ===
                  _vm.formState
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-success text-center",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "check"] },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("complete_registration.success")) +
                            " "
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "d-block small mt-3",
                            attrs: { to: "/login" },
                          },
                          [_vm._v(_vm._s(_vm.$t("link.login_page")))]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm.CompleteRegistrationFormState.UPDATE_FAILURE ===
                  _vm.formState
                ? [
                    _vm.actionInProgress
                      ? _c(
                          "div",
                          { staticClass: "text-center" },
                          [_c("b-spinner", { attrs: { label: "Spinning" } })],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "alert alert-danger text-center",
                            attrs: { role: "alert" },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "triangle-exclamation"] },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("complete_registration.failure")
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-center mt-3",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "d-block small mr-3",
                              attrs: {
                                href: "#",
                                disabled: _vm.actionInProgress,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.changePassword.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("link.retry")))])]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "d-block small ml-3",
                              attrs: { to: "/login" },
                            },
                            [_vm._v(_vm._s(_vm.$t("link.login_page")))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                : _vm.CompleteRegistrationFormState.USER_LICENCE_LIMIT ===
                  _vm.formState
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-danger text-center",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "triangle-exclamation"] },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("user.error.user_limit_exceeded")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("div", { staticClass: "auth-footer mt-3 text-center" }, [
            _vm._v(
              _vm._s(
                `© ${new Date().getFullYear()} JanusKS Pty. Ltd. All rights reserved. Version ${
                  _vm.versionNumber
                }.`
              )
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }