var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "AppHeaderDropdown",
        { attrs: { right: "", "no-caret": "" } },
        [
          _c("template", { slot: "header" }, [
            _c("span", { staticClass: "d-none d-md-inline" }, [
              _vm._v(" " + _vm._s(_vm.fullName) + " "),
            ]),
            _c("img", {
              staticClass: "img-avatar",
              attrs: { src: _vm.avatarUrl },
              on: { error: _vm.avatarImageError },
            }),
          ]),
          _vm._v("\\ "),
          _c(
            "template",
            { slot: "dropdown" },
            [
              _vm.canView("USER")
                ? [
                    _c(
                      "b-dropdown-item",
                      { on: { click: _vm.profile } },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "user"] },
                        }),
                        _vm._v(_vm._s(_vm.$t("header_dropdown.profile"))),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "dropdown-item dropdown-checkbox",
                  on: { click: _vm.toggleTheme },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "moon-stars"] },
                  }),
                  _vm._v(_vm._s(_vm.$t("header_dropdown.dark_theme")) + " "),
                  _c("b-form-checkbox", {
                    staticClass: "d-inline-block ml-2",
                    attrs: {
                      readonly: "",
                      disabled: "",
                      switch: "",
                      id: "theme-toggler",
                    },
                    model: {
                      value: _vm.nightMode,
                      callback: function ($$v) {
                        _vm.nightMode = $$v
                      },
                      expression: "nightMode",
                    },
                  }),
                ],
                1
              ),
              _c("b-dropdown-divider"),
              _vm.hasPermission("ADMIN__ACCOUNT_MANAGEMENT")
                ? _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://accounts.projectal.com/",
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "users-gear"] },
                      }),
                      _vm._v(
                        _vm._s(_vm.$t("header_dropdown.account_management"))
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-dropdown-item",
                { on: { click: _vm.about } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-question"] },
                  }),
                  _vm._v(_vm._s(_vm.$t("header_dropdown.about"))),
                ],
                1
              ),
              _c(
                "b-dropdown-item",
                { on: { click: _vm.help } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-question"] },
                  }),
                  _vm._v(_vm._s(_vm.$t("header_dropdown.help"))),
                ],
                1
              ),
              _c(
                "b-dropdown-item",
                { on: { click: _vm.logout } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "right-from-bracket"] },
                  }),
                  _vm._v(_vm._s(_vm.$t("header_dropdown.logout"))),
                ],
                1
              ),
            ],
            2
          ),
        ],
        2
      ),
      _c("ProfileModal", {
        attrs: {
          id: _vm.userId,
          show: _vm.profileShow,
          title: _vm.profileTitle,
        },
        on: {
          "update:show": function ($event) {
            _vm.profileShow = $event
          },
        },
      }),
      _c("AboutModal", {
        attrs: { show: _vm.aboutShow, title: _vm.aboutTitle },
        on: {
          "update:show": function ($event) {
            _vm.aboutShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }