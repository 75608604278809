var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "edit-cell" },
    [
      _c("span", [_vm._v(_vm._s(_vm.label))]),
      _c("DateTimeModal", {
        attrs: {
          show: _vm.show,
          dateTime: _vm.value,
          labelDate: _vm.labelDate,
          labelTime: _vm.labelTime,
          editMode: _vm.editorMode,
          isOptional: _vm.optional,
        },
        on: {
          "update:show": function ($event) {
            _vm.show = $event
          },
          ok: _vm.ok,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }