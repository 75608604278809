var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "position-relative scheduler",
      style: _vm.width != null ? { width: _vm.width } : {},
    },
    [
      _vm.showNoRowsOverlay
        ? _c("div", { staticClass: "no-rows-overlay" }, [
            _c("span", { staticClass: "grid-overlay" }, [
              _vm._v(_vm._s(_vm.noRowsMessage)),
            ]),
          ])
        : _vm._e(),
      _vm.holdUpdateUntilTreeDataChanged
        ? _c("div", { staticClass: "loading-overlay" }, [
            _c("span", { staticClass: "grid-overlay" }, [
              _vm._v(_vm._s("loading...")),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dhx_cal_container scheduler-border-part",
          style: { width: "100%", height: "" + _vm.height },
          attrs: { id: "scheduler_here" },
          on: {
            click: _vm.onClickScheduler,
            mouseover: _vm.onMouseOver,
            mouseleave: _vm.onMouseLeave,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dhx_cal_navline",
              style: _vm.width != null ? { width: _vm.width } : {},
            },
            [_c("div", { staticClass: "dhx_cal_date" })]
          ),
          _c("div", { staticClass: "dhx_cal_header" }),
          _c("div", {
            staticClass: "dhx_cal_data",
            style: _vm.width != null ? { width: _vm.width } : {},
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }