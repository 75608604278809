<template>
  <div>
    <span>{{ label }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'StageCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    this.updateValue(this.params.value, this.getOptions(this.params));
  },
  computed: {
    label() {
      return this.value? this.value : '';
    }
  },
  methods: {
    updateValue(newValue, options) {
      if (newValue == null) {
        this.value = null;
        return;
      }
      const taskType = this.params.data.taskType;
      const found = options.filter(i => i.value === newValue.uuId);
      if(found.length > 0) {
        this.value = found[0].text;
      } else {
        this.value = null;
      }
    },
    refresh(params) {
      this.params = params;
      this.updateValue(params.value, this.getOptions(params));
    },
    getOptions(params) {
      let options = null;
      const projStagesMap = params.projStagesMap;
      if (projStagesMap != null && projStagesMap instanceof Map) {
        const projId = params.data.projId;
        if (projStagesMap.has(projId)) {
          options = projStagesMap.get(projId);
        }
      } else if (params.data != null && Array.isArray(params.data.stageOptions)) { //Used in PagedAgGridGantt
        options = params.data.stageOptions;
      } else {
        options = params.options;
      }
      return options || [];
    }
  }
})
</script>
