
import { httpAjax } from '@/helpers';

export const staffLinkCompanyService = {
  create,
  update,
  remove,
};

/**
 * Create a new Link from staff to company 
 * by passing necessary information
 * @param String staffId, Staff's UUID
 * @param Array companys, Company details. Data sample: [{ uuId, level }]
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', companyList: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', companyLink: {level: 'Junior'} }] }
 */
function create(staffId, company) {
  const url = '/api/company/link/staff/add';
  const config = {
    headers: getHeaders()
  };

  const staffList = [{uuId: staffId}];

  const data = {
    uuId: company,
    staffList: staffList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Update existing link details of staff to company.
 * by passing  necessary information
 * @param String staffId, Staff's UUID
 * @param Array companys, Company details. Data sample: [{ uuId, level }] 
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', companyList: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', companyLink: {level: 'Junior'} }] }
 */
function update(staffId, company) {
  const url = '/api/company/link/staff/update';
  const config = {
    headers: getHeaders()
  }

  const staffList = [{uuId: staffId}];

  const data = {
    uuId: company,
    staffList: staffList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from staff to company.
 * by passing in an array list of links uuIds
 * @param String staffId, Staff's UUID
 * @param Array companyIds, list of Company's UUID 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', companyList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }
 */
function remove(staffId, company) {
  const url = '/api/company/link/staff/delete';
  const config = {
    headers: getHeaders()
  };

  const staffList = [{uuId: staffId}];

  const data = {
    uuId: company,
    staffList: staffList
  }

  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}