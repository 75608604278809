import { skillService, skillLinkTagService } from '@/services'
import { addTags, objectClone } from '@/helpers'
import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly, lackOfMandatoryField } from './common'
import { prepareCustomFieldColumnDef } from '@/helpers/custom-fields'

export const skillUtil = {
  listFunc: (self) => {
    const skillLevelList = self.enumList != null && Array.isArray(self.enumList.SkillLevelEnum)? self.enumList.SkillLevelEnum.filter(i => i.num > -1) : []
    return skillService.listFunc(skillLevelList)
  }
  , listNames: skillService.listNames
  , remove: skillService.remove
  , clone: skillService.clone
  , importDataFunc: (self) => {
    const skillLevelPropNames = self.enumList.SkillLevelEnum.map(i => i.value)

    return async (item, errorFunc) => {
      let method = 'create';
    
      const data = {
        name: item.name
        , identifier: item.identifier
        , color: item.color
      }
      const skillLevels = []
      for (const prop of skillLevelPropNames) {
        let v = item[prop]
        if (v == null || isNaN(v)) {
          v = 5
        } else if (typeof v != 'number') {
          v = parseInt(v)
        }
        if (v < 1) {
          v = 1
        } else if (v > 365) {
          v = 365
        }
        skillLevels.push({ kind: prop, data: v })
      }
      data.skillLevels = skillLevels

      if (self.customFields) {
        for (const cfield of self.customFields) {
          if (item[cfield.name]) {
            data[cfield.name] = item[cfield.name];
          }
        }
      }
      
      if (item.uuId) {
        data.uuId = item.uuId;
        method = 'update';
      }
      
      const result = await skillService[method]([data])
      .then(response => {
        const feedbackList = response.data.feedbackList
        if (Array.isArray(feedbackList) && 
              feedbackList.length > 0 && 
              feedbackList[0].uuId != null) {
          return feedbackList[0].uuId
        }
      })
      .catch((e) => {
        errorFunc(e)
        return null
      })

      if (result && item.tag) {
        await addTags(result, item.tag.split(',').map(t => { return { name: t.trim() }}), skillLinkTagService).catch(() => {
          // fail silently
        });
      }
    }
  }
  , buildParams: ({ request: {sortModel, endRow, startRow} }, { exportData=false, searchFilter=null, badgeFilters=null }={}) => {
    const params = {
      start: !exportData ? startRow : 0
      , limit: !exportData ? endRow - startRow + 1 : -1
      , ksort: []
      , order: []
      , filter: searchFilter
      , badgeFilters
    }
    
    for(let i = 0, len = sortModel.length; i < len; i++) {
      params.ksort.push(sortModel[i].colId)
      params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr')
    }
    if (searchFilter == null) {
      delete params.filter
    }
    return params
  }

  , getColumnDefs: (self) => {
    const colDefs = [
      {
        headerName: self.$t('skill.field.name')
        , field: 'uuId'
        , cellRenderer: 'detailLinkCellRenderer'
        , cellEditor: 'nameEditor'
        , cellEditorParams: {
          isOptional: false
        }
        , checkboxSelection: false
        , pinned: 'left'
        , lockPosition: 'left'
        , lockVisible: true
        , minWidth: 200
        , hide: false
        , sort: 'asc'
        , editable: params => self.canEdit('SKILL', ['name']) && params.data.readOnly != true
        , valueSetter: function(params) {
          const newValue = params.newValue.trim()
          const oldValue = objectClone(params.data.name)
          if (newValue !== '' && newValue != oldValue) {
            self.$set(params.data, 'oldName', oldValue)
            params.data.name = newValue
            return true
          }
        }
      }
      , {
        headerName: self.$t('skill.field.headCount'),
        field: 'staffCount'
        , cellRenderer: 'genericCellRenderer'
        , hide: false
        , minWidth: 100
        , sortable: false
        , editable: false
      }
      , {
        headerName: self.$t('skill.field.genericHeadCount'),
        field: 'genericStaffCount'
        , cellRenderer: 'genericCellRenderer'
        , hide: false
        , minWidth: 100
        , sortable: false
        , editable: false
      }
    ]

    const skillLevelList = Array.isArray(self.enumList.SkillLevelEnum)? self.enumList.SkillLevelEnum : []
    for (const skillLevel of skillLevelList) {
      if (skillLevel.num < 0) {
        continue
      }
      colDefs.push({
        headerName: skillLevel.text
        , colId: skillLevel.value
        , field: 'levelGroup'
        , minWidth: 150
        , cellRenderer: 'skillLevelCellRenderer'
        , cellEditor: 'skillLevelEditor'
        , sortable: false
        , editable: params => params.data.readOnly != true
        , hide: true
      })
      colDefs.push({
        headerName: self.$t('skill.field.level_count', [skillLevel.value])
        , field: `${skillLevel.value}Count`
        , cellRenderer: 'genericCellRenderer'
        , minWidth: 150
        , sortable: false
        , editable: false
        , hide: true
      })
    }

    colDefs.push(...[
      {
        headerName: self.$t('field.tag')
        , field: 'tag'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'tagEditor'
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('field.color')
        , field: 'color'
        , cellRenderer: 'colorCellRenderer'
        , cellEditor: 'colorEditor'
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('field.identifier_full')
        , field: 'identifier'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      }
    ])

    prepareCustomFieldColumnDef(colDefs, self.customFields, { self });

    const linkedEntities = [
      { selector: 'SKILL.TAG', field: 'tag', properties: ['name'] }
      , { selector: 'SKILL.STAFF', field: 'staffCount', properties: ['uuId'] }
      , { selector: 'SKILL.STAFF.COMPANY', field: 'staffCount', properties: ['uuId'] }
      , { selector: 'SKILL.STAFF', field: 'staffCount', properties: ['genericStaff']}
    ]
    
    //VIEW permission: Remove column from display list
    filterOutViewDenyProperties(colDefs, 'SKILL', linkedEntities)
    
    if (self.isEntityEditable) {
      //EDIT permission: set column to be read only.
      setEditDenyPropertiesReadOnly(colDefs, 'SKILL', linkedEntities)  
    } else {
      for (let i = 0, len = colDefs.length; i < len; i++) {
        colDefs[i].editable = false;
      }
    }
    return colDefs
  }
  , getColorMenuOptions: () => ({
    none: true
    , skill: false
  })
  , getImportDataProperties: (self) => {
    const properties = [
      { value: 'color', text: self.$t('field.color') }
      , { value: 'identifier', text: self.$t('field.identifier') }
      , { value: 'name', text: self.$t('skill.field.name') }
      , { value: 'tag', text: self.$t('field.tag') }
    ]
    for (const key in self.enumList.SkillLevelEnum) {
      const level = self.enumList.SkillLevelEnum[key]
      if (level.num < 0) {
        continue
      }
      properties.push({ value: level.value, text: level.value })
    }
    return properties
  }
  , entityUpdateApiUrl: '/api/skill/update'
  , entityDeleteApiUrl: '/api/skill/delete'
  , getValueChangedHandler: (self) => {
    //Construct relevant request for value change or,
    //Reject the change and prepare special fallback value if needed
    const levelExecutor = (/**propertyName**/) => {
      return (entityId, oldVal, newValue) => {
        const newSKillLevels = []
        for (const key in newValue) {
          newSKillLevels.push(newValue[key])
        }
        const entityObj = { 
          uuId: entityId
          , skillLevels: newSKillLevels
        }
        return {
          value: [{
            method: 'PUT',
            invoke: '/api/skill/update',
            body: [entityObj],
            vars: [],
            note: `SKILL_update__skillLevels`
          }]
          , status: 'SUCCESS'
        }
      }
    }

    const handlers = {}
    for (const key in self.enumList.SkillLevelEnum) {
      const level = self.enumList.SkillLevelEnum[key]
      if (level.num < 0) {
        continue
      }

      handlers[level.value] = {
        isAsync: false
        , execute: levelExecutor(level.value)
      }

    }
    return handlers
  }
  , getPropertyCompatibleFunc: (self) => {
    const dataGroup = {
      stringGroup: ['name', 'identifier']
    }
    const levelGroup = []
    for (const key in self.enumList.SkillLevelEnum) {
      const level = self.enumList.SkillLevelEnum[key]
      if (level.num < 0) {
        continue
      }
      levelGroup.push(level.value)
    }
    dataGroup.levelGroup = levelGroup
    return (src, tgt) => {
      if (src === tgt) {
        return { status: true }
      }
    
      const keys = Object.keys(dataGroup)
      for(const key of keys) {
        if (dataGroup[key].includes(src) && dataGroup[key].includes(tgt)) {
          return { status: true }
        }  
      }
      return { status: false, colId: tgt }
    }
  }
  , getPropertyDeleteHandler: (self) => {
    const handlers = {
      tag: []
    }
    for (const key in self.enumList.SkillLevelEnum) {
      const level = self.enumList.SkillLevelEnum[key]
      if (level.num < 0) {
        continue
      }
      const prop = level.value
      handlers[prop] = (data) => { 
        const value = objectClone(data.levelGroup)
        value[prop] = { kind: prop, data: 5 }
        return { value, oldValue: data.levelGroup, status: 'SUCCESS' }}
    }
    return handlers
  }
  , getPropertyCopyHandler: (self) => {
    //Handle fill / copy & paste / ctrl + Enter action
    //Format the source Value to fit target property specification
    let maxNameLength = 200
    let maxIdentifierLength = 200
    if (self.modelInfo != null) {
      let val = self.modelInfo.filter(info => info.field === 'name')
      if (val.length > 0) {
        maxNameLength = val[0].max
      }
      val = self.modelInfo.filter(info => info.field === 'identifier')
      if (val.length > 0) {
        maxIdentifierLength = val[0].max
      }
    } 

    const handleSkillLevel = (property, srcValue, tgtData) => {
      if (srcValue == null || isNaN(srcValue)) {
        const v = tgtData.levelGroup != null 
                  && tgtData.levelGroup[property] != null
                  && tgtData.levelGroup[property].data != null? tgtData.levelGroup[property].data : { kind: property, data: 5 }
        return { 
          value: v, status: 'ABORT', colId: property }
      }

      let sVal = null
      if (typeof srcValue == 'number') {
        sVal = srcValue
      } else {
        sVal = parseInt(srcValue) 
      }

      if (sVal < 1) {
        sVal = 1
      } else if (sVal > 365) {
        sVal = 365
      }

      const newValue = objectClone(tgtData.levelGroup)
      newValue[property].data = sVal
      return { value: newValue, status: 'SUCCESS' }
    }

    //Expected format when return value is a function:
    //{ value, status } or
    //{ value, status, colId } when status is ABORT
    //Possible status: 'SUCCESS' | 'ABORT'
    //colId is optional but is useful for specifying a different colId as reset value.
    const handlers = {
      color: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.trim().length == 0) {
          value = null
        }
        return { value, status: 'SUCCESS' }
      }
      , name: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.length > maxNameLength) {
          value = srcValue.substring(0, maxNameLength)
        }
        return { value, status: 'SUCCESS' }
      }
      , identifier: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && value.length > maxIdentifierLength) {
          value = srcValue.substring(0, maxIdentifierLength)
        }
        return { value, status: 'SUCCESS' }
      }
    }

    for (const key in self.enumList.SkillLevelEnum) {
      const level = self.enumList.SkillLevelEnum[key]
      if (level.num < 0) {
        continue
      }
      const prop = level.value
      handlers[prop] = (srcValue, tgtData) => {
        return handleSkillLevel(prop, srcValue, tgtData)
      }
    }

    return handlers
  }
  , consolidatePropertyGroupWhenValueChangedFunc: (self) => {
    //Handle Skill Levels
    const skillLevelNames = self.enumList.SkillLevelEnum.map(i => i.value)

    return (valueChangedList) => {
      const rawList = objectClone(valueChangedList)
      const resultList = []

      const relatedList = []
      const unrelatedList = []
      for (const item of rawList) {
        if (skillLevelNames.includes(item.property)) {
          relatedList.push(item)
          continue
        }
        unrelatedList.push(item)
      }
      rawList.splice(0, rawList.length, ...unrelatedList)
      const obj = {}
      for (const item of relatedList) {
        if (Object.hasOwn(obj, item.entityId)) {
          obj[item.entityId].newValue[item.property] = item.newValue[item.property]
        } else {
          obj[item.entityId] = objectClone(item)
        }
      }
      resultList.push(...Object.values(obj))
  
      if (rawList.length > 0) {
        resultList.push(...rawList)
      }
  
      return resultList
    }
  }

  , lackOfMandatoryField: () => {
    return lackOfMandatoryField([{ entity: 'SKILL', action: 'VIEW' }])
  }
  , getExportDataPropertyHandler: (self) => {
    const handlers = {}
    for (const key in self.enumList.SkillLevelEnum) {
      const level = self.enumList.SkillLevelEnum[key]
      if (level.num < 0) {
        continue
      }
      handlers[level.value] = (params) => {
        return params.value != null 
          && params.value[level.value] != null 
          && params.value[level.value].data != null? 
          params.value[level.value].data : ''
      }
    }
    return handlers
  }
  , getMandatoryFields() {
    return [
      'name'
    ]
  }
  , customCellForClipboardHandler: (self) => {

    const handleSkillLevel = (prop, srcData) => {
      return { srcColId: prop, newValue: srcData.levelGroup[prop].data }
    }

    const handlers = {}
    for (const key in self.enumList.SkillLevelEnum) {
      const level = self.enumList.SkillLevelEnum[key]
      if (level.num < 0) {
        continue
      }
      const prop = level.value
      handlers[prop] = (srcData) => {
        return handleSkillLevel(prop, srcData)
      }
    }

    return handlers
  }
  // , getSrcValueForFillOperationHandler: (self) => {

  //   const handleSkillLevel = (prop, srcData) => {
  //     return srcData.levelGroup[prop].data
  //   }

  //   const handlers = {}
  //   for (const key in self.enumList.SkillLevelEnum) {
  //     const level = self.enumList.SkillLevelEnum[key]
  //     if (level.num < 0) {
  //       continue
  //     }
  //     const prop = level.value
  //     handlers[prop] = (srcData) => {
  //       return handleSkillLevel(prop, srcData)
  //     }
  //   }

  //   return handlers
  // }
  , getCustomSrcValueHandler: (self) => {
    const handlers = {}
    for (const key in self.enumList.SkillLevelEnum) {
      const level = self.enumList.SkillLevelEnum[key]
      if (level.num < 0) {
        continue
      }
      const prop = level.value
      handlers[prop] = (srcData) => {
        return srcData.levelGroup[prop].data
      }
    }

    return handlers
  }
  , getBadgeFilterFields: (self) => {
    const fields = [
      { value: 'name', text: self.$t('skill.field.name') }
      , { value: 'color', text: self.$t('skill.field.color') }
      , { value: 'tagName', text: self.$t('field.tag') }
      , { value: 'identifier', text: self.$t('field.identifier') }
    ];
    if (Array.isArray(self.customFields) && self.customFields.length > 0) {
      for (const f of self.customFields) {
        if (f.type == 'String' || f.type == 'Enum<String>') {
          fields.push({ value: f.name, text: f.displayName });
        }
      }
    }
    fields.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }))
    return fields;
  }
  , getBadgeFilterOptionFetchFunc: (self) => {
    return (field) => {
      let f = field;
      if (f == 'tagName') {
        f = 'TAG.name'
      }
      return skillService.listUniqueValuesOfProperty(f)
      .then(data => {
        if (data.length > 0 && self.badgeFilters != null && self.badgeFilters.length > 0) {
          const found = self.badgeFilters.find(i => i.field == field)
          if (found != null && Array.isArray(found.value) && found.value.length > 0) {
            
            //Normal handling
            const rList = data.map(i => ({ 
              text: !i ? '(Empty)' : i
              , checked: found.value.find(j => j.text != null 
                                          && (typeof j.text === 'string' && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) 
                                              || j.text == i) != null
            }))
            if (rList.find(i => i.text == '(Empty)') == null) {
              rList.unshift({ text: '(Empty)', checked: false })
            }
            return rList;
          }
        }
        
        //Normal handling
        const rList = data.map(i => ({ text: !i ? '(Empty)' : i, checked: false }))
        if (rList.find(i => i.text == '(Empty)') == null) {
          rList.unshift({ text: '(Empty)', checked: false })
        }
        return rList;
      });
    }
  }
  , prepareDataForOkEmit: (rowNodes) => {
    if (Array.isArray(rowNodes)) {
      return rowNodes.map(i => ({
        uuId: i.data.uuId
        , name: i.data.name
        , color: i.data.color
      }));
    } 
    return []
  }
}
