var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "edit-cell" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.params.title,
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.onOk, hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showPopup,
            callback: function ($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup",
          },
        },
        [
          _c(
            "b-form-group",
            [
              _c("b-form-textarea", {
                ref: "textarea",
                attrs: {
                  placeholder: _vm.params.placeholder,
                  "max-rows": 6,
                  autofocus: "",
                  rows: 6,
                },
                model: {
                  value: _vm.editValue,
                  callback: function ($$v) {
                    _vm.editValue = $$v
                  },
                  expression: "editValue",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }