<template>
  <div class="div-outer-div">
    <span class="div-percent-value">{{ label }}</span>
    <span v-if="!(params.noFill)" class="percent-bar-holder">
      <span class="div-percent-bar" :style="{ width: `${width}%`, backgroundColor: bgColor }"></span>
    </span>
  </div>
</template>

<script>
import Vue from 'vue';
import { toFixed } from '@/helpers/task-duration-process';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'PercentageCellRenderer',
  data() {
    return {
      width: null,
      bgColor: null,
      label: null,
      precision: 0
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);

    if (!this.params.node.rowPinned) {
      if (this.params.precision != null && typeof this.params.precision == 'number') {
        this.precision = this.params.precision;
      }
      this.width =  this.params && this.params.value && this.params.value !== "" ? toFixed((Array.isArray(this.params.value) ? this.params.value.reduce((a, b) => a + b, 0) : this.params.value) * 100, this.precision) : 0;
      if(this.width < 1) {
        this.bgColor = 'var(--progress-0)';
      } else if (this.width < 25) {
        this.bgColor = 'var(--progress-25)';
      } else if (this.width < 50) {
        this.bgColor = 'var(--progress-50)';
      } else if (this.width < 75) {
        this.bgColor = 'var(--progress-75)';
      } else if (this.width < 99) {
        this.bgColor = 'var(--progress-99)';
      } else {
        this.bgColor = 'var(--progress-100)';
      }
      
      if (!(this.params.noFill)) {
        this.label = this.width == 0? 'Not started' : `${this.width}%`;
      }
      else {
        this.label = `${this.width}%`;
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.div-outer-div {
  display: flex;
  max-height: 25px;
  border-radius: 4px;
  width: 100%;
}
.percent-bar-holder {
	width: 100%;
}
.div-percent-bar {
  border-radius: 3px;
  display: inline-block;
  height: 100%;
}
.div-percent-value {
  min-width: 34px;
  max-width: 34px;
  text-align: left;
}
</style>