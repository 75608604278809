import { httpAjax } from "../helpers";

export const authenticationService = {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword,
  changePasswordForRegister,
  changePasswordForReset
};

function register(token) {
  const data = {
    'tokenId': token
  };
  return httpAjax.post('/auth/register', data, {
    headers: defaultHeaders(),
  });
}

function forgotPassword(username) {
  const data = {
    'username': username
  };
  return httpAjax.post('/auth/forgot/password', data, {
    headers: defaultHeaders(),
  });
}

function resetPassword(token) {
  const data = {
    'tokenId': token
  };
  return httpAjax.post('/auth/reset/password', data, {
    headers: defaultHeaders(),
  });
}

/**
 * Update user password. 
 * 
 * @param {String} password   New password
 * @param {String} confirm    Confirm password
 * @param {String} token
 */
function changePasswordForRegister(password, confirm, token) {
  const data = {
    'password': password,
    'confirm': confirm,
    'tokenId': token
  };
  return httpAjax.post('/auth/register/password', data, {
    headers: defaultHeaders()
  });
}


/**
 * Update user password. 
 * 
 * @param {String} password   New password
 * @param {String} confirm    Confirm password
 * @param {String} token
 */
 function changePasswordForReset(password, confirm, token) {
  const data = {
    'password': password,
    'confirm': confirm,
    'tokenId': token
  };
  return httpAjax.post('/auth/reset/password', data, {
    headers: defaultHeaders()
  });
}

function login(username, password) {
  const data = {
    'username': username,
    'password': password,
    'applicationId': 'projectal-web'
  };
  return httpAjax.post('/auth/login', data, {
    headers: defaultHeaders(),
  })
}

/**
 *  Invalidate user token
 */
function logout() {
  return httpAjax.post('/api/user/logout', null, {
    header: Object.assign(defaultHeaders())
  });
}

// Private Methods
function defaultHeaders() {
  return {
    'Content-Type': 'application/json'
  }
}