import { locationService, locationLinkTagService } from '@/services'
import { addTags, objectClone } from '@/helpers'
import { countryCodes } from '@/selectOptions'
import { toFixed } from '@/helpers/task-duration-process'
import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly, lackOfMandatoryField } from './common'
import { prepareCustomFieldColumnDef } from '@/helpers/custom-fields'

export const locationUtil = {
  list: (bParams, { self }={}) => {
    return locationService.list(bParams, false, self?.customFields)
  }
  , listNames: locationService.listNames
  , remove: locationService.remove
  , clone: locationService.clone
  , importDataFunc: ( self ) => {
      return async (item, errorFunc) => {
      let method = 'create';
      
      const data = {
        name: item.name
        , street: item.street
        , town: item.town
        , region: item.region
        , postcode: item.postcode
        , countryCode: item.country
        , identifier: item.identifier
        , color: item.color
      }

      if (self.customFields) {
        for (const cfield of self.customFields) {
          if (item[cfield.name]) {
            data[cfield.name] = item[cfield.name];
          }
        }
      }
      
      if (item.uuId) {
        data.uuId = item.uuId;
        method = 'update';
      }
      
      const result = await locationService[method]([data])
      .then(response => {
        const feedbackList = response.data.feedbackList
        if (Array.isArray(feedbackList) && 
              feedbackList.length > 0 && 
              feedbackList[0].uuId != null) {
          return feedbackList[0].uuId
        }
      })
      .catch((e) => {
        errorFunc(e)
        return null
      })

      if (result && item.tag) {
        await addTags(result, item.tag.split(',').map(t => { return { name: t.trim() }}), locationLinkTagService).catch(() => {
          // fail silently
        });
      }
    }
  }
  , buildParams: ({ request: {sortModel, endRow, startRow} }, { exportData=false, searchFilter=null, badgeFilters=null }={}) => {
    const params = {
      start: !exportData ? startRow : 0
      , limit: !exportData ? endRow - startRow + 1 : -1
      , ksort: []
      , order: []
      , filter: searchFilter
      , badgeFilters
    }
    
    for(let i = 0, len = sortModel.length; i < len; i++) {
      params.ksort.push(sortModel[i].colId === 'countryName' ? 'countryCode' : sortModel[i].colId)
      params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr')
    }
    if (searchFilter == null) {
      delete params.filter
    }
    return params
  }
  , getColumnDefs: (self) => {
    const colDefs = [
      {
        headerName: self.$t('location.field.name')
        , field: 'uuId'
        , cellRenderer: 'detailLinkCellRenderer'
        , cellEditor: 'nameEditor'
        , cellEditorParams: {
          isOptional: false
        }
        , checkboxSelection: false
        , pinned: 'left'
        , lockPosition: 'left'
        , lockVisible: true
        , minWidth: 200
        , hide: false
        , sort: 'asc'
        , editable: params => self.canEdit('LOCATION', ['name']) && params.data.readOnly != true
        , valueSetter: function(params) {
          const newValue = params.newValue.trim()
          const oldValue = objectClone(params.data.name)
          if (newValue !== '' && newValue != oldValue) {
            self.$set(params.data, 'oldName', oldValue)
            params.data.name = newValue
            return true
          }
        }
      },
      {
        headerName: self.$t('location.field.headCount'),
        field: 'staffCount'
        , cellRenderer: 'genericCellRenderer'
        , hide: false
        , minWidth: 100
        , sortable: false
        , editable: false
      }, 
      {
        headerName: self.$t('location.field.genericHeadCount'),
        field: 'genericStaffCount'
        , cellRenderer: 'genericCellRenderer'
        , hide: false
        , minWidth: 100
        , sortable: false
        , editable: false
      },
      {
        headerName: self.$t('location.field.town')
        , field: 'town'
        , cellRenderer: 'genericCellRenderer'
        , hide: false
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('location.field.country')
        , field: 'countryCode'
        , cellEditor: 'listEditor'
        , cellRenderer: 'enumCellRenderer'
        , cellRendererParams: { 
          options: countryCodes
          , enableReadonlyStyle: self.nonAdmin? false : true //set null to make the setReadOnlyIfNotEditable() logic not to consider it
        }
        , cellEditorParams: { options: countryCodes }
        , hide: false
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('location.field.region')
        , field: 'region'
        , cellRenderer: 'genericCellRenderer'
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('location.field.street')
        , field: 'street'
        , cellRenderer: 'genericCellRenderer'
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('location.field.postcode')
        , field: 'postcode'
        , cellRenderer: 'genericCellRenderer'
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('location.field.rebates')
        , field: 'rebates'
        , cellRenderer: 'genericEntityArrayCellRenderer'
        , cellRendererParams: {
          labelFormatter: (params) => {
            if (!Array.isArray(params.value) || params.value.length == 0) {
              return { value: [] }
            }
            params.value.forEach(i => {
              if (!isNaN(i.rebate)) {
                i.label = `${i.name} (${toFixed(i.rebate * 100, 0)}%)`
              }
            })
            return { value: params.value }
          }
          , enableReadonlyStyle: self.nonAdmin? false : true //set null to make the setReadOnlyIfNotEditable() logic not to consider it
        }
        , cellEditor: 'rebatesEditor'
        , editable: params => params.data.readOnly != true
        , hide: true
      },
      {
        headerName: self.$t('field.tag')
        , field: 'tag'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'tagEditor'
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('field.color')
        , field: 'color'
        , cellRenderer: 'colorCellRenderer'
        , cellEditor: 'colorEditor'
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('field.identifier_full')
        , field: 'identifier'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      }
    ]

    prepareCustomFieldColumnDef(colDefs, self.customFields, { self })

    const linkedEntities = [
      { selector: 'LOCATION.TAG', field: 'tag', properties: ['name'] }
      , { selector: 'LOCATION.REBATE', field: 'rebates', properties: ['name'] }
    ]

    //VIEW permission: Remove column from display list
    filterOutViewDenyProperties(colDefs, 'LOCATION', linkedEntities)

    //EDIT permission: set column to be read only.
    setEditDenyPropertiesReadOnly(colDefs, 'LOCATION', linkedEntities)

    return colDefs
  }
  , getColorMenuOptions: () => ({
    none: true
    , location: false
  })
  , getImportDataProperties: (self) => [
    { value: 'color', text: self.$t('field.color') }
    , { value: 'identifier', text: self.$t('field.identifier') }
    , { value: 'name', text: self.$t('location.field.name') }
    , { value: 'town', text: self.$t('location.field.town') }
    , { value: 'country', text: self.$t('location.field.country') }
    , { value: 'region', text: self.$t('location.field.region') }
    , { value: 'street', text: self.$t('location.field.street') }
    , { value: 'postcode', text: self.$t('location.field.postcode') }
    , { value: 'tag', text: self.$t('field.tag') }
  ]
  , entityUpdateApiUrl: '/api/location/update'
  , entityDeleteApiUrl: '/api/location/delete'
  , getValueChangedHandler: (/** self */) => {
    return {
      rebates: {
        isAsync: false
        , execute: (entityId, oldVal, newValue) => {
          const oldList = Array.isArray(oldVal)? oldVal.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const list = Array.isArray(newValue)? newValue.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const toAdd = []
          const toUpdate = []
          const unchangedIds = []
  
          for(const item of list) {
            const index = oldList.findIndex(j => j.uuId === item.uuId)
            if(index == -1) {
              toAdd.push(item)
            } else {
              unchangedIds.push(item.uuId)
            }
          }
  
          const toAddIds = toAdd.map(i => i.uuId)
          const toUpdateIds = toUpdate.map(i => i.uuId)
          const toRemove = oldList.filter(i => !toAddIds.includes(i.uuId) 
                                              && !toUpdateIds.includes(i.uuId) 
                                              && !unchangedIds.includes(i.uuId))
          const requests = []
  
          if(toAdd.length > 0) {
            const addRebateLinkReqTemplate = function(refId, locationId, rebateList) {
              const list = [];
              rebateList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/location/link/rebate/add`,
                body: { 
                  uuId: locationId,
                  rebateList: list
                },
                vars: [],
                note: `locationAddRebateLink__${refId}__${rebateList[0].uuId}`
              }
            }
            for (const [index, rebate] of toAdd.entries()) {
              requests.push(addRebateLinkReqTemplate(`${index}_${entityId}`, entityId, [rebate]));
            }
          }
  
          if(toUpdate.length > 0) {
            const updateRebateLinkReqTemplate = function(refId, locationId, rebateList) {
              const list = [];
              rebateList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/location/link/rebate/update`,
                body: { 
                  uuId: locationId,
                  rebateList: list
                },
                vars: [],
                note: `locationUpdateRebateLink__${refId}__${rebateList[0].uuId}`
              }
            }
            for (const [index, rebate] of toUpdate.entries()) {
              requests.push(updateRebateLinkReqTemplate(`${index}_${entityId}`, entityId, [rebate]));
            }
          }
  
          if(toRemove.length > 0) {
            const removeRebateLinkReqTemplate = function(refId, locationId, rebateList) {
              const list = [];
              rebateList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/location/link/rebate/delete`,
                body: { 
                  uuId: locationId,
                  rebateList: list
                },
                vars: [],
                note: `locationRemoveRebateLink__${refId}__${rebateList[0].uuId}`
              }
            }
            for (const [index, rebate] of toRemove.entries()) {
              requests.push(removeRebateLinkReqTemplate(`${index}_${entityId}`, entityId, [rebate]));
            }
          }
  
          if (requests.length > 0) {
            return {
              value: requests
              , status: 'SUCCESS'
            }
          }
  
          return {
            value: oldVal
            , status: 'ABORT'
            , property: 'rebates'
          }
        }
      }
    }
  }
  , getPropertyDeleteHandler: (/** self */) => {
    return {
      tag: []
    }
  }
  , getPropertyCopyHandler: (self) => {
    let maxNameLength = 200
    let maxIdentifierLength = 200
    if (self.modelInfo != null) {
      let val = self.modelInfo.filter(info => info.field === 'name')
      if (val.length > 0) {
        maxNameLength = val[0].max
      }
      val = self.modelInfo.filter(info => info.field === 'identifier')
      if (val.length > 0) {
        maxIdentifierLength = val[0].max
      }
    } 

    //Expected format when return value is a function:
    //{ value, status } or
    //{ value, status, colId } when status is ABORT
    //Possible status: 'SUCCESS' | 'ABORT'
    //colId is optional but is useful for specifying a different colId as reset value.
    return {
      color: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.trim().length == 0) {
          value = null
        }
        return { value, status: 'SUCCESS' }
      }
      , name: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.length > maxNameLength) {
          value = srcValue.substring(0, maxNameLength)
        }
        return { value, status: 'SUCCESS' }
      }
      , identifier: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && value.length > maxIdentifierLength) {
          value = srcValue.substring(0, maxIdentifierLength)
        }
        return { value, status: 'SUCCESS' }
      }
    }
  }
  , lackOfMandatoryField: () => {
    return lackOfMandatoryField([{ entity: 'LOCATION', action: 'VIEW' }])
  }
  , getMandatoryFields() {
    return [
      'name'
    ]
  }
  , getBadgeFilterFields: (self) => {
    const fields = [
      { value: 'name', text: self.$t('location.field.name') }
      , { value: 'color', text: self.$t('location.field.color') }
      , { value: 'tagName', text: self.$t('field.tag') }
      , { value: 'identifier', text: self.$t('field.identifier') }
      , { value: 'rebateName', text: self.$t('location.field.rebates') }
      , { value: 'street', text: self.$t('location.field.street') }
      , { value: 'town', text: self.$t('location.field.town') }
      , { value: 'region', text: self.$t('location.field.region') }
      , { value: 'postcode', text: self.$t('location.field.postcode') }
      , { value: 'countryCode', text: self.$t('location.field.country') }
    ];
    if (Array.isArray(self.customFields) && self.customFields.length > 0) {
      for (const f of self.customFields) {
        if (f.type == 'String' || f.type == 'Enum<String>') {
          fields.push({ value: f.name, text: f.displayName });
        }
      }
    }
    fields.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }))
    return fields;
  }
  , getBadgeFilterOptionFetchFunc: (self) => {
    return (field) => {
      let f = field;
      if (f == 'tagName') {
        f = 'TAG.name'
      } else if (f == 'rebateName') {
        f = 'REBATE.name'
      }
      return locationService.listUniqueValuesOfProperty(f)
      .then(data => {
        if (data.length > 0 && self.badgeFilters != null && self.badgeFilters.length > 0) {
          const found = self.badgeFilters.find(i => i.field == field)
          if (found != null && Array.isArray(found.value) && found.value.length > 0) {
            if (field == 'countryCode') {
              //Additional property 'value' is added to keep the original value.
              const list = [];
              for (const d of data) {
                let text = d
                if (!d) {
                  text = '(Empty)'
                } else {
                  const found = countryCodes.find(j => j.value === d);
                  text = found != null? found.text : '(Empty)'
                }
                const value = d ? d : null
                list.push({
                  text
                  , value
                  , checked: found.value.find(j => j.value != null && j.value == value) != null
                })
              }

              if (list.find(i => i.text == '(Empty)') == null) {
                list.unshift({ text: '(Empty)', value: null, checked: false })
              }
              return list
            }

            //Normal handling
            const rList = data.map(i => ({ 
              text: !i ? '(Empty)' : i
              , checked: found.value.find(j => j.text != null 
                                          && (typeof j.text === 'string' && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) 
                                              || j.text == i) != null
            }))
            if (rList.find(i => i.text == '(Empty)') == null) {
              rList.unshift({ text: '(Empty)', checked: false })
            }
            return rList;
          }
        }
        
        if (field == 'countryCode') {
          //Additional property 'value' is added to keep the original value.
          let list = []
          for (const d of data) {
            let text = d
            if (!d) {
              text = '(Empty)'
            } else {
              const found = countryCodes.find(j => j.value === d);
              text = found != null? found.text : '(Empty)'
            }
            const value = d ? d : null
            list.push({
              text
              , value
              , checked: false
            })
          }
          
          if (list.find(i => i.text == '(Empty)') == null) {
            list.unshift({ text: '(Empty)', value: null, checked: false })
          }
          return list
        }

        //Normal handling
        const rList = data.map(i => ({ text: !i ? '(Empty)' : i, checked: false }))
        if (rList.find(i => i.text == '(Empty)') == null) {
          rList.unshift({ text: '(Empty)', checked: false })
        }
        return rList;
      });
    }
  }
  , prepareDataForOkEmit: (rowNodes) => {
    if (Array.isArray(rowNodes)) {
      return rowNodes.map(i => ({
        uuId: i.data.uuId
        , name: i.data.name
        , rebates: i.data.rebates
      }));
    } 
    return []
  }
  , getExportDataPropertyHandler: (/**self */) => {
    const formatRebates = (params) => {
      if (Array.isArray(params.value) && params.value.length > 0) {
        return params.value.map(i => `${i.name} (${i.rebate * 100}%)`).join(', ')
      }
      return ''
    }

    return {
      rebates: (params) => {
        return formatRebates(params)
      }
      
    }
  }
}