
import { httpAjax } from '@/helpers';

export const taskLinkStageService = {
  create,
  remove
};

/**
 * Create a new Link from task to stage 
 * by passing necessary information
 * @param String taskId, task's UUID
 * @param Array stage, stage details. Data sample: { uuId }
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', stage: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx' } }
 */
function create(taskId, stage) {
  const url = '/api/task/link/stage/add';
  const config = {
    headers: getHeaders()
  };

  const data = {
    uuId: taskId,
    stage
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from task to stage.
 * by passing in an array list of links uuIds
 * @param String taskId, task's UUID
 * @param Array stageIds, list of stage's UUID 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', stage: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }
 */
function remove(taskId, stage) {
  const url = '/api/task/link/stage/delete';
  const config = {
    headers: getHeaders()
  };

  const data = {
    uuId: taskId,
    stage
  }
  return httpAjax.post(url, data, config);
}


function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}