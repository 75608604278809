var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "datetime-cell-renderer" }, [
    _vm._v(_vm._s(_vm.label)),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }