<template>
  <KanbanBoard :projectId="projectId"  />
</template>

<script>
export default {
  name: 'ProjectKanban',
  components: {
    KanbanBoard: () => import('@/components/Kanban/Board'),
  },
  created() {
    this.projectId = this.$route.params.id;
  },
}
</script>