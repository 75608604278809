var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDisabled
    ? _c(
        "a",
        _vm._b(
          {
            class: _vm.classList,
            attrs: { tabindex: "-1" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
              },
            },
          },
          "a",
          _vm.attributes,
          false
        ),
        [
          _c("font-awesome-icon", {
            staticClass: "nav-icon",
            attrs: { icon: _vm.icon },
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.name))]),
          _vm.badge && _vm.badge.text
            ? _c("b-badge", { attrs: { variant: _vm.badge.variant } }, [
                _vm._v(_vm._s(_vm.badge.text)),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm.isExternalLink
    ? _c(
        "a",
        _vm._b(
          { class: _vm.classList, attrs: { href: _vm.url } },
          "a",
          _vm.attributes,
          false
        ),
        [
          _c("font-awesome-icon", {
            staticClass: "nav-icon",
            attrs: { icon: _vm.icon },
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.name))]),
          _vm.badge && _vm.badge.text
            ? _c("b-badge", { attrs: { variant: _vm.badge.variant } }, [
                _vm._v(_vm._s(_vm.badge.text)),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm.emitevent
    ? _c(
        "a",
        _vm._b(
          {
            class: _vm.classList,
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleClick(_vm.emitevent, _vm.params)
              },
              mouseleave: _vm.onMenuLeave,
            },
          },
          "a",
          _vm.attributes,
          false
        ),
        [
          _vm.icon !== ""
            ? _c("font-awesome-icon", {
                staticClass: "nav-icon",
                attrs: { icon: _vm.icon },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { attrs: { id: "foldername" + this.id } }, [
            _vm._v(_vm._s(_vm.name)),
          ]),
          _vm.isEllipsisActive()
            ? _c("b-popover", {
                attrs: {
                  target: "foldername" + this.id,
                  placement: "right",
                  boundary: "viewport",
                  "custom-class": "popover-margin",
                  triggers: "hover",
                  offset: "-100",
                  content: _vm.name,
                },
              })
            : _vm._e(),
          _vm.badge && _vm.badge.text
            ? _c("b-badge", { attrs: { variant: _vm.badge.variant } }, [
                _vm._v(_vm._s(_vm.badge.text)),
              ])
            : _vm._e(),
          _c(
            "span",
            { staticClass: "action-container-class" },
            _vm._l(_vm.actions, function (action, index) {
              return _c(
                "div",
                { key: index, staticClass: "action-class" },
                [
                  action.emitevent
                    ? _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleActionClick(action)
                            },
                          },
                        },
                        [
                          _vm.hasPermission(action.permission)
                            ? _c("font-awesome-icon", {
                                staticClass: "text-white",
                                attrs: {
                                  id: "ACTION_" + action.id,
                                  icon: action.icon,
                                },
                              })
                            : _vm._e(),
                          _c("b-popover", {
                            attrs: {
                              target: "ACTION_" + action.id,
                              placement: "top",
                              triggers: "hover",
                              content: _vm.$t(action.name),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.hasPermission(action.permission)
                    ? _c(
                        "b-dropdown",
                        {
                          ref: "menu",
                          refInFor: true,
                          staticClass: "pb-1 action-menu",
                          attrs: { variant: "link", "no-caret": "" },
                        },
                        [
                          _c("template", { slot: "button-content" }, [
                            _c(
                              "div",
                              { staticClass: "text" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: action.icon },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._l(action.items, function (item, itemindex) {
                            return [
                              !action.url || !action.url.startsWith("blob")
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      key: itemindex,
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleActionClick(item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t(item.name)))]
                                  )
                                : _c(
                                    "b-dropdown-item",
                                    {
                                      key: itemindex,
                                      attrs: {
                                        download: item.params.download,
                                        href: action.url,
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t(item.name)))]
                                  ),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      )
    : _c(
        "div",
        { on: { mouseleave: _vm.onMenuLeave } },
        [
          _c(
            "router-link",
            _vm._b(
              {
                class: _vm.classList,
                attrs: { id: _vm.id, to: _vm.url, exact: "" },
              },
              "router-link",
              _vm.attributes,
              false
            ),
            [
              _vm.icon !== ""
                ? _c("font-awesome-icon", {
                    staticClass: "nav-icon",
                    attrs: { icon: _vm.icon },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { attrs: { id: "name" + this.id } }, [
                _vm._v(_vm._s(_vm.name)),
              ]),
              _vm.isEllipsisActive()
                ? _c("b-popover", {
                    attrs: {
                      target: "" + this.id,
                      placement: "right",
                      boundary: "viewport",
                      "custom-class": "popover-margin",
                      triggers: "hover",
                      offset: "-100",
                      content: _vm.name,
                    },
                  })
                : _vm._e(),
              _vm.badge && _vm.badge.text
                ? _c("b-badge", { attrs: { variant: _vm.badge.variant } }, [
                    _vm._v(_vm._s(_vm.badge.text)),
                  ])
                : _vm._e(),
              _c(
                "span",
                { staticClass: "action-container-class" },
                _vm._l(_vm.actions, function (action, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "action-class" },
                    [
                      action.emitevent
                        ? _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleActionClick(action)
                                },
                              },
                            },
                            [
                              _vm.hasPermission(action.permission)
                                ? _c("font-awesome-icon", {
                                    staticClass: "text-white",
                                    attrs: {
                                      id: "ACTION_" + action.id,
                                      icon: action.icon,
                                    },
                                  })
                                : _vm._e(),
                              _c("b-popover", {
                                attrs: {
                                  target: "ACTION_" + action.id,
                                  placement: "right",
                                  triggers: "hover",
                                  content: _vm.$t(action.name),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.hasPermission(action.permission)
                        ? _c(
                            "b-dropdown",
                            {
                              ref: "menu",
                              refInFor: true,
                              staticClass: "action-menu",
                              attrs: { variant: "link", "no-caret": "" },
                            },
                            [
                              _c("template", { slot: "button-content" }, [
                                _c(
                                  "div",
                                  { staticClass: "text" },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: action.icon },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._l(action.items, function (item, itemindex) {
                                return [
                                  !item.url || !item.url.startsWith("blob")
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          key: itemindex,
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleActionClick(item)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t(item.name)))]
                                      )
                                    : _c(
                                        "b-dropdown-item",
                                        {
                                          key: itemindex,
                                          attrs: {
                                            download: item.params.download,
                                            href: item.url,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.preventBubble.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t(item.name)))]
                                      ),
                                ]
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }