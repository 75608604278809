<template>
  <span>{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'TaskTypeCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    this.prepareValue();
  },
  computed: {
    label() {
      return this.value? this.value : 'N/A';
    }
  },
  methods: {
    prepareValue() {
      const data = this.params && this.params.data? this.params.data : null;
      const taskType = data && data.taskType? data.taskType : null;
      if (taskType == null) {
        this.value = null;
        return;
      }
      if (taskType == 'Project' && data && data.uuId == 'ROOT') { //used by task compact view
        this.value = this.params.context.componentParent.$t('task_type.Special_Project')
        return;
      }
      this.value = taskType != null? this.params.context.componentParent.$t(`task_type.${taskType}`) : null;
    }
  }
})
</script>
