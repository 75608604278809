var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BlankCenterContainer",
    [
      _c("b-col", { attrs: { md: "10" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column justify-content-center flex-md-row align-items-center",
          },
          [
            _c("h1", { staticClass: "float-left display-1 mr-md-4" }, [
              _vm._v("404"),
            ]),
            _c("div", { staticClass: "text-center text-md-left" }, [
              _c("h4", { staticClass: "pt-3" }, [_vm._v("Oops! You're lost.")]),
              _c("p", { staticClass: "text-muted mb-0" }, [
                _vm._v("The page you are looking for was not found."),
              ]),
              _c(
                "p",
                { staticClass: "text-muted" },
                [
                  _vm._v("Back to "),
                  _c("router-link", { attrs: { to: "/" } }, [
                    _vm._v("Dashboard"),
                  ]),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }