<template>
  <div ref="stage-cell-editor" class="edit-cell" @focusout="onFocusOut" @keydown="onKeyDown">
    <multiselect ref="multiselect" v-model="value.uuId" class="custom-dropdown-options aggrid-cell-editor enable-option-icon"
      :max-height="300"
      :options="optionStages.map(i => i.value)"
      :custom-label="getStageOptionLabel"
      :placeholder="''"
      :searchable="false" 
      :allow-empty="true"
      :showLabels="false"
      @input="onChange">
      <template slot="option" slot-scope="props">
        <font-awesome-icon class="selected-option-icon" v-if="value.uuId == props.option" :icon="['far', 'check']" />
        <span class="option__title">{{ getStageOptionLabel(props.option) }}</span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Vue from 'vue';
import { stageService } from '@/services';
import { objectClone } from '@/helpers';
import Multiselect from 'vue-multiselect';

const KEYCODE_ENTER = 13;
const KEYCODE_ESCAPE = 27;
const KEYCODE_TAB = 9;

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';

export default Vue.extend({
  name: 'StageEditor',
  components: {
    Multiselect
  },
  data() {
    return {
      value: null
      , optionStages: null
      , compactMode: false
      , isOpen: true
    };
  },
  created() {
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }

    if (this.params.options) {
      let options = this.params.options != null? this.params.options : [];
      if (this.params.data.projId != null && this.params.projStagesMap != null) {
        const projId = this.params.data.projId;
        const m = this.params.projStagesMap;
        if (m.has(projId)) {
          options = m.get(projId);
        }
      }
      this.optionStages = objectClone(options.filter(i => i.value != null));

      //Filtered stage options if task has tag property and it is not empty
      this.filterStageOptions(options, (result) => {
        this.optionStages = result;
      })
    }
    else if (this.params.data.stageOptions != null) { //used in PagedAgGridGantt
      this.optionStages = objectClone(this.params.data.stageOptions);

      //Filtered stage options if task has tag property and it is not empty
      this.filterStageOptions(this.params.data.stageOptions, (result) => {
        this.optionStages = result;
      })
    }
    else if(this.params.data.stages) {
      this.optionStages = [];
      // this.optionStages.unshift({ uuId: null, name: '' });
      this.optionStages.splice(0, this.optionStages.length, ...this.params.data.stages.map(i => {
        return { value: i.uuId, text: i.name } 
      }));
    }

    const params = this.params;
    let startValue;
    if (params.key === KEY_BACKSPACE || params.key === KEY_DELETE) {
      // if backspace or delete pressed, we clear the cell
      startValue = { uuId: null, name: '' };
    } else if (params.charPress) {
      // if a letter was pressed, we start with the letter
      if (this.optionStages == null || this.optionStages.length == 0) {
        startValue = { uuId: null, name: '' };
      } else {
        startValue = { uuId: this.optionStages[0].value, name: this.optionStages[0].name };
        const found = this.optionStages.find(i => i.text.toLowerCase().startsWith(params.charPress.toLowerCase()));
        if (found) {
          startValue = { uuId: found.value, name: found.text };
        }
      }
    } else {
      // otherwise we start with the current value
      startValue = this.compactMode? objectClone(params.value.single): objectClone(params.value);
    }
    this.value = startValue;
  },
  mounted() {
    
  },
  methods: {
    getValue() {
      if (this.compactMode) {
        if (this.params.value == null) {
          return this.params.value;
        }
        
        const orgValue = this.params.value.single;
        const curValue = this.value;
        
        if (curValue == null && orgValue == null) {
          return this.params.value;
        } else if (curValue != null && orgValue != null && curValue.uuId == orgValue.uuId) {
          return this.params.value;
        } else if (orgValue == null && curValue.uuId == null) {
          //When there is no valid task and user set a null stage value. Return original value to avoid task creation.
          return this.params.value;
        }
        const updatedValue = objectClone(this.params.value);
        updatedValue.single = this.value;
        return updatedValue;
      } else {
        return this.value;
      }
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },

    onChange(val) {
      const oldUuId = this.params.data.stage != null? this.params.data.stage.uuId : null;
      if(oldUuId != val) {
        const name = this.optionStages.filter(i => i.value == val)[0].text;
        this.value = { uuId: val, name };
      }
    },

    onKeyDown(event) {
      const keyCode = this.getCharCodeFromEvent(event);
      const api = this.params.api;
      if (KEYCODE_ENTER == keyCode) {
        event.stopPropagation();
        event.preventDefault();
        if (event.shiftKey) {
          this.moveUp(api, this.params.rowIndex, this.params.column.colId);
        } else {
          this.moveDown(api, this.params.rowIndex, this.params.column.colId);
        }
        return;
      } else if (KEYCODE_TAB == keyCode) {
        event.stopPropagation();
        event.preventDefault();
        if (event.shiftKey) {
          api.tabToPreviousCell();
        } else {
          api.tabToNextCell();
        }
        return;
      }

      if (keyCode == KEYCODE_ESCAPE || keyCode == KEYCODE_ENTER) {
        if (keyCode == KEYCODE_ESCAPE) {
          this.value = this.params.value;
        }
        setTimeout(() => {
          this.params.api.stopEditing();
        }, 100);
      }
    },

    getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    },

    onFocusOut() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 300)
    },

    moveUp(api, pRowIndex, pColId) {
      setTimeout(() => {
        let rowIndex = pRowIndex - 1;
        const colId = pColId;
        if (rowIndex < 0) {
          rowIndex = 0;
        }
        api.clearRangeSelection();
        api.setFocusedCell(rowIndex, colId, null);
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        });
      })
    },

    moveDown(api, pRowIndex, pColId) {
      setTimeout(() => {
        let rowIndex = pRowIndex + 1;
        const colId = pColId;
        if (api.getDisplayedRowAtIndex(rowIndex) == null) {
          rowIndex = this.params.rowIndex;
        }
        api.clearRangeSelection();
        api.setFocusedCell(rowIndex, colId, null);
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        });
      })
    },

    filterStageOptions(options, successCallback) {
      let tagNames = this.compactMode? this.params.value?.data?.tag : this.params.data?.tag;
      if (tagNames == null) {
        tagNames = [];
      } 
      const stageIds = options.filter(i => i.value != null).map(i => i.value);
      if (stageIds.length > 0 && tagNames.length > 0) {
        const canViewTag = this.params.context?.componentParent != null 
                          && this.params.context.componentParent.canView('TAG')
                          && this.params.context.componentParent.canView('STAGE', ['TAG'])
        if (canViewTag) {
          const currentValue = this.compactMode? this.params.value?.single?.uuId: this.params.value?.uuId;
          let stageOpts = objectClone(options);
          const listParams = { start: 0, limit: -1, ksort: 'name' }
          const tagFilterList = ['_or_'];
          const tagList = [];
          for(const t of tagNames) {
            tagList.push(['STAGE.TAG.name', 'is', t])
          }
          tagFilterList.push(tagList);
          listParams.tagFilter = tagFilterList;
          listParams.holder = stageIds;
          stageService.list(listParams).then((response) => {
            if (response.arg_total > 0) {
              stageOpts = response.data.map(i => ({ value: i.uuId, text: i.name }));
              if (currentValue != null && stageOpts.find(i => i.uuId == currentValue) == null) {
                let found = options.find(i => i.value == currentValue);
                //Keep the disabled but currently selected option
                if (found != null) {
                  found = objectClone(found);
                  found.disabled = true;
                  stageOpts.push(found);
                }
              }
              // const emptyOpt = options.find(i => i.value == null);
              // if (emptyOpt != null && stageOpts.find(i => i.value == null) == null) {
              //   stageOpts.unshift(emptyOpt);
              // }
              if (typeof successCallback === 'function') {
                successCallback(stageOpts);
              }
            }
          }).catch(e => {
            console.error(e) // eslint-disable-line no-console
          });

        }
      }
    },
    getStageOptionLabel(value) {
      return this.optionStages.find(i => i.value === value)?.text || value;
    },
    isPopup() {
      return true;
    },
    getPopupPosition() {
      return 'over';
    },
    afterGuiAttached() {
      const popupEditor = this.$el.closest('.ag-popup-editor');
      popupEditor.style.width = this.params.eGridCell.style.width;
      this.$refs.multiselect.$el.focus();
    },
    destroy() {

    }
  }
})
</script>
<style lang="css">

</style>