import { customerService, customerLinkTagService
  , customerLinkLocationService, contactService } from '@/services'
import { addTags, objectClone } from '@/helpers'
import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly, lackOfMandatoryField } from './common'
import { prepareCustomFieldColumnDef } from '@/helpers/custom-fields'
import { countryCodes } from '@/selectOptions'

const parsePot = (data) => {
  if (typeof data === 'undefined') {
    return '';
  }
  
  if (typeof data === 'string' &&
      data.length !== 0) {
    data = JSON.parse(data);
  }
  
  const ret = [];
  for (const d of data) {
    ret.push(`${d.kind} ${d.data}`);
  }
  return ret.join('; ');
}
const parseLot = (data) => {
  if (typeof data === 'undefined') {
    return '';
  }
  
  if (typeof data === 'string' &&
      data.length !== 0) {
    return JSON.parse(data);
  }
  
  return '';
}
const parseSocial = (social) => {
  if (social.includes(':')) {
    const parts = social.split(': ');
    return { kind: parts[0], data: parts[1] };
  }
  return { kind: 'other', data: social }
}

export const customerUtil = {
  list: (bParams, { self }={}) => {
    return customerService.listv2(bParams, self?.customFields)
  }
  , listNames: customerService.listNames
  , remove: customerService.remove
  , clone: customerService.clone
  , importDataFunc: (self) => {
    return async (item, errorFunc) => {
      let method = 'create';
      const data = {
        name: item.name,
        nickName: item.nickname,
        description: item.desc,
        identifier: item.identifier,
        color: item.color
      }
      

      if (self.customFields) {
        for (const cfield of self.customFields) {
          if (item[cfield.name]) {
            data[cfield.name] = item[cfield.name];
          }
        }
      }
      
      if (item.uuId) {
        data.uuId = item.uuId;
        method = 'update'

        const cmp = await customerService.query([{ uuId: data.uuId }], ['LOCATION']).then((response) => {
          return response.data.objectList;
        }).catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });

        if (cmp[0].locationList.length > 0 &&
            item.location && item.location.length !== 0) {
          for (const loc of cmp[0].locationList) {
            if (item.location.uuId !== loc.uuId) {
              await customerLinkLocationService.remove(data.uuId, [loc.uuId]);
            }
          }
        }
      }
      
      
      if (item.socials &&
          item.socials.length > 0) {
        data['socials'] = item.socials.split(', ').map(p => { return parseSocial(p) });    
      }
      
      if (item.websites &&
          item.websites.length > 0) {
        data['websites'] = item.websites.split(', ');    
      }
      
      const result = await customerService[method]([data])
      .then(response => {
        const feedbackList = response.data.feedbackList;
        if (Array.isArray(feedbackList) && 
              feedbackList.length > 0 && 
              feedbackList[0].uuId != null) {
          return feedbackList[0].uuId;
        }
      })
      .catch((e) => {
        errorFunc(e);
        return null;
      });
      
      if (result) {
        if (item.location) {
          await customerLinkLocationService.create(result, [item.location.uuId]).catch(() => {
            // fail silently
          });
        }
          
        if (item.tag) {
          await addTags(result, item.tag.split(',').map(t => { return { name: t.trim() }}), customerLinkTagService).catch(() => {
            // fail silently
          }); 
        }
        
        if (item.contact) {
          const contacts = item.contact.split('|');
          for (const contact of contacts) {
            const lines = contact.split(', ');
            const data = { lastName: '' };
            for (const line of lines) {
              const pair = line.split(': ');
              if (pair[0] === self.$t('contact.field.firstName')) {
                data['firstName'] = pair[1];
              }
              else if (pair[0] === self.$t('contact.field.lastName')) {
                data['lastName'] = pair[1];
              }
              else if (pair[0] === self.$t('contact.field.position')) {
                data['position'] = pair[1];
              }
              else if (pair[0] === self.$t('contact.field.company')) {
                data['company'] = pair[1];
              }
              else if (pair[0] === self.$t('contact.field.phones')) {
                data['phones'] = [];
                const arr = pair[1].split('; ');
                for (const phone of arr) {
                  const pot = phone.split(' ');
                  data['phones'].push({ kind: pot[0], data: pot[1] });
                }
              }
              else if (pair[0] === self.$t('contact.field.socials')) {
                data['socials'] = [];
                const arr = pair[1].split('; ');
                for (const social of arr) {
                  const pot = social.split(' ');
                  if (pot.length > 0 && pot[0] !== '') {
                    data['socials'].push({ kind: pot[0], data: pot[1] });
                  }
                }
              }
              else if (pair[0] === self.$t('contact.field.emails')) {
                const lot = pair[1].split(',');
                if (lot.length > 0 && lot[0] !== '') {
                  data['emails'] = lot;
                }
              }
              else if (pair[0] === self.$t('contact.field.websites')) {
                const lot = pair[1].split(',');
                if (lot.length > 0 && lot[0] !== '') {
                  data['websites'] = lot;
                }
              }
            }
            await contactService.create([data], result)
            .then(response => {
              const feedbackList = response.data.feedbackList;
              if (Array.isArray(feedbackList) && 
                    feedbackList.length > 0 && 
                    feedbackList[0].uuId != null) {
                return feedbackList[0].uuId;
              }
            })
            .catch((e) => {
              errorFunc(e);
              return null;
            });
          }
        }
      }
    }
  }
  , buildParams: ({ request: {sortModel, endRow, startRow} }, { exportData=false, searchFilter=null, badgeFilters=null }={}) => {
    const params = {
      start: !exportData ? startRow : 0
      , limit: !exportData ? endRow - startRow + 1 : -1
      , ksort: []
      , order: []
      , filter: searchFilter
      , badgeFilters
    }
    
    for(let i = 0, len = sortModel.length; i < len; i++) {
      if (sortModel[i].colId === 'projects') {
        params.ksort.push('projectName')
      } else if (sortModel[i].colId === 'locations') {
        params.ksort.push('locationName')
      } else {
        params.ksort.push(sortModel[i].colId)
      }
      params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr')
    }
    if (searchFilter == null) {
      delete params.filter
    }
    return params
  }
  , getColumnDefs: (self) => {
    const colDefs = [
      {
        headerName: self.$t('field.avatar'),
        field: 'avatarRef',
        minWidth: self.nonAdmin? 35: 26,
        maxWidth: self.nonAdmin? 35: 26,
        hide: false,
        headerComponent: 'blankHeaderComponent',
        cellRenderer: 'avatarCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: (params) => !self.nonAdmin && params.data.readOnly != true,
          nonAdmin: self.nonAdmin
        },
        pinned: 'left',
        lockPosition: 'left',
        menuTabs: [],
        editable: false
      },
      {
        headerName: self.$t('customer.field.name')
        , field: 'uuId'
        , cellRenderer: 'detailLinkCellRenderer'
        , cellRendererParams: {
          ignoreAvatar: true
          , enableReadonlyStyle: self.nonAdmin? false : null //set null to make the setReadOnlyIfNotEditable() logic not to consider it
        }
        , cellEditor: 'nameEditor'
        , cellEditorParams: {
          isOptional: false
        }
        , checkboxSelection: false
        , pinned: 'left'
        , lockPosition: 'left'
        , lockVisible: true
        , minWidth: 200
        , hide: false
        , sort: 'asc'
        , editable: params => self.canEdit('CUSTOMER', ['name']) && params.data.readOnly != true
        , valueSetter: function(params) {
          const newValue = params.newValue.trim()
          const oldValue = objectClone(params.data.name)
          if (newValue !== '' && newValue != oldValue) {
            self.$set(params.data, 'oldName', oldValue)
            params.data.name = newValue
            return true
          }
          return false
        }
      },
      {
        headerName: self.$t('customer.field.country')
        , field: 'locationCountry'
        , cellRenderer: 'genericCellRenderer'
        , hide: false
      },
      {
        headerName: self.$t('customer.field.location')
        , field: 'locations'
        , cellRenderer: 'genericEntityArrayCellRenderer'
        , cellEditor: 'locationsEditor'
        , cellEditorParams: {
          multiple: true
        }
        , editable: params => params.data.readOnly != true
        , hide: false
      },
      {
        headerName: self.$t('customer.field.projects')
        , field: 'projects'
        , cellRenderer: 'genericEntityArrayCellRenderer'
        , cellEditor: 'projectsEditor'
        , editable: params => params.data.readOnly != true
        , hide: false
      },
      {
        headerName: self.$t('customer.field.nickName')
        , field: 'nickName'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , editable: params => params.data.readOnly != true
        , hide: false
      },
      {
        headerName: self.$t('customer.field.description')
        , field: 'description'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'multilineEditor'
        , cellEditorParams: { title: self.$t('task.edit_description') }
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('customer.field.socials')
        , field: 'socials'
        , minWidth: 100
        , cellRenderer: 'genericEntityArrayCellRenderer'
        , cellRendererParams: {
            labelFormatter: (params) => {
              if (Array.isArray(params.value)) {
                params.value.forEach(i => {
                  if (i.kind != null && i.data != null) {
                    i.label = `${i.kind}:${i.data}`
                  } else {
                    i.label = ''
                  }
                })
              } 
              return { value: params.value }
            }
            , enableReadonlyStyle: self.nonAdmin? false : null //set null to make the setReadOnlyIfNotEditable() logic not to consider it
          }
        , cellEditor: 'socialsEditor'
        , cellEditorParams: {
          permissionName: 'CUSTOMER'
        }
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('customer.field.websites')
        , field: 'websites'
        , minWidth: 100
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'websitesEditor'
        , cellEditorParams: {
          permissionName: 'CUSTOMER'
        }
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('customer.field.contacts')
        , field: 'contacts'            
        , cellRenderer: 'genericEntityArrayCellRenderer'
        , cellRendererParams: { 
            labelFormatter: (params) => {
              if (Array.isArray(params.value)) {
                params.value.forEach(i => {
                  if (i.firstName != null) {
                    i.label = i.firstName
                  } else {
                    i.label = ''
                  }
                  if (i.lastName != null) {
                    i.label = `${i.label}${i.label.length > 0? ' ' : ''}${i.lastName}`
                  }
                })
              } 
              return { value: params.value }
            }
            , enableReadonlyStyle: self.nonAdmin? false : null //set null to make the setReadOnlyIfNotEditable() logic not to consider it
            
          }
        , minWidth: 100
        , hide: true
      },
      {
        headerName: self.$t('field.tag')
        , field: 'tag'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'tagEditor'
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('field.color')
        , field: 'color'
        , cellRenderer: 'colorCellRenderer'
        , cellEditor: 'colorEditor'
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('field.identifier_full')
        , field: 'identifier'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      }
    ]

    prepareCustomFieldColumnDef(colDefs, self.customFields, { self });

    const linkedEntities = [
        { selector: 'CUSTOMER.TAG', field: 'tag', properties: ['name'] }
      , { selector: 'CUSTOMER.LOCATION', field: 'locations', properties: ['name'] }
      , { selector: 'CUSTOMER.LOCATION', field: 'locationCountry', properties: ['countryCode'] }
      , { selector: 'CUSTOMER.CONTACT', field: 'contacts', properties: ['firstName', 'lastName'] }
      , { selector: 'CUSTOMER.PROJECT', field: 'projects', properties: ['name', 'CUSTOMER'] }
    ]

    //VIEW permission: Remove column from display list
    filterOutViewDenyProperties(colDefs, 'CUSTOMER', linkedEntities)

    if (self.isEntityEditable) {
      //EDIT permission: set column to be read only.
      setEditDenyPropertiesReadOnly(colDefs, 'CUSTOMER', linkedEntities)
    } else {
      for (let i = 0, len = colDefs.length; i < len; i++) {
        colDefs[i].editable = false;
      }
    }
    return colDefs
  }

  , getColorMenuOptions: () => ({
    none: true
    , customer: false
    , location: false
  })
  , getImportDataProperties: (self) => [
    { value: 'color', text: self.$t('field.color') }
    , { value: 'contact', text: self.$t('customer.field.contacts') }
    , { value: 'desc', text: self.$t('customer.field.description') }
    , { value: 'identifier', text: self.$t('field.identifier') }
    , { value: 'location', text: self.$t('customer.field.location') }
    , { value: 'name', text: self.$t('customer.field.name') }
    , { value: 'nickname', text: self.$t('customer.field.nickName') }
    , { value: 'socials', text: self.$t('customer.field.socials') }
    , { value: 'tag', text: self.$t('field.tag') }
    , { value: 'websites', text: self.$t('customer.field.websites') }
  ]
  , entityUpdateApiUrl: '/api/customer/update'
  , entityDeleteApiUrl: '/api/customer/delete'
  , getValueChangedHandler: (/** self */) => {
    return {
      projects: {
        isAsync: false
        , execute: (entityId, oldVal, newValue) => {
          const oldList = Array.isArray(oldVal)? oldVal.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const list = Array.isArray(newValue)? newValue.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const toAdd = []
          const toUpdate = []
          const unchangedIds = []

          for(const item of list) {
            const index = oldList.findIndex(j => j.uuId === item.uuId)
            if(index == -1) {
              toAdd.push(item)
            } else {
              unchangedIds.push(item.uuId)
            }
          }

          const toAddIds = toAdd.map(i => i.uuId)
          const toUpdateIds = toUpdate.map(i => i.uuId)
          const toRemove = oldList.filter(i => !toAddIds.includes(i.uuId) 
                                              && !toUpdateIds.includes(i.uuId) 
                                              && !unchangedIds.includes(i.uuId))
          const requests = []

          if(toAdd.length > 0) {
            const addProjectLinkReqTemplate = function(refId, projectId, customerList) {
              const list = [];
              customerList.forEach(i => {
                list.push( {
                  uuId: i
                });
              });
              return {
                method: 'POST',
                invoke: `/api/project/link/customer/add`,
                body: { 
                  uuId: projectId,
                  customerList: list
                },
                vars: [],
                note: `projectAddCustomerLink__${refId}__${customerList[0]}`
              }
            }
            for (const [index, project] of toAdd.entries()) {
              requests.push(addProjectLinkReqTemplate(`${index}_${project.uuId}`, project.uuId, [entityId]));
            }
          }

          if(toUpdate.length > 0) {
            const updateProjectLinkReqTemplate = function(refId, projectId, customerList) {
              const list = [];
              customerList.forEach(i => {
                list.push( {
                  uuId: i
                });
              });
              return {
                method: 'POST',
                invoke: `/api/project/link/customer/update`,
                body: { 
                  uuId: projectId,
                  customerList: list
                },
                vars: [],
                note: `projectUpdateCustomerLink__${refId}__${customerList[0]}`
              }
            }
            for (const [index, project] of toUpdate.entries()) {
              requests.push(updateProjectLinkReqTemplate(`${index}_${project.uuId}`, project.uuId, [entityId]));
            }
          }

          if(toRemove.length > 0) {
            const removeProjectLinkReqTemplate = function(refId, projectId, customerList) {
              const list = [];
              customerList.forEach(i => {
                list.push( {
                  uuId: i
                });
              });
              return {
                method: 'POST',
                invoke: `/api/project/link/customer/delete`,
                body: { 
                  uuId: projectId,
                  customerList: list
                },
                vars: [],
                note: `projectRemoveCustomerLink__${refId}__${customerList[0]}`
              }
            }
            for (const [index, project] of toRemove.entries()) {
              requests.push(removeProjectLinkReqTemplate(`${index}_${project.uuId}`, project.uuId, [entityId]));
            }
          }

          if (requests.length > 0) {
            return {
              value: requests
              , status: 'SUCCESS'
            }
          }

          return {
            value: oldVal
            , status: 'ABORT'
            , property: 'projects'
          }
        }
      }
      , locations: {
        isAsync: false
        , execute: (entityId, oldVal, newValue) => {
          const oldList = Array.isArray(oldVal)? oldVal.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const list = Array.isArray(newValue)? newValue.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const toAdd = []
          const toUpdate = []
          const unchangedIds = []

          for(const item of list) {
            const index = oldList.findIndex(j => j.uuId === item.uuId)
            if(index == -1) {
              toAdd.push(item)
            } else {
              unchangedIds.push(item.uuId)
            }
          }

          const toAddIds = toAdd.map(i => i.uuId)
          const toUpdateIds = toUpdate.map(i => i.uuId)
          const toRemove = oldList.filter(i => !toAddIds.includes(i.uuId) 
                                              && !toUpdateIds.includes(i.uuId) 
                                              && !unchangedIds.includes(i.uuId))
          const requests = []

          if(toAdd.length > 0) {
            const addLocationLinkReqTemplate = function(refId, customerId, locationList) {
              const list = [];
              locationList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/customer/link/location/add`,
                body: { 
                  uuId: customerId,
                  locationList: list
                },
                vars: [],
                note: `customerAddLocationLink__${refId}__${locationList[0].uuId}`
              }
            }
            for (const [index, location] of toAdd.entries()) {
              requests.push(addLocationLinkReqTemplate(`${index}_${entityId}`, entityId, [location]));
            }
          }

          if(toUpdate.length > 0) {
            const updateLocationLinkReqTemplate = function(refId, customerId, locationList) {
              const list = [];
              locationList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/customer/link/location/update`,
                body: { 
                  uuId: customerId,
                  locationList: list
                },
                vars: [],
                note: `customerUpdateLocationLink__${refId}__${locationList[0].uuId}`
              }
            }
            for (const [index, location] of toUpdate.entries()) {
              requests.push(updateLocationLinkReqTemplate(`${index}_${entityId}`, entityId, [location]));
            }
          }

          if(toRemove.length > 0) {
            const removeLocationLinkReqTemplate = function(refId, customerId, locationList) {
              const list = [];
              locationList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/customer/link/location/delete`,
                body: { 
                  uuId: customerId,
                  locationList: list
                },
                vars: [],
                note: `customerRemoveLocationLink__${refId}__${locationList[0].uuId}`
              }
            }
            for (const [index, location] of toRemove.entries()) {
              requests.push(removeLocationLinkReqTemplate(`${index}_${entityId}`, entityId, [location]));
            }
          }

          if (requests.length > 0) {
            return {
              value: requests
              , status: 'SUCCESS'
            }
          }

          return {
            value: oldVal
            , status: 'ABORT'
            , property: 'locations'
          }
        }
      }
      , socials: {
        isAsync: false
        , execute: (entityId, oldVal, newValue) => {
          return {
            value: Array.isArray(newValue)? newValue.map(i => { return { kind: i.kind, data: i.data }}) : []
            , status: 'SUCCESS'
            , isDataProp: true
          }
        }
        
        

      }
    }
  }
  , getPropertyDeleteHandler: (/** self */) => {
    return {
      tag: []
    }
  }
  , getPropertyCopyHandler: (self) => {
    let maxNameLength = 200
    let maxIdentifierLength = 200
    if (self.modelInfo != null) {
      let val = self.modelInfo.filter(info => info.field === 'name')
      if (val.length > 0) {
        maxNameLength = val[0].max
      }
      val = self.modelInfo.filter(info => info.field === 'identifier')
      if (val.length > 0) {
        maxIdentifierLength = val[0].max
      }
    } 

    //Expected format when return value is a function:
    //{ value, status } or
    //{ value, status, colId } when status is ABORT
    //Possible status: 'SUCCESS' | 'ABORT'
    //colId is optional but is useful for specifying a different colId as reset value.
    return {
      color: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.trim().length == 0) {
          value = null
        }
        return { value, status: 'SUCCESS' }
      }
      , name: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.length > maxNameLength) {
          value = srcValue.substring(0, maxNameLength)
        }
        return { value, status: 'SUCCESS' }
      }
      , identifier: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && value.length > maxIdentifierLength) {
          value = srcValue.substring(0, maxIdentifierLength)
        }
        return { value, status: 'SUCCESS' }
      }
    }
  }

  , getExportDataPropertyHandler: (self) => {
    const formatArray = (params) => {
      if (Array.isArray(params.value)) {
        return params.value.map(i => i.name).join(',')
      }
      return ''
    }
    
    
    return {
      projects: (params) => {
        return formatArray(params)
      }
      , companies: (params) => {
        return formatArray(params)
      }
      , socials: (params) => {
        if (Array.isArray(params.value)) {
          return params.value.filter(i => i.kind != null && i.data != null)
          .map(i => `${i.kind}: ${i.data}`).join(', ')
        }
        return ''
      }
      , websites: (params) => {
        if (Array.isArray(params.value)) {
          return params.value.join(', ')
        }
        return ''
      }
      , locations: (params) => {
        return formatArray(params)
      }
      , contacts: (params) => {
        if (Array.isArray(params.value)) {
          return params.value
          .map(i => {
            return `${self.$t('contact.field.firstName')}: ${i.firstName}, ${self.$t('contact.field.lastName')}: ${i.lastName ? i.lastName : ''}, ${self.$t('contact.field.position')}: ${i.position}, ${self.$t('contact.field.company')}: ${i.company ? i.company : ''}, ${self.$t('contact.field.phones')}: ${parsePot(i.phone)}, ${self.$t('contact.field.socials')}: ${parsePot(i.socials)}, ${self.$t('contact.field.emails')}: ${parseLot(i.emails)}, ${self.$t('contact.field.websites')}: ${parseLot(i.websites)}`
          }).join('|')
          
        }
        return ''
      }
    }
  }
  , lackOfMandatoryField: () => {
    return lackOfMandatoryField([{ entity: 'CUSTOMER', action: 'VIEW' }])
  }
  , getMandatoryFields() {
    return [
      'name'
    ]
  }
  , getBadgeFilterFields: (self) => {
    const fields = [
      { value: 'name', text: self.$t('customer.field.name') }
      , { value: 'color', text: self.$t('customer.field.color') }
      , { value: 'tagName', text: self.$t('field.tag') }
      , { value: 'identifier', text: self.$t('field.identifier') }
      , { value: 'nickName', text: self.$t('customer.field.nickName') }
      , { value: 'locationCountry', text: self.$t('customer.field.country') }
      , { value: 'locationName', text: self.$t('customer.field.location') }
      , { value: 'projectName', text: self.$t('customer.field.projects') }
      , { value: 'contactFirstName', text: self.$t('string.contact_firstname') }
      , { value: 'contactLastName', text: self.$t('string.contact_lastname') }
    ];
    if (Array.isArray(self.customFields) && self.customFields.length > 0) {
      for (const f of self.customFields) {
        if (f.type == 'String' || f.type == 'Enum<String>') {
          fields.push({ value: f.name, text: f.displayName });
        }
      }
    }
    fields.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }))
    return fields;
  }
  , getBadgeFilterOptionFetchFunc: (self) => {
    return (field) => {
      let f = field;
      if (f == 'tagName') {
        f = 'TAG.name'
      } else if (f == 'locationCountry') {
        f = "LOCATION.countryCode"
      } else if (f == 'locationName') {
        f = "LOCATION.name"
      } else if (f == 'projectName') {
        f = "PROJECT.name"
      } else if (f == 'contactFirstName') {
        f = 'CONTACT.firstName'
      } else if (f == 'contactLastName') {
        f = 'CONTACT.lastName'
      }
      return customerService.listUniqueValuesOfProperty(f)
      .then(data => {
        if (data.length > 0 && self.badgeFilters != null && self.badgeFilters.length > 0) {
          const found = self.badgeFilters.find(i => i.field == field)
          if (found != null && Array.isArray(found.value) && found.value.length > 0) {
            if (field == 'locationCountry') {
              //Additional property 'value' is added to keep the original value.
              const list = [];
              for (const d of data) {
                let text = d
                if (!d) {
                  text = '(Empty)'
                } else {
                  const found = countryCodes.find(j => j.value === d);
                  text = found != null? found.text : '(Empty)'
                }
                const value = d ? d : null
                list.push({
                  text
                  , value
                  , checked: found.value.find(j => j.value != null && j.value == value) != null
                })
              }
              if (list.find(i => i.text == '(Empty)') == null) {
                list.unshift({ text: '(Empty)', value: null, checked: false })
              }
              return list
            }

            //Normal handling
            const rList = data.map(i => ({ 
              text: !i ? '(Empty)' : i
              , checked: found.value.find(j => j.text != null 
                                          && (typeof j.text === 'string' && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) 
                                              || j.text == i) != null
            }))
            if (rList.find(i => i.text == '(Empty)') == null) {
              rList.unshift({ text: '(Empty)', checked: false })
            }
            return rList;
          }
        }

        if (field == 'locationCountry') {
          //Additional property 'value' is added to keep the original value.
          let list = []
          for (const d of data) {
            let text = d
            if (!d) {
              text = '(Empty)'
            } else {
              const found = countryCodes.find(j => j.value === d);
              text = found != null? found.text : '(Empty)'
            }
            const value = d ? d : null
            list.push({
              text
              , value
              , checked: false
            })
          }
          if (list.find(i => i.text == '(Empty)') == null) {
            list.unshift({ text: '(Empty)', value: null, checked: false })
          }
          return list
        }
        
        //Normal handling
        const rList = data.map(i => ({ text: !i ? '(Empty)' : i, checked: false }))
        if (rList.find(i => i.text == '(Empty)') == null) {
          rList.unshift({ text: '(Empty)', checked: false })
        }
        return rList;
      });
    }
  }
  , prepareDataForOkEmit: (rowNodes) => {
    if (Array.isArray(rowNodes)) {
      return rowNodes.map(i => ({
        uuId: i.data.uuId
        , name: i.data.name
        , color: i.data.color
      }));
    } 
    return []
  }
}