<template>
  <a :class="classList" v-bind="attributes" tabindex="-1" v-on:click.stop.prevent v-if="isDisabled">
    <font-awesome-icon :icon="icon" class="nav-icon"/> <span>{{name}}</span>
    
    <!-- <i :class="classIcon"></i> <span>{{name}}</span> -->
    <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
  </a>
  <a :href="url" :class="classList" v-bind="attributes" v-else-if="isExternalLink">
    <font-awesome-icon :icon="icon" class="nav-icon"/> <span>{{name}}</span>
    <!-- <i :class="classIcon"></i> {{name}} -->
    <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
  </a>
  <a @click.stop="handleClick(emitevent, params)" @mouseleave="onMenuLeave" :class="classList" v-bind="attributes" v-else-if="emitevent">
    <font-awesome-icon v-if="icon !== ''" :icon="icon" class="nav-icon"/> <span :id="`foldername${this.id}`">{{name}}</span>
    <b-popover
      v-if="isEllipsisActive()"
      :target="`foldername${this.id}`"
      placement="right"
      boundary="viewport"
      custom-class="popover-margin"
      triggers="hover"
      offset="-100"
      :content="name">
    </b-popover> 
    <!-- <i :class="classIcon"></i> {{name}} -->
    <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
    <span class="action-container-class">
      <div class="action-class" v-for="(action, index) in actions" v-bind:key="index">
        <div v-if="action.emitevent" @click.stop="handleActionClick($event, action)">
          <font-awesome-icon v-if="!action.permission || hasPermission(action.permission)" :id="`ACTION_${action.id}`" class="text-white" :icon="action.icon"/>
          <b-popover
            :target="`ACTION_${action.id}`"
            placement="top"
            triggers="hover"
            :content="$t(action.name)">
          </b-popover>
        </div>
        <b-dropdown variant="link" ref="menu" class="pb-1 action-menu" no-caret v-else-if="!action.permission || hasPermission(action.permission)">
          <template slot="button-content">
            <div class="text">
              <font-awesome-icon :icon="action.icon"/>
            </div>
          </template>
      
          <template v-for="(item, itemindex) in action.items">
            <b-dropdown-item v-if="!action.url || !action.url.startsWith('blob')" v-bind:key="itemindex" @click.stop="handleActionClick($event, item)">{{ $t(item.name) }}</b-dropdown-item>
            <b-dropdown-item v-bind:key="itemindex" :download="item.params.download" :href="action.url" v-else>{{ $t(item.name) }}</b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
    </span>
  </a>
  <div @mouseleave="onMenuLeave" v-else>
    <router-link v-if="url" :id="id" :to="url" :class="classList" v-bind="attributes" exact>
      <font-awesome-icon v-if="icon !== ''" :icon="icon" class="nav-icon"/> <span :id="`name${this.id}`">{{name}}</span>
      <b-popover
        v-if="isEllipsisActive()"
        :target="`${this.id}`"
        placement="right"
        boundary="viewport"
        custom-class="popover-margin"
        triggers="hover"
        offset="-100"
        :content="name">
      </b-popover> 
      <!-- <i :class="classIcon"></i> {{name}} -->
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
      <span class="action-container-class">
        <div class="action-class" v-for="(action, index) in actions" v-bind:key="index">
          <div v-if="action.emitevent" @click.stop="handleActionClick($event, action)">
            <font-awesome-icon v-if="!action.permission || hasPermission(action.permission)" :id="`ACTION_${action.id}`" class="text-white" :icon="action.icon"/>
            <b-popover
              :target="`ACTION_${action.id}`"
              placement="right"
              triggers="hover"
              :content="$t(action.name)">
            </b-popover>
          </div>
          <b-dropdown variant="link" ref="menu" class="action-menu" no-caret v-else-if="!action.permission || hasPermission(action.permission)">
            <template slot="button-content">
              <div class="text">
                <font-awesome-icon :icon="action.icon"/>
              </div>
            </template>
        
            <template v-for="(item, itemindex) in action.items">
              <b-dropdown-item v-if="(!item.url || !item.url.startsWith('blob')) && (!item.permission || hasPermission(item.permission))" v-bind:key="itemindex" @click.stop="handleActionClick($event, item)">{{ $t(item.name) }}</b-dropdown-item>
              <b-dropdown-item v-bind:key="itemindex" :download="item.params.download" :href="item.url" @click.stop="preventBubble" v-else-if="!item.permission || hasPermission(item.permission)">{{ $t(item.name) }}</b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
      </span>
    </router-link>
    <div :id="id" :class="classList" v-bind="attributes" v-else>
      <font-awesome-icon v-if="icon !== ''" :icon="icon" class="nav-icon"/> <span :id="`name${this.id}`">{{name}}</span>
      <b-popover
        v-if="isEllipsisActive() || hasPopup"
        :target="`${this.id}`"
        placement="right"
        boundary="viewport"
        custom-class="popover-margin"
        triggers="hover"
        offset="-100"
        :content="popupContent">
      </b-popover> 
      <!-- <i :class="classIcon"></i> {{name}} -->
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
      <span class="action-container-class">
        <div class="action-class" v-for="(action, index) in actions" v-bind:key="index">
          <div v-if="action.emitevent" @click.stop="handleActionClick($event, action)">
            <font-awesome-icon v-if="!action.permission || hasPermission(action.permission)" :id="`ACTION_${action.id}`" class="text-white" :icon="action.icon"/>
            <b-popover
              :target="`ACTION_${action.id}`"
              placement="top"
              boundary="viewport"
              triggers="hover"
              :content="$t(action.name)">
            </b-popover>
          </div>
          <b-dropdown variant="link" ref="menu" class="action-menu" no-caret v-else-if="!action.permission || hasPermission(action.permission)">
            <template slot="button-content">
              <div class="text">
                <font-awesome-icon :icon="action.icon"/>
              </div>
            </template>
        
            <template v-for="(item, itemindex) in action.items">
              <b-dropdown-item v-if="(!item.url || !item.url.startsWith('blob')) && (!item.permission || hasPermission(item.permission))" v-bind:key="itemindex" @click.stop="handleActionClick($event, item)">{{ $t(item.name) }}</b-dropdown-item>
              <b-dropdown-item v-bind:key="itemindex" :download="item.params.download" :href="item.url" @click.stop="preventBubble" v-else-if="!item.permission || hasPermission(item.permission)">{{ $t(item.name) }}</b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/helpers';

export default {
  name: 'sidebar-nav-link',
  props: {
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    icon: {
      type: [String, Array],
      default: ''
    },
    badge: {
      type: Object,
      default: () => {}
    },
    variant: {
      type: String,
      default: ''
    },
    classes: {
      type: [String, Array, Object],
      default: ''
    },
    attributes: {
      type: Object,
      default: () => { return Object.create(null) }
    },
    actions: {
      type: Array,
      default: () => { return [] }
    },
    emitevent: {
      type: String,
      default: null
    },
    params: {
      type: Object,
      default: () => { return Object.create(null) }
    }
  },
  data() {
    return {
      id: this.attributes && this.attributes.id ? this.attributes.id : Math.random().toString(36).substr(2, 9)
    }
  },
  computed: {
    classList () {
      return [
        'nav-link',
        this.linkVariant,
        ...this.disabledClasses,
        ...this.attrClasses,
        ...this.itemClasses
      ]
    },
    // classIcon () {
    //   return [
    //     'nav-icon',
    //     this.icon
    //   ]
    // },
    linkVariant () {
      return this.variant ? `nav-link-${this.variant}` : ''
    },
    hasPopup() {
      return this.attributes.popup;
    },
    popupContent() {
      if (this.attributes.popup) {
        return this.attributes.popupContent();
      }
      return this.name;
    },
    itemClasses () {
      return this.getClassArray(this.classes)
    },
    attrClasses () {
      return this.getClassArray(this.attributes.class)
    },
    disabledClasses () {
      return this.isDisabled ? 'disabled'.split(' ') : []
    },
    isDisabled () {
      return Boolean(this.attributes.disabled)
    },
    isExternalLink () {
      return Boolean(this.url.substring(0, 4) === 'http')
    }
  },
  methods: {
    onMenuLeave() {
      if (this.$refs.menu &&
          this.$refs.menu.length > 0) {
        this.$refs.menu[0].hide();
      }
    },
    getClassArray(classes) {
      return !classes ? [] : typeof classes === 'string' || classes instanceof String ? classes.split(' ') : Array.isArray(classes) ? classes : Object.keys(classes).filter(i=>classes[i])
    },
    handleClick(event, params) {
      EventBus.$emit(event, params);
    },
    getTextWidth(text, font) {
      // re-use canvas object for better performance
      const canvas = this.canvas || (this.canvas = document.createElement("canvas"));
      const context = canvas.getContext("2d");
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    },
    getCssStyle(element, prop) {
      return window.getComputedStyle(element, null).getPropertyValue(prop);
    },
    getCanvasFont(el = document.body) {
      const fontWeight = this.getCssStyle(el, 'font-weight') || 'normal';
      const fontSize = this.getCssStyle(el, 'font-size') || '16px';
      const fontFamily = this.getCssStyle(el, 'font-family') || 'Times New Roman';
      
      return `${fontWeight} ${fontSize} ${fontFamily}`;
    },
    isEllipsisActive() {
      const e = this.getTextWidth(this.name, this.getCanvasFont(this.$el));

      return e > 115;
    },
    handleActionClick(e, action) {
      const current = this.$router.currentRoute;
      if (current !== this.url &&
          action.url !== null) {
        this.$router.push(action.url);
      }
      
      if (action.emitevent) {
        EventBus.$emit(action.emitevent, action.params);
      }
      e.stopPropagation();
      e.preventDefault();
    },
    preventBubble(e) {
     e.stopPropagation();
    }
  }
}
</script>
<style>
.popover-margin {
  margin: 0;
}

  .action-container-class {
    position: absolute;
    right: 3px;
    display: inline-block;
  }
  
  .action-class {
    position: relative;
    cursor: pointer;
    min-width: 12px;
    margin-right: 5px;
    display: inline-block;
    opacity: 0;
    text-align: center;
  }
  
  .sidebar .nav-link:hover .action-class,
  .action-container-class:hover .action-class,
  .action-container-class:focus .action-class,
  .action-class:hover,
  .action-class:focus {
  
    opacity: 1;
  }
  
  .router-link-exact-active.active {
    position: unset;
  }
</style>
