
import { httpAjax } from '@/helpers';

export const bookingLinkRebateService = {
  create,
  remove,
};

/**
 * Create a new Link from booking to rebate 
 * by passing necessary information
 * @param String bookingId, Task's UUID
 * @param Array rebates, Rebate details. Data sample: [{ uuId, level }]
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', rebateList: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', rebateLink: {level: 'Junior'} }] }
 */
function create(bookingId, rebates) {
  const url = '/api/booking/link/rebate/add';
  const config = {
    headers: getHeaders()
  };

  const rebateList = []
  rebates.forEach(i => {
    rebateList.push( {
      uuId: i.uuId
    });
  });

  const data = {
    uuId: bookingId,
    rebateList: rebateList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from booking to rebate.
 * by passing in an array list of links uuIds
 * @param String bookingId, Task's UUID
 * @param Array rebateIds, list of Rebate's UUID 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', rebateList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }
 */
function remove(bookingId, rebates) {
  const url = '/api/booking/link/rebate/delete';
  const config = {
    headers: getHeaders()
  };

  const rebateList = []
  rebates.forEach(i => {
    rebateList.push( {
      uuId: i.uuId
    });
  });

  const data = {
    uuId: bookingId,
    rebateList: rebateList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}