<template>
  <div class="image-container" :class="isPreview ? 'img-preview-container' : ''" v-if="avatarUrl">
    <img class="image" :class="isPreview ? 'img-contain' : ''" width="120" :height="isPreview ? 25 : 120" :src="avatarUrl"/>
  </div>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'ImageCellRenderer',
  data() {
    return {}
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
  },
  computed: {
    avatarUrl() {
      return this.params && this.params.value && this.params.value !== "00000000-0000-0000-0000-000000000000" ? (this.params.value.startsWith('data:image/') ? this.params.value : `${process.env.BASE_URL}api/file/${this.params.value}`) : null;
    },
    isPreview() {
      return this.params && this.params.value && (this.params.isPreview || this.params.value.startsWith('data:image/'));
    }
  }
});
</script>

<style>
.image {
  object-fit: scale-down;
  width: 100%;
}

.image-container {
  margin: 0 auto;
}

.img-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img-preview-container {
  height: 26px;
}
</style>