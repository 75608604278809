import { profileService } from '@/services';

const categoryKey = 'kanban';
const folderKey = 'project';

export const kanbanProfileService = {
  create,
  list,
  update,
  remove,
};

/**
 * Create a new kanbanProfile 
 * by passing necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function create(data, projectId) {
  return profileService.create(data, categoryKey, folderKey, projectId);
}

/**
 * Retrieving a concise list of dashboardProfile info
 */
function list(projectId) {
  return profileService.list(categoryKey, folderKey, projectId);
}

/**
 * Update dashboardProfile details 
 * by passing  necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function update(data, projectId) {
  return profileService.update(data, categoryKey, folderKey, projectId);
}

/**
 * Delete a list of dashboardProfiles
 * by passing in an array list of uuIds
 * @param {Array} data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data, projectId) {
  return profileService.remove(data, categoryKey, folderKey, projectId);
}