<template>
  <div class="app">
    <AppHeader fixed :class="headerClass">
      <SidebarToggler class="d-lg-none" display="md" mobile :defaultOpen="false" />
      <router-link class="navbar-brand" to="/dashboard">
        <span class="navbar-brand-full" width="178" height="50" alt="Projectal Logo"></span>
        <img class="navbar-brand-minimized" :src="`${baseUrl}img/brand/logo-icon.svg`" width="45" height="30" alt="Projectal Logo">
      </router-link>
      <b-navbar-nav>
        <slot name="headerCompany"/>
      </b-navbar-nav>
      <b-navbar-nav class="mr-1">
        <slot name="headerDropdownAccount"/>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :key="currentPathId()" :navItems="allowedNavList"/>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list" :url-params="urlParams" :selectedState="selectedState" style="position:fixed;width: 100%;z-index: 11" @sandbox="selectSandboxModalOk" @epoch="selectEpochModalOk"/>
        <div class="container-fluid page-container">
          <slot></slot>
        </div>
      </main>
    </div>
    
    <DataviewModal :show.sync="addDataviewShow" :data="data" :userId="userId" :folders="dataviewParams.isPublic ? publicFolders : privateFolders" :folderUuid="dataviewParams.isPublic ? publicGroup : group" :isPublic="dataviewParams.isPublic" @success="dataviewCreated"/>
        
    <b-modal id="dataview-to-import-modal"
        no-close-on-backdrop
        content-class="shadow"
        size="md"
        v-model="importDataviewShow"
      >
      <div slot="modal-title" class="w-100" v-html="$t('dataview.import')"></div>
      <b-form-group id="field-file" :label="$t('task.import.file')" label-for="file-import">
        <b-form-file
          
          id="file-import"
          v-model="file"
          :state="Boolean(file)"
          :placeholder="$t('task.import.choose')"
          drop-placeholder="Drop file here..."
          :browse-text="$t('task.import.browse')"
        ></b-form-file>
      </b-form-group>
      
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" :disabled="file === null" @click="importModalOk">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    
    <SandboxModal :show.sync="createSandboxShow" @success="createSandboxModalOk"/>
    
    <EpochSelectorModal :show.sync="selectEpochShow" @success="selectEpochModalOk"/>
        
    <SandboxSelectorModalForAdmin :show.sync="manageSandboxShow"/>
    
    <DataviewFolderModal :id="folderUuid" :folderName="folderName" :title="folderUuid !== null && folderUuid.indexOf('DATAVIEW_FOLDER_NEW_') !== 0 ? $t('dataview.edit_folder') : $t('dataview.create_folder')" :parentData="folderParentData" :visibility="dataviewParams.isPublic ? 'public' : 'private'" :show.sync="createFolderDataviewShow" @success="createFolderModalOk"/>
    
    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="deleteFolderDataviewShow"
        :ok-title="$t('button.confirm')"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="deleteFolderModalOk"
        >
      <div class="d-block">
        {{ $t('dataview.confirmation.delete_folder') }}
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="deleteFolderModalOk">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="deleteDataviewShow"
        :ok-title="$t('button.confirm')"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="deleteDataviewModalOk"
        >
      <div class="d-block">
        {{ $t('dataview.confirmation.delete') }}
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="deleteDataviewModalOk">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <DataviewFolderSelectorModal :title="dataviewParams.isPublic ? $t('dataview.public_dataview_folders') : $t('dataview.dataview_folders')"
        :show.sync="dataviewFolderSelectorShow"
        :visibility="dataviewParams.isPublic ? 'public' : 'private'"
        :mode="'MANAGE'"
        />
        
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import nav from '@/_nav';
import { Header as AppHeader } from '@coreui/vue';
import Breadcrumb from '@/components/Breadcrumb'
import { Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, SidebarToggler } from '@/components/Sidebar'
import { EventBus, strRandom } from '@/helpers';
import { dataviewProfileService, companyService } from '@/services';

export default {
  name: 'HeaderSidebarContainer',
  components: {
    AppHeader,
    AppSidebar,
    Breadcrumb,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    DataviewModal: () => import('@/components/modal/DataviewModal'),
    DataviewFolderModal: () => import('@/components/modal/DataviewFolderModal'),
    DataviewFolderSelectorModal: () => import('@/components/modal/DataviewFolderSelectorModal'),
    SandboxModal: () => import('@/components/modal/SandboxModal'),
    EpochSelectorModal: () => import('@/components/modal/EpochSelectorModal'),
    SandboxSelectorModalForAdmin: () => import('@/components/modal/SandboxSelectorModalForAdmin')
  },
  data: function() {
    return {
      baseUrl: process.env.BASE_URL,
      permissionReady: false,
      whitelistedNavItems: ['DASHBOARD', 'DATAVIEW'],
      dynamicNav: [],
      refreshKey: 0,
      addDataviewShow: false,
      userId: null,
      dataviewParams: {
        isPublic: false
      },
      importDataviewShow: false,
      deleteDataviewShow: false,
      file: null,                 // File object to hold user selected file.
      data: null,
      createFolderDataviewShow: false,
      deleteFolderDataviewShow: false,
      dataviewFolderSelectorShow: false,
      folderName: null,
      folderUuid: null,
      folderParentData: {
        name: null,
        uuId: null
      },
      group: null,
      publicGroup: null,
      groupHistory: [],
      publicGroupHistory: [],
      privateFolders: {},
      publicFolders: {},
      privateDataviews: [],
      publicDataviews: [],
      createSandboxShow: false,
      selectEpochShow: false,
      manageSandboxShow: false,
      selectedState: { name: 'live', icon: 'wave-pulse' }
    }
  },
  watch: {
    $route: function(to, from) {
      this.onCreated();
    }
  },
  computed: {
    name () {
      return this.$route.name;
    },
    list () {
      return this.$route.matched.filter((route) => (route.name || route.meta && route.meta.label != null) && !route.path.match(/.*\/list$/g));
    },
    urlParams () {
      return this.$route.params;
    },
    allowedNavList () {
      // just mentioning refreshKey
      this.refreshKey;
      const userPermissions = this.$store.state.authentication.user.permissionList || [];
      return this.filterNavItems(nav.items.concat(this.dynamicNav), userPermissions);
    },
    headerClass() {
      if (this.$store.state.epoch.value) {
        return 'epoch-active';
      }
      return '';
    }
  },
  methods: {
    currentPathId () {
      const path = this.$router.currentRoute.path;
      const reg = new RegExp('^\\/.+?\\/([0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12})$', 'i');
      const matched = path.match(reg);
      return matched? matched[matched.length-1] : null;
    },
    onCreated() {
      this.dynamicNav = [];
      this.userId = this.$store.state.authentication.user.uuId;
      this.$store.dispatch('data/status').then(value => {
          document.title = `Projectal - ${value.companyName}`;
        })
        .catch(e => {
          document.title = 'Projectal';
        });
  
      const self = this;
      this.$nextTick(() => {
        for (var item of nav.dynamicItems) {
          if (typeof item.childrenFunc === 'function') {
            const navItem = item;
            item.childrenFunc(function(children, visibility) {
              if (visibility === 'private') {
                self.privateDataviews = children;
              }
              else {
                self.publicDataviews = children;
              }
              
              const group = visibility === 'private' ? self.group : self.publicGroup;
              navItem.children = children.filter(c => (group === null && !c.parent) || c.parent === group);
              if (group !== null) {
                const prevGroup = visibility === 'private' ? self.groupHistory[self.groupHistory.length - 1] : self.publicGroupHistory[self.publicGroupHistory.length - 1]
                navItem.children.unshift({ key: 'DATAVIEW', no_translate: true, name: '..', icon: ['far','folder'], params: {goup: true, group: prevGroup}, emitevent: visibility === 'private' ? 'dataview-set-group' : 'dataview-public-set-group' });
              }
              
              if (visibility === 'private') {
                self.privateFolders = children.reduce(function(map, obj) {
                    map[obj.uuId] = obj.name;
                    return map;
                }, {});
              }
              else {
                self.publicFolders = children.reduce(function(map, obj) {
                    map[obj.uuId] = obj.name;
                    return map;
                }, {});
              }
              self.dynamicNav.splice(navItem.order, 0, navItem);
            });
          }
        }
      });
      
    },
    dataviewCreated(result) {
      const data = result.data;
      if (data.clue === 'Created') {
        if (result.visibility.value === 'private') {
          this.$router.push(`/dataview/${data.uuId}`);
        }
        else {
          this.$router.push(`/dataview/public/${data.uuId}`);
        }
      }
      else {
        // updated data view, reload the menu in case the name changed
        EventBus.$emit('dataview-reload');
        
        // if this is the currently displayed data view
        if (this.currentPathId() === result.uuId) {
          EventBus.$emit('reload-current');
        }
      }
    },
    hasMultipleKeys(allowedViewPermissions, key) {
      const keys = key.split('|');
      for (const k of keys) {
        if (allowedViewPermissions.includes(k)) {
          return true;
        }
      }
      return false;
    },
    filterNavItems(navItems, userPermissions) {
      const allowedViewPermissions = userPermissions.filter(p => p.name.includes('VIEW') || p.name.includes('ADMIN__APPLICATION_MANAGEMENT')).map(p => {
        return p.name.substring(0, p.name.indexOf('__'));
      });
      if (userPermissions.find(p => p.name.includes('ADMIN__PLANNER_STAFF'))) {
        allowedViewPermissions.push('PLANNER_STAFF');
      }
      if (userPermissions.find(p => p.name.includes('ADMIN__PLANNER_RESOURCE'))) {
        allowedViewPermissions.push('PLANNER_RESOURCE');
      }
      const allowedNavItems = navItems.filter(i => (!i.key || allowedViewPermissions.includes(i.key) || this.hasMultipleKeys(allowedViewPermissions, i.key) || this.whitelistedNavItems.includes(i.key)));
      const planner = allowedNavItems.find(f => f.key === "PLANNER_STAFF|PLANNER_RESOURCE");
      if (planner) {
        if (!userPermissions.find(p => p.name === 'ADMIN__PLANNER_STAFF')) {
          planner.url = '/planner/resources';  // direct the user to the resource planner if no permission for staff planner
        }
        else {
          planner.url = '/planner/';
        }
      }
      return allowedNavItems;
    },
    async importModalOk() {
      const text = await this.file.text();
      this.data = JSON.parse(text);
      this.data.parent = this.group;
      this.importDataviewShow = false;
      this.addDataviewShow = true;
    },
    async deleteDataviewModalOk() {
      await dataviewProfileService.remove([{ uuId: this.dataviewParams.uuId }],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.onCreated();
        return data[0];
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return null;
      });
      this.deleteDataviewShow = false;
      if (location.pathname.endsWith(this.dataviewParams.uuId)) {
        this.$router.push('/dashboard');
      }
      EventBus.$emit('folder-created');
    },
    async getCompany() {
      const company = await companyService.list({limit: -1, start: 0}).then((response) => {
        const data = response.data;
        return data.filter(d => d.type === 'Primary')[0];
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
        return null;
      });
      return company;
    },
    async shareDataviewProfile(uuId, shareToId, isPublic = false) {
        await dataviewProfileService.share(uuId, shareToId, isPublic).then((response) => {  
        const data = response.data[response.data.jobCase];
        return data;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
    },
    createSandboxModalOk({name}) {
      this.selectedState = { name: this.$t('sandbox_name', [name]), icon: 'code-branch' }; 
    },
    selectSandboxModalOk({ name }) {
      
      this.selectedState = { name: this.$t('sandbox_name', [name]), icon: 'code-branch' }; 
      
      // force the view to update
      EventBus.$emit('language-change');
    },
    selectEpochModalOk({epoch, name}) {
      
      this.selectedState = { name: this.$t('epoch_date', [name]), icon: 'clock-rotate-left' };  
      
      // force the view to update
      EventBus.$emit('language-change');
    },
    async createFolderModalOk({folderUuid, folderName, parentUuid, parentName}) {
      // update
      if (folderUuid) {
        await dataviewProfileService.update([{ uuId: folderUuid, name: folderName, type: 'folder', sharedVisibility: this.dataviewParams.isPublic ? 'public' : 'private', parent: parentUuid }],
                          this.userId).then((response) => {  
          const data = response.data[response.data.jobCase];
          return data[0];
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
          return null;
        });
      }
      else { // create
        const uuId = await dataviewProfileService.create([{ name: folderName, type: 'folder', parent: parentUuid, sharedVisibility: this.dataviewParams.isPublic ? 'public' : 'private' }],
                          this.userId).then((response) => {  
          const data = response.data[response.data.jobCase];
          return data[0].uuId;
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
          return null;
        });
        
        const company = await this.getCompany();
        if (this.dataviewParams.isPublic) {
          await this.shareDataviewProfile(uuId, company.uuId, true);
        }
      }
      this.createFolderDataviewShow = false;
      // updated data view, reload the menu in case the name changed
      setTimeout(() => {
        const userId = this.$store.state.authentication.user.uuId;
        this.$store.dispatch('data/clearDataViewProfileCache', userId, localStorage.companyId);
   
        EventBus.$emit('dataview-reload');
      }, 100);
      EventBus.$emit('folder-created');
    },
    async deleteFolderModalOk() {
      const folderUuid = this.dataviewParams.uuId;
      let dataviews = this.dataviewParams.isPublic ? 
        this.publicDataviews : 
        this.privateDataviews;
      const folders = dataviews.reduce(function(map, obj) {
          map[obj.uuId] = obj;
          return map;
      }, {});
      dataviews = dataviews.filter(d => this.getDataPath(d, folders).includes(folderUuid));
      
      // delete the contents
      for (const dv of dataviews) {
        await this.deleteDataviewProfile(dv.uuId);
      }
      this.deleteFolderDataviewShow = false;
      EventBus.$emit('folder-created');
    },
    async deleteDataviewProfile(uuId) {
      await dataviewProfileService.remove([{ uuId: uuId }],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.onCreated();
        return data[0];
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return null;
      });
    },
    getDataPath(data, folders) {
      const ret = [data.uuId];
      let parent = data.parent;
      while (parent) {
        ret.unshift(parent);
        parent = folders[parent] ? folders[parent].parent : null;
      }
      return ret;
    }
  },
  created() {
    this.onCreated();
    const self = this;
    
    EventBus.$on('dataview-add', (payload) => {
      self.data = null;
      self.addDataviewShow = true;
      self.dataviewParams = payload;
    });
    
    EventBus.$on('dataview-edit', (payload) => {
      self.data = payload.profile;
      self.addDataviewShow = true;
      self.dataviewParams = payload;
    });
    
    EventBus.$on('dataview-delete', (payload) => {
      self.data = payload.profile;
      self.deleteDataviewShow = true;
      self.dataviewParams = payload;
    });
    
    EventBus.$on('dataview-copy', (payload) => {
      self.data = cloneDeep(payload.profile);
      delete self.data['uuId'];
      self.data.name = `${self.data.name} ${this.$t('dataview.copy_text')}`;
      if (self.data.sharedVisibility === 'public') {
        delete self.data.parent;
      }
      self.data.sharedVisibility = 'private';
      const userId = this.$store.state.authentication.user.uuId;
      self.data.sharingMembers = userId;
      self.data.editingPermissions = userId;
      self.addDataviewShow = true;
      self.dataviewParams = payload;
    });
    
    EventBus.$on('dataview-import', (payload) => {
      self.importDataviewShow = true;
      self.dataviewParams = payload;
    });
    
    EventBus.$on('sandbox-add', (payload) => {
      self.createSandboxShow = true;
    });
    
    EventBus.$on('sandbox-added', (name) => {
      this.selectedState = { name: name, icon: 'code-branch' };
    });
    
    EventBus.$on('sandbox-name', (name) => {
      this.selectedState = { name: name, icon: 'code-branch' };
    });
    
    EventBus.$on('epoch-clear', (payload) => {
      this.selectedState = { name: 'live', icon: 'wave-pulse' };
      this.$store.state.sandbox.value = null; // clear the sandbox if set
      this.$store.state.sandbox.name = null;
      this.$store.state.epoch.value = null; // clear the epoch if set
 
      // force the view to update
      EventBus.$emit('language-change');
    });
    
    EventBus.$on('epoch-select', (payload) => {
      self.selectEpochShow = true;
    });
    
    EventBus.$on('sandbox-manage', (payload) => {
      self.manageSandboxShow = true;
    });
    
    EventBus.$on('dataview-create-folder', (payload) => {
      self.folderName = null;
      self.folderUuid = `DATAVIEW_FOLDER_NEW_${strRandom(5)}`;
      let parentFolder = payload.isPublic ? self.publicGroup : self.group;
      if (parentFolder) {
        self.folderParentData = { 
          uuId: parentFolder, 
          name: !payload.isPublic ? 
            self.privateFolders[parentFolder] :
            self.publicFolders[parentFolder]
        };
      }
      else {
        self.folderParentData = {
          uuId: null,
          name: null
        }
      }

      self.createFolderDataviewShow = true;
      self.dataviewParams = payload;
    });
    
    EventBus.$on('dataview-edit-folder', (payload) => {
      self.folderName = payload.profile.name;
      self.folderUuid = payload.profile.uuId;
      const visibility = payload.profile.sharedVisibility;
      const parent = payload.profile.parent;
      if (parent) {
        self.folderParentData = { 
          uuId: payload.profile.parent, 
          name: visibility === 'private' ? 
            self.privateFolders[parent] :
            self.publicFolders[parent]
        };
      }
      else {
        self.folderParentData = {
          name: null,
          uuId: null
        };
      }
      self.dataviewParams = payload;
      self.createFolderDataviewShow = true;
    });
    
    EventBus.$on('dataview-delete-folder', (payload) => {
      self.folderName = null;
      self.deleteFolderDataviewShow = true;
      self.dataviewParams = payload;
    });
    
    EventBus.$on('dataview-manage-folders', (payload) => {
      self.dataviewParams = payload;
      self.dataviewFolderSelectorShow = true;
    });
    
    EventBus.$on('dataview-reload', (payload) => {
        const userId = this.$store.state.authentication.user.uuId;
        this.$store.dispatch('data/clearDataViewProfileCache', userId, localStorage.companyId);
   
      self.onCreated();
    });
    
    EventBus.$on('dataview-set-group', (payload) => {
      if (payload.goup) {
        self.groupHistory.pop();
      }
      else {
        self.groupHistory.push(self.group);
      }
      self.group = payload.group;
      self.onCreated();
    });
    
    EventBus.$on('dataview-public-set-group', (payload) => {
      if (payload.goup) {
        self.publicGroupHistory.pop();
      }
      else {
        self.publicGroupHistory.push(self.publicGroup);
      }
      self.publicGroup = payload.group;
      self.onCreated();
    });
    
    // Go to the top level folder
    EventBus.$on('dataview-top-group', (payload) => {
      self.groupHistory = [];
      self.group = null;
      self.onCreated();
    });
    
    // Go to the top level folder
    EventBus.$on('dataview-public-top-group', (payload) => {
      self.publicGroupHistory = [];
      self.publicGroup = null;
      self.onCreated();
    });
  },
  brforeDestroy() {
    EventBus.$off('dataview-add')
    EventBus.$off('dataview-edit')
    EventBus.$off('dataview-delete')
    EventBus.$off('dataview-copy')
    EventBus.$off('dataview-import')
    EventBus.$off('dataview-create-folder')
    EventBus.$off('dataview-edit-folder')
    EventBus.$off('dataview-delete-folder')
    EventBus.$off('dataview-manage-folders')
    EventBus.$off('dataview-reload')
    EventBus.$off('dataview-set-group')
    EventBus.$off('dataview-public-set-group')
    EventBus.$off('dataview-top-group')
    EventBus.$off('dataview-public-top-group')
  }

  
}
</script>
<style scoped>
.main {
  overflow-y: hidden;
}
.epoch-active {
  background: var(--timemachine-disabled-bg);
}
</style>

