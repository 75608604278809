import { httpAjax } from '@/helpers';
import { urlParams } from '../helpers';

export const bookingService = {
  create,
  list,
  get,
  update,
  remove,
  clone
};

/**
 * Create a new booking 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', name: 'Paramount' ... }]
 */
function create(data) {
  let url = `/api/booking/add`;

  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Clone a task 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', name: 'Paramount' ... }]
 */
function clone(data, ref) {
  let url = `/api/booking/clone?reference=${ref}`;

  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of booking info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'Paramount', ksort='name', order: 'incr' }}
 */
function list(params) {
  const fields = {
    uuId: 'BOOKING.uuId', 
    name: 'BOOKING.name',
    startTime: 'BOOKING.beginDate',
    endTime: 'BOOKING.untilDate',
    duration: 'BOOKING.duration',
    staffUuid: 'BOOKING.STAFF.uuId',
    color: 'BOOKING.color'
  }
  

  let data = {
    'name'  : 'Task List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'BOOKING'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if(params.filter && params.filter.length > 0) {
  if (typeof params.filter === 'string') {
      data['filter'] = [
        '_or_', [
          ['STAFF.BOOKING.name', 'regex', params.filter]
        ]
      ]
  }
  else {
    data['filter'] = params.filter;
  }
  }

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i]]);
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order]);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

/**
 * Retrieving a list of booking details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function get(data, links=null) {
  const url = urlParams('/api/booking/get', links, null);
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Update booking details 
 * by passing  necessary information
 * @param Array data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', name: 'Paramount' ... }]
 */
function update(data) {
  const url = `/api/booking/update`;
  
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of bookings
 * by passing in an array list of uuIds
 * @param Array data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = `/api/booking/delete`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}
