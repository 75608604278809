var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c("div", { attrs: { id: "project_tiles_container" } }, [
        _c(
          "div",
          { staticClass: "project-controls" },
          [
            _c(
              "PriorityNavigation",
              {
                staticClass: "projects-priority-nav",
                attrs: { ulClassName: "projects-priority-nav-ul" },
              },
              [
                _c(
                  "li",
                  [
                    _vm.canAdd()
                      ? _c(
                          "b-btn",
                          {
                            staticClass: "filter-button btn-secondary",
                            attrs: { id: `BTN_ADD` },
                            on: {
                              click: function ($event) {
                                return _vm.modalOpen(true)
                              },
                            },
                          },
                          [
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: `BTN_ADD`,
                                  triggers: "hover",
                                  placement: "top",
                                  boundary: "viewport",
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("button.add")) + " ")]
                            ),
                            _c("font-awesome-icon", {
                              style: { color: "var(--grid-toolbar-button)" },
                              attrs: { icon: ["far", "plus"] },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "b-btn",
                      {
                        staticClass: "filter-button btn-secondary",
                        attrs: { id: "tileViewToggle" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleTileView()
                          },
                        },
                      },
                      [
                        _c("b-popover", {
                          attrs: {
                            target: "tileViewToggle",
                            placement: "top",
                            boundary: "viewport",
                            triggers: "hover",
                            content: _vm.$t(
                              _vm.tileView
                                ? "project.toggle_view_list"
                                : "project.toggle_view_grid"
                            ),
                          },
                        }),
                        _c("font-awesome-icon", {
                          style: { color: "var(--grid-toolbar-button)" },
                          attrs: {
                            icon: ["far", _vm.tileView ? "list" : "grid"],
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("li", [
                  _c(
                    "div",
                    { staticClass: "search-container" },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "search-group" },
                        [
                          _c("b-form-input", {
                            staticClass: "search-field",
                            attrs: {
                              placeholder: _vm.$t("project.placeholder.search"),
                              trim: "",
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                return _vm.keydownHandler.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.searchValue,
                              callback: function ($$v) {
                                _vm.searchValue = $$v
                              },
                              expression: "searchValue",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-btn",
                                {
                                  attrs: { variant: "danger" },
                                  on: { click: _vm.clear },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "search-clear",
                                    attrs: { icon: ["far", "xmark"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-btn",
                                {
                                  attrs: { variant: "secondary" },
                                  on: { click: _vm.search },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["far", "magnifying-glass"],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "li",
                  [
                    _c(
                      "b-btn",
                      {
                        staticClass: "filter-button btn-secondary",
                        attrs: { id: "project_settings" },
                        on: {
                          click: function ($event) {
                            return _vm.showProjectSettings()
                          },
                        },
                      },
                      [
                        _c("b-popover", {
                          attrs: {
                            target: "project_settings",
                            placement: "top",
                            boundary: "viewport",
                            triggers: "hover",
                            content: _vm.$t("project.settings.filter"),
                          },
                        }),
                        _c("font-awesome-icon", {
                          class:
                            _vm.filter === null ||
                            _vm.filter.query === null ||
                            (_vm.filter.query !== null &&
                              _vm.filter.query.children.length === 0)
                              ? ""
                              : "active",
                          attrs: { icon: ["far", "filter"] },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "b-btn",
                      {
                        staticClass: "clear-filter-button btn-secondary",
                        attrs: { id: "clear_project_settings" },
                        on: {
                          click: function ($event) {
                            return _vm.clearProjectSettings()
                          },
                        },
                      },
                      [
                        _c("b-popover", {
                          attrs: {
                            target: "clear_project_settings",
                            placement: "top",
                            boundary: "viewport",
                            triggers: "hover",
                            content: _vm.$t("project.settings.clear"),
                          },
                        }),
                        _c("font-awesome-icon", {
                          style: {
                            paddingTop: "5px",
                            color: "var(--grid-toolbar-button)",
                          },
                          attrs: { icon: ["far", "filter-circle-xmark"] },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("li", [
                  _c(
                    "div",
                    {
                      staticClass: "view no-left-padding",
                      on: _vm._d({ mouseleave: _vm.onViewLeave }, [
                        _vm.viewMouseEnterEvent,
                        _vm.onViewOver,
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "view",
                          staticClass: "action-bar-dropdown view-btn",
                          attrs: {
                            id: `PROJECT_BTN_VIEW`,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "desktop"] },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: { click: _vm.savePreset },
                            },
                            [
                              _c("span", { staticClass: "action-item-label" }, [
                                _vm._v(_vm._s(_vm.$t("project.button.save"))),
                              ]),
                            ]
                          ),
                          _c("b-dropdown-divider"),
                          _vm._l(_vm.projectViews, function (item, index) {
                            return [
                              _c(
                                "b-dropdown-item",
                                {
                                  key: index,
                                  staticClass: "action-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.loadViewSettings(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-label-with-icon",
                                      attrs: { id: `viewname${index}` },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  _vm.isEllipsisActive(item.name)
                                    ? _c("b-popover", {
                                        attrs: {
                                          target: `viewname${index}`,
                                          placement: "top",
                                          boundary: "viewport",
                                          "custom-class": "popover-margin",
                                          triggers: "hover",
                                          offset: "-100",
                                          content: item.name,
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    [
                                      !_vm.editPermission(item)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "action-item-icon",
                                              attrs: {
                                                id: `BTN_COPY_${index}`,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.copyColumnSettings(
                                                    index,
                                                    item.name,
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "copy"],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.editPermission(item)
                                        ? _c("b-popover", {
                                            attrs: {
                                              target: `BTN_COPY_${index}`,
                                              placement: "top",
                                              boundary: "viewport",
                                              triggers: "hover",
                                              content: _vm.$t(
                                                "project.button.copy"
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      !_vm.editPermission(item)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "action-item-icon position-third",
                                              attrs: {
                                                id: `BTN_INFO_${index}`,
                                              },
                                              on: _vm._d(
                                                {
                                                  mouseleave: function (
                                                    $event
                                                  ) {
                                                    return _vm.onInfoLeave(
                                                      index
                                                    )
                                                  },
                                                },
                                                [
                                                  _vm.infoMouseEnterEvent,
                                                  function ($event) {
                                                    return _vm.onInfoOver(index)
                                                  },
                                                ]
                                              ),
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "circle-info"],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.editPermission(item)
                                        ? _c("b-popover", {
                                            ref: `BTN_INFO_${index}`,
                                            refInFor: true,
                                            attrs: {
                                              target: `BTN_INFO_${index}`,
                                              show: _vm.showInfo[index],
                                              placement: "top",
                                              boundary: "viewport",
                                              triggers: "manual",
                                              content: _vm.$t("owner", [
                                                _vm.projectViews[index].owner,
                                              ]),
                                            },
                                            on: {
                                              "update:show": function ($event) {
                                                return _vm.$set(
                                                  _vm.showInfo,
                                                  index,
                                                  $event
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.editPermission(item)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "action-item-icon position-third",
                                              attrs: {
                                                id: `BTN_SHARE_${index}`,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.shareColumnSettings(
                                                    index,
                                                    item.name,
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    item.defaultView
                                                      ? "fas"
                                                      : "far",
                                                    "share-nodes",
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.editPermission(item)
                                        ? _c("b-popover", {
                                            attrs: {
                                              target: `BTN_SHARE_${index}`,
                                              placement: "top",
                                              boundary: "viewport",
                                              triggers: "hover",
                                              content: _vm.$t(
                                                "project.button.share"
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.editPermission(item)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "action-item-icon position-second",
                                              attrs: {
                                                id: `BTN_UPDATE_${index}`,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.updateColumnSettings(
                                                    index,
                                                    item.name,
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "save"],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.editPermission(item)
                                        ? _c("b-popover", {
                                            attrs: {
                                              target: `BTN_UPDATE_${index}`,
                                              placement: "top",
                                              boundary: "viewport",
                                              triggers: "hover",
                                              content: _vm.$t(
                                                "project.button.update"
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.editPermission(item)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "action-item-icon",
                                              attrs: {
                                                id: `BTN_DELETE_${index}`,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.removeColumnSettings(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "trash-alt"],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.editPermission(item)
                                        ? _c("b-popover", {
                                            attrs: {
                                              target: `BTN_DELETE_${index}`,
                                              placement: "top",
                                              boundary: "viewport",
                                              triggers: "hover",
                                              content: _vm.$t(
                                                "project.button.delete"
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
                _c("li", [
                  _c(
                    "span",
                    {
                      on: _vm._d({ mouseleave: _vm.onColoringLeave }, [
                        _vm.colorMouseEnterEvent,
                        _vm.onColoringOver,
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "coloring",
                          staticClass:
                            "no-left-padding coloring-btn action-bar-dropdown",
                          attrs: {
                            id: `BTN_COLORING_PROJECT_TILES`,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "palette"] },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "b-dropdown-group",
                            { attrs: { header: _vm.$t("colorby") } },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("none")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [_vm._v(_vm._s(_vm.$t("none")))]
                                  ),
                                  _vm.coloring.none
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("project")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("project.coloring.project")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.project
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("company")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("company.coloring.company")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.company
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("location")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("project.coloring.location")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.location
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("customer")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("project.coloring.customer")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.customer
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("status")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("project.coloring.status")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.status
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onColorChange("rebate")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("project.coloring.rebate")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.rebate
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("li", [
                  _vm.tileView
                    ? _c(
                        "div",
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "filter-button btn-secondary",
                                  attrs: {
                                    pressed: _vm.allProjectsExpanded,
                                    id: "project-list-switch",
                                    name: "project-list-switch ",
                                  },
                                  on: {
                                    "update:pressed": function ($event) {
                                      _vm.allProjectsExpanded = $event
                                    },
                                    click: _vm.onExpand,
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    style: {
                                      color: "var(--grid-toolbar-button)",
                                    },
                                    attrs: {
                                      icon: [
                                        "far",
                                        _vm.allProjectsExpanded
                                          ? "eye-slash"
                                          : "eye",
                                      ],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("b-popover", {
                            attrs: {
                              target: "project-list-switch",
                              placement: "top",
                              triggers: "hover",
                              content: _vm.$t(
                                _vm.allProjectsExpanded
                                  ? "project.settings.hide"
                                  : "project.settings.show"
                              ),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("li", [
                  _c(
                    "div",
                    { staticClass: "project-attribute-toggler" },
                    [
                      _c(
                        "b-dropdown",
                        {
                          staticClass: "project-attribute-btn",
                          attrs: {
                            id: "ddown-offset",
                            variant: "link",
                            offset: "25",
                            "no-caret": "",
                          },
                        },
                        [
                          _c("template", { slot: "button-content" }, [
                            _c(
                              "div",
                              { staticClass: "text" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "ellipsis-vertical"] },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.tileView
                            ? _c(
                                "b-dropdown-group",
                                {
                                  attrs: {
                                    header: _vm.$t("project.display.title"),
                                  },
                                },
                                [
                                  _vm._l(_vm.display, function (item, key) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: key,
                                          staticClass: "dropdown-item",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.toggleVisible(key)
                                                },
                                              },
                                              model: {
                                                value: _vm.display[key],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.display,
                                                    key,
                                                    $$v
                                                  )
                                                },
                                                expression: "display[key]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.displayLabels[key])
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "b-dropdown-group",
                            {
                              attrs: {
                                header: _vm.$t("project.progress.title"),
                              },
                            },
                            [
                              _vm.canView("PROJECT", ["progress"])
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-item progress-toggle",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.toggleProgressFilter(
                                                "_progress0"
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.progressFilters["_progress0"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.progressFilters,
                                                "_progress0",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "progressFilters['_progress0']",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "legend-item project-tile-0",
                                          }),
                                          _c("span", [_vm._v("0%")]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canView("PROJECT", ["progress"])
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-item progress-toggle",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.toggleProgressFilter(
                                                "_progress25"
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.progressFilters[
                                                "_progress25"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.progressFilters,
                                                "_progress25",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "progressFilters['_progress25']",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "legend-item project-tile-25",
                                          }),
                                          _c("span", [_vm._v("1% - 25%")]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canView("PROJECT", ["progress"])
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-item progress-toggle",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.toggleProgressFilter(
                                                "_progress50"
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.progressFilters[
                                                "_progress50"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.progressFilters,
                                                "_progress50",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "progressFilters['_progress50']",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "legend-item project-tile-50",
                                          }),
                                          _c("span", [_vm._v("26% - 50%")]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canView("PROJECT", ["progress"])
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-item progress-toggle",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.toggleProgressFilter(
                                                "_progress75"
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.progressFilters[
                                                "_progress75"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.progressFilters,
                                                "_progress75",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "progressFilters['_progress75']",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "legend-item project-tile-75",
                                          }),
                                          _c("span", [_vm._v("51% - 75%")]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canView("PROJECT", ["progress"])
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-item progress-toggle",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.toggleProgressFilter(
                                                "_progress99"
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.progressFilters[
                                                "_progress99"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.progressFilters,
                                                "_progress99",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "progressFilters['_progress99']",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "legend-item project-tile-99",
                                          }),
                                          _c("span", [_vm._v("76% - 99%")]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canView("PROJECT", ["progress"])
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-item progress-toggle",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.toggleProgressFilter(
                                                "_progress100"
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.progressFilters[
                                                "_progress100"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.progressFilters,
                                                "_progress100",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "progressFilters['_progress100']",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "legend-item project-tile-100",
                                          }),
                                          _c("span", [_vm._v("100%")]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        2
                      ),
                      _c("b-popover", {
                        attrs: {
                          target: "ddown-offset",
                          placement: "top",
                          triggers: "hover",
                          content: _vm.$t("project.settings.display"),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm.tileView
          ? _c(
              "div",
              [
                _vm.loadingFav
                  ? _c(
                      "div",
                      { staticClass: "loading" },
                      [
                        _c("b-spinner", {
                          attrs: { variant: "primary", label: "Loading..." },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _vm.filteredFavProjects().length > 0
                          ? _c(
                              "b-row",
                              { staticClass: "fav-background" },
                              [
                                _vm._l(_vm.favProjects, function (project) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.shouldDisplay(project),
                                            expression:
                                              "shouldDisplay(project)",
                                          },
                                        ],
                                        key: `fav${
                                          project.uuId
                                        }_${JSON.stringify(_vm.coloring)}`,
                                        class: _vm.projectClass(
                                          project.progress
                                        ),
                                        style: _vm.projectStyle(project),
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "link",
                                            on: {
                                              click: function ($event) {
                                                _vm.projectId = project.uuId
                                                _vm.renderPopup($event)
                                              },
                                            },
                                          },
                                          [
                                            _c("ProjectTile", {
                                              attrs: {
                                                project: project,
                                                coloring: _vm.coloring,
                                                display: _vm.display,
                                                favourite: true,
                                              },
                                              on: {
                                                toggleFavourite:
                                                  _vm.toggleFavourite,
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "project-title",
                                                style:
                                                  _vm.projectStyle(project),
                                              },
                                              [_vm._v(_vm._s(project.name))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                !_vm.allProjectsExpanded
                  ? _c(
                      "div",
                      { staticClass: "hidden-message" },
                      [
                        _c("div", { staticClass: "hidden-message-text" }, [
                          _vm._v(_vm._s(_vm.$t("project.list_hidden"))),
                        ]),
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "eye-slash"] },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-collapse",
                  {
                    staticClass: "project-list mt-2",
                    attrs: { id: "collapse-4" },
                    model: {
                      value: _vm.allProjectsExpanded,
                      callback: function ($$v) {
                        _vm.allProjectsExpanded = $$v
                      },
                      expression: "allProjectsExpanded",
                    },
                  },
                  [
                    _vm.loading
                      ? _c(
                          "div",
                          { staticClass: "loading" },
                          [
                            _c("b-spinner", {
                              attrs: {
                                variant: "primary",
                                label: "Loading...",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "b-row",
                              [
                                _vm._l(
                                  _vm.projectBatch,
                                  function (projects, index) {
                                    return [
                                      _vm.defer(index)
                                        ? [
                                            _vm._l(
                                              projects,
                                              function (project) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.shouldDisplay(
                                                              project
                                                            ) &&
                                                            !project.favourite,
                                                          expression:
                                                            "shouldDisplay(project) && !project.favourite",
                                                        },
                                                      ],
                                                      key: `${
                                                        project.uuId
                                                      }_${JSON.stringify(
                                                        _vm.coloring
                                                      )}`,
                                                      class: _vm.projectClass(
                                                        project.progress
                                                      ),
                                                      style:
                                                        _vm.projectStyle(
                                                          project
                                                        ),
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass: "link",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.projectId =
                                                                project.uuId
                                                              _vm.renderPopup(
                                                                $event
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("ProjectTile", {
                                                            attrs: {
                                                              project: project,
                                                              coloring:
                                                                _vm.coloring,
                                                              display:
                                                                _vm.display,
                                                              searchFilter:
                                                                _vm.searchFilter,
                                                              forceRender:
                                                                project.forceRender,
                                                            },
                                                            on: {
                                                              toggleFavourite:
                                                                _vm.toggleFavourite,
                                                              "update:forceRender":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    project,
                                                                    "forceRender",
                                                                    $event
                                                                  )
                                                                },
                                                              "update:force-render":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    project,
                                                                    "forceRender",
                                                                    $event
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "project-title",
                                                              style:
                                                                _vm.projectStyle(
                                                                  project
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  project.name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              }
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                  ]
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("GanttActionBar", {
                  attrs: {
                    readOnly: false,
                    allowManage: _vm.allowManage,
                    hideSecondRow: true,
                    disableEdit: true,
                    disableDelete: true,
                    disableOutdent: true,
                    disableIndent: true,
                    disableTaskCopy: true,
                    disableTaskPaste: true,
                    disableWhenMoreThanOne: true,
                    disableManualScheduleTask: true,
                    disableAutoScheduleTask: true,
                    disableManualScheduleProject: true,
                    disableAutoScheduleProject: true,
                    hasAutoAssignTasks: false,
                    dates: _vm.control.dates,
                    startDate: _vm.control.startDate,
                    endDate: _vm.control.endDate,
                    timescale: _vm.control.timescale,
                    criticalPath: _vm.control.criticalPath,
                    freeFloat: _vm.control.freeFloat,
                    hideOptProjectStartToEnd: true,
                    hideOptProjectSchedule: true,
                    hideOptTaskSchedule: true,
                    hideOptProjectsSchedule: false,
                    isTemplate: false,
                    hideTaskOutdent: true,
                    hideTaskIndent: true,
                    hideTaskCopy: true,
                    hideTaskPaste: true,
                    hideTemplateApply: true,
                    hideTemplateSave: true,
                    hideFileImport: true,
                    hideScheduleMenu: true,
                    hideCriticalPath: true,
                    hideFreeFloat: true,
                    hideAutoAssignTasks: true,
                    permissionName: _vm.permissionName,
                    coloring: _vm.coloring,
                  },
                  on: {
                    "update:timescale": function ($event) {
                      return _vm.$set(_vm.control, "timescale", $event)
                    },
                    "update:criticalPath": function ($event) {
                      return _vm.$set(_vm.control, "criticalPath", $event)
                    },
                    "update:critical-path": function ($event) {
                      return _vm.$set(_vm.control, "criticalPath", $event)
                    },
                    "update:freeFloat": function ($event) {
                      return _vm.$set(_vm.control, "freeFloat", $event)
                    },
                    "update:free-float": function ($event) {
                      return _vm.$set(_vm.control, "freeFloat", $event)
                    },
                    datesChanged: _vm.handleControlDates,
                    startDateChanged: function ($event) {
                      return _vm.actionBarDateChanged($event, {
                        isStartDate: true,
                      })
                    },
                    endDateChanged: function ($event) {
                      return _vm.actionBarDateChanged($event, {
                        isStartDate: false,
                      })
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    ref: "splitter-container",
                    staticClass: "splitter-container",
                    style: _vm.splitterStyle,
                  },
                  [
                    _c(
                      "div",
                      { ref: "lhs-grid", staticClass: "lhs-grid" },
                      [
                        _c("ag-grid-vue", {
                          staticClass:
                            "ag-theme-balham projectlist-grid-height",
                          style: _vm.lhsGridStyle,
                          attrs: {
                            id: "pt-grid",
                            gridOptions: _vm.gridOptions,
                            columnDefs: _vm.columnDefs,
                            context: _vm.context,
                            defaultColDef: _vm.defaultColDef,
                            getRowId: (params) => params.data.uuId,
                            noRowsOverlayComponent: "noRowsOverlay",
                            noRowsOverlayComponentParams:
                              _vm.noRowsOverlayComponentParams,
                            overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                            cacheBlockSize: 10000,
                            rowData: _vm.rowData,
                            rowModelType: "serverSide",
                            rowMultiSelectWithClick: false,
                            rowSelection: "multiple",
                            serverSideInfiniteScroll: true,
                            headerHeight: 35,
                            sideBar: false,
                            suppressDragLeaveHidesColumns: "",
                            suppressContextMenu: "",
                            suppressCellFocus: false,
                            suppressMultiSort: false,
                            singleClickEdit: false,
                            enableRangeSelection: true,
                            rowHeight: 33,
                            alwaysShowHorizontalScroll: "",
                          },
                          on: { "grid-ready": _vm.onGridReady },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        ref: "resizer",
                        staticClass: "resizer",
                        attrs: { id: "grid-gantt-resizer" },
                        on: { mousedown: _vm.mouseDownHandler },
                      },
                      [
                        _c(
                          "div",
                          {
                            ref: "resizer-overlay",
                            staticClass: "resizer-overlay",
                            attrs: { id: "resizer-overlay" },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "resizer-icon",
                              attrs: { icon: ["far", "arrows-left-right"] },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        ref: "rhs-chart",
                        staticClass: "rhs-chart",
                        staticStyle: { overflow: "hidden" },
                      },
                      [
                        _c("DhtmlxGantt", {
                          attrs: {
                            useCase: "project-tiles",
                            scrollY: _vm.scrollState.top,
                            scrollX: _vm.scrollState.left,
                            height: _vm.ganttHeight,
                            router: _vm.routerObj,
                            ganttData: _vm.ganttData,
                            startDate: _vm.control.startDate,
                            endDate: _vm.control.endDate,
                            timescale: _vm.control.timescale,
                            criticalPath: _vm.control.criticalPath,
                            freeFloat: _vm.control.freeFloat,
                            markerStartText: _vm.marker.projectStart,
                            markerEndText: _vm.marker.projectEnd,
                            markerTodayText: _vm.marker.today,
                            pendingRerender: _vm.pendingRerender,
                            heightOffset: 230,
                            rowHeight: 33,
                            scaleHeight: 36,
                            showPadlock: false,
                            defaultColoring: _vm.coloring.none,
                            showMarkerStartText: _vm.marker.showStartText,
                            showMarkerEndText: _vm.marker.showEndText,
                            showMarkerTodayText: _vm.marker.showTodayText,
                            collapseId: _vm.collapseId,
                            expandId: _vm.expandId,
                            selectedTasks: _vm.taskSelectionIds,
                            collapseIds: _vm.collapseIds,
                            expandIds: _vm.expandIds,
                            readOnly: true,
                            filteredIds: _vm.filteredIds,
                          },
                          on: {
                            "update:scrollX": function ($event) {
                              return _vm.$set(_vm.scrollState, "left", $event)
                            },
                            "update:scroll-x": function ($event) {
                              return _vm.$set(_vm.scrollState, "left", $event)
                            },
                            "update:pendingRerender": function ($event) {
                              _vm.pendingRerender = $event
                            },
                            "update:pending-rerender": function ($event) {
                              _vm.pendingRerender = $event
                            },
                            ganttScroll: _vm.ganttScrollHandler,
                            ganttCreated: _vm.createdGantt,
                            taskSelectionChanged:
                              _vm.taskSelectionChangedHandler,
                            taskClicked: _vm.taskClickedHandler,
                            cellFocused: _vm.taskCellFocusedHandler,
                            markerChangedStart: function ($event) {
                              return _vm.markerStateHandler(
                                $event,
                                "showStartText"
                              )
                            },
                            markerChangedEnd: function ($event) {
                              return _vm.markerStateHandler(
                                $event,
                                "showEndText"
                              )
                            },
                            markerChangedToday: function ($event) {
                              return _vm.markerStateHandler(
                                $event,
                                "showTodayText"
                              )
                            },
                            "progress-complete": _vm.progressComplete,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
      ]),
      _c("ProjectFilterModal", {
        attrs: {
          show: _vm.filterShow,
          userId: _vm.userId,
          data: _vm.filter,
          profiles: _vm.profiles,
        },
        on: {
          "update:show": function ($event) {
            _vm.filterShow = $event
          },
          success: _vm.filterSuccess,
        },
      }),
      _vm.projectShow
        ? _c("ProjectModal", {
            attrs: {
              id: _vm.projectId,
              readOnly: !_vm.canEdit("PROJECT", [], _vm.projectId),
              show: _vm.projectShow,
              title: _vm.projectTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.projectShow = $event
              },
              success: _vm.projectSuccess,
            },
          })
        : _vm._e(),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptSaveShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.save"),
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptSaveShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptShareShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.share"),
          sharing: true,
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptShareShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteViewOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteViewShow,
            callback: function ($$v) {
              _vm.confirmDeleteViewShow = $$v
            },
            expression: "confirmDeleteViewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("task.confirmation.delete_view")) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }