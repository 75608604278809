<template>
  <div class="flex-container">
    <AlertFeedback v-if="alertMsg != null && alertState != 'success'" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert" @offsetHeight="updateGridHeight"/>
    <PriorityNavigation ref="project-grid-toolbar" class="grid-toolbar border" v-if="allowManage" 
      :dropDownStayOpened="badgeFilterModalOpened != 'close'" 
      :closeDropdown.sync="closePriorityNavDropdown" 
      @[priorityNavMouseOverEvent].native="onPriorityNavMouseOverOrTouchEnd"
      @[priorityNavTouchEndEvent].native="onPriorityNavMouseOverOrTouchEnd"
      >
        <li v-if="canAdd()">
          <span :id="`BTN_ADD_${id}`">
            <b-btn :disabled="isAccessDenied || actionProcessing" @click="taskEditOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          </span>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.add_task') }}
          </b-popover>
        </li>
        <li v-if="canView()">
          <span :id="`BTN_EDIT_${id}`">
            <b-btn :disabled="isAccessDenied || disableEdit || actionProcessing" @click="taskEditOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ canEdit()? $t('button.edit_task') : $t('button.view_task') }}
          </b-popover>
        </li>
        <li v-if="canDelete()">
          <span :id="`BTN_DELETE_${id}`">
            <b-btn :disabled="isAccessDenied || disableDelete || actionProcessing" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
          </span>
          <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.delete_task') }}
          </b-popover>
        </li>
        <li class="divider" v-if="(canAdd() || canView() || canDelete) && canEdit(permissionName, ['TASK'])">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="canEdit(permissionName, ['TASK'])">
          <span :id="`BTN_OUTDENT_${id}`">
            <b-btn :disabled="isAccessDenied || disableOutdent || actionProcessing" @click="taskOutdent"><font-awesome-icon :icon="['far','outdent']"/></b-btn>
          </span>
          <b-popover :target="`BTN_OUTDENT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.outdent') }}
          </b-popover>
        </li>
        <li v-if="canEdit(permissionName, ['TASK'])">
          <span  :id="`BTN_INDENT_${id}`">
            <b-btn :disabled="isAccessDenied || disableIndent || actionProcessing" @click="taskIndent"><font-awesome-icon :icon="['far','indent']"/></b-btn>
          </span>
          <b-popover :target="`BTN_INDENT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.indent') }}
          </b-popover>
        </li>
        <li class="divider" v-if="canAdd()">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="canAdd()">
           <span :id="`BTN_COPY_${id}`">
            <b-btn :disabled="isAccessDenied || disableTaskCopy || actionProcessing" @click="taskCopy"><font-awesome-icon :icon="['far','copy']"/></b-btn>
          </span>
          <b-popover :target="`BTN_COPY_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.copy') }}
          </b-popover>
        </li>
        <li v-if="canAdd()">
          <span :id="`BTN_PASTE_${id}`">
            <b-btn :disabled="isAccessDenied || disableTaskPaste || actionProcessing" @click="taskPaste"><font-awesome-icon :icon="['far','paste']"/></b-btn>
          </span>
          <b-popover :target="`BTN_PASTE_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.paste') }}
          </b-popover>
        </li>
        <li class="divider" v-if="(canAdd('TASK') && canList('TEMPLATE__PROJECT')) || (canAdd('TEMPLATE__PROJECT') && canAdd('TEMPLATE__TASK'))">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="canAdd('TASK') && canList('TEMPLATE__PROJECT')">
          <span :id="`BTN_APPLY_TEMPLATE_${id}`">
            <b-btn :disabled="isAccessDenied || actionProcessing" @click="templateApply"><font-awesome-icon :icon="['far','layer-plus']"/></b-btn>
          </span>
          <b-popover :target="`BTN_APPLY_TEMPLATE_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.apply_template') }}
          </b-popover>
        </li>
        <li v-if="canAdd('TEMPLATE__PROJECT') && canAdd('TEMPLATE__TASK')">
          <span :id="`BTN_SAVE_TEMPLATE_${id}`">
            <b-btn :disabled="isAccessDenied || disableDelete || actionProcessing" @click="templateSave"><font-awesome-icon :icon="['far','layer-group']"/></b-btn>
          </span>
          <b-popover :target="`BTN_SAVE_TEMPLATE_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.save_template') }}
          </b-popover>
        </li>
        <li class="divider">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="canAdd('TASK')">
          <span :id="`BTN_IMPORT_DOCUMENT_${id}`">
            <b-btn :disabled="isAccessDenied || disableWhenMoreThanOne || actionProcessing" @click="fileImport"><font-awesome-icon :icon="['far','inbox-in']"/></b-btn>
          </span>
          <b-popover :target="`BTN_IMPORT_DOCUMENT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.import_document') }}
          </b-popover>
        </li>
        <li>
          <span :id="`BTN_EXPORT_DOCUMENT_${id}`">
            <b-btn :disabled="isAccessDenied || actionProcessing" @click="fileExport"><font-awesome-icon :icon="['far','inbox-out']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EXPORT_DOCUMENT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.export_document') }}
          </b-popover>
        </li>
        <li class="divider" v-if="canEdit('PROJECT') || canEdit('TASK')">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="canEdit('PROJECT')">
          <template v-if="disableManualScheduleProject">
            <span :id="`BTN_MANUAL_SCHEDULE_PROJECT_${id}`">
              <b-btn :disabled="isAccessDenied || actionProcessing" @click="autoScheduleProjectHandler"><font-awesome-icon :icon="['far','thumbtack']"/></b-btn>
            </span>
            <b-popover :target="`BTN_MANUAL_SCHEDULE_PROJECT_${id}`" triggers="hover" placement="top" boundary="viewport">
              {{ $t('task.button.manual_scheduled') }}
            </b-popover>
          </template>
          <template v-if="disableAutoScheduleProject">
            <span :id="`BTN_AUTO_SCHEDULE_PROJECT_${id}`">
              <b-btn :disabled="isAccessDenied || actionProcessing" @click="manualScheduleProjectHandler"><font-awesome-icon :icon="['far','calendar-check']"/></b-btn>
            </span>
            <b-popover :target="`BTN_AUTO_SCHEDULE_PROJECT_${id}`" triggers="hover" placement="top" boundary="viewport">
              {{ $t('task.button.auto_scheduled') }}
            </b-popover>
          </template>
        </li>
        <li v-if="canEdit('TASK')" class="scheduler" @[schedulerMouseEnterEvent]="onSchedulerOver" @mouseleave="onSchedulerLeave">
          <b-dropdown :disabled="isAccessDenied" :id="`BTN_SCHEDULE_${id}`" ref="scheduler" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'calendar-days']"/>
            </template>
            <template v-if="!isAccessDenied">
              <b-dropdown-item :disabled="disableManualScheduleTask || actionProcessing" @click="manualScheduleTaskHandler" href="#">
                <font-awesome-icon class="item-icon" :icon="['far','thumbtack']"/><span class="item-label">{{ $t('task.button.manual_schedule_task') }}</span>
              </b-dropdown-item>
              <b-dropdown-item :disabled="disableAutoScheduleTask ||  actionProcessing" @click="autoScheduleTaskHandler" href="#">
                <font-awesome-icon class="item-icon" :icon="['far','calendar-check']"/><span class="item-label">{{ $t('task.button.auto_schedule_task') }}</span>
              </b-dropdown-item>
              <b-dropdown-divider/>
              <b-dropdown-item :disabled="actionProcessing" @click="scheduleProjectNowHandler" href="#">
                <font-awesome-icon class="item-icon" :icon="['far', 'calendar-days']"/><span class="item-label">{{ $t('task.button.schedule_project_now') }}</span>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </li>
        <li v-if="canEdit('TASK')">
          <span :id="`BTN_AUTO_ASSIGN_STAFF_${id}`">
            <b-btn :disabled="isAccessDenied || autoAssignTasks().length === 0 || inProgressShow" @click="autoAssignStaff"><font-awesome-icon :icon="['far', 'user-helmet-safety']"/></b-btn>
          </span>
          <b-popover :target="`BTN_AUTO_ASSIGN_STAFF_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.auto_assign_staff') }}
          </b-popover>
        </li>
        <li class="divider">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li>
          <span :id="`BTN_COLLAPSE_${id}`">
            <b-btn :disabled="isAccessDenied || inProgressShow || expandLevel === 0" @click="collapse"><font-awesome-icon :icon="['far', 'magnifying-glass-minus']"/></b-btn>
          </span>
          <b-popover :target="`BTN_COLLAPSE_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.minus') }}
          </b-popover>
        </li>
        <li>
          <span :id="`BTN_EXPAND_${id}`">
            <b-btn :disabled="isAccessDenied || inProgressShow || expandLevel === maxLevel" @click="expand"><font-awesome-icon :icon="['far', 'magnifying-glass-plus']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EXPAND_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.plus') }}
          </b-popover>
        </li>
        <li @[filterMouseEnterEvent]="onFilterOver" @mouseleave="onFilterLeave">
          <b-dropdown :disabled="isAccessDenied" :id="`BTN_FILTER_${id}`" ref="filter"
            class="action-bar-dropdown text-filter" 
            toggle-class="text-decoration-none" no-caret
            @hide="onFilterTextDropdownHide"
          >
            <template #button-content>
              <font-awesome-icon :class="filterText !== '' ? 'active' : ''" :icon="['far', 'file-magnifying-glass']"/>
            </template>
            <template v-if="!isAccessDenied">
              <b-dropdown-form @submit.stop.prevent class="filter-padding">
                <b-input-group class="search-input">
                  <b-form-input v-model="filterText" @focus="filterTextFocus = true" @blur="filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit"></b-form-input>
                  <b-input-group-append>
                    <b-btn @click="onFilterClear" class="search-append" size="sm" variant="danger"><font-awesome-icon class="search-clear" :icon="['far', 'xmark']"/></b-btn>
                  </b-input-group-append>
                  <b-input-group-append>
                    <b-btn @click="onFilterSubmit" class="search-append search-append-bg" size="sm"><font-awesome-icon variant="secondary" :icon="['far', 'magnifying-glass']"/></b-btn>
                  </b-input-group-append>
                </b-input-group>
              </b-dropdown-form>
            </template>
          </b-dropdown>
        </li>
        <li @[badgeFilterMouseEnterEvent]="onBadgeFilterEnter" 
            @[badgeFilterMouseOverEvent]="onBadgeFilterOver" 
            @[badgeFilterMouseLeaveEvent]="onBadgeFilterLeave" 
            @[badgeFilterTouchEndEvent]="onBadgeFilterOver">
          <b-dropdown :disabled="isAccessDenied" :id="`BTN_BADGE_FILTER_${id}`" ref="badgeFilter"
            class="action-bar-dropdown text-filter" 
            toggle-class="text-decoration-none" no-caret
            @hide="onBadgeFilterDropdownHide"
          >
            <template #button-content>
              <font-awesome-icon :class="badgeFilters.length > 0 ? 'active' : ''" :icon="['far', 'filter']"/>
            </template>
            <template v-if="!isAccessDenied">
              <b-dropdown-form @submit.stop.prevent class="filter-padding">
                <TaskViewBadgeFilter :filters="badgeFilters" 
                  :fields="badgeFilterFields" 
                  :fieldValues="badgeFilterFieldValues" 
                  @modified="onBadgeFilterModified" 
                  @filterModalOpened="onBadgeFilterModalOpened"
                  @filterModalClosed="onBadgeFilterModalClosed"
                  @fetchFieldOptions="onBadgeFilterFetchOptions"
                  />
              </b-dropdown-form>
            </template>
          </b-dropdown>
        </li>
        <li class="divider">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li class="view" @[viewMouseEnterEvent]="onViewOver" @mouseleave="onViewLeave">
          <b-dropdown :disabled="isAccessDenied" :id="`BTN_VIEW_${id}`" ref="view" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far','desktop']"/>
            </template>
            <template v-if="!isAccessDenied">
              <b-dropdown-item @click="showAllColumns" href="#">
                <span class="action-item-label">{{ $t('task.button.all_columns') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showNoColumns" href="#">
                <span class="action-item-label">{{ $t('task.button.no_columns') }}</span>
              </b-dropdown-item>
              <b-dropdown-divider/>
              <b-dropdown-item @click="savePreset" href="#">
                <span class="action-item-label">{{ $t('task.button.save') }}</span>
              </b-dropdown-item>
              <b-dropdown-divider/>
              <template v-for="(item, index) in views">
                <b-dropdown-item class="action-item" :disabled="actionProcessing" @click="loadViewSettings(item)" href="#" :key="index">
                  <span :id="`viewname${index}`" class="action-item-label-with-icon">{{ item.name }}</span>
                  <b-popover
                    v-if="isEllipsisActive(item.name)"
                    :target="`viewname${index}`"
                    placement="top"
                    boundary="viewport"
                    custom-class="popover-margin"
                    triggers="hover"
                    offset="-100"
                    :content="item.name">
                  </b-popover> 
                  <span>
                    <template v-if="!editPermission(item)">
                      <span class="action-item-icon"
                          :id="`COPY_COLUMN_${index}`"
                          @click.stop.prevent="copyColumnSettings(item.name, item)">
                        <font-awesome-icon class="" :icon="['far','copy']"/>
                      </span>
                      <b-popover :target="`COPY_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                        {{ $t('task.button.copy') }}
                      </b-popover>
                      <span class="action-item-icon position-third" 
                          @[infoMouseEnterEvent]="onInfoOver(index)" @mouseleave="onInfoLeave(index)"
                          :id="`BTN_INFO_${index}`">
                        <font-awesome-icon class="" :icon="['far','circle-info']"/>
                      </span>
                      <b-popover
                        :target="`BTN_INFO_${index}`"
                        :ref="`BTN_INFO_${index}`"
                        :show.sync="showInfo[index]"
                        placement="top"
                        boundary="viewport"
                        triggers="manual"
                        :content="$t('owner', [views[index].owner])">
                      </b-popover>
                    </template>
                    <template v-if="editPermission(item)">
                      <span class="action-item-icon position-third"
                          :id="`SHARE_COLUMN_${index}`"
                          @click.stop.prevent="shareColumnSettings(index, item.name, item)">
                        <font-awesome-icon class="" :icon="[item.defaultView ? 'fas' : 'far','share-nodes']"/>
                      </span>
                      <b-popover :target="`SHARE_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                        {{ $t('task.button.share') }}
                      </b-popover>
                    </template>
                    <template v-if="editPermission(item)">
                      <span class="action-item-icon position-second" 
                          :id="`UPDATE_COLUMN_${index}`"
                          @click.stop.prevent="updateColumnSettings(index, item.name, item)">
                        <font-awesome-icon class="" :icon="['far', 'floppy-disk']"/>
                      </span>
                      <b-popover :target="`UPDATE_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                        {{ $t('task.button.update') }}
                      </b-popover>
                    </template>
                    <template v-if="editPermission(item)">
                      <span class="action-item-icon"
                          :id="`REMOVE_COLUMN_${index}`"
                          @click.stop.prevent="removeColumnSettings(index)">
                        <font-awesome-icon class="" :icon="['far', 'trash-can']"/>
                      </span>
                      <b-popover :target="`REMOVE_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                        {{ $t('task.button.delete') }}
                      </b-popover>
                    </template>
                  </span>
                </b-dropdown-item>
              </template>
            </template>
          </b-dropdown>
        </li>
        <li @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
          <b-dropdown :disabled="isAccessDenied" :id="`BTN_COLORING_${id}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'palette']"/>
            </template>
            <template v-if="!isAccessDenied">
              <b-dropdown-group :header="$t('colorby')">
                <b-dropdown-item @click="onColorChange('none', 'task_view_coloring')" href="#">
                  <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
                </b-dropdown-item>
                <b-dropdown-item @click="onColorChange('task', 'task_view_coloring')" href="#">
                  <span class="action-item-label">{{ $t('task.coloring.task') }}</span><font-awesome-icon class="active-check" v-if="coloring.task" :icon="['far', 'check']"/>
                </b-dropdown-item>
                <b-dropdown-item @click="onColorChange('stage', 'task_view_coloring')" href="#">
                  <span class="action-item-label">{{ $t('task.coloring.stage') }}</span><font-awesome-icon class="active-check" v-if="coloring.stage" :icon="['far', 'check']"/>
                </b-dropdown-item>
                <b-dropdown-item @click="coloring.company = !coloring.company; onColorChange('skill', 'task_view_coloring')" href="#">
                  <span class="action-item-label">{{ $t('task.coloring.skill') }}</span><font-awesome-icon class="active-check" v-if="coloring.skill" :icon="['far', 'check']"/>
                </b-dropdown-item>
                <b-dropdown-item @click="onColorChange('staff', 'task_view_coloring')" href="#">
                  <span class="action-item-label">{{ $t('task.coloring.staff') }}</span><font-awesome-icon class="active-check" v-if="coloring.staff" :icon="['far', 'check']"/>
                </b-dropdown-item>
                <b-dropdown-item @click="onColorChange('resource', 'task_view_coloring')" href="#">
                  <span class="action-item-label">{{ $t('task.coloring.resource') }}</span><font-awesome-icon class="active-check" v-if="coloring.resource" :icon="['far', 'check']"/>
                </b-dropdown-item>
                <b-dropdown-item @click="onColorChange('rebate', 'task_view_coloring')" href="#">
                  <span class="action-item-label">{{ $t('task.coloring.rebate') }}</span><font-awesome-icon class="active-check" v-if="coloring.rebate" :icon="['far', 'check']"/>
                </b-dropdown-item>
                <b-dropdown-item @click="onColorChange('file', 'task_view_coloring')" href="#">
                  <span class="action-item-label">{{ $t('task.coloring.file') }}</span><font-awesome-icon class="active-check" v-if="coloring.file" :icon="['far', 'check']"/>
                </b-dropdown-item>
              </b-dropdown-group>
            </template>
          </b-dropdown>
        </li>
        <li>
          <span :id="`BTN_ENABLE_FLATLIST_${id}`">
            <b-btn :disabled="isAccessDenied || actionProcessing" @click="toggleFlatListHandler"><font-awesome-icon :icon="['far',flatList ? 'list-tree' : 'list']"/></b-btn>
          </span>
          <b-popover :target="`BTN_ENABLE_FLATLIST_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t(flatList ? 'task.button.tree' : 'task.button.flatlist') }}
          </b-popover>
        </li>

        <li>
          <span :id="`BTN_ENABLE_COMPACT_VIEW_${id}`">
            <b-btn :disabled="isAccessDenied || actionProcessing || compactLimit" :pressed="isCompactView" @click="toggleCompactViewHandler"><font-awesome-icon :icon="['far','table-cells']"/></b-btn>
          </span>
          <b-popover :target="`BTN_ENABLE_COMPACT_VIEW_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t(compactLimit ? 'task.error.compact_limit' : 'task.button.compact_view') }}
          </b-popover>
        </li>
    </PriorityNavigation>

    <div v-if="this.id === null">
        No valid project id specified in the URL.
    </div>
    <div class="flex-grid-container" v-else>
      <ag-grid-vue style="width: 100%;" :style="{ height: gridHeight }" class="ag-theme-balham" id="project-tasks-grid" ref="project-tasks-grid"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          animateRows
          :autoGroupColumnDef="autoGroupColumnDef"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          :getMainMenuItems="getMainMenuItems"
          
          noRowsOverlayComponent="noRowsOverlay"
          :noRowsOverlayComponentParams="noRowsOverlayComponentParams"

          :overlayLoadingTemplate="overlayLoadingTemplate"
          :getDataPath="data => flatList ? [data.uuId] : data.path"
          :getRowId="params => params.data.uuId"
          :rowData="rowData"
          :rowMultiSelectWithClick="false"
          rowSelection="multiple"
          :sideBar="false"
          suppressContextMenu
          suppressDragLeaveHidesColumns
          :suppressCellFocus="false"
          suppressClipboardApi
          :singleClickEdit="false"
          :enableRangeSelection="true"
          :enableFillHandle="true"
          :fillOperation="fillOperation"
          fillHandleDirection="xy"
          :processCellForClipboard="processCellForClipboard"
          :processCellFromClipboard="processCellFromClipboard"
          :navigateToNextCell="navigateToNextCell"
          :tabToNextCell="tabToNextCell"
          
          suppressMultiSort
          suppressScrollOnNewData
          treeData
          @row-drag-enter="onRowDragEnter"
          @row-drag-end="onRowDragEnd"
          @row-drag-move="onRowDragMove"
          @row-drag-leave="onRowDragLeave"
          @cell-key-down="onCellKeyDown"
          @paste-start="onPasteStart"
          @paste-end="onPasteEnd"
          @cell-focused="cellFocused"
          :suppressRowDrag="!canEdit()"
          enableCellEditingOnBackspace
          >
      </ag-grid-vue>
    </div>

    <TaskModal :show.sync="taskEditShow" :mode="mode" @success="taskEditSuccess" :id.sync="taskEdit.uuId"
      :projectId="id" :parentId="taskEdit.parentId" :taskType="isCompactView? 'Project': null"/>

    <TaskTemplateSaveModal :show.sync="templateSaveShow" 
      :taskIds="templateTaskIds" 
      :projectId="id"
      @success="templateSaveSuccess" />

    <!-- task template selector -->
    <GenericSelectorModalForAdmin v-if="taskTemplateSelectorShow"
      :show.sync="taskTemplateSelectorShow" 
      :entityService="templateTaskUtil"
      entity="TEMPLATE__PROJECT"
      nonAdmin
      @ok="taskTemplateSelectorOk"
    />
    
    <!--Gantt Import Dialog -->
    <GanttImportDialog :projectId="id" :show="docImportShow" :existingData="rowData"
      @modal-ok="docImportOk"
      @modal-cancel="docImportCancel" />
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable" @cancel="progressCancel"/>

    <SaveViewModal :show.sync="promptSaveShow" :name="saveName" :title="$t('task.confirmation.save')" :profile="saveProfile" @ok="confirmSaveOk"/>
    <SaveViewModal :show.sync="promptShareShow" :name="saveName" :title="$t('task.confirmation.share')" :sharing="true" :profile="saveProfile" @ok="confirmSaveOk"/>
      
    <b-modal :title="$t('task.auto_assign.summary')"
        v-model="autoAssignSummaryShow"
        @ok="autoAssignSummaryOk"
        ok-only
        scrollable
        content-class="shadow"
        no-close-on-backdrop
        >
      <ul class="task-summary-list" v-if="autoAssignSummary.length > 0">
        <li class="d-block task-summary-list-item" v-for="(item,index) in autoAssignSummary" :key="index">
          <div>
            <div class="task-summary-title">{{ getPathNames(taskNames[item.taskUUID].path) }}</div>
            <template v-if="item.staffAssignmentList.length > 0">
              <div  class="d-block" v-for="(staff, sIndex) in item.staffAssignmentList" :key="sIndex">
                {{ $t('task.auto_assign.assigned', [staff.name]) }}
              </div>
            </template>
            <div v-if="item.staffAssignmentList.length === 0" class="d-block">
              {{ $t('task.auto_assign.none_assigned') }}
            </div>
          </div>
        </li>
      </ul>
      <div v-if="autoAssignSummary.length === 0" class="d-block">
        {{ $t('task.auto_assign.none_assigned') }}
      </div>
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" variant="danger" @click="ok()">{{ $t('button.close') }}</b-button>
      </template>
    </b-modal>
    
    <AutoAssignStaffModal :projectId="id" :companies="project ? project.companyList : null" :tasks="autoAssignTasks()" :show.sync="autoAssignStaffShow" @success="autoAssignStaffSuccess" />
   
   <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteViewShow"
        @ok="confirmDeleteViewOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.delete_view') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <TaskDateTimeDurationCalculation :show.sync="durationCalculationShow" 
      :taskName="durationCalculation.taskName"
      :defaultActionForNonWorkPrompt="durationCalculation.defaultActionForNonWorkPrompt"
      :skipOutOfProjectDateCheck="durationCalculation.skipOutOfProjectDateCheck"
      :enableManualScheduleSuggestion="durationCalculation.enableManualScheduleSuggestion"
      showApplyAllCheckbox
      :trigger="durationCalculation.trigger"
      :startDateStr="durationCalculation.startDateStr"
      :startTimeStr="durationCalculation.startTimeStr"
      :closeDateStr="durationCalculation.closeDateStr"
      :closeTimeStr="durationCalculation.closeTimeStr"
      :durationDisplay="durationCalculation.durationDisplay"
      :calendar.sync="durationCalculation.calendar"
      :projectScheduleFromStart="durationCalculation.projectScheduleFromStart"
      :taskAutoScheduleMode="durationCalculation.taskAutoScheduleMode"
      :constraintType="durationCalculation.constraintType"
      :constraintDateStr="durationCalculation.constraintDateStr"
      :lockDuration="durationCalculation.lockDuration"
      :oldDateStr="durationCalculation.oldDateStr"
      :oldTimeStr="durationCalculation.oldTimeStr"
      :projectStartDateStr="durationCalculation.projectStartDateStr"
      :projectCloseDateStr="durationCalculation.projectCloseDateStr"
      :oldConstraintType="durationCalculation.oldConstraintType"
      :oldConstraintDateStr="durationCalculation.oldConstraintDateStr"
      :resizeMode="durationCalculation.resizeMode == true"
      :durationConversionOpts="durationConversionOpts"
      @success="durationCalculationOk"
      @skip="durationCalculationOk({ skip: true })"
      @cancel="durationCalculationCancel"
      @calendarChange="durationCalculationCalendarChange"
      :clearPreviousChoice="resetDurationCalculationState"
    />

    <TaskGroupSelectorModal v-if="taskGroupShow"
      :companyId="companyId"
      :userId="userId"
      :show.sync="taskGroupShow"
      :data="taskGroup.data"
      :propertyOptions="taskGroup.propertyOptions"
      :feedback="taskGroup.feedback"
      @changed="taskGroupChanged"
      @deleted="taskGroupDeleted"
      @reload="taskGroupReload"
    />

    <TaskGroupLocalModal v-if="taskGroupLocalShow" 
      :userId="userId"
      :companyId="companyId"
      :show.sync="taskGroupLocalShow" 
      :uuId="taskGroupLocal.uuId" 
      :headerName="taskGroupLocal.headerName"
      :property="taskGroupLocal.property"
      :propertyOptions="taskGroupLocal.propertyOptions"
      :children="taskGroupLocal.children"
      :dynamicTasks="taskGroupLocal.dynamicTasks"
      @ok="taskGroupLocalOk"
    />

    <TaskGroupModal v-if="newTaskGroupShow" 
      :userId="userId"
      :companyId="companyId"
      :show.sync="newTaskGroupShow" 
      :uuId="newTaskGroup.uuId" 
      :headerName="newTaskGroup.headerName"
      :description="newTaskGroup.description"
      :property="newTaskGroup.property"
      :propertyOptions="newTaskGroup.propertyOptions"
      :children="newTaskGroup.children"
      :dynamicTasks="newTaskGroup.dynamicTasks"
      :sharedVisibility="newTaskGroup.sharedVisibility"
      :sharingMembers="newTaskGroup.sharingMembers"
      :editors="taskGroup.editors"
      @ok="newTaskGroupOk"
    />

    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="tcConfirmDeleteShow"
        @hidden="tcConfirmDeleteShow=false"
        @ok="tcConfirmDeleteOk"
        @cancel="tcConfirmDeleteCancel"
        content-class="task-delete-modal shadow"
        no-close-on-backdrop
        >
      
      <p>{{ tcConfirmDeleteStatement }}</p>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-form-checkbox v-if="tcShowApplyAllCheckbox" class="apply-to-all" v-model="taskCol.applyAll">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <TaskGroupTaskEdit :show.sync="taskColEditShow" 
      :name="taskColEdit.name" 
      :color="taskColEdit.color" 
      :skills="taskColEdit.skills"
      :staff="taskColEdit.staff"
      :resources="taskColEdit.resources"
      :rebates="taskColEdit.rebates"
      :tags="taskColEdit.tags"
      @ok="taskColEditOk"
    />

    <ApplyTemplateConfigModal :show.sync="applyTemplateConfigShow" @ok="applyTemplateConfigOk" @cancel="applyTemplateConfigCancel" />

    <b-modal :title="$t('title_confirm_apply_change_to_task')"
        v-model="confirmApplyChangeToTaskShow"
        @ok="confirmApplyChangeToTaskOk"
        @cancel="confirmApplyChangeToTaskCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ confirmApplyToTaskStatement }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import { 
  strRandom, 
  msToTime, 
  objectClone, 
  invertColor, 
  getFirstColor, 
  calculateStaffUsage, 
  processSystemCalendar,
  processCalendar,
  transformCalendar,
  isEllipsisActive
} from '@/helpers';
import { getCustomFieldInfo, prepareCustomFieldColumnDef, handleCustomFieldError } from '@/helpers/custom-fields'
import { cloneDeep, debounce } from 'lodash';
import { taskViewService, taskService, staffService, projectService, templateProjectService,
         layoutProfileService, locationService, profileService,
         calendarService, viewProfileService, companyService, taskGroupProfileService, compositeService, tagService } from '@/services';
import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly } from '@/views/management/script/common';
import RowSelectorCellRenderer from '@/components/Aggrid/CellRenderer/RowSelector';
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/ViewDetailLink';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import DurationCellRenderer from '@/components/Aggrid/CellRenderer/Duration';
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage';
import TaskResourceCellRenderer from '@/components/Aggrid/CellRenderer/TaskResource';
import TaskSkillCellRenderer from '@/components/Aggrid/CellRenderer/TaskSkill';
import TaskStaffCellRenderer from '@/components/Aggrid/CellRenderer/TaskStaff';
import TaskTypeCellRenderer from '@/components/Aggrid/CellRenderer/TaskType';
import ImageCellRenderer from '@/components/Aggrid/CellRenderer/Image';
import NoteCellRenderer from '@/components/Aggrid/CellRenderer/Note';
import TaskAutoSchedulingCellRenderer from '@/components/Aggrid/CellRenderer/TaskAutoScheduling';
import RebateCellRenderer from '@/components/Aggrid/CellRenderer/Rebate';
import TaskTemplateCellRenderer from '@/components/Aggrid/CellRenderer/TaskTemplate';
import ConstraintCellRenderer from '@/components/Aggrid/CellRenderer/Constraint';
import StageCellRenderer from '@/components/Aggrid/CellRenderer/Stage';
import EnumCellRenderer from '@/components/Aggrid/CellRenderer/Enum';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';
import GenericCellRenderer from '@/components/Aggrid/CellRenderer/Generic';
import DateOnlyCellRenderer from '@/components/Aggrid/CellRenderer/DateOnly';
import BooleanCellRenderer from '@/components/Aggrid/CellRenderer/Boolean';

import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';

import ColorEditor from '@/components/Aggrid/CellEditor/Color';
import TagEditor from '@/components/Aggrid/CellEditor/Tag';
import DurationEditor from '@/components/Aggrid/CellEditor/Duration';
import DateTimeEditor from '@/components/Aggrid/CellEditor/DateTime';
import CostEditor from '@/components/Aggrid/CellEditor/Cost';
import PercentageEditor from '@/components/Aggrid/CellEditor/Percentage';
import NumericEditor from '@/components/Aggrid/CellEditor/Numeric';
import ListEditor from '@/components/Aggrid/CellEditor/List';
import StaffEditor from '@/components/Aggrid/CellEditor/Staff';
import StageEditor from '@/components/Aggrid/CellEditor/Stage';
import ImageEditor from '@/components/Aggrid/CellEditor/Image';
import StringEditor from '@/components/Aggrid/CellEditor/String';
import ResourceEditor from '@/components/Aggrid/CellEditor/Resources';
import SkillEditor from '@/components/Aggrid/CellEditor/Skills';
import NameEditor from '@/components/Aggrid/CellEditor/Name';
import WorkEffortEditor from '@/components/Aggrid/CellEditor/WorkEffort_v1';
import MultilineEditor from '@/components/Aggrid/CellEditor/Multiline';
import CommentEditor from '@/components/Aggrid/CellEditor/Note3';
import RebateEditor from '@/components/Aggrid/CellEditor/Rebates';
import TaskTemplateEditor from '@/components/Aggrid/CellEditor/TaskTemplate_v1';
import ConstraintEditor from '@/components/Aggrid/CellEditor/Constraint';
import FloatNumericEditor from '@/components/Aggrid/CellEditor/FloatNumeric';
import IntegerNumericEditor from '@/components/Aggrid/CellEditor/IntegerNumeric';
import SelectionHeaderComponent from '@/components/Aggrid/CellHeader/RangeSelection';
import currencies from '@/views/management/script/currencies';

import { TaskTemplateDataUtil } from '@/components/Task/script/task.template.util';
import { TaskViewRequestGenerator } from '@/components/Task/script/task.view.request.generator';
import { TaskViewPropertyUtil } from '@/components/Task/script/task.view.property.util';

import TaskCompactHeaderGroup from '@/components/Aggrid/CellHeaderGroup/TaskCompact';
import TaskCompactUtil from '@/components/Aggrid/CellHeader/TaskCompactUtil';
import TaskColCompactRenderer from '@/components/Aggrid/CellRenderer/Compact/TaskCol';
import TaskColAutoSchedulingCompactRenderer from '@/components/Aggrid/CellRenderer/Compact/TaskColAutoScheduling';
import TaskColColorCompactRenderer from '@/components/Aggrid/CellRenderer/Compact/TaskColColor';
import TaskColPercentageCompactRenderer from '@/components/Aggrid/CellRenderer/Compact/TaskColPercentage';
import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation';

import { templateTaskUtil } from '@/views/management/script/taskTemplate';

import { DEFAULT_CALENDAR, TRIGGERS, convertDisplayToDuration, convertDurationToDisplay, analyzeDurationAUM
 , getEarliestOrLatestWorkHour, isValidDateStrFormat, extractDurationConversionOpts
} from '@/helpers/task-duration-process';
import { prepareCompactViewData, taskColumnDefs, taskColumnMetadata } from '@/helpers/task-compact-view';
import {
  durationComparator,
  numberComparator,
  booleanComparator,
  skillComparator,
  staffComparator,
  noteComparator,
  stageComparator,
  resourceComparator,
  rebateComparator,
  constraintComparator
} from '@/helpers/task-column-comparator';

import { isTouchDevice } from '@/helpers/mobile';

const createBlob = (response) => response.blob();

// keep track of tasks loaded
let taskLoaded = {};

const fromBlobToBase64 = (blob) =>
  new Promise((res) => {
    const reader = new FileReader();
    reader.onloadend = () => res(reader.result);
    reader.readAsDataURL(blob);
  });
  
function getImageDimensions(file) {
  return new Promise (function (resolved/*, rejected*/) {
    var i = new Image()
    i.onload = function(){
      resolved({w: i.width, h: i.height})
    };
    i.src = file
  })
}

const createBase64ImageFromURL = (url) =>
  fetch(url)
    .then(createBlob)
    .then((blob) => fromBlobToBase64(blob));


export default {
  name: 'ProjectTasks',
  components: {
    'ag-grid-vue': AgGridVue,
    TaskModal: () => import('@/components/modal/TaskModal'),
    TaskGroupSelectorModal: () => import('@/components/modal/TaskGroupSelectorModal'),
    TaskGroupLocalModal: () => import('@/components/modal/TaskGroupLocalModal'),
    TaskGroupModal: () => import('@/components/modal/TaskGroupModal'),
    TaskTemplateSaveModal: () => import('@/components/modal/TaskTemplateSaveModal'),
    GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog'),
    AutoAssignStaffModal: () => import('@/components/modal/AutoAssignStaffModal'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),
    TaskDateTimeDurationCalculation: () => import('@/components/Task/TaskDateTimeDurationCalculation'),
    AlertFeedback: () => import('@/components/AlertFeedback'),
    SaveViewModal: () => import('@/components/modal/SaveViewModal.vue'),
    TaskGroupTaskEdit: () => import('@/components/TaskGroup/TaskEdit'),
    ApplyTemplateConfigModal: () => import('@/components/modal/ApplyTemplateConfigModal'),
    PriorityNavigation,
    TaskViewBadgeFilter: () => import('@/components/Filter/TaskViewBadgeFilter.vue'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin'),
    //aggrid cell renderer/editor/header/Overlay component
    /* eslint-disable vue/no-unused-components */
    'rowSelectorCellRenderer': RowSelectorCellRenderer,
    'costCellRenderer': CostCellRenderer,
    'detailLinkCellRenderer': DetailLinkCellRenderer,
    'dateTimeCellRenderer': DateTimeCellRenderer,
    'durationCellRenderer': DurationCellRenderer,
    'percentageCellRenderer': PercentageCellRenderer,
    'taskResourceCellRenderer': TaskResourceCellRenderer,
    'enumCellRenderer': EnumCellRenderer,
    'taskSkillCellRenderer': TaskSkillCellRenderer,
    'taskStaffCellRenderer': TaskStaffCellRenderer,
    'taskTypeCellRenderer': TaskTypeCellRenderer,
    'taskAutoSchedulingCellRenderer': TaskAutoSchedulingCellRenderer,
    'imageCellRenderer': ImageCellRenderer,
    'noteCellRenderer': NoteCellRenderer,
    'rebateCellRenderer': RebateCellRenderer,
    'taskTemplateCellRenderer': TaskTemplateCellRenderer,
    'constraintCellRenderer': ConstraintCellRenderer,
    'stageCellRenderer': StageCellRenderer,   
    'colorEditor': ColorEditor,
    'tagEditor': TagEditor, 
    'durationEditor': DurationEditor,
    'dateTimeEditor': DateTimeEditor,
    'percentageEditor': PercentageEditor,
    'costEditor': CostEditor,
    'listEditor': ListEditor,
    'staffEditor': StaffEditor,
    'resourceEditor': ResourceEditor,
    'skillEditor': SkillEditor,
    'numericEditor': NumericEditor,
    'nameEditor': NameEditor,
    'workEffortEditor': WorkEffortEditor,
    'multilineEditor': MultilineEditor,
    'commentEditor': CommentEditor,
    'rebateEditor': RebateEditor,
    'taskTemplateEditor': TaskTemplateEditor,
    'constraintEditor': ConstraintEditor,
    'stageEditor': StageEditor,
    'stringEditor': StringEditor,
    'floatNumericEditor': FloatNumericEditor,
    'integerNumericEditor': IntegerNumericEditor,
    'taskCompactHeaderGroup': TaskCompactHeaderGroup,
    'taskColCompactRenderer': TaskColCompactRenderer,
    'taskColColorCompactRenderer': TaskColColorCompactRenderer,
    'taskColPercentageCompactRenderer': TaskColPercentageCompactRenderer,
    'taskColAutoSchedulingCompactRenderer': TaskColAutoSchedulingCompactRenderer,
    'taskCompactUtil': TaskCompactUtil,
    'imageEditor': ImageEditor,
    'selectionHeaderComponent': SelectionHeaderComponent,
    'colorCellRenderer': ColorCellRenderer,
    'genericCellRenderer': GenericCellRenderer,
    'dateOnlyCellRenderer': DateOnlyCellRenderer,
    'booleanCellRenderer': BooleanCellRenderer,

    'noRowsOverlay': NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
    
  },
  props: {
    id: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: 'BOTH'
    }
  },
  data() {
    return {
      userId: null,
      companyId: null,
      permissionName: 'TASK',
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,

      gridOptions: null,
      gridApi: null,
      autoGroupColumnDef: null,
      columnDefs: [],
      columnApi: null,
      context: null,
      defaultColDef: null,
      rowData: null, //default to null. With null value, ag-grid will show loading overlay.
      expandLevel: 0,
      maxLevel: 0,
      filterText: '',
      filterTextFocus: false,
      
      selected: [],
      parentRootId: null,

      taskEdit: {
        uuId: null,
        parentId: null
      },
      taskEditShow: false,
      actionProcessing: false,

      templateSaveShow: false,
      templateTaskIds: null,

      taskTemplateSelectorShow: false,
      taskTemplateApplyTaskIds: null,

      taskCopyIds: [],
      taskNames: {},
      
      docImportShow: false,
      docImportTaskId: null,
      
      autoAssignStaffShow: false,
      autoAssignSettings: null,
      autoAssignSummaryShow: false,
      autoAssignSummary: [],

      project: { 
        name: null,
        autoScheduling: false,
        locationId: null,
        companyList: null,
        startDateStr: null,
        closeDateStr: null,
        scheduleMode: 'ASAP'
      },
      
      optionConstraint: [],
      optionPriority: [],
      optionStages: [],
      optionComplexity: [],
      optionCurrency: [],

      taskTemplates: [],
      settings: {},
      promptSaveShow: false,
      promptShareShow: false,
      saveName: null,
      saveProfile: null,
      saveIndex: -1,
      confirmDeleteViewShow: false,
      deleteViewIndex: -1,
      calendar: null,
      stageMap: {},
      previousScrollPosLeft: -1,
      previousScrollPosTop: -1,
      columnCache: [],

      durationCalculationShow: false, 
      durationCalculationTasks: [],
      durationCalculationPendingUpdateTasks: [],
      durationCalculation: {
        taskName: null
        , trigger: TRIGGERS.START_DATE
        , startDateStr: null
        , startTimeStr: null
        , closeDateStr: null
        , closeTimeStr: null
        , durationDisplay: null
        , calendar: DEFAULT_CALENDAR
        , projectScheduleFromStart: true
        , taskAutoScheduleMode: true
        , constraintType: null
        , constraintDateStr: null
        , oldDateStr: null
        , oldTimeStr: null
        , lockDuration: false
        , defaultActionForNonWorkPrompt: null
        , skipOutOfProjectDateCheck: false
        , projectStartDateStr: null
        , projectCloseDateStr: null
        , oldConstraintType: null
        , oldConstraintDateStr: null
        , taskId: null
      },
      resetDurationCalculationState: false,

      layoutProfile: {},
      views: [],
      
      //Used by Compact View
      isCompactView: false,
      
      taskGroupShow: false,
      taskGroup: {
        data: [],
        propertyOptions: [],
        feedback: {
          msg: null,
          alertState: null,
        }
      },

      taskGroupLocalShow: false,
      taskGroupLocal: {
        groupId: null,
        headerName: null,
        property: null,
        propertyOptions: [],
        children: [],
        dynamicTasks: []
      },

      newTaskGroupShow: false,
      newTaskGroup: {
        headerName: null,
        property: null,
        propertyOptions: [],
        children: [],
        sharingMembers: [],
        editors: [],
        sharedVisibility: 'Public'
      },

      tcConfirmDeleteShow: false,
      tcConfirmDeleteTasks: [],
      taskCol: {
        taskName: null,
        parentName: null,
        taskId: null,
        parentId: null,
        colId: null,
        applyAll: false
      },

      closePriorityNavDropdown: false,
      filterValue: '',

      taskColEditShow: false,
      taskColEdit: {
        colId: null,
        groupId: null,
        name: null,
        color: null,
        skills: [],
        staff: [],
        resources: [],
        rebates: [],
        tags: []
      },

      pendingListByFillOperation: [],
      triggeredByFillOperation: false,
      processValueChangedList: [],
      processDateValueChangedList: [],
      pendingProcessRequestList: [],
      pendingRequestBatchList: [],
      pendingDeleteCells: [],
      modelInfo: null,
      enumList: {},
      
      rowSelectorClicked_allColsSelectedRowIndexList: [],
      rangeSelection: [],
      taskSelection: [],
      
      coloring: {
        none: true,
        task: false,
        stage: false, 
        skill: false,
        staff: false,    
        resource: false,   
        rebate: false, 
        file: false  
      },

      noRowsMessage: null,
      noRowsOverlayComponentParams: null,

      isAccessDenied: false,
      lastOpenColumnMenuParams: null,
      total: 0,
      loaded: 0,

      applyTemplateConfigShow: false,
      compactApplyTemplateTarget: [],
      compactLimit: false,

      confirmApplyChangeToTaskShow: false,
      confirmApplyChangeToTaskPayload: {},
      confirmApplyChangeToTaskIsColumnEdit: false,
      confirmApplyChangeToTaskColName: null,

      calendarType: {
        holderId: null,
        type: null
      },
      projectCalendar: null,
      systemCalendar: null

      //BadgeFilter related
      , badgeFilters: [
        // { field: 'taskName', name: 'Task Name: Task #1, Task #2', value: [{ checked: true, text: 'Task #1'}, { checked: true, text: 'Task #2'}] }
        // , { field: 'skillName', name: 'Skill Name: Rigging, Animation', value: [{ checked: true, text: 'Rigging'}, { checked: true, text: 'Animation'}] }
      ]
      , badgeFilterFields: [
        { value: 'complexity', text: 'Complexity' }
        , { value: 'identifier', text: 'Identifier' }        
        , { value: 'parentTasks', text: 'Parent Tasks' }
        , { value: 'priority', text: 'Priority' }
        , { value: 'rebateName', text: 'Rebate Name' }
        , { value: 'resourceName', text: 'Resource Name' }
        , { value: 'skillName', text: 'Skill Name' }
        , { value: 'staffName', text: 'Staff Name' }
        , { value: 'stageName', text: 'Stage Name' }
        , { value: 'tagName', text: 'Tag Name' }
        , { value: 'taskName', text: 'Task Name' }
        , { value: 'type', text: 'Type' }
      ]
      , badgeFilterFieldValues: {
        // taskName: [
        //   { text: 'Task #1' }
        //   , { text: 'Task #2' }
        // ],
        // skillName: [
        //   { text: 'Rigging' },
        //   { text: 'Layout' },
        //   { text: 'Animation' }
        // ]
      }
      , badgeFilterFocus: false
      , badgeFilterModalOpened: 'close'
      , flatList: false

      , customFields: []
      , skillCustomFields: []
      , resourceCustomFields: []
      , noteCustomFields: []
      , showInfo: []

      , durationConversionOpts: {}

      , gridHeight: 'calc(100vh - 195px)'
    }
  },
  beforeMount() {
    this.$store.dispatch('data/enumList')
    .then(response => {
      if (response != null) {
        if (response.jobCase != null && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          const keys = Object.keys(propertyList);
          for (const k of keys) {
            const obj = propertyList[k]
            const codes = Object.keys(obj)
            const list = []
            for (const c of codes) {
              list.push({ value: c, text: c, num: obj[c] })
            }
            this.$set(this.enumList, k, list)
          }
        }

        //Fill up currency name property
        if (Array.isArray(this.enumList.CurrencyEnum)) {
          const currencyList = this.enumList.CurrencyEnum
          for (const item of currencyList)   {
            const found = currencies.find(i => i.code === item.value)
            if (found != null && found.name != null) {
              item.text = `${found.code} (${found.name})`
            }
          }
        }

        this.optionComplexity.splice(0, this.optionComplexity.length, ...this.enumList.GanttComplexityEnum)
        this.optionPriority.splice(0, this.optionPriority.length, ...this.enumList.GanttPriorityEnum)
        this.optionCurrency.splice(0, this.optionCurrency.length, ...this.enumList.CurrencyEnum)
      }
    })
    .catch(e => {
      this.httpAjaxError(e)
    })
    
    const self = this;
    const canEdit = this.canEdit();

    this.gridOptions = { 
      onViewportChanged: debounce(function(params)  {
        const firstRow = params.firstRow;
        const lastRow = params.lastRow;
        if (lastRow !== -1) {
          self.firstRow = firstRow;
          self.lastRow = lastRow;
          self.redrawViewport(params, firstRow, lastRow);
        }
      }, 50),  
      onModelUpdated: debounce(function(/**event*/)  {
        self.redrawViewport({api: self.gridOptions.api}, self.firstRow, self.lastRow);
      }, 500),   
      isExternalFilterPresent: function() {
        return Object.hasOwn(self, 'searchData');
      },
      doesExternalFilterPass: function doesExternalFilterPass(node) {
        if (node.data) {
          if (!self.searchData) {
            return true;
          }
          
          const selfPass = self.searchData.find(d => d.uuId === node.data.uuId);

          /*if (selfPass && node.childrenAfterGroup && node.childrenAfterGroup.length) {
            const allPass = self.doAllChildrenPass(node);
            if (allPass.passed > 0 && allPass.failed === 0) {
              return true;
            }
            else if (allPass.passed !== 0 && allPass.failed > 0) {
              return false; // so that we can check the children themselves
            }
          }*/
          return selfPass ? true : false;
        }
        return false;
      },
      getRowHeight: params => self.hasImage(params) ? 170 : 25,
      suppressRowClickSelection: true,
      onCellValueChanged: function(event) {
        let colId = event.column.colId;
        const rowIndex = event.rowIndex;
        let newValue = event.newValue;
        let oldValue = event.oldValue;
        const rowNode = event.api.getDisplayedRowAtIndex(rowIndex);
        if (event.column.colId == self.COLUMN_AGGRID_AUTOCOLUMN) {
          colId = 'name';
          newValue = rowNode.data.name;
          oldValue = rowNode.data.oldName; //oldName is added in autoGroupColumnDef.valueSetter.
        }

        const payload = {
          colId
          , data: objectClone(rowNode.data)
          , newValue
          , oldValue
          , property: colId
          , taskId: rowNode.data.uuId
          , parentId: rowNode.data.pUuId
          , taskName: rowNode.data.name
          , taskType: rowNode.data.taskType
          //The following are default task property values. Used when creating new task
          , color: event.colDef.color
          , skills: event.colDef.skills
          , staff: event.colDef.staff
          , resources: event.colDef.resources
          , rebates: event.colDef.rebates
          , tags: event.colDef.tags
        }
        
        let found = self.originalRowDataList.find(i => i.uuId == rowNode.data.uuId);
        if (found == null) {
          found = rowNode.data != null? JSON.parse(JSON.stringify(rowNode.data)) : null;
          found[colId] = oldValue != null? JSON.parse(JSON.stringify(oldValue)) : oldValue;
          self.originalRowDataList.push(found)
        } else {
          found[colId] = oldValue != null? JSON.parse(JSON.stringify(oldValue)) : oldValue;
        }
        const taskColPrefix = self.taskColPrefix;

        if (colId.startsWith(taskColPrefix)) {
          if (rowNode.data[colId] == null) {
            //Defensive code against issue #1234.
            return; //Not expecting this. Stop proceed further to avoid javascript error.
          }
          const property = rowNode.data[colId].property;
          payload.data = oldValue.data;
          //Special case for totalActualDuration
          if ('totalActualDuration' === property) {
            payload.data = newValue.data;
          }
          payload.oldValue = oldValue.single; 
          payload.newValue = newValue.single;
          payload.property = property;
          payload.taskId = oldValue.uuId != null? oldValue.uuId : null;
          payload.parentId = rowNode.data.uuId;
          payload.taskType = 'Task'; //colId starts with taskColPrefix must be 'Task'

          let taskName = '<undefined>';
          const regex = new RegExp(`^${taskColPrefix}([A-Za-z0-9-]+)_([0-9][0-9])_(.*)$`);
          const r = colId.match(regex);
          if (r != null) {
            taskName = r[3];
          }
          payload.taskName = taskName;
        }

        if (colId === self.COLUMN_AGGRID_AUTOCOLUMN || payload.newValue == payload.oldValue) {
          //do nothing
        } else if (self.triggeredByFillOperation) {
          self.pendingListByFillOperation.push(payload);
        } else if (self.isPasteInProgress) {
          self.processValueChangedList.push(payload);
        } else {
          self.processValueChangedList.push(payload);
          self.inProgressLabel = self.$t('task.progress.updating_tasks');
          self.processValueChanged(event.api);
        }
      },
      onColumnRowGroupChanged: function(event) {
        self.maxLevel = 0;
        event.api.forEachNode(node => {
          if (node.level > self.maxLevel) {
            self.maxLevel = node.level;
          }
        });
      },
      onFirstDataRendered: function(event) {
        self.maxLevel = 0;
        event.api.forEachNode(node => {
          if (node.level > self.maxLevel) {
            self.maxLevel = node.level;
          }
        });
        if (typeof self.layoutProfile.taskExpandLevel === 'undefined') {
          self.gridOptions.groupDefaultExpanded = self.expandLevel = self.maxLevel;
        }
        
        event.api.resetRowHeights();
        
        // Fixed an issue that stage cell has no value because options is not ready when it is rendered.
        // Refresh Cells as the options should now ready.
        event.api.refreshCells({ force: true, columns: ['stage']});
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.columnApi
              .getAllGridColumns()
              .findIndex(col => {
                return col === self.lastOpenColumnMenuParams.column;
              });

          //Rearrange column position only when it is not -1
          if (columnMenuColumnIndex > -1) {
            if (self.isCompactView) {
              //Feature Specification: keep task col position arrangement when the colKey is task column
              //Only rearrange position for non task col.
              if (!colKey.startsWith('taskcol_')) {
                if (self.lastOpenColumnMenuParams.column.colId.startsWith('taskcol_')) {
                  const colIds = params.columnApi.getAllGridColumns().map(i => i.colId);
                  const startIndex = columnMenuColumnIndex;
                  const len = colIds.length;
                  columnMenuColumnIndex = len - 2; //set second last index as default value
                  if (params.visible) {
                    for (let i = startIndex; i < len; i++) {
                      if (!colIds[i].startsWith('taskcol_')) {
                        columnMenuColumnIndex = i - 1;
                        break;
                      }
                    }
                  } else {
                    //Rearrange the newly hidden non task col column
                    const cols = params.columnApi.getAllGridColumns().map(i => { 
                      return { 
                        colId: i.colId
                        , headerName: i.colDef.headerName
                        , hide: i.colDef.hide
                        , pinned: i.pinned
                        , groupId: i.colId.startsWith('taskcol_') && i.originalParent != null? i.originalParent.groupId : null
                        , groupHeaderName: i.colId.startsWith('taskcol_') && i.originalParent != null && i.originalParent.colGroupDef != null? i.originalParent.colGroupDef.headerName : null
                      }
                    })
                    const columnState =  params.columnApi.getColumnState();
                    //Get the actual property(hide) value from columnState
                    for (const col of columnState) {
                      const found = cols.find(i => i.colId == col.colId)
                      if (found) {
                        found.hide = col.hide;
                      }
                    }

                    //Find out the new position for colKey column among hidden columns
                    const lastVisibleColIndex = cols.findLastIndex(i => i.hide == false);
                    const found = cols.find(i => i.colId == colKey);
                    columnMenuColumnIndex = lastVisibleColIndex - 1; //Set fallback column position
                    for (let i = len - 1; i > lastVisibleColIndex; i--) {
                      //Compare against groupHeaderName in case it is a task col with group headerName
                      if (cols[i].groupHeaderName != null) {
                        if (cols[i].groupHeaderName <= found.headerName) {
                          columnMenuColumnIndex = i - 1;
                          break;
                        }
                      }
                      else if (cols[i].headerName != null) {
                        if (cols[i].headerName <= found.headerName) {
                          columnMenuColumnIndex = i - 1;
                          break;
                        }
                      }
                    }
                  }
                    
                }
                params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);  
              }
            } else {
              params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
            }
          }
          
        }

        // For non compact view, sort the columns
        // Columns sorting of compact view will be performed in loadTasks() function and is more efficient
        if (!self.isCompactView) {
          const cols = params.columnApi.getAllGridColumns().map(i => { 
            return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned, groupId: i.colId.startsWith('taskcol_') && i.originalParent != null? i.originalParent.groupId : null }} )
          const columnState =  params.columnApi.getColumnState();
          //get the actual hide value from columnState
          for (const col of columnState) {
            const found = cols.find(i => i.colId == col.colId)
            if (found) {
              found.hide = col.hide;
            }
          }
          cols.sort(self.columnDefSortFunc)
          for (const [index,c] of cols.entries()) {
            params.columnApi.moveColumn(c.colId, index);
          }
        }
        self.saveColumnState();
        
        const shownColumns = params.columns.filter(i => i.visible && !i.colId.startsWith(self.taskColPrefix));
        if (shownColumns.some(i => !self.columnCache.includes(i.colId))) {
          taskLoaded = {};  // clear the cache so we reload the values from the different columns
          self.loadTasks(self.id, {
            callback: () =>{
              if(params.api) {
                setTimeout(() => {
                  params.api.resetRowHeights();
                }, 10);
                
                if(self.gridOptions.api.getDisplayedRowCount() < 1) {
                  params.api.showNoRowsOverlay();
                } else {
                  params.api.hideOverlay();
                }
              }
            }
          });
        }
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onSortChanged: function(/** event */) {
        self.saveColumnState();
        const value = self.gridOptions.columnApi.getColumnState().find(s => s.sort != null)
        
        if (value) {
          self.sort = { colId: value.colId === 'ag-Grid-AutoColumn' ? 'name' : value.colId, direction: value.sort };
        }
        else {
          delete self.sort;
        }
        
        if (!self.isCompactView) {
          self.sortChanged = true; // force a refresh of the tree
          self.showInProgress(self.$t('task.grid.loading'));
          self.loadTasks(self.id, { callback: function() {
            self.sortChanged = false;
            self.inProgressShow = false;
          }});
        }
      },
      onDragStopped: function(/** event */) {
        self.saveColumnState();
      },
      onGridSizeChanged: function(event) {
        const width = event.api.gridBodyCtrl && event.api.gridBodyCtrl.eBodyViewport? event.api.gridBodyCtrl.eBodyViewport.clientWidth : 0;
        self.autoGroupColumnDef.pinned = width < 800 ? '' : 'left';
        event.api.setAutoGroupColumnDef(self.autoGroupColumnDef);
      },
      onRowDataUpdated: function() {
        self.resetPreviousScrollPosition(self.previousScrollPosLeft, self.previousScrollPosTop);
        //self.inProgressShow = false;
      },
      onRangeSelectionChanged: function(event) {
        if (event.finished == true) {
          if (self.triggeredByFillOperation) {
            const pendingList = self.pendingListByFillOperation.splice(0, self.pendingListByFillOperation.length);
            self.processValueChangedList.push(...pendingList);
            self.consolidateChangedCellValues(self.processValueChangedList);
            self.triggeredByFillOperation = false;
            self.inProgressLabel = self.$t('task.progress.updating_tasks');
            self.processValueChanged(event.api);
          }
          const cellRanges = event.api.getCellRanges();
          const originalRanges = cellRanges.map(i => {
            return {
              rowStartIndex: i.startRow.rowIndex,
              rowEndIndex: i.endRow.rowIndex,
              columns: i.columns.map(j => j.colId)
            }
          });
          if (cellRanges.length > 0) {
            const lastRange = (() => {
                const tmp = cellRanges[cellRanges.length-1];
                const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex;
                return {
                  rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
                  rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
                  columns: tmp.columns.map(i => i.colId),
                  startColumn: tmp.startColumn.colId,
                  orgRowStartIndex: tmp.startRow.rowIndex,
                  orgRowEndIndex: tmp.endRow.rowIndex
                }
              })();
            const lastColumnId = lastRange.startColumn == lastRange.columns[0]? lastRange.columns[lastRange.columns.length-1] : lastRange.columns[0]; 
            //Handle drag selection which starts (mouse click press) or finishes (mouse click release) on rowSelector column. 
            //Brief: Include all cells of the selected rows into the cell ranges as if user clicks on rowSelector columns
            if (lastRange.columns.length > 1 && (lastColumnId == 'rowSelector' || lastRange.startColumn == 'rowSelector')) {
              self.rowSelectorChanged({
                cellRanges,
                originalRanges,
                lastRange,
                api: event.api, 
                columnApi: event.columnApi 
              })
            } else {
              //Brief: Reshape previous ranges to avoid clashing with last/latest range.
              const lastRangeColumns = lastRange.columns;
              const isLastRangeAsc = lastRange.rowStartIndex <= lastRange.rowEndIndex;
              const lastRangeStartIndex = isLastRangeAsc? lastRange.rowStartIndex : lastRange.rowEndIndex;
              const lastRangeEndIndex = isLastRangeAsc? lastRange.rowEndIndex : lastRange.rowStartIndex;

              const cRanges = cellRanges.map(i => {
                return {
                  rowStartIndex: i.startRow.rowIndex,
                  rowEndIndex: i.endRow.rowIndex,
                  columns: i.columns.map(j => j.colId)
                }
              })
              cRanges.splice(cRanges.length - 1, 1);
              const newCellRanges = [];
              let hasChanges = false;

              //Before reshape, check if last range's start cell clashes with previous cell range.
              //If yes, do nothing (and expect aggrid will do its job to remove cell ranges)
              const startColumnId = lastRange.startColumn;
              const startCell = {
                rowStartIndex: lastRange.orgRowStartIndex,
                rowEndIndex: lastRange.orgRowStartIndex
              }
              let isStartCellClashed = false;
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex;
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex;
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex;
                const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex;
                const columnClashed = range.columns.filter(i => i == startColumnId).length > 0;
                if (isRowClashed && columnClashed) {
                  isStartCellClashed = true;
                  break;
                }
              }
              if (isStartCellClashed) {
                return;
              }

              //Reshape previous ranges to avoid clashing with last/latest range.
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex;
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex;
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex;
                const isRowClashed = (lastRangeStartIndex >= cellRangeStartIndex && lastRangeStartIndex <= cellRangeEndIndex) ||
                                     (cellRangeStartIndex >= lastRangeStartIndex && cellRangeStartIndex <= lastRangeEndIndex);
                const clashedColumns = range.columns.filter(i => lastRangeColumns.includes(i));                
                if (!isRowClashed || clashedColumns.length == 0) {
                  newCellRanges.push(range);
                  continue;
                }
                
                //Reshape cell range if it clash with lastRange.
                const splitTopNeeded = cellRangeStartIndex < lastRangeStartIndex;
                const splitBottomNeeded = cellRangeEndIndex > lastRangeEndIndex;
                const firstClashedCol = clashedColumns[0];
                const splitLeftNeeded = range.columns.findIndex(i => i == firstClashedCol) != 0;
                const lastClashedCol = clashedColumns[clashedColumns.length-1];
                const splitRightNeeded = range.columns.findIndex(i => i == lastClashedCol) != range.columns.length-1;

                if (splitTopNeeded) {
                  newCellRanges.push({
                    rowStartIndex: cellRangeStartIndex,
                    rowEndIndex: lastRangeStartIndex-1,
                    columns: objectClone(range.columns)
                  });
                  hasChanges = true;
                }

                if (splitRightNeeded) {
                  const startColIdx = range.columns.findIndex(i => i == lastClashedCol)+1;
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(startColIdx)
                  });
                  hasChanges = true;
                }

                if (splitBottomNeeded) {
                  newCellRanges.push({
                    rowStartIndex: lastRangeEndIndex+1,
                    rowEndIndex: cellRangeEndIndex,
                    columns: objectClone(range.columns)
                  });
                  hasChanges = true;
                }

                if (splitLeftNeeded) {
                  const sliceLen = range.columns.findIndex(i => i == firstClashedCol);
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(0, sliceLen)
                  });
                  hasChanges = true;
                }
              }

              //Merge last range to any existing range when condition met.
              //Conditions: 1) Matched rows and column(s) in sequence, or 
              //            2) Matched columns and row(s) in sequence.
              let hasRangeMerged = false;
              if (newCellRanges.length > 0) {
                const allColumns = event.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId);
                const lastRowStartColIndex = allColumns.findIndex(i => i == lastRangeColumns[0]);
                const lastRowEndColIndex = allColumns.findIndex(i => i == lastRangeColumns[lastRangeColumns.length - 1]);
                const cloned = objectClone(newCellRanges);
                const newRanges = [];
                for (const cRange of cloned) {
                  const startColIndex = allColumns.findIndex(i => i == cRange.columns[0]);
                  const endColIndex = allColumns.findIndex(i => i == cRange.columns[cRange.columns.length - 1]);
                  const isRowIndexMatched = cRange.rowStartIndex == lastRangeStartIndex && cRange.rowEndIndex == lastRangeEndIndex;
                  const isColumnMatched = startColIndex == lastRowStartColIndex && endColIndex == lastRowEndColIndex;
                  const isRowInSequence = cRange.rowStartIndex -1 == lastRange.rowEndIndex || cRange.rowEndIndex + 1 == lastRange.rowStartIndex;
                  const isColumnInSequence = startColIndex - 1 == lastRowEndColIndex || endColIndex + 1 == lastRowStartColIndex;
                  if (isRowIndexMatched && isColumnInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex
                      , rowEndIndex: lastRangeEndIndex
                      , columns: lastRowStartColIndex < startColIndex? [...lastRangeColumns, ...cRange.columns] : [...cRange.columns, ...lastRangeColumns]
                    });
                    hasRangeMerged = true;
                    continue;
                  } else if (isColumnMatched && isRowInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex < cRange.rowStartIndex? lastRangeStartIndex : cRange.rowStartIndex
                      , rowEndIndex: lastRangeEndIndex > cRange.rowEndIndex? lastRangeEndIndex : cRange.rowEndIndex
                      , columns: lastRangeColumns
                    })
                    hasRangeMerged = true;
                    continue;
                  }
                  newRanges.push(cRange);
                }
                if (hasRangeMerged) {
                  newCellRanges.splice(0, newCellRanges.length, ...newRanges);
                }
              }
              
              //hasChanges flag is important to avoid infinite loop. 
              //any addCellRange() call will trigger onRangeSelectionChange() event.
              //Don't call addCellRange() when no change is required.
              if (hasChanges || hasRangeMerged) {
                //Adding last range when hasRangeMerged is false. 
                //Details: If hasRangeMerged is true, the last range has been merged to one of the previous range.
                if (!hasRangeMerged) {
                  newCellRanges.push({
                    rowStartIndex: lastRange.rowStartIndex,
                    rowEndIndex: lastRange.rowEndIndex,
                    columns: lastRange.columns
                  });
                }
                event.api.clearRangeSelection();
                for (const ncRange of newCellRanges) {
                  event.api.addCellRange(ncRange);
                }
              } else {
                //When thing settles down, update taskSelection variable.
                let selectedTasks = [];
                let compactApplyTemplateTasks = []; //Collect target parent (when user select task col). Multiple identical uuId means number of times of inserting template to same parent.
                let compactApplyTemplateTaskSet = new Set(); //Collect target parent (When user select the AgGrid_AutoColumn cell or property cell). Only one is allowed.
                for (const oRange of originalRanges) {
                  const startRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowEndIndex : oRange.rowStartIndex;
                  const endRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowStartIndex : oRange.rowEndIndex;
                  const columns = oRange.columns;
                  const taskCols = columns.filter(i => i.startsWith(self.taskColPrefix));
                  for (let i = startRowIdx; i <= endRowIdx; i++) {
                    const rowNode = event.api.getDisplayedRowAtIndex(i);
                    
                    if (rowNode == null) {
                      continue;
                    }

                    //Handle non task Cols.
                    if ((columns.length - taskCols.length) > 0) {
                      //Treat any non task cell selection is ag-Grid-AutoColumn cell selection.
                      selectedTasks.push({ 
                         uuId: rowNode.data.uuId
                        , name: rowNode.data.name
                        , parent: rowNode.data.pUuId
                        , parentName: rowNode.data.pName
                        , colId: self.COLUMN_AGGRID_AUTOCOLUMN
                        , rowIndex: rowNode.rowIndex
                      });

                      if(self.isCompactView) {
                        compactApplyTemplateTaskSet.add(rowNode.data.uuId)
                      }
                    }

                    //Handle task cols (which exist in compact view)
                    if (taskCols.length > 0) {
                      for(const tCol of taskCols) {
                        if (rowNode.data[tCol] &&
                            rowNode.data[tCol].uuId != null) {
                          selectedTasks.push({ 
                            uuId: rowNode.data[tCol].uuId
                            , name: rowNode.data[tCol].data.name
                            , parent: rowNode.data.uuId
                            , parentName: rowNode.data.name
                            , colId: tCol
                            , rowIndex: rowNode.rowIndex
                          });
                        }
                        
                        if(self.isCompactView) {
                          compactApplyTemplateTasks.push(rowNode.data.uuId)
                        }
                      }
                    }
                  }
                }
                
                if (self.isCompactView) {// Only applicable in compact view.
                  //Remove any selected taskcol items if the 'ag-Grid-AutoColumn' of the same row is selected.
                  const summaryTasks = selectedTasks.filter(i => i.colId == self.COLUMN_AGGRID_AUTOCOLUMN);
                  for (const sTask of summaryTasks) {
                    selectedTasks = selectedTasks.filter(i => i.parent != sTask.uuId);
                  }

                  self.compactApplyTemplateTarget.splice(0, self.compactApplyTemplateTarget.length, ...compactApplyTemplateTasks, ...compactApplyTemplateTaskSet)
                }

                //Rearrange order of the tasks.
                //Tasks without rowIndex will be pushed to the bottom of the list. Theorectically, all tasks should have rowIndex property.
                //The first task/summary task will be target parent for new task creation.
                selectedTasks.sort(function( a, b ) {
                  if (a.rowIndex == null && b.rowIndex == null) {
                    return 0;
                  }
                  if (b.rowIndex == null || a.rowIndex < b.rowIndex){
                    return -1;
                  }
                  if (a.rowIndex == null || a.rowIndex > b.rowIndex){
                    return 1;
                  }
                  return 0;
                });
                
                if (self.taskSelection.length !== selectedTasks.length || 
                    !self.taskSelection.every((o, idx) => o.uuId === selectedTasks[idx].uuId)) {
                  self.taskSelection.splice(0, self.taskSelection.length, ...selectedTasks);
                }
              }
            }
          } else if (!self.dragging) {
            //Clean up taskSelection when range selection is empty.
            self.taskSelection.splice(0, self.taskSelection.length);
            event.api.clearFocusedCell();
          }
          event.api.refreshHeader();


        }
      },
      onRowGroupOpened(event) {
        //Reset row selection and range selection when clicking row group icon to expand/collapse.
        if (event.api.getCellRanges().length > 0) {
          event.api.deselectAllFiltered();
          event.api.clearRangeSelection();
        }

        if (self.isTaskExpandedByActionBar == false) {
          //The following logic is for action: user clicks on the expand/collapse icon in the autoGroupColumn column.
          let rowUuId = event.data.uuId;
          if(event.node.expanded) {
            self.manualExpandedSet.add(rowUuId);
            self.manualCollapsedSet.delete(rowUuId);
          } else {
            self.manualExpandedSet.delete(rowUuId);
            self.manualCollapsedSet.add(rowUuId);
          }
        }
      },
    };
    
    const defaultCellClass = (params) => { return params.data != null && params.data.taskType === 'Project' ? ['grid-cell-summary']: [] };

    this.autoGroupColumnDef = {
      headerName: this.$t('task.title'),
      pinned: 'left',
      minWidth: 150,
      width: 200,
      menuTabs: ['columnsMenuTab'],
      editable: this.canEdit(this.permissionName, ['name'])? (params) => { 
          return params.data && params.data.taskPath != null && params.data.readOnly != true 
        } : false,
      cellStyle: params => {
        const defaultStyle = {
          'height': '100%',
          'display': 'flex ',
          'align-items': 'center',
          'overflow': 'hidden',
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap'
        };
        if (params.data &&
          params.data.color &&
          this.coloring.task) {
          return { ...defaultStyle, background: params.node.data.color, color: invertColor(params.node.data.color, true) };
        }
        else if (params.data &&
          params.data.stageColor &&
          this.coloring.stage) {
          const color = getFirstColor(params.data.stageColor);
          if (color) return { ...defaultStyle, background: color, color: invertColor(color, true) };
        }
        else if (params.data &&
          params.data.rebateColor &&
          this.coloring.rebate) {
          const color = getFirstColor(params.data.rebateColor);
          if (color) return { ...defaultStyle, background: color, color: invertColor(color, true) };
        }
        else if (params.data &&
          params.data.fileColor &&
          this.coloring.file) {
          const color = getFirstColor(params.data.fileColor);
          if (color) return { ...defaultStyle, background: color, color: invertColor(color, true) };
        }
        else if (params.data &&
          params.data.staffColor &&
          this.coloring.staff) {
          const color = getFirstColor(params.data.staffColor);
          if (color) return { ...defaultStyle, background: color, color: invertColor(color, true) };
        }
        else if (params.data &&
          params.data.skillColor &&
          this.coloring.skill) {
          const color = getFirstColor(params.data.skillColor);
          if (color) return { ...defaultStyle, background: color, color: invertColor(color, true) };
        }
        else if (params.data &&
          params.data.resourceColor &&
          this.coloring.resource) {
          const color = getFirstColor(params.data.resourceColor);
          if (color) return { ...defaultStyle, background: color, color: invertColor(color, true) };
        }
        return defaultStyle;
      },
      cellClass: canEdit? (params) => {
        if (params == null || params.colDef == null) {
          return [];
        }
        let isEditable = false;
        if (typeof params.colDef.editable == 'function') {
          isEditable = params.colDef.editable(params);
        } else {
          isEditable = params.colDef.editable == true? true : false;
        }
        
        if (!isEditable) {
          return ['read-only'];
        }
        return [];
      } : ['read-only'],
      cellEditor: 'nameEditor',
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: 'detailLinkCellRenderer',
        enableReadonlyStyle: true
      },
      comparator: (valueA, valueB, nodeA, nodeB) => {
        if(nodeA.group == nodeB.group) {
          if (valueA === null && valueB === null) {
            return 0;
          }
          if (valueA === null) {
            return -1;
          }
          if (valueB === null) {
            return 1;
          }          
          if (!nodeA.data.label) {
            return -1;
          }
          if (!nodeB.data.label) {
            return 1;
          }
          
          return nodeA.data.label.toLowerCase().localeCompare(nodeB.data.label.toLowerCase());
        } else if(nodeA.group) {
          return 1;
        } else if(nodeB.group) {
          return -1;
        }
      },
      valueSetter: function(params) {
        const newValue = params.newValue.trim();
        const oldValue = objectClone(params.data.name);
        if (newValue !== '' && newValue != oldValue) {
          self.$set(params.data, 'oldName', oldValue);
          params.data.name = newValue;
          return true;
        }
        return false;
      },
      getQuickFilterText: function(params) {
          return params.data ? params.data.name : '';
      }
      
    };

    const colDefs = [
      {
        headerName: this.$t('task.field.actualCost'),
        field: 'actualCost',
        minWidth: 150,
        hide: false,
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
          , customCurrencyProp: 'currencyCode'
        },
        comparator: numberComparator
      },
      {
        headerName: this.$t('task.field.actualCostNet'),
        field: 'actualCostNet',
        minWidth: 150,
        hide: true,
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
          , customCurrencyProp: 'currencyCode'
        },
        comparator: numberComparator
      },
      {
        headerName: this.$t('task.field.actualDuration'),
        field: 'totalActualDuration',
        hide: false,
        minWidth: 150,
        cellEditor: 'workEffortEditor',
        editable: canEdit? (params) => {
            return params.data != null && params.data.taskType !== 'Project' && params.data.readOnly != true
          } : false,
        comparator: durationComparator
      },
      {
        headerName: this.$t('task.field.autoScheduling'),
        field: 'autoScheduling',
        minWidth: 100,
        hide: true,
        cellEditor: 'listEditor',
        cellEditorParams: { 
          options: [
            { value: true, text: this.$t('task.autoschedule.auto') },
            { value: false, text: this.$t('task.autoschedule.manual') }
          ]
        },
        cellRenderer: 'taskAutoSchedulingCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.taskType !== 'Project' && params.data.readOnly != true
          } : false,
        // cellClass: (params) => {
        //   const classes = defaultCellClass(params);
        //   if (params.data == null || params.data.taskType === 'Project') {
        //     classes.push('cell-disabled');
        //   }
        //   return classes;
        // },
        comparator: booleanComparator
      },
      {
        headerName: this.$t('task.field.closeTime'),
        field: 'closeTime',
        minWidth: 150,
        hide: false,
        cellRenderer: 'dateTimeCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'dateTimeEditor',
        cellEditorParams: { 
          labelDate: this.$t('task.field.closeTime')
        },
        editable: canEdit? (params) => { 
            return params.data && params.data.taskType == 'Task' && params.data.readOnly != true
          } : false,
        getQuickFilterText: function(params) {
            return self.toDateTime(params.value);
        },
        comparator: numberComparator
      },
      {
        headerName: this.$t('field.color'),
        field: 'color',
        cellRenderer: 'colorCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        hide: true,
        cellEditor: 'colorEditor',
        editable: canEdit? (params) => { 
            return params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false
      },
      {
        headerName: this.$t('task.field.complexity'),
        field: 'complexity',
        minWidth: 150,
        hide: true,
        cellEditor: 'listEditor',
        cellEditorParams: { options: this.optionComplexity, isEnumType: true },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        // cellClass: (params) => {
        //   const classes = defaultCellClass(params);
        //   if (params.data == null || params.data.uuId == 'ROOT') {
        //     classes.push('cell-disabled');
        //   }
        //   return classes;
        // }
      },
      {
        headerName: this.$t('task.field.constraint'),
        colId: 'constraint',
        field: 'constraint',
        minWidth: 205,
        hide: true,
        cellEditor: 'constraintEditor',
        cellEditorParams: { field: 'type', options: this.optionConstraint },
        cellRenderer: 'constraintCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.taskType !== 'Project' && params.data.readOnly != true 
          } : false,
        // cellClass: (params) => {
        //   const classes = defaultCellClass(params);
        //   if (params.data == null || params.data.taskType === 'Project' || params.data.uuId == 'ROOT') {
        //     classes.push('cell-disabled');
        //   }
        //   return classes;
        // },
        comparator: constraintComparator
      },
      {
        headerName: this.$t('task.field.currencyCode'),
        field: 'currencyCode',
        cellEditor: 'listEditor',
        cellRenderer: 'enumCellRenderer',
        cellRendererParams: { options: this.optionCurrency, enableReadonlyStyle: true },
        cellEditorParams: { options: this.optionCurrency, isEnumType: true },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        hide: true
      },
      {
        headerName: this.$t('task.field.description'),
        field: 'description',
        minWidth: 150,
        hide: true,
        cellEditor: 'multilineEditor',
        cellEditorParams: { title: this.$t('task.edit_description') },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
      },
      {
        headerName: this.$t('task.field.estimatedCost'),
        field: 'estimatedCost',
        hide: false,
        minWidth: 150,
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
          , customCurrencyProp: 'currencyCode'
        },
        comparator: numberComparator
      },
      {
        headerName: this.$t('task.field.estimatedCostNet'),
        field: 'estimatedCostNet',
        hide: true,
        minWidth: 150,
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
          , customCurrencyProp: 'currencyCode'
        },
        comparator: numberComparator
      },
      {
        headerName: this.$t('task.field.estimatedDuration'),
        field: 'estimatedDuration',
        hide: false,
        minWidth: 150,
        cellEditor: 'durationEditor',
        cellStyle: {
          'height': '100%',
          'display': 'flex ',
          'align-items': 'center '
        },
        editable: canEdit? (params) => { 
            return params.data && params.data.taskType == 'Task' && params.data.readOnly != true
          } : false,
        comparator: durationComparator
      },
      {
        headerName: this.$t('task.field.fixedDuration'),
        field: 'fixedDuration',
        hide: false,
        minWidth: 150,
        cellEditor: 'durationEditor',
        cellStyle: {
          'height': '100%',
          'display': 'flex ',
          'align-items': 'center '
        },
        valueGetter: (params) => {
          if (typeof params.data.fixedDuration !== 'undefined' &&
              params.data.fixedDuration !== '' &&
            // eslint-disable-next-line
            !/\d+[\.,m,h,D,W,M,Y]/.test(params.data.fixedDuration)) {
            return params.data.fixedDuration + 'D';
          }
          return params.data.fixedDuration;
        },
        editable: canEdit? (params) => { 
            return params.data && (params.data.taskType == 'Task' || params.data.taskType == 'Project') && params.data.readOnly != true
          } : false,
        comparator: durationComparator
      },
      {
        headerName: this.$t('task.field.estimatedTimeToComplete'),
        field: 'estimatedTimeToComplete',
        hide: true,
        minWidth: 150,
        cellEditor: 'durationEditor',
        cellStyle: {
          'border-width': '1px',
          'height': '100%',
          'display': 'flex ',
          'align-items': 'center '
        },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.taskType == 'Task' && params.data.readOnly != true
          } : false,
        comparator: durationComparator
      },
      {
        headerName: this.$t('task.field.fixedCost'),
        field: 'fixedCost',
        hide: true,
        minWidth: 150,
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
          , customCurrencyProp: 'currencyCode'
        },
        cellEditor: 'costEditor',
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        comparator: numberComparator
      },
      {
        headerName: this.$t('task.field.fixedCostNet'),
        field: 'fixedCostNet',
        hide: true,
        minWidth: 150,
        cellStyle: {
          'border-width': '1px',
          'height': '100%',
          'display': 'flex ',
          'align-items': 'center '
        },
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
          , customCurrencyProp: 'currencyCode'
        },
        comparator: numberComparator,
      },
      {
        headerName: this.$t('task.field.fixedCostTotal'),
        field: 'totalFixedCost',
        hide: true,
        minWidth: 150,
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
          , customCurrencyProp: 'currencyCode'
        },
        comparator: numberComparator
      },
      {
        headerName: this.$t('task.field.fixedCostTotalNet'),
        field: 'totalFixedCostNet',
        hide: true,
        minWidth: 150,
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
          , customCurrencyProp: 'currencyCode'
        },
        comparator: numberComparator
      },
      {
        headerName: this.$t('field.identifier_full'),
        field: 'identifier',
        minWidth: 150,
        hide: true,
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        cellEditor: 'stringEditor',
      },
      {
        headerName: this.$t('task.field.image'),
        field: 'avatarRef',
        minWidth: 150,
        hide: true,
        cellRenderer: 'imageCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        // cellClass: (params) => {
        //   const classes = defaultCellClass(params);
        //   if (params.data == null || params.data.uuId == 'ROOT') {
        //     classes.push('cell-disabled');
        //   }
        //   return classes;
        // },
        cellEditor: 'imageEditor',
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false
      },
      {
        headerName: this.$t('task.field.notes'),
        field: 'notes',
        minWidth: 150,
        hide: false,
        cellRenderer: 'noteCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'commentEditor',
        cellEditorParams: { 
          entityName: 'TASK'
        },
        editable: canEdit? (params) => { 
          return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
        } : false,
        comparator: noteComparator
      },
      {
        headerName: this.$t('task.field.priority'),
        field: 'priority',
        minWidth: 150,
        hide: true,
        cellEditor: 'listEditor',
        cellEditorParams: { options: this.optionPriority, isEnumType: true },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false
      },
      {
        headerName: this.$t('task.field.progress'),
        field: 'progress',
        minWidth: 150,
        hide: false,
        cellRenderer: 'percentageCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'percentageEditor',
        editable: canEdit? (params) => { 
            return params.data && params.data.taskType !== 'Project' && params.data.readOnly != true
          } : false,
        comparator: numberComparator
      },
      {
        headerName: this.$t('task.field.rebates'),
        field: 'rebates',
        minWidth: 150,
        hide: true,
        cellRenderer: 'rebateCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'rebateEditor',
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        comparator: rebateComparator,
      },
      {
        headerName: this.$t('task.field.resources'),
        field: 'resources',
        minWidth: 150,
        hide: true,
        cellRenderer: 'taskResourceCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'resourceEditor',
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        comparator: resourceComparator,
        // cellClass: (params) => {
        //   const classes = defaultCellClass(params);
        //   if (params.data == null || params.data.uuId == 'ROOT') {
        //     classes.push('cell-disabled');
        //   }
        //   return classes;
        // }
      },
      {
        headerName: this.$t('task.field.skills'),
        field: 'skills',
        minWidth: 150,
        hide: false,
        cellRenderer: 'taskSkillCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'skillEditor',
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        comparator: skillComparator,
        // cellClass: (params) => {
        //   const classes = defaultCellClass(params);
        //   if (params.data == null || params.data.uuId == 'ROOT') {
        //     classes.push('cell-disabled');
        //   }
        //   return classes;
        // }
      },
      {
        headerName: this.$t('task.field.staffs'),
        field: 'staffs',
        minWidth: 150,
        hide: false,
        cellRenderer: 'taskStaffCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'staffEditor',
        cellEditorParams: { 
          companyList: () => this.project ? this.project.companyList : null
        },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        comparator: staffComparator,
        // cellClass: (params) => {
        //   const classes = defaultCellClass(params);
        //   if (params.data == null || params.data.uuId == 'ROOT') {
        //     classes.push('cell-disabled');
        //   }
        //   return classes;
        // }
      },
      {
        headerName: this.$t('task.field.stage'),
        field: 'stage',
        minWidth: 150,
        hide: true,
        cellRenderer: 'stageCellRenderer',
        cellRendererParams: { options: this.optionStages, enableReadonlyStyle: true },
        cellEditor: 'stageEditor',
        cellEditorParams: { options: this.optionStages },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true 
          } : false,
        comparator: stageComparator,
        // cellClass: (params) => {
        //   const classes = defaultCellClass(params);
        //   if (params.data == null || params.data.uuId == 'ROOT') {
        //     classes.push('cell-disabled');
        //   }
        //   return classes;
        // }
      },
      {
        headerName: this.$t('task.field.startTime'),
        field: 'startTime',
        minWidth: 150,
        hide: false,
        cellRenderer: 'dateTimeCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'dateTimeEditor',
        cellEditorParams: { 
          labelDate: this.$t('task.field.startTime'),
          optional: true
        },
        editable: canEdit? (params) => { 
            return params.data && params.data.taskType !== 'Project' && params.data.readOnly != true
          } : false,
        getQuickFilterText: function(params) {
            return self.toDateTime(params.value);
        },
        comparator: numberComparator
      },
      {
        headerName: this.$t('field.tag'),
        field: 'tag',
        minWidth: 150,
        hide: true,
        cellEditor: 'tagEditor',
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false
      },
      {
        headerName: this.$t('task.field.taskType'),
        field: 'taskType',
        minWidth: 150,
        hide: false,
        cellRenderer: 'taskTypeCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        getQuickFilterText: function(params) {
            return params.value === 'Project' ? 'Summary Task' : params.value;
        }
      },
      {
        headerName: this.$t('task.field.template'),
        field: 'template',
        hide: true,
        cellRenderer: 'taskTemplateCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'taskTemplateEditor',
        minWidth: 120,
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.taskType === 'Project' && params.data.readOnly != true
          } : false,
        // cellClass: (params) => {
        //   const classes = defaultCellClass(params);
        //   if (params.data == null || params.data.uuId == 'ROOT' || params.data.taskType !== 'Project') {
        //     classes.push('cell-disabled');
        //   }
        //   return classes;
        // }
      },
      {
        headerName: this.$t('task.field.taskpath'),
        field: 'taskPath',
        minWidth: 150,
        hide: true,
        // cellClass: (params) => {
        //   const classes = defaultCellClass(params);
        //   if (params.data == null || params.data.uuId == 'ROOT') {
        //     classes.push('cell-disabled');
        //   }
        //   return classes;
        // },
        valueGetter: (params) => {
          if (!self.exporting &&
              params.data.taskPath) {
            return params.data.taskPath.substr(params.data.taskPath.indexOf('\n') + 1).replace(/\n/g, ' / ');
          }
          return params.data.taskPath;
        }
      },
      {
        headerName: this.$t('task.field.parentTasks'),
        field: 'parentTasks',
        minWidth: 150,
        hide: true,
        sortable: false,
        valueGetter: (params) => {
          if (!self.exporting &&
              params.data.taskPath) {
            const start = params.data.taskPath.indexOf('\n');
            return params.data.taskPath.substr(start + 1, params.data.taskPath.lastIndexOf('\n') - (start + 1)).replace(/\n/g, ' / ');
          }
          return params.data.taskPath;
        }
      }
      
      
    ];

    const linkedEntities = [
        { selector: 'PROJECT.TASK.TAG', field: 'tag', properties: ['name'] }
      , { selector: 'PROJECT.TASK.STAGE', field: 'stage', properties: ['name'] }
      , { selector: 'PROJECT.TASK.TASK-SKILL', field: 'skills', properties: ['level'] }
      , { selector: 'PROJECT.TASK.TASK-STAFF', field: 'staffs', properties: ['duration', 'durationAUM', 'utilization'] }
      , { selector: 'PROJECT.TASK.TASK-RESOURCE', field: 'resources', properties: ['utilization', 'quantity'] }
      , { selector: 'PROJECT.TASK.NOTE', field: 'notes', properties: ['text', 'identifier', 'modified'] }
      , { selector: 'PROJECT.TASK.NOTE.USER', field: 'notes', properties: ['firstName', 'lastName'] }
      , { selector: 'PROJECT.TASK.PROJECT_TEMPLATE', field: 'template', properties: ['name'] }
      , { selector: 'PROJECT.TASK.SKILL', field: 'skills', properties: ['name'] }
      , { selector: 'PROJECT.TASK.RESOURCE', field: 'resources', properties: ['name'] }
      , { selector: 'PROJECT.TASK.REBATE', field: 'rebates', properties: ['name'] }
      , { selector: 'PROJECT.TASK.STAFF', field: 'staffs', properties: ['firstName', 'lastName', 'genericStaff'] }
      , { selector: 'PROJECT.TASK.STAGE', field: 'stageColor', properties: ['color'] }
      , { selector: 'PROJECT.TASK.SKILL', field: 'skillColor', properties: ['color'] }
      , { selector: 'PROJECT.TASK.RESOURCE', field: 'resourceColor', properties: ['color'] }
      , { selector: 'PROJECT.TASK.REBATE', field: 'rebateColor', properties: ['color'] }
      , { selector: 'PROJECT.TASK.STAFF', field: 'staffColor', properties: ['color'] }
      , { selector: 'PROJECT.TASK.STORAGE_FILE', field: 'fileColor', properties: ['color'] }
    ]

    const viewLinkedEntities = JSON.parse(JSON.stringify(linkedEntities));
    viewLinkedEntities.push(...[
      { selector: 'PROJECT.TASK', field: 'fixedCost', properties: ['currencyCode'] }
      , { selector: 'PROJECT.TASK', field: 'fixedCostNet', properties: ['currencyCode'] }
      , { selector: 'PROJECT.TASK', field: 'actualCost', properties: ['currencyCode'] }
      , { selector: 'PROJECT.TASK', field: 'actualCostNet', properties: ['currencyCode'] }
      , { selector: 'PROJECT.TASK', field: 'estimatedCost', properties: ['currencyCode'] }
      , { selector: 'PROJECT.TASK', field: 'estimatedCostNet', properties: ['currencyCode'] }
      , { selector: 'PROJECT.TASK', field: 'totalFixedCost', properties: ['currencyCode'] }
      , { selector: 'PROJECT.TASK', field: 'totalFixedCostNet', properties: ['currencyCode'] }
    ])
    
    const requests = [
      this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
      , getCustomFieldInfo(this, 'TASK').catch(e => this.httpAjaxError(e))
      , getCustomFieldInfo(this, 'SKILL_LINK', null, { customFieldsPropName: 'skillCustomFields' }).catch(e => this.httpAjaxError(e))
      , getCustomFieldInfo(this, 'RESOURCE_LINK', null, { customFieldsPropName: 'resourceCustomFields' }).catch(e => this.httpAjaxError(e))
      , getCustomFieldInfo(this, 'NOTE', null, { customFieldsPropName: 'noteCustomFields' }).catch(e => this.httpAjaxError(e))
    ];
    Promise.allSettled(requests)
    .finally(() => {
      if (Array.isArray(this.customFields) && this.customFields.length > 0) {
        for (const c of this.customFields) {
          this.badgeFilterFields.push({
            value: c.name
            , text: c.displayName
          })
        }
      }
      

      const customDefs = []
      prepareCustomFieldColumnDef(customDefs, this.customFields, { self: this })
      
      if (customDefs.length > 0) {
        for (const def of customDefs) {
          //only editable when it is not project/ROOT row.
          def.editable = (params) => {
            return params.data?.uuId != 'ROOT' && params.data.readOnly != true
          }
        }
        colDefs.push(...customDefs);
      }

      //VIEW permission: Remove column from display list
      filterOutViewDenyProperties(colDefs, 'PROJECT.TASK', viewLinkedEntities);
      
      //Update linkedEntities for EDIT permission checking
      //Some fields need to work as a group. If one of them being read-only will lead to failure of updating value as they are updated as a group.
      const durationGroup = ['startTime', 'closeTime', 'duration', 'durationAUM', 'lockDuration', 'autoScheduling', 'constraintType', 'constraintTime'];
      const editList = [
        { selector: 'PROJECT.TASK', field: 'startTime', properties: durationGroup }
        , { selector: 'PROJECT.TASK', field: 'closeTime', properties: durationGroup }
        , { selector: 'PROJECT.TASK', field: 'estimatedDuration', properties: durationGroup }
        , { selector: 'PROJECT.TASK', field: 'constraint', properties: durationGroup }
        , { selector: 'PROJECT.TASK', field: 'autoScheduling', properties: durationGroup }
        , { selector: 'PROJECT.TASK', field: 'estimatedTimeToComplete', properties: durationGroup }
        , { selector: 'PROJECT.TASK', field: 'totalActualDuration', properties: ['STAFF'] } //No edit when STAFF is read-only
      ]
      for (const item of editList) {
        const index = linkedEntities.findIndex(i => i.field == item.field && i.selector == item.selector)
        linkedEntities.splice(index < 0? 0:index, index < 0?0:1, item)
      }

      //EDIT permission: set column to be read only.
      setEditDenyPropertiesReadOnly(colDefs, 'PROJECT.TASK', linkedEntities);
      colDefs.sort(this.columnDefSortFunc);
      colDefs.unshift(this.getRowSelectorColumn());
      this.columnDefs = colDefs;
      
      if (this.isGridReady == true) {
        //Continue loading layout profile.
        const gridApi = this.gridOptions.api;
        this.loadLayoutProfile()
        .finally(() => {
          if(this.id && this.invalidList != null && !this.invalidList.includes(this.id)) {
            gridApi.showLoadingOverlay();
          } else {
            gridApi.showNoRowsOverlay();
          }
        });
        this.isGridReady = false
      }
      this.isCustomFieldsReady = true;
    })

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      hide: false,
      editable: false,
      cellRenderer: 'genericCellRenderer',
      cellRendererParams: {
        enableReadonlyStyle: true
      },
      menuTabs: ['columnsMenuTab'],
      columnsMenuParams: {
        contractColumnSelection: true
      },
      lockPinned: true,
      cellStyle: params => {
        if (params.column.colId !== this.COLUMN_AGGRID_AUTOCOLUMN) {
          return {
            'height': '100%',
            'display': 'flex ',
            'align-items': 'center ',
          }
        }
        else {
          if (self.hasImage(params)) {
            return {
              'height': '100%'
            }
          }
          else {
            return {
              'height': '25px'
            }
          }
        }
      },
      cellClass: defaultCellClass,
      suppressKeyboardEvent: (params) => {
        if (params.event.keyCode === 46 
            || params.event.keyCode === 35 || params.event.keyCode == 36 
            || (params.event.keyCode == 37 && params.event.ctrlKey)) {
          return true;
        } else if (params.event.keyCode == 13) {//'Enter'
          if (params.event.ctrlKey) {
            return true;
          }
          if ((typeof params.colDef.editable == 'boolean' && !params.colDef.editable) || 
              (typeof params.colDef.editable == 'function' &&  !params.colDef.editable({ data: params.data }))) {
            return true;
          }
        } else if (params.event.ctrlKey && params.event.keyCode == 68) { //'D'
          return true;
        }
        return false;
      }
    };
    
    this.context = {
      componentParent: self
    };

    this.populateTaskConstraint();
    this.populateTaskTemplates();
  },
  async created() {
    this.getModelInfo();
    this.getDurationConversionOpts();
    this.templateTaskUtil = templateTaskUtil;
    this.isGridReady = false;
    this.isCustomFieldsReady = false;
    this.rowDragOffsetY = -7;
    this.rowDragPrevOverNodeIds = [];
    this.isDateCalcInProgress = false;
    this.isPasteInProgress = false;
    this.cellCopyPrefix = 'PRJTL_COPIED_OBJ=';
    this.taskColPrefix = 'taskcol_';
    this.taskGroupPrefix = 'taskgroup_';
    this.COLUMN_AGGRID_AUTOCOLUMN = 'ag-Grid-AutoColumn';
    this.entityId = this.$route.params.id;
    this.userId = this.$store.state.authentication.user.uuId;
    this.nodeChildrenList = null;
    this.nodeId = null;
    this.manualExpandedSet = new Set();
    this.manualCollapsedSet = new Set();
    this.isTaskExpandedByActionBar = false;
    this.invalidList = ['tasks', 'gantt', 'staff'];
    this.originalRowDataList = [];
    if(this.id == null || this.invalidList.includes(this.id)) {
      this.resetAlert({ msg: this.$t('error.no_active_project_selected'), alertState: alertStateEnum.ERROR });
      this.actionProcessing = true;
    }
    
    await this.prepareProjectDetails();
    // Prepare companyId. This is needed for the profile loading and will be used by taskgroup management.
    if (!localStorage.companyId) {
      await this.prepareCompanyId();
    } else {
      this.companyId = localStorage.companyId;
    }

    this.loadUserProfile(); // User profile holds Task views
    this.loadPublicProfile(); // Public profile holds public Staff views

    this.taskDialogOpen = this.$route.query.tdOpen;
    this.taskDialogId = this.$route.query.tId;

    this.tabTimeoutId = null;
    this.tabRowIndex = null;
    this.tabColumn = null;

    this.lastFocusedCell = null;

    document.addEventListener('keydown', this.keyDownHandler);
    this.cellEditingTriggeredByEnter = false;

    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }

    this.templateApplyDetails = null;
  },
  mounted() {
    document.addEventListener('click', this.stopEditing);
    const viewport = document.querySelector(".ag-center-cols-viewport");
    if (viewport) {
      viewport.addEventListener('click', this.onClickGrid);
    }
    document.addEventListener('keyup', this.loseCellFocusOnEscapeKey);

    //BadgeFilter related
    document.addEventListener('click', this.toggleBadgeFilterFocus);
    document.addEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
  },
  beforeDestroy() {
    //BadgeFilter related
    document.removeEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
    document.removeEventListener('click', this.toggleBadgeFilterFocus);
    this.badgeFilterFocus = false;
    this.badgeFilterModalOpened = 'close';
    
    document.removeEventListener('click', this.stopEditing);
    const viewport = document.querySelector(".ag-center-cols-viewport");
    if (viewport) {
      viewport.removeEventListener('click', this.onClickGrid);
    }
    document.removeEventListener('keyup', this.loseCellFocusOnEscapeKey);    
    this.nodeChildrenList = null;
    this.nodeId = null;
    this.taskDialogOpen = null;
    this.taskDialogId = null;
    this.invalidList = null;
    this.rowDragPrevOverNodeIds = null;

    this.manualExpandedSet = null;
    this.manualCollapsedSet = null;
    this.originalRowDataList = null;    
    
    document.removeEventListener('keydown', this.keyDownHandler);
    
    this.keyDownListenerId = null;

    if (this.tabTimeoutId != null) {
      clearTimeout(this.tabTimeoutId);
    }
    this.tabRowIndex = null;
    this.tabColumn = null;

    this.lastFocusedCell = null;
    this.templateApplyDetails = null;
    this.isGridReady = false;

    this.searchData = null;
    this.templateTaskUtil = null;
  },
  watch: {
    alertMsg: function(nVal, oVal) {
      if (oVal != nVal && nVal == null) {
        this.updateGridHeight();
      }
    }
  },
  computed: {         
    infoMouseEnterEvent() {
      return isTouchDevice()? null : 'mouseenter';
    },
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    overlayLoadingTemplate() {
      if (this.total !== 0) {
        return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('task.grid.loading_percent', [(this.loaded / this.total * 100).toFixed(0)]) }</span>`;
      }
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('task.grid.loading') }</span>`;
    },
    disableEdit() {
      return this.taskSelection.length != 1 || this.taskSelection.find(i => i.uuId == 'ROOT') != null;
    },
    disableDelete() {
      return this.taskSelection.length < 1 || this.taskSelection.find(i => i.uuId == 'ROOT') != null;
    },
    disableOutdent() {
      if (this.taskSelection.find(i => i.uuId == 'ROOT') != null) {
        return true;
      }
      const level = this.isCompactView? 1: 0;
      const filtered = this.taskSelection
        .filter(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN)
        .filter(i => {
          const rowNode = this.gridOptions.api.getRowNode(i.uuId);
          return rowNode != null && rowNode.level > level;
        });
      return this.taskSelection.length < 1 || filtered.length == 0;
    },
    disableIndent() {
      if (this.taskSelection.find(i => i.uuId == 'ROOT') != null) {
        return true;
      }
      const hasNoQualifiedParent = (tSelection) => {
        const filtered = tSelection
        .filter(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN)
        .find(i => {
          const rowNode = this.gridOptions.api.getRowNode(i.uuId);
          if (rowNode != null) {
            for (let idx = rowNode.rowIndex - 1; idx >= 0; idx--) {
              const prev = this.gridOptions.api.getDisplayedRowAtIndex(idx);
              if (prev == null || prev.level < rowNode.level) {
                break;
              } else if (prev.level > rowNode.level) {
                continue;
              } else if (prev != null && prev.level === rowNode.level && prev.data.taskType == 'Project') {
                return false;
              }
            }
          }
          return true;
        });
        return typeof filtered !== 'undefined';
      }
      return this.taskSelection.length < 1 || hasNoQualifiedParent(this.taskSelection);
    },
    disableTaskCopy() {
      return this.taskSelection.length  < 1 || this.taskSelection.find(i => i.uuId == 'ROOT') != null;
    },
    disableTaskPaste() {
      return this.taskCopyIds.length < 1 || this.taskSelection.length > 1;
    },
    disableWhenMoreThanOne() {
      return this.taskSelection.length > 1;
    },
    disableManualScheduleTask() {
      if ( this.taskSelection.find(i => i.uuId == 'ROOT') != null) {
        return true;
      }
      if (this.isCompactView) {
        return this.taskSelection
        .filter(i => {
          if (i.colId == this.COLUMN_AGGRID_AUTOCOLUMN) {
            const rowNode = this.gridOptions.api.getRowNode(i.uuId);
            if (rowNode.data.taskType == 'Project') {
              return false;
            }
            return rowNode.data.autoScheduling;
          }
          //Handle task from task column
          const colObj = this.gridOptions.api.getRowNode(i.parent).data[i.colId];
          return colObj != null && colObj.data != null && colObj.data.autoScheduling;
        }).length < 1;
      } else {
        return this.taskSelection
        .filter(i => {
          const rowNode = this.gridOptions.api.getRowNode(i.uuId);
          return rowNode.data.taskType != 'Project' && rowNode.data.autoScheduling;
        }).length < 1;
      }
    },
    disableAutoScheduleTask() {
      if ( this.taskSelection.find(i => i.uuId == 'ROOT') != null) {
        return true;
      }
      if (this.isCompactView) {
        return this.taskSelection
        .filter(i => {
          if (i.colId == this.COLUMN_AGGRID_AUTOCOLUMN) {
            const rowNode = this.gridOptions.api.getRowNode(i.uuId);
            if (rowNode.data.taskType == 'Project') {
              return false;
            }
            return rowNode.data.autoScheduling;
          }
          //Handle task from task column
          const colObj = this.gridOptions.api.getRowNode(i.parent).data[i.colId];
          return colObj != null && colObj.data != null && colObj.data.autoScheduling != true;
        }).length < 1;
      } else {
        return this.taskSelection
        .filter(i => {
          const rowNode = this.gridOptions.api.getRowNode(i.uuId);
          return rowNode.data.taskType != 'Project' && rowNode.data.autoScheduling != true;
        }).length < 1;
      }
    },
    highlightAutoSchedule() {
      if (this.selected.length == 0) {
        return false;
      }
      
      let selected = [];
      for (const uuId of this.selected) {
        selected.push(this.gridOptions.api.getRowNode(uuId));
      }
      // The selected list can't contain any manuals
      return selected.filter(s => !s.autoScheduling).length == 0;
    },
    schedulerMouseEnterEvent() {
      return isTouchDevice()? null : 'mouseenter';
    },
    viewMouseEnterEvent() {
      return isTouchDevice()? null : 'mouseenter';
    },
    filterMouseEnterEvent() {
      return isTouchDevice()? null : 'mouseenter';
    },
    badgeFilterMouseEnterEvent() {
      return isTouchDevice()? null : 'mouseenter';
    },
    badgeFilterMouseOverEvent() {
      return isTouchDevice()? null : 'mouseover';
    },
    badgeFilterMouseLeaveEvent() {
      return isTouchDevice()? null : 'mouseleave';
    },
    badgeFilterTouchEndEvent() {
      return isTouchDevice()? 'touchend' : null;
    },
    priorityNavMouseOverEvent() {
      return isTouchDevice()? null : 'mouseover';
    },
    priorityNavTouchEndEvent() {
      return isTouchDevice()? 'touchend' : null;
    },
    disableManualScheduleProject() {
      return !this.project.autoScheduling;
    },
    disableAutoScheduleProject() {
      return this.project.autoScheduling;
    },
    compactViewEnabled() {
      return this.isCompactView;
    },
    tcShowApplyAllCheckbox() {
      return this.tcConfirmDeleteTasks.length > 0;
    },
    tcConfirmDeleteStatement() {
      const key = this.taskCol.parentName == null? 'task.confirmation.taskcol_delete_from_project' : 'task.confirmation.taskcol_delete';
      return this.$t(key, [this.taskCol.taskName, this.taskCol.parentName]);
    },
    taskColumnDefs() {
      return taskColumnDefs(this);
    },
    maxNameLength() {
      var values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === 'name';
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === 'identifier';
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxDescriptionLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === 'description';
      });
      return values.length !== 0 ? values[0].max : 10000;
    },
    colorMouseEnterEvent() {
      return isTouchDevice()? null : 'mouseenter';
    },
    confirmApplyToTaskStatement() {
      return this.confirmApplyChangeToTaskColName? this.$t('confirm_apply_change_to_task_arg', [this.confirmApplyChangeToTaskColName]) : this.$t('confirm_apply_change_to_task');
    }
  },
  methods: {
    getStageUuid(name) {
      return this.stageMap[name];
    },
    async locationCalendar(locationId) {
      let data = await locationService.calendar(locationId)
      .then(response => {
        return (response && response.data && response.data.jobCase? response.data[response.data.jobCase] : []) || [];
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      })
      if (data.length > 0) {
        this.digestCalendarResponse(data);

      }
    },
    async systemLocationCalendar() {
      this.calendarType.holderId = null;
      this.calendarType.type = 'system';
      let data = await calendarService.get([{ uuId: '00000000-0000-0000-0000-000000000000'}])
      .then(response => {
        return (response && response.data? response.data : []) || [];
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      })
      if (data.length > 0) {
        this.digestCalendarResponse(data, ['base_calendar']);
      } else {
        this.$set(this, 'calendar', DEFAULT_CALENDAR); // Fallback to default calendar.
      }
    },
    digestCalendarResponse(data, calendarOrder=['location','base_calendar']) {
      const calendar = {};
      const existingTypes = [];
      for(const order of calendarOrder) {
        const calObj = data.find(i => i.name === order); 
        if(!calObj) {
          continue;
        }
        const calendarList = calObj.calendarList;
        
        for(const type of calendarList) {
          if (!type || type.length < 1 || (calendar[type.type] && calendar[type.type][0].calendar !== order)) {
            continue;
          }
          if(!calendar[type.type]) {
            existingTypes.push(type.type);
            calendar[type.type] = [];
          }
          const cloned = cloneDeep(type);
          cloned.calendar = order;
          if(cloned.startHour) {
            cloned.startHour = msToTime(cloned.startHour);
          }
          if(cloned.endHour) {
            cloned.endHour = msToTime(cloned.endHour);
          }
          calendar[type.type].push(cloned);
        }
      }
      
      const nonDayOfWeek = ['Leave', 'Working']
      const types = Object.keys(calendar);
      for(const type of types) {
        const typeObj = calendar[type];
        if(!nonDayOfWeek.includes(type) || typeObj.isWorking) {
          //typeObj.startHour = msToTime(typeObj.startHour);
          //typeObj.endHour = msToTime(typeObj.endHour);
        }
      }
      if (this.calendarType.type == 'project-location') {
        this.projectCalendar = JSON.parse(JSON.stringify(calendar));
      } else if (this.calendarType.type == 'system') {
        this.systemCalendar = JSON.parse(JSON.stringify(calendar));
      }
      this.$set(this, 'calendar', calendar);
    },
    hasImage(params) {
      return this.gridOptions.columnApi.getColumn("avatarRef") !== null && 
             this.gridOptions.columnApi.getColumn("avatarRef").visible && 
             params.data &&
             params.data.avatarRef && 
             params.data.avatarRef !== "00000000-0000-0000-0000-000000000000";
    },
    onClickGrid(event) {
      const src = event.target;
      const viewport = document.querySelector(".ag-center-cols-viewport");
      if (viewport) {
        if (viewport === src) {
          this.gridOptions.api.stopEditing();
        }
      }
    },
    stopEditing(event) {
      const src = event.target;
      var parentElement = src.parentElement;
      var isGrid = src.classList.contains('ag-cell-wrapper');
      var isEmptyArea = src.classList.contains('ag-body-viewport');
      var element = document.getElementById('project-tasks-grid');
      var rect = element.getBoundingClientRect();
      
      if (isEmptyArea && this.gridOptions != null && this.gridOptions.api != null) {
        // user clicked on the empty area in the grid where no rows exist
        this.gridOptions.api.stopEditing();
        return;
      }
      
      // Some Span elements do not get detected by element.contains so
      // the client coordinates must be used
      if ((src !== element && element.contains(src)) ||
          (event.clientX > rect.left && event.clientX < rect.right &&
           event.clientY > rect.top && event.clientY < rect.bottom)) {
        isGrid = true;
      }
  
      var isModal = src.firstChild &&
                    typeof src.firstChild.classList !== 'undefined' && 
                    src.firstChild.classList.contains('modal-dialog');
      while (parentElement) {
        if (parentElement.id === 'project-tasks-grid') {
          isGrid = true;
          break;
        }
        else if (parentElement.classList.contains('modal-content')) {
          isModal = true;
          break;
        }
        parentElement = parentElement.parentElement;
      }
      
      if (!isGrid && !isModal && this.gridOptions != null && this.gridOptions.api != null) {
        this.gridOptions.api.stopEditing();
      }
    },
    expandLevels() {
      if (typeof this.layoutProfile.taskExpandLevel !== 'undefined') {
        this.gridOptions.groupDefaultExpanded = this.expandLevel = this.layoutProfile.taskExpandLevel;
        this.processNodes();
      }
    },
    updateColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = { 
        name: this.saveName, 
        uuId: profile.uuId, 
        type: 'task', 
        defaultView: profile.defaultView,
        sharedVisibility: cloneDeep(profile.sharedVisibility),
        sharingMembers: cloneDeep(profile.sharingMembers),
        editingPermissions: cloneDeep(profile.editingPermissions),
        expandLevel: this.expandLevel, 
        columns: this.layoutProfile.taskColumns,
        coloring: this.coloring,
        compactColumns: this.layoutProfile.compactTaskColumns,
        compactView: this.layoutProfile.compactView != null? this.layoutProfile.compactView : false,
        filterText: cloneDeep(this.filterText),
        flatList: cloneDeep(this.flatList)
      };
      this.saveIndex = index;
      this.promptSaveShow = true;
    },
    copyColumnSettings(name , profile) {
      const columns = profile.columns;
      this.saveName = `${name} ${this.$t('dataview.copy_text')}`;
      this.saveProfile = { 
        name: `${name} ${this.$t('dataview.copy_text')}`,
        uuId: null,
        type: 'task',
        sharedVisibility: 'private',
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        expandLevel: profile.expandLevel, 
        columns: columns,
        filterText: cloneDeep(profile.filterText),
        flatList: cloneDeep(profile.flatList)
      };
      this.saveIndex = -1;
      this.promptSaveShow = true;
    },
    shareColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = profile;
      this.saveIndex = index;
      this.promptShareShow = true;
    },
    removeColumnSettings(index) {
      this.confirmDeleteViewShow = true;
      this.deleteViewIndex = index;
    },
    confirmDeleteViewOk() {
      const toRemove = this.views.splice(this.deleteViewIndex, 1);
      viewProfileService.remove([{ uuId: toRemove[0].uuId }], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadViewSettings(view) {
      if (typeof view.expandLevel !== 'undefined') {
        this.layoutProfile.taskExpandLevel = this.gridOptions.groupDefaultExpanded = this.expandLevel = view.expandLevel;
        this.processNodes();
      }
      
      const coloringChanged = JSON.stringify(view.coloring) !== JSON.stringify(this.coloring) 
      this.loadColors(view.coloring);
      this.layoutProfile.task_view_coloring = this.coloring;
      
      this.filterText = view.filterText ? view.filterText : '';

      let oldFlatList = this.flatList;
      this.flatList = typeof view.flatList !== 'undefined' ? view.flatList : false;
      if (oldFlatList !== this.flatList) {
        // the flatList mode has changed, redraw
        let rowData = [];
        this.gridOptions.api.forEachNode(node => rowData.push(node.data));
        this.gridOptions.api.setRowData([]);
        this.gridOptions.api.setRowData(rowData);
      }
      
      this.badgeFilters = Array.isArray(view.tasksBadgeFilter) ?  view.taskBadgeFilter : [];
      this.layoutProfile.tasksFilterText = this.filterText;
      this.changeFilter(this.filterText, false);
      
      this.layoutProfile.compactView = view.compactView != null? view.compactView : false;
      const isDifferentView = this.isCompactView != this.layoutProfile.compactView;
      this.isCompactView = this.layoutProfile.compactView;
      
      // Only keep the columns that are valid for this context
      var keys = this.columnDefs.map(c => c.field);
      keys.push(this.COLUMN_AGGRID_AUTOCOLUMN); // include the auto group column
      var columns = view.columns.filter(c => keys.includes(c.colId));

      let compactColumns = view.compactColumns != null? view.compactColumns : [];
      compactColumns = compactColumns.filter(c => keys.includes(c.colId) || (c.groupId != null && c.groupId.startsWith(this.taskGroupPrefix)));
      // No valid columns? Show nothing. We have to do this or
      // loadColumnSettings will exit early if there are no columns.
      if (this.layoutProfile.compactView && compactColumns.length == 0) {
        this.gridOptions.api.setColumnDefs([]);
        return;
      } else if (!this.layoutProfile.compactView && columns.length == 0) {
        this.gridOptions.api.setColumnDefs([]);
        return;
      }
      this.layoutProfile.taskColumns = columns;
      this.layoutProfile.compactTaskColumns = compactColumns;      
      this.loadColumnSettings();
      
      // save the view name in the profile
      this.layoutProfile.viewName = view.name;
      
      // Save the new layout after applying it
      this.updateLayoutProfile({ clearViewName: false });

      const shownColumns =
          columns
          ? columns.filter(i => i.colId !== this.COLUMN_AGGRID_AUTOCOLUMN).map(i => i.colId)
          : [];
      if (isDifferentView || shownColumns.some(i => !this.columnCache.includes(i))) {
        taskLoaded = {};  // clear the cache so we reload the values from the different columns
        this.loadTasks(this.id, {
          callback: () =>{
            if(this.gridOptions != null && this.gridOptions.api != null) {
              if(this.gridOptions.api.getDisplayedRowCount() < 1) {
                this.gridOptions.api.showNoRowsOverlay();
              } else {
                this.gridOptions.api.hideOverlay();
              }
            }
          }
        });
      }
      else if (coloringChanged) {
        this.gridOptions.api.redrawRows();
      }
      
      this.$store.dispatch("breadcrumb/updateView", view.name, { root: true });
    },
    verifySkills(skills) {
      for (let i = skills.length - 1; i >= 0; i--) {
        const skill = skills[i];
        const skillEnums = this.enumList.SkillLevelEnum;
        if (!skillEnums.find(s => s.value === skill.level)) {
          skills.splice(i, 1);
        }
      }
      return skills;
    },
    loadColumnSettings() {
      if (this.gridOptions.api == null) {
        return;
      }
      
      let columns = this.isCompactView? this.layoutProfile.compactTaskColumns : this.layoutProfile.taskColumns;

      if (columns == null || columns.length == 0) {
        // The user doesn't have custom columns yet, so use defaults
        const columnDefs = cloneDeep(this.columnDefs);
        const index = columnDefs.findIndex(i => i.field == 'taskType');
        if (index != -1) {
          columnDefs[index].cached = true;
        }
        this.gridOptions.api.setAutoGroupColumnDef([]);
        this.gridOptions.api.setAutoGroupColumnDef(this.autoGroupColumnDef);
        this.gridOptions.api.setColumnDefs([]);
        this.gridOptions.api.setColumnDefs(columnDefs);
        this.gridOptions.api.resetRowHeights();
        return;
      }

      //Set autoGroupColumn
      const autoGroupSetting = columns.find(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN);
      if (autoGroupSetting) {
        this.autoGroupColumnDef.width = autoGroupSetting.width;
        this.autoGroupColumnDef.sort = autoGroupSetting.sort;
        this.gridOptions.api.setAutoGroupColumnDef({
          ...this.autoGroupColumnDef
        })
      }

      const propOpts = this.getTaskGroupProperty();

      //Populate taskgroup column definition into columns if there is any task group.
      const clonedColumns = objectClone(columns);
      for (let i = 0, len = clonedColumns.length; i < len; i++) {
        const col = clonedColumns[i];
        
        // restore the sort order
        if (col.sort) {
          this.sort = { colId: col.colId === 'ag-Grid-AutoColumn' ? 'name' : col.colId, direction: col.sort };
        }
        
        if (col.groupId != null) {
          if (col.children == null) {
            continue;
          }
          const totalMode = !!col.totalMode;
          const metadata = taskColumnMetadata(this, col.property);          
          const groupColDef = metadata.groupColumnDef;
          groupColDef.groupId = col.groupId;
          groupColDef.headerName = col.headerName;
          groupColDef.property = col.property;
          groupColDef.lastModified = col.lastModified;
          groupColDef.totalMode = totalMode;
          groupColDef.headerGroupComponentParams.totalMode = totalMode;
          groupColDef.hasDifferences = groupColDef.headerGroupComponentParams.hasDifferences = !!col.hasDifferences;
          const foundDef = this.columnDefs.find(c => c.field == col.property);
          groupColDef.headerGroupComponentParams.propertyLabel = foundDef != null? foundDef.headerName : null;
          groupColDef.headerGroupComponentParams.property = foundDef != null? foundDef.field : null;
          groupColDef.headerGroupComponentParams.propertyOpts = propOpts;
          groupColDef.native = !!col.native;
          columns[i] = groupColDef;
          
          //Add the column definition to the existing group column's children list.
          const children = col.children;
          for (let j = 0, jLen = children.length; j < jLen; j++) {
            const child = children[j];
            const colDef = cloneDeep(metadata.columnDef);
            colDef.colId = child.colId;
            colDef.field = child.colId;
            colDef.headerName = child.headerName;
            colDef.native = child.native != null? child.native : false;
            colDef.color = child.color != null? child.color : null;
            colDef.skills = Array.isArray(child.skills)? this.verifySkills(child.skills) : null;
            colDef.staff = Array.isArray(child.staff)? child.staff : null;
            colDef.resources = Array.isArray(child.resources)? child.resources : null;
            colDef.rebates = Array.isArray(child.rebates)? child.rebates : null;
            colDef.tags = Array.isArray(child.tags)? child.tags : null;
            colDef.width = child.width;
            colDef.sort = child.sort;
            colDef.cellRendererParams.totalMode = totalMode;
            
            if (colDef.comparatorFunc != null) {
              //Get a new comparator based on totalMode
              colDef.comparator = colDef.comparatorFunc(totalMode);
            }
            colDef.hide = child.hide;
            columns[i].children.push(colDef);
          }

          //Add a task utility child column if children is empty. It will be removed in loadTasks() when at least 1 tasks is found. 
          //Reason: Ag-grid will remove any group column with empty children.
          //We need the group column to be returned by api.getColumnDefs() in loadTasks().
          //Task utility provide a button to let user to open task group local modal.
          if (columns[i].children.length == 0) {
            columns[i].children.push(this.getTaskUtilColDef())
          }
        }
      }
      
      const columnDefTemplate = cloneDeep(this.columnDefs);
      //Add task group defs from columns to columnDefTemplate if there is any.
      const taskGroupDef = columns.filter(c => c.colId == null);
      if (taskGroupDef != null && taskGroupDef.length > 0) {
        columnDefTemplate.push(...taskGroupDef);
      }
      
      // order the columns based upon the order in 'columns'
      const rowSelectorColIndex = columnDefTemplate.findIndex(i => i.colId == 'rowSelector');
      const columnDefs = [];
      //First column must be the rowSelector column. 
      if (rowSelectorColIndex != -1) {
        columnDefs.push(columnDefTemplate.splice(rowSelectorColIndex, 1)[0]);
      }
      columns.forEach(function(col) {
        if (col.groupId != null) {
          let index = columnDefTemplate.findIndex(c => c.groupId === col.groupId);
          if (index == -1) {
            return;
          }
          columnDefs.push(columnDefTemplate[index]);
          columnDefTemplate.splice(index, 1);
        } else {
          let index = columnDefTemplate.findIndex((c) => {
            if (c.colId != null) {
              return c.colId == col.colId;
            }
            return c.field === col.colId;
          });
          if (index == -1) {
            return;
          }
          columnDefs.push(columnDefTemplate[index]);
          columnDefTemplate.splice(index, 1);
        }
      });
      columnDefs.push(...columnDefTemplate);

      //Setting width and sort. (For non-task column only)
      for (const column of columnDefs) {
        if (column.colId == 'rowSelector') {
          //RowSelector column is always visible, even when it is missing from user profile.
          continue;
        }
        const setting = columns.find(c => c.colId != null && (c.colId === column.field || c.colId === column.colId));
        if (setting == null) {
          column.hide = true;
        } else {
          column.hide = setting.hide;
          column.width = setting.width;
          column.sort = setting.sort;
        }
      }

      const index = columnDefs.findIndex(i => i.field == 'taskType');
      if (index != -1) {
        columnDefs[index].cached = true;
      }
      

      this.gridOptions.api.setAutoGroupColumnDef([]);
      this.gridOptions.api.setAutoGroupColumnDef(this.autoGroupColumnDef);
      this.gridOptions.api.setColumnDefs([]);
      this.gridOptions.api.setColumnDefs(columnDefs);
      this.gridOptions.api.resetRowHeights();
      return false;
    },
    savePreset() {
      this.saveName = null;
      this.saveIndex = -1;
      this.saveProfile = { 
        name: this.saveName, 
        type: 'task', 
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        expandLevel: this.expandLevel, 
        columns: this.layoutProfile.taskColumns,//columns.map(c => { return { colId: c.colId, width: c.actualWidth, sort: c.sort }}),
        compactColumns: this.layoutProfile.compactTaskColumns,
        coloring: this.coloring,
        compactView: this.layoutProfile.compactView != null? this.layoutProfile.compactView : false,
        filterText: cloneDeep(this.filterText),
        badgeFilters: JSON.parse(JSON.stringify(this.badgeFilters)),
        flatList: cloneDeep(this.flatList)
      };
      this.promptSaveShow = true;
    },
    async updateUsers(profile, updateUsers, service) {
      if (updateUsers) {
        const users = updateUsers.split(',');
        for (const user of users) {
          const profileData = await service.list(this.entityId, user)
           .then(response => {
             return response.data[response.data.jobCase];
          });
         
          if (profileData.length > 0) {
            profileData[0]['task_view_coloring'] = profile.coloring;
            profileData[0]['tasksBadgeFilter'] = profile.filter;
            profileData[0]['tasksFilterText'] = profile.filterText;
            profileData[0]['viewName'] = profile.name;
            profileData[0]['taskExpandLevel'] = profile.expandLevel;
            profileData[0]['compactView'] = profile.compactView;
            profileData[0]['taskColumns'] = profile.columns;
            profileData[0]['compactTaskColumns'] = profile.compactColumns;
            profileData[0]['flatList'] = profile.flatList;
            await service.update(profileData, this.entityId, user)
          }
        }
      }
    },
    confirmSaveOk({ /**name,*/ profile, newDefault, updateUsers, sharing }) {      
      if (newDefault) {
        // find the existing default view and turn it off
        const defaultView = this.views.find(v => v.defaultView);
        if (defaultView) {
          defaultView.defaultView = false;
          viewProfileService.updatePreset([defaultView], this.userId)
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
        }
      }
      
      this.updateUsers(profile, updateUsers, layoutProfileService);
      
      if (this.saveIndex !== -1) {
        this.views.splice(this.saveIndex, 1, profile);
      }
      else {
        this.addViews([profile]);
      }
      
      if (!sharing) {
        // save the view name in the profile
        this.layoutProfile.viewName = profile.name;
        this.$store.dispatch("breadcrumb/updateView", profile.name, { root: true });
        this.updateLayoutProfile({ clearViewName: false });
      }
    },
    toggleCurrentColumnVisibility(toHide) {
      const columnDefs = this.gridOptions.api.getColumnDefs();
      for (const cDef of columnDefs) {
        if (cDef.colId == 'rowSelector') {
          cDef.hide = false; //rowSelector is always visible.
          continue;
        }
        if (cDef.groupId != null && cDef.groupId.startsWith(this.taskGroupPrefix)) {
          const children = cDef.children;
          if (children == null || children.length == 0) {
            continue;
          }
          for (const child of children) {
            child.hide = toHide;
          }
        } else {
          cDef.hide = toHide;
        }
      }
      this.gridOptions.api.setColumnDefs(columnDefs);
    },
    showAllColumns() {
      this.toggleCurrentColumnVisibility(false);
    },
    showNoColumns() {
      this.toggleCurrentColumnVisibility(true);
    },
    initializeLayoutProfile() {
      if (!Object.prototype.hasOwnProperty.call(this.layoutProfile, 'taskColumns')) {
        this.layoutProfile.taskColumns = [];
      }
      if (!Object.prototype.hasOwnProperty.call(this.layoutProfile, 'compactTaskColumns')) {
        this.layoutProfile.compactTaskColumns = [];
      }
      if (!Object.prototype.hasOwnProperty.call(this.layoutProfile, 'compactView')) {
        this.layoutProfile.compactView = false;
      }
    },
    async createLayoutProfile() {
      this.initializeLayoutProfile();
      await layoutProfileService.create([this.layoutProfile], this.entityId, this.userId).then((response) => {
        const data = response.data[response.data.jobCase];
        this.layoutProfile.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    updateLayoutProfile({ successCallback=null, failCallback=null, clearViewName=true } = {}) {
      // clear the view name from the breadcrumb
      if (clearViewName) {
        this.layoutProfile.viewName = null;
        this.$store.dispatch("breadcrumb/clearView");
      }
      
      if (!Object.prototype.hasOwnProperty.call(this.layoutProfile, 'uuId')) {
        // Dataviews are triggering the watchers when opening the
        // relevant tab and trying to save. Ignore those since nothing
        // has loaded yet.
        return;
      }
      layoutProfileService.update([this.layoutProfile], this.entityId, this.userId)
      .then(() => { 
        if (successCallback != null) {
          successCallback();
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
        if (failCallback != null) {
          failCallback();
        }
      });
    },
    async getProfileTaskGroups() {
      if (!localStorage.companyId) {
        const data = await companyService.list({limit: -1, start: 0}).then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
          return null;
        });

        if (data != null) {
          const company = data.filter(d => d.type === 'Primary');
          if (company.length > 0) {
            localStorage.companyId = company[0].uuId;
            this.companyId = company[0].uuId;
          }
        }
      }

      return await taskGroupProfileService.list(localStorage.companyId, this.userId)
      .then(result => {
        if (result.hasError) {
          return null;
        }
        return result.list;
      })
      .catch(e => {
        console.error(e) //eslint-disable-line no-console
        return null;
      });
    },
    prepareCompanyId() {
      return companyService.list({limit: -1, start: 0})
      .then((response) => {
        const data = response.data;
        if (data != null) {
        const company = data.filter(d => d.type === 'Primary');
        if (company.length > 0) {
          localStorage.companyId = company[0].uuId;
          this.companyId = company[0].uuId;
        }
      }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColors(profile) {
      const self = this;
      self.coloring.none = profile ? profile.none : true;
      self.coloring.staff = profile ? profile.staff : false;
      self.coloring.task = profile ? profile.task : false;
      self.coloring.stage = profile ? profile.stage : false;
      self.coloring.rebate = profile ? profile.rebate : false;
      self.coloring.skill = profile ? profile.skill : false;
      self.coloring.resource = profile ? profile.resource : false;
      self.coloring.file = profile ? profile.file : false;
    },
    async loadLayoutProfile() {
      const self = this;
      const profileData = await layoutProfileService.list(this.entityId, this.userId).then((response) => {
        return response.data[response.data.jobCase];
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
        return null;
      });
      
      if (profileData.length === 0) {
        await self.createLayoutProfile();
        this.useDefault = true;
        const defaultView = this.views.find(v => v.defaultView);
        if (defaultView) {
          this.loadViewSettings(defaultView);
        }
        else {
          // expand all by default
          self.gridOptions.groupDefaultExpanded = 999;
        }
      } else {
        self.layoutProfile = profileData[0];
        self.initializeLayoutProfile();
        self.gridOptions.groupDefaultExpanded = self.expandLevel = self.layoutProfile.taskExpandLevel;
        if (typeof self.layoutProfile.taskExpandLevel === 'undefined') {
          self.gridOptions.groupDefaultExpanded = -1;
        }
        self.isCompactView = self.layoutProfile.compactView;
        self.loadColumnSettings();
        self.loadColors(self.layoutProfile.task_view_coloring);
        self.filterText = typeof self.layoutProfile.tasksFilterText !== 'undefined' ? self.layoutProfile.tasksFilterText : '';
        self.badgeFilters = typeof self.layoutProfile.tasksBadgeFilter !== 'undefined' ? self.layoutProfile.tasksBadgeFilter : [];
        self.flatList = typeof self.layoutProfile.flatList !== 'undefined' ? self.layoutProfile.flatList : false;
        if (self.layoutProfile.viewName) {
          this.$store.dispatch("breadcrumb/updateView", self.layoutProfile.viewName, { root: true });
        }

      }

      if(this.id && this.invalidList != null && !this.invalidList.includes(this.id)) {
        const self = this;
        this.loadTasks(this.id, {
          callback: () => {
            // get staff availability
            this.getStaffUsageDetails();
            
            if(this.gridOptions != null && this.gridOptions.api != null) {
              if(self.gridOptions.api.getDisplayedRowCount().length < 1) {
                this.gridOptions.api.showNoRowsOverlay();
              } else {
                this.gridOptions.api.hideOverlay();
              }
            }
          }
        });
      }
    },
    createUserProfile() {
      this.initializeUserProfile();
      viewProfileService.create(this.userProfile, this.userId).then((response) => {
        const data = response.data[response.data.jobCase];
        this.userProfile.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    updateUserProfile() {
      viewProfileService.update([this.userProfile], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadUserProfile() {
      const self = this;
      const userProfile = await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {
        return value.length > 0 ? value[0] : {};
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      // move the views from the old array in a single profile to their own profiles
      if (Object.prototype.hasOwnProperty.call(userProfile, 'views')) {
        const list = [];
        for (const profile of userProfile.views) {
          profile.type = 'task';
          profile.editingPermissions = self.userId;
          profile.sharingMembers = cloneDeep(self.userId);
          profile.sharedVisibility = 'private';
          await viewProfileService.createPreset([profile], this.userId).then((response) => {
            const data = response.data[response.data.jobCase];
            profile.uuId = data[0].uuId;
          })
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
          list.push(profile);
        }
        delete userProfile.views;
        await viewProfileService.update([userProfile], self.userId).then((/**response**/) => {
          //
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
        });
        self.views = list;
      }
      else {
        const views = await this.$store.dispatch('data/presetviewProfileList', self.userId).then((value) => {
          return value;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
        });
        
        this.addViews(views);
      }
    },
    async loadPublicProfile() {
      if (!localStorage.companyId) {
        const data = await companyService.list({limit: -1, start: 0}).then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
          return null;
        });

        if (data != null) {
          const company = data.filter(d => d.type === 'Primary');
          if (company.length > 0) {
            localStorage.companyId = company[0].uuId;
            this.companyId = company[0].uuId;
          }
        }
      }

      const views = await this.$store.dispatch('data/viewProfileListPublic', localStorage.companyId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    },
    addViews(views) {
      for (const view of views) {
        // if not in the list, add it
        if (view.type === 'task' &&
            this.views.findIndex((i) => i.uuId === view.uuId) === -1) {
          this.showInfo.push(false);
          this.views.push(view);
          if (this.useDefault && view.defaultView) {
            this.loadViewSettings(view);
          }
        }
      }
      
      this.views.sort(function( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ){
          return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
      });
    },
    editPermission(view) {
      if (typeof view.editingPermissions === 'undefined') {
        return true;    
      }
      
      return view.editingPermissions.includes(this.userId);
    },
    async populateTaskConstraint() {
      if (this.optionConstraint.length !== 0) {
        return;
      }
      
      const service = taskService;
      let list = await service.optionConstraint()
      .then(response => {
        return response;
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      });
      this.optionConstraint.splice(0, this.optionConstraint.length, ...list.map(i => { 
        return { value: i.label, text: this.$t(`constraint_type.${i.label}`)} 
      }));
    },
    async populateTaskTemplates() {
      let list = await templateProjectService.list({ start: 0, limit: -1 }).then((response) => {
        return response.data;
      })
      .catch(function(error) {
        console.error(error); // eslint-disable-line no-console
      });
      
      this.taskTemplates.splice(0, this.taskTemplates.length, ...list.map(i => { return { value: i.uuId, text: i.name } }));
      this.taskTemplates.sort(function(a, b){return a.text.localeCompare(b.text)});
      this.taskTemplates.unshift({ value: null, text: this.$t('task.none') });
    },
    resetPreviousScrollPosition(left, top) {
      // Reset value
      this.$nextTick(() => {
        if ( this.gridOptions == null || this.gridOptions.api == null) {
          return;
        }
        const gridApi = this.gridOptions.api;
        if (left > -1 && gridApi && gridApi.gridBodyCtrl && gridApi.gridBodyCtrl.bodyScrollFeature) {
          gridApi.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(top);
        }
        if (top > -1 && gridApi && gridApi.gridBodyCtrl && gridApi.gridBodyCtrl.bodyScrollFeature) {
          gridApi.gridBodyCtrl.bodyScrollFeature.setHorizontalScrollPosition(left);
        }
        //After use, reset to -1
        this.previousScrollPosTop = -1;
        this.previousScrollPosLeft = -1;
      });
    },
    async updateTags(taskId, oldTagList, newTagList) {
      const oldTagNames = oldTagList != null? objectClone(oldTagList) : [];
      const oldTagFilters = [];
      for(const t of oldTagNames) {
        if (t == null || t.trim().length == 0) {
          continue;
        }
        oldTagFilters.push({ value: t.trim(), regex: false });
      }      
      let oldTags;
      if (oldTagFilters.length > 0) {
        oldTags = await tagService.list_with_filters({filters: oldTagFilters}).then((response) => {
          return response.data.filter(i => i.uuId != null);
        });
      } else {
        oldTags = [];
      }

      const newTagNames = objectClone(newTagList);
      const newTagFilters = [];
      for(const t of newTagNames) {
        if (t == null || t.trim().length == 0) {
          continue;
        }
        newTagFilters.push({ value: t.trim(), regex: false });
      }      
      let newTags;
      if (newTagFilters.length > 0) {
        newTags = await tagService.list_with_filters({filters: newTagFilters}).then((response) => {
          const data = response.data;
          const list = [];
          for (const f of newTagFilters) {
            const found = data.find(i => i.name.localeCompare(f.value, undefined, { sensitivity: 'base' }) === 0);
            if (found != null) {
              list.push(found);
            }else {
              list.push({ uuId: null, name: f.value });
            }
          }
          return list;
        });
      } else {
        newTags = [];
      }
      return TaskViewRequestGenerator.updateTag(taskId, oldTags, newTags);
    },
    async onGridReady(/*params*/) {
      //Set up a flag when grid is ready before customFields data. 
      //The flag will be used by beforeMount() to continue load layout profile.
      if (!this.isCustomFieldsReady) {
        this.isGridReady = true;
      } else {
        const gridApi = this.gridOptions.api;
        await this.loadLayoutProfile();
        
        if(this.id && this.invalidList != null && !this.invalidList.includes(this.id)) {
          gridApi.showLoadingOverlay();
        } else {
          gridApi.showNoRowsOverlay();
        }
      }
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    async taskTreeList(projectId, fields) {
      let retry = false;
      const allData = [];
      let start = 0;
      this.total = 0;
      if (!this.isCompactView &&
          this.sort &&
          this.sort.colId.startsWith('taskcol')) {
        delete this.sort;  
      }
        
      do {
        let data = await taskViewService.listTree(
                          { self: this, start: start, limit: -1, ksort: this.sort, sortByParent: !this.flatList }
                          , projectId, fields, false, this.customFields
                          , { skillCustomFields: this.skillCustomFields, resourceCustomFields: this.resourceCustomFields, noteCustomFields: this.noteCustomFields })
        .then(response => {
          if (response.lackOfMandatoryFields === true) {
            this.isAccessDenied = true;
            this.showNoRowsOverlay(this.$t('entity_selector.error.insufficient_permission_to_show_data'));
            return [];
          }
          else if (response.status === 207 &&
                   response.jobClue.clue === 'Query_timeout') {
            // we did not get all the records, issue another request to get the remaining records
            // starting at arg_count
            // don't retry forever
            if (start + response.arg_count === response.arg_total ||
              response.arg_count === 0 && retry) {
              retry = false;
            }
            else {
              start = start + response.arg_count;
              retry = true;
            }
          }
          else if (response.status === 200) {
            retry = false; // success
          }
          
          this.total = response.arg_total;
          //this.columnCache = response.fields;
          return response.data || [];
        })
        .catch(e => {
          this.isAccessDenied = true;
          if (e != null && e.response != null && e.response.status == 403) {
            this.showNoRowsOverlay(this.$t('entity_selector.error.insufficient_permission_to_show_data'));
          } else {
            console.error(e) //eslint-disable-line no-console
            this.showNoRowsOverlay(this.$t('task.grid.error.failed_to_load'));
          }
          return [];
        });
        allData.push(...data);
        this.loaded = allData.length;
      } while (retry);
      
      if (this.filterText !== '' || this.badgeFilters.length > 0) {
        this.changeFilter(this.filterText);
      }
      
      return allData;
      
    },
    async loadTasks(projectId, { callback=null } = {}) {

      //BadgeFilter related
      //Empty the value. New data will be fetched when needed
      if (Object.keys(this.badgeFilterFieldValues).length > 0) {
        this.badgeFilterFieldValues = {}
      }
      

      const self = this;
      
      // Prepare companyId. This is needed for the profile loading and will be used by taskgroup management.
      if (this.companyId == null) {
        if (!localStorage.companyId) {
          await this.prepareCompanyId();
        } else {
          this.companyId = localStorage.companyId;
        }
      }

      //Clear/reset the list whenever data is reloaded
      if (this.originalRowDataList.length > 0) {
        this.originalRowDataList.splice(0, this.originalRowDataList.length)
      }
      
      const gridApi = this.gridOptions != null && this.gridOptions.api? this.gridOptions.api : null;
      const columnApi = this.gridOptions != null && this.gridOptions.columnApi? this.gridOptions.columnApi : null;
      if(gridApi) {
        //Register current scroll position before refresh data
        const { top } = gridApi.getVerticalPixelRange();
        const { left } = gridApi.getHorizontalPixelRange();
        this.previousScrollPosTop = top;
        this.previousScrollPosLeft = left;
      }
      let taskColumns = []; //Used by compact view
      if(columnApi) {
        this.columnCache = columnApi.getAllDisplayedColumns()
                .filter(i => i.colId !== this.COLUMN_AGGRID_AUTOCOLUMN && !i.colId.startsWith(this.taskColPrefix))
                .map(i => i.colId);
        
        if (this.isCompactView) {
          //Consolidate data of api.getAllDisplayedColumns() and api.getColumnDefs().
          //Details: actualWidth (as width), sort and hide properties from api.getAllDisplayedColumns and column Definitions from api.getColumnDefs()
          //Assign the consolidated data to taskColumns variable.
          taskColumns = this.consolidateColumnDefsAndDisplay();

          //Check columns against taskGroupProfile. Remove task group column which couldn't find a match in the task group profile list.
          const profileTaskGroups = await this.getProfileTaskGroups();
          if (profileTaskGroups != null) {
            const prefixLen = this.taskGroupPrefix.length;
            const profIds = taskColumns.filter(i => i.groupId != null).map(i => i.groupId.substring(prefixLen));
            const relatedTaskGroups = profileTaskGroups.filter(i => profIds.includes(i.uuId));
            taskColumns = this.consolidateTaskGroup(taskColumns, relatedTaskGroups);
          }

          //Remove task utility child column from taskgroup column if there is other task column available.
          //The task utility child column is added in loadColumnSettings just to make sure the group column is not removed by ag-grid.
          const taskUtilColId = this.getTaskUtilColDef().colId;
          taskColumns.filter(i => i.groupId != null).forEach(i => {
            let index = i.children.findIndex(j => j.colId == taskUtilColId);
            if (index > -1 && i.children.length > 1) {
              i.children.splice(index, 1);
            }
          });

          //Add default taskGroup if there is no task group included
          if (!taskColumns.some(i => i.groupId != null)) {
            const defaultTaskGroup = await taskGroupProfileService.getDefault(this.companyId, this.userId, { createIfNone: true })
            .then(result => {
              return result;
            })
            .catch(() => {
              return null;
            })
 
            if (defaultTaskGroup == null) {
              this.resetAlert({ msg: this.$t('task.group.error.failed_to_get_default'), alertState: alertStateEnum.ERROR });
            } else {
              const defaultTaskGroupDef = this.populateTaskGroupDef(defaultTaskGroup);
              if (defaultTaskGroupDef.children.length == 0) {
                defaultTaskGroupDef.children.push(this.getTaskUtilColDef());
              }
              taskColumns.unshift(this.populateTaskGroupDef(defaultTaskGroup));
            }
          }
        }
        
        const requiredProperties = taskColumns.filter(i => i.groupId != null).map(i => i.property);
        requiredProperties.forEach(i => {
          if (!this.columnCache.includes(i)) {
            this.columnCache.push(i);
          }
        })

        //Make sure taskPath is in the columnCache. TaskPath is important to determine if the autoGroupColumn link is being rendered.
        if (!this.columnCache.includes('taskPath')) {
          this.columnCache.push('taskPath');
        } 
        //Make sure autoScheduling is in the columnCache. AutoScheduling is needed in SpreadSheet (compact) View for auto/manual task schedule button state.
        if (this.isCompactView && !this.columnCache.includes('autoScheduling')) {
          this.columnCache.push('autoScheduling');
        }
      } else {
        this.columnCache = [];
      }

      if (!this.forceReload && !this.exporting && !this.sortChanged && typeof this.firstRow !== 'undefined') {
        // just redraw the view port tasks
        this.redrawViewport({api: this.gridOptions.api}, this.firstRow, this.lastRow);
      }
      else {
        this.forceReload = false;
        taskLoaded = {};
        const requests = [];
        if (this.isCompactView) {
          requests.push(this.getProjectDetailsWhenReloadTask());
        }
        const fields = self.exporting ? self.columnCache.filter(i => !i.startsWith(self.taskColPrefix)) : self.isCompactView ? self.columnCache : [];
        requests.push(this.taskTreeList(projectId, fields));
        let list = [];
        let projData = null;
        
        await Promise.allSettled(requests).then(results => {
            let index = 0    
            if (this.isCompactView) {
              const projectResult = results[index];
              index++;
              if (projectResult.status == 'fulfilled') {
                projData = projectResult.value;
              }
            }
            
            
            const taskTreeListResult = results[index];
            if (taskTreeListResult.status == 'fulfilled') {
              list = taskTreeListResult.value;
            }
        });
  
        var children = [];
        var maxChildren = 0;
        this.compactLimit = false;
        list.forEach(i => {
          //Generate children properties
          if (i.pUuId && 
              (children.length === 0 || i.pUuId === children[0].pUuId)) {
            children.push(i);   
          }
          else if (children.length > 0) {
            const p = list.find(j => j.uuId == children[0].pUuId);
            if (p) {
              if (!this.sort) { // only sort if the user has not selected a column sort
                children.sort((a, b) => {
                  return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0; 
                });
              }
              p.children = children;
              if (children.length > maxChildren) {
                maxChildren = children.length;
              }
            }
            
            for (const child of children) {
              child.parent = p;
            }
            children = [i];
          }
          this.taskNames[i.uuId] = {name: i.name};
  
        });
        
        if (children.length > 0) {
          const p = list.find(j => j.uuId == children[0].pUuId);
          if (p) {
            if (!this.sort) { // only sort if the user has not selected a column sort
              children.sort((a, b) => {
                return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0; 
              });
            }
            p.children = children;
              if (children.length > maxChildren) {
                maxChildren = children.length;
              }
          }
          
          for (const child of children) {
            child.parent = p;
          }
          children = [];
        }
        
        if (maxChildren > 300) {
          this.compactLimit = true;
        }
        
        let maxSummaryTaskDepth = 0; //Used by compact view
        
        if (!this.sort) { // only sort if the user has not selected a column sort
          list.sort((a, b) => {
            return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0; 
          });
        }
        
        const flatTree = [];
        const populatePath = (path, children) => {
          if(children) {
            for(const child of children) {
              flatTree.push(child);
              child.path = [...path, child.uuId];
              child.treeDepth = child.path.length; 
              if (child.taskType == 'Project' && child.treeDepth > maxSummaryTaskDepth) {
                maxSummaryTaskDepth = child.treeDepth;
              }
              this.taskNames[child.uuId].path = child.path;
              populatePath(child.path, child.children);
            }
          }
        }
        populatePath([], list.filter(i => i.pUuId == 'ROOT'));
  
        // sort the flat list in the order in which the tasks would appear if
        // they were in a tree
        if (this.flatList && !this.sort) { // only sort if the user has not selected a column sort
          list = flatTree;
        }
        
        const removeParentProperties = (list) => {
          if (list) {
            for(const item of list) {
              delete item.parent;
            }
          }
        }
        const removeChildrenProperties = (list) => {
          if(list) {
            for(const item of list) {
              delete item.children;
            }
          }
        }
  
        if (this.isCompactView) {
          const compactViewData = prepareCompactViewData(list, taskColumns, maxSummaryTaskDepth, this, projData);
          list = compactViewData.list; //Override the list with the preprocess summary tasks for compact view.
          taskColumns = compactViewData.taskColumns;
  
          //Skip updating taskExpandLevel because the grid data is not ready at this point and it corrupts the expandlevel value.
          this.saveColumnState({ compactColumns: taskColumns, skipTaskExpandLevel: true }); 
          this.gridOptions.api.stopEditing(true);
          this.loadColumnSettings();
        }
  
        removeParentProperties(list);
        removeChildrenProperties(list);
  
        if(this.gridOptions != null && this.gridOptions.api != null) {
          this.gridOptions.api.showLoadingOverlay();
          this.gridOptions.api.setRowData(list);
          
          if(list.length < 1) {
            this.gridOptions.api.showNoRowsOverlay();
          } else {
            this.gridOptions.api.hideOverlay();
          }
          //A fix to make sure the triangle icon in cell is being rendered.
          //This also ensures that the names are redrawn after being edited.
          if (this.firstDrawDone) {
            setTimeout(() => {
              if (this.gridOptions != null && this.gridOptions.api != null) {
                this.gridOptions.api.redrawRows();
              }
            }, 300);
          }
          this.redrawViewport({api: this.gridOptions.api}, this.firstRow, this.lastRow);
        }
      }
      
      if(callback && typeof callback === 'function') {
        callback();
      }
      
      if (this.taskDialogOpen) {
        this.taskDialogOpen = false;
        this.openDetail(this.taskDialogId);
        this.taskDialogId = null;
      }
    
      if (this.firstDrawDone) {
        this.$nextTick(() => {
          // expand the tasks
          this.expandLevels();
          this.restoreNodeState();
        });
      }
      this.firstDrawDone = true;
    },
    openDetail(id) {
      this.taskEdit.uuId = id;
      this.taskEdit.parentId = null;
      this.taskEditShow = true;
      this.resetAlert();
    },
    detailLinkLabel(params) {
      if (params.data) {
        return params.data.name;
      }
      return '';
    },
    detailLinkId(params) {
      return params.data.uuId;
    },
    ok() {
      if (this.gridOptions == null || this.gridOptions.api == null) {
        return;
      }
      const details = this.gridOptions.api.getSelectedNodes().map(i => { return {uuId: i.data.uuId, name: i.data.name} });
      this.$emit('ok', { ids: [...this.selected], details: details });
    },
    taskEditSuccess(payload) {
      // reload staff usage date in case it has changed
      this.getStaffUsageDetails();
      if (this.taskEdit.uuId.startsWith('TASK_NEW_')) {
        this.forceReload = true; // reload the structure to get the new task.  This could be improved by just inserting the new task
      }
      this.forceReload = true;
      taskLoaded = {};
      this.reloadTasks(() => {
        this.resetAlert({ msg: payload.msg });
        if (payload.imageChanged) {
          this.gridOptions.api.resetRowHeights();
        }

        // Make sure the new task node is expanded and visible.
        if (this.taskEdit.uuId.startsWith('TASK_NEW_')) {
          if (this.taskEdit.parentId == null || this.taskEdit.parentId == this.id) {
            return;
          }

          this.manualExpandedSet.add(this.taskEdit.parentId);
          setTimeout(() => {
            this.processNodes();
          }, 0);
        }
        
      })
    },
    taskEditOpen(isNew) {
      this.taskEdit.parentId = null;//Reset to default
      if(isNew || this.taskSelection.length == 0) {
        const api = this.gridOptions.api;
        this.taskEdit.uuId = `TASK_NEW_${strRandom(5)}`;
        let selectedTask = this.taskSelection.length > 0? this.taskSelection[0] : null;
        //Handle special case: Click add task button when user select an empty task Col cell.
        //Find the row node where the selected column resides and set it as parent.
        if (selectedTask == null && this.isCompactView) {
          const cellRanges = api.getCellRanges();
          if (cellRanges.length > 0) {
            const range = cellRanges[0];
            const rowIndex = range.startRow.rowIndex > range.endRow.rowIndex? range.endRow.rowIndex : range.startRow.rowIndex;
            const rowNode = api.getDisplayedRowAtIndex(rowIndex);
            if (rowNode != null && range.startColumn.colId.startsWith(this.taskColPrefix)) {
              this.taskEdit.parentId = rowNode.data.uuId;
            }
          }
        } else {
          let parentId = selectedTask != null? (selectedTask.colId.startsWith(this.taskColPrefix)? this.taskSelection[0].parent : this.taskSelection[0].uuId) : null;
          if (parentId != null) {
            //Find the grand parent which taskType is 'Project' if current parent's task type is not 'Project'
            let parentNode = api.getRowNode(parentId);
            if (parentNode != null && parentNode.data != null && parentNode.data.taskType != 'Project') {
              do {
                parentNode = parentNode.parent;
              } while (parentNode && parentNode.data != null && parentNode.data.taskType != 'Project');
              if (parentNode && parentNode.data != null) {
                parentId = parentNode.data.uuId;
              } else {
                parentId = null;
              }
            }
          }
          this.taskEdit.parentId = parentId;
        }
      } else {
        const selectedId = this.taskSelection[0].uuId;
        this.taskEdit.uuId = selectedId;
      }
      this.taskEditShow = true;
      this.resetAlert();
    },
    rowDelete() {
      this.resetAlert();
      if (this.taskSelection.length == 0) {
        return;
      }
      const taskColTasks = this.getUpperTaskNodesFromSelection();//objectClone(this.taskSelection);
      //Prepare data for taskcol delete confirmation dialog
      this.tcConfirmDeleteTasks = taskColTasks;
      this.prepareTaskColConfirmDeleteDialog();
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      } else if (422 === response?.status) {
        const feedbackList = response.data[response.data.jobCase];
        const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
        if (msg != null) {
          alertMsg = msg;
        }
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
    },
    async taskIndent() {
      this.resetAlert();
      this.actionProcessing = true;
      //Assuming the order of taskSelection items matches the grid display order.
      const toIndentTasks = this.taskSelection.filter(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN);
      const processedTasks = [];
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('task.progress.updating_tasks');
      while (toIndentTasks.length > 0) {
        //Collect all tasks with same parent
        const processingTasks = toIndentTasks.filter(i => i.parent == toIndentTasks[0].parent);
        for (const t of processingTasks) {
          const idx = toIndentTasks.findIndex(i => i.uuId == t.uuId);
          if (idx == -1) {
            continue;
          }
          toIndentTasks.splice(idx, 1);
        }
        
        //Move to next iteration when the list is empty
        if (processingTasks.length == 0) {
          continue;
        }

        const localProcessedTasks = [];
        for (const t of processingTasks) {
          
          const rowNode = this.gridOptions.api.getRowNode(t.uuId);
          if (rowNode == null) {
            continue;
          }

          //Find new parent
          let newParentId = null;
          for (let idx = rowNode.rowIndex - 1; idx >= 0; idx--) {
            const prev = this.gridOptions.api.getDisplayedRowAtIndex(idx);
            if (prev == null || prev.level < rowNode.level) {
              break;
            } else if (prev.level > rowNode.level) {
              continue;
            } else if (prev != null 
                && prev.level === rowNode.level 
                && prev.data.taskType == 'Project' 
                && localProcessedTasks.find(i => i.uuId == prev.id) == null) {
              newParentId = prev.id;
              break;
            }
          }

          //Skip current tasks and move to next iteration when new parent can't be found.
          if (newParentId == null) {
            continue;
          }

          const newParentNode = this.gridOptions.api.getRowNode(newParentId);
          let orderRef = null;
          if (newParentNode.data != null && newParentNode.data.taskType == 'Project' && newParentNode.allLeafChildren.length > 0) {
            const level = newParentNode.allLeafChildren[0].level + 1;
            const leafChildren = newParentNode.allLeafChildren.slice(1);
            const lastChild = leafChildren.find(i => i.lastChild == true && i.level == level);
            if (lastChild != null) {
              orderRef = `next:${lastChild.data.uuId}`;
            }
          }

          localProcessedTasks.push({
            uuId: t.uuId
            , name: t.name
            , parent: newParentId
            , orderRef
          });
        }

        processedTasks.push(...localProcessedTasks.splice(0, localProcessedTasks.length));
      }

      //Call backend api to update accordingly.
      const errors = [];
      if(processedTasks.length > 0) {
        const { failed, isUnexpectedError } = await taskService.updateParent(processedTasks.map(i => { return { uuId: i.uuId, parent: i.parent, orderRef: i.orderRef } }))
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'OK').map(i => i.args[0]);
            const failTasks = processedTasks.filter(i => failIds.includes(i.uuId));
            return { failed: failTasks };
          }
          return { failed: [] };
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
          if(e.response && 422 == e.response.status) {
            const data = e.response.data;
            const list = data[data.jobCase];
            const failTasks = [];
            for (let i = 0, len = list.length; i < len; i++) {
              if (list[i].clue !== 'OK') {
                failTasks.push(processedTasks[i]);
              }
            }
            return { failed: failTasks };
          } else {
            return { isUnexpectedError: true }
          }
        });
        if(isUnexpectedError) {
          if(errors.length > 0) {
            errors.push(this.$t('task.error.failed_to_indent_rest_tasks'))
          } else {
            errors.push(this.$t('task.error.failed_to_indent_tasks'));
          }
        } else {
          if(failed && failed.length > 0) {
            for(let i = 0, len = failed.length; i < len; i++) {
              errors.push(this.$t('task.error.failed_to_indent_task', [failed[i].name]));
            }
          }
        }
      }

      if(errors.length === 1) {
        this.resetAlert({ msg: errors[0], alertState: alertStateEnum.ERROR });
      } else if(errors.length > 1) {
        this.resetAlert({ msg: this.$t('task.indent_partial'), alertState: alertStateEnum.WARNING, details: errors });
      }

      this.forceReload = true;
      taskLoaded = {};
      this.reloadTasks(() => {
        this.$nextTick(() => {
          const api = this.gridOptions != null && this.gridOptions.api != null? this.gridOptions.api : null;
          api.clearFocusedCell();
          api.clearRangeSelection();
          this.inProgressShow = false;
        });
      });
    },
    async taskOutdent() {
      this.resetAlert();
      this.actionProcessing = true;
      const api = this.gridOptions.api;
      if (api == null) {
        return;
      }
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('task.progress.updating_tasks');
      //   Before         After
      //  --------       ------
      //  ST               ST
      //   <-ST            ST
      //     <-T   ==>     T
      //     <-T           T
      const toOutdentTasks = this.taskSelection.filter(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN);

      //Sort by rowIndex. The row order plays an important role in outdent process.
      for (const pTask of toOutdentTasks) {
        const rowData = api.getRowNode(pTask.uuId);
        if (rowData == null) {
          continue;
        }
        pTask.rowIndex = rowData.rowIndex;
      }
      toOutdentTasks.sort((a, b) => {
        if (a.rowIndex == null && b.rowIndex == null) {
          return 0;
        }
        if (a.rowIndex == null || a.rowIndex < b.rowIndex) {
          return -1;
        }
        if (b.rowIndex == null  || a.rowIndex > b.rowIndex) {
          return 1;
        }
        return 0;
      });
      
      const processedTasks = [];
      while (toOutdentTasks.length > 0) {
        //Collect all tasks with same parent
        let processingTasks = toOutdentTasks.filter(i => i.parent == toOutdentTasks[0].parent);
        for (const t of processingTasks) {
          const idx = toOutdentTasks.findIndex(i => i.uuId == t.uuId);
          if (idx == -1) {
            continue;
          }
          toOutdentTasks.splice(idx, 1);
        }
        
        //Move to next iteration when the list is empty
        if (processingTasks.length == 0) {
          continue;
        }

        //'ROOT' is an agreed value to represent root of task tree.
        //Move to next iteration when parent is ROOT, there is no point to do outdentation.
        if (processingTasks[0].parent == 'ROOT') {
          continue;
        }
        
        const parentNode = api.getRowNode(processingTasks[0].parent);
        if (parentNode == null|| parentNode.data == null || parentNode.data.pUuId == null) {
          continue;
        }
        
        //1) Find the grandparent.
        let newParentId = parentNode.data.pUuId == 'ROOT'? this.id : parentNode.data.pUuId;
        //2) If the parent is part of the processedTasks, use the new assigned parent of the parent instead.
        const found = processedTasks.find(i => i.uuId == parentNode.data.uuId);
        if (found != null) {
          newParentId = found.parent;
        }

        const localProcessedTasks = [];
        for (const t of processingTasks) {
          
          const rowNode = api.getRowNode(t.uuId);
          if (rowNode == null) {
            continue;
          }

          localProcessedTasks.push({
            uuId: t.uuId
            , name: t.name
            , parent: newParentId
            , oldParent: parentNode.data.uuId
          });
        }

        //Prepare orderAt and orderAs properties. They are needed for keeping the task order.
        for (let i = 0, len = localProcessedTasks.length; i < len; i++) {
          const t = localProcessedTasks[i];
          if (i == 0) {
            t.orderAt = t.oldParent;
            t.orderAs = true;
          } else {
            t.orderAt = localProcessedTasks[i-1].uuId;
            t.orderAs = true;
          }
        }

        processedTasks.push(...localProcessedTasks.splice(0, localProcessedTasks.length));
      }

      
      if(processedTasks.length > 0) {
        //Group task by parent
        const parentNTasksMap = new Map();
        for (const t of processedTasks) {
          let list = null;
          if (parentNTasksMap.has(t.parent)) {
            list = parentNTasksMap.get(t.parent);
          } else {
            list = [];
            parentNTasksMap.set(t.parent, list);
          }
          list.push(t);
        }
        
        const requests = [];
        for (const value of parentNTasksMap.values()) {
          const r = TaskViewRequestGenerator.outdentTask(this.id, value);
          if (r.length > 0) {
            requests.push(...r);
          }
        }

        if (requests.length > 0) {
          //Keep the last focused cell info and reapply it after data refresh
          const resetFocus = (api, cell) => {
            api.clearRangeSelection();
            if (cell != null && cell.rowIndex != null && cell.colId != null) {
              api.setFocusedCell(cell.rowIndex, cell.colId, null);
              api.addCellRange({
                rowStartIndex: cell.rowIndex
                , rowEndIndex: cell.rowIndex
                , columnStart: cell.colId
                , columnEnd: cell.colId
              });
            }
          }

          //As recommended by Chrism, turn off project.autoScheduling during the data change. It will improve performance.
          if (this.project.autoScheduling && requests.length > 1) {
            requests.unshift({
              'note': 'Disable macros',
              'invoke': 'PUT /api/system/features?entity=macros&action=DISABLE'
            });
            requests.unshift({
              'note': 'Disable project scheduling',
              'invoke': 'PUT /api/system/features?entity=scheduling&action=DISABLE'
            });
          }
          
          await compositeService.exec(requests).then(response => {
            const feedbackList = response.data[response.data.jobCase];
            const hasNotOk = feedbackList.some(i => i.clue != 'OK');
            if (hasNotOk) {
              this.resetAlert({ msg: this.$t('task.error.failed_to_outdent_tasks'), alertState: alertStateEnum.ERROR });
            }
            this.forceReload = true;
            taskLoaded = {};
            this.reloadTasks(() => {
              setTimeout(() => {
                resetFocus(api, this.lastFocusedCell);
              }, 0);
              this.inProgressShow = false;
            });
          }).catch(() => {
            this.resetAlert({ msg: this.$t('task.error.failed_to_outdent_tasks'), alertState: alertStateEnum.ERROR });
            this.reloadTasks(() => {
              setTimeout(() => {
                resetFocus(api, this.lastFocusedCell);
              }, 0);
              this.inProgressShow = false;
            });
          });
        }
      }
    },
    onRowDragEnter(event) {
      this.dragging = true;
      this.resetAlert();
      event.api.deselectAllFiltered();
      event.api.clearRangeSelection();
      this.rowDragClearPrevNodeStyle();
      
      //Disable drag feature when dragged node is ROOT.
      if (event.node.key == 'ROOT') {
        event.api.setSuppressRowDrag(true);
        return;
      }
    },
    async onRowDragEnd(e) {
      this.dragging = false;
      // after drag the selection is cleared
      this.taskSelection.splice(0, this.taskSelection.length);
      
      this.rowDragClearPrevNodeStyle();
      this.draggedOverNodeHandler(null, null, true);
      //Reenable drag feature when dragged node is ROOT.
      if (e.node.key == 'ROOT') {
        e.api.setSuppressRowDrag(false);
        return;
      }
      //No reordering when drag over ROOT node
      if (e.overNode && e.overNode.key == 'ROOT') {
        return;
      }
      if(e.overNode && e.node.rowIndex != e.overIndex) {
        const node = e.node;
        const overNode = e.overNode;
        const currentY = e.y;

        //No reordering when drag a parent task over it's child/grandchild task.
        if (overNode.data.path.includes(node.data.uuId)) {
          return;
        }

        const rowNode = this.gridOptions.api.getRowNode(node.data.uuId);
        const targetNode = this.gridOptions.api.getRowNode(overNode.data.uuId);
        //0.3 equals 30% of row Height. Decide task to be inserted before or after.
        const insertAfter = Math.round(overNode.rowHeight * 0.3) < (currentY - overNode.rowTop + this.rowDragOffsetY); 
        // const srcIndex = rowNode.rowIndex;
        const srcRow = rowNode.data;
        // const targetIndex = targetNode.rowIndex;
        const targetRow = targetNode.data;
        const targetParent = targetRow.pUuId !== node.data.pUuId ? targetRow.pUuId : null;
        this.inProgressShow = true;
        this.inProgressLabel = this.$t('task.progress.updating_tasks');
        const result = await this.reOrderTask(srcRow.uuId, 'ROOT'===targetParent?this.id:targetParent, targetRow.uuId, insertAfter);
        if(result) {
          const self = this;
          self.sortChanged = true;
          this.loadTasks(this.id, {
            callback: () => {
              this.$nextTick(() => {
                this.processNodes();
                self.sortChanged = false;
                this.inProgressShow = false;
              });
            }
          });
        }
      }
    },
    onRowDragMove(e) {
      if (e.overNode) {
        const overNode = e.overNode;
        let rowNodeChildren = document.querySelectorAll(
          `[row-id="${overNode.id}"] > div`
        );
        this.draggedOverNodeHandler([...rowNodeChildren], overNode.id);

        const prevOverNodeId = this.rowDragPrevOverNodeIds.pop();
        if (prevOverNodeId != null) {
          const prevOverNodeElems = document.querySelectorAll(`.ag-row[row-id="${prevOverNodeId}"]`);
          for (const el of prevOverNodeElems) {
            el.classList.remove('drag-to-bottom', 'drag-to-top');
          }
        }
        
        const node = e.node;
        if (node.id == e.overNode.id) {
          return; 
        }
        if (!this.rowDragPrevOverNodeIds.includes(overNode.id)) {
          this.rowDragPrevOverNodeIds.push(overNode.id);
        }
        
        const currentY = e.y;
        //0.3 equals 30% of row Height. Decide task to be inserted before or after.
        const insertAfter = Math.round(overNode.rowHeight * 0.3) < (currentY - overNode.rowTop) + this.rowDragOffsetY;
        const rowElems = document.querySelectorAll(`.ag-row[row-id="${overNode.id}"]`);
        for (const el of rowElems) {
          if (insertAfter) {
            el.classList.add('drag-to-bottom');
          } else {
            el.classList.add('drag-to-top');
          }
        }
      }
    },
    onRowDragLeave(/*e*/) {
      this.rowDragClearPrevNodeStyle();
      this.draggedOverNodeHandler(null, null, true);
    },
    rowDragClearPrevNodeStyle() {
      for (const id of this.rowDragPrevOverNodeIds) {
        const prevOverNodeElems = document.querySelectorAll(`.ag-row[row-id="${id}"]`);
        for (const el of prevOverNodeElems) {
          el.classList.remove('drag-to-bottom', 'drag-to-top');
        }
      }
    },
    draggedOverNodeHandler(newNodeChildren, newNodeId, dragHasEnded = false) {
      if (dragHasEnded) {
        if(this.nodeChildrenList) {
          this.nodeChildrenList.forEach(child => {
            child.classList.remove('row-drag-hover-over');
          });
        }
        this.nodeChildrenList = null;
        this.nodeId = null;
        return;
      }

      if (this.nodeId === newNodeId) {
        return; //Do nothing if hovering over same thing
      }

      if (this.nodeId === null) {
        this.nodeId = newNodeId;
        this.nodeChildrenList = newNodeChildren;
        this.nodeChildrenList.forEach(child => {
          child.classList.add('row-drag-hover-over');
        });
      }

      if (this.nodeId !== newNodeId) {
        this.nodeChildrenList.forEach(child => {
          child.classList.remove('row-drag-hover-over');
        });
        this.nodeChildrenList = newNodeChildren;
        this.nodeId = newNodeId;
        this.nodeChildrenList.forEach(child => {
          child.classList.add('row-drag-hover-over');
        });
      }
    },
    async reOrderTask(selectId, targetParent, targetId, insertAfter) {
      let result = await taskService.updateParentnOrder([{
        uuId: selectId, parent: targetParent
      }], targetId, insertAfter)
      .then(() => {
        return true;
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
        if (e.response.data.feedbackList[0].clue === 'Forbidden_relation') {
          this.resetAlert({ msg: this.$t('task.grid.error.failed_permission'), alertState: alertStateEnum.ERROR });
        }
        else {
          this.resetAlert({ msg: this.$t('task.grid.error.failed_to_reorder'), alertState: alertStateEnum.ERROR });
        }
        this.inProgressShow = false;
        return false;
      });
      return result;
    },
    async templateApply() {
      this.resetAlert();
      if (this.isCompactView) {
        this.taskTemplateApplyTaskIds = this.compactApplyTemplateTarget.map(i => { 
          if (i == 'ROOT') {
            return this.id;
          }
          return i;
        });
      } else {
        this.taskTemplateApplyTaskIds = this.taskSelection.map(i => { 
          if (i.uuId == 'ROOT') {
            return this.id;
          }
          return i.uuId;
        });
      }
      this.taskTemplateSelectorShow = true;
    },
    async taskTemplateSelectorOk({ details }) {
      if(details && details.length > 0) {
        this.templateApplyDetails = details;
        this.applyTemplateConfigShow = true;
      }
    },
    async applyTemplateConfigOk({ count, action, enableGroup }) {
      if (isNaN(count) || count < 1 || !(action == 'append' || action == 'override') 
          || this.templateApplyDetails == null || this.templateApplyDetails.length < 1) {
        return;
      }
      this.showInProgress(this.$t('task.progress.insert_template'));
      this.actionProcessing = true;
      
      const parentIds = this.taskTemplateApplyTaskIds != null? objectClone(this.taskTemplateApplyTaskIds) : [];
      if(parentIds.length == 0) {
        parentIds.push(this.id);
      }

      const details = this.templateApplyDetails;
      const moreThanOne = details.length > 1;
      if (parentIds.length > 0) {
        TaskTemplateDataUtil.applyTaskTemplateAdv(parentIds, details, action=='override', { count, enableGroup: enableGroup==true }) //override is always false. Please refer issue #1311.
        .then(() => {
          this.inProgressShow = false;
          this.forceReload = true;
          taskLoaded = {};
          this.reloadTasks(() => {
            this.resetAlert({ msg: this.$t(moreThanOne? 'task.apply_template_plural' : 'task.apply_template') });
          })
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
          this.inProgressShow = false;
          this.reloadTasks(() => {
            this.resetAlert({ msg: this.$t(moreThanOne? 'task.apply_template_failure_plural' : 'task.apply_failure_template'), alertState: alertStateEnum.ERROR });
          })
        });
      } else {
        this.inProgressShow = false;
      }
    },
    applyTemplateConfigCancel() {
      this.templateApplyDetails = null;
    },
    templateSave() {
      this.resetAlert();
      this.templateTaskIds = this.getTaskIdForCopyOrTemplateSave();
      this.templateSaveShow = true;
    },
    templateSaveSuccess({ hasError, msg } ) {
      this.resetAlert({ msg, alertState: hasError? alertStateEnum.ERROR : alertStateEnum.SUCCESS });
    },
    taskCopy() {
      this.resetAlert();
      this.taskCopyIds.splice(0, this.taskCopyIds.length, ...this.getTaskIdForCopyOrTemplateSave());
      this.gridOptions.api.clearRangeSelection();
      this.gridOptions.api.clearFocusedCell();
    },
    async taskPaste() {
      const self = this;
      this.actionProcessing = true;
      this.isTaskPaste = true;
      this.resetAlert();
      let selectedId = null;
      if (this.taskSelection.length > 0) {
        selectedId = this.taskSelection[0].uuId;
      } else {
        const focusedCell = this.gridOptions.api.getFocusedCell();
        if (focusedCell == null) {
          selectedId = null //set to null, assuming the parent will be project
        }
        else {
          const rowNode = this.gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
          if (rowNode == null) {
            this.actionProcessing = false;
            return;
          }
          selectedId = rowNode.data.uuId;
        }
      }
      const ids = cloneDeep(this.taskCopyIds);

      if(ids.length < 1) {
        if (this.gridOptions != null && this.gridOptions.api != null) {
          this.gridOptions.api.clearRangeSelection();
          this.gridOptions.api.hideOverlay();
        }
        this.resetAlert({ msg: this.$t('task.paste_nothing') });
        this.actionProcessing = false;
        return;
      }
      this.showInProgress(this.$t('task.progress.paste_tasks'));
      const projectId = this.id;
      await TaskTemplateDataUtil.createTasksFromTasksAdv(ids.map(i => { return { uuId: i } }), { uuId: projectId }, { uuId: projectId }, selectedId != null? { uuId: selectedId } : { uuId: projectId })
      .then(result => {
        if (result.hasError == true) {
          this.resetAlert({ msg: this.$t('error_failed_to_paste_task'), alertState: alertStateEnum.ERROR });
        }
      })

      this.forceReload = true;
      taskLoaded = {};
      this.reloadTasks(function() {
        self.sortChanged = false;
        self.inProgressShow = false;
      });
    },
    fileImport() {
      this.resetAlert();
      this.docImportShow = true;
      const selectedIds = this.taskSelection.filter(i => i.uuId != 'ROOT').map(i => i.uuId);
      this.docImportTaskId = selectedIds.length > 0? selectedIds[0]: null;
    },
    async fileExport() {
      this.resetAlert();
      this.exporting = true;
      this.showInProgress(this.$t('task.exporting'));
      await this.loadTasks(this.id);
      
      const keys = this.gridOptions.columnApi
          .getAllDisplayedColumns()
          .filter(i => i.colId != 'rowSelector')
          .map(column => column.getColId());
      if (!keys.includes('taskPath')) {
        keys.push('taskPath');
      }
      if (!keys.includes('taskType')) {
        keys.push('taskType');
      }
      
      const avatars = [];
      const avatarData = {};
      this.gridOptions.api.forEachNode(node => {
        if (node.data.avatarRef) {
          avatars.push(node.data.avatarRef);
        }
      });
      
      for (const a of avatars) {
        const imgUrl = a !== "00000000-0000-0000-0000-000000000000" ? `${process.env.BASE_URL}api/file/${a}`: null;
        if (imgUrl) {
          const res = await createBase64ImageFromURL(imgUrl);
          if (res && !res.startsWith('data:application/json')) {
            var dimensions = await getImageDimensions(res);
            dimensions.data = res;
            avatarData[a] = dimensions;
          }
        }
      }
      
      const self = this;
      this.gridOptions.api.exportDataAsExcel({ 
        fileName: 'Tasks'
        , sheetName: 'Tasks'
        , rowHeight: 20
        , columnKeys: keys
        , processCellCallback: TaskTemplateDataUtil.processCellCallback(self)
        , addImageToCell: (rowIndex, col, value) => {
          if (col.colId !== 'avatarRef') {
            return;
          }
          
          if (value !== '' && avatarData[value]) {
            const img = avatarData[value];
            const scale = img.h / 20.0;
            let contentType = img.data.substr(5, img.data.indexOf(';') - 5);
            return {
              image: {
                id: rowIndex,
                base64: img.data,
                imageType: contentType.split('/')[1],
                width: img.w / scale,
                height: img.h / scale
              },
            };
          }
        }
      });
      delete this.exporting;
      this.inProgressShow = false;
    },
    async autoSchedule() {
      this.resetAlert();
      // From the selected rows, only get the ones that are currently on manual
      let filtered = this.gridOptions.api.getSelectedNodes()
                            .filter(i => !i.data.autoScheduling)
                            .map(i => { return {uuId: i.data.uuId, autoScheduling: true} });
      if (filtered.length > 0) {
        await taskService.update(filtered).then(() => {
          this.reloadTasks()
        });
      }
    },
    autoAssignStaff() {
      this.resetAlert();
      this.autoAssignStaffShow = true;
    },
    async docImportOk({ items }) {
      const projectId = this.id;
      const selectedId = this.docImportTaskId;
      this.docImportShow = false;
      if(items.length == 0) {
        this.gridOptions.api.deselectAll();
        this.docImportTaskId = null;
        return;
      }
      this.actionProcessing = true;
      this.showInProgress(this.$t('task.progress.import_document'), true);
      const errors = await TaskTemplateDataUtil.createTasksFromImportedDocument(items, projectId, selectedId, false, this.inProgressState, this, this.durationConversionOpts);
      let count = items.length;
      items.forEach(i => {
        count += 1 + i.items? i.items.length : 0; //Do a rough counting. Used to decide whether use singular or plural message.
      });

      this.inProgressShow = false;
      this.forceReload = true;
      taskLoaded = {};
      this.reloadTasks(() => {
        this.getStaffUsageDetails();
        this.resetAlert(errors.length === 0 ? 
            { msg: this.$t(count > 1? 'task.import_tasks' : 'task.import_task'), alertState: alertStateEnum.SUCCESS }
            :
            { msg: this.$t('task.import_complete_with_errors'), alertState: alertStateEnum.WARNING });
        if (errors.length == 0) {
          setTimeout(() => {
            this.gridOptions.groupDefaultExpanded = this.expandLevel = 999; //Set a large number to force expanding the project regardless of previous state.
            this.processNodes();
            this.layoutProfile.taskExpandLevel = this.expandLevel;
            this.updateLayoutProfile();
          }, 0);
        } else {
          this.processNodes();
        }
      })
    },
    docImportCancel() {
      this.docImportShow = false;
      this.gridOptions.api.deselectAll();
      this.docImportTaskId = null;
    },
    autoAssignStaffSuccess(result) {
      this.autoAssignSettings = cloneDeep(result);
      this.showInProgress(this.$t('task.progress.assigning_staff'), true);
      this.allocateStaff(this.autoAssignSettings.staffList);
    },
    async allocateStaff(staffList) {
      const self = this;
      var skillMatchList = [];
      const settings = self.autoAssignSettings.settings;
            
      if (settings.match_staff_based_on_skills) {
        skillMatchList.push({ 'level': 'Yes' });
      }
      else {
        skillMatchList.push({ 'level': 'No' });
      }
      if (settings.include_staff_exact_skill) {
        skillMatchList.push({ 'level': 'Exact' });
      }
      if (settings.include_staff_lower_skill) {
        skillMatchList.push({ 'level': 'Low', 'changeDuration': settings.adjust_task_duration_lower });
      }
      if (settings.include_staff_higher_skill) {
        skillMatchList.push({ 'level': 'High', 'changeDuration': settings.adjust_task_duration_higher });
      }
      const taskList = self.autoAssignTasks();
      for (var idx = 0; idx < taskList.length; idx+=10) {
        if (self.inProgressState.cancel) {
          break;
        }
        const list = taskList.slice(idx, idx + 10).map(t => { return { uuId: t.uuId } });
        this.inProgressLabel = this.$t('task.progress.assigning_staff_to_plural', [`${Math.trunc(idx / taskList.length * 100)}%`]);
        let data = await staffService.allocation({}, {
                                       type: "Assign",
                                       includeAssignedTask: !settings.skip_already_assigned,
                                       includeStartedTask: !settings.skip_already_started,
                                       replaceGenericStaff: settings.replace_generic_only,
                                       overAllocateStaff: settings.allow_over_alloc,
                                       skillMatchList: skillMatchList,
                                       staffList: staffList, 
                                       taskList: list
                                     })
        .then(response => {
          return response.data[response.data.jobCase].length !== 0 &&
                                   response.data[response.data.jobCase][0].length !== 0 ? response.data[response.data.jobCase][0] : [];
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
          return [];
        });

        
        for (var j = 0; j < data.length; j++) {
          self.autoAssignSummary.push(data[j]);
        }
      } 
      self.autoAssignSummaryShow = true;
      self.loadTasks(this.id); 
      self.inProgressShow = false;
    },
    autoAssignSummaryOk() {
      this.autoAssignSummary = [];
      // reload staff usage details in case they have changed
      this.getStaffUsageDetails();
    },
    autoAssignTasks() {
      const api = this.gridOptions != null? this.gridOptions.api: null;
      if (api == null) {
        return [];
      }

      const tasks = this.getUpperTaskNodesFromSelection().filter(i => i.uuId != 'ROOT');
      const taskIdSet = new Set();
      for (const t of tasks) {
        if (t.colId == this.COLUMN_AGGRID_AUTOCOLUMN) {
          const rowNode = api.getRowNode(t.uuId);
          const rowData = rowNode.data;

          //When it is not a summary task, add the uuId to taskIdSet and move on to next iteration.
          if (rowData.taskType != 'Project') {
            taskIdSet.add(t.uuId);
            continue;
          }
          
          //Add all valid task id from task columns of the summary task to taskIdSet
          const keys = Object.keys(rowData).filter(j => j.startsWith(this.taskColPrefix));
          for(const k of keys) {
            if (rowData[k].uuId == null) {
              continue;
            }
            taskIdSet.add(rowData[k].uuId);
          }
          
          //Add all valid task id from the child (or grand child) nodes of the summary task to taskIdSet.
          for (const childNode of rowNode.allLeafChildren) {
            const nodeData = childNode.data;

            if (nodeData.taskType != 'Project') {
              taskIdSet.add(nodeData.uuId);
              continue;
            }
            const keys = Object.keys(nodeData).filter(j => j.startsWith(this.taskColPrefix));
            for(const k of keys) {
              if (nodeData[k].uuId == null) {
                continue;
              }
              taskIdSet.add(nodeData[k].uuId);
            }  
          }
        } else { //Add task id of task (from task columns) to taskIdSet
          taskIdSet.add(t.uuId);
        }
      }
      if (taskIdSet.size > 0) {
        tasks.push(...(Array.from(taskIdSet).map(i => { return { uuId: i } })));
      }
      return Array.from(taskIdSet).map(i => { return { uuId: i } });
    },
    showInProgress(label=null, isStoppable=false) {
      this.inProgressState.cancel = false;
      this.inProgressShow = true;
      this.inProgressLabel = label;
      this.inProgressStoppable = isStoppable;
    },
    progressCancel() {
      this.$set(this.inProgressState, 'cancel', true);
      this.inProgressLabel = this.$t('task.progress.stopping');
    },
    getPathNames(path) {
      var names = '';
      for (var idx = 0; idx < path.length; idx++) {
        names += ' / ';
        names += this.taskNames[path[idx]].name;
      }
      return names;
    }
    , async scheduleProjectHandler(isAuto) {
      const service = projectService;
      this.resetAlert();
      this.actionProcessing = true;
      const data = [{
        uuId: this.id
        , autoScheduling: isAuto
      }];
      await service.update(data).then(() => {
        this.project.autoScheduling = isAuto;
        this.reloadTasks();
      });
      
    },
    async scheduleProjectNowHandler() {
      const service = projectService;
      this.resetAlert();
      this.actionProcessing = true;
      const data = [{ uuId: this.id}];
      
      await service.schedule(data).then(() => {
        this.reloadTasks();
      });
    },
    async scheduleTaskHandler(isAuto) {
      const api = this.gridOptions != null? this.gridOptions.api : null;
      if (api == null) {
        return;
      }
      const service = taskService;
      this.resetAlert();
      this.actionProcessing = true;

      let filtered = null;
      if (this.isCompactView) {
        //Get the task uuId from the selected summary task 
        const tasks = this.taskSelection.filter(i => i.uuId != 'ROOT' && i.colId.startsWith(this.taskColPrefix));
        filtered = [];
        for (const t of tasks) {
          const rowData = api.getRowNode(t.parent).data;
          const colObj = rowData[t.colId];
          if (colObj.uuId != null && colObj.data != null && colObj.data.autoScheduling !== isAuto) {
            filtered.push({ uuId: t.uuId, autoScheduling: isAuto });
          }
        }
      } else {
        // From the selected rows, only get the ones that are currently on auto
        filtered = this.taskSelection
                    .filter(i => {
                      if (i.uuId == 'ROOT') {
                        return false;
                      }
                      const rowNode = api.getRowNode(i.uuId);
                      if (rowNode.data.taskType != 'Project' && rowNode.data.autoScheduling === !isAuto) {
                        return true;
                      }
                      return false;
                    })
                    .map(i => { return {uuId: i.uuId, autoScheduling: isAuto} });
      }
      if (filtered.length > 0) {
        const cellRanges = api.getCellRanges().map(i => {
          return {
            rowStartIndex: i.startRow.rowIndex
            , rowEndIndex: i.endRow.rowIndex
            , columns: i.columns.map(j => j.colId)
          }
          
        });
        await service.update(filtered).then(() => {
          this.reloadTasks(() => {
            api.clearRangeSelection();
            for (const cRange of cellRanges) {
              api.addCellRange(cRange);
            }
            if (this.lastFocusedCell != null && this.lastFocusedCell.rowIndex != null && this.lastFocusedCell.colId != null) {
              api.setFocusedCell(this.lastFocusedCell.rowIndex, this.lastFocusedCell.colId);
            }
          });
        });
      } else {
        this.actionProcessing = false;
      }
    },
    async getStaffUsageDetails() {
      if (this.$router.currentRoute.path.indexOf('/manage/template/') === 0) {
        // staff usage api supports only PROJECT
        return;
      }
      
      let startDateStr = this.project.startDateStr;
      let closeDateStr = this.project.closeDateStr;
      
      if (startDateStr === null ||
          closeDateStr === null) {
        // get the start and end dates from tasks
     
        const response = await projectService.span(this.project.uuId)
        .then(response => {
          return response;
        });
        
        if (response.min !== 32503680000000 && response.min !== 32400000) {
          startDateStr = moment.utc(response.min).format('YYYY-MM-DD');
        }
        if (response.max !== 0 && response.max !== 32503680000000) {
          closeDateStr = moment.utc(response.max).format('YYYY-MM-DD');
        }
      
      }
      
      if (!startDateStr ||
          !closeDateStr) {
        return;    
      }
      
      const self = this;
      await staffService.usage({ 
          start: 0, 
          limit: -1, 
          begin: startDateStr, 
          until: closeDateStr, 
          holder: this.id
      }, this.id, null)
      .then(response => {
        self.staffUsage = {};
        const data = response.data[response.data.jobCase];
        const entityList = response.data['entityList'];
        const baseCalendar = entityList['00000000-0000-0000-0000-000000000000'] ? 
          processSystemCalendar(entityList['00000000-0000-0000-0000-000000000000'].calendarList) : null;
        for (let j = 0; j < data.length; j++) {
          // prepare calendar lists
          const locationUuId = data[j].locationList.length > 0 ? data[j].locationList[0].uuId : null;
          const locationCalendar = locationUuId !== null ? entityList[locationUuId].calendarList : null;
          const calendarList = data[j].calendarList;
          const calendars = [ calendarList, locationCalendar, baseCalendar ];
          // populate the tasks from the dictionary
          for (const task of data[j].taskList) {
            for(var k in entityList[task.uuId]) task[k]=entityList[task.uuId][k];
          }
          data[j].resourceAllocationList = calculateStaffUsage(data[j], moment(startDateStr), moment(closeDateStr), 'month', calendars);
        }
        
        for (const d of data) {
          for (const keyusage of Object.keys(d.resourceAllocationList)) {
            const usage = d.resourceAllocationList[keyusage];
            if (usage.c) {
              self.staffUsage[d.name] = self.$t('staff.error.overallocated');
            }
            else if (usage.a === -1) {
              if (usage.t) {
                const tasks = [];
                for (const task of usage.t) {
                  tasks[task.tu] = !task.w ? self.$t('staff.not_employed') : ((task.w / (task.duration / 60)) * 100) <= 90 ? { message: self.$t('staff.partial_available'), w: task.w, te: task.te, a: usage.a} : null;
                }
                self.staffUsage[d.name] = tasks;
              }
              else {
                self.staffUsage[d.name] = self.$t('staff.not_employed');
              }
            }
            else if (!usage.a) {
              self.staffUsage[d.name] = self.$t('staff.not_available');
            }
            else if (usage.t) {
              const tasks = [];
              for (const task of usage.t) {
                if (((task.w / (task.duration / 60)) * 100) <= 90) {
                  tasks[task.tu] = { message: self.$t('staff.partial_available'), w: task.w, te: task.te, a: usage.a};
                }
              }
              
              if (!self.staffUsage[d.name]) {
                self.staffUsage[d.name] = tasks;
              }
            }
          }
        }
        // data.objectList[7].resourceAllocationList[0].c - overallocated
        // data.objectList[7].resourceAllocationList[0].a == -1 not working
      })
      .catch(e => { 
        if (e != null && e.response != null && e.response.status == 403) {
          //Do nothing when it is insufficient permission. 
          //Reason: The requested detail is just supportive information to show symbol in staff column.
        }
      });
    },
    async prepareProjectDetails() {
      await projectService.get([{ uuId: this.id }], ['COMPANY', 'STAGE_LIST', 'LOCATION'])
      .then(response => {
        const listName = response.data.jobCase;
        const _project = response.data[listName][0];
        this.project.autoScheduling = _project.autoScheduling != null? _project.autoScheduling : true;
        this.project.scheduleMode = _project.scheduleMode != null? _project.scheduleMode : 'ASAP';
        this.project.startDateStr = _project.scheduleStart != null? moment.utc(_project.scheduleStart).format('YYYY-MM-DD') : null;
        this.project.closeDateStr = _project.scheduleFinish != null? moment.utc(_project.scheduleFinish).format('YYYY-MM-DD') : null;
        this.project.companyList = _project.companyList ? _project.companyList : [];
        
        this.$store.dispatch("breadcrumb/update", _project.name, { root: true });
        
        const stageList = _project.stageList || [];
        if(stageList.length > 0) {
          if(this.optionStages.length == 0) {
            stageList.unshift({ uuId: null, name: '' });
            this.optionStages.splice(0, this.optionStages.length, ...stageList.map(i => {
              return { value: i.uuId, text: i.name } 
            }));
          }
        }

        if (_project.locationList && _project.locationList.length > 0) {
          this.project.locationId = _project.locationList[0].uuId;
          this.calendarType.holderId = this.project.locationId;
          this.calendarType.type = 'project-location';
          this.locationCalendar(this.project.locationId);
        } else {
          this.project.locationId = null;
          this.systemLocationCalendar();
        }
        
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    getProjectDetailsWhenReloadTask() {
      return projectService.get([{ uuId: this.id }], ['NOTE','REBATE'])
      .then(response => {
        const listName = response.data.jobCase;
        return response.data[listName][0];
      })
      .catch(e => {
        console.error(e); //eslint-disable-line no-console
        return null;
      })
    },
    manualScheduleProjectHandler(event) {
      event.preventDefault();
      if(!this.disableManualScheduleProject) {
        this.scheduleProjectHandler(false);
      }
    },
    autoScheduleProjectHandler(event) {
      event.preventDefault();
      if(!this.disableAutoScheduleProject) {
        this.scheduleProjectHandler(true);
      }
    },
    manualScheduleTaskHandler(event) {
      event.preventDefault();
      if(!this.disableManualScheduleTask) {
        this.scheduleTaskHandler(false);
      }
    },
    autoScheduleTaskHandler(event) {
      event.preventDefault();
      if(!this.disableAutoScheduleTask) {
        this.scheduleTaskHandler(true);
      }
    },
    toggleCompactViewHandler(event) {
      if (event) {
        event.preventDefault();
      }
      this.gridOptions.api.clearRangeSelection();
      this.gridOptions.api.clearFocusedCell();
      this.taskSelection.splice(0, this.taskSelection.length);
      this.isCompactView = !this.isCompactView;
      this.layoutProfile.compactView = this.isCompactView;
      this.updateLayoutProfile();
      this.loadColumnSettings();
      this.gridOptions.api.showLoadingOverlay();
      
      // force a full structure reload
      this.forceReload = true;
      taskLoaded = {};
      
      this.loadTasks(this.id, {
        callback: () =>{
          if(this.gridOptions != null && this.gridOptions.api != null) {
            if(this.gridOptions.api.getDisplayedRowCount() < 1) {
              this.gridOptions.api.showNoRowsOverlay();
            } else {
              this.gridOptions.api.hideOverlay();
              this.gridOptions.api.setFocusedCell(0, this.COLUMN_AGGRID_AUTOCOLUMN);
              const range = {
                rowStartIndex: 0,
                rowEndIndex: 0,
                columns: ['ag-Grid-AutoColumn']
              }
              this.gridOptions.api.addCellRange(range);
            }
          }

          setTimeout(() => {
            this.processNodes();
          }, 300);
        }
      })
    },
    processNodes() {
      this.maxLevel = 0;
      if (this.gridOptions == null || this.gridOptions.api == null) {
        return;
      }
      //Fixed #897, use setTimeout to make sure grid node is refreshed.
      setTimeout(() => {
        this.isTaskExpandedByActionBar = true;
        const mExpandedSet = this.manualExpandedSet;
        const mCollpsedSet = this.manualCollapsedSet;
        if (this.gridOptions != null && this.gridOptions.api != null) {
          this.gridOptions.api.forEachNode(node => {
            if (node.data && mExpandedSet.has(node.data.uuId)) {
              node.expanded = true;
            } else if (node.data && mCollpsedSet.has(node.data.uuId)) {
              node.expanded = false;
            } else {
              if (node.level < this.expandLevel) {
                node.expanded = true;
              } else if (node.allChildrenCount && node.allChildrenCount > 0) {
                node.expanded = false;
              }
            }
            
            // update the max level
            if (node.level > this.maxLevel) {
              this.maxLevel = node.level;
            }
          });
          this.gridOptions.api.onGroupExpandedOrCollapsed();
        }
        
        if (this.maxLevel < this.expandLevel) {
          this.gridOptions.groupDefaultExpanded = this.expandLevel = this.maxLevel;
        }

        //setTimeout is used to wait the onRowGroupOpened events finish their process.
        setTimeout(() => {
          this.isTaskExpandedByActionBar = false;
        }, 300);

      }, 50)
    },
    restoreNodeState() {
      if (this.nodeState && this.gridOptions != null && this.gridOptions.api != null) {
        this.gridOptions.api.forEachNode(node => {
          if (node.id in this.nodeState) {
            node.setExpanded(this.nodeState[node.id].expanded);
            node.setSelected(this.nodeState[node.id].selected);
          }
        });
        this.nodeState = {};
      }
    },
    collapse() {
      this.expandLevel--;
      this.processNodes();
      
      // save
      this.layoutProfile.taskExpandLevel = this.expandLevel;
      this.updateLayoutProfile();
    },
    expand() {
      this.expandLevel++;
      this.processNodes();
      
      // save
      this.layoutProfile.taskExpandLevel = this.expandLevel;
      this.updateLayoutProfile();
    },
    onSchedulerOver() {
      this.$refs.scheduler.visible = true;
    },
    onSchedulerLeave() {
      this.$refs.scheduler.visible = false;
    },
    onViewOver() {
      this.$refs.view.visible = true;
    },
    onViewLeave(arg) {
      if (typeof arg?.toElement?.className === 'string' &&
          arg.toElement.className !== 'arrow' &&
          arg.toElement.className && !arg.toElement.className.startsWith('popover')) {
        this.$refs.view.visible = false;
      }
    },
    onFilterOver() {
      this.$refs.filter.visible = true;
    },
    onFilterLeave() {
      this.$refs.filter.visible = false;
    },
    onFilterSubmit() {
      this.filterTextFocus = false;
      this.$refs.filter.visible = false;
      this.closePriorityNavDropdown = true; //Signal priorityNavigation to close the dropdown.
      
      this.changeFilter(this.filterText);
    },
    onFilterClear() {
      this.filterText = '';
      this.changeFilter(this.filterText);
    },
    async dateTimeDurationValueChanged(taskId, property, newValue, rowData, { skipOutOfProjectDateCheck=false, defaultActionForNonWorkPrompt=null, oldValue=null }={}) {
      //Change lock state to true to prevent other thread calling dateTimeDurationValueChanged.
      //Reason: The TaskDateTimeDurationCalculation modal can only be called one at a time.
      this.isDateCalcInProgress = true;

      let trigger = TRIGGERS.DURATION;
      if (property == 'startTime') {
        trigger = TRIGGERS.START_DATE;
      } else if (property == 'closeTime') {
        trigger = TRIGGERS.CLOSE_DATE;
      } else if (property == 'constraint') {
        trigger = TRIGGERS.CONSTRAINT_TYPE;
      } else if (property == 'autoScheduling') {
        trigger = TRIGGERS.TASK_SCHEDULE_MODE;
      }

      const projectScheduleFromStart = this.project.scheduleMode != null? this.project.scheduleMode == 'ASAP' : true;
      
      const nodeData = rowData;
      let newDateStr = null;
      let newTimeStr = null;
      let newConstraintType = null;
      let newConstraintDateStr = null;
      let newAutoScheduling = null;
      if (TRIGGERS.START_DATE == trigger || TRIGGERS.CLOSE_DATE == trigger) {
        const newDateTime = newValue != null? moment.utc(newValue) : null;
        if (newDateTime != null) {
          newDateStr = newDateTime.format('YYYY-MM-DD');
          newTimeStr = newDateTime.format('HH:mm');
        }
      } else if (TRIGGERS.CONSTRAINT_TYPE == trigger || TRIGGERS.CONSTRAINT_DATE == trigger) {
        if (newValue != null) {
          newConstraintType = newValue.type;
          newConstraintDateStr = newValue.time != null? moment.utc(newValue.time).format('YYYY-MM-DD') : null;
        }
      } else if (TRIGGERS.TASK_SCHEDULE_MODE == trigger) {
        if (newValue != null) {
          newAutoScheduling = newValue;
        }
      }
      let startDateTime = nodeData.startTime != null? moment.utc(nodeData.startTime) : null;
      if (TRIGGERS.START_DATE == trigger && oldValue != null) {
        startDateTime = moment.utc(oldValue);
      }
      let closeDateTime = nodeData.closeTime != null? moment.utc(nodeData.closeTime) : null;
      if (TRIGGERS.CLOSE_DATE == trigger && oldValue != null) {
        closeDateTime = moment.utc(oldValue);
      }

      const constraintDateTime = nodeData.constraint.time != null? moment.utc(nodeData.constraint.time) : null;
      
      const startDateStr = startDateTime != null? startDateTime.format('YYYY-MM-DD') : null;
      const startTimeStr = startDateTime != null? startDateTime.format('HH:mm') : null;
      const closeDateStr = closeDateTime != null? closeDateTime.format('YYYY-MM-DD') : null;
      const closeTimeStr = closeDateTime != null? closeDateTime.format('HH:mm') : null;
      const constraintType = nodeData.constraint.type != null? nodeData.constraint.type : null;
      const constraintDateStr = constraintDateTime != null? constraintDateTime.format('YYYY-MM-DD') : null;

      const dc = this.durationCalculation;
      dc.taskId = taskId;
      dc.trigger = trigger;
      dc.taskName = nodeData.name;
      dc.resizeMode = false; //default (false): task move action.
      if (TRIGGERS.START_DATE == trigger && !projectScheduleFromStart) {
        dc.resizeMode = true;
      } else if (TRIGGERS.CLOSE_DATE == trigger && projectScheduleFromStart) {
        dc.resizeMode = true;
      }

      this.calendar = null; //reset to null
      if (nodeData.staffs.length > 0) {
        this.calendarType.holderId = nodeData.staffs[0].uuId;
        this.calendarType.type = 'staff';
        dc.enableManualScheduleSuggestion = false;
        dc.defaultActionForNonWorkPrompt = null;
        this.calendar = await staffService.calendar(nodeData.staffs[0].uuId)
        .then((response) => {
          // combine the calendar lists into single lists for each day
          const data = response.data[response.data.jobCase];
          return transformCalendar(processCalendar(data));
        })
        .catch((e) => {
          this.httpAjaxError(e);
          return null;
        });

      } else if (this.projectCalendar != null) {
        this.calendarType.holderId = this.project.locationId;
        this.calendarType.type = 'project-location';
        this.calendar = this.projectCalendar;
        dc.enableManualScheduleSuggestion = true;
        dc.defaultActionForNonWorkPrompt = 'move';
      } else if (this.systemCalendar != null) {
        this.calendarType.holderId = null;
        this.calendarType.type = 'system';
        this.calendar = this.systemCalendar;
        dc.enableManualScheduleSuggestion = true;
        dc.defaultActionForNonWorkPrompt = 'move';
      }

      //defensive code: fallback to default calendar
      if (this.calendar == null) {
        this.calendarType.holderId == null;
        this.calendarType.type = 'system';
        this.calendar = cloneDeep(DEFAULT_CALENDAR);
        dc.enableManualScheduleSuggestion = true;
        dc.defaultActionForNonWorkPrompt = 'move';
      }
      dc.calendar = this.calendar;

      dc.oldDateStr = null;
      dc.oldTimeStr = null;
      if (TRIGGERS.START_DATE == trigger) { // || TRIGGERS.START_TIME == trigger) {
        dc.startDateStr = newDateStr;
        dc.startTimeStr = newTimeStr;
        dc.oldDateStr = startDateStr;
        dc.oldTimeStr = startTimeStr;
      } else {
        dc.startDateStr = startDateStr;
        dc.startTimeStr = startTimeStr;
      }
      if (TRIGGERS.CLOSE_DATE == trigger) { // || TRIGGERS.CLOSE_TIME == trigger) {
        dc.closeDateStr = newDateStr;
        dc.closeTimeStr = newTimeStr;
        dc.oldDateStr = closeDateStr;
        dc.oldTimeStr = closeTimeStr;
      } else {
        dc.closeDateStr = closeDateStr;
        dc.closeTimeStr = closeTimeStr;
      }

      //Check if trigger is time related
      if (TRIGGERS.START_DATE == trigger && dc.startDateStr == dc.oldDateStr) {
        dc.trigger = trigger = TRIGGERS.START_TIME;
      } else if (TRIGGERS.CLOSE_DATE == trigger && dc.closeDateStr == dc.oldDateStr) {
        dc.trigger = trigger = TRIGGERS.CLOSE_TIME;
      }

      //Make sure duration value is in string format
      let rawDuration = nodeData.duration;
      if (rawDuration != null && typeof rawDuration != 'string') {
        rawDuration = convertDurationToDisplay(rawDuration, nodeData.durationAUM != null? nodeData.durationAUM: 'D', this.durationConversionOpts);
      }
      dc.durationDisplay = TRIGGERS.DURATION == trigger? newValue : rawDuration;
      dc.lockDuration = nodeData.lockDuration != null? nodeData.lockDuration : false;
      
      if (TRIGGERS.CONSTRAINT_TYPE == trigger || TRIGGERS.CONSTRAINT_DATE == trigger) {
        dc.constraintType = newConstraintType;
        dc.constraintDateStr = newConstraintDateStr;
        dc.oldConstraintType = constraintType;
        dc.oldConstraintDateStr = constraintDateStr;
      } else {
        dc.constraintType = constraintType;
        dc.constraintDateStr = constraintDateStr;
      }
      
      dc.skipOutOfProjectDateCheck = skipOutOfProjectDateCheck == true;
      
      //Override defaultActionForNonWorkPrompt when provided parameter is not null
      if (defaultActionForNonWorkPrompt != null) {
        dc.defaultActionForNonWorkPrompt = defaultActionForNonWorkPrompt;
      }
      
      dc.taskAutoScheduleMode = newAutoScheduling != null? newAutoScheduling : (nodeData.autoScheduling != null? nodeData.autoScheduling : true);
      dc.projectScheduleFromStart = projectScheduleFromStart;
      dc.projectStartDateStr = this.project != null? this.project.startDateStr : null;
      dc.projectCloseDateStr = this.project != null? this.project.closeDateStr : null;
      
      //Start calculation
      //$nextTick is required to wait for the on-going ui rendering to be done.
      this.$nextTick(() => {
        this.durationCalculationShow = true;
      });
    },
    
    async durationCalculationOk({ skip=false, startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay, constraintType, constraintDateStr, taskAutoScheduleMode=null }) {
      this.isDateCalcInProgress = false;
      if (skip) {
        //Call processValueChanged() for next iteration.
        this.inProgressLabel = this.$t('task.progress.updating_tasks');
        this.processValueChanged(this.gridOptions.api);
        return;
      }

      const task = { uuId: this.durationCalculation.taskId }
      if (startDateStr != null) {
        const startDateTime = moment.utc(startDateStr, 'YYYY-MM-DD');
        if (startTimeStr != null) {
          const token = startTimeStr.split(':');
          startDateTime.hour(token[0]).minute(token[1]);
        }
        task.startTime = startDateTime.valueOf();
      } else {
        task.startTime = null;
      }

      if (closeDateStr != null) {
        const closeDateTime = moment.utc(closeDateStr, 'YYYY-MM-DD');
        if (closeTimeStr != null) {
          const token = closeTimeStr.split(':');
          closeDateTime.hour(token[0]).minute(token[1]);
        }
        task.closeTime = closeDateTime.valueOf();
      } else {
        task.closeTime = null;
      }

      if (taskAutoScheduleMode != null) {
        task.autoScheduling = taskAutoScheduleMode;
      } else if (TRIGGERS.TASK_SCHEDULE_MODE === this.durationCalculation.trigger) {
        task.autoScheduling = this.durationCalculation.taskAutoScheduleMode;
      }

      if (durationDisplay != null) {
        const { value } = convertDisplayToDuration(durationDisplay, this.durationConversionOpts);
        const { unit } = analyzeDurationAUM(durationDisplay);
        task.durationAUM = unit;
        task.duration = value;
      } else if (task.autoScheduling == true) {
        task.durationAUM = 'D';
        task.duration = 480; // 1 day = 480 minutes
      } else {
        task.duration = null; 
      }

      task.constraintType = constraintType;
      if (constraintDateStr != null) {
        task.constraintTime = moment.utc(constraintDateStr, 'YYYY-MM-DD').valueOf();
      } else {
        task.constraintTime = null;
      }

      this.pendingProcessRequestList.push({
        method: 'PUT',
        invoke: `/api/task/update`,
        body: [task],
        vars: [],
        note: `taskUpdate__${task.uuId}`
      });

      //Last, call processValueChanged() for next iteration
      this.inProgressLabel = this.$t('task.progress.updating_tasks');
      this.processValueChanged(this.gridOptions.api);
    },
    durationCalculationCancel() {
      this.isDateCalcInProgress = false;
      //As agreed with Paul, when user chose to abort the operation, cancel all the requests including the requests being processed/generated previously.
      this.pendingProcessRequestList.splice(0, this.pendingProcessRequestList.length);
      this.processDateValueChangedList.splice(0, this.processDateValueChangedList.length);
      this.processValueChangedList.splice(0, this.processValueChangedList.length);
      
      //Revert the affected row data to original value
      if (this.originalRowDataList.length > 0) {
        const api = this.gridOptions.api;
        const oldData = JSON.parse(JSON.stringify(this.originalRowDataList));
        api.applyTransaction({ update: oldData });
        api.refreshCells({ force: true, rowNodes: oldData });
        this.originalRowDataList.splice(0, this.originalRowDataList.length);
      }
      this.inProgressShow = false;
      return;
    },
    async durationCalculationCalendarChange({ toAddExceptions, toUpdateExceptions }) {
      //1. Call calendar service (API) to add or update the exceptions
      //2. Update the calendar object
      //3. Call calcDateTimeDuration() to restart calculation with updated calendar object.
      //4. Reload latest calendar from backend for future usage.

      let hasError = false;
      const errorMsg = this.$t(`calendar.error.failed_to_update_calendar`);
      const calendar = this.calendar;
      const locationId = this.calendarType?.holderId ?? null;
      
      // Defensive code: Theorectically durationCalculationCalendarChange() will only be call when calendarType.type is 'staff'. 
      // When it is not staff, abort operation.
      if (this.calendarType.type != 'staff') {
        return;
      }
      
      if (toUpdateExceptions != null && toUpdateExceptions.length > 0) {
        const _toUpdateExceptions = cloneDeep(toUpdateExceptions);
        _toUpdateExceptions.forEach(i => {
          delete i.calendar;
        });
        await calendarService.update(_toUpdateExceptions, locationId)
        .then(response => {
          if (response == null || 207 == response.status) {
            hasError = true;
            this.resetAlert({ msg: errorMsg, alertState: alertStateEnum.ERROR });
            return;
          }

          //Update the calendar object with the change
          if (calendar.Leave == null) {
            calendar.Leave = [];
          }
          for (let i = 0, len = _toUpdateExceptions.length; i < len; i++) {
            const curException = _toUpdateExceptions[i];
            //When exception type is Working, add it to calendar.Working
            if (curException.type == 'Working') {
              if (calendar.Working == null) {
                calendar.Working = [];
              }
              calendar.Working.push(cloneDeep(curException));
            }

            //Remove old leave exception if exception type is 'Working'. Otherwise, update the old leave exception.
            const idx = calendar.Leave.findIndex(j => j.uuId === curException.uuId);
            if (idx != -1) {
              if (curException.type == 'Working') {
                calendar.Leave.splice(idx, 1);
              } else {
                calendar.Leave[idx] = cloneDeep(curException);
              }
            }
          }
        })
        .catch(() => {
          hasError = true;
          this.resetAlert({ msg: errorMsg, alertState: alertStateEnum.ERROR });
        });
      }

      //Stop proceed further when failed to update calendar exception.
      if (hasError) {
        this.durationCalculationCancel();
        return;
      }

      if (toAddExceptions != null && toAddExceptions.length > 0) {
        await calendarService.create(toAddExceptions, locationId)
        .then(response => {
          if (response == null || 207 == response.status) {
            hasError = true;
            this.resetAlert({ msg: errorMsg, alertState: alertStateEnum.ERROR });
          } else { //
            //Fill the uuId to exception and add the newly created exception to calendar object
            const list = response.data[response.data.jobCase];
            for (let i = 0, len = list.length; i < len; i++) {
              const curItem = list[i];
              if (curItem && curItem.uuId != null) {
                const curException =  toAddExceptions[i];
                curException.uuId = curItem.uuId;
                if (calendar[curException.type] == null) {
                  calendar[curException.type] = [];
                }
                calendar[curException.type].push(cloneDeep(curException));
              } else {
                hasError = true;
                this.resetAlert({ msg: errorMsg, alertState: alertStateEnum.ERROR });
                break;
              }
            }
          }
        })
        .catch(() => {
          hasError = true;
          this.resetAlert({ msg: errorMsg, alertState: alertStateEnum.ERROR });
        });
      }

      if (hasError) {
        this.durationCalculationCancel();
      } else {
        const dc = this.durationCalculation;
        dc.calendar = this.calendar;
        dc.skipOutOfProjectDateCheck = true; //Set true. Reason: Usually user choose to proceed in the projectDateCheck logic before reaching this calendarChange logic.
        
        this.$nextTick(() => {
          this.durationCalculationShow = true;
        });
      }
    },
    changeFilter: debounce(function(value, updateProfile=true) {
      const self = this;
      this.filterValue = value;
      if (this.gridOptions == null || this.gridOptions.api == null) {
        return;
      }
      
      if (updateProfile) {
        this.layoutProfile.tasksFilterText = value;
        this.updateLayoutProfile();
      }
            
      if (value === '' && this.badgeFilters.length == 0) {
        delete self.searchData;
        self.gridOptions.api.onFilterChanged();
        return;
      }
      
      this.showInProgress(this.$t('task.filtering'));
      taskViewService.listTree(
            { self: this, start: 0, limit: -1, ksort: this.sort, filterValue: this.filterValue, badgeFilters: this.badgeFilters, sortByParent: !this.flatList }
            , self.id, [], false, this.customFields
            , { skillCustomFields: this.skillCustomFields, resourceCustomFields: this.resourceCustomFields, noteCustomFields: this.noteCustomFields })
        .then(response => {
        
          self.searchData = response.data || [];
          self.gridOptions.api.onFilterChanged();
          self.inProgressShow = false;
        })
        .catch(() => {          
          self.searchData = [];
          self.gridOptions.api.onFilterChanged();
          self.inProgressShow = false;
        });
        
    }, 100),
    toDateTime(value) {
      let rawValue = value ? value : null;
      if (rawValue == 0 || rawValue == '' || rawValue == 9223372036854776000) {
        rawValue = null;
      }
      return rawValue != null? moment.utc(rawValue).format('YYYY-MM-DD hh:mm A') : null;
    },
    onFilterTextDropdownHide(bvEvent) {
      if(this.filterTextFocus){
        bvEvent.preventDefault();
      }
    },
    taskGroupTotalModeToggled(groupId) {
      this.taskColumnTotalMode = !this.taskColumnTotalMode;
      
      const columnDefs = this.gridOptions.api.getColumnDefs();
      const groupColDef = columnDefs.find(i => i.groupId == groupId);
      if (groupColDef != null) {
        const nextState = !groupColDef.totalMode;
        groupColDef.totalMode = nextState;
        groupColDef.headerGroupComponentParams.totalMode = nextState;
        const children = groupColDef.children;
        for (let i = 0, len = children.length; i < len; i++) {
          children[i].cellRendererParams.totalMode = nextState;
          if (children[i].comparatorFunc != null) {
              //Get a new comparator based on totalMode (nextState)
              children[i].comparator = children[i].comparatorFunc(nextState);
            }
        }
        const params = {
          force: true,
          suppressFlash: false,
          columns: children.map(i => i.colId)
        };
        this.gridOptions.api.refreshCells(params);
        this.gridOptions.api.setColumnDefs(columnDefs);

        setTimeout(() => {
          this.saveColumnState({ skipTaskExpandLevel: true });
        }, 300);
      }
    },
    async taskGroupSaveAs(groupId) {
      const taskGroupDef = this.gridOptions.api.getColumnDefs().find(i => i.groupId == groupId);
      if (taskGroupDef == null) {
        //TODO: show error msg;
        return;
      }
        
      const profileId = this.extractProfileId(taskGroupDef.groupId);
      const profileTaskGroup = await taskGroupProfileService.get(profileId)
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0];
      })
      .catch(() => {
        return null;
      });

      if (profileTaskGroup == null) {
        //TODO: show error msg;
        return;
      }

      const children = taskGroupDef.children;

      //From columnDef (local)
      this.newTaskGroup.headerName = taskGroupDef.headerName;
      this.newTaskGroup.property = taskGroupDef.property;
      this.newTaskGroup.children = children != null? children.filter(i => i.native == true).map(i => { return {
        name: i.headerName, color: i.color } }) : [];
      const taskUtilColId = this.getTaskUtilColDef().colId;
      this.newTaskGroup.dynamicTasks = children != null? children.filter(i => i.colId != taskUtilColId).map(i => { return { 
        name: i.headerName, color: i.color } }) : [];
      //From profile      
      this.newTaskGroup.description = profileTaskGroup.description;
      this.newTaskGroup.propertyOptions = this.getTaskGroupProperty();
      this.newTaskGroup.sharingMembers = profileTaskGroup.sharingMembers;
      this.newTaskGroup.editors = profileTaskGroup.editors;
      this.newTaskGroup.sharedVisibility = profileTaskGroup.sharedVisibility;
      this.newTaskGroupShow = true;
    },
    async taskGroupAddNewTask(groupId) {
      this.taskGroupEdit(groupId);
    },
    saveColumnState({ compactColumns=null, skipTaskExpandLevel=false } = {}) {
      //Defensive code to avoid javascript error. Usually this condition is true when user navigate away from the page and the component is being destroyed()
      if (this.gridOptions == null || this.gridOptions.columnApi == null) {
        return;
      }
      
      const columns = this.gridOptions.columnApi.getAllDisplayedColumns();
      if (this.isCompactView) {
        if (compactColumns != null) {
          const sanitizedColumns = this.sanitizeCompactTaskColumns(compactColumns)
          const autoGroupColumn = this.gridOptions.columnApi.getAllDisplayedColumns()
            .filter(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN).map(i => { 
              return { colId: i.colId, width: i.actualWidth, sort: i.sort, hide: false }
            })[0];
          sanitizedColumns.unshift(autoGroupColumn);
          this.layoutProfile.compactTaskColumns = sanitizedColumns;
        } else {
          const compactTaskColumns = this.gridOptions.api.getColumnDefs().filter(i => i.hide != true || (i.groupId != null && i.groupId.startsWith(this.taskGroupPrefix)))
          .map(i => {
            const rObj = { colId: i.colId, width: i.width, sort: i.sort, groupId: i.groupId, hide: !!i.hide };
            if (i.groupId != null) {
              delete rObj.colId;
              delete rObj.hide;
              delete rObj.width;
              delete rObj.sort;
              rObj.headerName = i.headerName;
              rObj.lastModified = i.lastModified;
              rObj.hasDifferences = !!i.hasDifferences;
              rObj.property = i.property;
              rObj.totalMode = !!i.totalMode;
              rObj.children = i.children.map(c => { 
                return { 
                  colId: c.colId, width: c.width, sort: c.sort, hide: !!c.hide
                  , headerName: c.headerName
                  , native: c.native != null? c.native: false
                  , color: c.color != null? c.color: null
                  , skills: Array.isArray(c.skills) && c.skills.length > 0? c.skills: null
                  , staff: Array.isArray(c.staff) && c.staff.length > 0? c.staff: null
                  , resources: Array.isArray(c.resources) && c.resources.length > 0? c.resources: null
                  , rebates: Array.isArray(c.rebates) && c.rebates.length > 0? c.rebates: null
                  , tags: Array.isArray(c.tags) && c.tags.length > 0? c.tags: null
                }
              });
            }
            return rObj;
          });
          const autoGroupColumn = this.gridOptions.columnApi.getAllDisplayedColumns()
            .filter(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN).map(i => { 
              return { colId: i.colId, width: i.actualWidth, sort: i.sort, hide: false }
            })[0];
          compactTaskColumns.unshift(autoGroupColumn);
          this.layoutProfile.compactTaskColumns = compactTaskColumns;
        }
        
      } else {
        this.layoutProfile.taskColumns = columns.map(c => { 
          return { colId: c.colId, width: c.actualWidth, sort: c.sort }
        });
      }
      if (!skipTaskExpandLevel) {
        this.layoutProfile.taskExpandLevel = this.expandLevel;
      }
      
      this.updateLayoutProfile();
    },
    // openTaskGroupSelectorModal() {
    taskGroupManageTaskGroups() {
      const data = [];
      const prefix = this.taskGroupPrefix;
      this.taskGroup.propertyOptions = this.getTaskGroupProperty();
    
      //Prepare available task group list
      const groupColumnDefs = this.gridOptions.api.getColumnDefs().filter(i => i.groupId != null && i.groupId.startsWith(prefix));
      for (let i = 0, len = groupColumnDefs.length; i < len; i++) {
        const colDef = groupColumnDefs[i];
        const profileId = colDef.groupId.substring(prefix.length);
        data.push(profileId);
      }
      this.taskGroup.data = data;
      

      this.taskGroup.feedback = { msg: null, alertState: null }
      this.taskGroupShow = true;
    },
    getTaskGroupProperty() {
      //Prepare property options
      const options = [];
      Array.from(this.taskColumnDefs.keys()).forEach(k => {
        const f = this.columnDefs.find(c => c.field == k || (c.field == 'constraint' && c.colId == k));
        if (f != null) {
          if (f.field == 'constraint') {
            options.push({ value: f.colId, text: f.headerName });
          } else {
            options.push({ value: f.field, text: f.headerName });
          }
          
        }
      });
      return options.sort(this.columnSortFunc);
    },
    columnSortFunc(a, b) {
      const nameA = a.text != null? a.text.toLowerCase() : a.text;
      const nameB = b.text != null? b.text.toLowerCase() : b.text;
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    },
    taskGroupChanged(payload) {
      let compactTaskCols = this.consolidateColumnDefsAndDisplay();
      compactTaskCols = this.consolidateTaskGroup(compactTaskCols, payload);
      this.layoutProfile.compactTaskColumns = compactTaskCols;
      this.updateLayoutProfile();
    },
    findLastSequence(array, regex) {
      for (let i = array.length - 1; i >= 0; --i) {
        const x = array[i];
        if (x.colId == null) {
          continue;
        }
        const match = x.colId.match(regex);
        if (match == null) {
          continue;
        }
        return match[1];
      }
      return -1;
    },
    hasTaskGroupChanged(profileGroup, groupB) {
      if (profileGroup == null && groupB == null) {
        return false;
      } else if (profileGroup == null && groupB != null || profileGroup != null && groupB == null) {
        return true;
      }

      if (profileGroup.lastModified != groupB.lastModified) {
        return true;
      }

      if (profileGroup.property != groupB.property) {
        return true;
      }

      if (profileGroup.headerName != groupB.headerName || profileGroup.property != groupB.property) {
        return true;
      }

      const childrenA =  profileGroup.children;
      const childrenB =  groupB.children.filter(i => i.native == true).map(i => ({ 
        name: i.headerName
        , color: i.color
        , skills: i.skills
        , staff: i.staff
        , resources: i.resources
        , rebateds: i.rebates
        , tags: i.tags
      }));

      if (childrenA.length != childrenB.length) {
        return true;
      }

      let childA = null;
      let childB = null
      let hasChange = false;
      for (let i = 0, len = childrenA.length; i < len; i++) {
        childA = childrenA[i];
        childB = childrenB[i];
        if (childA.name != childB.name 
          || childA.color != childB.color
          || (childA.skills == null && childB.skills != null)
          || (childA.skills != null && childB.skills == null)
          || (childA.staff == null && childB.staff != null)
          || (childA.staff != null && childB.staff == null)
          || (childA.resources == null && childB.resources != null)
          || (childA.resources != null && childB.resources == null)
          || (childA.rebates == null && childB.rebates != null)
          || (childA.rebates != null && childB.rebates == null)
          || (childA.tags == null && childB.tags != null)
          || (childA.tags != null && childB.tags == null)
          ) {
          hasChange = true;
          break;
        }
        //skills
        if (Array.isArray(childA.skills)) {
          if (childA.skills.length != childB.skills.length) {
            hasChange = true;
            break;
          }
          let count = childA.skills.length;
          for (const item of childA.skills) {
            const found = childB.skills.find(j => j.uuId == item.uuId);
            if (found == null || found.name != item.name || found.level != item.level) {
              hasChange = true;
              break;
            }
            count--;
          }
          if (hasChange || count != 0) {
            break;
          }
        }
        //staff
        if (Array.isArray(childA.staff)) {
          if (childA.staff.length != childB.staff.length) {
            hasChange = true;
            break;
          }
          let count = childA.staff.length;
          for (const item of childA.staff) {
            const found = childB.staff.find(j => j.uuId == item.uuId);
            if (found == null || found.name != item.name || found.utilization != item.utilization) {
              hasChange = true;
              break;
            }
            count--;
          }
          if (hasChange || count != 0) {
            break;
          }
        }
        //resources
        if (Array.isArray(childA.resources)) {
          if (childA.resources.length != childB.resources.length) {
            hasChange = true;
            break;
          }
          let count = childA.resources.length;
          for (const item of childA.resources) {
            const found = childB.resources.find(j => j.uuId == item.uuId);
            if (found == null || found.name != item.name || found.quantity != item.quantity || found.utilization != item.utilization) {
              hasChange = true;
              break;
            }
            count--;
          }
          if (hasChange || count != 0) {
            break;
          }
        }
        //rebates
        if (Array.isArray(childA.rebates)) {
          if (childA.rebates.length != childB.rebates.length) {
            hasChange = true;
            break;
          }
          let count = childA.rebates.length;
          for (const item of childA.rebates) {
            const found = childB.rebates.find(j => j.uuId == item.uuId);
            if (found == null || found.name != item.name || found.rebate != item.rebate) {
              hasChange = true;
              break;
            }
            count--;
          }
          if (hasChange || count != 0) {
            break;
          }
        }
        //tags
        if (Array.isArray(childA.tags)) {
          if (childA.tags.length != childB.tags.length) {
            hasChange = true;
            break;
          }
          let count = childA.tags.length;
          for (const item of childA.tags) {
            const found = childB.tags.find(j => j.uuId == item.uuId);
            if (found == null || found.name != item.name) {
              hasChange = true;
              break;
            }
            count--;
          }
          if (hasChange || count != 0) {
            break;
          }
        }
      }
      
      childA = null;
      childB = null;
      return hasChange;
    },
    populateTaskGroupDef(profileTaskGroup) {
      const taskGroupProfId = profileTaskGroup.uuId;
      const metadata = taskColumnMetadata(this, profileTaskGroup.property);
      const newGroupColDef = metadata.groupColumnDef;
      newGroupColDef.lastModified = profileTaskGroup.lastModified;
      newGroupColDef.headerName = profileTaskGroup.headerName;
      newGroupColDef.property = newGroupColDef.headerGroupComponentParams.property = profileTaskGroup.property;
      newGroupColDef.groupId = `${this.taskGroupPrefix}${taskGroupProfId}`;
      
      //Process children
      const taskObjs = profileTaskGroup.children;
      const newChildren = newGroupColDef.children;
      for (const tObj of taskObjs) {        
        const name = tObj.name;
        const regex = new RegExp(this.getTaskColIdRegex(taskGroupProfId, name));
        const nextSequence = this.findLastSequence(newChildren, regex) + 1;
        const newColDef = cloneDeep(metadata.columnDef);
        newColDef.colId = newColDef.field = this.getTaskColId(taskGroupProfId, nextSequence, name);
        newColDef.headerName = name;
        newColDef.color = tObj.color;
        newColDef.native = true;
        newChildren.push(newColDef);        
      }

      return newGroupColDef;
    },
    consolidateTaskGroup(compactTaskCols, profileTaskGroups) {
      const pTaskGroups = objectClone(profileTaskGroups);
      const result = objectClone(compactTaskCols);
      const startIndex = this.taskGroupPrefix.length;

      //Manage existing taskgroup columns: 1) Update the matched column with latest properties value; 2) Remove unmatched columns.
      for (const c of compactTaskCols) {
        if (c.groupId == null) {
          continue; //continue to next item if not a taskGroup column
        }
        
        const foundIndex = pTaskGroups.findIndex(d => d.uuId == c.groupId.substring(startIndex));
        if (foundIndex == -1) {
          //Remove unrecognized taskGroup from result (/cloned compactTaskCols)
          const index = result.findIndex(i => i.groupId == c.groupId);
          result.splice(index, 1);
          continue;
        } else {
          const foundObj = pTaskGroups.splice(foundIndex, 1)[0]; //assign it to variable and also remove it from pTaskGroups.
          const taskGroupCol = result.find(i => i.groupId == c.groupId);
          //set up 'hasDifferences' property
          taskGroupCol.hasDifferences = this.hasTaskGroupChanged(foundObj, taskGroupCol);
          if (foundObj.triggeredByUserChange != true) {
            continue; //Continue to next item if the triggeredByUserChange is not true.
          }
          const metadata = taskColumnMetadata(this, foundObj.property);

          
          taskGroupCol.lastModified = foundObj.lastModified;
          taskGroupCol.headerName = foundObj.headerName;
          taskGroupCol.property = taskGroupCol.headerGroupComponentParams.property = foundObj.property;
          
          //Process children: Based on latest taskGroup info from pTaskGroups, rearrange childColumns order or add new column if necessary.
          const taskObjs = foundObj.children;
          const childColumns = taskGroupCol.children;
          const newChildren = [];          
          const taskGroupProfId = foundObj.uuId;
          for (const tObj of taskObjs) {
            const name = tObj.name;
            const regex = new RegExp(this.getTaskColIdRegex(taskGroupProfId, name));
            const index = childColumns.findIndex(i => i.colId.match(regex) != null);
            if (index > -1) {
              const col = childColumns.splice(index, 1)[0];
              col.headerName = name; //Sync name to latest value.
              col.color = tObj.color; //Sync color to latest value.
              col.skills = tObj.skills;
              col.staff = tObj.staff;
              col.resources = tObj.resources;
              col.rebates = tObj.rebates;
              col.tags = tObj.tags;
              newChildren.push(col);
            } else {
              const nextSequence = this.findLastSequence(newChildren, regex) + 1;
              const newColDef = cloneDeep(metadata.columnDef);
              newColDef.colId = newColDef.field = this.getTaskColId(taskGroupProfId, nextSequence, name);
              newColDef.headerName = name;
              newColDef.cellRendererParams.totalMode = taskGroupCol.totalMode;
              newColDef.color = tObj.color;
              newColDef.skills = tObj.skills;
              newColDef.staff = tObj.staff;
              newColDef.resources = tObj.resources;
              newColDef.rebates = tObj.rebates;
              newColDef.tags = tObj.tags;
              newColDef.native = true;
              newChildren.push(newColDef);
            }
          }
          if (childColumns.length > 0) {
            for (const child of childColumns) {
              child.native = false;
            }
            newChildren.push(...childColumns);
          }
          //Add one dummy child column with empty headerName when there is no children.
          //Reason: Group without children will be discarded by Ag-grid. This dummy child column will be removed in...
          if (newChildren.length == 0) {
            newChildren.push(this.getTaskUtilColDef());
          }
          childColumns.splice(0, childColumns.length, ...newChildren)
        }
      }

      //Add the remaining taskGroups as new taskgroup columns
      if (pTaskGroups.length > 0) {
        const newTaskGroups = [];
        for (const g of pTaskGroups) {
          const taskGroupProfId = g.uuId;
          const taskGroupId = `${this.taskGroupPrefix}${g.uuId}`;
          const metadata = taskColumnMetadata(this, g.property);
          const newGroupColDef = metadata.groupColumnDef;
          newGroupColDef.groupId = taskGroupId;
          newGroupColDef.lastModified = g.lastModified;
          newGroupColDef.headerName = g.headerName;
          newGroupColDef.property = g.property;
          newGroupColDef.children = [];
          if (g.children == null) {
            g.children = [];
          }
          for (const tObj of g.children) {
            const name = tObj.name;
            const regex = new RegExp(this.getTaskColIdRegex(taskGroupProfId, name));
            const nextSequence = this.findLastSequence(newGroupColDef.children, regex) + 1;
            const newColDef = cloneDeep(metadata.columnDef);
            newColDef.colId = newColDef.field = this.getTaskColId(taskGroupProfId, nextSequence, name);
            newColDef.headerName = name;
            newColDef.native = true;
            newColDef.color = tObj.color;
            newColDef.skills = tObj.skills;
            newColDef.staff = tObj.staff;
            newColDef.resources = tObj.resources;
            newColDef.rebates = tObj.rebates;
            newColDef.tags = tObj.tags;
            newGroupColDef.children.push(newColDef);
          }
          newTaskGroups.push(newGroupColDef);
        }
        
        //Append new task groups after existing task groups or prepend to the result if no existing task group found.
        if (newTaskGroups.length > 0) {
          let i = result.length -1;
          for ( ; i >= 0; i--) {
            if (result[i].groupId != null && result[i].groupId.startsWith(this.taskGroupPrefix)) {
              break;
            }
          }
          if (i == -1) {
            result.unshift(...newTaskGroups);
          } else {
            result.splice(i+1, 0, ...newTaskGroups);
          }
        }
      }
      return result;
    },
    async taskGroupDeleted(payload) {
      const groupIds = payload.groupIds;
      
      const compactTaskCols = this.layoutProfile.compactTaskColumns != null? this.layoutProfile.compactTaskColumns : [];
      const successMsg = payload.deleteSuccessMsg;
      const failureMsg = payload.deleteFailureMsg;
      if (groupIds.length == 0 || compactTaskCols.length == 0) {
        self.taskGroup.feedback = { msg: successMsg, alertState: alertStateEnum.SUCCESS }
        return;
      }
      for (let i = 0, len = groupIds.length; i < len; i++) {
        const index = compactTaskCols.findIndex(c => c.groupId != null && c.groupId == groupIds[i]);
        if (index > -1) {
          compactTaskCols.splice(index, 1);
        }
      }
      this.layoutProfile.compactTaskColumns = compactTaskCols;
      const self = this;
      this.updateLayoutProfile({
        successCallback: () => {
          self.taskGroup.feedback = { msg: successMsg, alertState: alertStateEnum.SUCCESS }
        },
        failCallback: () => {
          self.taskGroup.feedback = { msg: failureMsg, alertState: alertStateEnum.ERROR }
        }
      });
    },
    constructTaskGroupChildColumnIds(taskNames, property) {
      const list = [];
      for (let i = 0, len = taskNames.length; i < len; i++) {
        const taskName = taskNames[i];
        const sequenceNo = list.filter(l => l.taskName == taskName).reduce((r, l) => { return r > l.sequenceNo? r : l.sequenceNo }, -1) + 1;
        list.push({ 
          colId: `${this.taskColPrefix}${property}_${sequenceNo < 10? '0':''}${sequenceNo}_${taskName}`,
          taskName,
          sequenceNo
        });
      }
      return list.map(i => i.colId);
    },
    taskGroupReload() {
      this.forceReload = true;
      this.loadColumnSettings();
      this.reloadTasks();
    },
    tcConfirmDeleteOk() {
      const tasks = [{ 
        taskId: this.taskCol.taskId
        , parentId: this.taskCol.parentId 
        , colId: this.taskCol.colId
      }];
      if (this.taskCol.applyAll == true) {
        tasks.push(...this.tcConfirmDeleteTasks.map(i => {
          return {
            taskId: i.uuId
            , parentId: i.parent
            , colId: i.colId
          }
        }));
        this.tcConfirmDeleteTasks.splice(0, this.tcConfirmDeleteTasks.length);
      }
      
      const deleteTaskReqTemplate = (taskId) => {
        return {
          method: 'POST',
          invoke: `/api/task/delete`,
          body: [{
            uuId: taskId,
          }],
          vars: [],
          note: `taskDelete__${taskId}`
        }
      }
      const toBeUpdatedMap = new Map();
      const toBeUpdated = [];
      const toBeRemoved = [];
      const leafChildrenToBeRemoved = []; //Used to remove child rows of deleted summary task in grid for better visual.
      const toBeRemovedRowIndexes = [];
      for(const task of tasks) {
        this.pendingProcessRequestList.push(deleteTaskReqTemplate(task.taskId));
        
        if (task.colId == this.COLUMN_AGGRID_AUTOCOLUMN) {
          const rowNode = this.gridOptions.api.getRowNode(task.taskId);
          const rowData = objectClone(rowNode.data);
          toBeRemoved.push(rowData);
          toBeRemovedRowIndexes.push(rowNode.rowIndex);
          //Collect child row data and will be used in grid applytransaction to remove rows for better visual.
          if (rowData.taskType == 'Project') {
            if (rowNode.allLeafChildren == null || rowNode.allLeafChildren.length < 2) {
              continue;
            }
            const rowNodeChildren = rowNode.allLeafChildren;
            for (let i = 1, len = rowNodeChildren.length; i < len; i++) {
              leafChildrenToBeRemoved.push(objectClone(rowNodeChildren[i].data));
            }
          }
          
        } else {
          if (!toBeUpdatedMap.has(task.parentId)) {
            toBeUpdatedMap.set(task.parentId, objectClone(this.gridOptions.api.getRowNode(task.parentId).data));
          }
          const rowData = toBeUpdatedMap.get(task.parentId);
          rowData[task.colId].single = TaskViewPropertyUtil.getEmptyDataModel(rowData[task.colId].property, rowData[task.colId].data).value;
          delete rowData[task.colId].uuId;
          delete rowData[task.colId].data;
        }
      }
      if (toBeUpdatedMap.size > 0) {
        toBeUpdated.push(...Array.from(toBeUpdatedMap.values()));
      }

      // //Adjust focused cell position to cell above (if possible) when the row which focused cell belongs to is removed.
      let suggestedRowIndex = -1;
      if (toBeRemovedRowIndexes.length > 0) {
        const currentFocusedCell = this.gridOptions.api.getFocusedCell();
        if (currentFocusedCell != null) {
          const rowIndex = currentFocusedCell.rowIndex;
          const colId = currentFocusedCell.column.colId;
          const rowPinned = currentFocusedCell.rowPinned;
          const isFocusedCellAffected = toBeRemovedRowIndexes.includes(rowIndex);
          if (isFocusedCellAffected) {
            suggestedRowIndex = rowIndex - 1;
            while (toBeRemovedRowIndexes.includes(suggestedRowIndex)) {
              suggestedRowIndex = suggestedRowIndex - 1;
            }
          }
          if (suggestedRowIndex > -1) {
            this.gridOptions.api.clearRangeSelection();
            this.gridOptions.api.setFocusedCell(suggestedRowIndex, colId, rowPinned);
          }
        }
      }

      if (toBeUpdated.length > 0 || toBeRemoved.length > 0) {
        if (leafChildrenToBeRemoved.length > 0) {
          toBeRemoved.push(...leafChildrenToBeRemoved);
        }
        this.gridOptions.api.applyTransaction({ update: toBeUpdated.length > 0? toBeUpdated : null, remove: toBeRemoved.length > 0? toBeRemoved : null });
        if (toBeUpdated.length > 0) {
          this.gridOptions.api.refreshCells({ force: true, rowNodes: toBeUpdated });
        }
      }
      
      setTimeout(() => {
        this.prepareTaskColConfirmDeleteDialog();
      }, 300);
    },
    prepareTaskColConfirmDeleteDialog() {
      if (this.tcConfirmDeleteTasks == null || this.tcConfirmDeleteTasks.length == 0) {
        this.deleteCell();
        return;
      }
      
      const task = this.tcConfirmDeleteTasks.shift();
      this.taskCol.taskId = task.uuId;
      this.taskCol.taskName = task.name;
      this.taskCol.parentName = task.parent == null || task.parent == 'ROOT'? null : task.parentName;
      this.taskCol.applyAll = false;
      this.taskCol.parentId = task.parent;
      this.taskCol.colId = task.colId;
      this.inProgressShow = false;
      this.tcConfirmDeleteShow = true;
    },
    tcConfirmDeleteCancel() {
      if (this.pendingProcessRequestList.length > 0) {
        this.deleteCell();
      }
    },
    reloadTasks(callbackFunc=null, { hideOverlay=true } = {}) {
      if (this.gridOptions != null && this.gridOptions.api != null) {
        if (hideOverlay !== false) {
          this.gridOptions.api.hideOverlay();
        }
        //reset status
        this.isTaskPaste = false;
        this.actionProcessing = false;
        this.loadTasks(this.id, {
          callback: () => {
            this.$nextTick(() => {
              if (callbackFunc != null) {
                callbackFunc();
              }
            });
          }
        });
      }
    },
    
    //Used by taskcol cell renderer
    taskOpen(taskId) {
      this.openDetail(taskId);
    },
    //Used by taskcol cell renderer
    taskDelete(tasks) {
      if (tasks.length > 0) {
        //Prepare data for taskcol delete confirmation dialog
        this.tcConfirmDeleteTasks = tasks;
        this.prepareTaskColConfirmDeleteDialog();
      }
    },
    taskGroupPropertyChanged(payload) {
      const groupId = payload.groupId;
      this.forceReload = true;
      taskLoaded = {};
      this.updateExistingTaskGroup({
        uuId: this.extractProfileId(groupId)
        , property: payload.property
      });
    },
    //Used by taskcompact header group renderer
    taskGroupEdit(groupId) {
      const tg = this.gridOptions.api.getColumnDefs().find(i => i.groupId == groupId);
      this.taskGroupLocal.uuId = groupId;
      this.taskGroupLocal.headerName = tg.headerName;
      this.taskGroupLocal.property = tg.property;
      this.taskGroupLocal.children = tg.children.filter(i => i.native == true).map(i => ({ 
        name: i.headerName
        , color: i.color
        , skills: i.skills
        , staff: i.staff
        , resources: i.resources
        , rebates: i.rebates
        , tags: i.tags
      }));
      this.taskGroupLocal.dynamicTasks = tg.children.filter(i => i.colId != this.getTaskUtilColDef().colId).map(i => i.headerName);
      this.taskGroupLocal.propertyOptions = this.getTaskGroupProperty();
      this.taskGroupLocalShow = true;
    },
    async taskGroupSync(groupId) {
      const profileId = this.extractProfileId(groupId);
      const data = await taskGroupProfileService.get(profileId)
      .then(response => {
        return response.data[response.data.jobCase][0];
        
      })
      .catch(e => {
        console.error(e); //eslint-disable-line
        return null;
      })

      if (data == null) {
        this.resetAlert({ msg: this.$t('task.group.error.failed_to_sync'), alertState: alertStateEnum.ERROR });
        return;
      }
      this.forceReload = true;
      taskLoaded = {};
      this.updateExistingTaskGroup(data);
    },
    async taskGroupUpload(groupId) {
      const taskGroupDef = this.gridOptions.api.getColumnDefs().find(i => i.groupId == groupId);
      if (taskGroupDef == null) {
        this.resetAlert({ msg: this.$t('task.group.error.failed_to_update'), alertState: alertStateEnum.ERROR });
        return;
      }
      
      const profileId = this.extractProfileId(taskGroupDef.groupId);
      const profileTaskGroup = await taskGroupProfileService.get(profileId)
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0];
      })
      .catch(() => {
        return null;
      });

      if (profileTaskGroup == null) {
        this.resetAlert({ msg: this.$t('task.group.error.failed_to_update'), alertState: alertStateEnum.ERROR });
        return;
      }

      profileTaskGroup.headerName = taskGroupDef.headerName;
      profileTaskGroup.property = taskGroupDef.property;
      profileTaskGroup.children = taskGroupDef.children != null
        ? taskGroupDef.children.filter(i => i.native == true).map(i => ({ 
            name: i.headerName
            , color: i.color 
            , skills: i.skills
            , staff: i.staff
            , resources: i.resources
            , rebates: i.rebates
            , tags: i.tags
          })) 
        : [];
      profileTaskGroup.lastModified = moment.utc().valueOf();
      const hasError = await taskGroupProfileService.update([profileTaskGroup], this.userId)
      .then(() => {
        return false;
      })
      .catch(() => {
        return true;
      });

      //Update taskgroup.hasDifferences to false so that the taskgroup menu will be updated to reflect the actual state.
      taskGroupDef.hasDifferences = false;

      //Update task group in the layout profile. Main purpose is to update the lastModified property.
      this.updateExistingTaskGroup({
        uuId: profileId,
        lastModified: profileTaskGroup.lastModified
      }, { reloadTasks: false });

      if (hasError) {
        this.resetAlert({ msg: this.$t('task.group.error.failed_to_update'), alertState: alertStateEnum.ERROR });
      } else {
        this.resetAlert({ msg: this.$t('task.group.update'), alertState: alertStateEnum.SUCCESS });
      }
    },
    updateExistingTaskGroup(payload, { reloadTasks=true }={}) {
      const profId = payload.uuId;
      const groupId = `${this.taskGroupPrefix}${payload.uuId}`;
      const compactColumns = this.layoutProfile.compactTaskColumns;
      const found = compactColumns.find(i => i.groupId == groupId);
      if (found == null) {
        return; //stop proceed further.
      }

      if (payload.headerName != null) {
        found.headerName = payload.headerName;
      }
      if (payload.property != null) {
        found.property = payload.property;
      }
      if (payload.lastModified != null) {
        //Update only when lastModified is provided. No change to lastModified when user makes local changes.
        found.lastModified = payload.lastModified;
      }

      found.hasDifferences = false;
      
      if (payload.children != null) {
        const metadata = taskColumnMetadata(this, payload.property);
        const minWidth = metadata.columnDef.minWidth;
        const pChildren = payload.children; //Expecting at least on item in payload.children.
        const newChildren = [];
        const existingTasks = found.children;
        const oldNativeTaskNames = existingTasks.filter(i => i.native == true).map(i => i.headerName);
        for (const tObj of pChildren) {
          const taskName = tObj.name;
          const index = existingTasks.findIndex(i => i.headerName == taskName);
          if (index > -1) {
            let idx = oldNativeTaskNames.findIndex(i => i.head);
            if (idx > -1) {
              oldNativeTaskNames.splice(idx , 1);
            }
            const childTask = existingTasks.splice(index, 1)[0];
            if (childTask.width < minWidth) {
              childTask.width = minWidth;
            }
            childTask.native = true;
            childTask.color = tObj.color;
            if (Array.isArray(tObj.skills)) {
              childTask.skills = tObj.skills;
            } else {
              delete childTask.skills;
            }
            if (Array.isArray(tObj.staff)) {
              childTask.staff = tObj.staff;
            } else {
              delete childTask.staff;
            }
            if (Array.isArray(tObj.resources)) {
              childTask.resources = tObj.resources;
            } else {
              delete childTask.resources;
            }
            if (Array.isArray(tObj.rebates)) {
              childTask.rebates = tObj.rebates;
            } else {
              delete childTask.rebates;
            }
            if (Array.isArray(tObj.tags)) {
              childTask.tags = tObj.tags;
            } else {
              delete childTask.tags;
            }
            newChildren.push(childTask);
          } else {
            const regex = new RegExp(this.getTaskColIdRegex(profId, taskName));
            const nextSequence = this.findLastSequence(newChildren, regex) + 1;
            const newColDef = cloneDeep(metadata.columnDef);
            newColDef.colId = newColDef.field = this.getTaskColId(profId, nextSequence, taskName);
            newColDef.headerName = taskName;
            newColDef.cellRendererParams.totalMode = found.totalMode;
            newColDef.color = tObj.color;
            if (Array.isArray(tObj.skills)) {
              newColDef.skills = tObj.skills;
            }
            if (Array.isArray(tObj.staff)) {
              newColDef.staff = tObj.staff;
            }
            if (Array.isArray(tObj.resources)) {
              newColDef.resources = tObj.resources;
            }
            if (Array.isArray(tObj.rebates)) {
              newColDef.rebates = tObj.rebates;
            }
            if (Array.isArray(tObj.tags)) {
              newColDef.tags = tObj.tags;
            }
            newColDef.native = true;
            newChildren.push(newColDef);
          }
        }
        //When oldNativeTaskNames is not empty, remove it from existingTasks.
        for(const tName of oldNativeTaskNames) {
          const idx = existingTasks.findIndex(i => i.native == true && i.headerName == tName);
          if (idx > -1) {
            existingTasks.splice(idx, 1);
          }
        }
        if (existingTasks.length > 0) {
          for (const exTask of existingTasks) {
            exTask.native = false;
            newChildren.push(exTask);
          }
        }
        found.children = newChildren;
      }
      
      this.saveColumnState({ compactColumns, skipTaskExpandLevel: true });
      this.loadColumnSettings();
      if (reloadTasks == true) {
        this.gridOptions.api.showLoadingOverlay();
        this.reloadTasks(() => {
          this.gridOptions.api.hideOverlay();
        }, { hideOverlay: false });
      }
    },
    async taskGroupLocalOk({ payload, changedChildren }) {
      //payload.uuId is a group uuId. Convert to profileId as the updateExistingTaskGroup is expecting profileId.
      payload.uuId = this.extractProfileId(payload.uuId);
      this.confirmApplyChangeToTaskIsColumnEdit = false;
      
      if (changedChildren.length == 0) {
        this.forceReload = true;
        taskLoaded = {};
        this.updateExistingTaskGroup(payload, { reloadTasks: true });
        return;
      } else {
        changedChildren.forEach(i => {
          i.colId = `taskcol_${payload.uuId}_00_${i.oldName}`;
        });
        this.confirmApplyChangeToTaskColName = null;
        this.confirmApplyChangeToTaskPayload = {
          payload: payload
          , changedChildren: changedChildren
        }
        this.confirmApplyChangeToTaskShow = true;
      }
    },
    newTaskGroupOk() {
      this.resetAlert({ msg: this.$t('task.group.create'), alertState: alertStateEnum.SUCCESS });
    },
    getTaskColIdRegex(_taskGroupProfId, _name) {
      return `^${this.taskColPrefix}${_taskGroupProfId}_([0-9][0-9])_${_name}$`;
    },
    getTaskColId(_taskGroupProfId, _nextSequence, _name) {
      return `${this.taskColPrefix}${_taskGroupProfId}_${_nextSequence < 10? '0':''}${_nextSequence}_${_name}`;
    },
    extractProfileId(groupId) {
      return groupId.substring(this.taskGroupPrefix.length);
    },
    getTaskUtilColDef() {
      return { 
        colId: 'TASK_UTIL', 
        headerName: 'New Task',
        headerComponent: 'taskCompactUtil',
        width: 200,
        lockVisible: true
      }
    },
    getMainMenuItems(params) {
      const colId = params.column.colId;
      const headerName = params.column.colDef.headerName;
      if (colId != null && colId.startsWith(this.taskColPrefix)) {
        const menuItems = [];
        menuItems.push({
          name: this.$t('label_edit_default_task'),
          action: () => {
            this.updateTaskColName(colId
              , params.column.parent.groupId
              , headerName
              , {
                color: params.column.colDef.color
                , skills: params.column.colDef.skills
                , staff: params.column.colDef.staff
                , resources: params.column.colDef.resources
                , rebates: params.column.colDef.rebates
                , tags: params.column.colDef.tags
              }
            );
          },
          icon: '<i class="fa-regular fa-pen-to-square"/>'
        })
        return menuItems;
      } 
      else if (colId != null && colId === 'rowSelector') {
        
        const menuItems = [];
        menuItems.push({
          name: this.$t('button.selectall'),
          action: () => {
            const api = params.api;
            const rows = api.rowModel.rowsToDisplay.filter(i => i.key != 'ROOT');//Row with 'ROOT' key is a virtual row.
            if (rows.length > 0) {
              const range = {
                rowStartIndex: rows[0].rowIndex,
                rowEndIndex: rows[rows.length-1].rowIndex,
                columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
              }
              api.clearRangeSelection();
              api.addCellRange(range);

              //Make sure there is a focused cell in the grid.
              //Without focused cell, cell navigation, delete key interaction will not work.
              if(api.getFocusedCell() == null) {
                api.setFocusedCell(range.rowStartIndex, range.columns[0]);
              }
            }
          },
        });
        menuItems.push({
          name: this.$t('button.selectnone'),
          action: () => {
            params.api.deselectAllFiltered();
            params.api.clearRangeSelection();
            params.api.clearFocusedCell();
          },
        });

        const selectCellRangeByType = (api, columnApi, type) => {
          const newCellRanges = [];
          const rows = api.rowModel.rowsToDisplay;
          let startIndex = -1;

          if (this.isCompactView) {
            for (const row of rows) {
              if (row.data.taskType == type && row.key != 'ROOT') {//Row with 'ROOT' key is a virtual row.
                
                if (startIndex == -1) {
                  startIndex = row.rowIndex;
                }
                continue; //Move to next item as the whole row is included.
              } else if (startIndex != -1) {
                //Commit the current new range and reset the startIndex for next new range.
                newCellRanges.push({
                  rowStartIndex: startIndex,
                  rowEndIndex: row.rowIndex,
                  columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
                })
                startIndex = -1;
              }

              //Handle taskCol cells
              if (row.data.taskType == 'Project' && type == 'Task') {
                const data = row.data;
                const taskColNames = Object.getOwnPropertyNames(data).filter(i => i.startsWith(this.taskColPrefix));
                const includedColumns = [];
                for (const name of taskColNames) {
                  if (data[name] == null || data[name].uuId == null) {
                    continue; //Move to next item if it has no valid task uuId.
                  }
                  includedColumns.push(name);
                }
                if (includedColumns.length > 0) {
                  newCellRanges.push({
                    rowStartIndex: row.rowIndex,
                    rowEndIndex: row.rowIndex,
                    columns: includedColumns
                  })
                }
              }
            }
          } else { // normal view
            for (const row of rows) {
              if (row.data.taskType == type) {
                if (startIndex == -1) {
                  startIndex = row.rowIndex;
                }
                continue;
              } else if (startIndex != -1) {
                newCellRanges.push({
                  rowStartIndex: startIndex,
                  rowEndIndex: row.rowIndex - 1,
                  columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
                })
                startIndex = -1;
                continue;
              }
            }
            
          }

          if (startIndex != -1 && rows.length > 0) {
            const lastRowIndex = rows[rows.length -1].rowIndex;
            newCellRanges.push({
              rowStartIndex: startIndex,
              rowEndIndex: lastRowIndex,
              columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
            })
          }

          api.clearRangeSelection();
          for (const cellRange of newCellRanges) {
            api.addCellRange(cellRange);
          }
          
          //Make sure there is a focused cell in the grid.
          //Without focused cell, cell navigation, delete key interaction will not work.
          if(newCellRanges.length > 0 && api.getFocusedCell() == null) {
            api.setFocusedCell(newCellRanges[0].rowStartIndex, newCellRanges[0].columns[0]);
          }
        }
        
        menuItems.push({
          name: this.$t('task.select_summary'),
          action: () => {
            selectCellRangeByType(params.api, params.columnApi, 'Project');
          },
        });
        
        menuItems.push({
          name: this.$t('task.select_task'),
          action: () => {
            selectCellRangeByType(params.api, params.columnApi, 'Task');
          },
        });
        
        if (!this.isCompactView) {
          menuItems.push({
            name: this.$t('task.select_milestone'),
            action: () => {
              selectCellRangeByType(params.api, params.columnApi, 'Milestone');
            },
          });
        }

        const selectCellRangeByLevel = (api, columnApi, level) => {
          const newCellRanges = [];
          const rows = api.rowModel.rowsToDisplay;
          let startIndex = -1;

          if (this.isCompactView) {
            for (const row of rows) {
              //Handle Ag-Grid-AutoColumn cell
              if (row.level == level+1) {
                if (startIndex == -1) {
                  startIndex = row.rowIndex;
                }
              } else if (startIndex != -1) {
                newCellRanges.push({
                  rowStartIndex: startIndex,
                  rowEndIndex: row.rowIndex - 1,
                  columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
                })
                startIndex = -1;
              }

              //Handle task col cell
              if (row.level == level) {
                const data = row.data;
                const taskColNames = Object.getOwnPropertyNames(data).filter(i => i.startsWith(this.taskColPrefix));
                const includedColumns = [];
                for (const name of taskColNames) {
                  if (data[name] == null || data[name].uuId == null) {
                    continue; //Move to next item if it has no valid task uuId.
                  }
                  includedColumns.push(name);
                }
                if (includedColumns.length > 0) {
                  newCellRanges.push({
                    rowStartIndex: row.rowIndex,
                    rowEndIndex: row.rowIndex,
                    columns: includedColumns
                  })
                }
              }
            }
            
            if (startIndex != -1) {
              newCellRanges.push({
                rowStartIndex: startIndex,
                rowEndIndex: rows[rows.length -1].rowIndex,
                columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
              })
            }
            
            api.clearRangeSelection();
            for (const cellRange of newCellRanges) {
              api.addCellRange(cellRange);
            }

            //Make sure there is a focused cell in the grid.
            //Without focused cell, cell navigation, delete key interaction will not work.
            if(newCellRanges.length > 0 && api.getFocusedCell() == null) {
              api.setFocusedCell(newCellRanges[0].rowStartIndex, newCellRanges[0].columns[0]);
            }
          } else {
            for (const row of rows) {
              if (row.level == level) {
                if (startIndex == -1) {
                  startIndex = row.rowIndex;
                }
                continue;
              } else if (startIndex != -1) {
                newCellRanges.push({
                  rowStartIndex: startIndex,
                  rowEndIndex: row.rowIndex - 1,
                  columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
                })
                startIndex = -1;
                continue;
              }
            }
            if (startIndex != -1) {
              newCellRanges.push({
                rowStartIndex: startIndex,
                rowEndIndex: rows[rows.length -1].rowIndex,
                columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
              })
            }
            api.clearRangeSelection();
            for (const cellRange of newCellRanges) {
              api.addCellRange(cellRange);
            }

            //Make sure there is a focused cell in the grid.
            //Without focused cell, cell navigation, delete key interaction will not work.
            if(newCellRanges.length > 0 && api.getFocusedCell() == null) {
              api.setFocusedCell(newCellRanges[0].rowStartIndex, newCellRanges[0].columns[0]);
            }
          }
        }
        
        const levelMenu = [];
        for (let lvl = 0; lvl <= this.maxLevel; lvl++) {
         levelMenu.push({
            name: lvl + 1,
            action: () => {
              selectCellRangeByLevel(params.api, params.columnApi, lvl);
            },
          });
        }
        
        menuItems.push({
          name: this.$t('task.select_level'),
          subMenu: levelMenu,
        });
        return menuItems;
      }
      return params.defaultItems;
    },
    updateTaskColName(colId, groupId, headerName
        , { color=null, skills=null, staff=null, resources=null, rebates=null, tags=null }) {
      const api = this.gridOptions.api;
      if (colId == null || groupId == null || api == null) {
        return;
      }
      let _taskName = headerName;
      if (_taskName == null) {
        const profId = this.extractProfileId(groupId);
        const regex = new RegExp(`^${this.taskColPrefix}${profId}_([0-9][0-9])_(.*)$`);
        const match = colId.match(regex);
        if (match == null || match.length < 3) {
          return;
        }
        _taskName = match[2];
      }
      
      this.taskColEdit.colId = colId;
      this.taskColEdit.groupId = groupId;
      this.taskColEdit.name =  _taskName;
      this.taskColEdit.color = color;
      this.taskColEdit.skills = skills;
      this.taskColEdit.staff = staff;
      this.taskColEdit.resources = resources;
      this.taskColEdit.rebates = rebates;
      this.taskColEdit.tags = tags;
      this.taskColEditShow = true;
    },
    async taskColEditOk(payload) {
      const api = this.gridOptions.api;
      if (api == null) {
        return;
      }

      this.confirmApplyChangeToTaskColName = this.taskColEdit.name;
      this.confirmApplyChangeToTaskPayload = {
        payload: {
          groupId: this.taskColEdit.groupId
          , uuId: this.extractProfileId(this.taskColEdit.groupId)
        }
        , changedChildren: [
          {
            oldName: this.taskColEdit.name
            , colId: this.taskColEdit.colId
            , newValue: payload
          }
        ]
      }
      this.confirmApplyChangeToTaskIsColumnEdit = true;
      this.confirmApplyChangeToTaskShow = true;
    },
    sanitizeCompactTaskColumns(compactTaskColumns) {
      const sanitized = [];
      for (const col of compactTaskColumns) {
        if (col.children && col.children.length > 1000) {
          // too many columns for us to work with
          this.resetAlert({ msg: this.$t('task.error.compact_limit'), alertState: alertStateEnum.WARNING });
          this.toggleCompactViewHandler(null);
        }
        
        if (col.groupId != null && col.groupId.startsWith(this.taskGroupPrefix)) {
          sanitized.push({
            groupId: col.groupId,
            property: col.property,
            headerName: col.headerName,
            totalMode: !!col.totalMode,
            hasDifferences: !!col.hasDifferences,
            children: col.children.map(i => {
              return {
                colId: i.colId,
                headerName: i.headerName,
                width: i.width,
                hide: !!i.hide,
                sort: i.sort,
                native: !!i.native,
                color: i.color != null? i.color : null,
                skills: Array.isArray(i.skills) && i.skills.length > 0? i.skills: null,
                staff: Array.isArray(i.staff) && i.staff.length > 0? i.staff: null,
                resources: Array.isArray(i.resources) && i.resources.length > 0? i.resources: null,
                rebates: Array.isArray(i.rebates) && i.rebates.length > 0? i.rebates: null,
                tags: Array.isArray(i.tags) && i.tags.length > 0? i.tags: null,
              }
            }),
            lastModified: col.lastModified
          });
        } else {
          sanitized.push({
            colId: col.colId,
            headerName: col.headerName,
            width: col.width,
            hide: !!col.hide,
            sort: col.sort
          })
        }
      }
      return sanitized;
    },
    consolidateColumnDefsAndDisplay() {
      const rawDisplayedCols = this.gridOptions.columnApi.getAllDisplayedColumns();
      const displayedCols = [];
      rawDisplayedCols.forEach(i => {
        if (i.colId.startsWith(this.taskColPrefix)) { //belongs to task group child column
          let tg = displayedCols.find(j => j.groupId == i.parent.groupId);
          if (tg == null) {
            tg = { groupId: i.parent.groupId, children: [] }
            displayedCols.push(tg);
          }
          tg.children.push({ colId: i.colId, width: i.actualWidth, sort: i.sort });
        } else {
          displayedCols.push({ colId: i.colId, width: i.actualWidth, sort: i.sort }); //a normal column
        }
      });

      const cDefs = this.gridOptions.api.getColumnDefs();

      for (const col of cDefs) {
        if (col.groupId != null) {
          const found = displayedCols.find(i => i.groupId === col.groupId);
          if (found == null) {
            continue;
          }
          if (col.children == null) {
            col.children = [];
          }
          if (found.children.length > 0) {
            for (const child of found.children) {
              const cFound = col.children.find(i => i.colId === child.colId);
              if (cFound == null) {
                continue;
              }
              cFound.width = child.width;
              cFound.sort = child.sort;
            }
          }
        } else {
          const found = displayedCols.find(i => i.colId === col.colId);
          if (found != null) {
            found.hide = false;
          } else {
            col.hide = true;
          }
        }
      }
      cDefs.sort(this.columnDefSortFunc);
      return cDefs;
    },
    getUpperTaskNodesFromSelection() {
      const api = this.gridOptions.api;
      const processedTasks = [];
      const processingTasks = [];
      //Brief: Remove the selected child tasks if their parent or grand parent is also a part of the selection.
      //1) Collect the task's path. It is needed to identify the relationship of parent and child.
      for (const t of this.taskSelection) {
        if (t.colId == this.COLUMN_AGGRID_AUTOCOLUMN) {
          const cloned = objectClone(t);
          const rowNode = api.getRowNode(t.uuId);
          if (rowNode == null) {
            continue;
          }
          cloned.path = rowNode.data.path;
          if (rowNode.data.path) {
            cloned.pathString = rowNode.data.path.join(',');
          }
          processingTasks.push(cloned);
        } else {
          const cloned = objectClone(t);
          const rowNode = api.getRowNode(t.parent);
          if (rowNode == null) {
            continue;
          }
          const path = objectClone(rowNode.data.path);
          path.push(t.uuId);
          cloned.path = path;
          cloned.pathString = path.join(',');
          processingTasks.push(cloned);
        }
      }

      processingTasks.sort((a, b) => {
        if(a.path && b.path && a.path.length > b.path.length) return 1;
        if(a.path && b.path && a.path.length < b.path.length) return -1;
        return 0;
      })

      //2) Remove unneeded tasks
      while (processingTasks.length > 0) {
        const task = processingTasks.splice(0, 1)[0];
        const matchPattern = [];
        if (task.path) {
          for (let i = 0, len = task.path.length; i < len; i++) {
            matchPattern.push(task.path.slice(0, i+1).join(','));
          }
        }
        
        let found = false;
        for (const mPattern of matchPattern) {
          if (processedTasks.find( i => i.pathString == mPattern) != null) {
            found = true;
            break;
          }
        }
        
        if (!found) {
          processedTasks.push(task);
        }
      }
      return processedTasks;
    },
    getTaskIdForCopyOrTemplateSave() {
      return this.getUpperTaskNodesFromSelection().map(i => i.uuId);
    },
    loseCellFocusOnEscapeKey(event) {
      if (event.keyCode !== 27) {
        return;
      }
      
      let api = this.gridOptions != null && this.gridOptions.api != null? this.gridOptions.api : null;
      if (api == null || this.taskEditShow) { //skip when cell editing is in progress
        return;
      }
      api.deselectAllFiltered();
      api.clearRangeSelection();
      api.clearFocusedCell();
    },
    fillOperation(params) {
      //Skip when the target cell is not editable or is rowSelector.
      const payload = { 
        node: params.rowNode
        , data: params.rowNode.data
        , column: params.column
        , colDef: params.column.colDef
        , api: params.api
        , columnApi: params.columnApi
        , context: params.context
      }
      if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
          !params.column.colDef.editable || params.column.colId == 'rowSelector') {
        return params.currentCellValue;
      }

      //Skip when the target cell is blacklisted
      const blackListed = ['totalActualDuration'];
      if (blackListed.includes(params.column.colId)) {
        return params.currentCellValue;
      }

      //Used to signal batch operation in onCellValueChanged event.
      this.triggeredByFillOperation = true;

      //Find the source Column, source rowNode based on the available details in params object.
      const tgtRowNode = params.rowNode;
      const targetColId = params.column.colId;
      let srcRowNode = null; 
      let sourceColId = null;
      
      if (params.direction == 'left' || params.direction == 'right') {
        srcRowNode = tgtRowNode; //Default: Same row as target.
        const columns = params.columnApi.getColumnState().filter(i => i.hide != true).map(i => i.colId);
        const targetColIdx = columns.findIndex(i => i == targetColId)
        let sourceColIdx = targetColIdx - params.currentIndex - 1;
        if (params.direction == 'left') {
          sourceColIdx = targetColIdx + params.currentIndex + 1;
        }
        sourceColId = columns[sourceColIdx];
      } else {// direction: up or down
        let srcRowIndex = tgtRowNode.rowIndex - params.currentIndex - 1;
        if (params.direction == 'up') {
          srcRowIndex = tgtRowNode.rowIndex + params.currentIndex + 1;
        }
        srcRowNode = params.api.getDisplayedRowAtIndex(srcRowIndex);
        sourceColId = targetColId; //Default: Same column as source.
      }

      //Check if column is taskGroup column
      const isSrcTaskCol = sourceColId.startsWith(this.taskColPrefix);
      const isTgtTaskCol = targetColId.startsWith(this.taskColPrefix);

      let isCompatible = false;
      let srcProperty = sourceColId;
      let tgtProperty = targetColId;
      //Check if the source and target's property value type are compatible
      if (sourceColId == targetColId) {
        isCompatible = true;
      } else {
        if (sourceColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
          srcProperty = 'name';
        }
        if (targetColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
          tgtProperty = 'name';
        }
        if (isSrcTaskCol) {
          srcProperty = srcRowNode.data[sourceColId].property;
        }
        if (isTgtTaskCol) {
          tgtProperty = tgtRowNode.data[targetColId].property;
        }

        isCompatible = TaskViewPropertyUtil.isCompatible(srcProperty, tgtProperty);
      }

      if (!isCompatible) {
        return params.currentCellValue;
      }

      //Skip fill operation when source is taskCol and has no valid task
      if (isSrcTaskCol && srcRowNode.data[sourceColId].uuId == null) {
        return params.currentCellValue;
      }

      let srcValue = isSrcTaskCol? srcRowNode.data[sourceColId].single : srcRowNode.data[sourceColId == this.COLUMN_AGGRID_AUTOCOLUMN? 'name' : sourceColId];
      if ('staffs' == srcProperty && 'staffs' == tgtProperty) {
        const tgtValue = objectClone(tgtRowNode.data[targetColId]);
        let tgtStaffs = tgtValue;
        if (isTgtTaskCol) {
          if (tgtValue.single == null) {
            tgtValue.single = [];
          }
          tgtStaffs = tgtValue.single;
        }
        // const tgtStaffs = isTgtTaskCol? tgtValue.single : tgtValue;
        if (srcValue.length == 0 && tgtStaffs.length == 0) {
          return params.currentCellValue; //No change when both lists are empty.
        }

        //Remove all staff from tgtStaffs
        if (srcValue.length == 0) {
          tgtStaffs.splice(0, tgtStaffs.length);
          return tgtValue;
        }

        const updatedStaffs = [];
        for (const srcStaff of srcValue) {
          const found = tgtStaffs.find(i => i.uuId == srcStaff.uuId);
          if (found != null) {
            //Update tgtStaff with srcStaff's utilization. Keep the rest.
            found.utilization = srcStaff.utilization;
            updatedStaffs.push(found);
          } else {
            //Clean up workEffort (duration, durationAUM) when add to new list.
            delete srcStaff.duration;
            delete srcStaff.durationAUM;
            updatedStaffs.push(srcStaff);
          }
        }
        tgtStaffs.splice(0, tgtStaffs.length, ...updatedStaffs);
        return tgtValue;
      }

      if ('notes' == srcProperty && 'notes' == tgtProperty) {
        const tgtValue = objectClone(tgtRowNode.data[targetColId]);
        let tgtNotes = tgtValue;
        if (isTgtTaskCol) {
          if (tgtValue.single == null) {
            tgtValue.single = [];
          }
          tgtNotes = tgtValue.single;
        }
        // const tgtNotes = isTgtTaskCol? tgtValue.single : tgtValue;
        if (srcValue.length == 0 && tgtNotes.length == 0) {
          return params.currentCellValue; //No change when both lists are empty.
        }

        //Remove the top item from the tgtNotes when src is empty
        if (srcValue.length == 0) {
          tgtNotes.shift();
          return tgtValue;
        }

        const newNote = objectClone(srcValue[0]);
        if (newNote.identifier == null || newNote.identifier.trim().length == 0) {
          delete newNote.identifier;
        }
        delete newNote.uuId;
        tgtNotes.unshift(newNote);
        return tgtValue;
      }

      if ('name' === tgtProperty || 'description' === tgtProperty || 'identifier' === tgtProperty) {
        srcValue = this.truncatePropValue(tgtProperty, srcValue);
      }
      
      if (isTgtTaskCol) {
        const tgtValue = objectClone(tgtRowNode.data[targetColId]); //clone and return new object to trigger onCellValueChanged() event
        tgtValue.single = srcValue;
        return tgtValue;
      }
      
      return srcValue;
    },
    getRowSelectorColumn() {
      const self = this;
      return {
        headerName: '',
        colId: 'rowSelector',
        field: 'taskType',
        width: 67,
        minWidth: 67,
        maxWidth: 67,
        hide: false,
        cellRenderer: 'rowSelectorCellRenderer',
        cellRendererParams: {
          isReadOnly: !this.canEdit(),
          enableReadonlyStyle: true
        },
        pinned: 'left',
        lockPosition: 'left',
        lockVisible: true,
        suppressColumnsToolPanel: true,

        menuTabs: ['generalMenuTab'],
        resizable: false,
        headerComponent: 'selectionHeaderComponent',
        suppressFillHandle: true ,
        rowDrag: () => !self.flatList,
        rowDragText: function(params) {
          return params.rowNode && params.rowNode.data? params.rowNode.data.name : params.defaultTextValue;
        },
      }
    },
    //Referenced in RowSelector.vue
    rowSelectorMouseDown(rowIndex=null) {
      if (rowIndex == null) {
        return;
      }
      
      //Consolidate all ranges's row and column details into rowColumnMap 
      const rowColumnMap = new Map();
      const cellRanges = this.gridOptions.api.getCellRanges();
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex;
        const rowEndIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex;
        const columns = cRange.columns.map(i => i.colId);
        if (rowStartIndex == rowEndIndex) {
          if (!rowColumnMap.has(rowStartIndex)) {
            rowColumnMap.set(rowStartIndex, new Set());
          }
          const rCol = rowColumnMap.get(rowStartIndex);
          for (const col of columns) {
            if (col == 'rowSelector') {
              continue;
            }
            rCol.add(col);
          }
          continue;
        }

        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          if (!rowColumnMap.has(i)) {
            rowColumnMap.set(i, new Set());
          }
          const rCol = rowColumnMap.get(i);
          for (const col of columns) {
            rCol.add(col);
          }
        }
      }

      const maxColumnsLength = this.gridOptions.columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').length;
      //Reset list
      this.rowSelectorClicked_allColsSelectedRowIndexList.splice(0, this.rowSelectorClicked_allColsSelectedRowIndexList.length);
      //Check which row has full set of columns in range selection.
      //When the row has full set, add it to tobeUnselected list
      for (let [key, value] of rowColumnMap) {
        if (value.size == maxColumnsLength) {
          this.rowSelectorClicked_allColsSelectedRowIndexList.push(key);
        }
      }
    },
    //Referenced in RowSelector.vue
    rowSelectorMouseUp({ ctrlKey=false, shiftKey=false, rowIndex=null } = {}) {
      const cellRanges = this.gridOptions.api.getCellRanges();
      const originalRanges = cellRanges.map(i => {
        return {
          rowStartIndex: i.startRow.rowIndex,
          rowEndIndex: i.endRow.rowIndex,
          columns: i.columns.map(j => j.colId)
        }
      });

      //Handle case when shift key is pressed and click on row selector
      if (shiftKey == true && rowIndex != null) {
        const focusedCell = this.gridOptions.api.getFocusedCell();
        const focusedCellRowIndex = focusedCell.rowIndex;
        const focusedCellColId = focusedCell.column.colId;

        let cellRanges = this.gridOptions.api.getCellRanges();
        cellRanges = cellRanges.map(i => {
          return {
            rowStartIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.endRow.rowIndex : i.startRow.rowIndex,
            rowEndIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.startRow.rowIndex : i.endRow.rowIndex,
            columns: i.columns.map(i => i.colId)
          }
        })
        const newRanges = [];
        
        let isDirectionUp = rowIndex < focusedCellRowIndex;
        let newStartRowIndex = rowIndex > focusedCellRowIndex? focusedCellRowIndex : rowIndex;
        let newEndRowIndex = rowIndex > focusedCellRowIndex? rowIndex : focusedCellRowIndex;
        
        //Handle case when both shift key and ctrl key are pressed
        if (ctrlKey == true) {
          //Remove last range if there is any. New range will be created as replacement later.
          if (cellRanges.length > 0) {
            cellRanges.splice(cellRanges.length-1 , 1);
          }

          //Reshape previous ranges to avoid new range created between the row which last focused cell resides and the row which user click
          for (const cRange of cellRanges) {
            const isClashed = (newStartRowIndex >= cRange.rowStartIndex && newStartRowIndex <= cRange.rowEndIndex) ||
                                (cRange.rowStartIndex >= newStartRowIndex && cRange.rowStartIndex <= newEndRowIndex);
            
            if (!isClashed) {
              //Transfer the range to newCellRanges when there is no row clashed with last range.
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
              continue;
            }

            //split existing range to exclude the last range's selected rows.
            if (cRange.rowStartIndex < newStartRowIndex && cRange.rowEndIndex >= newStartRowIndex) {
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: newStartRowIndex-1,
                columns: cRange.columns
              })
            }
            if (cRange.rowEndIndex > newEndRowIndex && cRange.rowStartIndex <= newEndRowIndex) {
              newRanges.push({
                rowStartIndex: newEndRowIndex+1,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
            }
          }
        }

        //New range replacing last range if there is any
        newRanges.push({
          rowStartIndex: isDirectionUp? newEndRowIndex: newStartRowIndex,
          rowEndIndex: isDirectionUp? newStartRowIndex : newEndRowIndex,
          columns: this.gridOptions.columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        });

        this.gridOptions.api.clearRangeSelection();
        for (const nRange of newRanges) {
          this.gridOptions.api.addCellRange(nRange);
        }
        this.gridOptions.api.setFocusedCell(focusedCellRowIndex, focusedCellColId);
        return;
      }

      //Handle edge case: shift click rowSelector when there is no existing range selection.
      if (cellRanges.length == 0) {
        const curFocusedCell = this.gridOptions.api.getFocusedCell();
        if (curFocusedCell == null) {
          this.gridOptions.api.clearFocusedCell();
          return;
        }
        const rowIndex = this.gridOptions.api.getFocusedCell().rowIndex;
        this.gridOptions.api.addCellRange({
          rowStartIndex: rowIndex,
          rowEndIndex: rowIndex,
          columns: this.gridOptions.columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        })
        this.gridOptions.api.setFocusedCell(rowIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null);
        return;
      }

      const lastRange = (() => {
        const tmp = cellRanges[cellRanges.length-1];
        const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex;
        return {
          rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
          rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
          columns: tmp.columns.map(i => i.colId),
          startColumn: tmp.startColumn.colId,
          orgRowStartIndex: tmp.startRow.rowIndex,
          orgRowEndIndex: tmp.endRow.rowIndex
        }
      })();

      this.rowSelectorChanged({ 
        cellRanges,
        originalRanges,
        lastRange,
        api: this.gridOptions.api, 
        columnApi: this.gridOptions.columnApi 
      });
    },
    rowSelectorChanged({ cellRanges, originalRanges, lastRange, api, columnApi }) {
      //Brief: Depends on user interaction, add/remove cell range selection.
      //General Specification:
      //If startCell clashes with previous ranges, remove all cell in the new range.
      //If startCell does not clash with previous ranges, select all cells in the new range. (reshape cell in previous ranges to avoid new range).
      //If only rowSelector column in the new range,
      //   - Clash or not clash becomes irrelavant
      //   - Select all cell if not all cells are selected in the row. Otherwise, remove selected cell from any ranges.
      //   - If row count is more than one, focus on 1st row (starting row)

      if (cellRanges.length > 0) {
        const startColumnId = lastRange.startColumn;
        const previousRanges = cellRanges.slice(0, cellRanges.length-1).map(i => {
          const isAsc = i.startRow.rowIndex <= i.endRow.rowIndex;
          return {
            rowStartIndex: isAsc? i.startRow.rowIndex : i.endRow.rowIndex,
            rowEndIndex: isAsc? i.endRow.rowIndex : i.startRow.rowIndex,
            columns: i.columns.map(j => j.colId)
          }
        })

        // //Check is last range is single row or multiple row selection
        // const isSingleRow = lastRange.rowEndIndex - lastRange.rowStartIndex == 0;
        
        //Check if last range's start cell clashes with previous cell ranges.
        const startCell = {
          rowStartIndex: lastRange.orgRowStartIndex,
          rowEndIndex: lastRange.orgRowStartIndex
        }
        let isStartCellClashed = false;
        for (const range of previousRanges) {
          const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex;
          const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex;
          const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex;
          const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex;
          const columnClashed = range.columns.filter(i => i == startColumnId).length > 0;
          if (isRowClashed && columnClashed) {
            isStartCellClashed = true;
            break;
          }
        }

        //Determine to unselect or select row
        let toUnselect = this.rowSelectorClicked_allColsSelectedRowIndexList.includes(startCell.rowStartIndex);

        //Prepare new cell ranges for previous ranges
        const newCellRanges = [];
        for (const range of previousRanges) {

          const isClashedWithLastRange = (lastRange.rowStartIndex >= range.rowStartIndex && lastRange.rowStartIndex <= range.rowEndIndex) ||
                                          (range.rowStartIndex >= lastRange.rowStartIndex && range.rowStartIndex <= lastRange.rowEndIndex);

          if (!isClashedWithLastRange) {
            //Transfer the range to newCellRanges when there is no row clashed with last range.
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
            continue;
          }

          //split existing range to exclude the last range's selected rows.
          if (range.rowStartIndex < lastRange.rowStartIndex && range.rowEndIndex >= lastRange.rowStartIndex) {
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: lastRange.rowStartIndex-1,
              columns: range.columns
            })
          }
          if (range.rowEndIndex > lastRange.rowEndIndex && range.rowStartIndex <= lastRange.rowEndIndex) {
            newCellRanges.push({
              rowStartIndex: lastRange.rowEndIndex+1,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
          }
        }

        if (!isStartCellClashed && !toUnselect) {
          //Prepare new cell range for last range
          newCellRanges.push({
            rowStartIndex: lastRange.rowStartIndex,
            rowEndIndex: lastRange.rowEndIndex,
            columns: columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
          })
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null);
          }
        }

        if (toUnselect) {
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null);
          }
        }
        
        //Check against original ranges. Don't update cell ranges if there is no changes. It helps to avoid infinite loop.
        if (JSON.stringify(originalRanges) != JSON.stringify(newCellRanges)) {
          api.clearRangeSelection();
          for (const newRange of newCellRanges) {
            api.addCellRange(newRange);
          }
        } else {
          const rowNodes = api.rowModel.rowsToDisplay;
          const selection = new Set();
          for (const range of originalRanges) {
            let startIdx = range.rowStartIndex;
            let endIdx = range.rowEndIndex;
            if (startIdx > endIdx) {
              startIdx = range.rowEndIndex;
              endIdx = range.rowStartIndex;
            }

            for (let i = startIdx; i <= endIdx; i++) {
              selection.add(rowNodes[i].data.uuId);
            }
          }
        }
        
      }
    },
    async processValueChanged(api) {
      const projectId = this.id;
      this.inProgressLabel = this.$t('task.progress.updating_tasks');
      this.inProgressShow = true;
      
      if (this.pendingProcessRequestList.length > 250) {
        do {
          this.pendingRequestBatchList.push(this.pendingProcessRequestList.splice(0, 250));
        } while(this.pendingProcessRequestList.length > 250);
      }

      //Prepare to call compose api request and end the session
      if (this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {

        //Keep the last focused cell info and reapply it after data refresh
        const resetFocus = (api, cell) => {
          api.clearRangeSelection();
          if (cell != null && cell.rowIndex != null && cell.colId != null) {
            api.setFocusedCell(cell.rowIndex, cell.colId, null);
            api.addCellRange({
              rowStartIndex: cell.rowIndex
              , rowEndIndex: cell.rowIndex
              , columnStart: cell.colId
              , columnEnd: cell.colId
            });
          }
        }

        //Add the requests as a new batch to batch list.
        if (this.pendingProcessRequestList.length > 0) {
          const requests = this.pendingProcessRequestList.splice(0, this.pendingProcessRequestList.length);
          this.pendingRequestBatchList.push(requests);
        }

        //Process the request batch
        if (this.pendingRequestBatchList.length > 0) {
          const bList = this.pendingRequestBatchList.splice(0, this.pendingRequestBatchList.length);         
          let hasError = false;
          let errorObj = null;
          let alertMsg = null;
          let index = 0;
          for (const b of bList) {
            //Turn off project autoScheduling, webhook and macro during batch CRUD operation
            // to improve performance.
            b.unshift({
              'note': 'Disable macros',
              'invoke': 'PUT /api/system/features?entity=macros&action=DISABLE'
            });
            b.unshift({
              'note': 'Disable project scheduling',
              'invoke': 'PUT /api/system/features?entity=scheduling&action=DISABLE'
            });
            b.push({
              'note': 'Exit script execution, and do not restore some disabled commands',
              'invoke': 'PUT /api/system/features?entity=script&action=EXIT'
            });
            
            await compositeService.exec(b)
            .then(response => {
              if (response.status == 207) {
                const feedbackList = response.data[response.data.jobCase];
                const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
                if (msg != null) {
                  hasError = true;
                  alertMsg = msg;
                }
              }
            })
            .catch(e => {
              hasError = true;
              errorObj = e;
            });
            
            if (hasError) {
              break;
            }
            index++;
          }

          if (!hasError && this.project != null && this.project.autoScheduling) {
            await projectService.schedule([{ uuId: this.id }])
            .catch(e => {
              hasError = true;
              errorObj = e;
            });
          }

          if (hasError) {
            if (errorObj != null && alertMsg == null) {
              alertMsg = this.$t('error_failed_to_complete_operation');
              const response = errorObj.response;
              if (response) {
                if (403 === response.status) {
                  alertMsg = this.$t('error.authorize_action')
                } else if (422 === response.status) {
                  const feedbackList = response.data[response.data.jobCase];
                  const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
                  if (msg != null) {
                    alertMsg = msg;
                  }
                }
              }
            }
            this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
          } else {
            this.resetAlert();
          }
          taskLoaded = {}; // force reloading from the server
          if (this.isCompactView) {
            this.forceReload = true;
          }
          this.reloadTasks(() => {
            setTimeout(() => {
              resetFocus(api, this.lastFocusedCell);
            }, 0);
            this.inProgressShow = false;
          });
        } else {
          this.inProgressShow = false;
        }
        
        //Reset session properties
        this.resetDurationCalculationState = true;
        // this.inProgressShow = false;
        return;
      }
      
      let isPendingOtherProcess = false;
      do {
        let currentItem = null;
        if (this.processDateValueChangedList.length > 0) {
          currentItem = this.processDateValueChangedList.shift();
        } else {
          currentItem = this.processValueChangedList.shift();
        }
        
        if (currentItem == null) {
          //Defensive code: move to next iteration when it is null
          continue;
        }

        const colId = currentItem.colId;
        const property = currentItem.property;
        const oldValue = currentItem.oldValue;
        let newValue = currentItem.newValue;
        const rowData = currentItem.data;
        let taskId = currentItem.taskId;
        const parentId = currentItem.parentId;
        const taskName = currentItem.taskName;
        const taskType = currentItem.taskType;
        const color = currentItem.color;
        const skippedWhenTypeSummaryTask = [
          'startTime', 'closeTime', 'estimatedDuration'
          , 'progress', 'autoScheduling'
          , 'constraint', 'estimatedTimeToComplete'
        ];
        if (taskType == 'Project' && skippedWhenTypeSummaryTask.includes(property)) {
          continue;
        }
        
        const skippedWhenTypeMilestone = [
          'closeTime', 'estimatedDuration', 'estimatedTimeToComplete'
        ];
        if (taskType == 'Milestone' && skippedWhenTypeMilestone.includes(property)) {
          continue;
        }
        
        if (colId == 'template') {
          //Change in template column may add/remove rows
          //Signal a full reload to have proper rendering
          this.forceReload = true;
        }

        //TaskId is possible to be null when colId starts with `taskcol_`.
        //Create new task
        if (taskId == null) {
          const task = { 
            taskType: 'Task',
            name: taskName,
            parent: parentId == 'ROOT'? this.id : parentId,
            constraintType: "As_soon_as_possible", //Default to ASAP as this matches how logic works in taskModal.
            autoScheduling: true, //Default to true as this matches how logic works in taskModal.
            duration: 480,
            durationAUM: 'D',
            color
          }

          //Set custom Field if default value is set
          if (Array.isArray(this.customFields) && this.customFields.length > 0) {
            for (const c of this.customFields) {
              if (c.def != null) {
                task[c.name] = c.def;
              }
            }
          }

          //Set value to task's startDate or closeDate when project autoScheduling is false
          if (this.project.autoScheduling != true) {
            //Get proper startDate or endDate for new task
            //1. Get the parent's startDate
            //2. Get the project's scheduleFromStart
            //3. Depends on scheduleFromStart, set parent startDate to task's startDate or endDate
            //4. Default date will be today date in case no valid value is available
            const projectScheduleFromStart = this.project.scheduleMode != null? this.project.scheduleMode == 'ASAP' : true;
            let suggestedDateStr = moment.utc().format('YYYY-MM-DD');
            let suggestedTimeStr = null;
            if (projectScheduleFromStart) {
              if (parentId === 'ROOT') {
                if (isValidDateStrFormat(this.project.startDateStr)) {
                  suggestedDateStr = this.project.startDateStr;
                }
              } else {
                const foundParent = api.getRowNode(parentId);
                if (foundParent != null && foundParent.data.startTime != null && foundParent.data.startTime != 0) {
                  const parentStartDateTime = moment.utc(foundParent.data.startTime);
                  suggestedDateStr = parentStartDateTime.format('YYYY-MM-DD');
                  suggestedTimeStr = parentStartDateTime.format('HH:mm');
                }
              }
              if (suggestedTimeStr == null) {
                suggestedTimeStr = getEarliestOrLatestWorkHour(this.calendar, moment.utc(suggestedDateStr, 'YYYY-MM-DD'), { isLatest: true, ignoreLeave: false });
              }
              task.startTime = moment.utc(`${suggestedDateStr} ${suggestedTimeStr}`, 'YYYY-MM-DD HH:mm').valueOf();
            } else {
              if (parentId === 'ROOT') {
                if (isValidDateStrFormat(this.project.closeDateStr)) {
                  suggestedDateStr = this.project.closeDateStr;
                }
              } else {
                const foundParent = api.getRowNode(parentId);
                if (foundParent != null && foundParent.data.closeTime != null && foundParent.data.closeTime != 0) {
                  const parentcloseDateTime = moment.utc(foundParent.data.closeTime);
                  suggestedDateStr = parentcloseDateTime.format('YYYY-MM-DD');
                  suggestedTimeStr = parentcloseDateTime.format('HH:mm');
                }
              }
              if (suggestedTimeStr == null) {
                suggestedTimeStr = getEarliestOrLatestWorkHour(this.calendar, moment.utc(suggestedDateStr, 'YYYY-MM-DD'), { isLatest: true, ignoreLeave: false });
              }
              task.closeTime = moment.utc(`${suggestedDateStr} ${suggestedTimeStr}`, 'YYYY-MM-DD HH:mm').valueOf();
            }
          }

          const taskRefId = `row_${parentId}__col_${this.simpleHashCode(colId)}`; //sanitise value otherwise backend api will reject the job due to unprocessable characters.
          const refId = `row_${parentId}__col_${colId}`;
          this.pendingProcessRequestList.push({
            method: 'POST',
            invoke: `/api/task/add?holder=${projectId}`,
            body: [task],
            vars: [{ 
              name: taskRefId,
              path: '$.feedbackList.uuId'
            }],
            note: `taskAdd__${refId}`
          });
          taskId = `@{${taskRefId}}`; // taskId variable syntax for composite api.

          if ('tag' !== property && Array.isArray(currentItem.tags) && currentItem.tags.length > 0) {
            const request = await this.updateTags(taskId, [], currentItem.tags.map(i => i.name));
            if (request.length > 0) {
              this.pendingProcessRequestList.push(...request);
            }
          }
          if ('staffs' !== property && Array.isArray(currentItem.staff) && currentItem.staff.length > 0) {
            const request = TaskViewRequestGenerator.updateStaff(taskId, [], currentItem.staff);
            if (request.length > 0) {
              this.pendingProcessRequestList.push(...request);
            }
          }
          if ('resources' !== property && Array.isArray(currentItem.resources) && currentItem.resources.length > 0) {
            const request = TaskViewRequestGenerator.updateResource(taskId, [], currentItem.resources,  { customFields: this.resourceCustomFields });
            if (request.length > 0) {
              this.pendingProcessRequestList.push(...request);
            }
          }
          if ('rebates' !== property && Array.isArray(currentItem.rebates) && currentItem.rebates.length > 0) {
            const request = TaskViewRequestGenerator.updateRebate(taskId, [], currentItem.rebates);
            if (request.length > 0) {
              this.pendingProcessRequestList.push(...request);
            }
          } 
          if ('skills' !== property && Array.isArray(currentItem.skills) && currentItem.skills.length > 0) {
            const request = TaskViewRequestGenerator.updateSkill(taskId, [], currentItem.skills, { customFields: this.skillCustomFields });
            if (request.length > 0) {
              this.pendingProcessRequestList.push(...request);
            }
          }
        }

        if ('avatarRef' === property) {
          setTimeout(() => {
            api.resetRowHeights();
          }, 10);
        }

        if ('tag' === property) {
          const request = await this.updateTags(taskId, oldValue, newValue);
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('template' === property) {
          let request = null;
          if (oldValue?.uuIds != null && oldValue.uuIds.length > 0 && (newValue?.uuIds == null || newValue.uuIds.length == 0)) {
            request = TaskViewRequestGenerator.removeTaskTemplate(taskId, oldValue.uuIds);
          } else {
            request = TaskViewRequestGenerator.applyTaskTemplate(taskId, newValue.uuIds, { override: true, group: newValue.uuIds.length > 1 });
          }
          
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('totalActualDuration' === property) {
          //This flow only for action of click and edit 'totalActualDuration' cell directly. No fill operation. No copy and paste.
          const oldStaff = rowData.oldStaffs; //This property is added in WorkEffortEditor.
          const newStaff = rowData.staffs;
          const request = TaskViewRequestGenerator.updateStaff(taskId, oldStaff, newStaff);
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('notes' === property) {
          const request = TaskViewRequestGenerator.updateNote(taskId, oldValue != null? oldValue : [], newValue, { customFields: this.noteCustomFields });
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('estimatedTimeToComplete' === property) {
          if (rowData == null) { //When target is non-existing (/empty) task
            const unit = analyzeDurationAUM(newValue).unit;
            const durationInMinutes = convertDisplayToDuration(newValue, this.durationConversionOpts).value;
            const task = { uuId: taskId, duration: durationInMinutes, durationAUM: unit }
            
            this.pendingProcessRequestList.push({
              method: 'PUT',
              invoke: '/api/task/update',
              body: [task],
              vars: [],
              note: `taskUpdate__${colId}`
            })
            
          } else {
            if (this.isDateCalcInProgress) {
              this.processDateValueChangedList.push(currentItem);
              break;
            }
            let totalWorkEffort = 0;
            if (rowData.staffs != null) {
              for (const staff of rowData.staffs) {
                totalWorkEffort += staff.duration;
              }
            }
            const etcInMinutes = convertDisplayToDuration(newValue, this.durationConversionOpts).value;
            let estimatedDuration = totalWorkEffort + etcInMinutes;
            estimatedDuration = convertDurationToDisplay(estimatedDuration, 'D', this.durationConversionOpts);
            this.dateTimeDurationValueChanged(taskId, 'estimatedDuration', estimatedDuration, rowData);
            isPendingOtherProcess = true;
            break;
          }
        } else if ('fixedDuration' === property) {
          const task = { uuId: taskId };
          const durationInMinutes = convertDisplayToDuration(newValue, this.durationConversionOpts).value;
          task.fixedDuration = durationInMinutes;
      
          this.pendingProcessRequestList.push({
            method: 'PUT',
            invoke: '/api/task/update',
            body: [task],
            vars: [],
            note: `taskUpdate__${colId}`
          })
        } else if (['startTime', 'closeTime', 'estimatedDuration', 'constraint', 'autoScheduling'].includes(property)) {
          if (rowData == null) { //When target is non-existing (/empty) task
            const task = { uuId: taskId }
            if ('estimatedDuration' == property) {
              const unit = analyzeDurationAUM(newValue).unit;
              const durationInMinutes = convertDisplayToDuration(newValue, this.durationConversionOpts).value;
              task.duration = durationInMinutes;
              task.durationAUM = unit;
            } else if ('constraint' == property) {
              task.constraintType = newValue.type;
              task.constraintTime = newValue.time;
            } else {
              task[property] = newValue;
            }
            
            this.pendingProcessRequestList.push({
              method: 'PUT',
              invoke: '/api/task/update',
              body: [task],
              vars: [],
              note: `taskUpdate__${colId}`
            })
          } else {
            //Fallback value for startTime or closeTime when newValue is empty (null or 0) and task is autoScheduled
            if ((property == 'startTime' || property == 'closeTime') && (newValue == null || newValue == 0) && rowData.autoScheduling == true && rowData.taskType == 'Task') {
              let parentDateStr = null;
              let parentTimeStr = null;
              
              const projectScheduleFromStart = this.project.scheduleMode != null? this.project.scheduleMode == 'ASAP' : true;
              if (parentId === 'ROOT' || parentId == null) {
                currentItem.parentId = this.projectId;
                if (projectScheduleFromStart) {
                  if (isValidDateStrFormat(this.project.startDateStr)) {
                    parentDateStr = this.project.startDateStr;
                  }
                } else {
                  if (isValidDateStrFormat(this.project.closeDateStr)) {
                    parentDateStr = this.project.closeDateStr;
                  }
                }
              } else {
                let foundParent = api.getRowNode(currentItem.parentId);
                if (foundParent != null) {
                  foundParent = foundParent.data;
                }
                if (foundParent != null) {
                  if (projectScheduleFromStart) {
                    if (foundParent.startTime != null && foundParent.startTime != 0) {
                      const parentDateTime = moment.utc(foundParent.startTime);
                      parentDateStr = parentDateTime.format('YYYY-MM-DD');
                      parentTimeStr = parentDateTime.format('HH:mm');
                    }
                  } else {
                    if (foundParent.closeTime != null && foundParent.closeTime != 0) {
                      const parentDateTime = moment.utc(foundParent.closeTime);
                      parentDateStr = parentDateTime.format('YYYY-MM-DD');
                      parentTimeStr = parentDateTime.format('HH:mm');
                    }
                  }
                }
                //Fallback to project (start|close) date
                if (parentDateStr == null) {
                  if (projectScheduleFromStart) {
                    if (isValidDateStrFormat(this.project.startDateStr)) {
                      parentDateStr = this.project.startDateStr;
                    }
                  } else {
                    if (isValidDateStrFormat(this.project.closeDateStr)) {
                      parentDateStr = this.project.closeDateStr;
                    }
                  }
                }
                //Fallback to today date
                if (parentDateStr == null) {
                  parentDateStr = moment.utc().format('YYYY-MM-DD');
                }
              }
              if (parentTimeStr != null) {
                newValue = currentItem.newValue = moment.utc(`${parentDateStr} ${parentTimeStr}`, 'YYYY-MM-DD HH:mm').valueOf();
              } else {
                newValue = currentItem.newValue = moment.utc(parentDateStr, 'YYYY-MM-DD').valueOf();
              }
              currentItem.defaultActionForNonWorkPrompt = 'move'
              currentItem.skipOutOfProjectDateCheck = true
            }
            if (this.isDateCalcInProgress) {
              this.processDateValueChangedList.push(currentItem);
              break;
            }

            const opt = {};
            if (Object.hasOwn(currentItem, 'defaultActionForNonWorkPrompt')) {
              opt.defaultActionForNonWorkPrompt = currentItem.defaultActionForNonWorkPrompt;
            }
            if (Object.hasOwn(currentItem, 'skipOutOfProjectDateCheck')) {
              opt.skipOutOfProjectDateCheck = currentItem.skipOutOfProjectDateCheck;
            }
            opt.oldValue = currentItem.oldValue;
            
            //0 is usually set by delete action. For calculation to work properly for deletion, set newValue to null.
            this.dateTimeDurationValueChanged(taskId, property, newValue == 0? null: newValue, rowData, opt);
            isPendingOtherProcess = true;
            break;
          }
        } else if ('staffs' === property) {
          const request = TaskViewRequestGenerator.updateStaff(taskId, oldValue, newValue);
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('resources' === property) {
          const request = TaskViewRequestGenerator.updateResource(taskId, oldValue, newValue, { customFields: this.resourceCustomFields });
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('rebates' === property) {
          const request = TaskViewRequestGenerator.updateRebate(taskId, oldValue, newValue);
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('skills' === property) {
          const request = TaskViewRequestGenerator.updateSkill(taskId, oldValue, newValue, { customFields: this.skillCustomFields });
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('stage' === property) {
          const oldStage = oldValue != null && oldValue.uuId != null? oldValue : null;
          const newStage = newValue;
          const request = TaskViewRequestGenerator.updateStage(taskId, oldStage, newStage);
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else { // update task
          const task = { uuId: taskId }
          const blankToNullList = ['currencyCode', 'complexity', 'durationAUM', 'priority'];
          if (typeof newValue == 'string' && newValue.trim().length == 0 && blankToNullList.includes(property)) {
            task[property] = null;
          } else {
            task[property] = newValue;
          }
          
          this.pendingProcessRequestList.push({
            method: 'PUT',
            invoke: '/api/task/update',
            body: [task],
            vars: [],
            note: `taskUpdate__${property}`
          })
        }

      } while(this.processValueChangedList.length > 0);
      
      if(!isPendingOtherProcess && this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {
        this.inProgressShow = false;
        //Last, call itself again to begin next iteration
        this.processValueChanged(api);
      }
    },
    getModelInfo() {
      this.$store.dispatch('data/info', {type: "api", object: "TASK"}).then(value => {
        this.modelInfo = value.TASK.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    truncatePropValue(property, value) {
      if ('name' === property && value != null && value.length > this.maxNameLength) {
        return value.substring(0, this.maxNameLength);
      }
      if ('description' === property && value != null && value.length > this.maxDescriptionLength) {
        return value.substring(0, this.maxDescriptionLength);
      }
      if ('identifier' === property && value != null && value.length > this.maxIdentifierLength) {
        return value.substring(0, this.maxIdentifierLength);
      }
      return value;
    },
    simpleHashCode(str) {
      var hash = 0, i, chr;
      if (str.length === 0) return hash;
      for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    processCellForClipboard(params) {
      const rowData = params.node.data;
      let srcColId = params.column.colId;
      if (srcColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
        srcColId = 'name';
      }

      const srcRowId = rowData.uuId;
      const srcRowData = params.api.getRowNode(srcRowId).data;
      const isSrcTaskCol = srcColId.startsWith(this.taskColPrefix);

      const source = {
        colId: srcColId
        // , data: objectClone(srcRowData)
        , value: srcRowData[srcColId]
        , property: srcColId
        , taskId:   srcRowData.uuId
        // , parentId: srcRowData.pUuId
        // , taskName: srcRowData.name
        // , taskType: srcRowData.taskType
      }

      if (isSrcTaskCol) {
        const valueObj = source.value;
        source.value = valueObj.single; 
        source.property = valueObj.property;
        source.taskId = valueObj.uuId != null? valueObj.uuId : null;
      }
      
      return this.cellCopyPrefix+JSON.stringify(source);
    },
    processCellFromClipboard(params) {
      let rowData = params.node.data;
      //Skip when the target cell is not editable or is rowSelector.
      const payload = { 
        node: params.node
        , data: params.node.data
        , column: params.column
        , colDef: params.column.colDef
        , api: params.api
        , columnApi: params.columnApi
        , context: params.context
      }
      if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
          !params.column.colDef.editable || params.column.colId == 'rowSelector') {
        return rowData[params.column.colId];
      }
      
      let colId = params.column.colId;
      if (colId == this.COLUMN_AGGRID_AUTOCOLUMN) {
        colId = 'name';
      }
      
      //Skip when the target cell is blacklisted
      const blackListed = ['totalActualDuration'];
      if (blackListed.includes(colId)) {
        return rowData[colId];
      }

      let source = params.value;
      //Defensive code: return original value when the value is not from aggrid cell copy.
      if (typeof source !== 'string' || !source.startsWith(this.cellCopyPrefix)) {
        return rowData[colId];
      }

      //Restore value to JSON object.
      source = source.substring(this.cellCopyPrefix.length);
      source = JSON.parse(source);
      
      const isSrcTaskCol = source.colId.startsWith(this.taskColPrefix);
      
      const target = {
        colId: colId
        , data: objectClone(rowData)
        , value: rowData[colId]
        , property: colId
        , taskId:   rowData.uuId
        , parentId: rowData.pUuId
        , taskName: rowData.name
        , taskType: rowData.taskType
      }

      const taskColPrefix = this.taskColPrefix;
      if (colId.startsWith(taskColPrefix)) {
        const valueObj = target.value;
        target.data = valueObj.data;
        target.value = valueObj.single; 
        target.property = valueObj.property;
        target.taskId = valueObj.uuId != null? valueObj.uuId : null;
        target.parentId = rowData.uuId;
        target.taskType = 'Task'; //colId starts with taskColPrefix must be 'Task'

        let taskName = '<undefined>';
        const regex = new RegExp(`^${taskColPrefix}([A-Za-z0-9-]+)_([0-9][0-9])_(.*)$`);
        const r = colId.match(regex);
        if (r != null) {
          taskName = r[3];
        }
        target.taskName = taskName;
      }

      let isCompatible = false;
      if (source.property == target.property) {
        isCompatible = true;
      } else {
        isCompatible = TaskViewPropertyUtil.isCompatible(source.property, target.property);
      }

      if (!isCompatible) {
        return rowData[colId];
      }

      //Skip parse action when source is taskCol and has no valid task
      if (isSrcTaskCol && source.taskId == null) {
        return rowData[colId];
      }

      const isTgtTaskCol = colId.startsWith(this.taskColPrefix);
      let srcValue = source.value;
      if ('staffs' == source.property && 'staffs' == target.property) {
        const tgtValue = objectClone(rowData[colId]);
        let tgtStaffs = tgtValue;
        if (isTgtTaskCol) {
          if (tgtValue.single == null) {
            tgtValue.single = [];
          }
          tgtStaffs = tgtValue.single;
        }
        if (srcValue.length == 0 && tgtStaffs.length == 0) {
          return rowData[colId]; //No change when both lists are empty.
        }

        //Remove all staff from tgtStaffs
        if (srcValue.length == 0) {
          tgtStaffs.splice(0, tgtStaffs.length);
          return tgtValue;
        }

        const updatedStaffs = [];
        for (const srcStaff of srcValue) {
          const found = tgtStaffs.find(i => i.uuId == srcStaff.uuId);
          if (found != null) {
            //Update tgtStaff with srcStaff's utilization. Keep the rest.
            found.utilization = srcStaff.utilization;
            updatedStaffs.push(found);
          } else {
            //Clean up workEffort (duration, durationAUM) when add to new list.
            delete srcStaff.duration;
            delete srcStaff.durationAUM;
            updatedStaffs.push(srcStaff);
          }
        }
        tgtStaffs.splice(0, tgtStaffs.length, ...updatedStaffs);
        return tgtValue;
      }

      if ('notes' == source.property && 'notes' == target.property) {
        const tgtValue = objectClone(rowData[colId]);
        let tgtNotes = tgtValue;
        if (isTgtTaskCol) {
          if (tgtValue.single == null) {
            tgtValue.single = [];
          }
          tgtNotes = tgtValue.single;
        }
        if (srcValue.length == 0 && tgtNotes.length == 0) {
          return rowData[colId]; //No change when both lists are empty.
        }

        //Remove the top item from the tgtNotes when src is empty
        if (srcValue.length == 0) {
          tgtNotes.shift();
          return tgtValue;
        }

        const newNote = objectClone(srcValue[0]);
        if (newNote.identifier == null || newNote.identifier.trim().length == 0) {
          delete newNote.identifier;
        }
        delete newNote.uuId;
        tgtNotes.unshift(newNote);
        return tgtValue;
      }

      if ('name' === target.property || 'description' === target.property || 'identifier' === target.property) {
        srcValue = this.truncatePropValue(target.property, srcValue);
      }

      if (isTgtTaskCol) {
        const tgtValue = objectClone(rowData[colId]); //clone and return new object to trigger onCellValueChanged() event
        tgtValue.single = srcValue;
        return tgtValue;
      }
      return srcValue;
    },
    prepareTargetCellData(colId, rowData, { color=null }={}) {
      const target = {
        colId: colId
        , data: objectClone(rowData)
        , oldValue: rowData[colId]
        , property: colId
        , taskId:   rowData.uuId
        , parentId: rowData.pUuId
        , taskName: rowData.name
        , taskType: rowData.taskType
        , color: color
      }

      const isTgtTaskCol = colId.startsWith(this.taskColPrefix);

      if (isTgtTaskCol) {
        const valueObj = target.oldValue;
        target.data = valueObj.data;
        target.oldValue = valueObj.single; 
        target.property = valueObj.property;
        target.taskId = valueObj.uuId != null? valueObj.uuId : null;
        target.parentId = rowData.uuId;
        target.taskType = 'Task'; //colId starts with taskColPrefix must be 'Task'

        let taskName = '<undefined>';
        const regex = new RegExp(`^${this.taskColPrefix}([A-Za-z0-9-]+)_([0-9][0-9])_(.*)$`);
        const r = colId.match(regex);
        if (r != null) {
          taskName = r[3];
        }
        target.taskName = taskName;
      }

      return target;
    },
    ctrlEnterFillCellPropCheck_note(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix);
      let tgtNotes = tgtValue;
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = [];
        }
        tgtNotes = tgtValue.single;
      }
      if (srcValue.length == 0 && tgtNotes.length == 0) {
        // return rowData[colId]; //No change when both lists are empty.
        return { action: 'continue' }
      }

      //Remove the top item from the tgtNotes when src is empty
      if (srcValue.length == 0) {
        tgtNotes.shift();
        return { action: 'add', newValue: tgtNotes }
      }

      const newNote = objectClone(srcValue[0]);
      if (newNote.identifier == null || newNote.identifier.trim().length == 0) {
        delete newNote.identifier;
      }
      delete newNote.uuId;
      tgtNotes.unshift(newNote);
      return { action: 'add', newValue: tgtNotes }
    },
    ctrlEnterFillCellPropCheck_staff(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix);
      let tgtStaffs = tgtValue;
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = [];
        }
        tgtStaffs = tgtValue.single;
      }
      if (srcValue.length == 0 && tgtStaffs.length == 0) {
        //No change when both lists are empty.
        return { action: 'continue' }
      }

      let newValue = [];
      if (srcValue.length > 0) {
        const updatedStaffs = [];
        for (const srcStaff of srcValue) {
          const found = tgtStaffs.find(i => i.uuId == srcStaff.uuId);
          if (found != null) {
            //Update tgtStaff with srcStaff's utilization. Keep the rest.
            found.utilization = srcStaff.utilization;
            updatedStaffs.push(found);
          } else {
            //Clean up workEffort (duration, durationAUM) when add to new list.
            delete srcStaff.duration;
            delete srcStaff.durationAUM;
            updatedStaffs.push(srcStaff);
          }
        }
        newValue = updatedStaffs;
      }
      return { action: 'add', newValue }
    },
    ctrlEnterFillCell(api, rowIndex, srcColId) {
      const srcRowNode = api.getDisplayedRowAtIndex(rowIndex);
      if (srcRowNode == null) {
        return;
      }

      //Prepare source (property) value
      const srcRowData = srcRowNode.data;
      const isSrcTaskCol = srcColId.startsWith(this.taskColPrefix);

      const source = {
        colId: srcColId
        , value: srcRowData[srcColId]
        , property: srcColId
        , taskId:   srcRowData.uuId
      }

      if (isSrcTaskCol) {
        const valueObj = source.value;
        source.value = valueObj.single; 
        source.property = valueObj.property;
        source.taskId = valueObj.uuId != null? valueObj.uuId : null;
      }

      //Skip when source is taskCol and has no valid task
      if (isSrcTaskCol && source.taskId == null) {
        return;
      }

      const pendingFilled = [];
      const cellRanges = api.getCellRanges();
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex;
        const rowEndIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex;
        const columns = cRange.columns.map(i => { return { colId: i.colId, color: i.userProvidedColDef.color }});
        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          const curRowNode = api.getDisplayedRowAtIndex(i);
          if (curRowNode == null) {
            continue;
          }

          const tgtRowData = curRowNode.data;
          for (const col of columns) {
            if (col.colId === srcColId && rowIndex == i) {
              continue; //Skip when current cell (rowIndex & colId) is source cell
            }
            const target = this.prepareTargetCellData(col.colId, tgtRowData, { color: col.color });
            let isCompatible = false;
            if (source.property == target.property) {
              isCompatible = true;
            } else {
              isCompatible = TaskViewPropertyUtil.isCompatible(source.property, target.property);
            }
            if (!isCompatible) {
              continue;
            }

            let srcValue = source.value;
            if ('staffs' == source.property && 'staffs' == target.property) {
              const result = this.ctrlEnterFillCellPropCheck_staff(srcValue, target);
              if (result.action == 'continue') {
                continue;
              }
              target.newValue = result.newValue;
              pendingFilled.push(target);
              continue;
            }

            if ('notes' == source.property && 'notes' == target.property) {
              const result = this.ctrlEnterFillCellPropCheck_note(srcValue, target);
              if (result.action == 'continue') {
                continue;
              }
              target.newValue = result.newValue;
              pendingFilled.push(target);
              continue;
            }

            if ('name' === target.property || 'description' === target.property || 'identifier' === target.property) {
              srcValue = this.truncatePropValue(target.property, srcValue);
            }

            const isTgtTaskCol = target.colId.startsWith(this.taskColPrefix);
            if (isTgtTaskCol) {
              //Skip if lack of required permission
              if (target.taskId == null && !this.canAdd(this.permissionName)) {
                continue;
              }
              if (target.taskId != null && !this.canEdit(this.permissionName)) {
                continue;
              }
              const tgtValue = objectClone(target.oldValue); //clone and return new object to trigger onCellValueChanged() event
              tgtValue.single = srcValue;
              target.newValue = tgtValue;
              pendingFilled.push(target);
              continue;
            }

            target.newValue = srcValue;
            pendingFilled.push(target);
          }
        }
      }

      if (pendingFilled.length > 0) {
        this.processValueChangedList.push(...pendingFilled);
        this.inProgressLabel = this.$t('task.progress.updating_tasks');
        this.processValueChanged(api);
      }
    },
    onCellKeyDown(params) {
      if (params.event.key == 'Delete' && this.canDelete(this.permissionName)) { //'Delete'
        const cellRanges = params.api.getCellRanges();
        const taskColPrefix = this.taskColPrefix;
        //Prepare cell information
        const taskColTasks = [];
        const processedCells = []; //Used to elimate duplicate records.
        for (const cRange of cellRanges) {
          const startRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex;
          const lastRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex; 
          
          const columns = cRange.columns;
          for (let idx = startRowIdx; idx <= lastRowIdx; idx++) {
            const rowNode = params.api.getDisplayedRowAtIndex(idx);
            //Exclude project (ROOT key) node
            if (rowNode.key == 'ROOT' && columns.find(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN) != null) {
              continue;
            }
            for (const column of columns) {
              const colId = column.colId;
              const rowId = rowNode.data.uuId;
              const found = processedCells.find(i => rowId == i.rowId && (this.COLUMN_AGGRID_AUTOCOLUMN == i.colId || colId == i.colId));
              if (found != null) {
                continue; //Duplicated cell is found. Process to next iteration.
              }
              processedCells.push({ rowId, colId });

              //Handle 'ag-Grid-AutoColumn' cell
              //Brief: Delete ag-Grid-AutoColumn cell means remove the summary task (whole row) and thus delete all it's children.
              //Remove previously handled cells of the same row before current `ag-Grid-AutoColumn`.
              if (this.COLUMN_AGGRID_AUTOCOLUMN == colId) {
                //Remove redundant cells in taskColTasks
                let redundantTaskColCells = [];
                taskColTasks.forEach((i, idx) => {
                  if (i.parent == rowId) {
                    redundantTaskColCells.push(idx);
                  }
                });
                if (redundantTaskColCells.length > 0) {
                  redundantTaskColCells = redundantTaskColCells.reverse();
                  for (const idx of redundantTaskColCells) {
                    taskColTasks.splice(idx, 1);
                  }
                }
                
                //Remove redundant cells in (non taskCol) pendingDeleteCells
                let redundantPendingDeleteCells = [];
                this.pendingDeleteCells.forEach((i, idx) => {
                  if (i.taskId == rowId) {
                    redundantPendingDeleteCells.push(idx);
                  }
                });
                if (redundantPendingDeleteCells.length > 0) {
                  redundantPendingDeleteCells = redundantPendingDeleteCells.reverse();
                  for (const idx of redundantPendingDeleteCells) {
                    this.pendingDeleteCells.splice(idx, 1);
                  }
                }

                taskColTasks.push({
                   uuId: rowNode.data.uuId
                  , name: rowNode.data.name
                  , parent: rowNode.data.pUuId
                  , parentName: rowNode.data.pName
                  , colId
                });
                continue;
              }

              if (rowNode.data[colId] == null) {
                continue; //Skip when the property value is null.
              }

              //Handle taskCol cell
              if (colId.startsWith(taskColPrefix)) {
                if (rowNode.data[colId].uuId == null)  {
                  //Skip when there is no valid task for the taskcol cell.
                  continue;
                }

                taskColTasks.push({
                  uuId: rowNode.data[colId].uuId
                  , name: rowNode.data[colId].data.name
                  , parent: rowId
                  , parentName: rowNode.data.name
                  , colId
                });
                continue;
              }
              //Handle non taskCol cell
              //Skip when the cell is not editable
              let isEditable;
              if (typeof this.defaultColDef.editable === 'function') {
                isEditable = this.defaultColDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }});
              } else if (typeof this.defaultColDef.editable === 'boolean') {
                isEditable = this.defaultColDef.editable;
              }
              const colDef = this.columnDefs.find(i => i.colId == colId || i.field == colId);
              if (typeof colDef.editable === 'function') {
                isEditable = colDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }});
              } else if (typeof colDef.editable === 'boolean') {
                isEditable = colDef.editable;
              }
              if (!isEditable) {
                continue;
              }
              
              //Special handling non taskCol cell (startTime and closeTime)
              if ((colId == 'startTime' || colId == 'closeTime') && rowNode.data.autoScheduling == true) {
                  const payload = {
                    colId
                    , data: objectClone(rowNode.data)
                    , newValue: null
                    , oldValue: null
                    , property: colId
                    , taskId: rowNode.data.uuId
                    , parentId: rowNode.data.pUuId
                    , taskName: rowNode.data.name
                    , taskType: rowNode.data.taskType
                    , color: '' //Not applicable
                  }
                  this.processValueChangedList.push(payload);
                  continue
              }
              
              this.pendingDeleteCells.push({
                colId
                , data: objectClone(rowNode.data)
                , property: colId
                , taskId: rowId
                , parenId: rowNode.data.pUuId
                , taskName: rowNode.data.name
                , taskType: rowNode.data.taskType
                , isTaskCol: false
                , value: rowNode.data[colId]
              });
            }
          }
        }

        if (this.processValueChangedList.length > 0) {
          this.inProgressLabel = this.$t('task.progress.updating_tasks');
          this.processValueChanged(params.api);
        }

        if (taskColTasks.length > 0) {
          //Prepare data for taskcol delete confirmation dialog
          this.tcConfirmDeleteTasks = taskColTasks;
          this.prepareTaskColConfirmDeleteDialog();
        } else if (this.pendingDeleteCells.length > 0) {
          this.deleteCell();
        }
      } else if ((params.event.keyCode == 13 || params.event.keyCode == 68) && params.event.ctrlKey) {
        const api = params.api;
        if (api == null) {
          return;
        }

        const focusedCell = api.getFocusedCell();
        if (focusedCell == null) {
          return;
        }

        const cellRanges = api.getCellRanges();
        if (cellRanges.length == 0 || (cellRanges.length > 1 && params.event.keyCode == 68)) { //Ctrl+D supports only single range
          return;
        }

        const fRange = cellRanges.find(i => {
          if (i.startRow != null && i.endRow != null && i.columns.find(j => j.colId == focusedCell.column.colId) != null) {
            return i.startRow.rowIndex <= focusedCell.rowIndex && focusedCell.rowIndex <= i.endRow.rowIndex;
          }
          return false;
        })
        if (fRange == null) {
          return;
        }
        
        //Stop operation when range is a single cell selection because nothing to copy and fill.
        if (fRange.startRow.rowIndex == fRange.endRow.rowIndex && fRange.columns.length == 1) {
          return;
        }

        const rowIndex = fRange.startRow.rowIndex < fRange.endRow.rowIndex? fRange.startRow.rowIndex : fRange.endRow.rowIndex;
        this.ctrlEnterFillCell(api, rowIndex, fRange.columns[0].colId);
        return;

      } else if (params.event.keyCode == 13) {
        //Navigate to next cell when user press enter on the read-only cell.
        let editable = false;
        const api = params.api;
        if (api == null) {
          return;
        }
        const fRowIndex = params.rowIndex;
        const column = params.column;
        if (typeof column.colDef.editable == 'boolean') {
          editable = column.colDef.editable;
        } else if (typeof column.colDef.editable == 'function') {
          const data = params.data;
          editable = column.colDef.editable({ data });
        }
        if (!editable) {
          let rowIndex;
          if (params.event.shiftKey) {
            rowIndex = fRowIndex - 1;
            if (rowIndex < 0) {
              rowIndex = 0;
            }
          } else {
            rowIndex = fRowIndex + 1;
            const lastRowIndex = api.getDisplayedRowCount() - 1;
            if (rowIndex > lastRowIndex) {
              rowIndex = lastRowIndex;
            }
          }
          //Navigate to next cell below when user press enter on ready only cell.
          if (api.getDisplayedRowAtIndex(rowIndex) != null) {
            this.navigateCellTo(api, rowIndex, column.colId, null);
            return;
          }
        }
      } else if (params.event.key == '.') { //Fixed the issue of cell editing is not triggered by '.' key
        const editingCells = params.api.getEditingCells()
        if (editingCells.length == 0) {
          params.api.setFocusedCell(params.rowIndex, params.column.colId);
          params.api.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: params.column.colId,
            key: params.event.key
          });
        }
      }
    },
    async deleteCell() {
      //deleteCell() expects only non-taskcol cell.
      const pendingItems = [];
      const cells = this.pendingDeleteCells.splice(0, this.pendingDeleteCells.length);
      for (const cell of cells) {
        cell.oldValue = cell.value;
        //Some models may update the property in data. Hence, it is important that cell.data is passed as reference to keep the change and will be used later.
        cell.newValue = TaskViewPropertyUtil.getEmptyDataModel(cell.property, cell.data).value; 
        delete cell.value;
        pendingItems.push(cell);
      }
      
      if (pendingItems.length > 0) {
        const data = [];
        for (const item of pendingItems) {
          const rowData = item.data; //use item.data for applyTransaction because some delete actions may update other property values in the data. (e.g totalActualDuration)
          rowData[item.colId] = item.newValue;
          data.push(rowData);
        }
        this.gridOptions.api.applyTransaction({ update: data });
        this.processValueChangedList.push(...pendingItems);
      }
      
      this.inProgressLabel = this.$t('task.progress.deleting');
      this.processValueChanged(this.gridOptions.api);
    },
    onPasteStart(/** params */) {
      this.isPasteInProgress = true;
    },
    onPasteEnd(params) {
      this.isPasteInProgress = false;

      this.consolidateChangedCellValues(this.processValueChangedList);
      if (this.processValueChangedList.length > 0) {
        this.inProgressLabel = this.$t('task.progress.paste_tasks');
        this.processValueChanged(params.api);  
      }
    },
    consolidateChangedCellValues(valueChangedlist) {

      //Handle edge case: Copy & paste multiple duration related cells may trigger multiple requests which send incorrect data to backend.
      //Solution: Consolidate duration related cell value changes into one if they belong to the same task.
      const durationGroup = ['startTime', 'closeTime', 'estimatedDuration', 'constraint', 'autoScheduling']
      const ids = valueChangedlist
                  .filter(i => i.taskId != null && durationGroup.includes(i.property))
                  .map(i => i.taskId);

      for (const id of ids) {
        const len = valueChangedlist.filter(i => i.taskId == id && durationGroup.includes((i.property))).length;
        if (len < 1) {
          continue;
        }
        const list = [];
        for (let i = 0; i < len; i++) {
          const idx = valueChangedlist.findIndex(j => j.taskId == id && durationGroup.includes(j.property));
          list.push(valueChangedlist.splice(idx, 1)[0]);
        }

        //Sort out the trigger value
        const triggerOrder = ['estimatedDuration','startTime','closeTime','autoScheduling','constraint'];
        const property = list.reduce((r, c) => {
          if (r == null) {
            return c.property;
          }
          if (triggerOrder.findIndex(i => i == c.property) < triggerOrder.findIndex(i => i == r)) {
            return c.property;
          }
          return r;
        }, null);
        
        //Find out the cell value change with sorted (trigger) property
        const foundIdx = list.findIndex(i => i.property === property);
        const consolidatedItem = list.splice(foundIdx, 1)[0];
        //Update the consolidated item with all other values
        for (const l of list) {
          if (l.property == 'estimatedDuration') {
            consolidatedItem.data.duration = l.newValue;
          } else {
            consolidatedItem.data[l.property] = l.newValue;
          }
        }

        valueChangedlist.push(consolidatedItem);
      }

      return valueChangedlist;
    },
    navigateToNextCell(params) {
      const previousCellPosition = params.previousCellPosition;
      const nextCellPosition = params.nextCellPosition;

      if (nextCellPosition == null) {
        return params.previousCellPosition;
      }
      //Clear range selection when move focus from cell to header.
      if (nextCellPosition.rowIndex < 0 && previousCellPosition.rowIndex > -1) {
        params.api.clearRangeSelection();
      }
      // Stay in previousCell when next Cell belonged to rowSelector column.
      if (nextCellPosition.column.colId === 'rowSelector') {
        return previousCellPosition;
      }
      return nextCellPosition;
    },
    tabToNextCell(params) {
      //Fix for the bug: Multiple tabToNextCell events are fired when tab while cell editing.
      const curColId = params.previousCellPosition.column.colId;
      const columns = params.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector');
      let rowIndex = params.previousCellPosition.rowIndex;
      let index = columns.findIndex(i => i.colId == curColId);
      let nextColIdx;
      if (index == 0 && params.backwards) {
        rowIndex -= 1;
        nextColIdx = columns.length - 1;
      } else if (index == columns.length-1 && !params.backwards) {
        rowIndex += 1;
        nextColIdx = 0;
      } else if (params.backwards) {
        nextColIdx = index - 1;
      } else {
        nextColIdx = index + 1;
      }
      const column = columns[nextColIdx];

      if  (this.tabTimeoutId == null) {
        this.tabRowIndex = rowIndex;
        this.tabColumn = column;
        this.tabTimeoutId = setTimeout(() => {
          this.tabRowIndex = null;
          this.tabColumn = null;
          this.tabTimeoutId = null;
        }, 0);
        setTimeout(() => {
          params.api.clearRangeSelection();
          params.api.setFocusedCell(rowIndex, column.colId);
          params.api.addCellRange({
            rowStartIndex: rowIndex
            , rowEndIndex: rowIndex
            , columns: [column.colId]
          })
          params.api.ensureIndexVisible(rowIndex, null);
          params.api.ensureColumnVisible(column.colId, 'auto');
        }, 0);
        return params.previousCellPosition;
      }
      //Skip cell navigation. 
      //Pro: to break infinite loop
      //Cons: will cause cell focus appear in the top left html element (e.g. Company logo)
      //Hence, a really short timeout duration is used to minimise the appearance of the cell focus.
      return null;
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    onColorChange(val, color_key) {
      const coloring = this.coloring;
      for (const key of Object.keys(coloring)) {
        coloring[key] = false;
      }
      coloring[val] = true;
      this.layoutProfile[color_key] = coloring;
      this.updateLayoutProfile();
      this.gridOptions.api.redrawRows();

    },
    getRowColor(data) {
      if (data &&
        data.color &&
        this.coloring.task) {
        return data.color;
      }
      else if (data &&
        data.stageColor &&
        this.coloring.stage) {
        return getFirstColor(data.stageColor);
      }
      else if (data &&
        data.rebateColor &&
        this.coloring.rebate) {
        return getFirstColor(data.rebateColor);
      }
      else if (data &&
        data.fileColor &&
        this.coloring.file) {
        return getFirstColor(data.fileColor);
      }
      else if (data &&
        data.staffColor &&
        this.coloring.staff) {
        return getFirstColor(data.staffColor);
      }
      else if (data &&
        data.skillColor &&
        this.coloring.skill) {
        return getFirstColor(data.skillColor);
      }
      else if (data &&
        data.resourceColor &&
        this.coloring.resource) {
        return getFirstColor(data.resourceColor);
      }
    },
    cellFocused(event) {
      if (event.rowIndex != null && event.column != null) {
        this.lastFocusedCell = { rowIndex: event.rowIndex, colId: event.column.colId }  
      }
    },
    keyDownHandler(e) {
      if (!e.target.classList.contains('ag-cell') && e.target.tagName != 'BODY' || e.target.classList.contains('modal-open')) {
        return;
      }
    
      if (e.key == 'Delete' && e.target.closest('.lhs-grid') == null 
          && this.gridOptions.api.getCellRanges() != null && this.gridOptions.api.getCellRanges().length > 0) {
        if (!e.target.classList.contains('ag-cell-value')) { //Skip if the target is ag grid cell, as onCellKeyDown will be triggered by ag grid.
          //Construct the necessary payload value and call the cellKeyDown method 
          this.onCellKeyDown({ api: this.gridOptions.api, event: { keyCode: e.keyCode, key: e.key } });
        }
      } else if (e.keyCode == 36 || e.keyCode == 35) {//Home & End Key
        const api = this.gridOptions.api;
        if (api == null) {
          return;
        }
        e.stopPropagation();
        e.preventDefault();
        if (e.shiftKey) {
          return;
        }
        const rowCount = api.getDisplayedRowCount();
        if (rowCount == 0) {
          return;
        }

        const firstRowIndex = 0;
        const lastRowIndex = rowCount - 1;
        const columns = this.gridOptions.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId);
        const startColumn = columns[0];
        const lastColumn = columns[columns.length - 1];

        const rowIndex = e.keyCode == 36? firstRowIndex : lastRowIndex;
        const colId = e.keyCode == 36? startColumn : lastColumn;
        const vPosition = e.keyCode == 36? 'top' : 'bottom';

        if (e.ctrlKey) {
          this.navigateCellTo(api, rowIndex, colId, vPosition);
        } else {
          const focusedCell = api.getFocusedCell();
          if (focusedCell != null) {
            this.navigateCellTo(api, focusedCell.rowIndex, colId, null);
          }
        }
        return;
      } else if (e.keyCode == 37) { //ArrowLeft Key
        if (e.ctrlKey != true) {
          return 
        }
        e.stopPropagation();
        e.preventDefault();
        const api = this.gridOptions.api;
        const focusedCell = api.getFocusedCell();
        if (focusedCell != null) {
          const columns = this.gridOptions.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId);
          this.navigateCellTo(api, focusedCell.rowIndex, columns[0], null);
        }
      } else if (e.ctrlKey && e.keyCode == 65) {
        e.stopPropagation();
        e.preventDefault();
        const api = this.gridOptions.api;
        const totalCount = api.getDisplayedRowCount();
        if (totalCount == 0) {
          return;
        }
        const columns = this.gridOptions.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId);

        api.clearRangeSelection();
        api.addCellRange({
          rowStartIndex: this.isCompactView? 1 : 0,
          rowEndIndex: totalCount - 1,
          columns
        });
        //Set a focus cell if there is none
        const focusCell = api.getFocusedCell();
        if (focusCell != null) {
          return;
        }
        api.setFocusedCell(this.isCompactView? 1 : 0, columns[0], null);
      } else if (e.ctrlKey && e.keyCode == 68) {//'D'
        e.stopPropagation();
        e.preventDefault();
      }
    },
    navigateCellTo(api, pRowIndex, pColId, vPosition=null) {
      setTimeout(() => {
        let rowIndex = pRowIndex;
        const colId = pColId;
        api.clearRangeSelection();
        api.setFocusedCell(rowIndex, colId, null);
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        });
        api.ensureIndexVisible(rowIndex, vPosition);
        api.ensureColumnVisible(colId, 'auto');
      })
    },
    checkPermissions(defs) {
      const permList = this.$store.state.authentication.user.permissionList.filter(f => f.name === 'TASK__VIEW');
      const perms = permList.length > 0 ? 
                    permList[0] : 
                    [];
      const denyRules = perms && perms.permissionLink && perms.permissionLink.denyRules ?
                        perms.permissionLink.denyRules : [];
                        
      for (var i = defs.length - 1; i >= 0; i--) {
        const entry = defs[i].field;
        if (denyRules.includes(entry)) {
          defs.splice(i, 1);
        }
      }
      return defs;
    },
    prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage;  
      }
      return this.$t('task.grid.no_data');
    },
    showNoRowsOverlay(msg=null) {
      this.noRowsMessage = msg
      if (this.gridOptions != null && this.gridOptions.api != null) {
        this.gridOptions.api.hideOverlay()
        setTimeout(() => {
          this.gridOptions.api.showNoRowsOverlay()
        })
      }
    },
    columnDefSortFunc(a, b) {
      const pinnedA = a.pinned == 'left';
      const pinnedB = b.pinned == 'left';
      if (pinnedA == true && pinnedB != true) {
        return -1;
      } else if (pinnedB == true && pinnedA != true) {
        return 1;
      } else if (pinnedA == true && pinnedB == true) {
        return 0; //don't sort when both are pinned. use the natural sort order.
      }

      //No sorting for task column
      const groupIdA = a.groupId;
      const groupIdB = b.groupId;
      if (groupIdA != null && groupIdB == null) {
        return 0;
      } else if (groupIdB != null && groupIdA == null) {
        return 0;
      } else if (groupIdA != null && groupIdB != null) {
        return 0; //don't sort when both are task groups
      }

      const hideA = a.hide;
      const hideB = b.hide;
      if (hideA != true && hideB == true) {
        return -1;
      } else if (hideB != true && hideA == true) {
        return 1;
      } else if (hideA != true && hideB != true) {
        return 0; //don't sort when both are visible
      }
      
      const nameA = a.headerName != null? a.headerName.toLowerCase() : a.headerName;
      const nameB = b.headerName != null? b.headerName.toLowerCase() : b.headerName;
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    },
    redrawViewport(params, firstRow, lastRow) {
      const self = this;
      const ids = [];
      if (params.api == null) {
        return;
      }
      for (let idx = firstRow; idx <= lastRow; idx++) {
        const row = params.api.getDisplayedRowAtIndex(idx);
        if (row && row.data && row.data.uuId !== 'ROOT' && !(row.data.uuId in taskLoaded)) {
          ids.push(row.data.uuId);
        }
      }
      
      if (ids.length !== 0) {
        taskViewService.listTree(
                        { start: 0, limit: -1, filter: ids, entity: 'TASK', sortByParent: !this.flatList }
                        , self.id, self.columnCache.filter(i => !i.startsWith(self.taskColPrefix)), false, self.customFields
                        , { skillCustomFields: this.skillCustomFields, resourceCustomFields: this.resourceCustomFields.filter(rc => !rc.entity || rc.entity.includes('TASK-RESOURCE')), noteCustomFields: this.noteCustomFields })
        .then(response => {
          const nodes = [];
          for (const row of response.data) {
            const rowNode = params.api.getRowNode(row.uuId);
            if (rowNode == null) {
              continue;
            }
            for (const key of Object.keys(row)) {
              rowNode.data[key] = row[key];
            }
            taskLoaded[row.uuId] = true;
            rowNode.rowHeight = null;
            nodes.push(rowNode);
          }
          params.api.resetRowHeights();
          params.api.redrawRows({rowNodes: nodes});
          //params.api.refreshCells();
        });
      }
    },
    async updateTaskColTasks(api, colId, payload) {
      const newName = payload.name;
      const newColor = payload.color;
      const newSkills = payload.skills != null ? payload.skills : [];
      const newStaff = payload.staff != null ? payload.staff : [];
      const newResources = payload.resources != null ? payload.resources : [];
      const newRebates = payload.rebates != null ? payload.rebates : [];
      const newTags = payload.tags != null ? payload.tags : [];
      const taskIds = [];
      api.forEachNode((rowNode) => {
        if (rowNode.data[colId] != null && rowNode.data[colId].uuId != null) {
          taskIds.push(rowNode.data[colId].uuId);
        }
      });

      if (taskIds.length > 0) {
        const allowColorUpdate = this.allowTaskUpdate(['color']);
        const allowSkillsUpdate = this.allowTaskSkillsUpdate();
        const allowStaffUpdate = this.allowTaskStaffUpdate();
        const allowResourcesUpdate = this.allowTaskResourcesUpdate();
        const allowRebatesUpdate = this.allowTaskRebatesUpdate();
        const allowTagsUpdate = this.allowTaskTagsUpdate();
        
        const requests = taskIds.map(i => {
          const data = {
            uuId: i,
            name: newName,
            color: newColor
          }
          if (!allowColorUpdate) {
            delete data.color;
          }
          return {
            method: 'PUT',
            invoke: '/api/task/update',
            body: [data],
            vars: [],
            note: 'taskUpdate_colEdit'
         }
        });

        const rData = await taskService.queryLinkResources(taskIds).then(data => {
          return data;
        })
        .catch(() => {
          return [];
        });

        let request = null;
        for (const d of rData) {
          if (allowTagsUpdate) {
            request = await this.updateTags(d.uuId, d.tags.map(i => i.name), newTags.map(i => i.name));
            if (request.length > 0) {
              requests.push(...request);
            }
          }
          if (allowStaffUpdate) {
            request = TaskViewRequestGenerator.updateStaff(d.uuId, d.staff, newStaff);
            if (request.length > 0) {
              requests.push(...request);
            }
          }
          if (allowResourcesUpdate) {
            request = TaskViewRequestGenerator.updateResource(d.uuId, d.resources, newResources, { customFields: this.resourceCustomFields });
            if (request.length > 0) {
              requests.push(...request);
            }
          }
          if (allowRebatesUpdate) {
            request = TaskViewRequestGenerator.updateRebate(d.uuId, d.rebates, newRebates);
            if (request.length > 0) {
              requests.push(...request);
            }
          }
          if (allowSkillsUpdate) {
            request = TaskViewRequestGenerator.updateSkill(d.uuId, d.skills, newSkills, { customFields: this.skillCustomFields });
            if (request.length > 0) {
              requests.push(...request);
            }
          }
        }
        return Promise.resolve(requests);
      } else {
        return Promise.resolve([]);
      }
    },
    async confirmApplyChangeToTaskOk() {
      const changedChildren = this.confirmApplyChangeToTaskPayload.changedChildren;
      const api = this.gridOptions.api;
      if (api != null) {
        this.showInProgress(this.$t('task.progress.updating_tasks'));
        this.actionProcessing = true;
        const requests = [];
        for (let i = 0, len = changedChildren.length; i < len; i++) {
          const requestList = await this.updateTaskColTasks(api, changedChildren[i].colId, changedChildren[i].newValue);
          if (requestList.length > 0) {
            requests.push(...requestList);
          }
        }
        
        let result = null;
        if (requests.length == 0) {
          result = {
            alertState: alertStateEnum.SUCCESS,
            alertMsg: this.$t('task.update_plural')
          }
        } else {
          requests.unshift({
            'note': 'Disable macros',
            'invoke': 'PUT /api/system/features?entity=macros&action=DISABLE'
          });
          requests.unshift({
            'note': 'Disable project scheduling',
            'invoke': 'PUT /api/system/features?entity=scheduling&action=DISABLE'
          });
          if (this.project != null && this.project.autoScheduling) {
            requests.unshift({
              method: 'PUT',
              invoke: `/api/project/update`,
              body: [{
                uuId: this.id
                , autoScheduling: false
              }],
              vars: [],
              note: `Task__project(${this.id})__autoSCheduling(false)`
            });
          
            requests.push({
              method: 'PUT',
              invoke: `/api/project/update`,
              body: [{
                uuId: this.id
                , autoScheduling: true
              }],
              vars: [],
              note: `Task__project(${this.id})__autoSCheduling(true)`
            });
          }
          
          result = await compositeService.exec(requests)
          .then(response => {
            if (response.status == 207) {
              const data = response.data[response.data.jobCase];
              const errorClues = data.filter(i => i.clue != 'OK' && i.clue != 'Already_have_edge' && i.clue != 'Nothing_to_do');
              if (errorClues.length > 0) {
                return {
                  alertState: alertStateEnum.WARNING,
                  alertMsg: this.$t('task.update_partial_plural')
                };
              }
            }
            return {
              alertState: alertStateEnum.SUCCESS,
              alertMsg: this.$t('task.update_plural')
            };
          })
          .catch(() => ({
            alertState: alertStateEnum.ERROR,
            alertMsg: this.$t('task.error.failed_to_update_tasks')
          }));
        }
        
        this.inProgressShow = false;
        this.actionProcessing = false;

        this.resetAlert({ msg: result.alertMsg, alertState: result.alertState });
      }
      this.updateTaskGroupProfile();
    },
    confirmApplyChangeToTaskCancel() {
      this.updateTaskGroupProfile();
    },
    updateTaskGroupProfile() {
      if (this.confirmApplyChangeToTaskIsColumnEdit == true) {
        //Update column Def headerName and colId values.
        const groupId = this.confirmApplyChangeToTaskPayload.payload.groupId;
        const profId = this.confirmApplyChangeToTaskPayload.payload.uuId;

        const oldName = this.confirmApplyChangeToTaskPayload.changedChildren[0].oldName;
        const colId = this.confirmApplyChangeToTaskPayload.changedChildren[0].colId;
        const changedChild = this.confirmApplyChangeToTaskPayload.changedChildren[0].newValue;
        
        const newName = changedChild.name;
        const newColor = changedChild.color;
        const newSkills = changedChild.skills != null ? changedChild.skills : [];
        const newStaff = changedChild.staff != null ? changedChild.staff : [];
        const newResources = changedChild.resources != null ? changedChild.resources : [];
        const newRebates = changedChild.rebates != null ? changedChild.rebates : [];
        const newTags = changedChild.tags != null ? changedChild.tags : [];
        
        const groups = this.layoutProfile.compactTaskColumns.filter(i => i.groupId != null);
        const native = groups.find(i => i.groupId == groupId).children.find(i => i.colId).native;

        const regex = new RegExp(`^${this.taskColPrefix}([A-Za-z0-9-]+)_([0-9][0-9])_(.*)$`);
        
        //Apply it to the affected child of all groups (including hidden groups).
        for (const grp of groups) {
          const children = grp.children;
          let target = null;

          if (grp.groupId === groupId) {
            target = children.find(i => i.colId === colId);
          } else {
            //If original col is not native, search non native cols in other groups' children first. If no, then search native cols.
            if (!native) {
              target = children.find(i => {
                // return i.native == false && i.colId.endsWith(oldName)
                if (i.native) {
                  return false;
                }
                const r = i.colId.match(regex);
                if (r == null || r[3] != oldName) {
                  return false;
                }
                return true;
              });
            }
            if (target == null) {
              // target = children.find(i => i.colId.endsWith(oldName));
              target = children.find(i => {
                const r = i.colId.match(regex);
                if (r == null || r[3] != oldName) {
                  return false;
                }
                return true;
              })
            }
          }

          if (target == null) {
            continue;
          }
          target.headerName = newName;
          target.color = newColor;
          target.skills = newSkills;
          target.staff = newStaff;
          target.resources = newResources;
          target.rebates = newRebates;
          target.tags = newTags;
          
          //1) old Name: rearrange sequence of all columnDef with same headerName;
          const oldNameCols = children.filter(i => i.headerName == oldName);
          for (const [index, col] of oldNameCols.entries()) {
            const nextSequence = index;
            col.field = col.colId = `${this.taskColPrefix}${profId}_${nextSequence < 10? '0'+nextSequence : nextSequence}_${col.headerName}`;
          }

          //2) new Name: rearrange sequence of all columnDef with same headerName;
          const newNameCols = children.filter(i => i.headerName == newName);
          for (const [index, col] of newNameCols.entries()) {
            const nextSequence = index;
            col.field = col.colId = `${this.taskColPrefix}${profId}_${nextSequence < 10? '0'+nextSequence : nextSequence}_${col.headerName}`;
          }
        }
        

        this.layoutProfile.compactTaskColumns = this.sanitizeCompactTaskColumns(this.layoutProfile.compactTaskColumns);
        this.updateLayoutProfile({ successCallback: () => {
            this.forceReload = true;
            taskLoaded = {};
            this.loadColumnSettings();
            this.reloadTasks(); 
          }
        });
      } else {
        this.forceReload = true;
        taskLoaded = {};
        this.updateExistingTaskGroup(this.confirmApplyChangeToTaskPayload.payload, { reloadTasks: true });
        this.confirmApplyChangeToTaskPayload = null;
      }
    },
    allowTaskAdd(properties=[]) {
      return Array.isArray(properties) && properties.length > 0? this.canAdd('TASK', properties) : this.canAdd('TASK');
    },
    allowTaskUpdate(properties=[]) {
      return Array.isArray(properties) && properties.length > 0? this.canEdit('TASK', properties) : this.canEdit('TASK');
    },
    allowTaskSkillsUpdate() {
      return this.canView('SKILL') && this.canView('TASK', ['SKILL']) 
      && this.canEdit('TASK', ['SKILL']);
    },
    allowTaskStaffUpdate() {
      return this.canView('STAFF') && this.canView('TASK', ['STAFF']) 
      && this.canEdit('TASK', ['STAFF']);
    },
    allowTaskResourcesUpdate() {
      return this.canView('RESOURCE') && this.canView('TASK', ['RESOURCE']) 
      && this.canEdit('TASK', ['RESOURCE']);
    },
    allowTaskRebatesUpdate() {
      return this.canView('REBATE') && this.canView('TASK', ['REBATE']) 
      && this.canEdit('TASK', ['REBATE']);
    },
    allowTaskTagsUpdate() {
      return this.canView('TAG') && this.canView('TASK', ['TAG'])
      && this.canAdd('TAG') && this.canEdit('TASK', ['TAG']);
    },
    onBadgeFilterModified(filter) {
      this.badgeFilterFocus = true; //Pin the badgeFilter when a change is made.
      const removedFilterFields = this.badgeFilters.filter(i => filter.find(j => j.field == i.field) == null).map(i => i.field);
      this.badgeFilters = filter;
      
      for (const f of filter) {
        if (Object.hasOwn(this.badgeFilterFieldValues, f.field)) {
          if (Array.isArray(f.value) && f.value.length > 0) {
            this.badgeFilterFieldValues[f.field].forEach(i => {
              i.checked = f.value.find(j => j.text.localeCompare(i.text, undefined, { sensitivity: 'base' }) == 0) != null;
            });
          } else {
            this.badgeFilterFieldValues[f.field].forEach(i => {
              if (i.checked) {
                i.checked = false;
              }
            });
          }
        }
      }
      //reset checked state for the previously selected but now removed filter
      if (removedFilterFields.length > 0) {
        for (const f of removedFilterFields) {
          if (Object.hasOwn(this.badgeFilterFieldValues, f)) {
            this.badgeFilterFieldValues[f].forEach(i => {
              if (i.checked) {
                i.checked = false;
              }
            });
           
          }
        }
      }
      this.badgeFilterFieldValues = JSON.parse(JSON.stringify(this.badgeFilterFieldValues)); //Force triggering vue reactivity
      this.layoutProfile['tasksBadgeFilter'] = filter;
      this.changeFilter(this.filterText);
    },
    onBadgeFilterDropdownHide(bvEvent) {
      if (this.badgeFilterFocus || this.badgeFilterModalOpened != 'close') {
        bvEvent.preventDefault();
      }
    },
    onBadgeFilterEnter() {
      this.$refs.badgeFilter.visible = true;
    },
    onBadgeFilterOver(evt) {
      if (this.$refs.badgeFilter?.$el.id != null && evt.target.closest('.dropdown-toggle') != null && this.badgeFilterModalOpened != 'open' && this.badgeFilterFocus) {
        const id = evt.target.closest('.dropdown-toggle').id;
        if (id != null && id.startsWith(this.$refs.badgeFilter?.$el.id)) {
          this.badgeFilterFocus = false; 
        }
      }
    },
    onBadgeFilterLeave() {
      if (!this.badgeFilterFocus) {
        this.$refs.badgeFilter.visible = false;
      }
    },
    onBadgeFilterModalOpened() {
      this.badgeFilterModalOpened = 'open';
      this.badgeFilterFocus = true;
    },
    onBadgeFilterModalClosed() {
      this.badgeFilterModalOpened = 'signaled-close';
    },
    toggleBadgeFilterFocus(evt) {
      if (this.badgeFilterModalOpened == 'signaled-close') {
        this.badgeFilterModalOpened = 'close';
      } else if (this.badgeFilterFocus && this.badgeFilterModalOpened == 'close' && (this.$refs.badgeFilter?.$el?.id == null || evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) == null)) {
        this.badgeFilterFocus = false;
      } else if (!this.badgeFilterFocus && evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) != null) {
        this.badgeFilterFocus = true;
      }
    },
    handleBadgeFilterEscapeKeyDown(e) {
      const evt = e || window.event;
      if (evt.keyCode === 27 && this.badgeFilterFocus) {
        this.badgeFilterFocus = false;
        this.badgeFilterModalOpened = 'close';
        this.closePriorityNavDropdown = true;
      }
    },
    onPriorityNavMouseOverOrTouchEnd(evt) {
      if ((this.$refs.badgeFilter?.$el.id == null || evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) == null)) {
        this.badgeFilterFocus = false;
      }
    },
    onBadgeFilterFetchOptions(payload) {
      let serviceCall = null;
      if (payload.field == 'taskName') {
        serviceCall = taskViewService.getUniqueTaskName(this.id)
      } else if (payload.field == 'identifier') {
        serviceCall = taskViewService.getUniqueIdentifier(this.id)
      } else if (payload.field == 'parentTasks') {
        serviceCall = taskViewService.getUniqueParentTasks(this.id)
      } else if (payload.field == 'rebateName') {
        serviceCall = taskViewService.getUniqueRebateName(this.id)
      } else if (payload.field == 'resourceName') {
        serviceCall = taskViewService.getUniqueResourceName(this.id)
      } else if (payload.field == 'skillName') {
        serviceCall = taskViewService.getUniqueSkillName(this.id)
      } else if (payload.field == 'staffName') {
        serviceCall = taskViewService.getUniqueStaffName(this.id)
      } else if (payload.field == 'stageName') {
        serviceCall = taskViewService.getUniqueStageName(this.id)
      } else if (payload.field == 'tagName') {
        serviceCall = taskViewService.getUniqueTagName(this.id)
      } else if (payload.field == 'type') {
        serviceCall = taskViewService.getUniqueType(this.id)
      } else if (payload.field == 'priority') {
        serviceCall = taskViewService.getUniquePriority(this.id)
      } else if (payload.field == 'complexity') {
        serviceCall = taskViewService.getUniqueComplexity(this.id)
      } else if (this.customFields.find(i => i.name == payload.field) != null) {
        serviceCall = taskViewService.getTaskUniqueCustomFieldName(this.id, payload.field)
      }
      if (serviceCall != null) {
        serviceCall
        .then(data => {
          if (data.length > 0 && this.badgeFilters.length > 0) {
            const found = this.badgeFilters.find(i => i.field == payload.field);
            if (found != null && Array.isArray(found.value) && found.value.length > 0) {
              this.$set(this.badgeFilterFieldValues, payload.field, data.map(i => ({ 
                text: payload.field === 'type' && i === 'Project' ? 'Summary Task' : !i ? '(Empty)' : i
                , checked: found.value.find(j => j.text != null && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) != null
              })));
              return;
            }
          } 
          this.$set(this.badgeFilterFieldValues, payload.field, data.map(i => ({ text: payload.field === 'type' && i === 'Project' ? 'Summary Task' : !i ? '(Empty)' : i, checked: false })));
        });
      } else {
        this.badgeFilterFieldValues[payload.field] = [];
      }
    },
    toggleFlatListHandler() {
      this.flatList = !this.flatList;
      this.layoutProfile.flatList = this.flatList;
      this.updateLayoutProfile();
      
      this.gridOptions.api.setRowData([]);
      this.forceReload = true;
      this.loadTasks(this.id);
    },
    doAllParentsPass(node) {
      const doesPass = this.searchData.find(d => d.uuId === node.data.uuId);
      if (!doesPass) {
        return false;
      }
      
      if (node.parent.id !== 'ROOT_NODE_ID') {
        return this.doAllParentsPass(node.parent);
      }
      return true;
    },
    doAllChildrenPass(node) {
      let passed = 0;
      let failed = 0;
      
      // check this node
      if (this.searchData.find(d => d.uuId === node.data.uuId) &&
          this.doAllParentsPass(node)) {
        passed++;
      }
      else {
        failed++;
      }
      
      if (node.childrenAfterGroup && node.childrenAfterGroup.length > 0) {
        for (const child of node.childrenAfterGroup) {
          const result = this.doAllChildrenPass(child);
          passed += result.passed;
          failed += result.failed;
        }
      }
      return { passed: passed, failed: failed };
    },
    onInfoOver(index) {
      profileService.nodeList(this.views[index].uuId).then((response) => {
        this.views[index].owner = response.data.resultList.filter(v => this.views[index].editingPermissions.includes(v.uuId)).map(r => { return r.name }).join(", ");
        this.$set(this.showInfo, index, true);
      });
    },
    onInfoLeave(index) {
      this.$set(this.showInfo, index, false);
    },
    getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    updateGridHeight(value=null) {
      this.gridHeight = `calc(100vh - 195px - ${value != null? value+16 : 0}px)`;
    },
    isEllipsisActive(text) {
      return isEllipsisActive(text, this);
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-toolbar {

  .btn {
    font-size: 1rem;
  }
  ul {
    margin: 0;
  }
}

.flex-container {
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
}

.flex-grid-container {
  flex: 1;
}

.task-grid-height {
  height: calc(100vh - 195px);
  //min-height: 300px;
}

.action-v-divider {
  cursor: default;
  margin: 0 6px;
}

.task-summary-list {
  list-style-type: none;
  max-height: 300px;
  overflow-y: auto;
}

.task-summary-list-item {
  margin-bottom: 10px;
}

.task-summary-title {
  font-weight: bold;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.active {
  color: var(--projectal-orange)
}

.search-append {
  line-height: 11px;
  font-size: 0.8rem !important;
}

.grid-toolbar .btn.btn-secondary.search-append-bg {
  margin: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: var(--form-control-addon-bg) !important;
}

.search-input {
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>
