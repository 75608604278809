<template>
  <nav class="sidebar-nav" @scroll="scrolled">
    <ul class="nav">
      <template v-for="(item, index) in filterPermitted(navItems)">
        <template v-if="item.title">
          <SidebarNavTitle :key="index" :name="$t(item.name)" :classes="item.class" :wrapper="item.wrapper"/>
        </template>
        <template v-else-if="item.divider">
          <SidebarNavDivider :key="index" :classes="item.class"/>
        </template>
        <template v-else-if="item.label">
          <SidebarNavLabel :key="index" :name="$t(item.name)" :url="item.url" :icon="item.icon" :label="item.label" :classes="item.class"/>
        </template>
        <template v-else>
          <template v-if="item.children">
            <!-- First level dropdown -->
            <template v-if="item.url">
              <SidebarNavLinkDropdown :profileid="item.profileid" :key="index " :name="$t(item.name)" :url="item.url" :icon="item.icon" :regex="item.regex" :keepopen="item.keepopen" :actions="item.actions"  :emitevent="item.emitevent" :params="item.params">
                <template  v-slot:default="slotProps">
                  <template v-for="(childL1, index1) in filterPermitted(item.children)">
                    <template v-if="childL1.children">
                      <!-- Second level dropdown -->
                      <SidebarNavDropdown :key="index1" :name="childL1.name" :url="childL1.url" :icon="childL1.icon">
                        <li :key="index2" class="nav-item" v-for="(childL2, index2) in childL1.children">
                          <SidebarNavLink :name="childL2.name" :url="childL2.url" :icon="childL2.icon" :badge="childL2.badge" :variant="childL2.variant" :attributes="childL2.attributes" :emitevent="childL2.emitevent" :params="childL2.params" :actions="childL2.actions"/>
                        </li>
                      </SidebarNavDropdown>
                    </template>
                    <template v-else>
                      <SidebarNavItem :key="index1" :classes="item.class">
                        <SidebarNavLink v-if="slotProps.id" :name="$t(childL1.name)" :url="`${childL1.url}/${slotProps.id}`" :icon="childL1.icon" :badge="childL1.badge" :variant="childL1.variant" :attributes="childL1.attributes" :emitevent="childL1.emitevent" :params="childL1.params" :actions="childL1.actions" />
                        <SidebarNavLink :name="childL1.no_translate ? childL1.name : $t(childL1.name)" :url="childL1.url" :icon="childL1.icon" :badge="childL1.badge" :variant="childL1.variant" :attributes="childL1.attributes" :emitevent="childL1.emitevent" :params="childL1.params" :actions="childL1.actions" v-else/>
                      </SidebarNavItem>
                    </template>
                  </template>
                </template>
              </SidebarNavLinkDropdown>
            </template>
            <template v-else>
              <SidebarNavDropdown :key="index" :name="$t(item.name)" :url="item.url" :icon="item.icon" :actions="item.actions" :emitevent="item.emitevent" :params="item.params">
                <template v-for="(childL1, index1) in filterPermitted(item.children)">
                  <template v-if="childL1.children">
                    <!-- Second level dropdown -->
                    <SidebarNavDropdown :key="index1" :name="$t(childL1.name)" :url="childL1.url" :icon="childL1.icon">
                      <li :key="index2" class="nav-item" v-for="(childL2, index2) in filterPermitted(childL1.children)">
                        <SidebarNavLink :name="$t(childL2.name)" :url="childL2.url" :icon="childL2.icon" :badge="childL2.badge" :variant="childL2.variant" :attributes="childL2.attributes" />
                      </li>
                    </SidebarNavDropdown>
                  </template>
                  <template v-else>
                    <SidebarNavItem :key="index1" :classes="item.class">
                      <SidebarNavLink :name="$t(childL1.name)" :url="childL1.url" :icon="childL1.icon" :badge="childL1.badge" :variant="childL1.variant" :attributes="childL1.attributes"/>
                    </SidebarNavItem>
                  </template>
                </template>
              </SidebarNavDropdown>
            </template>
          </template>
          <template v-else>
            <SidebarNavItem :key="index" :classes="item.class">
              <SidebarNavLink :name="$t(item.name)" :url="item.url" :icon="item.icon" :badge="item.badge" :variant="item.variant" :attributes="item.attributes" :actions="item.actions"  :emitevent="item.emitevent" :params="item.params"/>
            </SidebarNavItem>
          </template>
        </template>
      </template>
    </ul>
    <slot></slot>
  </nav>
</template>

<script>
import SidebarNavDivider from './SidebarNavDivider'
import SidebarNavDropdown from './SidebarNavDropdown'
import SidebarNavLink from './SidebarNavLink'
import SidebarNavTitle from './SidebarNavTitle'
import SidebarNavItem from './SidebarNavItem'
import SidebarNavLabel from './SidebarNavLabel'
import SidebarNavLinkDropdown from './SidebarNavLinkDropdown'

export default {
  name: 'SidebarNav',
  components: {
    SidebarNavDivider,
    SidebarNavDropdown,
    SidebarNavLink,
    SidebarNavTitle,
    SidebarNavItem,
    SidebarNavLabel,
    SidebarNavLinkDropdown
  },
  props: {
    navItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data () {
    return {}
  },
  computed: {
  },
  methods: {
    scrolled() {
      const nav = document.querySelector('.sidebar-nav');
      localStorage.scrollTop = nav.scrollTop;
    },
    filterPermitted(items) {
      return items.filter(i => !i.permission || this.hasPermission(i.permission));
    }
  }
}
</script>

<style scoped lang="css">
  
  ::v-deep .indent-menu svg {
    margin-left: 25px !important;
  }

  ::v-deep .sidebar-minimized .indent-menu-text svg {
    margin-left: 15px !important;
  }
  
  ::v-deep .sidebar .indent-menu-text .nav-link svg.nav-icon {
    margin-left: 25px;
  }
      
  ::v-deep .side-link-active .nav-dropdown-items {
    max-height: unset;
  }
  
</style>
