import Vue from 'vue';
import Vuex from 'vuex';

import alert from './alert.module';
import { authentication } from './authentication.module';
import breadcrumb from './breadcrumb.module';
import { data } from './data.module';
import company from './company.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alert,
    authentication,
    breadcrumb,
    data,
    company
  },
});
