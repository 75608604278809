// import * as moment from 'moment-timezone';
import moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;

export function nextWorkingDay(value, noOfDay, isBackward=false, calendar=null) {
  const  operator = isBackward? 'subtract' : 'add';
  const nextDay = (date, dayDiff) => date.clone()[operator](dayDiff, 'days');
  let date = value.utc().clone();
  let durationValue = noOfDay;
  while(durationValue > 0) {
    date = nextDay(date, 1);
    if(isWorkingDay(date, calendar)) {
      durationValue--;
    }
  }
  return date.clone();
}

export function earliestWorkHour(date, calendar=null, auto=true) {
  if(!auto) {
    return '00:00:00';
  }
  if(calendar != null) {
    return workHour(calendar, date, null, true);
  } else {
    return '09:00:00';
  }
}

export function isWorkingDay(date, calendar=null) {
  if(calendar != null) {
    return workHour(calendar, date, null, true) != null;
  } else {
    return true;
  }
}

/**
 * Return work hour in string format 'HH:mm' or 'HH:mm:ss' of the given date
 * @param {Date} date Date used to find out the work hour
 * @param {*} defaultValue  Fallback value when no available work hour found. Usually means the provided date is non working day.
 * @param {Boolean} isEarliest Set true to return earliest work hour; Set false to return latest work hour.
 */
export function workHour(calendar, date, defaultValue=null, isEarliest=true) {
  const _cal = calendar != null? calendar : [];
  const targetDate = moment.utc(date);

  if(_cal.length == 0) {
    return defaultValue;
  }
  //Looking priority:
  // Leave > Working > day of week.

  //Looking for leave exception
  const leaves = _cal['Leave'] || [];
  const leaveApplied = leaves.some(i => {
    if(i.type === 'Leave') {
      const target = moment.utc(date).valueOf();
      const startLeaveDate = moment.utc(i.startDate).valueOf();
      const endLeaveDate = moment.utc(i.endDate).valueOf();
      return target >= startLeaveDate && target <= endLeaveDate;
    }
    return false;
  });
  //If leave exception exists, return defaultValue as no work hour will be considered.
  if(leaveApplied) {
    return defaultValue;
  }

  //Looking for working exception
  const workings = _cal['Working'] || [];
  const workingException = workings.find(i => {
    if(i.type === 'Working') {
      const target = moment.utc(date).valueOf();
      const startWorkingDate = moment.utc(i.startDate).valueOf();
      const endWorkingDate = moment.utc(i.endDate).valueOf();
      return target >= startWorkingDate && target <= endWorkingDate;
    }
  });
  //If working exception exists, return startHour of the exception.
  if(workingException) {
    return isEarliest?workingException.startHour:workingException.endHour;
  }

  //Looking for standard day Of week
  const weekday = _cal[targetDate.locale('en').format('dddd')] || [];
  const compareFunc = (target, item) => isEarliest? target > item : target < item;
  const propKey = isEarliest? 'startHour' : 'endHour';
  const result = weekday.length > 0? 
    weekday
    .filter(i => i[propKey])
    .map(i => i[propKey] || null)
    .reduce((t,i) => t? (i && compareFunc(t, i)? i: t) : i, defaultValue) : 
    defaultValue;

  return result;
}

/**
 * Return a list of work hours of the provided date. Empty list will be returned when the provided date is non-working day.
 * @param {Moment} date DateObject Date used to find out the work hours of that day.
 * @param {Object} calendar An object contains calendar information.
 */
export function workHours(calendar, date) {
  const _cal = calendar != null? calendar : [];
  const targetDate = date;
  
  //Looking priority:
  // Leave > Working > day of week.

  //Looking for leave exception
  const leaves = _cal['Leave'] || [];
  const leaveApplied = leaves.some(i => {
    if(i.type === 'Leave') {
      const target = targetDate.valueOf();
      const startLeaveDate = moment.utc(i.startDate).valueOf();
      const endLeaveDate = moment.utc(i.endDate).valueOf();
      return target >= startLeaveDate && target <= endLeaveDate;
    }
    return false;
  });
  //If leave exception exists, return defaultValue as no work hour will be considered.
  if(leaveApplied) {
    return [];
  }

  //Looking for working exception
  const workings = _cal['Working'] || [];
  const workingException = workings.find(i => {
    if(i.type === 'Working') {
      const target = targetDate.valueOf();
      const startWorkingDate = moment.utc(i.startDate).valueOf();
      const endWorkingDate = moment.utc(i.endDate).valueOf();
      return target >= startWorkingDate && target <= endWorkingDate;
    }
  });
  //If working exception exists, return startHour of the exception.
  if(workingException) {
    return [workingException];
  }

  //Looking for standard day Of week
  const weekday = _cal[targetDate.locale('en').format('dddd')] || [];
  return weekday
    .filter(i => i.startHour)
    .sort((firstEl, secondEl) => firstEl.startHour > secondEl.startHour? 1 : firstEl.startHour < secondEl.startHour? -1 : 0);
}

export function maxDuratioUnit(unit1, unit2) {
  const order = ['Y','M','W','D','h','m'];
  const index1 = order.findIndex(i => i === unit1);
  const index2 = order.findIndex(i => i === unit2);
  return index1 < index2? unit1 : unit2;
}

export function getCharCodeFromEvent(event) {
  event = event || window.event;
  return typeof event.which === 'undefined' ? event.keyCode : event.which;
}

function deleteOrBackspace(event) {
  return (
    [KEY_DELETE, KEY_BACKSPACE].indexOf(getCharCodeFromEvent(event)) >
    -1
  );
}

function isLeftOrRight(event) {
  return [37, 39].indexOf(getCharCodeFromEvent(event)) > -1;
}

export function onDurationKeyDown(event, finished) {
  if (isLeftOrRight(event) || deleteOrBackspace(event)) {
    event.stopPropagation();
    return;
  }

  if (!finished && !isKeyPressedAllowed(event)) {
    if (event.preventDefault) event.preventDefault();
  }
}

export function onDurationKeyUp(event, value) {
  const target = event.currentTarget;
  var val = target.value;
  if (target.selectionStart !== target.selectionEnd) {
    val = val.slice(target.selectionStart, target.selectionEnd - target.selectionStart);
  }
  
  if (/^[0-9.dwy]+$/.test(val)) {
    return value.toUpperCase();
  }
  else if (/^[0-9.H]+$/.test(val)) {
    return value.toLowerCase();
  }
  return value;
}

function isKeyPressedAllowed(event) {
  const charStr = event.key;
  const target = event.currentTarget;
  var val = target.value;
  if (target.selectionStart !== target.selectionEnd) {
    if (target.selectionStart === 0 && target.selectionEnd === val.length) {
      val = '';
    }
    else if (target.selectionStart !== 0) {
      val = val.substr(0, target.selectionStart) + val.substr(target.selectionEnd, val.length);
    }
  }
  
  return isCharAllowed(charStr, val, target.selectionStart - 1);
}

function isCharAllowed(charStr, val, inputIndex) {
  const unitIndex = val.search(/[hdwmy]$/i);
  const dotIndex = val.search(/[.]/);
  return (/^[0-9]+$/i.test(charStr) && (inputIndex < unitIndex || unitIndex === -1)) ||
  (/^[.]+$/i.test(charStr) && (dotIndex === -1 && (inputIndex < unitIndex || unitIndex === -1))) || 
         (/^[hdwmy]+$/i.test(charStr) && /^[0-9.]+$/i.test(val));
}