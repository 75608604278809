var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "sidebar-nav", on: { scroll: _vm.scrolled } },
    [
      _c(
        "ul",
        { staticClass: "nav" },
        [
          _vm._l(_vm.filterPermitted(_vm.navItems), function (item, index) {
            return [
              item.title
                ? [
                    _c("SidebarNavTitle", {
                      key: index,
                      attrs: {
                        name: _vm.$t(item.name),
                        classes: item.class,
                        wrapper: item.wrapper,
                      },
                    }),
                  ]
                : item.divider
                ? [
                    _c("SidebarNavDivider", {
                      key: index,
                      attrs: { classes: item.class },
                    }),
                  ]
                : item.label
                ? [
                    _c("SidebarNavLabel", {
                      key: index,
                      attrs: {
                        name: _vm.$t(item.name),
                        url: item.url,
                        icon: item.icon,
                        label: item.label,
                        classes: item.class,
                      },
                    }),
                  ]
                : [
                    item.children
                      ? [
                          item.url
                            ? [
                                _c("SidebarNavLinkDropdown", {
                                  key: index,
                                  attrs: {
                                    profileid: item.profileid,
                                    name: _vm.$t(item.name),
                                    url: item.url,
                                    icon: item.icon,
                                    regex: item.regex,
                                    keepopen: item.keepopen,
                                    actions: item.actions,
                                    emitevent: item.emitevent,
                                    params: item.params,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (slotProps) {
                                          return [
                                            _vm._l(
                                              _vm.filterPermitted(
                                                item.children
                                              ),
                                              function (childL1, index1) {
                                                return [
                                                  childL1.children
                                                    ? [
                                                        _c(
                                                          "SidebarNavDropdown",
                                                          {
                                                            key: index1,
                                                            attrs: {
                                                              name: childL1.name,
                                                              url: childL1.url,
                                                              icon: childL1.icon,
                                                            },
                                                          },
                                                          _vm._l(
                                                            childL1.children,
                                                            function (
                                                              childL2,
                                                              index2
                                                            ) {
                                                              return _c(
                                                                "li",
                                                                {
                                                                  key: index2,
                                                                  staticClass:
                                                                    "nav-item",
                                                                },
                                                                [
                                                                  _c(
                                                                    "SidebarNavLink",
                                                                    {
                                                                      attrs: {
                                                                        name: childL2.name,
                                                                        url: childL2.url,
                                                                        icon: childL2.icon,
                                                                        badge:
                                                                          childL2.badge,
                                                                        variant:
                                                                          childL2.variant,
                                                                        attributes:
                                                                          childL2.attributes,
                                                                        emitevent:
                                                                          childL2.emitevent,
                                                                        params:
                                                                          childL2.params,
                                                                        actions:
                                                                          childL2.actions,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "SidebarNavItem",
                                                          {
                                                            key: index1,
                                                            attrs: {
                                                              classes:
                                                                item.class,
                                                            },
                                                          },
                                                          [
                                                            slotProps.id
                                                              ? _c(
                                                                  "SidebarNavLink",
                                                                  {
                                                                    attrs: {
                                                                      name: _vm.$t(
                                                                        childL1.name
                                                                      ),
                                                                      url: `${childL1.url}/${slotProps.id}`,
                                                                      icon: childL1.icon,
                                                                      badge:
                                                                        childL1.badge,
                                                                      variant:
                                                                        childL1.variant,
                                                                      attributes:
                                                                        childL1.attributes,
                                                                      emitevent:
                                                                        childL1.emitevent,
                                                                      params:
                                                                        childL1.params,
                                                                      actions:
                                                                        childL1.actions,
                                                                    },
                                                                  }
                                                                )
                                                              : _c(
                                                                  "SidebarNavLink",
                                                                  {
                                                                    attrs: {
                                                                      name: childL1.no_translate
                                                                        ? childL1.name
                                                                        : _vm.$t(
                                                                            childL1.name
                                                                          ),
                                                                      url: childL1.url,
                                                                      icon: childL1.icon,
                                                                      badge:
                                                                        childL1.badge,
                                                                      variant:
                                                                        childL1.variant,
                                                                      attributes:
                                                                        childL1.attributes,
                                                                      emitevent:
                                                                        childL1.emitevent,
                                                                      params:
                                                                        childL1.params,
                                                                      actions:
                                                                        childL1.actions,
                                                                    },
                                                                  }
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                ]
                                              }
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ]
                            : [
                                _c(
                                  "SidebarNavDropdown",
                                  {
                                    key: index,
                                    attrs: {
                                      name: _vm.$t(item.name),
                                      url: item.url,
                                      icon: item.icon,
                                      actions: item.actions,
                                      emitevent: item.emitevent,
                                      params: item.params,
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.filterPermitted(item.children),
                                      function (childL1, index1) {
                                        return [
                                          childL1.children
                                            ? [
                                                _c(
                                                  "SidebarNavDropdown",
                                                  {
                                                    key: index1,
                                                    attrs: {
                                                      name: _vm.$t(
                                                        childL1.name
                                                      ),
                                                      url: childL1.url,
                                                      icon: childL1.icon,
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.filterPermitted(
                                                      childL1.children
                                                    ),
                                                    function (childL2, index2) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: index2,
                                                          staticClass:
                                                            "nav-item",
                                                        },
                                                        [
                                                          _c("SidebarNavLink", {
                                                            attrs: {
                                                              name: _vm.$t(
                                                                childL2.name
                                                              ),
                                                              url: childL2.url,
                                                              icon: childL2.icon,
                                                              badge:
                                                                childL2.badge,
                                                              variant:
                                                                childL2.variant,
                                                              attributes:
                                                                childL2.attributes,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            : [
                                                _c(
                                                  "SidebarNavItem",
                                                  {
                                                    key: index1,
                                                    attrs: {
                                                      classes: item.class,
                                                    },
                                                  },
                                                  [
                                                    _c("SidebarNavLink", {
                                                      attrs: {
                                                        name: _vm.$t(
                                                          childL1.name
                                                        ),
                                                        url: childL1.url,
                                                        icon: childL1.icon,
                                                        badge: childL1.badge,
                                                        variant:
                                                          childL1.variant,
                                                        attributes:
                                                          childL1.attributes,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                        ]
                      : [
                          _c(
                            "SidebarNavItem",
                            { key: index, attrs: { classes: item.class } },
                            [
                              _c("SidebarNavLink", {
                                attrs: {
                                  name: _vm.$t(item.name),
                                  url: item.url,
                                  icon: item.icon,
                                  badge: item.badge,
                                  variant: item.variant,
                                  attributes: item.attributes,
                                  actions: item.actions,
                                  emitevent: item.emitevent,
                                  params: item.params,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                  ],
            ]
          }),
        ],
        2
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }