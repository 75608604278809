import { httpAjax, urlParams } from "@/helpers";
import { getKeysWithoutRedactedFields } from './common'
import { getPermissionDenyProperties, hasPermission } from '@/helpers/permission'
export const skillService = {
  create,
  list,
  listFunc,
  query,
  update,
  remove,
  clone,
  listDashboard,
  listNames,
  dashboardListNames,
  listUniqueValuesOfProperty
};

/**
 * Create a new skill
 * by passing necessary information
 * @param Array data
 * e.g [{uuId: 'null', fullName: 'John Doe' ... }]
 */
function create(data) {
  const url = "/api/skill/add";
  const config = {
    headers: getHeaders(),
  };
  return httpAjax.post(url, data, config);
}

function listFunc(levelList) {
  return (params, { companyId=null, self }={}) => {
    return list(params, companyId, { enableLevelGroup: true, levelList, customFields: self?.customFields })
  }
  
}

function isActive(start, end) {
  const now = new Date();
  
  return (now.getTime() > start && now.getTime() < end);
}

/**
 * Retrieving a concise list of skill info by
 * passing in pagination, sorting and filtering parameters
 * @param Object params
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
function list(params, companyUuId = null, { enableLevelGroup=false, levelList=[], customFields=[] } = {}) {
  const fields = {
    uuId: "SKILL.uuId",
    name: "SKILL.name",
    skillLevels: "SKILL.skillLevels",
    skillStaffLevels: "SKILL.SKILL-STAFF.level",
    staffList: "SKILL.STAFF.uuId",
    staffCompanyUuId: "SKILL.STAFF.COMPANY.uuId",
    genericStaff: "SKILL.STAFF.genericStaff",
    quota: "SKILL.STAFF.resourceQuota",
    startDate: "SKILL.STAFF.startDate",
    endDate: "SKILL.STAFF.endDate",
    identifier: 'SKILL.identifier',
    color: 'SKILL.color',
    tag: 'SKILL.TAG.name',
    readOnly: 'SKILL.readOnly'
  };
  if (Array.isArray(customFields) && customFields.length > 0) {
    for(const cField of customFields) {
      fields[cField.name] = `SKILL.${cField.name}`;
    }
  }

  let data = {
    name: "Skill List",
    type: "msql",
    nominate: "SKILL",
    start: params.start,
    limit: params.limit,
    select: Object.keys(fields).map((i) => [fields[i]]),
  };

  if (params.holders) {
    data['holder'] = params.holders;
  }
  
  if (params.filter && params.filter.length > 0) {
    const matchColumns = [
      ['SKILL.name', 'has', params.filter]
    ]

    const skillDeniedProperties = getPermissionDenyProperties('SKILL','VIEW')
    if (!skillDeniedProperties.includes('identifier')) {
      matchColumns.push(['SKILL.identifier', 'has', params.filter])
    }
    if (!skillDeniedProperties.includes('color')) {
      matchColumns.push(['SKILL.color', 'has', params.filter])
    }

    if (Array.isArray(customFields) && customFields.length > 0) {
      for (const c of customFields) {
        if ((c.type == 'String' || c.type == 'Enum<String>') && !skillDeniedProperties.includes(c.name)) {
          matchColumns.push([`SKILL.${c.name}`, 'has', params.filter])
        }
      }
    }

    const tagDeniedProperties = getPermissionDenyProperties('TAG', 'VIEW')
    if (hasPermission('TAG', 'VIEW') &&
        !skillDeniedProperties.includes('TAG') && 
        !skillDeniedProperties.includes('TAG.name') && 
        !tagDeniedProperties.includes('name') ) {
      matchColumns.push(['SKILL.TAG.name', 'has', params.filter])
    }


    data["filter"] = ['_or_', matchColumns];
  }

  //BadgeFilter related
  if (Array.isArray(params.badgeFilters) && params.badgeFilters.length > 0) {
    const badgeFilterList = [];
    for (const f of params.badgeFilters) {
      if (f.field == null || !Array.isArray(f.value) || f.value.length == 0) {
        continue;
      }
      
      let field = null;
      if (f.field == 'tagName') {
        field = 'SKILL.TAG.name';
      } else {
        field = `SKILL.${f.field}`;
      } 


      const isEqual = !f.operator || f.operator === 'is';
      let valueList = [];
      const value = f.value;
      for (const v of value) {
        if (v.text != null && (typeof v.text === 'number' || (typeof v.text === 'string' && v.text&& v.text.length > 0 && v.text !== '(Empty)'))) {
          //use value when exists. Otherwise use text
          //Reason: When value property exists, it means the text is localized and can't be used in filter.
          valueList.push([field, 'eq', Object.hasOwn(v, 'value')? v.value : v.text]);
        }
        //Start processing (EMPTY) field value 
        else if (isEqual) {
          valueList.push('_not_', [[field]])
        } else {
          //Special handling: when is not empty, put it into badgeFilterList directly to act as 'field must exist'. 
          badgeFilterList.push([field])
        }
      }
      if (valueList.length > 0) {
        if (valueList.length > 1) {
          valueList = ['_or_', valueList];
        }
        if (!isEqual) {
          badgeFilterList.push('_not_', valueList);
        } else {
          badgeFilterList.push(...valueList);
        }
      }
    }
    if (badgeFilterList.length > 0) {
      if (Array.isArray(data.filter) && data.filter.length > 0) {
        data.filter = [...data.filter, ...badgeFilterList]
      } else {
        data.filter = [...badgeFilterList]
      }
    }
  }

  if (params.ksort && params.ksort.length > 0) {
    data["sort"] = [];
    if (Array.isArray(params.ksort)) {
      for (let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === "uuId" ? "name" : params.ksort[i];
        data["sort"].push([fields[sortKey], params.order[i], "", "lowerCase"]);
      }
    } else {
      data["sort"].push([fields[params.ksort], params.order, "", "lowerCase"]);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.badgeFilters;
  delete params.holders;
  delete params.fields;
  
  const url = "/api/query/match";
  const config = {
    params: params,
  };
  
  return httpAjax.post(url, data, config).then((response) => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);

    return {
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort ? params.ksort : "",
      arg_order: params.order ? params.arg_order : null,
      data: rawData.map((i) => {
        const result = {};
        for (const level of levelList) {
          result[`${level.value}Count`] = 0
        }

        for (let j = 0, len = i.length; j < len; j++) {
          if (keys[j] === "skillStaffLevels") {
            for (const level of levelList) {
              result[`${level.value}Count`] = i[j].filter(
                (val) => val === level.value
              ).length;
            }
          } else if (keys[j] === "staffList") {
            //Issue: When skill is not assgined to any staff, a static string will be returned.
            //Solution: Ignore and exclude it.
            result[keys[j]] = i[j]
              ? i[j].filter(
                  (val) => val !== "00000000-0000-0000-0000-000000000000"
                )
              : [];
          // } else if (keys[j] == 'skillLevels') {
          //   //Due to backend bug, a trick is added to resolve the missing closing curly bracket.
          //   if (result.skillLevels != null && typeof result.skillLevels == 'string' && result.skillLevels.length > 0 && result.skillLevels != '[]') {
          //     result.skillLevels.substring(0, result.skillLevels.length - 1) + '}' + result.skillLevels.substring(result.skillLevels.length - 1)
          //   }
          //   result.skillLevels = JSON.parse(result.skillLevels)
          } else {
            result[keys[j]] = i[j];
          }
        }
        
        if (companyUuId !== null) {
          
          result.staffCount = 0;
          result.genericStaffCount = 0;
          if (Array.isArray(result.staffCompanyUuId) && Array.isArray(result.genericStaff)) {
            for (let x = 0, len = result.staffCompanyUuId.length; x < len; x++) {
              if (result.genericStaff[x] !== true && result.staffCompanyUuId[x] === companyUuId) {
                result.staffCount++;
              }
              else if (result.staffCompanyUuId[x] === companyUuId) {
                result.genericStaffCount++;
              }
            }
          }
        } else {
          result.staffCount = 0;
          result.genericStaffCount = 0;
          if (Array.isArray(result.genericStaff)) {
            for (let x = 0, len = result.genericStaff.length; x < len; x++) {
              if (result.genericStaff[x] !== true) {
                if (isActive(result.startDate[x], result.endDate[x])) {
                  result.staffCount++;
                }
              }
              else {
                result.genericStaffCount += result.quota[x];
              }
            }
          }
        }
        //Remove genericStaff as it is not needed anymore after getting staffCount
        delete result.genericStaff;

        if (enableLevelGroup && levelList.length > 0) {
          result.levelGroup = {}
          const sLevels = Array.isArray(result.skillLevels)? result.skillLevels : []
          for (const level of levelList) {
            const found = sLevels.find(i => i.kind == level.value)
            if (found) {
              result.levelGroup[level.value] = found
            } else {
              result.levelGroup[level.value] = { kind: level.value, data: 5 }
            }
          } 
        }

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      }),
    };
  });
}

/**
 * Retrieving a list of skill details by
 * passing in an array list of uuIds as arguments
 * @param Array data
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function query(data, links = null) {
  const url = urlParams("/api/skill/get", links);
  const config = {
    headers: getHeaders(),
  };
  return httpAjax.post(url, data, config);
}

/**
 * Update skill details
 * by passing  necessary information
 * @param Array data
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', fullName: 'John Doe' ... }]
 */
function update(data) {
  const url = "/api/skill/update";
  const config = {
    headers: getHeaders(),
  };
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of skills
 * by passing in an array list of uuIds
 * @param Array data
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = "/api/skill/delete";
  const config = {
    headers: getHeaders(),
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ "Content-Type": "application/json" });
}

/**
 * Clone a skill with given new name
 * @param String refId Target skill UuId
 * @param Object data Object contains name for cloned skill.
 */
function clone(refId, data) {
  const url = "/api/skill/clone";
  const config = {
    data: data,
    headers: getHeaders(),
    params: { reference: refId },
  };
  return httpAjax.post(url, data, config);
}

function listDashboard(params) {
  const fields = {
    uuId: ["SKILL.uuId"],
    name: ["SKILL.name"],
    skillLevels: ["SKILL.SKILL-STAFF.level"],
    staffIds: ["SKILL.STAFF.uuId"],
    staffStart: ["SKILL.STAFF.startDate", "<OLD>", "yyyy-MM-dd"],
    staffEnd: ["SKILL.STAFF.endDate", "<AYE>", "yyyy-MM-dd"],
    staffFirstName: ["SKILL.STAFF.firstName"],
    staffLastName: ["SKILL.STAFF.lastName"],
    genericStaff: ["SKILL.STAFF.genericStaff"],
  };

  let data = {
    name: "Skill Dashboard List",
    type: "msql",
    nominate: "SKILL",
    start: 0,
    limit: -1,
    select: Object.keys(fields).map((i) => fields[i]),
  };

  if (params.companyrule) {
    data['filter'] = [params.companyrule];
  }
  delete params.companyrule;
  
  const url = "/api/query/match";
  const config = {
    headers: getHeaders(),
  };
  return httpAjax.post(url, data, config).then((response) => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);

    return {
      arg_total: response.data.arg_total,
      data: rawData.map((i) => {
        const result = {};
        for (let j = 0; j < i.length; j++) {
          result[keys[j]] = i[j];
        }
        result.staffList = [];
        if (result.staffIds) {
          for (var k = 0; k < result.staffIds.length; k++) {
            if (!result.genericStaff[k]) {
              result.staffList.push({
                uuId: result.staffIds[k],
                startDate: result.staffStart[k],
                endDate: result.staffEnd[k],
                skillLevel: result.skillLevels[k],
                firstName: result.staffFirstName[k],
                lastName: result.staffLastName[k],
              });
            }
          }
        }
        
        delete result.staffIds;
        delete result.staffStart;
        delete result.staffEnd;
        delete result.skillLevels;
        delete result.staffEmail;

        return result;
      }),
    };
  });
}

/**
 * Retrieving a concise list of SKILL info by 
 * count of tasks and sum of duration
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
function dashboardListNames(projectId) {
  const fields = {
    uuId: ['SKILL.uuId'],
    name: ['SKILL.name'],
    count: [
        "SKILL.TASK.name",
        0,
        "<AUTO>",
        "count"
    ],
    totalDuration: [
        "SKILL.TASK.estimatedDuration",
        0,
        "<AUTO>",
        "sum"
    ]
  }
  

  let data = {
    'name'  : 'SKILL Dashboard Name List'
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'group': 'SKILL'
    ,'select': Object.keys(fields).map(i => fields[i])
    ,'filter': [['SKILL.TASK.PROJECT.uuId', 'eq', projectId]]
  }

  const url = '/api/query/match';
  const config = {

  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

/**
 * Retrieving a concise list of SKILL info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
function listNames(params) {
  const fields = {
    uuId: 'SKILL.uuId',
    name: 'SKILL.name'
  }
  

  let data = {
    'name'  : 'SKILL Name List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'SKILL'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

function listUniqueValuesOfProperty(field) {
  const fieldSelector = [`SKILL.${field}`]
  
  const data = {
    'name'  : `Unique SKILL property: ${field}`
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'select': [
      fieldSelector
    ]
    , 'dedup': true
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    let rawData = response.data[listName] || [];
    rawData = rawData.map(i => i[0]);
    rawData.sort((a, b) => {
      if (typeof a === 'string') {
        return a.localeCompare(b, undefined, { sensitivity: 'base'})
      } else {
        if (a < b) {
          return -1
        } else if (a > b) {
          return 1
        }
        return 0
      }
    });
    return rawData;
  });
}
