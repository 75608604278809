

<template>
  <div :style="labelStyle">
    <span class="ml-1">{{ label }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import { invertColor } from '@/helpers';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'ColorCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    this.value = this.params && this.params.value ? this.params.value : null;
  },
  computed: {
    label() {
      return this.value;
    },
    labelStyle() {
      const rowColor = this.value ? this.value : null;
      const style = [];
      if (rowColor) {
        style.push(`width: 100%; background-color: ${rowColor}; color: ${invertColor(rowColor, true)}`);
      }
      return style.join(';');
    }
  }
})
</script>
<style lang="scss" scoped>

</style>