import axios from 'axios';
import https from 'https';
import { cloneDeep } from 'lodash';
import store from '@/store'

const httpAjax = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  withCredentials: true,// Set true when need to include credential in header
});

httpAjax.interceptors.request.use((config) => {
  if (!config.url.startsWith('/api/system/') &&
      !config.url.startsWith('/api/profile/')) {
    if (store.state.epoch && store.state.epoch.value) {
      // use config.params if it has been set
      config.params = config.params || {};
      // add any client instance specific params to config
      config.params['epoch'] = store.state.epoch.value;
    }
    else if (store.state.sandbox && store.state.sandbox.value) {
      // use config.params if it has been set
      config.params = config.params || {};
      // add any client instance specific params to config
      config.params['sandbox'] = store.state.sandbox.value;
    }
  }
  return config;
});

httpAjax.interceptors.response.use(undefined, (error) => {
  // Handle unauthorized errors with a logout event
  if (error.response && error.response.status === 401) {
    const originUrl = cloneDeep(window.App.$router.currentRoute.path);
    if('/login' != originUrl) {
      window.App.$store.dispatch('authentication/logout');
      window.App.$router.push({ path:'/login', query: { RelayState: originUrl } });
    }
  }
  else if (error.response && error.response.status === 429) {
    const timeout = error.response.headers['x-rate-limit-retry-after-seconds'] * 1000;
    console.log(`Rate limit triggered on ${error.config.url}.  Waiting for ${timeout}ms.`) // eslint-disable-line no-console
    return new Promise(
        // Use X-Retry-After rather than Retry-After 
        resolve => setTimeout(() => {
          resolve(httpAjax(error.config));
        }, timeout)
      )
  }
  // Otherwise handle non-authentication errors normally
  return new Promise((resolve, reject) => {
    reject(error);
  });
});

export default httpAjax;