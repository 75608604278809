var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-cell" },
    [
      _c("NoteWithListModal", {
        attrs: {
          show: _vm.noteShow,
          id: _vm.noteUuid,
          holderid: _vm.entityUuId,
          notes: _vm.notes,
          title: _vm.$t("comment.edit_comment"),
          showList: _vm.showList,
          newText: _vm.newText,
          highlightAllOnFocus: _vm.highlightAllOnFocus,
        },
        on: { cancel: _vm.closePicker, changed: _vm.changed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }