<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
  /* Import Material Design Icons Set */
  @import '~material-design-icons/iconfont/material-icons.css';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
    
  // Import Main styles for this application
  @import 'assets/scss/style';


</style>
