import { objectClone } from '@/helpers';

export const TaskViewPropertyUtil = {
  isCompatible
  , getEmptyDataModel
}
const dataGroup = {
  dateGroup: ['startTime','closeTime']
  , durationGroup: ['estimatedDuration', 'totalActualDuration', 'estimatedTimeToComplete', 'fixedDuration']
  , costGroup: ['fixedCost', 'fixedCostNet'
                        , 'estimatedCost', 'estimatedCostNet'
                        , 'actualCost', 'actualCostNet'
                        , 'plannedCost', 'plannedCostNet']
, stringGroup: ['name', 'description', 'identifier']
}


function isCompatible(src, tgt) {
  if (src === tgt) {
    return true;
  }

  const keys = Object.keys(dataGroup);
  for(const key of keys) {
    if (dataGroup[key].includes(src) && dataGroup[key].includes(tgt)) {
      return true;
    }  
  }
  return false; 
}

const emptyDataModel = new Map();

emptyDataModel.set('stage', { uuId: null });
emptyDataModel.set('staffs', []);
emptyDataModel.set('rebates', []);
emptyDataModel.set('resources', []);
emptyDataModel.set('skills', []);
emptyDataModel.set('tag', []);
emptyDataModel.set('totalActualDuration', (data) => {
  if (data == null) {
    return '0D';
  }
  const oldStaffs = objectClone(data.staffs);
  const staffs = data.staffs;
  for (const staff of staffs) {
    staff.duration = 0;
  }
  data.oldStaffs = oldStaffs;
  return '0D';
})

function getEmptyDataModel(property, data) {
  if (emptyDataModel.has(property)) {
    const val = emptyDataModel.get(property);
    if (typeof val === 'function') {
      return { found: true, value: val(data) }  
    }
    return { found: true, value: val };
  } else {
    let value = data[property];
    if (typeof value == 'string') {
      if (property == 'color') {
        value = null;
      } else {
        value = '';
      }
    } else if (typeof value == 'number') {
      value = 0;
    } else if (typeof value == 'object') {
      value = { uuId: null }
    } else if (Array.isArray(value)) {
      value = [];
    }
    return { found: false, value }
  }
}
