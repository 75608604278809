
import { httpAjax, urlParams } from '@/helpers';
import { getKeysWithoutRedactedFields } from './common'
import { getPermissionDenyProperties } from '@/helpers/permission'
export const sandboxService = {
  create,
  list,
  update,
  remove,
  audit,
  detail,
  migrate,
  status
};

/**
 * Create a new sandbox 
 * by passing necessary information
 * @param none
 */
function create(data) {
  const url = '/api/system/sandbox/add?opts=now';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Update sandbox details 
 * by passing  necessary information
 * @param sandbox
 */
function update(data) {
  const url = `/api/system/sandbox/update?sandbox=${data.uuId}`;
  const config = {
    headers: getHeaders()
  }
  delete data.uuId;
  return httpAjax.put(url, data, config);
}

/**
 * Update sandbox details 
 * by passing  necessary information
 * @param sandbox
 */
function list() {
  const url = `/api/system/sandbox/list`;
  const config = {
    headers: getHeaders()
  }
  return httpAjax.get(url, config);
}

/**
 * Get audit sandbox details 
 * by passing  necessary information
 * @param sandbox
 */
function audit(sandbox) {
  const url = `/api/system/sandbox/audit?sandbox=${sandbox}&opts=main,mute,head,trim,path&links=ALL`;
  const config = {
    headers: getHeaders()
  }
  return httpAjax.get(url, config);
}

/**
 * Get status sandbox details 
 * by passing  necessary information
 * @param sandbox
 */
function status(sandbox) {
  const url = `/api/system/sandbox/migrate/status?sandbox=${sandbox}`;
  const config = {
    headers: getHeaders()
  }
  return httpAjax.get(url, config);
}

/**
 * Update sandbox details 
 * by passing  necessary information
 * @param sandbox
 */
function migrate(sandbox, data, links='ALL', test=false) {
  const url = test ? `/api/system/sandbox/migrate?links=${links}&sandbox=${sandbox}&opts=notes,defy,test` :
    `/api/system/sandbox/migrate?links=${links}&sandbox=${sandbox}&opts=notes,defy,pure`;
  const config = {
    headers: getHeaders()
  }
  delete data.uuId;
  return httpAjax.post(url, data, config);
}

/**
 * Get audit sandbox details 
 * by passing  necessary information
 * @param sandbox
 */
function detail(sandbox, uuId) {
  const url = `/api/system/sandbox/audit?sandbox=${sandbox}&opts=full,one,mute,head&holder=${uuId}`;
  const config = {
    headers: getHeaders()
  }
  return httpAjax.get(url, config);
}

/**
 * Delete a list of sandboxs
 * @param sandbox
 */
function remove(id) {
  const url = `/api/system/sandbox/delete?sandbox=${id}`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.delete(url, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}
