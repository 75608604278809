/**
 * Builds a URL like /project/get?links=STAGE,STAFF&macros=true
 * 
 * Links: array of entitry names
 * Macros: true to calculate macros
 */
export function urlParams(url, links=null, macros=null) {
  let _url = url;
  let params = {};
  if (links) {
    params['links'] = links.join(',')
  }
  if (macros) {
    params['macros'] = true;
  }
  let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  if (queryString) {
    _url += '?' + queryString;
  }
  return _url;
}

/**
 * Creates a body for a query in a composite script
 */
export function createBody(params, fields, filter, nominate, dedup) {
  let data = {
    'name'  : params.name ? params.name : 'Query'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'sep_array' : ' / '
    ,'select': Object.keys(fields).map(i => typeof fields[i] === 'string' ? [fields[i]] : fields[i])
  }
  
  if (params.holder) {
    data['holder'] = params.holder;
  }
  
  if (params.epoch) {
    data['epoch'] = params.epoch;
  }
  
  if (filter !== null) {
      data['filter'] = filter;
  }
  
  if (nominate) {
    data[nominate.type] = nominate.field;
  }

  if (dedup !== null) {
    data['dedup'] = dedup;
  }
  
  if (params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if (Array.isArray(params.ksort) && Array.isArray(params.order)) {
      for (let i = 0, len = params.ksort.length; i < len; i++) {
        data['sort'].push([params.ksort[i], params.order[i], '', 'lowerCase']);
      }
    } 
    else if (Array.isArray(params.ksort) && ((typeof params.ksort[0] === 'string' && !params.ksort[0].startsWith('=')) ||
             typeof params.ksort[0] === 'object')) {
      for (let i = 0, len = params.ksort.length; i < len; i++) {
        if (typeof params.ksort[i] === 'object') {
          if (typeof params.ksortType !== 'undefined' && params.ksortType !== null && params.ksortType[i] === 'String') {
            data['sort'].push([params.ksort[i].field, params.ksort[i].sortdirection, '', 'lowerCase']);
          }
          else {
            data['sort'].push([params.ksort[i].field, params.ksort[i].sortdirection]);
          }
        }
        else {
          if (params.ksortType && params.ksortType[i] === 'String') {
            data['sort'].push([params.ksort[i], 'incr', '', 'lowerCase']);
          }
          else {
            data['sort'].push([params.ksort[i], 'incr']);
          }
        }
      }
    }
    else if (params.ksortType === 'String') {
      data['sort'].push([params.ksort, params.order, '', 'lowerCase']);
    }
    else if (params.ksortAgFunc !== null) {
      if (params.ksortAgFunc === 'year' ||
        params.ksortAgFunc === 'month' ||
        params.ksortAgFunc === 'day' ||
        params.ksortAgFunc === 'cos' ||
        params.ksortAgFunc === 'sin' ||
        params.ksortAgFunc === 'tan') {
        data['sort'].push([params.ksort, params.order, params.ksortAgFunc]);
      }
      else {
        if (params.ksortAgFunc === 'count') {
            const parts = params.ksort.split('.');
            parts.splice(parts.length - 1, 1);
            params.ksort = `${parts.join('.')}.uuId`;
          data['sort'].push([params.ksort, params.order, 0, params.ksortAgFunc]);
          data['select'].push([params.ksort, params.order, 0, params.ksortAgFunc]);
        }
        else {
          data['sort'].push([params.ksort, params.order, 0, params.ksortAgFunc]);
        }
      }
    }
    else {
      data['sort'].push([params.ksort, params.order]);
    }
  }
  return data;
}
