

<template>
  <div>
    <span>{{ label }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'NoteCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    this.value = this.params && Array.isArray(this.params.value) && this.params.value.length > 0 ? this.params.value[0].text: null;
  },
  computed: {
    label() {
      return this.value;
    }
  }
})
</script>
