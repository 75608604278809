var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BlankCenterContainer",
    [
      _c(
        "b-col",
        { attrs: { md: "5" } },
        [
          _c("div", { staticClass: "standard-logo-wrapper mx-auto" }, [
            _c("div", {
              staticClass: "standard-logo",
              attrs: {
                id: "logo",
                alt: "Projectal Logo",
                border: "0",
                name: "logo",
              },
            }),
          ]),
          _c("b-card", { staticClass: "mx-auto" }, [
            _c(
              "div",
              [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      dismissible: "",
                      fade: "",
                      show: _vm.alertShow,
                      variant: _vm.alert.type,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _vm.alert.type === "danger"
                      ? _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-exclamation"] },
                        })
                      : _vm._e(),
                    _vm.alert.type === "success"
                      ? _c("font-awesome-icon", {
                          attrs: { icon: ["far", "check"] },
                        })
                      : _vm._e(),
                    _vm._v("   " + _vm._s(_vm.alert.message) + " "),
                  ],
                  1
                ),
                _c("div", { staticClass: "sign-in-text" }, [
                  _vm._v(_vm._s(_vm.$t("login.sign_in"))),
                ]),
                _c(
                  "div",
                  { staticClass: "sso-group" },
                  [
                    _vm._l(_vm.ssoProviders, function (provider, key) {
                      return [
                        _c(
                          "button",
                          {
                            key: key,
                            staticClass:
                              "btn btn-secondary btn-block position-relative",
                            on: {
                              click: function ($event) {
                                return _vm.ssoLogin(provider.login)
                              },
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "sso-provider-icon",
                              style: {
                                backgroundImage: "url(" + provider.icon + ")",
                              },
                            }),
                            _vm._v(_vm._s(provider.name)),
                          ]
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm.projectalLogin
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _vm.ssoProviders.length !== 0
                      ? _c(
                          "label",
                          {
                            staticClass: "text-n-line",
                            class: _vm.loginExpand ? null : "collapsed",
                            attrs: {
                              "aria-expanded": _vm.loginExpand
                                ? "true"
                                : "false",
                              "aria-controls": "collapse-4",
                            },
                            on: {
                              click: function ($event) {
                                _vm.loginExpand = !_vm.loginExpand
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "or-sign-in-text" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("login.or_login_with")) + " "
                                ),
                                _vm.loginExpand
                                  ? _c("font-awesome-icon", {
                                      staticClass: "collapsable-icon",
                                      attrs: { icon: ["far", "chevron-up"] },
                                    })
                                  : _c("font-awesome-icon", {
                                      staticClass: "collapsable-icon",
                                      attrs: { icon: ["far", "chevron-down"] },
                                    }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "b-collapse",
                      {
                        model: {
                          value: _vm.loginExpand,
                          callback: function ($$v) {
                            _vm.loginExpand = $$v
                          },
                          expression: "loginExpand",
                        },
                      },
                      [
                        _c(
                          "b-form-group",
                          [
                            _c(
                              "b-input-group",
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c(
                                      "b-input-group-text",
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "user"] },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.username,
                                      expression: "username",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: { required: _vm.validate },
                                      expression: "{ required: validate }",
                                    },
                                  ],
                                  staticClass: "last-child form-control",
                                  class: {
                                    "is-invalid": _vm.showUsernameError,
                                  },
                                  attrs: {
                                    type: "email",
                                    id: "username",
                                    placeholder: _vm.$t(
                                      "login.placeholder_email"
                                    ),
                                    "data-vv-as": _vm.$t(
                                      "login.field.email_address"
                                    ),
                                    "data-vv-name": "username",
                                    "data-vv-delay": "500",
                                  },
                                  domProps: { value: _vm.username },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.username = $event.target.value
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-invalid-feedback",
                              {
                                staticClass: "alert-danger form-field-alert",
                                class: { "d-block": _vm.showUsernameError },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["far", "circle-exclamation"],
                                  },
                                }),
                                _vm._v(
                                  "  " +
                                    _vm._s(_vm.errors.first("username")) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          [
                            _c(
                              "b-input-group",
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c(
                                      "b-input-group-text",
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "lock"] },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.password,
                                      expression: "password",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: { required: _vm.validate },
                                      expression: "{ required: validate }",
                                    },
                                  ],
                                  staticClass: "last-child form-control",
                                  class: {
                                    "is-invalid": _vm.showPasswordError,
                                  },
                                  attrs: {
                                    type: "password",
                                    id: "password",
                                    placeholder: _vm.$t(
                                      "login.placeholder_password"
                                    ),
                                    "data-vv-as": _vm.$t(
                                      "login.field.password"
                                    ),
                                    "data-vv-name": "password",
                                    "data-vv-delay": "500",
                                  },
                                  domProps: { value: _vm.password },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.password = $event.target.value
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-invalid-feedback",
                              {
                                staticClass: "alert-danger form-field-alert",
                                class: { "d-block": _vm.showPasswordError },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["far", "circle-exclamation"],
                                  },
                                }),
                                _vm._v(
                                  "    " +
                                    _vm._s(_vm.errors.first("password")) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-block",
                            attrs: {
                              disabled: _vm.isSubmitDisabled || _vm.submitting,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleSubmit.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  _vm.submitting
                                    ? "button.logging_in"
                                    : "button.login"
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "d-block small mt-3",
                                attrs: { to: "/forgot/password" },
                              },
                              [_vm._v(_vm._s(_vm.$t("link.forgot_password")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "auth-footer mt-3 text-center" }, [
            _vm._v(
              _vm._s(
                `© ${new Date().getFullYear()} JanusKS Pty. Ltd. All rights reserved. Version ${
                  _vm.versionNumber
                }.`
              )
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }