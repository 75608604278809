
import { httpAjax } from '@/helpers';

export const projectLinkStatusService = {
  create,
  remove
};

function create(projectId, stages) {
  const url = '/api/project/link/stage/add';
  const config = {
    headers: getHeaders()
  };

  var stageList = {uuId: stages[0].uuId};

  const data = {
    uuId: projectId,
    stage: stageList
  }

  return httpAjax.post(url, data, config);
}


function remove(projectId, stages) {
  const url = '/api/project/link/stage/delete';
  const config = {
    headers: getHeaders()
  };

  var stageList = {uuId: stages[0].uuId};

  const data = {
    uuId: projectId,
    stage: stageList
  }
  return httpAjax.post(url, data, config);
}


function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}