var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex" }, [
    _c("div", { staticClass: "d-flex select-button" }, [
      _c(
        "div",
        {
          ref: "menuButton",
          staticClass: "customHeaderMenuButton",
          on: {
            click: function ($event) {
              return _vm.onSelectToggle($event)
            },
          },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "selection-icon",
            attrs: {
              icon: _vm.selectedAll
                ? ["far", "square-check"]
                : _vm.selectedSome
                ? ["far", "square-minus"]
                : ["far", "square"],
            },
          }),
        ],
        1
      ),
      _vm.params.enableMenu
        ? _c(
            "div",
            {
              ref: "menuButton",
              staticClass: "select-menu ml-1 customHeaderMenuButton",
              on: {
                click: function ($event) {
                  return _vm.onMenuClicked($event)
                },
              },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "selection-icon",
                attrs: { icon: ["far", "caret-down"] },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c("span", { staticClass: "ml-1 display-name" }, [
      _vm._v(_vm._s(_vm.params.displayName)),
    ]),
    _c(
      "span",
      {
        ref: "eSortIndicator",
        staticClass:
          "ag-sort-indicator-container paged-range-select-sort-indicator",
      },
      [
        _vm.params.enableSorting
          ? _c(
              "span",
              {
                ref: "eSortOrder",
                staticClass: "ag-sort-indicator-icon ag-sort-order",
                class: { "ag-hidden": _vm.sortIndexDisplay === null },
                attrs: { "aria-hidden": "true" },
              },
              [_vm._v(_vm._s(_vm.sortIndexDisplay))]
            )
          : _vm._e(),
        _vm.params.enableSorting
          ? _c(
              "span",
              {
                ref: "eSortAsc",
                staticClass: "ag-sort-indicator-icon ag-sort-ascending-icon",
                class: { "ag-hidden": _vm.ascSort !== true },
                attrs: { "aria-hidden": "true" },
              },
              [
                _c("span", {
                  staticClass: "ag-icon ag-icon-asc",
                  attrs: { unselectable: "on", role: "presentation" },
                }),
              ]
            )
          : _vm._e(),
        _vm.params.enableSorting
          ? _c(
              "span",
              {
                ref: "eSortDesc",
                staticClass: "ag-sort-indicator-icon ag-sort-descending-icon",
                class: { "ag-hidden": _vm.descSort !== true },
                attrs: { "aria-hidden": "true" },
              },
              [
                _c("span", {
                  staticClass: "ag-icon ag-icon-desc",
                  attrs: { unselectable: "on", role: "presentation" },
                }),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }