<template>
  <div class="edit-cell">
    <div v-if="staffLength > 0">
      <StaffWorkEffortModal :childTaskEfforts="childTasksActualDuration" :workEfforts="staffs" :show.sync="staffWorkEffortShow" @cancel="staffWorkEffortCancel" @ok="staffWorkEffortOk"/>
    </div>
    <b-modal v-model="showAlert" :title="$t('task_alert.status.no_staff')"
        ok-only
        content-class="shadow"
        no-close-on-backdrop
        @ok="onAlertOk"
        >
      <span>{{ $t('task.alert.cannot_edit_work_effort') }}</span>
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" variant="danger" @click="ok()">{{ $t('button.close') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { onDurationKeyDown, onDurationKeyUp, maxDuratioUnit } from '@/helpers';
import { analyzeDurationAUM, convertDurationToDisplay } from '@/helpers/task-duration-process';
const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_ENTER = 13;
const KEY_TAB = 9;

export default Vue.extend({
  name: 'WorkEffortEditor',
  components: {
    StaffWorkEffortModal: () => import('@/components/modal/StaffWorkEffortModal')
  },
  data() {
    return {
      showAlert: false,
      value: '',
      cancelBeforeStart: false,
      staffWorkEffortShow: true,
      childTasksActualDuration: 0,
      highlightAllOnFocus: true,
      staffs: [],
      compactMode: false,
      durationConversionOpts: {}
    };
  },
  computed: {
    totalWorkEffort() {
      let total = 0;
      let unit = 'D';
      for(const item of this.params.data.staffs) {
        total += item.duration || 0;
        const { unit: itemUnit } = analyzeDurationAUM(item.durationAUM);
        unit = maxDuratioUnit(unit, itemUnit || 'm');
      }
      return convertDurationToDisplay(total, unit, this.durationConversionOpts);
    },
    staffLength() {
      return this.compactMode? this.params.value.data.staffs.length : this.params.data.staffs.length;
    },
  },
  methods: {
    async staffWorkEffortOk(workEfforts) {
      const staffs = this.compactMode? this.params.value.data.staffs : this.params.data.staffs;
      for(const workEffort of workEfforts) {
        const index = staffs.findIndex(i => i.uuId === workEffort.uuId);
        if(index > -1) {
          this.$set(staffs[index], 'duration', workEffort.duration);
          this.$set(staffs[index], 'durationAUM', workEffort.durationAUM);
        }
      }
      this.value = this.totalWorkEffort;

      
      
      // update the values in the db.  Update all selected rows or just the edited row
      const nodes = this.params.api.getSelectedNodes();
      const colId = this.params.colDef.colId;
      if (nodes.length > 1) {
        for (const node of nodes) {
          const nodeStaffs = this.compactMode? node.data[colId].data.staffs : node.data.staffs;
          const oldStaff = node.data.uuId === this.params.data.uuId ? this.staffs : nodeStaffs;
          const originStaffIds = oldStaff.map(i => i.uuId);
          
          const relatedUpdatedStaff = staffs.filter(i => originStaffIds.includes(i.uuId));
          const relatedUpdatedStaffIds = relatedUpdatedStaff.map(i => i.uuId);
          const newStaff = [];
          for(const s of oldStaff) {
            if(relatedUpdatedStaffIds.includes(s.uuId)) {
              newStaff.push(relatedUpdatedStaff.filter(i => i.uuId == s.uuId)[0]);
            } else {
              newStaff.push(s);
            }
          }

          if(newStaff.length > 0) {
            const taskId = this.compactMode? node.data[colId].uuId : node.data.uuId;
            await this.params.context.componentParent.updateStaffs(taskId, oldStaff, newStaff);
          }
        }
      }
      else {
        const uuId = this.compactMode? this.params.value.uuId : this.params.data.uuId;
        const oldStaffs = this.compactMode? this.params.value.data.staffs: this.params.data.staffs;
        await this.params.context.componentParent.updateStaffs(uuId, this.staffs, oldStaffs)
      }
      this.params.context.componentParent.refreshTasks();
      this.closePicker();
    },
    staffWorkEffortCancel() {
      this.closePicker();
    },
    getValue() {
      if (this.compactMode) {
        const orgValue = this.params.value.single;
        const curValue = this.value;
        if (orgValue != curValue) {
          return this.value;
        }
        return this.params.value;
      } else {
        return this.value;
      }
      
    },

    onAlertOk() {
      this.closePicker();
    },
    
    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    
    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },

    setInitialState(params) {
      let startValue;
      let highlightAllOnFocus = true;
      
      if (params.keyPress === KEY_BACKSPACE || params.keyPress === KEY_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (params.charPress) {
        // if a letter was pressed, we start with the letter
        startValue = params.charPress;
        highlightAllOnFocus = false;
      } else {
        // otherwise we start with the current value
        startValue = this.compactMode? params.value.single : params.value;
        
        if (params.keyPress === KEY_F2) {
          highlightAllOnFocus = false;
        }
      }

      this.value = startValue;
      this.highlightAllOnFocus = highlightAllOnFocus;
      if (this.compactMode) {
        this.staffs = this.params.value.data.staffs.map(s => { 
          return { 
            uuId: s.uuId
            , duration: s.duration
            , durationAUM: s.durationAUM
            , name: s.name
            , utilization: s.utilization 
          } 
        });
      } else {
        this.staffs = this.params.data.staffs.map(s => { 
          return { 
            uuId: s.uuId
            , duration: s.duration
            , durationAUM: s.durationAUM
            , name: s.name
            , utilization: s.utilization 
          } 
        });
      }
      
    },

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    isCancelAfterEnd() {
      return this.value > 1000000;
    },

    onKeyDown(event) {
      onDurationKeyDown(event, this.finishedEditingPressed(event));
    },

    onKeyUp(event) {
      this.value = onDurationKeyUp(event, this.value);
    },
    
    getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    },

    isCharNumeric(charStr) {
      return /\d/.test(charStr);
    },

    finishedEditingPressed(event) {
      const charCode = this.getCharCodeFromEvent(event);
      return charCode === KEY_ENTER || charCode === KEY_TAB;
    },
  },

  created() {
    if (this.params.context?.componentParent?.durationConversionOpts != null) {
      this.durationConversionOpts = this.params.context.componentParent.durationConversionOpts;
    }
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    this.showAlert = this.compactMode? this.params.value.data.staffs.length == 0 : this.params.data.staffs.length === 0;
    this.setInitialState(this.params);
  },
  mounted() {
    Vue.nextTick(() => {
      // need to check if the input reference is still valid - if the edit was cancelled before it started there
      // wont be an editor component anymore
      if (this.$refs.input) {
        this.$refs.input.focus();
        if (this.highlightAllOnFocus) {
          this.$refs.input.select();

          this.highlightAllOnFocus = false;
        } else {
          // when we started editing, we want the caret at the end, not the start.
          // this comes into play in two scenarios: a) when user hits F2 and b)
          // when user hits a printable character, then on IE (and only IE) the caret
          // was placed after the first character, thus 'apply' would end up as 'pplea'
          const length = this.$refs.input.value
            ? this.$refs.input.value.length
            : 0;
          if (length > 0) {
            this.$refs.input.setSelectionRange(length, length);
          }
        }

        this.$refs.input.focus();
      }
    });
  }
})
</script>