

<template>
  <div class="ag-cell-label-container ag-header-cell-sorted-none" role="presentation" @click="headerClicked">
      <div :id="`CELL_ADD_${groupId}`" ref="eLabel" class="ag-header-cell-label" role="presentation">
          <span ref="eText" class="ag-header-cell-text" style="margin-left: auto; margin-right: auto;">
            <font-awesome-icon icon="fa-regular fa-plus" :style="{ color: 'var(--grid-toolbar-button)' }"/>
          </span>
      </div>
      <b-popover :target="`CELL_ADD_${groupId}`" triggers="hover" placement="top" boundary="viewport">
        {{ $t('task_compact.button.add_task') }}
      </b-popover>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'TaskCompactUtilHeader',
  data() {
    return {
      groupId: null
    };
  },
  created() {
    this.groupId = this.params.column.parent.groupId;
  },
  methods: {
    headerClicked() {
      this.params.context.componentParent.taskGroupAddNewTask(this.groupId);
    },
  }
})
</script>