<template>
    <span class="datetime-cell-renderer">{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'DateOnlyCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    // this.value = this.params && this.params.value && this.params.value !== '' && this.params.value !== 9223372036854776000 ? moment(this.params.value).format('YYYY-MM-DD hh:mm A') : null;
    let rawValue = this.params? this.params.value : null;
    // eslint-disable-next-line
    if (rawValue == 0 || rawValue == '' || rawValue == 9223372036854776000 || rawValue === 253402174800000 || rawValue === 253402214400000 || rawValue === 32503680000000) {
      rawValue = null;
    }      
    if (rawValue && Array.isArray(rawValue)) {
      let display = [];
      for (const val of rawValue) {
        display.push(moment.utc(val).format('L'));
      }
      this.value = display.join(', ');
      return;
    }
    this.value = rawValue != null? moment.utc(rawValue).format('L') : null;
  },
  computed: {
    label() {
      return this.value? this.value : '';
    }
  }
})
</script>
