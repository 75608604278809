<template>
  <div>
    <span>{{ label }}</span>
    <font-awesome-icon class="ml-1" v-if="hasError" style="color: red;font-size: 1.125em;" :icon="['fas', 'triangle-exclamation']"/>
  </div>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'PriorityCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    const data = this.params && this.params.data? this.params.data : null;
    const priority = data && data.priority? data.priority : null;

    this.value = this.params.value;
  },
  computed: {
    label() {
      return this.value? this.value : '';
    },
    hasError() {
      return this.params.options && this.value ? this.params.options.filter(o => o.value === this.value).length === 0 : false;
    }
  }
})
</script>
