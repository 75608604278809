export default {

  
  themeLight: {
    'text-light': '#616161', // gray-700
    'text-medium': '#424242', // gray-800
    'text-dark' : "#212121", // gray-900
    'surface-bg': '#ffffff',
    'stroke': '#fff',
    'text-on-bar': '#000',
    'axis-stroke': '#eee',

    'status-none': '#E0E0E0',        // gray-300
    'status-gray': '#7f7f7f',        // gray-300
    'status-red': '#EF5350',         // red-400
    'status-orange': '#FFA726',      // orange-400
    'status-light-blue': '#03A9F4',  // light-blue-500
    'status-blue': '#1976D2',        // blue-700
    'status-light-green': '#7CB342', // light-green-600
    'status-green': '#388E3C',       // green-700
    
    /* --progress-x in theme css */
    'progress-0': '#7f7f7f',   // mid grey
    'progress-25': '#FFA726',  // orange-400
    'progress-50': '#03A9F4',  // light-blue-500
    'progress-75': '#1976D2',  // blue-700
    'progress-99': '#7CB342',  // light-green-600
    'progress-100': '#388E3C', // green-700

    /* Bootstrap theme colors, needed for skill levels to match skill badges. Founs in _variables.scss. */
    'bs-success': '#4CAF50',
    'bs-info': '#29B6F6',
    'bs-danger': '#E57373'
  },

  themeDark: {
    'text-light': '#F5F5F5', // gray-100
    'text-medium': '#EEEEEE', // gray-200
    'text-dark' : "#E0E0E0", // gray-300
    'surface-bg': '#424242', // gray-900
    'stroke': '#424242',
    'text-on-bar': '#000',
    'axis-stroke': '#555',

    'status-none': '#E0E0E0AA',        // gray-300
    'status-gray': '#7f7f7fAA',        // gray-300
    'status-red': '#EF5350AA',
    'status-orange': '#FFA726AA',         
    'status-light-blue': '#03A9F4AA',  
    'status-blue': '#1976D2AA',        
    'status-light-green': '#7CB342AA', 
    'status-green': '#388E3CAA',       
    
    /* --progress-x in theme css */
    'progress-0': '#7f7f7fAA',
    'progress-25': '#FFA726AA',
    'progress-50': '#03A9F4AA',
    'progress-75': '#1976D2AA',
    'progress-99': '#7CB342AA',
    'progress-100': '#388E3CAA',

    /* Bootstrap theme colors, needed for skill levels to match skill badges. Founs in _variables.scss. */
    'bs-success': '#4CAF50',
    'bs-info': '#29B6F6',
    'bs-danger': '#E57373'
  },

  getThemeColor(color) {
    var theme = window.localStorage.getItem('theme');
    if (theme == 'dark') {
      return this.themeDark[color];
    } else {
      return this.themeLight[color];
    }
  },
}
