import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);

export const DEFAULT_CALENDAR = {
  'Sunday': [
    {
      'name': 'Base Sunday Calendar',
      'type': 'Sunday',
      'isWorking': false,
      'calendar': 'base_calendar'
    }
  ],
  'Monday': [
    {
      'name': 'Base Monday Calendar',
      'type': 'Monday',
      'startHour': '09:00',
      'endHour': '17:00',
      'isWorking': true,
      'calendar': 'base_calendar'
    }
  ],
  'Tuesday': [
    {
      'name': 'Base Tuesday Calendar',
      'type': 'Tuesday',
      'startHour': '09:00',
      'endHour': '17:00',
      'isWorking': true,
      'calendar': 'base_calendar'
    }
  ],
  'Wednesday': [
    {
      'name': 'Base Wednesday Calendar',
      'type': 'Wednesday',
      'startHour': '09:00',
      'endHour': '17:00',
      'isWorking': true,
      'calendar': 'base_calendar'
    }
  ],
  'Thursday': [
    {
      'name': 'Base Thursday Calendar',
      'type': 'Thursday',
      'startHour': '09:00',
      'endHour': '17:00',
      'isWorking': true,
      'calendar': 'base_calendar'
    }
  ],
  'Friday': [
    {
      'name': 'Base Friday Calendar',
      'type': 'Friday',
      'startHour': '09:00',
      'endHour': '17:00',
      'isWorking': true,
      'calendar': 'base_calendar'
    }
  ],
  'Saturday': [
    {
      'name': 'Base Saturday Calendar',
      'type': 'Saturday',
      'isWorking': false,
      'calendar': 'base_calendar'
    }
  ]
}
Object.freeze(DEFAULT_CALENDAR);

export const WEEKDAY = ['Sunday', 'Monday', 'Tuesday', 'Wednessday', 'Thursday', 'Friday', 'Saturday'];
Object.freeze(WEEKDAY);

export const TRIGGERS = {
  DURATION: 'duration'
  , START_DATE: 'startDate'
  , START_TIME: 'startTime'
  , CLOSE_DATE: 'closeDate'
  , CLOSE_TIME: 'closeTime'
  , CONSTRAINT_TYPE: 'constraintType'
  , CONSTRAINT_DATE: 'constraintDate'
  , START_DATE_BY_CONSTRAINT: 'startDateByConstraint'
  , CLOSE_DATE_BY_CONSTRAINT: 'closeDateByConstraint'
  , TASK_SCHEDULE_MODE: 'taskScheduleMode'
}
Object.freeze(TRIGGERS);

//calcDateTimeDuration ACTION_MODE
const CALC_DTD_ACTION_MODE = {
  TASK_RESIZE: 'taskResize'
  , TASK_MOVE: 'taskMove'
  , TASK_CALC_MISSING_VALUE: 'taskCalcMissingValue'
}


export const CONSTRAINT_TYPES = {
  ASAP: 'As_soon_as_possible'
  , ALAP: 'As_late_as_possible'
  , FNET: 'Finish_no_earlier_than'
  , SNET: 'Start_no_earlier_than'
  , FNLT: 'Finish_no_later_than'
  , SNLT: 'Start_no_later_than'
  , MFO: 'Must_finish_on'
  , MSO: 'Must_start_on'
};
Object.freeze(CONSTRAINT_TYPES);

export const MODAL_TYPES = {
  NON_WORK_DAY: 'to_create_exception_for_non_workday'
  , OUT_OF_PROJECT_DATE_RANGE: 'to_confirm_date_outside_project_date_range'
  , CONSTRAINT_CHANGE: 'to_confirm_constraint_change'
}
Object.freeze(MODAL_TYPES)

export function isValidTrigger(value) {
  return Object.values(TRIGGERS).includes(value);
}

export function isValidConstraintType(value) {
  return Object.values(CONSTRAINT_TYPES).includes(value);
}

const startDateRelatedConstraintTypes = [
  CONSTRAINT_TYPES.SNET
  , CONSTRAINT_TYPES.SNLT
  , CONSTRAINT_TYPES.MSO
]
export function isConstraintTypeStartDateRelated(value) {
  return startDateRelatedConstraintTypes.includes(value);
}

const closeDateRelatedConstraintTypes = [
  CONSTRAINT_TYPES.FNET
  , CONSTRAINT_TYPES.FNLT
  , CONSTRAINT_TYPES.MFO
]
export function isConstraintTypeCloseDateRelated(value) {
  return closeDateRelatedConstraintTypes.includes(value);
}

export function toFixed(value, precision) { // To format number to specified precision
  const pow = Math.pow(10, precision);
  const _value = Math.round(value * pow)
  return _value / pow;
}

/**
 * Extract value and unit from durationAUM or display value with unit.
 * @param {Number} value Either durationAUM or display value with unit.
 * @param {Number} defaultValue Fallback value if there is no valid value. If supplied defaultValue is not null and is invalid, 0 will be used.
 * @param {String} defaultUnit Fallback value if there is no valid unit. If supplied defaultUnit is invalid, 'D' will be used.
 */
 export function analyzeDurationAUM(value, defaultValue=0, defaultUnit='D') {
  if(!defaultUnit || !defaultUnit.match(/^[Y|M|W|D|h|m]$/)) {
    defaultUnit = 'D';
  }
  if(defaultValue != null && 'number' !== typeof defaultValue) {
    defaultValue = 0;
  }
  if(!value) {
    return { unit: defaultUnit, value: defaultValue };
  } else if ('number' === typeof value) {
    return { unit: defaultUnit, value: toFixed(value,1) };
  } else if ('string' === typeof value) {
    const tokens = value.trim().split(' ');
    const v = tokens[0];
    const matchGroup = v.match(/^([0-9]*(\.[0-9]+?)?)([Y|M|W|D|h|m]?)$/);
    if(matchGroup) {
      const num = toFixed(parseFloat(matchGroup[1]), 3);
      const unit = matchGroup[3].length == 0? defaultUnit:matchGroup[3];
      return { unit: unit, value: isNaN(num)? defaultUnit : num };
    }
  }
  return { unit: defaultUnit, value: defaultValue };
}

export function extractDurationConversionOpts(payload) {
  const opts = {}
  if (payload != null) {
    if (payload['schedule.base-calendar.start-hour'] != null) {
      opts.startHour = payload['schedule.base-calendar.start-hour'];
    }
    if (payload['schedule.base-calendar.close-hour'] != null) {
      opts.closeHour = payload['schedule.base-calendar.close-hour'];
    }
    if (payload['schedule.hours-per-day'] != null) {
      opts.hourPerDay = payload['schedule.hours-per-day'];
    }
    if (payload['schedule.hours-per-week'] != null) {
      opts.hourPerWeek = payload['schedule.hours-per-week'];
    }
    if (payload['schedule.days-per-month'] != null) {
      opts.dayPerMonth = payload['schedule.days-per-month'];
    }
    if (payload['schedule.days-per-year'] != null) {
      opts.dayPerYear = payload['schedule.days-per-year'];
    }
  }
  return opts;
}

/**
 * Convert duration (always in minutes) to display value (duration with target unit)
 * @param {Number} duration Duration value returned from backend API. As agreed, it is always in minutes.
 * @param {String} unit display unit
 */
 export function convertDurationToDisplay(duration, unit, { 
    hourPerDay=-1, hourPerWeek=-1, dayPerMonth=-1, dayPerYear=-1 
  }={}) {

  const minutePerDay = (hourPerDay > -1? hourPerDay : 8) * 60;
  const minutePerWeek = hourPerWeek > -1? (hourPerWeek * 60) : (5 * minutePerDay);
  const minutePerMonth = (dayPerMonth > -1? dayPerMonth : 20) * minutePerDay;
  const minutePerYear = (dayPerYear > -1? dayPerYear : 260) * minutePerDay;
  
  if (!unit || !(unit.match(/^[Y|M|W|D|h|m]$/))) {
    unit = 'D';
  }
  if (!duration || 'number' !== typeof duration) {
    return `0${unit}`; //Fallback to 0 with provided unit. If no unit supplied, fallback to 'D'.
  } else if ('Y' === unit) {
    return `${toFixed(duration / minutePerYear, 3)}Y`;
  } else if ('M' === unit) {
    return `${toFixed(duration / minutePerMonth, 3)}M`;
  } else if ('W' === unit) {
    return `${toFixed(duration / minutePerWeek, 3)}W`;
  } else if ('D' === unit) {
    return `${toFixed(duration / minutePerDay, 3)}D`;
  } else if ('h' === unit) {
    return `${toFixed(duration / 60, 3)}h`;
  } else { /* default is m */
    return `${toFixed(duration, 3)}m`;
  }
}

/**
 * Convert duration to rounded display value.
 * Fallback to 'D' when provide targetAUM is not a valid unit.
 * 
 * @param {Number} duration value in minutes
 * @param {String} targetAUM Duration alternative unit measurement. Format: ['m', 'h', 'D', 'W', 'M', 'Y']
 */
 export function convertDurationToRoundedDisplay(duration, targetAUM, opts=null) {
  const targetDisplay = convertDurationToDisplay(duration, targetAUM, opts);
  const { unit, value: dValue } = analyzeDurationAUM(targetDisplay, 0, 'D');
  return `${dValue < 1? 1 : toFixed(dValue, 0)}${unit}`;
}

/**
 * Counvert provided duration display value to integer. round up when value > 0.4. Otherwise, round it down.
 * 
 * @param {Number} durationDisplay 
 */
 export function roundDurationDisplay(durationDisplay, targetAUM=null, precisionNum=0, opts=null) {
  let _targetAUM = null;
  if (targetAUM != null && targetAUM.match(/^[Y|M|W|D|h|m]$/) != null) {
    _targetAUM = targetAUM;
  }

  let unit = null;
  let value = null;
  
  let result = analyzeDurationAUM(durationDisplay, 0, 'D');
  unit = result.unit;
  value = result.value;

  if (_targetAUM != null && _targetAUM != unit) {
    const duration = convertDisplayToDuration(`${value}${unit}`, opts).value;
    const dDisplay = convertDurationToDisplay(duration, _targetAUM, opts);
    result = analyzeDurationAUM(dDisplay, 0, _targetAUM);
    unit = result.unit;
    value = result.value;
  }
  return `${toFixed(value, precisionNum)}${unit}`;
  // return `${value < 1? 1 : toFixed(value, precisionNum)}${unit}`; //This is obsolete.
}

/**
 * Convert display value (or duration with unit) to duration (always in minutes as agreed).
 * Agreement with Chris:
 *   1Y = 250D; 
 *   1M = 21D;
 *   1W = 5D;
 *   1D = 8h;
 * @param {*} durationAUM 
 */
export function convertDisplayToDuration(displayValue, { 
    hourPerDay=-1, hourPerWeek=-1, dayPerMonth=-1, dayPerYear=-1 
  }={}) {
  
  const unit = 'm'; //It is always 'm' as agreed.
  const minutePerDay = (hourPerDay > -1? hourPerDay : 8) * 60;
  const minutePerWeek = hourPerWeek > -1? (hourPerWeek * 60) : (5 * minutePerDay);
  const minutePerMonth = (dayPerMonth > -1? dayPerMonth : 20) * minutePerDay;
  const minutePerYear = (dayPerYear > -1? dayPerYear : 260) * minutePerDay;

  //Round final value to nearest integer as minute is the lowest possible unit.
  const { unit: srcUnit, value: srcValue } = analyzeDurationAUM(displayValue);
  if ('Y' === srcUnit) {
    return { unit, value: toFixed(srcValue * minutePerYear, 0) };
  } else if ('M' === srcUnit) {
    return { unit, value: toFixed(srcValue * minutePerMonth, 0) };
  } else if ('W' === srcUnit) {
    return { unit, value: toFixed(srcValue * minutePerWeek, 0) };
  } else if ('D' === srcUnit) {
    return { unit, value: toFixed(srcValue * minutePerDay, 0) };
  } else if ('h' === srcUnit) {
    return { unit, value: toFixed(parseFloat(srcValue * 60), 0) };
  } else {
    return { unit, value: toFixed(srcValue, 0) }; 
  }
}

/**
 * Verify if the provided calendar is valid.
 * Return true if the calendar has atleast one valid (Weekday) property with startHour and endHour.
 * @param Object calendar 
 * @returns true if the calendar has atleast one valid (Weekday) property with startHour and endHour. Otherwise, returns false.
 */
export function isValidCalendar(calendar) {
  if (calendar == null) {
    return false;
  }
  let isValid = false;
  for (let i = 0, len = 7; i < len; i++) {
    const workHours = calendar[moment().locale('en').day(i).format('dddd')];
    if(workHours == null || workHours.length == 0) {
      continue;
    }
    if (workHours.some(i => Object.prototype.hasOwnProperty.call(i, 'startHour') && 
                            Object.prototype.hasOwnProperty.call(i, 'endHour'))) {
      isValid = true;
      break;
    }
  }
  return isValid;
}

/**
 * Return a list of work hours of the provided date. 
 * Return empty list when one of the following condition happens:
 * 1. The provided date is non-working day and ignoreLeave is false.
 * 2. The provided date is invalid.
 * 3. The provided date has no valid work hour.
 * 
 * @param {Moment} date DateObject Date used to find out the work hours of that day.
 * @param {Object} calendar An object contains calendar information.
 * @param {Object} options
 * options {Boolean} ignoreLeave: Flag to indicate whether to skip checking Leave. True to skip. Default is false.
 * options {Boolean} ignoreWorkException: Flag to indicate whether to skip checking working exception. True to skip. Default is false.
 * options {Boolean} ignoreWorkExceptionTime: Flag to indicate whether to skip checking time in working exception section. True to skip. Default is false.
 */
 export function workHours(calendar, date, { ignoreLeave=false, ignoreWorkException=false, ignoreWorkExceptionTime=false } = {}) {
  const _cal = calendar != null? calendar : [];
  //Return empty array when targetDate is null or not an instance of Moment class.
  if (date == null || !(date instanceof moment)) {
    return [];
  }
  const targetDate = date.clone().second(0).millisecond(0);
  
  //Looking priority:
  // Leave > Working > day of week.

  if (!ignoreLeave) {
    //Looking for leave exception
    const leaves = _cal['Leave'] || [];
    const leaveApplied = leaves.some(i => {
      if (i.type === 'Leave') {
        const target = targetDate.clone().hour(0).minute(0).valueOf();
        const startLeaveDate = moment.utc(i.startDate, 'YYYY-MM-DD').valueOf();
        const endLeaveDate = moment.utc(i.endDate, 'YYYY-MM-DD').valueOf();
        return target >= startLeaveDate && target <= endLeaveDate;
      }
      return false;
    });
    //If leave exception exists, return defaultValue as no work hour will be considered.
    if (leaveApplied) {
      return [];
    }
  }
  
  if (!ignoreWorkException) {
    //Looking for working exception
    const workings = _cal['Working'] || [];
    const workingException = workings.find(i => {
      if (i.type === 'Working') {
        let target = targetDate.valueOf();
        if (ignoreWorkExceptionTime) {
          target = targetDate.clone().hour(0).minute(0).second(0).millisecond(0).valueOf();
        }
        const startWorkingDate = typeof i.startDate === 'string' ? moment.utc(i.startDate, 'YYYY-MM-DD').valueOf() : i.startDate;
        const endWorkingDate = typeof i.endDate === 'string' ? moment.utc(i.endDate, 'YYYY-MM-DD').add(1, 'day').subtract(1, 'minute').valueOf() : i.endDate;
        return target >= startWorkingDate && target <= endWorkingDate;
      }
    });
    //If working exception exists, return startHour of the exception.
    if (workingException) {
      return [workingException];
    }
  }
  
  //Looking for standard day Of week
  const weekday = _cal[targetDate.locale('en').format('dddd')] || [];
  return weekday
    .filter(i => i.startHour)
    .sort((firstEl, secondEl) => firstEl.startHour > secondEl.startHour? 1 : firstEl.startHour < secondEl.startHour? -1 : 0);
}

/**
 * Return true if the provided date is a working day. Otherwise, return false.
 * 
 * @param {Moment} date 
 * @param {Object} calendar 
 * @returns 
 */
export function isWorkingDay(calendar, date) {
  let _cal = DEFAULT_CALENDAR;
  if (isValidCalendar(calendar)) {
    _cal = calendar;
  }
  return workHours(_cal, date, { ignoreWorkExceptionTime: true }).length > 0;
}

/**
 * Return true if provided value (timeStr) match ##:## or ##:##:## format and the time should be within valid hour, minute and second range.
 * 
 * @param {String} timeStr
 * @returns 
 */
export function isValidTimeStrFormat(timeStr) {
  if (timeStr == null || timeStr.length == 0) {
    return false;
  }
  
  const match = timeStr.match(/^([0-9][0-9]):([0-9][0-9])(:([0-9][0-9]))?$/);
  if (match == null) {
    return false;
  }

  const hourPart = match[1];
  const minutePart = match[2];
  if (parseInt(hourPart) <= 24 && parseInt(minutePart) < 60) {
    const secondPart = match[4] != null? match[4] : null;
    if (secondPart != null && parseInt(secondPart) >= 60) {
      return false;
    }
    return true;
  }
  return false;
}

/**
 * Return true if provided value (dateStr) match YYYY-MM-DD format and the month and date should be within their valid value range respectively.
 * 
 * @param {String} dateStr
 * @returns 
 */
export function isValidDateStrFormat(dateStr) { 
  return dateStr != null && dateStr.match(/^([12]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/) != null;
}

/**
 * WARNING: This is a private method. Please refrain from using it.
 * Get next closest working time if the provided date time is not a valid working time.
 * - Find previous day if the provided date is a non working day and isBackward is true.
 * - Find previous day's latest work hour if the provided time is earlier than earliest work hour and isBackward is true.
 * - Find next day if the provideddate is a non working day and isBackward is false.
 * - Find next day's earliest hour if the provided time is later than the latest work hour and isBackward is false. 
 * 
 * @param {Moment} value 
 * @param {Object} calendar 
 * @returns { value } when valid parameters are passed. Otherwise, { error } when invalid parameter is detected. 
 */
 export function __getValidWorkingTime(calendar, value, { isBackward=false } = {}) {
  //When calendar is not valid, return original value.
  if (!isValidCalendar(calendar)) {
    return { error: 'invalid_calendar' }
  }
  if (value == null || !(value instanceof moment)) {
    return { error: 'invalid_date' }
  }

  let _isBackward = false;
  if (isBackward != null) {
    _isBackward = isBackward;
  }

  let date = value.clone();

  //If it is not in valid working hour, find next working hour section of the day.
  //If it is earlier than earliest working hour, use the earliest working hour.
  //If it is later than latest working hour, find next day, rinse and repeat.
  const findNextWorkingTime = (_calendar, _date) => {
    let nextHour = null; //It is next startHour if isBackward is false. Otherwise it is next endHour.
    let foundWorkHour = null;
    let isBeyondHour = false; //If true, it means the time is either earlier than earliest hour or is later than latest hour.

    //Find out the working hour
    const timeStr = _date.format('HH:mm');
    let workHourList = workHours(_calendar, _date);
    if(_isBackward == null || workHourList.length == 0) {
      if (_isBackward) {
        const nextDay = _date.clone().add(-1, 'days').set('Hour',23).set('minute', 59).set('second', 59);
        return findNextWorkingTime(_calendar, nextDay);
      } else {
        const nextDay = _date.clone().add(1, 'days').set('Hour', 0).set('minute', 0).set('second', 0);
        return findNextWorkingTime(_calendar, nextDay);
      }
    }

    if(_isBackward) {
      workHourList = workHourList.reverse();
    }

    for (let i = 0, len = workHourList.length; i < len; i++) {
      const curWorkHour = workHourList[i];

      if (_isBackward) {
        if (curWorkHour.endHour < timeStr) {
          nextHour = curWorkHour.endHour;
          break;
        }
        if (curWorkHour.startHour <= timeStr && curWorkHour.endHour >= timeStr) {
          foundWorkHour = curWorkHour;
          break;
        }
        if (curWorkHour.startHour > timeStr && len == i+1) {
          isBeyondHour = true;
          break;
        }
      } else {
        if (curWorkHour.startHour > timeStr) {
          nextHour = curWorkHour.startHour;
          break;
        }
        if (curWorkHour.startHour <= timeStr && curWorkHour.endHour >= timeStr) {
          foundWorkHour = curWorkHour;
          break;
        }
        if (curWorkHour.endHour < timeStr && len == i+1) {
          isBeyondHour = true;
          break;
        }
      }
    }
    if (isBeyondHour) {
      if (_isBackward) {
        const nextDay = _date.clone().add(-1, 'days').set('Hour', 23).set('minute', 59).set('second', 59);
        return findNextWorkingTime(_calendar, nextDay);
      } else {
        const nextDay = _date.clone().add(1, 'days').set('Hour', 0).set('minute', 0).set('second', 0);
        return findNextWorkingTime(_calendar, nextDay);
      }
    } else if(foundWorkHour != null) {
      return _date;
    } else {
      return moment.utc(`${_date.format('YYYY-MM-DD')} ${nextHour}:00`, 'YYYY-MM-DD HH:mm:00');
    }
  }

  return { 
    value: findNextWorkingTime(calendar, date)
  }
}

/**
 * WARNING: This is a private method. Please refrain from using it.
 * Get earliest or latest working time of the provided date based on the provided 'isLatest' boolean parameter.
 * Get the previous working day's earliest working time if the provided date is a non working day and isLatest is false.
 * Get the previous working day's latest working time if the provided date is a non working day and isLatest is true.
 * 
 * @param {Moment|Date} date 
 * @param {Object} calendar 
 * @param {Object} options
 * @returns {Object}: { value: '##:##' } when valid parameters are passed. Otherwise, { error } when invalid parameter is detected.
 * Options {Boolean} isLatest: Flag to indicate whether to return earliest or latest hour. Default is false.
 * Options {Boolean} ignoreLeave: Flag to indicate whether to skip checking Leave. True to skip. Default is false.
 * 
 */
export function __getEarliestOrLatestWorkHour(calendar, date, { isLatest=false, ignoreLeave=false } = {}) {
  if (!isValidCalendar(calendar)) {
    return { error: 'invalid_calendar' }
  }
  let targetDate = date;
  if (targetDate == null || !(targetDate instanceof moment)) {
    return { error: 'invalid_date' }
  }

  let wHours = workHours(calendar, date, { ignoreLeave, ignoreWorkExceptionTime: true });
  if (wHours.length == 0) {
    wHours = __closestWorkingDayWorkHours(calendar, date.clone()).value;
  }

  return { 
    value: isLatest? wHours[wHours.length-1].endHour : wHours[0].startHour
  }
}

/**
 * Get earliest or latest working time of the provided date based on the provided 'isLatest' boolean parameter.
 * Get the previous working day's earliest working time if the provided date is a non working day and isLatest is false.
 * Get the previous working day's latest working time if the provided date is a non working day and isLatest is true.
 * 
 * @param {Moment|Date} date 
 * @param {Object} calendar Default calendar is used when invalid calendar is provided.
 * @param {Object} options
 * @returns {String}: '##:##' when valid parameters are passed. Otherwise, NULL when invalid parameter is detected.
 * Options {Boolean} isLatest: Flag to indicate whether to return earliest or latest hour. Default is false.
 * Options {Boolean} ignoreLeave: Flag to indicate whether to skip checking Leave. True to skip. Default is false.
 * 
 */
export function getEarliestOrLatestWorkHour(calendar, date, { isLatest=false, ignoreLeave=false } = {}) {
  let _calendar = DEFAULT_CALENDAR;
  if (isValidCalendar(calendar)) {
    _calendar = calendar;
  }

  if (date == null || !(date instanceof moment)) {
    return null;
  }

  const result = __getEarliestOrLatestWorkHour(_calendar, date, { isLatest, ignoreLeave });
  if (result != null && result.value != null) {
    return result.value;
  }
  return null;
}

/**
 * Get the next available working day. Time will always be reset to 00:00:00 as it is not considered in this function.
 *  
 * @param {Object} calendar 
 * @param {Moment} date 
 * @param {Boolean} isBackward Determine the date finding direction. Find backward if true. Otherwise find forward.
 * @returns null if calendar is invalid or date is null or date is not instance of moment. Otherwise, return a moment date instance.
 */
export function nextAvailableWorkingDay(calendar, date, { isBackward=false } = {}) {
  if (date == null || !(date instanceof moment)) {
    return null;
  }
  if (!isValidCalendar(calendar)) {
    return date.clone();
  }

  let _isBackward = false;
  if (isBackward != null) {
    _isBackward = isBackward || false;
  }

  const _nextAvailableWorkingDay = (cDate) => {
    if (isWorkingDay(calendar, cDate)) {
      return cDate;
    } else {
      if (_isBackward) {
        cDate.subtract(1, 'day');
      } else {
        cDate.add(1, 'day');
      }
      return _nextAvailableWorkingDay(cDate);
    }
  }

  let cDate = date.clone().hour(0).minute(0).second(0);
  return _nextAvailableWorkingDay(cDate);
}

/**
 * WARNING: This is a private method. Please refrain from using it.
 * Get the closest (previous) working day's work hours.
 * 
 * @param {Object} calendar 
 * @param {Moment} date 
 * @returns {Object}: { value } when valid parameters are passed. Otherwise, { error } when invalid parameter is detected.
 */
export function __closestWorkingDayWorkHours(calendar, date) {
  if (!isValidCalendar(calendar)) {
    return { error: 'invalid_calendar' }
  }  
  if(date == null || !(date instanceof moment)) {
    return { error: 'invalid_date' }
  }
  const whs = workHours(calendar, date, { ignoreLeave: false, ignoreWorkExceptionTime: true });
  if (whs.length == 0) {
    return __closestWorkingDayWorkHours(calendar, date.clone().subtract(1, 'day'));
  }
  return { value: whs };
}

/**
 * WARNING: This is a private method.
 * Get the total work capacity of the provided date.
 * 
 * @param {Object} calendar 
 * @param {Moment} date 
 * @returns {Object}: { value } if passed parameters are valid. Otherwise, { error } if invalid parameter is detected.
 * The value is number in minute unit;
 */
export function __workCapacity(calendar, date) {
  if (!isValidCalendar(calendar)) {
    return { error: 'invalid_calendar' }
  }  
  if(date == null || !(date instanceof moment)) {
    return { value: 0 }
  }

  const wHours = workHours(calendar, date, { ignoreWorkExceptionTime: true });
  const totalMinutes = wHours.reduce((acc, curValue) => {
    let token = curValue.startHour.split(':');
    const startHourInMinutes = moment.duration({ hours: token[0], minutes: token[1] }).asMinutes();
    token = curValue.endHour.split(':');
    const endHourInMinutes = moment.duration({ hours: token[0], minutes: token[1] }).asMinutes();
    acc += (endHourInMinutes - startHourInMinutes);
    return acc;
  }, 0);
  return { value: totalMinutes }
}

/**
 * WARNING: This is a private method.
 * Get the total work capacity of the provided date by adding up duration from one of the following ranges base on the 'isStart' option:
 * If provided date is a non working day, the closest (previous) working day's work hours are referenced.
 *
 * [isAuto: true]
 * //isStart: true
 * 1. If provided date's time is earlier than the earliest work hour, count starts from the earliest hour to the latest hour.
 * 2. If provided date's time is in between the valid work hours, count starts from the time to the latest hour.
 * 3. If provided date's time is later than the latest work hour , the work capacity is 0.
 * //isStart: false
 * 1. If provided date's time is earlier than the  earliest work hour, the work capacity is 0.
 * 2. If provided date's time is in between the valid work hours, count starts from the earliest hour to time.
 * 3. If provided date's time is later than latest work hour, count starts from the time to latest work hour. 
 * 
 * [isAuto: false]
 * //isStart: true
 * 1. Provided date's time to latest work hour.
 * 2. provided date's time to the 24:00 if provided date's time is later than the latest work hour.
 * //isStart: false
 * 3. Earliest work hour to provided date's time
 * 4. 00:00 to provided date's time if provided date's time is earlier than the earliest work hour.
 * 
 * @param {Object} calendar 
 * @param {Moment} date 
 * 
 * @returns {Object}: { value } | { error }
 */
export function __specialWorkCapacity(calendar, date, { isStart=true, isAuto=true } = {}) {
  //Validate parameters
  if (!isValidCalendar(calendar)) {
    return { error: 'invalid_calendar' }
  }
  if(date == null || !(date instanceof moment)) {
    return { error: 'invalid_date' }
  }

  //Sanitize options
  let _isStart = true;
  if (isStart != null) {
    _isStart = isStart;
  }
  let _isAuto = true;
  if (isAuto != null) {
    _isAuto = isAuto;
  }

  let wHours = workHours(calendar, date, { ignoreLeave: false, ignoreWorkException: false, ignoreWorkExceptionTime: true });
  if (wHours.length == 0) {
    wHours = __closestWorkingDayWorkHours(calendar, date.clone().subtract(1, 'day')).value;
  }
  
  const earliestWorkHour = wHours.reduce((min, curValue) => {
    if(min == null) {
      return curValue.startHour;
    }
    return min < curValue.startHour? min : curValue.startHour;
  }, null);
  const latestWorkHour = wHours.reduce((max, curValue) => {
    if(max == null) {
      return curValue.endHour;
    }
    return max > curValue.endHour? max : curValue.endHour;
  }, null);

  
  const diffDuration = (st, ct) => {
    return moment.duration({ hours: ct[0], minutes: ct[1] }).asMinutes() - moment.duration({ hours: st[0], minutes: st[1] }).asMinutes();
  }

  let timeStr = date.format('HH:mm');
  const returnObj = { value: 0 } //Initialize returnObj.value to 0. Default value.
  if (_isAuto) {
    if (_isStart) {
      if (timeStr <= latestWorkHour) {
        //When time is earlier than the earliest work hour, set time to earliestWorkHour.
        if (timeStr < earliestWorkHour) {
          timeStr = earliestWorkHour;
        }

        const timeToken = timeStr.split(':');
        returnObj.value = wHours.reduce((acc, curValue) => {
          if(timeStr > curValue.endHour) {
            return acc;
          } else if (timeStr < curValue.startHour) {
            return acc + diffDuration(curValue.startHour.split(':'), curValue.endHour.split(':'));
          } else {
            return acc + diffDuration(timeToken, curValue.endHour.split(':'));
          }
        }, 0);
      }
    } else { // isAuto: true, isStart:false
      if (timeStr > earliestWorkHour) {
        //When time is earlier than the earliest work hour, set time to earliestWorkHour.
        if (timeStr  > latestWorkHour) {
          timeStr = latestWorkHour;
        }
        const timeToken = timeStr.split(':');
        returnObj.value = wHours.reduce((acc, curValue) => {
          if(timeStr < curValue.startHour) {
            return acc;
          } else if (timeStr > curValue.endHour) {
            return acc + diffDuration(curValue.startHour.split(':'), curValue.endHour.split(':'));
          } else {
            return acc + diffDuration(curValue.startHour.split(':'), timeToken);
          }
        }, 0);
      }
    }
  } else {
    if (_isStart) {
      if (timeStr > latestWorkHour) {
        //time -> 24:00
  
        // latest      time         24:00
        // |             |----------->|
        const token = timeStr.split(':');
        returnObj.value = moment.duration({ hours: 24 }).asMinutes() - moment.duration({ hours: token[0], minutes: token[1] }).asMinutes();
      } else {
        //time -> latest work hour
  
        // 00:00     earliest    time                  latest
        // |            |          |-------------------->|
        // or
        // 00:00     time  earliest                    latest
        // |            |-----|------------------------->|
        let total = 0;
        const timeToken = timeStr.split(':');
        if (timeStr < earliestWorkHour) {
          total = diffDuration(timeToken, earliestWorkHour.split(':'));
        }
        returnObj.value = total + wHours.reduce((acc, curValue) => {
          if(timeStr > curValue.endHour) {
            return acc;
          } else if (timeStr < curValue.startHour) {
            return acc + diffDuration(curValue.startHour.split(':'), curValue.endHour.split(':'));
          } else {
            return acc + diffDuration(timeToken, curValue.endHour.split(':'));
          }
        }, 0);
      }
    } else { //isStart: false
      if (timeStr < earliestWorkHour) {
        //00:00 -> time
  
        // 00:00             time        earliest
        // |------------------>|            |
        const token = timeStr.split(':');
        returnObj.value = moment.duration({ hours: token[0], minutes: token[1] }).asMinutes();
      } else {
        //earliest work hour -> time
  
        // 00:00    earliest              time        latest
        // |           |------------------>|            |
        // or
        // 00:00    earliest             latest        time
        // |           |-------------------|----------->|
        let total = 0;
        const timeToken = timeStr.split(':');
        if (timeStr > latestWorkHour) {
          total = diffDuration(latestWorkHour.split(':'), timeToken);
        }
        
        returnObj.value = total + wHours.reduce((acc, curValue) => {
          if(timeStr < curValue.startHour) {
            return acc;
          } else if (timeStr > curValue.endHour) {
            return acc + diffDuration(curValue.startHour.split(':'), curValue.endHour.split(':'));
          } else {
            return acc + diffDuration(curValue.startHour.split(':'), timeToken);
          }
        }, 0);
      }
    }
  }

  return returnObj;
}

export function __diffDuration(startTime, closeTime) {
  if (!isValidTimeStrFormat(startTime)) {
    return { error: 'invalid_startTime' }
  }
  if (!isValidTimeStrFormat(closeTime)) {
    return { error: 'invalid_closeTime' }
  }
  const st = startTime.split(':');
  const ct = closeTime.split(':');
  return { value: (parseInt(ct[0]) * 60) + parseInt(ct[1]) - (parseInt(st[0]) * 60)  - parseInt(st[1]) };
}

/**
 * Calculate the total work capacity between fromDate and toDate.
 * 
 * @param {Object} calendar 
 * @param {Moment} fromDate 
 * @param {Moment} toDate 
 * @param {Object} options
 * options {Boolean} isAuto: Task auto schedule mode. Default is true.
 * options {Number} maxDurationInMinutes: Maximum duration allowed. Default: 10 years in minutes or 10 * 250 * 8 * 60.
 * @returns {Object}: { value } when valid parameters are provided. Otherwise, { error } when invalid parameter is detected.
 */
export function __calculateWorkCapacity(calendar, fromDate, toDate, { isAuto=true, maxDurationInMinutes=1200000 } = {}) {
  if (!isValidCalendar(calendar)) {
    return { error: 'invalid_calendar' }
  }
  if (fromDate == null || !(fromDate instanceof moment)) {
    return { error: 'invalid_fromDate' }
  }
  if (toDate == null || !(toDate instanceof moment)) {
    return { error: 'invalid_toDate' }
  }

  let _isAuto = true;
  if (isAuto != null) {
    _isAuto = isAuto;
  }

  let _maxDurationInMinutes = 1200000;
  if (maxDurationInMinutes != null && typeof maxDurationInMinutes === 'number' && maxDurationInMinutes > 0) {
    _maxDurationInMinutes = maxDurationInMinutes;
  }

  const isStart = fromDate.diff(toDate) < 0;

  //A fromDate and toDate are on same day.
  if (fromDate.format('YYYY-MM-DD') == toDate.format('YYYY-MM-DD')) {
    const dateTime1 = isStart? fromDate.clone() : toDate.clone();
    const dateTime2 = isStart? toDate.clone() : fromDate.clone();
    const time1 = dateTime1.format('HH:mm');
    const time2 = dateTime2.format('HH:mm');
    let wHours = workHours(calendar, fromDate, { ignoreLeave: false, ignoreWorkExceptionTime: true });

    if (wHours.length == 0) {
      if (_isAuto) { 
        //return value: 0 when provided day is a non working day and isAuto is true
        return { value: 0 }
      }
      wHours = __closestWorkingDayWorkHours(calendar, fromDate).value;
    }

    const earliest = wHours[0].startHour;
    const latest = wHours[wHours.length-1].endHour;
    let total = 0;
    if (time2 <= earliest || time1 >= latest) {
      //00:00           time1         time2         earliest     latest             24:00
      // |                |============>|              |           |                  |
      // or
      //00:00          earliest       latest         time1       time2              24:00
      // |                |             |              |==========>|                  |
      total = __diffDuration(time1, time2).value;
    } else {
      total = __specialWorkCapacity(calendar, dateTime1, { isStart: true, isAuto: _isAuto }).value;
      if (time2 <= latest) {
        //00:00          earliest       time1         time2       latest              24:00
        // |                |            |=============>|           |                   |
        // or
        //00:00           time1        earliest         time2      latest             24:00
        // |                |=============|=============>|           |                  |
        total -= __specialWorkCapacity(calendar, dateTime2, { isStart: true, isAuto: _isAuto }).value;
      } else if (time2 > latest) {
        //00:00           time1        earliest        latest      time2              24:00
        // |                |=============|==============|==========>|                  |
        // or
        //00:00          earliest       time1          latest      time2              24:00
        // |                |             |==============|==========>|                  |
        total += __diffDuration(latest, time2).value;
      }
    }
    if (total > _maxDurationInMinutes) {
      total = _maxDurationInMinutes;
    }
    return { value: total }
  } 
  //B) First Day + [Day in between] + Last Day
  else { 
    //first Day + last Day
    let total = 0;
    let wHours = workHours(calendar, fromDate, { ignoreLeave: false, ignoreWorkExceptionTime: true });
    if (!(wHours.length == 0 && _isAuto)) { 
      //Count the work capacity when task is manual scheduled, even though the day is a non working day.
      total = __specialWorkCapacity(calendar, fromDate, { isStart, isAuto: _isAuto }).value; //first day capacity
    }
    
    wHours = workHours(calendar, toDate, { ignoreLeave: false, ignoreWorkExceptionTime: true });
    if (!(wHours.length == 0 && _isAuto)) { 
      //Count the work capacity when task is manual scheduled, even though the day is a non working day.
      total += __specialWorkCapacity(calendar, toDate, { isStart: !isStart, isAuto: _isAuto }).value; //last day capacity
    }
    
    //days in between.
    const time1 = isStart? fromDate.clone() : toDate.clone();
    const time2 = isStart? toDate.clone() : fromDate.clone();
    time1.hour(0).minute(0).second(0).millisecond(0);
    time2.hour(0).minute(0).second(0).millisecond(0);
    time1.add(1, 'day');

    while(time1.diff(time2) < 0) {
      total += __workCapacity(calendar, time1.clone()).value;
      if (total > _maxDurationInMinutes) {
        break;
      }
      time1.add(1, 'day');
    }

    if (total > _maxDurationInMinutes) {
      total = _maxDurationInMinutes;
    }
    return { value: total }
  }
}

/**
 * 
 * @param {Object} calendar 
 * @param {Moment} date 
 * @param {Number} duration 
 * @param {Object} options 
 * options {Boolean} isStart: Indicate the date is a startDate or closeDate. Different calculation will be used. Default is true.
 * @returns { value } The value (final date) is a moment instance.
 * { error } will be returned when one of the parameters is invalid.
 */
export function __addDurationToDate(calendar, date, duration, { isStart=true, isAuto=true } = {}) {
  if (!isValidCalendar(calendar)) {
    return { error: 'invalid_calendar' }
  }
  if (date == null || !(date instanceof moment)) {
    return { error: 'invalid_date' }
  }
  if (duration == null || typeof duration != 'number') {
    return { error: 'invalid_duration'}
  }
  
  if (duration < 1) {
    return { value: date.clone() }
  }

  let balance = duration;
  let wHours = workHours(calendar, date, { ignoreLeave: false, ignoreWorkExceptionTime: true });
  if (wHours.length == 0 && !isAuto) {
    wHours = __closestWorkingDayWorkHours(calendar, date.clone().subtract(1, 'day')).value;
  }
  
  let targetTime = date.format('HH:mm');
  let finalDate = date.clone();
  let finalTime = null;
  //Special calculation for first day when first day is a working day.
  if (wHours.length != 0) {
    if (isStart) {
      let capacity = 0;
      for (let i = 0, len = wHours.length; i < len; i++) {
        const curHour = wHours[i];
        
        if (i == 0 && targetTime < curHour.startHour && !isAuto) {
          //Time       Earliest
          // |----------->|
          capacity = __diffDuration(targetTime, curHour.startHour).value;
          if (balance <= capacity) {
            finalTime = moment.utc(targetTime, 'HH:mm').add(balance, 'minutes').format('HH:mm');
            balance = 0;
            break;
          }
          balance -= capacity;
        }
        
        if (targetTime <= curHour.startHour) {
          //Time        startHour                  endHour        latest
          // |=============>|-------------------------|-------------|
          capacity = __diffDuration(curHour.startHour, curHour.endHour).value;
          if (balance <= capacity) {
            finalTime = moment.utc(curHour.startHour, 'HH:mm').add(balance, 'minutes').format('HH:mm');
            balance = 0;
            break;
          }
          balance -= capacity;
        } else if (targetTime > curHour.startHour && targetTime <= curHour.endHour) {
          //startHour          Time          endHour              latest
          // |------------------|==============>|-------------------|
          capacity = __diffDuration(targetTime, curHour.endHour).value;
          if (balance <= capacity) {
            finalTime = moment.utc(targetTime, 'HH:mm').add(balance, 'minutes').format('HH:mm');
            balance = 0;
            break;
          }
          balance -= capacity;
        }
  
        if (i == (len-1) && targetTime > curHour.endHour && !isAuto) {
          //latest       Time                 24:00
          // |------------|====================>|
          const st = targetTime.split(':');
          capacity = moment.duration({ hours: 24 }).asMinutes() - moment.duration({ hours: st[0], minutes: st[1] }).asMinutes()
          if (balance <= capacity) {
            finalTime = moment.utc(targetTime, 'HH:mm').add(balance, 'minutes').format('HH:mm');
            balance = 0;
            break;
          }
          balance -= capacity;
        }
      }
      
    } else {
      wHours = wHours.reverse();
      let capacity = 0;
      for (let i = 0, len = wHours.length; i < len; i++) {
        const curHour = wHours[0];
        
        if (i == 0 && targetTime > curHour.endHour) {
          //Latest       Time
          // |<-----------|
          capacity = __diffDuration(curHour.endHour, targetTime).value;
          if (balance <= capacity) {
            finalTime = moment.utc(targetTime, 'HH:mm').subtract(balance, 'minutes').format('HH:mm');
            balance = 0;
            break;
          }
          balance -= capacity;
        }
  
        if (targetTime >= curHour.endHour) {
          //earliest    startHour                  endHour        time
          // |--------------|<========================|------------|
          capacity = __diffDuration(curHour.startHour, curHour.endHour).value;
          if (balance <= capacity) {
            finalTime = moment.utc(curHour.endHour, 'HH:mm').subtract(balance, 'minutes').format('HH:mm');
            balance = 0;
            break;
          }
          balance -= capacity;
        } else if (targetTime >= curHour.startHour && targetTime < curHour.endHour) {
          //earliest        startHour         time              endhour
          // |<-----------------|<=============|                  |
          capacity = __diffDuration(curHour.startHour, targetTime).value;
          if (balance <= capacity) {
            finalTime = moment.utc(targetTime, 'HH:mm').subtract(balance, 'minutes').format('HH:mm');
            balance = 0;
            break;
          }
          balance -= capacity;
        }
  
        if (i == (len-1) && targetTime < curHour.startHour && !isAuto) {
          //00:00        Time                 Earliest
          // |<-----------|                     |
          const st = targetTime.split(':');
          capacity = moment.duration({ hours: st[0], minutes: st[1] }).asMinutes()
          if (balance <= capacity) {
            finalTime = moment.utc(targetTime, 'HH:mm').subtract(balance, 'minutes').format('HH:mm');
            balance = 0;
            break;
          }
          balance -= capacity;
        }
      }
    }
  }

  if (balance == 0) {
    return {
      value: moment.utc(`${finalDate.format('YYYY-MM-DD')} ${finalTime}`, 'YYYY-MM-DD HH:mm')
    }
  }
  //End - Special calculation for first day.

  const nextDay = (d) => {
    return isStart? d.add(1, 'day') : d.subtract(1, 'day');
  }

  const findFinalDateTime = (_date) => {
    let isNotDone = true;
    let finalDateTime = null;
    let curDate = _date.clone();
    do {
      wHours = workHours(calendar, curDate, { ignoreLeave: false, ignoreWorkException: false, ignoreWorkExceptionTime: true });
      if (wHours.length == 0) {
        nextDay(curDate);
        continue;
      }
      if (!isStart) {
        wHours = wHours.reverse();
      }
      
      for (let i = 0, len = wHours.length; i < len; i++) {
        const curHour = wHours[i];
        const capacity = __diffDuration(curHour.startHour, curHour.endHour).value;
        if (balance <= capacity) {
          if(isStart) {
            finalDateTime = moment.utc(`${curDate.format('YYYY-MM-DD')} ${curHour.startHour}`, 'YYYY-MM-DD HH:mm').add(balance, 'minutes');
          } else {
            finalDateTime = moment.utc(`${curDate.format('YYYY-MM-DD')} ${curHour.endHour}`, 'YYYY-MM-DD HH:mm').subtract(balance, 'minutes');
          }
          balance = 0;
          break;
        }
        balance -= capacity;
      }
      if (balance > 0) {
        nextDay(curDate);
        continue;
      }
      isNotDone = false;
    } while (isNotDone);
    return finalDateTime;
  }  
  nextDay(finalDate);
  return {
    value: findFinalDateTime(finalDate)
  }
}

/**
 * 
 * The following logic replicates Ms Project.
 * When in AUTO schedule mode and project scheduleFrom is ASAP:
 * 1) startTime is earlier than the earliest work hour. startTime is adjusted to the earliest work hour.
 * 2) startTime is later than latest workhour. The startTime is adjusted to next day's earliest work hour.
 * 3) closeTime is earlier than the earliest work hour. It remains untouched. 
 * 4) closeTime is later than the latest work hour. It remains untouched.
 * The duration calculation will not count those time outside of effective work hour.
 * 
 * When in AUTO schedule mode and project scheduleFrom is ALAP:
 * 1) closeTime is earlier than the earliest work hour. The closeTime is adjusted to previous day's latest work hour.
 * 2) closeTime is later than latest workhour. closeTime is adjusted to the latest work hour.
 * 3) startTime is earlier than the earliest work hour. It remains untouched. 
 * 4) startTime is later than the latest work hour. It remains untouched. * 
 * The duration calculation will not count those time outside of effective work hour.
 * 
 * 
 * @param {*} payload
 * - {String} trigger: Default is 'duration'. Used to determine which value is changed. Candidate: [startDate, startTime, closeDate, closeTime, duration, constraintType and constraintDate, startDateByConstraint, closeDateByConstraint].
 * - {String} startDateStr: Expected format: 'YYYY-MM-DD'
 * - {String} startTimeStr: Expected format: 'HH:mm'
 * - {String} closeDateStr: Expected format: 'YYYY-MM-DD'
 * - {String} closeTimeStr: Expected format: 'HH:mm'
 * - {String} durationDisplay: ExpectedFormat: '#[Unit]'. E.g.: 1D, 1.5D, 1W, 1M, 2Y
 * - {Object} calendar: Calendar Object.
 * - {Boolean} projScheduleFromStart: Default is true.
 * - {Boolean} taskAutoScheduleMode: Default is true.
 * - {String} constraintType: E.g.: As_soon_as_possible, As_late_as_possible.
 * - {String} constraintDateStr: Expected format: 'YYYY-MM-DD'
 * - {Boolean} lockDuration: Default is false.
 * - {String} oldDateStr: Expected format: 'YYYY-MM-DD'. It is previous startDate when trigger is 'startDate' or 'startTime'. Otherwise, previous closeDate when trigger is 'closeDate' or 'closeTime'.
 * - {String} oldTimeStr: Expected format: 'HH:mm'. It is previous startTime when trigger is 'startDate' or 'startTime'. Otherwise, previous closeTime when trigger is 'closeDate' or 'closeTime'.
 * - {Boolean} skipOutOfProjectDateCheck: Default is false. When true, skip checking if provided trigger related date is outside of project start and end date range.
 * - {Boolean} autoMoveForNonWorkingDay: Default is false. When true, date which falls on non working day will be adjusted to next available working day automatically.
 * - {Boolean} resizeMode: Default is false. Calculation of Trigger 'duration' will not be affected when resizeMode is true.
 * @returns 
 */
export function calcDateTimeDuration({ 
  trigger
  , startDateStr, startTimeStr
  , closeDateStr, closeTimeStr
  , durationDisplay
  , calendar
  , projScheduleFromStart
  , taskAutoScheduleMode
  , constraintType, constraintDateStr
  , lockDuration=false
  , oldDateStr, oldTimeStr
  , projectStartDateStr, projectCloseDateStr
  , skipOutOfProjectDateCheck=false
  , autoMoveForNonWorkingDay=false
  , resizeMode=false
  , maxDurationInDays=2500
  , durationConversionOpts={}
} = {}) {
  //1. Sanitize parameters and initialize local variables. 
  //------------------------------------------------------
  //1.1 trigger
  let _trigger = 'duration';
  if (isValidTrigger(trigger)) {
    _trigger = trigger;
  }
  //1.2 calendar
  let _calendar = DEFAULT_CALENDAR;
  if (isValidCalendar(calendar)) {
    _calendar = calendar;
  }
  //1.3 taskAutoScheduleMode
  let _tAutoScheduleMode = taskAutoScheduleMode != null? taskAutoScheduleMode : true;
  //1.4 projScheduleFrom
  let _projScheduleFromStart = projScheduleFromStart != null? projScheduleFromStart : true;
  //1.5 constraintType, constraintDateStr
  let _constraintType = isValidConstraintType(constraintType)? constraintType : (_projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP);
  let _constraintDateStr = _constraintType != CONSTRAINT_TYPES.ASAP && _constraintType != CONSTRAINT_TYPES.ALAP? constraintDateStr : null;
  if (_constraintDateStr === undefined || (_constraintDateStr != null && !isValidDateStrFormat(_constraintDateStr))) {
    _constraintDateStr = null;
  }


  if (TRIGGERS.CONSTRAINT_TYPE != _trigger && _constraintDateStr == null && 
      _constraintType != CONSTRAINT_TYPES.ASAP && _constraintType != CONSTRAINT_TYPES.ALAP) {
    _constraintType = _projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP;
  }

  //1.6 lockDuration
  let _lockDuration = false;
  if (lockDuration != null) {
    _lockDuration = lockDuration;
  }

  let _maxDurationInMinutes = 1200000;
  if (maxDurationInDays != null && typeof maxDurationInDays === 'number' && maxDurationInDays > 0) {
    _maxDurationInMinutes = maxDurationInDays * (durationConversionOpts.hourPerDay * 60);
  }

  //1.7 oldDateStr, oldTimeStr
  let oldDate = null;
  if (isValidDateStrFormat(oldDateStr)) {
    oldDate = moment.utc(oldDateStr, 'YYYY-MM-DD');
  }
  if (oldDate != null) {
    if (isValidTimeStrFormat(oldTimeStr)) {
      const oldTimeToken = oldTimeStr.split(':');
      oldDate.hour(oldTimeToken[0]).minute(oldTimeToken[1]);
    } else {
      const isBackward = _trigger == TRIGGERS.CLOSE_DATE || _trigger == TRIGGERS.CLOSE_TIME;
      oldDate = __getValidWorkingTime(_calendar, oldDate, { isBackward }).value;
      const suggestedTimeStr = __getEarliestOrLatestWorkHour(_calendar, oldDate, { isLatest: isBackward }).value;
      const oldTimeToken = suggestedTimeStr.split(':');
      oldDate.hour(oldTimeToken[0]).minute(oldTimeToken[1]);
    }
  }

  //1.8 startDateStr, startTimeStr
  let _startDateStr = startDateStr;
  if (!isValidDateStrFormat(_startDateStr)) {
    _startDateStr = null;
  }

  let _startTimeStr = startTimeStr;
  if (!isValidTimeStrFormat(_startTimeStr)) {
    _startTimeStr = null;
  }

  //1.9 closeDateStr, closeTimeStr
  let _closeDateStr = closeDateStr;
  if (!isValidDateStrFormat(_closeDateStr)) {
    _closeDateStr = null;
  }

  let _closeTimeStr = closeTimeStr;
  if (!isValidTimeStrFormat(_closeTimeStr)) {
    _closeTimeStr = null;
  }

  //2.0 durationDisplay
  const { unit: dUnit, value: dValue } = analyzeDurationAUM(durationDisplay, null);
  let durationUnit = dUnit;
  let _durationDisplay = dValue != null? `${dValue}${dUnit}` : null;

  //2.1 projectStartDateStr, projectCloseDateStr, skipOutOfProjectDateCheck
  let projectStartDate = null;
  if (isValidDateStrFormat(projectStartDateStr)) {
    projectStartDate = moment.utc(projectStartDateStr, 'YYYY-MM-DD');
  }

  let projectCloseDate = null;
  if (isValidDateStrFormat(projectCloseDateStr)) {
    projectCloseDate = moment.utc(projectCloseDateStr, 'YYYY-MM-DD');
  }

  let _skipOutOfProjectDateCheck = false;
  if (skipOutOfProjectDateCheck != null) {
    _skipOutOfProjectDateCheck = skipOutOfProjectDateCheck;
  }

  //2.2 autoMoveForNonWorkingDay
  let _autoMoveForNonWorkingDay = false;
  if (autoMoveForNonWorkingDay != null) {
    _autoMoveForNonWorkingDay = autoMoveForNonWorkingDay;
  }

  //2.3 resizeMode
  let _resizeMode = false;
  if (resizeMode != null) {
    _resizeMode = resizeMode;
  }

  //ReturnObj initialization
  const returnObj = {
    startDateStr: _startDateStr
    , startTimeStr: _startTimeStr
    , closeDateStr: _closeDateStr
    , closeTimeStr: _closeTimeStr
    , durationDisplay: _durationDisplay
    , constraintType: _constraintType
    , constraintDateStr: _constraintDateStr
  }
  
  const calcCloseDateOrDuration = (oldDate, { startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay, constraintType, constraintDateStr }) => {
    const result = {
      startDateStr
      , startTimeStr
      , closeDateStr
      , closeTimeStr
      , durationDisplay
      , constraintType
      , constraintDateStr
    }
    const startDate = moment.utc(`${startDateStr} ${startTimeStr}`, 'YYYY-MM-DD HH:mm');

    const useDuration = (customDurationDisplay=null) => {
      let duration = convertDisplayToDuration(customDurationDisplay != null? customDurationDisplay : durationDisplay, durationConversionOpts).value;

      // Reset duration to allowed duration limit (maxDurationInMinutes) if duration is greater than that limit.
      if (duration > _maxDurationInMinutes) {
        duration = _maxDurationInMinutes;
        result.durationDisplay = convertDurationToDisplay(duration, durationUnit, durationConversionOpts);
      }

      //calculate closeDate using duration and startDate
      const closeDate = __addDurationToDate(_calendar, startDate, duration, { isStart: true, isAuto: _tAutoScheduleMode }).value;
      result.closeDateStr = closeDate.format('YYYY-MM-DD');
      result.closeTimeStr = closeDate.format('HH:mm');
    }

    const useCloseDate = () => {
      const closeDate = moment.utc(closeDateStr, 'YYYY-MM-DD');
      let closeTime = closeTimeStr;
      //calculate duration using startDate and closeDate
      if (closeTime == null) {
        //set the latest work hour of the day to closeTime
        closeTime = __getEarliestOrLatestWorkHour(_calendar, closeDate, { isLatest: true }).value;
        result.closeTimeStr = closeTime;
      }
      const ct = closeTime.split(':');
      closeDate.hour(ct[0]).minute(ct[1]).second(0).millisecond(0);
      if(startDate.diff(closeDate) > 0) {
        result.durationDisplay = '0D';
        useDuration(result.durationDisplay);
      } else {
        const duration = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
        if (duration == _maxDurationInMinutes) {
          // Possibly hitting the allowed maximum duration limit when true, recalculate the startDate with closeDate and duration.
          const newStartDate = __addDurationToDate(_calendar, closeDate, duration, { isStart: false, isAuto: _tAutoScheduleMode }).value;
          result.startDateStr = newStartDate.format('YYYY-MM-DD');
          result.startTimeStr = newStartDate.format('HH:mm');
        }
        result.durationDisplay = convertDurationToDisplay(duration, durationUnit, durationConversionOpts);
      }
    }

    const useGap = () => {
      //1) Find out the original gap between old startDate and closeDate.
      //2) Find out the new gap between new startDate and closeDate.
2        //3.1) Use the new gap to recalculate closeDate.
      //4) If projectSecduleFromStart is false (or resizeMode is true):
        //4.1) If duration is valid and duration is greater than new gap:
          //Use duration to recalculate startDate.
        //4.2) If duration is invalid or duration is less than new gap:
          //Use new gap to recalculate startDate.
      const closeDate = moment.utc(closeDateStr, 'YYYY-MM-DD');
      let closeTime = closeTimeStr;
      //calculate duration using startDate and closeDate
      if (closeTime == null) {
        //set the latest work hour of the day to closeTime
        closeTime = __getEarliestOrLatestWorkHour(_calendar, closeDate, { isLatest: true }).value;
        result.closeTimeStr = closeTime;
      }
      const ct = closeTime.split(':');
      closeDate.hour(ct[0]).minute(ct[1]).second(0).millisecond(0);

      if (_projScheduleFromStart && !_resizeMode) {
        const oldStartDate = oldDate.clone();
        const gapInMinutes = __calculateWorkCapacity(_calendar, oldStartDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
        //Recalculate closeDate by using the gap between old startDate and closeDate.
        useDuration(`${gapInMinutes}m`);
      } else {
        //Calculate the new gap between (new) startDate and closeDate.
        let newGapInMinutes = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
        
        const isValidDuration = durationDisplay != null;
        let durationUnit = 'D';
        if (isValidDuration) {
          durationUnit = analyzeDurationAUM(durationDisplay).unit;
          const duration = convertDisplayToDuration(durationDisplay, durationConversionOpts).value;
          if (duration > newGapInMinutes) {
            newGapInMinutes = duration;
          }
        }

        //durationDisplay should not be updated with the new gap. Unless the original duration is null.
        if (result.durationDisplay == null) {
          result.durationDisplay = convertDurationToDisplay(newGapInMinutes, durationUnit, durationConversionOpts)
        }

        //calculate startDate using duration and closeDate
        const newStartDate = __addDurationToDate(_calendar, closeDate, newGapInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
        result.startDateStr = newStartDate.format('YYYY-MM-DD');
        result.startTimeStr = newStartDate.format('HH:mm');
      }
    }

    //Check the validity of the remaining fields: Duration and closeDate. closeTime is ignored.
    const isValidCloseDate = closeDateStr != null;
    const isValidDuration = durationDisplay != null;
    if (_resizeMode && isValidCloseDate) {
      if (_lockDuration) {
        useGap();
      } else {
        useCloseDate();
      }
    } else if (_projScheduleFromStart) {
      //Priority: 
      //When lockDuration is true: gap > duration > closeDate
      //When lockDuration is false: duration > closeDate
      if (_lockDuration && isValidCloseDate) {
        //Find out the gap between startDate and closeDate
        //Use the gap to recalculate closeDate or startDate.
        useGap();
      } else if (isValidDuration) {
        useDuration();
      } else if (isValidCloseDate) {
        useCloseDate();
      }
    } else {
      //Priority: 
      //When lockDuration is true: gap > closeDate > duration
      //When lockDuration is false: closeDate > duration
      if (_lockDuration && isValidCloseDate) {
        //Find out the gap between startDate and closeDate
        //Use the gap to recalculate closeDate or startDate.
        useGap();
      } else if (isValidCloseDate) {
        useCloseDate();
      } else if (isValidDuration) {
        useDuration();
      }
    }
    //Update constraint
    if (TRIGGERS.START_DATE_BY_CONSTRAINT != _trigger) {
      result.constraintType = _projScheduleFromStart? CONSTRAINT_TYPES.SNET : CONSTRAINT_TYPES.SNLT;
    }
    result.constraintDateStr = result.startDateStr;
    return result;
  }

  const calcStartDateOrDuration = (oldDate, { startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay, constraintType, constraintDateStr } = {}) => {
    const result = {
      startDateStr
      , startTimeStr
      , closeDateStr
      , closeTimeStr
      , durationDisplay
      , constraintType
      , constraintDateStr
    } 
    const closeDate = moment.utc(`${closeDateStr} ${closeTimeStr}`, 'YYYY-MM-DD HH:mm');

    const useDuration = (customDurationDisplay=null) => {
      let duration = convertDisplayToDuration(customDurationDisplay !=null? customDurationDisplay : durationDisplay, durationConversionOpts).value;

      // Reset duration to allowed duration limit (maxDurationInMinutes) if duration is greater than that limit.
      if (duration > _maxDurationInMinutes) {
        duration = _maxDurationInMinutes;
        result.durationDisplay = convertDurationToDisplay(duration, durationUnit, durationConversionOpts);
      }
      //calculate  closeDate using duration and startDate
      const startDate = __addDurationToDate(_calendar, closeDate, duration, { isStart: false, isAuto: _tAutoScheduleMode }).value;
      result.startDateStr = startDate.format('YYYY-MM-DD');
      result.startTimeStr = startDate.format('HH:mm');
    }

    const useStartDate = () => {
      const startDate = moment.utc(startDateStr, 'YYYY-MM-DD');
      let startTime = startTimeStr;
      //calculate duration using startDate and closeDate
      if (startTime == null) {
        //set the latest work hour of the day to closeTime
        startTime = __getEarliestOrLatestWorkHour(_calendar, startDate, { isLatest: false }).value;
        result.startTimeStr = startTime;
      }
      const st = startTime.split(':');
      startDate.hour(st[0]).minute(st[1]).second(0).millisecond(0);

      if(startDate.diff(closeDate) > 0) {
        result.durationDisplay = '0D';
        useDuration(result.durationDisplay);
      } else {
        const duration = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
        if (duration == _maxDurationInMinutes) {
          // Possibly hitting the allowed maximum duration limit when true, recalculate the closeDate with startDate and duration.
          const newCloseDate = __addDurationToDate(_calendar, startDate, duration, { isStart: true, isAuto: _tAutoScheduleMode }).value;
          result.closeDateStr = newCloseDate.format('YYYY-MM-DD');
          result.closeTimeStr = newCloseDate.format('HH:mm');
        }
        result.durationDisplay = convertDurationToDisplay(duration, durationUnit, durationConversionOpts);
      }
    }

    const useGap = () => {
      //1) Find out the new gap between startDate and new closeDate.
      //2) If projectScheduleFromStart is false:
        //2.1) Use the new gap to recalculate startDate.
      //3) If projectSecduleFromStart is true:
        //3.1) If duration is valid and duration is greater than new gap:
          //Use duration to recalculate closeDate.
        //3.2) If duration is invalid or duration is less than new gap:
          //Use new gap to recalculate closeDate.

      const startDate = moment.utc(startDateStr, 'YYYY-MM-DD');
      let startTime = startTimeStr;
      //calculate duration using startDate and closeDate
      if (startTime == null) {
        //set the latest work hour of the day to startTime
        startTime = __getEarliestOrLatestWorkHour(_calendar, startDate, { isLatest: false }).value;
        result.startTimeStr = startTime;
      }
      const st = startTime.split(':');
      startDate.hour(st[0]).minute(st[1]).second(0).millisecond(0);

      if (_projScheduleFromStart || _resizeMode) {
        //Calculate the new gap between (new) startDate and closeDate.
        let newGapInMinutes = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
        const isValidDuration = durationDisplay != null;
        let durationUnit = 'D';
        if (isValidDuration) {
          durationUnit = analyzeDurationAUM(durationDisplay).unit;
          const duration = convertDisplayToDuration(durationDisplay, durationConversionOpts).value;
          if (duration > newGapInMinutes) {
            newGapInMinutes = duration;
          }
        }

        //durationDisplay should not be updated with the new gap. Unless the original duration is null.
        if (result.durationDisplay == null) {
          result.durationDisplay = convertDurationToDisplay(newGapInMinutes, durationUnit, durationConversionOpts)
        }

        //calculate startDate using duration and closeDate
        const newCloseDate = __addDurationToDate(_calendar, startDate, newGapInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
        result.closeDateStr = newCloseDate.format('YYYY-MM-DD');
        result.closeTimeStr = newCloseDate.format('HH:mm');
      } else {
        const oldCloseDate = oldDate.clone();
        const gapInMinutes = __calculateWorkCapacity(_calendar, startDate, oldCloseDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
        //Recalculate closeDate by using the gap between startDate and old closeDate.
        useDuration(`${gapInMinutes}m`);
      }
    }

    //Check the validity of the remaining fields: Duration and startDate. startTime is ignored.
    const isValidStartDate = startDateStr != null;
    const isValidDuration = durationDisplay != null;
    if (_resizeMode && isValidStartDate) {
      if (_lockDuration) {
        useGap();
      } else {
        useStartDate();
      }
    } else if (!_projScheduleFromStart) {
      //Priority: 
      //When lockDuration is true: gap > duration > startDate
      //When lockDuration is false: duration > startDate
      if (_lockDuration && isValidStartDate) {
        //Find out the gap between startDate and closeDate
        //Use the gap to recalculate closeDate or startDate.
        useGap();
      } else if (isValidDuration) {
        useDuration();
      } else if (isValidStartDate) {
        useStartDate();
      }
    } else {
      //Priority: 
      //When lockDuration is true: gap > startDate > duration
      //When lockDuration is false: startDate > duration
      if (_lockDuration && isValidStartDate) {
        //Find out the gap between startDate and closeDate
        //Use the gap to recalculate closeDate or startDate.
        useGap();
      } else if (isValidStartDate) {
        useStartDate();
      } else if (isValidDuration) {
        useDuration();
      }
    }
    //Update constraint
    if (TRIGGERS.CLOSE_DATE_BY_CONSTRAINT != _trigger) {
      result.constraintType = _projScheduleFromStart? CONSTRAINT_TYPES.FNET : CONSTRAINT_TYPES.FNLT;
    }
    result.constraintDateStr = result.closeDateStr;
    return result;
  }

  const calcStartDateOrCloseDate = ({ startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay, constraintType, constraintDateStr }) => {
    const result = {
      startDateStr
      , startTimeStr
      , closeDateStr
      , closeTimeStr
      , durationDisplay
      , constraintType
      , constraintDateStr
    }
    let duration = convertDisplayToDuration(durationDisplay, durationConversionOpts).value;
    
    // Reset duration to allowed duration limit (max) if duration is greater than that limit
    if (duration > _maxDurationInMinutes) {
      duration = _maxDurationInMinutes;
      result.durationDisplay = convertDurationToDisplay(duration, durationUnit, durationConversionOpts);
    }

    const useStartDate = ({ customDateStr=null } = {}) => {
      let startDate = moment.utc(startDateStr, 'YYYY-MM-DD');
      let startTime = startTimeStr;

      if (customDateStr != null) {
        startDate = moment.utc(customDateStr, 'YYYY-MM-DD');
        startTime = null;
        result.startDateStr = startDate.format('YYYY-MM-DD');
      } else {
          let toUseConstraintDate = false;
          if (constraintType == CONSTRAINT_TYPES.MSO && constraintDateStr != null) {
            toUseConstraintDate = true;
          } else if (constraintType == CONSTRAINT_TYPES.SNET && 
              constraintDateStr != null && startDateStr < constraintDateStr) {
            toUseConstraintDate = true;
          } else if (constraintType == CONSTRAINT_TYPES.SNLT && 
              constraintDateStr != null && startDateStr > constraintDateStr) {
            toUseConstraintDate = true;
          }

        if (toUseConstraintDate) {
          startDate = moment.utc(constraintDateStr, 'YYYY-MM-DD');
          startTime = null;
          result.startDateStr = startDate.format('YYYY-MM-DD');
        }
      }

      if (isWorkingDay(_calendar, startDate)) {
        //calculate duration using startDate and closeDate
        if (startTime == null) {
          //set the latest work hour of the day to closeTime
          startTime = __getEarliestOrLatestWorkHour(_calendar, startDate, { isLatest: false }).value;
          result.startTimeStr = startTime;
        } else if (_tAutoScheduleMode) {
          const token = startTime.split(':');
          startDate.hour(token[0]).minutes(token[1]);
          const suggestedDateTime = __getValidWorkingTime(_calendar, startDate, { isBackward: false }).value;
          startDate = suggestedDateTime;
          startTime = suggestedDateTime.format('HH:mm');
          result.startDateStr = startDate.format('YYYY-MM-DD');
          result.startTimeStr = startTime;
        } 
      } else {
        if (_tAutoScheduleMode) {
          const suggestedDateTime = __getValidWorkingTime(_calendar, startDate, { isBackward: false }).value;
          startDate = suggestedDateTime;
          startTime = __getEarliestOrLatestWorkHour(_calendar, suggestedDateTime, { isLatest: false }).value;
          result.startDateStr = startDate.format('YYYY-MM-DD');
          result.startTimeStr = startTime;
        } else if (startTime == null) {
          startTime = __getEarliestOrLatestWorkHour(_calendar, startDate, { isLatest: false }).value;
          result.startTimeStr = startTime;
        }
      }
      //Apply startTime to startDate because startDate may not have latest startTime value.
      const st = startTime.split(':');
      startDate.hour(st[0]).minute(st[1]).second(0).millisecond(0);

      let gapInMinutes = 0;
      if (_lockDuration && closeDateStr != null) {
        let originalCloseDate = moment.utc(closeDateStr, 'YYYY-MM-DD');
        if (closeTimeStr != null) {
          const cToken = closeTimeStr.split(':');
          originalCloseDate.hour(cToken[0]).minute(cToken[1]);
        }
        originalCloseDate = __getValidWorkingTime(_calendar, originalCloseDate, { isBackward: true }).value;
        
        gapInMinutes = __calculateWorkCapacity(_calendar, startDate, originalCloseDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
      }

      if (duration >= gapInMinutes) {
        //calculate  closeDate using duration and startDate
        const closeDate = __addDurationToDate(_calendar, startDate, duration, { isStart: true, isAuto: _tAutoScheduleMode }).value;
        const _newCloseDateStr = closeDate.format('YYYY-MM-DD');
        if (constraintType == CONSTRAINT_TYPES.FNET && 
            constraintDateStr != null && _newCloseDateStr < constraintDateStr) {
          useCloseDate({ customDateStr: constraintDateStr });
          return;
        } else if (constraintType == CONSTRAINT_TYPES.FNLT &&
            constraintDateStr != null && _newCloseDateStr > constraintDateStr) {
          useCloseDate({ customDateStr: constraintDateStr });
          return;
        } else if (constraintType == CONSTRAINT_TYPES.MFO &&
            constraintDateStr != null && _newCloseDateStr != constraintDateStr) {
          useCloseDate({ customDateStr: constraintDateStr });
          return;
        }

        //Check if calculated closeDate is beyond project closeDate and show OUT_OF_PROJECT_DATE_RANGE modal if conditions met.
        const dateOnly = moment.utc(_newCloseDateStr, 'YYYY-MM-DD');
        const prevDateOnly = result.closeDateStr != null? moment.utc(result.closeDateStr, 'YYYY-MM-DD') : null;
        if (!_skipOutOfProjectDateCheck && projectCloseDate != null && projectCloseDate.diff(dateOnly) < 0) {
          if (prevDateOnly == null || projectCloseDate.diff(prevDateOnly) >= 0) {
            returnObj.modal = {
              type: MODAL_TYPES.OUT_OF_PROJECT_DATE_RANGE
              , trigger: _trigger
              , dateStr: closeDate.format('YYYY-MM-DD')
              , projectDateStr: projectCloseDateStr
              , isStart: false
            }
            return;
          }
        }
        
        result.closeDateStr = closeDate.format('YYYY-MM-DD');
        result.closeTimeStr = closeDate.format('HH:mm');
      }
    }
    
    const useCloseDate = ({ customDateStr=null} = {}) => {
      let closeDate = moment.utc(closeDateStr, 'YYYY-MM-DD');
      let closeTime = closeTimeStr;

      if (customDateStr != null) {
        closeDate = moment.utc(customDateStr, 'YYYY-MM-DD');
        closeTime = null;
        result.closeDateStr = closeDate.format('YYYY-MM-DD');
      } else {
        let toUseConstraintDate = false;
        if (constraintType == CONSTRAINT_TYPES.MFO && constraintDateStr != null) {
          toUseConstraintDate = true;
        } else if (constraintType == CONSTRAINT_TYPES.FNET && 
            constraintDateStr != null && closeDateStr < constraintDateStr) {
          toUseConstraintDate = true;
        } else if (constraintType == CONSTRAINT_TYPES.FNLT && 
            constraintDateStr != null && closeDateStr > constraintDateStr) {
          toUseConstraintDate = true;
        }

        if (toUseConstraintDate) {
          closeDate = moment.utc(constraintDateStr, 'YYYY-MM-DD');
          closeTime = null;
          result.closeDateStr = closeDate.format('YYYY-MM-DD');
        }
      } 

      if (isWorkingDay(_calendar, closeDate)) {
         //calculate duration using startDate and closeDate
        if (closeTime == null) {
          //set the latest work hour of the day to closeTime
          closeTime = __getEarliestOrLatestWorkHour(_calendar, closeDate, { isLatest: true }).value;
          result.closeTimeStr = closeTime;
        } else if (_tAutoScheduleMode) {
          const token = closeTime.split(':');
          closeDate.hour(token[0]).minutes(token[1]);
          const suggestedDateTime = __getValidWorkingTime(_calendar, closeDate, { isBackward: true }).value;
          closeDate = suggestedDateTime;
          closeTime = suggestedDateTime.format('HH:mm');
          result.closeDateStr = closeDate.format('YYYY-MM-DD');
          result.closeTimeStr = closeTime;
        }
      } else {
        if (_tAutoScheduleMode) {
          const suggestedDateTime = __getValidWorkingTime(_calendar, closeDate, { isBackward: true }).value;
          closeDate = suggestedDateTime;
          closeTime = __getEarliestOrLatestWorkHour(_calendar, suggestedDateTime, { isLatest: true }).value;
          result.closeDateStr = closeDate.format('YYYY-MM-DD');
          result.closeTimeStr = closeTime;
        } else {
          closeTime = __getEarliestOrLatestWorkHour(_calendar, closeDate, { isLatest: true }).value;
          result.closeTimeStr = closeTime;
        }
      }
      //Apply closeTime to closeDate because closeDate may not have latest closeTime value.
      const ct = closeTime.split(':');
      closeDate.hour(ct[0]).minute(ct[1]).second(0).millisecond(0);

      let gapInMinutes = 0;
      if (_lockDuration) {
        let originalStartDate = null;
        if (startDateStr != null) {
          originalStartDate = moment.utc(startDateStr, 'YYYY-MM-DD');
          originalStartDate = __getValidWorkingTime(_calendar, originalStartDate, { isBackward: false }).value;
        }
        gapInMinutes = __calculateWorkCapacity(_calendar, originalStartDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
      }
      
      if (duration >= gapInMinutes) {
        //calculate closeDate using duration and startDate
        const startDate = __addDurationToDate(_calendar, closeDate, duration, { isStart: false, isAuto: _tAutoScheduleMode }).value;
        const _newStartDateStr = startDate.format('YYYY-MM-DD');
        if (constraintType == CONSTRAINT_TYPES.SNET && 
            constraintDateStr != null && _newStartDateStr < constraintDateStr) {
          useStartDate({ customDateStr: constraintDateStr });
          return;
        } else if (constraintType == CONSTRAINT_TYPES.SNLT &&
            constraintDateStr != null && _newStartDateStr > constraintDateStr) {
          useStartDate({ customDateStr: constraintDateStr });
          return;
        } else if (constraintType == CONSTRAINT_TYPES.MSO &&
            constraintDateStr != null && _newStartDateStr != constraintDateStr) {
          useStartDate({ customDateStr: constraintDateStr });
          return;
        }

        //Check if calculated startDate is before project startDate and show OUT_OF_PROJECT_DATE_RANGE modal if conditions met.
        const dateOnly = moment.utc(_newStartDateStr, 'YYYY-MM-DD');
        const prevDateOnly = result.startDateStr != null? moment.utc(result.startDateStr, 'YYYY-MM-DD') : null;
        if (!_skipOutOfProjectDateCheck && projectStartDate != null && projectStartDate.diff(dateOnly) > 0) {
          if (prevDateOnly == null || projectStartDate.diff(prevDateOnly) <= 0) {
            returnObj.modal = {
              type: MODAL_TYPES.OUT_OF_PROJECT_DATE_RANGE
              , trigger: _trigger
              , dateStr: startDate.format('YYYY-MM-DD')
              , projectDateStr: projectStartDateStr
              , isStart: true
            }
            return;
          }
        }

        result.startDateStr = startDate.format('YYYY-MM-DD');
        result.startTimeStr = startDate.format('HH:mm');
      }
    }

    const isValidStartDate = _startDateStr != null;
    const isValidCloseDate = _closeDateStr != null;

    if (_projScheduleFromStart) {
      if (isValidStartDate) {
        useStartDate();
      } else if (isValidCloseDate) {
        useCloseDate();
      }
    } else {
      if (isValidCloseDate) {
        useCloseDate();
      } else if (isValidStartDate) {
        useStartDate();
      }
    }
    return result;
  }

  //2.4 determine which field to be calculated and perform calculation.
  if (TRIGGERS.START_DATE == _trigger || TRIGGERS.START_DATE_BY_CONSTRAINT == _trigger) {
    if (_startDateStr == null) {
      //Clear startTimeStr and durationDisplay and prepare returnobj.
      returnObj.startDateStr = null;
      returnObj.startTimeStr = null;
      returnObj.durationDisplay = null;
      if (isConstraintTypeStartDateRelated(returnObj.constraintType)) {
        returnObj.constraintType = _projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP;
        returnObj.constraintDateStr = null;
      }
      return returnObj;
    }

    let startDate = moment.utc(_startDateStr, 'YYYY-MM-DD');

    //Prepare details for confirmation dialog for date outside of project date range when conditions are met.
    if (!_skipOutOfProjectDateCheck && projectStartDate != null && 
        startDate != null && projectStartDate.diff(startDate.clone().hour(0).minute(0).second(0).millisecond(0)) > 0) {
      if (oldDate == null || (oldDate != null && projectStartDate.diff(oldDate.clone().hour(0).minute(0).second(0).millisecond(0)) <= 0)) {
        const suggestedDateTime = __getValidWorkingTime(_calendar, startDate, { isBackward: false }).value;
        returnObj.modal = {
          type: MODAL_TYPES.OUT_OF_PROJECT_DATE_RANGE
          , trigger: _trigger
          , dateStr: _startDateStr
          , projectDateStr: projectStartDateStr
          , isStart: true
          , suggestedDateStr: suggestedDateTime.format('YYYY-MM-DD')
          , suggestedTimeStr: suggestedDateTime.format('HH:mm')
        }
        return returnObj;
      }
    }

    //Check if it is a non working day
    const isWorkDay = isWorkingDay(_calendar, startDate);

    if (_tAutoScheduleMode && !isWorkDay) {
      const suggestedDateTime = __getValidWorkingTime(_calendar, startDate, { isBackward: false }).value;
      if (_autoMoveForNonWorkingDay) { //Set startDate with the suggested date & time when autoMoveForNonWorkingDay is true.
        _startDateStr = suggestedDateTime.format('YYYY-MM-DD');
        _startTimeStr = suggestedDateTime.format('HH:mm');
      } else {
        //If it is non working day and in auto schedule mode, prepare returnObj with modal properties. Used in rendering confirmation dialog.
        returnObj.modal = {
          type: MODAL_TYPES.NON_WORK_DAY
          , trigger: _trigger
          , dateStr: _startDateStr
          , isBackward: false
          , suggestedDateStr: suggestedDateTime.format('YYYY-MM-DD')
          , suggestedTimeStr: suggestedDateTime.format('HH:mm')
        }
        return returnObj;
      }
    }

    if (_startTimeStr == null) {
      //Get the earliest work hour of the day. If startDate is a non working day, look for closest (previous) working day's earliest work hour.
      _startTimeStr = __getEarliestOrLatestWorkHour(_calendar, startDate).value;
    } else if (_tAutoScheduleMode && _projScheduleFromStart) {
      const suggestedDateTime = __getValidWorkingTime(_calendar, moment.utc(`${_startDateStr} ${_startTimeStr}`, 'YYYY-MM-DD HH:mm'), { isBackward: false }).value;
      _startDateStr = suggestedDateTime.format('YYYY-MM-DD');
      _startTimeStr = suggestedDateTime.format('HH:mm');
    }
    returnObj.startDateStr = _startDateStr;
    returnObj.startTimeStr = _startTimeStr;
    //Calculate value
    return calcCloseDateOrDuration(oldDate, returnObj);
  } else if (TRIGGERS.START_TIME == _trigger) {
    if (_startTimeStr == null) {
      //Clear startTimeStr and durationDisplay and prepare returnobj.
      returnObj.startDateStr = null;
      returnObj.startTimeStr = null;
      returnObj.durationDisplay = null;
      if (isConstraintTypeStartDateRelated(returnObj.constraintType)) {
        returnObj.constraintType = _projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP;
        returnObj.constraintDateStr = null;
      }
      return returnObj;
    }
    //Do nothing when startDate is null
    if (_startDateStr == null) {
      return returnObj;
    }
    
    if (_tAutoScheduleMode && _projScheduleFromStart) {
      const suggestedDateTime = __getValidWorkingTime(_calendar, moment.utc(`${_startDateStr} ${_startTimeStr}`, 'YYYY-MM-DD HH:mm'), { isBackward: false }).value;
      _startDateStr = suggestedDateTime.format('YYYY-MM-DD');
      _startTimeStr = suggestedDateTime.format('HH:mm');
    }
    returnObj.startDateStr = _startDateStr;
    returnObj.startTimeStr = _startTimeStr;

    //Calculate value
    return calcCloseDateOrDuration(oldDate, returnObj);

  } else if (TRIGGERS.CLOSE_DATE == _trigger || TRIGGERS.CLOSE_DATE_BY_CONSTRAINT == _trigger) {
    if (_closeDateStr == null) {
      //Clear closeTimeStr and durationDisplay and prepare returnobj.
      returnObj.closeDateStr = null;
      returnObj.closeTimeStr = null;
      returnObj.durationDisplay = null;
      if (isConstraintTypeCloseDateRelated(returnObj.constraintType)) {
        returnObj.constraintType = _projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP;
        returnObj.constraintDateStr = null;
      }
      return returnObj;
    }

    let closeDate = moment.utc(_closeDateStr, 'YYYY-MM-DD');

    //Prepare details for confirmation dialog for date outside of project date range when conditions are met.
    if (!_skipOutOfProjectDateCheck && projectCloseDate != null && closeDate != null && 
        projectCloseDate.diff(closeDate.clone().hour(0).minute(0).second(0).millisecond(0)) < 0) {
      if (oldDate == null || (oldDate != null && projectCloseDate.diff(oldDate.clone().hour(0).minute(0).second(0).millisecond(0)) >= 0)) {
        const suggestedDateTime = __getValidWorkingTime(_calendar, closeDate, { isBackward: true }).value;
        returnObj.modal = {
          type: MODAL_TYPES.OUT_OF_PROJECT_DATE_RANGE
          , trigger: _trigger
          , dateStr: _closeDateStr
          , projectDateStr: projectCloseDateStr
          , isStart: false
          , suggestedDateStr: suggestedDateTime.format('YYYY-MM-DD')
          , suggestedTimeStr: suggestedDateTime.format('HH:mm')
        }
        return returnObj;
      }
    }

    //Check if it is a non working day
    const isWorkDay = isWorkingDay(_calendar, closeDate);
    if (_tAutoScheduleMode && !isWorkDay) {
      const suggestedDateTime = __getValidWorkingTime(_calendar, closeDate, { isBackward: true }).value;
      if (_autoMoveForNonWorkingDay) { //Set closeDate with the suggested date & time when autoMoveForNonWorkingDay is true.
        closeDate = suggestedDateTime.clone();
        _closeDateStr = suggestedDateTime.format('YYYY-MM-DD');
        _closeTimeStr = suggestedDateTime.format('HH:mm');
        returnObj.closeDateStr = _closeDateStr;
        returnObj.closeTimeStr = _closeTimeStr;
      } else {
        //If it is non working day and in auto schedule mode, prepare returnObj with modal properties. Used in rendering confirmation dialog.
        returnObj.modal = {
          type: MODAL_TYPES.NON_WORK_DAY
          , trigger: _trigger
          , dateStr: _closeDateStr
          , isBackward: true
          , suggestedDateStr: suggestedDateTime.format('YYYY-MM-DD')
          , suggestedTimeStr: suggestedDateTime.format('HH:mm')
        }
        return returnObj;
      }
    }
    //Get the latest work hour of the day. If closeDate is a non working day, look for closest (previous) working day's latest work hour.
    const latestHour = __getEarliestOrLatestWorkHour(_calendar, closeDate, { isLatest: true }).value;
    if (_closeTimeStr == null || (_tAutoScheduleMode && _closeTimeStr > latestHour)) {
      _closeTimeStr = latestHour;
    }
    returnObj.closeTimeStr = _closeTimeStr;

    //Calculate value
    return calcStartDateOrDuration(oldDate, returnObj);
  } else if (TRIGGERS.CLOSE_TIME == _trigger) {
    if (_closeTimeStr == null) {
      //Clear closeTimeStr and durationDisplay and prepare returnobj.
      returnObj.closeDateStr = null;
      returnObj.closeTimeStr = null;
      returnObj.durationDisplay = null;
      if (isConstraintTypeCloseDateRelated(returnObj.constraintType)) {
        returnObj.constraintType = _projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP;
        returnObj.constraintDateStr = null;
      }
      return returnObj;
    }
    //Do nothing when startDate is null
    if (_closeDateStr == null) {
      return returnObj;
    }

    if (_tAutoScheduleMode && !_projScheduleFromStart) {
      const suggestedDateTime = __getValidWorkingTime(_calendar, moment.utc(`${_closeDateStr} ${_closeTimeStr}`, 'YYYY-MM-DD HH:mm'), { isBackward: true }).value;
      _closeDateStr = suggestedDateTime.format('YYYY-MM-DD');
      _closeTimeStr = suggestedDateTime.format('HH:mm');
    }
    returnObj.closeDateStr = _closeDateStr;
    returnObj.closeTimeStr = _closeTimeStr;

    const latestHour = __getEarliestOrLatestWorkHour(_calendar, moment.utc(_closeDateStr, 'YYYY-MM-DD'), { isLatest: true }).value;
    if(_tAutoScheduleMode && _closeTimeStr > latestHour) {
      _closeTimeStr = latestHour;
    }
    returnObj.closeTimeStr = _closeTimeStr;
    //Calculate value
    return calcStartDateOrDuration(oldDate, returnObj);
  } else if (TRIGGERS.CONSTRAINT_TYPE == _trigger || TRIGGERS.CONSTRAINT_DATE == _trigger) {
    if (_constraintDateStr != null) {
      const payload = {
        trigger: TRIGGERS.START_DATE_BY_CONSTRAINT
        , startDateStr: _startDateStr
        , startTimeStr: _startTimeStr
        , closeDateStr: _closeDateStr
        , closeTimeStr: _closeTimeStr
        , durationDisplay: _durationDisplay
        , calendar: _calendar
        , projScheduleFromStart: true
        , taskAutoScheduleMode: _tAutoScheduleMode
        , constraintType: _constraintType
        , constraintDateStr: _constraintDateStr
        , lockDuration: _lockDuration
        , oldDateStr: _startDateStr
        , oldTimeStr: _startTimeStr
        , projectStartDateStr
        , projectCloseDateStr
        , skipOutOfProjectDateCheck: _skipOutOfProjectDateCheck
        , autoMoveForNonWorkingDay: _autoMoveForNonWorkingDay
        , resizeMode: false
        , maxDurationInDays
      }

      const _constraintDate = moment.utc(_constraintDateStr, 'YYYY-MM-DD');
      let forceUpdate = false;
      if (CONSTRAINT_TYPES.MSO == _constraintType || 
          CONSTRAINT_TYPES.SNET == _constraintType || 
          CONSTRAINT_TYPES.SNLT == _constraintType) {
        const _startDate = _startDateStr != null? moment.utc(_startDateStr, 'YYYY-MM-DD'): null;
        if (_startDate == null) {
          forceUpdate = true;
        } else if (CONSTRAINT_TYPES.MSO == _constraintType && _constraintDate.diff(_startDate) != 0) {
          forceUpdate = true;
        } else if (CONSTRAINT_TYPES.SNET == _constraintType && _constraintDate.diff(_startDate) > 0) {
          forceUpdate = true;
        } else if (CONSTRAINT_TYPES.SNLT == _constraintType && _constraintDate.diff(_startDate) < 0) {
          forceUpdate = true;
        }
        if (forceUpdate) {
          payload.startDateStr = _constraintDateStr;
          payload.startTimeStr = null;
          
        }
      } else {
        const _closeDate = _closeDateStr != null? moment.utc(_closeDateStr, 'YYYY-MM-DD'): null;
        if (_closeDate == null) {
          forceUpdate = true;
        } else if (CONSTRAINT_TYPES.MFO == _constraintType && _constraintDate.diff(_closeDate) != 0) {
          forceUpdate = true;
        } else if (CONSTRAINT_TYPES.FNET == _constraintType && _constraintDate.diff(_closeDate) > 0) {
          forceUpdate = true;
        } else if (CONSTRAINT_TYPES.FNLT == _constraintType && _constraintDate.diff(_closeDate) < 0) {
          forceUpdate = true;
        }
        if (forceUpdate) {
          payload.trigger = TRIGGERS.CLOSE_DATE_BY_CONSTRAINT;
          payload.projScheduleFromStart = false;
          payload.oldDateStr = _closeDateStr;
          payload.oldTimeStr = _closeTimeStr;
          payload.closeDateStr = _constraintDateStr;
          payload.closeTimeStr = null;
        }
      }
      if (forceUpdate) {
        return calcDateTimeDuration(payload);
      }
    }
    //Return original values as no change is required.
    return returnObj;
  } else { //duration
    if (_durationDisplay == null) {
      if (_projScheduleFromStart) {
        //Clear closeTimeStr and durationDisplay and prepare returnobj.
        if (_startDateStr != null) {
          returnObj.closeDateStr = null;
          returnObj.closeTimeStr = null;
        }
        returnObj.durationDisplay = null;
        return returnObj;
      } else {
        //Clear startTimeStr and durationDisplay and prepare returnobj.
        if (_closeDateStr != null) {
          returnObj.startDateStr = null;
          returnObj.startTimeStr = null;
        }
        returnObj.durationDisplay = null;
        return returnObj;
      }
    }
    return calcStartDateOrCloseDate(returnObj);
  }
}

/**
 * 
  * @param {*} payload
 * //Main fields involved in the calculation
 * - {String} startDateStr: Expected format: 'YYYY-MM-DD'
 * - {String} startTimeStr: Expected format: 'HH:mm'
 * - {String} closeDateStr: Expected format: 'YYYY-MM-DD'
 * - {String} closeTimeStr: Expected format: 'HH:mm'
 * - {String} durationDisplay: ExpectedFormat: '#[Unit]'. E.g.: 1D, 1.5D, 1W, 1M, 2Y
 * - {String} constraintType: E.g.: As_soon_as_possible, As_late_as_possible.
 * - {String} constraintDateStr: Expected format: 'YYYY-MM-DD'
 * 
 * //State of flag to dictate the calculation flow:
 * - {String} trigger: Default is 'duration'. Used to determine which value is changed. Candidate: [startDate, startTime, closeDate, closeTime, duration, constraintType and constraintDate, startDateByConstraint, closeDateByConstraint].
 * - {Boolean} resizeMode: Default is false. Calculation of Trigger 'duration' will not be affected when resizeMode is true.
 * - {Object} calendar: Calendar Object.
 * - {Boolean} lockDuration: Default is false.
 * - {Boolean} projScheduleFromStart: Default is true.
 * - {Boolean} taskAutoScheduleMode: Default is true.
 * - {String} projectStartDateStr: Expected format: 'YYYY-MM-DD'
 * - {String} projectCloseDateStr: Expected format: 'YYYY-MM-DD'
 * - {Boolean} skipOutOfProjectDateCheck: Default is false. When true, skip checking if provided trigger related date is outside of project start and end date range.
 * - {Boolean} autoMoveForNonWorkingDay: Default is false. When true, date which falls on non working day will be adjusted to next available working day automatically.
 * 
 * //Previous values will be used in dialog prompt for changes confirmation: e.g.: Inform and ask user whether agree to proceed with the constraint change.
 * - {String} prevStartDateStr: Expected format: 'YYYY-MM-DD'. It is previous startDate when trigger is 'startDate' or 'startTime'. Otherwise, previous closeDate when trigger is 'closeDate' or 'closeTime'.
 * - {String} prevStartTimeStr: Expected format: 'HH:mm'. It is previous startTime when trigger is 'startDate' or 'startTime'. Otherwise, previous closeTime when trigger is 'closeDate' or 'closeTime'.
 *
 * Notes:
 * - Order of dialog prompts: Out-of-project-date-range > non-work -> constraint-change.
 * @returns 
 */
export function calcDateTimeDurationv2({
  //State or flag which affects the calculation flow.
  trigger
  , resizeMode=false
  , calendar  
  , lockDuration=false  
  , projScheduleFromStart=true
  , taskAutoScheduleMode=true
  , projectStartDateStr, projectCloseDateStr
  , skipOutOfProjectDateCheck=false
  , autoMoveForNonWorkingDay=false  
  , maxDurationInDays=2500
  , disableDurationBuffer=false
  , autoConstraintChange=false
  //Values
  , startDateStr, startTimeStr
  , closeDateStr, closeTimeStr  
  , durationDisplay  
  , constraintType, constraintDateStr
  //Previous values will be used in dialog prompt for changes confirmation: e.g.: Inform and ask user whether agree to proceed with the day set on non-working day.
  , prevDateStr, prevTimeStr
  //Options: { hourPerDay, hourPerWeek, dayPerMonth, dayPerYear } //default value will be used if not provided
  , durationConversionOpts={}
  //AddOn: 
  //Meant to be used by calcRoundedDuration().
  //It is only applicable for calculating missing duration
  //Round up the calculated duration and recalculate new dateTime (either startTime or closeTime) based on the given trigger
  , enableRoundUpResult=false
} = {}) {
  ////// 1.0 Validate parameters //////
  //Description: Either set a default value or throw an exception if the parameter is invalid  
    
  //trigger: Default to duration. Possible value: startDate, startTime, closeDate, closeTime, duration, constraintType, constraintDate
  //resizeMode: Default false.
  //calendar: Default to DEFAULT_CALENDAR.
  //lockDuration: Default false.
  //projectScheduleFromStart: Default true.
  //taskAutoScheduleMode: Default true.
  //projectStartDateStr/projectCloseDateStr: When projectStartDateStr/projectCloseDateStr is invalid (in term of date format, not null), reset to null
  //skipOutOfProjectDateCheck: Default false.
  //autoMoveForNonWorkingDay: Default false.
  //maxDurationInDays: Default 2500. 
  //autoConstraintChange: Default false.
  //startDate/closeDate/startTime/closeTime: When startDate/closeDate/startTime/closeTime is invalid (in term of date format, not null), reset to null
  //durationDisplay: Default is null.
  //constraintType: Default ASAP if projectScheduleFromStart is true; or ALAP if projectScheduleFromStart is false.
  //constraintDate: Default is null.
  
  // prevDateStr, prevTimeStr: When prevDate is invalid (in term of date format, not null), reset to null; When prevTime is invalid, reset to earliest workhour or latest workhour depends on trigger value.
  // prevConstraintType, prevConstraintDateStr: Default ASAP if projectScheduleFromStart is true; or ALAP if projectScheduleFromStart is false.
  // prevDurationDisplay: Default is null.
  
  //trigger
  let _trigger = 'duration';
  if (isValidTrigger(trigger)) {
    _trigger = trigger;
  }

  //resizeMode
  let _resizeMode = false;
  if (resizeMode != null) {
    _resizeMode = resizeMode;
  }

  //calendar
  let _calendar = DEFAULT_CALENDAR;
  if (isValidCalendar(calendar)) {
    _calendar = calendar;
  }

  //lockDuration
  let _lockDuration = false;
  if (lockDuration != null) {
    _lockDuration = lockDuration;
  }

  //projScheduleFrom
  let _projScheduleFromStart = projScheduleFromStart != null? projScheduleFromStart : true;

  //taskAutoScheduleMode
  let _tAutoScheduleMode = taskAutoScheduleMode != null? taskAutoScheduleMode : true;

  //projectStartDateStr, projectCloseDateStr, skipOutOfProjectDateCheck
  let projectStartDate = null;
  if (isValidDateStrFormat(projectStartDateStr)) {
    projectStartDate = moment.utc(projectStartDateStr, 'YYYY-MM-DD');
  }
  let projectCloseDate = null;
  if (isValidDateStrFormat(projectCloseDateStr)) {
    projectCloseDate = moment.utc(projectCloseDateStr, 'YYYY-MM-DD');
  }

  //skipOutOfProjectDateCheck
  let _skipOutOfProjectDateCheck = false;
  if (skipOutOfProjectDateCheck != null) {
    _skipOutOfProjectDateCheck = skipOutOfProjectDateCheck;
  }

  //autoMoveForNonWorkingDay
  let _autoMoveForNonWorkingDay = false;
  if (autoMoveForNonWorkingDay != null) {
    _autoMoveForNonWorkingDay = autoMoveForNonWorkingDay;
  }
  
  //maxDurationInDays
  let _maxDurationInMinutes = 1200000;
  if (maxDurationInDays != null && typeof maxDurationInDays === 'number' && maxDurationInDays > 0) {
    _maxDurationInMinutes = maxDurationInDays * (durationConversionOpts.hourPerDay * 60);
  }

  let _disableDurationBuffer = false;
  if (disableDurationBuffer != null) {
    _disableDurationBuffer = disableDurationBuffer;
  }

  //autoConstraintChange
  let _autoConstraintChange = false;
  if (autoConstraintChange != null) {
    _autoConstraintChange = autoConstraintChange;
  }

  //startDateStr, startTimeStr
  let _startDateStr = startDateStr;
  if (!isValidDateStrFormat(_startDateStr)) {
    _startDateStr = null;
  }
  let _startTimeStr = startTimeStr;
  if (!isValidTimeStrFormat(_startTimeStr)) {
    _startTimeStr = null;
  }

  //closeDateStr, closeTimeStr
  let _closeDateStr = closeDateStr;
  if (!isValidDateStrFormat(_closeDateStr)) {
    _closeDateStr = null;
  }
  let _closeTimeStr = closeTimeStr;
  if (!isValidTimeStrFormat(_closeTimeStr)) {
    _closeTimeStr = null;
  }

  //durationDisplay
  const { unit: dUnit, value: dValue } = analyzeDurationAUM(durationDisplay, null);
  let durationUnit = dUnit; //It is needed later when convert new duration back to durationDisplay.
  let _durationDisplay = dValue != null? `${dValue}${dUnit}` : null;

  //constraintType, constraintDateStr
  let _constraintType = isValidConstraintType(constraintType)? constraintType : (_projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP);
  let _constraintDateStr = _constraintType != CONSTRAINT_TYPES.ASAP && _constraintType != CONSTRAINT_TYPES.ALAP? constraintDateStr : null;
  if (_constraintDateStr === undefined || (_constraintDateStr != null && !isValidDateStrFormat(_constraintDateStr))) {
    _constraintDateStr = null;
  }
  if (TRIGGERS.CONSTRAINT_TYPE != _trigger && _constraintDateStr == null && 
      _constraintType != CONSTRAINT_TYPES.ASAP && _constraintType != CONSTRAINT_TYPES.ALAP) {
    _constraintType = _projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP;
  }
  
  //prevDateStr, preTimeStr
  let _prevDateStr = prevDateStr;
  if (!isValidDateStrFormat(_prevDateStr)) {
    _prevDateStr = null;
  }
  let _prevTimeStr = prevTimeStr;
  if (!isValidTimeStrFormat(_prevTimeStr)) {
    _prevTimeStr = null;
  }

  let prevDate = null;
  if (_prevDateStr != null) {
    prevDate = moment.utc(_prevDateStr, 'YYYY-MM-DD');
    if (_prevTimeStr != null) {
      const pt = _prevTimeStr.split(':');
      prevDate.hour(pt[0]).minute(pt[1]).second(0).millisecond(0);
    }
  }

  const MINIMUM_DURATION_IN_MINUTES = 480; //Equal to '1D when 8h = 1D'
  const MINIMUM_DURATION_DISPLAY = '1D';

  ////// 2.0 Internal function declaration
  //Description: functions declared here can refer parent variables, eliminate the need to pass state/flag value as function parameter.
  const consolidateStartDate = ({ sDateStr, sTimeStr } = {}) => {
    let startDate = moment.utc(sDateStr, 'YYYY-MM-DD');
    //Get the earliest work hour of the day. If startDate is a non working day, look for closest (previous) working day's earliest work hour.
    const earliestHour = __getEarliestOrLatestWorkHour(_calendar, startDate, { isLatest: false }).value;
    if (sTimeStr == null || (_tAutoScheduleMode && sTimeStr < earliestHour)) {
      sTimeStr = earliestHour;
    }
    const st = sTimeStr.split(':');
    startDate.hour(st[0]).minute(st[1]).second(0).millisecond(0);
    return startDate;
  }

  const consolidateCloseDate = ({ cDateStr, cTimeStr } = {}) => {
    let closeDate = moment.utc(cDateStr, 'YYYY-MM-DD');
    //Get the latest work hour of the day. If closeDate is a non working day, look for closest (previous) working day's latest work hour.
    const latestHour = __getEarliestOrLatestWorkHour(_calendar, closeDate, { isLatest: true }).value;
    if (cTimeStr == null || (_tAutoScheduleMode && cTimeStr > latestHour)) {
      cTimeStr = latestHour;
    }
    const ct = cTimeStr.split(':');
    closeDate.hour(ct[0]).minute(ct[1]).second(0).millisecond(0);
    return closeDate;
  }

  const processCloseDate = ({ closeDate, _returnObj } = {}) => {
    //check OutOfProjectDate and return OUT_OF_PROJECT_DATE_RANGE modal data if condition is met.
    if (!_skipOutOfProjectDateCheck && closeDate != null) {
      const dateOnly = closeDate.clone().hour(0).minute(0).second(0).millisecond(0);
      const prevDateOnly = prevDate != null? prevDate.clone().hour(0).minute(0).second(0).millisecond(0) : null;
      if (projectCloseDate != null && projectCloseDate.diff(dateOnly) < 0 && _trigger != TRIGGERS.DURATION) {
        if (prevDate == null || projectCloseDate.diff(prevDateOnly) >= 0) {
          _returnObj.modal = {
            type: MODAL_TYPES.OUT_OF_PROJECT_DATE_RANGE
            , trigger: _trigger
            , dateStr: closeDate.format('YYYY-MM-DD')
            , projectDateStr: projectCloseDateStr
            , isStart: false
          }
          return { returnObj: _returnObj };
        }
      } else if (projectStartDate != null && projectStartDate.diff(dateOnly) > 0 && _trigger != TRIGGERS.DURATION) {
        if (prevDate == null || projectStartDate.diff(prevDateOnly) <= 0) {
          _returnObj.modal = {
            type: MODAL_TYPES.OUT_OF_PROJECT_DATE_RANGE
            , trigger: _trigger
            , dateStr: closeDate.format('YYYY-MM-DD')
            , projectDateStr: projectStartDateStr
            , isStart: true
          }
          return { returnObj: _returnObj };
        }
      }
    }

    let newStartDate = null;

    //Check if it is a non working day. If yes, return NON_WORK_DAY modal data if condition is met.
    const isWorkDay = isWorkingDay(_calendar, closeDate);
    if (_tAutoScheduleMode && !isWorkDay) {
      let isBackward = true;
      let suggestedDateTime = __getValidWorkingTime(_calendar, closeDate, { isBackward }).value;
      // If the suggestedDateTime is earlier than startDate, get new suggested date by moving forward.
      if (_returnObj.startDateStr != null) {
        const _sDateTime = consolidateStartDate({ sDateStr: _returnObj.startDateStr, sTimeStr: _returnObj.startTimeStr })
        if (suggestedDateTime.diff(_sDateTime) < 1 && _resizeMode) {
          if (_projScheduleFromStart) {
            isBackward = false;
            let newDate = closeDate.clone()
            if (!_disableDurationBuffer) {
              newDate.add(MINIMUM_DURATION_DISPLAY.substring(0, MINIMUM_DURATION_DISPLAY.length-1), 'days') // +1 is to include the starting day.
            }
            newDate = nextAvailableWorkingDay(_calendar, newDate, { isBackward }).hours(newDate.hours()).minutes(newDate.minutes);
            suggestedDateTime = __getValidWorkingTime(_calendar, newDate, { isBackward }).value; //Make sure the newDate a valid working time and day.
            //Restore isBackward to original value
            isBackward = true;
          }
        }
      }
      
      if (_autoMoveForNonWorkingDay) { //Set closeDate with the suggested date & time when autoMoveForNonWorkingDay is true.
        const cDateStr = suggestedDateTime.format('YYYY-MM-DD');
        const cTimeStr = suggestedDateTime.format('HH:mm');
        _returnObj.closeDateStr = cDateStr;
        _returnObj.closeTimeStr = cTimeStr;
        closeDate = moment.utc(`${cDateStr} ${cTimeStr}`, 'YYYY-MM-DD HH:mm');
        return { closeDate, returnObj: _returnObj, startDate: newStartDate }
      } else {
        //If it is non working day and in auto schedule mode, prepare returnObj with modal properties. Used in rendering confirmation dialog.
        _returnObj.modal = {
          type: MODAL_TYPES.NON_WORK_DAY
          , trigger: _trigger
          , dateStr: closeDate.format('YYYY-MM-DD')
          , isBackward
          , suggestedDateStr: suggestedDateTime.format('YYYY-MM-DD')
          , suggestedTimeStr: suggestedDateTime.format('HH:mm')
        }
        return { returnObj: _returnObj };
      }
    }

    return { closeDate, startDate: newStartDate };
  }

  const processStartDate = ({ startDate, _returnObj } = {}) => {
    //check OutOfProjectDate and return OUT_OF_PROJECT_DATE_RANGE modal data if condition is met.
    if (!_skipOutOfProjectDateCheck && startDate != null) {
      const dateOnly = startDate.clone().hour(0).minute(0).second(0).millisecond(0);
      const prevDateOnly = prevDate != null? prevDate.clone().hour(0).minute(0).second(0).millisecond(0) : null;
      if (projectStartDate != null && projectStartDate.diff(dateOnly) > 0 && _trigger != TRIGGERS.DURATION) {
        if (prevDateOnly == null || projectStartDate.diff(prevDateOnly) <= 0) {
          _returnObj.modal = {
            type: MODAL_TYPES.OUT_OF_PROJECT_DATE_RANGE
            , trigger: _trigger
            , dateStr: startDate.format('YYYY-MM-DD')
            , projectDateStr: projectStartDateStr
            , isStart: true
          }
          return { returnObj: _returnObj };
        }
      } else if (projectCloseDate != null && projectCloseDate.diff(dateOnly) < 0 && _trigger != TRIGGERS.DURATION) {
        if ((prevDateOnly == null || projectCloseDate.diff(prevDateOnly) >= 0)) {
          _returnObj.modal = {
            type: MODAL_TYPES.OUT_OF_PROJECT_DATE_RANGE
            , trigger: _trigger
            , dateStr: startDate.format('YYYY-MM-DD')
            , projectDateStr: projectCloseDateStr
            , isStart: false
          }
          return { returnObj: _returnObj };
        }
      }
    }

    let newCloseDate = null;

    //Check if it is a non working day. If yes, return NON_WORK_DAY modal data if condition is met.
    const isWorkDay = isWorkingDay(_calendar, startDate);
    if (_tAutoScheduleMode && !isWorkDay) {
      let isBackward = false;
      let suggestedDateTime = __getValidWorkingTime(_calendar, startDate, { isBackward }).value;
      // If the suggestedDateTime is later than closeDate, get new suggested date by moving backward.
      if (_returnObj.closeDateStr != null) {
        const _cDateTime = consolidateCloseDate({ cDateStr: _returnObj.closeDateStr, cTimeStr: _returnObj.closeTimeStr })
        if (_cDateTime.diff(suggestedDateTime) < 1 && _resizeMode) {
          if (!_projScheduleFromStart) {
            isBackward = true;
            let newDate = startDate.clone()
            if (!_disableDurationBuffer) {
              newDate.subtract(MINIMUM_DURATION_DISPLAY.substring(0, MINIMUM_DURATION_DISPLAY.length-1), 'days') // +1 is to include the starting day.
            }
            newDate = nextAvailableWorkingDay(_calendar, newDate, { isBackward }).hours(newDate.hours()).minutes(newDate.minutes);
            suggestedDateTime = __getValidWorkingTime(_calendar, newDate, { isBackward }).value; //Make sure the newDate a valid working time and day.
            //Restore isBackward to original value
            isBackward = false;
          }
        }
      }

      if (_autoMoveForNonWorkingDay) { //Set startDate with the suggested date & time when autoMoveForNonWorkingDay is true.
        const sDateStr = suggestedDateTime.format('YYYY-MM-DD');
        const sTimeStr = suggestedDateTime.format('HH:mm');
        _returnObj.startDateStr = sDateStr;
        _returnObj.startTimeStr = sTimeStr;
        startDate = moment.utc(`${sDateStr} ${sTimeStr}`, 'YYYY-MM-DD HH:mm');
        return { startDate, returnObj: _returnObj, closeDate: newCloseDate };
      } else {
        //If it is non working day and in auto schedule mode, prepare returnObj with modal properties. Used in rendering confirmation dialog.
        _returnObj.modal = {
          type: MODAL_TYPES.NON_WORK_DAY
          , trigger: _trigger
          , dateStr: startDate.format('YYYY-MM-DD')
          , isBackward
          , suggestedDateStr: suggestedDateTime.format('YYYY-MM-DD')
          , suggestedTimeStr: suggestedDateTime.format('HH:mm')
        }
        return { returnObj: _returnObj };
      }
    }
    return { startDate, closeDate: newCloseDate }
  }

  // @param _returnObj {Object}: returnObj
  // @param honourConstraint {boolean}: flag to force a date/duration recalculation to honour the constraint if TRUE. Default to FALSE.
  // Priority honourConstraint > autoConstraintChange  > confirmation modal
  const processConstraint = ({ _returnObj, honourConstraint=false } = {}) => {
    if (!_tAutoScheduleMode) {
      return { returnObj: _returnObj };
    }

    let constType = _returnObj.constraintType;
    let constDateStr = _returnObj.constraintDateStr;
    
    //Defensive code: If constraint type is empty, reset to ASAP/ALAP depends on projectSchheduleFromStart.
    if (constType == null) {
      _returnObj.constraintType = _projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP;
      _returnObj.constraintDateStr = null;
      return { returnObj: _returnObj }
    }
    const constDate = constDateStr == null? null : moment.utc(constDateStr, 'YYYY-MM-DD');
    //comply-check logic [START]: Check if the constraint type is respected.
    let isComplied = true;
    if (isConstraintTypeStartDateRelated(constType)) {
      const startDate = _returnObj.startDateStr != null? moment.utc(_returnObj.startDateStr, 'YYYY-MM-DD'): null;

      //Defensive code: If startDate is empty, reset constraint type to ASAP /ALAP depends on projectScheduleFromStart.
      if (startDate == null) {
        _returnObj.constraintType = _projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP;
        _returnObj.constraintDateStr = null;
        return { returnObj: _returnObj }
      }
      
      if (CONSTRAINT_TYPES.MSO == constType && constDate.diff(startDate) != 0) {
        isComplied = false;
      } else if (CONSTRAINT_TYPES.SNET == constType && startDate.diff(constDate) < 0) {
        isComplied = false;
      } else if (CONSTRAINT_TYPES.SNLT == constType && startDate.diff(constDate) > 0) {
        isComplied = false;
      }
    } else if (isConstraintTypeCloseDateRelated(constType)) {
      const closeDate = _returnObj.closeDateStr != null? moment.utc(_returnObj.closeDateStr, 'YYYY-MM-DD'): null;
      
      //Defensive code: If closeDate is empty, reset constraint type to ASAP /ALAP depends on projectScheduleFromStart.
      if (closeDate == null) {
        _returnObj.constraintType = _projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP;
        _returnObj.constraintDateStr = null;
        return { returnObj: _returnObj }
      }
      
      if (CONSTRAINT_TYPES.MFO == constType && constDate.diff(closeDate) != 0) {
        isComplied = false;
      } else if (CONSTRAINT_TYPES.FNET == constType && closeDate.diff(constDate) < 0) {
        isComplied = false;
      } else if (CONSTRAINT_TYPES.FNLT == constType && closeDate.diff(constDate) > 0) {
        isComplied = false;
      }
    }

    if (isComplied) {
      return { returnObj: _returnObj }
    }
    //comply-check logic [END]    
    //From this point onwards, constraintType should not be ASAP/ALAP as they should hit the comply-check logic and return.    
    
    if (honourConstraint) {
      if (_lockDuration) {
        //calculate the gap between startDate and closeDate
        //use the gap to recalculate the closeDate/startDate with constraintDateStr
        const startDate = moment.utc(`${_returnObj.startDateStr} ${_returnObj.startTimeStr}`, 'YYYY-MM-DD HH:mm');
        const closeDate = moment.utc(`${_returnObj.closeDateStr} ${_returnObj.closeTimeStr}`, 'YYYY-MM-DD HH:mm');
        const gapInMinutes = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
        if (isConstraintTypeStartDateRelated(constType)) {
          const constDate = moment.utc(`${constDateStr} ${_returnObj.startTimeStr}`, 'YYYY-MM-DD HH:mm');
          const newCloseDate = __addDurationToDate(_calendar, constDate, gapInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
          _returnObj.startDateStr = constDate.format('YYYY-MM-DD');
          _returnObj.startTimeStr = constDate.format('HH:mm');
          _returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD');
          _returnObj.closeTimeStr = newCloseDate.format('HH:mm');
        } else {
          const constDate = moment.utc(`${constDateStr} ${_returnObj.closeTimeStr}`, 'YYYY-MM-DD HH:mm');
          const newStartDate = __addDurationToDate(_calendar, constDate, gapInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
          _returnObj.startDateStr = newStartDate.format('YYYY-MM-DD');
          _returnObj.startTimeStr = newStartDate.format('HH:mm');
          _returnObj.closeDateStr = constDate.format('YYYY-MM-DD');
          _returnObj.closeTimeStr = constDate.format('HH:mm');
        }
      } else {
        //user duration to recalculate the closeDate/startDate with constraintDateStr
        const durationInMinutes = convertDisplayToDuration(_returnObj.durationDisplay, durationConversionOpts).value;
        if (isConstraintTypeStartDateRelated(constType)) {
          const constDate = moment.utc(`${constDateStr} ${_returnObj.startTimeStr}`, 'YYYY-MM-DD HH:mm');
          const newCloseDate = __addDurationToDate(_calendar, constDate, durationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
          _returnObj.startDateStr = constDate.format('YYYY-MM-DD');
          _returnObj.startTimeStr = constDate.format('HH:mm');
          _returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD');
          _returnObj.closeTimeStr = newCloseDate.format('HH:mm');
        } else {
          const constDate = moment.utc(`${constDateStr} ${_returnObj.closeTimeStr}`, 'YYYY-MM-DD HH:mm');
          const newStartDate = __addDurationToDate(_calendar, constDate, durationInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
          _returnObj.startDateStr = newStartDate.format('YYYY-MM-DD');
          _returnObj.startTimeStr = newStartDate.format('HH:mm');
          _returnObj.closeDateStr = constDate.format('YYYY-MM-DD');
          _returnObj.closeTimeStr = constDate.format('HH:mm');
        }
      }
      return { returnObj: _returnObj };
    }
    
    //From this point onwards, prepare constraint-change modal data unless autoConstraintChange is TRUE.
    let newConstType = null;
    let newConstDateStr = null;
    if (_trigger == TRIGGERS.START_DATE || _trigger == TRIGGERS.START_DATE_BY_CONSTRAINT || _trigger == TRIGGERS.START_TIME) {
      newConstType = _projScheduleFromStart? CONSTRAINT_TYPES.SNET : CONSTRAINT_TYPES.SNLT;
      newConstDateStr = _returnObj.startDateStr;
    } else if (_trigger == TRIGGERS.CLOSE_DATE || _trigger == TRIGGERS.CLOSE_DATE_BY_CONSTRAINT || _trigger == TRIGGERS.CLOSE_TIME) { 
      newConstType = _projScheduleFromStart? CONSTRAINT_TYPES.FNET : CONSTRAINT_TYPES.FNLT;
      newConstDateStr = _returnObj.closeDateStr;
    } else { // _trigger == TRIGGERS.DURATION
      if (_projScheduleFromStart) {
        newConstType = CONSTRAINT_TYPES.SNET;
        newConstDateStr = _returnObj.startDateStr;
      } else {
        newConstType = CONSTRAINT_TYPES.FNLT;
        newConstDateStr = _returnObj.closeDateStr;
      }
    }

    if (_autoConstraintChange) {
      _returnObj.constraintType = newConstType;
      _returnObj.constraintDateStr = newConstDateStr;
      return { returnObj: _returnObj };
    }
     
    _returnObj.modal = {
      type: MODAL_TYPES.CONSTRAINT_CHANGE
      , trigger: isConstraintTypeStartDateRelated(newConstType)? TRIGGERS.START_DATE_BY_CONSTRAINT : TRIGGERS.CLOSE_DATE_BY_CONSTRAINT
      , constraintType: _returnObj.constraintType
      , constraintDateStr: _returnObj.constraintDateStr
      , suggestedConstraintType: newConstType
      , suggestedConstraintDateStr: newConstDateStr
    }
    return { returnObj: _returnObj }
  }


  ////// 3.0 Evaluate the relationship of values (startDate, closeDate and constraint). Stop proceed further, and return result when matching one of the following conditions:
  //Description: Need to think about how to handle START_DATE_BY CONSTRAINT logic flow
  //       - Clear duration value When trigger is startDate/startTime, and startDate/startTime is null/empty (because the intention is to clear out the startDate/startTime value).  
  //           Reset constraint from SNET/SNLT/MSO to ASAP/ALAP (depends on projectScheduleFromStart[true=>ASAP:false=>ALAP]) if taskAutoScheduleMode is true.
  //       - Clear duration value when trigger is closeDate/closeTime, and closeDate/closeTime is null/empty (because the intention is to clear out the closeDate/closeTime value).
  //           Reset constraint from FNET/FNLT/MFO to ASAP/ALAP (depends on projectScheduleFromStart[true=>ASAP:false=>ALAP]) if taskAutoScheduleMode is true.
  //       - Clear startDate/startTime when trigger is duration, and duration is null/empty, and projectScheduleFromStart is false.
  //           Reset constraint from SNET/SNLT/MSO to ASAP/ALAP (depends on projectScheduleFromStart[true=>ASAP:false=>ALAP]) if taskAutoScheduleMode is true.
  //       - Clear closeDate/closeTime when trigger is duration, and duration is null/empty, and projectScheduleFromStart is true.
  //           Reset constraint from FNET/FNLT/MFO to ASAP/ALAP (depends on projectScheduleFromStart[true=>ASAP:false=>ALAP]) if taskAutoScheduleMode is true.
  //       - return original values when only one value (startDate, loseDate and duration) is valid. 
  if ((_trigger == TRIGGERS.START_DATE && _startDateStr == null) || (_trigger == TRIGGERS.START_DATE_BY_CONSTRAINT && _startDateStr == null) || (_trigger == TRIGGERS.START_TIME && _startTimeStr == null)) {
    let _constType = constraintType;
    let _constDate = constraintDateStr;
    if (taskAutoScheduleMode && (_constType == CONSTRAINT_TYPES.SNET || _constType == CONSTRAINT_TYPES.SNLT || _constType == CONSTRAINT_TYPES.MSO)) {
        _constType = _projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP;
        _constDate = null;
    }
    return {
      startDateStr: null
      , startTimeStr: null
      , closeDateStr
      , closeTimeStr
      , durationDisplay: null
      , constraintType: _constType
      , constraintDateStr: _constDate
    }
  }

  if ((_trigger == TRIGGERS.CLOSE_DATE && _closeDateStr == null) || (_trigger == TRIGGERS.CLOSE_TIME && _closeTimeStr == null)) {
    let _constType = constraintType;
    let _constDate = constraintDateStr;
    if (taskAutoScheduleMode && (_constType == CONSTRAINT_TYPES.FNET || _constType == CONSTRAINT_TYPES.FNLT || _constType == CONSTRAINT_TYPES.MFO)) {
        _constType = _projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP;
        _constDate = null;
    }
    return {
      startDateStr
      , startTimeStr
      , closeDateStr: null
      , closeTimeStr: null
      , durationDisplay: null
      , constraintType: _constType
      , constraintDateStr: _constDate
    }
  }

  if (_trigger == TRIGGERS.DURATION && _durationDisplay == null) {
    let _constType = constraintType;
    let _constDate = constraintDateStr;
    if (taskAutoScheduleMode) {
      let isMatch = false;
      if (_projScheduleFromStart) {
        isMatch =_constType == CONSTRAINT_TYPES.FNET || _constType == CONSTRAINT_TYPES.FNLT || _constType == CONSTRAINT_TYPES.MFO;
      } else {
        isMatch =_constType == CONSTRAINT_TYPES.SNET || _constType == CONSTRAINT_TYPES.SNLT || _constType == CONSTRAINT_TYPES.MSO;
      }
      //Defensive code: set constraintType to ASAP/ ALAP if it is empty.
      if (isMatch || _constType == null) {
        _constType = _projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP;
        _constDate = null;
      }
    } else {
      //Set constraint type to ASAP/ALAP (depends on projectScheduleFromStart) when it is empty.
      if (_constType == null) {
        _constType = _projScheduleFromStart? CONSTRAINT_TYPES.ASAP : CONSTRAINT_TYPES.ALAP;
        _constDate = null;
      }
    }
    return {
      startDateStr: _projScheduleFromStart? startDateStr : null
      , startTimeStr: _projScheduleFromStart? startTimeStr : null
      , closeDateStr: _projScheduleFromStart? null : closeDateStr
      , closeTimeStr: _projScheduleFromStart? null : closeTimeStr
      , durationDisplay: null
      , constraintType: _constType
      , constraintDateStr: _constDate
    }
  }

  if (_trigger == TRIGGERS.CONSTRAINT_TYPE && 
      _constraintDateStr == null && 
      _constraintType != CONSTRAINT_TYPES.ASAP && _constraintType != CONSTRAINT_TYPES.ALAP) {
    return {
      startDateStr
      , startTimeStr
      , closeDateStr
      , closeTimeStr
      , durationDisplay
      , constraintType
      , constraintDateStr
    }
  }

  let nullCounter = 0;
  if (_startDateStr == null) {
    nullCounter+=1;
  }
  if (_closeDateStr == null) {
    nullCounter+=1;
  }
  if (_durationDisplay == null) {
    nullCounter+=1;
  }
  if (nullCounter > 1) {
    const returnObj = {
      startDateStr
      , startTimeStr
      , closeDateStr
      , closeTimeStr
      , durationDisplay
      , constraintType
      , constraintDateStr
    }

    if (_trigger == TRIGGERS.CONSTRAINT_TYPE || _trigger == TRIGGERS.CONSTRAINT_DATE || _trigger == TRIGGERS.TASK_SCHEDULE_MODE) {
      //Set startDate with constraintDate (if startDate doesn't comply with the constraint) when constraintType is startDate related
      //Set closeDate with constraintDate (if closeDate comply with the constraint) when constraintType is closeDate related
      let isComplied = true;      
      if (isConstraintTypeStartDateRelated(_constraintType)) {
        const startDate = returnObj.startDateStr != null? moment.utc(returnObj.startDateStr, 'YYYY-MM-DD'): null;
        if (startDate == null || _constraintDateStr == null) {
          return returnObj;
        }
        const constDate = moment.utc(_constraintDateStr, 'YYYY-MM-DD');
        if (CONSTRAINT_TYPES.MSO == _constraintType && constDate.diff(startDate) != 0) {
          isComplied = false;
        } else if (CONSTRAINT_TYPES.SNET == _constraintType && constDate.diff(startDate) > 0) {
          isComplied = false;
        } else if (CONSTRAINT_TYPES.SNLT == _constraintType && constDate.diff(startDate) < 0) {
          isComplied = false;
        }
        if (!isComplied) {
          returnObj.startDateStr = _constraintDateStr;
        }
      } else if (isConstraintTypeCloseDateRelated(_constraintType)) {
        const closeDate = _closeDateStr != null? moment.utc(_closeDateStr, 'YYYY-MM-DD'): null;
        if (closeDate == null || _constraintDateStr == null) {
          return returnObj;
        }
        const constDate = moment.utc(_constraintDateStr, 'YYYY-MM-DD');
        if (CONSTRAINT_TYPES.MFO == _constraintType && constDate.diff(closeDate) != 0) {
          isComplied = false;
        } else if (CONSTRAINT_TYPES.FNET == _constraintType && constDate.diff(closeDate) > 0) {
          isComplied = false;
        } else if (CONSTRAINT_TYPES.FNLT == _constraintType && constDate.diff(closeDate) < 0) {
          isComplied = false;
        }
        if (!isComplied) {
          returnObj.closeDateStr = _constraintDateStr;
        }
      }
    }
    return returnObj;
  }
  
  ////// 4.0 Identifiy actionMode
  //Description:
  // - actionMode:
  //       - Task-Move means the duration remains untouched. Both startDate or closeDate are updated.
  //       - Task-Resize means the duration is recalculated and constraint remains untouched. Either startDate or closeDate is updated but not both.
  //       - Task-Calculate-Missing-Value means one of the values (startDate, closeDate and duration) is empty. Calculate the missing one.
  
  let actionMode = CALC_DTD_ACTION_MODE.TASK_MOVE;
  if (_resizeMode && _startDateStr != null && _closeDateStr != null) {
    //actionMode can not be TASK_RESIZE when trigger is related to constraint or taskScheduleMode.
    if (_trigger != TRIGGERS.CONSTRAINT_TYPE && _trigger != TRIGGERS.CONSTRAINT_DATE && _trigger != TRIGGERS.TASK_SCHEDULE_MODE) {
      actionMode = CALC_DTD_ACTION_MODE.TASK_RESIZE;
    }
  } else if (nullCounter == 1) {
    actionMode = CALC_DTD_ACTION_MODE.TASK_CALC_MISSING_VALUE;
  }
  
  ////// 5.0 Start calculation
  //Description: Need to think about how to handle START_DATE_BY CONSTRAINT logic flow
  //  - When task-calculate-missing-value
  //       - Check the constraint type and the missing-value relationship.
  //          a) If missing-value is startDate, and constraint type is SNET/SNLT/MSO, reset constraint type to ASAP/ALAP depends on projectScheduleFromStart.
  //          b) If missing-value is closeDate, and constraint type is FNET/FNLT/MFO, reset constraint type to ASAP/ALAP depends on projectScheduleFromStart.
  //          c) If missing-value is duration, 
  //             - When constraint type is SNET/SNLT/MSO, and projectScheduleFromStart is false, honor the startDate if 
  //       - Calculate the missing value.

  //  - When Task-Resize
  //       - Calculate new duration. 
  //       - If lockDuration is true, keep the origin duration if new duration is greater than original duration
  //       - Adjust startDate and endDate to respect constraint. 
  //       - Check if skipProjectRangeCheck is false. If yes check if startDate/endDate is beyond project date range. If yes, prepare data for dialog prompt.
  
  //  - When task-move,
  //       - Recalculate startDate/closeDate depends on trigger.
  //       - Constraint type becomes SNET when projectScheduleFromStart is true. Prompt to ask to confirm the constraint change. 
  //       - Constraint type becomes FNLT when projectScheduleFromStart is false. Prompt to ask to confirm the constraint change. 
  //       - no change in duration.


  //Declare and initialize returnObj
  const returnObj = {
    startDateStr: _startDateStr
    , startTimeStr: _startTimeStr
    , closeDateStr: _closeDateStr
    , closeTimeStr: _closeTimeStr
    , durationDisplay: _durationDisplay
    , constraintType: _constraintType
    , constraintDateStr: _constraintDateStr
  }
  
  if (actionMode == CALC_DTD_ACTION_MODE.TASK_RESIZE) {
    if (_trigger == TRIGGERS.START_DATE || _trigger == TRIGGERS.START_TIME || _trigger == TRIGGERS.START_DATE_BY_CONSTRAINT) {
      //Recalculate the duration if lockDuration is false.
      //Make sure the new gap is larger and equal to the duration if lockDuration is true
      let startDate = consolidateStartDate({ sDateStr: _startDateStr, sTimeStr: _startTimeStr });
      returnObj.startDateStr = startDate.format('YYYY-MM-DD');
      returnObj.startTimeStr = startDate.format('HH:mm');      
      const result = processStartDate({ startDate, _returnObj: cloneDeep(returnObj) });
      if (result.startDate == null) {
        if (result.returnObj.durationDisplay == null) {
          const closeDate = consolidateStartDate({ sDateStr: _closeDateStr, sTimeStr: _closeTimeStr })
          if (closeDate.diff(startDate) <= 0) {
            result.returnObj.durationDisplay = '0D';
          } else {
            const originalDurationInMinutes = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes } = {}).value;
            result.returnObj.durationDisplay = convertDurationToDisplay(originalDurationInMinutes, durationUnit, durationConversionOpts)
          }
        }
        return result.returnObj;
      }
      
      startDate = result.startDate;
      returnObj.startDateStr = startDate.format('YYYY-MM-DD');
      returnObj.startTimeStr = startDate.format('HH:mm');

      let closeDate = null;
      if (result.closeDate != null) {
        closeDate =  result.closeDate;
      } else {
        closeDate = consolidateCloseDate({ cDateStr: _closeDateStr, cTimeStr: _closeTimeStr });
      }
      returnObj.closeDateStr = closeDate.format('YYYY-MM-DD');
      returnObj.closeTimeStr = closeDate.format('HH:mm');
      
      let durationInMinutes = convertDisplayToDuration(_durationDisplay, durationConversionOpts).value;
      if (startDate.diff(closeDate) > 0) {
        //Recalculate closeDate with startDate and duration (Either predefined minimum duration or origin duration if lockDuration is TRUE), when startDate is later than closeDate
        let newDurationInMinutes = _disableDurationBuffer? 0 : durationConversionOpts?.hourPerDay != null? durationConversionOpts.hourPerDay * 60 : MINIMUM_DURATION_IN_MINUTES;
        if (_lockDuration) {
          newDurationInMinutes = durationInMinutes;
        }
        
        const newCloseDate = __addDurationToDate(_calendar, startDate, newDurationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
        returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD');
        returnObj.closeTimeStr = newCloseDate.format('HH:mm');
        returnObj.durationDisplay = convertDurationToDisplay(newDurationInMinutes, durationUnit, durationConversionOpts);
      } else {
        let gapInMinutes = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
        if (gapInMinutes == _maxDurationInMinutes) {
          //Possibly hitting the allowed maximum duration limit when true, recalculate the startDate with closeDate and maxDurationInMinutes.
          const newStartDate = __addDurationToDate(_calendar, closeDate, _maxDurationInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
          returnObj.startDateStr = newStartDate.format('YYYY-MM-DD');
          returnObj.startTimeStr = newStartDate.format('HH:mm');
        }
        
        if (_lockDuration) {
          if (gapInMinutes < durationInMinutes) {
            //Recalculate startDate/closeDate (depends on projectScheduleFromStart) with duration because the new gap (duration) between startDate and closeDate is less than duration.
            if (_projScheduleFromStart) {
              const newCloseDate = __addDurationToDate(_calendar, startDate, durationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
              returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD');
              returnObj.closeTimeStr = newCloseDate.format('HH:mm');
            } else {
              const newStartDate = __addDurationToDate(_calendar, closeDate, durationInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
              returnObj.startDateStr = newStartDate.format('YYYY-MM-DD');
              returnObj.startTimeStr = newStartDate.format('HH:mm');
            }
          }
        } else {
          returnObj.durationDisplay = convertDurationToDisplay(gapInMinutes, durationUnit, durationConversionOpts);
        }
      }
    } else if (_trigger == TRIGGERS.CLOSE_DATE || _trigger == TRIGGERS.CLOSE_TIME || _trigger == TRIGGERS.CLOSE_DATE_BY_CONSTRAINT) {
      //Recalculate the duration if lockDuration is false.
      //Make sure the new gap is larger and equal to the duration if lockDuration is true
      let closeDate = consolidateCloseDate({ cDateStr: _closeDateStr, cTimeStr: _closeTimeStr });
      returnObj.closeDateStr = closeDate.format('YYYY-MM-DD');
      returnObj.closeTimeStr = closeDate.format('HH:mm');
      const result = processCloseDate({ closeDate, _returnObj: cloneDeep(returnObj) });
      if (result.closeDate == null) {
        if (result.returnObj.durationDisplay == null) {
          const startDate = consolidateStartDate({ sDateStr: _startDateStr, sTimeStr: _startTimeStr })
          if (closeDate.diff(startDate) <= 0) {
            result.returnObj.durationDisplay = '0D';
          } else {
            const originalDurationInMinutes = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes } = {}).value;
            result.returnObj.durationDisplay = convertDurationToDisplay(originalDurationInMinutes, durationUnit, durationConversionOpts)
          }
        }
        return result.returnObj;
      }
      
      closeDate = result.closeDate;
      returnObj.closeDateStr = closeDate.format('YYYY-MM-DD')
      returnObj.closeTimeStr = closeDate.format('HH:mm')

      let startDate = null;
      if (result.startDate != null) {
        startDate =  result.startDate;
      } else {
        startDate = consolidateStartDate({ sDateStr: _startDateStr, sTimeStr: _startTimeStr });
      }
      
      returnObj.startDateStr = startDate.format('YYYY-MM-DD');
      returnObj.startTimeStr = startDate.format('HH:mm');  
      
      let durationInMinutes = convertDisplayToDuration(_durationDisplay, durationConversionOpts).value;
      if (startDate.diff(closeDate) > 0) {
        //Recalculate startDate with closeDate and duration (Either predefined minimum duration or origin duration if lockDuration is TRUE), when startDate is later than closeDate
        let newDurationInMinutes = _disableDurationBuffer? 0 : durationConversionOpts?.hourPerDay != null? durationConversionOpts.hourPerDay * 60 : MINIMUM_DURATION_IN_MINUTES;
        if (_lockDuration) {
          newDurationInMinutes = durationInMinutes;
        }
        startDate = __addDurationToDate(_calendar, closeDate, newDurationInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
        returnObj.startDateStr = startDate.format('YYYY-MM-DD');
        returnObj.startTimeStr = startDate.format('HH:mm');
        returnObj.durationDisplay = convertDurationToDisplay(newDurationInMinutes, durationUnit, durationConversionOpts);
      } else {
        let gapInMinutes = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
        if (gapInMinutes == _maxDurationInMinutes) {
          //Possibly hitting the allowed maximum duration limit when true, recalculate the closeDate with startDate and maxDurationInMinutes.
          const newCloseDate = __addDurationToDate(_calendar, startDate, _maxDurationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
          returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD');
          returnObj.closeTimeStr = newCloseDate.format('HH:mm');
        }

        if (_lockDuration) {
          let durationInMinutes = convertDisplayToDuration(_durationDisplay, durationConversionOpts).value;
          if (gapInMinutes < durationInMinutes) {
            //Recalculate closeDate with duration because the new gap (duration) between startDate and closeDate is less than duration.
            const newCloseDate = __addDurationToDate(_calendar, startDate, durationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
            returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD');
            returnObj.closeTimeStr = newCloseDate.format('HH:mm');
          }
        } else {
          returnObj.durationDisplay = convertDurationToDisplay(gapInMinutes, durationUnit, durationConversionOpts);
        }
      }
    } else { //_trigger is duration
      return {
        error: {
          code: 'invalid_data_combination'
          , message: `actionMode should not be '${CALC_DTD_ACTION_MODE.TASK_RESIZE}'' when trigger is '${TRIGGERS.DURATION}'`
          , data: {
            trigger: _trigger
            , actionMode: actionMode
          }
        }
      }
    }
    
    //Honour Constraint
    const result = processConstraint({ _returnObj: cloneDeep(returnObj), honourConstraint: true });
    return result.returnObj;
  } else if (actionMode == CALC_DTD_ACTION_MODE.TASK_CALC_MISSING_VALUE) { //Calculate the missing value
    if (_startDateStr == null) { //Calculate the missing startDate
      let closeDate = consolidateCloseDate({ cDateStr: _closeDateStr, cTimeStr: _closeTimeStr });
      returnObj.closeDateStr = closeDate.format('YYYY-MM-DD');
      returnObj.closeTimeStr = closeDate.format('HH:mm');

      //Defensive code: _durationDisplay should not be null when when task is auto-scheduled.
      if (_durationDisplay == null && _tAutoScheduleMode) {
        _durationDisplay = _disableDurationBuffer? '0D' : MINIMUM_DURATION_DISPLAY; //Set to predefined minimum duration
        returnObj.durationDisplay = _durationDisplay; //update returnObj
      }

      if (_trigger == TRIGGERS.DURATION) {
        let duration = convertDisplayToDuration(_durationDisplay, durationConversionOpts).value;
        const newStartDate = __addDurationToDate(_calendar, closeDate, duration, { isStart: false, isAuto: _tAutoScheduleMode }).value;
        returnObj.startDateStr = newStartDate.format('YYYY-MM-DD');
        returnObj.startTimeStr = newStartDate.format('HH:mm');
      } else if (_trigger == TRIGGERS.CLOSE_DATE || _trigger == TRIGGERS.CLOSE_TIME || _trigger == TRIGGERS.CLOSE_DATE_BY_CONSTRAINT) {
        const result = processCloseDate({ closeDate, _returnObj: cloneDeep(returnObj) });
        if (result.closeDate == null) {
          return result.returnObj;
        }
        closeDate = result.closeDate;
        
        let duration = convertDisplayToDuration(_durationDisplay, durationConversionOpts).value;
        const newStartDate = __addDurationToDate(_calendar, closeDate, duration, { isStart: false, isAuto: _tAutoScheduleMode }).value;
        returnObj.startDateStr = newStartDate.format('YYYY-MM-DD');
        returnObj.startTimeStr = newStartDate.format('HH:mm');
      } else { // _trigger is related to constraintType, constraintDate, or taskScheduleMode
        //Do nothing.
      }
    } else if (_closeDateStr == null) { //Calculate the missing closeDate
      let startDate = consolidateStartDate({ sDateStr: _startDateStr, sTimeStr: _startTimeStr });
      returnObj.startDateStr = startDate.format('YYYY-MM-DD');
      returnObj.startTimeStr = startDate.format('HH:mm');

      //Defensive code: _durationDisplay should not be null when when task is auto-scheduled.
      if (_durationDisplay == null && _tAutoScheduleMode) {
        _durationDisplay = _disableDurationBuffer? '0D' : MINIMUM_DURATION_DISPLAY; //Set to predefined minimum duration
        returnObj.durationDisplay = _durationDisplay; //Update returnObj
      }
      
      if (_trigger == TRIGGERS.DURATION) {
        let duration = convertDisplayToDuration(_durationDisplay, durationConversionOpts).value;
        const newCloseDate = __addDurationToDate(_calendar, startDate, duration, { isStart: true, isAuto: _tAutoScheduleMode }).value;
        returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD');
        returnObj.closeTimeStr = newCloseDate.format('HH:mm');
      } else if (_trigger == TRIGGERS.START_DATE || _trigger == TRIGGERS.START_TIME || _trigger == TRIGGERS.START_DATE_BY_CONSTRAINT) {
        const result = processStartDate({ startDate, _returnObj: cloneDeep(returnObj) });
        if (result.startDate == null) {
          return result.returnObj;
        }
        startDate = result.startDate;

        let duration = convertDisplayToDuration(_durationDisplay, durationConversionOpts).value;
        const newCloseDate = __addDurationToDate(_calendar, startDate, duration, { isStart: true, isAuto: _tAutoScheduleMode }).value;
        
        returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD');
        returnObj.closeTimeStr = newCloseDate.format('HH:mm');
      } else { //_trigger is related to constraintType, constraintDate
        //Do nothing.
      }
    } else { //Calculate the missing duration
      let startDate = moment.utc(_startDateStr, 'YYYY-MM-DD');
      //Get the earliest work hour of the day. If startDate is a non working day, look for closest (previous) working day's earliest work hour.
      const earliestHour = __getEarliestOrLatestWorkHour(_calendar, startDate, { isLatest: false }).value;
      if (_startTimeStr == null || (_tAutoScheduleMode && _startTimeStr < earliestHour)) {
        _startTimeStr = earliestHour;
        returnObj.startTimeStr =_startTimeStr; //update returnObj
      }
      const st = _startTimeStr.split(':');
      startDate.hour(st[0]).minute(st[1]).second(0).millisecond(0);

      let closeDate = moment.utc(_closeDateStr, 'YYYY-MM-DD');
      //Get the latest work hour of the day. If closeDate is a non working day, look for closest (previous) working day's latest work hour.
      const latestHour = __getEarliestOrLatestWorkHour(_calendar, closeDate, { isLatest: true }).value;
      if (_closeTimeStr == null || (_tAutoScheduleMode && _closeTimeStr > latestHour)) {
        _closeTimeStr = latestHour;
        returnObj.closeTimeStr =_closeTimeStr; //update returnObj
      }
      const ct = _closeTimeStr.split(':');
      closeDate.hour(ct[0]).minute(ct[1]).second(0).millisecond(0);
      
      if (_trigger == TRIGGERS.START_DATE || _trigger == TRIGGERS.START_DATE_BY_CONSTRAINT || _trigger == TRIGGERS.START_TIME) {
        const result = processStartDate({ startDate, _returnObj: cloneDeep(returnObj) });
        if (result.startDate == null) {
          if (result.returnObj.durationDisplay == null) {
            const closeDate = consolidateStartDate({ sDateStr: _closeDateStr, sTimeStr: _closeTimeStr })
            if (closeDate.diff(startDate) <= 0) {
              result.returnObj.durationDisplay = '0D';
            } else {
              const originalDurationInMinutes = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes } = {}).value;
              result.returnObj.durationDisplay = convertDurationToDisplay(originalDurationInMinutes, durationUnit, durationConversionOpts)
            }
          }
          return result.returnObj;
        }
        startDate = result.startDate;
        
      } else if (_trigger == TRIGGERS.CLOSE_DATE || _trigger == TRIGGERS.CLOSE_DATE_BY_CONSTRAINT || _trigger == TRIGGERS.CLOSE_TIME) {
        const result = processCloseDate({ closeDate, _returnObj: cloneDeep(returnObj) });
        if (result.closeDate == null) {
          if (result.returnObj.durationDisplay == null) {
            const startDate = consolidateStartDate({ sDateStr: _startDateStr, sTimeStr: _startTimeStr })
            if (closeDate.diff(startDate) <= 0) {
              result.returnObj.durationDisplay = '0D';
            } else {
              const originalDurationInMinutes = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes } = {}).value;
              result.returnObj.durationDisplay = convertDurationToDisplay(originalDurationInMinutes, durationUnit, durationConversionOpts)
            }
          }
          return result.returnObj;
        }
        closeDate = result.closeDate;
      } else {//_trigger which is related to constraintDate / constraintTime
        //Do nothing.
      }
      
      if (startDate.diff(closeDate) > 0) {
        //Update closeDate
        returnObj.closeDateStr = closeDate.format('YYYY-MM-DD');
        returnObj.closeTimeStr = closeDate.format('HH:mm');

        returnObj.durationDisplay = _disableDurationBuffer? '0D' : MINIMUM_DURATION_DISPLAY; //Set to predefined minimum duration
        const durationInMinutes = convertDisplayToDuration(returnObj.durationDisplay, durationConversionOpts).value;
        if (_trigger == TRIGGERS.START_DATE || _trigger == TRIGGERS.START_DATE_BY_CONSTRAINT || _trigger == TRIGGERS.START_TIME) {
          if (durationInMinutes > 0) {
            const newCloseDate = __addDurationToDate(_calendar, startDate, durationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
            returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD');
            returnObj.closeTimeStr = newCloseDate.format('HH:mm');
          } else {
            returnObj.closeDateStr = startDate.format('YYYY-MM-DD');
            returnObj.closeTimeStr = startDate.format('HH:mm');
          }
        } else {
          if (durationInMinutes > 0) {
            const newStartDate = __addDurationToDate(_calendar, closeDate, durationInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
            returnObj.startDateStr = newStartDate.format('YYYY-MM-DD');
            returnObj.startTimeStr = newStartDate.format('HH:mm');
          } else {
            returnObj.startDateStr = closeDate.format('YYYY-MM-DD');
            returnObj.startTimeStr = closeDate.format('HH:mm');
          }
        }
      } else {
        const duration = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
        if (duration == _maxDurationInMinutes) {
          // Possibly hitting the allowed maximum duration limit when true, recalculate the closeDate with startDate and maxDurationInMinutes.
          const newCloseDate = __addDurationToDate(_calendar, startDate, _maxDurationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
          returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD');
          returnObj.closeTimeStr = newCloseDate.format('HH:mm');
        }
        returnObj.durationDisplay = convertDurationToDisplay(duration, durationUnit, durationConversionOpts);
        if (enableRoundUpResult == true) {
          //Rounding up the duration and recalculate the startDate or closeDate depending on the trigger.
          returnObj.durationDisplay = roundDurationDisplay(returnObj.durationDisplay, 'D', 0, durationConversionOpts);
          //Defensive code: set to '1D' if it is '0D'
          if (returnObj.durationDisplay == '0D') {
            returnObj.durationDisplay = '1D';
          }
          const durationInMinutes = convertDisplayToDuration(returnObj.durationDisplay, durationConversionOpts).value;
          if (_trigger == TRIGGERS.START_DATE || _trigger == TRIGGERS.START_DATE_BY_CONSTRAINT || _trigger == TRIGGERS.START_TIME) {
            if (durationInMinutes > 0) {
              const newCloseDate = __addDurationToDate(_calendar, startDate, durationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
              returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD');
              returnObj.closeTimeStr = newCloseDate.format('HH:mm');
            } else {
              returnObj.closeDateStr = startDate.format('YYYY-MM-DD');
              returnObj.closeTimeStr = startDate.format('HH:mm');
            }
          } else {
            if (durationInMinutes > 0) {
              const newStartDate = __addDurationToDate(_calendar, closeDate, durationInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
              returnObj.startDateStr = newStartDate.format('YYYY-MM-DD');
              returnObj.startTimeStr = newStartDate.format('HH:mm');
            } else {
              returnObj.startDateStr = closeDate.format('YYYY-MM-DD');
              returnObj.startTimeStr = closeDate.format('HH:mm');
            }
          }                    
        }
      }
    }
    
    //Honour Constraint
    const result = processConstraint({ _returnObj: cloneDeep(returnObj), honourConstraint: true });
    return result.returnObj;
  } else { //TASK_MOVE
    let honourConstraint = false;
    if (_trigger == TRIGGERS.START_DATE || _trigger == TRIGGERS.START_TIME || _trigger == TRIGGERS.START_DATE_BY_CONSTRAINT || (_trigger == TRIGGERS.TASK_SCHEDULE_MODE && _projScheduleFromStart)) {
      //Recalculate the closeDate and closeTime
      let startDate = consolidateStartDate({ sDateStr: _startDateStr, sTimeStr: _startTimeStr });
      returnObj.startDateStr = startDate.format('YYYY-MM-DD');
      returnObj.startTimeStr = startDate.format('HH:mm');
      const result = processStartDate({ startDate, _returnObj: cloneDeep(returnObj) });
      if (result.startDate == null) {
        return result.returnObj;
      }
      startDate = result.startDate;
      if (_trigger == TRIGGERS.TASK_SCHEDULE_MODE) {
        //startDate may be adjusted in processStartDate(). Sync it to returnObj.
        returnObj.startDateStr = startDate.format('YYYY-MM-DD');
        returnObj.startTimeStr = startDate.format('HH:mm');
        honourConstraint = true;
      }

      let durationInMinutes = convertDisplayToDuration(_durationDisplay, durationConversionOpts).value;

      let newCloseDate = null;
      if (_lockDuration) {
        //Defensive code when prevDateStr is empty. 
        let closeDate = consolidateCloseDate({ cDateStr: _closeDateStr, cTimeStr: _closeTimeStr });
        if (_prevDateStr == null) {
          if (closeDate.diff(startDate) > 0) {
            const gapInMinutes = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
            //Recalculate closeDate with startDate and duration if the gap between startDate and closeDate is less than duration.
            if (gapInMinutes < durationInMinutes) {
              newCloseDate = __addDurationToDate(_calendar, startDate, durationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
            } else {
              //Keep the same closeDate if gap between startDate and CloseDate is larger than and equal to duration.
              newCloseDate = closeDate;
            }
          } else {
            //Recalculate closeDate with startDate and duration when closeDate is earlier than startDate.
            newCloseDate = __addDurationToDate(_calendar, startDate, durationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
          }
        } else {
          let prevStartDate = consolidateStartDate({ sDateStr: _prevDateStr, sTimeStr: _prevTimeStr });
          const gapInMinutes = __calculateWorkCapacity(_calendar, prevStartDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
          newCloseDate = __addDurationToDate(_calendar, startDate, gapInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
        }
      } else {
        newCloseDate = __addDurationToDate(_calendar, startDate, durationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
      }
      returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD');
      returnObj.closeTimeStr = newCloseDate.format('HH:mm');
    } else if (_trigger == TRIGGERS.CLOSE_DATE || _trigger == TRIGGERS.CLOSE_TIME || _trigger == TRIGGERS.CLOSE_DATE_BY_CONSTRAINT || (_trigger == TRIGGERS.TASK_SCHEDULE_MODE && !_projScheduleFromStart)) {
      //Rescalculate the startDate and startTime
      let closeDate = consolidateCloseDate({ cDateStr: _closeDateStr, cTimeStr: _closeTimeStr });
      returnObj.closeDateStr = closeDate.format('YYYY-MM-DD');
      returnObj.closeTimeStr = closeDate.format('HH:mm');
      const result = processCloseDate({ closeDate, _returnObj: cloneDeep(returnObj) });
      if (result.closeDate == null) {
        return result.returnObj;
      }
      closeDate = result.closeDate;
      if (_trigger == TRIGGERS.TASK_SCHEDULE_MODE) {
        //closeDate may be adjusted in processCloseDate(). Sync it to returnObj.
        returnObj.closeDateStr = closeDate.format('YYYY-MM-DD');
        returnObj.closeTimeStr = closeDate.format('HH:mm');
        honourConstraint = true;
      }

      let durationInMinutes = convertDisplayToDuration(_durationDisplay, durationConversionOpts).value;

      let newStartDate = null;
      if (_lockDuration) {
        //Defensive code when prevDateStr is empty. 
        let startDate = consolidateStartDate({ sDateStr: _startDateStr, sTimeStr: _startTimeStr });
        if (_prevDateStr == null) {
          if (closeDate.diff(startDate) > 0) {
            const gapInMinutes = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
            //Recalculate startDate with closeDate and duration if the gap between startDate and closeDate is less than duration.
            if (gapInMinutes < durationInMinutes) {
              newStartDate = __addDurationToDate(_calendar, closeDate, durationInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
            } else {
              //Keep the same startDate if gap between startDate and CloseDate is larger than and equal to duration.
              newStartDate = startDate;
            }
          } else {
            //Recalculate startDate with closeDate and duration when closeDate is earlier than startDate.
            newStartDate = __addDurationToDate(_calendar, closeDate, durationInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
          }
        } else {
          let prevCloseDate = consolidateCloseDate({ cDateStr: _prevDateStr, cTimeStr: _prevTimeStr });
          const gapInMinutes = __calculateWorkCapacity(_calendar, startDate, prevCloseDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
          newStartDate = __addDurationToDate(_calendar, closeDate, gapInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
        }
      } else {
        newStartDate = __addDurationToDate(_calendar, closeDate, durationInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
      }
      returnObj.startDateStr = newStartDate.format('YYYY-MM-DD');
      returnObj.startTimeStr = newStartDate.format('HH:mm');
    } else if (_trigger == TRIGGERS.DURATION) {
      //If lockDuration is TRUE, recalculate closeDate/startDate depends on projectScheduleFromStart only if the gap between startDate and closeDate is less than duration.
      //If lockDuration is FALSE, recalculate closeDate/startDate depends on projectScheduleFromStart.      
      let startDate = consolidateStartDate({ sDateStr: _startDateStr, sTimeStr: _startTimeStr });
      returnObj.startDateStr = startDate.format('YYYY-MM-DD');
      returnObj.startTimeStr = startDate.format('HH:mm');
      
      let result = processStartDate({ startDate, _returnObj: cloneDeep(returnObj) });
      if (result.startDate == null) {
        return result.returnObj;
      }
      startDate = result.startDate;

      let closeDate = consolidateCloseDate({ cDateStr: _closeDateStr, cTimeStr: _closeTimeStr });
      returnObj.closeDateStr = closeDate.format('YYYY-MM-DD');
      returnObj.closeTimeStr = closeDate.format('HH:mm');
      result = processCloseDate({ closeDate, _returnObj: cloneDeep(returnObj) });
      if (result.closeDate == null) {
        return result.returnObj;
      }
      closeDate = result.closeDate;

      let durationInMinutes = convertDisplayToDuration(_durationDisplay, durationConversionOpts).value;
      
      const validateOutOfProjectRange = (preDateObj, dateObj) => {
        if (!_skipOutOfProjectDateCheck && dateObj != null) {
          const dateOnly = dateObj.clone().hour(0).minute(0).second(0).millisecond(0);
          const prevDateOnly = preDateObj != null? preDateObj.clone().hour(0).minute(0).second(0).millisecond(0) : null;
          if (projectStartDate != null && projectStartDate.diff(dateOnly) > 0) {
            if (prevDateOnly == null || projectStartDate.diff(prevDateOnly) <= 0) {
              return {
                type: MODAL_TYPES.OUT_OF_PROJECT_DATE_RANGE
                , trigger: _trigger
                , dateStr: dateObj.format('YYYY-MM-DD')
                , projectDateStr: projectStartDateStr
                , isStart: true
              }
            }
          } else if (projectCloseDate != null && projectCloseDate.diff(dateOnly) < 0) {
            if ((prevDateOnly == null || projectCloseDate.diff(prevDateOnly) >= 0)) {
              return {
                type: MODAL_TYPES.OUT_OF_PROJECT_DATE_RANGE
                , trigger: _trigger
                , dateStr: dateObj.format('YYYY-MM-DD')
                , projectDateStr: projectCloseDateStr
                , isStart: false
              }
            }
          }
        }
        return null;
      }

      if (_lockDuration) {
        if (closeDate.diff(startDate) > 0) {
          const gapInMinutes = __calculateWorkCapacity(_calendar, startDate, closeDate, { isAuto: _tAutoScheduleMode, maxDurationInMinutes: _maxDurationInMinutes }).value;
          //Recalculate startDate with closeDate and duration if the gap between startDate and closeDate is less than duration.
          if (gapInMinutes < durationInMinutes) {
            if (_projScheduleFromStart) {
              let newCloseDate = __addDurationToDate(_calendar, startDate, durationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
              const modal = validateOutOfProjectRange(closeDate, newCloseDate);
              if (modal != null) {
                returnObj.modal = modal;
                return returnObj;
              }
              returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD')
              returnObj.closeTimeStr = newCloseDate.format('HH:mm')
            } else {
              let newStartDate = __addDurationToDate(_calendar, closeDate, durationInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
              const modal = validateOutOfProjectRange(startDate, newStartDate);
              if (modal != null) {
                returnObj.modal = modal;
                return returnObj;
              }
              returnObj.startDateStr = newStartDate.format('YYYY-MM-DD')
              returnObj.startTimeStr = newStartDate.format('HH:mm')
            }
          }
        } else {
          //Defensive code: Recalculate startDate/closeDate (depends on projectScheduleFromStart) with duration when startDate is later than closeDate
          if (_projScheduleFromStart) {
            let newCloseDate = __addDurationToDate(_calendar, startDate, durationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
            const modal = validateOutOfProjectRange(closeDate, newCloseDate);
            if (modal != null) {
              returnObj.modal = modal;
              return returnObj;
            }
            returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD')
            returnObj.closeTimeStr = newCloseDate.format('HH:mm')
          } else {
            let newStartDate = __addDurationToDate(_calendar, closeDate, durationInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
            const modal = validateOutOfProjectRange(startDate, newStartDate);
            if (modal != null) {
              returnObj.modal = modal;
              return returnObj;
            }
            returnObj.startDateStr = newStartDate.format('YYYY-MM-DD')
            returnObj.startTimeStr = newStartDate.format('HH:mm')
          }
        }
      }
      else {
        if (_projScheduleFromStart) {
          let newCloseDate = __addDurationToDate(_calendar, startDate, durationInMinutes, { isStart: true, isAuto: _tAutoScheduleMode }).value;
          const modal = validateOutOfProjectRange(closeDate, newCloseDate);
          if (modal != null) {
            returnObj.modal = modal;
            return returnObj;
          }
          returnObj.closeDateStr = newCloseDate.format('YYYY-MM-DD')
          returnObj.closeTimeStr = newCloseDate.format('HH:mm')
        } else {
          let newStartDate = __addDurationToDate(_calendar, closeDate, durationInMinutes, { isStart: false, isAuto: _tAutoScheduleMode }).value;
          const modal = validateOutOfProjectRange(startDate, newStartDate);
          if (modal != null) {
            returnObj.modal = modal;
            return returnObj;
          }
          returnObj.startDateStr = newStartDate.format('YYYY-MM-DD')
          returnObj.startTimeStr = newStartDate.format('HH:mm')
        }
      }

    } else { //_trigger == TRIGGERS.CONSTRAINT_TYPE || _trigger == TRIGGERS.CONSTRAINT_DATE || _trigger == TRIGGERS.TASK_SCHEDULE_MODE
      //Prepare out-of-project-date-range modal data when projectDate is not null and constraintDate is out of project date range.
      if (isConstraintTypeStartDateRelated(returnObj.constraintType)) {
        let startDate = consolidateStartDate({ sDateStr: _constraintDateStr, sTimeStr: _startTimeStr });
        const result = processStartDate({ startDate, _returnObj: cloneDeep(returnObj) });
        if (result.startDate == null) {
          return result.returnObj;
        }
      } else if (isConstraintTypeCloseDateRelated(returnObj.constraintType)) {
        let closeDate = consolidateCloseDate({ cDateStr: _constraintDateStr, cTimeStr: _closeTimeStr });
        const result = processCloseDate({ closeDate, _returnObj: cloneDeep(returnObj) });
        if (result.closeDate == null) {
          return result.returnObj;
        }
      } //else constraintType is ASAP/ALAP. Skip out-of-project-date-range check because constraintDate is null. 
      
      honourConstraint = true;
    }
    
    const result = processConstraint({ _returnObj: cloneDeep(returnObj), honourConstraint });
    return result.returnObj;
  }

}

/**
 * It is only applicable for calculating missing duration
 * Round up the calculated duration and recalculate new dateTime (either startTime or closeTime) based on the given trigger
 */
export function calcRoundedDuration({
  trigger
  , calendar  
  , projScheduleFromStart=true
  , taskAutoScheduleMode=true
  //Values
  , startDateStr, startTimeStr
  , closeDateStr, closeTimeStr
  , durationConversionOpts={}  
}={}) {
  return calcDateTimeDurationv2({
    trigger
    , calendar  
    , projScheduleFromStart
    , taskAutoScheduleMode
    , autoMoveForNonWorkingDay: true
    , skipOutOfProjectDateCheck: true
    //Values
    , startDateStr, startTimeStr
    , closeDateStr, closeTimeStr
    , durationDisplay: null
    , durationConversionOpts
    , enableRoundUpResult: true
  })
}