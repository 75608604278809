var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex" }, [
    _c("div", { staticClass: "d-flex select-button" }, [
      _c(
        "div",
        {
          ref: "menuButton",
          staticClass: "customHeaderMenuButton",
          on: {
            click: function ($event) {
              return _vm.onSelectToggle($event)
            },
          },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "selection-icon",
            attrs: {
              icon: _vm.selectedAll
                ? ["far", "square-check"]
                : _vm.selectedSome
                ? ["far", "square-minus"]
                : ["far", "square"],
            },
          }),
        ],
        1
      ),
      _vm.params.enableMenu
        ? _c(
            "div",
            {
              ref: "menuButton",
              staticClass: "select-menu ml-1 customHeaderMenuButton",
              on: {
                click: function ($event) {
                  return _vm.onMenuClicked($event)
                },
              },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "selection-icon",
                attrs: { icon: ["far", "caret-down"] },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c("span", { staticClass: "ml-1 display-name" }, [
      _vm._v(_vm._s(_vm.params.displayName)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }