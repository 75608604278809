//This file is subject to be obsolete. Try not to refer this file.
const payFrequencies = function(parentComponent) {
  const payFreq = [    
    { value: 'One_shot', text: 'One Shot'},
    { value: 'Annually', text: 'Pay Annually' },
    { value: 'Monthly', text: 'Pay Monthly' },
    { value: 'Weekly', text: 'Pay Weekly' },
    { value: 'Daily', text: 'Pay Daily' },
    { value: 'Hourly', text: 'Pay Hourly' }
  ]
  if(parentComponent != null) {
    for(let f of payFreq) {
      f.text = parentComponent.$t(`payFrequency.${f.value}`);
    }
  }
  return payFreq;
}

export default payFrequencies;
