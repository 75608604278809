var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "edit-cell" },
    [
      _c("ColorModal", {
        attrs: { show: _vm.colorShow, color: _vm.value, update: _vm.update },
        on: { cancel: _vm.closePicker, changed: _vm.changed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }