import { profileService } from '@/services';

const folderKey = 'company';

export const sandboxProfileService = {
  create,
  list,
  update,
  remove
};

/**
 * Create a new dataviewProfile 
 * by passing necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function create(data, sandboxId, companyId) {
  return profileService.create(data, sandboxId, folderKey, companyId);
}

/**
 * Retrieving a concise list of dataviewProfile info
 */
function list(sandboxId, companyId) {
  return profileService.list(sandboxId, folderKey, companyId);
}

/**
 * Update dataviewProfile details 
 * by passing  necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function update(data, sandboxId, companyId) {
  return profileService.update(data, sandboxId, folderKey, companyId);
}

/**
 * Delete a list of data view Profiles
 * by passing in an array list of uuIds
 * @param {Array} data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data, sandboxId, companyId) {
  return profileService.remove(data, sandboxId, folderKey, companyId);
}
