import { httpAjax } from '@/helpers';
import { getKeysWithoutRedactedFields, getRedactedFields } from './common';

export const taskViewService = {
  listTree,
  listSummaryTree,
  durationComparator,
  numberComparator,
  booleanComparator: numberComparator,
  skillComparator,
  staffComparator,
  noteComparator,
  stageComparator,
  resourceComparator,
  rebateComparator,
  constraintComparator,
  getUniqueTaskName,
  getUniqueIdentifier,
  getUniqueParentTasks,
  getUniqueSkillName,
  getUniqueStaffName,
  getUniqueRebateName,
  getUniqueResourceName,
  getUniqueStageName,
  getUniqueTagName,
  getUniqueType,
  getUniqueComplexity,
  getUniquePriority,
  getTaskUniqueCustomFieldName
};

function transformSortField(field) {
  if (field === 'totalActualDuration') {
    return 'actualDuration';
  }
  else if (field === 'notes') {
    return 'NOTE.text';
  }
  else if (field === 'skills') {
    return 'SKILL.name';
  }
  else if (field === 'stage') {
    return 'STAGE.name';
  }
  else if (field === 'staffs') {
    return 'STAFF.name';
  }
  else if (field === 'resources') {
    return 'RESOURCE.name';
  }
  else if (field === 'constraint') {
    return 'constraintType';
  }
  else if (field === 'rebates') {
    return 'REBATE.name';
  }
  else if (field === 'tag') {
    return 'TAG.name';
  }
  else if (field === 'taskPath') {
    return 'fullPath';
  }
  else if (field === 'template') {
    return 'PROJECT_TEMPLATE.name';
  }
  return field;
}

function listTree(params, projectId, requestedFields, isTemplate=false, customFields=[], { skillCustomFields=[], resourceCustomFields=[], noteCustomFields=[] }={}) {
  const K_PROJECT = isTemplate? 'PROJECT_TEMPLATE' : params.entity ? '' : 'PROJECT';
  const K_TASK = isTemplate? 'TASK_TEMPLATE' : params.entity ? 'TASK(one)' : 'TASK';

  // always include color
  if (requestedFields.length > 0 &&
      requestedFields[0] !== 'estimatedDurationOnly') { // special case for spreadsheet mode
    requestedFields.push('color');
    if (!isTemplate) {
      requestedFields.push('stageColor');
    }
    requestedFields.push('skillColor');
    requestedFields.push('staffColor');
    requestedFields.push('resourceColor');
    requestedFields.push('rebateColor');
    requestedFields.push('fileColor');
  }
  
  const requestedSelectors = [];
  requestedFields.forEach(i => {
    const converted = __convertGridColumnToSelector(i, false);
    if (Array.isArray(converted)) {
      requestedSelectors.push(...converted);
    } else {
      requestedSelectors.push(converted);
    }
  });

  const { fields, mandatoryFields } = _prepareFields(requestedSelectors, K_PROJECT, K_TASK, customFields, { skillCustomFields, resourceCustomFields, noteCustomFields });
  
  let data = {
    'name'    : `${isTemplate? 'Template Task': 'Task'} Tree List (Dynamic)`
    ,'type'   : 'msql'
    ,'sep_array': '/'
    ,'timeout': 300
    ,'start'  : params.start
    ,'limit'  : params.limit
    ,'nominate': params.entity ? params.entity : `${K_PROJECT}.${K_TASK}`
    ,'holder' : [projectId]
    ,'select' : Object.keys(fields).map(i => fields[i])
  }

  data['filter'] = [
    //[`${K_PROJECT}.uuId`, 'eq', projectId]
  ]

  if (params.filter) {
    data['holder'] = params.filter;
  } else {
    if (params.filterValue) {
      data['filter'].push("_or_");
      const filters = [[`${K_PROJECT}.${K_TASK}.name`, 'has', params.filterValue]];
      if (params.self.canView(K_TASK, ['description'])) {
        filters.push([`${K_PROJECT}.${K_TASK}.description`, 'has', params.filterValue]);
      }
      if (params.self.canView(K_TASK, ['identifier'])) {
        filters.push([`${K_PROJECT}.${K_TASK}.identifier`, 'has', params.filterValue]);
      }
  
      if (params.self.canView('STAFF', ['name'])) {
        filters.push([`${K_PROJECT}.${K_TASK}.STAFF.name`, 'has', params.filterValue]);
      }
  
      if (params.self.canView('TAG', ['name'])) {
        filters.push([`${K_PROJECT}.${K_TASK}.TAG.name`, 'has', params.filterValue]);
      }
      
      if (params.self.canView('RESOURCE', ['name'])) {
        filters.push([`${K_PROJECT}.${K_TASK}.RESOURCE.name`, 'has', params.filterValue]);
      }
      
      if (params.self.canView('REBATE', ['name'])) {
        filters.push([`${K_PROJECT}.${K_TASK}.REBATE.name`, 'has', params.filterValue]);
      }
  
      if (params.self.canView('SKILL', ['name'])) {
        filters.push([`${K_PROJECT}.${K_TASK}.SKILL.name`, 'has', params.filterValue]);
      }
      data['filter'].push(filters);
    }

    //BadgeFilter related
    if (Array.isArray(params.badgeFilters) && params.badgeFilters.length > 0) {
      const badgeFilterList = [];
      for (const f of params.badgeFilters) {
        if (f.field == null) {
          continue;
        }
        
        const link = f.link ? '.PARENT_ALL_TASK' : '';
        let field = null;
        if (f.field == 'taskName') {
          field = `${K_PROJECT}.${K_TASK}${link}.name`;
        } else if (f.field == 'description') {
          field = `${K_PROJECT}.${K_TASK}${link}.description`;       
        } else if (f.field == 'identifier') {
          field = `${K_PROJECT}.${K_TASK}${link}.identifier`;       
        } else if (f.field == 'parentTasks') {
          field = `${K_PROJECT}.${K_TASK}${link}.fullPath`;
        } else if (f.field == 'rebateName') {
          field = `${K_PROJECT}.${K_TASK}${link}.REBATE.name`;
        } else if (f.field == 'resourceName') {
          field = `${K_PROJECT}.${K_TASK}${link}.RESOURCE.name`;
        } else if (f.field == 'skillName') {
          field = `${K_PROJECT}.${K_TASK}${link}.SKILL.name`;
        } else if (f.field == 'staffName') {
          field = `${K_PROJECT}.${K_TASK}${link}.STAFF.name`;
        } else if (f.field == 'stageName') {
          field = `${K_PROJECT}.${K_TASK}${link}.STAGE.name`;
        } else if (f.field == 'tagName') {
          field = `${K_PROJECT}.${K_TASK}${link}.TAG.name`;
        } else if (f.field == 'type') {
          field = `${K_PROJECT}.${K_TASK}.taskType`;
        } else if (f.field == 'complexity') {
          field = `${K_PROJECT}.${K_TASK}${link}.complexity`;
        } else if (f.field == 'priority') {
          field = `${K_PROJECT}.${K_TASK}${link}.priority`;        
        } else if (f.field == 'closeTime') {
          field = `${K_PROJECT}.${K_TASK}${link}.closeTime`;
        } else if (f.field == 'startTime') {
          field = `${K_PROJECT}.${K_TASK}${link}.startTime`;
        } else if (f.field == 'progress') {
          field = `${K_PROJECT}.${K_TASK}${link}.progress`;
        } else {
          const found = customFields.find(i => i.name == f.field);
          if (found != null) {
            field = `${K_PROJECT}.${K_TASK}${link}.${found.name}`
          }
        } 

        if (field == null) {
          continue;
        }
        const valueList = [];
        const value = f.value;
        const value2 = f.value2;
        if (Array.isArray(value)) {
          for (const v of value) {
            if (f.field === 'parentTasks') {
              if (v.text != null && v.text.length > 0 && v.text !== '(Empty)' &&
                  (!f.operator || f.operator === 'is')) {
                valueList.push([field, 'has', v.text]);
              }
              else if (f.operator !== 'is') {
                valueList.push("_not_" ,[ [field, 'has', v.text] ])
              }
            }
            else {
              if (v.text != null && v.text.length > 0 && v.text !== '(Empty)') {
                valueList.push([field, !f.operator || f.operator === 'is' ? 'eq' : 'neq', f.field === 'type' && v.text === 'Summary Task' ? 'Project' : v.text]);
              }
              else if (!f.operator || f.operator === 'is') {
                valueList.push("_not_" ,[ [field] ])
              }
              else {
                valueList.push([field])
              }
            }
          }
        }
        else {
          // it is a single value
          if (f.field === 'progress') {
            if (f.operator === 'between') {
              valueList.push('_and_');
              // divide by 100
              valueList.push([
                [
                  field, 'gte', value / 100.0
                ],
                [
                  field, 'lte', value2 / 100.0
                ]
              ]);
            }
            else {
              // divide by 100
              valueList.push([field, !f.operator || f.operator === 'is' ? 'eq' : f.operator === 'not' ? 'neq' : f.operator, value / 100.0]);
            }
          }
          else if (f.operator === 'between') {
            valueList.push('_and_');
            valueList.push([
              [
                field, 'gte', value
              ],
              [
                field, 'lte', value2
              ]
            ]);
          }
          else {
            valueList.push([field, !f.operator || f.operator === 'is' ? 'eq' : f.operator === 'not' ? 'neq' : f.operator, value]);
          }
        }

        if (valueList.length > 0) {
          badgeFilterList.push(!f.operator || f.operator === 'is' ? '_or_' : '_and_');
          badgeFilterList.push(valueList);
        }
      }
      if (badgeFilterList.length > 0) {
        if (Array.isArray(data.filter) && data.filter.length > 0) {
          data.filter = [...data.filter, '_and_', badgeFilterList]
        } else {
          data.filter = ['_and_', badgeFilterList]
        }
      }
    }
  } 

  
  
  if (!params.entity) {
    data['sort'] = [
      [isTemplate ? "PROJECT_TEMPLATE.TASK_TEMPLATE.PARENT_TASK_TEMPLATE.uuId" : "PROJECT.TASK.PARENT_TASK.uuId", "asc"]
      ]
  }
  
  if (params.ksort && params.ksort.length > 0) {
   // Can't run lowerCase on numeric fields
    let numeric = ['fixedCost', 'avatarRef', 'priority', 'fixedDuration', 'startTime', 'closeTime'];
    if (!params.sortByParent) {
      // if it is a flat list don't sort by parent
      data['sort'] = [];
    }
    for (const ks of params.ksort) {
      const field = transformSortField(ks.colId);
      if (numeric.includes(field)) {
        data['sort'].push([`${K_PROJECT}.${K_TASK}.${field}`, ks.direction == 'asc'? 'incr' : ks.direction == 'desc'? 'decr' : ks.direction]);
      }
      else {
        data['sort'].push([`${K_PROJECT}.${K_TASK}.${field}`, ks.direction == 'asc'? 'incr' : ks.direction == 'desc'? 'decr' : ks.direction, 'lowerCase']);
      }
    }
  }
  
  if (params.ksort) {
    // Can't run lowerCase on numeric fields
    let numeric = ['fixedCost', 'avatarRef', 'priority', 'fixedDuration', 'startTime', 'closeTime'];
    if (!params.sortByParent) {
      // if it is a flat list don't sort by parent
      data['sort'] = [];
    }
    if (Array.isArray(params.ksort)) {
      for (const ks of params.ksort) {
        const field = transformSortField(ks.colId);
        if (numeric.includes(field)) {
          data['sort'].push([`${K_PROJECT}.${K_TASK}.${field}`, ks.direction == 'asc'? 'incr' : ks.direction == 'desc'? 'decr' : ks.direction]);
        }
        else {
          data['sort'].push([`${K_PROJECT}.${K_TASK}.${field}`, ks.direction == 'asc'? 'incr' : ks.direction == 'desc'? 'decr' : ks.direction, 'lowerCase']);
        }
      }
    } else { //legacy sort handling
      const field = transformSortField(params.ksort.colId);
      if (numeric.includes(field)) {
        data['sort'].push([`${K_PROJECT}.${K_TASK}.${field}`, params.ksort.direction]);
      }
      else {
        data['sort'].push([`${K_PROJECT}.${K_TASK}.${field}`, params.ksort.direction, 'lowerCase']);
      }
    }
     
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;
  delete params.badgeFilters;
  delete params.entity;
  delete params.self;
  delete params.sortByParent;
  
  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    
    //Check if mandatory fields are redacted
    const mandatoryFieldKeys = Object.keys(mandatoryFields)
    const redactedFields = getRedactedFields(fields, response);
    if (redactedFields.length > 0) {
      for (const key of redactedFields) {
        if (mandatoryFieldKeys.includes(key)) {
          return { 
            arg_total: 0
            , arg_ksort: ''
            , arg_order: null
            , fields: []
            , data: []
            , lackOfMandatoryFields: true
          }
        }
      }
    }
    
    const keys = getKeysWithoutRedactedFields(fields, response);
    const columns = keys.map(i => {
      return __convertGridColumnToSelector(i, true);
    });

    return { 
      arg_total: response.data.arg_total,
      arg_count: response.data.arg_count,
      status: response.status,
      jobClue: response.data.jobClue,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      fields: [...new Set(columns)],
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        
        //Preprocess Parent details
        if (Array.isArray(result.pUuId)) {
          result.pUuId = result.pUuId[0];
        }
        result.pUuId = !result.pUuId || result.pUuId.length == 0 || result.pUuId == projectId? 'ROOT': result.pUuId;
        //result.pName = !result.pName || result.pName.length == 0? '': result.pName[0];


        //Preprocess startTime and closeTime
        if (result.startTime == 'null') {
          result.startTime = null;
        }

        if (result.closeTime == 'null') {
          result.closeTime = null;
        }

        //Preprocess stage details
        if (result.stageId) {
          if(result.stageId.length > 0) {
            const stgId = result.stageId[0] != null? result.stageId[0] : null;
            const stgName = result.stageName[0] != null? result.stageName[0] : null;
            result.stage = { uuId: stgId, name: stgName }
          } else {
            result.stage = { uuId: null, name: '' }
          }
        }
        delete result.stageId;
        delete result.stageName;

        //Preprocess note details
        if (result.noteId) {
          result.notes = [];
          for (let i = 0; i < result.noteId.length; i++) {
            const author = result.noteFirstName.length > i ? `${result.noteFirstName[i]} ${result.noteLastName[i]}` : null;
            const n = { 
              uuId: result.noteId[i]
              , text: result.noteText[i]
              , identifier: result.noteIdentifier[i]
              , modified: result.noteModified[i]
              , author: author
              , authorRef: result.noteAuthorRef[i]
            }
            for (const f of noteCustomFields) {
              n[f.name] = result[`note_${f.name}`][i]
            }
            result.notes.push(n);
          }
          result.notes.sort((a, b) => {
            return b.modified - a.modified;
          });
        }
        delete result.noteId;
        delete result.noteText;
        delete result.noteIdentifier;
        delete result.noteModified;
        delete result.noteFirstName;
        delete result.noteLastName;
        delete result.noteAuthorRef;

        // Preprocess rebates
        result.rebates = [];
        if (result.rebateUuid) {
          for (let i = 0; i < result.rebateUuid.length; i++) {
            result.rebates.push({
              uuId: result.rebateUuid[i],
              name: result.rebateName[i],
              rebate: result.rebateRebate[i],
            });
          }
        }
        
        //Preprocess template
        if(result.templateUuId && result.templateUuId.length > 0) {
          result.template = {
            uuIds: result.templateUuId,
            names: result.templateName
          }
        } else {
          result.template = { uuIds: [], names: [] };
        }
        delete result.templateUuId;
        delete result.templateName;

        //Preprocess constraint type and constraint time
        if (result.constraintType && result.taskType != 'Project') {
          result.constraint = {
            type: result.constraintType,
            time: result.constraintTime == null || result.constraintTime == 'null'? null : result.constraintTime
          }
        } else {
          result.constraint = { type: null, time: null }
        }
        // Don't delete them; we need the keys to exist for cache detection
        // delete result.constraintType;
        // delete result.constraintTime;

        //Preprocess Skill details
        result.skills = [];
        if(result.skillIds && result.skillIds.length > 0) {
          for(let i = 0, len = result.skillIds.length; i < len; i++) {
            const s = {
              uuId: result.skillIds[i],
              name: result.skillNames[i],
              level: result.skillLevels[i]
            }
            for (const f of skillCustomFields) {
              s[f.name] = result[`skill_${f.name}`][i]
            }
            result.skills.push(s)
          }
        }
        delete result.skillIds;
        delete result.skillNames;
        delete result.skillLevels;
        for (const f of skillCustomFields) {
          delete result[`skill_${f.name}`]
        }

        //Preprocess Staff details
        result.staffs = [];
        if(result.staffIds && result.staffIds.length > 0) {
          for(let i = 0, len = result.staffIds.length; i < len; i++) {
            const firstName = result.staffFirstNames[i];
            const lastName = result.staffLastNames[i];
            result.staffs.push({
              uuId: result.staffIds[i],
              name: `${firstName || ''}${firstName && firstName.length > 0?' ':''}${lastName || ''}`,
              utilization: result.staffUtilizations[i],
              unit: result.staffUnits[i],
              duration: result.staffDuration[i] / 60000, // convert ms to minutes
              durationAUM: result.staffDurationAUM[i],
              genericStaff: result.staffGenericStaff[i]
            })
          }
        }
        delete result.staffIds;
        delete result.staffFirstNames;
        delete result.staffLastNames;
        delete result.staffUtilizations;
        delete result.staffUnits;
        delete result.staffDuration;
        delete result.staffDurationAUM;
        delete result.staffGenericStaff;

        //Preprocess Resources details
        result.resources = [];
        if(result.resourceIds && result.resourceIds.length > 0) {
          for(let i = 0, len = result.resourceIds.length; i < len; i++) {
            const r = {
              uuId: result.resourceIds[i]
              , name: result.resourceNames[i]
              , unit: result.resourceUnits[i]
              , utilization: result.resourceUtilizations[i]
            }
            for (const f of resourceCustomFields) {
              r[f.name] = result[`resource_${f.name}`][i]
            }
            result.resources.push(r);
          }
        }
        delete result.resourceIds;
        delete result.resourceNames;
        delete result.resourceUnits;
        delete result.resourceUtilizations;
        for (const f of resourceCustomFields) {
          delete result[`resource_${f.name}`]
        }
        
        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

function listSummaryTree(params, projectId, requestedFields, isTemplate=false) {

  const K_PROJECT = isTemplate? 'PROJECT_TEMPLATE' : params.entity ? '' : 'PROJECT';
  const K_TASK = isTemplate? 'TASK_TEMPLATE' : 'TASK';
  const prefix = `${K_PROJECT}.${K_TASK}`
  const fields = {
    uuId:  [`${prefix}.uuId`],
    name:  [`${prefix}.name`],
    pUuId: [`${prefix}.PARENT_${K_TASK}.uuId`]

  }

  let data = {
    'name'    : `${isTemplate? 'Template Task': 'Task'} Tree List (Dynamic)`
    ,'type'   : 'msql'
    ,'sep_array': '/'
    ,'timeout': 300
    ,'start'  : params.start
    ,'limit'  : params.limit
    ,'nominate': `${K_PROJECT}.${K_TASK}`
    ,'holder' : [projectId]
    ,'select' : Object.keys(fields).map(i => fields[i])
  }

  data['filter'] = [
    ["PROJECT.TASK.taskType", "eq", "Project"]
  ]
  
  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    
    const keys = getKeysWithoutRedactedFields(fields, response);
    const columns = keys.map(i => {
      return __convertGridColumnToSelector(i, true);
    });

    return { 
      arg_total: response.data.arg_total,
      arg_count: response.data.arg_count,
      status: response.status,
      jobClue: response.data.jobClue,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      fields: [...new Set(columns)],
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        
        //Preprocess Parent details
        result.pUuId = !result.pUuId || result.pUuId.length == 0 || result.pUuId == projectId? 'ROOT': result.pUuId[0];
        result.pName = !result.pName || result.pName.length == 0? '': result.pName[0];

        return result;
      })
    }
  });
}

function _prepareFields(requestedFields, projectKey, taskKey, customFields=[], { skillCustomFields=[], resourceCustomFields=[], noteCustomFields=[] }={}) {
  const prefix = projectKey !== '' ? `${projectKey}.${taskKey}` : taskKey;
  const fields = {
    uuId:  [`${prefix}.uuId`],
    name:  [`${prefix}.name`],
    pUuId: [`${prefix}.PARENT_${taskKey}.uuId`],
    //pName: [`${prefix}.PARENT_${taskKey}.name`],
    taskType: [`${prefix}.taskType`],
    order: [`${prefix}.order`],
    readOnly: [`${prefix}.readOnly`]
  }
  const mandatoryFields = JSON.parse(JSON.stringify(fields));

  const coreFields = ['uuId, name', 'pUuId', 'pName', 'taskType'];
  if(requestedFields != null && requestedFields.length > 0) {
    let remainingFields = requestedFields.filter(i => !coreFields.includes(i));

    const addDurationGroup = function(remainingFields, fields) {
      remainingFields = remainingFields.filter(i => !durationGroup.includes(i));
      fields.startTime = [`${prefix}.startTime`, 'null'];
      fields.closeTime = [`${prefix}.closeTime`, 'null'];
      fields.duration = [`${prefix}.duration`, 0, 'Days'];
      fields.durationAUM = [`${prefix}.durationAUM`];
      fields.lockDuration = [`${prefix}.lockDuration`];
      fields.autoScheduling = [`${prefix}.autoScheduling`];
      fields.constraintType = [`${prefix}.constraintType`];
      fields.constraintTime = [`${prefix}.constraintTime`, 'null'];
    }

    const durationGroup = ['startTime', 'closeTime', 'duration', 'durationAUM', 'lockDuration', 'autoScheduling', 'constraintType', 'constraintTime'];
    if(remainingFields.length > 0 && remainingFields.includes('estimatedDuration')) {
      remainingFields = remainingFields.filter(i => i != 'estimatedDuration');
      fields.estimatedDuration = [`${prefix}.estimatedDuration`, 0, 'Days'];
      addDurationGroup(remainingFields, fields);
    } else if(remainingFields.length > 0 && remainingFields.some(i => durationGroup.includes(i))) {
      addDurationGroup(remainingFields, fields);
    }
    else if (remainingFields.length > 0 && remainingFields[0] === 'estimatedDurationOnly') {
      fields.estimatedDuration = [`${prefix}.estimatedDuration`, 0, 'Days'];
    }

    //Tag is needed to filter stage option
    const stageGroup = ['stageId', 'stageName'];
    if(remainingFields.length > 0 && remainingFields.some(i => stageGroup.includes(i))) {
      remainingFields = remainingFields.filter(i => !stageGroup.includes(i) && 'tag' != i);
      fields.stageId = [`${prefix}.STAGE.uuId`];
      fields.stageName = [`${prefix}.STAGE.name`];
      fields.tag = [`${prefix}.TAG.name`];
    }

    const skillGroup = ['skillIds', 'skillNames', 'skillLevels'];
    if(remainingFields.length > 0 && remainingFields.some(i => skillGroup.includes(i))) {
      remainingFields = remainingFields.filter(i => !skillGroup.includes(i));
      fields.skillIds = [`${prefix}.SKILL.uuId`];
      fields.skillNames = [`${prefix}.SKILL.name`];
      fields.skillLevels = [`${prefix}.${taskKey}-SKILL.level`];
      for (const f of skillCustomFields) {
        fields[`skill_${f.name}`] = [`${prefix}.${taskKey}-SKILL.${f.name}`];
      }
    }

    const staffGroup = ['staffIds', 'staffFirstNames', 'staffLastNames', 'staffUtilizations', 'staffDuration', 'staffDurationAUM', 'staffGenericStaff'];
    const addStaffGroup = function(remainingFields, fields) {
      remainingFields = remainingFields.filter(i => !staffGroup.includes(i));
      fields.staffIds = [`${prefix}.STAFF.uuId`];
      fields.staffFirstNames = [`${prefix}.STAFF.firstName`];
      fields.staffLastNames = [`${prefix}.STAFF.lastName`];
      fields.staffGenericStaff = [`${prefix}.STAFF.genericStaff`];
      fields.staffUtilizations = [`${prefix}.${taskKey}-STAFF.utilization`];
      fields.staffUnits = [`${prefix}.${taskKey}-STAFF.quantity`];
      fields.staffDuration = [`${prefix}.${taskKey}-STAFF.duration`];
      fields.staffDurationAUM = [`${prefix}.${taskKey}-STAFF.durationAUM`];
    }
    if (fields.duration != null) {
      //duration calculation needs staff calendar. So staff data is needed 
      addStaffGroup(remainingFields, fields);
    }
    if(remainingFields.length > 0 && remainingFields.includes('totalActualDuration')) {
      remainingFields = remainingFields.filter(i => i != 'totalActualDuration');
      fields.totalActualDuration = [`${prefix}.actualDuration`, 0, "Days"];
      addStaffGroup(remainingFields, fields);
    } else if(remainingFields.length > 0 && remainingFields.some(i => staffGroup.includes(i))) {
      addStaffGroup(remainingFields, fields);
    }

    const resourceGroup = ['resourceIds', 'resourceNames', 'resourceUnits'];
    if(remainingFields.length > 0 && remainingFields.some(i => resourceGroup.includes(i))) {
      remainingFields = remainingFields.filter(i => !resourceGroup.includes(i));
      fields.resourceIds = [`${prefix}.RESOURCE.uuId`];
      fields.resourceNames = [`${prefix}.RESOURCE.name`];
      fields.resourceUnits = [`${prefix}.${taskKey}-RESOURCE.quantity`];
      fields.resourceUtilizations = [`${prefix}.${taskKey}-RESOURCE.utilization`];
      for (const f of resourceCustomFields) {
        fields[`resource_${f.name}`] = [`${prefix}.${taskKey}-RESOURCE.${f.name}`];
      }
    }

    const noteGroup = ['noteId', 'noteText', 'noteIdentifier', 'noteModified', 'noteFirstName', 'noteLastName', 'noteAuthorRef'];
    if(remainingFields.length > 0 && remainingFields.some(i => noteGroup.includes(i))) {
      remainingFields = remainingFields.filter(i => !noteGroup.includes(i));
      fields.noteId = [`${prefix}.NOTE.uuId`];
      fields.noteText = [`${prefix}.NOTE.text`];
      fields.noteIdentifier = [`${prefix}.NOTE.identifier`];
      fields.noteModified = [`${prefix}.NOTE.modified`];
      fields.noteFirstName = [`${prefix}.NOTE.USER.firstName`];
      fields.noteLastName = [`${prefix}.NOTE.USER.lastName`];
      fields.noteAuthorRef = [`${prefix}.NOTE.USER.uuId`];
      for (const f of noteCustomFields) {
        fields[`note_${f.name}`] = [`${prefix}.NOTE.${f.name}`];
      }
    }

    const rebateGroup = ['rebateUuid', 'rebateName', 'rebateRebate'];
    if(remainingFields.length > 0 && remainingFields.some(i => rebateGroup.includes(i))) {
      remainingFields = remainingFields.filter(i => !rebateGroup.includes(i));
      fields.rebateUuid = [`${prefix}.REBATE.uuId`];
      fields.rebateName = [`${prefix}.REBATE.name`];
      fields.rebateRebate = [`${prefix}.REBATE.rebate`];
    }

    const templateGroup = ['templateUuId', 'templateName'];
    if(remainingFields.length > 0 && remainingFields.some(i => templateGroup.includes(i))) {
      remainingFields = remainingFields.filter(i => !templateGroup.includes(i));
      fields.templateUuId = [`${prefix}.PROJECT_TEMPLATE.uuId`];
      fields.templateName = [`${prefix}.PROJECT_TEMPLATE.name`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('taskPath')) {
      remainingFields = remainingFields.filter(i => i != 'taskPath');
      fields.taskPath = [`${prefix}.fullPath`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('description')) {
      remainingFields = remainingFields.filter(i => i != 'description');
      fields.description = [`${prefix}.description`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('progress')) {
      remainingFields = remainingFields.filter(i => i != 'progress');
      fields.progress = [`${prefix}.progress`]
      // fields.progress = [['=actualProgress(A,B)', [`${prefix}`], true]];
    }

    if(remainingFields.length > 0 && remainingFields.includes('priority')) {
      remainingFields = remainingFields.filter(i => i != 'priority');
      fields.priority = [`${prefix}.priority`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('complexity')) {
      remainingFields = remainingFields.filter(i => i != 'complexity');
      fields.complexity = [`${prefix}.complexity`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('plannedCost')) {
      remainingFields = remainingFields.filter(i => i != 'plannedCost');
      fields.plannedCost = [['=plannedCost(A,B,C)', [`${prefix}`], '<AUTO>', false]];
    }

    if(remainingFields.length > 0 && remainingFields.includes('plannedCostNet')) {
      remainingFields = remainingFields.filter(i => i != 'plannedCostNet');
      fields.plannedCostNet = [['=plannedCost(A,B,C)', [`${prefix}`], '<AUTO>', true]];
    }

    if(remainingFields.length > 0 && remainingFields.includes('plannedProgress')) {
      remainingFields = remainingFields.filter(i => i != 'plannedProgress');
      fields.plannedProgress = [`${projectKey}.${taskKey}plannedProgress`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('avatarRef')) {
      remainingFields = remainingFields.filter(i => i != 'avatarRef');
      fields.avatarRef = [`${prefix}.avatarRef`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('estimatedCost')) {
      remainingFields = remainingFields.filter(i => i != 'estimatedCost');
      fields.estimatedCost = [`${prefix}.estimatedCost`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('estimatedCostNet')) {
      remainingFields = remainingFields.filter(i => i != 'estimatedCostNet');
      fields.estimatedCostNet = [`${prefix}.estimatedCostNet`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('actualCost')) {
      remainingFields = remainingFields.filter(i => i != 'actualCost');
      fields.actualCost = [`${prefix}.actualCost`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('actualCostNet')) {
      remainingFields = remainingFields.filter(i => i != 'actualCostNet');
      fields.actualCostNet = [`${prefix}.actualCostNet`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('fixedCost')) {
      remainingFields = remainingFields.filter(i => i != 'fixedCost');
      fields.fixedCost = [`${prefix}.fixedCost`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('fixedDuration')) {
      remainingFields = remainingFields.filter(i => i != 'fixedDuration');
      fields.fixedDuration = [`${prefix}.fixedDuration`, '0D', "Days"];
    }

    if(remainingFields.length > 0 && remainingFields.includes('fixedCostNet')) {
      remainingFields = remainingFields.filter(i => i != 'fixedCostNet');
      fields.fixedCostNet = [`${prefix}.fixedCostNet`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('totalFixedCost')) {
      remainingFields = remainingFields.filter(i => i != 'totalFixedCost');
      fields.totalFixedCost = [`${prefix}.totalFixedCost`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('totalFixedCostNet')) {
      remainingFields = remainingFields.filter(i => i != 'totalFixedCostNet');
      fields.totalFixedCostNet = [`${prefix}.totalFixedCostNet`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('estimatedTimeToComplete')) {
      remainingFields = remainingFields.filter(i => i != 'estimatedTimeToComplete');
      fields.estimatedTimeToComplete = [`${prefix}.estimatedTimeToComplete`, 0, "Days"];
      if (fields.duration == null) {
        addDurationGroup(remainingFields, fields);
      }
      if (fields.staffIds == null) {
        addStaffGroup(remainingFields, fields);
      }
    }

    
    if(remainingFields.length > 0 && remainingFields.includes('currencyCode')) {
      remainingFields = remainingFields.filter(i => i != 'currencyCode');
      fields.currencyCode = [`${prefix}.currencyCode`];
    } else {
      //Include currency when any cost field exists
      const costGroup = ['plannedCost', 'plannedCostNet', 'estimatedCost'
        , 'estimatedCostNet', 'actualCost', 'actualCostNet', 'fixedCost', 'fixedCostNet'
        , 'totalFixedCost', 'totalFixedCostNet'
      ]
      const fieldNames = Object.getOwnPropertyNames(fields);
      if (costGroup.some(i => fieldNames.includes(i))) {
        fields.currencyCode = [`${prefix}.currencyCode`];
      }
    }
    
    if(remainingFields.length > 0 && remainingFields.includes('identifier')) {
      remainingFields = remainingFields.filter(i => i != 'identifier');
      fields.identifier = [`${prefix}.identifier`];
    }
    
    if(remainingFields.length > 0 && remainingFields.includes('tag')) {
      remainingFields = remainingFields.filter(i => i != 'tag');
      fields.tag = [`${prefix}.TAG.name`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('color')) {
      remainingFields = remainingFields.filter(i => i != 'color');
      fields.color = [`${prefix}.color`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('stageColor')) {
      remainingFields = remainingFields.filter(i => i != 'stageColor');
      fields.stageColor = [`${prefix}.STAGE.color`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('skillColor')) {
      remainingFields = remainingFields.filter(i => i != 'skillColor');
      fields.skillColor = [`${prefix}.SKILL.color`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('staffColor')) {
      remainingFields = remainingFields.filter(i => i != 'staffColor');
      fields.staffColor = [`${prefix}.STAFF.color`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('resourceColor')) {
      remainingFields = remainingFields.filter(i => i != 'resourceColor');
      fields.resourceColor = [`${prefix}.RESOURCE.color`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('rebateColor')) {
      remainingFields = remainingFields.filter(i => i != 'rebateColor');
      fields.rebateColor = [`${prefix}.REBATE.color`];
    }

    if(remainingFields.length > 0 && remainingFields.includes('fileColor')) {
      remainingFields = remainingFields.filter(i => i != 'fileColor');
      fields.fileColor = [`${prefix}.STORAGE_FILE.color`];
    }

    if(remainingFields.length > 0 && Array.isArray(customFields) && customFields.length > 0) {
      for (const f of remainingFields) {
        if (customFields.find(i => i.name == f) != null)   {
          fields[f] = [`${prefix}.${f}`];
        }
      }
    }
  }

  return { fields, mandatoryFields };
}

/**
 * WARNING: This is private method.
 * Convert grid column name to query selector name.
 * If reverseMapping is true, convert query selector name to grid column name.
 * returned selector can be a string format or an array object.
 * @param {String} value 
 * @param {Boolean} reverseMapping Default is false.
 * @returns {String} result
 */
 function __convertGridColumnToSelector(value, reverseMapping=false) {
  const list = [
    { grid: 'template', selector: ['templateUuId', 'templateName'] }
    , { grid: 'rebates', selector: ['rebateUuid', 'rebateName', 'rebateRebate'] }
    , { grid: 'notes', selector: ['noteId', 'noteText', 'noteModified', 'noteFirstName', 'noteLastName', 'noteAuthorRef'] }
    , { grid: 'resources', selector: ['resourceIds', 'resourceNames', 'resourceUnits'] }
    , { grid: 'staffs', selector: ['staffId', 'staffFirstName', 'staffLastName', 'staffUtilization', 'staffDuration', 'staffDurationAUM', 'staffGenericStaff'] }
    , { grid: 'skills', selector: ['skillIds', 'skillNames', 'skillLevels'] }
    , { grid: 'stage', selector: ['stageId', 'stageName'] }
    , { grid: 'constraint', selector: ['constraintType', 'constraintTime'] }
  ];
  if(reverseMapping) {
    const matched = list.find(i => {
      const selector = i.selector;
      if (Array.isArray(selector)) {
        return selector.includes(value);
      } 
      return selector === value;
    });
    if(matched != null) {
      return matched.grid;
    }
    return value;
  }

  const matched = list.find(i => i.grid === value);
  if(matched != null) {
    return matched.selector;
  } else {
    return value;
  }
}

function durationComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (valueA != null && typeof valueA !== 'string' || valueB != null && typeof valueB !== 'string') {
    return 0; //Skip sorting when the date type of provided values are not string.
  }
  
  const vA = parseFloat(valueA.substring(0, valueA.length - 1));
  const vB = parseFloat(valueB.substring(0, valueB.length - 1));
  return vA - vB;
}

function numberComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }
  if ((typeof valueA !== 'number' && typeof valueA !== 'boolean') || 
      (typeof valueB !== 'number' && typeof valueB !== 'boolean')) {
    return 0; //Skip sorting when the date type of provided values are not string.
  }
  return valueA - valueB;
}

function skillComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
    return 0; //Skip sorting when the date type of provided values are not string.
  }

  return valueA.map(i => `${i.name}${i.level}`).join().localeCompare(
    valueB.map(i => `${i.name}${i.level}`).join());
}

function staffComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
    return 0; //Skip sorting when the date type of provided values are not string.
  }

  return valueA.map(i => `${i.name}${i.utilization}`).join().localeCompare(
    valueB.map(i => `${i.name}${i.utilization}`).join());
}

function noteComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
    return 0;
  }

  return valueA.map(i => i.text).join().localeCompare(
    valueB.map(i => i.text).join());
}

function stageComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if ( typeof valueA !== 'object' || typeof valueB !== 'object') {
    return 0;
  }

  if (valueA.name == null) {
    return -1;
  }

  if (valueB.name == null) {
    return 1;
  }

  return valueA.name.localeCompare(valueB.name);
}

function resourceComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
    return 0;
  }

  return valueA.map(i => `${i.name}${i.unit}`).join().localeCompare(
    valueB.map(i => `${i.name}${i.unit}`).join());
}

function rebateComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
    return 0;
  }

  return valueA.map(i => `${i.name}${i.rebate}`).join().localeCompare(
    valueB.map(i => `${i.name}${i.rebate}`).join());
}

function constraintComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null || valueA.type == null) {
    return -1;
  }
  if (valueB == null || valueB.type == null) {
    return 1;
  }

  if ( typeof valueA !== 'object' || typeof valueB !== 'object') {
    return 0;
  }

  if (valueA.type == null) {
    return -1;
  }

  if (valueB.type == null) {
    return 1;
  }

  if (valueA.type != valueB.type) {
    return valueA.type.localeCompare(valueB.type);
  }
  if (valueA.time == null) {
    return -1;
  }
  if (valueB.time == null) {
    return 1;
  }
  return valueA.time - valueB.time;
}

function getUniqueTaskName(projectId) {
  return __getUniqueName(projectId, 'Task', ['PROJECT.TASK.name'])
}

function getUniqueIdentifier(projectId) {
  return __getUniqueName(projectId, 'Tag', ['PROJECT.TASK.identifier'])
}

function getUniqueParentTasks(projectId) {
  return __getUniqueParentTaskName(projectId, 'fullPath', ['PROJECT.TASK.fullPath'])
}

function getUniqueType(projectId) {
  return __getUniqueName(projectId, 'Tag', ['PROJECT.TASK.taskType'])
}

function getUniqueComplexity(projectId) {
  return __getUniqueName(projectId, 'Tag', ['PROJECT.TASK.complexity'])
}

function getUniquePriority(projectId) {
  return __getUniqueName(projectId, 'Tag', ['PROJECT.TASK.priority'])
}

function getUniqueSkillName(projectId) {
  return __getUniqueName(projectId, 'Skill', ['PROJECT.TASK.SKILL.name'])
}

function getUniqueStaffName(projectId) {
  return __getUniqueName(projectId, 'Tag', ['PROJECT.TASK.STAFF.name'])
}

function getUniqueRebateName(projectId) {
  return __getUniqueName(projectId, 'Tag', ['PROJECT.TASK.REBATE.name'])
}

function getUniqueResourceName(projectId) {
  return __getUniqueName(projectId, 'Tag', ['PROJECT.TASK.RESOURCE.name'])
}

function getUniqueStageName(projectId) {
  return __getUniqueName(projectId, 'Tag', ['PROJECT.TASK.STAGE.name'])
}

function getUniqueTagName(projectId) {
  return __getUniqueName(projectId, 'Tag', ['PROJECT.TASK.TAG.name'])
}

function getTaskUniqueCustomFieldName(projectId, fieldName) {
  return __getUniqueName(projectId, 'Task', [`PROJECT.TASK.${fieldName}`])
}

function __getUniqueName(projectId, field, fieldSelector) {
  const data = {
    'name'  : `Unique ${field} Names`
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'select': [
      fieldSelector
    ]
    , 'dedup': true
    , 'holder': [projectId]
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    let rawData = response.data[listName] || [];
    rawData = rawData.map(i => i[0]);
    rawData.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base'}));
    return rawData;
  });
}

function __getUniqueParentTaskName(projectId, field, fieldSelector) {
  const data = {
    'name'  : `Unique ${field} Names`
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'select': [
      fieldSelector
    ]
    , 'dedup': true
    , 'holder': [projectId]
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    let rawData = response.data[listName] || [];
    rawData = rawData.map(i => i[0].substr(i[0].indexOf('\n') + 1, i[0].lastIndexOf('\n') - (i[0].indexOf('\n') + 1)));
    rawData = [...new Set(rawData)];
    let emptyIdx = rawData.findIndex(r => r === "");
    if (emptyIdx !== -1) {
      rawData.splice(emptyIdx, 1);
    }
    rawData.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base'}));
    return rawData;
  });
}