
import { httpAjax } from '@/helpers';

export const folderService = {
  create,
  link,
  unlink,
  get,
  list,
  update,
  remove,
  getRootFiles
};

/**
 * Create a new folder 
 * by passing necessary information
 * @param FormData formData 
 * Note: Possible properties for formData: 
 *  1. name: String; Mandatory field.
 *  2. folder: Blob/File Object; Mandatory field.
 *  3. notes: String; Optional field.
 *  4. checksum: md5 hash. Optional field. If supply, backend validation will kick in.
 *  5. parent: uuId. Optinal field. serve as folder/directory for upload folder.
 */
function create(formData) {
  const url = '/api/folder/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, formData, config);
}

/**
 * link a file to a folder by passing the folder uuId
 * and the file uuId
 * {
 *     "uuId": "3b69de21-d276-4888-8572-d8990e71533b",
 *     "files": [ 
 *         {
 *             "uuId": "d73c4b87-9715-46b2-abd5-61c7af8ab6d8"
 *         }
 *     ]
   }
 * @param uuId uuId of folder
 */
function link(folder, uuId, type) {
  const url = `/api/folder/link/${type}/add`;
  const config = {
    headers: getHeaders()
  };
  const data = {
    uuId: folder
  };
    
  data[type + 's'] = [
    {
      uuId: uuId
    }
  ];
  return httpAjax.post(url, data, config);
}

/**
 * unlink a file to a folder by passing the folder uuId
 * and the file uuId
 * {
 *     "uuId": "3b69de21-d276-4888-8572-d8990e71533b",
 *     "files": [ 
 *         {
 *             "uuId": "d73c4b87-9715-46b2-abd5-61c7af8ab6d8"
 *         }
 *     ]
   }
 * @param uuId uuId of folder
 */
function unlink(folder, uuId, type) {
  const url = `/api/folder/link/${type}/delete`;
  const config = {
    headers: getHeaders()
  };
  const data = {
    uuId: folder
  };
    
  data[type + 's'] = [
    {
      uuId: uuId
    }
  ];
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a list of folder details by 
 * passing in an array list of uuIds as arguments
 * @param uuId uuId of folder
 */
function get(uuId) {
  if (uuId == null) {
    uuId = '00000000-0000-0000-0000-000000000000';
  }
  const url = '/api/folder/get?links=file,folder, tag';
  const config = {
    params: {
      uuId: uuId
    },
    
  };
  return httpAjax.get(url, config);
}

/**
 * Retrieving a list of folder info (folder content excluded)
 * @param parentId parent UuId
 */
function list(parentId) {
  const url = '/api/folder/list';
  const config = {
    params: {
      holder: parentId,
      level: true // only get folders under the current parent
    },
    headers: getHeaders()
  }
  return httpAjax.get(url, config);
}


/**
 * update an  existing folder 
 * by passing necessary information
 * @param FormData formData 
 * Note: Possible properties for formData: 
 *  1. name: String; Mandatory field.
 *  2. folder: Blob/File Object; Mandatory field.
 *  3. notes: String; Optional field.
 *  4. checksum: md5 hash. Optional field. If supply, backend validation will kick in.
 *  5. parent: uuId. Optinal field. serve as folder/directory for upload folder.
 *  6. uuId: target uuId; Mandatory field.
 */
function update(data) {
  const url = '/api/folder/update';
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a folder
 * by passing in uuId
 * @param String uuId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function remove(uuId) {
  const url = `/api/folder/delete/${uuId}`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.delete(url, config);
}

function getRootFiles() {
  const fields = {
    uuId: ["STORAGE_FILE.uuId"]
    , name: ["STORAGE_FILE.name"]
    , type: ["STORAGE_FILE.type"]
    , parent: [
                "STORAGE_FILE.STORAGE_FOLDER.name",
                "<AUTO>",
                "length"
              ]
  }

  let data = {
    'name': 'File Details List'
    , 'type': 'msql'
    , 'start': 0
    , 'limit': -1
    , 'nominate': 'STORAGE_FILE'
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    const mappedData = rawData.map(i => {
      const result = {}
      for (let j = 0, len = i.length; j < len; j++) {
        result[keys[j]] = i[j];
      }

      return result;
    });
    return mappedData.filter(f => f.parent.length === 0);
  });
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}