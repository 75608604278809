<template>
  <div :id="`pt-${id}`" class="container" :style="{ height: containerHeight }">
    <div class="color-top" :style="{'background-color': getColor(projectData.progress * 100)}">
    </div>
    
    <div v-if="display.avatar || display.banner" class="avatar-container" :style="{'background-image': `url(${display.banner ? bannerUrl : null})`}">
      <b-img-lazy id="avatar-avatar" class="image-avatar" style="object-fit:cover" :src="avatarUrl" v-if="display.avatar && avatarUrl != null" @error.native="handleAvatarError"/>
    </div>
    <div class="banner-placeholder" v-else>
    </div>
    
    <span :class="favourite ? 'favourite-button favourite-button-display' : 'favourite-button'">
      <div class="favourite-circle"></div>
      <b-popover
        :target="`FAVORITE_${id}`"
        placement="top"
        triggers="hover"
        :content="favourite ? $t('button.remove_favourite') : $t('button.add_favourite')">
      </b-popover>
      <b-btn :id="`FAVORITE_${id}`" @mousedown="toggleFavourite"><font-awesome-icon :icon="[favourite ? 'fas' : 'far','star']"/></b-btn>
    </span>
    
    <div class="pt-2" v-if="display.progress">
      <canvas :id="id" class="chart-size pt-3 pr-3 pl-3"></canvas>
      <b-popover
        :target="id"
        placement="top"
        triggers="hover"
        :content="$t('project.field.progress')">
      </b-popover>
    </div>
    <div class="placeholder" v-if="noDisplay">
    </div>

    <div style="margin-bottom: 18px; margin-top: 15px">
    <template v-if="dataLoaded">
      <template v-if="display.dates">
        <div class="d-flex flex-row line-row">
            <div class="project-tile-line line-icon tile-line-icon"><font-awesome-icon class="rowicon-color fa-lg" :icon="['far', 'calendar-days']"/></div>
            <div :id="`SCHEDULE_START_${id}`" class="project-tile-line line-startDate">
              <b-popover
                :target="`SCHEDULE_START_${id}`"
                placement="top"
                triggers="hover"
                :content="$t('project.field.scheduleStart')">
              </b-popover>
              <div>{{ startTime }}</div>
            </div>
            <div class="project-tile-line line-dash"><div>-</div></div>
            <div :id="`SCHEDULE_FINISH_${id}`" class="project-tile-line line-closeDate">
              <b-popover
                :target="`SCHEDULE_FINISH_${id}`"
                placement="top"
                triggers="hover"
                :content="$t('project.field.scheduleFinish')">
              </b-popover>
              <div>{{ closeTime }}</div>
            </div>
        </div>
      </template>
      
      <template v-if="display.cost || display.duration">
        <div class="d-flex flex-row text-center line-header">
          <div class="project-tile-line line-icon tile-line-icon"></div>
          <div class="project-tile-line line-budget"><div>{{ labelBudget }}</div></div>
          <div class="project-tile-line line-actual"><div>{{ labelActual }}</div></div>
          <div class="project-tile-line line-plusminus"><div>{{ labelPlusMinus }}</div></div>
        </div>

        <template v-for="(item, key) in items">
        <div class="d-flex flex-row text-center line-row" :key="key" v-if="(display.cost && key == 0) || (display.duration && key == 1)">
          <div class="project-tile-line line-icon tile-line-icon"><font-awesome-icon class="rowicon-color fa-lg" :icon="['far', item.rowicon]"/></div>
          <div :id="`BUDGET_${id}_${key}`" class="project-tile-line line-budget">
            <b-popover
                :target="`BUDGET_${id}_${key}`"
                placement="top"
                triggers="hover"
                :content="item.budget_tooltip">
              </b-popover>
            <div>{{ item.budget }}</div>
          </div>
          <div :id="`ACTUAL_${id}_${key}`" class="project-tile-line line-actual">
            <b-popover
              :target="`ACTUAL_${id}_${key}`"
              placement="top"
              triggers="hover"
              :content="item.actual_tooltip">
            </b-popover>
            <div>{{ item.actual }}</div>
          </div>
          <div :id="`DIFF_${id}_${key}`" class="project-tile-line line-plusminus" 
            :class="typeof item.diff_plus_minus === 'undefined' || item.diff_plus_minus >= 0 ? 'value-positive' : 'value-negative'">
            <b-popover
              :target="`DIFF_${id}_${key}`"
              placement="top"
              triggers="hover"
              :content="item.diff_tooltip">
            </b-popover>
            <div>{{ item.plus_minus }}</div>
          </div>
        </div>
        </template>
      </template>
      <template v-if="display.status">
        <div class="d-flex flex-row line-row">
          <div class="project-tile-line line-icon tile-line-icon"><font-awesome-icon class="rowicon-color fa-lg" :icon="['far', 'square-info']"/></div>
            <div :id="`STATUS_${id}`" class="project-tile-line line-status">
              <b-popover
                :target="`STATUS_${id}`"
                placement="top"
                triggers="hover"
                :content="$t('project.field.status')">
              </b-popover>
              <div class="line-status-value">{{ projectData.status }}</div>
            </div>
        </div>
      </template>
    </template>
    </div>
  </div>
</template>
<script>
import debounce from 'lodash/debounce';
import { randomString, msToDate, costFormat, costFormatAdv, getFirstColor, invertColor } from "@/helpers";
import Chart from "chart.js";
import cColors from "@/_chartColors";
import { EventBus } from '@/helpers';
const CenterLabel = {
  beforeDraw: function (chart) {
    if (chart.config.options.elements.center) {
      //Get ctx from string
      var ctx = chart.chart.ctx;
      
      //Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || "Arial";
      var txt = centerConfig.text;
      var color = centerConfig.color || "#000";
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding/100) * (chart.innerRadius * 2)
      //Start with a base font of 30px
      ctx.font = `30px ${fontStyle}`;
      
      //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth;
      // Fix cosmetic bug when character numbers lesser than 3
      if(txt.length < 3) {
        stringWidth = ctx.measureText("00%").width;
      } else {
        stringWidth = ctx.measureText(txt).width
      }
      var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(20 * widthRatio);
      var elementHeight = (chart.innerRadius * 2);
      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight);
      //Set font settings to draw it correctly.
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2 + (fontSizeToUse * 0.8));
      ctx.font = `${fontSizeToUse}px ${fontStyle}`;
      ctx.fillStyle = color;
      
      //Draw text in center
      ctx.fillText(txt, centerX, centerY);
    }
  }
};
const projectChartData = (progressColor) => { 
  return {
    type: "doughnut",
    data: {
      labels: [
        "Completed",
        "Planned",
      ],
      datasets: [{
        data: [50, 50],
        backgroundColor: [
          progressColor? progressColor : "#41B883",
        ],
        hoverBackgroundColor: [
          progressColor? progressColor : "#41B883",
        ]
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        center: {
          text: "75%",
          color: progressColor? progressColor : "#41B883",
          fontStyle: "Arial",
          sidePadding: 20 //As a percentage
        },
        arc: {
          borderColor: "#00000000"
        }
      },
      legend: {
        display: false,
      },
      cutoutPercentage: 70,
      circumference: Math.PI,
      rotation: Math.PI,
      tooltips: {
        enabled: false,
      },
      animation: {
        animateRotate: true
      }
    }
  }
};

const CONTAINER_HEIGHT = 467;

export default {
  name: "ProjectTile",
  props: {
    title:         { type: String, default: "" },
    progress:      { type: Number, default: 0 },
    queryId:       { type: String, required: false },
    maxHeightPx:   { type: Number, default: 150 },
    titleFontSize: { type: Number, default: 0 },
    project:       { type: Object, default: null},
    favourite:     { type: Boolean, default: false},
    display:       { type: Object, default: null},
    searchFilter:  { type: String, default: null},
    forceRender:   { type: Boolean, default: false },
    coloring:      { type: Object, default: () => { return {} } }
  },
  data: function() {
    return {
      baseUrl: process.env.BASE_URL,
      avatarError: false,
      id: `GAUGE_${randomString(5)}`,
      projectData: {},
      dataLoaded: false,
      chart: null,
      items: [
          { rowicon: 'badge-dollar', budget: '', actual: '', plus_minus: '' },
          { rowicon: 'clock', budget: '', actual: '', plus_minus: '' }
      ],
      progressColor: "#00d5ff"
    }
  },
  methods: {
    handleAvatarError() {
      this.avatarError = true;
    },
    toggleFavourite(e) {
      e.preventDefault();
      this.$emit('toggleFavourite', { uuId: this.project.uuId, favourite: !this.favourite });
    },
    getColor(progress) {
      const color = this.getColorBy(this.projectData);
      if (color) {
        return color;
      }
      
      var progressColor = this.progressColor;
      if (progress === 0) {
        progressColor = cColors.getThemeColor('progress-0');
      }
      else if (progress <= 25) {
        progressColor = cColors.getThemeColor('progress-25');
      }
      else if (progress <= 50) {
        progressColor = cColors.getThemeColor('progress-50');
      }
      else if (progress <= 75) {
        progressColor = cColors.getThemeColor('progress-75');
      }
      else if (progress <= 99) {
        progressColor = cColors.getThemeColor('progress-99');
      }
      else if (progress === 100) {
        progressColor = cColors.getThemeColor('progress-100');
      }
      return progressColor;
    },
    updateProgress(value) {
      const color = this.getColor(value);
      this.chart.data.datasets[0].data = [value, (100 - value)];
      this.chart.data.datasets[0].backgroundColor[0] = color; 
      this.chart.options.elements.center.color = color; 
      this.chart.data.datasets[0].hoverBackgroundColor[0] = color; 
      this.chart.options.elements.center.text = `${Math.trunc(value)}%`;
      this.chart.update();
    },
    createChart(chartId, chartData) {
      const ctx = document.getElementById(chartId).getContext("2d");
      this.chart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
        plugins: [CenterLabel]
      });
    },
    prepareChart() {
      const progress = this.progress;
      const remain = 100 - progress;
      const elem = document.getElementById(this.id);
      if (elem === null) {
        return;
      }
      const ctx = elem.getContext("2d");
      this.progressColor = this.getColor(progress);
      const data = projectChartData(this.progressColor);
      data.data.datasets[0].data = [progress, remain];
      data.options.elements.center.text = `${progress}%`;
      this.createChart(this.id, data);
        
      if (typeof this.projectData.progress !== 'undefined') {
        this.updateProgress(this.projectData.progress * 100);
      }
    },
    async prepareData() {
      this.dataLoaded = true;
      this.projectData = this.project;
      if (this.display.progress) {
        this.prepareChart();
      }

      if (this.$i18n === null) {
        return;
      }

      this.items[0].budget_tooltip = this.$t('project.field.total_estimated_cost');
      this.items[0].actual_tooltip = this.$t('project.field.total_actual_cost');
      this.items[0].diff_tooltip = this.$t('project.field.cost_difference');
      this.items[1].budget_tooltip = this.projectData.fixedDuration ? this.$t('project.field.fixedDuration') : this.$t('project.field.total_estimated_effort');
      this.items[1].actual_tooltip = this.$t('project.field.total_actual_effort');
      this.items[1].diff_tooltip = this.$t('project.field.effort_difference');
      const isCurrencyCodeAbsent = this.projectData.currencyCode == null || this.projectData.currencyCode.trim().length == 0;
      if (this.projectData.fixedCost != null && this.projectData.fixedCost > 0) {
        this.items[0].budget_tooltip = this.$t('project.field.fixedCost');
        this.items[0].budget = isCurrencyCodeAbsent? 
          `$${ costFormat(this.projectData.fixedCost)}` : costFormatAdv(this.projectData.fixedCost, this.projectData.currencyCode);
      }
      else if (typeof this.projectData.estimatedCost !== 'undefined') {
        this.items[0].budget = isCurrencyCodeAbsent? 
          `$${ costFormat(this.projectData.estimatedCost)}` : costFormatAdv(this.projectData.estimatedCost, this.projectData.currencyCode);
      }
      if (typeof this.projectData.actualCost !== 'undefined') {
        this.items[0].actual = isCurrencyCodeAbsent? 
          `$${ costFormat(this.projectData.actualCost)}` : costFormatAdv(this.projectData.actualCost, this.projectData.currencyCode);
      }
      if (this.projectData.fixedDuration) {
        this.items[1].budget = `${Math.trunc(this.projectData.fixedDuration)}D`;
      }
      else if (typeof this.projectData.estimatedDuration !== 'undefined') {
        this.items[1].budget = `${Math.trunc(this.projectData.estimatedDuration)}D`;
      }
      if (typeof this.projectData.totalActualDuration !== 'undefined') {
        this.items[1].actual = `${Math.trunc(this.projectData.totalActualDuration)}D`;
      }
      if (typeof this.projectData.estimatedCost !== 'undefined') {
        const budget = this.projectData.fixedCost != null && this.projectData.fixedCost > 0? this.projectData.fixedCost : this.projectData.estimatedCost;
        this.items[0].diff_plus_minus = budget - this.projectData.actualCost;
        const value = budget - this.projectData.actualCost;
        this.items[0].plus_minus = isCurrencyCodeAbsent? 
          (value < 0 ? `-$${costFormat(value).substr(1)}` : costFormatAdv(value, this.projectData.currencyCode))
          : costFormatAdv(value, this.projectData.currencyCode);
      }
      if (this.projectData.fixedDuration) {
        this.items[1].plus_minus = `${Math.trunc(this.projectData.fixedDuration - this.projectData.totalActualDuration)}D`;
        this.items[1].diff_plus_minus = this.projectData.fixedDuration - this.projectData.totalActualDuration;
      }
      else if (typeof this.projectData.estimatedDuration !== 'undefined') {
        this.items[1].plus_minus = `${Math.trunc(this.projectData.estimatedDuration - this.projectData.totalActualDuration)}D`;
        this.items[1].diff_plus_minus = this.projectData.estimatedDuration - this.projectData.totalActualDuration;
      }
    },
    isInViewport() {
      const rect = this.$el.getBoundingClientRect();
      if (rect.top === rect.bottom) {
        return false;
      }

      //The 370px is the height of a project tile. Setting the buffer (3 x 370) to let the rendering happens before it is scrolled into the view port.
      return (
          rect.top >= -370 &&
          rect.left >= 0 &&
          (rect.top <= (window.innerHeight || document.documentElement.clientHeight) + 370 ||
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    handleScroll(event) {
      if (this.isInViewport() && !this.dataLoaded) {
        this.prepareData();
      }
    },
    getColorBy(data) {
      if (data &&
        data.color &&
        this.coloring.project) {
        return data.color;
      }
      else if (data &&
        data.companyColor &&
        this.coloring.company) {
        const color = getFirstColor(data.companyColor);
        if (color) return color;
      }
      else if (data &&
        data.locationColor &&
        this.coloring.location) {
        const color = getFirstColor(data.locationColor);
        if (color) return color;
      }
      else if (data &&
        data.customerColor &&
        this.coloring.customer) {
        const color = getFirstColor(data.customerColor);
        if (color) return color;
      }
      else if (data &&
        data.statusColor &&
        this.coloring.status) {
        const color = getFirstColor(data.statusColor);
        if (color) return color;
      }
      else if (data &&
        data.rebateColor &&
        this.coloring.rebate) {
        const color = getFirstColor(data.rebateColor);
        if (color) return color;
      }
      return null;
    }

  },
  computed: {
    startTime() {
      if (this.projectData.scheduleStart === 0 ||
          this.projectData.scheduleStart == null) {
        return ' ';
      }
      const sTime = this.projectData.scheduleStart;
      return msToDate(sTime);
    },
    closeTime() {
      if (this.projectData.scheduleFinish === 0 ||
          this.projectData.scheduleFinish == null) {
        return ' ';
      }
      const cTime = this.projectData.scheduleFinish;
      return msToDate(cTime);
    },
    labelBudget() {
      return this.$t('project.field.budget');
    },
    labelActual() {
      return this.$t('project.field.actual');
    },
    labelPlusMinus() {
      return this.$t('project.field.plus_minus');
    },
    noDisplay() {
      return !this.display.progress &&
             !this.display.avatar &&
             !this.display.banner;
    },
    containerHeight() {
      let finalHeight = CONTAINER_HEIGHT;
      if (!this.display.avatar && !this.display.banner) {
        finalHeight -= 70;
      }
      
      if (!this.display.progress) {
        finalHeight -= 120;
      }

      if (!this.display.dates) {
        finalHeight -= 42;
      }

      if (!this.display.cost && !this.display.duration) {
        finalHeight -= 120;
      } else if (!this.display.cost) {
        finalHeight -= 42;
      } else if (!this.display.duration) {
        finalHeight -= 42;
      }

      if (!this.display.status) {
        finalHeight -= 42;
      }

      return `${finalHeight}px`;
    },
    avatarUrl() {
      return this.projectData.avatarRef && this.projectData.avatarRef !== '00000000-0000-0000-0000-000000000000' && !this.avatarError ? `${this.baseUrl}api/file/${this.projectData.avatarRef}` : null;
    },
    bannerUrl() {
      if (!this.display.banner ||
          !this.projectData.bannerRef ||
          this.projectData.bannerRef === '00000000-0000-0000-0000-000000000000') {
        return null;
      }
      return `${this.baseUrl}api/file/${this.projectData.bannerRef}`;
    }
  },
  watch: {
    display: {
      handler: function (val, oldVal) {
        if (val.progress && 'progress' in this.projectData) {
          this.$nextTick(() => {
            this.prepareChart();
          });
        }
      },
      deep: true
    },
    project: {
      handler: function(val) {
        if (val !== null) {
          this.prepareData();
        }
      }
    },
    searchFilter: {
      handler: function(val) {
        if (val !== '' && this.isInViewport() && !this.dataLoaded) {
          this.prepareData();
        }
      }
    },
    forceRender(val) {
      if (val && !this.dataLoaded) {
        this.prepareData();
      }
      this.$nextTick(() => {
        this.$emit('update:forceRender', false);
      })
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
    window.removeEventListener('scroll', this.handleDebouncedScroll);
    EventBus.$off('theme-change');
  },
  mounted() {
    //if (this.isInViewport()) {
      this.prepareData();
    //}
  },
  created() {
    EventBus.$on('theme-change', () => {
      this.prepareChart();
    });

    this.handleDebouncedScroll = debounce(this.handleScroll, 100);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  }
}
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  background-color: var(--surface-bg);
  padding-bottom: 15px;
  height: 333px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.chart-size {
  width: 227px;
  height: 100px;
  min-height: 100px;
}

.container:hover .favourite-button {
  display: block;
}

.favourite-button {
  position: absolute;
  top: 2px;
  right: 0;
  display: none;
}


@media only screen and (max-width: 768px) {
  .favourite-button {
    display: block;
  }
}

.favourite-button-display {
  display: block;
}

.favourite-button .btn {
  color: var(--project-fav-icon);
  background-color: transparent;
  border: none;
  font-size: 18px;
  position: absolute;
  top: 13px;
  right: 0;
}

.favourite-circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 7px;
  top: 17px;
  opacity: 0.6;
  background: white;
}

.banner-placeholder {
  min-height: 30px;
}

.placeholder {
  min-height: 15px;
  margin-bottom: 15px;
}

.project-progress {
  height: 50px;
}

.project-tile-line {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  border: 3px solid var(--surface-bg);
  color: var(--text-dark);

  &.div {
    flex: 0 1 auto;
  }

  &.tile-line-icon {
    justify-content: left;
  }

  &.line-icon {
    width: 30px;
    color: var(--text-light);
  }

  &.line-startDate {
    width: 108px;
    background-color: var(--project-tile-cell);
    border-left: none;
  }

  &.line-dash {
    width: 8px;
  }

  &.line-closeDate {
    width: 108px;
    background-color: var(--project-tile-cell);
    border-right: none;
  }

  &.line-budget {
    background-color: var(--project-tile-cell);
    width: 76px;
  }

  &.line-actual {
    background-color: var(--project-tile-cell);
    width: 78px;
  }

  &.line-plusminus {
    background-color: var(--project-tile-cell);
    width: 76px;
    border-right: none;

    &.value-positive {
      background-color: var(--project-tile-cell) !important;
    }

    &.value-negative {
      background-color: var(--project-cost-alert) !important;
    }
  }
  
  &.line-status {
    width: 230px;
    background-color: var(--project-tile-cell);
    border-left: none;
    border-right: none;
    margin-left: 3px;
  }
}

.line-row {
  height: 42px; 
  color: var(--text-light);
  justify-content: space-around;
}


.line-header .project-tile-line {
  margin-top: 6px;
  height: 30px;
  &.line-budget, &.line-actual, &.line-plusminus {
    background-color: transparent;
  }
}


.avatar-container {
  display: inline-block;
  height: 100px;
  width: calc(100% + 30px);
  margin-left: -15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--surface-bg);
  top: 12px;
  position: relative;
}

.color-top {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-size: 300px, 12px;
  height: 12px;
  width: 100%;
  position: absolute;
  left: 0;
}

.image-avatar {
  width:48px;
  height:48px;
  border: 2px solid var(--banner-avatar-border);
  border-radius: 50%;
  display: block;
  transition: .5s ease;
  backface-visibility: hidden;
  opacity: 1;
  margin-top: 26px;
  margin-left: 15px;
}

.icon-avatar {
  margin-top: 15px;
  margin-left: 15px;
  font-size: 48px;
}

.progress-bar-container {
  position: absolute;
  margin-top: 20px;
  width: calc(100% - 30px);
  margin-top: 15px;
  margin-bottom: 15px;
  background: var(--project-tile-progress-back);
  height: 28px;
  border-radius: 2px;
}

.progress-bar-content {
  position: absolute;
  height: 28px;
  border-radius: 2px;
}

.progress-bar-text {
  width: 100%;
  color: #f7f7f7;
  position: absolute;
  text-align: center;
  font-weight: bold;
  font-size: 1.1em;
  line-height: 28px;
}

.line-status-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}
</style>