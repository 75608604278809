<template>
  <div class="edit-cell">
    <TagListModal :show="tagShow"
      :tags="value"
      :enableEntityOptions="enableEntityOptions"
      @cancel="closePicker"
      @changed="changed"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { objectClone } from '@/helpers';

export default Vue.extend({
  name: 'TagEditor',
  components: {
    TagListModal: () => import('@/components/modal/TagListModal.vue')
  },
  data() {
    return {
      value: '',
      cancelBeforeStart: false,
      compactMode: false,
      tagShow: true,
      enableEntityOptions: false
    };
  },
  methods: {
    isPopup() {
      return true;
    },
    getValue() {
       if (this.compactMode) {
        const orgValue = this.params.value.single;
        const curValue = this.value;

        if (orgValue == null && (curValue == null || curValue.length == 0 )) {
          return this.params.value;
        } else if ((orgValue == null && curValue != null && curValue.length > 0) || 
                    (orgValue != null && (orgValue.length != curValue.length))) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        
        let hasChanged = false;
        for (const t of orgValue) {
          if (!curValue.includes(t)) {
            hasChanged = true;
            break;
          }
        }
        
        if (hasChanged) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        return this.params.value;
      } else {
        return this.value;
      }
    },

    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },

    setInitialState(params) {
      this.value = this.compactMode? (params.value.single != null? objectClone(params.value.single) : []) : params.value;
    },
    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    changed(val) {
      this.value = val;
      this.closePicker();
    }
  },

  created() {
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    if (this.params.enableEntityOptions == true) {
      this.enableEntityOptions = true;
    }
    this.setInitialState(this.params);
  }
})
</script>

<style lang="scss">

</style>
