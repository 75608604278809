var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-relative" },
    [
      _c("div", { staticClass: "scheduler-actions pl-1" }, [
        _vm.showAdd
          ? _c(
              "span",
              [
                _c("b-popover", {
                  attrs: {
                    target: "BTN_ADD_SCHEDULER",
                    placement: "top",
                    triggers: "hover",
                    content: _vm.$t("button.add"),
                  },
                }),
                _c(
                  "b-btn",
                  {
                    staticClass: "add-event",
                    attrs: { id: "BTN_ADD_SCHEDULER" },
                    on: { click: _vm.eventAdd },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "plus"] },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.canEdit()
          ? _c(
              "span",
              [
                _c("b-popover", {
                  attrs: {
                    target: "BTN_EDIT_SCHEDULER",
                    placement: "top",
                    triggers: "hover",
                    content: _vm.$t("button.edit"),
                  },
                }),
                _c(
                  "b-btn",
                  {
                    staticClass: "edit-event",
                    attrs: {
                      disabled: _vm.disableEdit,
                      id: "BTN_EDIT_SCHEDULER",
                    },
                    on: { click: _vm.eventEdit },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "pen-to-square"] },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.canDelete("ACTIVITY") ||
        _vm.canDelete("BOOKING") ||
        _vm.canDelete("TASK") ||
        _vm.canDelete("CALENDAR")
          ? _c(
              "span",
              [
                _c("b-popover", {
                  attrs: {
                    target: "BTN_DELETE_SCHEDULER",
                    placement: "top",
                    triggers: "hover",
                    content: _vm.$t("button.delete"),
                  },
                }),
                _c(
                  "b-btn",
                  {
                    staticClass: "delete-event",
                    attrs: {
                      disabled: _vm.disableDelete,
                      id: "BTN_DELETE_SCHEDULER",
                    },
                    on: { click: _vm.eventDelete },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "trash-can"] },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "span",
          [
            _c("b-popover", {
              attrs: {
                target: "BTN_COPY_SCHEDULER",
                placement: "top",
                triggers: "hover",
                content: _vm.$t("button.copy"),
              },
            }),
            _c(
              "b-btn",
              {
                staticClass: "copy-event",
                attrs: { disabled: _vm.disableCopy, id: "BTN_COPY_SCHEDULER" },
                on: { click: _vm.eventCopy },
              },
              [_c("font-awesome-icon", { attrs: { icon: ["far", "copy"] } })],
              1
            ),
          ],
          1
        ),
        _c(
          "span",
          [
            _c("b-popover", {
              attrs: {
                target: "BTN_PASTE_SCHEDULER",
                placement: "top",
                triggers: "hover",
                content: _vm.$t("button.paste"),
              },
            }),
            _c(
              "b-btn",
              {
                staticClass: "paste-event",
                attrs: {
                  disabled: _vm.disablePaste,
                  id: "BTN_PASTE_SCHEDULER",
                },
                on: { click: _vm.eventPaste },
              },
              [_c("font-awesome-icon", { attrs: { icon: ["far", "paste"] } })],
              1
            ),
          ],
          1
        ),
        _c(
          "span",
          [
            _c(
              "span",
              { attrs: { id: "BTN_IMPORT_SCHEDULER" } },
              [
                _c(
                  "b-btn",
                  {
                    staticClass: "mr-1 export-event",
                    attrs: {
                      disabled:
                        !_vm.filter.usage &&
                        !_vm.filter.activity &&
                        !_vm.filter.booking &&
                        !_vm.filter.task &&
                        !_vm.filter.vacation,
                    },
                    on: { click: _vm.fileImport },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "inbox-in"] },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "BTN_IMPORT_SCHEDULER",
                  triggers: "hover",
                  placement: "top",
                  boundary: "viewport",
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("task.button.import_document")) + " "
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "span",
          [
            _c(
              "span",
              { attrs: { id: "BTN_EXPORT_SCHEDULER" } },
              [
                _c(
                  "b-btn",
                  {
                    staticClass: "mr-1 export-event",
                    attrs: {
                      disabled:
                        !_vm.filter.usage &&
                        !_vm.filter.activity &&
                        !_vm.filter.booking &&
                        !_vm.filter.task &&
                        !_vm.filter.vacation,
                    },
                    on: { click: _vm.fileExport },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "inbox-out"] },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "BTN_EXPORT_SCHEDULER",
                  triggers: "hover",
                  placement: "top",
                  boundary: "viewport",
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("task.button.export_document")) + " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteEventOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteEventShow,
            callback: function ($$v) {
              _vm.confirmDeleteEventShow = $$v
            },
            expression: "confirmDeleteEventShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    _vm.selected.length === 1
                      ? "activity.confirmation.delete"
                      : "activity.confirmation.delete_plural"
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }