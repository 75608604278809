import { profileService } from '@/services';

const categoryKey = 'language';
const folderKey = 'user';
const publicFolderKey = 'company';

export const languageProfileService = {
  create,
  createPublic,
  list,
  listPublic,
  update,
  updatePublic,
  remove
};

/**
 * Create a new dataviewProfile 
 * by passing necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function create(data, userId) {
  return profileService.create(data, categoryKey, folderKey, userId);
}

/**
 * Create a new dataviewProfile 
 * by passing necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function createPublic(data, companyId) {
  return profileService.create(data, categoryKey, publicFolderKey, companyId);
}

/**
 * Retrieving a concise list of dataviewProfile info
 */
function list(userId) {
  return profileService.list(categoryKey, folderKey, userId);
}

/**
 * Retrieving a concise list of dataviewProfile info
 */
function listPublic(companyId) {
  return profileService.list(categoryKey, publicFolderKey, companyId);
}

/**
 * Update dataviewProfile details 
 * by passing  necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function update(data, userId) {
  return profileService.update(data, categoryKey, folderKey, userId);
}

/**
 * Update dataviewProfile details 
 * by passing  necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function updatePublic(data, companyId) {
  return profileService.update(data, categoryKey, publicFolderKey, companyId);
}

/**
 * Delete a list of data view Profiles
 * by passing in an array list of uuIds
 * @param {Array} data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data, userId) {
  return profileService.remove(data, categoryKey, folderKey, userId);
}
