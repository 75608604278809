var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.avatarUrl
    ? _c(
        "div",
        {
          staticClass: "image-container",
          class: _vm.isPreview ? "img-preview-container" : "",
        },
        [
          _c("img", {
            staticClass: "image",
            class: _vm.isPreview ? "img-contain" : "",
            attrs: {
              width: "120",
              height: _vm.isPreview ? 25 : 120,
              src: _vm.avatarUrl,
            },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }