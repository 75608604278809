export function strCapitalize(value) {
  if(value && value.length > 1) {
    const tokens = value.trim().split(' ');
    const updated = tokens.reduce((result, i) => {
      let v = i && i.length > 1? i.charAt(0).toUpperCase() + i.slice(1): i.toUpperCase();
      return result && result.length > 0?`${result} ${v}`: v;
    }, '');
    return updated;
  }
  return value;
}