
import { httpAjax, urlParams } from '@/helpers';
import { toFixed } from '@/helpers/task-duration-process';
import { getPermissionDenyProperties } from '@/helpers/permission'
import { getKeysWithoutRedactedFields } from './common'
export const rebateService = {
  create,
  list,
  listv2,
  update,
  remove,
  clone,
  query,
  listNames,
  listUniqueValuesOfProperty
};

/**
 * Create a new rebate 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', fullName: 'John Doe' ... }]
 */
function create(data) {
  const url = '/api/rebate/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of rebate info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='fullName', order: 'incr' }}
 */
function list(params) {
  return listv2(params, { formatter: { rebate: (v) => toFixed(v * 100, 2) }})
}

function listv2(params, { formatter={}, customFields=[] } = {}) {
  const fields = {
    uuId: 'REBATE.uuId', 
    name: 'REBATE.name', 
    rebate: 'REBATE.rebate',
    identifier: 'REBATE.identifier',
    color: 'REBATE.color',
    tag: 'REBATE.TAG.name',
    readOnly: 'REBATE.readonly'
  }
  if (Array.isArray(customFields) && customFields.length > 0) {
    for(const cField of customFields) {
      fields[cField.name] = `REBATE.${cField.name}`;
    }
  }
  

  let data = {
    'name'  : 'Rebate List'
    ,'type' : 'msql'
    ,'nominate': 'REBATE'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if (params.holder) {
    data['holder'] = params.holder;
  }
  
  if(params.filter && params.filter.length > 0) {
    const matchColumns = [
      ['REBATE.name', 'has', params.filter]
    ]

    const rebateDeniedProperties = getPermissionDenyProperties('REBATE','VIEW')
    if (!rebateDeniedProperties.includes('rebate')) {
      matchColumns.push(['REBATE.rebate', 'has', params.filter])
    }
    if (!rebateDeniedProperties.includes('identifier')) {
      matchColumns.push(['REBATE.identifier', 'has', params.filter])
    }
    if (!rebateDeniedProperties.includes('color')) {
      matchColumns.push(['REBATE.color', 'has', params.filter])
    }
    if (Array.isArray(customFields) && customFields.length > 0) {
      for (const c of customFields) {
        if ((c.type == 'String' || c.type == 'Enum<String>') && !rebateDeniedProperties.includes(c.name)) {
          matchColumns.push([`REBATE.${c.name}`, 'has', params.filter])
        }
      }
    }

    const tagDeniedProperties = getPermissionDenyProperties('TAG', 'VIEW')
    if (!rebateDeniedProperties.includes('TAG') && 
        !rebateDeniedProperties.includes('TAG.name') && 
        !tagDeniedProperties.includes('name') ) {
      matchColumns.push(['REBATE.TAG.name', 'has', params.filter])
    }

    data['filter'] = [
      '_or_', matchColumns
    ]
  }

  //BadgeFilter related
  if (Array.isArray(params.badgeFilters) && params.badgeFilters.length > 0) {
    const badgeFilterList = [];
    for (const f of params.badgeFilters) {
      if (f.field == null || !Array.isArray(f.value) || f.value.length == 0) {
        continue;
      }
      
      let field = null;
      if (f.field == 'tagName') {
        field = 'REBATE.TAG.name';
      } else {
        field = `REBATE.${f.field}`;
      } 

      const isEqual = !f.operator || f.operator === 'is';
      let valueList = [];
      const value = f.value;
      for (const v of value) {
        if (v.text != null && (typeof v.text === 'number' || (typeof v.text === 'string' && v.text&& v.text.length > 0 && v.text !== '(Empty)'))) {
          //use value when exists. Otherwise use text
          //Reason: When value property exists, it means the text is localized and can't be used in filter.
          valueList.push([field, 'eq', Object.hasOwn(v, 'value')? v.value : v.text]);
        }
        //Start processing (EMPTY) field value 
        else if (isEqual) {
          valueList.push('_not_', [[field]])
        } else {
          //Special handling: when is not empty, put it into badgeFilterList directly to act as 'field must exist'. 
          badgeFilterList.push([field])
        }
      }
      if (valueList.length > 0) {
        if (valueList.length > 1) {
          valueList = ['_or_', valueList];
        }
        if (!isEqual) {
          badgeFilterList.push('_not_', valueList);
        } else {
          badgeFilterList.push(...valueList);
        }
      }
    }
    if (badgeFilterList.length > 0) {
      if (Array.isArray(data.filter) && data.filter.length > 0) {
        data.filter = [...data.filter, ...badgeFilterList]
      } else {
        data.filter = [...badgeFilterList]
      }
    }
  }

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i], '', 'lowerCase']);
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order, '', 'lowerCase']);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.badgeFilters;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          if (formatter[keys[j]] != null) {
            result[keys[j]] = formatter[keys[j]](i[j]);
          }
          // if (keys[j] === 'rebate') {
          //   result[keys[j]] = toFixed(i[j] * 100, 2); // the server stores the rebate as 0 to 1
          // }
          else {
            result[keys[j]] = i[j];
          }
        }

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}


/**
 * Update rebate details 
 * by passing  necessary information
 * @param Array data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', fullName: 'John Doe' ... }]
 */
function update(data) {
  const url = '/api/rebate/update';
  const config = {
    headers: getHeaders()
  }
  data['holder'] = data.uuId;
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of rebates
 * by passing in an array list of uuIds
 * @param Array data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = '/api/rebate/delete';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}

/**
 * Clone a skill with given new name
 * @param String refId Target skill UuId
 * @param Object data Object contains name for cloned skill.
 */
function clone(refId, data) {
  const url = '/api/rebate/clone';
  const config = {
    data: data,
    headers: getHeaders(),
    params: { reference: refId }
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a list of resource details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function query(data, links=null) {
  const url = urlParams('/api/rebate/get', links);
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
  * Retrieving a concise list of REBATE info by 
  * passing in pagination, sorting and filtering parameters
  * @param Object params 
  * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
  */
function listNames(params) {
  const fields = {
    uuId: 'REBATE.uuId',
    name: 'REBATE.name'
  }
  

  let data = {
    'name'  : 'REBATE Name List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'REBATE'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

function listUniqueValuesOfProperty(field) {
  const fieldSelector = [`REBATE.${field}`]
  
  const data = {
    'name'  : `Unique REBATE property: ${field}`
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'select': [
      fieldSelector
    ]
    , 'dedup': true
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    let rawData = response.data[listName] || [];
    rawData = rawData.map(i => i[0]);
    rawData.sort((a, b) => {
      if (typeof a === 'string') {
        return a.localeCompare(b, undefined, { sensitivity: 'base'})
      } else {
        if (a < b) {
          return -1
        } else if (a > b) {
          return 1
        }
        return 0
      }
    });
    return rawData;
  });
}