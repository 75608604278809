<template>
  <div class="edit-cell">
    <b-modal
        v-model="showPopup" :title="params.title"
        @ok="onOk"
        @hidden="hidden"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-form-group>
        <b-form-textarea ref="textarea"
          :placeholder="params.placeholder"
          v-model="editValue"
          :max-rows="6"
          autofocus
          :rows="6"/>
      </b-form-group>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { objectClone } from '@/helpers';

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEYCODE_ENTER = 13;
const KEYCODE_TAB = 9;
const KEYCODE_BACKSPACE = 8;
const KEYCODE_DELETE = 46;

export default Vue.extend({
  name: 'MultilineEditor',
  components: {
    
  },
  data() {
    return {
      value: '',
      editValue: '',
      showPopup: true,
      cancelBeforeStart: false,
      highlightAllOnFocus: false,
      commentShow: true,
      commentUuid: null,
      isQuitProperly: false
    };
  },
  methods: {
    getValue() {
      if (this.compactMode) {
        const orgValue = this.params.value.single;
        const curValue = this.value;
        if (orgValue == curValue) {
          return this.params.value;
        }
        const updatedValue = objectClone(this.params.value);
        updatedValue.single = this.value;
        return updatedValue;
      } else {
        return this.value;
      }
    },
    
    isPopup() {
      return true;
    },

    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },

    setInitialState(params) {
      let startValue;
      let highlightAllOnFocus = false;

      if (params.key === KEY_BACKSPACE || params.key === KEY_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (params.charPress) {
        // if a letter was pressed, we start with the letter
        startValue = params.charPress;
        highlightAllOnFocus = false;
      }
      else {
        // otherwise we start with the current value
        startValue = this.compactMode? params.value.single: params.value;
        
        if (params.key === KEY_F2) {
          highlightAllOnFocus = true;
        }
      }
      this.value = startValue;
      this.editValue = startValue;
      this.highlightAllOnFocus = highlightAllOnFocus;
    },

    onKeyDown(event) {
      if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
        event.stopPropagation();
        return;
      }

      if (
        !this.finishedEditingPressed(event) &&
        !this.isKeyPressedChar(event)
      ) {
        if (event.preventDefault) event.preventDefault();
      }
    },

    getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    },

    isChar(charStr) {
      return /\d|[a-zA-Z]/.test(charStr);
    },

    isKeyPressedChar(event) {
      const charStr = event.key;
      return this.isChar(charStr);
    },
    
    finishedEditingPressed(event) {
      const charCode = this.getCharCodeFromEvent(event);
      return charCode === KEYCODE_ENTER || charCode === KEYCODE_TAB;
    },

    deleteOrBackspace(event) {
      return (
        [KEYCODE_DELETE, KEYCODE_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) >
        -1
      );
    },

    isLeftOrRight(event) {
      return [37, 39].indexOf(this.getCharCodeFromEvent(event)) > -1;
    },
    
    onOk() {
      this.isQuitProperly = true;
      this.value = this.editValue;
    },
    
    hidden() {
      if (!this.isQuitProperly) {
        this.value = this.compactMode? this.params.value.single : this.params.value;
      }
      this.closePicker();
    },
    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
  },

  created() {
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    this.setInitialState(this.params);
  },
  mounted() {
    setTimeout(() => {
      // need to check if the input reference is still valid - if the edit was cancelled before it started there
      // wont be an editor component anymore
      if (this.$refs.textarea) {
        this.$refs.textarea.focus();
        if (this.highlightAllOnFocus) {
          this.$refs.textarea.select();

          this.highlightAllOnFocus = false;
        } else {
          // when we started editing, we want the caret at the end, not the start.
          // this comes into play in two scenarios: a) when user hits F2 and b)
          // when user hits a printable character, then on IE (and only IE) the caret
          // was placed after the first character, thus 'apply' would end up as 'pplea'
          const length = this.$refs.textarea.value
            ? this.$refs.textarea.value.length
            : 0;
          if (length > 0) {
            this.$refs.textarea.setSelectionRange(length, length);
          }
        }

        this.$refs.textarea.focus();
      }
    }, 100);
  }
})
</script>