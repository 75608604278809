var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.alertMsg != null && _vm.alertState != "success"
        ? _c("AlertFeedback", {
            attrs: {
              msg: _vm.alertMsg,
              details: _vm.alertMsgDetails.list,
              detailTitle: _vm.alertMsgDetails.title,
              alertState: _vm.alertState,
            },
            on: {
              resetAlert: _vm.resetAlert,
              offsetHeight: _vm.updateGridHeight,
            },
          })
        : _vm._e(),
      _vm.allowManage
        ? _c(
            "PriorityNavigation",
            {
              staticClass: "grid-toolbar border",
              attrs: {
                closeDropdown: _vm.closePriorityNavDropdown,
                forceRefresh: _vm.forcePriorityNavRefresh,
              },
              on: {
                "update:forceRefresh": function ($event) {
                  _vm.forcePriorityNavRefresh = $event
                },
                "update:force-refresh": function ($event) {
                  _vm.forcePriorityNavRefresh = $event
                },
              },
            },
            [
              _vm.canAdd()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: `BTN_ADD_${_vm.id}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: { disabled: _vm.actionProcessing },
                              on: {
                                click: function ($event) {
                                  return _vm.taskEditOpen(true)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                style: { color: "var(--grid-toolbar-button)" },
                                attrs: { icon: ["far", "plus"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: `BTN_ADD_${_vm.id}`,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.add")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canView()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: `BTN_EDIT_${_vm.id}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.disableEdit || _vm.actionProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.taskEditOpen(false)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "pen-to-square"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: `BTN_EDIT_${_vm.id}`,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.canEdit()
                                  ? _vm.$t("button.edit_task")
                                  : _vm.$t("button.view_task")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canDelete()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: `BTN_DELETE_${_vm.id}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.disableDelete || _vm.actionProcessing,
                              },
                              on: { click: _vm.rowDelete },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "trash-can"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: `BTN_DELETE_${_vm.id}`,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.delete")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.canAdd() || _vm.canView() || _vm.canDelete()) &&
              _vm.canEdit()
                ? _c("li", { staticClass: "divider" }, [
                    _c(
                      "span",
                      {
                        staticClass: "action-v-divider",
                        attrs: { readonly: "" },
                      },
                      [_vm._v("|")]
                    ),
                  ])
                : _vm._e(),
              _vm.canEdit()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: `BTN_OUTDENT_${_vm.id}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.disableOutdent || _vm.actionProcessing,
                                id: `BTN_OUTDENT_${_vm.id}`,
                              },
                              on: { click: _vm.taskOutdent },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "outdent"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: `BTN_OUTDENT_${_vm.id}`,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.outdent")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canEdit()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: `BTN_INDENT_${_vm.id}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.disableIndent || _vm.actionProcessing,
                              },
                              on: { click: _vm.taskIndent },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "indent"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: `BTN_INDENT_${_vm.id}`,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.indent")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.canView() || _vm.canDelete() || _vm.canEdit()) &&
              _vm.canAdd()
                ? _c("li", { staticClass: "divider" }, [
                    _c(
                      "span",
                      {
                        staticClass: "action-v-divider",
                        attrs: { readonly: "" },
                      },
                      [_vm._v("|")]
                    ),
                  ])
                : _vm._e(),
              _vm.canAdd()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: `BTN_COPY_${_vm.id}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.disableTaskCopy || _vm.actionProcessing,
                              },
                              on: { click: _vm.taskCopy },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "copy"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: `BTN_COPY_${_vm.id}`,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.copy")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canAdd()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: `BTN_PASTE_${_vm.id}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.disableTaskPaste || _vm.actionProcessing,
                              },
                              on: { click: _vm.taskPaste },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "paste"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: `BTN_PASTE_${_vm.id}`,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.paste")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("li", { staticClass: "divider" }, [
                _c(
                  "span",
                  { staticClass: "action-v-divider", attrs: { readonly: "" } },
                  [_vm._v("|")]
                ),
              ]),
              _c(
                "li",
                [
                  _c(
                    "span",
                    { attrs: { id: `BTN_EXPORT_DOCUMENT_${_vm.id}` } },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: { disabled: _vm.actionProcessing },
                          on: { click: _vm.fileExport },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "inbox-out"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: `BTN_EXPORT_DOCUMENT_${_vm.id}`,
                        triggers: "hover",
                        placement: "top",
                        boundary: "viewport",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("task.button.export_document")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.canEdit("TEMPLATE__PROJECT") || _vm.canEdit("TEMPLATE__TASK")
                ? _c("li", { staticClass: "divider" }, [
                    _c(
                      "span",
                      {
                        staticClass: "action-v-divider",
                        attrs: { readonly: "" },
                      },
                      [_vm._v("|")]
                    ),
                  ])
                : _vm._e(),
              _vm.canEdit("TEMPLATE__PROJECT")
                ? _c(
                    "li",
                    [
                      _vm.disableManualScheduleProject
                        ? [
                            _c(
                              "span",
                              {
                                attrs: {
                                  id: `BTN_MANUAL_SCHEDULE_PROJECT_${_vm.id}`,
                                },
                              },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { disabled: _vm.actionProcessing },
                                    on: {
                                      click: _vm.autoScheduleProjectHandler,
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "thumbtack"] },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: `BTN_MANUAL_SCHEDULE_PROJECT_${_vm.id}`,
                                  triggers: "hover",
                                  placement: "top",
                                  boundary: "viewport",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("task.button.manual_scheduled")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm.disableAutoScheduleProject
                        ? [
                            _c(
                              "span",
                              {
                                attrs: {
                                  id: `BTN_AUTO_SCHEDULE_PROJECT_${_vm.id}`,
                                },
                              },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { disabled: _vm.actionProcessing },
                                    on: {
                                      click: _vm.manualScheduleProjectHandler,
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["far", "calendar-check"],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: `BTN_AUTO_SCHEDULE_PROJECT_${_vm.id}`,
                                  triggers: "hover",
                                  placement: "top",
                                  boundary: "viewport",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("task.button.auto_scheduled")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.canEdit("TEMPLATE__TASK")
                ? _c(
                    "li",
                    {
                      staticClass: "scheduler",
                      on: _vm._d({ mouseleave: _vm.onSchedulerLeave }, [
                        _vm.schedulerMouseEnterEvent,
                        _vm.onSchedulerOver,
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "scheduler",
                          staticClass: "action-bar-dropdown",
                          attrs: {
                            id: `BTN_SCHEDULE_${_vm.id}`,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "calendar-days"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4096594029
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                disabled:
                                  _vm.disableManualScheduleTask ||
                                  _vm.actionProcessing,
                                href: "#",
                              },
                              on: { click: _vm.manualScheduleTaskHandler },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "item-icon",
                                attrs: { icon: ["far", "thumbtack"] },
                              }),
                              _c("span", { staticClass: "item-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("task.button.manual_schedule_task")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                disabled:
                                  _vm.disableAutoScheduleTask ||
                                  _vm.actionProcessing,
                                href: "#",
                              },
                              on: { click: _vm.autoScheduleTaskHandler },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "item-icon",
                                attrs: { icon: ["far", "calendar-check"] },
                              }),
                              _c("span", { staticClass: "item-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("task.button.auto_schedule_task")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "li",
                [
                  _c(
                    "span",
                    { attrs: { id: `BTN_COLLAPSE_${_vm.id}` } },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.inProgressShow || _vm.expandLevel === 0,
                          },
                          on: { click: _vm.collapse },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "magnifying-glass-minus"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: `BTN_COLLAPSE_${_vm.id}`,
                        triggers: "hover",
                        placement: "top",
                        boundary: "viewport",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("task.button.minus")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "span",
                    { attrs: { id: `BTN_EXPAND_${_vm.id}` } },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.inProgressShow ||
                              _vm.expandLevel === _vm.maxLevel,
                          },
                          on: { click: _vm.expand },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "magnifying-glass-plus"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: `BTN_EXPAND_${_vm.id}`,
                        triggers: "hover",
                        placement: "top",
                        boundary: "viewport",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("task.button.plus")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                {
                  on: _vm._d({ mouseleave: _vm.onFilterLeave }, [
                    _vm.filterMouseEnterEvent,
                    _vm.onFilterOver,
                  ]),
                },
                [
                  !_vm.filterPinned
                    ? _c(
                        "b-dropdown",
                        {
                          ref: "filter",
                          staticClass: "action-bar-dropdown text-filter",
                          attrs: {
                            id: `BTN_FILTER_${_vm.id}`,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          on: { hide: _vm.onFilterTextDropdownHide },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      class:
                                        _vm.filterText !== "" ? "active" : "",
                                      attrs: {
                                        icon: ["far", "file-magnifying-glass"],
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3499512239
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-form",
                            {
                              staticClass: "filter-padding",
                              on: {
                                submit: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "search-input" },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.$t("task.filter"),
                                    },
                                    on: {
                                      focus: function ($event) {
                                        _vm.filterTextFocus = true
                                      },
                                      blur: function ($event) {
                                        _vm.filterTextFocus = false
                                      },
                                      keypress: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.onFilterSubmit.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.filterText,
                                      callback: function ($$v) {
                                        _vm.filterText = $$v
                                      },
                                      expression: "filterText",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-btn",
                                        {
                                          staticClass:
                                            "search-append search-append-bg",
                                          attrs: {
                                            id: `BTN_SEARCH_FILTER_${_vm.id}`,
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.filterText = ""
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: ["far", "xmark"] },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-popover",
                                        {
                                          attrs: {
                                            target: `BTN_SEARCH_FILTER_${_vm.id}`,
                                            triggers: "hover",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("button.search")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-btn",
                                        {
                                          staticClass:
                                            "search-append search-append-bg",
                                          attrs: {
                                            id: `BTN_PIN_FILTER_${_vm.id}`,
                                            size: "sm",
                                          },
                                          on: { click: _vm.onPinFilter },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: ["far", "thumbtack"],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-popover",
                                        {
                                          attrs: {
                                            target: `BTN_PIN_FILTER_${_vm.id}`,
                                            triggers: "hover",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("button.pinOnToolbar")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "li",
                [
                  _vm.filterPinned
                    ? _c(
                        "b-input-group",
                        { staticClass: "search-input" },
                        [
                          _c("b-form-input", {
                            attrs: { placeholder: _vm.$t("task.filter") },
                            on: {
                              focus: function ($event) {
                                _vm.filterTextFocus = true
                              },
                              blur: function ($event) {
                                _vm.filterTextFocus = false
                              },
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onFilterSubmit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.filterText,
                              callback: function ($$v) {
                                _vm.filterText = $$v
                              },
                              expression: "filterText",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "search-append search-append-bg",
                                  attrs: {
                                    id: `BTN_SEARCH_FILTER_${_vm.id}`,
                                    size: "sm",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.filterText = ""
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "xmark"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: `BTN_SEARCH_FILTER_${_vm.id}`,
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("button.search")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "search-append search-append-bg",
                                  attrs: {
                                    id: `BTN_UNPIN_FILTER_${_vm.id}`,
                                    size: "sm",
                                  },
                                  on: { click: _vm.onUnPinFilter },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "thumbtack"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: `BTN_UNPIN_FILTER_${_vm.id}`,
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("button.unpinFromToolbar")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "li",
                {
                  staticClass: "view",
                  on: _vm._d({ mouseleave: _vm.onViewLeave }, [
                    _vm.viewMouseEnterEvent,
                    _vm.onViewOver,
                  ]),
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      ref: "view",
                      staticClass: "action-bar-dropdown",
                      attrs: {
                        id: `BTN_VIEW_${_vm.id}`,
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "desktop"] },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4186387599
                      ),
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: { href: "#" },
                          on: { click: _vm.showAllColumns },
                        },
                        [
                          _c("span", { staticClass: "action-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("task.button.all_columns"))),
                          ]),
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: { href: "#" },
                          on: { click: _vm.showNoColumns },
                        },
                        [
                          _c("span", { staticClass: "action-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("task.button.no_columns"))),
                          ]),
                        ]
                      ),
                      _c("b-dropdown-divider"),
                      _c(
                        "b-dropdown-item",
                        { attrs: { href: "#" }, on: { click: _vm.savePreset } },
                        [
                          _c("span", { staticClass: "action-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("task.button.save"))),
                          ]),
                        ]
                      ),
                      _c("b-dropdown-divider"),
                      _vm._l(_vm.userProfile.views, function (item, index) {
                        return [
                          _c(
                            "b-dropdown-item",
                            {
                              key: index,
                              staticClass: "action-item",
                              attrs: {
                                disabled: _vm.actionProcessing,
                                href: "#",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.loadViewSettings(item)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "action-item-label-with-icon",
                                  attrs: { id: `viewname${index}` },
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _vm.isEllipsisActive(item.name)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: `viewname${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      "custom-class": "popover-margin",
                                      triggers: "hover",
                                      offset: "-100",
                                      content: item.name,
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "action-item-icon position-second",
                                  attrs: { id: `UPDATE_COLUMN_${index}` },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.updateColumnSettings(
                                        index,
                                        item.name
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "floppy-disk"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: `UPDATE_COLUMN_${index}`,
                                    triggers: "hover",
                                    placement: "top",
                                    boundary: "viewport",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("task.button.update")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "action-item-icon",
                                  attrs: { id: `REMOVE_COLUMN_${index}` },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.removeColumnSettings(index)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "trash-can"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: `REMOVE_COLUMN_${index}`,
                                    triggers: "hover",
                                    placement: "top",
                                    boundary: "viewport",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("task.button.delete")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      this.id === null
        ? _c("div", [_vm._v(" No valid project id specified in the URL. ")])
        : _c(
            "div",
            [
              _c("ag-grid-vue", {
                staticClass: "ag-theme-balham",
                staticStyle: { width: "100%" },
                style: { height: _vm.gridHeight },
                attrs: {
                  id: "project-tasks-grid",
                  gridOptions: _vm.gridOptions,
                  animateRows: "",
                  autoGroupColumnDef: _vm.autoGroupColumnDef,
                  columnDefs: _vm.columnDefs,
                  context: _vm.context,
                  defaultColDef: _vm.defaultColDef,
                  getMainMenuItems: _vm.getMainMenuItems,
                  noRowsOverlayComponent: "noRowsOverlay",
                  noRowsOverlayComponentParams:
                    _vm.noRowsOverlayComponentParams,
                  overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                  getDataPath: (data) => data.path,
                  getRowId: (params) => params.data.uuId,
                  groupDefaultExpanded: -1,
                  rowData: _vm.rowData,
                  rowMultiSelectWithClick: false,
                  rowSelection: "multiple",
                  sideBar: false,
                  suppressContextMenu: "",
                  suppressDragLeaveHidesColumns: "",
                  suppressCellFocus: false,
                  suppressMultiSort: false,
                  suppressClipboardApi: "",
                  singleClickEdit: false,
                  enableRangeSelection: true,
                  enableFillHandle: true,
                  fillOperation: _vm.fillOperation,
                  fillHandleDirection: "xy",
                  processCellForClipboard: _vm.processCellForClipboard,
                  processCellFromClipboard: _vm.processCellFromClipboard,
                  navigateToNextCell: _vm.navigateToNextCell,
                  tabToNextCell: _vm.tabToNextCell,
                  suppressScrollOnNewData: "",
                  treeData: "",
                  suppressRowDrag: !_vm.canEdit(),
                  enableCellEditingOnBackspace: "",
                  suppressGroupRowsSticky: "",
                },
                on: {
                  "grid-ready": _vm.onGridReady,
                  "row-drag-enter": _vm.onRowDragEnter,
                  "row-drag-end": _vm.onRowDragEnd,
                  "row-drag-move": _vm.onRowDragMove,
                  "row-drag-leave": _vm.onRowDragLeave,
                  "cell-key-down": _vm.onCellKeyDown,
                  "paste-start": _vm.onPasteStart,
                  "paste-end": _vm.onPasteEnd,
                  "cell-focused": _vm.cellFocused,
                },
              }),
            ],
            1
          ),
      _c("TaskModal", {
        attrs: {
          show: _vm.taskEditShow,
          mode: _vm.mode,
          id: _vm.taskEdit.uuId,
          projectId: _vm.id,
          parentId: _vm.taskEdit.parentId,
          isTemplate: "",
        },
        on: {
          "update:show": function ($event) {
            _vm.taskEditShow = $event
          },
          success: _vm.taskEditSuccess,
          "update:id": function ($event) {
            return _vm.$set(_vm.taskEdit, "uuId", $event)
          },
        },
      }),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
          cancel: _vm.progressCancel,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteViewOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteViewShow,
            callback: function ($$v) {
              _vm.confirmDeleteViewShow = $$v
            },
            expression: "confirmDeleteViewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("task.confirmation.delete_view")) + " "),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.save"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmSaveOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled:
                          _vm.saveName === null ||
                          _vm.saveName.trim().length == 0,
                        size: "sm",
                        variant: "success",
                      },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptSaveShow,
            callback: function ($$v) {
              _vm.promptSaveShow = $$v
            },
            expression: "promptSaveShow",
          },
        },
        [
          _c("b-form-input", {
            attrs: { maxlength: "25", trim: "" },
            model: {
              value: _vm.saveName,
              callback: function ($$v) {
                _vm.saveName = $$v
              },
              expression: "saveName",
            },
          }),
        ],
        1
      ),
      _c("TaskDateTimeDurationCalculation", {
        attrs: {
          show: _vm.durationCalculationShow,
          taskName: _vm.durationCalculation.taskName,
          defaultActionForNonWorkPrompt: "move",
          skipOutOfProjectDateCheck:
            _vm.durationCalculation.skipOutOfProjectDateCheck,
          showApplyAllCheckbox: "",
          trigger: _vm.durationCalculation.trigger,
          startDateStr: _vm.durationCalculation.startDateStr,
          startTimeStr: _vm.durationCalculation.startTimeStr,
          closeDateStr: _vm.durationCalculation.closeDateStr,
          closeTimeStr: _vm.durationCalculation.closeTimeStr,
          durationDisplay: _vm.durationCalculation.durationDisplay,
          calendar: _vm.durationCalculation.calendar,
          projectScheduleFromStart:
            _vm.durationCalculation.projectScheduleFromStart,
          taskAutoScheduleMode: _vm.durationCalculation.taskAutoScheduleMode,
          constraintType: _vm.durationCalculation.constraintType,
          constraintDateStr: _vm.durationCalculation.constraintDateStr,
          lockDuration: _vm.durationCalculation.lockDuration,
          oldDateStr: _vm.durationCalculation.oldDateStr,
          oldTimeStr: _vm.durationCalculation.oldTimeStr,
          oldConstraintType: _vm.durationCalculation.oldConstraintType,
          oldConstraintDateStr: _vm.durationCalculation.oldConstraintDateStr,
          durationConversionOpts: _vm.durationConversionOpts,
        },
        on: {
          "update:show": function ($event) {
            _vm.durationCalculationShow = $event
          },
          "update:calendar": function ($event) {
            return _vm.$set(_vm.durationCalculation, "calendar", $event)
          },
          success: _vm.durationCalculationOk,
          skip: function ($event) {
            return _vm.durationCalculationOk({ skip: true })
          },
          cancel: _vm.durationCalculationCancel,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "task-delete-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.tcConfirmDeleteShow = false
            },
            ok: _vm.tcConfirmDeleteOk,
            cancel: _vm.tcConfirmDeleteCancel,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _vm.tcShowApplyAllCheckbox
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.taskCol.applyAll,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskCol, "applyAll", $$v)
                            },
                            expression: "taskCol.applyAll",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tcConfirmDeleteShow,
            callback: function ($$v) {
              _vm.tcConfirmDeleteShow = $$v
            },
            expression: "tcConfirmDeleteShow",
          },
        },
        [_c("p", [_vm._v(_vm._s(_vm.tcConfirmDeleteStatement))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }