import * as mime from 'mime-types';

export function labelFilename(name, type) {

  if (!name || !type || name == '' || type == '-') {
    return '';
  }
  const typeDerivedFrmName = mime.lookup(name);
  let fileExtension = (typeDerivedFrmName === false)? `.${mime.extension(type)}`: '';
  if(fileExtension === '.false') {
    fileExtension = '';
  }
  return `${decodeURIComponent(name)}${fileExtension}`;
}