

<template>
  <div class="d-flex">
    <div class="d-flex select-button">
     <div ref="menuButton" class="customHeaderMenuButton" @click="onSelectToggle($event)">
       <font-awesome-icon class="selection-icon" :icon="selectedAll ? ['far', 'square-check'] : selectedSome ? ['far', 'square-minus'] : ['far','square']"/>
     </div>
     <div v-if="params.enableMenu" ref="menuButton" class="select-menu ml-1 customHeaderMenuButton" @click="onMenuClicked($event)">
       <font-awesome-icon class="selection-icon" :icon="['far','caret-down']"/>
     </div>
   </div>
   <span class="ml-1 display-name">{{ params.displayName }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import { fieldOptions } from '@/selectOptions';

export default Vue.extend({
  name: 'SelectionHeaderComponent',
  data() {
    return {
      value: null,
      options: fieldOptions,
      ascSort: null,
      descSort: null,
      noSort: null,
      selectedAll: false,
      selectedSome: false,      
    }
  },
  beforeMount() {
    this.prepareData();
  },
  computed: {

  },
  methods: {
    prepareData() {
      this.$nextTick(() => {
        const selectionStatus = this.getSelectionStatus();
        this.selectedAll = selectionStatus == 'all';
        this.selectedSome = selectionStatus == 'some';
      });
    },
    refresh(params) {
      this.params = params;
      this.prepareData();
    },
    getSelectionStatus() {
      const api = this.params.api;
      const cellRanges = api.getCellRanges();
      if (cellRanges.length == 0) {
        return 'none';
      }
      const selectedRows = new Set();
      for (const range of cellRanges) {
        let startIdx = range.startRow.rowIndex;
        let endIdx = range.endRow.rowIndex;
        if (startIdx > endIdx) {
          startIdx = range.endRow.rowIndex;
          endIdx = range.startRow.rowIndex;
        }
        
        for (let i = startIdx; i <= endIdx; i++) {
          selectedRows.add(i);
        }
      }

      const rowNode  = api.getDisplayedRowAtIndex(0);
      const totalCount = rowNode && rowNode.id == 'ROOT'? api.rowModel.rowsToDisplay.length-1 : api.rowModel.rowsToDisplay.length;
      if (selectedRows.size == 0) {
        return 'none';
      } else if ( selectedRows.size == totalCount) {
        return 'all';
      } else {
        return 'some';
      }
    },
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },
    onSelectToggle() {
      const api = this.params.api;
      if (this.selectedAll) {
        api.deselectAllFiltered();
        api.clearRangeSelection();
      }
      else {
        const rows = api.rowModel.rowsToDisplay;
        if (rows.length > 0) {
          let startIndex = rows[0].rowIndex;
          if (rows[0].id == 'ROOT') {
            if (rows.length > 1) {
              startIndex = rows[1].rowIndex;
            }
            else {
              api.clearRangeSelection();
              api.clearFocusedCell();
              return;
            }
          }
          const range = {
            rowStartIndex: startIndex,
            rowEndIndex: rows[rows.length-1].rowIndex,
            columns: ['ag-Grid-AutoColumn']
          }
          api.clearRangeSelection();
          api.addCellRange(range);

          //Make sure there is a focused cell in the grid.
          //Without focused cell, cell navigation, delete key interaction will not work.
          if(api.getFocusedCell() == null) {
            api.setFocusedCell(range.rowStartIndex, range.columns[0]);
          }
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.selection-icon {
  font-size: 18px;
  margin-top: 1px;
  color: var(--gray-500);
}

.select-menu {
  padding: 0 2px;
}

.select-button {
  padding: 3px;
  width: fit-content;
  border: 1px solid transparent;
}

.display-name {
  margin-top: 5px;
}

.select-button:focus,
.select-button:active,
.select-button:hover {
  background: var(--backdrop);
  border-radius: 3px;
  border: 1px solid var(--border-dark);
}
</style>
