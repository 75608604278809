
import { httpAjax } from '@/helpers';

export const fileLinkTagService = {
  create,
  remove
};

/**
 * Create a new Link from file to tag 
 * by passing necessary information
 * @param String fileId, file's UUID
 * @param Array tag, tag details. Data sample: { uuId }
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', tag: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx' } }
 */
function create(fileId, tagList) {
  const url = '/api/file/link/tag/add';
  const config = {
    headers: getHeaders()
  };

  const data = {
    uuId: fileId,
    tagList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from file to tag.
 * by passing in an array list of links uuIds
 * @param String fileId, file's UUID
 * @param Array tagIds, list of tag's UUID 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', tag: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }
 */
function remove(fileId, tagList) {
  const url = '/api/file/link/tag/delete';
  const config = {
    headers: getHeaders()
  };

  const data = {
    uuId: fileId,
    tagList
  }
  return httpAjax.post(url, data, config);
}


function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}