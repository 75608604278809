
import { httpAjax } from '@/helpers';

export const staffLinkSkillService = {
  create,
  update,
  remove,
};

/**
 * Create a new Link from staff to skill 
 * by passing necessary information
 * @param String staffId, Staff's UUID
 * @param Array skills, Skill details. Data sample: [{ uuId, level }]
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', skillList: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', skillLink: {level: 'Junior'} }] }
 */
function create(staffId, skills) {
  const url = '/api/staff/link/skill/add';
  const config = {
    headers: getHeaders()
  };

  const skillList = []
  skills.forEach(i => {
    const custom = { ...i };
    delete custom['name'];
    delete custom['uuId'];
    delete custom['level'];
    
    skillList.push( {
      uuId: i.uuId, 
      skillLink: {
        level: i.level,
        ...custom
      }
    } );
  });

  const data = {
    uuId: staffId,
    skillList: skillList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Update existing link details of staff to skill.
 * by passing  necessary information
 * @param String staffId, Staff's UUID
 * @param Array skills, Skill details. Data sample: [{ uuId, level }] 
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', skillList: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', skillLink: {level: 'Junior'} }] }
 */
function update(staffId, skills) {
  const url = '/api/staff/link/skill/update';
  const config = {
    headers: getHeaders()
  }

  const skillList = []
  skills.forEach(i => {
    const custom = { ...i };
    delete custom['name'];
    delete custom['uuId'];
    delete custom['level'];
    
    skillList.push( {
      uuId: i.uuId, 
      skillLink: {
        level: i.level,
        ...custom
      }
    } );
  });

  const data = {
    uuId: staffId,
    skillList: skillList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from staff to skill.
 * by passing in an array list of links uuIds
 * @param String staffId, Staff's UUID
 * @param Array skillIds, list of Skill's UUID 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', skillList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }
 */
function remove(staffId, skills) {
  const url = '/api/staff/link/skill/delete';
  const config = {
    headers: getHeaders()
  };

  const skillList = []
  skills.forEach(i => {
    skillList.push( {uuId: i.uuId} );
  });

  const data = {
    uuId: staffId,
    skillList: skillList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}