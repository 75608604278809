var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-header-container" },
    [
      _vm.label
        ? _c("span", { staticClass: "mr-1" }, [_vm._v(_vm._s(_vm.label))])
        : _vm._e(),
      _c("b-form-select", {
        staticClass: "select-header",
        attrs: { options: _vm.options },
        on: { change: _vm.onChange },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }