export function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function processRegExp(re) {
  //force the rule to match the whole string
  if (!re.startsWith('^')) {
    re = `^${re}`;
  }
  if (!re.endsWith('$')) {
    re = `${re}$`;
  }
  return re;
}