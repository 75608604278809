

<template>
  <span :class="{ 'font-weight-bold': isBold }">{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from '../util.js';

export default Vue.extend({
  name: 'TaskColCompactRenderer',
  data() {
    return {
      value: null,
      taskId: null,
      clickOutsideEventHandler: null,
      color: null,
      isBold: false
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    this.prepareValue();
  },
  mounted() {
    const compId = this.params.eGridCell.getAttribute("comp-id");
    this.updateBgColor(compId);
    if (this.taskId != null) {
      this.renderMenuIcon(compId);
    }
  },
  beforeDestroy() {
    this.cleanUp();
  },
  computed: {
    label() {
      return this.params.displayFormatter(this.value);
    },
    menuOptDeleteTasksLabel() {
      return 'Delete Selected Tasks'
    },
    menuOptDeleteTaskLabel() {
      return 'Delete Task';
    },
    menuOptEditTaskLabel() {
      return 'Edit Task';
    }
  },
  methods: {
    cleanUp() {
      if (this.clickOutsideEventHandler != null) {
        this.clickOutsideEventHandler({ target: null });
        document.removeEventListener('mousedown', this.clickOutsideEventHandler);
        this.clickOutsideEventHandler = null;
      }
    },
    prepareValue() {
      if (this.params.value == null) {
        return;
      }

      if (this.params.totalMode != null && this.params.value.childTotal != null) {
        this.isBold = this.params.totalMode;
      }

      if (this.params.data != null 
          && this.params.data[this.params.column.colId]
          && this.params.data[this.params.column.colId].data != null 
          && this.params.context != null 
          && this.params.context.componentParent != null 
          && this.params.context.componentParent.coloring != null) {
        const colorKey = this.getColorPropKey(this.params.context.componentParent.coloring);
        this.color = this.params.data[this.params.column.colId].data[colorKey];
      } else {
        this.color = null; 
      }
      
      const { taskId, value } = this.params.prepareValue(this.params);
      if (taskId != null || this.taskId != null) {
        this.taskId = taskId;
      }
      if (value != null || this.value != null) {
        this.value = value;
      }
    },
    refresh(params) {
      const prevTaskId = this.taskId;
      this.params = params;
      this.prepareValue();
      const compId = this.params.eGridCell.getAttribute("comp-id");

      this.updateBgColor(compId);

      //Make sure the menuIcon is being rendered. During mounted() event, menuIcon rendering is skipped when the `params.value` object is not ready.
      if (prevTaskId == null && this.taskId != null) {
        this.renderMenuIcon(compId);
      } 
      //Make sure the menuIcon is being removed after task delete.
      else if (prevTaskId != null && this.taskId == null) {
        const eGridCellElem = document.querySelector(`div[comp-id="${compId}"]`);
        if (eGridCellElem != null) {
          this.$nextTick(() => {
            let menuIcon = eGridCellElem.querySelector('.menu-icon-toggler');
            if (menuIcon != null) {
              this.cleanUp();
              eGridCellElem.removeChild(menuIcon);
              menuIcon = null;
            }
          })
        }
      } 
      //Refresh the triangle visibility based on latest label value.
      else if (this.taskId != null) {
        const eGridCellElem = document.querySelector(`div[comp-id="${compId}"]`);
        if (eGridCellElem != null) {
          this.$nextTick(() => {
            let triangleIconElem = eGridCellElem.querySelector('.triangle.triangle_tr');
            if (triangleIconElem != null) {
              const hideTriangle = this.label != null && typeof this.label === 'string' && this.label.trim().length > 0;
              if (hideTriangle) {
                triangleIconElem.classList.add('triangle_transparent');
              } else {
                triangleIconElem.classList.remove('triangle_transparent');
              } 
            }
          })
        }
      }
    },
    updateBgColor(compId) {
      const eGridCellElem = document.querySelector(`div[comp-id="${compId}"]`);
      if (eGridCellElem != null && this.color != null) {
        eGridCellElem.style.setProperty('--bg-color', this.color);
      } else {
        eGridCellElem.style.removeProperty('--bg-color');
      }
    },
    renderMenuIcon(compId) {
      const hideTriangle = this.label != null && (typeof this.label !== 'string' || this.label.trim().length > 0)? ' triangle_transparent' : '';
      const htmlContentStr = `
        <div class="menu-icon-toggler" style="position: absolute; top: 3px; right: 0; width: 11px; height: 100%;">
            <div class="triangle triangle_tr${hideTriangle}"></div>
            <i class="fa-regular fa-ellipsis-vertical taskcol-cell-menu-icon invisible"/>
        </div>
      `;
      const menuIcon = this.htmlToElement(htmlContentStr.trim().replaceAll(/>\n[\s]+/g, '>'));
      this.$nextTick(() => {
        const eGridCellElem = document.querySelector(`div[comp-id="${compId}"]`);
        if (eGridCellElem != null) {
          eGridCellElem.appendChild(menuIcon);
        }
        
      })
      //Register events for Menu Icon
      menuIcon.addEventListener('click', this.handleMenuIconClick);
      menuIcon.addEventListener('mouseenter', this.handleMenuIconMouseEnter);
      menuIcon.addEventListener('mouseleave', this.handleMenuIconMouseLeave);
    }
    ,htmlToElement(html) {
      var template = document.createElement('template');
      html = html.trim(); // Never return a text node of whitespace as the result
      template.innerHTML = html;
      return template.content.firstChild;
    },
    handleMenuIconClick(event) {
      event.preventDefault();
      event.stopPropagation();

      //Defensive code: Remove popup element and related listener if the clickOutsideEventHandler is not null.
      this.cleanUp();

      let toggler = event.target;
      if (!toggler.classList.contains('menu-icon-toggler')) {
        toggler = event.target.closest('.menu-icon-toggler');
      }
      const targetClientRect = toggler.getBoundingClientRect();
      const agGridBodyClientRect = event.target.closest('.ag-root-wrapper-body').getBoundingClientRect();
      const left = targetClientRect.left - agGridBodyClientRect.left;
      const top = targetClientRect.top - agGridBodyClientRect.top;
      this.renderCellMenu({ left, top });
    },
    handleMenuIconMouseEnter(event) {
      //Prevent the menu Icon from showing up when user is performing mouse press and dragging action (e.g: fill operation)
      if (event.buttons > 0) {
        return;
      }
      //Fixed #1902
      if (event.target.parentElement != null && event.target.parentElement.classList.contains('ag-cell-focus')) {
        event.target.style.top = '0px';
      }
      event.target.classList.add('cursor-pointer');
      event.target.querySelector('.triangle').classList.add('invisible');
      event.target.querySelector('.taskcol-cell-menu-icon').classList.remove('invisible');
    },
    handleMenuIconMouseLeave(event) {
      event.target.style.top = '3px'; //Fixed #1902
      event.target.classList.remove('cursor-pointer');
      event.target.querySelector('.triangle').classList.remove('invisible');
      event.target.querySelector('.taskcol-cell-menu-icon').classList.add('invisible');
    },
    renderCellMenu({ left, top } = {}) {
      const hasMultiSelection = this.params.api.getSelectedRows().length > 1;
      let deleteTasksHtmlContentStr = '';
      if (hasMultiSelection) {
        deleteTasksHtmlContentStr = `
          <div class="ag-menu-option" tabindex="-1" role="treeitem" aria-level="1" data-action="delete-tasks">
              <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation"></span>
              <span ref="eName" class="ag-menu-option-part ag-menu-option-text">${ this.menuOptDeleteTasksLabel }</span>
              <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
              <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
          </div>
        ` 
      }

      const htmlContentStr = `
        <div class="ag-tabs ag-menu ag-focus-managed ag-ltr ag-popup-child" role="dialog" aria-label="Column Menu" style="visibility: hidden">
          <div role="tablist" class="ag-tabs-header ag-menu-header">
              <span role="tab" tabindex="-1" class="ag-tab ag-tab-selected" aria-label="general">
                  <span class="ag-icon ag-icon-menu" unselectable="on" role="presentation"></span>
              </span>
          </div>
          <div role="presentation" class="ag-tabs-body ag-menu-body">
              <div class="ag-menu-list ag-focus-managed" role="tree">
                  <div class="ag-tab-guard ag-tab-guard-top" role="presentation" tabindex="0"></div>
                  <div class="ag-menu-option" tabindex="-1" role="treeitem" aria-level="1" data-action="edit-task">
                      <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation"></span>
                      <span ref="eName" class="ag-menu-option-part ag-menu-option-text">${ this.menuOptEditTaskLabel }</span>
                      <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
                      <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
                  </div>
                  <div class="ag-menu-option" tabindex="-1" role="treeitem" aria-level="1" data-action="delete-task">
                      <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation"></span>
                      <span ref="eName" class="ag-menu-option-part ag-menu-option-text">${ this.menuOptDeleteTaskLabel }</span>
                      <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
                      <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
                  </div>
                  ${ deleteTasksHtmlContentStr }
                  <div class="ag-tab-guard ag-tab-guard-bottom" role="presentation" tabindex="0"></div>
              </div>
          </div>
      </div>
      `;
      const cellMenu = this.htmlToElement(htmlContentStr.trim().replaceAll(/>\n[\s]+/g, '>'));
      cellMenu.style.left = `${left - 15}px`;
      cellMenu.style.top = `${top}px`;
      let popupElement = document.querySelector('.ag-root-wrapper > .ag-popup');
      if (popupElement != null) {
        popupElement.remove;
      }
      popupElement = document.createElement('div');
      popupElement.classList.add('ag-theme-balham')
      popupElement.classList.add('ag-popup');
      popupElement.classList.add('header-group-column-popup');
      popupElement.appendChild(cellMenu);
      document.querySelector('.ag-root-wrapper').appendChild(popupElement);

      //Register event for menu header
      const menuHeader = cellMenu.querySelector('.ag-menu-header');
      menuHeader.addEventListener('click', this.handleMenuHeaderClick);
      
      //Register events for menu options      
      const menuOptions = cellMenu.querySelectorAll('.ag-menu-option');
      if (menuOptions != null) {
        for (let i = 0, len = menuOptions.length; i < len; i++) {
          //'Click'
          menuOptions[i].addEventListener('click', this.handleMenuOptionClick);
          //'Mouseenter' and 'Mouseleave'
          menuOptions[i].addEventListener('mouseenter', this.addMenuOptionHighlight);
          menuOptions[i].addEventListener('mouseleave', this.removeMenuOptionHighlight);
        }
      }

      //Adjust left position if not enough room for the menu width
      const viewportWidth = document.documentElement.clientWidth;
      const rect = cellMenu.getBoundingClientRect();
      const availableWidth = viewportWidth - rect.left;
      const requiredWidth = rect.right - rect.left;
      if (requiredWidth > availableWidth) {
        let adjustedLeft = left - (requiredWidth - availableWidth) - 27;
        cellMenu.style.left = `${adjustedLeft}px`;
      }
      cellMenu.style.visibility = 'visible';

      //Register event for hiding menu when clicking outside of menu
      this.clickOutsideEventHandler = function(e) {
        const popupElem = document.querySelector('.header-group-column-popup');
        if (popupElem != null) {
          if (!popupElem.contains(e.target)) {
            popupElem.remove();
          }
        }
      }
      document.addEventListener('mousedown', this.clickOutsideEventHandler);
    },
    handleMenuOptionClick(event) {
      const menuOption = event.target.closest('.ag-menu-option');
      const action = menuOption.dataset.action;
      const colId = this.params.column.colId;
      const headerName = this.params.colDef.headerName;
      const row = this.params.data;
      if (action == null) {
        //do nothing.  
      } else if (action == 'edit-task') {
        this.params.context.componentParent.taskOpen(this.taskId);
      } else if (action == 'delete-task') {
        this.params.context.componentParent.taskDelete([
          { uuId: row[colId].uuId, name: headerName, parent: row.uuId, parentName: row.name, colId }
        ]);
      }
      this.cleanUp();
    },
    addMenuOptionHighlight(event) {
      const menuOption = event.target.closest('.ag-menu-option');
      const activeClass = 'ag-menu-option-active';
      if (!menuOption.classList.contains(activeClass)) {
        menuOption.classList.add(activeClass);
      }
    },
    removeMenuOptionHighlight(event) {
      const menuOption = event.target.closest('.ag-menu-option');
      const activeClass = 'ag-menu-option-active';
      if (menuOption.classList.contains(activeClass)) {
        menuOption.classList.remove(activeClass);
      }
    },
    handleMenuHeaderClick(/* event */) {
      this.cleanUp();
    },
    getColorPropKey(coloring) {
      if (coloring == null) {
        return null;
      }
      if (coloring.task) {
        return 'color';
      }
      if (coloring.stage) {
        return 'stageColor';
      }
      if (coloring.rebate) {
        return 'rebateColor';
      }
      if (coloring.file) {
        return 'fileColor';
      }
      if (coloring.staff) {
        return 'staffColor';
      }
      if (coloring.skill) {
        return 'skillColor';
      }
      if (coloring.resource) {
        return 'resourceColor';
      }
      return null;
    }
  }
})
</script>