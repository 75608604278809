<template>
  <span>{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

export default Vue.extend({
  name: 'TaskConstraintCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    const data = this.params && this.params.data? this.params.data : null;
    const constraintType = data && data.constraintType != null? data.constraintType : this.params.value;
    let constraintDate = '';
    if(constraintType && 'As_late_as_possible' != constraintType && 'As_soon_as_possible' != constraintType) {
      constraintDate = data.constraintTime? ' ' + moment(data.constraintTime).format('L') : '';
    }
    this.value = constraintType? this.params.context.componentParent.$t(`constraint_type.${constraintType}`) + constraintDate  : null;
  },
  computed: {
    label() {
      return this.value? this.value : '';
    }
  }
})
</script>
