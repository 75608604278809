var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-link", { attrs: { to: _vm.url, custom: "" } }, [
    _c(
      "li",
      { staticClass: "nav-item nav-dropdown", attrs: { disabled: "" } },
      [
        _c(
          "div",
          {
            staticClass: "nav-link nav-dropdown-toggle",
            on: { click: _vm.handleClick },
          },
          [_c("i", { class: _vm.classIcon }), _vm._v(" " + _vm._s(_vm.name))]
        ),
        _vm.actions
          ? _vm._l(_vm.actions, function (action, index) {
              return _c("router-link", {
                key: index,
                staticClass: "float-right",
                attrs: { to: action.url },
              })
            })
          : _vm._e(),
        _c("ul", { staticClass: "nav-dropdown-items" }, [_vm._t("default")], 2),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }