const togglePs = {
  methods: {
    togglePs(toggle) {
      const sidebar = document.querySelectorAll('.sidebar-nav section')
      for (var i = 0; i < sidebar.length; i++) {
        // sidebar.classList.toggle('ps', toggle);
        // sidebar.classList.toggle('ps-container', toggle);
        // sidebar.classList.toggle('ps--active-y', toggle);
        if (toggle) {
          sidebar[i].classList.add('ps', 'ps-container', 'ps--active-y')
        } else {
          sidebar[i].classList.remove('ps', 'ps-container', 'ps--active-y')
        }
      }
    }
  }
}

export { togglePs }
