
import { httpAjax } from '@/helpers';

export const staffLinkResourceService = {
  create,
  update,
  remove,
};

/**
 * Create a new Link from staff to resource 
 * by passing necessary information
 * @param String staffId, Staff's UUID
 * @param Array resources, Resource details. Data sample: [{ uuId, level }]
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', resourceList: [ { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', resourceLink: { unit: 1, utilization: 1.00 } }] }
 */
function create(staffId, resources) {
  const url = '/api/staff/link/resource/add';
  const config = {
    headers: getHeaders()
  };

  const resourceList = []
  resources.forEach(i => {
    const custom = { ...i };
    delete custom['name'];
    delete custom['uuId'];
    delete custom['utilization'];
    delete custom['quantity'];
    
    resourceList.push({
      uuId: i.uuId, 
      resourceLink: { 
        utilization: i.utilization ? i.utilization : 1.00, 
        quantity: i.quantity,
        ...custom
      }
    });
  });

  const data = {
    uuId: staffId,
    resourceList: resourceList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Update existing link details of staff to resource.
 * by passing  necessary information
 * @param String staffId, Staff's UUID
 * @param Array resources, Resource details. Data sample: [{ uuId, level }] 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', resourceList: [ { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', resourceLink: { unit: 1, utilization: 1.00 } }] }
 */
function update(staffId, resources) {
  const url = '/api/staff/link/resource/update';
  const config = {
    headers: getHeaders()
  }

  const resourceList = []
  resources.forEach(i => {
    const custom = { ...i };
    delete custom['name'];
    delete custom['uuId'];
    delete custom['utilization'];
    delete custom['quantity'];
    
    resourceList.push({
      uuId: i.uuId, 
      resourceLink: { 
        utilization: i.utilization ? i.utilization : 1.00, 
        quantity: Object.hasOwn(i, 'resourceUnit')? i.resourceUnit : i.quantity,
        ...custom
      }
    });
  });

  const data = {
    uuId: staffId,
    resourceList: resourceList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from staff to resource.
 * by passing in an array list of links uuIds
 * @param String staffId, Staff's UUID
 * @param Array resourceIds, list of Resource's UUID 
 * request body format sample: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', resourceList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }]
 */
function remove(staffId, resources) {
  const url = '/api/staff/link/resource/delete';
  const config = {
    headers: getHeaders()
  };

  const resourceList = []
  resources.forEach(i => {
    resourceList.push( {uuId: i.uuId} );
  });

  const data = {
    uuId: staffId,
    resourceList: resourceList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}