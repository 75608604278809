export function toFixed(value, precision) { // To format number to specified precision
  const pow = Math.pow(10, precision);
  const _value = Math.round(value * pow)
  return _value / pow;
}

export function floatFormatter(defaultValue=0.00, minValue=null) {
  
  return (value, precision=2) => {
    const _minValue = minValue != null? minValue.toFixed(precision) : null;
    const _defaultValue = defaultValue != null? defaultValue.toFixed(precision) : null;
    if(value && !isNaN(value)) {
      const v = parseFloat(value).toFixed(precision);
      return _minValue != null? v >= _minValue? v : _minValue : v;
    } else {
      return _defaultValue;
    }
  }
}

export function floatFormat(value, precision=2, defaultValue=0.00, minValue=null) {
  const _minValue = minValue != null? minValue.toFixed(precision) : null;
  const _defaultValue = defaultValue != null? defaultValue.toFixed(precision) : null;
  if(value && !isNaN(value)) {
    const v = parseFloat(value).toFixed(precision);
    return _minValue != null? v >= _minValue? v : _minValue : v;
  } else {
    return _defaultValue;
  }
}