

<template>
  <div>
    <span>{{ label }}</span>
    <template v-if="hasError">
      <font-awesome-icon :id="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`" class="ml-1" style="color: red;font-size: 1.125em;" :icon="['fas', 'triangle-exclamation']"/>
      <b-popover
        :target="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`"
        placement="top"
        triggers="hover"
        :content="$t(params.errorMessage)">
      </b-popover>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'EnumCellRenderer',
  data() {
    return {
      value: null,
      found: false
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    this.value = null;
    if (this.params && this.params.value !== -1) {
      const text = this.params.options.filter(o => o.value === this.params.value);
      if (text.length > 0) {
        this.value = text[0].text;
        this.found = true;
      }
    }
  },
  computed: {
    label() {
      return this.value;
    },
    hasError() {
      return (this.value && !this.found) || (this.params.required && !this.value);
    }
  }
})
</script>
