<template>
  <span>{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'GenericObjectCellRenderer',
  data() {
    return {
      value: null
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);

    const constructLabel = this.params.labelFormatter;
    if (constructLabel != null && typeof constructLabel == 'function') {
      const result = constructLabel(this.params)
      this.value = result.value
    } else {
      const v = this.params.value
      this.value = v != null && v.name != null? v.name : ''
    }
  },
  computed: {
    label() {
      return this.value != null? this.value: '';
    }
  },
  methods: {
    
  }
})
</script>
