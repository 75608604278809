import { cloneDeep } from 'lodash';
import { costFormat, costFormatAdv } from '@/helpers';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { taskService, templateTaskService, compositeService
, projectService, templateProjectService, fileService, folderService
// , taskLinkStageService
, locationService, calendarService, layoutProfileService
} from '@/services';
import { nextWorkingDay, isWorkingDay, earliestWorkHour, msToTime, strRandom
} from '@/helpers';
import { analyzeDurationAUM, convertDisplayToDuration, __addDurationToDate } from '@/helpers/task-duration-process';
import { getCustomFieldExportDataPropertyHandler } from '@/helpers/custom-fields'

export const TaskTemplateDataUtil = {
  processCellCallback,
  createTasksFromImportedDocument,
  applyTaskTemplate,
  applyTaskTemplateAdv,
  createTemplateTasksFromTasksAdv,
  createTasksFromTasksAdv,
  createTemplateTasksFromTemplateTasksAdv,
}

async function __updateProjectAutoScheduling(projectId, autoScheduling, { isTemplate=false, service=null } = {}) {
  let _service = isTemplate? templateProjectService : projectService;
  if (service != null) {
    _service = service;
  }
  await _service.update([{ uuId: projectId, autoScheduling }])
  .catch(e => {
    console.error(e); // eslint-disable-line no-console
  });
}

async function __getProject(projectId, isTemplate=false) {
  let service = isTemplate? templateProjectService : projectService;
  let data = await service.get([{ uuId: projectId }])
  .then(response => {
    return (response && response.data && response.data.jobCase? response.data[response.data.jobCase] : []) || [];
  })
  .catch(() => {
    return [];
  });

  if(data.length > 0) {
    const projectData = data[0];
    const autoScheduling = projectData.autoScheduling != null? projectData.autoScheduling: true; //Default to true when no 'autoScheduling' property.
    const locationList = Array.isArray(projectData.locationList)? projectData.locationList : [];
    return { uuId: projectId, autoScheduling, locationId: locationList.length > 0? locationList[0].uuId : null }
  } else {
    return { uuId: projectId, autoScheduling: false, locationId: null }
  }
}

async function __locationCalendar(locationId, { defaultValue=null } = {}) {
  let data = await locationService.calendar(locationId)
  .then(response => {
    return (response && response.data && response.data.jobCase? response.data[response.data.jobCase] : []) || [];
  })
  .catch(() => {
    return [];
  })
  if (data.length > 0) {
    return __digestCalendarResponse(data);
  }
  return defaultValue; // Fallback to default calendar.
}

async function __systemLocationCalendar({ defaultValue=null } = {}) {
  let data = await calendarService.get([{ uuId: '00000000-0000-0000-0000-000000000000'}])
  .then(response => {
    return (response && response.data[response.data.jobCase]? response.data[response.data.jobCase] : []) || [];
  })
  .catch(() => {
    return [];
  })
  if (data.length > 0) {
    return __digestCalendarResponse([{ uuId: '00000000-0000-0000-0000-000000000000', name: 'base_calendar', calendarList:data[0]}], ['base_calendar']);
  } else {
    return defaultValue; // Fallback to default calendar.
  }
}

function __digestCalendarResponse(data, calendarOrder=['location','base_calendar']) {
  const calendar = {};
  const existingTypes = [];
  for(const order of calendarOrder) {
    const calObj = data.find(i => i.name === order);
    if(!calObj) {
      continue;
    }
    const calendarList = calObj.calendarList;
    for(const type of calendarList) {
      if (!type || type.length < 1 || (calendar[type.type] && calendar[type.type][0].calendar !== order)) {
        continue;
      }
      if(!calendar[type.type]) {
        existingTypes.push(type.type);
        calendar[type.type] = [];
      }
      const cloned = cloneDeep(type);
      cloned.calendar = order;
      if(cloned.startHour) {
        cloned.startHour = `${msToTime(cloned.startHour)}:00`;
      }
      if(cloned.endHour) {
        cloned.endHour = `${msToTime(cloned.endHour)}:00`;
      }
      calendar[type.type].push(cloned);
    }
  }
  return calendar;
}

function processCellCallback(self) {
  const formatCost = (value, currencyCode=null) => {
    let rawValue = parseInt(value);
    if (rawValue < 0) {
      return '';
    }
    else {
      return currencyCode == null? `$${costFormat(rawValue, {notation:'standard'})}` : costFormatAdv(rawValue, currencyCode, {notation:'standard'});
    }
  }
  const customFieldHandler = getCustomFieldExportDataPropertyHandler(self.customFields)
  return function(params) {
    if (params.column.colId.indexOf('ag-Grid-AutoColumn') !== -1) {
      return params.node.data.name;
    } else if (params.column.colId.indexOf('startTime') !== -1) {
      if (params.node.data.startTime === 0) {
        return '';
      }
      return moment(params.node.data.startTime).format();
    } else if (params.column.colId.indexOf('closeTime') !== -1) {
      if (params.node.data.closeTime === 9223372036854776000 ||
          params.node.data.closeTime === 0) {
        return '';
      }
      return moment(params.node.data.closeTime).format();
    } else if (params.column.colId.indexOf('constraintTime') !== -1) {
      if (params.node.data.constraintTime === 9223372036854776000 ||
          params.node.data.constraintTime === 0) {
        return '';
      }
      return moment(params.node.data.constraintTime).format();
    } else if (params.column.colId.indexOf('estimatedCost') !== -1 || params.column.colId.indexOf('actualCost') !== -1 ||
               params.column.colId.indexOf('fixedCost') !== -1 ||
               params.column.colId.indexOf('Cost') !== -1) {
                const currencyCode = params.node != null 
                && params.node.data != null
                && params.node.data.currencyCode != null 
                && params.node.data.currencyCode.trim().length > 0? params.node.data.currencyCode : null
      return formatCost(params.value, currencyCode)
      
    } else if (params.column.colId.indexOf('staffs') !== -1) {
      return params.node.data.staffs != null? params.node.data.staffs.map(i => i.utilization === 1 ? i.name : `${i.name} (${i.utilization * 100}%)`).join(', ') : '';
    } else if (params.column.colId.indexOf('skills') !== -1) {
      return params.node.data.skills != null? params.node.data.skills.map(i => `${i.name} (${i.level})`).join(', ') : '';
    } else if (params.column.colId.indexOf('resources') !== -1) {
      return params.node.data.resources != null? params.node.data.resources.map(i => {
        return `${i.name} (${i.unit})`;
      }).join(', ') : '';
    } else if (params.column.colId.indexOf('constraintType') !== -1) {
      const data = params.node.data;
      const constraintType = data && data.constraintType != null? data.constraintType : null;
      // let constraintDate = '';
      // if(constraintType && 'As_late_as_possible' != constraintType && 'As_soon_as_possible' != constraintType) {
      //   constraintDate = data.constraintTime? ' ' + moment(data.constraintTime).format('YYYY-MM-DD') : '';
      // }
      return constraintType? constraintType : null;
    } else if (params.column.colId.indexOf('priority') !== -1) {
      return params.value != null && params.value.length > 0? self.$t(`priority.${params.value}`) : null;
    } else if (params.column.colId.indexOf('taskPath') !== -1) {
      return params.value != null && params.value.length > 0? params.value : null;
    } else if (params.column.colId.indexOf('parentTasks') !== -1) {
      if (params.node.data.taskPath) {
        const start = params.node.data.taskPath.indexOf('\n');
        return params.node.data.taskPath.substr(start + 1, params.node.data.taskPath.lastIndexOf('\n') - (start + 1)).replace(/\n/g, ' / ');
      }
      return params.value != null && params.value.length > 0? params.value : null;
    }
    else if (params.column.colId.indexOf('Type') !== -1) {
      return params.value != null && params.value.length > 0 && params.value === 'Project' ? 'Summary Task' : params.value;
    }
    else if (params.column.colId.indexOf('progress') !== -1) {
      return params.value != null ? `${Math.round(params.value * 100)}%` : '';
    }
    else if (params.column.colId.indexOf('stage') !== -1) {
      return params.value != null && params.value.name ? params.value.name : '';
    }
    else if (params.column.colId === 'constraint') {
      return params.value != null && params.value.type ? params.value.type : '';
    }
    else if (params.column.colId === 'constraint_time') {
      return params.value != null && params.value.time ? moment(params.value.time).format('YYYY-MM-DD') : '';
    }
    else if (params.column.colId.indexOf('template') !== -1) {
      if (params.value == null) {
        return '';
      }
      if (params.value.name != null) {
        return params.value.name;
      }
      if (params.value.names != null && Array.isArray(params.value.names)) {
        return params.value.names.join(', ');
      }
      return '';
    }
    else if (params.column.colId.indexOf('avatarRef') !== -1) {
      return params.value !== '00000000-0000-0000-0000-000000000000' ? params.value : '';
    }
    else if (params.column.colId.indexOf('notes') !== -1) {
      return params.value !== null && params.value ? params.value.map(n => { return n.text }).join('. \r\n') : '';
    }
    else if (params.column.colId.indexOf('rebates') !== -1) {
      return params.value !== null && params.value ? params.value.map(n => { return `${n.name}(${n.rebate*100}%)` }).join(', ') : '';
    }
    else if (params.column.colId.startsWith('taskcol_')) {
      if (params.value == null || (params.value.single == null && params.value.childTotal == null)) {
        return '';
      } 
      const displayFormatter = params.column.userProvidedColDef != null 
                                && params.column.userProvidedColDef.cellRendererParams != null 
                                && params.column.userProvidedColDef.cellRendererParams.displayFormatter != null? 
                                  params.column.userProvidedColDef.cellRendererParams.displayFormatter : null;
      if (displayFormatter != null) {
        let val = null;
        if (params.value.single != null) {
          val = params.value.single;
        }
        if (params.value.childTotal != null 
              && params.column != null 
              && params.column.colDef != null 
              && params.column.colDef.cellRendererParams != null
              && params.column.colDef.cellRendererParams.totalMode == true) {
          const cRParams = params.column.colDef.cellRendererParams;
          if (cRParams.aggregator != null) {
            val = cRParams.aggregator(val, params.value.childTotal);
          } else if (val != null) {
            val += params.value.childTotal;
          } else {
            val = params.value.childTotal;
          }
        }
        return displayFormatter(val);
      }
      return params.value.single;
    } else if (Object.hasOwn(customFieldHandler, params.column.colId)) {
      return customFieldHandler[params.column.colId](params)
    }
    return params.value;
  } 
  
}

function parseCost(val) {
  if (typeof val === 'undefined' ||
      val === '') {
    return null;
  }
  
  if (val.indexOf('$') === 0) {
    val = val.substr(1);
  }
  
  var num = parseFloat(val);
  if (val.includes('k')) {
    num = num * 1000;
  }
  return `${num}`;
}

function getStartDate(calendar) {
  let startDate = moment.utc();
  let workHour = '00:00:00';
  if(!isWorkingDay(startDate, calendar)) {
    startDate = nextWorkingDay(startDate, 1, false, calendar);
  }
  workHour = earliestWorkHour(startDate, calendar);
  return moment(`${startDate.format('YYYY-MM-DD')}T${workHour}`, 'YYYY-MM-DDTHH:mm:ss');
}

function getEndDate(calendar, startDate, duration) {
  const newClose = __addDurationToDate(calendar, moment(startDate), duration);
  return newClose.value;
}

// function totalItems(items) {
//   if (!items) {
//     return 0;
//   }
  
//   let total = items.length;
//   for (const item of items) {
//     total += totalItems(item.items);
//   }
//   return total;
// }

function base64ToArrayBuffer(base64) {
  var binaryString = atob(base64);
  var bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

async function createTasksFromImportedDocument(items, projectId, parentId=null, isTemplateTask=false, state={ cancel: false }, self=null, durationConversionOpts={}) {
  const service = isTemplateTask? templateTaskService : taskService;
  const serviceApi = isTemplateTask? `/api/template/task/add?holder=${projectId}` : `/api/task/add?holder=${projectId}`;
  const serviceApiUpdate = isTemplateTask? `/api/template/task/update` : `/api/task/update`;
  let cmdList = [];
  let ids = {};
  let taskMap = {};
  let idx = 0;
  const pService = isTemplateTask? templateProjectService : projectService;
  const reterrors = [];
  
  const project = await pService.get([{ uuId: projectId }], ['LOCATION', 'REBATE'])
  .then(response => {
    const list = response.data[response.data.jobCase];
    return list && list.length > 0? list[0] : null;
  })
  .catch(e => {
    console.error(e); // eslint-disable-line no-console
    return null;
  });

  if (self.inProgressState.cancel) {
    return;
  }
  
  const profileData = await layoutProfileService.list(projectId, self.userId).then((response) => {
    return response.data[response.data.jobCase];
  })
  .catch((e) => {
    console.error(e); // eslint-disable-line no-console
    return null;
  });
  
  const taskGroup = self.isCompactView && profileData && profileData.length > 0 ? profileData[0].compactTaskColumns.find(c => c.groupId && c.groupId.startsWith('taskgroup')) : null;
  const restoreAutoScheduling = project !== null ? project.autoScheduling : false;
  if (project.autoScheduling) {
    project.autoScheduling = false;
    await __updateProjectAutoScheduling(project.uuId, project.autoScheduling, { service: pService });
  }
  
  let calendar = null;
  if(project) {
    const locationList = project.locationList || [];
    if(locationList.length > 0) { 
      calendar = await __locationCalendar(locationList[0].uuId);
    } else {
      calendar = await __systemLocationCalendar();
    }
  }
  
  if (self.inProgressState.cancel) {
    if (restoreAutoScheduling) {
      project.autoScheduling = true;
      await __updateProjectAutoScheduling(project.uuId, project.autoScheduling, { service: pService });
    }
    return;
  }

  let parentTask = null;
  if (parentId != null) {
    parentTask = await service.get([{ uuId: parentId}])
    .then(response => {
      const listName = response.data.jobCase;
      const data = response.data[listName] || [];
      if(data.length > 0) {
        return data[0];
      } else {
        return null;
      }
    })
    .catch(e => {
      console.error(e); // eslint-disable-line no-console
      return null;
    });
    
    if (self.inProgressState.cancel) {
      return;
    }

    if(parentTask) {
      const grandParentTaskId = await service.parent(projectId, parentId)
      .then(response => {
        const data = response.data || [];
        return data.length > 0 && data[0].pUuId && data[0].pUuId.length > 0? data[0].pUuId : null;
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
        return null;
      });
      parentTask.parent = grandParentTaskId;
    }
  } else {
    parentTask = await service.listTree({ start: 0, limit: 1}, projectId)
    .then(response => {
      if(response.data && response.data.length > 0) {
        return response.data[0];
      } else {
        return null;
      }
    })
    .catch(e => {
      console.error(e); // eslint-disable-line no-console
      return null;
    });
    if(parentTask) {
      delete parentTask.uuId; //Remove uuId to indicate no parentId passed in.
    }
  }

  if (self.inProgressState.cancel) {
    if (restoreAutoScheduling) {
      project.autoScheduling = true;
      await __updateProjectAutoScheduling(project.uuId, project.autoScheduling, { service: pService });
    }
    return;
  }

  cmdList.push({
       "note":"Enable macro calculations"
      ,"invoke"    : "PUT /api/system/features?entity=macros&action=DISABLE"
  });
  cmdList.push({
    "note": "Disable ordering validation"  , 
    "invoke": "PUT /api/system/features?entity=ordering&action=DISABLE"  
  });
  
  const projAutoScheduling = project && Object.hasOwn(project, 'autoScheduling')? project.autoScheduling : true;
  if(parentTask == null) { //Fallback and set default value for parentTask.startTime.
    let startDate = moment.utc();
    let workHour = '00:00:00';
    if(projAutoScheduling) {
      if(!isWorkingDay(startDate, calendar)) {
        startDate = nextWorkingDay(startDate, 1, false, calendar);
      }
      workHour = earliestWorkHour(startDate, calendar);
    }
    startDate = moment(`${startDate.format('YYYY-MM-DD')}T${workHour}`, 'YYYY-MM-DDTHH:mm:ss');
    parentTask = {
      startTime: startDate.valueOf()
    }
  }

  if (self.inProgressState.cancel) {
    if (restoreAutoScheduling) {
      project.autoScheduling = true;
      await __updateProjectAutoScheduling(project.uuId, project.autoScheduling, { service: pService });
    }
    return;
  }
  
  const errors = await _addTask(items, parentTask, restoreAutoScheduling, durationConversionOpts); //Add tasks reclusively
  reterrors.push(...errors);
  
  async function _addTask(childItems, parentTask, projAutoScheduling, durationConversionOpts={}) {
    let currentItem = null;
    const taskErrors = [];
    
    for(let i = 0, len = childItems.length; i < len; i++) {
      currentItem = childItems[i];
      const repeat = currentItem.count ? currentItem.count : 1;
      for (let rep = 0; rep < repeat; rep++) {
        const { unit } = analyzeDurationAUM(currentItem.duration, 1, 'D');
        const { value } = convertDisplayToDuration(currentItem.duration, durationConversionOpts);
        const fixedduration = convertDisplayToDuration(currentItem.fixedduration, durationConversionOpts);
        const startDate = currentItem.startdate ? moment.utc(currentItem.startdate).valueOf() : getStartDate(calendar).valueOf();
        const endDate = currentItem.enddate ? moment.utc(currentItem.enddate).valueOf() : getEndDate(calendar, startDate, value).valueOf();
        const data = {
          name: currentItem.name
          , description: currentItem.desc
          , taskType: currentItem.type !== "" && (currentItem.type === "Task" || currentItem.type === "Milestone") ? currentItem.type : "Project"
          , identifier: currentItem.identifier
          , priority: currentItem.priority 
          , progress: currentItem.progress
          , autoScheduling: typeof currentItem.schedulemode === 'undefined' ? projAutoScheduling :
            typeof currentItem.schedulemode === 'string' ? "true" === currentItem.schedulemode : currentItem.schedulemode
          , constraintType: currentItem.constraintType ? currentItem.constraintType : 'As_soon_as_possible'
          , constraintTime: currentItem.constraintTime ? moment.utc(currentItem.constraintTime).valueOf() : null
          , fixedCost: parseCost(currentItem.fixedcost) 
          , fixedDuration: fixedduration.value
          , currencyCode: currentItem.currency ? currentItem.currency : null
          , startTime: currentItem.type === 'Summary Task' ? null : startDate
          , closeTime: currentItem.type === 'Summary Task' || currentItem.type === 'Milestone' ? null : endDate
          , duration: value
          , durationAUM: unit
          , complexity: currentItem.complexity
        }
        
        if (taskGroup) {
          const defaults = taskGroup.children.find(c => c.colId.endsWith(currentItem.name));
          
          if (defaults) {
            if (defaults.color) {
              data.color = defaults.color;
            }
            if (defaults.skills &&
                !currentItem.skillList) {
              currentItem.skillList = defaults.skills;
            }
            if (defaults.staff &&
                !currentItem.staffList) {
              currentItem.staffList = defaults.staff;
            }
            if (defaults.resources &&
                !currentItem.resourceList) {
              currentItem.resourceList = defaults.resources;
            }
            if (defaults.rebates &&
                !currentItem.rebates) {
              currentItem.rebates = defaults.rebates;
            }
            if (defaults.tags &&
                !currentItem.tags) {
              currentItem.tags = defaults.tags;
            }
          }
        }
        
        if (self.customFields) {
          for (const cfield of self.customFields) {
            if (currentItem[cfield.name]) {
              data[cfield.name] = currentItem[cfield.name];
            }
          }
        }
        
        // for updating existing tasks
        if (currentItem.uuId) {
          delete data['parent'];
          data.uuId = currentItem.uuId;
          if (data.taskType === 'Project') {
            delete data['progress'];
          }
        }
        
        if (((data.closeTime && data.startTime && data.closeTime === data.startTime) || data.closeTime < data.startTime) && data.duration !== 0) {
          const newClose = __addDurationToDate(calendar, moment(data.closeTime), data.duration);
          data.closeTime = newClose.value.valueOf();
        }
        
        if (currentItem.image) {
          if (currentItem.image.startsWith('data:')) {
          
            let uploadParent = null;
            const list = await folderService.get(null);
            for (const folder of list.data.propertyList.folders) {
              if (folder.name === 'Import') {
                uploadParent = folder.uuId;
                break;
              }
            }
            if (uploadParent === null) {
              uploadParent = await folderService.create({ name: 'Import'}).then((response) => {  
                return response.data.jobClue.uuId;
              });
            }
            let contentType = currentItem.image.substr(5, currentItem.image.indexOf(';') - 5);
            const blob = new Blob([base64ToArrayBuffer(currentItem.image.substr(currentItem.image.indexOf(',')+1))], {type: contentType});
            const form = new FormData();
            form.append('file', blob);
            form.append('name', `${strRandom(10)}.${contentType.split('/')[1]}`);
            let fileId = await fileService.upload(form).then((response) => {  
              return response.data.jobClue.uuId;
            })
            .catch(() => {
              return null;
            });
            await folderService.link(uploadParent, fileId, 'file');
            data.avatarRef = fileId;
          }
          else {
            // it is a uuId
            data.avatarRef = currentItem.image;
          }
        }
        
        if ((!self || !self.inProgressState.cancel) &&
            cmdList.length > 500) {
          // const taskId = 
          await compositeService.exec(cmdList)
          .then(response => {
            const feedbackList = response.data.feedbackList;
            if (Array.isArray(feedbackList) && 
                  feedbackList.length > 0) {
              for (let c = 0; c < cmdList.length; c++) {
                const cmd = cmdList[c];
                if (cmd.vars) {
                  const name = `@{${cmd.vars[0].name}}`;
                  const uuId = feedbackList[c].uuId;
                  ids[name] = uuId;
                  taskMap[name].uuId = uuId;
                }
              }
              return `@{${idName}}`;
            }
          })
          .catch((e) => {
            taskErrors.push(e);
            return null;
          });
          cmdList = [];

          cmdList.push({
               "note":"Enable macro calculations"
              ,"invoke"    : "PUT /api/system/features?entity=macros&action=DISABLE"
          });
          cmdList.push({
            "note": "Disable ordering validation"  , 
            "invoke": "PUT /api/system/features?entity=ordering&action=DISABLE"  
          });
        }

        let orderAt = null;
        data.parent = currentItem.project ? currentItem.project.uuId : projectId;
        if (parentTask.uuId) { 
          if ('Project' === parentTask.taskType) {
            if (parentTask.uuId in ids) {
              data.parent = ids[parentTask.uuId];
            }
            else {
              data.parent = parentTask.uuId; //Append as child
            }
          } else {
            data.parent = 'ROOT' === parentTask.parent || parentTask.parent && parentTask.parent.length == 0? null : parentTask.parent; //Append as sibling
          }
          if(data.parent != null) {
            //orderAt = parentTask.uuId; //Re-order to insert after parentId (Destination task)
          }
        }

        idx++;
        const idName = `task${idx}`;
        taskMap[`@{${idName}}`] = currentItem;
        cmdList.push({
          "invoke": orderAt ? `${data.uuId ? serviceApiUpdate : currentItem.project ? `/api/task/add?holder=${currentItem.project.uuId}` : serviceApi}&order-at=${orderAt}` : data.uuId ? serviceApiUpdate  : currentItem.project ? `/api/task/add?holder=${currentItem.project.uuId}` : serviceApi
          ,"method": data.uuId ? "PUT" : "POST"
          ,"body": [data]
          ,"vars": [{"name": idName,"path": "$.feedbackList.uuId"}]
        });

        if (self.inProgressState.cancel) {
          break;
        }

        if(currentItem.skillList && currentItem.skillList.length > 0) {
          currentItem.skillList.forEach(i => {
            cmdList.push({
              "invoke": "/api/task/link/skill/add",
              "body": {
                uuId: `@{${idName}}`,
                skillList: [{uuId: i.uuId,
                  skillLink: {level: i.skillLink ? i.skillLink.level : i.level}
                }]
              }
            });
          });
        }

        if(currentItem.staffList && currentItem.staffList.length > 0) {
          // const staffList = []
          currentItem.staffList.forEach(i => {
            cmdList.push({
              "invoke": '/api/task/link/staff/add',
              "body": {
                uuId: `@{${idName}}`,
                staffList: [{
                  uuId: i.uuId, 
                  resourceLink: {
                    utilization: i.resourceLink ? i.resourceLink.utilization : i.utilization
                  }
                }]
              }
            });
          });
          
          
        }

        if(currentItem.resourceList && currentItem.resourceList.length > 0) {

          currentItem.resourceList.forEach(i => {
            cmdList.push({
              "invoke": '/api/task/link/resource/add',
              "body": {
                uuId: `@{${idName}}`,
                resourceList: [{uuId: i.uuId, resourceLink: { quantity: i.quantity, utilization: i.utilization ? i.utilization : 1.00 }}]
              }
            });
          });

        }
        
        if(currentItem.stage) {

          cmdList.push({
            "invoke": '/api/task/link/stage/add',
            "body": {
              uuId: `@{${idName}}`,
              stage: {
                uuId: currentItem.stage
              }
            }
          });
        }

        if(currentItem.rebates && currentItem.rebates.length !== 0) {
          for (const rebate of currentItem.rebates) {
            cmdList.push({
              "invoke": '/api/task/link/rebate/add',
              "body": {
                uuId: `@{${idName}}`,
                rebateList: [{uuId: rebate.uuId}]
              }
            });
          }
        } else if (project.rebateList && project.rebateList.length > 0) {
          // If there is no rebate list given, default to using the project rebates, if any
          for (const rebate of project.rebateList) {
            cmdList.push({
              "invoke": '/api/task/link/rebate/add',
              "body": {
                uuId: `@{${idName}}`,
                rebateList: [{
                  uuId: rebate.uuId
                }]
              }
            });
          }
        }

        if(currentItem.tags && currentItem.tags.length !== 0) {
          for (const tag of currentItem.tags) {
            cmdList.push({
              "invoke": '/api/task/link/tag/add',
              "body": {
                uuId: `@{${idName}}`,
                tagList: [{uuId: tag.uuId}]
              }
            });
          }
        }

        if(currentItem.notes) {
          currentItem.notes.forEach(i => {
            if (i.text !== '') {
              cmdList.push({
                "invoke": `/api/note/add?holder=@{${idName}}`,
                "body": [i]
              });
            }
          });
        }
        
        if (currentItem.templateUuid) {
          const templateProjectIds = currentItem.templateUuid.uuIds;
          templateProjectIds.forEach(i => {
            cmdList.push({
              "invoke": `/api/task/task_template/add?override=${false}&group=${false}`,
              "body": {
                uuId: `@{${idName}}`,
                templateList: [{
                  uuId: i
                }]
              }
            });
          });
        }

        if(currentItem.items && currentItem.items.length > 0) {
          const cloneParent = cloneDeep(data);
          cloneParent.uuId = `@{${idName}}`;
          const err = await _addTask(currentItem.items, cloneParent, projAutoScheduling, true);
          if (err.hasError) {
            taskErrors.push(err);
          }
          
          if (self.inProgressState.cancel) {
            break;
          }
        }
      }
    }
    
    if (self) {
      self.importedItems = items;
    }
    return taskErrors;
  }

  cmdList.push({
       "note":"Enable macro calculations"
      ,"invoke"    : "PUT /api/system/features?entity=macros&action=ENABLE"
  });
  
  if ((!self || !self.inProgressState.cancel)) {
    await compositeService.exec(cmdList)
    .then(response => {
      const feedbackList = response.data.feedbackList;
      if (Array.isArray(feedbackList) && 
            feedbackList.length > 0) {
        for (let c = 0; c < cmdList.length; c++) {
          const cmd = cmdList[c];
          if (cmd.vars) {
            const name = `@{${cmd.vars[0].name}}`;
            const uuId = feedbackList[c].uuId;
            ids[name] = uuId;
            taskMap[name].uuId = uuId;
          }
        }
      }
    })
    .catch((/** e */) => {
      return null;
    });
  }
  cmdList = [];
  
  if (restoreAutoScheduling) {
    project.autoScheduling = true;
    await __updateProjectAutoScheduling(project.uuId, project.autoScheduling, { service: pService });
  }
  return reterrors;
}

/**
 * @param {Array} targetParents contain a list of uuIds. Target parent for the template to be added under it as children. uuId can be task uuId or project uuId.
 * @param {Array} templateProjects contain a list of template project objects with data format { uuId, name }
 * @param {Boolean} override is flag to signal backend whether to clean up the children of the target task before apply new template.
 * @returns Promise with resolve({ status: 'OK' }) and reject(error)
 */
async function applyTaskTemplate(targetParents, templateProjects, override) {
  if (targetParents == null || targetParents.length < 1 || templateProjects == null || templateProjects.length < 1) {
    return Promise.resolve({ status: 'OK' });
  }

  //Call task applyTaskTemplate API
  const templateProjectIds = templateProjects.map(item => item.uuId);
  const appyTemplateRequests = [];
  for (let i = 0, len = targetParents.length; i < len; i++)  {
    appyTemplateRequests.push(taskService.applyTaskTemplate(targetParents[i], templateProjectIds, { override, group: templateProjects.length > 1 }));
  }
  const result = await Promise.all(appyTemplateRequests)
  .then(() => {
    return {}
  })
  .catch(e => {
    return { error: e };
  });

  if (result.error != null) {
    return Promise.reject(result.error);
  }
  
  return Promise.resolve({ status: 'OK' });
}

async function applyTaskTemplateAdv(targetParents, templateProjects, override, { count=1, enableGroup=false } = {}) {
  if (targetParents == null || targetParents.length < 1 || templateProjects == null || templateProjects.length < 1) {
    return Promise.resolve({ status: 'OK' });
  }

  const getRequestTemplate = function(targetId, templateIds, { override=false, group=false, loop=1, times=1 }={}) { 
    const t = {
      note: "Apply Template"
      , method: 'POST'
      , invoke: `/api/task/task_template/add?override=${override}&group=${group}`
      , body: {
          uuId: targetId
          , templateList: templateIds.map(i => { return { uuId: i } })
        }
    }
    if (times > 1) {
      t.repeat = { 
        loop: loop
        , times: times
      }
    }
    return t;
  }

  const disablingRequests = [
    {
        "note": "Disable webhook events",
        "invoke": "PUT /api/system/features?entity=webhooks&action=DISABLE"
    },
    {
        "note": "Disable macro calculations",
        "invoke": "PUT /api/system/features?entity=macros&action=DISABLE"
    },
    {
        "note": "Disable project scheduling",
        "invoke": "PUT /api/system/features?entity=scheduling&action=DISABLE"
    }
  ]

  const templateProjectIds = templateProjects.map(item => item.uuId);
  const group = enableGroup;
  let isOverride = override;
  const requests = [];
  for (const tgtParent of targetParents) {
    let _count = count;
    if (isOverride) {
      //Special handling for override: generate first request with override=true parameter.
      requests.push(getRequestTemplate(tgtParent, templateProjectIds, { override: true, group }));
      _count--;
      isOverride = false;
    }
    if (_count < 1) {
      continue; //move on to next iteration
    }
    requests.push(getRequestTemplate(tgtParent, templateProjectIds, { override: false, group, times: _count }));
  }
  
  if (requests.length > 1) {
    requests.unshift(...disablingRequests);
  }

  const result = await compositeService.exec(requests)
  .then(() => {
    return {}
  })
  .catch(e => {
    return { error: e }
  });

  if (result.error != null) {
    return Promise.reject(result.error);
  }
  
  return Promise.resolve({ status: 'OK' });
}

function __cloneTask(tgtProjectId, srcTaskId, payload, isSrcTemplate=false ,isTgtTemplate=false) {
  return {
    method: 'POST',
    invoke: `/api/${isTgtTemplate? 'template/':'' }task/clone?holder=${tgtProjectId}&reference=${srcTaskId}`,
    body: payload,
    vars: [],
    note: `cloneFrom${isSrcTemplate? 'Template':'' }ProjectTo${isTgtTemplate? 'Template':'' }Project__targetProject(${tgtProjectId})__referenceTask(${srcTaskId})`
  }
}

function __setProjectAutoScheduling(projectId, autoScheduling, isTemplate=false) {
  return {
    method: 'PUT',
    invoke: `/api${isTemplate? '/template':'' }/project/update`,
    body: [{
      uuId: projectId
      , autoScheduling
    }],
    vars: [],
    note: `${isTemplate? 'Template':'' }Task__project(${projectId})__autoSCheduling(${autoScheduling})`
  }
}

/**
 * Task cloning within the projects (Non-template)
 *
 * @param {*} srcTasks Array: source task object array. In each object, uuId is a mandatory property.
 * @param {*} tgtProject Object: target project object. uuId is a mandatory property while autoScheduling is optional but help skipping additional request call.
 * @param {*} tgtParent Object: target task object. uuId is a mandatory property. tgtParent is optional. When null value is provided, tgtProject will be used as fallback.
 */
async function createTasksFromTasksAdv(srcTasks, srcProject, tgtProject, tgtTask=null) {
  return await __createTaskAdv(srcTasks, srcProject, tgtProject, tgtTask)
}

async function createTemplateTasksFromTemplateTasksAdv(srcTasks, srcProject, tgtProject, tgtTask=null) {
  return await __createTaskAdv(srcTasks, srcProject, tgtProject, tgtTask, { isSrcTemplate: true, isTgtTemplate: true })
}

async function createTemplateTasksFromTasksAdv(srcTasks, srcProject, tgtProject) {
  return await __createTaskAdv(srcTasks, srcProject, tgtProject, null, { isSrcTemplate: false, isTgtTemplate: true })
}

async function __createTaskAdv(srcTasks, srcProject, tgtProject, tgtTask, { isSrcTemplate=false, isTgtTemplate=false }={}) {
  //Validate parameters
  if (!Array.isArray(srcTasks) || srcTasks.some(i => i.uuId == null)
      || srcProject == null || srcProject.uuId == null
      || tgtProject == null || tgtProject.uuId == null 
      || (tgtTask != null && tgtTask.uuId == null)) {
    return Promise.resolve({ hasError: true, errorMsg: 'missing_argument' });
  }

  if (srcTasks.length == 0) {
    return Promise.resolve({ hasError: false });
  }

  let _tgtProject = tgtProject;
  if (!Object.hasOwn(tgtProject, 'autoScheduling')) {
    _tgtProject = await __getProject(tgtProject.uuId, isTgtTemplate);
  }

  let _tgtTask = tgtTask == null? tgtProject : tgtTask;
  
  const requests = []
  for (const task of srcTasks) {
    const payload = { parent: _tgtTask.uuId };
    if (task.name != null) {
      payload.name = task.name;
    }
    requests.push(__cloneTask(tgtProject.uuId, task.uuId, payload, isSrcTemplate, isTgtTemplate))
  }

  let hasError = false;
  if (requests.length > 0) {
    const bList = []
    if (requests.length > 250) {
      let batchCount = 0;
      do {
        const newBatch = requests.splice(0, 250);
        //Turn off project autoScheduling, webhook and macro during batch CRUD operation
        // to improve performance.
        if (batchCount == 0 && _tgtProject.autoScheduling) {
          //Only required in the beginning of first batch
          newBatch.unshift(__setProjectAutoScheduling(_tgtProject.uuId, false, isTgtTemplate))
        }
        //Required in the beginning of each and every batch
        newBatch.unshift({
          'note': 'Disable macros',
          'invoke': 'PUT /api/system/features?entity=macros&action=DISABLE'
        });
        newBatch.unshift({
          'note': 'Disable project scheduling',
          'invoke': 'PUT /api/system/features?entity=scheduling&action=DISABLE'
        });

        bList.push(newBatch);
        batchCount+=1;
        
      } while(requests.length > 250);

      if (requests.length > 0) {
        requests.unshift({
          'note': 'Disable macros',
          'invoke': 'PUT /api/system/features?entity=macros&action=DISABLE'
        });
        requests.unshift({
          'note': 'Disable project scheduling',
          'invoke': 'PUT /api/system/features?entity=scheduling&action=DISABLE'
        });
        bList.push(requests);
      }

      if (tgtProject.autoScheduling) {
        //Only required in the last of last batch
        bList[bList.length-1].push(__setProjectAutoScheduling(_tgtProject.uuId, true, isTgtTemplate))
      }
      
    } else {
      if (tgtProject.autoScheduling) {
        requests.unshift(__setProjectAutoScheduling(_tgtProject.uuId, false, isTgtTemplate))
        requests.push(__setProjectAutoScheduling(_tgtProject.uuId, true, isTgtTemplate))
      }
      requests.unshift({
        'note': 'Disable macros',
        'invoke': 'PUT /api/system/features?entity=macros&action=DISABLE'
      });
      requests.unshift({
        'note': 'Disable project scheduling',
        'invoke': 'PUT /api/system/features?entity=scheduling&action=DISABLE'
      });
      bList.push(requests);
    }

    for (const b of bList) {
      await compositeService.exec(b)
      .catch(() => {
        hasError = true;
      });
      if (hasError) {
        break;
      }
    }
  }

  return Promise.resolve({ hasError });
}