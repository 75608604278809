var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.allowManage && _vm.isEditable
    ? _c(
        "div",
        {
          staticClass: "detail-link-cell",
          on: { mouseleave: _vm.onContainerMouseLeave },
        },
        [
          _vm.avatarUrl
            ? _c("img", {
                staticClass: "image",
                attrs: { width: "120", height: 25, src: _vm.avatarUrl },
              })
            : _vm._e(),
          _vm.icon
            ? _c("font-awesome-icon", {
                staticClass: "mr-1",
                style: _vm.iconStyle,
                attrs: { icon: _vm.icon },
              })
            : _vm._e(),
          _vm.isCompany
            ? _c("font-awesome-icon", {
                staticClass: "mr-1",
                style: _vm.iconStyle,
                attrs: { icon: "building" },
              })
            : _vm._e(),
          _vm.tag
            ? _c("div", { staticClass: "tag", class: _vm.tagClass }, [
                _vm._v(_vm._s(_vm.tag)),
              ])
            : _vm._e(),
          _vm.hasError
            ? [
                _c("font-awesome-icon", {
                  staticClass: "mr-1 mb-2",
                  staticStyle: {
                    "vertical-align": "bottom",
                    color: "red",
                    "font-size": "1.125em",
                  },
                  attrs: {
                    id: `CELL_ERROR_${_vm.params.eGridCell.getAttribute(
                      "comp-id"
                    )}`,
                    icon: ["fas", "triangle-exclamation"],
                  },
                }),
                _c("b-popover", {
                  attrs: {
                    target: `CELL_ERROR_${_vm.params.eGridCell.getAttribute(
                      "comp-id"
                    )}`,
                    placement: "top",
                    triggers: "hover",
                    content: _vm.hasErrorContent,
                  },
                }),
              ]
            : _vm._e(),
          _c(
            "a",
            {
              class: { "grid-cell-summary": _vm.isSummaryTask },
              style: _vm.labelStyle,
              attrs: {
                id: `DETAIL_LINK_${_vm.params.eGridCell.getAttribute(
                  "comp-id"
                )}`,
                href: "#",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.invokeParentMethod.apply(null, arguments)
                },
                mouseover: _vm.onMouseOver,
              },
            },
            [_vm._v(_vm._s(_vm.label))]
          ),
          _vm.tooltip
            ? _c(
                "b-popover",
                {
                  attrs: {
                    target: `DETAIL_LINK_${_vm.params.eGridCell.getAttribute(
                      "comp-id"
                    )}`,
                    triggers: "hover",
                    placement: "right",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.label) + " ")]
              )
            : _vm._e(),
          _vm.params &&
          _vm.params.node &&
          _vm.params.node.data &&
          _vm.params.node.data.superUser
            ? _c("font-awesome-icon", {
                staticClass: "ml-1",
                style: _vm.iconStyle,
                attrs: { icon: ["far", "shield"] },
              })
            : _vm._e(),
        ],
        2
      )
    : _c(
        "div",
        [
          _vm.icon
            ? _c("font-awesome-icon", {
                staticClass: "mr-1",
                style: _vm.iconStyle,
                attrs: { icon: _vm.icon },
              })
            : _vm._e(),
          _vm.isCompany
            ? _c("font-awesome-icon", {
                staticClass: "mr-1",
                attrs: { icon: ["far", "apartment"] },
              })
            : _vm._e(),
          _vm.tag
            ? _c("div", { staticClass: "tag", class: _vm.tagClass }, [
                _vm._v(_vm._s(_vm.tag)),
              ])
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.label))]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }