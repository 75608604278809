<template>
  <router-link :to="url" custom>
    <li class="nav-item nav-dropdown" disabled>
      <div class="nav-link nav-dropdown-toggle" @click="handleClick"><i :class="classIcon"></i> {{name}}</div>
      <template v-if="actions">
        <router-link class="float-right" v-for="(action, index) in actions" v-bind:key="index" :to="action.url">
         
        </router-link>
      </template>
      <ul class="nav-dropdown-items">
        <slot></slot>
      </ul>
    </li>
  </router-link>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default: () => { return [] }
    }
  },
  computed: {
    classIcon () {
      return [
        'nav-icon',
        this.icon
      ]
    }
  },
  methods: {
    handleClick (e) {
      e.preventDefault()
      e.target.parentElement.classList.toggle('open')
    }
  }
}
</script>

<style scoped lang="css">
  .nav-link {
    cursor:pointer;
  }
</style>
