var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("SkillBadgeGroupModal", {
    attrs: {
      show: _vm.modalShow,
      skillList: _vm.skillList,
      edgeName: _vm.edgeName,
    },
    on: {
      "update:show": function ($event) {
        _vm.modalShow = $event
      },
      ok: _vm.skillSelectorOk,
      cancel: _vm.skillSelectorCancel,
      skillDeleted: _vm.skillSelectorDeleted,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }