var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    { staticClass: "breadcrumb" },
    [
      _vm._l(_vm.routeRecords, function (routeObject, index) {
        return [
          _vm.hasParam(routeObject) && _vm.isLast(index)
            ? [
                _vm.hasMetaLabel(routeObject)
                  ? [
                      _c(
                        "li",
                        { key: index, staticClass: "breadcrumb-item" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: _vm.getRecordRoutePath(routeObject),
                              },
                            },
                            [_vm._v(_vm._s(_vm.contextName))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        { key: index + "_1", staticClass: "breadcrumb-item" },
                        [
                          _c("span", { staticClass: "active" }, [
                            _vm._v(_vm._s(_vm.getName(routeObject))),
                          ]),
                        ]
                      ),
                    ]
                  : [
                      _c("li", { key: index, staticClass: "breadcrumb-item" }, [
                        _c("span", { staticClass: "active" }, [
                          _vm._v(_vm._s(_vm.contextName)),
                        ]),
                      ]),
                    ],
              ]
            : _vm.getName(routeObject) !== ""
            ? _c(
                "li",
                { key: index, staticClass: "breadcrumb-item" },
                [
                  _vm.hasParam(routeObject) && _vm.isLast(index)
                    ? _c("span", { staticClass: "active" }, [
                        _vm._v(_vm._s(_vm.getName(routeObject))),
                      ])
                    : _vm.isLast(index)
                    ? _c("span", { staticClass: "active" }, [
                        _vm._v(_vm._s(_vm.getName(routeObject))),
                      ])
                    : "" === routeObject.path
                    ? _c("router-link", { attrs: { to: "/" } }, [
                        _vm._v(_vm._s(_vm.getName(routeObject))),
                      ])
                    : _c(
                        "router-link",
                        {
                          attrs: {
                            to: _vm.getRecordRoutePathCustom(routeObject),
                          },
                        },
                        [_vm._v(_vm._s(_vm.getName(routeObject)))]
                      ),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
      _vm.viewName
        ? _c("span", { staticClass: "ml-1" }, [
            _vm._v("/ " + _vm._s(_vm.viewName)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }