var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "aggrid-gantt-container", staticClass: "position-relative" },
    [
      _vm.alertMsg != null && _vm.alertState != "success"
        ? _c("AlertFeedback", {
            attrs: {
              msg: _vm.alertMsg,
              details: _vm.alertMsgDetails.list,
              detailTitle: _vm.alertMsgDetails.title,
              alertState: _vm.alertState,
            },
            on: {
              resetAlert: _vm.resetAlert,
              offsetHeight: _vm.updateAlertOffsetHeight,
            },
          })
        : _vm._e(),
      _c("GanttActionBar", {
        attrs: {
          readOnly: _vm.isAccessDenied,
          allowManage: _vm.allowManage,
          actionProcessing: _vm.actionProcessing,
          disableEdit: _vm.disableEdit,
          disableDelete: _vm.disableDelete,
          disableOutdent: _vm.disableOutdent,
          disableIndent: _vm.disableIndent,
          disableTaskCopy: _vm.disableTaskCopy,
          disableTaskPaste: _vm.disableTaskPaste,
          disableWhenMoreThanOne: _vm.disableWhenMoreThanOne,
          isAccessDenied: _vm.isAccessDenied,
          projectId: _vm.projectId,
          badgeFilters: _vm.badgeFilters,
          disableManualScheduleTask: _vm.disableManualScheduleTask,
          disableAutoScheduleTask: _vm.disableAutoScheduleTask,
          disableManualScheduleProject: _vm.disableManualScheduleProject,
          disableAutoScheduleProject: _vm.disableAutoScheduleProject,
          hasAutoAssignTasks: _vm.hasAutoAssignTasks,
          flatList: _vm.flatList,
          dates: _vm.control.dates,
          startDate: _vm.control.startDate,
          endDate: _vm.control.endDate,
          timescale: _vm.control.timescale,
          criticalPath: _vm.control.criticalPath,
          freeFloat: _vm.control.freeFloat,
          hideOptProjectStartToEnd: _vm.hideOptProjectStartToEnd,
          hideOptProjectSchedule: !_vm.isValidProjectId,
          hideOptTaskSchedule: true,
          isTemplate: _vm.isTemplate,
          hideTaskOutdent: false,
          hideTaskIndent: false,
          hideTaskCopy: false,
          hideTaskPaste: false,
          hideTemplateApply: _vm.isTemplate,
          hideTemplateSave: _vm.isTemplate,
          hideFileImport: _vm.isTemplate,
          hideScheduleMenu: false,
          hideCriticalPath: false,
          hideFreeFloat: false,
          hideAutoAssignTasks: _vm.isTemplate,
          permissionName: _vm.permissionName,
          views: _vm.ganttViews,
          coloring: _vm.coloring,
          expandLevel: _vm.expandLevel,
          maxLevel: _vm.maxLevel,
          filterText: _vm.filterValue,
          customFields: _vm.customFields,
          menuTogglerOffsetTop: _vm.alertOffsetHeight,
        },
        on: {
          "task-new-open": _vm.taskNewHandler,
          "task-edit-open": _vm.taskEditHandler,
          "row-delete": _vm.rowDeleteHandler,
          "task-outdent": _vm.taskOutdentHandler,
          "task-indent": _vm.taskIndentHandler,
          "task-copy": _vm.taskCopyHandler,
          "task-paste": _vm.taskPasteHandler,
          "template-apply": _vm.applyTemplateHandler,
          "template-save": _vm.saveTemplateHandler,
          "file-import": _vm.importDocumentHandler,
          "file-export": _vm.fileExportHandler,
          "all-columns": _vm.showAllColumns,
          "no-columns": _vm.showNoColumns,
          "load-view-settings": _vm.loadViewSettings,
          "copy-columns": _vm.copyColumnSettings,
          "share-columns": _vm.shareColumnSettings,
          "update-columns": _vm.updateColumnSettings,
          "remove-columns": _vm.removeColumnSettings,
          "save-columns": _vm.savePreset,
          "color-change": _vm.onColorChange,
          badgeFilterModified: _vm.onBadgeFilterModified,
          "manual-schedule-task": function ($event) {
            return _vm.scheduleTaskHandler(false)
          },
          "auto-schedule-task": function ($event) {
            return _vm.scheduleTaskHandler(true)
          },
          "manual-schedule-project": function ($event) {
            return _vm.scheduleProjectHandler(false)
          },
          "auto-schedule-project": function ($event) {
            return _vm.scheduleProjectHandler(true)
          },
          "schedule-project-now": _vm.scheduleProjectNowHandler,
          "auto-assign-staff": _vm.autoAssignStaffHandler,
          "toggle-flat-list": _vm.toggleFlatListHandler,
          "update:timescale": function ($event) {
            return _vm.$set(_vm.control, "timescale", $event)
          },
          "update:criticalPath": function ($event) {
            return _vm.$set(_vm.control, "criticalPath", $event)
          },
          "update:critical-path": function ($event) {
            return _vm.$set(_vm.control, "criticalPath", $event)
          },
          "update:freeFloat": function ($event) {
            return _vm.$set(_vm.control, "freeFloat", $event)
          },
          "update:free-float": function ($event) {
            return _vm.$set(_vm.control, "freeFloat", $event)
          },
          datesChanged: _vm.handleControlDates,
          "task-collapse": _vm.taskCollapse,
          "task-expand": _vm.taskExpand,
          startDateChanged: function ($event) {
            return _vm.actionBarDateChanged($event, { isStartDate: true })
          },
          endDateChanged: function ($event) {
            return _vm.actionBarDateChanged($event, { isStartDate: false })
          },
          filterTextChanged: _vm.filterTextChanged,
          showSettings: _vm.showSettings,
        },
      }),
      _c(
        "div",
        {
          ref: "splitter-container",
          staticClass: "splitter-container",
          style: _vm.splitterStyle,
        },
        [
          _c(
            "div",
            { ref: "lhs-grid", staticClass: "lhs-grid" },
            [
              _c("ag-grid-vue", {
                staticClass: "ag-theme-balham task-grid-height",
                style: _vm.lhsGridStyle,
                attrs: {
                  id: "pt-grid",
                  gridOptions: _vm.gridOptions,
                  animateRows: "",
                  autoGroupColumnDef: _vm.autoGroupColumnDef,
                  columnDefs: _vm.columnDefs,
                  context: _vm.context,
                  defaultColDef: _vm.defaultColDef,
                  getMainMenuItems: _vm.getMainMenuItems,
                  noRowsOverlayComponent: "noRowsOverlay",
                  noRowsOverlayComponentParams:
                    _vm.noRowsOverlayComponentParams,
                  overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                  getDataPath: function (data) {
                    return _vm.flatList ? [data.uuId] : data.path
                  },
                  getRowId: function (params) {
                    return params.data.uuId
                  },
                  rowData: _vm.rowData,
                  rowMultiSelectWithClick: false,
                  rowSelection: "multiple",
                  sideBar: false,
                  suppressContextMenu: "",
                  suppressDragLeaveHidesColumns: "",
                  suppressCellFocus: false,
                  suppressClipboardApi: "",
                  singleClickEdit: false,
                  enableRangeSelection: true,
                  enableFillHandle: true,
                  fillOperation: _vm.fillOperation,
                  fillHandleDirection: "xy",
                  processCellForClipboard: _vm.processCellForClipboard,
                  processCellFromClipboard: _vm.processCellFromClipboard,
                  navigateToNextCell: _vm.navigateToNextCell,
                  tabToNextCell: _vm.tabToNextCell,
                  suppressMultiSort: false,
                  suppressScrollOnNewData: "",
                  suppressRowClickSelection: "",
                  suppressRowDrag: !_vm.canEdit(),
                  treeData: "",
                  headerHeight: 34,
                  isGroupOpenByDefault: _vm.isGroupOpenByDefault,
                  enableCellEditingOnBackspace: "",
                },
                on: {
                  "grid-ready": _vm.onGridReady,
                  "row-drag-enter": _vm.onRowDragEnter,
                  "row-drag-end": _vm.onRowDragEnd,
                  "row-drag-move": _vm.onRowDragMove,
                  "row-drag-leave": _vm.onRowDragLeave,
                  "cell-key-down": _vm.onCellKeyDown,
                  "paste-start": _vm.onPasteStart,
                  "paste-end": _vm.onPasteEnd,
                  "cell-focused": _vm.cellFocused,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "resizer",
              staticClass: "resizer",
              attrs: { id: "grid-gantt-resizer" },
              on: { mousedown: _vm.mouseDownHandler },
            },
            [
              _c(
                "div",
                {
                  ref: "resizer-overlay",
                  staticClass: "resizer-overlay",
                  attrs: { id: "resizer-overlay" },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "resizer-icon",
                    attrs: { icon: ["far", "arrows-left-right"] },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { ref: "rhs-chart", staticClass: "rhs-chart" },
            [
              _c("DhtmlxGantt", {
                attrs: {
                  scrollY: _vm.scrollState.top,
                  scrollX: _vm.scrollState.left,
                  height: _vm.ganttHeight,
                  router: _vm.routerObj,
                  ganttData: _vm.ganttData,
                  startDate: _vm.control.startDate,
                  endDate: _vm.control.endDate,
                  timescale: _vm.control.timescale,
                  criticalPath: _vm.control.criticalPath,
                  freeFloat: _vm.control.freeFloat,
                  markerStartText: _vm.marker.projectStart,
                  markerEndText: _vm.marker.projectEnd,
                  markerTodayText: _vm.marker.today,
                  pendingRerender: _vm.pendingRerender,
                  markerStartDate: _vm.project.scheduleStartDate,
                  markerEndDate: _vm.project.scheduleCloseDate,
                  enableProjectColumn: this.projectId == null,
                  enableTaskPathColumn: this.projectId == null,
                  defaultColoring: _vm.coloring.none,
                  hideWeekends: _vm.taskGanttSettings.hideWeekends,
                  weekNumbers: _vm.taskGanttSettings.weekNumbers,
                  weekBase: _vm.taskGanttSettings.weekBase,
                  showMarkerStartText: _vm.marker.showStartText,
                  showMarkerEndText: _vm.marker.showEndText,
                  showMarkerTodayText: _vm.marker.showTodayText,
                  collapseId: _vm.collapseId,
                  expandId: _vm.expandId,
                  collapseIds: _vm.collapseIds,
                  expandIds: _vm.expandIds,
                  selectedTasks: _vm.taskSelectionIds,
                  readOnly:
                    !_vm.canEdit() ||
                    _vm.ganttReadOnly ||
                    _vm.ganttInsufficientEditPermission,
                  calendar: _vm.calendar,
                  filteredIds: _vm.filteredIds,
                  toDeleteTaskIds: _vm.ganttDeleteTaskIds,
                  applyGanttMarker: _vm.signalApplyGanttMarker,
                },
                on: {
                  "update:scrollX": function ($event) {
                    return _vm.$set(_vm.scrollState, "left", $event)
                  },
                  "update:scroll-x": function ($event) {
                    return _vm.$set(_vm.scrollState, "left", $event)
                  },
                  "update:pendingRerender": function ($event) {
                    _vm.pendingRerender = $event
                  },
                  "update:pending-rerender": function ($event) {
                    _vm.pendingRerender = $event
                  },
                  taskNew: _vm.taskNewHandler,
                  taskEdit: _vm.taskEditHandler,
                  taskLinkEdit: _vm.linkEditHandler,
                  ganttScroll: _vm.ganttScrollHandler,
                  taskUpdated: _vm.taskUpdatedHandler,
                  taskLinkUpdated: _vm.taskLinkUpdatedHandler,
                  taskLinkCreated: _vm.taskLinkUpdatedHandler,
                  taskLinkUpdateError: _vm.refreshData,
                  taskLinkCreateError: _vm.refreshData,
                  taskUpdateError: _vm.refreshData,
                  ganttCreated: _vm.createdGantt,
                  taskSelectionChanged: _vm.taskSelectionChangedHandler,
                  taskClicked: _vm.taskClickedHandler,
                  cellFocused: _vm.taskCellFocusedHandler,
                  markerChangedStart: function ($event) {
                    return _vm.markerStateHandler($event, "showStartText")
                  },
                  markerChangedEnd: function ($event) {
                    return _vm.markerStateHandler($event, "showEndText")
                  },
                  markerChangedToday: function ($event) {
                    return _vm.markerStateHandler($event, "showTodayText")
                  },
                  "progress-complete": _vm.progressComplete,
                  "update:applyGanttMarker": function ($event) {
                    _vm.signalApplyGanttMarker = $event
                  },
                  "update:apply-gantt-marker": function ($event) {
                    _vm.signalApplyGanttMarker = $event
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("TaskModal", {
        attrs: {
          mode: "BOTH",
          id: _vm.taskEdit.uuId,
          isTemplate: _vm.isTemplate,
          parentId: _vm.taskEdit.parentId,
          projectId: _vm.project.uuId,
          show: _vm.state.taskShow,
        },
        on: {
          "update:id": function ($event) {
            return _vm.$set(_vm.taskEdit, "uuId", $event)
          },
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "taskShow", $event)
          },
          success: _vm.taskEditSuccess,
        },
      }),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
          cancel: _vm.progressCancel,
        },
      }),
      _c("GanttImportDialog", {
        attrs: { projectId: _vm.projectId, show: _vm.state.importDocumentShow },
        on: { "modal-ok": _vm.importOk, "modal-cancel": _vm.importCancel },
      }),
      _vm.projectId != null && !_vm.isTemplate
        ? [
            _c("TaskTemplateSaveModal", {
              attrs: {
                show: _vm.state.saveTemplateShow,
                taskIds: _vm.saveTemplateEdit.taskIds,
                projectId: _vm.projectId,
              },
              on: {
                "update:show": function ($event) {
                  return _vm.$set(_vm.state, "saveTemplateShow", $event)
                },
                success: _vm.templateSaveSuccess,
              },
            }),
            _vm.state.applyTemplateShow
              ? _c("GenericSelectorModalForAdmin", {
                  attrs: {
                    show: _vm.state.applyTemplateShow,
                    entityService: _vm.templateTaskUtil,
                    entity: "TEMPLATE__PROJECT",
                    nonAdmin: "",
                  },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "applyTemplateShow", $event)
                    },
                    ok: _vm.taskTemplateSelectorOk,
                  },
                })
              : _vm._e(),
            _c("ApplyTemplateConfigModal", {
              attrs: { show: _vm.applyTemplateConfigShow },
              on: {
                "update:show": function ($event) {
                  _vm.applyTemplateConfigShow = $event
                },
                ok: _vm.applyTemplateConfigOk,
                cancel: _vm.applyTemplateConfigCancel,
              },
            }),
          ]
        : _vm._e(),
      _c("TaskLinkModal", {
        attrs: {
          show: _vm.state.taskLinkEditShow,
          taskId: _vm.taskLinkEdit.taskId,
          predecessorId: _vm.taskLinkEdit.predecessorId,
          isTemplate: _vm.isTemplate,
          readOnly: !_vm.canEdit(_vm.permissionName, ["TASK"]),
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "taskLinkEditShow", $event)
          },
          success: _vm.taskLinkEditSuccess,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.auto_assign.summary"),
            "ok-only": "",
            scrollable: "",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.autoAssignSummaryOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.autoAssignSummaryShow,
            callback: function ($$v) {
              _vm.autoAssignSummaryShow = $$v
            },
            expression: "autoAssignSummaryShow",
          },
        },
        [
          _vm.autoAssignSummary.length > 0
            ? _c(
                "ul",
                { staticClass: "task-summary-list" },
                _vm._l(_vm.autoAssignSummary, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "d-block task-summary-list-item",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "task-summary-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.getPathNames(_vm.taskNames[item.taskUUID])
                              )
                            ),
                          ]),
                          item.staffAssignmentList.length > 0
                            ? _vm._l(
                                item.staffAssignmentList,
                                function (staff, sIndex) {
                                  return _c(
                                    "div",
                                    { key: sIndex, staticClass: "d-block" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "task.auto_assign.assigned",
                                              [staff.name]
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                }
                              )
                            : _vm._e(),
                          item.staffAssignmentList.length === 0
                            ? _c("div", { staticClass: "d-block" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("task.auto_assign.none_assigned")
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.autoAssignSummary.length === 0
            ? _c("div", { staticClass: "d-block" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("task.auto_assign.none_assigned")) + " "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptSaveShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.save"),
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptSaveShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptShareShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.share"),
          sharing: true,
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptShareShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c("AutoAssignStaffModal", {
        attrs: {
          projectId: _vm.project ? _vm.project.uuId : null,
          companies: _vm.project.companyList,
          tasks: _vm.autoAssignTasks(),
          show: _vm.autoAssignStaffShow,
        },
        on: {
          "update:show": function ($event) {
            _vm.autoAssignStaffShow = $event
          },
          success: _vm.autoAssignStaffSuccess,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "" +
                      (_vm.selected.length > 1
                        ? "task.confirmation.delete_plural"
                        : "task.confirmation.delete")
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_change_on_complete"),
            "content-class": "change-on-complete-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: { close: _vm.changeOnCompleteCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  _vm.processTaskMoveChangedList.length > 0
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.applyAllChangeOnComplete,
                            callback: function ($$v) {
                              _vm.applyAllChangeOnComplete = $$v
                            },
                            expression: "applyAllChangeOnComplete",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.changeOnCompleteOk()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.changeOnCompleteCancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.confirmChangeOnCompleteShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "confirmChangeOnCompleteShow", $$v)
            },
            expression: "state.confirmChangeOnCompleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("task.confirmation.change_on_complete")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteViewOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteViewShow,
            callback: function ($$v) {
              _vm.confirmDeleteViewShow = $$v
            },
            expression: "confirmDeleteViewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("task.confirmation.delete_view")) + " "),
          ]),
        ]
      ),
      _c("TaskDateTimeDurationCalculation", {
        attrs: {
          show: _vm.durationCalculationShow,
          taskName: _vm.durationCalculation.taskName,
          defaultActionForNonWorkPrompt:
            _vm.durationCalculation.defaultActionForNonWorkPrompt,
          enableManualScheduleSuggestion:
            _vm.durationCalculation.enableManualScheduleSuggestion,
          skipOutOfProjectDateCheck:
            _vm.durationCalculation.skipOutOfProjectDateCheck,
          showApplyAllCheckbox: "",
          trigger: _vm.durationCalculation.trigger,
          startDateStr: _vm.durationCalculation.startDateStr,
          startTimeStr: _vm.durationCalculation.startTimeStr,
          closeDateStr: _vm.durationCalculation.closeDateStr,
          closeTimeStr: _vm.durationCalculation.closeTimeStr,
          durationDisplay: _vm.durationCalculation.durationDisplay,
          calendar: _vm.durationCalculation.calendar,
          projectScheduleFromStart:
            _vm.durationCalculation.projectScheduleFromStart,
          taskAutoScheduleMode: _vm.durationCalculation.taskAutoScheduleMode,
          constraintType: _vm.durationCalculation.constraintType,
          constraintDateStr: _vm.durationCalculation.constraintDateStr,
          lockDuration: _vm.durationCalculation.lockDuration,
          oldDateStr: _vm.durationCalculation.oldDateStr,
          oldTimeStr: _vm.durationCalculation.oldTimeStr,
          projectStartDateStr: _vm.durationCalculation.projectStartDateStr,
          projectCloseDateStr: _vm.durationCalculation.projectCloseDateStr,
          resizeMode: _vm.durationCalculation.resizeMode,
          oldConstraintType: _vm.durationCalculation.oldConstraintType,
          oldConstraintDateStr: _vm.durationCalculation.oldConstraintDateStr,
          clearPreviousChoice: _vm.resetDurationCalculationState,
          durationConversionOpts: _vm.durationConversionOpts,
        },
        on: {
          "update:show": function ($event) {
            _vm.durationCalculationShow = $event
          },
          "update:calendar": function ($event) {
            return _vm.$set(_vm.durationCalculation, "calendar", $event)
          },
          success: _vm.durationCalculationOk,
          skip: function ($event) {
            return _vm.durationCalculationOk({ skip: true })
          },
          cancel: _vm.durationCalculationCancel,
          calendarChange: _vm.durationCalculationCalendarChange,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "task-delete-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.tcConfirmDeleteShow = false
            },
            ok: _vm.tcConfirmDeleteOk,
            cancel: _vm.tcConfirmDeleteCancel,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.tcShowApplyAllCheckbox
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.taskCol.applyAll,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskCol, "applyAll", $$v)
                            },
                            expression: "taskCol.applyAll",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tcConfirmDeleteShow,
            callback: function ($$v) {
              _vm.tcConfirmDeleteShow = $$v
            },
            expression: "tcConfirmDeleteShow",
          },
        },
        [_c("p", [_vm._v(_vm._s(_vm.tcConfirmDeleteStatement))])]
      ),
      _c("TaskGanttSettingsModal", {
        attrs: { show: _vm.settingsShow, settings: _vm.taskGanttSettings },
        on: {
          "update:show": function ($event) {
            _vm.settingsShow = $event
          },
          ok: _vm.taskGanttSettingsOk,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }