<template>
  <span>{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'TaskSkillCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    const list = Array.isArray(this.params.value)? this.params.value: [];
    const skills = [];
    for(const i of list) {
      skills.push(i.level? `${i.name} (${i.level})`: i.name);
    }
    this.value = skills.join(', ');
  },
  computed: {
    label() {
      return this.value && this.value.length > 0? this.value : '';
    }
  }
})
</script>
