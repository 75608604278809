var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SidebarNavItem", { attrs: { classes: _vm.classList.navItem } }, [
    _c(
      "a",
      { class: _vm.classList.navLink, attrs: { href: _vm.url } },
      [
        _c("font-awesome-icon", { attrs: { icon: "icon" } }),
        _vm._v(" " + _vm._s(_vm.name) + " "),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }