import { projectService
  , projectLinkTagService, projectLinkRebateService, projectLinkCustomerService
  , projectLinkLocationService, projectLinkStageService
  , projectLinkStatusService } from '@/services'
import { addTags, objectClone, costFormat, costFormatAdv } from '@/helpers'
import { scheduleMode } from '@/selectOptions'
import { toFixed, convertDisplayToDuration } from '@/helpers/task-duration-process'
import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly, lackOfMandatoryField } from './common'
import { prepareCustomFieldColumnDef } from '@/helpers/custom-fields'
import * as moment from 'moment-timezone'
moment.tz.setDefault('Etc/UTC')

const parseCost = (val) => {
  if (typeof val === 'undefined') {
    return null;
  }
  
  if (val.indexOf('$') === 0) {
    val = val.substr(1);
  }
  
  var num = parseFloat(val);
  if (val.includes('k')) {
    num = num * 1000;
  }
  return `${num}`;
}

const autoSchedulingOptions = (self) => {
  return [
    { value: true, text: self.$t('project.autoschedule.auto') }
    , { value: false, text: self.$t('project.autoschedule.manual') }
  ]
}


export const projectUtil = {
  list: (bParams, { self }={}) => {
    return projectService.listv2(bParams, self?.customFields)
  }
  , listNames: projectService.listNames
  , remove: projectService.remove
  , clone: projectService.clonev2
  , importDataFunc: (self) => {
    return async (item, errorFunc) => {
      const starttime = typeof item.starttime === 'string' ? moment.utc(item.starttime, 'YYYY-MM-DD').valueOf() : item.starttime
      const closetime = typeof item.closetime === 'string' ? moment.utc(item.closetime, 'YYYY-MM-DD').valueOf() : item.closetime
      let method = 'create';
      
      const data = {
        name: item.name
        , autoScheduling: true
        , scheduleStart: item.starttime != null && starttime > 0 ? starttime : null
        , scheduleFinish: item.closetime != null && closetime > 0 ? closetime : null
        , scheduleFrom: item.scheduleFrom !== null ? item.scheduleFrom : null
        , priority: item.priority
        , currencyCode: item.currencycode
        , description: item.description
        , fixedCost: item.fixedCost ? parseCost(item.fixedCost) : null
        , identifier: item.identifier
        , color: item.color
        , fixedDuration: convertDisplayToDuration(item.fixedDuration, self?.durationConversionOpts != null? self.durationConversionOpts : {}).value
      }

      if (self.customFields) {
        for (const cfield of self.customFields) {
          if (item[cfield.name]) {
            data[cfield.name] = item[cfield.name];
          }
        }
      }
      
      if (item.uuId) {
        data.uuId = item.uuId;
        method = 'update';
      }
      const result = await projectService[method]([data])
      .then(response => {
        const feedbackList = response.data.feedbackList
        if (Array.isArray(feedbackList) && 
              feedbackList.length > 0 && 
              feedbackList[0].uuId != null) {
          return feedbackList[0].uuId
        }
      })
      .catch((e) => {
        errorFunc(e)
        return null
      })

      if (result) {
        if (item.location && item.location.uuId) {
          if (data.uuId) {
            
            // unlink the previous location
            const locationId = await projectService.get([{ uuId: data.uuId}], ['LOCATION']).then((response) => {
              return response.data.objectList[0].locationList[0].uuId;
            })
            
            await projectLinkLocationService.remove(data.uuId, [locationId]).catch(() => {
              // fail silently
            });
          }

          await projectLinkLocationService.create(result, [item.location.uuId]).catch(() => {
            // fail silently
          });
        }
    
        if (item.tag) {
          await addTags(result, item.tag.split(',').map(t => { return { name: t.trim() }}), projectLinkTagService).catch(() => {
            // fail silently
          });
        }
    
        if (item.customer && item.customer.uuId !== null) {
          await projectLinkCustomerService.create(result, [item.customer]).catch(() => {
            // fail silently
          });
        }
        
        if (item.rebates && item.rebates.length > 0 && item.rebates[0].uuId !== null) {
          await projectLinkRebateService.create(result, item.rebates).catch(() => {
            // fail silently
          });
        }
        
        if (item.stages && item.stages.length > 0 && item.stages[0].uuId !== null) {
          await projectLinkStageService.create(result, item.stages).catch(() => {
            // fail silently
          });
        }
        
        if (item.status && item.status.uuId !== null) {
          await projectLinkStatusService.create(result, [item.status]).catch(() => {
            // fail silently
          });
        }
      }
    }
  }
  , buildParams: ({ request: {sortModel, endRow, startRow} }, { exportData=false, searchFilter=null, self=null, badgeFilters=null, projectIds=null }={}) => {
    const params = {
      start: !exportData ? startRow : 0
      , limit: !exportData ? endRow - startRow + 1 : -1
      , ksort: []
      , order: []
      , filter: searchFilter
      , badgeFilters
    }
    
    for(let i = 0, len = sortModel.length; i < len; i++) {
      if (sortModel[i].colId === 'uuId') {
        params.ksort.push('name');
      } else if (sortModel[i].colId === 'skills') {
        params.ksort.push('skillName');
      } else if (sortModel[i].colId === 'locations') {
        params.ksort.push('locationName');
      } else if (sortModel[i].colId === 'customers') {
        params.ksort.push('customerName');
      } else if (sortModel[i].colId === 'companies') {
        params.ksort.push('companyName');
      } else if (sortModel[i].colId === 'rebates') {
        params.ksort.push('rebateName');
      } else if (sortModel[i].colId === 'progressPercent') {
        params.ksort.push('progress');
      } else if (sortModel[i].colId === 'status') {
        params.ksort.push('statusName')
      } else {
        params.ksort.push(sortModel[i].colId);
      }
      params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr')
    }

    if (self != null && typeof self.getCompanyRule === 'function') {
      const companyrule = self.getCompanyRule('PROJECT.COMPANY.uuId');
      if (companyrule) {
        if (params.filter == null || params.filter == '') {
          params.filter = [companyrule]
        }
        else {
          params.filter = [
            '_and_'
            , [
              ['PROJECT.name', 'regex', searchFilter]
              , companyrule
            ]
          ];
        }
      }
    }
    
    if (Array.isArray(projectIds) != null && projectIds.length > 0) {
      const rule = ['PROJECT.uuId', 'within', projectIds.join('|')];
      if (params.filter == null || params.filter === '') {
        params.filter = [rule];
      } else {
        params.filter.push(rule);
      }
    }
    
    return params
  }
  , getColumnDefs: (self) => {
    const priorityOpts = self.enumList.GanttPriorityEnum
    const currencies = self.enumList.CurrencyEnum
    const scheduleFromOpts = scheduleMode(self)
    const autoSchedulingOpts = autoSchedulingOptions(self)
    const colDefs = [
      {
        headerName: self.$t('field.avatar'),
        field: 'avatarRef',
        minWidth: self.nonAdmin? 35: 26,
        maxWidth: self.nonAdmin? 35: 26,
        hide: false,
        headerComponent: 'blankHeaderComponent',
        cellRenderer: 'avatarCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: (params) => !self.nonAdmin && params.data.readOnly != true,
          nonAdmin: self.nonAdmin
        },
        pinned: 'left',
        lockPosition: 'left',
        menuTabs: [],
        editable: false
      }
      , {
        headerName: self.$t('project.field.name')
        , field: 'uuId'
        , cellRenderer: 'detailLinkCellRenderer'
        , cellRendererParams: {
          ignoreAvatar: true
          , enableReadonlyStyle: self.nonAdmin? false : null //set null to make the setReadOnlyIfNotEditable() logic not to consider it
        }
        , cellEditor: 'nameEditor'
        , cellEditorParams: {
          isOptional: false
        }
        , checkboxSelection: false
        , pinned: 'left'
        , lockPosition: 'left'
        , lockVisible: true
        , minWidth: 200
        , hide: false
        , sort: 'asc'
        , editable: params => self.canEdit('PROJECT', ['name']) && params.data.readOnly != true
        , valueSetter: function(params) {
          const newValue = params.newValue.trim()
          const oldValue = objectClone(params.data.name)
          if (newValue !== '' && newValue != oldValue) {
            self.$set(params.data, 'oldName', oldValue)
            params.data.name = newValue
            return true
          }
          return false
        }
      },
      {
        headerName: self.$t('project.field.customer')
        , field: 'customers'
        , cellRenderer: 'genericEntityArrayCellRenderer'
        
        , hide: false
        , cellEditor: 'customersEditor'
        , cellEditorParams: {
          isOptional: false
        }
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('project.field.progress')
        , field: 'progressPercent'
        , cellRenderer: 'genericCellRenderer'
        , hide: false
      },
      {
        headerName: self.$t('project.field.scheduleStart')
        , field: 'scheduleStart'
        , cellRenderer: 'dateOnlyCellRenderer'
        , cellEditor: 'dateTimeEditor'
        , cellEditorParams: {
          editorMode: 1
          , displayMode: 'date'
          , labelDate: self.$t('label.editDate')
          , treatAsNull: 0
          , optional: true
        }
        , editable: params => params.data.readOnly != true
        , hide: true
      },
      {
        headerName: self.$t('project.field.scheduleFinish')
        , field: 'scheduleFinish'
        , cellRenderer: 'dateOnlyCellRenderer'
        , cellEditor: 'dateTimeEditor'
        , cellEditorParams: {
          editorMode: 1
          , displayMode: 'date'
          , labelDate: self.$t('label.editDate')
          , treatAsNull: 0
          , optional: true
        }
        , editable: params => params.data.readOnly != true
        , hide: true
      },
      {
        headerName: self.$t('project.field.estimatedDuration')
        , field: 'estimatedDuration'
        , cellRenderer: 'durationCellRenderer'
        , cellRendererParams: {
          unit: 'days'
          , enableReadonlyStyle: self.nonAdmin? false : null //set null to make the setReadOnlyIfNotEditable() logic not to consider it
        }
        , hide: true
      },
      {
        headerName: self.$t('project.field.fixedDuration')
        , field: 'fixedDuration'
        , cellRenderer: 'durationCellRenderer'
        , cellRendererParams: {
          unit: 'days'
          , enableReadonlyStyle: self.nonAdmin? false : null //set null to make the setReadOnlyIfNotEditable() logic not to consider it
        }
        , editable: params => params.data.readOnly != true
        , hide: true
      },
      {
        headerName: self.$t('project.field.company')
        , field: 'companies'
        , cellRenderer: 'genericEntityArrayCellRenderer'
        , cellEditor: 'companiesEditor'
        , cellEditorParams: {
          isOptional: false
        }
        , editable: params => params.data.readOnly != true
        , hide: true
      },
      {
        headerName: self.$t('project.field.location')
        , field: 'locations'
        , cellRenderer: 'genericEntityArrayCellRenderer'
        , cellEditor: 'locationsEditor'
        , editable: params => params.data.readOnly != true
        , hide: true
      },
      {
        headerName: self.$t('project.field.priority')
        , field: 'priority'
        , cellEditor: 'listEditor'
        , cellEditorParams: { options: priorityOpts, isEnumType: true }
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('project.field.autoScheduling')
        , field: 'autoScheduling'
        , hide: true
        , cellEditor: 'listEditor'
        , cellEditorParams: { options: autoSchedulingOpts }
        , cellRenderer: 'enumCellRenderer'
        , cellRendererParams: {  
          options: autoSchedulingOpts
          , enableReadonlyStyle: self.nonAdmin? false : true 
        }
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('project.field.scheduleMode')
        , field: 'scheduleMode'
        , hide: true
        , cellEditor: 'listEditor'
        , cellEditorParams: { options: scheduleFromOpts }
        , cellRenderer: 'enumCellRenderer'
        , cellRendererParams: {  
          options: scheduleFromOpts
          , enableReadonlyStyle: self.nonAdmin? false : true 
        }
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('project.field.currencyCode')
        , field: 'currencyCode'
        , hide: true
        , cellEditor: 'listEditor'
        , cellEditorParams: { options: currencies, isEnumType: true }
        , cellRenderer: 'enumCellRenderer'
        , cellRendererParams: {  
          options: currencies
          , enableReadonlyStyle: self.nonAdmin? false : true 
        }
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('project.field.fixedCost')
        , field: 'fixedCost'
        , cellRenderer: 'costCellRenderer'
        , cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        }
        , cellEditor: 'costEditor'
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('project.field.fixedCostNet')
        , field: 'fixedCostNet'
        , cellRenderer: 'costCellRenderer'
        , cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        }
        , hide: true
      },
      {
        headerName: self.$t('project.field.actualCost')
        , field: 'actualCost'
        , cellRenderer: 'costCellRenderer'
        , cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        }
        , hide: true
      },
      {
        headerName: self.$t('project.field.actualCostNet')
        , field: 'actualCostNet'
        , cellRenderer: 'costCellRenderer'
        , cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        }
        , hide: true
      },
      {
        headerName: self.$t('project.field.estimatedCost')
        , field: 'estimatedCost'
        , cellRenderer: 'costCellRenderer'
        , cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        }
        , hide: true
      },
      {
        headerName: self.$t('project.field.estimatedCostNet')
        , field: 'estimatedCostNet'
        , cellRenderer: 'costCellRenderer'
        , cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        }
        , hide: true
      },
      {
        headerName: self.$t('project.field.rebates')
        , field: 'rebates'
        , cellRenderer: 'genericEntityArrayCellRenderer'
        , cellRendererParams: {
          labelFormatter: (params) => {
            if (!Array.isArray(params.value) || params.value.length == 0) {
              return { value: [] }
            }
            params.value.forEach(i => {
              if (!isNaN(i.rebate)) {
                i.label = `${i.name} (${toFixed(i.rebate * 100, 0)}%)`
              }
            })
            return { value: params.value }
          }
        }
        , cellEditor: 'rebatesEditor'
        , editable: params => params.data.readOnly != true
        , hide: true
      },
      {
        headerName: self.$t('project.field.status')
        , field: 'status'
        , cellRenderer: 'genericObjectCellRenderer'
        , hide: true
        , cellEditor: 'statusEditor'
        , cellEditorParams: {
          filterEntity: 'PROJECT'
          , filterTagProperty: 'tag'
        }
        , editable: params => params.data.readOnly != true
      },
      // {
      //   headerName: self.$t('project.field.stages'),
      //   field: 'stages',
      //   hide: true
      // },
      {
        headerName: self.$t('project.field.description')
        , field: 'description'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'multilineEditor'
        , cellEditorParams: { title: self.$t('task.edit_description') }
        , hide: false
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('field.tag')
        , field: 'tag'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'tagEditor'
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('field.color')
        , field: 'color'
        , cellRenderer: 'colorCellRenderer'
        , cellEditor: 'colorEditor'
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('field.identifier_full')
        , field: 'identifier'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      }
    ]
    prepareCustomFieldColumnDef(colDefs, self.customFields, { self });

    const linkedEntities = [
        { selector: 'PROJECT.TAG', field: 'tag', properties: ['name'] }
      , { selector: 'PROJECT.COMPANY', field: 'companies', properties: ['name', 'PROJECT'] }
      , { selector: 'PROJECT.LOCATION', field: 'locations', properties: ['name'] }
      , { selector: 'PROJECT.CUSTOMER', field: 'customers', properties: ['name'] }
      , { selector: 'PROJECT.REBATE', field: 'rebates', properties: ['name'] }
      , { selector: 'PROJECT.STAGE', field: 'status', properties: ['name']}
      
    ]

    const viewLinkedEntities = JSON.parse(JSON.stringify(linkedEntities))
    viewLinkedEntities.push(...[
      { selector: 'PROJECT', field: 'fixedCost', properties: ['currencyCode'] }
      , { selector: 'PROJECT', field: 'fixedCostNet', properties: ['currencyCode'] }
      , { selector: 'PROJECT', field: 'actualCost', properties: ['currencyCode'] }
      , { selector: 'PROJECT', field: 'actualCostNet', properties: ['currencyCode'] }
      , { selector: 'PROJECT', field: 'estimatedCost', properties: ['currencyCode'] }
      , { selector: 'PROJECT', field: 'estimatedCostNet', properties: ['currencyCode'] }
    ])

    //VIEW permission: Remove column from display list
    filterOutViewDenyProperties(colDefs, 'PROJECT', viewLinkedEntities)

    if (self.isEntityEditable) {
      //EDIT permission: set column to be read only.
      setEditDenyPropertiesReadOnly(colDefs, 'PROJECT', linkedEntities)  
    } else {
      for (let i = 0, len = colDefs.length; i < len; i++) {
        colDefs[i].editable = false;
      }
    }
    return colDefs
  }
  , getColorMenuOptions: () => ({
    none: true
    , project: false
    , company: false
    , location: false
    , customer: false
    , status: false
    , rebate: false
  })
  , getImportDataProperties: (self) => [
    { value: 'color', text: self.$t('field.color') }
    , { value: 'identifier', text: self.$t('field.identifier') }
    , { value: 'name', text: self.$t('project.field.name') }
    , { value: 'description', text: self.$t('project.field.description') }
    , { value: 'tag', text: self.$t('field.tag') }
    , { value: 'company', text: self.$t('project.field.company') }
    , { value: 'currencycode', text: self.$t('project.field.currencyCode') }
    , { value: 'customer', text: self.$t('project.field.customer') }
    , { value: 'closetime', text: self.$t('project.field.scheduleFinish') }
    , { value: 'fixedCost', text: self.$t('project.field.fixedCost') }
    , { value: 'fixedDuration', text: self.$t('project.field.fixedDuration') }
    , { value: 'location', text: self.$t('project.field.location') }
    , { value: 'priority', text: self.$t('project.field.priority') }
    , { value: 'rebates', text: self.$t('task.field.rebates') }
    , { value: 'schedulemode', text: self.$t('project.field.scheduleMode') }
    , { value: 'stages', text: self.$t('project.field.stages') }
    , { value: 'starttime', text: self.$t('project.field.scheduleStart') }
    , { value: 'status', text: self.$t('project.field.status') }
  ]
  , entityUpdateApiUrl: '/api/project/update'
  , entityDeleteApiUrl: '/api/project/delete'
  , getValueChangedHandler: (/** self */) => {
   
    return {
      customers: {
        isAsync: false
        , execute: (entityId, oldVal, newValue) => {
          const oldList = Array.isArray(oldVal)? oldVal.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const list = Array.isArray(newValue)? newValue.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const toAdd = []
          const toUpdate = []
          const unchangedIds = []

          for(const item of list) {
            const index = oldList.findIndex(j => j.uuId === item.uuId)
            if(index == -1) {
              toAdd.push(item)
            } else {
              unchangedIds.push(item.uuId)
            }
          }

          const toAddIds = toAdd.map(i => i.uuId)
          const toUpdateIds = toUpdate.map(i => i.uuId)
          const toRemove = oldList.filter(i => !toAddIds.includes(i.uuId) 
                                              && !toUpdateIds.includes(i.uuId) 
                                              && !unchangedIds.includes(i.uuId))
          const requests = []

          if(toAdd.length > 0) {
            const addCustomerLinkReqTemplate = function(refId, projectId, customerList) {
              const list = [];
              customerList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/project/link/customer/add`,
                body: { 
                  uuId: projectId,
                  customerList: list
                },
                vars: [],
                note: `projectAddCustomerLink__${refId}__${customerList[0].uuId}`
              }
            }
            for (const [index, customer] of toAdd.entries()) {
              requests.push(addCustomerLinkReqTemplate(`${index}_${entityId}`, entityId, [customer]));
            }
          }

          if(toUpdate.length > 0) {
            const updateCustomerLinkReqTemplate = function(refId, projectId, customerList) {
              const list = [];
              customerList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/project/link/customer/update`,
                body: { 
                  uuId: projectId,
                  customerList: list
                },
                vars: [],
                note: `projectUpdateCustomerLink__${refId}__${customerList[0].uuId}`
              }
            }
            for (const [index, customer] of toUpdate.entries()) {
              requests.push(updateCustomerLinkReqTemplate(`${index}_${entityId}`, entityId, [customer]));
            }
          }

          if(toRemove.length > 0) {
            const removeCustomerLinkReqTemplate = function(refId, projectId, customerList) {
              const list = [];
              customerList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/project/link/customer/delete`,
                body: { 
                  uuId: projectId,
                  customerList: list
                },
                vars: [],
                note: `projectRemoveCustomerLink__${refId}__${customerList[0].uuId}`
              }
            }
            for (const [index, customer] of toRemove.entries()) {
              requests.push(removeCustomerLinkReqTemplate(`${index}_${entityId}`, entityId, [customer]));
            }
          }

          if (requests.length > 0) {
            return {
              value: requests
              , status: 'SUCCESS'
            }
          }

          return {
            value: oldVal
            , status: 'ABORT'
            , property: 'customers'
          }
        }
      }
      , companies: {
        isAsync: false
        , execute: (entityId, oldVal, newValue) => {
          const oldList = Array.isArray(oldVal)? oldVal.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const list = Array.isArray(newValue)? newValue.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const toAdd = []
          const toUpdate = []
          const unchangedIds = []

          for(const item of list) {
            const index = oldList.findIndex(j => j.uuId === item.uuId)
            if(index == -1) {
              toAdd.push(item)
            } else {
              unchangedIds.push(item.uuId)
            }
          }

          const toAddIds = toAdd.map(i => i.uuId)
          const toUpdateIds = toUpdate.map(i => i.uuId)
          const toRemove = oldList.filter(i => !toAddIds.includes(i.uuId) 
                                              && !toUpdateIds.includes(i.uuId) 
                                              && !unchangedIds.includes(i.uuId))
          const requests = []

          if(toAdd.length > 0) {
            const addCompanyLinkReqTemplate = function(refId, companyId, projectList) {
              const list = [];
              projectList.forEach(i => {
                list.push( {
                  uuId: i
                });
              });
              return {
                method: 'POST',
                invoke: `/api/company/link/project/add`,
                body: { 
                  uuId: companyId,
                  projectList: list
                },
                vars: [],
                note: `companyAddProjectLink__${refId}__${projectList[0]}`
              }
            }
            for (const [index, company] of toAdd.entries()) {
              requests.push(addCompanyLinkReqTemplate(`${index}_${company.uuId}`, company.uuId, [entityId]));
            }
          }

          if(toUpdate.length > 0) {
            const updateCompanyLinkReqTemplate = function(refId, companyId, projectList) {
              const list = [];
              projectList.forEach(i => {
                list.push( {
                  uuId: i
                });
              });
              return {
                method: 'POST',
                invoke: `/api/company/link/project/update`,
                body: { 
                  uuId: companyId,
                  projectList: list
                },
                vars: [],
                note: `companyUpdateProjectLink__${refId}__${projectList[0]}`
              }
            }
            for (const [index, company] of toUpdate.entries()) {
              requests.push(updateCompanyLinkReqTemplate(`${index}_${company.uuId}`, company.uuId, [entityId]));
            }
          }

          if(toRemove.length > 0) {
            const removeCompanyLinkReqTemplate = function(refId, companyId, projectList) {
              const list = [];
              projectList.forEach(i => {
                list.push( {
                  uuId: i
                });
              });
              return {
                method: 'POST',
                invoke: `/api/company/link/project/delete`,
                body: { 
                  uuId: companyId,
                  projectList: list
                },
                vars: [],
                note: `companyRemoveProjectLink__${refId}__${projectList[0]}`
              }
            }
            for (const [index, company] of toRemove.entries()) {
              requests.push(removeCompanyLinkReqTemplate(`${index}_${company.uuId}`, company.uuId, [entityId]));
            }
          }

          if (requests.length > 0) {
            return {
              value: requests
              , status: 'SUCCESS'
            }
          }

          return {
            value: oldVal
            , status: 'ABORT'
            , property: 'companies'
          }
        }
      }
      , locations: {
        isAsync: false
        , execute: (entityId, oldVal, newValue) => {
          const oldList = Array.isArray(oldVal)? oldVal.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const list = Array.isArray(newValue)? newValue.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const toAdd = []
          const toUpdate = []
          const unchangedIds = []

          for(const item of list) {
            const index = oldList.findIndex(j => j.uuId === item.uuId)
            if(index == -1) {
              toAdd.push(item)
            } else {
              unchangedIds.push(item.uuId)
            }
          }

          const toAddIds = toAdd.map(i => i.uuId)
          const toUpdateIds = toUpdate.map(i => i.uuId)
          const toRemove = oldList.filter(i => !toAddIds.includes(i.uuId) 
                                              && !toUpdateIds.includes(i.uuId) 
                                              && !unchangedIds.includes(i.uuId))
          const requests = []

          if(toAdd.length > 0) {
            const addLocationLinkReqTemplate = function(refId, projectId, locationList) {
              const list = [];
              locationList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/project/link/location/add`,
                body: { 
                  uuId: projectId,
                  locationList: list
                },
                vars: [],
                note: `projectAddLocationLink__${refId}__${locationList[0].uuId}`
              }
            }
            for (const [index, location] of toAdd.entries()) {
              requests.push(addLocationLinkReqTemplate(`${index}_${entityId}`, entityId, [location]));
            }
          }

          if(toUpdate.length > 0) {
            const updateLocationLinkReqTemplate = function(refId, projectId, locationList) {
              const list = [];
              locationList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/project/link/location/update`,
                body: { 
                  uuId: projectId,
                  locationList: list
                },
                vars: [],
                note: `projectUpdateLocationLink__${refId}__${locationList[0].uuId}`
              }
            }
            for (const [index, location] of toUpdate.entries()) {
              requests.push(updateLocationLinkReqTemplate(`${index}_${entityId}`, entityId, [location]));
            }
          }

          if(toRemove.length > 0) {
            const removeLocationLinkReqTemplate = function(refId, projectId, locationList) {
              const list = [];
              locationList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/project/link/location/delete`,
                body: { 
                  uuId: projectId,
                  locationList: list
                },
                vars: [],
                note: `projectRemoveLocationLink__${refId}__${locationList[0].uuId}`
              }
            }
            for (const [index, location] of toRemove.entries()) {
              requests.push(removeLocationLinkReqTemplate(`${index}_${entityId}`, entityId, [location]));
            }
          }

          if (requests.length > 0) {
            return {
              value: requests
              , status: 'SUCCESS'
            }
          }

          return {
            value: oldVal
            , status: 'ABORT'
            , property: 'locations'
          }
        }
      }
      , rebates: {
        isAsync: false
        , execute: (entityId, oldVal, newValue) => {
          const oldList = Array.isArray(oldVal)? oldVal.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const list = Array.isArray(newValue)? newValue.map(i => { return { uuId: i.uuId, name: i.name } } ) : []
          const toAdd = []
          const toUpdate = []
          const unchangedIds = []

          for(const item of list) {
            const index = oldList.findIndex(j => j.uuId === item.uuId)
            if(index == -1) {
              toAdd.push(item)
            } else {
              unchangedIds.push(item.uuId)
            }
          }

          const toAddIds = toAdd.map(i => i.uuId)
          const toUpdateIds = toUpdate.map(i => i.uuId)
          const toRemove = oldList.filter(i => !toAddIds.includes(i.uuId) 
                                              && !toUpdateIds.includes(i.uuId) 
                                              && !unchangedIds.includes(i.uuId))
          const requests = []

          if(toAdd.length > 0) {
            const addRebateLinkReqTemplate = function(refId, projectId, rebateList) {
              const list = [];
              rebateList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/project/link/rebate/add`,
                body: { 
                  uuId: projectId,
                  rebateList: list
                },
                vars: [],
                note: `projectAddRebateLink__${refId}__${rebateList[0].uuId}`
              }
            }
            for (const [index, rebate] of toAdd.entries()) {
              requests.push(addRebateLinkReqTemplate(`${index}_${entityId}`, entityId, [rebate]));
            }
          }

          if(toUpdate.length > 0) {
            const updateRebateLinkReqTemplate = function(refId, projectId, rebateList) {
              const list = [];
              rebateList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/project/link/rebate/update`,
                body: { 
                  uuId: projectId,
                  rebateList: list
                },
                vars: [],
                note: `projectUpdateRebateLink__${refId}__${rebateList[0].uuId}`
              }
            }
            for (const [index, rebate] of toUpdate.entries()) {
              requests.push(updateRebateLinkReqTemplate(`${index}_${entityId}`, entityId, [rebate]));
            }
          }

          if(toRemove.length > 0) {
            const removeRebateLinkReqTemplate = function(refId, projectId, rebateList) {
              const list = [];
              rebateList.forEach(i => {
                list.push( {
                  uuId: i.uuId
                });
              });
              return {
                method: 'POST',
                invoke: `/api/project/link/rebate/delete`,
                body: { 
                  uuId: projectId,
                  rebateList: list
                },
                vars: [],
                note: `projectRemoveRebateLink__${refId}__${rebateList[0].uuId}`
              }
            }
            for (const [index, rebate] of toRemove.entries()) {
              requests.push(removeRebateLinkReqTemplate(`${index}_${entityId}`, entityId, [rebate]));
            }
          }

          if (requests.length > 0) {
            return {
              value: requests
              , status: 'SUCCESS'
            }
          }

          return {
            value: oldVal
            , status: 'ABORT'
            , property: 'rebates'
          }
        }
      }
      , status: {
        isAsync: false
        , execute: (entityId, oldVal, newValue) => {
          const oldObj = oldVal != null? { uuId: oldVal.id, name: oldVal.name } : { uuId: null, name: null }
          const newObj = newValue != null? { uuId: newValue.id, name: newValue.name } : { uuId: null, name: null }

          let toAdd = null
          let toRemove = null
          
          if (oldObj.uuId == null && newObj.uuId != null) {
            toAdd = newObj
          } else if (oldObj.uuId != null && newObj.uuId == null) {
            toRemove = oldObj
          } else if (oldObj.uuId != null && oldObj.uuId != newObj.uuId) {
            toAdd = newObj
            toRemove = oldObj
          }

          const requests = []

          //Order matters: First remove and later add
          if(toRemove != null) {
            const removeStageLinkReqTemplate = function(refId, projectId, stageObj) {
              return {
                method: 'POST',
                invoke: `/api/project/link/stage/delete`,
                body: { 
                  uuId: projectId,
                  stage: stageObj
                },
                vars: [],
                note: `projectRemoveStageLink__${refId}__${stageObj.uuId}`
              }
            }
            requests.push(removeStageLinkReqTemplate(entityId, entityId, toRemove))
          }

          if(toAdd != null) {
            const addStageLinkReqTemplate = function(refId, projectId, stageObj) {
              return {
                method: 'POST',
                invoke: `/api/project/link/stage/add`,
                body: { 
                  uuId: projectId,
                  stage: stageObj
                },
                vars: [],
                note: `projectAddStageLink__${refId}__${stageObj.uuId}`
              }
            }
            requests.push(addStageLinkReqTemplate(entityId, entityId, toAdd))
          }

          if (requests.length > 0) {
            return {
              value: requests
              , status: 'SUCCESS'
            }
          }

          return {
            value: oldVal
            , status: 'ABORT'
            , property: 'status'
          }
        }
      }
    }
  }
  , getPropertyCompatibleFunc: (self) => {
    const _dataGroup = {
      stringGroup: ['name', 'description', 'identifier']
    }
    return (src, tgt) => {
      if (src === tgt) {
        return { status: true }
      }
    
      const keys = Object.keys(_dataGroup)
      for(const key of keys) {
        if (_dataGroup[key].includes(src) && _dataGroup[key].includes(tgt)) {
          return { status: true }
        }  
      }
      return { status: false, colId: tgt }
    }
  }
  , getPropertyDeleteHandler: (/** self */) => {
    return {
      tag: []
    }
  }
  , getPropertyCopyHandler: (self) => {
    let maxNameLength = 200
    let maxIdentifierLength = 200
    let maxDescriptionLength = 200
    if (self.modelInfo != null) {
      let val = self.modelInfo.filter(info => info.field === 'name')
      if (val.length > 0) {
        maxNameLength = val[0].max
      }
      val = self.modelInfo.filter(info => info.field === 'identifier')
      if (val.length > 0) {
        maxIdentifierLength = val[0].max
      }
      val = self.modelInfo.filter(info => info.field === 'description')
      if (val.length > 0) {
        maxDescriptionLength = val[0].max
      }
    } 

    //Expected format when return value is a function:
    //{ value, status } or
    //{ value, status, colId } when status is ABORT
    //Possible status: 'SUCCESS' | 'ABORT'
    //colId is optional but is useful for specifying a different colId as reset value.
    return {
      color: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.trim().length == 0) {
          value = null
        }
        return { value, status: 'SUCCESS' }
      }
      , name: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.length > maxNameLength) {
          value = srcValue.substring(0, maxNameLength)
        }
        return { value, status: 'SUCCESS' }
      }
      , identifier: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && value.length > maxIdentifierLength) {
          value = srcValue.substring(0, maxIdentifierLength)
        }
        return { value, status: 'SUCCESS' }
      }
      , description: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && value.length > maxDescriptionLength) {
          value = srcValue.substring(0, maxDescriptionLength)
        }
        return { value, status: 'SUCCESS' }
      }
    }
  }
  , getExportDataPropertyHandler: (/** self */) => {
    const formatCost = (value, currencyCode=null) => {
      let rawValue = parseInt(value);
      if (rawValue < 0) {
        return '';
      }
      else {
        return currencyCode == null? `$${costFormat(rawValue, {notation:'standard'})}` : costFormatAdv(rawValue, currencyCode, {notation:'standard'});
      }
    }
    const formatArray = (params) => {
      if (params.value != null) {
        return params.value.map(i => i.name).join(',')
      }
      return ''
    }
    const formatDate =(params) => {
      if (params.value != null) {
        return moment.utc(params.value).format()
      }
      return ''
    }
    
    return {
      customers: (params) => {
        return formatArray(params)
      }
      , companies: (params) => {
        return formatArray(params)
      }
      , rebates: (params) => {
        if (params.value != null) {
          return params.value.map(i => {
            if (isNaN(i.rebate)) {
              return i.name
            } else {
              return `${i.name} (${toFixed(i.rebate * 100, 0)}%)`
            }
          }).join(',')
        }
        return ''
      }
      , status: (params) => {
        if (params.value != null) {
          return params.value.name
        }
        return ''
      }
      , scheduleStart: (params) => {
        return formatDate(params)
      }
      , scheduleFinish: (params) => {
        return formatDate(params)
      }
      , locations: (params) => {
        return formatArray(params)
      }
      , estimatedCost: (params) => {
        const currencyCode = params.node != null 
                              && params.node.data != null
                              && params.node.data.currencyCode != null 
                              && params.node.data.currencyCode.trim().length > 0? params.node.data.currencyCode : null
        return formatCost(params.value, currencyCode)
      }
      , actualCost: (params) => {
        const currencyCode = params.node != null 
                              && params.node.data != null
                              && params.node.data.currencyCode != null 
                              && params.node.data.currencyCode.trim().length > 0? params.node.data.currencyCode : null
        return formatCost(params.value, currencyCode)
      }
      , fixedCost: (params) => {
        const currencyCode = params.node != null 
                              && params.node.data != null
                              && params.node.data.currencyCode != null 
                              && params.node.data.currencyCode.trim().length > 0? params.node.data.currencyCode : null
        return formatCost(params.value, currencyCode)
      }
      , actualCostNet: (params) => {
        const currencyCode = params.node != null 
                              && params.node.data != null
                              && params.node.data.currencyCode != null 
                              && params.node.data.currencyCode.trim().length > 0? params.node.data.currencyCode : null
        return formatCost(params.value, currencyCode)
      }
      , fixedCostNet: (params) => {
        const currencyCode = params.node != null 
                              && params.node.data != null
                              && params.node.data.currencyCode != null 
                              && params.node.data.currencyCode.trim().length > 0? params.node.data.currencyCode : null
        return formatCost(params.value, currencyCode)
      }
      , estimatedCostNet: (params) => {
        const currencyCode = params.node != null 
                              && params.node.data != null
                              && params.node.data.currencyCode != null 
                              && params.node.data.currencyCode.trim().length > 0? params.node.data.currencyCode : null
        return formatCost(params.value, currencyCode)
      }
    }
  }
  , lackOfMandatoryField: () => {
    return lackOfMandatoryField([{ entity: 'PROJECT', action: 'VIEW' }])
  }
  , getMandatoryFields() {
    return [
      'name', 'companies', 'customers'
      , 'autoScheduling', 'scheduleMode', 'currencyCode'
    ]
  }
  , getBadgeFilterFields: (self) => {
    const fields = [
      { value: 'name', text: self.$t('project.field.name') }
      , { value: 'color', text: self.$t('project.field.color') }
      , { value: 'tagName', text: self.$t('field.tag') }
      , { value: 'currencyCode', text: self.$t('project.field.currencyCode') }
      , { value: 'identifier', text: self.$t('field.identifier') }
      , { value: 'customerName', text: self.$t('project.field.customers') }
      , { value: 'companyName', text: self.$t('project.field.company') }
      , { value: 'rebateName', text: self.$t('project.field.rebates') }
      , { value: 'stageName', text: self.$t('project.field.status') }
      , { value: 'locationName', text: self.$t('project.field.location') }
      , { value: 'autoScheduling', text: self.$t('project.field.autoScheduling') }
      , { value: 'scheduleMode', text: self.$t('project.field.scheduleMode') }
      , { value: 'priority', text: self.$t('project.field.priority') }
    ];
    if (Array.isArray(self.customFields) && self.customFields.length > 0) {
      for (const f of self.customFields) {
        if (f.type == 'String' || f.type == 'Enum<String>') {
          fields.push({ value: f.name, text: f.displayName });
        }
      }
    }
    fields.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }))
    return fields;
  }
  , getBadgeFilterOptionFetchFunc: (self) => {
    return (field) => {
      let f = field;
      if (f == 'tagName') {
        f = 'TAG.name'
      } else if (f == 'locationName') {
        f = 'LOCATION.name'
      } else if (f == 'stageName') {
        f = 'STAGE.name'
      } else if (f == 'rebateName') {
        f = 'REBATE.name'
      } else if (f == 'customerName') {
        f = 'CUSTOMER.name'
      } else if (f == 'companyName') {
        f = 'COMPANY.name'
      }
      return projectService.listUniqueValuesOfProperty(f)
      .then(data => {
        if (data.length > 0 && self.badgeFilters != null && self.badgeFilters.length > 0) {
          const found = self.badgeFilters.find(i => i.field == field)
          if (found != null && Array.isArray(found.value) && found.value.length > 0) {
            if (field == 'scheduleMode') {
              //Additional property 'value' is added to keep the original value.
              const list = [];
              for (const d of data) {
                const text = d !== 'ALAP'? self.$t('scheduleMode.ASAP') : self.$t('scheduleMode.ALAP')
                const value = d !== 'ALAP'? 'ASAP' : 'ALAP'
                list.push({
                  text
                  , value
                  , checked: found.value.find(j => j.value != null && j.value == value) != null
                })
              }
              if (list.find(i => i.text == '(Empty)') == null) {
                list.unshift({ text: '(Empty)', value: null, checked: false })
              }
              return list
            } else if (field == 'autoScheduling') {
              //Additional property 'value' is added to keep the original value.
              const list = [];
              for (const d of data) {
                const text = d !== true? self.$t('project.autoschedule.manual') : self.$t('project.autoschedule.auto')
                const value = d !== true? false : true
                list.push({
                  text
                  , value
                  , checked: found.value.find(j => j.value != null && j.value == value) != null
                })
              }
              if (list.find(i => i.text == '(Empty)') == null) {
                list.unshift({ text: '(Empty)', value: null, checked: false })
              }
              return list
            } else if (field == 'currencyCode') {
              //Additional property 'value' is added to keep the original value.
              const list = [];
              for (const d of data) {
                let text = d
                if (!d) {
                  text = '(Empty)'
                } else {
                  const found = self.enumList.CurrencyEnum.find(j => j.value === d);
                  text = found != null? found.text : '(Empty)'
                }
                const value = d ? d : null
                list.push({
                  text
                  , value
                  , checked: found.value.find(j => j.value != null && j.value == value) != null
                })
              }
              if (list.find(i => i.text == '(Empty)') == null) {
                list.unshift({ text: '(Empty)', value: null, checked: false })
              }
              return list
            }
            
            //Normal handling
            const rList = data.map(i => ({ 
              text: !i ? '(Empty)' : i
              , checked: found.value.find(j => j.text != null 
                                          && (typeof j.text === 'string' && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) 
                                              || j.text == i) != null
            }))
            if (rList.find(i => i.text == '(Empty)') == null) {
              rList.unshift({ text: '(Empty)', checked: false })
            }
            return rList;
          }
        }
        if (field == 'scheduleMode') {
          //Additional property 'value' is added to keep the original value.
          const list = data.map(i => ({ text: i !== 'ALAP'? self.$t('scheduleMode.ASAP') : self.$t('scheduleMode.ALAP'), value: i !== 'ALAP' ? 'ASAP' : 'ALAP', checked: false }))
          if (list.find(i => i.text == '(Empty)') == null) {
            list.unshift({ text: '(Empty)', value: null, checked: false })
          }
          return list
        } else if (field == 'autoScheduling') {
          //Additional property 'value' is added to keep the original value.
          const list = data.map(i => ({ text: i !== true? self.$t('project.autoschedule.manual') : self.$t('project.autoschedule.auto'), value: i !== true ? false : true, checked: false }))
          if (list.find(i => i.text == '(Empty)') == null) {
            list.unshift({ text: '(Empty)', value: null, checked: false })
          }
          return list
        } else if (field == 'currencyCode') {
          //Additional property 'value' is added to keep the original value.
          let list = []
          for (const d of data) {
            let text = d
            if (!d) {
              text = '(Empty)'
            } else {
              const found = self.enumList.CurrencyEnum.find(j => j.value === d);
              text = found != null? found.text : '(Empty)'
            }
            const value = d ? d : null
            list.push({
              text
              , value
              , checked: false
            })
          }
          if (list.find(i => i.text == '(Empty)') == null) {
            list.unshift({ text: '(Empty)', value: null, checked: false })
          }
          return list
        }
        
        //Normal handling
        const rList = data.map(i => ({ text: !i ? '(Empty)' : i, checked: false }))
        if (rList.find(i => i.text == '(Empty)') == null) {
          rList.unshift({ text: '(Empty)', checked: false })
        }
        return rList;
      });
    }
  }
  , prepareDataForOkEmit: (rowNodes) => {
    if (Array.isArray(rowNodes)) {
      return rowNodes.map(i => {
        const stageList = [];
        if (i.data.stages) {
          for (let j = 0; j < i.data.stages.length; j++) {
            stageList.push({ uuId: i.data.stagesUuId[j], name: i.data.stages[j] });
          }
        }
        return {
          uuId: i.data.uuId
          , name: i.data.name
          , color: i.data.color
          , stageList: stageList
          , autoScheduling: i.data.autoScheduling
        }
      });
    } 
    return []
  }
}