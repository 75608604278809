import axios from 'axios';
const API_KEY="ef664abc-b858-41a3-97a7-96c3c4d0e205"
  const URL="https://holidayapi.com/v1/holidays"
    const COUNTRIES_URL="https://holidayapi.com/v1/countries"

export function getHolidayCountries() {
  return axios.get(`${COUNTRIES_URL}?key=${API_KEY}`);
}


export function getHolidays(country, year) {
  return axios.get(`${URL}?key=${API_KEY}&country=${country}&year=${year}&public=true&subdivisions=true`);
}