import { httpAjax } from '@/helpers';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

export const enumService = {
  update,
  list,
  getPermission,
  history
}

/**
 * Update enum details 
 * by passing  necessary information
 * @param String object Enumeration name
 * @param Object data Enumeration items. e.g For CompanyTypeEnum, {, "Primary": 0, "Subsidiary": 1, ... } 
 * @param Object opts Set true to action(s) needed.
 * 
 */
function update(object, data, { add=false, change=false, remove=false, disable=false }={}) {
  if (object == null) {
    throw new Error('missing_object_param')
  }
  
  let optsParams = []
  if (add) {
    optsParams.push('allowAdding')
  }
  if (change) {
    optsParams.push('allowRename')
  }
  if (remove) {
    optsParams.push('allowDelete')
  }
  if (disable) {
    optsParams.push('allowDisable')
    optsParams.push('allowCleanup')
  }
  
  const url = `/api/system/schema?type=enum&object=${object}${optsParams.length > 0? '&opts='+optsParams.join(','): ''}`;

  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}

function list({ object=null, activeOnly=false }={}) {
  let url = `/api/system/schema?type=enum&opts=${activeOnly? 'use': 'all'}`
  if (object != null) {
    url += `&object=${object}`
  }
  
  const config = {
    headers: getHeaders()
  }
  return httpAjax.get(url, config);
}

function getPermission() {
  const config = {
    headers: getHeaders()
  }
  return httpAjax.get('/api/system/schema?type=enum&opts=brief', config);
}

/**
 * Get history list
 * @param {*} holder entity uuId 
 * @param {*} option { start, limit }
 */
function history(enumType, parentComponent, { start=0, limit=-1 } = {}) {
  const url = `/api/system/history?type=enum&object=${enumType}&start=${start}&limit=${limit}&order=desc`;
  return httpAjax.get(url, {}).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    
    return {
      arg_total: response.data.arg_total != null? response.data.arg_total : 0,
      data: _preprocessHistoryData(enumType, parentComponent, rawData)
    }
  });
}

function _preprocessHistoryData(enumType, parentComponent, rawData) {
  if (rawData == null) {
    return [];
  }

  const keyMap = new Map();
  const data = [];
  let rawItem = null;
  for (let i = 0, len = rawData.length; i < len; i++) {
    rawItem = rawData[i];
    if(rawItem.epoch == null) {
      continue;
    }

    const person = rawItem.user || {};
    person.name = `${person.firstName? person.firstName+' ':''}${person.lastName? person.lastName:''}`;

    const historyItem = {
      epoch: rawItem.epoch
      , person
      , timestamp: moment(rawItem.epoch).valueOf()
      , events: []
    }
    try {
      if (rawItem.properties != null) { //Handle properties case
        _processPropertiesData(enumType, rawItem.properties, historyItem, parentComponent);
      } else { //Handle default case
        let action = rawItem.action;
        if ('CREATE' == rawItem.action) {
          action = parentComponent.$t('history.action.created');
        } else if ('UPDATE' == rawItem.action) {
          action = parentComponent.$t('history.action.updated');
        } else if ('DELETE' == rawItem.action) {
          action = parentComponent.$t('history.action.deleted');
        }
        const type = parentComponent.$t(`enum.${enumType}`)
        if (rawItem.name != null) {
          historyItem.events.push(parentComponent.$t('history.action_with_name', [ type, action, rawItem.name ]));
        } else {
          historyItem.events.push(parentComponent.$t('history.action_default', [ type, action ]));
        }
        
      }
    } catch(e) {
      console.error(e); //eslint-disable-line no-console
      historyItem.events.push(parentComponent.$t('history.action_error'));
    }

    //To create unique key (string) for duplicated epoch value
    const getNewKey = function(value, dMap) {
      if(dMap.has(value)) {
        let index = value.indexOf('_');
        if (index == -1) {
          return getNewKey(`${value}_1`, dMap);
        }
        const number = parseInt(value.substring(index+1));
        if(isNaN(number)) {
          return getNewKey(`${value.substring(0, index)}_1`, dMap);
        }
        return getNewKey(`${value.substring(0, index)}_${number+1}`, dMap);
      }
      return value;
    }
    const key = getNewKey('' + historyItem.epoch, keyMap);// Convert historyItem.epoch to string
    historyItem.id = key;
    keyMap.set(key, historyItem);
    data.push(historyItem);
  }
  return { core: data };
}

function _processPropertiesData(enumType, properties, historyItem, parentComponent) {
  let propItem = null;
  for (let j = 0, jLen = properties.length; j < jLen; j++) {
    propItem = properties[j];
    
    let propOld = propItem.oldValue;
    let propNew = propItem.newValue;

    propOld = propOld != null && propOld != 'null'? propOld : null;
    propNew = propNew != null && propNew != 'null'? propNew : null;

    let oldKeys, newKeys
    if (propOld != null && propOld[enumType] != null) {
      oldKeys = Object.keys(propOld[enumType])
    } else {
      oldKeys = [] //set empty list
    }

    if (propNew != null && propNew[enumType] != null) {
      newKeys = Object.keys(propNew[enumType])
    } else {
      newKeys = [] //set empty list
    }

    //Remove item with negative value
    const oldList = []
    for (const k of oldKeys) {
      const v = propOld[enumType][k]
      if (typeof v === 'number' && v > -1) {
        oldList.push(k)
      }
    }
    const newList = []
    for (const k of newKeys) {
      const v = propNew[enumType][k]
      if (typeof v === 'number' && v > -1) {
        newList.push(k)
      }
    }

    let fromStr = '<div><ul>'
    fromStr += oldList.map(i => `<li>${i}</li>`).join('')
    fromStr += '</ul></div>'

    let toStr = '<div><ul>'
    toStr += newList.map(i => `<li>${i}</li>`).join('')
    toStr += '</ul></div>'


    let htmlContentStr = parentComponent.$t('enum.history_action_change_from_prop')
    htmlContentStr += fromStr
    htmlContentStr += parentComponent.$t('enum.history_action_change_to_prop')
    htmlContentStr += toStr


    historyItem.events.push(htmlContentStr);
  }
}