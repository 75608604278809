<template>
  <span>{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'TaskTemplateCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    const value = this.params.value != null && this.params.value.names != null? this.params.value.names.join(', ') : null;
    let toShow = this.params.data != null? this.params.data.taskType == 'Project' : false;
    if (typeof this.params.showFunc === 'function') {
      toShow = this.params.showFunc(this.params);
    }

    if (toShow) {
      this.value = value;
    }
  },
  computed: {
    label() {
      return this.value || '';
    }
  }
})
</script>
