const currencies = [ 
  { code: "AED", name: "United Arab Emirates Dirhams", num: 784 }
	, { code: "ALL", name: "Albania Leke", num: 8 }
	, { code: "AMD", name: "Armenia Drams", num: 51 }
	, { code: "ANG", name: "Netherlands Antilles Guilders", num: 532 }
	, { code: "ARS", name: "Argentina Pesos", num: 32 }
	, { code: "AUD", name: "Australia Dollars", num: 36 }
	, { code: "BAM", name: "Bosnia and Herzegovena Convertible Marka", num: 977 }
	, { code: "BBD", name: "Barbados Dollars", num: 52 }
	, { code: "BDT", name: "Bangladesh Taka", num: 50 }
	, { code: "BGN", name: "Bulgaria Leva", num: 975 }
	, { code: "BHD", name: "Bahrain Dinars", num: 48 }
	, { code: "BMD", name: "Bermuda Dollars", num: 60 }
	, { code: "BND", name: "Brunei Dollars", num: 96 }
	, { code: "BOB", name: "Bolivia Bolivianos", num: 68 }
	, { code: "BRL", name: "Brazil Reals", num: 986 }
	, { code: "BSD", name: "Bahamas Dollars", num: 44 }
	, { code: "BWP", name: "Botswana Pulas", num: 72 }
	, { code: "BYR", name: "Belarus Rubles", num: 974 }
	, { code: "CAD", name: "Canada Dollars", num: 124 }
	, { code: "CHF", name: "Switzerland Francs", num: 756 }
	, { code: "CLP", name: "Chile Pesos", num: 152 }
	, { code: "CNY", name: "China Yuan Renminbi", num: 156 }
	, { code: "COP", name: "Colombia Pesos", num: 170 }
	, { code: "CRC", name: "Costa Rica Colones", num: 188 }
	, { code: "CUP", name: "Cuba Pesos", num: 192 }
	, { code: "CYP", name: "Cyprus Pounds (obsolete)", num: 196 }
	, { code: "CZK", name: "Czech Republic Koruny", num: 203 }
	, { code: "DKK", name: "Denmark Kroner", num: 208 }
	, { code: "DOP", name: "Dominican Republic Pesos", num: 214 }
	, { code: "DZD", name: "Algeria Dinars", num: 12 }
	, { code: "EGP", name: "Egypt Pounds", num: 818 }
	, { code: "ETB", name: "Ethiopia Birr", num: 230 }
	, { code: "EUR", name: "Euro", num: 978 }
	, { code: "FJD", name: "Fiji Dollars", num: 242 }
	, { code: "GBP", name: "United Kingdom Pounds", num: 826 }
	, { code: "GEL", name: "Georgia Lari", num: 981 }
	, { code: "GTQ", name: "Guatemala Quetzales", num: 320 }
	, { code: "HKD", name: "Hong Kong Dollars", num: 344 }
	, { code: "HNL", name: "Honduras Lempiras", num: 340 }
	, { code: "HRK", name: "Croatia Kuna", num: 191 }
	, { code: "HUF", name: "Hungary Forint", num: 348 }
	, { code: "IDR", name: "Indonesia Rupiahs", num: 360 }
	, { code: "ILS", name: "Israel New Shekels", num: 376 }
	, { code: "INR", name: "India Rupees", num: 356 }
	, { code: "IQD", name: "Iraq Dinars", num: 368 }
	, { code: "IRR", name: "Iran Rials", num: 364 }
	, { code: "ISK", name: "Iceland Kronur", num: 352 }
	, { code: "JMD", name: "Jamaica Dollars", num: 388 }
	, { code: "JOD", name: "Jordan Dinars", num: 400 }
	, { code: "JPY", name: "Japan Yen", num: 392 }
	, { code: "KES", name: "Kenya Shillings", num: 404 }
	, { code: "KGS", name: "Kyrgyzstan Sums", num: 417 }
	, { code: "KHR", name: "Cambodia Riels", num: 116 }
	, { code: "KRW", name: "Korea (South) Won", num: 410 }
	, { code: "KWD", name: "Kuwait Dinars", num: 414 }
	, { code: "KZT", name: "Kazakhstan Tenge", num: 398 }
	, { code: "LBP", name: "Lebanon Pounds", num: 422 }
	, { code: "LKR", name: "Sri Lanka Rupees", num: 144 }
	, { code: "LTL", name: "Lithuania Litai", num: 440 }
	, { code: "LVL", name: "Latvia Lati", num: 428 }
	, { code: "LYD", name: "Libya Dinars", num: 434 }
	, { code: "MAD", name: "Morocco Dirhams", num: 504 }
	, { code: "MDL", name: "Moldova Lei", num: 498 }
	, { code: "MKD", name: "Macedonia Denars", num: 807 }
	, { code: "MOP", name: "Macau Patacas", num: 446 }
	, { code: "MTL", name: "Malta Liri (obsolete)", num: 470 }
	, { code: "MUR", name: "Mauritius Rupees", num: 480 }
	, { code: "MWK", name: "Malawi Kwachas", num: 454 }
	, { code: "MXN", name: "Mexico Pesos", num: 484 }
	, { code: "MYR", name: "Malaysia Ringgits", num: 458 }
	, { code: "NAD", name: "Namibia Dollars", num: 516 }
	, { code: "NGN", name: "Nigeria Nairas", num: 566 }
	, { code: "NIO", name: "Nicaragua Cordobas", num: 558 }
	, { code: "NOK", name: "Norway Kroner", num: 578 }
	, { code: "NPR", name: "Nepal Rupees", num: 524 }
	, { code: "NZD", name: "New Zealand Dollars", num: 554 }
	, { code: "OMR", name: "Oman Rials", num: 512 }
	, { code: "PAB", name: "Panama Balboa", num: 590 }
	, { code: "PEN", name: "Peru Nuevos Soles", num: 604 }
	, { code: "PHP", name: "Philippines Pesos", num: 608 }
	, { code: "PKR", name: "Pakistan Rupees", num: 586 }
	, { code: "PLN", name: "Poland Zlotych", num: 985 }
	, { code: "PYG", name: "Paraguay Guarani", num: 600 }
	, { code: "QAR", name: "Qatar Riyals", num: 634 }
	, { code: "ROL", name: "Romania Lei (obsolete)", num: 642 }
	, { code: "RON", name: "Romania New Lei", num: 946 }
	, { code: "RUB", name: "Russia Rubles", num: 643 }
	, { code: "SAR", name: "Saudi Arabia Riyals", num: 682 }
	, { code: "SEK", name: "Sweden Kronor", num: 752 }
	, { code: "SGD", name: "Singapore Dollars", num: 702 }
	, { code: "SKK", name: "Slovakia Koruny (obsolete)", num: 703 }
	, { code: "SYP", name: "Syria Pounds", num: 760 }
	, { code: "THB", name: "Thailand Baht", num: 764 }
	, { code: "TJS", name: "Tajikistan Somoni", num: 972 }
	, { code: "TMT", name: "Turkmenistan New Manats", num: 934 }
	, { code: "TND", name: "Tunisia Dinars", num: 788 }
	, { code: "TRL", name: "Turkey Lira (obsolete)", num: 792 }
	, { code: "TRY", name: "Turkey Lira", num: 949 }
	, { code: "TTD", name: "Trinidad and Tobago Dollars", num: 780 }
	, { code: "TWD", name: "Taiwan New Dollars", num: 901 }
	, { code: "TZS", name: "Tanzania Shillings", num: 834 }
	, { code: "UAH", name: "Ukraine Hryvnia", num: 980 }
	, { code: "UGX", name: "Uganda Shillings", num: 800 }
	, { code: "USD", name: "United States Dollars", num: 840 }
	, { code: "UYU", name: "Uruguay Pesos", num: 858 }
	, { code: "UZS", name: "Uzbekistan Sums", num: 860 }
	, { code: "VEF", name: "Venezuela Bolivares", num: 937 }
	, { code: "VND", name: "Vietnam Dong", num: 704 }
	, { code: "VUV", name: "Vanuatu Vatu", num: 548 }
	, { code: "XAF", name: "Coopération financière en Afrique centrale BEAC", num: 950 }
	, { code: "XAG", name: "Silver Ounces", num: 961 }
	, { code: "XAU", name: "Gold Ounces", num: 959 }
	, { code: "XCD", name: "Eastern Caribbean Dollars", num: 951 }
	, { code: "XOF", name: "Communauté Africaine Financière Francs BEAC", num: 952 }
	, { code: "XPD", name: "Palladium Ounces", num: 964 }
	, { code: "XPF", name: "Comptoirs Français du Pacifique Francs", num: 953 }
	, { code: "XPT", name: "Platinum Ounces", num: 962 }
	, { code: "ZAR", name: "South Africa Rand", num: 710 }
	, { code: "ZMK", name: "Zambia Kwacha", num: 894 }
	, { code: "ZWL", name: "Zimbabwe Dollars", num: 932 }
]

export default currencies;
