var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "aggrid-scheduler-container", staticClass: "position-relative" },
    [
      _vm.alertMsg != null
        ? _c("AlertFeedback", {
            attrs: {
              msg: _vm.alertMsg,
              details: _vm.alertMsgDetails.list,
              detailTitle: _vm.alertMsgDetails.title,
              alertState: _vm.alertError ? "danger" : "success",
            },
            on: {
              resetAlert: _vm.resetAlert,
              offsetHeight: _vm.updateGridHeight,
            },
          })
        : _vm._e(),
      !_vm.isWidget
        ? _c(
            "div",
            { staticClass: "staff-action-bar border-part" },
            [
              _c(
                "PriorityNavigation",
                {
                  staticClass: "grid-toolbar",
                  attrs: {
                    dropDownStayOpened: _vm.badgeFilterModalOpened != "close",
                    closeDropdown: _vm.closePriorityNavDropdown,
                  },
                  on: {
                    "update:closeDropdown": function ($event) {
                      _vm.closePriorityNavDropdown = $event
                    },
                    "update:close-dropdown": function ($event) {
                      _vm.closePriorityNavDropdown = $event
                    },
                  },
                  nativeOn: _vm._d({}, [
                    _vm.priorityNavMouseOverEvent,
                    function ($event) {
                      return _vm.onPriorityNavMouseOverOrTouchEnd.apply(
                        null,
                        arguments
                      )
                    },
                    _vm.priorityNavTouchEndEvent,
                    function ($event) {
                      return _vm.onPriorityNavMouseOverOrTouchEnd.apply(
                        null,
                        arguments
                      )
                    },
                  ]),
                },
                [
                  _c("li", [
                    _c(
                      "span",
                      { staticClass: "d-flex ml-2 mr-2" },
                      [
                        _c(
                          "label",
                          { staticClass: "mr-1", attrs: { for: "dates" } },
                          [_vm._v(_vm._s(_vm.$t("staff.dates")))]
                        ),
                        _c("b-form-select", {
                          staticClass: "mw-150 minw-170",
                          attrs: {
                            id: "dates",
                            options: _vm.dateOptions,
                            size: "sm",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.rangeSelected()
                            },
                          },
                          model: {
                            value: _vm.dates,
                            callback: function ($$v) {
                              _vm.dates = $$v
                            },
                            expression: "dates",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "span",
                      { staticClass: "d-flex mr-1 date start-date-elevation" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "mr-1 align-self-baseline",
                            attrs: { for: "startDate" },
                          },
                          [_vm._v(_vm._s(_vm.$t("staff.from")))]
                        ),
                        _c("b-form-datepicker", {
                          staticClass: "date-picker d-flex",
                          attrs: {
                            id: "staffUsageStartDate",
                            "today-button": "",
                            "reset-button": "",
                            "close-button": "",
                            "hide-header": "",
                            "label-today-button": _vm.$t("date.today"),
                            "label-reset-button": _vm.$t("date.reset"),
                            "label-close-button": _vm.$t("date.close"),
                            "today-button-variant": "primary",
                            "reset-button-variant": "danger",
                            "close-button-variant": "secondary",
                            size: "sm",
                          },
                          on: { input: _vm.dateChanged },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function (ref) {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "calendar-days"] },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2621928167
                          ),
                          model: {
                            value: _vm.startDate,
                            callback: function ($$v) {
                              _vm.startDate = $$v
                            },
                            expression: "startDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "span",
                      { staticClass: "d-flex mr-1 date" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "mr-1 align-self-baseline",
                            attrs: { for: "endDate" },
                          },
                          [_vm._v(_vm._s(_vm.$t("staff.to")))]
                        ),
                        _c("b-form-datepicker", {
                          staticClass: "date-picker d-flex",
                          attrs: {
                            id: "staffUsageEndDate",
                            "today-button": "",
                            "reset-button": "",
                            "close-button": "",
                            "hide-header": "",
                            "label-today-button": _vm.$t("date.today"),
                            "label-reset-button": _vm.$t("date.reset"),
                            "label-close-button": _vm.$t("date.close"),
                            "today-button-variant": "primary",
                            "reset-button-variant": "danger",
                            "close-button-variant": "secondary",
                            size: "sm",
                          },
                          on: { input: _vm.dateChanged },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function (ref) {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "calendar-days"] },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2621928167
                          ),
                          model: {
                            value: _vm.endDate,
                            callback: function ($$v) {
                              _vm.endDate = $$v
                            },
                            expression: "endDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "li",
                    [
                      _c(
                        "b-btn",
                        {
                          staticClass: "ml-1",
                          attrs: {
                            id: "BTN_REFRESH_" + _vm.id,
                            pressed: _vm.highlightRefresh,
                          },
                          on: {
                            click: _vm.daySelected,
                            "update:pressed": function ($event) {
                              _vm.highlightRefresh = $event
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "arrows-rotate"] },
                          }),
                          _c("b-popover", {
                            attrs: {
                              target: "BTN_REFRESH_" + _vm.id,
                              placement: "top",
                              boundary: "viewport",
                              triggers: "hover",
                              content: _vm.$t("button.refresh"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("li", [
                    _c(
                      "span",
                      { staticClass: "d-flex mr-1" },
                      [
                        _c(
                          "label",
                          { staticClass: "mr-1", attrs: { for: "timescale" } },
                          [_vm._v(_vm._s(_vm.$t("staff.timescale")))]
                        ),
                        _c("b-form-select", {
                          staticClass: "mw-150",
                          attrs: {
                            id: "timescale",
                            options: _vm.spanOptions,
                            size: "sm",
                          },
                          model: {
                            value: _vm.span,
                            callback: function ($$v) {
                              _vm.span = $$v
                            },
                            expression: "span",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "li",
                    {
                      on: _vm._d({ mouseleave: _vm.onGroupingLeave }, [
                        _vm.groupingMouseEnterEvent,
                        _vm.onGroupingOver,
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "grouping",
                          staticClass: "action-bar-dropdown",
                          attrs: {
                            id: "BTN_GROUPING_" + _vm.id,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "list-tree"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3504015540
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-group",
                            { attrs: { header: _vm.$t("groupby") } },
                            [
                              _vm.canView("COMPANY")
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onGroupChange(
                                            "company",
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "mr-1 grouping-icon",
                                            attrs: {
                                              icon: ["far", "building"],
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("staff.group.company")
                                            )
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.grouping.company
                                        ? _c("font-awesome-icon", {
                                            staticClass: "active-check",
                                            attrs: { icon: ["far", "check"] },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canView("LOCATION")
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onGroupChange(
                                            "location",
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "mr-1 grouping-icon",
                                            attrs: {
                                              icon: ["far", "map-marked-alt"],
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("staff.group.location")
                                            )
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.grouping.location
                                        ? _c("font-awesome-icon", {
                                            staticClass: "active-check",
                                            attrs: { icon: ["far", "check"] },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canView("STAGE")
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onGroupChange(
                                            "stage",
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "mr-1 grouping-icon",
                                            attrs: {
                                              icon: ["far", "signs-post"],
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(_vm.$t("staff.group.stage"))
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.grouping.stage
                                        ? _c("font-awesome-icon", {
                                            staticClass: "active-check",
                                            attrs: { icon: ["far", "check"] },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canView("PROJECT")
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onGroupChange(
                                            "project",
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "mr-1 grouping-icon",
                                            attrs: {
                                              icon: ["far", "chart-network"],
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("staff.group.project")
                                            )
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.grouping.project
                                        ? _c("font-awesome-icon", {
                                            staticClass: "active-check",
                                            attrs: { icon: ["far", "check"] },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canView("DEPARTMENT")
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onGroupChange(
                                            "department",
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "mr-1 grouping-icon",
                                            attrs: { icon: ["far", "sitemap"] },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("staff.group.department")
                                            )
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.grouping.department
                                        ? _c("font-awesome-icon", {
                                            staticClass: "active-check",
                                            attrs: { icon: ["far", "check"] },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canView("SKILL") &&
                              _vm.canView("STAFF", ["SKILL"])
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onGroupChange(
                                            "skills",
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "mr-1 grouping-icon",
                                            attrs: {
                                              icon: ["far", "head-side-brain"],
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(_vm.$t("staff.group.skill"))
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.grouping.skills
                                        ? _c("font-awesome-icon", {
                                            staticClass: "active-check",
                                            attrs: { icon: ["far", "check"] },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-group",
                            { attrs: { header: _vm.$t("stafftype") } },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onGroupChange("staff", false)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "mr-1 grouping-icon",
                                        attrs: {
                                          icon: ["far", "user-hard-hat"],
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(_vm.$t("staff.group.staff"))
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.grouping.staff
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onGroupChange(
                                        "showGeneric",
                                        false
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "mr-1 grouping-icon",
                                        attrs: { icon: ["far", "users"] },
                                      }),
                                      _vm._v(
                                        _vm._s(_vm.$t("staff.generic_staff"))
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.showGeneric
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    {
                      on: _vm._d({ mouseleave: _vm.onAllocLeave }, [
                        _vm.allocMouseEnterEvent,
                        _vm.onAllocOver,
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "alloc",
                          staticClass: "alloc-dropdown action-bar-dropdown",
                          attrs: {
                            id: "BTN_ALLOC_" + _vm.id,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "clock"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2107372213
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-group",
                            { attrs: { header: _vm.$t("show") } },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onShowChange("usage")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [_vm._v(_vm._s(_vm.$t("staff.usage")))]
                                  ),
                                  _vm.show.usage
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.canView("BOOKING")
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onShowChange("booking")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("booking.title"))
                                          ),
                                        ]
                                      ),
                                      _vm.show.booking
                                        ? _c("font-awesome-icon", {
                                            staticClass: "active-check",
                                            attrs: { icon: ["far", "check"] },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canView("ACTIVITY")
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onShowChange("activity")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("activity.title"))
                                          ),
                                        ]
                                      ),
                                      _vm.show.activity
                                        ? _c("font-awesome-icon", {
                                            staticClass: "active-check",
                                            attrs: { icon: ["far", "check"] },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canView("TASK")
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onShowChange("task")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [_vm._v(_vm._s(_vm.$t("task.title")))]
                                      ),
                                      _vm.show.task
                                        ? _c("font-awesome-icon", {
                                            staticClass: "active-check",
                                            attrs: { icon: ["far", "check"] },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onShowChange("vacation")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [_vm._v(_vm._s(_vm.$t("vacation.title")))]
                                  ),
                                  _vm.show.vacation
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-group",
                            { attrs: { header: _vm.$t("units") } },
                            [
                              _c(
                                "li",
                                {
                                  attrs: {
                                    "data-v-05ab69f6": "",
                                    role: "presentation",
                                  },
                                  on: _vm._d(
                                    {
                                      mouseleave: _vm.onCostLeave,
                                      click: _vm.onCostClick,
                                    },
                                    [_vm.costMouseEnterEvent, _vm.onCostOver]
                                  ),
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        role: "menuitem",
                                        href: "#",
                                        target: "_self",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [_vm._v(_vm._s(_vm.$t("button.cost")))]
                                      ),
                                      _c("font-awesome-icon", {
                                        staticClass: "menu-chevron",
                                        attrs: {
                                          icon: ["far", "chevron-right"],
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          ref: "cost",
                                          staticClass: "sub-menu-dropdown",
                                        },
                                        [
                                          _vm.canView("TASK", ["fixedCost"]) ||
                                          _vm.canView("BOOKING", [
                                            "fixedCost",
                                          ]) ||
                                          _vm.canView("ACTIVITY", ["fixedCost"])
                                            ? _c(
                                                "b-dropdown-item",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.staffAlloc =
                                                        "fixedcost"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "action-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "button.fixed_cost"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.staffAlloc === "fixedcost"
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "active-check",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "check",
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.canView("TASK", [
                                            "fixedCostNet",
                                          ]) ||
                                          _vm.canView("BOOKING", [
                                            "fixedCost",
                                          ]) ||
                                          _vm.canView("ACTIVITY", ["fixedCost"])
                                            ? _c(
                                                "b-dropdown-item",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.staffAlloc =
                                                        "fixedcostnet"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "action-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "button.fixed_cost_net"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.staffAlloc ===
                                                  "fixedcostnet"
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "active-check",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "check",
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.canView("STAFF", [
                                            "payAmount",
                                            "payFrequency",
                                            "payCurrency",
                                          ])
                                            ? _c(
                                                "b-dropdown-item",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.staffAlloc = "estcost"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "action-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "button.estcost"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.staffAlloc === "estcost"
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "active-check",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "check",
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.canView("STAFF", [
                                            "payAmount",
                                            "payFrequency",
                                            "payCurrency",
                                          ]) && _vm.canView("REBATE")
                                            ? _c(
                                                "b-dropdown-item",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.staffAlloc =
                                                        "estcostnet"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "action-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "button.estcostnet"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.staffAlloc ===
                                                  "estcostnet"
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "active-check",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "check",
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.canView("TASK", ["actualCost"]) ||
                                          _vm.canView("ACTIVITY", [
                                            "actualCost",
                                          ])
                                            ? _c(
                                                "b-dropdown-item",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.staffAlloc =
                                                        "actualcost"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "action-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "button.actual_cost"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.staffAlloc ===
                                                  "actualcost"
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "active-check",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "check",
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.canView("TASK", [
                                            "actualCostNet",
                                          ]) ||
                                          _vm.canView("ACTIVITY", [
                                            "actualCost",
                                          ])
                                            ? _c(
                                                "b-dropdown-item",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.staffAlloc =
                                                        "actualcostnet"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "action-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "button.actual_cost_net"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.staffAlloc ===
                                                  "actualcostnet"
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "active-check",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "check",
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  attrs: {
                                    "data-v-05ab69f6": "",
                                    role: "presentation",
                                  },
                                  on: _vm._d(
                                    {
                                      mouseleave: _vm.onDurationLeave,
                                      click: _vm.onDurationClick,
                                    },
                                    [
                                      _vm.durationMouseEnterEvent,
                                      _vm.onDurationOver,
                                    ]
                                  ),
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        role: "menuitem",
                                        href: "#",
                                        target: "_self",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("button.duration"))
                                          ),
                                        ]
                                      ),
                                      _c("font-awesome-icon", {
                                        staticClass: "menu-chevron",
                                        attrs: {
                                          icon: ["far", "chevron-right"],
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          ref: "duration",
                                          staticClass: "sub-menu-dropdown",
                                        },
                                        [
                                          _vm.canView("TASK", [
                                            "fixedDuration",
                                          ]) ||
                                          _vm.canView("BOOKING", [
                                            "fixedDuration",
                                          ]) ||
                                          _vm.canView("ACTIVITY", [
                                            "fixedDuration",
                                          ])
                                            ? _c(
                                                "b-dropdown-item",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.staffAlloc =
                                                        "fixeddurationhours"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "action-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "button.fixeddurationhours"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.staffAlloc ===
                                                  "fixeddurationhours"
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "active-check",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "check",
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.canView("TASK", [
                                            "fixedDuration",
                                          ]) ||
                                          _vm.canView("BOOKING", [
                                            "fixedDuration",
                                          ]) ||
                                          _vm.canView("ACTIVITY", [
                                            "fixedDuration",
                                          ])
                                            ? _c(
                                                "b-dropdown-item",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.staffAlloc =
                                                        "fixeddurationdays"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "action-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "button.fixeddurationdays"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.staffAlloc ===
                                                  "fixeddurationdays"
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "active-check",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "check",
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.canView("TASK", [
                                            "estimatedDuration",
                                          ]) ||
                                          _vm.canView("BOOKING", [
                                            "estimatedDuration",
                                          ]) ||
                                          _vm.canView("ACTIVITY", [
                                            "estimatedDuration",
                                          ])
                                            ? _c(
                                                "b-dropdown-item",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.staffAlloc = "hours"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "action-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "button.estimateddurationhours"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.staffAlloc === "hours"
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "active-check",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "check",
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.canView("TASK", [
                                            "estimatedDuration",
                                          ]) ||
                                          _vm.canView("BOOKING", [
                                            "estimatedDuration",
                                          ]) ||
                                          _vm.canView("ACTIVITY", [
                                            "estimatedDuration",
                                          ])
                                            ? _c(
                                                "b-dropdown-item",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.staffAlloc = "days"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "action-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "button.estimateddurationdays"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.staffAlloc === "days"
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "active-check",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "check",
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.canView("TASK", [
                                            "actualDuration",
                                          ]) ||
                                          _vm.canView("ACTIVITY", [
                                            "actualDuration",
                                          ])
                                            ? _c(
                                                "b-dropdown-item",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.staffAlloc =
                                                        "actualdurationhours"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "action-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "button.actualdurationhours"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.staffAlloc ===
                                                  "actualdurationhours"
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "active-check",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "check",
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.canView("TASK", [
                                            "actualDuration",
                                          ]) ||
                                          _vm.canView("ACTIVITY", [
                                            "actualDuration",
                                          ])
                                            ? _c(
                                                "b-dropdown-item",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.staffAlloc =
                                                        "actualdurationdays"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "action-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "button.actualdurationdays"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.staffAlloc ===
                                                  "actualdurationdays"
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "active-check",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "check",
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.staffAlloc = "percent"
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [_vm._v(_vm._s(_vm.$t("button.percent")))]
                                  ),
                                  _vm.staffAlloc === "percent"
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.staffAlloc = "headcount"
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("button.head_count"))
                                      ),
                                    ]
                                  ),
                                  _vm.staffAlloc === "headcount"
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-group",
                            { attrs: { header: _vm.$t("filter") } },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: { click: _vm.onUnders },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [_vm._v(_vm._s(_vm.$t("button.unders")))]
                                  ),
                                  _vm.showUnders
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: { click: _vm.onOptimal },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [_vm._v(_vm._s(_vm.$t("button.optimal")))]
                                  ),
                                  _vm.showOptimal
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: { click: _vm.onOvers },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [_vm._v(_vm._s(_vm.$t("button.overs")))]
                                  ),
                                  _vm.showOvers
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.id !== null
                    ? _c("li", [
                        _c("span", { staticClass: "d-flex" }, [
                          _c(
                            "span",
                            [
                              _c("b-popover", {
                                attrs: {
                                  target: "btn_usageproject",
                                  placement: "top",
                                  boundary: "viewport",
                                  triggers: "hover",
                                  content: _vm.staffUsageProject
                                    ? _vm.$t("staff.staff_usage_project")
                                    : _vm.$t("staff.staff_usage_all_projects"),
                                },
                              }),
                              _c(
                                "b-btn",
                                {
                                  staticClass: "tool-button",
                                  attrs: {
                                    id: "btn_usageproject",
                                    pressed: _vm.staffUsageProject,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.allProjects(null)
                                    },
                                    "update:pressed": function ($event) {
                                      _vm.staffUsageProject = $event
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "chart-network"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("li", { staticClass: "divider" }, [
                    _c(
                      "span",
                      {
                        staticClass: "action-v-divider",
                        attrs: { readonly: "" },
                      },
                      [_vm._v("|")]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "span",
                      { attrs: { id: "SPAN_COLLAPSE_" + _vm.id } },
                      [
                        _c("b-popover", {
                          attrs: {
                            target: "SPAN_COLLAPSE_" + _vm.id,
                            placement: "top",
                            boundary: "viewport",
                            triggers: "hover",
                            content: _vm.$t("staff.button.minus"),
                          },
                        }),
                        _c(
                          "b-btn",
                          {
                            attrs: {
                              disabled: _vm.expandLevel === 0,
                              id: "BTN_COLLAPSE_" + _vm.id,
                            },
                            on: { click: _vm.collapse },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "search-minus"] },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "span",
                      { attrs: { id: "SPAN_EXPAND_" + _vm.id } },
                      [
                        _c("b-popover", {
                          attrs: {
                            target: "SPAN_EXPAND_" + _vm.id,
                            placement: "top",
                            boundary: "viewport",
                            triggers: "hover",
                            content: _vm.$t("staff.button.plus"),
                          },
                        }),
                        _c(
                          "b-btn",
                          {
                            attrs: {
                              disabled: _vm.expandLevel === _vm.maxLevel,
                              id: "BTN_EXPAND_" + _vm.id,
                            },
                            on: { click: _vm.expand },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "search-plus"] },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "li",
                    {
                      on: _vm._d({ mouseleave: _vm.onFilterLeave }, [
                        _vm.filterMouseEnterEvent,
                        _vm.onFilterOver,
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "filter",
                          staticClass: "action-bar-dropdown text-filter",
                          attrs: {
                            id: "BTN_FILTER_" + _vm.id,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          on: { hide: _vm.onFilterTextDropdownHide },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      class:
                                        _vm.filterText !== "" ? "active" : "",
                                      attrs: { icon: ["far", "file-search"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3771862229
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-form",
                            {
                              staticClass: "filter-padding",
                              on: {
                                submit: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "search-input" },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.$t("task.filter"),
                                    },
                                    on: {
                                      focus: function ($event) {
                                        _vm.filterTextFocus = true
                                      },
                                      blur: function ($event) {
                                        _vm.filterTextFocus = false
                                      },
                                      keypress: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.onFilterSubmit.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.filterText,
                                      callback: function ($$v) {
                                        _vm.filterText = $$v
                                      },
                                      expression: "filterText",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-btn",
                                        {
                                          staticClass: "search-append",
                                          attrs: {
                                            size: "sm",
                                            variant: "danger",
                                          },
                                          on: { click: _vm.onFilterClear },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "search-clear",
                                            attrs: { icon: "times" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-btn",
                                        {
                                          staticClass:
                                            "search-append search-append-bg",
                                          attrs: { size: "sm" },
                                          on: { click: _vm.onFilterSubmit },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              variant: "secondary",
                                              icon: ["far", "magnifying-glass"],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    {
                      on: _vm._d({}, [
                        _vm.badgeFilterMouseEnterEvent,
                        _vm.onBadgeFilterEnter,
                        _vm.badgeFilterMouseOverEvent,
                        _vm.onBadgeFilterOver,
                        _vm.badgeFilterMouseLeaveEvent,
                        _vm.onBadgeFilterLeave,
                        _vm.badgeFilterTouchEndEvent,
                        _vm.onBadgeFilterOver,
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "badgeFilter",
                          staticClass: "action-bar-dropdown text-filter",
                          attrs: {
                            id: "BTN_BADGE_FILTER_" + _vm.id,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          on: { hide: _vm.onBadgeFilterDropdownHide },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      class:
                                        _vm.badgeFilters.length > 0
                                          ? "active"
                                          : "",
                                      attrs: { icon: ["far", "filter"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3929189510
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-form",
                            {
                              staticClass: "filter-padding",
                              on: {
                                submit: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c("TaskViewBadgeFilter", {
                                attrs: {
                                  filters: _vm.badgeFilters,
                                  fields: _vm.badgeFilterFields,
                                  fieldValues: _vm.badgeFilterFieldValues,
                                },
                                on: {
                                  modified: _vm.onBadgeFilterModified,
                                  filterModalOpened:
                                    _vm.onBadgeFilterModalOpened,
                                  filterModalClosed:
                                    _vm.onBadgeFilterModalClosed,
                                  fetchFieldOptions:
                                    _vm.onBadgeFilterFetchOptions,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "view",
                      on: _vm._d({ mouseleave: _vm.onViewLeave }, [
                        _vm.viewMouseEnterEvent,
                        _vm.onViewOver,
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "view",
                          staticClass: "action-bar-dropdown",
                          attrs: {
                            id: "BTN_VIEW_" + _vm.id,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "desktop"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4186387599
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: { click: _vm.savePreset },
                            },
                            [
                              _c("span", { staticClass: "action-item-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      _vm.id
                                        ? "staff.button.save"
                                        : "staff.button.save_planner"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c("b-dropdown-divider"),
                          _vm._l(_vm.staffViews, function (item, index) {
                            return [
                              _c(
                                "b-dropdown-item",
                                {
                                  key: index,
                                  staticClass: "action-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.loadViewSettings(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-label-with-icon",
                                      attrs: { id: "viewname" + index },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  _vm.isEllipsisActive(item.name)
                                    ? _c("b-popover", {
                                        attrs: {
                                          target: "viewname" + index,
                                          placement: "top",
                                          boundary: "viewport",
                                          "custom-class": "popover-margin",
                                          triggers: "hover",
                                          offset: "-100",
                                          content: item.name,
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    [
                                      !_vm.editPermission(item)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "action-item-icon",
                                              attrs: {
                                                id: "BTN_COPY_" + index,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.copyColumnSettings(
                                                    item.name,
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "copy"],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.editPermission(item)
                                        ? _c("b-popover", {
                                            attrs: {
                                              target: "BTN_COPY_" + index,
                                              placement: "top",
                                              boundary: "viewport",
                                              triggers: "hover",
                                              content: _vm.$t(
                                                _vm.id
                                                  ? "staff.button.copy"
                                                  : "staff.button.copy_planner"
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      !_vm.editPermission(item)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "action-item-icon position-third",
                                              attrs: {
                                                id: "BTN_INFO_" + index,
                                              },
                                              on: _vm._d(
                                                {
                                                  mouseleave: function (
                                                    $event
                                                  ) {
                                                    return _vm.onInfoLeave(
                                                      index
                                                    )
                                                  },
                                                },
                                                [
                                                  _vm.infoMouseEnterEvent,
                                                  function ($event) {
                                                    return _vm.onInfoOver(index)
                                                  },
                                                ]
                                              ),
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "circle-info"],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.editPermission(item)
                                        ? _c("b-popover", {
                                            ref: "BTN_INFO_" + index,
                                            refInFor: true,
                                            attrs: {
                                              target: "BTN_INFO_" + index,
                                              show: _vm.showInfo[index],
                                              placement: "top",
                                              boundary: "viewport",
                                              triggers: "manual",
                                              content: _vm.$t("owner", [
                                                _vm.staffViews[index].owner,
                                              ]),
                                            },
                                            on: {
                                              "update:show": function ($event) {
                                                return _vm.$set(
                                                  _vm.showInfo,
                                                  index,
                                                  $event
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.editPermission(item)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "action-item-icon position-third",
                                              attrs: {
                                                id: "BTN_SHARE_" + index,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.shareColumnSettings(
                                                    index,
                                                    item.name,
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    item.defaultView
                                                      ? "fas"
                                                      : "far",
                                                    "share-nodes",
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.editPermission(item)
                                        ? _c("b-popover", {
                                            attrs: {
                                              target: "BTN_SHARE_" + index,
                                              placement: "top",
                                              boundary: "viewport",
                                              triggers: "hover",
                                              content: _vm.$t(
                                                _vm.id
                                                  ? "staff.button.share"
                                                  : "staff.button.share_planner"
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.editPermission(item)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "action-item-icon position-second",
                                              attrs: {
                                                id: "BTN_UPDATE_" + index,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.updateColumnSettings(
                                                    index,
                                                    item.name,
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "save"],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.editPermission(item)
                                        ? _c("b-popover", {
                                            attrs: {
                                              target: "BTN_UPDATE_" + index,
                                              placement: "top",
                                              boundary: "viewport",
                                              triggers: "hover",
                                              content: _vm.$t(
                                                _vm.id
                                                  ? "staff.button.update"
                                                  : "staff.button.update_planner"
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.editPermission(item)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "action-item-icon",
                                              attrs: {
                                                id: "BTN_DELETE_" + index,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.removeColumnSettings(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "trash-alt"],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.editPermission(item)
                                        ? _c("b-popover", {
                                            attrs: {
                                              target: "BTN_DELETE_" + index,
                                              placement: "top",
                                              boundary: "viewport",
                                              triggers: "hover",
                                              content: _vm.$t(
                                                _vm.id
                                                  ? "staff.button.delete"
                                                  : "staff.button.delete_planner"
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    {
                      on: _vm._d({ mouseleave: _vm.onColoringLeave }, [
                        _vm.colorMouseEnterEvent,
                        _vm.onColoringOver,
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "coloring",
                          staticClass: "action-bar-dropdown",
                          attrs: {
                            id: "BTN_COLORING_" + _vm.id,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "palette"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1244906976
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-group",
                            { attrs: { header: _vm.$t("colorby") } },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.onColorChange(
                                        "none",
                                        _vm.getProfileEntryName("coloring")
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [_vm._v(_vm._s(_vm.$t("none")))]
                                  ),
                                  _vm.coloring.none
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.onColorChange(
                                        "company",
                                        _vm.getProfileEntryName("coloring")
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("staff.coloring.company"))
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.company
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.onColorChange(
                                        "location",
                                        _vm.getProfileEntryName("coloring")
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("staff.coloring.location")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.location
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.onColorChange(
                                        "stage",
                                        _vm.getProfileEntryName("coloring")
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("staff.coloring.stage"))
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.stage
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.onColorChange(
                                        "project",
                                        _vm.getProfileEntryName("coloring")
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("staff.coloring.project"))
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.project
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.onColorChange(
                                        "department",
                                        _vm.getProfileEntryName("coloring")
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("staff.coloring.department")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.department
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.onColorChange(
                                        "skill",
                                        _vm.getProfileEntryName("coloring")
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("staff.coloring.skill"))
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.skill
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.onColorChange(
                                        "staff",
                                        _vm.getProfileEntryName("coloring")
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("staff.coloring.staff"))
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.staff
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.onColorChange(
                                        "event",
                                        _vm.getProfileEntryName("coloring")
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("staff.coloring.event"))
                                      ),
                                    ]
                                  ),
                                  _vm.coloring.event
                                    ? _c("font-awesome-icon", {
                                        staticClass: "active-check",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("li", [
                    _c(
                      "div",
                      { staticClass: "planner-locker" },
                      [
                        _c("span", { staticClass: "ml-1 mr-1" }, [
                          _vm._v(_vm._s(_vm.$t("lock"))),
                        ]),
                        _c("b-form-checkbox", {
                          attrs: { switch: "", "data-vv-name": "lockPlanner" },
                          on: { change: _vm.lockChange },
                          model: {
                            value: _vm.lockPlanner,
                            callback: function ($$v) {
                              _vm.lockPlanner = $$v
                            },
                            expression: "lockPlanner",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "menu-toggler" },
                [
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "settings-dropdown",
                      attrs: { offset: "25", "no-caret": "" },
                    },
                    [
                      _c("template", { slot: "button-content" }, [
                        _c(
                          "div",
                          { staticClass: "text" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "ellipsis-vertical"] },
                            }),
                          ],
                          1
                        ),
                      ]),
                      [
                        _c(
                          "b-dropdown-item",
                          { on: { click: _vm.showSettings } },
                          [_vm._v(_vm._s(_vm.$t("button.settings")))]
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("SchedulerToolbar", {
        attrs: {
          scheduler: _vm.scheduler,
          selected: _vm.selected,
          filter: _vm.show,
          showDeleteConfirmation: _vm.showDeleteConfirmation,
          showAdd:
            _vm.canAdd("BOOKING") ||
            _vm.canAdd("ACTIVITY") ||
            _vm.canAdd("TASK") ||
            _vm.canAdd("CALENDAR"),
        },
        on: {
          "update:showDeleteConfirmation": function ($event) {
            _vm.showDeleteConfirmation = $event
          },
          "update:show-delete-confirmation": function ($event) {
            _vm.showDeleteConfirmation = $event
          },
          copyEvent: _vm.copyEvent,
          pasteEvent: _vm.pasteEvent,
          addEvent: _vm.addEvent,
          editEvent: _vm.editEvent,
          deleteEvent: _vm.deleteEvent,
          import: _vm.fileImport,
          exportToFile: _vm.fileExport,
          schedulerToolbarCreated: _vm.schedulerToolbarCreated,
        },
      }),
      _vm.showLoadingOverlay
        ? _c(
            "div",
            {
              staticClass: "overlay",
              style: _vm.isWidget
                ? "height: " + _vm.height + "px"
                : "height: calc(100vh - " + _vm.heightOffset + "px)",
            },
            [
              _c("span", { staticClass: "grid-overlay" }, [
                _c("div", {
                  staticClass: "mr-1 spinner-grow spinner-grow-sm text-dark",
                }),
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      _vm.id || _vm.isDataView
                        ? "staff.grid.loading"
                        : "staff.grid.planner_loading"
                    )
                  )
                ),
              ]),
            ]
          )
        : _c("div", [
            _c(
              "div",
              {
                ref: "splitter-container",
                staticClass: "splitter-container",
                style: _vm.splitterStyle,
              },
              [
                _c(
                  "div",
                  { ref: "lhs-grid", staticClass: "lhs-grid" },
                  [
                    _c("ag-grid-vue", {
                      staticClass: "ag-theme-balham staffplanner-grid-height",
                      style: _vm.lhsGridStyle,
                      attrs: {
                        id: "pt-grid",
                        alwaysShowHorizontalScroll: "",
                        autoGroupColumnDef: _vm.autoGroupColumnDef,
                        cacheBlockSize: 10000,
                        columnDefs: _vm.columnDefs,
                        context: _vm.context,
                        defaultColDef: _vm.defaultColDef,
                        getDataPath: function (data) {
                          return data.path
                        },
                        getRowId: function (params) {
                          return params.data.key
                        },
                        gridOptions: _vm.gridOptions,
                        headerHeight: 40,
                        noRowsOverlayComponent: "noRowsOverlay",
                        noRowsOverlayComponentParams:
                          _vm.noRowsOverlayComponentParams,
                        overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                        processCellForClipboard: _vm.processCellForClipboard,
                        rowModelType: "clientSide",
                        rowMultiSelectWithClick: false,
                        rowSelection: "multiple",
                        serverSideInfiniteScroll: false,
                        sideBar: false,
                        singleClickEdit: false,
                        suppressCopyRowsToClipboard: "",
                        suppressDragLeaveHidesColumns: "",
                        suppressContextMenu: "",
                        suppressMultiSort: false,
                        treeData: "",
                      },
                      on: { "grid-ready": _vm.onGridReady },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    ref: "resizer",
                    staticClass: "resizer",
                    attrs: { id: "grid-gantt-resizer" },
                    on: { mousedown: _vm.mouseDownHandler },
                  },
                  [
                    _c(
                      "div",
                      {
                        ref: "resizer-overlay",
                        staticClass: "resizer-overlay",
                        attrs: { id: "resizer-overlay" },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "resizer-icon",
                          attrs: { icon: ["far", "arrows-left-right"] },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    ref: "rhs-chart",
                    staticClass: "rhs-chart",
                    staticStyle: { overflow: "hidden" },
                  },
                  [
                    _c("Scheduler", {
                      style: {
                        height: _vm.isWidget
                          ? _vm.height + "px"
                          : "calc(100vh - " + _vm.heightOffset + "px)",
                      },
                      attrs: {
                        collapseId: _vm.collapseId,
                        coloring: _vm.coloring,
                        customFields: _vm.customFields,
                        companyCustomFields: _vm.companyCustomFields,
                        locationCustomFields: _vm.locationCustomFields,
                        stageCustomFields: _vm.stageCustomFields,
                        projectCustomFields: _vm.projectCustomFields,
                        departmentCustomFields: _vm.departmentCustomFields,
                        skillCustomFields: _vm.skillCustomFields,
                        data: _vm.schedulerTasks,
                        deleteEventId: _vm.deleteEventId,
                        disableCreate:
                          !_vm.canAdd("BOOKING") &&
                          !_vm.canAdd("ACTIVITY") &&
                          !_vm.canAdd("TASK") &&
                          !_vm.canAdd("CALENDAR"),
                        endDate: _vm.schedulerEndDate,
                        expandId: _vm.expandId,
                        expandLevel: _vm.expandLevel,
                        fields: _vm.fields,
                        companyFields: _vm.companyFields,
                        locationFields: _vm.locationFields,
                        stageFields: _vm.stageFields,
                        projectFields: _vm.projectFields,
                        departmentFields: _vm.departmentFields,
                        skillFields: _vm.skillFields,
                        filter: _vm.show,
                        filterByProject: _vm.grouping.project,
                        filterObject: _vm.badgeFilters,
                        exportToFile: _vm.exportToFile,
                        grouping: _vm.grouping,
                        height: _vm.isWidget
                          ? _vm.height + "px"
                          : _vm.schedulerHeight > -1
                          ? _vm.schedulerHeight + "px"
                          : "calc(100vh - " + _vm.heightOffset2 + "px)",
                        markedTimespans: _vm.markedTimespans,
                        noRowsMessage: _vm.$t("staff.grid.no_data"),
                        permission: _vm.permissionName,
                        projectId: _vm.staffUsageProject
                          ? _vm.id
                          : "000000000000-0000-0000-0000-00000000",
                        projectName:
                          _vm.id && _vm.project ? _vm.project.name : null,
                        redraw: _vm.redrawScheduler,
                        redrawEventId: _vm.redrawEventId,
                        showAdd:
                          _vm.canAdd("BOOKING") ||
                          _vm.canAdd("ACTIVITY") ||
                          _vm.canAdd("TASK") ||
                          _vm.canAdd("CALENDAR"),
                        showNoRowsOverlay: _vm.showNoRowsOverlay,
                        showOvers: _vm.showOvers,
                        showOptimal: _vm.showOptimal,
                        showUnders: _vm.showUnders,
                        skipProcessNodes: _vm.skipProcessNodes,
                        holdUpdateUntilTreeDataChanged:
                          _vm.holdUpdateUntilTreeDataChanged,
                        span: _vm.schedulerSpan,
                        staffAlloc: _vm.staffAlloc,
                        startDate: _vm.schedulerStartDate,
                        unallocStart: _vm.unallocStart,
                        unallocEnd: _vm.unallocEnd,
                        width: _vm.schedulerWidth,
                        lockPlanner: _vm.lockPlanner,
                      },
                      on: {
                        "update:skipProcessNodes": function ($event) {
                          _vm.skipProcessNodes = $event
                        },
                        "update:skip-process-nodes": function ($event) {
                          _vm.skipProcessNodes = $event
                        },
                        "update:holdUpdateUntilTreeDataChanged": function (
                          $event
                        ) {
                          _vm.holdUpdateUntilTreeDataChanged = $event
                        },
                        "update:hold-update-until-tree-data-changed": function (
                          $event
                        ) {
                          _vm.holdUpdateUntilTreeDataChanged = $event
                        },
                        clickItem: _vm.schedulerClickItem,
                        collapsedId: _vm.onCollapsedId,
                        copyEvent: _vm.copyEvent,
                        dateChange: _vm.dateChange,
                        deleteEvent: function ($event) {
                          _vm.showDeleteConfirmation = true
                        },
                        expandedId: _vm.onExpandedId,
                        exportEnd: _vm.exportEnd,
                        exportStart: _vm.exportStart,
                        folderToggle: _vm.folderToggle,
                        gotoday: _vm.gotoDaily,
                        markedSpansCreated: _vm.markedSpansCreated,
                        pasteEvent: _vm.pasteEvent,
                        redrawn: _vm.schedulerRedrawn,
                        redrawEventComplete: _vm.onRedrawEventComplete,
                        schedulerCreated: _vm.schedulerCreated,
                        schedulerDataRender: _vm.schedulerDataRender,
                        schedulerScroll: _vm.ganttScrollHandler,
                        selectionChanged: _vm.onSelectionChanged,
                        showtasks: _vm.onCellClicked,
                        tooltip: _vm.onTooltip,
                        updated: _vm.eventUpdated,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
      _c("ActivityModal", {
        attrs: {
          readOnly: _vm.activityEdit.readonly,
          show: _vm.activityEditShow,
          id: _vm.activityEdit.uuId,
        },
        on: {
          "update:show": function ($event) {
            _vm.activityEditShow = $event
          },
          success: _vm.modalSuccessNoReload,
          "update:id": function ($event) {
            return _vm.$set(_vm.activityEdit, "uuId", $event)
          },
        },
      }),
      _c("BookingModal", {
        attrs: {
          readOnly: _vm.bookingEdit.readonly,
          show: _vm.bookingEditShow,
          id: _vm.bookingEdit.uuId,
        },
        on: {
          "update:show": function ($event) {
            _vm.bookingEditShow = $event
          },
          success: _vm.modalSuccessNoReload,
          "update:id": function ($event) {
            return _vm.$set(_vm.bookingEdit, "uuId", $event)
          },
        },
      }),
      _c("TaskModal", {
        attrs: {
          readOnly: _vm.taskEdit.readonly,
          show: _vm.taskEditShow,
          id: _vm.taskEdit.uuId,
          projectId: _vm.taskEdit.projectUuid,
        },
        on: {
          "update:show": function ($event) {
            _vm.taskEditShow = $event
          },
          success: _vm.modalSuccessNoReload,
          "update:id": function ($event) {
            return _vm.$set(_vm.taskEdit, "uuId", $event)
          },
        },
      }),
      _vm.stageShow
        ? _c("StageModal", {
            attrs: {
              id: _vm.stageId,
              show: _vm.stageShow,
              title: _vm.stageTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.stageShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _vm.staffShow
        ? _c("StaffModal", {
            attrs: {
              id: _vm.staffId,
              show: _vm.staffShow,
              title: _vm.$t(
                _vm.isGeneric
                  ? "staff.title_generic_detail"
                  : "staff.title_detail"
              ),
              isGeneric: _vm.isGeneric,
            },
            on: {
              "update:show": function ($event) {
                _vm.staffShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _vm.skillShow
        ? _c("SkillModal", {
            attrs: {
              id: _vm.skillId,
              show: _vm.skillShow,
              title: _vm.$t("skill.title_detail"),
            },
            on: {
              "update:show": function ($event) {
                _vm.skillShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _c("LocationModal", {
        attrs: {
          id: _vm.locationId,
          show: _vm.locationShow,
          title: _vm.$t("location.title_detail"),
        },
        on: {
          "update:show": function ($event) {
            _vm.locationShow = $event
          },
          success: _vm.modalSuccess,
        },
      }),
      _vm.allowManage && _vm.departmentShow
        ? _c("DepartmentModal", {
            attrs: {
              id: _vm.departmentId,
              show: _vm.departmentShow,
              title: _vm.$t("department.title_detail"),
              queryParent: true,
            },
            on: {
              "update:show": function ($event) {
                _vm.departmentShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _vm.allowManage && _vm.companyShow
        ? _c("CompanyModal", {
            attrs: {
              id: _vm.companyId,
              show: _vm.companyShow,
              title: _vm.$t("company.title_detail"),
            },
            on: {
              "update:show": function ($event) {
                _vm.companyShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptSaveShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.save"),
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptSaveShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptShareShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.share"),
          sharing: true,
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptShareShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c("TaskSelectorModal", {
        attrs: {
          title: _vm.$t("staff.select_task_title"),
          show: _vm.taskSelectShow,
          data: _vm.taskSelectData,
          "ok-title": _vm.$t("button.select"),
          "ok-disabled": _vm.taskEdit.uuId === null,
        },
        on: {
          "update:show": function ($event) {
            _vm.taskSelectShow = $event
          },
          ok: _vm.taskSelectOk,
        },
      }),
      _vm.projectShow
        ? _c("ProjectModal", {
            attrs: {
              id: _vm.projectEditId,
              show: _vm.projectShow,
              title: _vm.$t("project.title_detail"),
            },
            on: {
              "update:show": function ($event) {
                _vm.projectShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _c("ExceptionModal", {
        attrs: {
          event: _vm.vacationEdit,
          baseStartHour: _vm.baseStartHour,
          baseEndHour: _vm.baseEndHour,
          title: _vm.$t(
            _vm.vacationEdit.readonly
              ? "location.view_exception"
              : "location.edit_exception"
          ),
          readOnly: _vm.vacationEdit.readonly,
          show: _vm.vacationShow,
        },
        on: {
          "update:show": function ($event) {
            _vm.vacationShow = $event
          },
          success: _vm.vacationSuccess,
        },
      }),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
          cancel: _vm.progressCancel,
        },
      }),
      _c("PlannerSettingsModal", {
        attrs: {
          show: _vm.settingsShow,
          settings: _vm.show,
          title: _vm.$t("staff.planner_settings.title"),
        },
        on: {
          "update:show": function ($event) {
            _vm.settingsShow = $event
          },
          success: _vm.plannerSettingsSuccess,
        },
      }),
      _c("TaskDateTimeDurationCalculation", {
        attrs: {
          show: _vm.durationCalculationShow,
          defaultActionForNonWorkPrompt: null,
          trigger: _vm.durationCalculation.trigger,
          startDateStr: _vm.durationCalculation.startDateStr,
          startTimeStr: _vm.durationCalculation.startTimeStr,
          closeDateStr: _vm.durationCalculation.closeDateStr,
          closeTimeStr: _vm.durationCalculation.closeTimeStr,
          durationDisplay: _vm.durationCalculation.durationDisplay,
          calendar: _vm.durationCalculation.calendar,
          projectScheduleFromStart:
            _vm.durationCalculation.projScheduleFromStart,
          bookingAutoScheduleMode:
            _vm.durationCalculation.bookingAutoScheduleMode,
          constraintType: _vm.durationCalculation.constraintType,
          constraintDateStr: _vm.durationCalculation.constraintDateStr,
          lockDuration: _vm.durationCalculation.lockDuration,
          oldDateStr: _vm.durationCalculation.oldDateStr,
          oldTimeStr: _vm.durationCalculation.oldTimeStr,
          oldConstraintType: _vm.durationCalculation.oldConstraintType,
          oldConstraintDateStr: _vm.durationCalculation.oldConstraintDateStr,
          skipOutOfProjectDateCheck:
            _vm.durationCalculation.skipOutOfProjectDateCheck,
          resizeMode: _vm.durationCalculation.resizeMode,
          durationConversionOpts: _vm.durationConversionOpts,
        },
        on: {
          "update:show": function ($event) {
            _vm.durationCalculationShow = $event
          },
          "update:calendar": function ($event) {
            return _vm.$set(_vm.durationCalculation, "calendar", $event)
          },
          success: _vm.durationCalculationOk,
          cancel: _vm.durationCalculationCancel,
          skip: _vm.durationCalculationCancel,
          calendarChange: _vm.durationCalculationCalendarChange,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteViewOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteViewShow,
            callback: function ($$v) {
              _vm.confirmDeleteViewShow = $$v
            },
            expression: "confirmDeleteViewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("task.confirmation.delete_view")) + " "),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("vacation.confirmation.show"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.showVacationOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptShowVacation,
            callback: function ($$v) {
              _vm.promptShowVacation = $$v
            },
            expression: "promptShowVacation",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("vacation.confirmation.show_events")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("activity.confirmation.show"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.showActivityOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptShowActivity,
            callback: function ($$v) {
              _vm.promptShowActivity = $$v
            },
            expression: "promptShowActivity",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("activity.confirmation.show_events")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("booking.confirmation.show"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.showBookingOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptShowBooking,
            callback: function ($$v) {
              _vm.promptShowBooking = $$v
            },
            expression: "promptShowBooking",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("booking.confirmation.show_events")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.show"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.showTaskOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptShowTask,
            callback: function ($$v) {
              _vm.promptShowTask = $$v
            },
            expression: "promptShowTask",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("task.confirmation.show_events")) + " "),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.field.autoScheduling"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.scheduleChangeOk, cancel: _vm.scheduleChangeCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptScheduleChange,
            callback: function ($$v) {
              _vm.promptScheduleChange = $$v
            },
            expression: "promptScheduleChange",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("task.confirmation.task_schedule_change")) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("staff.maximum_grouping_title"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.groupingAlertOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showGroupingAlert,
            callback: function ($$v) {
              _vm.showGroupingAlert = $$v
            },
            expression: "showGroupingAlert",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("staff.maximum_grouping")) + " "),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("staff.unallocated"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.unallocatedOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptUnallocated,
            callback: function ($$v) {
              _vm.promptUnallocated = $$v
            },
            expression: "promptUnallocated",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("staff.unallocated_hint")))]),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", xl: "6" } },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "mr-1 align-self-baseline",
                          attrs: { for: "unallocStart" },
                        },
                        [_vm._v(_vm._s(_vm.$t("staff.from")))]
                      ),
                      _c("b-form-datepicker", {
                        staticClass: "d-flex",
                        attrs: {
                          "today-button": "",
                          "reset-button": "",
                          "close-button": "",
                          "hide-header": "",
                          "label-today-button": _vm.$t("date.today"),
                          "label-reset-button": _vm.$t("date.reset"),
                          "label-close-button": _vm.$t("date.close"),
                          "today-button-variant": "primary",
                          "reset-button-variant": "danger",
                          "close-button-variant": "secondary",
                          size: "sm",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "button-content",
                            fn: function (ref) {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "calendar-days"] },
                                }),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.unallocStart,
                          callback: function ($$v) {
                            _vm.unallocStart = $$v
                          },
                          expression: "unallocStart",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", xl: "6" } },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "mr-1 align-self-baseline",
                          attrs: { for: "unallocEnd" },
                        },
                        [_vm._v(_vm._s(_vm.$t("staff.to")))]
                      ),
                      _c("b-form-datepicker", {
                        staticClass: "d-flex",
                        attrs: {
                          "today-button": "",
                          "reset-button": "",
                          "close-button": "",
                          "hide-header": "",
                          "label-today-button": _vm.$t("date.today"),
                          "label-reset-button": _vm.$t("date.reset"),
                          "label-close-button": _vm.$t("date.close"),
                          "today-button-variant": "primary",
                          "reset-button-variant": "danger",
                          "close-button-variant": "secondary",
                          size: "sm",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "button-content",
                            fn: function (ref) {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "calendar-days"] },
                                }),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.unallocEnd,
                          callback: function ($$v) {
                            _vm.unallocEnd = $$v
                          },
                          expression: "unallocEnd",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("EventTypeModal", {
        attrs: {
          title: _vm.$t("staff.event_type"),
          show: _vm.promptType,
          event: _vm.newEvent,
          profileValues: _vm.eventTypeValues,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptType = $event
          },
          ok: _vm.onPromptTypeOk,
          cancel: _vm.onPromptTypeCancel,
        },
      }),
      _c("SelectImportType", {
        attrs: {
          title: _vm.$t("staff.select_import"),
          show: _vm.selectImportShow,
        },
        on: {
          ok: _vm.onSelectImportTypeOk,
          "update:show": function ($event) {
            _vm.selectImportShow = $event
          },
        },
      }),
      _c("GanttImportDialog", {
        attrs: {
          properties: _vm.importProperties,
          mode: _vm.importType,
          show: _vm.docImportShow,
        },
        on: {
          "modal-ok": _vm.docImportOk,
          "modal-cancel": _vm.docImportCancel,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }