
import { httpAjax } from '@/helpers';

export const taskLinkResourceService = {
  create,
  update,
  remove,
};

/**
 * Create a new Link from task to resource 
 * by passing necessary information
 * @param String taskId, Task's UUID
 * @param Array resources, Resource details. Data sample: [{ uuId, level }]
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', resourceList: [ { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', resourceLink: { unit: 1, utilization: 1.00 } }] }
 */
function create(taskId, resources) {
  const url = '/api/task/link/resource/add';
  const config = {
    headers: getHeaders()
  };

  const resourceList = []
  resources.forEach(i => {
    const custom = { ...i };
    delete custom['name'];
    delete custom['uuId'];
    delete custom['utilization'];
    delete custom['quantity'];
    
    resourceList.push( {uuId: i.uuId, resourceLink: { quantity: i.quantity, utilization: i.utilization ? i.utilization : 1.00, ...custom }} );
  });

  const data = {
    uuId: taskId,
    resourceList: resourceList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Update existing link details of task to resource.
 * by passing  necessary information
 * @param String taskId, Task's UUID
 * @param Array resources, Resource details. Data sample: [{ uuId, level }] 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', resourceList: [ { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', resourceLink: { unit: 1, utilization: 1.00 } }] }
 */
function update(taskId, resources) {
  const url = '/api/task/link/resource/update';
  const config = {
    headers: getHeaders()
  }

  const resourceList = []
  resources.forEach(i => {
    const custom = { ...i };
    delete custom['name'];
    delete custom['uuId'];
    delete custom['utilization'];
    delete custom['quantity'];
    
    resourceList.push( {uuId: i.uuId, resourceLink: { quantity: i.quantity, utilization: i.utilization ? i.utilization : 1.00, ...custom }} );
  });

  const data = {
    uuId: taskId,
    resourceList: resourceList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from task to resource.
 * by passing in an array list of links uuIds
 * @param String taskId, Task's UUID
 * @param Array resourceIds, list of Resource's UUID 
 * request body format sample: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', resourceList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }]
 */
function remove(taskId, resources) {
  const url = '/api/task/link/resource/delete';
  const config = {
    headers: getHeaders()
  };

  const resourceList = []
  resources.forEach(i => {
    resourceList.push( {uuId: i.uuId} );
  });

  const data = {
    uuId: taskId,
    resourceList: resourceList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}