var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-text" },
    [
      _vm._v(_vm._s(_vm.label) + " "),
      _vm.partial
        ? _c("font-awesome-icon", {
            attrs: {
              id: "HEADER_" + _vm.params.column.colId,
              icon: ["far", "circle-info"],
            },
          })
        : _vm._e(),
      _vm.partial
        ? _c("b-popover", {
            attrs: {
              target: "HEADER_" + _vm.params.column.colId,
              placement: "top",
              triggers: "hover",
              content: _vm.$t("staff.partial"),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }