<template>
  <span>{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import { convertDurationToDisplay } from '@/helpers/task-duration-process';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'DurationCellRenderer',
  data() {
    return {
      value: null,
      durationConversionOpts: {}
    }
  },
  created() {
    if (this.params.context?.componentParent?.durationConversionOpts != null) {
      this.durationConversionOpts = this.params.context.componentParent.durationConversionOpts;
    }
  },
  beforeMount() {
    if (this.params.unit) {
      this.value = Math.round(this.params.value) + ' ' + this.params.unit;
    } else if (typeof this.params.value !== 'string') {
      this.value = convertDurationToDisplay(this.params.value / 60000, 'D', this.durationConversionOpts);
    } else {
      this.value = this.params.value;
    }
    setReadOnlyIfNotEditable(this.params);
  },
  computed: {
    label() {
      return this.value? this.value : '';
    }
  }
})
</script>
