export function costFormat(value, { locale='en', notation='compact' } = {}) {
  let _val = value;
  // eslint-disable-next-line
  if (!_val || !/^-*[\d,\.]+$/.test(_val)) {
    _val = 0;
  }
  let formatter = Intl.NumberFormat(locale, { notation });
  return formatter.format(_val);
}

export function costFormatAdv(value, currencyCode, { locale='en', notation='compact'}={}) {
  let _val = value;
  // eslint-disable-next-line
  if (!_val || !/^-*[\d,\.]+$/.test(_val)) {
    _val = 0;
  }
  
  // make sure we have a currency code
  if (currencyCode === "") {
    currencyCode = "USD";
  }
  let formatter = Intl.NumberFormat(locale, { notation, style: 'currency', currency: currencyCode });
  return formatter.format(_val);
}