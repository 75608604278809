<template>
  <div class="edit-cell">
    <span>{{ label }}</span>
    <TaskConstraintModal :show.sync="selectorShow" :constraintType="value.type" :constraintTime="value.time" :optionConstraint="optionConstraint" @ok="ok"/>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);

export default Vue.extend({
  name: 'ConstraintEditor',
  components: {
    TaskConstraintModal: () => import('@/components/modal/TaskConstraintModal')
  },
  data() {
    return {
      selectorShow: true,
      value: null,
      optionConstraint: null,
      compactMode: false,
    };
  },
  watch: {
    selectorShow(newValue) {
      if(!newValue) {
        this.closePicker();
      }
    }
  },
  methods: {
    /** Reserved methods START */
    getValue() {
      if (this.compactMode) {
        const curValue = this.value;
        const orgValue = this.params.value.single;
        if (orgValue == curValue) {
          return this.params.value;
        } else if (orgValue == null) {
          if (curValue != null && curValue.type == null) {
            return this.params.value;
          }
          return this.value;
        } else if (curValue.type == orgValue.type && curValue.time == orgValue.time) {
          return this.params.value;
        }
        this.params.value.single = curValue;
        return cloneDeep(this.params.value);
      } else {
        return this.value;
      }
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },
    /** Reserved methods END */

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    ok({ constraintType, constraintTime }) {
      if(this.value.type != constraintType || this.value.time != constraintTime) {
        this.value = { type: constraintType, time: constraintTime };
      }
      this.closePicker();
    },
    prepareValue() {
      if (this.compactMode) {
        if (this.params.value != null && this.params.value.single != null) {
          this.value = this.params.value.single;
        } else {
          this.value = { type: null, time: null }
        }
      } else {
        if (this.params.value != null) {
          this.value = this.params.value;
        } else {
          this.value = { type: null, time: 0 }
        }
      }
    }
  },
  created() {
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    this.prepareValue();
    this.optionConstraint = this.params.options != null? this.params.options : [];
  },
  mounted() {

  },
  computed: {
    label() {
      if (this.value.type == null) {
        return '';
      }
      const type = this.params.context.componentParent.$t(`constraint_type_abbreviation.${this.value.type}`);
      return this.value.time == null || this.value.time == 0? type : `${type} ${moment(this.value.time).format('L')}`;
    }
  }
})
</script>