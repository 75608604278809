import { 
  msToTime
} from '@/helpers';

export function processCalendar(data) {
  let calendar = {};
  for (let i = 0, len = data.length; i < len; i++) {
    const name = data[i].name;
    for (let j = 0, jLen = data[i].calendarList.length; j < jLen; j++) {
      const type = data[i].calendarList[j].type;
      if (typeof calendar[type] === 'undefined') {
        calendar[type] = {};
      }
      if (typeof calendar[type][name] === 'undefined') {
        calendar[type][name] = [];
      }
      calendar[type][name].push(data[i].calendarList[j]);
    }
  }
  return calendar;
}

export function transformCalendar(calendar) {
  for (const key of Object.keys(calendar)) {
    // Duration calculation requires calendar be arrays
    if (typeof calendar[key] === 'object') {
      if (calendar[key].staff) {
        const location = calendar[key].location; // we may need this for Leave
        calendar[key] = calendar[key].staff;
        if (key === 'Leave') {
          // we want the location holidays also
          if (location) {
            calendar[key].push(...location);
          }
        }
      }
      else if (calendar[key].location) {
        calendar[key] = calendar[key].location;
      }
      else if (calendar[key].base_calendar) {
        calendar[key] = calendar[key].base_calendar;
      }
      
      if (calendar[key]) {
        for (const cal of calendar[key]) {
          if (typeof cal.startHour === 'number') {
            cal.startHour = msToTime(cal.startHour);
          }
          if (typeof cal.endHour === 'number') {
            cal.endHour = msToTime(cal.endHour);
          }
        }
      }
    }
  }
  return calendar;
}

export const CALENDAR_WEEKDAY_NAME = [
  'Sunday'
  , 'Monday'
  , 'Tuesday'
  , 'Wednesday'
  , 'Thursday'
  , 'Friday'
  , 'Saturday'
]
Object.freeze(CALENDAR_WEEKDAY_NAME);