<template>
  <span>{{ label }}</span>
</template>

<script>
import { cloneDeep } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'StaffTypeCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    this.prepareValue();
  },
  computed: {
    label() {
      return this.value? this.value : '';
    }
  },
  methods: {
    prepareValue() {
      const data = this.params != null && this.params.data != null? this.params.data : null;
      this.value = data  != null && data.staffType != null? data.staffType : (this.params != null && this.params.value != null? this.params.value : null);
    }
  }
})
</script>
