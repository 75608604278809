import cColors from "@/_chartColors";

export default {
/**
 * i: A unique identifier within this set. Increment as you add (TODO: why not just use type?)
 * type: Unique key within *dashboard* (prefix duplicates with 'p' for project, h for home)
 * label: Used in the settings menu to know which widget to toggle.
 * maxh, maxw: Maximum height and width of the widget.
 * minh, minw: Minimum height and width of the widget.
 * 
 * The following parameters are used when creating the board or the 'reset dashboard' button
 * is pressed. If the widget is loaded from user settings, prefer the user's setting instead.
 * enable: Whether to show the widget on the board
 * x, y: Board co-ordinates
 * w, h: Widget dimensions.
 */
home: [
  { enable: true, "x":7,"y":7,"w":5,"h":4,"i":"6", type:'staff', label: 'Staff Utilization', "minw": 5, "minh": 3},
  { enable: true, "x":7,"y":4,"w":5,"h":3,"i":"12", type:'myschedule', label: 'My Work Schedule', "minw": 5, "minh": 3},
  { enable: true, "x":0,"y":14,"w":6,"h":6,"i":"13", type:'departmentstaff', label: 'Departments', "minw": 4, "minh": 4},
  { enable: true, "x":0,"y":11,"w":12,"h":3,"i":"14", type:'activetasks', label: 'Active Tasks', "minw": 5, "minh": 3},
  { enable: true, "x":0,"y":4,"w":7,"h":4,"i":"15", type:'mytasks', label: 'My Tasks', "minw": 5, "minh": 3},
  { enable: true, "x":0,"y":8,"w":7,"h":3,"i":"16", type:'activeprojects', label: 'Active Projects', "minw": 5, "minh": 3},
  { enable: true, "x":6,"y":14,"w":6,"h":6,"i":"17", type:'hskills', label: 'Staff Skills', "minw": 4, "minh": 4},
  { enable: true, "x":0,"y":0,"w":12,"h":3,"i":"19", type:'timeline', label: 'Project Timeline', "minw": 5, "minh": 4, useDefault: true},
],

project: [
  { enable: true, "x":0,"y":0,"w":5,"h":6,"i":"1", type:'info', label: 'Information', "minw": 4, "minh": 4},
  { enable: true, "x":5,"y":0,"w":4,"h":4,"i":"2", type:'progress', label: 'Progress', "minw": 3, "minh": 3},
  { enable: true, "x":9,"y":0,"w":3,"h":4,"i":"3", type:'tasks', label: 'Tasks', "minw": 3, "minh": 3},
  { enable: true, "x":5,"y":12,"w":7,"h":5,"i":"4", type:'pstaff', label: 'Staff', "minw": 4, "minh": 3},
  { enable: true, "x":0,"y":10,"w":5,"h":5,"i":"6", type:'kanban', label: 'Kanban Board', "minw": 3, "minh": 3 },
  { enable: true, "x":0,"y":15,"w":5,"h":5,"i":"8", type:'pbudget',label: 'Budget vs Actual Costs', "maxh":7, "minw": 3, "minh":6},
  { enable: true, "x":5,"y":17,"w":7,"h":5,"i":"9", type:'skills', label: 'Skills', "minw": 4, "minh": 4},
  //{ enable: true, "x":5,"y":17,"w":7,"h":5,"i":"20", type:'departments', label: 'Departments', "minw": 4, "minh": 4},
  { enable: true, "x":0,"y":6,"w":5,"h":4,"i":"10", type:'milestones', label: 'Milestones', "minw": 4, "minh": 3},
  { enable: true, "x":5,"y":4,"w":7,"h":4,"i":"11", type:'talerts', label: 'Task Alerts', "minw": 4, "minh": 3},
  { enable: true, "x":5,"y":8,"w":7,"h":4,"i":"18", type:'pactivetasks', label: 'Active Tasks', "minw": 4, "minh": 3},
],

progress:  { enable: false, "w":3,"h":4,"i":"_", type:'gauge', label: 'Progress', "minw": 3, "minh": 4 },
launchpad: { enable: false, "w":3,"h":4,"i":"_", type:'launchpad', label: 'Launchpad', "minw": 2, "minh": 2 },
dataview: { enable: false, "w":12,"h":6,"i":"_", type:'dataview', label: 'Data View', "minw": 4, "minh": 4 },

  /**
   * 
   * @param orientation True for portrait, false for landscape
   * @param itemCount Number of items in the chart
   */
  getPieChartOptions(orientation, width, height, items) {
    var base = {
      title: {
        text: "", // Override this
        fontSize: 14,
        color: cColors.getThemeColor('text-dark'),
        fontWeight: 'bold',
        fontFamily: 'Roboto',
      },
      background: {
        fill: '#00000000', // Transparent in every theme
      },
      padding: {top: 30, bottom: 30, left: 80, right: 80},
      series: [
        {
          data: [],// Override this
          type: 'pie',
          calloutLabelKey: '', // Override this
          angleKey: '', // Override this
          calloutLabel: {
            minAngle: 10,
            fontSize: 12,
            offset: 3,
            color: cColors.getThemeColor('text-medium'),
            fontFamily: 'Roboto',
          },
          calloutLine: {
            strokeWidth: 0,
            length: 8,
            colors: this.getPieChartCalloutColors()
          },
          fills: this.getChartFills(),
          strokes: this.getPieChartStrokes(),
          strokeWidth: 2,
          // tooltipRenderer: // Optionally override this
        },
      ],
      legend: {
        enabled: true,
        position: orientation ? 'bottom' : 'right',
        spacing: 4,
        item: { 
          marker: {
            size: 8,
          },
          label: { 
            color: cColors.getThemeColor('text-medium'),
            fontFamily: 'Roboto',
          },
          paddingX: 8,
          paddingY: 4 
        }
      }
    }

    // Hide legend in smaller frames
    if (orientation && height < 400) {
      base.legend = {
        enabled: false
      };
    }
    if (!orientation && width < 400) {
      base.legend = {
        enabled: false
      };
    }

    // Small
    if (width < 350) {     
      base.padding = {left: 30, right: 30};
      base.series[0].calloutLabel.fontSize = 10;
      base.series[0].calloutLabel.offset = 1;
    }

    // Very small
    if (width < 250) {
      base.padding = {left: 15, right: 15};
      base.series[0].calloutLabel.offset = 0;
    }
    return base;
  },
  getChartFills() {
    return [
      '#febe76',
      '#ff7979',
      '#badc58',
      '#f9ca23',
      '#f0932b',
      '#eb4c4b',
      '#6ab04c',
      '#7ed6df',
      "#FFA07A",
      "#A0522D",
      "#87CEEB",
      "#FFFACD",
      "#DDA0DD",
      "#9400D3",
      "#F5FFFA",
      "#228B22",
      "#FDF5E6",
      "#66CDAA",
      "#008000",
      "#FFDEAD",
      "#FFA500",
      "#008080",
      "#D2691E",
      "#2E8B57",
      "#32CD32",
      "#20B2AA",
      "#800080",
      "#FFE4E1",
      "#FF69B4",
      "#000080",
      "#8B008B",
      "#D2B48C",
      "#BDB76B",
      "#556B2F",
      "#7B68EE",
      "#A9A9A9",
      "#00FFFF",
      "#7B68EE",
      "#FF8C00",
      "#7FFFD4",
      "#00008B",
      "#006400",
      "#00CED1",
      "#B0E0E6",
      "#696969",
      "#FFB6C1",
      "#FAEBD7",
      "#00FA9A",
      "#ADFF2F",
      "#800000",
      "#BC8F8F",
      "#8FBC8F",
      "#FFC0CB",
      "#F5DEB3",
      "#6B8E23",
      "#FF6347",
      "#48D1CC",
      "#00FFFF",
      "#708090",
      "#DB7093",
      "#E9967A",
      "#FFD700",
      "#B8860B",
      "#6495ED",
      "#FF00FF",
      "#87CEFA",
      "#FFF5EE",
      "#8B0000",
      "#F0E68C",
      "#4682B4",
      "#6A5ACD",
      "#C0C0C0",
      "#FFEBCD",
      "#DAA520",
      "#3CB371",
      "#CD853F",
      "#5F9EA0",
      "#F08080",
      "#DCDCDC",
      "#9ACD32",
      "#808080",
      "#191970",
      "#FFFF00",
      "#FFE4B5",
      "#FAF0E6",
      "#808000",
      "#D8BFD8",
      "#C71585",
      "#FFE4C4",
      "#FF0000",
      "#4B0082",
      "#4169E1",
      "#FFFAFA",
      "#FAFAD2",
      "#FF7F50",
      "#00FF7F",
      "#EEE8AA",
      "#E6E6FA",
      "#1E90FF",
      "#0000CD",
      "#DC143C",
      "#FFF0F5",
      "#00BFFF",
      "#98FB98",
      "#AFEEEE",
      "#D3D3D3",
      "#90EE90",
      "#0000FF",
      "#DEB887",
      "#EE82EE",
      "#FFDAB9",
      "#FFFFE0",
      "#40E0D0",
      "#A52A2A",
      "#008B8B",
      "#7CFC00",
      "#CD5C5C",
      "#ADD8E6",
      "#B22222",
      "#E0FFFF",
      "#00FF00",
      "#FFEFD5",
      "#9966CC",
      "#FF00FF",
      "#BA55D3",
      "#FFF8DC",
      "#F5F5DC",
      "#F0F8FF",
      "#F0FFF0",
      "#9370DB",
      "#B0C4DE",
      "#FF4500",
      "#9932CC",
      "#FF1493",
      "#8A2BE2",
      "#FFA07A",
      "#778899",
      "#DA70D6",
      "#F0FFFF",
      "#7FFF00",
      "#2F4F4F",
      "#FFFFF0",
      "#483D8B",
      "#F4A460",
      "#FA8072",
      "#8B4513",
      "#000000",
    ];
  },
  getPieChartStrokes() {
    return [
      cColors.getThemeColor('stroke'),
    ];
  },
  getPieChartCalloutColors() {
    return this.getChartFills();
  },
  /**
   * Generate tooltips with a count of the item and its name.
   * @param key "labelKey" from the series to use in tooltip
   * @param itemName E.g, "task" will render "5 tasks"
   * @param False to turn off pluralization (workaround for "staff")
   */
  getChartTooltipRenderer(key, itemName, plural=true) {
    const maybePluralize = (count, noun, suffix = 's') =>
    `${count} ${noun}${count !== 1 ? suffix : ''}`;

    return params => {
      // Change the hover tooltip text
      let lines = [
        '<div style="padding: 10px;">',
        params.datum[key] + ": ",
        null, // put text in this slot
        '</div>'
      ]
      const count = params.datum.count;
      lines[2] = plural ? maybePluralize(count, itemName) : count + ' ' + itemName;
      return lines.join("");
    }
  },
  getBarChartOptions(width) {
    var base = {
      data: [],// Override this
      title: {
        text: "", // Override this
        fontSize: 14,
        color: cColors.getThemeColor('text-dark'),
        fontWeight: 'bold',
        fontFamily: 'Roboto',
      },
      background: {
        fill: '#00000000', // Transparent in every theme
      },
      padding: {left: 0, right: 8, top: 4, bottom: 6},
      series: [
        {
          type: 'bar',
          xKey: '', // Override this
          yKeys: '', // Override this
          label: {
            formatter: params => {
              if (params.value == 0) {
                // Bar width is 0 when value is 0 and looks bad, so don't show anything
                return "";
              }
              return params.value.toFixed(0);
            },
            fontSize: 8,
            fontWeight: 'bold',
            color: cColors.getThemeColor('text-on-bar'),
            fontFamily: 'Roboto',
          },
          fills: [cColors.getThemeColor('progress-25')],
          strokes: [cColors.getThemeColor('progress-25')],
          // tooltipRenderer: // Optionally override this
        },
      ],
      axes: [
        {
          type: 'number',
          position: 'bottom',
          label: { color: cColors.getThemeColor('text-medium'), fontFamily: 'Roboto', },
          title: {text: ''},
          tick: {
            count: 10
          },
          gridStyle: [{ stroke: cColors.getThemeColor('axis-stroke'), lineDash: [0, 0] }]
        },
        {
          type: 'category',
          position: 'left',
          label: { color: cColors.getThemeColor('text-medium'), fontFamily: 'Roboto', },
          title: { text: '' }, // Override this
          gridStyle: [{ stroke: cColors.getThemeColor('axis-stroke'), lineDash: [0, 0] }]
        },
      ],
      legend: {
        enabled: true,
        item: { label: { color: cColors.getThemeColor('text-medium'), fontFamily: 'Roboto',}}
      }
    }

    if (width < 450) {
      base.legend.enabled = false;
    }
    return base;
  },
  getColumnChartOptions(width) {
    var base = {
      title: {
        text: "", // Override this
        fontSize: 14,
        color: cColors.getThemeColor('text-dark'),
        fontWeight: 'bold',
        fontFamily: 'Roboto',
      },
      background: {
        fill: '#00000000', // Transparent in every theme
      },
      series: [
        {
          data: [],// Override this
          type: 'column',
          xKey: '', // Override this
          yKeys: [], // Override this

          fills: this.getChartFills(),
          strokes: this.getPieChartStrokes(),
          strokeWidth: 2,
          // tooltipRenderer: // Optionally override this
        },
      ],
      legend: {
        enabled: true,
        position: 'right',
        spacing: 4,
        item: { 
          marker: {
            size: 8,
          },
          label: { 
            color: cColors.getThemeColor('text-medium'),
            fontFamily: 'Roboto',
          },
          paddingX: 8,
          paddingY: 4 
        }
      },
      axes: [
        {
          type: 'category',
          position: 'bottom',
          label: { color: cColors.getThemeColor('text-medium'), fontFamily: 'Roboto', },
          title: {text: ''},
          gridStyle: [{ stroke: cColors.getThemeColor('axis-stroke'), lineDash: [0, 0] }]
        },
        {
          type: 'number',
          position: 'left',
          label: { color: cColors.getThemeColor('text-medium'), fontFamily: 'Roboto', },
          title: { text: '' }, // Override this
          tick: {
            
          },
          gridStyle: [{ stroke: cColors.getThemeColor('axis-stroke'), lineDash: [0, 0] }]
        }
      ]
    }

    // Small
    if (width < 350) {     
      base.padding = {left: 30, right: 30};
      base.series[0].calloutLabel.fontSize = 10;
      base.series[0].calloutLabel.offset = 1;
    }

    // Very small
    if (width < 250) {
      base.padding = {left: 15, right: 15};
      base.series[0].calloutLabel.offset = 0;
    }
    return base;
  },
}
