var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ol",
        { staticClass: "breadcrumb" },
        [
          _vm._l(_vm.routeRecords, function (routeObject, index) {
            return [
              _vm.hasParam(routeObject) && _vm.isLast(index)
                ? [
                    _vm.hasMetaLabel(routeObject)
                      ? [
                          _c(
                            "li",
                            { key: index, staticClass: "breadcrumb-item" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: _vm.getRecordRoutePath(routeObject),
                                  },
                                },
                                [_vm._v(_vm._s(_vm.contextName))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              key: `${index}_1`,
                              staticClass: "breadcrumb-item",
                            },
                            [
                              _c("span", { staticClass: "active" }, [
                                _vm._v(_vm._s(_vm.getName(routeObject))),
                              ]),
                            ]
                          ),
                        ]
                      : [
                          _c(
                            "li",
                            { key: index, staticClass: "breadcrumb-item" },
                            [
                              _c("span", { staticClass: "active" }, [
                                _vm._v(_vm._s(_vm.contextName)),
                              ]),
                            ]
                          ),
                        ],
                  ]
                : _vm.getName(routeObject) !== ""
                ? _c(
                    "li",
                    { key: index, staticClass: "breadcrumb-item" },
                    [
                      _vm.hasParam(routeObject) && _vm.isLast(index)
                        ? _c("span", { staticClass: "active" }, [
                            _vm._v(_vm._s(_vm.getName(routeObject))),
                          ])
                        : _vm.isLast(index)
                        ? _c("span", { staticClass: "active" }, [
                            _vm._v(_vm._s(_vm.getName(routeObject))),
                          ])
                        : "" === routeObject.path
                        ? _c("router-link", { attrs: { to: "/" } }, [
                            _vm._v(_vm._s(_vm.getName(routeObject))),
                          ])
                        : _c(
                            "router-link",
                            {
                              attrs: {
                                to: _vm.getRecordRoutePathCustom(routeObject),
                              },
                            },
                            [_vm._v(_vm._s(_vm.getName(routeObject)))]
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          }),
          _vm.viewName
            ? _c("span", { staticClass: "ml-1" }, [
                _vm._v("/ " + _vm._s(_vm.viewName)),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "b-dropdown",
        {
          staticClass: "mode-button",
          class: _vm.getButtonClass(),
          style: { "--custom-label-max-width": _vm.labelMaxWidth },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function () {
                return [
                  _c(
                    "span",
                    { attrs: { id: "SELECTED_STATE_NAME" } },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "mr-2 fa-fw",
                        attrs: { icon: ["far", _vm.selectedState.icon] },
                      }),
                      _vm._v(
                        _vm._s(
                          _vm.$te(_vm.selectedState.name)
                            ? _vm.$t(_vm.selectedState.name)
                            : _vm.selectedState.name
                        )
                      ),
                    ],
                    1
                  ),
                  _vm.selectedState.icon === "clock-rotate-left"
                    ? _c("b-popover", {
                        attrs: {
                          target: "SELECTED_STATE_NAME",
                          placement: "top",
                          boundary: "viewport",
                          triggers: "hover",
                          content: _vm.selectedStatePopup,
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          [
            _c(
              "b-dropdown-item",
              {
                on: {
                  click: function ($event) {
                    return _vm.onMenu("epoch-clear")
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "action-item-label-with-icon" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "mr-2 fa-fw",
                      attrs: { icon: ["far", "wave-pulse"] },
                    }),
                    _vm._v(_vm._s(_vm.$t("live"))),
                  ],
                  1
                ),
                _vm.isTodayChecked()
                  ? _c(
                      "span",
                      { staticClass: "action-item-icon" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "check"] },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "b-dropdown-item",
              {
                on: {
                  click: function ($event) {
                    return _vm.onMenu("epoch-select")
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "action-item-label-with-icon" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "mr-2 fa-fw",
                      attrs: { icon: ["far", "clock-rotate-left"] },
                    }),
                    _vm._v(_vm._s(_vm.$t("select_epoch"))),
                  ],
                  1
                ),
                _vm.isTimeMachineChecked()
                  ? _c(
                      "span",
                      { staticClass: "action-item-icon" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "check"] },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _vm.canView()
              ? _c(
                  "b-dropdown-group",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c("font-awesome-icon", {
                                staticClass: "mr-2 fa-fw",
                                attrs: { icon: ["far", "code-branch"] },
                              }),
                              _vm._v(
                                _vm._s(_vm.$t("sandbox.menu_title")) + " "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      23419811
                    ),
                  },
                  [
                    _vm.canAdd()
                      ? _c(
                          "b-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.onMenu("sandbox-add")
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "submenu-indent action-item-label-with-icon",
                              },
                              [_vm._v(_vm._s(_vm.$t("sandbox.add")))]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.$store.state.sandbox.value &&
                    _vm.hasPermission("SANDBOX__MIGRATE")
                      ? _c(
                          "b-dropdown-item",
                          { on: { click: _vm.onPublish } },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "submenu-indent action-item-label-with-icon",
                              },
                              [_vm._v(_vm._s(_vm.$t("sandbox.review")))]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("b-dropdown-divider"),
                    _vm._l(_vm.sandboxList, function (item, index) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.onSandbox(item)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "submenu-indent action-item-label-with-icon",
                              attrs: { id: `sandboxname${index}` },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _vm.isEllipsisActive(item.name)
                            ? _c("b-popover", {
                                attrs: {
                                  target: `sandboxname${index}`,
                                  placement: "top",
                                  boundary: "viewport",
                                  "custom-class": "popover-margin",
                                  triggers: "hover",
                                  offset: "-100",
                                  content: item.name,
                                },
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            [
                              !_vm.editPermissionSandbox(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-icon position-third",
                                      attrs: {
                                        id: `BTN_SANDBOX_INFO_${index}`,
                                      },
                                      on: _vm._d(
                                        {
                                          mouseleave: function ($event) {
                                            return _vm.onSandboxInfoLeave(index)
                                          },
                                        },
                                        [
                                          _vm.infoMouseEnterEvent,
                                          function ($event) {
                                            return _vm.onSandboxInfoOver(index)
                                          },
                                        ]
                                      ),
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "circle-info"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.editPermissionSandbox(item)
                                ? _c("b-popover", {
                                    ref: `BTN_SANDBOX_INFO_${index}`,
                                    refInFor: true,
                                    attrs: {
                                      target: `BTN_SANDBOX_INFO_${index}`,
                                      show: _vm.showInfoSandbox[index],
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "manual",
                                      content: _vm.$t("owner", [
                                        _vm.sandboxList[index].ownerName,
                                      ]),
                                    },
                                    on: {
                                      "update:show": function ($event) {
                                        return _vm.$set(
                                          _vm.showInfoSandbox,
                                          index,
                                          $event
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.editPermissionSandbox(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-icon position-third",
                                      attrs: {
                                        id: `BTN_SANDBOX_SHARE_${index}`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.shareSandbox(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: [
                                            item.defaultView ? "fas" : "far",
                                            "share-nodes",
                                          ],
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.editPermissionSandbox(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: `BTN_SANDBOX_SHARE_${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t("button.share"),
                                    },
                                  })
                                : _vm._e(),
                              _vm.editPermissionSandbox(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-icon position-second",
                                      attrs: {
                                        id: `BTN_SANDBOX_UPDATE_${index}`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.saveSandbox(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "save"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.editPermissionSandbox(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: `BTN_SANDBOX_UPDATE_${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t("button.update_sandbox"),
                                    },
                                  })
                                : _vm._e(),
                              _vm.editPermissionSandbox(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "action-item-icon",
                                      attrs: {
                                        id: `BTN_SANDBOX_DELETE_${index}`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.deleteSandbox(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "trash-alt"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.editPermissionSandbox(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: `BTN_SANDBOX_DELETE_${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t("button.delete"),
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticClass: "action-item-icon",
                                  class: _vm.getCheckMarkClass(item),
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "check"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _vm.sandboxList.length !== 0
                      ? _c("b-dropdown-divider")
                      : _vm._e(),
                    _vm.canEdit()
                      ? _c(
                          "b-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.onMenu("sandbox-manage")
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "submenu-indent action-item-label-with-icon",
                              },
                              [_vm._v(_vm._s(_vm.$t("sandbox.manage")))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.canView("LABEL")
              ? _c(
                  "b-dropdown-group",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c("font-awesome-icon", {
                                staticClass: "mr-2 fa-fw",
                                attrs: { icon: ["far", "thumbtack"] },
                              }),
                              _vm._v(_vm._s(_vm.$t("label.menu_title")) + " "),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2482580679
                    ),
                  },
                  [
                    _vm.canAdd("LABEL")
                      ? _c(
                          "b-dropdown-item",
                          { on: { click: _vm.saveLabel } },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "submenu-indent action-item-label-with-icon",
                              },
                              [_vm._v(_vm._s(_vm.$t("label.add")))]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("b-dropdown-divider"),
                    _vm._l(_vm.labelList, function (item, index) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.onLabel(item)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "submenu-indent action-item-label-with-icon",
                              attrs: { id: `labelname${index}` },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: `labelname${index}`,
                                placement: "top",
                                boundary: "viewport",
                                "custom-class": "popover-margin",
                                triggers: "hover",
                                offset: "-100",
                              },
                            },
                            [
                              _c("div", [_vm._v(_vm._s(item.name))]),
                              _c("div", [
                                _vm._v(_vm._s(_vm.formatDate(item.epoch))),
                              ]),
                            ]
                          ),
                          _c(
                            "span",
                            [
                              _vm.canAdd("LABEL") && !_vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "action-item-icon",
                                      attrs: { id: `BTN_LABEL_COPY_${index}` },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.copyColumnSettings(
                                            item.name,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "copy"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canAdd("LABEL") && !_vm.editPermission(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: `BTN_LABEL_COPY_${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t("button.copy"),
                                    },
                                  })
                                : _vm._e(),
                              !_vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-icon position-third",
                                      attrs: { id: `BTN_LABEL_INFO_${index}` },
                                      on: _vm._d(
                                        {
                                          mouseleave: function ($event) {
                                            return _vm.onInfoLeave(index)
                                          },
                                        },
                                        [
                                          _vm.infoMouseEnterEvent,
                                          function ($event) {
                                            return _vm.onInfoOver(index)
                                          },
                                        ]
                                      ),
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "circle-info"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.editPermission(item)
                                ? _c("b-popover", {
                                    ref: `BTN_LABEL_INFO_${index}`,
                                    refInFor: true,
                                    attrs: {
                                      target: `BTN_LABEL_INFO_${index}`,
                                      show: _vm.showInfo[index],
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "manual",
                                      content: _vm.$t("owner", [
                                        _vm.labelList[index].owner,
                                      ]),
                                    },
                                    on: {
                                      "update:show": function ($event) {
                                        return _vm.$set(
                                          _vm.showInfo,
                                          index,
                                          $event
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-icon position-third",
                                      attrs: { id: `BTN_LABEL_SHARE_${index}` },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.shareColumnSettings(
                                            index,
                                            item.name,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "share-nodes"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.editPermission(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: `BTN_LABEL_SHARE_${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t("button.share"),
                                    },
                                  })
                                : _vm._e(),
                              _vm.canEdit("LABEL") && _vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-item-icon position-second",
                                      attrs: {
                                        id: `BTN_LABEL_UPDATE_${index}`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.updateColumnSettings(
                                            index,
                                            item.name,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "save"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canEdit("LABEL") && _vm.editPermission(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: `BTN_LABEL_UPDATE_${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t("button.update_label"),
                                    },
                                  })
                                : _vm._e(),
                              _vm.canDelete("LABEL") && _vm.editPermission(item)
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "action-item-icon",
                                      attrs: {
                                        id: `BTN_LABEL_DELETE_${index}`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.removeColumnSettings(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "trash-alt"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.canDelete("LABEL") && _vm.editPermission(item)
                                ? _c("b-popover", {
                                    attrs: {
                                      target: `BTN_LABEL_DELETE_${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t("button.delete"),
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticClass: "action-item-icon",
                                  class: _vm.getCheckMarkClassLabel(item),
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "check"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ],
        ],
        2
      ),
      _c("SaveLabelModal", {
        attrs: {
          show: _vm.promptSaveShow,
          name: _vm.saveName,
          title: _vm.saveProfile.uuId
            ? _vm.$t("label.update")
            : _vm.$t("label.save"),
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptSaveShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c("SaveLabelModal", {
        attrs: {
          show: _vm.promptShareShow,
          name: _vm.saveName,
          title: _vm.$t("label.share"),
          sharing: true,
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptShareShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteLabelOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteLabelShow,
            callback: function ($$v) {
              _vm.confirmDeleteLabelShow = $$v
            },
            expression: "confirmDeleteLabelShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("label.delete")) + " "),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteSandboxOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteSandboxShow,
            callback: function ($$v) {
              _vm.confirmDeleteSandboxShow = $$v
            },
            expression: "confirmDeleteSandboxShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("sandbox.delete")) + " "),
          ]),
        ]
      ),
      _vm.editorShow
        ? _c("SandboxModal", {
            attrs: {
              data: _vm.editData,
              id: _vm.editData.uuId,
              show: _vm.editorShow,
              sharing: _vm.editorShare,
              nameEdit: !_vm.editorShare,
              title: _vm.$t(
                _vm.editorShare ? "sandbox.title_share" : `sandbox.title_edit`
              ),
            },
            on: {
              "update:show": function ($event) {
                _vm.editorShow = $event
              },
              success: _vm.editorSuccess,
            },
          })
        : _vm._e(),
      _c("AuditSelectorModalForAdmin", {
        attrs: { show: _vm.auditShow },
        on: {
          "update:show": function ($event) {
            _vm.auditShow = $event
          },
          ok: _vm.onPublishComplete,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }