export const fieldOptions = [
  { value: null, text: 'None' },
  { 
    label: 'Summary Task',
    options: [
      { value: 'summary_name', text: 'Name' },
      { value: 'summary_desc', text: 'Description' },
      { value: 'summary_count', text: 'Count' },
      { value: 'summary_notes', text: 'Notes' },
      //{ value: 'summary_startdate', text: 'Start Date' }, 
      //{ value: 'summary_enddate', text: 'End Date' },
      //{ value: 'summary_duration', text: 'Duration' }, 
      { value: 'summary_priority', text: 'Priority' }, 
      //{ value: 'summary_progress', text: 'Progress' }, 
      //{ value: 'summary_schedulemode', text: 'Schedule Mode' }, 
      //{ value: 'summary_constraint', text: 'Constraint' },
      //{ value: 'summary_constraintTime', text: 'Constraint Date' },
      { value: 'summary_fixedcost', text: 'Fixed Cost' },
      { value: 'summary_fixedduration', text: 'Fixed Duration' },
      { value: 'summary_currency', text: 'Currency' },
      { value: 'summary_skills', text: 'Skills' },
      { value: 'summary_staffs', text: 'Staff' },
      { value: 'summary_resources', text: 'Resources' },
      { value: 'summary_rebates', text: 'Rebates' },
      { value: 'summary_complexity', text: 'Complexity' },
      //{ value: 'summary_workflow_stage', text: 'Workflow Stage' },
      { value: 'summary_identifier', text: 'Identifier' }
    ]
  },
  { 
    label: 'Task',
    options: [
      { value: 'count', text: 'Count' },
      { value: 'name', text: 'Name' },
      { value: 'desc', text: 'Description' },
      { value: 'notes', text: 'Notes' },
      { value: 'startdate', text: 'Start Date' }, 
      { value: 'enddate', text: 'End Date' },
      { value: 'duration', text: 'Duration' }, 
      { value: 'priority', text: 'Priority' }, 
      { value: 'progress', text: 'Progress' }, 
      { value: 'schedulemode', text: 'Schedule Mode' }, 
      { value: 'constraint', text: 'Constraint' },
      { value: 'constraintTime', text: 'Constraint Date' },
      { value: 'fixedcost', text: 'Fixed Cost' },
      { value: 'fixedduration', text: 'Fixed Duration' },
      { value: 'currency', text: 'Currency' },
      { value: 'skills', text: 'Skills' },
      { value: 'staffs', text: 'Staff' },
      { value: 'resources', text: 'Resources' },
      { value: 'rebates', text: 'Rebates' },
      { value: 'complexity', text: 'Complexity' },
      { value: 'workflow_stage', text: 'Workflow Stage' },
      { value: 'identifier', text: 'Identifier' }
    ]
  },
  { 
    label: 'Milestone',
    options: [
      { value: 'milestone_name', text: 'Name' },
      { value: 'milestone_desc', text: 'Description' },
      { value: 'milestone_count', text: 'Count' },
      { value: 'milestone_notes', text: 'Notes' },
      { value: 'milestone_startdate', text: 'Start Date' }, 
      //{ value: 'milestone_enddate', text: 'End Date' },
      //{ value: 'milestone_duration', text: 'Duration' }, 
      { value: 'milestone_priority', text: 'Priority' }, 
      { value: 'milestone_progress', text: 'Progress' }, 
      { value: 'milestone_schedulemode', text: 'Schedule Mode' }, 
      { value: 'milestone_constraint', text: 'Constraint' },
      { value: 'milestone_constraintTime', text: 'Constraint Date' },
      { value: 'milestone_fixedcost', text: 'Fixed Cost' },
      { value: 'milestone_currency', text: 'Currency' },
      { value: 'milestone_skills', text: 'Skills' },
      { value: 'milestone_staffs', text: 'Staff' },
      { value: 'milestone_resources', text: 'Resources' },
      { value: 'milestone_rebates', text: 'Rebates' },
      { value: 'milestone_complexity', text: 'Complexity' },
      { value: 'milestone_workflow_stage', text: 'Workflow Stage' },
      { value: 'milestone_identifier', text: 'Identifier' }
    ]
  },
  { value: 'task_path', text: 'Task Path' },
  { value: 'type', text: 'Type' },
  { value: 'image', text: 'Image'},
  { value: 'project', text: 'Project Name'}
];

export function initFieldOptions(self) {
  for (const option of fieldOptions) {
    if (typeof option === 'object' &&
        typeof option.options !== 'undefined') {
      for (const suboption of option.options) {
        if (suboption.value === 'summary_name'  ||
            suboption.value === 'milestone_name') {
          suboption.text = self.$t(`task.field.name`);
        }
        else if (suboption.value === 'desc' ||
            suboption.value === 'summary_desc'  ||
            suboption.value === 'milestone_desc') {
          suboption.text = self.$t(`task.field.description`);
        }
        else if (suboption.value === 'startdate' ||
            suboption.value === 'summary_startdate'  ||
            suboption.value === 'milestone_startdate') {
          suboption.text = self.$t(`task.field.startTime`);
        }
        else if (suboption.value === 'enddate' ||
            suboption.value === 'summary_enddate'  ||
            suboption.value === 'milestone_enddate') {
          suboption.text = self.$t(`task.field.closeTime`);
        }
        else if (suboption.value === 'fixedcost' ||
            suboption.value === 'summary_fixedcost'  ||
            suboption.value === 'milestone_fixedcost') {
          suboption.text = self.$t(`task.field.fixedCost`);
        }
        else if (suboption.value === 'fixedduration' ||
            suboption.value === 'summary_fixedduration'  ||
            suboption.value === 'milestone_fixedduration') {
          suboption.text = self.$t(`task.field.fixedDuration`);
        }
        else if (suboption.value === 'schedulemode' ||
            suboption.value === 'summary_schedulemode'  ||
            suboption.value === 'milestone_schedulemode') {
          suboption.text = self.$t(`task.field.autoScheduling`);
        }
        else if (suboption.value === 'currency' ||
            suboption.value === 'summary_currency'  ||
            suboption.value === 'milestone_currency') {
          suboption.text = self.$t(`task.field.currencyCode`);
        }
        else if (suboption.value === 'workflow_stage' ||
            suboption.value === 'summary_workflow_stage'  ||
            suboption.value === 'milestone_workflow_stage') {
          suboption.text = self.$t(`task.field.stage`);
        }
        else {
          const value = suboption.value.startsWith('summary_') 
            ? suboption.value.substr(8) :
              suboption.value.startsWith('milestone_') ?  suboption.value.substr(10) :
                suboption.value;
          suboption.text = self.$t(`task.field.${value}`);
        }
      }
      option.options.sort(function(a, b) {
        if(a.text < b.text) { return -1; }
        if(a.text > b.text) { return 1; }
        return 0;
      });
    }
    else {
      if (option.value === null) {
        option.text = self.$t(`task.none`);
      }
      else if (option.value === 'task_path') {
        option.text = self.$t(`task.field.taskpath`);
      }
      else if (option.value === 'type' ||
          option.value === 'summary_type'  ||
          option.value === 'milestone_type') {
        option.text = self.$t(`task.field.taskType`);
      }
      else if (option.value === 'project') {
        option.text = self.$t('filter_component.project_name');
      }
      else {
        option.text = self.$t(`task.field.${option.value}`);
      }
    }
  }
}