import { httpAjax } from '@/helpers';
import { urlParams } from '../helpers';

export const taskService = {
  create,
  clone,
  list,
  get,
  update,
  remove,
  optionConstraint,
  optionPriority,
  optionComplexity,
  optionType,
  optionLinkType,
  predecessorList,
  listTree,
  parent,
  updateParent,
  updateParentnOrder,
  listSpecificTaskTree,
  specificPredecessor,
  listKanban,
  listProjectDashboard,
  listProjectDashboardMilestones,
  listHomeDashboard,
  listTasksDashboard,
  listAssignedStage,
  span,
  getProjectLocationAndAutoScheduling,
  getParentName,
  getParentNameEpoch,
  applyTaskTemplate,
  listNames,
  queryLinkResources,
};

/**
 * Create a new task 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', name: 'Paramount' ... }]
 */
function create(data, projectId, orderAt=null, orderAs=true) {
  let url = `/api/task/add?holder=${projectId}`;
  if(orderAt != null) {
    url = `${url}&order-at=${orderAt}&order-as=${orderAs}`;
  }

  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Clone a task 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', name: 'Paramount' ... }]
 */
function clone(data, projectId, ref) {
  let url = `/api/task/clone?holder=${projectId}&reference=${ref}`;

  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of task info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'Paramount', ksort='name', order: 'incr' }}
 */
function list(params) {
  const fields = {
    uuId: 'PROJECT.TASK.uuId', 
    name: 'PROJECT.TASK.name',
    startTime: 'PROJECT.TASK.startTime',
    endTime: 'PROJECT.TASK.closeTime',
    duration: 'PROJECT.TASK.duration',
    progress: 'PROJECT.TASK.progress',
    priority: 'PROJECT.TASK.priority',
    complexity: 'PROJECT.TASK.complexity',
    constraintType: 'PROJECT.TASK.constraintType',
    constraintTime: 'PROJECT.TASK.constraintTime',
    taskType: 'PROJECT.TASK.taskType',
    projectId: 'PROJECT.uuId',
    projectName: 'PROJECT.name',
    parentTaskId: 'PROJECT.TASK.PARENT_TASK.uuId',
    parentTaskName: 'PROJECT.TASK.PARENT_TASK.name'
  }
  

  let data = {
    'name'  : 'Task List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if(params.filter && params.filter.length > 0) {
  if (typeof params.filter === 'string') {
      data['filter'] = [
        '_or_', [
          ['PROJECT.TASK.name', 'regex', params.filter]
        ]
      ]
  }
  else {
    data['filter'] = params.filter;
  }
  }

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i]]);
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order]);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

/**
 * Retrieving a concise list of task names 
 * @param Object name
 */
function listNames(name) {
  const fields = {
    name: 'TASK.name'
  }
  

  let data = {
    'name'  : 'Task Name List'
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : 100
    ,'limit_data' : true
    ,'dedup': true
    ,'select': Object.keys(fields).map(i => [fields[i]])
    ,'filter': [
      [
        'TASK.name',
        'regex',
        `(?i)${name}.+?`
      ]
    ]
  }

  if (name === null) {
    delete data['filter'];
  }
  
  const url = '/api/query/match';
  const config = {}
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return rawData.map(i => { return i[0] })
  });
}

/**
 * Retrieving a list of task details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function get(data, links=null) {
  const url = urlParams('/api/task/get', links, null);
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Update task details 
 * by passing  necessary information
 * @param Array data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', name: 'Paramount' ... }]
 */
function update(data, { orderAt, orderAs } = {}) {
  let params = orderAt? `?order-at=${orderAt}&order-as=${orderAs}` : '';
  const url = `/api/task/update${params}`;
  
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of tasks
 * by passing in an array list of uuIds
 * @param Array data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = `/api/task/delete`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}

function optionConstraint() {
  const url = '/api/project/enum?collection=opts_constraint'
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config)
  .then(response => {
    let constraints = response && response.data? response.data[response.data.jobCase].opts_constraint:[];
    constraints.sort((a, b) => {
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    });
    return constraints;
  });
}

function optionPriority() {
  const url = '/api/project/enum?collection=opts_priority'
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config)
  .then(response => {
    return response && response.data? response.data[response.data.jobCase].opts_priority:[];
  });
}

function optionComplexity() {
  const url = '/api/project/enum?collection=opts_complexity'
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config)
  .then(response => {
    return response && response.data? response.data[response.data.jobCase].opts_complexity:[];
  });
}

function optionType() {
  const url = '/api/project/enum?collection=opts_type'
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config)
  .then(response => {
    return response && response.data? response.data[response.data.jobCase].opts_type:[];
  });
}

function optionLinkType() {
  const url = '/api/project/enum?collection=opts_link'
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config)
  .then(response => {
    return response && response.data? response.data[response.data.jobCase].opts_link:[];
  });
}

/**
 * Retrieving a concise list of task predecessor info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'Paramount', ksort='name', order: 'incr' }}
 */
function predecessorList(params, uuId, customFields=[]) {
  const fields = {
    tUuId: ['TASK.uuId'],
    tName: ['TASK.name'],
    type:  ['TASK.TASK-PREDECESSOR_TASK.type'],
    lag: ['TASK.TASK-PREDECESSOR_TASK.lag', 0, 'days'],
    name: ['TASK.PREDECESSOR_TASK.name'],
    uuId: ['TASK.PREDECESSOR_TASK.uuId']
  }

  const cfKeys = customFields != null? customFields.map(i => i.name) : []
  for (const k of cfKeys) {
    fields[k] = [`TASK.TASK-PREDECESSOR_TASK.${k}`];
  }

  let data = {
    'name'  : 'Task Predecessor List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'select': Object.keys(fields).map(i => fields[i])
    ,'holder': uuId
  }

  if (uuId) {
    data['filter'] = [['TASK.uuId', 'eq', uuId]];
  }

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i]]);
      }
    } else {
      const sortKey = params.ksort === 'uuId'? 'name': params.ksort;
      data['sort'].push([fields[sortKey], params.order]);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

function listTree(params, projectId, showFullDetails=false) {
  const fields = {
    uuId:  ['PROJECT.TASK.uuId'],
    name:  ['PROJECT.TASK.name'],
    taskType: ['PROJECT.TASK.taskType'],
    pUuId: ['PROJECT.TASK.PARENT_TASK.uuId'],
    pName: ['PROJECT.TASK.PARENT_TASK.name'],
    startTime: ['PROJECT.TASK.startTime'],
    closeTime: ['PROJECT.TASK.closeTime'],
    avatarRef: ['PROJECT.TASK.avatarRef'],
    order: ['PROJECT.TASK.order']
  }
  if(showFullDetails) {
    fields['lockDuration']        = ['PROJECT.TASK.lockDuration'];
    fields['duration']            = ['PROJECT.TASK.duration', 0, 'Days'];
    fields['durationAUM']         = ['PROJECT.TASK.durationAUM'];
    fields['progress']            = ['PROJECT.TASK.progress'];
    fields['priority']            = ['PROJECT.TASK.priority'];
    fields['stageId']               = ['PROJECT.TASK.STAGE.uuId'];
    fields['stageName']           = ['PROJECT.TASK.STAGE.name'];
    fields['complexity']          = ['PROJECT.TASK.complexity'];
    fields['description']         = ['PROJECT.TASK.description'];
    // fields['fixedCost']           = ['PROJECT.TASK.fixedCost'];
    fields['constraintType']      = ['PROJECT.TASK.constraintType'];
    fields['constraintTime']      = ['PROJECT.TASK.constraintTime'];
    fields['autoScheduling']      = ['PROJECT.TASK.autoScheduling'];
    fields['estimatedCost']     = ['PROJECT.TASK.estimatedCost'];
    fields['estimatedCostNet']  = ['PROJECT.TASK.estimatedCostNet'];
    fields['actualCost']        = ['PROJECT.TASK.actualCost'];
    fields['actualCostNet']     = ['PROJECT.TASK.actualCostNet'];
    fields['plannedCost']           = [['=plannedCost(A,B,C)', ['PROJECT.TASK'], '<AUTO>', false]];
    fields['plannedCostNet']        = [['=plannedCost(A,B,C)', ['PROJECT.TASK'], '<AUTO>', true]];
    fields['fixedCost']           = ['PROJECT.TASK.fixedCost'];
    fields['fixedCostNet']        = ['PROJECT.TASK.fixedCostNet'];
    fields['estimatedDuration']  = ['PROJECT.TASK.estimatedDuration', 0, "Days"];
    fields['totalActualDuration']     = ['PROJECT.TASK.actualDuration', 0, "Days"];
    fields['estimatedTimeToComplete'] = ['PROJECT.TASK.estimatedTimeToComplete', 0, "Days"];
    fields['plannedProgress']         = ['plannedProgress'];
    
    fields['staffIds']             = ['PROJECT.TASK.STAFF.uuId'];
    fields['staffFirstNames']      = ['PROJECT.TASK.STAFF.firstName'];
    fields['staffLastNames']       = ['PROJECT.TASK.STAFF.lastName'];
    fields['staffUtilizations']    = ['PROJECT.TASK.TASK-STAFF.utilization'];
    fields['staffDuration']        = ['PROJECT.TASK.TASK-STAFF.duration'];
    fields['staffDurationAUM']        = ['PROJECT.TASK.TASK-STAFF.durationAUM'];
    fields['skillIds']             = ['PROJECT.TASK.SKILL.uuId'];
    fields['skillNames']           = ['PROJECT.TASK.SKILL.name'];
    fields['skillLevels']          = ['PROJECT.TASK.TASK-SKILL.level'];
    fields['resourceIds']          = ['PROJECT.TASK.RESOURCE.uuId'];
    fields['resourceNames']        = ['PROJECT.TASK.RESOURCE.name'];
    fields['resourceUnits']        = ['PROJECT.TASK.TASK-RESOURCE.quantity'];
    fields['taskPath']             = ["PROJECT.TASK.fullPath"];
    fields['noteId']               = ['PROJECT.TASK.NOTE.uuId'];
    fields['noteText']             = ['PROJECT.TASK.NOTE.text'];
    fields['noteModified']         = ['PROJECT.TASK.NOTE.modified'];
    fields['noteFirstName']        = ['PROJECT.TASK.NOTE.USER.firstName'];
    fields['noteLastName']         = ['PROJECT.TASK.NOTE.USER.lastName'];
    fields['noteAuthorRef']        = ['PROJECT.TASK.NOTE.USER.uuId'];
    fields['rebateUuid']           = ['PROJECT.TASK.REBATE.uuId'];
    fields['rebateName']           = ['PROJECT.TASK.REBATE.name'];
    fields['rebateRebate']         = ['PROJECT.TASK.REBATE.rebate'];
    fields['templateUuId']         = ['PROJECT.TASK.PROJECT_TEMPLATE.uuId']
    fields['templateName']         = ['PROJECT.TASK.PROJECT_TEMPLATE.name']
  }

  let data = {
    'name'    : 'Task Tree List'
    ,'type'   : 'msql'
    ,'sep_array': '/'
    ,'start'  : params.start
    ,'limit'  : params.limit
    ,'nominate': 'PROJECT.TASK'
    ,'holder' : [projectId]
    ,'select' : Object.keys(fields).map(i => fields[i])
  }
  

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Preprocess Parent details
        result.pUuId = !result.pUuId || result.pUuId.length == 0 || result.pUuId == projectId? 'ROOT': result.pUuId[0];
        result.pName = !result.pName || result.pName.length == 0? '': result.pName[0];

        //Preprocess stage details
        if (result.stageId) {
          if(result.stageId.length > 0) {
            const stgId = result.stageId[0] != null? result.stageId[0] : null;
            const stgName = result.stageName[0] != null? result.stageName[0] : null;
            result.stage = { uuId: stgId, name: stgName }
          } else {
            result.stage = { uuId: null, name: '' }
          }
        }
        delete result.stageId;
        delete result.stageName;

        //Preprocess note details
        if (result.noteId) {
          result.notes = [];
          for (let i = 0; i < result.noteId.length; i++) {
            const author = result.noteFirstName.length > i ? `${result.noteFirstName[i]} ${result.noteLastName[i]}` : null;
            result.notes.push({ uuId: result.noteId[i], text: result.noteText[i], modified: result.noteModified[i], author: author, authorRef: result.noteAuthorRef[i]});
          }
          result.notes.sort((a, b) => {
            return b.modified - a.modified;
          });
        }

        // Preprocess rebates
        result.rebates = [];
        if (result.rebateUuid) {
          for (let i = 0; i < result.rebateUuid.length; i++) {
            result.rebates.push({
              uuId: result.rebateUuid[i],
              name: result.rebateName[i],
              rebate: result.rebateRebate[i],
            });
          }
        }
        
        //Preprocess template
        if(result.templateUuId && result.templateUuId.length > 0) {
          result.template = {
            uuIds: result.templateUuId,
            names: result.templateName
          }
        } else {
          result.template = { uuId: null, name: null };
        }
        delete result.templateUuId;
        delete result.templateName;

        //Preprocess constraint type and constraint time
        if (result.constraintType && result.taskType != 'Project') {
          result.constraint = {
            type: result.constraintType,
            time: result.constraintTime || 0
          }
        } else {
          result.constraint = { type: null, time: 0 }
        }
        delete result.constraintType;
        delete result.constraintTime;
        

        //Preprocess Skill details
        result.skills = [];
        if(result.skillIds && result.skillIds.length > 0) {
          for(let i = 0, len = result.skillIds.length; i < len; i++) {
            result.skills.push({
              id: result.skillIds[i],
              name: result.skillNames[i],
              level: result.skillLevels[i]
            })
          }
        }
        delete result.skillIds;
        delete result.skillNames;
        delete result.skillLevels;

        //Preprocess Staff details
        result.staffs = [];
        if(result.staffIds && result.staffIds.length > 0) {
          for(let i = 0, len = result.staffIds.length; i < len; i++) {
            const firstName = result.staffFirstNames[i];
            const lastName = result.staffLastNames[i];
            result.staffs.push({
              uuId: result.staffIds[i],
              name: `${firstName || ''}${firstName && firstName.length > 0?' ':''}${lastName || ''}`,
              utilization: result.staffUtilizations[i],
              duration: result.staffDuration[i] / 60000, // convert ms to minutes
              durationAUM: result.staffDurationAUM[i]
            })
          }
        }
        delete result.staffIds;
        delete result.staffNames;
        delete result.staffUtilizations;

        //Preprocess Resources details
        result.resources = [];
        if(result.resourceIds && result.resourceIds.length > 0) {
          for(let i = 0, len = result.resourceIds.length; i < len; i++) {
            result.resources.push({
              uuId: result.resourceIds[i],
              name: result.resourceNames[i],
              unit: result.resourceUnits[i]
            })
          }
        }
        delete result.resourceIds;
        delete result.resourceNames;
        delete result.resourceUnits;

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

function parent(projectId, taskId) {
  const params = {
    start: 0,
    limit: 1
  }
  const fields = {
    uuId: ['PROJECT.TASK.uuId'],
    name: ['PROJECT.TASK.name'],
    pUuId: ['PROJECT.TASK.PARENT_TASK.uuId'],
    pName: ['PROJECT.TASK.PARENT_TASK.name'],
    pStartTime: ["PROJECT.TASK.PARENT_TASK.startTime", "null"]
  }

  let data = {
    'name'  : 'Parent Task List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'select': Object.keys(fields).map(i => fields[i])
    ,'holder': projectId
  }

  data['filter'] = [
    ['PROJECT.uuId', 'eq', projectId],
    ['PROJECT.TASK.uuId', 'eq', taskId]
  ]

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        
        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

/**
 * 
 * @param {*} data Format: [ { uuId, parent } ]
 * uuId: task uuId; parent: parent uuId
 */
function updateParent(data) {
  const url = `/api/task/update`;
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * 
 * @param {*} data Format: [ { uuId: task's uuId, parent: parent's uuId } ]
 * Note: Parent prop can be omitted if no re-parent is required.
 * @param {String} orderAt - target Task UuId.
 * @param {Boolean} insertAfter - Insert selected task after target task if true; insert before if false.
 */
function updateParentnOrder(data, orderAt, insertAfter=false) {
  const url = '/api/task/update';
  const config = {
    headers: getHeaders(),
    params: {
      'order-at': orderAt,
      'order-as': insertAfter
    }
  }
  return httpAjax.put(url, data, config);
}

function listSpecificTaskTree(taskId) {
  const fields = {
    tUuId: ['PROJECT.TASK.uuId'],
    tName: ['PROJECT.TASK.name'],
    uuId:  ['PROJECT.TASK.CHILD_ALL_TASK.uuId'],
    name:  ['PROJECT.TASK.CHILD_ALL_TASK.name'],
    pUuId: ['PROJECT.TASK.CHILD_ALL_TASK.PARENT_TASK.uuId'],
    pName: ['PROJECT.TASK.CHILD_ALL_TASK.PARENT_TASK.name'],
  }
  
  let data = {
    'name'    : 'Specific Task Tree List'
    ,'type'   : 'msql'
    ,'start'  : 0
    ,'limit'  : -1
    ,'leftjoin': 'PROJECT.TASK'
    ,'select' : Object.keys(fields).map(i => fields[i])
  }
  
  data['filter'] = [
    ['PROJECT.TASK.uuId', 'eq', taskId]
  ]

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Preprocess Child details
        result.uuId = result.uuId && result.uuId.length > 0? result.uuId : null;

        //Preprocess Parent details
        result.pUuId = result.pUuId && result.pUuId.length > 0? result.pUuId : null;
        

        return result;
      })
    }
  });
}


function specificPredecessor(uuId, predecessorId) {
  const params = {
    start: 0,
    limit: 1
  }
  const fields = {
    tUuId: ['TASK.uuId'], 
    tName: ['TASK.name'],
    type: ['TASK.TASK-PREDECESSOR_TASK.type'],
    lag: ['TASK.TASK-PREDECESSOR_TASK.lag', 0, 'days'],
    pName: ['TASK.PREDECESSOR_TASK.name'],
    pUuId: ['TASK.PREDECESSOR_TASK.uuId']
  }
  

  let data = {
    'name'  : 'Specific Task Predecessor List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'holder': uuId
    ,'select': Object.keys(fields).map(i => fields[i])
  }

  data['filter'] = [
    ['TASK.PREDECESSOR_TASK.uuId', 'eq', predecessorId]
  ]

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

function listKanban(params, allfields=false) {
  const prefix = allfields || params.taskId ? 'TASK' : 'PROJECT.TASK';
  let fields = {
    uuId:             [`${prefix}.uuId`]
  }
  
  if (allfields) {
    fields = {
        uuId:             [`${prefix}(one).uuId`],
        name:             [`${prefix}(one).name`],
        avatarRef:        [`${prefix}(one).avatarRef`],
        stage:            [`${prefix}(one).STAGE.uuId`],
        description:      [`${prefix}(one).description`],
        progress:         [`${prefix}(one).progress`],
        priority:         [`${prefix}(one).priority`],
        closeTime:        [`${prefix}(one).closeTime`],
        color:            [`${prefix}(one).color`],
        stageColor:       [`${prefix}(one).STAGE.color`],
        staffColor:       [`${prefix}(one).STAFF.color`],
        resourceColor:    [`${prefix}(one).RESOURCE.color`],
        skillColor:       [`${prefix}(one).SKILL.color`],
        fileColor:        [`${prefix}(one).STORAGE_FILE.color`],
        rebateColor:      [`${prefix}(one).REBATE.color`],
        staffIds:         [`${prefix}(one).STAFF.uuId`],
        staffFirstNames:  [`${prefix}(one).STAFF.firstName`],
        staffLastNames:   [`${prefix}(one).STAFF.lastName`],
        staffUtilization: [`${prefix}(one).TASK(one)-STAFF.utilization`],
        staffAvatarRef:   [`${prefix}(one).STAFF.avatarRef`],
        parents:           [`${prefix}(one).PARENT_ALL_TASK.name`, '_ROOT_']
      }
  }
  else if (params.taskId) {
    fields['uuId'] = [`${prefix}(one).uuId`];
    fields['projectId'] = ['TASK(one).PROJECT.uuId'];
    fields['stage'] = ['TASK(one).STAGE.uuId']
  }
  
  let sort = [];
  if (params && params.sortBy) {
    sort.push([params.sortBy, params.sortDirection])
  }
  let data = {
    'name'    : 'Kanban List'
    ,'type'   : 'msql'
    ,'start'  : params.start
    ,'limit'  : params.limit
    ,'nominate': `${prefix}`
    ,'select' : Object.keys(fields).map(i => fields[i])
    ,'sort'   : sort
  }
  
  if (!allfields) {
    delete data['nominate'];
  }
  
  if (params.taskId) {
    data['holder'] = params.taskId;
  }
  else {
    data['filter'] = [];
    if (params.projectId) {
      // All tasks in the project are candidates
      // data['filter'].push([`${prefix}.STAGE.uuId`, 'neq', ""]);
      data['filter'].push(['PROJECT.uuId', 'eq', params.projectId]);
      data['holder'] = [params.projectId];
    } else if (params.taskIds) {
      // Only this list of task uuIds are candidates
      data['filter'].push([`${prefix}.STAGE.uuId`, 'neq', ""]);
      data['filter'].push([`${prefix}.uuId`, 'within', params.taskIds.join("|")]);
    }
    
    if (params.filter) {
      data['filter'].push(...params.filter);
    }
  }
  
  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_count: response.data.arg_count,
      status: response.status,
      jobClue: response.data.jobClue,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Preprocess Child details
        result.uuId = result.uuId && result.uuId.length > 0? result.uuId : null;       

        if (result.stage) {
          if (result.stage.length == 0) {
            result.stage = null;
          } else {
            result.stage = Array.isArray(result.stage) ? result.stage[0] : result.stage;
          }
        }
        //Preprocess Staff details
        result.staffs = [];
        if(result.staffIds && result.staffIds.length > 0) {
          for(let i = 0, len = result.staffIds.length; i < len; i++) {
            const firstName = result.staffFirstNames[i];
            const lastName = result.staffLastNames[i];
            result.staffs.push({
              uuId: result.staffIds[i],
              name: `${firstName || ''}${firstName && firstName.length > 0?' ':''}${lastName || ''}`,
              utilization: Array.isArray(result.staffUtilization) && result.staffUtilization.length > i? result.staffUtilization[i] : 1,
              avatarRef: result.staffAvatarRef[i]
            })
          }
          delete result.staffIds;
          delete result.staffFirstNames;
          delete result.staffLastNames;
          delete result.staffUtilization;
          delete result.staffAvatarRef;
        }

        // Rebuild parent list as a single string, omitting the root (project)
        if (result.parents && result.parents.length > 0) {
          let parents = [];
          for (i = result.parents.length-1; i >= 0; i--) {
            let name = result.parents[i];
            if (name != "_ROOT_") {
              parents.push(result.parents[i])
            }
          }
          result.path = "/ " + parents.join(" / ");
          delete result.parents;
        }
        else {
          result.path = "/ ";
        }
        return result;
      })
    }
  });
}

function listProjectDashboard(params, projectId) {
  const fields = {
    uuId:             ['PROJECT.TASK.uuId']
  }
  
  let data = {
    'name'    : params.name ? params.name : 'Project Dashboard List'
    ,'type'   : 'msql'
    ,'start'  : params.start ? params.start : 0
    ,'limit'  : params.limit ? params.limit : -1
    ,'nominate': 'PROJECT.TASK'
    ,'timeout': 60
    ,'select' : Object.keys(fields).map(i => fields[i])
    ,'holder' : [projectId]
  }
  
  if (params.limit_data) {
    data['limit_data'] = true;
  }
  
  if (params.filter) {
    data['filter'] = params.filter;
  }
  
  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Preprocess Child details
        result.uuId = result.uuId && result.uuId.length > 0? result.uuId : null;       

        return result;
      })
    }
  });
}

function listTasksDashboard(tasks) {
  const fields = {
    uuId:             ['TASK(one).uuId'],
    progress:         ['TASK(one).progress'], // ProjectTasksWidget, ProjectTasksAlertsWidget
    startTime:         ['TASK(one).startTime'],//ProjectTasksAlertsWidget, ProjectMilestonesWidget
    endTime:           ['TASK(one).closeTime'],//ProjectTasksAlertsWidget, ProjectMilestonesWidget

    project:          ['TASK(one).PROJECT.name'],
    projectId:        ['TASK(one).PROJECT.uuId'],
    name:             ['TASK(one).name'],//ProjectMilestonesWidget
    parents:          ['TASK(one).fullPath'],//ProjectTasksAlertsWidget, ProjectMilestonesWidget
    estimatedCost: ['TASK(one).estimatedCost'],//ProjectTasksAlertsWidget
    actualCost:    ['TASK(one).actualCost'],//ProjectTasksAlertsWidget
    totalActualDuration:['TASK(one).actualDuration'],//ProjectTasksAlertsWidget
    workEffort:         ['TASK(one).TASK(one)-STAFF.duration'],//ProjectTasksAlertsWidget
    staffIds:           ['TASK(one).STAFF.uuId']//ProjectTasksAlertsWidget
  }
  
  let data = {
    'name'    : 'Tasks Dashboard List'
    ,'type'   : 'msql'
    ,'start'  : 0
    ,'limit'  : -1
    ,'nominate': 'TASK'
    ,'select' : Object.keys(fields).map(i => fields[i])
    ,'holder' : tasks
  }
  
  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        if (Array.isArray(result.project)) {
          result.project = result.project[0];
        }
        //Preprocess Child details
        result.uuId = result.uuId && result.uuId.length > 0? result.uuId : null;       

        if (result.stage && result.stage.length > 0) {
          result.stage = result.stage[0];
        } else {
          result.stage = null;
        }

        //Preprocess staff details
        result.staffs = [];
        if(result.staffIds && result.staffIds.length > 0) {
          for(let i = 0, len = result.staffIds.length; i < len; i++) {
            if (result.staffIds[i]) {
              result.staffs.push({
                uuId: result.staffIds[i],
              })
            }
          }
          delete result.staffIds;
        }

        // Rebuild parent list as a single string, omitting the root (project)
        if (result.parents) {
          let path = result.parents.split('\n');
          path.splice(0, 1); // remove the project name
          path.splice(path.length -1, 1); // remove the task name
          result.path = path.length !== 0 ? "/ " + path.join(" / ") : '';
          delete result.parents;
        }

        if (result.projectId && result.projectId.length > 0) {
          if (Array.isArray(result.projectId)) {
            result.projectId = result.projectId[0];
          }
        }
        
        return result;
      })
    }
  });
}

function listProjectDashboardMilestones(projectId) {
  const fields = {
    uuId:             ['PROJECT.TASK.uuId'],
    progress:         ['PROJECT.TASK.progress'], // ProjectMilestonesWidget
    startTime:         ['PROJECT.TASK.startTime'],//ProjectTasksAlertsWidget, ProjectMilestonesWidget
    endTime:           ['PROJECT.TASK.closeTime'],//ProjectTasksAlertsWidget, ProjectMilestonesWidget
    type:             ['PROJECT.TASK.taskType'],//ProjectMilestonesWidget
    name:             ['PROJECT.TASK.name'],//ProjectMilestonesWidget
    parents:          ['PROJECT.TASK.fullPath'],//ProjectTasksAlertsWidget, ProjectMilestonesWidget
  }
  
  let data = {
    'name'    : 'Project Dashboard Milestone List'
    ,'type'   : 'msql'
    ,'start'  : 0
    ,'limit'  : 100
    ,'limit_data': true
    ,'nominate': 'PROJECT.TASK'
    ,'select' : Object.keys(fields).map(i => fields[i])
    ,'sort'   : [['PROJECT.TASK.startTime', 'incr']]
    ,'holder' : [projectId]
    ,'filter' : [['PROJECT.TASK.taskType', 'eq', 'Milestone']]
  }
  
  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Preprocess Child details
        result.uuId = result.uuId && result.uuId.length > 0? result.uuId : null;       

        if (result.stage && result.stage.length > 0) {
          result.stage = result.stage[0];
        } else {
          result.stage = null;
        }

        //Preprocess staff details
        result.staffs = [];
        if(result.staffIds && result.staffIds.length > 0) {
          for(let i = 0, len = result.staffIds.length; i < len; i++) {
            if (result.staffIds[i]) {
              result.staffs.push({
                uuId: result.staffIds[i],
              })
            }
          }
          delete result.staffIds;
        }

        // Rebuild parent list as a single string, omitting the root (project)
        if (result.parents) {
          let path = result.parents.split('\n');
          path.splice(0, 1); // remove the project name
          path.splice(path.length -1, 1); // remove the task name
          result.path = path.length !== 0 ? "/ " + path.join(" / ") : '';
          delete result.parents;
        }

        if (result.projectId && result.projectId.length > 0) {
          result.projectId = result.projectId[0];
        }
        
        return result;
      })
    }
  });
}

function listHomeDashboard(params, activeOnly = false) {
  let fields = {
    uuId:             ['TASK.uuId'],
    name:             ['TASK.name'],
    parents:          ['TASK.PARENT_ALL_TASK.name', '_ROOT_'],
    progress:         ['TASK.progress'],
    startTime:        ['TASK.startTime'],
    endTime:          ['TASK.closeTime'],
    project:          ['TASK.PROJECT.name'],
    projectId:        ['TASK.PROJECT.uuId']
  }
  
  if (params.userEmail) {
    // start from STAFF if we are filtering by email
    fields = {
        uuId:             ['STAFF.TASK.uuId']
      }
  }
  
  let sort = [];
  if( !params.userEmail && params && params.ksort && params.ksort.length > 0) {
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i];
        const sortOrder = params.order[i];
        let p = [fields[sortKey][0], sortOrder];
        if (sortKey == 'name') {
          p.push("");
          p.push("lowercase");
        }
        sort.push(p);
      }
    } else {
      sort.push([fields[params.ksort][0], params.order]);
    }
  } else {
    sort.push([params.userEmail ? 'STAFF.TASK.startTime' : 'TASK.startTime', 'incr']);
  }

  delete params.ksort;
  delete params.order;

  let data = {
    'name'    : 'Home Dashboard List'
    ,'type'   : 'msql'
    ,'start'  : params.start
    ,'limit'  : params.limit
    ,'limit_data': activeOnly
    ,'temporal': 'lite'
    ,'nominate': params.userEmail ? 'STAFF' : 'TASK'
    ,'select' : Object.keys(fields).map(i => fields[i])
    ,'sort'   : sort
  }
  
  data['filter'] = [
    [params.userEmail ? 'STAFF.TASK.taskType' : 'TASK.taskType', 'eq', 'Task'],
    [params.userEmail ? 'STAFF.TASK.progress' : 'TASK.progress', 'lt', 1],
  ];
  if (activeOnly) {
    data['filter'].push([params.userEmail ? 'STAFF.TASK.progress' : 'TASK.progress', 'gt', 0]);
  }

  if (params && params.userEmail) {
    data['filter'].unshift(['STAFF.email', 'eq', params.userEmail])
  }

  if (params.companyrule) {
    data['filter'].push(params.companyrule);
  }

  delete params.companyrule;
  
  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Preprocess Child details
        result.uuId = result.uuId && result.uuId.length > 0? result.uuId : null;       

        // Rebuild parent list as a single string, omitting the root (project)
        if (result.parents && result.parents.length > 0) {
          let parents = [];
          for (i = result.parents.length-1; i >= 0; i--) {
            let name = result.parents[i];
            if (name != "_ROOT_") {
              parents.push(result.parents[i])
            }
          }
          result.path = "/ " + parents.join(" / ");
        }

        // Fix these up, because they come back as lists
        if (result.project && result.project.length > 0) {
          result.project = result.project[0];
        }
        if (result.projectId && result.projectId.length > 0) {
          if (Array.isArray(result.projectId)) {
            result.projectId = result.projectId[0];
          }
        }
        return result;
      })
    }
  });
}


 /**
  * Get a list of tasks that are assigned to a stage.
  * 
  * @param {*} projectId
  * @param {*} stageIds A list of stage IDs in the project.
  */
function listAssignedStage(projectId, stageIds) {
  const fields = {
    uuId:             ['PROJECT.TASK.uuId'],
  }
  
  let data = {
    'name'    : 'Project Stage Assigned List'
    ,'type'   : 'msql'
    ,'start'  : 0
    ,'limit'  : -1
    ,'nominate': 'PROJECT.TASK'
    ,'select' : Object.keys(fields).map(i => fields[i])
  }
  
  data['filter'] = [
    ['PROJECT.uuId', 'eq', projectId],
    ['PROJECT.TASK.STAGE.uuId', 'within', stageIds.join("|")],
  ];

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Preprocess Child details
        result.uuId = result.uuId && result.uuId.length > 0? result.uuId : null;       
        
        return result;
      })
    }
  });
}

/**
 * Retrieving a start and end date for all tasks in project
 * @param Object payload contains either taskIds or filter. If both are provided, taskIds is preferred.
 */
function span({taskIds=null, filter=null} = {}) { 
  const fields = {
    taskUuid: ["PROJECT.TASK.uuId"],
    min: ["PROJECT.TASK.startTime", "<AYETIME>", "time", "min"],
    max: ["PROJECT.TASK.closeTime", "<OLDTIME>", "time", "max"]
  }

  let data = {
    'name': 'List of the tasks, with earliest and latest dates specified by children'
    , 'type': 'msql'
    , 'start': 0
    , 'limit': -1
    ,'as_temporal': 'lite'
    , 'nominate': 'PROJECT.TASK'
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  if(taskIds != null && taskIds.length > 0) {
    data['filter'] = [
      ['PROJECT.TASK.uuId', 'within', taskIds && taskIds.length > 0? taskIds.join('|') : '']
    ]
  } else if(filter != null) {
    data['filter'] = filter;
  }
  

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    const mappedData = rawData.map(i => {
      const result = {}
      for (let j = 0, len = i.length; j < len; j++) {
        result[keys[j]] = i[j];
      }

      return result;
    });
    let min = 0;
    let max = 0;
    for (let i = 0; i < mappedData.length; i++) {
      if ((mappedData[i].min !== 0 && mappedData[i].min < min) || min === 0) {
        min = mappedData[i].min;
      }
      if (mappedData[i].max !== 9223372036854776000 && mappedData[i].max > max || max === 0) {
        max = mappedData[i].max;
      }
    }
    return {
      min: min,
      max: max
    }
  });
}

function getProjectLocationAndAutoScheduling(taskId) {
  const fields = {
    locationId: 'PROJECT.LOCATION.uuId'
    , autoScheduling: 'PROJECT.autoScheduling'
  }
  

  let data = {
    'name'  : 'Get Project Location'
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : 1
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  
  data['filter'] = [
    '_or_', [
      ['PROJECT.TASK.uuId', 'eq', taskId]
    ]
  ]
  
  const url = '/api/query/match';
  
  return httpAjax.post(url, data, {}).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        return result;
      })
    }
  });
}

/**
 * Retrieving parents' name of a task.
 * @param Object payload contains taskIds.
 */
function getParentName({taskIds=null} = {}) { 
  if (taskIds == null || taskIds.length < 1) {
    return [];
  }
  
  const fields = {
    
    projectId: ["TASK.PROJECT.uuId"],
    projectName: ["TASK.PROJECT.name"],
    taskId: ["TASK.uuId"],
    taskName: ["TASK.name"],
    parentIds: ["TASK.PARENT_ALL_TASK.uuId", "_ROOT_"],
    parentName: ["TASK.PARENT_ALL_TASK.name", "_ROOT_"]
  }

  let data = {
    'name': 'Get parent\'s name for given task ids'
    , 'type': 'msql'
    , 'start': 0
    , 'limit': -1
    , 'nominate': 'TASK'
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  data['holder'] = taskIds != null? taskIds: [];

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    const mappedData = rawData.map(i => {
      const result = {}
      for (let j = 0, len = i.length; j < len; j++) {
        if (keys[j] == 'projectId' || keys[j] == 'projectName') {
          result[keys[j]] = i[j][0];
        } else {
          result[keys[j]] = i[j];
        }
      }

      return result;
    });
    for (let i = 0, len = mappedData.length; i < len; i++) {
      const d = mappedData[i];
      d.parentName.push(d.projectName);
      d.taskNamePath = d.parentName != null? `${d.parentName.reverse().join(' / ')} / ${d.taskName}` : d.taskName;
    }

    return mappedData;
  });
}

/**
 * Retrieving parents' name of a task by providing task id and epoch time.
 * @param Object payload contains taskIds.
 */
function getParentNameEpoch({taskId=null, epoch} = {}) { 
  if(taskId == null) {
    return [];
  }
  
  const fields = {
    
    projectId: ["TASK.PROJECT.uuId"],
    projectName: ["TASK.PROJECT.name"],
    taskId: ["TASK.uuId"],
    taskName: ["TASK.name"],
    parentIds: ["TASK.PARENT_ALL_TASK.uuId", "_ROOT_"],
    parentName: ["TASK.PARENT_ALL_TASK.name", "_ROOT_"]
  }

  let data = {
    'name': 'Get parent\'s name for given task ids'
    , 'type': 'msql'
    , 'start': 0
    , 'limit': -1
    , 'nominate': 'TASK'
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  data['epoch'] = epoch;
  data['holder'] = [taskId];

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    const mappedData = rawData.map(i => {
      const result = {}
      for (let j = 0, len = i.length; j < len; j++) {
        if (keys[j] == 'projectId' || keys[j] == 'projectName') {
          result[keys[j]] = i[j][0];
        } else {
          result[keys[j]] = i[j];
        }
      }

      return result;
    });
    for (let i = 0, len = mappedData.length; i < len; i++) {
      const d = mappedData[i];
      d.parentName.push(d.projectName);
      d.taskNamePath = d.parentName != null? `${d.parentName.reverse().join(' / ')} / ${d.taskName}` : d.taskName;
    }

    return mappedData;
  });
}

function applyTaskTemplate(targetId, templateIds, { override=false, group=false } = {}) {
  const _override = override != null? override : false;
  const _group = group != null? group : false;
  const url = `/api/task/task_template/add?override=${_override}&group=${_group}`;
  
  const config = {
    headers: getHeaders()
  }
  const data = {
    uuId: targetId
    , templateList: templateIds.map(i => { return { uuId: i } })
  }
  return httpAjax.post(url, data, config);
}

function queryLinkResources(taskIds) {
  if(taskIds == null || !Array.isArray(taskIds) || taskIds.length == 0) {
    return Promise.resolve([]);
  }
  
  const fields = {
    uuId: ["TASK.uuId"],
    name: ["TASK.name"],
    skillId: ["TASK.SKILL.uuId"],
    skillLevel: ["TASK.TASK-SKILL.level"],
    staffId: ["TASK.STAFF.uuId"],
    staffUtilization: ["TASK.TASK-STAFF.utilization"],
    resourceId: ["TASK.RESOURCE.uuId"],
    resourceUtilization: ["TASK.TASK-RESOURCE.utilization"],
    resourceQuantity: ["TASK.TASK-RESOURCE.quantity"],
    rebateId: ["TASK.REBATE.uuId"],
    tagId: ["TASK.TAG.uuId"],
    tagName: ["TASK.TAG.name"]
  }
  let data = {
    'name': 'Get linked resources for given task ids'
    , 'type': 'msql'
    , 'start': 0
    , 'limit': -1
    , 'nominate': 'TASK'
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  data['holder'] = taskIds;

  const url = '/api/query/match';
  return httpAjax.post(url, data, {}).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    const mappedData = rawData.map(i => {
      const result = {}
      for (let j = 0, len = i.length; j < len; j++) {
        result[keys[j]] = i[j];
      }

      //skills
      result.skills = [];
      if (Array.isArray(result.skillId) && result.skillId.length > 0) {
        for (let j = 0, len = result.skillId.length; j < len; j++) {
          result.skills.push({
            uuId: result.skillId[j]
            , level: result.skillLevel[j]
          })
        }
      }
      delete result.skillId;
      delete result.skillLevel;

      //staff
      result.staff = [];
      if (Array.isArray(result.staffId) && result.staffId.length > 0) {
        for (let j = 0, len = result.staffId.length; j < len; j++) {
          result.staff.push({
            uuId: result.staffId[j]
            , utilization: result.staffUtilization[j]
          })
        }
      }
      delete result.staffId;
      delete result.staffUtilization;

      //resources
      result.resources = [];
      if (Array.isArray(result.resourceId) && result.resourceId.length > 0) {
        for (let j = 0, len = result.resourceId.length; j < len; j++) {
          result.resources.push({
            uuId: result.resourceId[j]
            , utilization: result.resourceUtilization[j]
            , quantity: result.resourceQuantity[j]
          })
        }
      }
      delete result.resourceId;
      delete result.resourceUtilization;
      delete result.resourceQuantity;

      //rebates
      result.rebates = Array.isArray(result.rebateId) && result.rebateId.length > 0? result.rebateId.map(j => ({ uuId: j })) : [];
      delete result.rebateId;

      //tags
      result.tags = [];
      if (Array.isArray(result.tagId) && result.tagId.length > 0) {
        for (let j = 0, len = result.tagId.length; j < len; j++) {
          result.tags.push({
            uuId: result.tagId[j]
            , name: result.tagName[j]
          })
        }
      }
      delete result.tagId;
      delete result.tagName;

      return result;
    });
    return mappedData;
  });
}