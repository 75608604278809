
import { httpAjax } from '@/helpers';

export const locationLinkRebateService = {
  create,
  remove
};

/**
 * Create a new Link from location to rebate 
 * by passing necessary information
 * @param String locationId, Project's UUID
 * @param Array rebates, rebate details. Data sample: [{ uuId }]
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', rebateList: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx' }] }
 */
function create(locationId, rebates) {
  const url = '/api/location/link/rebate/add';
  const config = {
    headers: getHeaders()
  };

  const rebateList = []
  rebates.forEach(i => {
    rebateList.push( {uuId: i.uuId} );
  });

  const data = {
    uuId: locationId,
    rebateList: rebateList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from location to rebate.
 * by passing in an array list of links uuIds
 * @param String locationId, Project's UUID
 * @param Array rebateIds, list of rebate's UUID 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', rebateList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }
 */
function remove(locationId, rebates) {
  const url = '/api/location/link/rebate/delete';
  const config = {
    headers: getHeaders()
  };

  const rebateList = []
  rebates.forEach(i => {
    rebateList.push( {uuId: i.uuId} );
  });

  const data = {
    uuId: locationId,
    rebateList: rebateList
  }
  return httpAjax.post(url, data, config);
}


function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}