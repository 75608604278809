var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", [_vm._v(_vm._s(_vm.label))]),
      _vm.hasError
        ? _c("font-awesome-icon", {
            staticClass: "ml-1",
            staticStyle: { color: "red", "font-size": "1.125em" },
            attrs: { icon: ["fas", "triangle-exclamation"] },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }