
import { httpAjax, urlParams } from '@/helpers';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

export const departmentService = {
  create,
  link,
  unlink,
  tree,
  query,
  update,
  remove,
  clone,
  listStaffInDepartments,
  list,
  companyLookup,
  listNames,
  listUniqueValuesOfProperty
};

/**
 * Create a new department 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', fullName: 'John Doe' ... }]
 */
function create(data) {
  const url = '/api/department/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Link a department to a parent 
 * by passing necessary information
 * @param Json data 
 * e.g {"uuId": "f9e8e71b-3047-4e5e-b8e2-b769241ea461",
		"departmentList": [
			{"uuId": "86991afd-4fa7-4eaf-9412-f17fdea4c9b7"}
		]}
 */
function link(data) {
  const url = '/api/department/link/department/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data[0], config);
}

/**
 * UnLink a department to a parent 
 * by passing necessary information
 * @param Json data 
 * e.g {"uuId": "f9e8e71b-3047-4e5e-b8e2-b769241ea461",
		"departmentList": [
			{"uuId": "86991afd-4fa7-4eaf-9412-f17fdea4c9b7"}
		]}
 */
function unlink(data) {
  const url = '/api/department/link/department/delete';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data[0], config);
}

/**
 * Retrieving a tree of department info 
 */
function tree(params) {

  const url = '/api/department/tree';
  const config = {
    params: params,
    
  }
  return httpAjax.get(url, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: '',
      arg_order: null,
      data: rawData.map(department => {
        function populateStatus(d) {
          if (d.departmentList) {
            d.departmentList.forEach(subDep => {
              populateStatus(subDep)
            });
          }
          if (d.staffList) {
            let now = moment();
            d.staffList.forEach(staff => {
              // Set 'Active' or 'Inactive' from start/end dates
              // Results always have a date, even when not set, with max/min value.
              var start = moment(staff.startDate);
              var end = moment(staff.endDate);
              if (start.isSameOrBefore(now) && end.isSameOrAfter(now)) {
                staff.status = 'Active';
              } else {
                staff.status = 'Inactive';
              }
            })
          }
        }
        populateStatus(department);
        return department;
      })
    }
  });
}

/**
 * Retrieving a list of department details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function query(data, links = null) {
  const url = urlParams('/api/department/get', links);
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of departments info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='fullName', order: 'incr' }}
 * @param Boolean isGenericStaff: Flag to get generic staff list if true
 */
//Commented the isGenericStaff parameter because it is not used in the logic
function list(params, customFields) {
  const fields = {
    uuId: 'DEPARTMENT.uuId',
    name: 'DEPARTMENT.name',
    color: 'DEPARTMENT.color',
    companyColor: 'DEPARTMENT.COMPANY.color',
    tag: 'DEPARTMENT.TAG.name',
    identifier: 'DEPARTMENT.identifier'
  }


  if (Array.isArray(customFields) && customFields.length > 0) {
    for(const cField of customFields) {
      fields[cField.name] = `DEPARTMENT.${cField.name}`;
    }
  }

  //When params.fields are provided, clean up the selectors which don't appear in the param.fields.
  if (Array.isArray(params.fields) && params.fields.length > 0) {
    const fieldProps = Object.keys(fields);
    for (const prop of fieldProps) {
      if (prop == 'uuId') {
        continue;
      }
      if (!params.fields.includes(prop)) {
        delete fields[prop];
      }
    }
  }
  
  let data = {
    'name': 'DEPARTMENT List'
    , 'type': 'msql'
    , 'nominate': 'DEPARTMENT'
    , 'start': params.start
    , 'limit': params.limit
    , 'select': Object.keys(fields).map(i => [fields[i]])
  }

  if (params.holders) {
    data['holder'] = params.holders;
  }
  
  if (params.filter && params.filter.length > 0) {
    if (typeof params.filter === 'string') {
      data['filter'] = [
        ['DEPARTMENT.name', 'regex', params.filter]
      ]
    }
    else {
      data['filter'] = params.filter;
    }
  }

  //BadgeFilter related
  if (Array.isArray(params.badgeFilters) && params.badgeFilters.length > 0) {
    const badgeFilterList = [];
    for (const f of params.badgeFilters) {
      if (f.field == null || !Array.isArray(f.value) || f.value.length == 0) {
        continue;
      }
      
      let field = null;
      if (f.field == 'tagName') {
        field = 'DEPARTMENT.TAG.name';
      } else if (f.field == 'companyName') {
        field = 'DEPARTMENT.COMPANY.name';
      } else {
        field = `DEPARTMENT.${f.field}`;
      } 

      const isEqual = !f.operator || f.operator === 'is';
      let valueList = [];
      const value = f.value;
      for (const v of value) {
        if (v.text != null && (typeof v.text === 'number' || (typeof v.text === 'string' && v.text&& v.text.length > 0 && v.text !== '(Empty)'))) {
          //use value when exists. Otherwise use text
          //Reason: When value property exists, it means the text is localized and can't be used in filter.
          valueList.push([field, 'eq', Object.hasOwn(v, 'value')? v.value : v.text]);
        }
        //Start processing (EMPTY) field value 
        else if (isEqual) {
          valueList.push('_not_', [[field]])
        } else {
          //Special handling: when is not empty, put it into badgeFilterList directly to act as 'field must exist'. 
          badgeFilterList.push([field])
        }
      }
      if (valueList.length > 0) {
        if (valueList.length > 1) {
          valueList = ['_or_', valueList];
        }
        if (!isEqual) {
          badgeFilterList.push('_not_', valueList);
        } else {
          badgeFilterList.push(...valueList);
        }
      }
    }
    if (badgeFilterList.length > 0) {
      if (Array.isArray(data.filter) && data.filter.length > 0) {
        data.filter = [...data.filter, ...badgeFilterList]
      } else {
        data.filter = [...badgeFilterList]
      }
    }
  }


  if (params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if (Array.isArray(params.ksort)) {
      for (let i = 0, len = params.ksort.length; i < len; i++) {
        if (typeof fields[params.ksort[i]] !== 'undefined') {
          data['sort'].push([fields[params.ksort[i]], params.order[i], '', 'lowerCase']);
        }
      }
    } else if (typeof fields[params.ksort] !== 'undefined'){
      data['sort'].push([fields[params.ksort], params.order, '', 'lowerCase']);
    }
  }

  //Delete un-needed keys from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;
  delete params.badgeFilters;
  delete params.holders;
  delete params.fields;
  
  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return {
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort ? params.ksort : '',
      arg_order: params.order ? params.arg_order : null,
      data: rawData.map(i => {
        const result = {}
        for (let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        return result;
      })
    }
  });
}

/**
 * Retrieving a concise list of departments info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='fullName', order: 'incr' }}
 * @param Boolean isGenericStaff: Flag to get generic staff list if true
 */
//Commented the isGenericStaff parameter because it is not used in the logic
function companyLookup(params, /**isGenericStaff=false*/) {
  const fields = {
    uuId: 'COMPANY.DEPARTMENT.uuId',
    name: 'COMPANY.DEPARTMENT.name'
  }

  let data = {
    'name': 'COMPANY DEPARTMENT Lookup'
    , 'type': 'msql'
    , 'group': 'COMPANY'
    , 'start': params.start
    , 'limit': params.limit
    , 'select': Object.keys(fields).map(i => [fields[i]])
  }

  if (params.filter && params.filter.length > 0) {
    if (typeof params.filter === 'string') {
      data['filter'] = [
        ['COMPANY.DEPARTMENT.name', 'regex', params.filter]
      ]
    }
    else {
      data['filter'] = params.filter;
    }
  }

  if (params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if (Array.isArray(params.ksort)) {
      for (let i = 0, len = params.ksort.length; i < len; i++) {
        if (typeof fields[params.ksort[i]] !== 'undefined') {
          data['sort'].push([fields[params.ksort[i]], params.order[i], '', 'lowerCase']);
        }
      }
    } else if (typeof fields[params.ksort] !== 'undefined'){
      data['sort'].push([fields[params.ksort], params.order, '', 'lowerCase']);
    }
  }

  //Delete un-needed keys from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return {
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort ? params.ksort : '',
      arg_order: params.order ? params.arg_order : null,
      data: rawData.map(i => {
        const result = {}
        for (let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = Array.isArray(i[j]) ? i[j][0] : i[j];
        }
        return result;
      })
    }
  });
}

/**
 * Update department details 
 * by passing  necessary information
 * @param Array data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', fullName: 'John Doe' ... }]
 */
function update(data) {
  const url = '/api/department/update';
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of departments
 * by passing in an array list of uuIds
 * @param Array data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = '/api/department/delete';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}

/**
 * Clone a department with given new name
 * @param String refId Target department UuId
 * @param Object data Object contains name for cloned department.
 */
function clone(refId, data) {
  const url = '/api/department/clone';
  const config = {
    data: data,
    headers: getHeaders(),
    params: { reference: refId }
  };
  return httpAjax.post(url, data, config);
}

/**
 * Get a list of each department and the start/end of each staff in them
 */
 function listStaffInDepartments(params) { 
  const fields = {
    name: ["DEPARTMENT.name"],
    startDate: ["DEPARTMENT.STAFF.startDate"],
    endDate: ["DEPARTMENT.STAFF.endDate"],
    genericStaff: ["DEPARTMENT.STAFF.genericStaff"]
  }

  let data = {
    'name'  : 'Department Staff List'
    ,'type' : 'msql'
    ,"nominate": "DEPARTMENT"
    ,'start' : 0
    ,'limit' : -1
    ,'select' : Object.keys(fields).map(i => fields[i])
  }

  if (params.companyrule) {
    data['filter'] = [params.companyrule];
  }
  delete params.companyrule;

  const url = '/api/query/match';
  const config = {}
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        return result;
      })
    }
  });
}


/**
* Retrieving a concise list of DEPARTMENT info by 
* passing in pagination, sorting and filtering parameters
* @param Object params 
* e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
*/
function listNames(params) {
  const fields = {
    uuId: 'DEPARTMENT.uuId',
    name: 'DEPARTMENT.name'
  }
  

  let data = {
    'name'  : 'DEPARTMENT Name List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'DEPARTMENT'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

function listUniqueValuesOfProperty(field) {
  const fieldSelector = [`DEPARTMENT.${field}`]
  
  const data = {
    'name'  : `Unique DEPARTMENT property: ${field}`
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'select': [
      fieldSelector
    ]
    , 'dedup': true
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    let rawData = response.data[listName] || [];
    rawData = rawData.map(i => i[0]);
    rawData.sort((a, b) => {
      if (typeof a === 'string') {
        return a.localeCompare(b, undefined, { sensitivity: 'base'})
      } else {
        if (a < b) {
          return -1
        } else if (a > b) {
          return 1
        }
        return 0
      }
    });
    return rawData;
  });
}
