<template>
  <div>
    <span>{{ label }}</span>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'PayFrequencyCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    const data = this.params && this.params.data? this.params.data : null;
    const payFrequency = data && data.payFrequency? data.payFrequency : (this.params.value? this.params.value : null);
    

    if (typeof this.params.getOptions == 'function') {
      const found = this.params.getOptions().find(i => i.value == payFrequency);
      if (found != null) {
        this.value = found.text;
      }
    }

    if (this.value == null) {
      this.value = this.params.value;
    }

  },
  computed: {
    label() {
      return this.value? this.value : '';
    }
  }
})
</script>
