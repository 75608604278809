var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "select-header-container", staticClass: "select-header-container" },
    [
      _vm.label
        ? _c("span", { staticClass: "mr-1" }, [_vm._v(_vm._s(_vm.label))])
        : _vm._e(),
      _c(
        "div",
        {
          ref: "list-multiselect-wrapper",
          staticClass: "list-cell-header-multiselect-wrapper",
        },
        [
          _c("multiselect", {
            ref: "list-multiselect",
            staticClass:
              "custom-dropdown-options list-cell-header enable-option-icon fit-label-fix fit-content-fix",
            attrs: {
              "max-height": 300,
              options: _vm.options.map((i) => i.value),
              "custom-label": _vm.getOptionLabel,
              placeholder: "",
              searchable: false,
              "allow-empty": false,
              showLabels: false,
            },
            on: { input: _vm.onChange, open: _vm.onSelectOpened },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function (props) {
                  return [
                    _vm.value == props.option
                      ? _c("font-awesome-icon", {
                          staticClass: "selected-option-icon",
                          attrs: { icon: ["far", "check"] },
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "option__title" }, [
                      _vm._v(_vm._s(_vm.getOptionLabel(props.option))),
                    ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }