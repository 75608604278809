
import { httpAjax } from '@/helpers';

export const userLinkAccessPolicyService = {
  create,
  remove,
};

/**
 * Create a new Link from user to access policy 
 * by passing necessary information
 * @param String userId, User's UUID
 * @param Array access policies, Access Policy details. Data sample: [{ uuId }]
 * request body format sample: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', accessPolicyList: { uuId: 'xxxx..' } }]
 */
function create(userId, accessPolicies) {
  const url = '/api/user/link/access_policy/add';
  const config = {
    headers: getHeaders()
  };

  const accessPolicyList = []
  accessPolicies.forEach(i => {
    accessPolicyList.push( {uuId: i} );
  });

  const data = {
    uuId: userId,
    accessPolicyList: accessPolicyList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from user to access policy.
 * by passing in an array list of links uuIds
 * @param String userId, User's UUID
 * @param Array accessPolicyIds, list of Access Policy's UUID 
 * request body format sample: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', accessPolicyList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }]
 */
function remove(userId, accessPolicies) {
  const url = '/api/user/link/access_policy/delete';
  const config = {
    headers: getHeaders()
  };

  const accessPolicyList = []
  accessPolicies.forEach(i => {
    accessPolicyList.push( {uuId: i} );
  });

  const data = {
    uuId: userId,
    accessPolicyList: accessPolicyList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}