var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.allowManage && _vm.isEditable
    ? _c("div", [
        _c(
          "a",
          {
            class: { "grid-cell-summary": _vm.isSummaryTask },
            style: _vm.labelStyle,
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.invokeParentMethod.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.label))]
        ),
      ])
    : _c("div", [
        _c("span", { style: _vm.isSummaryTask ? "font-weight: 500" : "" }, [
          _vm._v(_vm._s(_vm.label)),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }