<template>
  <BlankCenterContainer>
    <b-col md="6">
      <div class="standard-logo-wrapper mx-auto">
          <div id="logo" class="standard-logo" alt="Projectal Logo" border="0" name="logo"> </div>
      </div>
      <b-card class="mx-auto">
        <div class="text-center mb-4">
          <h4>{{ $t('reset_password.title') }}</h4>
          <p>{{ $t('reset_password.title_detail') }}
            <b-link id="LINK_PASS_INFO"><font-awesome-icon :icon="['far', 'circle-info']"/></b-link>
            <b-popover target="LINK_PASS_INFO" triggers="click blur" placement="top">
              <span v-html="passwordInfo"></span>
            </b-popover>
          </p>
        </div>
        

        <div v-if="ResetPasswordFormState.LOADING === formState" class="text-center">
          <b-spinner label="Spinning" />
        </div>
        <b-form v-else-if="ResetPasswordFormState.HAS_VALID_TOKEN === formState">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <font-awesome-icon :icon="['far', 'lock']" />
                </b-input-group-text>
              </b-input-group-prepend>
              <input type="password" class="last-child form-control" :class="{ 'is-invalid': showPasswordError }" id="inputPassword" placeholder="Enter Password" ref="password"
                :data-vv-as="$t('reset_password.field.password')"
                data-vv-name="password"
                data-vv-delay="500"
                v-model="password"
                v-validate="{ required: validate }"
                :state="fieldValidateUtil.stateValidate(false, veeFields, errors, 'password')"
                
                />
            </b-input-group>
            <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showPasswordError }">
              <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('password') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <font-awesome-icon :icon="['far', 'lock']" />
                </b-input-group-text>
              </b-input-group-prepend>
              <input type="password" class="last-child form-control" :class="{ 'is-invalid': showConfirmPasswordError }"  id="confirmPass" placeholder="Confirm Password"
                :data-vv-as="$t('reset_password.field.password_confirm')"
                data-vv-name="confirmPass"
                data-vv-delay="500"
                v-model="confirmPass"
                v-validate="{ required: validate, confirmed: 'password' }"
                />
            </b-input-group>
            <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showConfirmPasswordError }">
              <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('confirmPass') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-btn class=" btn-primary btn-block" @click.prevent="changePassword" :disabled="isSubmitDisabled">Submit</b-btn>
        </b-form>
        <template v-else-if="ResetPasswordFormState.HAS_INVALID_TOKEN === formState">
          <div class="alert alert-danger text-center" role="alert">
            <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>
            {{ $t('reset_password.error.invalid_token') }}
          </div>
          <div class="text-center">
            <router-link class="d-block small mt-3" to="/login">{{ $t('link.login_page') }}</router-link>
          </div>
        </template>

        <template v-else-if="ResetPasswordFormState.UPDATE_SUCCESS === formState">
          <div class="alert alert-success text-center" role="alert">
            <font-awesome-icon :icon="['far', 'check']" />
            {{ $t('reset_password.success') }}
          </div>
          <div class="text-center">
            <router-link class="d-block small mt-3" to="/login">{{ $t('link.login_page') }}</router-link>
          </div>
        </template>

        <template v-else-if="ResetPasswordFormState.UPDATE_FAILURE === formState">
          <div class="alert alert-danger text-center" role="alert">
            <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>
            {{ $t('reset_password.failure') }}
          </div>
          <div class="text-center">
            <router-link class="d-block small mt-3" to="/login">{{ $t('link.login_page') }}</router-link>
          </div>
        </template>

        <template v-else-if="ResetPasswordFormState.USER_LICENCE_LIMIT === formState">
          <div class="alert alert-danger text-center" role="alert">
            <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>
            {{ $t('user.error.user_limit_exceeded') }}
          </div>
        </template>
      </b-card>
      <div class="auth-footer mt-3 text-center">{{ `© ${(new Date()).getFullYear()} JanusKS Pty. Ltd.  All rights reserved. Version ${versionNumber}.` }}</div>
    </b-col>
  </BlankCenterContainer>
</template>

<script>
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { authenticationService } from '@/services'
import BlankCenterContainer from '@/views/layout/BlankCenterContainer'

export default {
  name: 'ResetPassword',
  components: {
    BlankCenterContainer,
  },
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      formState: 0,
      password: null,
      confirmPass: null,
      validate: false,
      versionNumber: null,
      passwordRules: {}
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil;
    this.validate = true;
    this.ResetPasswordFormState = Object.freeze({
      LOADING: 1,
      HAS_INVALID_TOKEN: 2,
      HAS_VALID_TOKEN: 3,
      UPDATE_SUCCESS: 4,
      UPDATE_FAILURE:5,
      USER_LICENCE_LIMIT:6
    })
    this.formState = this.ResetPasswordFormState.HAS_VALID_TOKEN;
    this.resetToken = this.$route.params.resetToken;
    
    // Get version number for footer
    const self = this;
    this.$store.dispatch('data/status').then(value => {
      self.versionNumber = value.version;
      document.title = `Projectal - ${value.companyName}`;
    })
    .catch(e => {
      this.versionNumber = "N/A";
      document.title = 'Projectal';
    });
    
    this.$store.dispatch('data/schemaPassword', {type: 'password' })
    .then((response) => {
      this.passwordRules = response;
    });
    
    //USED when debug ui, remove this before production deployment
    // this.formState = this.ResetPasswordFormState.HAS_VALID_TOKEN;
  },
  beforeUnmount() {
    this.validate = false;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.ResetPasswordFormState = null;
  },
  computed: {
    isSubmitDisabled() {
      return !this.password || !this.confirmPass || !!this.errors.first('password') || !!this.errors.first('confirmPass');
    },
    passwordInfo() {
      const mandatoryTexts = [];
      const optionalTexts = [];
      for (const key of Object.keys(this.passwordRules)) {
        const rule = this.passwordRules[key];
        let txt = '';
        if (Object.keys(rule).length === 2) {
          const key1 = Object.keys(rule)[0];
          const key2 = Object.keys(rule)[1];
          txt = this.toText(key1, [rule[key1], rule[key2]]);
          if (key1 !== 'permit_char_min' &&
              key1 !== 'use') {
            
            if (/\d$/.test(key)) {
              optionalTexts.push(txt);
            }
            else {
              mandatoryTexts.push(txt);
            }
          }
        }
        else if (rule['not_matching_user']) {
          txt = this.toText('not_matching_user', []);
          if (/\d$/.test(key)) {
            optionalTexts.push(txt);
          }
          else {
            mandatoryTexts.push(txt);
          }
        }
        else if (rule['not_matching_email']) {
          txt = this.toText('not_matching_email', []);
          if (/\d$/.test(key)) {
            optionalTexts.push(txt);
          }
          else {
            mandatoryTexts.push(txt);
          }
        }
        else if (rule['no_repeat_char_max']) {
          txt = this.toText('no_repeat_char_max', [rule['no_repeat_char_max']+1]);
          if (/\d$/.test(key)) {
            optionalTexts.push(txt);
          }
          else {
            mandatoryTexts.push(txt);
          }
        }
      }
      
      let ret = '';
      
      // mandatory rules
      if (mandatoryTexts.length !== 0) {
        ret += `<p class="text-left mb-0">${ this.$i18n.t('reset_password.password_info.must')}</p>
              <ul class="text-left pl-3">`;
      }
      
      for (const text of mandatoryTexts) {
        ret += `<li>${text}</li>`;
      }
      ret += `</ul>`;
      
      // optional rules
      if (optionalTexts.length !== 0) {
        ret += `<p class="text-left mb-0">${ this.$i18n.t('reset_password.password_info.optional', [this.passwordRules.rules.min])}</p>
              <ul class="text-left pl-3">`;
      }
      
      for (const text of optionalTexts) {
        ret += `<li>${text}</li>`;
      }
      ret += `</ul>`;
      return ret;
    },
    showConfirmPasswordError() {
      return fieldValidateUtil.hasError(this.errors, 'confirmPass');
    },
    showPasswordError() {
      return fieldValidateUtil.hasError(this.errors, 'password');
    }
  },
  methods: {
    toText(key, val) {
      return this.$t(`reset_password.${key}`, val);
    },
    changePassword() {
      authenticationService.changePasswordForReset(this.password, this.password, this.resetToken)
      .then(() => {
        this.formState = this.ResetPasswordFormState.UPDATE_SUCCESS;
      })
      .catch(e => {
        console.debug(e.response); // eslint-disable-line no-console
        const response = e.response;
        if (response) {
          if (406 === response.status) {
            this.errors.add({
              field: 'password',
              msg: this.$t('complete_registration.error.invalid_password')
            })
          } else if (404 === response.status) {
            this.formState = this.ResetPasswordFormState.HAS_INVALID_TOKEN;
          }
          else if (405 === response.status) {
            if (response.data.jobClue.hint === 'user.licence.limit') {
              this.formState = this.ResetPasswordFormState.USER_LICENCE_LIMIT;
            }
          }
        } else {
          this.formState = this.ResetPasswordFormState.UPDATE_FAILURE;
        }
      });
    }
  }  
};
</script>

