
import { httpAjax } from '@/helpers';
import { analyzeDurationAUM } from '@/helpers/task-duration-process';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { getKeysWithoutRedactedFields, getRedactedFields } from './common';

const fieldSelectorMap = __getAllFieldSelectorsMap();

export const aggridGanttService = {
  getTaskIdsByFilter,
  listTaskProjectDetails,
  listPagedTaskDataDynamic,
  listTaskDataDynamic,
  span
};

/**
 * Retrieving a start and end date for all tasks in project or among the provided taskIds.
 * @param Object payload contains either taskIds or filter. If both are provided, taskIds is preferred.
 */
 function span({entity='TASK', taskIds=null, filter=null} = {}) { 
  if (taskIds == null && filter == null) {
    return Promise.reject(new Error(`Missing parameter. Variable 'taskIds' and 'filter' can not be both null.`));
  }
  if (taskIds == null) {
    return Promise.reject(new Error('Missing parameter. '));
  }
  let _entity = 'TASK';
  if (taskIds == null) {
    _entity = entity;
  }
  const fields = {
    taskUuid: [`${_entity}.uuId`],
    min: [`${_entity}.startTime`, "<AYETIME>", "time", "min"],
    max: [`${_entity}.closeTime`, "<OLDTIME>", "time", "max"]
  }

  let data = {
    'name': 'List of the tasks, with earliest and latest dates specified by children'
    , 'type': 'msql'
    , 'start': 0
    , 'limit': -1
    , 'holder': taskIds
    , 'nominate': _entity
    , 'select': Object.keys(fields).map(i => fields[i])
  }  

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    const mappedData = rawData.map(i => {
      const result = {}
      for (let j = 0, len = i.length; j < len; j++) {
        result[keys[j]] = i[j];
      }

      return result;
    });
    let min = 0;
    let max = 0;
    for (let i = 0; i < mappedData.length; i++) {
      if ((mappedData[i].min !== 0 && mappedData[i].min < min) || min === 0) {
        min = mappedData[i].min;
      }
      if (mappedData[i].max !== 9223372036854776000 && mappedData[i].max > max || max === 0) {
        max = mappedData[i].max;
      }
    }
    return {
      min: min,
      max: max
    }
  });
}

/**
 * WARNING: This is a private method.
 * @params {Array} taskIds
 * @params {Array} list
 * @params {Boolean} removeParen
 */
function __convertToGanttExpectedFormat({ taskIds=[], list=[], removeParent=false } = {}) {
  // 1. Loop thru list, construct immediate parent of task.
  // 2. Construct task path start with project. e.g. /project/task name/task name...
  // 3. construct predecessor in an array of object with { id, type, lag, source } and filter out predecessors which are not part of the taskIds list.
  // 4. Delete unnecessary properties from object.
  // 5. Construct and return { data, collections: {link} } object.
  if(list == null || list.length < 1) {
   return { data: [], collections: { links: [] } }; 
  }
  const data = [];
  const links = [];
  for(let i = 0, len = list.length; i < len; i++) {
    const record = list[i];
    const obj = cloneDeep(record);

    if(!removeParent && record.pUuIds
        && record.pUuIds.length > 0 
        && record.projId !== record.pUuIds[0]) {
      obj.parent = record.pUuIds[0];
      obj.parentObj = { uuId: record.pUuIds[0], name: record.pNames && record.pNames.length > 0 ? record.pNames[0] : '' };
    }
    if(record.pNames && record.pNames.length > 0) {
      obj.taskPath = record.pNames.join('/');
    }

    obj.type = `${obj.type}`;
    if(record.preId && record.preId.length > 0)  {
      obj.predecessors = [];
      for(let j = 0, len = record.preId.length; j < len; j++) {
        const predecessor = {
          id: record.preLinkId[j]
          , source: record.preId[j]
          , type: `${record.preLinkType[j]}`
          , lag: record.preLinkLag[j]
          , target: record.id
        }
        obj.predecessors.push(predecessor);
        if(taskIds.includes(record.preId[j])) {
          links.push(cloneDeep(predecessor));
        }
      }
    }

    if (obj.readonly !== true) {
      delete obj.readonly;
    }

    //Determine if the task is qualified for 'unscheduled' state.
    let nullCounter = 0;
    if (null == obj.duration) {
      nullCounter++;
    }
    if (null == obj.start_date) {
      nullCounter++;
    }
    if (null == obj.end_date) {
      nullCounter++;
    }
    if (nullCounter > 1) {
      obj.unscheduled = true; // The task is unscheduled means no task bar is rendered. 
      
    }
    // Remark: set start_date to 1970-01-01 (which is 0 milliseconds) so that the gantt won't show error popup with message: Invalid date
    if (obj.start_date == null) {
      obj.start_date = '1970-01-01';
    }
    if (obj.end_date == null) {
      obj.end_date = '1970-01-01';
    }

    // For Project type, remove duration and durationAUM so that Gantt will recalculate the duration from start_date and end_date.
    if(obj.type == 1) {
      delete obj.duration;
      delete obj.durationAUM;
    }

    if(obj.type == 1) {
      obj.type = 'janusks_project';
    }

    if(obj.type == 0) { //type: Task
      obj.orgDuration = obj.duration; //Keep original duration, it will be used in task drag and update event.
      obj.orgStartDate = obj.start_date;
      obj.orgEndDate = obj.end_date;
      delete obj.duration;//Remove duration to let Gantt use start_date and end_date instead of duration.
    }

    //Delete raw props after processed data props are created.
    delete obj.pUuIds;
    delete obj.pNames;
    delete obj.pUuId;
    delete obj.pName;
    delete obj.preId;
    delete obj.preLinkId;
    delete obj.preLinkLag;
    delete obj.preLinkType;
    delete obj.preName;
    
    //Delete additional props that needed only by grid but not gantt.
    delete obj.startTime;
    delete obj.closeTime;
    // delete obj.durationInDays; //Keep obj.durationInDay. It is needed in task tooltip.
    delete obj.priority;
    delete obj.stage;
    delete obj.stageName;
    delete obj.complexity;
    delete obj.description;
    delete obj.fixedCost;
    delete obj.estimatedCost;
    delete obj.actualCost;
    delete obj.estimatedDuration;
    delete obj.totalActualDuration;
    delete obj.skillIds;
    delete obj.skillNames;
    delete obj.skillLevels;
    delete obj.resourceIds;
    delete obj.resourceNames;
    delete obj.resourceUnits;
    delete obj.constraint;
    delete obj.rebates;
    delete obj.template;
    delete obj.notes;
      
    data.push(obj);
  }
  
  return { 
    data
    , collections: {
      links
    }
  };
  //Sample
  // {
  //   "data":[
  //     {"id":"1", "text":"Project #2", "start_date":"01-04-2020", "duration":18,
  //       "progress":0.4, "open": true},
  //     {"id":"2", "text":"Task #1", "start_date":"02-04-2020", "duration":8,
  //       "progress":0.6, "parent":"1"},
  //     {"id":"3", "text":"Task #2", "start_date":"11-04-2020", "duration":8,
  //       "progress":0.6, "parent":"1"}
  //   ],
  //   collections: {
  //     "links":[
  //       {"id":"1", "source":"1", "target":"2", "type":"1"},
  //       {"id":"2", "source":"2", "target":"3", "type":"0"},
  //       {"id":"3", "source":"3", "target":"4", "type":"0"},
  //       {"id":"4", "source":"2", "target":"5", "type":"2"}
  //     ]
  //   }
  // }
}

/**
 * WARNING: This is a private method.
 * @params {Array} taskIds
 * @params {Array} list
 * @params {Boolean} removeParen
 */
function __convertToAgGridExpectedFormat({ list=[], taskNames=[], skillCustomFields=[], resourceCustomFields=[] } = {}) {
  //1. Set default value 'ROOT' for parent when the pUuId is empty or null or same to project id. And reset pName to empty if it is root.
  //2. Consolidate skill details into 'skills' object array.
  //3. Consolidate staff details into 'staffs' object array.
  //4. Consolidate resource details into 'resources' object array.
  //5. Create label property with name value for DetailLinkCellRenderer. (Note: Not sure if this is still in use. Need to double check)

  if(list == null || list.length < 1) {
    return { records: [], taskNames: [] }
  }
  
  const records = [];
  for(let i = 0, len = list.length; i < len; i++) {
    const obj = cloneDeep(list[i]);

    //Preprocess Skill details
    obj.skills = [];
    if(obj.skillIds && obj.skillIds.length > 0) {
      for(let i = 0, len = obj.skillIds.length; i < len; i++) {
        const s = {
          uuId: obj.skillIds[i],
          name: obj.skillNames[i],
          level: obj.skillLevels[i]
        }
        for (const f of skillCustomFields) {
          s[f.name] = obj[`skill_${f.name}`];
        }
        obj.skills.push(s);
      }
    }
    delete obj.skillIds;
    delete obj.skillNames;
    delete obj.skillLevels;
    for (const f of skillCustomFields) {
      delete obj[`skill_${f.name}`];
    }

    //Preprocess Resources details
    obj.resources = [];
    if(obj.resourceIds && obj.resourceIds.length > 0) {
      for(let i = 0, len = obj.resourceIds.length; i < len; i++) {
        const r = {
          uuId: obj.resourceIds[i],
          name: obj.resourceNames[i],
          unit: obj.resourceUnits[i]
        }
        for (const f of resourceCustomFields) {
          r[f.name] = obj[`resource_${f.name}`];
        }
        obj.resources.push(r);
      }
    }
    delete obj.resourceIds;
    delete obj.resourceNames;
    delete obj.resourceUnits;
    for (const f of resourceCustomFields) {
      delete obj[`resource_${f.name}`];
    }

    //Delete props that are needed only by gantt but not aggrid.
    delete obj.pUuIds;
    delete obj.pNames;
    delete obj.preId;
    delete obj.preLinkId;
    delete obj.preLinkLag;
    delete obj.preLinkType;
    delete obj.preName;
    delete obj.constraint_date;
    delete obj.constraint_type;
    delete obj.type;
    delete obj.open;
    
    //Rename some prop names to fit aggrid's expectation
    obj.uuId = obj.id;
    obj.name = obj.text;
    obj.duration = obj.durationInDays;
    obj.staffs = obj.staffList;
    obj.autoScheduling = obj.auto_scheduling;
    obj.readOnly = obj.readonly;
    delete obj.id;
    delete obj.text;
    delete obj.start_date;
    delete obj.end_date;
    delete obj.durationInDays;
    delete obj.staffList;
    delete obj.auto_scheduling;
    delete obj.readonly;

    //For Project type, override duration with estimateDuration
    if(obj.type == 1) {
      const { unit, value } =  analyzeDurationAUM(obj.estimatedDuration);
      obj.duration = value;
      obj.durationAUM = unit;
    }

    //Prepare for DetailLinkCellRenderer
    obj.label = obj.name;

    records.push(obj);
  }

  return { records, taskNames };

  //Sample:  
  // {
  //   uuId: 
  //   name:
  //   pUuId:
  //   pName:
  //   startTime:
  //   closeTime:
  //   duration:
  //   durationAUM:
  //   priority:
  //   state:
  //   stateName:
  //   complexity:
  //   taskType:
  //   description:
  //   estimatedCost:
  //   constraint: { type, time }
  //   autoScheduling:
  //   estimatedCost:
  //   actualCost:
  //   estimatedDuration:
  //   totalActualDuration:
  //   staffs:
  //   skills:
  //   resources:
  //   label:
  // }
}

async function listTaskProjectDetails() {

  const fields = {
    projId: [`PROJECT.uuId`]
    , projLocationId: [`PROJECT.LOCATION.uuId`]
    , stageIds: [`PROJECT.STAGE_LIST.uuId`]
    , stageNames: [`PROJECT.STAGE_LIST.name`]
  }

  const data = {
    'type': 'msql'
    ,'start': 0
    ,'limit': -1
    ,'nominate': 'PROJECT'
    ,'select': Object.keys(fields).map(i => fields[i])
  }

  data['name'] = `Get All Project Locations and stages`;

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);

    const result = rawData.map(i => {
      let tmpValue = null;
      const obj = {}
      for (let j = 0, len = i.length; j < len; j++) {
        tmpValue = i[j];
        const key = keys[j];
        if (key == 'stageIds' || key == 'stageNames') {
          obj[key] = tmpValue != null? tmpValue : [];
        } else if (tmpValue != null && Array.isArray(tmpValue)) {
         
          obj[key] = tmpValue.length > 0? tmpValue[0] : null;
         
        } else {
          obj[key] = tmpValue;
        }
      }

      obj.stages = [];
      if (obj.stageIds != null && obj.stageNames != null) {
        for (let i = 0, len = obj.stageIds.length; i < len; i++) {
          obj.stages.push({
            uuId: obj.stageIds[i]
            , name: obj.stageNames[i]
          });
        }
      }
      delete obj.stageIds;
      delete obj.stageNames;

      return obj;
    });
    return result;
  });
}


async function getTaskIdsByFilter(entity, filter) {
  let _filter = filter;
  if (_filter == null || !Array.isArray(_filter) || _filter.length == 0) {
    _filter = [];
  }

  const data = {
    'type': 'msql'
    ,'start': 0
    ,'limit': -1
    ,'nominate': entity
    ,'select': [[`${entity}.uuId`]]
  }
  data['name'] = 'Get Task Ids with filter';
  data['filter'] = _filter;
  const result = await httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    return rawData.map(i => {
      return i[0];
    });
  }).catch(() =>{
    return [];
  });

  return result;
}

function listPagedTaskDataDynamic({ offset=0, limit=-1, sortModel=null, taskIds=null, requestedFields=[]
                                    , customFields=[], skillCustomFields=[], resourceCustomFields=[]
                                    , noteCustomFields=[] } = {}) {
  const K_PROJECT = `PROJECT`;
  const K_TASK = 'TASK(one)';
  const K_TASK_NO_PREFIX = 'TASK(one)';

  // always include color
  requestedFields.push('taskColor');
  requestedFields.push('stageColor');
  requestedFields.push('skillColor');
  requestedFields.push('staffColor');
  requestedFields.push('resourceColor');
  requestedFields.push('rebateColor');
  requestedFields.push('fileColor');

  //Convert grid column/field to selector name
  if (requestedFields != null && requestedFields.length > 0) {
    const clonedFields = cloneDeep(requestedFields);
    const processedFields = [];
    while (clonedFields.length > 0) {
      const f = clonedFields.splice(0, 1)[0];
      const converted = __convertGridColumnToSelector(f, false);
      if (Array.isArray(converted)) {
        processedFields.push(...converted);
      } else {
        processedFields.push(converted);
      }
    }
    requestedFields.splice(0, requestedFields.length, ...processedFields);
  }
  
  const { fields, mandatoryFields }  = _prepareFields(requestedFields, { K_PROJECT, K_TASK, K_TASK_NO_PREFIX, customFields, skillCustomFields, resourceCustomFields, noteCustomFields }, true);

  let data = {
    'type': 'msql'
    , 'start': offset
    , 'limit': limit
    , 'nominate': 'TASK'
    , 'holder': taskIds
    , 'select': Object.keys(fields).map(i => fields[i])
    , 'name': 'Paged Tasks With Link by ProjectId (Dynamic)'
  }
  data['name'] = 'Paged Tasks With Link by ProjectId (Dynamic)';

  if (sortModel != null && sortModel.length > 0) {
    const _sort  = [];
    let colKey = null;
    for (let i = 0, len = sortModel.length; i < len; i++) {
      colKey = sortModel[i].colId;
      colKey = __convertGridColumnToSelector(colKey, false);
      if (Array.isArray(colKey)) {
        colKey = __getSortColKey(colKey[0]);
      } else {
        colKey = __getSortColKey(colKey);
      }
      const selector = __getSelector(colKey, { K_PROJECT, K_TASK, K_TASK_NO_PREFIX });
      if (selector == null || selector.length == 0) {
        continue;
      }
      const sortEntry = [selector[0], sortModel[i].sort === 'asc'? 'incr' : 'decr'];
      if (fieldSelectorMap.get(colKey).isString) {
        sortEntry.push('lowerCase');
      }
      _sort.push(sortEntry);
    }
    data['sort'] = _sort;
  }
  

  const url = '/api/query/match';
  return httpAjax.post(url, data, {}).then(response => {
    const total = response.data.arg_total;
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];

    //Check if mandatory fields are redacted
    const mandatoryFieldKeys = Object.keys(mandatoryFields)
    const redactedFields = getRedactedFields(fields, response);
    if (redactedFields.length > 0) {
      for (const key of redactedFields) {
        if (mandatoryFieldKeys.includes(key)) {
          return {
            fields: []
            , grid: { records: [], taskNames: [] }
            , gantt: { data: [], collections: { links: [] } }
            , total: 0
            , lackOfMandatoryFields: true
          }
        }
      }
    }

    const keys = getKeysWithoutRedactedFields(fields, response);

    const result = rawData.map(i => {
      const obj = {}
      for (let j = 0, len = i.length; j < len; j++) {
        obj[keys[j]] = i[j];
      }

      obj.projId = __getFirstItemForArrayValue(obj.projId);
      obj.projName = __getFirstItemForArrayValue(obj.projName);
      obj.projScheduleStart = __getFirstItemForArrayValue(obj.projScheduleStart);
      obj.projScheduleFinish = __getFirstItemForArrayValue(obj.projScheduleFinish);
      obj.projScheduleMode = __getFirstItemForArrayValue(obj.projScheduleMode);
      obj.projLocationId = __getFirstItemForArrayValue(obj.projLocationId);
      obj.projAutoScheduling = __getFirstItemForArrayValue(obj.projAutoScheduling);

      //Preprocess Parent details
      //Check the obj.pUuId against obj.projectId
      obj.pUuIds = obj.pUuId;
      obj.pUuId = !obj.pUuId || obj.pUuId.length == 0 || obj.pUuId[0] == obj.projId? 'ROOT' : obj.pUuId[0];
      //Assign pName to pNames before being override.
      obj.pNames = obj.pName;
      obj.pName = !obj.pName || obj.pName.length == 0? '': obj.pName[0];


      //Preprocess startTime, closeTime
      if (obj.start_date == 'null') {
        obj.start_date = null;
        obj.startTime = null; //Used by grid
      } else {
        obj.startTime = obj.start_date;
      }
      if (obj.end_date == 'null') {
        obj.end_date = null;
        obj.closeTime = null; //Used by grid
      } else {
        obj.closeTime = obj.end_date;
      }

      //Preprocess duration, durationInDays
      if (obj.duration == 'null') {
        obj.duration = null;
      }
      if (obj.durationInDays == 'null') {
        obj.durationInDays = null;
      }

      //Preprocess stage details
      if (obj.stageId) {
        if(obj.stageId.length > 0) {
          const stgId = obj.stageId[0] != null? obj.stageId[0] : null;
          const stgName = obj.stageName[0] != null? obj.stageName[0] : null;
          obj.stage = { uuId: stgId, name: stgName }
        } else {
          obj.stage = { uuId: null, name: '' }
        }
      }
      delete obj.stageId;
      delete obj.stageName;

      if (obj.stageOptionId) {
        obj.stageOptions = []
        for (let i = 0, len = obj.stageOptionId.length; i < len; i++) {
          obj.stageOptions.push({ 
            value: obj.stageOptionId[i]
            , text: obj.stageOptionName[i]
          });
        }
      }
      delete obj.stageOptionId;
      delete obj.stageOptionName;

      //Preprocess note details
      if (obj.noteId) {
        obj.notes = [];
        for (let i = 0; i < obj.noteId.length; i++) {
          const author = obj.noteFirstName.length > i ? `${obj.noteFirstName[i]} ${obj.noteLastName[i]}` : null;
          const n = { 
            uuId: obj.noteId[i]
            , text: obj.noteText[i]
            , identifier: obj.noteIdentifier[i]
            , modified: obj.noteModified[i]
            , author: author
            , authorRef: obj.noteAuthorRef[i]
          }
          for (const f of noteCustomFields) {
            n[f.name] = obj[`note_${f.name}`][i];
          }
          obj.notes.push(n);
        }
        obj.notes.sort((a, b) => {
          return b.modified - a.modified;
        });
      }
      delete obj.noteId;
      delete obj.noteFirstName;
      delete obj.noteLastName;
      delete obj.noteText;
      delete obj.noteIdentifier;
      delete obj.noteModified;
      delete obj.noteAuthorRef;
      for (const f of noteCustomFields) {
        delete obj[`note_${f.name}`];
      }

      // Preprocess rebates
      obj.rebates = [];
      if (obj.rebateUuid) {
        for (let i = 0; i < obj.rebateUuid.length; i++) {
          obj.rebates.push({
            uuId: obj.rebateUuid[i],
            name: obj.rebateName[i],
            rebate: obj.rebateRebate[i],
          });
        }
      }
      delete obj.rebateUuid;
      delete obj.rebateName;
      delete obj.rebateRebate;

      //Preprocess template
      if(obj.templateUuId && obj.templateUuId.length > 0) {
        obj.template = {
          uuIds: obj.templateUuId,
          names: obj.templateName
        }
      } else {
        obj.template = { uuId: null, name: null };
      }
      delete obj.templateUuId;
      delete obj.templateName;

      if (obj.constraint_date == 'null') {
        obj.constraint_date = null;
      }

      //Preprocess constraint type and constraint time
      if (obj.constraintType && obj.taskType != 'Project') {
        obj.constraint = {
          type: obj.constraintType,
          time: obj.constraint_date
        }
      } else {
        obj.constraint = { type: null, time: null }
      }

      obj.staffList = [];
      if(obj.staffId && obj.staffId.length > 0) {
        
        let name = null; 
        for(let k = 0, kLen = obj.staffId.length; k < kLen; k++) {
          name = obj.staffFirstName[k]? obj.staffFirstName[k] : '';
          if(obj.staffLastName[k]) {
            name = name.length > 0? `${name} ${obj.staffLastName[k]}` : obj.staffLastName[k];
          }          
          obj.staffList.push({ 
            uuId: obj.staffId[k]
            , name
            , utilization: obj.staffUtilization[k]
            , unit: obj.staffUnit[k]
            , duration: obj.staffDuration[k] / 60000  // convert ms to minutes
            , durationAUM: obj.staffDurationAUM[k]
            , genericStaff: obj.staffGenericStaff[k]
          });
        }
      }
      delete obj.staffId;
      delete obj.staffFirstName;
      delete obj.staffLastName;
      delete obj.staffUtilization;
      delete obj.staffUnit;
      delete obj.staffDuration;
      delete obj.staffDurationAUM;
      delete obj.staffGenericStaff;

      return obj;
    });

    const nonColumnFields = [
      'projId'
    , 'projName'
    , 'preName'
    , 'preId'
    , 'preLinkType'
    , 'preLinkLag'
    , 'preLinkId'
    , 'duration'
    , 'progress'
    , 'constraint_date'
    , 'constraint_type'
    , 'type'
    , 'taskColor'
    , 'stageColor'
    , 'skillColor'
    , 'staffColor'
    , 'resourceColor'
    , 'rebateColor'
    , 'fileColor'
    ]
    const columns = keys
                    .filter(i => !nonColumnFields.includes(i))
                    .map(i => {
                      return __convertGridColumnToSelector(i, true);
                    });
    const taskIds = result.map(i => i.id);


    //Construct taskPath and tree level
    const taskNames = {};
    result.forEach(i => {
      i.path = [i.id];
      taskNames[i.id] = i.text;
    });

    return {
      fields: [...new Set(columns)]
      , grid: __convertToAgGridExpectedFormat({ list: result, taskNames, skillCustomFields, resourceCustomFields })
      , gantt: __convertToGanttExpectedFormat({ taskIds, list: result, removeParent: true })
      , total
    } 
  });
}

/**
 * 
 * @param {*} payload
 * payload: {String} projectId
 * payload: {Boolean} isTemplate
 * payload: {Array} requestedFields
 * @returns 
 */
function listTaskDataDynamic({ start, projectId, isTemplate=false, requestedFields=[]
  , expandLevel=-1, manualExpandedIds=[], manualCollapsedIds=[], allfields = false, holders = null
  , ksort=null, customFields=[], sortByParent=true, self=null, badgeFilters=[], filterValue=null
  , skillCustomFields=[], resourceCustomFields=[], noteCustomFields=[] } = {}) {

  const K_PROJECT = isTemplate? 'PROJECT_TEMPLATE' : 'PROJECT';
  const K_TASK = isTemplate? (allfields ? 'TASK_TEMPLATE' : 'PROJECT_TEMPLATE.TASK_TEMPLATE') : !allfields ? 'PROJECT.TASK' : 'TASK(one)';
  const K_TASK_NO_PREFIX = isTemplate? 'TASK_TEMPLATE' : allfields ? 'TASK(one)' : 'TASK';
  const K_TASK_NO_PREFIX_APPEND = isTemplate? 'TASK_TEMPLATE' : 'TASK';

  // always include color
  if (allfields) {
    requestedFields.push('taskColor');
    
    if (!isTemplate) {
      requestedFields.push('stageColor');
    }
    requestedFields.push('skillColor');
    requestedFields.push('staffColor');
    requestedFields.push('resourceColor');
    requestedFields.push('rebateColor');
    requestedFields.push('fileColor');
  }
  
  //Convert grid column/field to selector name
  if (requestedFields != null && requestedFields.length > 0) {
    const clonedFields = cloneDeep(requestedFields);
    const processedFields = [];
    while (clonedFields.length > 0) {
      const f = clonedFields.splice(0, 1)[0];
      const converted = __convertGridColumnToSelector(f, false);
      if (Array.isArray(converted)) {
        processedFields.push(...converted);
      } else {
        processedFields.push(converted);
      }
    }
    requestedFields.splice(0, requestedFields.length, ...processedFields);
  }

  const { fields, mandatoryFields } = _prepareFields(
                                        requestedFields
                                        , { K_PROJECT, K_TASK, K_TASK_NO_PREFIX, K_TASK_NO_PREFIX_APPEND, customFields, skillCustomFields, resourceCustomFields, noteCustomFields }
                                        , allfields);

  //Project Gantt can't use these field selector statements. Another approach is used instead.
  delete fields.stageOptionId;
  delete fields.stageOptionName;

  if(isTemplate) {
    delete fields.stageId;
    delete fields.stageName;
    delete fields.templateUuId;
    delete fields.templateName;
    delete fields.projScheduleStart;
    delete fields.projScheduleFinish;
  }

  //Removing projLocationId due to query limitation. The projLocationId will be retrieved differently.
  delete fields.projLocationId;
  
  let data = {
    'type': 'msql'
    , 'start': start
    , 'limit': -1
    , 'timeout': 300
    , 'nominate': isTemplate? K_TASK : allfields ? 'TASK' : K_TASK
    , 'select': Object.keys(fields).map(i => fields[i])
    , 'holder': holders ? holders : projectId || ''
    , 'name': 'Tasks With Link by ProjectId (Dynamic)'
    , 'sep_array': '/'
    , 'sort': [[`${K_TASK}.PARENT_${K_TASK_NO_PREFIX}.uuId`, 'incr']]
    , 'filter': []
  }

  if (!holders) {
    if (filterValue) {
      data['filter'].push("_or_");
      const filters = [[`${K_TASK}.name`, 'has', filterValue]];
      if (self.canView(K_TASK, ['description'])) {
        filters.push([`${K_TASK}.description`, 'has', filterValue]);
      }
      if (self.canView(K_TASK, ['identifier'])) {
        filters.push([`${K_TASK}.identifier`, 'has', filterValue]);
      }
  
      if (self.canView('STAFF', ['name'])) {
        filters.push([`${K_TASK}.STAFF.name`, 'has', filterValue]);
      }
  
      if (self.canView('TAG', ['name'])) {
        filters.push([`${K_TASK}.TAG.name`, 'has', filterValue]);
      }
      
      if (self.canView('RESOURCE', ['name'])) {
        filters.push([`${K_TASK}.RESOURCE.name`, 'has', filterValue]);
      }
      
      if (self.canView('REBATE', ['name'])) {
        filters.push([`${K_TASK}.REBATE.name`, 'has', filterValue]);
      }
  
      if (self.canView('SKILL', ['name'])) {
        filters.push([`${K_TASK}.SKILL.name`, 'has', filterValue]);
      }
      data['filter'].push(filters);
    }
  
    //BadgeFilter related
    if (Array.isArray(badgeFilters) && badgeFilters.length > 0) {
      const badgeFilterList = [];
      for (const f of badgeFilters) {
        if (f.field == null || !Array.isArray(f.value) || f.value.length == 0) {
          continue;
        }
        
        let field = null;
        if (f.field == 'taskName') {
          field = `${K_TASK}.name`;
        } else if (f.field == 'identifier') {
          field = `${K_TASK}.identifier`;
        } else if (f.field == 'parentTasks') {
          field = `${K_TASK}.fullPath`;
        } else if (f.field == 'rebateName') {
          field = `${K_TASK}.REBATE.name`;
        } else if (f.field == 'resourceName') {
          field = `${K_TASK}.RESOURCE.name`;
        } else if (f.field == 'skillName') {
          field = `${K_TASK}.SKILL.name`;
        } else if (f.field == 'staffName') {
          field = `${K_TASK}.STAFF.name`;
        } else if (f.field == 'stageName') {
          field = `${K_TASK}.STAGE.name`;
        } else if (f.field == 'tagName') {
          field = `${K_TASK}.TAG.name`;
        } else if (f.field == 'type') {
          field = `${K_TASK}.taskType`;
        } else if (f.field == 'complexity') {
          field = `${K_TASK}.complexity`;
        } else if (f.field == 'priority') {
          field = `${K_TASK}.priority`;
        } else {
          const found = customFields.find(i => i.name == f.field);
          if (found != null) {
            field = `${K_TASK}.${found.name}`
          }
        }
        if (field == null) {
          continue;
        }
        const valueList = [];
        const value = f.value;
        for (const v of value) {
          if (f.field === 'parentTasks') {
            if (v.text != null && v.text.length > 0 && v.text !== '(Empty)' &&
                (!f.operator || f.operator === 'is')) {
              valueList.push([field, 'has', v.text]);
            }
            else if (f.operator !== 'is') {
              valueList.push("_not_" ,[ [field, 'has', v.text] ])
            }
          }
          else {
            if (v.text != null && v.text.length > 0 && v.text !== '(Empty)') {
              valueList.push([field, !f.operator || f.operator === 'is' ? 'eq' : 'neq', f.field === 'type' && v.text === 'Summary Task' ? 'Project' : v.text]);
            }
            else if (!f.operator || f.operator === 'is') {
              valueList.push("_not_" ,[ [field] ])
            }
            else {
              valueList.push([field])
            }
          }
        }
        if (valueList.length > 0) {
          badgeFilterList.push(!f.operator || f.operator === 'is' ? '_or_' : '_and_');
          badgeFilterList.push(valueList);
        }
      }
      if (badgeFilterList.length > 0) {
        if (Array.isArray(data.filter) && data.filter.length > 0) {
          data.filter = [...data.filter, '_and_', badgeFilterList]
        } else {
          data.filter = ['_and_', badgeFilterList]
        }
      }
    }
  }
  
  if (ksort) {
  // Can't run lowerCase on numeric fields
    let numeric = ['fixedCost', 'avatarRef', 'priority', 'fixedDuration', 'startTime', 'closeTime'];
    const field = transformSortField(ksort.colId);
    if (!sortByParent) {
      // if it is a flat list don't sort by parent
      data['sort'] = [];
    }
    if (numeric.includes(field)) {
      data['sort'].push([`${K_TASK}.${field}`, ksort.direction]);
    }
    else {
      data['sort'].push([`${K_TASK}.${field}`, ksort.direction, 'lowerCase']);
    }
  }
  
  if (allfields) {
    delete data['sort'];
  }
  
  const url = '/api/query/match';
  return httpAjax.post(url, data, {}).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];

    //Check if mandatory fields are redacted
    const mandatoryFieldKeys = Object.keys(mandatoryFields)
    const redactedFields = getRedactedFields(fields, response);
    if (redactedFields.length > 0) {
      for (const key of redactedFields) {
        if (mandatoryFieldKeys.includes(key)) {
          return {
            fields: []
            , grid: { records: [], taskNames: [] }
            , gantt: { data: [], collections: { links: [] } }
            , lackOfMandatoryFields: true
          }  
        }
      }
    }
    

    const keys = getKeysWithoutRedactedFields(fields, response);

    let result = rawData.map(i => {
      const obj = {}
      for (let j = 0, len = i.length; j < len; j++) {
        obj[keys[j]] = i[j];
      }

      obj.projId = __getFirstItemForArrayValue(obj.projId);
      obj.projName = __getFirstItemForArrayValue(obj.projName);
      obj.projScheduleStart = __getFirstItemForArrayValue(obj.projScheduleStart);
      obj.projScheduleFinish = __getFirstItemForArrayValue(obj.projScheduleFinish);
      obj.projScheduleMode = __getFirstItemForArrayValue(obj.projScheduleMode);
      obj.projLocationId = __getFirstItemForArrayValue(obj.projLocationId);
      obj.projAutoScheduling = __getFirstItemForArrayValue(obj.projAutoScheduling);

      //Preprocess Parent details
      //Check the obj.pUuId against obj.projectId
      obj.pUuIds = obj.pUuId;
      obj.pUuId = !obj.pUuId || obj.pUuId.length == 0 || obj.pUuId[0] == obj.projId? 'ROOT' : obj.pUuId[0];
      //Assign pName to pNames before being override.
      obj.pNames = obj.pName;
      obj.pName = !obj.pName || obj.pName.length == 0? '': obj.pName[0];

      //Preprocess startTime, closeTime
      if (obj.start_date == 'null' ||
          obj.start_date >= 9223372036854776000) {
        obj.start_date = null;
        obj.startTime = null; //Used by grid
      } else {
        obj.startTime = obj.start_date;
      }
      if (obj.end_date == 'null' ||
          obj.end_date >= 9223372036854776000) {
        obj.end_date = null;
        obj.closeTime = null; //Used by grid
      } else {
        obj.closeTime = obj.end_date;
      }

      //Preprocess duration, durationInDays
      if (obj.duration == 'null') {
        obj.duration = null;
      }
      if (obj.durationInDays == 'null') {
        obj.durationInDays = null;
      }

      //Preprocess stage details
      if (obj.stageId) {
        if(obj.stageId.length > 0) {
          const stgId = obj.stageId[0] != null? obj.stageId[0] : null;
          const stgName = obj.stageName[0] != null? obj.stageName[0] : null;
          obj.stage = { uuId: stgId, name: stgName }
        } else {
          obj.stage = { uuId: null, name: '' }
        }
      }
      delete obj.stageId;
      delete obj.stageName;

      //Preprocess note details
      if (obj.noteId) {
        obj.notes = [];
        for (let i = 0; i < obj.noteId.length; i++) {
          const author = obj.noteFirstName.length > i ? `${obj.noteFirstName[i]} ${obj.noteLastName[i]}` : null;
          const n = { 
            uuId: obj.noteId[i]
            , text: obj.noteText[i]
            , identifier: obj.noteIdentifier[i]
            , modified: obj.noteModified[i]
            , author: author
            , authorRef: obj.noteAuthorRef[i]
          }
          for (const f of noteCustomFields) {
            n[f.name] = obj[`note_${f.name}`][i];
          }
          obj.notes.push(n);
        }
        obj.notes.sort((a, b) => {
          return b.modified - a.modified;
        });
      }
      delete obj.noteId;
      delete obj.noteFirstName;
      delete obj.noteLastName;
      delete obj.noteText;
      delete obj.noteIdentifier;
      delete obj.noteModified;
      delete obj.noteAuthorRef;
      for (const f of noteCustomFields) {
        delete obj[`note_${f.name}`];
      }

      // Preprocess rebates
      obj.rebates = [];
      if (obj.rebateUuid) {
        for (let i = 0; i < obj.rebateUuid.length; i++) {
          obj.rebates.push({
            uuId: obj.rebateUuid[i],
            name: obj.rebateName[i],
            rebate: obj.rebateRebate[i],
          });
        }
      }
      delete obj.rebateUuid;
      delete obj.rebateName;
      delete obj.rebateRebate;

      //Preprocess template
      if(obj.templateUuId && obj.templateUuId.length > 0) {
        obj.template = {
          uuIds: obj.templateUuId,
          names: obj.templateName
        }
      } else {
        obj.template = { uuId: null, name: null };
      }
      delete obj.templateUuId;
      delete obj.templateName;

      if (obj.constraint_date == 'null') {
        obj.constraint_date = null;
      }

      //Preprocess constraint type and constraint time
      if (obj.constraintType && obj.taskType != 'Project') {
        obj.constraint = {
          type: obj.constraintType,
          time: obj.constraint_date
        }
      } else {
        obj.constraint = { type: null, time: null }
      }
      delete obj.constraintType;

      obj.staffList = [];
      if(obj.staffId && obj.staffId.length > 0) {
        
        let name = null; 
        for(let k = 0, kLen = obj.staffId.length; k < kLen; k++) {
          name = obj.staffFirstName[k]? obj.staffFirstName[k] : '';
          if(obj.staffLastName[k]) {
            name = name.length > 0? `${name} ${obj.staffLastName[k]}` : obj.staffLastName[k];
          }          
          obj.staffList.push({ 
            uuId: obj.staffId[k]
            , name
            , utilization: obj.staffUtilization[k]
            , unit: obj.staffUnit[k]
            , duration: obj.staffDuration[k] / 60000  // convert ms to minutes
            , durationAUM: obj.staffDurationAUM[k]
            , genericStaff: obj.staffGenericStaff[k]
          });
        }
      }
      delete obj.staffId;
      delete obj.staffFirstName;
      delete obj.staffLastName;
      delete obj.staffUtilization;
      delete obj.staffUnit;
      delete obj.staffDuration;
      delete obj.staffDurationAUM;
      delete obj.staffGenericStaff;
      
      return obj;
    });

    
    const nonColumnFields = [
      'projId'
    , 'projName'
    , 'projScheduleStart'
    , 'projScheduleFinish'
    , 'projScheduleMode'
    , 'projLocationId'
    , 'projAutoScheduling'
    , 'preName'
    , 'preId'
    , 'preLinkType'
    , 'preLinkLag'
    , 'preLinkId'
    , 'duration'
    , 'constraint_date'
    , 'constraint_type'
    , 'type'
    ]
    const columns = keys
                    .filter(i => !nonColumnFields.includes(i))
                    .map(i => {
                      return __convertGridColumnToSelector(i, true);
                    });
    const taskIds = result.map(i => i.id);


    //Construct taskPath and tree level
    const taskNames = {};
    var children = [];
    result.forEach(i => {
      if (i.pUuId && 
          (children.length === 0 || i.pUuId === children[0].pUuId)) {
        children.push(i);   
      }
      else if (children.length > 0) {
        const p = result.find(j => j.id == children[0].pUuId);
        if (p) {
          if (!ksort) {
            children.sort((a, b) => {
              return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0; 
            });
          }
          p.children = children;
        }
        
        for (const child of children) {
          child.parent = p;
        }
        children = [i];
      }
      taskNames[i.id] = {name: i.text};
    });

    if (children.length > 0) {
      const p = result.find(j => j.id == children[0].pUuId);
      if (p) {
        if (!ksort) {
          children.sort((a, b) => {
            return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0; 
          });
        }
        p.children = children;
      }
      
      for (const child of children) {
        child.parent = p;
      }
      children = [];
    }

    if (!ksort) {
      result.sort((a, b) => {
        return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0; 
      });
    }
    
    if (expandLevel == null) {
      expandLevel = -1;
    }
  
    const flatTree = [];
    const populatePathAndOpenState = (path, children) => {
      if(children) {
        for(const child of children) {
          flatTree.push(child);
          child.path = [...path, child.id];
          if (manualExpandedIds.includes(child.id)) {
            child.open = true;
          } else if (manualCollapsedIds.includes(child.id)) {
            child.open = false;
          } else {
            child.open = expandLevel > -1? (child.path.length - 1) < expandLevel : true;
          }
          
          taskNames[child.id].path = child.path;
          populatePathAndOpenState(child.path, child.children);
        }
      }
    }
    populatePathAndOpenState([], result.filter(i => i.pUuId == 'ROOT'));

    // sort the flat list in the order in which the tasks would appear if
    // they were in a tree
    if (self.flatList && !holders && !filterValue && badgeFilters.length === 0 && !ksort) { // only sort if the user has not selected a column sort
      result = flatTree;
    }
    
    const removeChildrenProperties = (list) => {
      if(list) {
        for(const item of list) {
          delete item.children;
        }
      }
    }
    removeChildrenProperties(result);

    return {
      arg_total: response.data.arg_total
      , arg_count: response.data.arg_count
      , status: response.status
      , jobClue: response.data.jobClue
      , fields: [...new Set(columns)]
      , grid: __convertToAgGridExpectedFormat({ list: result, taskNames, skillCustomFields, resourceCustomFields })
      , gantt: __convertToGanttExpectedFormat({ taskIds, list: result, removeParent: false })
      , redactedFields
    } 
  });
}


function _prepareFields(requestedFields, { K_PROJECT, K_TASK, K_TASK_NO_PREFIX, K_TASK_NO_PREFIX_APPEND
                                            , customFields=[], skillCustomFields=[], resourceCustomFields=[]
                                            , noteCustomFields=[] } = {}, allfields = false) {
  const options = {};
  if (K_PROJECT != null && typeof K_PROJECT === 'string') {
    if (allfields) {
      options.K_PROJECT = `${K_TASK}.${K_PROJECT}`;
    }
    else {
      options.K_PROJECT = K_PROJECT;
    }
  }
  if (K_TASK != null && typeof K_TASK === 'string') {
    options.K_TASK = K_TASK;
  }
  if (K_TASK_NO_PREFIX != null && typeof K_TASK_NO_PREFIX === 'string') {
    options.K_TASK_NO_PREFIX = K_TASK_NO_PREFIX;
  }
  if (K_TASK_NO_PREFIX_APPEND != null && typeof K_TASK_NO_PREFIX_APPEND === 'string') {
    options.K_TASK_NO_PREFIX_APPEND = K_TASK_NO_PREFIX_APPEND;
  }
  let fields = {
      id: __getSelector('id', options)
      , order: __getSelector('order', options)
      , text: __getSelector('text', options)
      , start_date: __getSelector('start_date', options)
      , end_date: __getSelector('end_date', options)
      , pUuId: __getSelector('pUuId', options)
      , type: __getSelector('type', options)
      , auto_scheduling: __getSelector('auto_scheduling', options)
      , readonly: __getSelector('readonly', options)
  }
  
  if (allfields) {
    fields = {
      // Gantt
      projId: __getSelector('projId', options)
      , projName: __getSelector('projName', options)
      , projScheduleStart: __getSelector('projScheduleStart', options)
      , projScheduleFinish: __getSelector('projScheduleFinish', options)
      , projScheduleMode: __getSelector('projScheduleMode', options)
      , projLocationId: __getSelector('projLocationId', options)
      , projAutoScheduling: __getSelector('projAutoScheduling', options)
      , id: __getSelector('id', options)
      , order: __getSelector('order', options)
      , text: __getSelector('text', options)
      , start_date: __getSelector('start_date', options)
      , end_date: __getSelector('end_date', options)
      , pUuId: __getSelector('pUuId', options)
      , pName: __getSelector('pName', options)
      , preName: __getSelector('preName', options)
      , preId: __getSelector('preId', options)
      , preLinkType: __getSelector('preLinkType', options)
      , preLinkLag: __getSelector('preLinkLag', options)
      , preLinkId: __getSelector('preLinkId', options)
      , lockDuration: __getSelector('lockDuration', options)
      , duration: __getSelector('duration', options)
      , progress: __getSelector('progress', options)
      , durationAUM: __getSelector('durationAUM', options)
      , constraint_date: __getSelector('constraint_date', options)
      , constraint_type: __getSelector('constraint_type', options)
      , auto_scheduling: __getSelector('auto_scheduling', options)
      , readonly: __getSelector('readonly', options)
      , type: __getSelector('type', options)
      // AgGrid
      , taskType: __getSelector('taskType', options)
      , durationInDays: __getSelector('durationInDays', options)
      , constraintType: __getSelector('constraintType', options)
    }
  }

  const mandatoryFields = JSON.parse(JSON.stringify(fields));

  //Custom field is not mandatory field so process it after mandatoryFields.
  if (allfields && Array.isArray(customFields) && customFields.length > 0) {
    const taskPrefix = Object.hasOwn(options, 'K_TASK')? options.K_TASK : 'PROJECT.TASK'
    for (const f of customFields) {
      fields[f.name] = [`${taskPrefix}.${f.name}`];
    }
  }

  const excludedFromRequestedFields = [
    'id'
    , 'text'
    , 'start_date'
    , 'end_date'
    , 'auto_scheduling'
    , 'duration'
    , 'lockDuration'
    , 'taskType'
    , ''
    , 'readonly'
  ];

  

  if(requestedFields != null && requestedFields.length > 0) {
    let remainingFields = requestedFields.filter(i => !excludedFromRequestedFields.includes(i));
    
    if(remainingFields.length > 0 && remainingFields.includes('estimatedDuration')) {
      remainingFields = remainingFields.filter(i => i != 'estimatedDuration');
      fields.estimatedDuration = __getSelector('estimatedDuration', options);
    }

    // const constraintGroup = ['constraintType', 'constraintTime'];
    // if(remainingFields.length > 0 && remainingFields.some(i => constraintGroup.includes(i))) {
    //   remainingFields = remainingFields.filter(i => !constraintGroup.includes(i));
    //   fields.constraintType = __getSelector('constraintType', options);
    //   //constraintTime value is same with constraint_date. And constraint_date is included by default.
    // }

    //Tag is needed to filter out stage options
    const stageGroup = ['stageId', 'stageName'];
    if(remainingFields.length > 0 && remainingFields.some(i => stageGroup.includes(i))) {
      remainingFields = remainingFields.filter(i => !stageGroup.includes(i) && 'tag' != i);
      fields.stageId = __getSelector('stageId', options);
      fields.stageName = __getSelector('stageName', options);
      fields.stageOptionId = __getSelector('stageOptionId', options);
      fields.stageOptionName = __getSelector('stageOptionName', options);
      fields.tag = __getSelector('tag', options);
    }

    const skillGroup = ['skillIds', 'skillLevels'];
    if(remainingFields.length > 0 && remainingFields.some(i => skillGroup.includes(i))) {
      remainingFields = remainingFields.filter(i => !skillGroup.includes(i));
      fields.skillIds = __getSelector('skillIds', options);
      fields.skillNames = __getSelector('skillNames', options);
      fields.skillLevels = __getSelector('skillLevels', options);
      for (const f of skillCustomFields) {
        fields[`skill_${f.name}`] = [`${options.K_TASK}.${options.K_TASK_NO_PREFIX}-SKILL.${f.name}`]
      }
    }

    const staffGroup = ['staffId', 'staffFirstName', 'staffLastName', 'staffUtilization', 'staffUnit', 'staffDuration', 'staffDurationAUM', 'staffGenericStaff'];
    const addStaffGroup = function(remainingFields, fields) {
      remainingFields = remainingFields.filter(i => !staffGroup.includes(i));
      fields.staffId = __getSelector('staffId', options);
      fields.staffFirstName = __getSelector('staffFirstName', options);
      fields.staffLastName = __getSelector('staffLastName', options);
      fields.staffUtilization = __getSelector('staffUtilization', options);
      fields.staffUnit = __getSelector('staffUnit', options);
      fields.staffDuration = __getSelector('staffDuration', options);
      fields.staffDurationAUM = __getSelector('staffDurationAUM', options);
      fields.staffGenericStaff = __getSelector('staffGenericStaff', options);
    }
    if (fields.duration != null) {
      //duration calculation needs staff calendar. So staff data is needed 
      addStaffGroup(remainingFields, fields);
    }
    if(remainingFields.length > 0 && remainingFields.includes('totalActualDuration')) {
      remainingFields = remainingFields.filter(i => i != 'totalActualDuration');
      fields.totalActualDuration = __getSelector('totalActualDuration', options);
      addStaffGroup(remainingFields, fields);
    } else if(remainingFields.length > 0 && remainingFields.some(i => staffGroup.includes(i))) {
      addStaffGroup(remainingFields, fields);
    }

    const resourceGroup = ['resourceIds', 'resourceNames', 'resourceUnits'];
    if(remainingFields.length > 0 && remainingFields.some(i => resourceGroup.includes(i))) {
      remainingFields = remainingFields.filter(i => !resourceGroup.includes(i));
      fields.resourceIds = __getSelector('resourceIds', options);
      fields.resourceNames = __getSelector('resourceNames', options);
      fields.resourceUnits = __getSelector('resourceUnits', options);
      for (const f of resourceCustomFields) {
        fields[`resource_${f.name}`] = [`${options.K_TASK}.${options.K_TASK_NO_PREFIX}-RESOURCE.${f.name}`]
      }
    }

    const noteGroup = ['noteId', 'noteText', 'noteModified', 'noteFirstName', 'noteLastName', 'noteAuthorRef'];
    if(remainingFields.length > 0 && remainingFields.some(i => noteGroup.includes(i))) {
      remainingFields = remainingFields.filter(i => !noteGroup.includes(i));
      fields.noteId = __getSelector('noteId', options);
      fields.noteText = __getSelector('noteText', options);
      fields.noteIdentifier = __getSelector('noteIdentifier', options);
      fields.noteModified = __getSelector('noteModified', options);
      fields.noteFirstName = __getSelector('noteFirstName', options);
      fields.noteLastName = __getSelector('noteLastName', options);
      fields.noteAuthorRef = __getSelector('noteAuthorRef', options);
      for (const f of noteCustomFields) {
        fields[`note_${f.name}`] = [`${options.K_TASK}.NOTE.${f.name}`]
      }
    }

    const rebateGroup = ['rebateUuid', 'rebateName', 'rebateRebate'];
    if(remainingFields.length > 0 && remainingFields.some(i => rebateGroup.includes(i))) {
      remainingFields = remainingFields.filter(i => !rebateGroup.includes(i));
      fields.rebateUuid = __getSelector('rebateUuid', options);
      fields.rebateName = __getSelector('rebateName', options);
      fields.rebateRebate = __getSelector('rebateRebate', options);
    }

    const templateGroup = ['templateUuId', 'templateName'];
    if(remainingFields.length > 0 && remainingFields.some(i => templateGroup.includes(i))) {
      remainingFields = remainingFields.filter(i => !templateGroup.includes(i));
      fields.templateUuId = __getSelector('templateUuId', options);
      fields.templateName = __getSelector('templateName', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('taskPath')) {
      remainingFields = remainingFields.filter(i => i != 'taskPath');
      fields.taskPath = __getSelector('taskPath', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('description')) {
      remainingFields = remainingFields.filter(i => i != 'description');
      fields.description = __getSelector('description', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('priority')) {
      remainingFields = remainingFields.filter(i => i != 'priority');
      fields.priority = __getSelector('priority', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('complexity')) {
      remainingFields = remainingFields.filter(i => i != 'complexity');
      fields.complexity = __getSelector('complexity', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('plannedCost')) {
      remainingFields = remainingFields.filter(i => i != 'plannedCost');
      fields.plannedCost = __getSelector('plannedCost', options);
    }
    
    if(remainingFields.length > 0 && remainingFields.includes('plannedCostNet')) {
      remainingFields = remainingFields.filter(i => i != 'plannedCostNet');
      fields.plannedCostNet = __getSelector('plannedCostNet', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('plannedProgress')) {
      remainingFields = remainingFields.filter(i => i != 'plannedProgress');
      fields.plannedProgress = __getSelector('plannedProgress', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('estimatedCost')) {
      remainingFields = remainingFields.filter(i => i != 'estimatedCost');
      fields.estimatedCost = __getSelector('estimatedCost', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('estimatedCostNet')) {
      remainingFields = remainingFields.filter(i => i != 'estimatedCostNet');
      fields.estimatedCostNet = __getSelector('estimatedCostNet', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('actualCost')) {
      remainingFields = remainingFields.filter(i => i != 'actualCost');
      fields.actualCost = __getSelector('actualCost', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('actualCostNet')) {
      remainingFields = remainingFields.filter(i => i != 'actualCostNet');
      fields.actualCostNet = __getSelector('actualCostNet', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('fixedCost')) {
      remainingFields = remainingFields.filter(i => i != 'fixedCost');
      fields.fixedCost = __getSelector('fixedCost', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('fixedDuration')) {
      remainingFields = remainingFields.filter(i => i != 'fixedDuration');
      fields.fixedDuration = __getSelector('fixedDuration', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('fixedCostNet')) {
      remainingFields = remainingFields.filter(i => i != 'fixedCostNet');
      fields.fixedCostNet = __getSelector('fixedCostNet', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('totalFixedCost')) {
      remainingFields = remainingFields.filter(i => i != 'totalFixedCost');
      fields.totalFixedCost = __getSelector('fixedCost', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('totalFixedCostNet')) {
      remainingFields = remainingFields.filter(i => i != 'totalFixedCostNet');
      fields.totalFixedCostNet = __getSelector('totalFixedCostNet', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('estimatedTimeToComplete')) {
      remainingFields = remainingFields.filter(i => i != 'estimatedTimeToComplete');
      fields.estimatedTimeToComplete = __getSelector('estimatedTimeToComplete', options);
      if (fields.constraintType == null) {
        fields.constraintType = __getSelector('constraintType', options);
      }
      if (fields.staffId == null) {
        addStaffGroup(remainingFields, fields);
      }
    }

    if(remainingFields.length > 0 && remainingFields.includes('currencyCode')) {
      remainingFields = remainingFields.filter(i => i != 'currencyCode');
      fields.currencyCode = __getSelector('currencyCode', options);
    } else {
      //Include currency when any cost field exists
      const costGroup = ['plannedCost', 'plannedCostNet', 'estimatedCost'
        , 'estimatedCostNet', 'actualCost', 'actualCostNet', 'fixedCost', 'fixedCostNet'
        , 'totalFixedCost', 'totalFixedCostNet'
      ]
      const fieldNames = Object.getOwnPropertyNames(fields);
      if (costGroup.some(i => fieldNames.includes(i))) {
        fields.currencyCode = __getSelector('currencyCode', options);
      }
    }
    
    if(remainingFields.length > 0 && remainingFields.includes('identifier')) {
      remainingFields = remainingFields.filter(i => i != 'identifier');
      fields.identifier = __getSelector('identifier', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('tag')) {
      remainingFields = remainingFields.filter(i => i != 'tag');
      fields.tag = __getSelector('tag', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('taskColor')) {
      remainingFields = remainingFields.filter(i => i != 'taskColor');
      fields.taskColor = __getSelector('taskColor', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('stageColor')) {
      remainingFields = remainingFields.filter(i => i != 'stageColor');
      fields.stageColor = __getSelector('stageColor', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('skillColor')) {
      remainingFields = remainingFields.filter(i => i != 'skillColor');
      fields.skillColor = __getSelector('skillColor', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('staffColor')) {
      remainingFields = remainingFields.filter(i => i != 'staffColor');
      fields.staffColor = __getSelector('staffColor', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('resourceColor')) {
      remainingFields = remainingFields.filter(i => i != 'resourceColor');
      fields.resourceColor = __getSelector('resourceColor', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('rebateColor')) {
      remainingFields = remainingFields.filter(i => i != 'rebateColor');
      fields.rebateColor = __getSelector('rebateColor', options);
    }

    if(remainingFields.length > 0 && remainingFields.includes('fileColor')) {
      remainingFields = remainingFields.filter(i => i != 'fileColor');
      fields.fileColor = __getSelector('fileColor', options);
    }
  }
  return { fields, mandatoryFields };
}

/**
 * WARNING: This is private method.
 * Convert grid column name to query selector name.
 * If reverseMapping is true, convert query selector name to grid column name.
 * returned selector can be a string format or an array object.
 * @param {String} value 
 * @param {Boolean} reverseMapping Default is false.
 * @returns {String} result
 */
function __convertGridColumnToSelector(value, reverseMapping=false) {
  const list = [
    { grid: 'uuId', selector: 'id' }
    , { grid: 'name' , selector: 'text' }
    , { grid: 'startTime', selector: 'start_date' }
    , { grid: 'closeTime', selector: 'end_date' }
    , { grid: 'autoScheduling', selector: 'auto_scheduling' }
    , { grid: 'duration', selector: 'durationInDays' }
    , { grid: 'template', selector: ['templateUuId', 'templateName'] }
    , { grid: 'rebates', selector: ['rebateUuid', 'rebateName', 'rebateRebate'] }
    , { grid: 'notes', selector: ['noteId', 'noteText', 'noteModified', 'noteFirstName', 'noteLastName', 'noteAuthorRef'] }
    , { grid: 'resources', selector: ['resourceIds', 'resourceNames', 'resourceUnits'] }
    , { grid: 'staffs', selector: ['staffId', 'staffFirstName', 'staffLastName', 'staffUtilization', 'staffUnit', 'staffDuration', 'staffDurationAUM', 'staffGenericStaff'] }
    , { grid: 'skills', selector: ['skillIds', 'skillLevels'] }
    , { grid: 'stage', selector: ['stageId', 'stageName'] }
    , { grid: 'constraint', selector: ['constraintType', 'constraintTime'] }
  ];
  if(reverseMapping) {
    const matched = list.find(i => {
      const selector = i.selector;
      if (Array.isArray(selector)) {
        return selector.includes(value);
      } 
      return selector === value;
    });
    if(matched != null) {
      return matched.grid;
    }
    return value;
  }
  const matched = list.find(i => i.grid === value);
  if(matched != null) {
    return matched.selector;
  } else {
    return value;
  }
}

/**
 * WARNING: This is a private method.
 * Return specific selector statement by providing field key.
 * @param {String} field 
 * @param {Object} options
 * @returns {Array} result.
 * option: {String} K_PROJECT - Project query prefix.
 * option: {String} K_TASK  - Task query prefix.
 * option: {String} K_TASK_NO_PREFIX - Task query entity.
 * option: {String} K_TASK_NO_PREFIX_APPEND - appended Task query entity.
 */
function __getSelector(field, { K_PROJECT, K_TASK, K_TASK_NO_PREFIX, K_TASK_NO_PREFIX_APPEND } = {}) {
  const options = {};
  if (K_PROJECT != null && typeof K_PROJECT === 'string') {
    options.K_PROJECT = K_PROJECT;
  }
  if (K_TASK != null && typeof K_TASK === 'string') {
    options.K_TASK = K_TASK;
  }
  if (K_TASK_NO_PREFIX != null && typeof K_TASK_NO_PREFIX === 'string') {
    options.K_TASK_NO_PREFIX = K_TASK_NO_PREFIX;
  }
  if (K_TASK_NO_PREFIX_APPEND != null && typeof K_TASK_NO_PREFIX_APPEND === 'string') {
    options.K_TASK_NO_PREFIX_APPEND = K_TASK_NO_PREFIX_APPEND;
  }
  return __replaceEntityKey(fieldSelectorMap.get(field).value, options);
}

/**
 * WARNING: This is a private method.
 * Replace all occurrence of the {K_PROJECT} and {K_TASK} with the provided values for the given selectorStatement.
 * @param {Array} selectorStatement 
 * @param {Object} options
 * option: {String} K_PROJECT - Project query prefix. Default: 'PROJECT'
 * option: {String} K_TASK  - Task query prefix. Default: 'PROJECT.TASK'
 * option: {String} K_TASK_NO_PREFIX - Task query entity. Default: 'TASK'
 * option: {String} K_TASK_NO_PREFIX_APPEND - appended Task query entity. Default: 'TASK'
 * option: {Boolean} init - selectorStatement will be cloned before manipulation if it is true.
 * @returns 
 */
function __replaceEntityKey(selectorStatement, { K_PROJECT='PROJECT', K_TASK='PROJECT.TASK', K_TASK_NO_PREFIX='TASK', K_TASK_NO_PREFIX_APPEND='TASK', init=true } = {}) {
  const _selectorStatement = init? cloneDeep(selectorStatement) : selectorStatement;
  if (_selectorStatement == null || !Array.isArray(_selectorStatement) || _selectorStatement.length == 0) {
    return _selectorStatement;
  }

  for (let i = 0, len = _selectorStatement.length; i < len; i++) {
    let value = _selectorStatement[i];
    if (Array.isArray(value)) {
      value =  __replaceEntityKey(value, { K_PROJECT, K_TASK, K_TASK_NO_PREFIX, K_TASK_NO_PREFIX_APPEND }, false);
    } else if (value != null && typeof value === 'string') {
      value = value.replaceAll('{K_PROJECT}', K_PROJECT);
      value = value.replaceAll('{K_TASK}', K_TASK);
      value = value.replaceAll('{K_TASK_NO_PREFIX}', K_TASK_NO_PREFIX);
      value = value.replaceAll('{K_TASK_NO_PREFIX_APPEND}', K_TASK_NO_PREFIX_APPEND);
    }
    _selectorStatement[i] = value;
  }
  return _selectorStatement;
}

/**
 * WARNING: This is a private method.
 * @returns an instance of Map which has field as key, selector statement as value.
 */
function __getAllFieldSelectorsMap() {
  const map = new Map();
  //<---Common or Used by Gantt--->
  map.set('projId',      { value: ['{K_PROJECT}.uuId'] });
  map.set('projName',    { value: ['{K_PROJECT}.name'], isString: true });
  map.set('projScheduleStart',    { value: ['{K_PROJECT}.scheduleStart', 'null'] });
  map.set('projScheduleFinish',    { value: ['{K_PROJECT}.scheduleFinish', 'null'] });
  map.set('projScheduleMode', { value: ['{K_PROJECT}.scheduleMode'] });
  map.set('projLocationId',   { value: ['{K_PROJECT}.LOCATION.uuId'] });
  map.set('projAutoScheduling',   { value: ['{K_PROJECT}.autoScheduling', false] });
  map.set('id',          { value: ['{K_TASK}.uuId'] });
  map.set('order',       { value: ['{K_TASK}.order']});
  map.set('text',        { value: ['{K_TASK}.name'], isString: true });
  map.set('start_date',  { value: ['{K_TASK}.startTime', 'null'] });
  map.set('end_date',    { value: ['{K_TASK}.closeTime', 'null'] });
  map.set('pUuId',       { value: ['{K_TASK}.PARENT_ALL_{K_TASK_NO_PREFIX_APPEND}.uuId', ''] });
  map.set('pName',       { value: ['{K_TASK}.PARENT_ALL_{K_TASK_NO_PREFIX_APPEND}.name', ''] });
  map.set('preName',     { value: ['{K_TASK}.PREDECESSOR_{K_TASK_NO_PREFIX_APPEND}.name'] });
  map.set('preId',       { value: ['{K_TASK}.PREDECESSOR_{K_TASK_NO_PREFIX_APPEND}.uuId'] });
  map.set('preLinkType', { value: ['{K_TASK}.{K_TASK_NO_PREFIX}-PREDECESSOR_{K_TASK_NO_PREFIX_APPEND}.type', 0, 'enum'] });
  map.set('preLinkLag',  { value: ['{K_TASK}.{K_TASK_NO_PREFIX}-PREDECESSOR_{K_TASK_NO_PREFIX_APPEND}.lag', 0, 'days'] });
  map.set('preLinkId',   { value: ['{K_TASK}.{K_TASK_NO_PREFIX}-PREDECESSOR_{K_TASK_NO_PREFIX_APPEND}.uuId'] });
  map.set('lockDuration',{ value: ['{K_TASK}.lockDuration'] });
  map.set('duration',    { value: ['{K_TASK}.duration', 'null', 'minutes'] });
  map.set('progress',    { value: ['{K_TASK}.progress', 0] });
  map.set('durationAUM', { value: ['{K_TASK}.durationAUM'] });
  map.set('constraint_date', { value: ['{K_TASK}.constraintTime', 'null'] });
  map.set('constraint_type', { value: ['{K_TASK}.constraintType', 0, 'enum'] });
  map.set('auto_scheduling', { value: ['{K_TASK}.autoScheduling', true] });
  map.set('type',            { value: ['{K_TASK}.taskType', 0, 'enum'] });
  map.set('readonly',        { value: ['{K_TASK}.readOnly', false]});

  //<---Used by AgGrid--->
  map.set('taskType',        { value: ['{K_TASK}.taskType'], isString: true });
  map.set('durationInDays',  { value: ['{K_TASK}.duration', 'null', 'Days'] });
  map.set('constraintType',  { value: ['{K_TASK}.constraintType'], isString: true });
  map.set('description',     { value: ['{K_TASK}.description'], isString: true });
  map.set('identifier',      { value: ['{K_TASK}.identifier'] });
  map.set('tag',             { value: ['{K_TASK}.TAG.name'] });
  map.set('currencyCode',    { value: ['{K_TASK}.currencyCode']});
  map.set('taskColor',           { value: ['{K_TASK}.color'] });
  map.set('stageColor',           { value: ['{K_TASK}.STAGE.color'] });
  map.set('skillColor',           { value: ['{K_TASK}.SKILL.color'] });
  map.set('staffColor',           { value: ['{K_TASK}.STAFF.color'] });
  map.set('resourceColor',           { value: ['{K_TASK}.RESOURCE.color'] });
  map.set('rebateColor',           { value: ['{K_TASK}.REBATE.color'] });
  map.set('fileColor',           { value: ['{K_TASK}.STORAGE_FILE.color'] });
  map.set('priority',        { value: ['{K_TASK}.priority'] });
  map.set('complexity',      { value: ['{K_TASK}.complexity'] });
  ////Group: Stage (Not applicable in Task template)
  map.set('stageId',         { value: ['{K_TASK}.STAGE.uuId'] });
  map.set('stageName',       { value: ['{K_TASK}.STAGE.name'], isString: true });
  map.set('stageOptionId',  { value: ['{K_PROJECT}.STAGE_LIST.uuId', '', '', 'fold'] });
  map.set('stageOptionName',  { value: ['{K_PROJECT}.STAGE_LIST.name', '', '', 'fold'] });
  ////Group: SKill
  map.set('skillIds',        { value: ['{K_TASK}.SKILL.uuId'] });
  map.set('skillNames',      { value: ['{K_TASK}.SKILL.name'], isString: true });
  map.set('skillLevels',     { value: ['{K_TASK}.{K_TASK_NO_PREFIX}-SKILL.level'] });
  ////Group: Staff
  map.set('staffId',         { value: ['{K_TASK}.STAFF.uuId'] });
  map.set('staffFirstName',  { value: ['{K_TASK}.STAFF.firstName'], isString: true });
  map.set('staffLastName',   { value: ['{K_TASK}.STAFF.lastName'], isString: true });
  map.set('staffGenericStaff', { value: ['{K_TASK}.STAFF.genericStaff']});
  map.set('staffUtilization',{ value: ['{K_TASK}.{K_TASK_NO_PREFIX}-STAFF.utilization'] });
  map.set('staffUnit',       { value: ['{K_TASK}.{K_TASK_NO_PREFIX}-STAFF.quantity'] });
  map.set('staffDuration',   { value: ['{K_TASK}.{K_TASK_NO_PREFIX}-STAFF.duration'] });
  map.set('staffDurationAUM',{ value: ['{K_TASK}.{K_TASK_NO_PREFIX}-STAFF.durationAUM'] });
  
  map.set('totalActualDuration', { value: ['{K_TASK}.actualDuration', 0, 'Days'] }); //Macro

  map.set('fixedDuration', { value: ['{K_TASK}.fixedDuration', 0, 'Days'] }); //Macro
  
  //// Group: Resources
  map.set('resourceIds',     { value: ['{K_TASK}.RESOURCE.uuId'] });
  map.set('resourceNames',   { value: ['{K_TASK}.RESOURCE.name'], isString: true });
  map.set('resourceUnits',   { value: ['{K_TASK}.{K_TASK_NO_PREFIX}-RESOURCE.quantity'] });
  //// Group: Note
  map.set('noteId',        { value: ['{K_TASK}.NOTE.uuId'] });
  map.set('noteText',      { value: ['{K_TASK}.NOTE.text'], isString: true });
  map.set('noteIdentifier',  { value: ['{K_TASK}.NOTE.identifier'] });
  map.set('noteModified',  { value: ['{K_TASK}.NOTE.modified'] });
  map.set('noteFirstName', { value: ['{K_TASK}.NOTE.USER.firstName'], isString: true });
  map.set('noteLastName',  { value: ['{K_TASK}.NOTE.USER.lastName'], isString: true });
  map.set('noteAuthorRef', { value: ['{K_TASK}.NOTE.USER.uuId'] });
  //// Group: Rebate
  map.set('rebateUuid',    { value: ['{K_TASK}.REBATE.uuId'] });
  map.set('rebateName',    { value: ['{K_TASK}.REBATE.name'], isString: true });
  map.set('rebateRebate',  { value: ['{K_TASK}.REBATE.rebate'] });
  //// Group: Template
  map.set('templateUuId',  { value: ['{K_TASK}.PROJECT_TEMPLATE.uuId'] });
  map.set('templateName',  { value: ['{K_TASK}.PROJECT_TEMPLATE.name'], isString: true });
  //// Macros
  map.set('taskPath',               { value : ['{K_TASK}.fullPath', ''] });
  map.set('plannedProgress',        { value : [['=plannedProgress(A,B)', ['{K_TASK}'], '<AUTO>']] });
  map.set('estimatedDuration', { value : ['{K_TASK}.estimatedDuration', 0, 'Days'] });
  map.set('estimatedTimeToComplete',{ value : ['{K_TASK}.estimatedTimeToComplete', 0, 'Days'] });
  map.set('plannedCost',            { value : [['=plannedCost(A,B,C)', ['{K_TASK}'], '<AUTO>', false]] });
  map.set('plannedCostNet',         { value : [['=plannedCost(A,B,C)', ['{K_TASK}'], '<AUTO>', true]] });
  map.set('estimatedCost',     { value : ['{K_TASK}.estimatedCost'] });
  map.set('estimatedCostNet',  { value : ['{K_TASK}.estimatedCostNet'] });
  map.set('actualCost',        { value : ['{K_TASK}.actualCost'] });
  map.set('actualCostNet',     { value : ['{K_TASK}.actualCostNet'] });
  map.set('fixedCost',              { value : ['{K_TASK}.fixedCost'] });
  map.set('fixedCostNet',           { value : ['{K_TASK}.fixedCostNet'] });
  map.set('totalFixedCost',              { value : ['{K_TASK}.totalFixedCost'] });
  map.set('totalFixedCostNet',           { value : ['{K_TASK}.totalFixedCostNet'] });
  return map;
}

/**
 * WARNING: This is private method.
 * Handling value which is possible to be an array object, get the first item as value if there is one.
 * @param {Any} value 
 * @returns 
 */
function __getFirstItemForArrayValue(value) {
  if (value != null && Array.isArray(value) && value.length > 0) {
    return value[0];
  }
  return value;
}

/**
 * WARNING: This is private method.
 * Get the appropriate name for group
 * @param {String} colKey
 */
function __getSortColKey(colKey) {
  if (colKey == 'name') {
    return 'text';
  }
  if (colKey == 'templateUuId') {
    return 'templateName';
  }
  if (colKey == 'stageId') {
    return 'stageName';
  }
  if (colKey == 'resourceIds') {
    return 'resourceNames';
  }
  if (colKey == 'skillIds') {
    return 'skillNames';
  }
  if (colKey == 'rebateUuid') {
    return 'rebateName';
  }
  if (colKey == 'staffId') {
    return 'staffFirstName';
  }
  if (colKey == 'noteId') {
    return 'noteText';
  }
  return colKey;
}

function transformSortField(field) {
  if (field === 'totalActualDuration') {
    return 'actualDuration';
  }
  else if (field === 'notes') {
    return 'NOTE.text';
  }
  else if (field === 'skills') {
    return 'SKILL.name';
  }
  else if (field === 'stage') {
    return 'STAGE.name';
  }
  else if (field === 'staffs') {
    return 'STAFF.name';
  }
  else if (field === 'resources') {
    return 'RESOURCE.name';
  }
  else if (field === 'constraint') {
    return 'constraintType';
  }
  else if (field === 'rebates') {
    return 'REBATE.name';
  }
  else if (field === 'tag') {
    return 'TAG.name';
  }
  else if (field === 'taskPath') {
    return 'fullPath';
  }
  else if (field === 'template') {
    return 'PROJECT_TEMPLATE.name';
  }
  return field;
}

// function __convertEmptyDate(target, prop, defaultValue='1970-01-01') {
//   //Remark: set 1970-01-01 so that the gantt won't show error popup with message: Invalid date
//   if (target[prop] == 0) {
//     target[prop] = defaultValue
//   }
//   else if (typeof target[prop] == 'number') {
//     target[prop] = moment.utc(target[prop]).format('YYYY-MM-DD HH:mm')
//   } else {
//     target[prop] = defaultValue
//   }
// }