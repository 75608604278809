var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("RebateBadgeGroupModal", {
    attrs: { show: _vm.modalShow, rebateList: _vm.rebateList },
    on: {
      "update:show": function ($event) {
        _vm.modalShow = $event
      },
      ok: _vm.rebateSelectorOk,
      cancel: _vm.rebateSelectorCancel,
      rebateDeleted: _vm.rebateSelectorDeleted,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }