var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "edit-cell" },
    [
      _c("span", [_vm._v(_vm._s(_vm.label))]),
      _c("TaskConstraintModal", {
        attrs: {
          show: _vm.selectorShow,
          constraintType: _vm.value.type,
          constraintTime: _vm.value.time,
          optionConstraint: _vm.optionConstraint,
        },
        on: {
          "update:show": function ($event) {
            _vm.selectorShow = $event
          },
          ok: _vm.ok,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }