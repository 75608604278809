

<template>
  <span>{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import { costFormat, costFormatAdv } from '@/helpers';
import { setReadOnlyIfNotEditable } from './util.js';
export default Vue.extend({
  name: 'CostCellRenderer',
  data() {
    return {
      value: null,
      currencyCode: null,
      hideSymbol: false
    }
  },
  beforeMount() {
    this.value = this.params && this.params.value !== -1? this.params.value: null;
    let _currencyCode = null;
    if (this.params != null && this.params.data != null) {
      if (this.params.customCurrencyProp != null) {
        _currencyCode = this.params.data[this.params.customCurrencyProp];
      } else {
        _currencyCode = this.params.data.payCurrency;
      }

      //Fixed #1416, hide the currency symbol for top and bottom totals
      if (this.params.data.hideSymbol == true) {
        this.hideSymbol = true;
      }
    }
    this.currencyCode = _currencyCode == null || _currencyCode.trim().length == 0? null : _currencyCode;
    
    
    setReadOnlyIfNotEditable(this.params);
  },
  computed: {
    label() {
      if (this.value && Array.isArray(this.value)) {
        let display = [];
        for (const val of this.value) {
          display.push(`${this.currencyCode == null? (this.hideSymbol? costFormat(this.value) : '$'+costFormat(val)) : costFormatAdv(val, this.currencyCode)}`);
        }
        return display.join(', ');
      }
      return this.value? `${this.currencyCode == null? (this.hideSymbol? costFormat(this.value) : '$'+costFormat(this.value)) : costFormatAdv(this.value, this.currencyCode)}` : this.currencyCode == null? (this.hideSymbol? '0' : '$0') : costFormatAdv(0, this.currencyCode);
    }
  }
})
</script>
