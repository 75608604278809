<template>
  <div v-if="allowManage && isEditable" class="detail-link-cell" @mouseleave="onContainerMouseLeave">
    <img v-if="avatarUrl" class="image" width="120" :height="25" :src="avatarUrl"/>

    <font-awesome-icon :style="iconStyle" class="mr-1" v-if="icon" :icon="icon"/>
    <font-awesome-icon :style="iconStyle" class="mr-1" v-if="isCompany" icon="building"/>
    <div v-if="tag" class="tag" :class="tagClass">{{ tag }}</div>
    <a :id="`DETAIL_LINK_${params.eGridCell.getAttribute('comp-id')}`" href="#" :class="{ 'grid-cell-summary': isSummaryTask }" :style="labelStyle" @click.stop.prevent="invokeParentMethod" @mouseover="onMouseOver">{{ label }}</a>
    <b-popover v-if="tooltip" :target="`DETAIL_LINK_${params.eGridCell.getAttribute('comp-id')}`" triggers="hover" placement="right">
      {{ label }}
    </b-popover>
    <font-awesome-icon :style="iconStyle" class="ml-1" v-if="params && params.node && params.node.data && params.node.data.superUser" :icon="['far', 'shield']"/>
  </div>
  <div v-else>
    <font-awesome-icon class="mr-1" v-if="isCompany" :icon="['far', 'apartment']"/>
    <div v-if="tag" class="tag" :class="tagClass">{{ tag }}</div>
    <span>{{ label }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import { invertColor } from '@/helpers';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'DetailLinkCellRenderer',
  data() {
    return {}
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
  },
  computed: {
    avatarUrl() {
      return this.params && !this.params.ignoreAvatar && this.params.data && this.params.data.avatarRef && this.params.data.avatarRef !== "00000000-0000-0000-0000-000000000000" ? `${process.env.BASE_URL}api/file/${this.params.data.avatarRef}` : null;
    },
    labelStyle() {
      const componentParent = this.params.context.componentParent;
      const rowColor = componentParent.getRowColor ? componentParent.getRowColor(this.params.data, this.params) : null;
      const style = [];
      if (this.isCompany) {
        style.push('font-weight: bold');
      }
      
      if (rowColor) {
        style.push(`color: ${invertColor(rowColor, true)}`);
      }
      
      if (this.avatarUrl) {
        style.push('vertical-align: text-bottom');
      }
      
      return style.join(';');
    },
    iconStyle() {
      const componentParent = this.params.context.componentParent;
      const rowColor = componentParent.getRowColor ? componentParent.getRowColor(this.params.data, this.params) : null;
      const style = [];
      
      if (rowColor) {
        style.push(`color: ${invertColor(rowColor, true)}`);
      }
      else {
        style.push('color: var(--projectal-orange)');
      }
      return style.join(';');
    },
    icon() {
      if (this.params.data && this.params.data.icon) {
        return this.params.data.icon;
      }
      const iconFunc = this.params.context.componentParent.detailLinkIcon;
      if (iconFunc) {
        return iconFunc(this.params);
      }
      return null;
    },
    tooltip() {
      const tooltipFunc = this.params.context.componentParent.detailLinkTooltip;
      if (tooltipFunc) {
        return tooltipFunc(this.params);
      }
      return null;
    },
    tag() {
      const tagFunc = this.params.context.componentParent.detailLinkTag;
      if (tagFunc) {
        return tagFunc(this.params);
      }
      return null;
    },
    tagClass() {
      const tagClassFunc = this.params.context.componentParent.detailLinkTagClass;
      if (tagClassFunc) {
        return tagClassFunc(this.params);
      }
      return null;
    },
    label() {
      if(!this.params) {
        return 'N/A';
      }
      
      if (this.params.useValue) {
        return this.params.value;
      }
      
      const labelFunc = this.params.context.componentParent.detailLinkLabel;
      if(labelFunc) {
        return labelFunc(this.params);
      }
      
      let label = this.params && this.params.label? this.params.node.data[this.params.label]: this.params.node.data.label;
      if(label) {
        return label;
      }
      return 'N/A';
    },
    allowManage() {
      var manage = this.params.context.componentParent.allowManage;
      var can = true;
      if (this.params.context.componentParent.permissionName) {
        can = this.params.context.componentParent.canView();
      }
      return manage && can;
    },
    isEditable() {
      if (this.params.node.rowPinned) {
        return false;
      }
      if (typeof this.params.context.componentParent.isEditable === 'undefined') {
        return true;
      }
      return this.params.context.componentParent.isEditable(this.params);
    },
    isCompany() {
      if (this.params.decorateCompany) {
        const type = this.params && this.params.node && this.params.node.data && this.params.node.data.type ? this.params.node.data.type : null;
        return type !== null;
      }
      return false;
    },
    isSummaryTask() {
      const type = this.params && this.params.node && this.params.node.data && this.params.node.data.taskType ? this.params.node.data.taskType : null;
      return type !== null && (type === 'Project');
    }
  },
  methods: {
    onMouseOver(e) {
      const mouseOverFunc = this.params.context.componentParent.detailLinkMouseOver;
      if (mouseOverFunc) {
        mouseOverFunc(this.params, e);
      }
    },
    onContainerMouseLeave(e) {
      const mouseLeaveFunc = this.params.context.componentParent.detailLinkContainerMouseLeave;
      if (mouseLeaveFunc) {
        mouseLeaveFunc(this.params, e);
      }
    },
    invokeParentMethod(e) {
      if (this.params.clickFunc) {
        this.params.clickFunc(this.params);
        return;
      }
      
      const idFunc = this.params.context.componentParent.detailLinkId;
      this.params.context.componentParent.openDetail(idFunc? idFunc(this.params) : this.params.value, this.params, { isGeneric: this.params.data.genericStaff }, e);
    },
    refresh(params) {
      this.params = params;
    },
  }
});
</script>

<style lang="scss" scoped>
.tag {
  border-radius: 3px;
  vertical-align: center;
  display: inline-block;
  color: var(--white);
  font-size: 0.8em;
  padding: 0 5px 0 5px;
  height: 15px;
  line-height: 15px;
  margin-right: 3px;
}

.tag-purple {
  background-color: var(--deep-purple-400);
}

.tag-indigo {
  background-color: var(--blue-900);
}

.tag-red {
  background-color: var(--red-200);
}

.tag-light-blue {
  background-color: var(--light-blue);
}

.tag-white {
  background-color: var(--green-400);
  color: var(--white);
}

.tag-blue {
  background-color: var(--blue);
}

.tag-teal {
  background-color: var(--teal);
}

.tag-pumpkin {
  background-color: var(--pumpkin);
}

.tag-yellow {
  background-color: var(--yellow);
  color: var(--black);
}

.image {
  object-fit: cover;
  width: 16px;
  height: 16px;
  border: 2px solid var(--banner-avatar-border);
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 1;
  vertical-align: baseline;
  margin-right: 3px;
  margin-bottom: 2px;
}

</style>