var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { class: _vm.classList },
    [
      _vm.wrapper && _vm.wrapper.element
        ? [
            _c(
              _vm.wrapper.element,
              _vm._b(
                { tag: "component" },
                "component",
                _vm.wrapper.attributes,
                false
              ),
              [_vm._v(" " + _vm._s(_vm.name) + " ")]
            ),
          ]
        : [_vm._v(" " + _vm._s(_vm.name) + " ")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }