<template>
  <div class="gantt-container">
    <Gantt :projectId="id" :heightOffset="-10" isTemplate></Gantt>
  </div>
</template>

<script>
import Gantt from '@/components/Gantt/AgGridGantt';

export default {
  name: 'TemplateProjectGantt',
  components: {
    Gantt
  },
  data () {
    return {
      id: null
    }
  },
  created() {
    this.id = this.$route.params.id;
  }
}
</script>
