<template>
  <HeaderSidebarContainer>
    <HeaderCompany slot="headerCompany"/>
    <HeaderDropdownAccount slot="headerDropdownAccount"/>
    <router-view v-if="languagesLoaded"></router-view>
  </HeaderSidebarContainer>
</template>

<script>
import HeaderSidebarContainer from '@/views/layout/HeaderSidebarContainer'
import HeaderDropdownAccount from '@/components/HeaderDropdownAccount'
import HeaderCompany from '@/components/HeaderCompany'
import { languageProfileService, fileService } from '@/services'
import { EventBus } from '@/helpers';

export default {
  name: 'DashView',
  components: {
    HeaderSidebarContainer,
    HeaderDropdownAccount,
    HeaderCompany
  },
  data() {
    return {
      languagesLoaded: false
    }
  },
  created() {
  
    EventBus.$on('language-change', () => {
      this.languagesLoaded = false;
      setTimeout(() => {
        this.languagesLoaded = true;
      }, 1000);
    });
  },
  beforeDestroy() {
    EventBus.$off('language-change');
  },
  beforeMount() {
    // load the languages
    this.loadLanguages();
  },
  methods: {
    async loadLanguages() {
      let browserLocale = window.navigator.language;
      if (typeof localStorage.companyId !== 'undefined') {
        const profile = await languageProfileService.listPublic(localStorage.companyId)
        .then(response => {
          return response.data[response.data.jobCase];
        })
        .catch(e => {
          localStorage.removeItem('companyId');
          return [];
        });
        
        if (profile.length !== 0) {
          const languages = profile[0].languages;
          if (languages) {
            for (const lang of languages) {
              if (lang.path !== null) {
                await fileService.get(lang.path.uuId, false).then(fileRes => {
                  this.$root.$i18n.setLocaleMessage(lang.locale, fileRes.data || {});
                })
                .catch(e => {
                  console.log(e); // eslint-disable-line
                });
              }
              
              // if the language is set to default, override the browser locale
              if (lang.default) {
                browserLocale = lang.locale;
              }
            }
          }
        }
      }
        
      let languageSet = false;
            
      // try to set the language based upon the user's setting
      const languageProfile = await languageProfileService.list(this.$store.state.authentication.user.uuId).then(response => {
        return response.data[response.data.jobCase];
      });
      if (languageProfile.length !== 0) {
        if (languageProfile[0].language !== this.$i18n.locale) {
          this.$i18n.locale = languageProfile[0].language;
        }
        languageSet = true;
      }
      
      if (!languageSet) {
        // set the language based upon the browser's locale
        this.$root.$i18n.locale = this.$root.$i18n.availableLocales.includes(browserLocale) ? browserLocale : "en-projectal";
      }
      this.languagesLoaded = true;
    }
  }
}
</script>

