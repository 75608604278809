import { rebateService, rebateLinkTagService } from '@/services'
import { addTags, objectClone } from '@/helpers'
import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly, lackOfMandatoryField } from './common'
import { prepareCustomFieldColumnDef } from '@/helpers/custom-fields'

export const rebateUtil = {
  list: (bParams, { self }={}) => {
    return rebateService.listv2(bParams, { customFields: self?.customFields })
  }
  , listNames: rebateService.listNames
  , remove: rebateService.remove
  , clone: rebateService.clone
  , importDataFunc: (self) => {
    return async (item, errorFunc) => {
      let method = 'create';
      
      const data = {
        name: item.name,
        rebate: parseInt(item.rebate) / 100,
        identifier: item.identifier,
        color: item.color
      }

      if (self.customFields) {
        for (const cfield of self.customFields) {
          if (item[cfield.name]) {
            data[cfield.name] = item[cfield.name];
          }
        }
      }
      
      if (item.uuId) {
        data.uuId = item.uuId;
        method = 'update';
      }
      const result = await rebateService[method]([data])
      .then(response => {
        const feedbackList = response.data.feedbackList
        if (Array.isArray(feedbackList) && 
              feedbackList.length > 0 && 
              feedbackList[0].uuId != null) {
          return feedbackList[0].uuId
        }
      })
      .catch((e) => {
        errorFunc(e)
        return null
      })

      if (result && item.tag) {
        await addTags(result, item.tag.split(',').map(t => { return { name: t.trim() }}), rebateLinkTagService).catch(() => {
          // fail silently
        });
      }
    }
  }
  , buildParams: ({ request: {sortModel, endRow, startRow} }, { exportData=false, searchFilter=null, badgeFilters=null }={}) => {
    const params = {
      start: !exportData ? startRow : 0
      , limit: !exportData ? endRow - startRow + 1 : -1
      , ksort: []
      , order: []
      , filter: searchFilter
      , badgeFilters
    }
    
    for(let i = 0, len = sortModel.length; i < len; i++) {
      params.ksort.push(sortModel[i].colId === 'countryName' ? 'countryCode' : sortModel[i].colId)
      params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr')
    }
    if (searchFilter == null) {
      delete params.filter
    }
    return params
  }

  , getColumnDefs: (self) => {
    const colDefs = [
      {
        headerName: self.$t('rebate.field.name')
        , field: 'uuId'
        , cellRenderer: 'detailLinkCellRenderer'
        , cellEditor: 'nameEditor'
        , cellEditorParams: {
          isOptional: false
        }
        , checkboxSelection: false
        , pinned: 'left'
        , lockPosition: 'left'
        , lockVisible: true
        , minWidth: 200
        , hide: false
        , sort: 'asc'
        , editable: params => params.data.readOnly != true && self.canEdit('REBATE', ['name'])
        , valueSetter: function(params) {
          const newValue = params.newValue.trim()
          const oldValue = objectClone(params.data.name)
          if (newValue !== '' && newValue != oldValue) {
            self.$set(params.data, 'oldName', oldValue)
            params.data.name = newValue
            return true
          }
        }
      },
      {
        headerName: self.$t('rebate.field.rebate')
        , field: 'rebate'
        , cellRenderer: 'percentageCellRenderer'
        , cellRendererParams: {
          noFill: true
          , precision: 3
        }
        , cellEditor: 'plainPercentageEditor'
        , hide: false
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('field.tag')
        , field: 'tag'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'tagEditor'
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('field.color')
        , field: 'color'
        , cellRenderer: 'colorCellRenderer'
        , cellEditor: 'colorEditor'
        , hide: true
        , editable: params => params.data.readOnly != true
      },
      {
        headerName: self.$t('field.identifier_full')
        , field: 'identifier'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      }
    ]

    prepareCustomFieldColumnDef(colDefs, self.customFields, { self });
    
    const linkedEntities = [{ selector: 'REBATE.TAG', field: 'tag', properties: ['name'] }]

    //VIEW permission: Remove column from display list
    filterOutViewDenyProperties(colDefs, 'REBATE', linkedEntities)
    if (self.isEntityEditable) {
      //EDIT permission: set column to be read only.
      setEditDenyPropertiesReadOnly(colDefs, 'REBATE', linkedEntities)
    } else {
      for (let i = 0, len = colDefs.length; i < len; i++) {
        colDefs[i].editable = false;
      }
    }
    return colDefs
  }
  , getColorMenuOptions: () => ({
    none: true
    , rebate: false
  })
  , getImportDataProperties: (self) => [
    { value: 'color', text: self.$t('field.color') }
    , { value: 'identifier', text: self.$t('field.identifier') }
    , { value: 'name', text: self.$t('rebate.field.name') }
    , { value: 'rebate', text: self.$t('rebate.field.rebate') }
    , { value: 'tag', text: self.$t('field.tag') }
  ]
  , entityUpdateApiUrl: '/api/rebate/update'
  , entityDeleteApiUrl: '/api/rebate/delete'
  , getValueChangedHandler: (/** self */) => ({})
  , getPropertyDeleteHandler: (/** self */) => {
    return {
      tag: []
    }
  }
  , getPropertyCopyHandler: (self) => {
    let maxNameLength = 200
    let maxIdentifierLength = 200
    if (self.modelInfo != null) {
      let val = self.modelInfo.filter(info => info.field === 'name')
      if (val.length > 0) {
        maxNameLength = val[0].max
      }
      val = self.modelInfo.filter(info => info.field === 'identifier')
      if (val.length > 0) {
        maxIdentifierLength = val[0].max
      }
    } 

    //Expected format when return value is a function:
    //{ value, status } or
    //{ value, status, colId } when status is ABORT
    //Possible status: 'SUCCESS' | 'ABORT'
    //colId is optional but is useful for specifying a different colId as reset value.
    return {
      color: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.trim().length == 0) {
          value = null
        }
        return { value, status: 'SUCCESS' }
      }
      , name: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.length > maxNameLength) {
          value = srcValue.substring(0, maxNameLength)
        }
        return { value, status: 'SUCCESS' }
      }
      , identifier: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && value.length > maxIdentifierLength) {
          value = srcValue.substring(0, maxIdentifierLength)
        }
        return { value, status: 'SUCCESS' }
      }
    }
  }
  , lackOfMandatoryField: () => {
    return lackOfMandatoryField([{ entity: 'REBATE', action: 'VIEW' }])
  }
  , getExportDataPropertyHandler: (/**self */) => {
    const formatRebate = (params) => {
      if (params.value != null) {
        return params.value * 100
      }
      return ''
    }

    return {
      rebate: (params) => {
        return formatRebate(params)
      }
      
    }
  }
  , getMandatoryFields() {
    return [
      'name'
    ]
  }
  , getBadgeFilterFields: (self) => {
    const fields = [
      { value: 'name', text: self.$t('rebate.field.name') }
      , { value: 'color', text: self.$t('rebate.field.color') }
      , { value: 'tagName', text: self.$t('field.tag') }
      , { value: 'identifier', text: self.$t('field.identifier') }
    ];
    if (Array.isArray(self.customFields) && self.customFields.length > 0) {
      for (const f of self.customFields) {
        if (f.type == 'String' || f.type == 'Enum<String>') {
          fields.push({ value: f.name, text: f.displayName });
        }
      }
    }
    fields.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }))
    return fields;
  }
  , getBadgeFilterOptionFetchFunc: (self) => {
    return (field) => {
      let f = field;
      if (f == 'tagName') {
        f = 'TAG.name'
      }
      return rebateService.listUniqueValuesOfProperty(f)
      .then(data => {
        if (data.length > 0 && self.badgeFilters != null && self.badgeFilters.length > 0) {
          const found = self.badgeFilters.find(i => i.field == field)
          if (found != null && Array.isArray(found.value) && found.value.length > 0) {
            //Normal handling
            const rList = data.map(i => ({ 
              text: !i ? '(Empty)' : i
              , checked: found.value.find(j => j.text != null 
                                          && (typeof j.text === 'string' && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) 
                                              || j.text == i) != null
            }))
            if (rList.find(i => i.text == '(Empty)') == null) {
              rList.unshift({ text: '(Empty)', checked: false })
            }
            return rList;
          }
        }
        
        //Normal handling
        const rList = data.map(i => ({ text: !i ? '(Empty)' : i, checked: false }))
        if (rList.find(i => i.text == '(Empty)') == null) {
          rList.unshift({ text: '(Empty)', checked: false })
        }
        return rList;
      });
    }
  }
  , prepareDataForOkEmit: (rowNodes) => {
    if (Array.isArray(rowNodes)) {
      return rowNodes.map(i => ({
        uuId: i.data.uuId
        , name: i.data.name
        , rebate: i.data.rebate
      }));
    } 
    return []
  }
}