var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gantt-container" },
    [_c("Gantt", { attrs: { projectId: _vm.id, heightOffset: -7 } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }