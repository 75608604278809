2<template>
  <div class="edit-cell">
    <NoteWithListModal :show="noteShow"
      :id="noteUuid"
      :holderid="entityUuId" 
      :notes="notes"
      @cancel="closePicker"
      @changed="changed"
      :title="$t('comment.edit_comment')"
      :showList="showList"
      :newText="newText"
      :highlightAllOnFocus="highlightAllOnFocus"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { objectClone } from '@/helpers';
import { noteService } from '@/services';

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';

export default Vue.extend({
  name: 'Note3Editor',
  components: {
    NoteWithListModal: () => import('@/components/modal/NoteWithListModal.vue')
  },
  data() {
    return {
      value: ''
      , highlightAllOnFocus: true
      , noteShow: true
      , noteUuid: null
      , entityName: null
      , entityUuId: null
      , notes: null
      , showList: true
      , compactMode: false
      , hasChange: false
      , newText: null
    };
  },
  methods: {
    getValue() {
      if (this.hasChange) {
        if (this.compactMode) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        return this.value;
      } 
      return this.params.value;
    }
    , isPopup() {
      return true;
    }
    , setInitialState(params) {
      let startValue = null;
      let highlightAllOnFocus = false;

      if (params.key === KEY_BACKSPACE || params.key === KEY_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (params.charPress) {
        // if a letter was pressed, we start with the letter
        startValue = params.charPress;
      }
      else if (params.key === KEY_F2) {
        highlightAllOnFocus = true;
      }
      this.newText = startValue;
      this.highlightAllOnFocus = highlightAllOnFocus;
      
      let isSingleSelected = true;
      if (params.api != null) {
        isSingleSelected = params.api.getSelectedNodes().length < 2;
      }
      this.showList = isSingleSelected;

      let value = this.compactMode? objectClone(params.value.single) : params.value;
      if (value == null) {
        value = [];
      }
      if (this.newText == null && isSingleSelected && value.length > 0) {
        this.noteUuid = value[0].uuId;
      }
      this.entityName = params.entityName;
      //Commented to make sure entityUuId always null. The variable is subject to be obsolete in future.
      // this.entityUuId = this.compactMode? params.value.uuId: params.data.uuId;
      this.notes = objectClone(value); //Clone the value, so any change on the cloned value will not affect params.value.
      this.value = value;
    }
    , closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
        const focusedCell = this.params.api.getFocusedCell();
        if (focusedCell != null) {
          const rowIndex = focusedCell.rowIndex;
          const colId = focusedCell.column.colId;
          const elem = document.querySelector(`div[row-index="${rowIndex}"] > div[col-id="${colId}"]`);
          if (elem != null) {
            elem.focus();
          }
        }
      }, 100);
    }
    , changed(notes=null) {
      this.hasChange = true;
      
      //Delegate the update job to parent component
      if (this.entityUuId == null) {
        this.value = notes != null? notes : [];
        this.closePicker();
      } else {
        noteService.list({ start: 0, limit: -1, ksort: 'modified', order: 'decr' } , this.entityName, this.entityUuId)
        .then(response => {
          if (!this.showList) {
            //Set only the top/latest note when in multi task mode. Useful for update job in parent component
            this.value = [objectClone(response.data[0])];
          } else {
            this.value = objectClone(response.data);
          }
        })
        .finally(() => {
          this.closePicker();
        });
      }
    }
  },
  created() {
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    this.setInitialState(this.params);
  },
  mounted() {
    
  }
})
</script>
