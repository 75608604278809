
/* 
  import of DHTMLX Gantt is required.
*/

import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

import { calcDateTimeDurationv2, roundDurationDisplay, calcRoundedDuration
  , TRIGGERS } from '@/helpers/task-duration-process';
import { debounce } from 'lodash';

export const ganttTemplateUtil = {
  create,
  getCalendar
}

function getCalendar(projLocationId, parentComponent) {
  let calendar = null;
  let _projLocationId = null;
  if (projLocationId != null) {
    _projLocationId = projLocationId;
  }
  if (parentComponent.locationCalendarMap != null && parentComponent.locationCalendarMap instanceof Map) {
    const locationCalendarMap = parentComponent.locationCalendarMap;
    if (locationCalendarMap.has(_projLocationId)) {
      calendar = locationCalendarMap.get(_projLocationId);
    }
  }

  if (calendar == null) {
    calendar = parentComponent.calendar;
  }
  return calendar;
}

function getDisplayedDateFromTaskDate(g, jsdate, scale) {
  if (jsdate == null) {
    return null;
  }
  const momentDate = moment.utc([
    jsdate.getFullYear()
    , jsdate.getMonth()
    , jsdate.getDate()
    , jsdate.getHours()
    , jsdate.getMinutes()
    , jsdate.getSeconds()
  ]);
  // return momentDate.format(`ddd, YYYY-MM-DD hh:mm`);
  const enableTime = scale == 'day';
  return momentDate.format(`ddd, L${ enableTime? ' hh:mm A': ''}`);
}

function getMomentDateWhenResizing(oldDate, date, scale) {
  const isDayScale = scale == 'day'? true : false;
  const isTimeChanged = oldDate.valueOf() != date.valueOf();
  
  const momentDate = moment.utc([
    date.getFullYear()
    , date.getMonth()
    , date.getDate()
    , date.getHours()
    , date.getMinutes()
    , date.getSeconds()
  ]);

  if (isTimeChanged) {
    if (isDayScale) { //focus on hour and minutes. 15 minutes as time interval.
      let delta = momentDate.minutes() % 15;
      if (delta < 8) {
        delta = 0;
      } else {
        delta = 1;
      }
      const minute = (parseInt(momentDate.minutes() / 15) + delta) * 15;
      if (minute >= 60) {
        momentDate.add(1, 'hour');
        momentDate.minutes(0);
      } else {
        momentDate.minutes(minute);
      }
    }
  }

  return momentDate;
}

function getMomentDateWhenResizingv2(oldDate, dateTime, scale) {
  const isDayScale = scale == 'day'? true : false;
  const isTimeChanged = oldDate.valueOf() != dateTime.valueOf();
  
  const momentDate = dateTime;

  if (isTimeChanged) {
    if (isDayScale) { //focus on hour and minutes. 15 minutes as time interval.
      let delta = momentDate.minutes() % 15;
      if (delta < 8) {
        delta = 0;
      } else {
        delta = 1;
      }
      const minute = (parseInt(momentDate.minutes() / 15) + delta) * 15;
      if (minute >= 60) {
        momentDate.add(1, 'hour');
        momentDate.minutes(0);
      } else {
        momentDate.minutes(minute);
      }
    }
  }

  return momentDate;
}

function getTooltipDuration(displayedDuration, durationAUM, timescale, durationConversionOpts={}) {
  //Use hour unit when in day scale and value is less than 1
  let unit = durationAUM;
  if (timescale == 'day' && 'D' == unit) {
    const dDisplay = displayedDuration;
    if (dDisplay.charAt(0) == '0') {
      unit = 'h';
    }
  }
  return roundDurationDisplay(displayedDuration, unit, 3, durationConversionOpts);
}

function hideAndResetTaskToolTipObject(tooltipObj) {
  tooltipObj.show = false;
  tooltipObj.taskId = null;
  tooltipObj.name = null;
  tooltipObj.startDateStr = null;
  tooltipObj.endDateStr = null;
  tooltipObj.durationDisplay = null;
  tooltipObj.taskType = null;
  tooltipObj.posX = -1;
  tooltipObj.posY = -1;
}

function taskMove_calcDurationDisplay(task, parentComponent) {
  const timescale = parentComponent.timescale;

  const convertDateToMoment = function(_date) {
    return moment.utc([
      _date.getFullYear()
      , _date.getMonth()
      , _date.getDate()
      , _date.getHours()
      , _date.getMinutes()
      , _date.getSeconds()
    ]);
  }
  const startTime = convertDateToMoment(task.start_date);
  const closeTime = convertDateToMoment(task.end_date);
  const calendar = getCalendar(task.projLocationId, parentComponent);

  let trigger = TRIGGERS.START_DATE;
  let projectScheduleFromStart = true;
  if (task.projScheduleFrom != null && task.projScheduleFrom == 'ALAP') { //Used by PagedAgGridGantt
    trigger = TRIGGERS.CLOSE_DATE;
    projectScheduleFromStart = false;
  } else if (parentComponent.project != null && parentComponent.project.scheduleFrom == 'ALAP') { //Used by AgGridGantt
    trigger = TRIGGERS.CLOSE_DATE;
    projectScheduleFromStart = false;
  }

  let taskAutoScheduleMode = task.auto_scheduling;
  if (task.actual_auto_scheduling != null) { //Used by PagedAgGridGantt
    taskAutoScheduleMode = task.actual_auto_scheduling; 
  }

  if (timescale != 'day') {
    const oct = task.org_end_date;
    closeTime.hour(oct.getHours()).minute(oct.getMinutes());
  }

  const payload = {
    trigger: trigger
    , startDateStr: startTime.format('YYYY-MM-DD')
    , startTimeStr: startTime.format('HH:mm')
    , closeDateStr: closeTime.format('YYYY-MM-DD')
    , closeTimeStr: closeTime.format('HH:mm')
    , durationDisplay: null
    , calendar: calendar
    , projScheduleFromStart: projectScheduleFromStart
    , taskAutoScheduleMode: taskAutoScheduleMode
    , lockDuration: false
    , autoMoveForNonWorkingDay: true
    , skipOutOfProjectDateCheck: true
  }
  if (parentComponent?.durationConversionOpts != null) {
    payload.durationConversionOpts = parentComponent.durationConversionOpts;
  }
  const result = calcDateTimeDurationv2(payload);
  return result.durationDisplay;
}

function taskResize_calcDurationDisplay(task, parentComponent) {
  const timescale = parentComponent.timescale;
  const startTime = getMomentDateWhenResizing(task.org_start_date, task.start_date, timescale);
  const closeTime = getMomentDateWhenResizing(task.org_end_date, task.end_date, timescale);
  const calendar = getCalendar(task.projLocationId, parentComponent);

  let trigger = TRIGGERS.START_DATE;
  let projectScheduleFromStart = true;
  if (task.projScheduleFrom != null && task.projScheduleFrom == 'ALAP') { //Used by PagedAgGridGantt
    trigger = TRIGGERS.CLOSE_DATE;
    projectScheduleFromStart = false;
  } else if (parentComponent.project != null && parentComponent.project.scheduleFrom == 'ALAP') { //Used by AgGridGantt
    trigger = TRIGGERS.CLOSE_DATE;
    projectScheduleFromStart = false;
  }

  let taskAutoScheduleMode = task.auto_scheduling;
  if (task.actual_auto_scheduling != null) { //Used by PagedAgGridGantt
    taskAutoScheduleMode = task.actual_auto_scheduling; 
  }

  if (timescale != 'day') {
    const oct = task.org_end_date;
    closeTime.hour(oct.getHours()).minute(oct.getMinutes());
  }

  const payload = {
    trigger: trigger
    , startDateStr: startTime.format('YYYY-MM-DD')
    , startTimeStr: startTime.format('HH:mm')
    , closeDateStr: closeTime.format('YYYY-MM-DD')
    , closeTimeStr: closeTime.format('HH:mm')
    , durationDisplay: null
    , calendar: calendar
    , projScheduleFromStart: projectScheduleFromStart
    , taskAutoScheduleMode: taskAutoScheduleMode
    , lockDuration: false
    , autoMoveForNonWorkingDay: true
    , skipOutOfProjectDateCheck: true
  }
  
  if (parentComponent?.durationConversionOpts != null) {
    payload.durationConversionOpts = parentComponent.durationConversionOpts;
  }

  if (timescale == 'day') {
    const result = calcDateTimeDurationv2(payload);
    return { 
      durationDisplay: result.durationDisplay //Note: unit of resultDisplay is always 'D'.
      , startDateStr: result.startDateStr
      , startTimeStr: result.startTimeStr
      , closeDateStr: result.closeDateStr
      , closeTimeStr: result.closeTimeStr 
    }
  } else {
    const result = calcRoundedDuration(payload);
    return { 
      durationDisplay: result.durationDisplay //Note: unit of resultDisplay is always 'D'.
      , startDateStr: result.startDateStr
      , startTimeStr: result.startTimeStr
      , closeDateStr: result.closeDateStr
      , closeTimeStr: result.closeTimeStr 
    }
  }
}

function taskMove_calcStartDateOrCloseDate(task, parentComponent) {
  const timescale = parentComponent.timescale;
  const startTime = getMomentDateWhenResizing(task.org_start_date, task.start_date, timescale);
  const closeTime = getMomentDateWhenResizing(task.org_end_date, task.end_date, timescale);
  const calendar = getCalendar(task.projLocationId, parentComponent);

  let trigger = TRIGGERS.START_DATE;
  let projectScheduleFromStart = true;
  if (task.projScheduleFrom != null && task.projScheduleFrom == 'ALAP') { //Used by PagedAgGridGantt
    trigger = TRIGGERS.CLOSE_DATE;
    projectScheduleFromStart = false;
  } else if (parentComponent.project != null && parentComponent.project.scheduleFrom == 'ALAP') { //Used by AgGridGantt
    trigger = TRIGGERS.CLOSE_DATE;
    projectScheduleFromStart = false;
  }

  let taskAutoScheduleMode = task.auto_scheduling;
  if (task.actual_auto_scheduling != null) { //Used by PagedAgGridGantt
    taskAutoScheduleMode = task.actual_auto_scheduling; 
  }

  if (timescale != 'day') { //expect 'week', 'month', and 'year'
    startTime.hour(task.org_start_date.getHours()).minute(task.org_start_date.getMinutes());
    closeTime.hour(task.org_end_date.getHours()).minute(task.org_end_date.getMinutes());
  }

  const payload = {
    trigger: trigger
    , startDateStr: projectScheduleFromStart? startTime.format('YYYY-MM-DD'): null
    , startTimeStr: projectScheduleFromStart? startTime.format('HH:mm') : null
    , closeDateStr: !projectScheduleFromStart? closeTime.format('YYYY-MM-DD'): null
    , closeTimeStr: !projectScheduleFromStart? closeTime.format('HH:mm'): null
    , durationDisplay: task.tm_duration_display
    , calendar: calendar
    , projScheduleFromStart: projectScheduleFromStart
    , taskAutoScheduleMode: taskAutoScheduleMode
    , lockDuration: false
    , autoMoveForNonWorkingDay: true
    , skipOutOfProjectDateCheck: true
  }
  if (parentComponent?.durationConversionOpts != null) {
    payload.durationConversionOpts = parentComponent.durationConversionOpts;
  }
  const result = calcDateTimeDurationv2(payload);
  return {
    startTime: result.startDateStr != null? 
                moment.utc(`${result.startDateStr}${result.startTimeStr != null? ' '+result.startTimeStr:''}`, 'YYYY-MM-DD HH:mm') : null
    , closeTime: result.closeDateStr != null? 
                moment.utc(`${result.closeDateStr}${result.closeTimeStr != null? ' '+result.closeTimeStr:''}`, 'YYYY-MM-DD HH:mm') : null
  }
}

function create(g, parentComponent) {
  g.templates.grid_folder = function(item) {
    return `<div class="gantt-task-icon dxi dxi-folder${(item.$open ? "-open" : "")}"></div>`;
  };

  g.templates.rightside_text = function(start, end, task) {
    return task.type == g.config.types.milestone? task.text:'';
  };

  // var open_tasks;
  
  const WEEKDAY = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

  const isWorkTime = function(date) {
    if (date == null || parentComponent == null || !Array.isArray(parentComponent.workTime) | parentComponent.workTime.length < 1) {
      return false;
    }

    const day = date.getDay();
    
    if (parentComponent.calendar != null) {
      const dateStr = `${date.getFullYear()}-${date.getMonth()< 9? '0':''}${date.getMonth()+1}-${date.getDate()<10?'0':''}${date.getDate()}`;

      //Determine if the date is weekday(/workday) or weekend(/dayoff) in usual routine.
      //If it is a workday, look for leave exception. If found, it is not a workday.
      //If it is a dayoff, look for working exception. If found, it is a workday.
      const weekDay = WEEKDAY[day];
      const isWorkDay = Array.isArray(parentComponent.calendar[weekDay]) && parentComponent.calendar[weekDay].length > 0? (parentComponent.calendar[weekDay][0].isWorking == true) : true;//Default is work day.
      
      if (isWorkDay) {
        if (Array.isArray(parentComponent.calendar.Leave) && parentComponent.calendar.Leave.length > 0) {
          const found = parentComponent.calendar.Leave.find(i => i.startDate <= dateStr && i.endDate >= dateStr)
          if (found != null) {
            return false;
          }
        }
        return true;
      }
      else {
        if (Array.isArray(parentComponent.calendar.Working) && parentComponent.calendar.Working.length > 0) {
          const found = parentComponent.calendar.Working.find(i => i.startDate <= dateStr && i.endDate >= dateStr)
          if (found != null) {
            return true;
          }
        }
        return false;
      }
    }

    //Fallback when calendar is null
    const found = parentComponent.workTime.find(i => i.day == day);
    if (found != null) {
      return found.hours != false;
    }
    return false;
  }

  g.templates.timeline_cell_class = function(task,date){
    return g.getScale().unit == 'day' && !isWorkTime(date)? 'weekend' : '';
  };

  g.attachEvent('onTemplatesReady', function() {
    // Reading Timestamp from the server
    g.templates.xml_date = function(dateString) {
      //To Trick gantt to display UTC date literally.
      const d = new Date(dateString);
      return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());
    };
    // Formatting Timestamp for server
    g.templates.xml_format = function(date) {
      //To Trick gantt to output UTC date literally.
      const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
      return d.valueOf();
    };
  });

  g.attachEvent('onTaskDrag', function(id, mode, task, original, e){
    if(task.dragMode) {
      let taskResizeResult = null;
      if(task.dragMode === 'progress') {
        task.text = `${parseInt(task.progress * 100)}%`;
      } else if(task.dragMode === 'resize') {
        if(task.resizeRight == null) {
          task.resizeRight = task.end_date != original.end_date;
        }

        taskResizeResult = taskResize_calcDurationDisplay(task, parentComponent);
        task.text = taskResizeResult.durationDisplay;
        task.durationDisplayOnDrag = task.text;
        task.startDateStrOnDrag = taskResizeResult.startDateStr;
        task.startTimeStrOnDrag = taskResizeResult.startTimeStr;
        task.closeDateStrOnDrag = taskResizeResult.closeDateStr;
        task.closeTimeStrOnDrag = taskResizeResult.closeTimeStr;
      }
      
      //Show task tooltip when user performing task dragging
      if (parentComponent.taskTooltip != null) {
        const tooltipObj = parentComponent.taskTooltip;
        if (task.dragMode == 'move' || task.dragMode == 'resize') {
          const timescale = parentComponent.timescale;
          let startTime, closeTime;
          if (task.dragMode == 'resize') {
            startTime = getMomentDateWhenResizingv2(
              task.org_start_date
              , moment.utc(`${taskResizeResult.startDateStr} ${taskResizeResult.startTimeStr}`, 'YYYY-MM-DD HH:mm')
              , timescale);
            closeTime = getMomentDateWhenResizingv2(
              task.org_end_date
              , moment.utc(`${taskResizeResult.closeDateStr} ${taskResizeResult.closeTimeStr}`, 'YYYY-MM-DD HH:mm')
              , timescale);
          } else {
            const result = taskMove_calcStartDateOrCloseDate(task, parentComponent);
            startTime = result.startTime;
            closeTime = result.closeTime;
          }
          const enableTime = timescale == 'day';
          tooltipObj.startDateStr = startTime.format(`ddd, L${ enableTime? ' hh:mm A': ''}`);
          tooltipObj.endDateStr = closeTime.format(`ddd, L${ enableTime? ' hh:mm A': ''}`);
          if (task.dragMode == 'resize') {
            tooltipObj.durationDisplay = getTooltipDuration(task.text, task.durationAUM, timescale
              , parentComponent?.durationConversionOpts != null? parentComponent.durationConversionOpts : {}
            );
          } else {
            tooltipObj.durationDisplay = getTooltipDuration(task.durationInDays, task.durationAUM, timescale
              , parentComponent?.durationConversionOpts != null? parentComponent.durationConversionOpts : {}
            );
          }
        } else if (task.dragMode == 'progress') {
          tooltipObj.progress = `${parseInt(task.progress * 100)}%`;
        }

        const { x } = parentComponent.calcTooltipPosition(e.x, tooltipObj.posY);
        tooltipObj.posX = x; //Just update x axis. Y axis has been set in onBeforeTasKDrag
      }
    }
	});

	g.attachEvent('onBeforeTaskDrag', function(id, mode, e){
    if (parentComponent.isReadOnly) {
      return false;
    }
    const task = g.getTask(id);
    if (task.type == 'janusks_project') {
      return false;
    }
    parentComponent.isTaskDragging = true;
    parentComponent.$emit('cellFocused', id); //Let LHS grid to set the latest cell focus.

    task.dragMode = mode;
    task.originalProgress = task.progress;
    if('progress' === mode) {
			task.temp_text = task.text;
			task.text = `${parseInt(task.progress * 100)}%`;
    } else if ('move' === mode) {
      task.org_start_date = task.start_date;
      task.org_end_date = task.end_date;
      if (task.lockDuration == true) {
        task.tm_duration_display = taskMove_calcDurationDisplay(task, parentComponent);
      } else {
        task.tm_duration_display = task.durationInDays;
      }
      
    } else if('resize' === mode) {
      task.org_start_date = task.start_date;
      task.org_end_date = task.end_date;
      task.temp_text = task.text;

      const startTime = moment.utc([
        task.start_date.getFullYear()
        , task.start_date.getMonth()
        , task.start_date.getDate()
        , task.start_date.getHours()
        , task.start_date.getMinutes()
        , task.start_date.getSeconds()
      ]);
      const closeTime = moment.utc([
        task.end_date.getFullYear()
        , task.end_date.getMonth()
        , task.end_date.getDate()
        , task.end_date.getHours()
        , task.end_date.getMinutes()
        , task.end_date.getSeconds()
      ]);

      const calendar = getCalendar(task.projLocationId, parentComponent);
      
      let trigger = TRIGGERS.START_DATE;
      let projectScheduleFromStart = true;
      if (task.projScheduleFrom != null && task.projScheduleFrom == 'ALAP') { //Used by PagedAgGridGantt
        trigger = TRIGGERS.CLOSE_DATE;
        projectScheduleFromStart = false;
      } else if (parentComponent.project != null && parentComponent.project.scheduleFrom == 'ALAP') { //Used by AgGridGantt
        trigger = TRIGGERS.CLOSE_DATE;
        projectScheduleFromStart = false;
      }

      let taskAutoScheduleMode = task.auto_scheduling;
      if (task.actual_auto_scheduling != null) { //Used by PagedAgGridGantt
        taskAutoScheduleMode = task.actual_auto_scheduling; 
      }

      const payload = {
        trigger: trigger
        , startDateStr: startTime.format('YYYY-MM-DD')
        , startTimeStr: startTime.format('HH:mm')
        , closeDateStr: closeTime.format('YYYY-MM-DD')
        , closeTimeStr: closeTime.format('HH:mm')
        , durationDisplay: null
        , calendar: calendar
        , projScheduleFromStart: projectScheduleFromStart
        , taskAutoScheduleMode: taskAutoScheduleMode
        , lockDuration: false
        , autoMoveForNonWorkingDay: true
        , skipOutOfProjectDateCheck: true
      }
      if (parentComponent?.durationConversionOpts != null) {
        payload.durationConversionOpts = parentComponent.durationConversionOpts;
      }
      const durationDisplay = calcDateTimeDurationv2(payload).durationDisplay;
      task.text = durationDisplay;
      task.durationDisplayOnDrag = durationDisplay;
    }
    
    //Show task tooltip when user performing task dragging
    if (parentComponent.taskTooltip != null && (task.dragMode == 'move' || task.dragMode == 'resize')) {
      const tooltipObj = parentComponent.taskTooltip;
      const timescale = parentComponent.timescale;
      const startTime = getMomentDateWhenResizing(task.org_start_date, task.start_date, timescale);
      const closeTime = getMomentDateWhenResizing(task.org_end_date, task.end_date, timescale);

      const enableTime = timescale == 'day';
      tooltipObj.startDateStr = startTime.format(`ddd, L${ enableTime? ' hh:mm A': ''}`);
      tooltipObj.endDateStr = closeTime.format(`ddd, L${ enableTime? ' hh:mm A': ''}`);
      if (task.dragMode == 'resize') {
        tooltipObj.durationDisplay = getTooltipDuration(task.text, task.durationAUM, timescale
          , parentComponent?.durationConversionOpts != null? parentComponent.durationConversionOpts : {}
        );
      } else { //move
        tooltipObj.durationDisplay = getTooltipDuration(task.durationInDays, task.durationAUM, timescale
          , parentComponent?.durationConversionOpts != null? parentComponent.durationConversionOpts : {}
        );
      }
      const { x, y } = parentComponent.calcTooltipPosition(e.x, e.target.getBoundingClientRect().top);
      tooltipObj.posX = x;
      tooltipObj.posY = y;
      tooltipObj.name = task.temp_text != null? task.temp_text : task.text;
      tooltipObj.taskType = task.type == 'janusks_project'? 1 : task.type;
      tooltipObj.taskId = task.id;
      tooltipObj.show = true;
    }


		return true;
	});

	g.attachEvent('onAfterTaskDrag', debounce(function(id, mode /** , e*/){
    parentComponent.isTaskDragging = false;
    const task = g.getTask(id);
    if('progress' === mode || 'resize' === mode) {
      task.text = task.temp_text;
      delete task.temp_text;
      g.refreshTask(id);
      g.render(); //Fixed #925. Force the gantt to redraw the missing resizing cursor and circle icons
    }
		return true;
  }, 50 ));

  g.attachEvent('onBeforeTaskChanged', function(/** id, mode, task */) {
    if (parentComponent.escapeKeyPressed === true) {
      parentComponent.isTaskDragging = false;
      parentComponent.escapeKeyPressed = false;
      return false;
    }
    return true;
  });

  g.attachEvent('onTaskClick', function(id, e) {
    parentComponent.$emit('taskClicked', id, e, parentComponent);
    return true;
  })
  
  g.attachEvent('onBeforeTaskMultiSelect', function(id, state /** , e*/) {
    if (parentComponent.isFocused) {
      if (parentComponent.timeoutHandler != null) {
        clearTimeout(parentComponent.timeoutHandler)
      }
      parentComponent.taskSelection[id] = state;
      parentComponent.timeoutHandler = setTimeout(() => {
        parentComponent.$emit('taskSelectionChanged', Object.entries(parentComponent.taskSelection).map(i => { return { id: i[0], state: i[1] } }))
        for (const propName in parentComponent.taskSelection){
          if (Object.hasOwn(parentComponent.taskSelection, propName)){
            delete parentComponent.taskSelection[propName];
          }
        }
      }, 200)
    }
    return true;
  });

  g.attachEvent('onGanttLayoutReady', function() {
    //Logic: Overwrite internal resizeScrollbars to empty function to make scrollbars always visible
    //Used to fix a scrolling issue caused by lhs grid has horizontal bar but rhs chart/gantt doesn't have one.
    g.$ui.getView("main").constructor.prototype._resizeScrollbars = function(){};
  });

  g.attachEvent('onBeforeTaskSelected', function(/** id */){
    return !g.config.readonly; //Disable row selection highlight when readonly is true.
  });

  g.attachEvent('onMouseMove', function (id, e){
    //Show task tooltip when mouse hover over task bar
    const tooltipObj = parentComponent != null && parentComponent.taskTooltip != null? parentComponent.taskTooltip : null;
    if (parentComponent.isTaskDragging || tooltipObj == null) {
      return;
    }

    if (id == null || e.target != null && e.target.classList.contains('gantt_task_row')) {
      if (tooltipObj.show && !parentComponent.isTaskDragging) {
        hideAndResetTaskToolTipObject(tooltipObj);
      }
    } else if (id == tooltipObj.taskId) {
      const { x, y } = parentComponent.calcTooltipPosition(e.x, e.y);
      tooltipObj.posX = x;
      tooltipObj.posY = y;
    } else {
      const task = g.getTask(id);
      if (task != null) {
        const timescale = parentComponent.timescale;
        tooltipObj.startDateStr = getDisplayedDateFromTaskDate(g, task.start_date, timescale);
        tooltipObj.endDateStr = getDisplayedDateFromTaskDate(g, task.end_date, timescale);
        tooltipObj.taskId = id;
        tooltipObj.name = task.temp_text != null? task.temp_text : task.text;
        tooltipObj.durationDisplay = getTooltipDuration(task.durationInDays, task.durationAUM, timescale
          , parentComponent?.durationConversionOpts != null? parentComponent.durationConversionOpts : {}
        );
        tooltipObj.progress = typeof task.progress !== 'undefined' ? `${task.progress * 100}%` : null;
        tooltipObj.status = typeof task.status !== 'undefined' && Array.isArray(task.status) && task.status.length !== 0 ? task.status[0] : null;
        
        tooltipObj.taskType = task.type == 'janusks_project'? 1 : task.type;
        const { x, y } = parentComponent.calcTooltipPosition(e.x, e.y);
        tooltipObj.posX = x;
        tooltipObj.posY = y;
        tooltipObj.show = true;
      }
    }
  });

  g.attachEvent('onParse', function(){
    //Hide tooltip after new data is parsed
    const tooltipObj = parentComponent != null && parentComponent.taskTooltip != null? parentComponent.taskTooltip : null;
    if (tooltipObj == null || tooltipObj.show == true) {
      hideAndResetTaskToolTipObject(tooltipObj);
    }
  });
  

  g.config.types['janusks_project'] = 'janusks_project';
  g.locale.labels['type_janusks_project'] = "Project";
}