var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("span", { staticClass: "grid-overlay" }, [
    _vm._v(_vm._s(_vm.params.msgFunc())),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }