
import { httpAjax } from '@/helpers';

export const templateTaskLinkSuccessorService = {
  create,
  update,
  remove,
};

/**
 * Create a new Link from template task to successor 
 * by passing necessary information
 * @param String taskId, Template Task's UUID
 * @param Array successors, Successor Template Task details. Data sample: [{ uuId }]
 * request body format sample: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', taskList: { uuId: 'xxxx..' } }]
 */
function create(taskId, successors) {
  const url = '/api/template/task/plan/task/add';
  const config = {
    headers: getHeaders()
  };

  const successorList = []
  successors.forEach(i => {
    successorList.push( {uuId: i.uuId, planLink: {type: i.type, lag: i.lag}} );
  });

  const data = {
    uuId: taskId,
    taskList: successorList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Update a new Link from template task to successor 
 * by passing necessary information
 * @param String taskId, Template Task's UUID
 * @param Array successors, Successor Template Task details. Data sample: [{ uuId }]
 * request body format sample: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', taskList: { uuId: 'xxxx..' } }]
 */
function update(taskId, successors) {
  const url = '/api/template/task/plan/task/update';
  const config = {
    headers: getHeaders()
  };

  const successorList = []
  successors.forEach(i => {
    successorList.push( {uuId: i.uuId, planLink: {type: i.type, lag: i.lag}} );
  });

  const data = {
    uuId: taskId,
    taskList: successorList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from template task to successor.
 * by passing in an array list of links uuIds
 * @param String taskId, Template Task' UUID
 * @param Array successors, list of Successor Template Task's UUID 
 * request body format sample: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', taskList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }]
 */
function remove(taskId, successors) {
  const url = '/api/template/task/plan/task/delete';
  const config = {
    headers: getHeaders()
  };

  const successorList = []
  successors.forEach(i => {
    successorList.push( {uuId: i.uuId} );
  });

  const data = {
    uuId: taskId,
    taskList: successorList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}