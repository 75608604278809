<template>
  <div class="gantt-container">
    <Gantt :projectId="id" :heightOffset="-7"></Gantt>
  </div>
</template>

<script>
import Gantt from '@/components/Gantt/AgGridGantt';

export default {
  name: 'ProjectGantt',
  components: {
    Gantt
  },
  data () {
    return {
      id: null
    }
  },
  created() {
    this.id = this.$route.params.id;
  }
}
</script>

<style lang="scss" scoped>
.gantt-container {
  margin-bottom: 25px;
}
</style>

