<template>
  <div class="div-outer-div">
    <span>{{ label }}</span>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'PercentageCellRenderer',
  data() {
    return {
      width: null,
      bgColor: null,
      label: null
    }
  },
  beforeMount() {
    var value = '';
    // kind:data
    if (this.params && this.params.value) {
      if (this.params.value.constructor === Array) {
        for (var val of this.params.value) {
          if (typeof val.kind !== 'undefined' &&
              typeof val.data !== 'undefined') {
            value === '' ? value = `${val.kind}:${val.data}` : value = `${value}, ${val.kind}:${val.data}`
          }
        }
      }
    }
    this.label = value;
  }
})
</script>
