var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "position-relative" }, [
    _c("div", {
      ref: "gantt_chart",
      style: _vm.ganttHeight,
      attrs: { id: "dhtmlx_gantt_container" },
    }),
    _c(
      "div",
      {
        staticClass: "task-tooltip",
        staticStyle: { "background-color": "white" },
        style: {
          top: _vm.tooltipTop + "px",
          left: _vm.tooltipLeft + "px",
          visibility: _vm.taskTooltip.show ? "visible" : "hidden",
        },
        attrs: { role: "dialog", "aria-label": "Tooltip" },
      },
      [
        _c("div", { staticClass: "header-box" }, [
          _c("div", { staticClass: "tag tag-yellow" }, [
            _vm._v(
              _vm._s(
                _vm.taskTooltip.taskType == 2
                  ? _vm.$t("label.milestone")
                  : _vm.taskTooltip.taskType == 1
                  ? _vm.$t("label.summary_task")
                  : _vm.taskTooltip.taskType == 3
                  ? _vm.$t("label.project")
                  : _vm.$t("label.task")
              )
            ),
          ]),
          _c("div", { staticClass: "header" }, [
            _vm._v(
              _vm._s(
                _vm.taskTooltip.name != null
                  ? _vm.taskTooltip.name
                  : _vm.$t("label.not_available")
              )
            ),
          ]),
        ]),
        _c(
          "div",
          [
            _vm.taskTooltip.taskType == 2
              ? _c("p", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("label.date")) + ": ")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.taskTooltip.startDateStr != null
                          ? _vm.taskTooltip.startDateStr
                          : _vm.$t("label.not_available")
                      )
                    ),
                  ]),
                ])
              : [
                  _c("p", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("task.field.startTime")) + ": "),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.taskTooltip.startDateStr != null
                            ? _vm.taskTooltip.startDateStr
                            : _vm.$t("label.not_available")
                        )
                      ),
                    ]),
                  ]),
                  _c("p", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("task.field.closeTime")) + ": "),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.taskTooltip.endDateStr != null
                            ? _vm.taskTooltip.endDateStr
                            : _vm.$t("label.not_available")
                        )
                      ),
                    ]),
                  ]),
                  _c("p", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("task.field.durationAUM")) + ": "),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.taskTooltip.durationDisplay != null
                            ? _vm.taskTooltip.durationDisplay
                            : _vm.$t("label.not_available")
                        )
                      ),
                    ]),
                  ]),
                  _vm.taskTooltip.progress
                    ? _c("p", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("project.display.progress")) + ": "
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.taskTooltip.progress != null
                                ? _vm.taskTooltip.progress
                                : _vm.$t("label.not_available")
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.taskTooltip.status
                    ? _c("p", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("project.field.status")) + ": "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.taskTooltip.status != null
                                ? _vm.taskTooltip.status
                                : _vm.$t("label.not_available")
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }