
import { httpAjax } from '@/helpers';

export const templateProjectLinkTagService = {
  create,
  remove,
};

/**
 * Create a new Link from template project to tag 
 * by passing necessary information
 * @param String projectId, Template Task's UUID
 * @param Array tags, Tag details. Data sample: [{ uuId, level }]
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', tagList: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', tagLink: {level: 'Junior'} }] }
 */
function create(projectId, tags) {
  const url = '/api/template/project/link/tag/add';
  const config = {
    headers: getHeaders()
  };

  const tagList = []
  tags.forEach(i => {
    tagList.push( {
      uuId: i.uuId
    });
  });

  const data = {
    uuId: projectId,
    tagList: tagList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from template project to tag.
 * by passing in an array list of links uuIds
 * @param String projectId, Template Task's UUID
 * @param Array tagIds, list of Tag's UUID 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', tagList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }
 */
function remove(projectId, tags) {
  const url = '/api/template/project/link/tag/delete';
  const config = {
    headers: getHeaders()
  };

  const tagList = []
  tags.forEach(i => {
    tagList.push( {
      uuId: i.uuId
    });
  });

  const data = {
    uuId: projectId,
    tagList: tagList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}