
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);

function getLang() {
  if (navigator.languages != undefined)
    return navigator.languages[0];
  else
    return navigator.language;
}

export function msToDate(ms) {
  var date = new Date(ms);

  return date.toLocaleDateString(getLang(), { timeZone: 'UTC' });
}

export function getShortDate(date) {
  var options = {
    weekday: "short",
    month: "2-digit",
    day: "numeric",
    timeZone: 'UTC'
  };
  return date.toLocaleDateString(getLang(), options);
}

export function getSpanDate(date, span) {
  if (span === 'date') {
    return date.format("l");
  }
  else if (span === 'month') {
    return date.format('MMM/YYYY');
  }
  else if (span === 'year') {
    return date.format('YYYY');
  }
  return date.format("l");
}

export function getNextWorkingDay(date) {
  if (date.getDay() === 6) { // Saturday, move to Monday
    date.setDate(date.getDate() + 2);
  }
  else if (date.getDay() === 0) { // Sunday, move to Monday
    date.setDate(date.getDate() + 1);
  }
  return date;
}

export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export function calcEpochs(span, startDate, endDate) {
  const epochs = [];
  const epochOptions = [];
  
  if (startDate !== null) {
    let start = moment(startDate);
    const end = moment(endDate);
    if (span === 'Daily') {
      do {
        epochs.push(start.unix() * 1000);
        epochOptions.push({ value: start.unix() * 1000, text: getSpanDate(start, 'date') });
        start = start.add(1, 'days');
      } while(start <= end)
    }
    else if (span === 'Weekly') {
      do {
        epochs.push(start.unix() * 1000);
        epochOptions.push({ value: start.unix() * 1000, text: getSpanDate(start, 'date') });
        start = start.add(7, 'days');
      } while(start <= end)
    }
    else if (span === 'Monthly') {
      do {
        epochs.push(start.unix() * 1000);
        epochOptions.push({ value: start.unix() * 1000, text: getSpanDate(start, 'month') });
        start = start.add(1, 'months');
      } while(start <= end)
    }
    else if (span === 'Yearly') {
      do {
        epochs.push(start.unix() * 1000);
        epochOptions.push({ value: start.unix() * 1000, text: getSpanDate(start, 'year') });
        start = start.add(1, 'years');
      } while(start <= end)
    }
  }
  return { epochs: epochs, epochOptions: epochOptions };
}
export function getDateRef(date, span) {
  let ref = `${date.getFullYear()}-${('0' + (date.getMonth()+1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
  
  if (span === 'year') {
    ref = date.getFullYear();
  }
  else if (span === 'month') {
    ref = `${date.getFullYear()}-${('0' + (date.getMonth()+1)).slice(-2)}`;
  }
  return ref;
}

export function convertDate(date, time) {
  if (date != null) {
    const dateTime = moment.utc(date, 'YYYY-MM-DD');
    if (time != null) {
      const token = time.split(':');
      dateTime.hour(token[0]).minute(token[1]);
    }
    return dateTime.valueOf();
  }
}

export function formatTooltipDate(date, showtime) {
  const disp = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString()
  if (showtime) {
    return moment(disp).format('ddd, L hh:mm A');
  }
  return moment(disp).format('ddd, L');
}

export function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  // Return array of year and week number
  return weekNo;
}

export function getWeekNumberProjectStart(start, d) {
  const startWeek = getWeekNumber(start);
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(start.getUTCFullYear(),0,1));
  // Calculate full weeks to nearest Thursday
  var weekNo = (Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7) - startWeek) + 1;
  // Return array of year and week number
  return weekNo;
}
