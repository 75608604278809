export function setReadOnlyIfNotEditable(params) {
  if (params == null || params.colDef == null 
      || (typeof params.enableReadonlyStyle === 'function'
          ? !params.enableReadonlyStyle(params) 
          : params.enableReadonlyStyle == null
          ? false //set false to fail this condition check: Don't consider enableReadonlyStyle when it is null or undefined
          : params.enableReadonlyStyle != true)) {
    return;
  }
  
  let isEditable = false;
  if (typeof params.colDef.editable == 'function') {
    isEditable = params.colDef.editable({
      node: params.node
      , data: params.data
      , column: params.column
      , colDef: params.colDef
      , api: params.api
      , context: params.context
    });
  } else {
    isEditable = params.colDef.editable == true? true : false;
  }
  
  if (isEditable) {
    return;
  }
  params.eGridCell.classList.add('read-only');
}