<template>
  <div class="animated fadeIn dataview-container">

    <AlertFeedback v-if="alertMsg != null && alertState != 'success'" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert" @offsetHeight="updateTabContentHeight"/>
    <div class="dataview-nav-tab-wrapper">
      <div v-if="!isWidget" class="data-view-navbar">
        <nav class="data-view-nav">
          <PriorityNavigation ulClassName="nav nav-pills">
            <li name="sheet" class="nav-pills nav-link active" @click.stop="dataViewNavClick"><a href="#" target="_self"><font-awesome-icon :icon="['far', 'th-list']"/>&nbsp;{{ $t('dataview.sheet') }}</a></li>
            <li name="gantt" class="nav-pills nav-link" v-if="isEntity('TASK') && showGantt" @click="prepareGantt()" @click.stop="dataViewNavClick"><a href="#" target="_self"><font-awesome-icon :icon="['far', 'stream']"/>&nbsp;{{ $t('dataview.gantt') }}</a></li>
            <li name="staff-usage" class="nav-pills nav-link" v-if="(isEntity('TASK') || isEntity('STAFF') || isEntity('DEPARTMENT') || isEntity('LOCATION') || isEntity('COMPANY') || isEntity('SKILL')) && showStaff && canView('STAFF')" @click="prepareStaff()" @click.stop="dataViewNavClick"><a href="#" target="_self"><font-awesome-icon :icon="['far', 'user-hard-hat']"/> {{ $t('dataview.staff_usage') }}</a></li>
            <li name="resource-usage" class="nav-pills nav-link" v-if="(isEntity('TASK') || isEntity('RESOURCE') || isEntity('COMPANY')) && showResource && canView('RESOURCE')" @click="prepareResource()" @click.stop="dataViewNavClick"><a href="#" target="_self"><font-awesome-icon :icon="['far', 'cart-flatbed-boxes']"/> {{ $t('dataview.resource_usage') }}</a></li>
            <li name="board" class="nav-pills nav-link" v-if="isEntity('TASK') && showBoard" @click="prepareBoard()" @click.stop="dataViewNavClick"><a href="#" target="_self"><font-awesome-icon :icon="['far', 'th-large']"/> {{ $t('dataview.board') }}</a></li>
            <li :name="`chart_${chart.id}`" class="nav-pills nav-link" :key="chart.id" v-for="chart in charts" @click.stop="dataViewNavClick">
              <a href="#" target="_self"><font-awesome-icon :icon="['far', 'chart-bar']"/>&nbsp;{{ chart.name }}</a>
            </li>
          </PriorityNavigation>
        </nav>
        <!-- The v-if duplicates each of the v-ifs in the buttons to decide if the dropdown shows or not. -->
        <div class="menu-toggler" v-if="(canView() && editPermission) || canAdd() || (canDelete() && editPermission) || (canEdit() && dataview !== null && dataview.editingPermissions === '') || (canEdit() && editPermission) || (canEdit() && editPermission)">
          <b-dropdown id="ddown-offset" variant="link" offset="25" no-caret>
            <template slot="button-content">
              <div class="text">
                <font-awesome-icon :icon="['far', 'ellipsis-vertical']"/>
              </div>
            </template>
        
            <template>
              <b-dropdown-item v-if="canView() && editPermission" @click="editDataview(dataview)">{{ $t('dataview.edit') }}</b-dropdown-item>
              <b-dropdown-item v-if="canAdd()" @click="copyDataview(dataview)">{{ $t('dataview.copy') }}</b-dropdown-item>
              <b-dropdown-item v-if="canView() && canResetDefault && !editPermission" @click="defaultSettings">{{ $t('dataview.reset_default') }}</b-dropdown-item>
              <b-dropdown-item v-if="canDelete() && editPermission" @click="removeDataview">{{ $t('dataview.delete') }}</b-dropdown-item>
              <b-dropdown-item v-if="canEdit() && editPermission" :download="dataview ? `${dataview.name}.json` : 'dataview.json'" :href="exportJson">{{ $t('dataview.export') }}</b-dropdown-item>
              <b-dropdown-item v-if="canEdit() && dataview !== null && dataview.editingPermissions === ''" @click="takeOwnership(dataview)">{{ $t('dataview.assign_edit_permissions') }}</b-dropdown-item>
              <b-dropdown-divider v-if="canEdit() && editPermission"></b-dropdown-divider>
              <b-dropdown-item v-if="canEdit() && editPermission" @click="createChart()">{{ $t('dataview.create_chart') }}</b-dropdown-item>
              <b-dropdown-item v-if="!editPermission">
                <span id="dataview-owner" @[ownerMouseEnterEvent]="onOwnerOver" @mouseleave="onOwnerLeave">{{ $t('dataview.owner') }}</span>
                    
                <b-popover
                  target="dataview-owner"
                  placement="top"
                  custom-class="popover-zindex-owner"
                  boundary="viewport"
                  triggers="hover"
                  :show.sync="showOwner"
                  :content="owner">
                </b-popover>
              </b-dropdown-item>
            </template>
          </b-dropdown>
          <b-popover
            target="ddown-offset"
            placement="top"
            boundary="viewport"
            triggers="hover"
            :content="$t('dataview.more')">
          </b-popover>
        </div>
      </div>
      
      <div class="existence-error" v-if="showExistenceError">
        {{ $t('dataview.error.existence') }}
      </div>
      <b-tabs v-else-if="id !== null && !showPermissionsError"
        :class="!isWidget ? 'tab-container' : 'widget-container'"
        v-model="activeTab" 
        active-nav-item-class="active"
        content-class="pt-1 w-100 dataview-tab-content" pills>
        <b-tab v-if="!isWidget || dataviewComponent === 'sheet'" :title="$t('dataview.sheet')">
          <template #title>
            <font-awesome-icon :icon="['far', 'table-list']"/> {{ $t('dataview.sheet') }}
          </template>
          <div class="flex-container" :style="{ height: sheetHeight }">
            <div class="grid-toolbar dataview-toolbar border" v-if="allowManage && !isWidget">
              <PriorityNavigation v-if="datesMode !== 'current'" class="d-flex time-toolbar border-bottom">
                <li>
                  <span v-if="!epoch && startDate && endDate" class="d-flex align-items-center ml-2 mr-2">
                    <span class="mr-1">{{ $t('dataview.chart.data') }}</span>
                    <multiselect v-model="timeseries_field" class="custom-dropdown-options dataview-bar enable-option-icon fit-content-fix"
                      :max-height="300"
                      :options="fieldOptions.map(i => i.value)"
                      :custom-label="getTimeseriesOptionLabel"
                      :placeholder="''"
                      :searchable="false" 
                      :allow-empty="false"
                      :showLabels="false"
                      :option-height="25"
                      @input="fieldSelected">
                      <template slot="option" slot-scope="props">
                        <font-awesome-icon class="selected-option-icon" v-if="timeseries_field == props.option" :icon="['far', 'check']" />
                        <span class="option__title">{{ getTimeseriesOptionLabel(props.option) }}</span>
                      </template>
                    </multiselect>
                  </span>
                </li>
                <li>
                  <span v-if="!epoch && startDate && endDate" readonly class="action-v-divider">|</span>
                </li>
                <li>
                  <span v-if="epoch && startDate && endDate" class="d-flex align-items-center ml-2 mr-2">
                  <label class="mr-1">{{ spanPrefix }}</label>
                    <span :id="`BTN_LEFT_EPOCH_${id}`">
                      <b-btn :disabled="loading || disableEpochLeft" @click="leftEpoch"><font-awesome-icon :icon="['far','chevron-circle-left']"/></b-btn>
                      <b-popover
                        :target="`BTN_LEFT_EPOCH_${id}`"
                        placement="top"
                        boundary="viewport"
                        triggers="hover"
                        :content="$t('dataview.previous')">
                      </b-popover>
                    </span>
                    <multiselect v-model="epoch" class="custom-dropdown-options dataview-bar enable-option-icon fit-content-fix"
                      :max-height="300"
                      :options="epochOptions.map(i => i.value)"
                      :custom-label="getEpochOptionLabel"
                      :placeholder="''"
                      :searchable="false" 
                      :allow-empty="false"
                      :showLabels="false"
                      :option-height="25"
                      @input="epochSelected">
                      <template slot="option" slot-scope="props">
                        <font-awesome-icon class="selected-option-icon" v-if="epoch == props.option" :icon="['far', 'check']" />
                        <span class="option__title">{{ getEpochOptionLabel(props.option) }}</span>
                      </template>
                    </multiselect>
                    <span :id="`BTN_RIGHT_EPOCH_${id}`">
                      <b-btn :disabled="loading || disableEpochRight" @click="rightEpoch"><font-awesome-icon :icon="['far','chevron-circle-right']"/></b-btn>
                      <b-popover
                      :target="`BTN_RIGHT_EPOCH_${id}`"
                        placement="top"
                        boundary="viewport"
                        triggers="hover"
                      :content="$t('dataview.next')">
                      </b-popover>
                    </span>
                  </span>
                </li>
                <li>
                  <span v-if="epoch && startDate && endDate" readonly class="action-v-divider">|</span>
                </li>
                <li>
                  <span class="d-flex align-items-center ml-2 mr-2">
                    <label class="mr-1" for="dates">{{ $t('staff.dates') }}</label>
                    <multiselect v-model="datesStr" class="custom-dropdown-options dataview-bar enable-option-icon fit-content-fix"
                      :max-height="300"
                      :options="filteredDateOptions.map(i => i.value)"
                      :custom-label="getDateOptionLabel"
                      :placeholder="''"
                      :searchable="false" 
                      :allow-empty="false"
                      :showLabels="false"
                      :option-height="25"
                      @input="rangeSelected">
                      <template slot="option" slot-scope="props">
                        <font-awesome-icon class="selected-option-icon" v-if="datesStr == props.option" :icon="['far', 'check']" />
                        <span class="option__title">{{ getDateOptionLabel(props.option) }}</span>
                      </template>
                    </multiselect>
                  </span>
                </li>
                <li>
                  <span class="d-flex align-items-center mr-1 date">
                    <label class="mr-1" for="startDate">{{ $t('staff.from') }}</label>
                    <b-form-datepicker id="startDate" v-model="startDate" class="date-picker d-flex" @input="dateChanged()"
                        today-button
                        reset-button
                        close-button
                        hide-header
                        :label-today-button="$t('date.today')"
                        :label-reset-button="$t('date.reset')"
                        :label-close-button="$t('date.close')"
                        today-button-variant="primary"
                        reset-button-variant="danger" 
                        close-button-variant="secondary"
                        size="sm"
                        :max="maxDate"
                      ></b-form-datepicker>
                  </span>
                </li>
                <li>
                  <span class="d-flex align-items-center mr-1 date">
                    <label class="mr-1" for="endDate">{{ $t('staff.to') }}</label>
                    <b-form-datepicker id="endDate" v-model="endDate" class="date-picker d-flex" @input="dateChanged()"
                        today-button
                        reset-button
                        close-button
                        hide-header
                        :label-today-button="$t('date.today')"
                        :label-reset-button="$t('date.reset')"
                        :label-close-button="$t('date.close')"
                        today-button-variant="primary"
                        reset-button-variant="danger" 
                        close-button-variant="secondary"
                        size="sm"
                        :max="maxDate"
                      ></b-form-datepicker>
                  </span>
                </li>
                <li>
                  <b-btn :id="`BTN_REFRESH_${id}`" class="ml-1" @click="daySelected" :pressed.sync="highlightRefresh">
                    <font-awesome-icon :class="highlightRefresh ? 'active' : ''" :icon="['far', 'arrows-rotate']"/>
                    <b-popover
                      :target="`BTN_REFRESH_${id}`"
                      placement="top"
                      boundary="viewport"
                      triggers="hover"
                      :content="$t('button.refresh')">
                    </b-popover>
                  </b-btn>
                </li>
                <li>
                  <span class="d-flex align-items-center mr-1">
                    <label class="mr-1" for="timescale">{{ $t('staff.timescale') }}</label>
                    <multiselect v-model="span" class="custom-dropdown-options dataview-bar enable-option-icon fit-content-fix"
                      :max-height="300"
                      :options="spanOptions.map(i => i.value)"
                      :custom-label="getSpanOptionLabel"
                      :placeholder="''"
                      :searchable="false" 
                      :allow-empty="false"
                      :showLabels="false"
                      :option-height="25"
                      @input="updateSpan">
                      <template slot="option" slot-scope="props">
                        <font-awesome-icon class="selected-option-icon" v-if="span == props.option" :icon="['far', 'check']" />
                        <span class="option__title">{{ getSpanOptionLabel(props.option) }}</span>
                      </template>
                    </multiselect>
                  </span>
                </li>
                <li>
                  <span :id="`BTN_USE_EPOCH_${id}`">
                    <b-btn :disabled="loading" @click="toggleEpoch" :style="useEpoch ? 'color: #E77615' : ''"><font-awesome-icon :icon="['far','history']"/></b-btn>
                    <b-popover
                    :target="`BTN_USE_EPOCH_${id}`"
                      placement="top"
                      boundary="viewport"
                      triggers="hover"
                    :content="useEpoch ? $t('dataview.timemachine_on') : $t('dataview.timemachine_off')">
                    </b-popover>
                  </span>
                </li>
              </PriorityNavigation>
              <div class="second-row-toolbar">
                <div class="d-inline-block">
                  <b-form-checkbox :disabled="loading" :id="`BTN_SELECT_${id}`" class="secondary-checkbox select-state" v-model="select_state.checked" :indeterminate="select_state.indeterminate" @change="selectionChanged"></b-form-checkbox>
                  <b-popover :target="`BTN_SELECT_${id}`" triggers="hover" placement="top" boundary="viewport">
                    {{ $t('button.select') }}
                  </b-popover>
                </div>
                <span v-if="canAdd(entityName) && !(entityName == 'TASK' && !canList('PROJECT')) && entityName !== 'NOTE'">
                  <b-popover
                    :target="`BTN_ADD_${id}`"
                    placement="top"
                    boundary="viewport"
                    triggers="hover"
                    :content="$t('button.add')">
                  </b-popover>
                  <b-btn :disabled="loading" :id="`BTN_ADD_${id}`" @click="editOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
                </span>
                <span v-if="canView(entityName)">
                  <b-popover
                    :target="`BTN_EDIT_${id}`"
                    placement="top"
                    boundary="viewport"
                    triggers="hover"
                    :content="$t('button.edit')">
                  </b-popover>
                  <b-btn :disabled="disableEdit || loading" :id="`BTN_EDIT_${id}`" @click="editOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
                </span>
                <span v-if="canDelete(entityName)">
                  <b-popover
                    :target="`BTN_DELETE_${id}`"
                    placement="top"
                    boundary="viewport"
                    triggers="hover"
                    :content="$t('button.delete')">
                  </b-popover>
                  <b-btn :disabled="disableDelete || loading" :id="`BTN_DELETE_${id}`" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
                </span>
                <span readonly class="action-v-divider">|</span>
                <span>
                  <b-popover
                    :target="`BTN_EXPORT_DOCUMENT_${id}`"
                    placement="top"
                    boundary="viewport"
                    triggers="hover"
                    :content="$t('task.button.export_document')">
                  </b-popover>
                  <b-btn :disabled="loading" :id="`BTN_EXPORT_DOCUMENT_${id}`" @click="fileExport"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
                </span>
                <span v-if="isEntity('TASK')">
                  <b-popover
                    :target="`BTN_AUTO_ASSIGN_STAFF_${id}`"
                    placement="top"
                    boundary="viewport"
                    triggers="hover"
                    :content="$t('task.button.auto_assign_staff')">
                  </b-popover>
                  <b-btn :disabled="autoAssignTasks().length === 0 || inProgressShow" :id="`BTN_AUTO_ASSIGN_STAFF_${id}`" @click="autoAssignStaff"><font-awesome-icon :icon="['far', 'user-helmet-safety']"/></b-btn>
                </span>
                <span @[timeModeMouseEnterEvent]="onTimeModeOver" @mouseleave="onTimeModeLeave">
                  <b-dropdown :id="`BTN_TIME_MODE_${id}`" ref="timeMode" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                      <font-awesome-icon :icon="['far', 'analytics']"/>
                    </template>
                    <b-dropdown-group :header="$t('dataview.date_settings')">
                      <b-dropdown-item @click="onCurrent" href="#">
                        <span class="action-item-label">{{ $t('dataview.current') }}</span><font-awesome-icon class="active-check" v-if="datesMode === 'current'" :icon="['far', 'check']"/>
                      </b-dropdown-item>
                      <b-dropdown-item @click="onActuals" href="#">
                        <span class="action-item-label">{{ $t('dataview.actuals') }}</span><font-awesome-icon class="active-check" v-if="datesMode === 'actuals'" :icon="['far', 'check']"/>
                      </b-dropdown-item>
                      <b-dropdown-item @click="onTimeSeries" href="#">
                        <span class="action-item-label">{{ $t('dataview.timeseries') }}</span><font-awesome-icon class="active-check" v-if="datesMode === 'timeseries'" :icon="['far', 'check']"/>
                      </b-dropdown-item>
                    </b-dropdown-group>
                  </b-dropdown>
                </span>
              </div>
            </div>
            <div v-if="dataviewLive !== null && dataviewLive.query" class="live-edit-toolbar">
              <template v-for="(item, itemIndex) in dataviewLive.query.children" class="d-flex mt-2">
                <div v-if="item.allowEditing" v-bind:key="itemIndex" class="d-flex align-items-center m-1">
                  <div class="d-flex rule-part">{{ item.displayName ? item.displayName : item.field !== null && item.field !== '' ? formatField(item) : $t('dataview.placeholder.field') }}</div>
                  <div class="d-flex ml-2 mr-2 rule-part">{{ $t(`dataview.operator.${item.operator}`) }}</div>
                  <FilterInput v-model="dataviewLive.query.children[itemIndex]" :schema="schema" :macros="macros" @enterKey="recalculate"/>
                  <b-btn :disabled="checkQuery" :id="`BTN_RECALCULATE_${id}_${itemIndex}`" class="ml-1" @click="recalculate">
                    <font-awesome-icon :icon="['far', 'arrows-rotate']"/>
                    <b-popover
                      :target="`BTN_RECALCULATE_${id}_${itemIndex}`"
                      placement="top"
                      boundary="viewport"
                      triggers="hover"
                      :content="$t('dataview.recalculate')">
                    </b-popover>
                  </b-btn>
                </div>
              </template>
            </div>
            <div style="flex: 1">
              <ag-grid-vue v-if="dataview" :style="isWidget ? `height: ${height}px;width: 100%;` : 'width: 100%;'" class="ag-theme-balham" :class="isWidget ? '' : 'dataview-grid-height'" id="dataview-grid"
                  :gridOptions="gridOptions"
                  @grid-ready="onGridReady"
                  :columnDefs="columnDefs"
                  :context="context"
                  :defaultColDef="defaultColDef"
                  pagination
                  :paginationPageSize="1000"
                  :paginationPageSizeSelector="false"
                  :getRowId="params => params.data.index"
                  suppressContextMenu
                  rowSelection="multiple"
                  rowMultiSelectWithClick
                  :overlayNoRowsTemplate="overlayNoRowsTemplate"
                  :serverSideInfiniteScroll="true"
                  :sideBar="false"
                  suppressDragLeaveHidesColumns
                  suppressCellFocus
                  :suppressMultiSort="false"
                  :tooltipShowDelay="0"
                  :cacheBlockSize="1000"
                  >
              </ag-grid-vue>
              
            </div>
          </div>
        </b-tab>
        <b-tab v-if="isEntity('TASK') && showGantt && (!isWidget || dataviewComponent === 'gantt')" :title="$t('dataview.gantt')" lazy>
          <template #title>
            <font-awesome-icon :icon="['far', 'bars-staggered']"/> {{ $t('dataview.gantt') }}
          </template>
          <Gantt :isDataView="true" :isWidget="isWidget" :dataviewId="dataviewId" :widgetOwner="widgetOwner" :height="ganttHeight" :heightOffset="2" :style="{ marginBottom: '8px' }" @gridGanttError="onGanttError" @ganttMsg="onGanttMsg" @ganttResetAlert="onGanttResetAlert" :taskIds="taskIds" :loading="loading"/>
        </b-tab>
        <b-tab v-if="(isEntity('TASK') || isEntity('STAFF') || isEntity('DEPARTMENT') || isEntity('LOCATION') || isEntity('COMPANY') || isEntity('SKILL')) && showStaff && (!isWidget || dataviewComponent === 'staff_usage')" :title="$t('dataview.staff_usage')" lazy>
          <template #title>
            <font-awesome-icon :icon="['far', 'user-helmet-safety']"/> {{ $t('dataview.staff_usage') }}
          </template>
          <PlannerStaff :isWidget="isWidget" :widgetOwner="widgetOwner" :height="height" :dataviewId="dataviewId" :projectId="projectId" :staffIds="staffIds" :heightOffset="235" :heightOffset2="285"/>
        </b-tab>
        <b-tab v-if="(isEntity('TASK') || isEntity('RESOURCE')) && showResource && (!isWidget || dataviewComponent === 'resource_usage')" :title="$t('dataview.staff_usage')" lazy>
          <template #title>
            <font-awesome-icon :icon="['far', 'cart-flatbed-boxes']"/> {{ $t('dataview.resource_usage') }}
          </template>
          <PlannerResource :isWidget="isWidget" :widgetOwner="widgetOwner" :height="height" :dataviewId="dataviewId" :projectId="projectId" :resourceIds="resourceIds" :heightOffset="235" :heightOffset2="285"/>
        </b-tab>
        <b-tab v-if="isEntity('TASK') && showBoard && (!isWidget || dataviewComponent === 'board')" :title="$t('dataview.board')" lazy>
          <template #title>
            <font-awesome-icon :icon="['far', 'table-cells-large']"/> {{ $t('dataview.board') }}
          </template>
          <KanbanBoard :isWidget="isWidget" :height="kanbanHeight" :taskIds="taskIds" :dataview="dataview" :heightOffset="270"  :idsLoading="loading" @profileChanged="onKanbanProfileChanged" style="padding-bottom: 8px"/>
        </b-tab>
        <b-tab :key="chart.id" v-for="(chart, index) in chartList" :title="chart.name" lazy>
          <template #title>
            <font-awesome-icon :icon="['far', 'chart-bar']"/> {{ chart.name }}
          </template>
          
          <div class="overflow-auto">
            <Chart 
            :schema="schema" 
            :chart="chart" 
            :dataview="dataview"
            :allowManage="allowManage"
            :editPermission="editPermission"
            :entityName="entityName"
            :isWidget="isWidget" 
            :height="height" 
            :width="width" 
            @editChart="editChart(index)"
            @removeChart="removeChart(index)"
            @chartExport="chartExport(`${dataview.name} - ${chart.name}`)"
            @updateChart="updateChart"
            @error="onChartError">
          </Chart>
          </div>
        </b-tab>
      </b-tabs>
          
      <div class="permission-error" v-else>
        {{ $t('dataview.error.permission') }}
      </div>
    </div>
    <DataviewModal v-if="addShow" :show.sync="addShow" :userId="userId" :folders="folders" :data="data" :isPublic="isPublic" @success="filterCreated"/>

    
    <InProgressModal v-if="inProgressShow" :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>
    
    <!-- project selector -->
    <GenericSelectorModalForAdmin v-if="projectSelectorShow"
      :show.sync="projectSelectorShow" 
      :entityService="projectUtil" 
      entity="PROJECT"
      nonAdmin
      @ok="projectSelectorOk"
    />
    
    <!-- task template selector -->
    <GenericSelectorModalForAdmin v-if="projectTemplateSelectorShow"
      :show.sync="projectTemplateSelectorShow" 
      :entityService="templateTaskUtil"
      entity="TEMPLATE__PROJECT"
      nonAdmin
      @ok="projectTemplateSelectorOk"
    />
    <ActivityModal :show.sync="activityEditShow" :mode="activityEdit.mode" @success="modalSuccess" :id.sync="activityEdit.uuId" />
    <BookingModal :show.sync="bookingEditShow" :mode="bookingEdit.mode" @success="modalSuccess" :id.sync="bookingEdit.uuId" />
    
    <TaskModal :show.sync="taskEditShow" :mode="mode" @success="modalSuccess" :id.sync="taskEdit.uuId" 
      :projectId="taskProjectId" :projectName="projectName" :parentId="taskEdit.parentId" />
    <TaskModal v-if="taskTemplateEdit.uuId" :show.sync="taskTemplateEditShow" :mode="mode" @success="modalSuccess" :id.sync="taskTemplateEdit.uuId" 
      :projectId="taskProjectId" :projectName="projectName" :parentId="taskTemplateEdit.parentId" isTemplate />
    <ProjectModal v-if="projectShow" :id="projectId" :show.sync="projectShow" @success="modalSuccess" :title="projectTitle"/> 
    <TaskTemplateModal v-if="projectTemplateShow" :id="projectTemplateId" :show.sync="projectTemplateShow" @success="modalSuccess" :title="templateTitle"></TaskTemplateModal>
    <CustomerModal v-if="customerShow" :id="customerId" :show.sync="customerShow" @success="modalSuccess" :title="customerTitle"/>
    <ContactModal v-if="contactShow" :id="contactId" :holderId="contactParentId" :show.sync="contactShow" @success="modalSuccess" :title="contactTitle"/>
    <!-- customer selector -->
    <GenericSelectorModalForAdmin v-if="customerSelectorShow"
      :show.sync="customerSelectorShow" 
      :entityService="customerUtil"
      entity="CUSTOMER"
      nonAdmin
      @ok="customerSelectorOk"
    />
    <DepartmentModal v-if="departmentShow" :id="departmentId" :queryParent="true" :parentData="selectedParent" :show.sync="departmentShow" @success="modalSuccess" :title="departmentTitle"/>
    <LocationModal v-if="locationShow" :id="locationId" :show.sync="locationShow" @success="modalSuccess" :title="locationTitle"/>
    <ResourceModal v-if="resourceShow" :id="resourceId" :show.sync="resourceShow" @success="modalSuccess" :title="resourceTitle"/>
    <SkillModal v-if="skillShow" :id="skillId" :show.sync="skillShow" @success="modalSuccess" :title="skillTitle"/>
    <StaffModal v-if="staffShow" :id="staffId" :show.sync="staffShow" :isGeneric="isGeneric" @success="modalSuccess" :title="staffTitle"/>
    <UserModal v-if="userShow" :id="userModalId" :show.sync="userShow" @success="modalSuccess" :title="userTitle"/>
    <StageModal v-if="stageShow" :id="stageId" :show.sync="stageShow" @success="modalSuccess" :title="stageTitle"/>
    <FileDetailsModal :id="fileDetailsId" :parentData="fileParentData" :show.sync="fileDetailsShow" @success="modalSuccess" :mode="mode"/>
    <ChartModal :show.sync="promptChartOptions" :data="chart" :schema="schema" :macros="macros" :fields="fields" @success="chartModalSuccess" :title="chartTitle"/>
    <CompanyModal v-if="allowManage && companyShow" :id="companyId" :masterCompany="masterCompany" :parentData="companyId !== null && companyId.indexOf('COMPANY_NEW_') === 0 ? masterCompany : null" :show.sync="companyShow" @success="modalSuccess" :title="companyTitle"/>
    <CommentModal :id="commentId" :show.sync="modalCommentShow" @success="modalSuccess"/>
    <RebateModal :id="rebateId" :show.sync="modalRebateShow" @success="modalSuccess"/>
    <!-- staff selector -->
    <StaffSelectorModalForAdmin v-if="showStaffSelector"
      :show.sync="showStaffSelector" 
      :staffListUuIds="staffUuIds"
      nonAdmin
      hideOkBtn
    />
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable" @cancel="progressCancel"/>

    <b-modal :title="$t('task.auto_assign.summary')"
        v-model="autoAssignSummaryShow"
        @ok="autoAssignSummaryOk"
        :ok-title="$t('button.close')"
        ok-only
        content-class="shadow"
        no-close-on-backdrop
        >
      <ul class="task-summary-list" v-if="autoAssignSummary.length > 0">
        <li class="d-block task-summary-list-item" v-for="(item,index) in autoAssignSummary" :key="index">
          <div>
            <div class="task-summary-title">{{ item.taskName }}</div>
            <template v-if="item.staffAssignmentList.length > 0">
              <div  class="d-block" v-for="(staff, sIndex) in item.staffAssignmentList" :key="sIndex">
                {{ $t('task.auto_assign.assigned', [`${staff.firstName} ${staff.lastName}`]) }}
              </div>
            </template>
            <div v-if="item.staffAssignmentList.length === 0" class="d-block">
              {{ $t('task.auto_assign.none_assigned') }}
            </div>
          </div>
        </li>
      </ul>
      <div v-if="autoAssignSummary.length === 0" class="d-block">
        {{ $t('task.auto_assign.none_assigned') }}
      </div>
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" variant="danger" @click="ok()">{{ $t('button.close') }}</b-button>
      </template>
    </b-modal>
    
    <AutoAssignStaffModal :tasks="autoAssignTasks()" :show.sync="autoAssignStaffShow" @success="autoAssignStaffSuccess" />
    
    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        :ok-title="$t('button.confirm')"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="confirmDeleteOk"
        >
      <div class="d-block">
        {{ deleteMessage }}
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmDeleteOk">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteDataviewShow"
        :ok-title="$t('button.confirm')"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="confirmDeleteDataviewOk"
        >
      <div class="d-block">
        {{ $t('dataview.confirmation.delete') }}
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmDeleteDataviewOk">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteChartShow"
        :ok-title="$t('button.confirm')"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="confirmDeleteChartOk"
        >
      <div class="d-block">
        {{ $t('dataview.confirmation.delete_chart') }}
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmDeleteChartOk">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('booking.confirmation.title_type')"
        v-model="confirmBookingTypeShow"
        :ok-title="$t('button.confirm')"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="confirmBookingTypeOk"
        >
      <b-form-group>
        <b-form-radio v-model="bookingEdit.mode" name="booking staff" value="staff">{{ $t('staff.title_selector') }}</b-form-radio>
        <b-form-radio v-model="bookingEdit.mode" name="booking resource" value="resource">{{ $t('booking.type.resource') }}</b-form-radio>
      </b-form-group>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmBookingTypeOk">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('activity.confirmation.title_type')"
        v-model="confirmActivityTypeShow"
        :ok-title="$t('button.confirm')"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="confirmActivityTypeOk"
        >
      <b-form-group>
        <b-form-radio v-model="activityEdit.mode" name="booking staff" value="staff">{{ $t('staff.title_selector') }}</b-form-radio>
        <b-form-radio v-model="activityEdit.mode" name="booking resource" value="resource">{{ $t('activity.type.resource') }}</b-form-radio>
      </b-form-group>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmActivityTypeOk">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('dataview.timeseries_hint_title')"
        v-model="timeseriesHintShow"
        :ok-title="$t('button.confirm')"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        >
      <div class="d-block">
        {{ $t('dataview.timeseries_hint') }}
      </div>
      
      <img class="timeseries-hint-img" src="/img/help/timeseries.png"/>
      
      <template v-slot:modal-footer="{ }">
        <b-form-checkbox class="hint-checkbox secondary-checkbox" v-model="timeseries_hint_donotshow">{{ $t('dataview.donotshow')}}</b-form-checkbox>
        <b-button size="sm" variant="danger" @click="closeHint()">{{ $t('button.close') }}</b-button>
      </template>
    </b-modal>
    
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);
import alertStateEnum from '@/enums/alert-state';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { strRandom, costFormat, costFormatAdv, getNextWorkingDay, 
        formatDate, getSpanDate, formatField, prepareQuery, replacePlaceholders } from '@/helpers';
import { convertDurationToDisplay, extractDurationConversionOpts } from '@/helpers/task-duration-process';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost';
import GenericCellRenderer from '@/components/Aggrid/CellRenderer/Generic'
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import TimeCellRenderer from '@/components/Aggrid/CellRenderer/Time';
import DurationCellRenderer from '@/components/Aggrid/CellRenderer/Duration';
import MinuteDurationCellRenderer from '@/components/Aggrid/CellRenderer/MinuteDuration';
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage';
import PayFrequencyCellRenderer from '@/components/Aggrid/CellRenderer/PayFrequency';
import ListCellRenderer from '@/components/Aggrid/CellRenderer/List';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';
import PriorityCellRenderer from '@/components/Aggrid/CellRenderer/Priority';
import TaskConstraintCellRenderer from '@/components/Aggrid/CellRenderer/TaskConstraint';
import TaskResourceCellRenderer from '@/components/Aggrid/CellRenderer/TaskResource';
import TaskSkillCellRenderer from '@/components/Aggrid/CellRenderer/TaskSkill';
import TaskStaffCellRenderer from '@/components/Aggrid/CellRenderer/TaskStaff';
import TaskTypeCellRenderer from '@/components/Aggrid/CellRenderer/TaskType';
import StaffTypeCellRenderer from '@/components/Aggrid/CellRenderer/StaffType';
import DateHeaderComponent from '@/components/Aggrid/CellHeader/Date';
import ListHeaderComponent from '@/components/Aggrid/CellHeader/List';
import { activityService, bookingService, dataviewProfileService, userService, queryService, 
         companyService, taskService, projectService, customerService, departmentService,
         locationService, resourceService, skillService, staffService, templateProjectService,
         stageService, fileService, layoutProfileService, templateTaskService,
         contactService, noteService, compositeService, profileService } from '@/services';
import { EventBus, transformField, buildFilter, getFieldType, getDefaultValue, formatFieldValue, calcEpochs, createBody, getSum, getMin, getMax, getAvg } from '@/helpers';
import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation';
import { projectUtil } from '@/views/management/script/project';
import { customerUtil } from '@/views/management/script/customer';
import { templateTaskUtil } from '@/views/management/script/taskTemplate';
//Project Pages
import Gantt from '@/components/Gantt/PagedAgGridGantt';
import PlannerStaff from '@/views/planner/PlannerStaff';
import PlannerResource from '@/views/planner/PlannerResource';
import Multiselect from 'vue-multiselect';

function httpAjaxError(e, self) {
  const response = e.response;
  let alertMsg = self.$t('error.internal_server');
  if (response && 403 === response.status) {
    alertMsg = self.$t('error.authorize_action');
  } else if (typeof response !== 'undefined') {
    const feedback = response.data[response.data.jobCase] ? response.data[response.data.jobCase][0] : '';
    if(feedback.args) {
      const clue = feedback.clue.toLowerCase();
      if (self.$te(`error.${clue}`)) {
        alertMsg = self.$t(`error.${clue}`, feedback.args);     
      }
    }
  } else {
    alertMsg = e.message;
  }
  self.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
}

function toKey(val) {
  if (typeof val === "string") {
    return val.replace(/\./g, ' ');
  }
  if (val[0] === '=fullPath(A)' ||
      val[0] === '=timeStamp(A)') {
    return `${val[1][0].substr(0, val[1][0].length - 5)}.${val[0]}`.replace(/\./g, ' ');
  }
  else if (val.length === 4 && val[3]) {
    // special handling for CostNet values
    return `${val[1][0]}.${val[0].replace('Cost', 'CostNet')}`.replace(/\./g, ' ');
  }
  
  return `${val[1][0]}.${val[0]}`.replace(/\./g, ' ');
}

function processData(data, keys, hiddenFieldNames, hiddenFields) {
    let index = 0;
  return data.map(i => {
      const result = {}
      for (let j = 0, len = i.length; j < len; j++) {
        const field = typeof keys[j] === 'string' ? keys[j].replace(/\./g, ' ') : toKey(keys[j][0]);

        if (typeof i[j] === 'string' ||
            typeof i[j] === 'number') {
          if (typeof i[j] === 'string' && i[j].indexOf('\n') !== -1) {
            result[field] = i[j].replace(/\n/g, ' / ');
          }
          else {
            result[field] = i[j];
          }
        }
        else if (i[j].constructor === Array &&
                 i[j].length > 0 &&
                 typeof i[j][0] === 'string') {
          if (field.indexOf('fullPath(A)') !== -1) {
            result[field] = i[j].join(' / ');
          }
          else {
            result[field] = i[j].join(', ');
          }
        }
        else {
          result[field] = i[j];
        }
        
        if (hiddenFields.includes(keys[j])) {
          const hiddenField = hiddenFieldNames[keys[j]];
          if (i[j].constructor === Array &&
                 i[j].length > 0) {
            result[hiddenField] = i[j][0];
          }
          else {
            result[hiddenField] = i[j];
          }
        }
      }
      result.index = index++;
      return result;
    });
}

function addDateColumns(self, allData) {
  const retdata = [];
    var labelValue = self.timeseries_label;
    const labelId = self.getFieldId(labelValue);
  let labelColumn = 
  {
    headerName: self.$t('dataview.value'),
    field: labelId,
    headerComponent: 'listHeaderComponent',
    headerComponentParams: { label: self.$t('dataview.chart.label'), options: self.fieldOptions, value: self.timeseries_label },
    cellRenderer: 'detailLinkCellRenderer',
    checkboxSelection: true,
    pinned: 'left',
    minWidth: 325,
    width: 325,
    hide: false,
    sortable: false,
    lockVisible: true,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    resizable: true,
    cellStyle: function(/*params*/) {
        
    }
  };
  const labelType = getFieldType(typeof labelValue === 'string' ? labelValue : labelValue.field, self.schema);
  labelColumn = self.getCellRenderer(labelColumn, labelValue, labelType, false);
      
  let columnDef = 
  {
    headerName: "Dates",
    marryChildren: true,
    suppressMovable: true,
    children: [] // populate dynamically
  };
  
  var group = 'date';
  if (self.span === "Monthly") {
    group = 'month';
  }
  else if (self.span === 'Yearly') {
    group = 'year';
  }
  
  let index = 0;
  const dateFields = [];
  for (const data of allData) {
    const d = moment(data.epoch);
    const header = getSpanDate(d, group);
    
    const field = header;
    let columnDefChild = {
      headerName: header,
      field: field,
      headerComponent: 'dateHeaderComponent',
      headerComponentParams: { epoch: data.epoch, tooltip: 'dataview.partial', nopartial: true },
      minWidth: 90,
      width: 90,
      hide: false,
      sortable: false,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      resizable: true,
      cellStyle: function(/*params*/) {
          
      }
    }
    var fieldValue = self.timeseries_field ? self.timeseries_field : null;
    if (fieldValue !== null) {
      const fieldId = self.getFieldId(fieldValue);
      const fieldType = getFieldType(typeof fieldValue === 'string' ? fieldValue : fieldValue.field, self.schema);
      columnDefChild = self.getCellRenderer(columnDefChild, fieldValue, fieldType, false);
       
      columnDef.children.push(columnDefChild);
      
      // keep track of all the dates we need data for
      dateFields.push(field);
      index++;
      
      for (let i = 0; i < data.data.length; i++) {
        if (retdata.length > i) {
          retdata[i][field] = data.data[i][fieldId];
          retdata[i][`${field}_staff`] = data.data[i][`${self.entity} STAFF uuId`];
          retdata[i][labelId] = data.data[i][labelId];
          retdata[i]['uuId'] = data.data[i]['uuId'];
          if (data.data[i]['PROJECTname']) {
            retdata[i]['PROJECTname'] = data.data[i]['PROJECTname'];
            retdata[i]['PROJECTuuId'] = data.data[i]['PROJECTuuId'];
          }
        }
        else {
          const val = {};
          val.index = index + i;
          val[field] = data.data[i][fieldId];
          val[`${field}_staff`] = data.data[i][`${self.entity} STAFF uuId`];
          val[labelId] = data.data[i][labelId];
          val['uuId'] = data.data[i]['uuId'];
          if (index !== 0) {
            // backfill the existing dates with 0
            //for (let idx = index - 1; idx >= 0; idx--) {
            //  val[dateFields[idx]] = 0;
            //}
          }
          retdata.push(val);
        }
      }
    }
  }
  self.columnDefs.splice(0, self.columnDefs.length, labelColumn);
  self.columnDefs.push(columnDef);
  if(self.gridApi && !self.gridApi.isDestroyed()) {
    self.gridApi.setGridOption('columnDefs', self.columnDefs);
  }
  return retdata;
}

function getKeys(self, keys, fields, hiddenFields, hiddenFieldNames) {
  // transform the macros
  for (var i = 0; i < self.fields.length; i++) {
    if (typeof self.fields[i] === 'string' && self.fields[i].includes('.=')) {
      let field = self.fields[i];
      keys.push(transformField(field, 'Macro'));
      // Transform the field to add project so that the values are in the correct order and not reversed
      if (field === "TASK.=fullPath(A)") {
        field = "TASK.PROJECT.=fullPath(A)";
      }
      fields.push(transformField(field, 'Macro'));
    }
    else if (typeof self.fields[i] === 'object') {
      const agField = self.fields[i].field.includes('.=') ? transformField(self.fields[i].field, 'Macro')[0] : self.fields[i].field;
      if (self.fields[i].agFunction === 'year' ||
          self.fields[i].agFunction === 'month' ||
          self.fields[i].agFunction === 'day' ||
          self.fields[i].agFunction === 'cos' ||
          self.fields[i].agFunction === 'sin' ||
          self.fields[i].agFunction === 'tan' ||
          self.fields[i].agFunction === 'length') {
        fields.push([agField, getDefaultValue(self.fields[i].agFunction), self.fields[i].agFunction]);
        
        // check for missing values in the select
        if (agField[0] === "=fullPath(A)" &&
            !self.fields.includes(agField[1][0])) {
          fields.push(agField[1][0]);
          keys.push(agField[1][0]);
          hiddenFields.push(agField[1][0]);
          hiddenFieldNames[agField[1][0]] = agField[1][0].split('.').join('');
        }
      }
      else {
        fields.push([agField, 0, getDefaultValue(self.fields[i].agFunction), self.fields[i].agFunction]);
      }
      keys.push(self.fields[i].name);
    }
    else {
      fields.push(self.fields[i]);
      keys.push(self.fields[i]);
    }
  }
  return { keys: keys, fields: fields, hiddenFields: hiddenFields, hiddenFieldNames: hiddenFieldNames };
}

function addPinnedRows(self, data, keys) {
  const firstFieldIndex = keys.length - self.fields.length;
  const nameKey = typeof keys[firstFieldIndex] === 'string' ? keys[firstFieldIndex].replace(/\./g, ' ') : toKey(keys[firstFieldIndex][0]);
  const topTotals = [];
  const bottomTotals = [];
  
  if (self.dataview === null) {
    return; // not ready yet
  }
  
  if (self.dataview.topSum ||
      self.dataview.bottomSum) {
    const sum = getSum(data, self);
    
    // only set the label if this is not a value
    if (typeof sum[nameKey] !== 'number') {
      sum[nameKey] = self.$t('dataview.total');
    }
    
    if (self.dataview.topSum) {
      topTotals.push(sum);
    }
    if (self.dataview.bottomSum) {
      bottomTotals.push(sum);
    }
  }
  if (self.dataview.topMin ||
      self.dataview.bottomMin) {
    const min = getMin(data, self);
    
    // only set the label if this is not a value
    if (typeof min[nameKey] !== 'number') {
      min[nameKey] = self.$t('dataview.min');
    }
    
    if (self.dataview.topMin) {
      topTotals.push(min);
    }
    if (self.dataview.bottomMin) {
      bottomTotals.push(min);
    }
  }
  if (self.dataview.topMax ||
      self.dataview.bottomMax) {
    const max = getMax(data, self);
    
    // only set the label if this is not a value
    if (typeof max[nameKey] !== 'number') {
      max[nameKey] = self.$t('dataview.max');
    }
    
    if (self.dataview.topMax) {
      topTotals.push(max);
    }
    if (self.dataview.bottomMax) {
      bottomTotals.push(max);
    }
  }
  if (self.dataview.topAvg ||
      self.dataview.bottomAvg) {
    const avg = getAvg(data, self);
    
    // only set the label if this is not a value
    if (typeof avg[nameKey] !== 'number') {
      avg[nameKey] = self.$t('dataview.average');
    }
    
    //Fixed #1416, hide the currency symbol for top and bottom totals
    if (self.dataview.topAvg) {
      topTotals.push(avg);
    }
    if (self.dataview.bottomAvg) {
      bottomTotals.push(avg);
    }
  }

  topTotals.forEach(i => {
    i.hideSymbol = true;
  })
  bottomTotals.forEach(i => {
    i.hideSymbol = true;
  })
  if (self.gridApi != null && !self.gridApi.isDestroyed()) {
    self.gridApi.setGridOption('pinnedTopRowData', topTotals);
    self.gridApi.setGridOption('pinnedBottomRowData', bottomTotals);
  }
}

async function loadData(params, self, ksort, order) {
  if (self.fields.length === 0) {
    params.success({ rowData: [], rowCount: 0 });
    self.gridApi.showNoRowsOverlay();
    return;
  }

  self.loading = true;
 
  let fields = [];
  let keys = [];
  let hiddenFields = [`${self.entity}.uuId`];
  let hiddenFieldNames = {};
  fields.push(`${self.entity}.uuId`);
  keys.push(`${self.entity}.uuId`);
  hiddenFieldNames[`${self.entity}.uuId`] = 'uuId';
  
  if (self.isEntity('TASK') ||
      self.isEntity('PROJECT')) {
    // for currency codes
    if (self.fields.findIndex(f => (typeof f === 'string' && f.indexOf('Cost') !== -1) || (typeof f === 'object' && f.name.indexOf('Cost') !== -1)) !== -1 &&
      !self.fields.includes(`${self.entity}.currencyCode`)) {
      fields.push(`${self.entity}.currencyCode`);
      keys.push(`${self.entity}.currencyCode`);
      hiddenFields.push(`${self.entity}.currencyCode`);
      hiddenFieldNames[`${self.entity}.currencyCode`] = 'currencyCode';
    }
  }
  
  if (self.fields.findIndex(f => (typeof f === 'object' && f.agFunction === 'count') && /^.+?\.STAFF\..+?$/.test(f.field)) !== -1) {
    const countIndex = self.fields.findIndex(f => (typeof f === 'object' && f.agFunction === 'count') && /^.+?\.STAFF\..+?$/.test(f.field));
    const countField = self.fields[countIndex].field;
    fields.push(`${countField.substr(0, countField.length - (countField.length - (countField.lastIndexOf('.') + 1)))}uuId`);
    keys.push(`${self.entity}.STAFF.uuId`);
    hiddenFields.push(`${self.entity}.STAFF.uuId`);
    hiddenFieldNames[`${self.entity}.STAFF.uuId`] = 'staffUuId';
  }
  
  if (self.isEntity('TASK')) {
    if (!self.fields.includes(`${self.entity}.name`)) {
      fields.push(`${self.entity}.name`);
      keys.push(`${self.entity}.name`);
    }
    hiddenFields.push(`${self.entity}.name`);
    hiddenFieldNames[`${self.entity}.name`] = 'taskName';
    if (!self.fields.includes(`${self.entity}.taskType`)) {
      fields.push(`${self.entity}.taskType`);
      keys.push(`${self.entity}.taskType`);
    }
    hiddenFields.push(`${self.entity}.taskType`);
    hiddenFieldNames[`${self.entity}.taskType`] = 'taskType';
    fields.push(`${self.entity}.PROJECT.uuId`);
    keys.push(`${self.entity}.PROJECT.uuId`);
    hiddenFields.push(`${self.entity}.PROJECT.uuId`);
    hiddenFieldNames[`${self.entity}.PROJECT.uuId`] = 'PROJECTuuId';
    if (!self.fields.includes(`${self.entity}.PROJECT.name`)) {
      fields.push(`${self.entity}.PROJECT.name`);
      keys.push(`${self.entity}.PROJECT.name`);
    }
    hiddenFields.push(`${self.entity}.PROJECT.name`);
    hiddenFieldNames[`${self.entity}.PROJECT.name`] = 'PROJECTname';
  }
  else if (self.isEntity('STAFF')) {
    if (self.fields.includes('STAFF.payAmount') &&
      !self.fields.includes('STAFF.payCurrency')) {
      fields.push(`${self.entity}.payCurrency`);
      keys.push(`${self.entity}.payCurrency`);
      hiddenFields.push(`${self.entity}.payCurrency`);
      hiddenFieldNames[`${self.entity}.payCurrency`] = 'payCurrency';
    }
    fields.push(`${self.entity}.genericStaff`);
    keys.push(`${self.entity}.genericStaff`);
    hiddenFields.push(`${self.entity}.genericStaff`);
    hiddenFieldNames[`${self.entity}.genericStaff`] = 'genericStaff';
  }
  else if (self.isEntity('TASK_TEMPLATE')) {
  
    fields.push(`${self.entity}.PROJECT_TEMPLATE.uuId`);
    keys.push(`${self.entity}.PROJECT_TEMPLATE.uuId`);
    hiddenFields.push(`${self.entity}.PROJECT_TEMPLATE.uuId`);
    hiddenFieldNames[`${self.entity}.PROJECT_TEMPLATE.uuId`] = 'PROJECT_TEMPLATEuuId';
    if (!self.fields.includes(`${self.entity}.PROJECT_TEMPLATE.name`)) {
      fields.push(`${self.entity}.PROJECT_TEMPLATE.name`);
      keys.push(`${self.entity}.PROJECT_TEMPLATE.name`);
    }
    hiddenFields.push(`${self.entity}.PROJECT_TEMPLATE.name`);
    hiddenFieldNames[`${self.entity}.PROJECT_TEMPLATE.name`] = 'PROJECT_TEMPLATEname';
  }
  
  if (self.entity.endsWith("STORAGE_FILE")) {
    fields.push("STORAGE_FILE.STORAGE_FOLDER.uuId");
    keys.push("STORAGE_FILE.STORAGE_FOLDER.uuId");
    hiddenFields.push("STORAGE_FILE.STORAGE_FOLDER.uuId");
    hiddenFieldNames['STORAGE_FILE.STORAGE_FOLDER.uuId'] = 'FOLDERuuId';
    fields.push("STORAGE_FILE.STORAGE_FOLDER.name");
    keys.push("STORAGE_FILE.STORAGE_FOLDER.name");
    hiddenFields.push("STORAGE_FILE.STORAGE_FOLDER.name");
    hiddenFieldNames['STORAGE_FILE.STORAGE_FOLDER.name'] = 'FOLDERname';
  }

  const costGroup = ['cost', 'payAmount', 'fixedCost', 'fixedCostNet', 'estimatedCost', 'estimatedCostNet'
    , 'actualCost', 'actualCostNet', 'totalFixedCost', 'totalFixedCostNet']
  for (const f of fields) {
    const index = f.lastIndexOf('.');
    if (index == -1) {
      continue;
    }

    const lastSegment = f.substring(index+1);
    if (lastSegment.trim().length == 0) {
      continue;
    }

    for (const c of costGroup) {
      if (lastSegment == c) {
        const prefix = f.substring(0, index);
        let currencyCode = 'currencyCode'
        if (c == 'payAmount') {
          currencyCode = 'payCurrency';
        }
        const curSelector = `${prefix}.${currencyCode}`;
        if (hiddenFields.includes(curSelector)) {
          continue;
        }
        keys.push(curSelector);
        hiddenFields.push(curSelector);
        hiddenFieldNames[curSelector] = curSelector.replaceAll('.', ' ');
      }
    }
  }

  const keysResult = getKeys(self, keys, fields, hiddenFields, hiddenFieldNames);
  keys = keysResult.keys;
  fields = keysResult.fields;
  hiddenFields = keysResult.hiddenFields;
  hiddenFieldNames = keysResult.hiddenFieldNames;

  var ksortType = null;
  var ksortAgFunc = null;
  if (ksort !== null) {
    if (ksort.includes('.=')) {
      ksort = transformField(ksort, 'Macro', 'sort');
    }
    else {
      if (Array.isArray(ksort)) {
        ksort = cloneDeep(ksort);
        for (var sortIdx = 0; sortIdx < ksort.length; sortIdx++) {
          if (typeof ksort[sortIdx] === 'object' && ksort[sortIdx].field.includes('.=')) {
            ksort[sortIdx].field = transformField(ksort[sortIdx].field, 'Macro', 'sort');
          }
          else if (typeof ksort[sortIdx] === 'string' && ksort[sortIdx].includes('.=')) {
            ksort[sortIdx] = transformField(ksort[sortIdx], 'Macro', 'sort');
          }
        }
      }
      ksortType = getFieldType(ksort, self.schema);
    }
  }
  
  const epochResult = calcEpochs(self.span, self.startDate, self.endDate);
  self.epochs = epochResult.epochs;
  self.epochOptions = epochResult.epochOptions;
  
  // we may find that the epoch selected from saved preferences is not in the epochs list because it is
  // showing a date range such as this week
  if (self.epoch && self.epochs.length !== 0 &&
      self.epochs.filter(e => e === self.epoch).length === 0) {
    // the epoch is not in the list, use the first one
    self.epoch = self.epochs[0];    
  }
  
  if (!self.epoch && self.epochs.length !== 0 &&
      self.datesMode !== 'current') {
    const allData = [];
    const cmdList = [];
    let index = 1;
    for (const epoch of self.epochs) {
      cmdList.push({
         "note":`${index}-query data for time series`
        ,"invoke" : self.useEpoch ? `/api/query/match?epoch=${epoch}` : "/api/query/match"
        ,"body"   :  createBody({       
           name: self.dataview.name,
            start: !self.exportData ? params.request.startRow : 0, 
            limit: !self.exportData ? params.request.endRow - params.request.startRow : -1, 
            ksort: ksort,
            ksortType: ksortType,
            ksortAgFunc: ksortAgFunc,
            order: order,
            epoch: self.useEpoch ? epoch : null
        }, self.processFieldsForGroup(fields), self.replaceNow(self.filter, epoch), { type: self.dataview.nominate === 'group-by' ? 'group' : self.nominate, field: self.dataview.nominate === 'group-by' ? self.dataview.groupfield : self.entity }, self.dedup)
      });
      index++;
    }
    
    const responseData = await compositeService.exec(cmdList, true)
    .then(response => {
      return response.data.feedbackList;
    })
    .catch(() => {
      return null;
    });
    
    if (responseData) {
      for (let idx = 0; idx < responseData.length; idx++) {
        const preparedData = processData(responseData[idx].fetch, keys, hiddenFieldNames, hiddenFields);
        const epoch = self.epochs[idx];
        
        if (allData.length === 0) {
          allData.push({ data: preparedData, epoch: epoch });
        }
        else {
          // We need to maintain the alignment of the rows so that if a value is not present in one dataset it
          // does not shift all the values up
          const data = [];
          for (const d of allData[allData.length - 1].data) {
            const index = preparedData.findIndex(od => od.uuId === d.uuId);
            if (index !== -1) {
              data.push(preparedData[index]);
              preparedData.splice(index, 1);
            }
            else {
              // the value does not exist, push an empty object
              const obj = { uuId: d.uuId };
              const field = typeof self.timeseries_label === 'string' ? self.timeseries_label.replace(/\./g, ' ') : toKey(self.timeseries_label[0]);
              obj[field] = d[field];
              data.push(obj);
            }
            
          }
          // push the rest of the data
          data.push(...preparedData);
          allData.push({ data: data, epoch: epoch, total: data.length });
        }
      }
    }    
    const dispData = addDateColumns(self, allData);
    if (dispData.length > 0) {
      params.success({ rowData: dispData, rowCount: dispData.length });
      addPinnedRows(self, dispData, keys);
      self.gridApi.hideOverlay();
      self.loading = false;
    }
    else {
      params.success({ rowData: [], rowCount: 0 });
      self.gridApi.showNoRowsOverlay();
      self.loading = false;
      return;
    }
  }
  else {
    const singleDataPoint = await queryService.query({       
        name: self.dataview.name,
        start: !self.exportData && !self.usingPinnedRows ? params.request.startRow : 0, 
        limit: !self.exportData && !self.usingPinnedRows ? params.request.endRow - params.request.startRow : -1, 
        ksort: ksort,
        epoch: self.epoch && self.useEpoch ? self.epoch : null,
        ksortType: ksortType,
        ksortAgFunc: ksortAgFunc,
        order: order
    }, self.processFieldsForGroup(fields), self.replaceNow(self.filter, self.epoch ? self.epoch : self.getToday()), { type: self.dataview.nominate === 'group-by' ? 'group' : self.nominate, field: self.dataview.nominate === 'group-by' ? self.dataview.groupfield : self.entity}, self.dedup, self.leftjoin)
    .then(response => {
      const data = response.data[response.data.jobCase];
      if (typeof data === 'undefined') {
        return null;
      }
      
      const preparedData = processData(data, keys, hiddenFieldNames, hiddenFields);
      return { data: preparedData, total: response.data.arg_total };
    })
    .catch(function(error) {
      console.error(error); // eslint-disable-line no-console
      httpAjaxError(error, self);
      return null;
    });

    if (singleDataPoint && singleDataPoint.data && singleDataPoint.total !== 0) {
      params.success({ rowData: singleDataPoint.data, rowCount: singleDataPoint.total });
      addPinnedRows(self, singleDataPoint.data, keys);
      self.gridApi.hideOverlay();
      self.loading = false;
    }
    else {
      params.success({ rowData: [], rowCount: 0 });
      self.gridApi.showNoRowsOverlay();
      self.loading = false;
    }
  }
}

function loadIds(self, entity) {

  const fields = [];
  fields.push(`${entity}.uuId`);
  self.staffIds = `${entity}.uuId`.endsWith('STAFF.uuId') ? [] : null;
  self.resourceIds = `${entity}.uuId`.endsWith('RESOURCE.uuId') ? [] : null;
  if (`${entity}.uuId`.endsWith('TASK.uuId')) {
    self.taskIds = `${entity}.uuId`.endsWith('TASK.uuId') ? [] : null;
  }
  self.projectId = null;
  self.projectTemplateId = null;
  if ((self.fields.includes('PROJECT.TASK.=fullPath(A)') ||
       self.fields.includes('PROJECT.TASK.=timeStamp(A)')) &&
      !(self.fields.includes('PROJECT.TASK.name'))) {
    fields.push('PROJECT.TASK.name');  
  }
  if ((self.fields.includes('TASK.PROJECT.=fullPath(A)') ||
       self.fields.includes('TASK.PROJECT.=timeStamp(A)')) &&
      !(self.fields.includes('TASK.PROJECT.name'))) {
    fields.push('TASK.PROJECT.name');  
  }
  
  self.loading = true;
    
  // transform the macros
  for (var i = 0; i < self.fields.length; i++) {
    if (typeof self.fields[i] === 'string' && self.fields[i].includes('.=')) {
      fields.push(transformField(self.fields[i], 'Macro'));
    }
    else if (typeof self.fields[i] === 'object') {
      const agField = self.fields[i].field.includes('.=') ? transformField(self.fields[i].field, 'Macro')[0] : self.fields[i].field;
      if (self.fields[i].agFunction === 'year' ||
          self.fields[i].agFunction === 'month' ||
          self.fields[i].agFunction === 'day' ||
          self.fields[i].agFunction === 'cos' ||
          self.fields[i].agFunction === 'sin' ||
          self.fields[i].agFunction === 'tan' ||
          self.fields[i].agFunction === 'length') {
        fields.push([agField, getDefaultValue(self.fields[i].agFunction), self.fields[i].agFunction]);
      }
      else {
        fields.push([agField, 0, getDefaultValue(self.fields[i].agFunction), self.fields[i].agFunction]);
      }
    }
    else {
      fields.push(self.fields[i]);
    }
  }
  
  let filter = self.filter;
  if (self.dataviewLive) {

    prepareQuery(self.dataviewLive.query, false);
    filter = buildFilter(self.dataviewLive.entity, self.dataviewLive.query);
    prepareQuery(self.dataviewLive.query, true);
  }
  
  if (self.entity === 'TASK' && !fields.includes('TASK.uuId')) {
    // make sure to get the uuId
    fields.push('TASK.uuId');    
  }
  
  queryService.query({ 
      name: self.dataview.name,
      start: 0, 
      limit: -1
  }, fields, self.filter, { type: self.nominate, field: self.entity }, self.dedup, self.leftjoin)
  .then(response => {
    const data = response.data[response.data.jobCase];
    if (typeof data === 'undefined') {
      return;
    }
    
    const keys = fields;
    const addedIds = {};
    data.map(i => {
      for (let j = 0, len = i.length; j < len; j++) {
        

        
        // uuId
        if (typeof keys[j] === 'string' && keys[j].endsWith("STAFF.uuId")) {
          if (self.staffIds !== null) {
            if (typeof i[j] === 'string') {
              const ids = self.staffIds.filter(s => s === i[j]);
              if (ids.length === 0) {
                self.staffIds.push(i[j]);
              }
            }
            else if (i[j].length > 0 && i[j][0] !== "") {
              for (var index = 0; index < i[j].length; index++) {
                const ids = self.staffIds.filter(s => s === i[j][index]);
                if (ids.length === 0) {
                  self.staffIds.push(i[j][index]);
                }
              }
            }
          }
        }
        else if (typeof keys[j] === 'string' && keys[j].endsWith("RESOURCE.uuId")) {
          if (self.resourceIds !== null) {
            if (typeof i[j] === 'string') {
              const ids = self.resourceIds.filter(s => s === i[j]);
              if (ids.length === 0) {
                self.resourceIds.push(i[j]);
              }
            }
            else if (i[j].length > 0 && i[j][0] !== "") {
              for (var indexR = 0; indexR < i[j].length; indexR++) {
                const ids = self.resourceIds.filter(s => s === i[j][indexR]);
                if (ids.length === 0) {
                  self.resourceIds.push(i[j][indexR]);
                }
              }
            }
          }
        }
        else if (typeof keys[j] === 'string' && keys[j].endsWith("TASK.uuId")) {
          if (typeof i[j] === 'string') {
            if (!(i[j] in addedIds)) {
              if (self.taskIds === null) {
                self.taskIds = [];
              }
              self.taskIds.push(i[j]);
              addedIds[i[j]] = true;
            }
          }
          else if (i[j].length > 0 && i[j][0] !== "") {
            for (var index2 = 0; index2 < i[j].length; index2++) {
              if (!(i[j][index2] in addedIds)) {
                self.taskIds.push(i[j][index2]);
                addedIds[i[j][index2]] = true;
              }
            }          
          }
        }
      }
    });
    self.loading = false;
  })
  .catch(function(error) {
    console.error(error); // eslint-disable-line no-console
    httpAjaxError(error, self);
    self.loading = false;
  });
}

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      const sortModel = params.request.sortModel;
      var ksort = null;
      var order = self.dataview !== null && typeof self.dataview.sortdirection !== 'undefined' ? self.dataview.sortdirection : 'incr';
      for(let i = 0, len = sortModel.length; i < len; i++) {
        ksort = sortModel[i].colId.replace(/ /g, '.');
        if (ksort === "TASK.PROJECT.=fullPath(A)" && self.entity === "TASK") {
          ksort = "TASK.=fullPath(A)";
        }
        
        if (ksort.indexOf('_1') !== -1) {
          ksort = ksort.substr(0, ksort.length - 2);
        }
        order = sortModel[i].sort === 'asc'? 'incr' : 'decr';
      }
      
      if (ksort === null) {
        ksort = self.sortfield;
      }
      loadData(params, self, ksort, order);
    }
  }
}

export default {
  name: 'Dataview',
  components: {
    'ag-grid-vue': AgGridVue,
    DataviewModal: () => import('@/components/modal/DataviewModal'),
    Gantt,
    PlannerStaff,
    PlannerResource,
    TaskModal: () => import('@/components/modal/TaskModal'),
    BookingModal: () => import('@/components/modal/BookingModal'),
    ActivityModal: () => import('@/components/modal/ActivityModal'),
    KanbanBoard: () => import('@/components/Kanban/Board'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),
    ProjectModal: () => import('@/components/modal/ProjectModal'),
    TaskTemplateModal: () => import ('@/components/modal/TaskTemplateModal'),
    CustomerModal: () => import('@/components/modal/CustomerModal'),
    CommentModal: () => import('@/components/modal/CommentModal'),
    RebateModal: () => import('@/components/modal/RebateModal'),
    ContactModal: () => import('@/components/modal/ContactModal'),
    DepartmentModal: () => import('@/components/modal/DepartmentModal.vue'),
    LocationModal: () => import('@/components/modal/LocationModal.vue'),
    ResourceModal: () => import('@/components/modal/ResourceModal.vue'),
    SkillModal: () => import('@/components/modal/SkillModal.vue'),
    StaffModal: () => import('@/components/modal/StaffModal.vue'),
    UserModal: () => import('@/components/modal/UserModal.vue'),
    StageModal: () => import('@/components/modal/StageModal.vue'),
    FileDetailsModal: () => import('@/components/modal/FileDetailsModal.vue'),
    CompanyModal: () => import('@/components/modal/CompanyModal.vue'),
    AutoAssignStaffModal: () => import('@/components/modal/AutoAssignStaffModal'),
    FilterInput: () => import('@/components/Filter/FilterInput'),
    Chart: () => import('@/components/Agcharts/Chart'),
    ChartModal: () => import('@/components/modal/ChartModal.vue'),
    AlertFeedback: () => import('@/components/AlertFeedback'),
    StaffSelectorModalForAdmin: () => import('@/components/modal/StaffSelectorModalForAdmin'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin'),
    PriorityNavigation,
    Multiselect,
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    costCellRenderer: CostCellRenderer,
    genericCellRenderer: GenericCellRenderer,
    detailLinkCellRenderer: DetailLinkCellRenderer,
    dateTimeCellRenderer: DateTimeCellRenderer,
    timeCellRenderer: TimeCellRenderer,
    durationCellRenderer: DurationCellRenderer,
    minuteDurationCellRenderer: MinuteDurationCellRenderer,
    payFrequencyCellRenderer: PayFrequencyCellRenderer,
    percentageCellRenderer: PercentageCellRenderer,
    priorityCellRenderer: PriorityCellRenderer,
    taskConstraintCellRenderer: TaskConstraintCellRenderer,
    taskResourceCellRenderer: TaskResourceCellRenderer,
    taskSkillCellRenderer: TaskSkillCellRenderer,
    taskStaffCellRenderer: TaskStaffCellRenderer,
    taskTypeCellRenderer: TaskTypeCellRenderer,
    listCellRenderer: ListCellRenderer,
    colorCellRenderer: ColorCellRenderer,
    staffTypeCellRenderer: StaffTypeCellRenderer,
    dateHeaderComponent: DateHeaderComponent,
    listHeaderComponent: ListHeaderComponent
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    mode: {
      type: String,
      default: 'BOTH'
    },
    isWidget: {
      type: Boolean,
      default: false
    },
    widgetOwner: {
      type: String,
      default: null
    },
    dataviewId: {
      type: String,
      default: null
    },
    dataviewPublic: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 300
    },
    width: {
      type: Number,
      default: 300
    },
    dataviewComponent: {
      type: String,
      default: 'sheet'
    }
  },
  data() {
    return {
      permissionName: 'DATAVIEW',
      modelInfo: null,
      activeTab: 0,
      id: null,
      loading: false,
      isPublic: false,
      dataview: null,
      dataviewLive: null, // a live edit copy of the data view
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,

      userId: null,
      dataviews: [],
      addShow: false,
      data: null,
      options: {},
      gridOptions: null,
      gridApi: null,
      autoGroupColumnDef: null,
      context: null,
      defaultColDef: null,
      
      selected: [],
      
      fields: [],
      fieldNames: [],
      filter: [],
      entity: null,
      dedup: false,
      schema: null,
      macros: null,
      
      // charts
      chartOptions: {},
      promptChartOptions: false,
      charts: [],
      chart: {
        id: null,
        labelKey: null,
        angleKey: null,
        xKey: null,
        yKeys: [null]
      },
      
      // staff usage
      projectTemplateId: null,
      projectName: null,
      staffIds: null,
      resourceIds: null,
      taskIds: null,
      isGeneric: false,
      
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      
      services: {
        'ACTIVITY': activityService,
        'BOOKING': bookingService,
        'TASK': taskService,
        'TASK_TEMPLATE': templateTaskService,
        'PROJECT': projectService,
        'PROJECT_TEMPLATE': templateProjectService,
        'CUSTOMER': customerService,
        'CONTACT': contactService,
        'DEPARTMENT': departmentService,
        'LOCATION': locationService,
        'RESOURCE': resourceService,
        'SKILL': skillService,
        'STAFF': staffService,
        'USER': userService,
        'STAGE': stageService,
        'STORAGE_FILE': fileService,
        'COMPANY': companyService,
        'NOTE': noteService
      },
      
      select_state: {
        checked: false,
        indeterminate: false
      },
      
      projectSelectorShow: false,
      taskEdit: {
        uuId: null,
        parentId: null
      },
      taskEditShow: false,
      bookingEdit: {
        uuId: null,
        parentId: null,
        mode: 'staff'
      },
      bookingEditShow: false,
      activityEdit: {
        uuId: null,
        mode: 'staff'
      },
      activityEditShow: false,
      
      projectTemplateSelectorShow: false,
      taskTemplateEdit: {
        uuId: null,
        parentId: null
      },
      taskTemplateEditShow: false,
      
      confirmDeleteShow: false,
      confirmDeleteDataviewShow: false,
      
      projectId: null,
      taskProjectId: null,
      projectShow: false,
      projectTemplateShow: false,
      
      customerId: null,
      customerShow: false,
      
      contactId: null,
      contactParentId: null,
      contactShow: false,
      customerSelectorShow: false,
      
      modalCommentShow: false,
      commentId: null,
      
      modalRebateShow: false,
      rebateId: null,
      
      departmentId: null,
      departmentShow: false,
      selectedParent: null,
      
      locationId: null,
      locationShow: false,
      
      resourceId: null,
      resourceShow: false,
      
      skillId: null,
      skillShow: false,
      
      staffId: null,
      staffShow: false,
      
      userModalId: null,
      userShow: false,
      
      stageShow: false,
      stageId: null,
      
      fileDetailsId: null,
      fileParentData: null,
      fileDetailsShow: false,
      
      companyId: null,
      companyShow: false,
      masterCompany: null,
      
      autoAssignStaffShow: false,
      autoAssignSettings: null,
      autoAssignSummaryShow: false,
      autoAssignSummary: [],
      
      confirmDeleteChartShow: false,
      deleteChartIndex: -1,

      priorityNavLoaded: false,

      settings: {}, // View settings for the Sheet tab
      
      reloadData: false, // when the user edits the data view
      exportJson: null,
      folders: {},
      showPermissionsError: false,
      showExistenceError: false,
      span: "Daily",
      dates: "this-week",
      highlightRefresh: false,
      datesStr: 'this-week',
      datesMode: "current",
      startDate: null,
      endDate: null,
      min: null,
      max: null,
      epoch: null,
      epochs: [],
      timeseries_field: null,
      timeseries_label: null,
      fieldOptions: [],
      epochOptions: [],
      useEpoch: true,
      timeseries_hint_donotshow: false,
      timeseriesHintShow: false,
      loadWidgetSheet: false,
      
      confirmBookingTypeShow: false,
      confirmActivityTypeShow: false,
      showStaffSelector: false,
      staffUuIds: null,

      durationConversionOpts: {},

      sheetHeight: 'calc(100vh - 209px)',
      ganttHeight: 0,
      kanbanHeight: 0,
      owner: null,
      showOwner: false
    }
  },
  watch: {
    $route: function() {
      this.onCreated();
      
      // reset the active tab to the sheet view
      this.setActiveTab(0);
    },
    dataviewComponent: function() {
      
      if (this.dataviewComponent === 'sheet') {
        this.loadWidgetSheet = true;
      }
      else if (this.dataviewComponent === 'gantt') {
        this.prepareGantt();
      }
      else if (this.dataviewComponent === 'staff_usage') {
        this.prepareStaff();
      }
      else if (this.dataviewComponent === 'resource_usage') {
        this.prepareResource();
      }
      else if (this.dataviewComponent === 'board') {
        this.prepareBoard();
      }
    },
    alertMsg: function(nVal, oVal) {
      if (oVal != nVal && nVal == null) {
        this.updateTabContentHeight();
      }
    },
    height: function(newValue) {
      this.ganttHeight = this.kanbanHeight = newValue;
    }
  },
  beforeMount() {
    const self = this;
    this.ganttHeight = this.kanbanHeight = this.height;
    // this.userId = this.$store.state.authentication.user.uuId;
    function saveColumnState() {
      const columns = self.gridApi.getColumns();
      self.settings.sheetColumns = columns.map(c => { return { colId: c.colId, width: c.actualWidth, sort: c.sort, sortIndex: c.sortIndex, hide: !c.visible }});
      self.updateViewProfile();
    }

    const defaultCellClass = (params) => { return params.data.taskType === 'Project' ? ['grid-cell-summary']: [] };
    this.gridOptions = {
      rowModelType: 'serverSide',
      getRowStyle: function (params) {
        if (params.node.rowPinned) {
          return { 'font-weight': 'bold' };
        }
      },
      onSelectionChanged: function(event) {
        if (!event.api.isDestroyed()) {
          self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
          self.updateSelectState(event.api);
        }
      },
      onColumnVisible: function(event) {
        event.api.sizeColumnsToFit();
        saveColumnState();
      },
      onSortChanged: function(/** event */) {
        saveColumnState();
      },
      onDragStopped: function(/** event */) {
        saveColumnState();
      },
    };
    
    // dynamic columns created from values returned from the server
    this.valueColumnDefs = null;
    
    this.columnDefs = [];
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      hide: false,
      menuTabs: ['columnsMenuTab'],
      cellClass: defaultCellClass
    };
    
    this.context = {
      componentParent: self
    };
  },
  mounted() {
    
  },
  created() {
    this.projectUtil = projectUtil;
    this.customerUtil = customerUtil;
    this.templateTaskUtil = templateTaskUtil;
    this.onCreated();
  },
  beforeDestroy() {
    EventBus.$off('profiles-loaded')
    EventBus.$off('reload-current')
    this.projectUtil = null;
    this.customerUtil = null;
    this.templateTaskUtil = null;
    this.gridApi = null;
  },
  computed: {
    spanOptions() {
      return [
        { text: this.$t('timescale.day'), value: "Daily" },
        { text: this.$t('timescale.week'), value: "Weekly" },
        { text: this.$t('timescale.month'), value: "Monthly" },
        { text: this.$t('timescale.year'), value: "Yearly" }
      ]
    },
    dateOptions() {
      const options = [
        // { text: this.$t('view_dates.custom'),  value: null},
        { text: this.$t('view_dates.custom'),  value: 'null' },
        // - means this calendar week Mon-Sun (01/06/2020 - 07/06/2020)
        { text: this.$t('view_dates.this_week'), value: "this-week" },
        // - means this calendar week Mon-Sun  to today's date (01/06/2020 - 03/06/2020)  
        { text: this.$t('view_dates.this_week_to_date'), value: "this-week-to-date" },  
        // - means this calendar month (01/06/2020 - 30/06/2020)
        { text: this.$t('view_dates.this_month'), value: "this-month" },  
        // - means this calendar month to today's date (01/06/2020 - 03/06/2020)
        { text: this.$t('view_dates.this_month_to_date'), value: "this-month-to-date" },
        // - means this calendar quarter (01/04/2020 - 30/06/2020)  
        { text: this.$t('view_dates.this_quarter'), value: "this-quarter" },  
        // - means this calendar quarter to today's date (01/04/2020 - 03/06/2020)
        { text: this.$t('view_dates.this_quarter_to_date'), value: "this-quarter-to-date" },
        // - means this calendar year (01/01/2020 -> 31/12/2020)  
        { text: this.$t('view_dates.this_year'), value: "this-year" },  
        // - means this calendar year to today's date (01/01/2020 -> 03/06/2020)
        { text: this.$t('view_dates.this_year_to_date'), value: "this-year-to-date" },
        // - means last Mon-Sun block (week) (25/05/2020 - 31/05/2020)  
        { text: this.$t('view_dates.last_week'), value: "last-week" },  
        // - means last Mon-Sun block (week) to today's date (25/05/2020 - 03/06/2020)
        { text: this.$t('view_dates.last_week_to_date'), value: "last-week-to-date" },
        // - means last calendar month (01/05/2020 - 31/05/2020)  
        { text: this.$t('view_dates.last_month'), value: "last-month" },  
        // - means last calendar month to today's date (01/05/2020 - 03/06/2020)
        { text: this.$t('view_dates.last_month_to_date'), value: "last-month-to-date" },
        // - means last calendar quarter (01/01/2020 - 31/03/2020)  
        { text: this.$t('view_dates.last_quarter'), value: "last-quarter" },  
        // - means last calendar quarter (01/01/2020 - 31/03/2020)
        { text: this.$t('view_dates.last_quarter_to_date'), value: "last-quarter-to-date" },
        // - means last calendar year (01/01/2019 -> 31/12/2019)  
        { text: this.$t('view_dates.last_year'), value: "last-year" },  
        // - means next Mon-Sun blocks (week) from today (08/06/2020 - 14/06/2020)
        { text: this.$t('view_dates.next_week'), value: "next-week" },  
        // - means next 4 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_4_weeks'), value: "next-4-weeks" },
        // - means next 8 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_8_weeks'), value: "next-8-weeks" },
        // - means next 12 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_12_weeks'), value: "next-12-weeks" },
        // - means next 4 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_24_weeks'), value: "next-24-weeks" },
        // - means next calendar month (01/07/2020 - 31/07/2020)  
        { text: this.$t('view_dates.next_month'), value: "next-month" },  
        // - means next calendar quarter (01/10/2020 - 31/12/2020)
        { text: this.$t('view_dates.next_quarter'), value: "next-quarter" },
        // - means next calendar year (01/01/2021 -> 31/12/2021)  
        { text: this.$t('view_dates.next_year'), value: "next-year" } 
      ]

      return options;
    },
    checkQuery() {
      if (!this.dataviewLive) {
        return true;
      }
      
      for (const filter of this.dataviewLive.query.children) {
        if (typeof filter.value1 === 'string' && filter.value1.trim() === "" &&
            !((filter.fieldtype === 'String' && filter.operator === 'eq') || (filter.fieldtype === 'String' && filter.operator === 'neq'))) {
          return true;
        }
      }
      return false;
    },
    usingPinnedRows() {
      return this.dataview && 
        (this.dataview.topSum ||
         this.dataview.topMin ||
         this.dataview.topMax ||
         this.dataview.topAvg ||
         this.dataview.bottomSum ||
         this.dataview.bottomMin ||
         this.dataview.bottomMax ||
         this.dataview.bottomAvg);
    },
    chartList() {
      if (!this.isWidget) {
        return this.charts;
      }
      else {
        return this.charts.filter(c => this.dataviewComponent === c.id);
      }
    },
    showStaff() {
      if (this.dataview !== null &&
          typeof this.dataview.showStaff !== 'undefined') {
        return this.dataview.showStaff;
      }
      return true;
    },
    showResource() {
      /*if (this.dataview !== null &&
          typeof this.dataview.showResource !== 'undefined') {
        return this.dataview.showResource;
      }*/
      return false;
    },
    showGantt() {
      if (this.dataview !== null &&
          typeof this.dataview.showGantt !== 'undefined') {
        return this.dataview.showGantt;
      }
      return true;
    },
    showBoard() {
      if (this.dataview !== null &&
          typeof this.dataview.showBoard !== 'undefined') {
        return this.dataview.showBoard;
      }
      return true;
    },
    filteredDateOptions() {
      if (this.useEpoch) {
        return this.dateOptions.slice(0, this.dateOptions.length - 5);
      }
      return this.dateOptions;
    },
    maxDate() {
      if (this.useEpoch) {
        return new Date();
      }
      return null;
    },
    spanPrefix() {
      const prefix = this.spanOptions.filter(s => s.value === this.span);
      if (prefix.length > 0) {
        return prefix[0].text;
      }
      return '';
    },
    disableEpochLeft() {
      return this.epoch === null || this.epochs.length === 0 || 0 === this.epochs.findIndex(e => e === this.epoch);
    },
    disableEpochRight() {
      return this.epoch === null || this.epochs.length === 0 || this.epochs.length - 1 === this.epochs.findIndex(e => e === this.epoch);
    },
    tabList() {
      const list = [];
      list.push({ name: 'sheet'});
      
      if(this.isEntity('TASK') && this.showGantt) {
        list.push({ name: 'gantt'});
      }
      
      if((this.isEntity('TASK') || this.isEntity('STAFF') || this.isEntity('DEPARTMENT') || this.isEntity('LOCATION') || this.isEntity('COMPANY') || this.isEntity('SKILL')) &&
          this.showStaff) {
        list.push({ name: 'staff-usage'});
      }
      
      if((this.isEntity('TASK') || this.isEntity('RESOURCE')) &&
          this.showResource) {
        list.push({ name: 'resource-usage'});
      }
      
      if(this.isEntity('TASK') && this.showBoard) {
        list.push({ name: 'board' });
      }

      for(const chart of this.charts) {
        list.push({ name: `chart_${chart.id}`});
      }
      return list;
    },
    companyTitle() {
      return this.companyId && this.companyId.indexOf('COMPANY_NEW') == -1? this.$t('company.title_detail'): this.$t('company.title_new');
    },
    chartTitle() {
      return this.chart && this.chart.id !== null ? this.$t('dataview.edit_chart'): this.$t('dataview.new_chart');
    },
    stageTitle() {
      return this.stageId && this.stageId.indexOf('STAGE_NEW') == -1? this.$t('stage.title_detail'): this.$t('stage.title_new');
    },
    userTitle() {
      return this.userModalId && this.userId.indexOf('USER_NEW') == -1? this.$t('user.user_edit'): this.$t('user.title_new');
    },
    staffTitle() {
      return this.staffId && this.staffId.indexOf('STAFF_NEW') == -1? this.$t('staff.title_detail'): this.$t('staff.title_new');
    },
    skillTitle() {
      return this.skillId && this.skillId.indexOf('SKILL_NEW') == -1? this.$t('skill.title_detail'): this.$t('skill.title_new');
    },
    resourceTitle() {
      return this.resourceId && this.resourceId.indexOf('RESOURCE_NEW') == -1? this.$t('resource.title_detail'): this.$t('resource.title_new');
    },
    locationTitle() {
      return this.locationId && this.locationId.indexOf('LOCATION_NEW') == -1? this.$t('location.title_detail'): this.$t('location.title_new');
    },
    departmentTitle() {
      return this.departmentId && this.departmentId.indexOf('DEPARTMENT_NEW') == -1? this.$t('department.title_detail'): this.$t('department.title_new');
    },
    deleteMessage() {
      if (this.entity === null) {
        return '';
      }
    
      const entitylist = typeof this.entity !== 'undefined' ? this.entity.split('.') : [];
      if (entitylist.length === 0) {
        return '';
      }
      
      const entity = entitylist[entitylist.length - 1];
      
      if (entity === 'TASK') {
        return this.$t(`${this.selected.length > 1? 'task.confirmation.delete_plural':'task.confirmation.delete'}`);
      }
      else if (entity === 'ACTIVITY') {
        return this.$t(`${this.selected.length > 1? 'activity.confirmation.delete_plural':'activity.confirmation.delete'}`);
      }
      else if (entity === 'BOOKING') {
        return this.$t(`${this.selected.length > 1? 'booking.confirmation.delete_plural':'booking.confirmation.delete'}`);
      }
      else if (entity === 'PROJECT') {
        return this.$t(`${this.selected.length > 1? 'project.confirmation.delete_plural':'project.confirmation.delete'}`);
      }
      else if (entity === 'PROJECT_TEMPLATE') {
        return this.$t(`${this.selected.length > 1? 'template.confirmation.delete_plural':'template.confirmation.delete'}`);
      }
      else if (entity === 'CUSTOMER') {
        return this.$t(`${this.selected.length > 1? 'customer.confirmation.delete_plural':'customer.confirmation.delete'}`);
      }
      else if (entity === 'DEPARTMENT') {
        return this.$t(`${this.selected.length > 1? 'department.confirmation.delete_plural':'department.confirmation.delete'}`, ['']);
      }
      else if (entity === 'LOCATION') {
        return this.$t(`${this.selected.length > 1? 'location.confirmation.delete_plural':'location.confirmation.delete'}`);
      }
      else if (entity === 'RESOURCE') {
        return this.$t(`${this.selected.length > 1? 'resource.confirmation.delete_plural':'resource.confirmation.delete'}`);
      }
      else if (entity === 'SKILL') {
        return this.$t(`${this.selected.length > 1? 'skill.confirmation.delete_plural':'skill.confirmation.delete'}`);
      }
      else if (entity === 'STAFF') {
        return this.$t(`${this.selected.length > 1? 'staff.confirmation.delete_plural':'staff.confirmation.delete'}`);
      }
      else if (entity === 'USER') {
        return this.$t(`${this.selected.length > 1? 'user.confirmation.delete_plural':'user.confirmation.delete'}`);
      }
      else if (entity === 'STAGE') {
        return this.$t(`${this.selected.length > 1? 'stage.confirmation.delete_plural':'stage.confirmation.delete'}`);
      }
      else if (entity === 'STORAGE_FILE') {
        return this.$t(`${this.selected.length > 1? 'file.confirmation.delete_plural':'file.confirmation.delete_file'}`, ['']);
      }
      else if (entity === 'COMPANY') {
        return this.$t(`${this.selected.length > 1? 'company.confirmation.delete_plural':'company.confirmation.delete'}`, ['']);
      }
      else if (entity === 'TASK_TEMPLATE') {
        return this.$t(`${this.selected.length > 1? 'template.confirmation.delete_plural':'template.confirmation.delete'}`, ['']);
      }
      else if (entity === 'CONTACT') {
        return this.$t(`${this.selected.length > 1? 'contact.confirmation.delete_plural':'contact.confirmation.delete'}`, ['']);
      }
      else if (entity === 'NOTE') {
        return this.$t(`${this.selected.length > 1? 'comment.confirmation.delete_plural':'comment.confirmation.delete'}`, ['']);
      }
      return '';
    },
    customerTitle() {
      return this.customerId && this.customerId.indexOf('CUSTOMER_NEW') == -1? this.$t('customer.title_detail'): this.$t('customer.title_new');
    },
    contactTitle() {
      return this.contactId && this.contactId.indexOf('CONTACT_NEW') == -1? this.$t('contact.title_detail'): this.$t('contact.title_new');
    },
    projectTitle() {
      return this.projectId && this.projectId.indexOf('PROJECT_NEW') == -1? this.$t('project.title_detail'): this.$t('project.title_new');
    },
    templateTitle() {
      return this.projectTemplateId && this.projectTemplateId.indexOf('TEMPLATE_NEW') == -1? this.$t('template.detail'): this.$t('template.new');
    },
    disableEdit() {
      return this.selected.length != 1;
    },
    editPermission() {
      if (this.dataview === null ||
          typeof this.dataview.editingPermissions === 'undefined') {
        return true;    
      }
      
      return this.dataview.editingPermissions.includes(this.userId);
    },
    disableDelete() {
      return this.selected.length < 1;
    },
    allowManage() {
      if (this.entity !== null) {
        const entitylist = typeof this.entity !== 'undefined' ? this.entity.split('.') : [];
        if (entitylist.length === 0) {
          return false;
        }
        
        const entity = entitylist[entitylist.length - 1];
        if (entity === 'STAFF-RESOURCE' ||
            entity === 'STAFF-SKILL' ||
            entity === 'TASK-RESOURCE' ||
            entity === 'TASK-SKILL') {
          return false;    
        }

      }
      
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('dataview.grid.no_data') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    staffTabIndex() {
      if (this.isEntity('TASK')) {
        if (this.showGantt) {
          return 2;
        }
        return 1;
      }
      else if (this.isEntity('STAFF') || this.isEntity('DEPARTMENT') || this.isEntity('LOCATION') || this.isEntity('COMPANY') || this.isEntity('SKILL')) {
        return 1;
      }
      return 2;
    },
    resourceTabIndex() {
      if (this.isEntity('TASK')) {
        if (this.showGantt && this.showStaff) {
          return 3;
        }
        else if (this.showGantt || this.showStaff) {
          return 2;
        }
        return 1;
      }
      else if (this.isEntity('RESOURCE')) {
        return 1;
      }
      return 3;
    },
    chartTabIndex() {
      if (this.isEntity('TASK')) {
        let index = 1;
        if (this.showGantt) {
          index++;
        }
        if (this.showStaff) {
          index++;
        }
        if (this.showResource) {
          index++;
        }
        if (this.showBoard) {
          index++;
        }
        return index;
      }
      else if ((this.isEntity('STAFF') || this.isEntity('DEPARTMENT') || this.isEntity('LOCATION') || this.isEntity('COMPANY') || this.isEntity('SKILL')) &&
               this.showStaff) {
        return 2;
      }
      else if ((this.isEntity('STAFF') || this.isEntity('DEPARTMENT') || this.isEntity('LOCATION') || this.isEntity('COMPANY') || this.isEntity('SKILL') || this.isEntity('RESOURCE')) &&
               this.showResource) {
        return 2;
      }
      return 1;
    },
    entityName() {
      if (this.entity) {
        const entitylist = this.entity.split('.');
        const eName = entitylist[entitylist.length - 1];
        if (eName === 'PROJECT_TEMPLATE') {
          return 'TEMPLATE__PROJECT';
        }
        else if (eName === 'TASK_TEMPLATE') {
          return 'TEMPLATE__TASK';
        }
        return eName;
      }
      return null;
    },
    payFrequencyOptions() {
      let options = null;
      if (this.modelInfo != null) {
        const rawOptions = this.modelInfo.find(f => f.field === 'payFrequency').options;
        if (rawOptions != null) {
          options = rawOptions.map(i => {
            return { value: i, text: this.$t(`payFrequency.${i}`) }
          });
        }
      }
      if (options != null) {
        return options;
      }
      return [];
    },
    timeModeMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    ownerMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    canResetDefault() {
      return typeof this.settings.dataview_settings !== 'undefined' &&
          typeof this.settings.dataview_settings[this.uuId] !== 'undefined';
    },
    nominate() {
      if (this.dataview &&
          typeof this.dataview.nominate === 'string') {
        return this.dataview.nominate;   
      }
      
      if (this.dataview &&
          this.dataview.nominate) {
          if (this.hasSubEntities(this.filter)) {
            return 'group';
          }
          return 'nominate';   
      }
      return 'left-join';
    }
  },
  methods: {
    hasSubEntities(filter) {
      if (Array.isArray(filter)) {
        for (const f of filter) {
          if (Array.isArray(f)) {
            const res = this.hasSubEntities(f);
            if (res) {
              return true;
            }
          }
          else if (typeof f === 'string') {
            const m =  f.match(/\./g);
            if (m !== null && m.length > 1) {
              return true;
            }
          }
        }
      }
      else if (typeof filter === 'string') {
        const m = filter.match(/\./g);
        if (m !== null && m.length > 1) {
          return true;
        }
      }
      return false;
    },
    onCreated() {
      this.getModelInfo();
      this.userId = this.$store.state.authentication.user.uuId;
      
      if (this.isWidget && this.dataviewComponent === 'sheet') {
        this.loadWidgetSheet = true;
      }
      
      // clear ids if we are changing data views
      this.dataview = null;
      this.staffIds = null;
      this.resourceIds = null;
      this.taskIds = null;
      this.settings = {};
      this.datesMode = 'current';
      
      this.entityId = this.id = this.isWidget && this.dataviewId ? this.dataviewId : typeof this.$route.params.id !== 'undefined' ? this.$route.params.id : null;
      const self = this;
      this.onOwner();
      this.isPublic = this.isWidget ? this.dataviewPublic : this.$router.currentRoute.path.indexOf('/dataview/public') === 0;
      
      EventBus.$on('profiles-loaded', () => {
        self.$nextTick(() => self.scrollToDataview());
      });
      
      EventBus.$on('reload-current', () => {
        self.reloadData = true;
        self.listDataviewProfiles(true);
        self.gridApi.deselectAll();
      });
      
      if (typeof this.id !== 'undefined' && this.id !== null && this.id !== 'add') {
        this.listDataviewProfiles();
      }
      else {
        setTimeout(() => {
          this.addDataview()
        }, 500);
      }
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "RESOURCE"}).then(value => {
        self.modelInfo = value.RESOURCE.properties;
      })
      .catch(e => {
        httpAjaxError(e, this);
      });
    },
    selectionChanged() {
      const self = this;
      this.gridApi.forEachNode(function(node) {
        if (typeof node.data !== 'undefined') {
          node.setSelected(self.select_state.checked && !self.select_state.indeterminate);
        }
      });
    },
    updateSelectState(gridApi) {
      const totalRows = gridApi.getDisplayedRowCount();
      const selected = gridApi.getSelectedRows().length;
    
      this.select_state.indeterminate = selected > 0 && selected < totalRows;
      this.select_state.checked = selected === totalRows;
    },
    autoAssignStaffSuccess(result) {
      this.autoAssignSettings = cloneDeep(result);
      this.showInProgress(this.$t('task.progress.assigning_staff'), true);
      this.allocateStaff(this.autoAssignSettings.staffList);     
    },
    async allocateStaff(staffList) {
      const self = this;
      var skillMatchList = [];
      const settings = self.autoAssignSettings.settings;
            
      if (settings.match_staff_based_on_skills) {
        skillMatchList.push({ 'level': 'Yes' });
      }
      else {
        skillMatchList.push({ 'level': 'No' });
      }
      if (settings.include_staff_exact_skill) {
        skillMatchList.push({ 'level': 'Exact' });
      }
      if (settings.include_staff_lower_skill) {
        skillMatchList.push({ 'level': 'Low', 'changeDuration': settings.adjust_task_duration_lower });
      }
      if (settings.include_staff_higher_skill) {
        skillMatchList.push({ 'level': 'High', 'changeDuration': settings.adjust_task_duration_higher });
      }
      const taskList = self.autoAssignTasks();
      for (var idx = 0; idx < taskList.length; idx+=10) {
        if (self.inProgressState.cancel) {
          break;
        }
        const list = taskList.slice(idx, idx + 10).map(t => { return { uuId: t.uuId } });
        this.inProgressLabel = this.$t('task.progress.assigning_staff_to_plural', [`${Math.trunc(idx / taskList.length * 100)}%`]);
        let data = await staffService.allocation({}, {
                                       type: "Assign",
                                       includeAssignedTask: !settings.skip_already_assigned,
                                       includeStartedTask: !settings.skip_already_started,
                                       overAllocateStaff: settings.allow_over_alloc,
                                       skillMatchList: skillMatchList,
                                       staffList: staffList, 
                                       taskList: list
                                     })
        .then(response => {
          return response.data[response.data.jobCase].length !== 0 ? response.data[response.data.jobCase] : [];
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
          return [];
        });

        
        for (var j = 0; j < data.length; j++) {
          data[j].taskName = taskList.filter(t => t.uuId === data[j].taskUUID)[0].taskName;
          self.autoAssignSummary.push(data[j]);
        }
      } 
      self.autoAssignSummaryShow = true;
      self.updateGrid(); 
      self.inProgressShow = false;
    },
    autoAssignSummaryOk() {
      this.autoAssignSummary = [];
    },
    autoAssignTasks() {
      if (this.gridApi !== null) {
        var tasks = [];
        const selected = this.gridApi && !this.gridApi.isDestroyed() ? this.gridApi.getSelectedNodes() : [];
        if (selected) {
          for (var i = 0; i < selected.length; i++) {
            const node = selected[i];
            if (node.data.taskType === 'Task' &&
                tasks.findIndex(u => u.uuId === node.data.uuId) === -1) {
              tasks.push(node.data);
            }
          }
        }
        return tasks;
      }
      return [];
    },
    showInProgress(label=null, isStoppable=false) {
      this.inProgressState.cancel = false;
      this.inProgressShow = true;
      this.inProgressLabel = label;
      this.inProgressStoppable = isStoppable;
    },
    progressCancel() {
      this.$set(this.inProgressState, 'cancel', true);
      this.inProgressLabel = this.$t('task.progress.stopping');
    },
    autoAssignStaff() {
      this.resetAlert();
      // const selectedId = this.gridApi.getSelectedNodes().map(i => { return i.data.uuId })[0];
      this.autoAssignStaffShow = true;
    },
    isEntity(value) {
      if (this.entity !== null) {
        const entitylist = typeof this.entity !== 'undefined' ? this.entity.split('.') : [];
        if (entitylist.length === 0) {
          return false;
        }
        
        const entity = entitylist[entitylist.length - 1];
        if (entity === value) {
          return true;    
        }

      }
      return false;
    },
    scrollToDataview() {
      const sidebar = document.querySelector('.sidebar-nav');
      const elem = document.querySelector('.sidebar-nav .active');
      if (elem !== null) {
        sidebar.scrollTop = localStorage.scrollTop;
      }
    },
    onGridReady(params) {
      const self = this;
      this.gridApi = params.api;
      if (this.loadWidgetSheet) {
        this.loadWidgetSheet = false;
        if (!this.usingPinnedRows) {
          this.gridApi.setGridOption('serverSideDatasource', new ServerSideDatasource(this));
        }
        else {
          loadData({
            success: (data) => {
              self.gridApi.setGridOption('rowData', data.rowData);
            },
            request: {
              startRow: 0,
              endRow: -1
            }
          },
          this,
          null,
          null);
        }
      }
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    addDataview() {
      this.data = null;
      this.addShow = true;
    },
    getFieldId(field) {
      var name = '';
      if (typeof field === 'string') {
        name = field.replace(/\./g, ' ');
      }
      else {
        if (typeof field.name !== 'undefined') {
          name = field.name;
        }
        else {
          name = this.fieldNames[field];
        }
      }
      return name;
    },
    getFieldName(field) {
      var name = '';
      if (typeof field === 'string') {
        name = typeof this.fieldNames[field] !== 'undefined' ? this.fieldNames[field] : field.replace(/\./g, ' ');
      }
      else {
        if (typeof field.name !== 'undefined') {
          name = field.name;
        }
        else {
          name = this.fieldNames[field];
        }
      }
      return name;
    },
    detailLinkLabel(params) {
      return params.value;
    },
    detailLinkId(params) {
      return params.data.uuId;
    },
    openDetail(id, params) {
      this.editOpenId(id, params.data);
    },
    populateFieldOptions(fields, fieldNames) {
      if (fields) {
        this.fieldOptions = [];
        for (let idx = 0; idx < fields.length; idx++) {
          const value = typeof fields[idx] === 'string' ? fields[idx] : fields[idx].name;
          this.fieldOptions.push({ value: value, text: typeof fieldNames[value] !== 'undefined' ? fieldNames[value] : value });
        }
        
        const fieldIndex = this.fieldOptions.findIndex(i => i.value === this.timeseries_field);
        if (fieldIndex === -1) {
          // does not exist
          this.timeseries_field = null;
        }
        
        if (this.timeseries_field === null) {
          this.timeseries_field = fields.length > 1 ? fields[1] : fields[0];
          if (typeof this.timeseries_field === 'object') {
            this.timeseries_field = this.timeseries_field.name;
          }
        }
        
        const labelIndex = this.fieldOptions.findIndex(i => i.value === this.timeseries_label);
        if (labelIndex === -1) {
          // does not exist
          this.timeseries_label = null;
        }
        
        if (this.timeseries_label === null) {
          this.timeseries_label = fields[0];
        }
      }
    },
    applyPermissions(data) {
      if (data.values) {
        data = cloneDeep(data);
        for (var i = data.values.length - 1; i >= 0; i--) {
          const entry = typeof data.values[i] === 'object' ? data.values[i].field.split('.') : data.values[i].split('.');
          const entity = entry[entry.length - 2];
          const permList = this.$store.state.authentication.user.permissionList.filter(f => f.name === `${entity}__VIEW`);
          const perms = permList.length > 0 ? 
                        permList[0] : 
                        [];
          const denyRules = perms && perms.permissionLink && perms.permissionLink.denyRules ?
                            perms.permissionLink.denyRules : [];
          if (denyRules.includes(entry[entry.length - 1])) {
            data.values.splice(i, 1);
          }
        }
      }
      return data;
    },
    async viewDataview({data, saveColumns=false, toResetAlert=true } = {}) {
      const self = this;
      if (this.schema === null) {
        await this.$store.dispatch('data/info', { type: 'query' }).then((value) => { 
          this.schema = value;
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
        });
        
        await this.$store.dispatch('data/info', { type: 'macro' }).then((value) => { 
          this.macros = value;
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
        });
      }

      // only load the settings on the first time
      if (Object.keys(this.settings).length === 0) {
        await this.loadViewProfile(); //Have to wait until the view profile ready. Otherwise, the following process will is unable to setup the correct content.
      }
      
      let detailLinked = self.nominate === 'group-by' ? true : false; // do not hotlink text in group by property
      if (toResetAlert) {
        this.resetAlert();
      }
      data = self.applyPermissions(data);
      this.fields = data.values;
      
      if (!this.fields) {
        return;
      }
      
      this.fieldNames = data.fieldNames ? data.fieldNames : {};
      this.populateFieldOptions(this.fields, this.fieldNames);
      this.entity = data.entity;
      this.filter = buildFilter(data.entity, data.query);
      this.dedup = data.dedup;
      this.sortfield = data.sortfield;
      this.charts = typeof data.charts !== 'undefined' && data.charts !== null ? data.charts : [];
        
      // for a widget we only show one tab
      if (this.isWidget) {
        if (this.dataviewComponent === 'gantt' && this.showGantt) {
          this.prepareGantt();
        }
        else if (this.dataviewComponent === 'staff_usage' && this.showStaff) {
          this.prepareStaff();
        }
        else if (this.dataviewComponent === 'resource_usage' && this.showResource) {
          this.prepareResource();
        }
        else if (this.dataviewComponent === 'board' && this.showBoard) {
          this.prepareBoard();
        }
      }
      
      for(let j = 0, jLen = this.charts.length; j < jLen; j++) {
        const series = this.charts[j].series;
        this.prepareToolTipRenderer(series);
      }
      
      
      // setup the column definitions
      var columnDefs = [];
      if (this.gridApi && !this.gridApi.isDestroyed()) {
        this.gridApi.setGridOption('columnDefs', columnDefs);
      }

      for (var idx = 0; idx < this.fields.length; idx++) {
        var fieldValue = this.fields[idx];
        const fieldType = getFieldType(typeof fieldValue === 'string' ? fieldValue : fieldValue.field, this.schema);
        const fieldId = this.getFieldId(fieldValue);
        const field = this.getFieldName(fieldValue);
        
        const columnDef = {
          colId: fieldId,
          headerName: field,
          field: fieldId,
          lockPosition: true,
          minWidth: 150,
          resizable: true,
          sortable: typeof fieldValue === 'string'
        };
        
        if (idx === 0 && !this.isWidget) {
          columnDef.checkboxSelection = true;
        }

        if ((fieldType === 'Date' && 
            (typeof fieldValue === 'string' || 
            !fieldValue.agFunction)) ||
            (typeof fieldValue === 'string' &&
             (fieldValue.endsWith('startDate') ||
             fieldValue.endsWith('endDate'))) ||
            (fieldType === 'Macro' &&
            typeof fieldValue === 'string' &&
            fieldValue.endsWith('=timeStamp(A)'))) {
          columnDef.cellRenderer = 'dateTimeCellRenderer';
        }
        else if ((fieldType === 'Time' && 
            (typeof fieldValue === 'string' || 
            !fieldValue.agFunction)) ||
            (typeof fieldValue === 'string' &&
             (fieldValue.endsWith('startHour') ||
             fieldValue.endsWith('endHour')))) {
          columnDef.cellRenderer = 'timeCellRenderer';
        }
        else if (fieldType === 'Duration' ||
                 (typeof fieldValue === 'object' &&
                 fieldValue.field.endsWith('.duration')) ||
                 (typeof fieldValue === 'string' &&
                 fieldValue.endsWith('.duration'))) {
          columnDef.cellRenderer = 'durationCellRenderer';
        }
        else if (fieldType === 'TaskType') {
          columnDef.cellRenderer = 'taskTypeCellRenderer';
        }
        else if (fieldType === 'StaffType') {
          columnDef.cellRenderer = 'staffTypeCellRenderer';
        }
        else if (fieldType === 'MinuteDuration') {
          columnDef.cellRenderer = 'minuteDurationCellRenderer';
        }
        else if (fieldType === 'Progress') {
          columnDef.cellRenderer = 'percentageCellRenderer';
        }
        else if (fieldType === 'Priority') {
          //columnDef.cellRenderer = 'priorityCellRenderer';
        }
        else if (fieldType === 'PayFrequency') {
          columnDef.cellRenderer = 'payFrequencyCellRenderer';
          columnDef.cellRendererParams  = {
            getOptions: () => {
              return self.payFrequencyOptions;
            }
          }
        }
        else if (typeof fieldValue === 'string' &&
                 (fieldValue.endsWith('.rebate') ||
                 fieldValue.endsWith('utilization'))) {
          columnDef.cellRenderer = 'percentageCellRenderer';
          columnDef.cellRendererParams = { noFill: true };
        }
        else if ((fieldType === 'Cost' && !(typeof fieldValue === 'object' &&
            (fieldValue.agFunction === 'count' || fieldValue.agFunction === 'length'))) || 
            (typeof fieldValue === 'string' && fieldValue.includes('payAmount'))) {
          columnDef.cellRenderer = 'costCellRenderer';
          const idx = fieldValue.lastIndexOf('.');
          const prefix = fieldValue.substring(0, idx).replaceAll('.', ' ');
          columnDef.cellRendererParams = {
            customCurrencyProp: `${prefix} ${fieldValue.includes('payAmount')? 'payCurrency':'currencyCode'}`
          }
        }
        else if (fieldType === 'Macro' && typeof this.fields[idx] === 'string') {
          if (this.fields[idx].includes('Cost')) {
            columnDef.cellRenderer = 'costCellRenderer';
            const idx = fieldValue.lastIndexOf(' ');
            const prefix = fieldValue.substring(0, idx).replaceAll('.', ' ');
            columnDef.cellRendererParams = {
              customCurrencyProp: `${prefix} currencyCode`
            }
          }
          else if (this.fields[idx].includes('Progress')) {
            columnDef.cellRenderer = 'percentageCellRenderer';
          }
          else if (this.fields[idx].includes('TimeToComplete')) {
            columnDef.cellRenderer = 'durationCellRenderer';
          }
        }
        else if (fieldType === 'List<KindData>' && typeof fieldValue === 'string') {
          columnDef.cellRenderer = 'listCellRenderer';
        }
        else if (typeof fieldValue === 'string' &&
                 fieldValue.endsWith(".color")) {
          columnDef.cellRenderer = 'colorCellRenderer';
        }
        else if (!detailLinked && typeof fieldValue === 'string' &&
                 this.canDetailLink(this.entityName) &&
                 this.entity === fieldValue.substring(0, fieldValue.lastIndexOf('.'))) {
          columnDef.cellRenderer = 'detailLinkCellRenderer';
          detailLinked = true;
        }
        else if (fieldValue.agFunction && fieldValue.agFunction === 'count' &&
                 /^.+?\.STAFF\..+?$/.test(fieldValue.field)) {
          columnDef.cellRenderer = 'detailLinkCellRenderer';
          columnDef.cellRendererParams  = {
            useValue: true,
            clickFunc: (params) => {
              self.staffUuIds = params.data[`${self.entity} STAFF uuId`].split(', ');
              self.showStaffSelector = true;
            }
          }
        }
        else {
          columnDef.cellRenderer = 'genericCellRenderer';
        }
        columnDefs.push(columnDef);
      }

      this.columnDefs = columnDefs;
      
      this.loadColumnSettings();
      this.settings.sheetColumns = columnDefs.map(c => { return { colId: c.colId, width: c.actualWidth, sort: c.sort, sortIndex: c.sortIndex }});
      if (saveColumns) {
        await this.updateViewProfile();
      }
      
      if(this.gridApi && !this.gridApi.isDestroyed()) {
        this.gridApi.setGridOption('columnDefs', columnDefs);
        if (!this.usingPinnedRows) {
          this.gridApi.setGridOption('serverSideDatasource', new ServerSideDatasource(this));
        }
        else {
          this.gridApi.showLoadingOverlay();
          loadData({
            success: (data) => {
              if (self.gridApi && !self.gridApi.isDestroyed()) {
                self.gridApi.setGridOption('rowData', data.rowData);
              }
            },
            request: {
              startRow: 0,
              endRow: -1
            }
          },
          this,
          this.sortfield,
          null);
        }
        this.gridApi.sizeColumnsToFit();
      }
      
      if (this.reloadData) {
        this.reloadData = false;
        
        // The dataview has changed, retrieve the ids again
        this.staffIds = null;
        this.resourceIds = null;
        this.taskIds = null;
        if (this.activeTab === this.staffTabIndex) {
          this.prepareStaff();
        }
        else if (this.activeTab === this.resourceTabIndex) {
          this.prepareResource();
        }
        else if (this.isEntity('TASK') && this.activeTab === 1) {
          this.prepareGantt();
        }
        else if (this.isEntity('TASK') && this.activeTab === 3) {
          this.prepareBoard();
        }
        
      }
    },
    canDetailLink(entity) {
      if (entity === 'RESOURCE-STAFF' ||
          entity === 'STAFF-RESOURCE' ||
          entity === 'TASK-SKILL' ||
          entity === 'SKILL-TASK' ||
          entity === 'SKILL-STAFF' ||
          entity === 'STAFF-SKILL' ||
          entity === 'RESOURCE-TASK_TEMPLATE' ||
          entity === 'TASK_TEMPLATE-RESOURCE') {
        return false;
      }
      return true;
    },
    getCellRenderer(columnDef, fieldValue, fieldType/**, detailLinked */) {
      const self = this;
      if ((fieldType === 'Date' && 
          (typeof fieldValue === 'string' ||
          !fieldValue.agFunction)) ||
          (fieldType === 'Macro' &&
          typeof fieldValue === 'string' &&
          fieldValue.endsWith('=timeStamp(A)'))) {
        columnDef.cellRenderer = 'dateTimeCellRenderer';
      }
      else if (fieldType === 'Duration' ||
               (fieldType === '' && fieldValue.includes('Duration'))) {
        columnDef.cellRenderer = 'durationCellRenderer';
      }
      else if (fieldType === 'TaskType') {
        columnDef.cellRenderer = 'taskTypeCellRenderer';
      }
      else if (fieldType === 'StaffType') {
        columnDef.cellRenderer = 'staffTypeCellRenderer';
      }
      else if (fieldType === 'MinuteDuration') {
        columnDef.cellRenderer = 'minuteDurationCellRenderer';
      }
      else if (fieldType === 'Progress') {
        columnDef.cellRenderer = 'percentageCellRenderer';
      }
      else if (fieldType === 'Priority') {
        //columnDef.cellRenderer = 'priorityCellRenderer';
      }
      else if (fieldType === 'PayFrequency') {
        columnDef.cellRenderer = 'payFrequencyCellRenderer';
      }
      else if (typeof fieldValue === 'string' &&
               fieldValue.endsWith('.rebate')) {
        columnDef.cellRenderer = 'percentageCellRenderer';
        columnDef.cellRendererParams = { noFill: true };
      }
      else if ((fieldType === 'Cost' && !(typeof fieldValue === 'object' &&
            (fieldValue.agFunction === 'count' || fieldValue.agFunction === 'length'))) || 
            (typeof fieldValue === 'string' && fieldValue.includes('payAmount'))) {
        columnDef.cellRenderer = 'costCellRenderer';
        const idx = fieldValue.lastIndexOf(' ');
        const prefix = fieldValue.substring(0, idx);
        columnDef.cellRendererParams = {
          customCurrencyProp: `${prefix} ${fieldValue.includes('payAmount')? 'payCurrency':'currencyCode'}`
        }
      }
      else if (fieldType === 'Macro' && typeof fieldValue === 'string') {
        if (fieldValue.includes('Cost')) {
          columnDef.cellRenderer = 'costCellRenderer';
          const idx = fieldValue.lastIndexOf(' ');
          const prefix = fieldValue.substring(0, idx);
          columnDef.cellRendererParams = {
            customCurrencyProp: `${prefix} currencyCode`
          }
        }
        else if (fieldValue.includes('Progress')) {
          columnDef.cellRenderer = 'percentageCellRenderer';
        }
        else if (fieldValue.includes('TimeToComplete')) {
          columnDef.cellRenderer = 'durationCellRenderer';
        }
      }
      else if (fieldType === 'List<KindData>') {
        columnDef.cellRenderer = 'listCellRenderer';
      }
      else if (this.fields.find(f => f.name === fieldValue)) {
        const fieldDef = this.fields.find(f => f.name === fieldValue);
        if (fieldDef.agFunction && fieldDef.agFunction === 'count' &&
               /^.+?\.STAFF\..+?$/.test(fieldDef.field)) {
          columnDef.cellRenderer = 'detailLinkCellRenderer';
          columnDef.cellRendererParams  = {
            useValue: true,
            clickFunc: (params) => {
              self.staffUuIds = params.data[`${params.column.colId}_staff`].split(', ');
              self.showStaffSelector = true;
            }
          }
        }
      }
      return columnDef;
    },
    prepareGantt() {
      if (this.taskIds === null) {
        loadIds(this, this.dataview.entity);
      }
    },
    prepareStaff() {
      if (this.staffIds === null) {
        if (this.entity.endsWith("TASK") || this.isEntity('DEPARTMENT') || this.isEntity('LOCATION') || this.isEntity('COMPANY') || this.isEntity('SKILL')) {
          loadIds(this, `${this.entity}.STAFF`);
        }
        else {
          loadIds(this, this.dataview.entity);
        }
      }
    },
    prepareResource() {
      if (this.resourceIds === null) {
        if (this.entity.endsWith("TASK") || this.isEntity('DEPARTMENT') || this.isEntity('LOCATION') || this.isEntity('COMPANY') || this.isEntity('SKILL')) {
          loadIds(this, `${this.entity}.RESOURCE`);
        }
        else {
          loadIds(this, this.dataview.entity);
        }
      }
    },
    prepareBoard() {
      if (this.taskIds === null) {
        loadIds(this, this.dataview.entity);
      }
    },
    editDataview(data) {
      this.data = data;
      this.addShow = true;
    },
    async copyDataview(dataview) {
      const copyData = cloneDeep(dataview);
      copyData.name = `${copyData.name} ${this.$t('dataview.copy_text')}`;
      copyData['uuId'] = null;
      if (copyData['sharedVisibility'] === 'public') {
        delete copyData['parent'];
      }
      copyData['sharedVisibility'] = 'private';
      copyData['sharingMembers'] = this.userId;
      copyData['editingPermissions'] = this.userId;
      this.data = copyData;
      this.addShow = true;
    },
    async createDataviewProfile(dataview) {
      const data = await layoutProfileService.create([dataview],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        return data[0];
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
        return null;
      });
      return data;
    },
    async listPublic(uuId, saveColumns = false) {
      const self = this;
      let result = false;
      const profileData = await this.$store.dispatch('data/dataViewProfileListPublic', uuId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
        return null;
      });

      if (profileData != null) {
        this.dataviews = profileData;
        const dataview = this.dataviews.filter(d => d.uuId === this.id);
        this.folders = this.dataviews.filter(f => f.type === 'folder').reduce(function(map, obj) {
            map[obj.uuId] = obj.name;
            return map;
        }, {});
        if (dataview.length === 0) {
          if (!self.isWidget) {
            this.$router.push('/dataview');
          }
          else {
            self.showExistenceError = true;
          }
        } else {
          result = true;
          this.dataview = dataview[0];
          this.dataviewLive = cloneDeep(this.dataview);
          
          
          // For pinned rows we need the client side model so that we have all the data
          this.gridOptions.rowModelType = self.usingPinnedRows ? 'clientSide' : 'serverSide';
          
          if (this.isWidget && this.$router.currentRoute.path.includes('/projects/summary/')) {
            replacePlaceholders(this.dataview.query, {
              '%PROJECTNAME%': self.$store.state.breadcrumb.contextName
            });
            replacePlaceholders(this.dataviewLive.query, {
              '%PROJECTNAME%': self.$store.state.breadcrumb.contextName
            });
          }
          prepareQuery(this.dataviewLive.query, true);
          this.createExportJson();
          const path = this.getDataviewFolderPath(dataview[0]);
          if (!this.isWidget) {
            this.$store.dispatch("breadcrumb/update", path !== null && path !== "" ? `${path} / ${dataview[0].name}` : dataview[0].name, { root: true });
          }
          this.viewDataview({ data: dataview[0], saveColumns });
        }
      }
      return result;
    },
    getDataviewFolderPath(dataview) {
      const ret = [];
      let parent = dataview.parent;
      while (parent) {
        const parentFolder = this.folders[parent];
        if (typeof parentFolder !== 'undefined') {
          ret.unshift(parentFolder);
          const parentDataview = this.dataviews.filter(d => d.uuId === parent);
          if (parentDataview.length !== 0) {
            parent = parentDataview[0].parent;
          }
        }
        else {
          break;
        }
      }
      return ret.join(' / ');
    },
    async listDataviewProfiles(saveColumns = false) {
      const self = this;
      let isLoaded = false;
      if (this.isPublic) {
        if (!localStorage.companyId) {
          const data = await companyService.list({limit: -1, start: 0}).then((response) => {
            return response.data;
          })
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
            return null;
          });

          if (data != null) {
            const company = data.filter(d => d.type === 'Primary');
            if (company.length > 0) {
              localStorage.companyId = company[0].uuId;
              isLoaded = await this.listPublic(company[0].uuId, saveColumns);
            }
          }
        }
        else {
          isLoaded = await this.listPublic(localStorage.companyId, saveColumns);
        }
      }

      if(isLoaded) {
        return;
      }
    
      this.$store.dispatch('data/dataViewProfileList', this.userId).then((response) => {
        this.dataviews = response;
        this.folders = this.dataviews.filter(f => f.type === 'folder').reduce(function(map, obj) {
            map[obj.uuId] = obj.name;
            return map;
        }, {});
        const dataview = this.dataviews.filter(d => d.uuId === this.id);
        if (dataview.length > 0) {
          this.dataview = dataview[0];
          this.dataviewLive = cloneDeep(this.dataview);
          
          // For pinned rows we need the client side model so that we have all the data
          this.gridOptions.rowModelType = self.usingPinnedRows ? 'clientSide' : 'serverSide';
          
          if (this.isWidget && this.$router.currentRoute.path.includes('/projects/summary/')) {
            replacePlaceholders(this.dataview.query, {
              '%PROJECTNAME%': this.$store.state.breadcrumb.contextName
            });
                        replacePlaceholders(this.dataviewLive.query, {
              '%PROJECTNAME%': this.$store.state.breadcrumb.contextName
            });
          }
          prepareQuery(this.dataviewLive.query, true);
          this.createExportJson();
          const path = this.getDataviewFolderPath(dataview[0]);
          if (!this.isWidget) {
            this.$store.dispatch("breadcrumb/update", path !== null && path !== "" ? `${path} / ${dataview[0].name}` : dataview[0].name, { root: true });
          }
          this.viewDataview({ data: dataview[0], saveColumns });
        }
        else if (!this.isPublic) {
          if (!this.isWidget) {
            this.$router.push('/dashboard');
          }
          else {
            this.showPermissionsError = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async filterCreated(result) {
      this.dataview = null;
      const data = result.data;
      if (result.visibility.changed) {
        if (result.visibility.value === 'private') {
          this.$router.push(`/dataview/${result.uuId}`);
        }
        else {
          this.$router.push(`/dataview/public/${result.uuId}`);
        }
      }
      else if (data.clue === 'Created') {
        this.$router.push(`/dataview/${data.uuId}`);
      }
      else {
        if (result.name.changed) {
          const elem = document.querySelector('.sidebar-nav .active span');
          if (elem !== null) {
            elem.innerText = result.name.text;
            // span > a > li > ul
            const ul = elem.parentElement.parentElement.parentElement;
            const listItems = ul.childNodes;
            var pos = -1;
            for (var i = 0; i < listItems.length; i++) {
              if (listItems[i].children[0].children[0].textContent.toLowerCase().localeCompare(elem.textContent.toLowerCase()) === 1) {
                pos = i;
                break;
              }
            }
            
            if (pos === -1) {
              ul.appendChild(elem.parentElement.parentElement);
            }
            else {
              ul.insertBefore(elem.parentElement.parentElement, listItems[pos]);
            }
          }
        }

        if (result.folder.changed) {
          // Reload the data views in the sidebar
          setTimeout(() => {
            const userId = this.$store.state.authentication.user.uuId;
            this.$store.dispatch('data/clearDataViewProfileCache', userId, localStorage.companyId);
       
            EventBus.$emit('dataview-reload');
          }, 100);
        }
        this.reloadData = true;
        this.listDataviewProfiles(true);
        this.gridApi.deselectAll();
      }
    },
    async confirmDeleteDataviewOk() { 
      if (this.dataview.sharedVisibility !== 'private') {
        const company = await companyService.list({limit: -1, start: 0}).then((response) => {
          const data = response.data;
          return data.filter(d => d.type === 'Primary');
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
          return null;
        });
        
        if (company.length > 0) {
          this.unshareDataviewProfile(company[0].uuId);
        }
      }
      
      const existingIds = typeof this.dataview.sharingMembers !== 'undefined' &&
                          this.dataview.sharingMembers !== ""
                           ? this.dataview.sharingMembers.split(',') : [];
      // remove unshared
      for (var existingId of existingIds) {
        await this.unshareDataviewProfile(existingId);
      } 
      
      this.deleteDataviewProfile(this.id);
      this.confirmDeleteDataviewShow = false;
    },
    removeDataview() {
      this.confirmDeleteDataviewShow = true;
    },
    takeOwnership(dataview) {
      dataview.editingPermissions = this.userId;
    },
    async updateDataviewProfile(dataview) {
      const data = await dataviewProfileService.update([dataview],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        return data[0];
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
        return null;
      });
      return data;
    },
    async unshareDataviewProfile(shareToId) {
        await dataviewProfileService.unshare(this.id, shareToId, this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        return data;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    deleteDataviewProfile(uuId) {
      const self = this;
      dataviewProfileService.remove([{ uuId: uuId }],
                        this.userId).then((response) => {  
        self.profileData = response.data;
        self.$router.push('/dashboard');
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    }, 
    fileExport() {
      
      const self = this;
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('dataview.exporting');
      this.exportData = true;
      
      let listener = () =>{
  
        this.gridApi.exportDataAsExcel({ 
          fileName: this.dataview.name
          , sheetName: this.dataview.name
          , rowHeight: 20
          , processCellCallback: function(params) {
            const index = params.api.columnModel.displayedColumns.findIndex(col => col.getColId() === params.column.colId)
            const field = self.datesMode === 'timeseries' && index !== 0 ? self.timeseries_field : self.fields[index];
            const fieldType = getFieldType(typeof field === 'object' ? field.field : field, self.schema);
            if (fieldType === 'Date') {
              if (Array.isArray(params.value)) {
                let values = [];
                for (const val of params.value) {
                  values.push(moment(val).format('YYYY-MM-DD hh:mm A'));
                }
                return values.join(', ');
              }
              return moment(params.value).format('YYYY-MM-DD hh:mm A');
            }
            else if (fieldType === 'Duration') {
              return convertDurationToDisplay(params.value / 60000, 'D', self.durationConversionOpts);
            }
            else if (fieldType === 'MinuteDuration') {
              return convertDurationToDisplay(params.value / 60000, 'D', self.durationConversionOpts);
            }
            else if (fieldType === 'Progress' ||
                     (typeof field === 'string' && field.endsWith('utilization'))) {
              return params.value ? `${Math.round(params.value * 100)}%` : `0%`;
            }
            else if (fieldType === 'Macro') {
              if (params.column.colId.replace(/ /g, '.').includes('Cost')) {
                const fieldId = params.column.colId;
                const idx = fieldId.lastIndexOf(' ');
                const prefix = fieldId.substring(0, idx);
                const currenyCodeProp = `${prefix} currencyCode`
                const currencyCode = params.node != null 
                                    && params.node.data != null 
                                    && params.node.data[currenyCodeProp] != null
                                    && params.node.data[currenyCodeProp].trim().length > 0? params.node.data[currenyCodeProp].trim() : null;
                return currencyCode == null? `$${costFormat(params.value, {notation: 'standard'})}` : costFormatAdv(params.value, currencyCode, {notation: 'standard'});
              }
              else if (params.column.colId.replace(/ /g, '.').includes('Progress')) {
                return `${Math.round(params.value * 100)}%`;
              }
            }
            else if (fieldType === 'Cost') {
              const fieldId = params.column.colId;
              const idx = fieldId.lastIndexOf(' ');
              const prefix = fieldId.substring(0, idx);
              const currenyCodeProp = `${prefix} ${params.column.colId.indexOf('payAmount') > -1? 'payCurrency' : 'currencyCode'}`
              const currencyCode = params.node != null 
                                  && params.node.data != null 
                                  && params.node.data[currenyCodeProp] != null
                                  && params.node.data[currenyCodeProp].trim().length > 0? params.node.data[currenyCodeProp].trim() : null;
              const _val = params.value == -1? 0 : params.value;                                  
              return currencyCode == null? `$${costFormat(_val, {notation: 'standard'})}` : costFormatAdv(_val, currencyCode, {notation: 'standard'});
            }
            return params.value;
          } 
        });
        this.exportData = false;
        this.inProgressShow = false;
        this.gridApi.removeEventListener('modelUpdated', listener);
      };
      
      if (!this.usingPinnedRows) {
        this.gridApi.refreshServerSide({purge: true});
        this.gridApi.addEventListener('modelUpdated', listener);
      }
      else {
        listener();
      }
    },
    createChart() {
      this.chart = null;
      this.promptChartOptions = true;
    },
    chartModalSuccess(result) {
      const chart = { 
        name: result.name, 
        description: result.description,
        entity: this.entity,
        filter: this.dataview.query, 
        sortfield: this.dataview.sortfield,
        groupfield: this.dataview.groupfield,
        sortdirection: this.dataview.sortdirection,
        dedup: this.dedup, 
        nominate: this.dataview.nominate,
        fields: this.fields, 
        series: result.series,
        axes: result.axes,
        legend: result.legend,
        format: result.format,
        datesMode: result.datesMode,
        dates: result.dates,
        endDate: result.endDate,
        startDate: result.startDate,
        span: result.span,
        epoch: result.epoch,
        useEpoch: result.useEpoch,
        fit: result.fit,
        display_title: result.display_title
      };

      if (chart.series != null && Array.isArray(chart.series)) {
        const series = chart.series;
        this.prepareToolTipRenderer(series);
      }
      
      if (result.id === null) {
        chart.id = `chart${strRandom(10)}`;
        this.charts.push(chart);
        setTimeout(() => {
          this.setActiveTab(this.chartTabIndex + this.charts.length - 1);
        }, 500);
      }
      else {
        chart.id = result.id;
        for (var i = 0; i < this.dataview.charts.length; i++) {
          if (this.dataview.charts[i].id === chart.id) {
            // Save the existing settings
            const span = this.dataview.charts[i].span;
            const startDate = this.dataview.charts[i].startDate;
            const endDate = this.dataview.charts[i].endDate;
            const dates = this.dataview.charts[i].dates;
            this.dataview.charts[i] = chart;
            // apply the saved settings
            this.dataview.charts[i].span = span;
            this.dataview.charts[i].startDate = startDate;
            this.dataview.charts[i].endDate = endDate;
            this.dataview.charts[i].dates = dates;
            break;
          }
        }
      }
      this.dataview.charts = this.charts;
      if (this.dataviewLive) {
        this.dataviewLive.charts = this.charts;
      }
      this.updateDataviewProfile(this.dataview);
      this.createExportJson();
    },
    updateChart(chart) {
      for (var i = 0; i < this.dataview.charts.length; i++) {
        if (this.dataview.charts[i].id === chart.id) {
          this.dataview.charts[i] = chart;
          break;
        }
      }
      this.dataview.charts = this.charts;
      this.updateDataviewProfile(this.dataview);
    },
    editChart(index) {
      this.chart = this.charts[index];
      this.promptChartOptions = true;
    },
    removeChart(index) {
      this.deleteChartIndex = index;
      this.confirmDeleteChartShow = true;
    },
    confirmDeleteChartOk() {
      // Upon deleting the last chart the last tab will be selected
      // so we need to make sure that the ids are loaded
      if (this.charts.length === 1) {
        if ((this.staffIds === null &&
            (this.isEntity('STAFF') || this.isEntity('DEPARTMENT') || this.isEntity('LOCATION') || this.isEntity('COMPANY') || this.isEntity('SKILL'))) ||
            (this.taskIds === null &&
            this.isEntity('TASK'))) {
            loadIds(this, this.dataview.entity);
        }
        this.setActiveTab(this.activeTab - 1);
      }
      this.charts.splice(this.deleteChartIndex, 1);
      this.dataview.charts = this.charts;
      this.updateDataviewProfile(this.dataview);
      this.confirmDeleteChartShow = false;
    },
    async editOpen(isNew) {
      const entitylist = this.entity.split('.');
      const entity = entitylist[entitylist.length - 1];
      
      if (entity === 'TASK') {
        this.taskEditOpen(isNew);
      }
      else if (entity === 'BOOKING') {
        this.bookingEditOpen(isNew);
      }
      else if (entity === 'ACTIVITY') {
        this.activityEditOpen(isNew);
      }
      else if (entity === 'TASK_TEMPLATE') {
        this.taskTemplateEditOpen(isNew);
      }
      else if (entity === 'PROJECT') {
        this.projectEditOpen(isNew);
      }
      else if (entity === 'PROJECT_TEMPLATE') {
        this.projectTemplateEditOpen(isNew);
      }
      else if (entity === 'CUSTOMER') {
        this.customerEditOpen(isNew);
      }
      else if (entity === 'CONTACT') {
        this.contactEditOpen(isNew);
      }
      else if (entity === 'DEPARTMENT') {
        this.departmentEditOpen(isNew);
      }
      else if (entity === 'LOCATION') {
        this.locationEditOpen(isNew);
      }
      else if (entity === 'RESOURCE') {
        this.resourceEditOpen(isNew);
      }
      else if (entity === 'SKILL') {
        this.skillEditOpen(isNew);
      }
      else if (entity === 'STAFF') {
        this.staffEditOpen(isNew);
      }
      else if (entity === 'USER') {
        this.userEditOpen(isNew);
      }
      else if (entity === 'STAGE') {
        this.stageEditOpen(isNew);
      }
      else if (entity === 'NOTE') {
        this.noteEditOpen(isNew);
      }
      else if (entity === 'REBATE') {
        this.rebateEditOpen(isNew);
      }
      else if (entity === 'STORAGE_FILE') {
        this.fileEditOpen(isNew);
      }
      else if (entity === 'COMPANY') {
        if (this.masterCompany === null) {
          this.masterCompany = await companyService.list({ limit: -1, start: 0 }).then((response) => {
            const data = response.data;
            const company = data.filter(d => d.type === 'Primary');
            return company[0];
          });
        }
        this.companyEditOpen(isNew);
      }
    },
    async editOpenId(id, data) {
      const entitylist = this.entity.split('.');
      const entity = entitylist[entitylist.length - 1];
      
      if (entity === 'TASK') {
        this.taskEditOpenId(id, data);
      }
      else if (entity === 'BOOKING') {
        this.bookingEditOpenId(id, data);
      }
      else if (entity === 'ACTIVITY') {
        this.activityEditOpenId(id, data);
      }
      else if (entity === 'TASK_TEMPLATE') {
        this.taskTemplateEditOpenId(id, data);
      }
      else if (entity === 'PROJECT') {
        this.projectEditOpenId(id);
      }
      else if (entity === 'PROJECT_TEMPLATE') {
        this.projectTemplateEditOpenId(id);
      }
      else if (entity === 'CUSTOMER') {
        this.customerEditOpenId(id);
      }
      else if (entity === 'CONTACT') {
        this.contactEditOpenId(id);
      }
      else if (entity === 'DEPARTMENT') {
        this.departmentEditOpenId(id);
      }
      else if (entity === 'LOCATION') {
        this.locationEditOpenId(id);
      }
      else if (entity === 'RESOURCE') {
        this.resourceEditOpenId(id);
      }
      else if (entity === 'SKILL') {
        this.skillEditOpenId(id);
      }
      else if (entity === 'STAFF') {
        this.staffEditOpenId(id, data['STAFF genericStaff']);
      }
      else if (entity === 'USER') {
        this.userEditOpenId(id);
      }
      else if (entity === 'STAGE') {
        this.stageEditOpenId(id);
      }
      else if (entity === 'NOTE') {
        this.noteEditOpenId(id);
      }
      else if (entity === 'REBATE') {
        this.rebateEditOpenId(id);
      }
      else if (entity === 'STORAGE_FILE') {
        this.fileEditOpenId(id, data);
      }
      else if (entity === 'COMPANY') {
        if (this.masterCompany === null) {
          this.masterCompany = await companyService.list({ limit: -1, start: 0 }).then((response) => {
            const data = response.data;
            const company = data.filter(d => d.type === 'Primary');
            return company[0];
          });
        }
        this.companyEditOpenId(id, data);
      }
    },
    modalSuccess(payload) {
      this.updateGrid(); 
      this.resetAlert({ msg: payload.msg });
      this.gridApi.deselectAll();
    },
    stageEditOpen(isNew) {
      if(isNew) {
        this.stageId = `STAGE_NEW_${strRandom(5)}`;
      } else {
        this.stageId = this.selected[0];
      }
      this.stageShow = true;
      this.resetAlert();
    },
    stageEditOpenId(id) {
      this.stageId = id;
      this.stageShow = true;
    },
    noteEditOpen(/** isNew */) {
      this.commentId = this.selected[0];
      
      this.modalCommentShow = true;
      this.resetAlert();
    },
    noteEditOpenId(id) {
      this.commentId = id;
      this.modalCommentShow = true;
    },
    rebateEditOpen(/** isNew */) {
      this.rebateId = this.selected[0];
      
      this.modalRebateShow = true;
      this.resetAlert();
    },
    rebateEditOpenId(id) {
      this.rebateId = id;
      this.modalRebateShow = true;
    },
    fileEditOpen(isNew) {
      if (isNew) {
        this.fileParentData = null;
        this.fileDetailsId = 'FILE_NEW';
      }
      else {
        if (this.gridApi == null || this.gridApi.isDestroyed()) {
          return;
        }
        const selectedNodes = this.gridApi.getSelectedNodes().map(i => { return { uuId: i.data.FOLDERuuId, name: i.data.FOLDERname }});
        this.fileParentData = {
                            uuId: selectedNodes[0].uuId,
                            name: selectedNodes[0].name
                          };
        this.fileDetailsId = this.selected[0];
      }
      this.fileDetailsShow = true;
      this.resetAlert();
    },
    fileEditOpenId(id, data) {
      this.fileParentData = {
                          uuId: data.FOLDERuuId,
                          name: data.FOLDERname
                        };
      this.fileDetailsId = id;
      this.fileDetailsShow = true;
    },
    companyEditOpen(isNew) {
      if(isNew) {
        this.companyId = `COMPANY_NEW_${strRandom(5)}`;
        if (this.gridApi == null || this.gridApi.isDestroyed()) {
          return;
        }
        // for a new company the selected node will be the parent of the new company
        if (this.gridApi.getSelectedNodes().length !== 0) {
          this.selectedParent = this.gridApi.getSelectedNodes()[0].data;
        }
        else {
          this.selectedParent = this.masterCompany;
        }
      } else {
        this.companyId = this.selected[0];
      }
      this.companyShow = true;
      this.resetAlert();
    },
    companyEditOpenId(id /**, data */) {
      this.companyId = id;
      this.companyShow = true;
      this.resetAlert();
    },
    userEditOpen(isNew) {
      if(isNew) {
        this.userModalId = `USER_NEW_${strRandom(5)}`;
      } else {
        this.userModalId = this.selected[0];
      }
      this.userShow = true;
      this.resetAlert();
    },
    userEditOpenId(id) {
      this.userModalId = id;
      this.userShow = true;
    },
    staffEditOpen(isNew) {
      if (this.gridApi == null || this.gridApi.isDestroyed()) {
        return;
      }
      if(isNew) {
        this.staffId = `STAFF_NEW_${strRandom(5)}`;
      } else {
        if (typeof this.selected[0] === 'string') {
          this.staffId = this.selected[0];
          const selectedNodes = this.gridApi.getSelectedNodes().map(i => { return { genericStaff: i.data['STAFF genericStaff'] }});
          if (selectedNodes.length > 0) {
            this.isGeneric = selectedNodes[0].genericStaff;
          }
        }
        else {
          this.staffId = this.selected[0].uuId;
          this.isGeneric = this.selected[0]['STAFF genericStaff'];
        }
      }
      this.staffShow = true;
      this.resetAlert();
    },
    staffEditOpenId(id, isGeneric) {
      this.staffId = id;
      this.isGeneric = isGeneric;
      this.staffShow = true;
    },
    skillEditOpen(isNew) {
      if(isNew) {
        this.skillId = `SKILL_NEW_${strRandom(5)}`;
      } else {
        this.skillId = this.selected[0];
      }
      this.skillShow = true;
      this.resetAlert();
    },
    skillEditOpenId(id) {
      this.skillId = id;
      this.skillShow = true;
    },
    resourceEditOpen(isNew) {
      if(isNew) {
        this.resourceId = `RESOURCE_NEW_${strRandom(5)}`;
      } else {
        this.resourceId = this.selected[0];
      }
      this.resourceShow = true;
      this.resetAlert();
    },
    resourceEditOpenId(id) {
      this.resourceId = id;
      this.resourceShow = true;
    },
    customerEditOpen(isNew) {
      if(isNew) {
        this.customerId = `CUSTOMER_NEW_${strRandom(5)}`;
      } else {
        this.customerId = this.selected[0];
      }
      this.customerShow = true;
      this.resetAlert();
    },
    customerEditOpenId(id) {
      this.customerId = id;
      this.customerShow = true;
    },
    contactEditOpen(isNew) {
      if(isNew) {
        this.contactId = `CONTACT_NEW_${strRandom(5)}`;
        this.contactParentId = null;
        this.customerSelectorShow = true;
      } else {
        this.contactId = this.selected[0];
        this.contactShow = true;
      }
      this.resetAlert();
    },
    contactEditOpenId(id) {
      this.contactId = id;
      this.contactShow = true;
    },
    departmentEditOpen(isNew) {
      if(isNew) {
        this.departmentId = `DEPARTMENT_NEW_${strRandom(5)}`;
        
        // for a new department the selected node will be the parent of the new department
        if (this.selected.length !== 0) {
          this.selectedParent = this.selected[0];
        }
        else {
          this.selectedParent = null;
        }
      } else {
        this.departmentId = this.selected[0];
      }
      this.departmentShow = true;
      this.resetAlert();
    },
    departmentEditOpenId(id) {
      this.departmentId = id;
      this.departmentShow = true;
    },
    locationEditOpen(isNew) {
      if(isNew) {
        this.locationId = `LOCATION_NEW_${strRandom(5)}`;
      } else {
        this.locationId = this.selected[0];
      }
      this.locationShow = true;
      this.alertMsg;
    },
    locationEditOpenId(id) {
      this.locationId = id;
      this.locationShow = true;
    },
    projectEditOpen(isNew) {
      if(isNew) {
        this.projectId = `PROJECT_NEW_${strRandom(5)}`;
      } else {
        this.projectId = this.selected[0];
      }
      this.projectShow = true;
      this.resetAlert();
    },
    projectEditOpenId(id) {
      this.projectId = id;
      this.projectShow = true;
    },
    projectTemplateEditOpen(isNew) {
      if(isNew) {
        this.projectTemplateId = `TEMPLATE_NEW_${strRandom(5)}`;
      } else {
        this.projectTemplateId = this.selected[0];
      }
      this.projectTemplateShow = true;
      this.resetAlert();
    },
    projectTemplateEditOpenId(id) {
      this.projectTemplateId = id;
      this.projectTemplateShow = true;
    },
    taskEditOpen(isNew) {
      if (this.gridApi == null || this.gridApi.isDestroyed()) {
        return;
      }
      if(isNew) {
        this.taskEdit.uuId = `TASK_NEW_${strRandom(5)}`;
        this.taskEdit.parentId = null;
        this.projectSelectorShow = true;
      } else {
        const selectedId = this.selected[0];
        const selectedNodes = this.gridApi.getSelectedNodes().map(i => { return { uuId: i.data.PROJECTuuId, name: i.data.PROJECTname }});
        this.projectName = selectedNodes[0].name;
        this.taskProjectId = selectedNodes[0].uuId;
        this.taskEdit.uuId = selectedId;
        this.taskEdit.parentId = null;
        this.taskEditShow = true;
      }
      this.resetAlert();
    },
    taskEditOpenId(id, data) {
      const selectedId = id;
      this.projectName = data.PROJECTname;
      this.taskProjectId = data.PROJECTuuId;
      this.taskEdit.uuId = selectedId;
      this.taskEdit.parentId = null;
      this.taskEditShow = true;
    },
    bookingEditOpen(isNew) {
      if (this.gridApi == null || this.gridApi.isDestroyed()) {
        return;
      }
      if(isNew) {
        this.bookingEdit.uuId = `BOOKING_NEW_${strRandom(5)}`;
        this.bookingEdit.parentId = null;
        this.confirmBookingTypeShow = true;
      } else {
        const selectedId = this.selected[0];
        const selectedNodes = this.gridApi.getSelectedNodes().map(i => { return { uuId: i.data.PROJECTuuId, name: i.data.PROJECTname }});
        this.projectName = selectedNodes[0].name;
        this.bookingProjectId = selectedNodes[0].uuId;
        this.bookingEdit.uuId = selectedId;
        this.bookingEdit.parentId = null;
        this.bookingEditShow = true;
      }
      this.resetAlert();
    },
    confirmBookingTypeOk() {
      this.confirmBookingTypeShow = false;
      this.bookingEditShow = true;
    },
    bookingEditOpenId(id, data) {
      const selectedId = id;
      this.projectName = data.PROJECTname;
      this.bookingProjectId = data.PROJECTuuId;
      this.bookingEdit.uuId = selectedId;
      this.bookingEdit.parentId = null;
      this.bookingEditShow = true;
    },
    activityEditOpen(isNew) {
      if(isNew) {
        this.activityEdit.uuId = `ACTIVITY_NEW_${strRandom(5)}`;
        this.activityEdit.parentId = null;
        this.confirmActivityTypeShow = true;
      } else {
        const selectedId = this.selected[0];
        this.activityEdit.uuId = selectedId;
        this.activityEditShow = true;
      }
      this.resetAlert();
    },
    confirmActivityTypeOk() {
      this.confirmActivityTypeShow = false;
      this.activityEditShow = true;
    },
    activityEditOpenId(id/**, data */) {
      const selectedId = id;
      this.activityEdit.uuId = selectedId;
      this.activityEdit.parentId = null;
      this.activityEditShow = true;
    },
    taskTemplateEditOpen(isNew) {
      if (this.gridApi == null || this.gridApi.isDestroyed()) {
        return;
      }
      if(isNew) {
        this.taskTemplateEdit.uuId = `TASK_NEW_${strRandom(5)}`;
        this.taskTemplateEdit.parentId = null;
        this.projectTemplateSelectorShow = true;
      } else {
        const selectedId = this.selected[0];
        const selectedNodes = this.gridApi.getSelectedNodes().map(i => { return { uuId: i.data.PROJECT_TEMPLATEuuId, name: i.data.PROJECT_TEMPLATEname }});
        this.projectName = selectedNodes[0].name;
        this.taskProjectId = selectedNodes[0].uuId;
        this.taskTemplateEdit.uuId = selectedId;
        this.taskTemplateEdit.parentId = null;
        this.taskTemplateEditShow = true;
      }
      this.resetAlert();
    },
    taskTemplateEditOpenId(id, data) {
      const selectedId = id;
      this.projectName = data.PROJECT_TEMPLATEname;
      this.taskProjectId = data.PROJECT_TEMPLATEuuId;
      this.taskTemplateEdit.uuId = selectedId;
      this.taskTemplateEdit.parentId = null;
      this.taskTemplateEditShow = true;
    },
    rowDelete() {
      this.resetAlert();
      this.confirmDeleteShow = true;
    },
    async confirmDeleteOk(){ 
      if (this.gridApi == null || this.gridApi.isDestroyed()) {
        return;
      }
      //1) Show in-progress message.
      const entitylist = this.entity.split('.');
      const entity = entitylist[entitylist.length - 1];
      const nameKey = `${entitylist.join(' ')} name`;
      let msg = null;
      let entityType = null;
      switch(entity) {
        case 'TASK':
        case 'BOOKING':
        case 'ACTIVITY':
        case 'PROJECT':
        case 'CUSTOMER':
        case 'LOCATION':
        case 'RESOURCE':
        case 'SKILL':
        case 'DEPARTMENT':
        case 'STAFF':
        case 'USER':
        case 'STAGE':
        case 'COMPANY':
          msg = this.$t('delete_in_progress_with_argument', [this.$t(`entityType.${entity}`).toLowerCase()]);
          entityType = entity.toLowerCase();
          break;
        case 'STORAGE_FILE':
          msg = this.$t('delete_in_progress_with_argument', [this.$t('entityType.FILE').toLowerCase()]);
          entityType = 'file';
          break;
        
        case 'PROJECT_TEMPLATE':
          msg = this.$t('delete_in_progress_with_argument', [this.$t('entityType.TEMPLATE').toLowerCase()]);
          entityType = 'template';
          break;
        default:
          msg = this.$t('delete_in_progress');
          break;
      }
      this.showInProgress(msg);
      this.actionProcessing = true;
      this.confirmDeleteShow = false;

      //2) Prepare delete data
      const selectedNodes = this.gridApi.getSelectedNodes();
      const toDeleteIdNames = selectedNodes.map(node => { return { uuId: node.data.uuId, name: node.data[nameKey] != null? node.data[nameKey] : null } });
      const toDeleteIds = this.selected.map(i => { return { uuId: i } });

      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`${entityType}.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];

      //2.5) Stop proceed further if no item is selected
      if (toDeleteIds.length == 0)  {
        this.inProgressShow = false;
        this.updateGrid();
        this.resetAlert({ msg: this.$t(entityType == null? "delete_nothing" : "dataview.delete_nothing") });
        return;
      }
      
      //3) Call API to delete data
      if (entity === 'STORAGE_FILE') {
        const requests = [];
        for (let i = 0, len = toDeleteIds.length; i < len; i++) {
          requests.push(fileService.remove(toDeleteIds[i].uuId));
        }
        
        await Promise.all(requests.map(p => p.catch(e => e)))
        .then((responses) => {
          const failedNames = [];
          for (let j = 0, jLen = responses.length; j < jLen; j++) {
            if (!(responses[j] instanceof Error)) {
              continue;
            }
            failedNames.push(toDeleteIdNames[j].name);
          }
          if (failedNames.length > 0) {
            alertState = alertStateEnum.WARNING;
            alertMsg = this.$t(`file.delete_partial`);
            if (failedNames.length == toDeleteIds.length) {
              alertState = alertStateEnum.ERROR;
              alertMsg = this.$t(`file.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
            } 
            const hasNullName = failedNames.some(item => item == null);
            if (!hasNullName) {
              alertMsgDetailTitle = this.$t(`file.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
              alertMsgDetailList.splice(0, alertMsgDetailList.length, ...failedNames);
            }
          }
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
          alertState = alertStateEnum.ERROR;
          alertMsg = this.$t(`file.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
        });
      } else {
        await this.services[entity].remove(toDeleteIds)
        .then(response => {
          if (response.status == 207) {
            alertState = alertStateEnum.WARNING;
            alertMsg = this.$t(`${entityType}.delete_partial`);
            alertMsgDetailTitle = this.$t(`${entityType}.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
            const feedbackList = response.data[response.data.jobCase];
            for (let i = 0, len = feedbackList.length; i < len; i++) {
              const feedback = feedbackList[i];
              if (feedback.clue == 'OK') {
                continue;
              }
              const targetId = toDeleteIds[i].uuId;
              const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
              alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : null);
            }
          }
        })
        .catch(e => {
          alertState = alertStateEnum.ERROR;
          alertMsg = this.$t(`${entityType}.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
          if (e.response) {
            const response = e.response;
            if (response.status == 422) {
              alertMsgDetailTitle = this.$t(`${entityType}.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
              const feedbackList = response.data[response.data.jobCase];
              for (let i = 0, len = feedbackList.length; i < len; i++) {
                const feedback = feedbackList[i];
                if (feedback.clue == 'OK') {
                  continue;
                }
                const targetId = toDeleteIds[i].uuId;
                const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
                alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : null);
              }
            } else if (403 === response.status) {
              alertMsg = this.$t('error.authorize_action');
            }
          }
        });
      }
      if (this.gridApi == null || this.gridApi.isDestroyed()) {
        return;
      }

      if (alertState !== alertStateEnum.ERROR) {
        this.viewDataview({ data: this.dataview, toResetAlert: false });
        this.gridApi.deselectAll();
      }
      
      const alertPayload = {
        msg: alertMsg,
        alertState: alertState
      }
      if (alertMsgDetailList.length > 0) {
        const hasNullName = alertMsgDetailList.some(name => name == null);
        if (!hasNullName) {
          alertPayload.details = alertMsgDetailList;
          alertPayload.detailTitle = alertMsgDetailTitle;
        }
      }
      this.resetAlert(alertPayload);
      this.inProgressShow = false;
      this.actionProcessing = false;
    },
    projectSelectorOk({ details }) {
      if(details && details.length > 0) {
        this.taskProjectId = details[0].uuId;
        this.projectName = details[0].name;
        this.taskEdit.parentId = null;
        this.taskEditShow = true;
      }
    },
    projectTemplateSelectorOk({ details }) {
      if(details && details.length > 0) {
        this.taskProjectId = details[0].uuId;
        this.projectName = details[0].name;
        this.taskTemplateEdit.parentId = null;
        this.taskTemplateEditShow = true;
      }
    },
    customerSelectorOk({ details }) {
      if(details && details.length > 0) {
        this.contactParentId = details[0].uuId;
        this.contactShow = true;
      }
    },
    chartExport(title) {
      document.querySelector(".chart-style canvas").toBlob(function(blob) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = title.includes('.') ? `${title}.png` : title;
        link.click();
        URL.revokeObjectURL(link.href);
      }, 'image/octet-stream');
    },
    onChartError(error) {
      httpAjaxError(error, this);
    },
    onGanttError(error) {
      httpAjaxError(error, this);
    },
    onGanttMsg({ msg, alertState=alertStateEnum.SUCCESS } = {}) {
      this.resetAlert({ msg, alertState });
    },
    onGanttResetAlert() {
      this.resetAlert();
    },
    dataViewNavClick(event) {
      /**
       * Update activeTab with the user choice.
       * Update the active state/style of links.
       * 
       * Due to the priorityNav feature, the collapsed links will be relocated under different <ul> element.
       * To get the full list of links, get all the <li> elements under .data-view-nav element.
       */
      const liElem = event.srcElement.closest('li');
      const activeName = liElem.getAttribute('name');
      const dataViewNavElem = event.srcElement.closest('.data-view-nav');
      const childs = dataViewNavElem.querySelectorAll('li');
      
      // clear the view name from the path
      this.$store.dispatch("breadcrumb/clearView");
      
      const tabList = this.tabList;
      for(let i = 0, len = tabList.length; i < len; i++) {
        const tab = tabList[i];
        if(tab.name == activeName) {
          this.activeTab = i;
          break;
        }
      }

      for (let i = 0; i < childs.length; i++) {
        if (this.activeTab === i) {
          childs[i].classList.add('active');
        } else if(childs[i] !== null && childs[i].classList !== null) {
          childs[i].classList.remove('active');
        }
      }
    },
    setActiveTab(index) {
      const dataViewNavElem = document.querySelector('.data-view-nav');
      if (dataViewNavElem === null) {
        return;
      }
      const childs = dataViewNavElem.querySelectorAll('li');

      this.activeTab = index;


      for (let i = 0; i < childs.length; i++) {
        if (this.activeTab === i) {
          childs[i].classList.add('active');
        } else if(childs[i] !== null && childs[i].classList !== null) {
          childs[i].classList.remove('active');
        }
      }
    },
    initializeViewProfile() {
      if (!Object.prototype.hasOwnProperty.call(this.settings, 'sheetColumns')) {
        this.settings.sheetColumns = [];
      }
    },
    createViewProfile() {
      this.initializeViewProfile();
      layoutProfileService.create([this.settings], this.entityId, this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async updateViewProfile() {
      if (!Object.prototype.hasOwnProperty.call(this.settings, 'uuId')) {
        // Dataviews are triggering the watchers when opening the
        // relevant tab and trying to save. Ignore those since nothing
        // has loaded yet.
        return;
      }
      await layoutProfileService.update([this.settings], this.entityId, this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadViewProfile() {
      const self = this;
      await layoutProfileService.list(this.entityId, this.userId).then((response) => {
        const profileData = response.data[response.data.jobCase];
        if (profileData.length === 0) {
          self.createViewProfile();
        } else {
          self.settings = profileData[0];
          self.initializeViewProfile();
          self.timeseries_hint_donotshow = self.settings.timeseries_hint_donotshow;
          self.loadSettings();
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      })
    },
    loadColumnSettings() {
      const columns = this.settings.sheetColumns;
      if (columns == null || columns.length == 0) {
        // The user doesn't have custom columns yet, so use defaults
        return;
      }
      
      for (const column of this.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = false; // do not hide new fields
        }
        else {
          column.hide = typeof setting[0].hide !== 'undefined' ? setting[0].hide : false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }

      return false;
    },
    prepareToolTipRenderer(series) {
      for (let i = 0, len = series.length; i < len; i++) {
        const chartType = series[i].type;

        const formatValue = (key, value) => { return formatFieldValue(key, value, this.schema, { durationFunc: (v) => `${v}D`, percentageFunc: (v) => `${v}%` }, this.durationConversionOpts) };

        if (chartType === 'histogram') {
          series[i].tooltip = {
            enabled: true,
            renderer: params => {
              return { 
                title: `${params.xName}: ${formatValue(params.xKey, params.xValue[0])}-${formatValue(params.xKey, params.xValue[1])}`
                , content: `${this.$t('dataview.chart.frequency')}: ${params.yValue}`
              }
            }
          }
        } else if (chartType === 'pie' || chartType === 'doughnut') {
          series[i].tooltip = {
            enabled: true,
            renderer: params => {
              return { 
                title: formatValue(params.labelKey, params.datum[params.labelKey])
                , content: `${params.title != null && params.title.trim().length > 0? params.title + ': ' : ''}${formatValue(params.angleKey, params.angleValue)}`
              }
            }
          }
        } else if (chartType === 'bar' || chartType === 'area' || chartType === 'column' || chartType === 'line' || chartType === 'scatter') {
          series[i].tooltip = {
            enabled: true,
            renderer: params => {
              let _title = null;
              const tokens = params.xKey.split(' ');
              if (tokens.length > 1) {
                _title = tokens.reduce((acc, curValue) => {
                  if (acc != null) {
                    return `${acc} ${formatValue(curValue, params.datum[curValue])}`;
                  }
                  return `${formatValue(curValue, params.datum[curValue])}`;
                }, null);
              } else {
                _title = formatValue(params.xKey, params.xValue);
              }
              return { 
                title: _title
                , content: `${params.yName != null && params.yName.trim().length > 0? params.yName + ': ' : ''}${formatValue(params.yKey, params.yValue)}`
              }
            }
          }
        } else if (chartType === 'bubble') {
          series[i].tooltip = {
            enabled: true,
            renderer: params => {
              return { 
                title: formatValue(params.xKey, params.xValue)
                , content: `<div>${params.yName != null && params.yName.trim().length > 0? params.yName + ': ' : ''}${formatValue(params.yKey, params.yValue)}</div>` +
                            `<div>${params.sizeName != null && params.sizeName.trim().length > 0? params.sizeName + ': ' : ''}${formatValue(params.sizeKey, params.datum[params.sizeKey])}</div>`
              }
            }
          }
        }
        
      }
    },
    createExportJson() {
      const exportDataview = cloneDeep(this.dataview);
      delete exportDataview.uuId;
      exportDataview.sharedVisibility = 'private';
      delete exportDataview.sharingMembers;
      delete exportDataview.editingPermissions;
      var json = JSON.stringify(exportDataview);
      var blob = new Blob([json], {type: "application/json"});
      this.exportJson = window.URL.createObjectURL(blob);
      const elem = document.querySelector('.sidebar-nav .active .action-container-class a[download]');
      if (elem) {
        elem.href = this.exportJson;
      }
    },
    async rangeSelected() {
      this.dates = this.datesStr == 'null'? null : this.datesStr;
      this.datesChanged();
      this.saveSettings();
      this.updateGrid(); 
    },
    updateGrid() {
      if (this.dataviewLive) {
        // use the user's live values
        this.recalculate();
        return;
      }
      this.viewDataview({ data: this.dataview }); 
    },
    async daySelected() {
      const self = this;
      let start = new Date(self.startDate);
      const end = new Date(self.endDate);
      if (end < start) {
        // do not allow start to be greater than end
        if (self.span === 'Daily') {
          start.setDate(start.getDate() + 1);
        }
        else if (self.span === 'Weekly') {
          start.setDate(start.getDate() + 7);
        }
        else if (self.span === 'Monthly') {
          const month = start.getMonth();
          start.setMonth(month + 1);
        }
        else if (self.span === 'Yearly') {
          const year = start.getFullYear();
          start.setYear(year + 1);
        }
        self.$nextTick(() => {
          self.$set(self, 'endDate', start.toISOString().split('T')[0]);
        });
        return;
      }
      const epochResult = calcEpochs(this.span, this.startDate, this.endDate);
      this.epochs = epochResult.epochs;
      if (this.epoch && this.epochs.length > 0) {
        if (!this.epochs.includes(this.epoch)) {
          this.epoch = this.epochs[0];
        }
      }
      else {
        this.epoch = null;
        this.datesMode = "timeseries";
      }
      this.dates = null;
      this.datesStr = 'null';
      this.saveSettings();
      this.updateGrid(); 
    },
    async updateSpan() {
      const epochResult = calcEpochs(this.span, this.startDate, this.endDate);
      this.epochs = epochResult.epochs;
      if (this.epoch && this.epochs.length > 0) {
        if (!this.epochs.includes(this.epoch)) {
          this.epoch = this.epochs[0];
        }
      }
      else {
        this.epoch = null;
        this.datesMode = "timeseries";
      }
      this.saveSettings();
      this.updateGrid(); 
    },
    datesChanged() {
      const self = this;
      this.datesChanging = true;
      if (this.dates === "project-start-to-end") {
        if (this.project === null || typeof this.projectData.scheduleStart === 'undefined' || typeof this.projectData.scheduleFinish === 'undefined') {
          this.dates = null;
          this.datesStr = 'null';
          let today = new Date();
          this.startDate = formatDate(today);
          today.setDate(today.getDate() + 7);
          this.endDate = formatDate(today);
        }
        else {
          this.startDate = formatDate(new Date(this.projectData.scheduleStart));
          this.endDate = formatDate(new Date(this.projectData.scheduleFinish));
        }
      } 
      else if (this.dates === "project-schedule") {
        if (this.min === 0 || this.max === 0 ||
            this.min === null || this.max === null ||
            this.min === 'NaN' || this.max === 'NaN') {
          this.dates = null;
          this.datesStr = 'null';
          let today = new Date();
          this.startDate = formatDate(today);
          today.setDate(today.getDate() + 7);
          this.endDate = formatDate(today);
        }
        else {
          // make sure the span is less than 3 years
          const diff = this.max - this.min;
          if (diff > 94670778000) {
            this.max = this.min + (94670778000 - 86400000);
          }
          this.startDate = formatDate(new Date(this.min));
          this.endDate = formatDate(new Date(this.max));
        }
      } 
      else if (this.dates === "this-week") {
        let today = getNextWorkingDay(new Date());
        const day = today.getDay();
        if (day !== 1) {
          if (day === 0) {
            today.setDate(today.getDate() + 1); // Monday
          }
          else {
            today.setDate(today.getDate() - (day - 1)); // Monday
          }
        }
        this.startDate = formatDate(today);
        
        // Sunday
        today.setDate(today.getDate() + 6); 
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "this-week-to-date") {
        let today = getNextWorkingDay(new Date());
        this.endDate = formatDate(today);
        
        const day = today.getDay();
        if (day !== 1) {
          if (day === 0) {
            today.setDate(today.getDate() + 1); // Monday
          }
          else {
            today.setDate(today.getDate() - (day - 1)); // Monday
          }
        }
        this.startDate = formatDate(today);
      } 
      else if (this.dates === "this-month") {
        let today = new Date();
        this.endDate = formatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        
        this.startDate = formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
      } 
      else if (this.dates === "this-month-to-date") {
        let today = new Date();
        this.endDate = formatDate(today);
        
        this.startDate = formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
      } 
      else if (this.dates === "this-quarter") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth()) / 3);
        
        let thisq;
        if (quarter == 4) {
            thisq = new Date (today.getFullYear(), 9, 1); // start in October
        } else {
            thisq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(thisq);
        const lastday = new Date(thisq.getFullYear(), thisq.getMonth() + 3, 0);
        this.endDate = formatDate(lastday);
      } 
      else if (this.dates === "this-quarter-to-date") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth()) / 3);
        
        let thisq;
        if (quarter == 4) {
            thisq = new Date (today.getFullYear(), 9, 1); // start in October
        } else {
            thisq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(thisq);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "this-year") {
        let today = new Date();
        const year = today.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
        this.endDate = [year, '12', '31'].join('-');
      } 
      else if (this.dates === "this-year-to-date") {
        let today = new Date();
        this.endDate = formatDate(today);
        const year = today.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
      } 
      else if (this.dates === "last-week") {
        let today = new Date();
             
        const day = today.getDay();
        today.setDate(today.getDate() - day); // Sunday
        this.endDate = formatDate(today);
        
        today.setDate(today.getDate() - 6); // Monday
        this.startDate = formatDate(today);
      } 
      else if (this.dates === "last-week-to-date") {
        let today = new Date();
        this.endDate = formatDate(today);
        const day = today.getDay();
        today.setDate(today.getDate() - day); // Sunday
        today.setDate(today.getDate() - 6); // Monday
        this.startDate = formatDate(today);
      } 
      else if (this.dates === "last-month") {
        let today = new Date();
        this.endDate = formatDate(new Date (today.getFullYear(), today.getMonth(), 0));        
        this.startDate = formatDate(new Date (today.getFullYear(), today.getMonth() - 1, 1));
      } 
      else if (this.dates === "last-month-to-date") {
        let today = new Date();
        this.endDate = formatDate(today);
            
        this.startDate = formatDate(new Date (today.getFullYear(), today.getMonth() - 1, 1));
      } 
      else if (this.dates === "last-quarter") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth()) / 3);
        quarter > 0 ? quarter-- : quarter = 4;
        
        let lastq;
        if (quarter == 4) {
            lastq = new Date (today.getFullYear() - 1, 9, 1); // start in October
        } else {
            lastq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(lastq);
        this.endDate = formatDate(new Date (today.getFullYear(), lastq.getMonth() + 3, 0));
      } 
      else if (this.dates === "last-quarter-to-date") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth()) / 3);
        quarter > 0 ? quarter-- : quarter = 4;
        
        let lastq;
        if (quarter == 4) {
            lastq = new Date (today.getFullYear() - 1, 9, 1); // start in October
        } else {
            lastq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(lastq);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "last-year") {
        const b = new Date();
        b.setFullYear(b.getFullYear() - 1);
        const year = b.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
        this.endDate = [year, '12', '31'].join('-');
      } 
      else if (this.dates === "next-week") {
        let today = getNextWorkingDay(new Date());
        const day = today.getDay();
        
        today.setDate(today.getDate() + (8 - day)); // Monday
        this.startDate = formatDate(today);
        today.setDate(today.getDate() + 6); // Sunday
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-4-weeks") {
        let today = getNextWorkingDay(new Date());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        this.startDate = formatDate(today);
        today.setDate((today.getDate() + 4 * 7) - 1);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-month") {
        let today = new Date();
        const nextm = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        this.startDate = formatDate(nextm);
        const lastday = new Date(nextm.getFullYear(), nextm.getMonth() + 1, 0);
        this.endDate = formatDate(lastday);
      } 
      else if (this.dates === "next-quarter") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth() + 3) / 3);
        var nextq;
        if (quarter == 4) {
            nextq = new Date (today.getFullYear() + 1, 1, 1);
        } else {
            nextq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(nextq);
        const lastday = new Date(nextq.getFullYear(), nextq.getMonth() + 3, 0);
        this.endDate = formatDate(lastday);
      } 
      else if (this.dates === "next-year") {
        const b = new Date();
        b.setFullYear(b.getFullYear() + 1);
        const year = b.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
        this.endDate = [year, '12', '31'].join('-');
      }
      Vue.nextTick(() => {
        self.datesChanging = false;
      });
      
      self.saveSettings();
    },
    dateHeaderClick(value) {
      this.epoch = value;
      this.updateGrid();
    },
    defaultSettings() {
      const self = this;
      // Delete the user's settings if they exist
      if (typeof self.settings.dataview_settings !== 'undefined' &&
          typeof self.settings.dataview_settings[self.uuId] !== 'undefined') {
        delete self.settings.dataview_settings[self.uuId];
      }

      // load the settings from the dataview
      if (!this.dataview.settings) {
        this.dataview.settings = {};
      }
      self.span = self.dataview.settings.span ? self.dataview.settings.span : "Daily";
      self.startDate = self.dataview.settings.startDate;
      self.endDate = self.dataview.settings.endDate;
      self.dates = typeof self.dataview.settings.dates !== 'undefined' ? self.dataview.settings.dates : "this-week";
      self.datesStr = self.dates == null? 'null' : self.dates;
      self.datesMode = typeof self.dataview.settings.datesMode !== 'undefined' ? self.dataview.settings.datesMode : "current";
      self.epoch = self.dataview.settings.epoch;
      self.timeseries_field = self.dataview.settings.timeseries_field ? self.dataview.settings.timeseries_field : null;
      self.timeseries_label = self.dataview.settings.timeseries_label ? self.dataview.settings.timeseries_label : null;
      self.datesChanged();
      
      this.updateGrid();
    },
    loadSettings() {
      const self = this;
      // Load the user's settings if they exist
      if (typeof self.settings.dataview_settings !== 'undefined' &&
          typeof self.settings.dataview_settings[self.uuId] !== 'undefined') {
        const settings = self.settings.dataview_settings[self.uuId];
        self.span = settings.span ? settings.span : "Daily";
        self.startDate = settings.startDate;
        self.endDate = settings.endDate;
        self.dates = typeof settings.dates !== 'undefined' ? settings.dates : "this-week";
        self.datesStr = self.dates == null? 'null' : self.dates;
        self.datesMode = typeof settings.datesMode !== 'undefined' ? settings.datesMode : "current";
        self.epoch = settings.epoch;
        self.timeseries_field = settings.timeseries_field ? settings.timeseries_field : null;
        self.timeseries_label = settings.timeseries_label ? settings.timeseries_label : null;
        self.useEpoch = typeof settings.useEpoch !== 'undefined' ? settings.useEpoch : false;
        self.datesChanged();
        
      }
      else {
        // load the settings from the dataview
        if (!this.dataview.settings) {
          this.dataview.settings = {};
        }
        self.span = self.dataview.settings.span ? self.dataview.settings.span : "Daily";
        self.startDate = self.dataview.settings.startDate;
        self.endDate = self.dataview.settings.endDate;
        self.dates = typeof self.dataview.settings.dates !== 'undefined' ? self.dataview.settings.dates : "this-week";
        self.datesStr = self.dates == null? 'null' : self.dates;
        self.datesMode = typeof self.dataview.settings.datesMode !== 'undefined' ? self.dataview.settings.datesMode : "current";
        self.epoch = self.dataview.settings.epoch;
        self.timeseries_field = self.dataview.settings.timeseries_field ? self.dataview.settings.timeseries_field : null;
        self.timeseries_label = self.dataview.settings.timeseries_label ? self.dataview.settings.timeseries_label : null;
        self.useEpoch = typeof self.dataview.settings.useEpoch !== 'undefined' ? self.dataview.settings.useEpoch : false;
        self.datesChanged();
        
      }

      if (self.datesMode === 'current') {
        self.epoch = null;
      }
    },
    async saveSettings() {
      const self = this;
      if (self.dataview.editingPermissions && self.dataview.editingPermissions.includes(this.userId)) {
        
        if (!this.dataview.settings) {
          this.dataview.settings = {};
        }
        self.dataview.settings.span = self.span;
        self.dataview.settings.startDate = self.startDate;
        self.dataview.settings.endDate = self.endDate;
        if (self.dataview.settings.dates !== self.dates) {
          // selection has changed, update the selected epoch
          if (self.epoch !== null) {
            const epochResult = calcEpochs(self.span, self.startDate, self.endDate);
            self.epoch = epochResult.epochs.length > 0 ? epochResult.epochs[0] : null;
          }
        }
        self.dataview.settings.epoch = self.epoch
        self.dataview.settings.dates = self.dates;
        self.dataview.settings.datesMode = self.datesMode;
        self.dataview.settings.timeseries_field = self.timeseries_field;
        self.dataview.settings.timeseries_label = self.timeseries_label;
        self.dataview.settings.useEpoch = self.useEpoch;
        await self.updateDataviewProfile(this.dataview);
      }
      else {
        if (typeof self.settings.dataview_settings === 'undefined') {
          self.settings.dataview_settings = {};
        }
        if (typeof self.settings.dataview_settings[self.uuId] === 'undefined') {
          self.settings.dataview_settings[self.uuId] = {};
        }
        self.settings.dataview_settings[self.uuId].span = self.span;
        self.settings.dataview_settings[self.uuId].startDate = self.startDate;
        self.settings.dataview_settings[self.uuId].endDate = self.endDate;
        if (self.settings.dataview_settings[self.uuId].dates !== self.dates) {
          // selection has changed, update the selected epoch
          if (self.epoch !== null) {
            const epochResult = calcEpochs(self.span, self.startDate, self.endDate);
            self.epoch = epochResult.epochs.length > 0 ? epochResult.epochs[0] : null;
          }
        }
        self.settings.dataview_settings[self.uuId].epoch = self.epoch
        self.settings.dataview_settings[self.uuId].dates = self.dates;
        self.settings.dataview_settings[self.uuId].datesMode = self.datesMode;
        self.settings.dataview_settings[self.uuId].timeseries_field = self.timeseries_field;
        self.settings.dataview_settings[self.uuId].timeseries_label = self.timeseries_label;
        self.settings.dataview_settings[self.uuId].useEpoch = self.useEpoch; 
        await self.updateViewProfile();
      }
    },
    async onCurrent() {
      this.datesMode = 'current';
      this.epoch = null;
      this.datesChanged();
      this.saveSettings();
      this.updateGrid(); 
    },
    async onActuals() { 
      const self = this;     
      this.datesMode = "actuals";
      this.datesChanged();
          
      const epochResult = calcEpochs(self.span, self.startDate, self.endDate);
      self.epochs = epochResult.epochs;
      self.epochOptions = epochResult.epochOptions;
      this.epoch = this.epochs.length !== 0 ? this.epochs[0] : null;
      this.saveSettings()
      this.updateGrid();
    },
    async onTimeSeries() {
      this.datesMode = "timeseries";
      this.datesChanged();
      
      this.epoch = null;
      this.saveSettings();
      this.updateGrid();
    },
    async fieldSelected() {
      this.saveSettings();
      this.updateGrid();
    },
    async listChange(value) {
      this.timeseries_label = value;
      this.saveSettings();
      this.updateGrid();
    },
    async leftEpoch() {
      const index = this.epochs.findIndex(e => e === this.epoch);
      if (index !== 0) {
        this.dataview.settings.epoch = this.epoch = this.epochs[index - 1];
        this.saveSettings();
        this.updateGrid();
      }
    },
    async rightEpoch() {
      const index = this.epochs.findIndex(e => e === this.epoch);
      if (index !== this.epochs.length - 1) {
        this.dataview.settings.epoch = this.epoch = this.epochs[index + 1];
        this.saveSettings();
        this.updateGrid();
      }
    },
    async epochSelected() {
      this.dataview.settings.epoch = this.epoch;
      this.saveSettings();
      this.updateGrid();
    },
    onTimeModeOver() {
      this.$refs.timeMode.visible = true;
    },
    onTimeModeLeave() {
      this.$refs.timeMode.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    formatField(item) {
      return formatField(item);
    },
    recalculate() {
      if (this.checkQuery) {
        return;
      }
      this.staffIds = this.resourceIds = this.taskIds = null;
      prepareQuery(this.dataviewLive.query, false);
      this.viewDataview({ data: this.dataviewLive });
      prepareQuery(this.dataviewLive.query, true);
    },
    toggleEpoch() {
      this.useEpoch = !this.useEpoch;
      if (!this.useEpoch &&
          !this.timeseries_hint_donotshow) {
        this.timeseriesHintShow = true;    
      }
      this.saveSettings();
      this.updateGrid();
    },
    closeHint() {
      this.timeseriesHintShow = false;
      if (this.timeseries_hint_donotshow) {
        this.settings.timeseries_hint_donotshow = this.timeseries_hint_donotshow;
        this.updateViewProfile();
      }
    },
    replaceNow(origFilter, epoch) {
      let filter = cloneDeep(origFilter);
      for (var f = 0; f < filter.length; f++) {
        if (typeof filter[f] === 'object') {
          filter[f] = this.replaceNow(filter[f], epoch);
        }
        else if (filter[f] === '<NOW>' &&
            epoch) {
          // if it is a less than rule we need to expand the date to the end of the period
          // so that we can test if the date falls within the period
          if (f === 2 &&
              (filter[1] === 'lte' || filter[1] === 'lt') &&
              (filter[0].endsWith('startTime') || 
              filter[0].endsWith('scheduleStart') || 
              filter[0].endsWith('startDate') ||
              filter[0].endsWith('closeTime') || 
              filter[0].endsWith('scheduleFinish') || 
              filter[0].endsWith('endDate')  ||
              filter[0].endsWith('beginDate') ||
              filter[0].endsWith('untilDate')))
          {
            const d = moment.unix(epoch / 1000);
            if (this.datesMode === 'timeseries') {
              if (this.span === 'Daily') {
                var newDateDay = d.add(1, 'days');
                filter[f] = newDateDay.unix() * 1000;
              }
              else if (this.span === 'Weekly') {
                var newDate = d.add(7, 'days');
                filter[f] = newDate.unix() * 1000;
              }
              else if (this.span === 'Monthly') {
                var newDate1 = d.add(1, 'months');
                filter[f] = newDate1.unix() * 1000;
              }
              else if (this.span === 'Yearly') {
                var newDate2 = d.add(1, 'years');
                filter[f] = newDate2.unix() * 1000;
              }
              else {
                filter[f] = epoch;
              }
            }
            else {
              filter[f] = epoch;
            }
          }
          else {
            filter[f] = epoch;
          }
        }
      }
      return filter;
    },      
    onOwner() {
      profileService.nodeList(this.id).then((response) => {
        const list = response.data.resultList
        userService.get(list).then((response) => {
          this.owner = response.data[response.data.jobCase].filter(v => v !== null && list.find(l => l.uuId === v.uuId)).map(r => { return r.name }).join(", ");
        });
      });
    },
    onOwnerOver() {
      this.showOwner = true;
    },
    onOwnerLeave() {
      this.showOwner = false;
    },
    getToday() {
      const date = new Date();
      date.setHours(0, 0, 0);
      return date.getTime();
    },
    onKanbanProfileChanged(profile) {
      this.dataview['kanban'] = profile;
      this.updateDataviewProfile(this.dataview);
    },
    getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    updateTabContentHeight(value=null) {
      this.sheetHeight = `calc(100vh - 209px - ${value != null? value+16 : 0}px)`;
      this.ganttHeight = this.height - (value != null? value+16 : 0);
      this.kanbanHeight = this.height - (value != null? value+16 : 0);
    },
    processFieldsForGroup(fields) {
      if (this.dataview.nominate === 'group-by') {
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];
          if (typeof field === 'object' &&
              field[0] === this.dataview.groupfield) {
             field[0] = `${field[0].substr(0, field[0].lastIndexOf('.'))}.uuId`;   
          }
        }
      }
      return fields;
    },
    getTimeseriesOptionLabel(value) {
      return this.fieldOptions.find(i => i.value === value)?.text || value;
    },
    getEpochOptionLabel(value) {
      return this.epochOptions.find(i => i.value === value)?.text || value;
    },
    getDateOptionLabel(value) {
      return this.dateOptions.find(i => i.value === value)?.text || value;
    },
    getSpanOptionLabel(value) {
      return this.spanOptions.find(i => i.value === value)?.text || value;
    },
    dateChanged() {
      this.highlightRefresh = true;
      this.dates = null;
      this.datesStr = 'null';
    }
  },
}

</script>

<style lang="scss">
.dataview-nav-tab-wrapper {
  background-color: var(--surface-bg);
}

.data-view-navbar .dropdown-menu {
  z-index: 10000;
}

.grid-toolbar ul {
  margin: 0;
}

.dataview-toolbar {
  display: block !important;
}

.dataview-toolbar.grid-toolbar .second-row-toolbar .btn {
  font-size: 1rem;
}

.action-v-divider {
  cursor: default;
  margin: 0 6px;
}

.btn-secondary.icon-button {
  color: var(--white);
  background-color: transparent;
  border: none;
}


.tab-container {
  border: 1px solid var(--border-medium);
  border-top: 0px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width:100%;
}

.dataview-tab-content {
  border: none !important;
}

.dataview-container {
  padding-bottom: 25px;
}

.dataview-container .tab-content .tab-pane {
  padding: 0;
}

.dataview-grid-height {
  height: 100%;
  min-height: 300px;
  padding-bottom: 8px;
}

.menu-toggler {
  position: absolute;
  // display: flex;
  // align-items: flex-end;
  font-size: 1.2rem;
  // margin-right: 0.5rem;
  // margin-top: 0.5rem;
  // float: right;
  // flex: 0 0 auto;
  right: 7px;
  top: 7px;
}

.select-state {
  display: inline-block;
  margin-left: 12px;
}

.tab-container ul[role=tablist],
.widget-container ul[role=tablist] {
  display: none; //Hide nav bar generated by bootstrapVue tabs component. 
}
</style>

<style lang="scss" scoped>
.data-view-nav {
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  a {
    color: var(--link-text);
    text-decoration: none;
    background-color: transparent;
  }
}

.time-toolbar {
  align-items: center;

  label {
    margin-bottom: 0;
  }
}

.live-edit-toolbar {
  background-color: var(--ag-header-background-color);
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0 10px;
  font-size: 12px;
}

.live-edit-toolbar .btn.btn-secondary,
.time-toolbar .btn.btn-secondary {
  background-color: transparent;
  border-color: transparent;
  padding: 2px 6px;
  margin: 8px 3px;
  border-radius: 3.2px;
  color: var(--grid-toolbar-button);
}

.data-view-navbar {
  position: relative;
  border: 1px solid var(--border-medium);
  border-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 0.2rem;
}

.active-check {
  position: absolute;
  right: 10px;
}

.permission-error,
.existence-error {
  text-align: center;
  margin-top: 25%;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 190px)
}

.rule-part {
  background-color: var(--form-control-disabled-bg);
  padding: 0px 8px;
  border-radius: 3px;
  align-items: center;
  height: 35px;
}

.timeseries-hint-img {
  object-fit: contain;
  width: 100%;
  margin-top: 10px;
}

.hint-checkbox {
  left: 15px;
  position: absolute;
}

.dataview-container .tab-container {
  margin-bottom: 0;
}

.dropdown-divider {
  margin: 0;
}

.border-bottom {
  border-bottom: 1px solid var(--bs-border) !important;
}

.popover-zindex-owner {
  z-index: 100001;
}

svg.active {
  color: #E77615;
}

</style>
