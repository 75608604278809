var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gantt-container" },
    [_c("Gantt", { attrs: { projectId: _vm.id, heightOffset: -7 } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }