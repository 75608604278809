
import { httpAjax, urlParams } from '@/helpers';
import { getKeysWithoutRedactedFields } from './common'
import { countryCodes } from "@/selectOptions";

export const companyService = {
  create,
  list,
  listv2,
  tree,
  query,
  update,
  remove,
  clone,
  link,
  unlink,
  listNames,
  listUniqueValuesOfProperty
};

/**
 * Create a new company 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', fullName: 'John Doe' ... }]
 */
function create(data) {
  const url = '/api/company/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of company info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
function list(params) {
  const fields = {
    uuId: 'COMPANY.uuId',
    name: 'COMPANY.name',
    type: 'COMPANY.type',
    avatarRef: 'COMPANY.avatarRef',
    bannerRef: 'COMPANY.bannerRef',
    location: 'COMPANY.LOCATION.name',
    locationColor: 'COMPANY.LOCATION.color',
    identifier: 'COMPANY.identifier',
    color: 'COMPANY.color',
    tag: 'COMPANY.TAG.name',
    staffList: "COMPANY.STAFF.uuId",
    genericStaff: "COMPANY.STAFF.genericStaff",
    quota: "COMPANY.STAFF.resourceQuota"
  }
  

  let data = {
    'name'  : 'Company List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'COMPANY'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if (params.holders) {
    data['holder'] = params.holders;
  }
  
  if(params.filter && params.filter.length > 0) {
    data['filter'] = [
      '_or_', [
        ['COMPANY.name', 'has', params.filter]
      ]
    ]
  }

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i], '', 'lowerCase']);
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order, '', 'lowerCase']);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.holders;
  delete params.fields;
  
  const url = '/api/query/match';
  const config = {
    params: params
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          if (keys[j] === 'locationName') {
            result[keys[j]] = countryCodes.filter(c => i[j].some(arr => arr === c.value)).map(v => { return v.text }).join(", ");
          }
          else if (keys[j] === 'projectNames') {
            result[keys[j]] = i[j].join(", ");
          }
          else {
            result[keys[j]] = i[j];
          }
        }

        result.staffCount = 0;
        result.genericStaffCount = 0;
        if (Array.isArray(result.genericStaff)) {
          for (let x = 0, len = result.genericStaff.length; x < len; x++) {
            if (result.genericStaff[x] !== true) {
              result.staffCount++;
            }
            else {
              result.genericStaffCount += result.quota[x];
            }
          }
        }
        
        return result;
      })
    }
  });
}

/**
 * Retrieving a concise list of company info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
function listNames(params) {
  const fields = {
    uuId: 'COMPANY.uuId',
    name: 'COMPANY.name'
  }
  

  let data = {
    'name'  : 'Company Name List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'COMPANY'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

function isActive(start, end) {
  const now = new Date();
  
  return (now.getTime() > start && now.getTime() < end);
}

function listv2(params, customFields=[]) {
  const fields = {
    uuId: 'COMPANY.uuId',
    name: 'COMPANY.name',
    type: 'COMPANY.type',
    avatarRef: 'COMPANY.avatarRef',
    bannerRef: 'COMPANY.bannerRef',
    locationUuId: 'COMPANY.LOCATION.uuId',
    locationCountry: 'COMPANY.LOCATION.countryCode',
    locationName: 'COMPANY.LOCATION.name',
    locationColor: 'COMPANY.LOCATION.color',
    identifier: 'COMPANY.identifier',
    color: 'COMPANY.color',
    tag: 'COMPANY.TAG.name',
    staffList: "COMPANY.STAFF.uuId",
    genericStaff: "COMPANY.STAFF.genericStaff",
    quota: "COMPANY.STAFF.resourceQuota",
    startDate: "COMPANY.STAFF.startDate",
    endDate: "COMPANY.STAFF.endDate",
    readOnly: "COMPANY.readOnly"
  }

  if (Array.isArray(customFields) && customFields.length > 0) {
    for(const cField of customFields) {
      fields[cField.name] = `COMPANY.${cField.name}`;
    }
  }

  //When params.fields are provided, clean up the selectors which don't appear in the param.fields.
  if (Array.isArray(params.fields) && params.fields.length > 0) {
    const fieldProps = Object.keys(fields);
    for (const prop of fieldProps) {
      if (prop == 'uuId') {
        continue;
      }
      if (!params.fields.includes(prop)) {
        delete fields[prop];
      }
    }
  }
  
  let data = {
    'name'  : 'Company List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'COMPANY'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if (params.holders) {
    data['holder'] = params.holders;
  }
  
  if(params.filter && params.filter.length > 0) {
    data['filter'] = [
      '_or_', [
        ['COMPANY.name', 'has', params.filter]
      ]
    ]
  }

  //BadgeFilter related
  if (Array.isArray(params.badgeFilters) && params.badgeFilters.length > 0) {
    const badgeFilterList = [];
    for (const f of params.badgeFilters) {
      if (f.field == null || !Array.isArray(f.value) || f.value.length == 0) {
        continue;
      }
      
      let field = null;
      if (f.field == 'tagName') {
        field = 'COMPANY.TAG.name';
      } else {
        field = `COMPANY.${f.field}`;
      } 

      const isEqual = !f.operator || f.operator === 'is';
      let valueList = [];
      const value = f.value;
      for (const v of value) {
        if (v.text != null && (typeof v.text === 'number' || (typeof v.text === 'string' && v.text&& v.text.length > 0 && v.text !== '(Empty)'))) {
          //use value when exists. Otherwise use text
          //Reason: When value property exists, it means the text is localized and can't be used in filter.
          valueList.push([field, 'eq', Object.hasOwn(v, 'value')? v.value : v.text]);
        }
        //Start processing (EMPTY) field value 
        else if (isEqual) {
          valueList.push('_not_', [[field]])
        } else {
          //Special handling: when is not empty, put it into badgeFilterList directly to act as 'field must exist'. 
          badgeFilterList.push([field])
        }
      }
      if (valueList.length > 0) {
        if (valueList.length > 1) {
          valueList = ['_or_', valueList];
        }
        if (!isEqual) {
          badgeFilterList.push('_not_', valueList);
        } else {
          badgeFilterList.push(...valueList);
        }
      }
    }
    if (badgeFilterList.length > 0) {
      if (Array.isArray(data.filter) && data.filter.length > 0) {
        data.filter = [...data.filter, ...badgeFilterList]
      } else {
        data.filter = [...badgeFilterList]
      }
    }
  }

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i], '', 'lowerCase']);
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order, '', 'lowerCase']);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.badgeFilters;
  delete params.holders;
  delete params.fields;
  
  const url = '/api/query/match';
  const config = {
    params: params
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        result.locations = [];
        if (result.locationUuId) {
          for (let l = 0; l < result.locationUuId.length; l++) {
            result.locations.push({
              uuId: result.locationUuId[l]
              , name: result.locationName[l]
              , country: result.locationCountry[l]
              , color: result.locationColor[l]
            })
          }
        }
        delete result.locationUuId;
        delete result.locationName;
        delete result.locationCountry;
        delete result.locationColor;

        result.staffCount = 0;
        result.genericStaffCount = 0;
        if (Array.isArray(result.genericStaff)) {
          for (let x = 0, len = result.genericStaff.length; x < len; x++) {
            if (result.genericStaff[x] !== true) {
              if (isActive(result.startDate[x], result.endDate[x])) {
                result.staffCount++;
              }
            }
            else {
              result.genericStaffCount += result.quota[x];
            }
          }
        }
        
        return result;
      })
    }
  });
}

/**
 * Link a company to a parent 
 * by passing necessary information
 * @param Json data 
 * e.g {"uuId": "f9e8e71b-3047-4e5e-b8e2-b769241ea461",
		"companyList": [
			{"uuId": "86991afd-4fa7-4eaf-9412-f17fdea4c9b7"}
		]}
 */
function link(data, type='company') {
  const url = `/api/company/link/${type}/add`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data[0], config);
}

/**
 * UnLink a company to a parent 
 * by passing necessary information
 * @param Json data 
 * e.g {"uuId": "f9e8e71b-3047-4e5e-b8e2-b769241ea461",
		"companyList": [
			{"uuId": "86991afd-4fa7-4eaf-9412-f17fdea4c9b7"}
		]}
 */
function unlink(data, type='company') {
  const url = `/api/company/link/${type}/delete`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data[0], config);
}

/**
 * Retrieving a list of company details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function query(data, links=null) {
  const url = urlParams('/api/company/get', links);
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a tree of company details
 */
function tree(includeDepartment, uuid = null) {
  const url = uuid ? `/api/company/tree?includeDepartment=${includeDepartment}&uuId=${uuid}` : `/api/company/tree?includeDepartment=${includeDepartment}`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config);
}

/**
 * Update company details 
 * by passing  necessary information
 * @param Array data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', fullName: 'John Doe' ... }]
 */
function update(data) {
  const url = '/api/company/update';
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Clone a company with given new name
 * @param String refId Target staff UuId
 * @param Object data Object contains name for cloned company.
 */
function clone(refId, data) {
  const url = '/api/company/clone';
  const config = {
    data: data,
    headers: getHeaders(),
    params: { reference: refId }
  };
  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of companys
 * by passing in an array list of uuIds
 * @param Array data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = '/api/company/delete';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}

function listUniqueValuesOfProperty(field) {
  const fieldSelector = [`COMPANY.${field}`]
  
  const data = {
    'name'  : `Unique COMPANY property: ${field}`
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'select': [
      fieldSelector
    ]
    , 'dedup': true
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    let rawData = response.data[listName] || [];
    rawData = rawData.map(i => i[0]);
    rawData.sort((a, b) => {
      if (typeof a === 'string') {
        return a.localeCompare(b, undefined, { sensitivity: 'base'})
      } else {
        if (a < b) {
          return -1
        } else if (a > b) {
          return 1
        }
        return 0
      }
    });
    return rawData;
  });
}
