
import { httpAjax } from '@/helpers';

export const userLinkPermissionService = {
  create,
  update,
  remove,
};

/**
 * Create a new Link from user to permission 
 * by passing necessary information
 * @param String userId, User's UUID
 * @param Array access policies, Permission details. Data sample: [{ uuId }]
 * request body format sample: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', permissionList: { uuId: 'xxxx..' } }]
 */
function create(userId, permissions) {
  const url = '/api/user/link/permission/add';
  const config = {
    headers: getHeaders()
  };

  const permissionList = []
  permissions.forEach(i => {
    permissionList.push( i );
  });

  const data = {
    uuId: userId,
    permissionList: permissionList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Update a Link from user to permission 
 * by passing necessary information
 * @param String userId, User's UUID
 * @param Array access policies, Permission details. Data sample: [{ uuId }]
 * request body format sample: {
    "uuId": "9f4c6571-3615-437e-9ae3-ce25909481db",
    "permissionList": [
        {
            "uuId": "a99a93b5-ba70-4865-811c-b3da1f82636a",
            "name": "RESOURCE__VIEW",
            "permissionLink": {
                "denyProperties": [
                    "payAmount"
                ]
            }
        },
        {
            "uuId": "2f59d6a2-ff6d-441d-9e47-88d1b6c805ce",
            "name": "RESOURCE__EDIT",
            "permissionLink": {
                "denyProperties": [
                    "payAmount"
                ]
            }
        }
    ]
}
 */
function update(userId, permissions) {
  const url = '/api/user/link/permission/update';
  const config = {
    headers: getHeaders()
  };

  const data = {
    uuId: userId,
    permissionList: permissions
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from user to permission.
 * by passing in an array list of links uuIds
 * @param String userId, User's UUID
 * @param Array permissionIds, list of Permission's UUID 
 * request body format sample: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', permissionList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }]
 */
function remove(userId, permissions) {
  const url = '/api/user/link/permission/delete';
  const config = {
    headers: getHeaders()
  };

  const permissionList = []
  permissions.forEach(i => {
    permissionList.push( {uuId: i} );
  });

  const data = {
    uuId: userId,
    permissionList: permissionList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}