import { profileService } from '@/services';

const categoryKey = 'company';
const folderKey = 'user';

export const companyProfileService = {
  create,
  list,
  update,
  remove,
};

/**
 * Create a new dashboardProfile 
 * by passing necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function create(data, userId) {
  return profileService.create(data, categoryKey, folderKey, userId);
}

/**
 * Retrieving a concise list of dashboardProfile info
 */
function list(userId) {
  return profileService.list(categoryKey, folderKey, userId);
}

/**
 * Update dashboardProfile details 
 * by passing  necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function update(data, userId) {
  return profileService.update(data, categoryKey, folderKey, userId);
}

/**
 * Delete a list of dashboardProfiles
 * by passing in an array list of uuIds
 * @param {Array} data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data, userId) {
  return profileService.remove(data, categoryKey, folderKey, userId);
}