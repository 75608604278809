<template>
  <RebateBadgeGroupModal :show.sync="modalShow" :rebateList="rebateList" @ok="rebateSelectorOk" @cancel="rebateSelectorCancel" @rebateDeleted="rebateSelectorDeleted"/>
</template>

<script>
import Vue from 'vue';
import { objectClone } from '@/helpers';

export default Vue.extend({
  name: 'RebatesEditor',
  components: {
    RebateBadgeGroupModal: () => import('@/components/modal/RebateBadgeGroupModal')
  },
  data() {
    return {
      value: ''
      , modalShow: false
      , rebateList: []
      
    };
  },
  computed: {
  },
  methods: {
    getValue() {
      if (this.compactMode) {
        const orgValue = this.params.value.single;
        const curValue = this.value;

        if (orgValue == null && curValue == null) {
          return this.params.value;
        } else if ((orgValue == null && curValue != null && curValue.length > 0) || 
                    (orgValue != null && (orgValue.length != curValue.length))) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        let hasChanged = false;
        for (let i = 0, len = orgValue.length; i < len; i++) {
          const found = curValue.find(v => v.uuId == orgValue[i].uuId);
          if (found == null) {
            hasChanged = true;
            break;
          }
        }
        
        if (hasChanged) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        return this.params.value;
      } else {
        return this.value;
      }
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    rebateSelectorOk(modifiedRebateList) {
      //Check array size
      if (!this.params.value ||
          modifiedRebateList.length != this.params.value.length) {
        this.value = modifiedRebateList;
        this.closePicker();
        return;
      }

      //Check UuId
      const originRebates = this.params.value;
      const originIds = originRebates.map(i => i.uuId);
      
      let hasChanged = false;
      for (let i = 0, len = modifiedRebateList.length; i < len; i++) {
        if (!originIds.includes(modifiedRebateList[i].uuId)) {
          hasChanged = true;
          break;
        }
      }
      if (hasChanged) {
        this.value = modifiedRebateList;
      }
      this.closePicker;
    },
    rebateSelectorCancel({ modifiedList=[]}={}) {
      if (modifiedList.length > 0) {
        const deletedIds = this.modifiedList.filter(i => i.action =='DELETE').map(i => i.uuId)
        const updatedList = this.modifiedList.filter(i => i.action == 'UPDATE')
        const remaining = this.value.filter(i => !deletedIds.includes(i.uuId))
        for (const item of remaining) {
          const obj = updatedList.find(i => i.uuId == item.uuId)
          if (obj == null) {
            continue
          }
          item.name = obj.name
          // item.color = obj.color
        }
        this.value = remaining
      }
      this.closePicker()
    },
    prepareRebateList() {
      if(this.value != null) {
        for (const rebate of this.value) {
          this.rebateList.push({ uuId: rebate.uuId, name: rebate.name, rebate: rebate.rebate });
        }
      }
    },
    rebateSelectorDeleted(/**{ removedList=[]}={}**/) {
      
    }
    
  },

  created() {
    this.modalShow = true;
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    this.value = this.compactMode? objectClone(this.params.value.single): this.params.value;
    this.prepareRebateList();
    
  }
})
</script>