
import { httpAjax } from '@/helpers';

export const departmentLinkStaffService = {
  create,
  remove,
};

/**
 * Create a new Link from department to skill 
 * by passing necessary information
 * @param String depId, department's UUID
 * @param Array staffIds, list of staff's UUID . Data sample: [ uuId ]
 * request body format sample: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', staffList: { uuId: 'xxx..'} }]
 */
function create(depId, staffs) {
  const url = '/api/department/link/staff/add';
  const config = {
    headers: getHeaders()
  };

  const staffList = []
  staffs.forEach(i => {
    staffList.push( {uuId: i} );
  });

  const data = {
    uuId: depId,
    staffList: staffList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from department to skill.
 * by passing in an array list of links uuIds
 * @param String depId, department's UUID
 * @param Array skillIds, list of staff's UUID 
 * request body format sample: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', staffList: { uuId: 'xxx..'} }]
 */
function remove(depId, staffs) {
  const url = '/api/department/link/staff/delete';
  const config = {
    headers: getHeaders()
  };

  const staffList = []
  staffs.forEach(i => {
    staffList.push( {uuId: i} );
  });

  const data = {
    uuId: depId,
    staffList: staffList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}