

<template>
  <div class="d-flex">
    <div class="d-flex select-button">
     <div ref="menuButton" class="customHeaderMenuButton" @click="onSelectToggle($event)">
       <font-awesome-icon class="selection-icon" :icon="selectedAll ? ['far', 'square-check'] : selectedSome ? ['far', 'square-minus'] : ['far','square']"/>
     </div>
     <div v-if="params.enableMenu" ref="menuButton" class="select-menu ml-1 customHeaderMenuButton" @click="onMenuClicked($event)">
       <font-awesome-icon class="selection-icon" :icon="['far','caret-down']"/>
     </div>
   </div>
   <span class="ml-1 display-name">{{ params.displayName }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import { fieldOptions } from '@/selectOptions';

export default Vue.extend({
  name: 'SelectionHeaderComponent',
  data() {
    return {
      value: null,
      options: fieldOptions,
      ascSort: null,
      descSort: null,
      noSort: null,
      selectedAll: false,
      selectedSome: false,
      // selectedLocalAll: false,
      columnName: 'name' 
    }
  },
  beforeMount() {
    this.prepareData();
  },
  computed: {

  },
  methods: {
    prepareData() {
      this.$nextTick(() => {
        const selectionStatus = this.getSelectionStatus();
        this.selectedAll = selectionStatus == 'all';
        this.selectedSome = selectionStatus == 'some';
      });
      if (this.params.targetColId != null && this.params.targetColId.trim().length > 0) {
        this.columnName = this.params.targetColId;
      }
    },
    refresh(params) {
      this.params = params;
      this.prepareData();
    },
    getSelectionStatus() {
      const api = this.params.api;
      if (api == null || api.getDisplayedRowCount() == 0) {
        return 'none';
      }

      //Get the current page status only
      const lastGridIdx = api.getDisplayedRowCount() - 1;
      const currentPage = api.paginationGetCurrentPage();
      const pageSize = api.paginationGetPageSize();
      const startPageIdx = currentPage * pageSize;
      let endPageIdx = ((currentPage + 1) * pageSize) - 1;
      if (endPageIdx > lastGridIdx) {
        endPageIdx = lastGridIdx;
      }

      let validRowCount = 0;
      let selectedCount = 0;
      for (let i = startPageIdx; i <= endPageIdx; i++) {
        const rowNode = api.getDisplayedRowAtIndex(i);
        validRowCount += 1;
        if (rowNode.selected == true) {
          selectedCount += 1;
        }
      }

      if (selectedCount == 0) {
        return 'none';
      } else if (validRowCount == selectedCount) {
        return 'all';
      } else {
        return 'some';
      }
    },
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },
    onSelectToggle() {
      const api = this.params.api;
      if (api == null) {
        return;
      }

      const toUnselectAll = this.selectedAll;
      //Update selectedAll with next state
      this.selectedAll = !toUnselectAll;
      
      const lastGridIdx = api.getDisplayedRowCount() - 1;
      const currentPage = api.paginationGetCurrentPage();
      const pageSize = api.paginationGetPageSize();
      const startPageIdx = currentPage * pageSize;
      let endPageIdx = ((currentPage + 1) * pageSize) - 1;
      if (endPageIdx > lastGridIdx) {
        endPageIdx = lastGridIdx;
      }

      if (toUnselectAll) {
        api.deselectAll();
        api.clearRangeSelection();
      }
      else {
        const rowCount = api.getDisplayedRowCount();
        if (rowCount == 0) {
          return;
        }

        api.clearRangeSelection();
        api.addCellRange({
          rowStartIndex: startPageIdx
          , rowEndIndex: endPageIdx
          , columns: ['name']
        });

        //Make sure there is a focused cell in the grid.
        //Without focused cell, cell navigation, delete key interaction will not work.
        if(api.getFocusedCell() == null) {
          api.setFocusedCell(startPageIdx, 'name');
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.selection-icon {
  font-size: 18px;
  margin-top: 1px;
  color: var(--gray-500);
}

.select-menu {
  padding: 0 2px;
}

.select-button {
  padding: 3px;
  width: fit-content;
  border: 1px solid transparent;
}

.display-name {
  margin-top: 5px;
}

.select-button:focus,
.select-button:active,
.select-button:hover {
  background: var(--backdrop);
  border-radius: 3px;
  border: 1px solid var(--border-dark);
}
</style>
