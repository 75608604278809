
import { httpAjax } from '@/helpers';

export const companyLinkLocationService = {
  create,
  remove,
};

/**
 * Create a new Link from company to location 
 * by passing necessary information
 * @param String companyId, Company's UUID
 * @param Array locations, Location details. Data sample: [{ uuId }]
 * request body format sample: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', locationList: { uuId: 'xxxx..' } }]
 */
function create(companyId, locations) {
  const url = '/api/company/link/location/add';
  const config = {
    headers: getHeaders()
  };

  const locationList = []
  locations.forEach(i => {
    locationList.push( {uuId: i} );
  });

  const data = {
    uuId: companyId,
    locationList: locationList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from company to location.
 * by passing in an array list of links uuIds
 * @param String companyId, Company's UUID
 * @param Array locationIds, list of Location's UUID 
 * request body format sample: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', locationList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }]
 */
function remove(companyId, locations) {
  const url = '/api/company/link/location/delete';
  const config = {
    headers: getHeaders()
  };

  const locationList = []
  locations.forEach(i => {
    locationList.push( {uuId: i} );
  });

  const data = {
    uuId: companyId,
    locationList: locationList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}