const timezones = [
  { timeZoneId: "1" , gmtAdjustment: "GMT-12:00", useDaylightTime: "0", value: "-12", text: "(GMT-12:00) International Date Line West" },
  { timeZoneId: "2" , gmtAdjustment: "GMT-11:00", useDaylightTime: "0", value: "-11", text: "(GMT-11:00) Midway Island, Samoa" },
  { timeZoneId: "3" , gmtAdjustment: "GMT-10:00", useDaylightTime: "0", value: "-10", text: "(GMT-10:00) Hawaii" },
  { timeZoneId: "4" , gmtAdjustment: "GMT-09:00", useDaylightTime: "1", value: "-9", text: "(GMT-09:00) Alaska" },
  { timeZoneId: "5" , gmtAdjustment: "GMT-08:00", useDaylightTime: "1", value: "-8", text: "(GMT-08:00) Pacific Time (US & Canada)" },
  { timeZoneId: "6" , gmtAdjustment: "GMT-08:00", useDaylightTime: "1", value: "-8", text: "(GMT-08:00) Tijuana, Baja California" },
  { timeZoneId: "7" , gmtAdjustment: "GMT-07:00", useDaylightTime: "0", value: "-7", text: "(GMT-07:00) Arizona" },
  { timeZoneId: "8" , gmtAdjustment: "GMT-07:00", useDaylightTime: "1", value: "-7", text: "(GMT-07:00) Chihuahua, La Paz, Mazatlan" },
  { timeZoneId: "9" , gmtAdjustment: "GMT-07:00", useDaylightTime: "1", value: "-7", text: "(GMT-07:00) Mountain Time (US & Canada)" },
  { timeZoneId: "10", gmtAdjustment: "GMT-06:00", useDaylightTime: "0", value: "-6", text: "(GMT-06:00) Central America" },
  { timeZoneId: "11", gmtAdjustment: "GMT-06:00", useDaylightTime: "1", value: "-6", text: "(GMT-06:00) Central Time (US & Canada)" },
  { timeZoneId: "12", gmtAdjustment: "GMT-06:00", useDaylightTime: "1", value: "-6", text: "(GMT-06:00) Guadalajara, Mexico City, Monterrey" },
  { timeZoneId: "13", gmtAdjustment: "GMT-06:00", useDaylightTime: "0", value: "-6", text: "(GMT-06:00) Saskatchewan" },
  { timeZoneId: "14", gmtAdjustment: "GMT-05:00", useDaylightTime: "0", value: "-5", text: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco" },
  { timeZoneId: "15", gmtAdjustment: "GMT-05:00", useDaylightTime: "1", value: "-5", text: "(GMT-05:00) Eastern Time (US & Canada)" },
  { timeZoneId: "16", gmtAdjustment: "GMT-05:00", useDaylightTime: "1", value: "-5", text: "(GMT-05:00) Indiana (East)" },
  { timeZoneId: "17", gmtAdjustment: "GMT-04:00", useDaylightTime: "1", value: "-4", text: "(GMT-04:00) Atlantic Time (Canada)" },
  { timeZoneId: "18", gmtAdjustment: "GMT-04:00", useDaylightTime: "0", value: "-4", text: "(GMT-04:00) Caracas, La Paz" },
  { timeZoneId: "19", gmtAdjustment: "GMT-04:00", useDaylightTime: "0", value: "-4", text: "(GMT-04:00) Manaus" },
  { timeZoneId: "20", gmtAdjustment: "GMT-04:00", useDaylightTime: "1", value: "-4", text: "(GMT-04:00) Santiago" },
  { timeZoneId: "21", gmtAdjustment: "GMT-03:30", useDaylightTime: "1", value: "-3.5", text: "(GMT-03:30) Newfoundland" },
  { timeZoneId: "22", gmtAdjustment: "GMT-03:00", useDaylightTime: "1", value: "-3", text: "(GMT-03:00) Brasilia" },
  { timeZoneId: "23", gmtAdjustment: "GMT-03:00", useDaylightTime: "0", value: "-3", text: "(GMT-03:00) Buenos Aires, Georgetown" },
  { timeZoneId: "24", gmtAdjustment: "GMT-03:00", useDaylightTime: "1", value: "-3", text: "(GMT-03:00) Greenland" },
  { timeZoneId: "25", gmtAdjustment: "GMT-03:00", useDaylightTime: "1", value: "-3", text: "(GMT-03:00) Montevideo" },
  { timeZoneId: "26", gmtAdjustment: "GMT-02:00", useDaylightTime: "1", value: "-2", text: "(GMT-02:00) Mid-Atlantic" },
  { timeZoneId: "27", gmtAdjustment: "GMT-01:00", useDaylightTime: "0", value: "-1", text: "(GMT-01:00) Cape Verde Is." },
  { timeZoneId: "28", gmtAdjustment: "GMT-01:00", useDaylightTime: "1", value: "-1", text: "(GMT-01:00) Azores" },
  { timeZoneId: "29", gmtAdjustment: "GMT+00:00", useDaylightTime: "0", value: "0", text: "(GMT+00:00) Casablanca, Monrovia, Reykjavik" },
  { timeZoneId: "30", gmtAdjustment: "GMT+00:00", useDaylightTime: "1", value: "0", text: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London" },
  { timeZoneId: "31", gmtAdjustment: "GMT+01:00", useDaylightTime: "1", value: "1", text: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
  { timeZoneId: "32", gmtAdjustment: "GMT+01:00", useDaylightTime: "1", value: "1", text: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
  { timeZoneId: "33", gmtAdjustment: "GMT+01:00", useDaylightTime: "1", value: "1", text: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris" },
  { timeZoneId: "34", gmtAdjustment: "GMT+01:00", useDaylightTime: "1", value: "1", text: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
  { timeZoneId: "35", gmtAdjustment: "GMT+01:00", useDaylightTime: "1", value: "1", text: "(GMT+01:00) West Central Africa" },
  { timeZoneId: "36", gmtAdjustment: "GMT+02:00", useDaylightTime: "1", value: "2", text: "(GMT+02:00) Amman" },
  { timeZoneId: "37", gmtAdjustment: "GMT+02:00", useDaylightTime: "1", value: "2", text: "(GMT+02:00) Athens, Bucharest, Istanbul" },
  { timeZoneId: "38", gmtAdjustment: "GMT+02:00", useDaylightTime: "1", value: "2", text: "(GMT+02:00) Beirut" },
  { timeZoneId: "39", gmtAdjustment: "GMT+02:00", useDaylightTime: "1", value: "2", text: "(GMT+02:00) Cairo" },
  { timeZoneId: "40", gmtAdjustment: "GMT+02:00", useDaylightTime: "0", value: "2", text: "(GMT+02:00) Harare, Pretoria" },
  { timeZoneId: "41", gmtAdjustment: "GMT+02:00", useDaylightTime: "1", value: "2", text: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
  { timeZoneId: "42", gmtAdjustment: "GMT+02:00", useDaylightTime: "1", value: "2", text: "(GMT+02:00) Jerusalem" },
  { timeZoneId: "43", gmtAdjustment: "GMT+02:00", useDaylightTime: "1", value: "2", text: "(GMT+02:00) Minsk" },
  { timeZoneId: "44", gmtAdjustment: "GMT+02:00", useDaylightTime: "1", value: "2", text: "(GMT+02:00) Windhoek" },
  { timeZoneId: "45", gmtAdjustment: "GMT+03:00", useDaylightTime: "0", value: "3", text: "(GMT+03:00) Kuwait, Riyadh, Baghdad" },
  { timeZoneId: "46", gmtAdjustment: "GMT+03:00", useDaylightTime: "1", value: "3", text: "(GMT+03:00) Moscow, St. Petersburg, Volgograd" },
  { timeZoneId: "47", gmtAdjustment: "GMT+03:00", useDaylightTime: "0", value: "3", text: "(GMT+03:00) Nairobi" },
  { timeZoneId: "48", gmtAdjustment: "GMT+03:00", useDaylightTime: "0", value: "3", text: "(GMT+03:00) Tbilisi" },
  { timeZoneId: "49", gmtAdjustment: "GMT+03:30", useDaylightTime: "1", value: "3.5", text: "(GMT+03:30) Tehran" },
  { timeZoneId: "50", gmtAdjustment: "GMT+04:00", useDaylightTime: "0", value: "4", text: "(GMT+04:00) Abu Dhabi, Muscat" },
  { timeZoneId: "51", gmtAdjustment: "GMT+04:00", useDaylightTime: "1", value: "4", text: "(GMT+04:00) Baku" },
  { timeZoneId: "52", gmtAdjustment: "GMT+04:00", useDaylightTime: "1", value: "4", text: "(GMT+04:00) Yerevan" },
  { timeZoneId: "53", gmtAdjustment: "GMT+04:30", useDaylightTime: "0", value: "4.5", text: "(GMT+04:30) Kabul" },
  { timeZoneId: "54", gmtAdjustment: "GMT+05:00", useDaylightTime: "1", value: "5", text: "(GMT+05:00) Yekaterinburg" },
  { timeZoneId: "55", gmtAdjustment: "GMT+05:00", useDaylightTime: "0", value: "5", text: "(GMT+05:00) Islamabad, Karachi, Tashkent" },
  { timeZoneId: "56", gmtAdjustment: "GMT+05:30", useDaylightTime: "0", value: "5.5", text: "(GMT+05:30) Sri Jayawardenapura" },
  { timeZoneId: "57", gmtAdjustment: "GMT+05:30", useDaylightTime: "0", value: "5.5", text: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
  { timeZoneId: "58", gmtAdjustment: "GMT+05:45", useDaylightTime: "0", value: "5.75", text: "(GMT+05:45) Kathmandu" },
  { timeZoneId: "59", gmtAdjustment: "GMT+06:00", useDaylightTime: "1", value: "6", text: "(GMT+06:00) Almaty, Novosibirsk" },
  { timeZoneId: "60", gmtAdjustment: "GMT+06:00", useDaylightTime: "0", value: "6", text: "(GMT+06:00) Astana, Dhaka" },
  { timeZoneId: "61", gmtAdjustment: "GMT+06:30", useDaylightTime: "0", value: "6.5", text: "(GMT+06:30) Yangon (Rangoon)" },
  { timeZoneId: "62", gmtAdjustment: "GMT+07:00", useDaylightTime: "0", value: "7", text: "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
  { timeZoneId: "63", gmtAdjustment: "GMT+07:00", useDaylightTime: "1", value: "7", text: "(GMT+07:00) Krasnoyarsk" },
  { timeZoneId: "64", gmtAdjustment: "GMT+08:00", useDaylightTime: "0", value: "8", text: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
  { timeZoneId: "65", gmtAdjustment: "GMT+08:00", useDaylightTime: "0", value: "8", text: "(GMT+08:00) Kuala Lumpur, Singapore" },
  { timeZoneId: "66", gmtAdjustment: "GMT+08:00", useDaylightTime: "0", value: "8", text: "(GMT+08:00) Irkutsk, Ulaan Bataar" },
  { timeZoneId: "67", gmtAdjustment: "GMT+08:00", useDaylightTime: "0", value: "8", text: "(GMT+08:00) Perth" },
  { timeZoneId: "68", gmtAdjustment: "GMT+08:00", useDaylightTime: "0", value: "8", text: "(GMT+08:00) Taipei" },
  { timeZoneId: "69", gmtAdjustment: "GMT+09:00", useDaylightTime: "0", value: "9", text: "(GMT+09:00) Osaka, Sapporo, Tokyo" },
  { timeZoneId: "70", gmtAdjustment: "GMT+09:00", useDaylightTime: "0", value: "9", text: "(GMT+09:00) Seoul" },
  { timeZoneId: "71", gmtAdjustment: "GMT+09:00", useDaylightTime: "1", value: "9", text: "(GMT+09:00) Yakutsk" },
  { timeZoneId: "72", gmtAdjustment: "GMT+09:30", useDaylightTime: "0", value: "9.5", text: "(GMT+09:30) Adelaide" },
  { timeZoneId: "73", gmtAdjustment: "GMT+09:30", useDaylightTime: "0", value: "9.5", text: "(GMT+09:30) Darwin" },
  { timeZoneId: "74", gmtAdjustment: "GMT+10:00", useDaylightTime: "0", value: "10", text: "(GMT+10:00) Brisbane" },
  { timeZoneId: "75", gmtAdjustment: "GMT+10:00", useDaylightTime: "1", value: "10", text: "(GMT+10:00) Canberra, Melbourne, Sydney" },
  { timeZoneId: "76", gmtAdjustment: "GMT+10:00", useDaylightTime: "1", value: "10", text: "(GMT+10:00) Hobart" },
  { timeZoneId: "77", gmtAdjustment: "GMT+10:00", useDaylightTime: "0", value: "10", text: "(GMT+10:00) Guam, Port Moresby" },
  { timeZoneId: "78", gmtAdjustment: "GMT+10:00", useDaylightTime: "1", value: "10", text: "(GMT+10:00) Vladivostok" },
  { timeZoneId: "79", gmtAdjustment: "GMT+11:00", useDaylightTime: "1", value: "11", text: "(GMT+11:00) Magadan, Solomon Is., New Caledonia" },
  { timeZoneId: "80", gmtAdjustment: "GMT+12:00", useDaylightTime: "1", value: "12", text: "(GMT+12:00) Auckland, Wellington" },
  { timeZoneId: "81", gmtAdjustment: "GMT+12:00", useDaylightTime: "0", value: "12", text: "(GMT+12:00) Fiji, Kamchatka, Marshall Is." },
  { timeZoneId: "82", gmtAdjustment: "GMT+13:00", useDaylightTime: "0", value: "13", text: "(GMT+13:00) Nuku'alofa" },
];

export default timezones;