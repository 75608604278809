var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    _vm._l(_vm.value, function (entity, index) {
      return _c(
        "div",
        { key: entity.uuId },
        [
          index !== 0 ? _c("span", [_vm._v(", ")]) : _vm._e(),
          _vm.hasError(entity)
            ? [
                _c("font-awesome-icon", {
                  staticClass: "mr-1",
                  staticStyle: { color: "red", "font-size": "1.125em" },
                  attrs: {
                    id:
                      "CELL_ERROR_" +
                      index +
                      "_" +
                      _vm.params.eGridCell.getAttribute("comp-id"),
                    icon: ["fas", "triangle-exclamation"],
                  },
                }),
                _c("b-popover", {
                  attrs: {
                    target:
                      "CELL_ERROR_" +
                      index +
                      "_" +
                      _vm.params.eGridCell.getAttribute("comp-id"),
                    placement: "top",
                    triggers: "hover",
                    content: _vm.errorMessage[entity.name],
                  },
                }),
              ]
            : _vm._e(),
          _c("span", [
            _vm._v(_vm._s(entity.label != null ? entity.label : entity.name)),
          ]),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }