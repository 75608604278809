<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="row justify-content-center">
        <slot></slot>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "BlankCenterContainer",
}
</script>

