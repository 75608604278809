<template>
  <div class="d-flex">
    <div v-for="(entity, index) in value" v-bind:key="entity.uuId">
      <span v-if="index !== 0">, </span>
      <template v-if="hasError(entity)">
        <font-awesome-icon :id="`CELL_ERROR_${index}_${params.eGridCell.getAttribute('comp-id')}`" class="mr-1" style="color: red;font-size: 1.125em;" :icon="['fas', 'triangle-exclamation']"/>
        <b-popover
          :target="`CELL_ERROR_${index}_${params.eGridCell.getAttribute('comp-id')}`"
          placement="top"
          triggers="hover"
          :content="errorMessage[entity.name]">
        </b-popover>
      </template>
      <span>{{ entity.label != null? entity.label : entity.name }}</span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'GenericEntityArrayCellRenderer',
  data() {
    return {
      value: null,
      errorMessage: {}
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    
    const constructLabel = this.params.labelFormatter;
    if (constructLabel != null && typeof constructLabel == 'function') {
      const result = constructLabel(this.params)
      this.value = result.value
      if (result.errorMessage != null && typeof result.errorMessage == 'object') {
        this.errorMessage = result.errorMessage
      }
    } else {
      this.value = Array.isArray(this.params.value)? this.params.value: []
    }
  },
  computed: {
    label() {
      return this.value && this.value.length > 0? this.value : '';
    }
  },
  methods: {
    hasError(entity) {
      return this.errorMessage[entity.name];
    }
  }
})
</script>
