var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "edit-cell", on: { focusout: _vm.onFocusOut } },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        ref: "input",
        staticClass: "form-control",
        attrs: { autofocus: "", min: "0", max: "999", type: "number" },
        domProps: { value: _vm.value },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.value = $event.target.value
            },
            _vm.onInput,
          ],
          keydown: function ($event) {
            return _vm.onKeyDown($event)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }