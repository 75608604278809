
import { httpAjax } from '@/helpers';

export const calendarService = {
  create,
  get,
  update,
  remove
};

/**
 * Create a new calendar 
 * by passing necessary information
 * @param FormData formData 
 * Note: Possible properties for formData: 
 *  1. calendar: String; Mandatory field.
 */
function create(formData, holder) {
  const url = '/api/calendar/add';
  const config = {
	params: {
    holder: holder
	},
    headers: getHeaders()
  };
  return httpAjax.post(url, formData, config);
}

/**
 * Retrieving a list of calendar details by 
 * passing in an array list of uuIds as arguments
 * @param uuId uuId of calendar
 */
function get(data) {
  const url = '/api/calendar/get';
  const config = {
    
  };
  return httpAjax.post(url, data, config);
}

/**
 * update an  existing calendar 
 * by passing necessary information
 * @param FormData formData 
 * Note: Possible properties for formData: 
 *  1. name: String; Mandatory field.
 *  2. calendar: Blob/File Object; Mandatory field.
 *  3. calendars: String; Optional field.
 *  4. checksum: md5 hash. Optional field. If supply, backend validation will kick in.
 *  5. parent: uuId. Optinal field. serve as calendar/directory for upload calendar.
 *  6. uuId: target uuId; Mandatory field.
 */
function update(data) {
  const url = '/api/calendar/update';
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a calendar
 * by passing in uuId
 * @param String uuId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function remove(data) {
  const url = '/api/calendar/delete';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}