export {
  durationComparator,
  numberComparator,
  booleanComparator,
  skillComparator,
  staffComparator,
  noteComparator,
  stageComparator,
  resourceComparator,
  rebateComparator,
  constraintComparator,
  departmentComparator,
  companyComparator,
  locationComparator
}

function durationComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (valueA != null && typeof valueA !== 'string' || valueB != null && typeof valueB !== 'string') {
    return 0; //Skip sorting when the date type of provided values are not string.
  }
  
  const vA = parseFloat(valueA.substring(0, valueA.length - 1));
  const vB = parseFloat(valueB.substring(0, valueB.length - 1));
  return vA - vB;
}

function numberComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }
  if ((typeof valueA !== 'number' && typeof valueA !== 'boolean') || 
      (typeof valueB !== 'number' && typeof valueB !== 'boolean')) {
    return 0; //Skip sorting when the date type of provided values are not string.
  }
  return valueA - valueB;
}

function booleanComparator(valueA, valueB) {
  return numberComparator(valueA, valueB);
}

function skillComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
    return 0; //Skip sorting when the data type of provided values are not array.
  }

  return valueA.map(i => `${i.name}${i.level}`).join().localeCompare(
    valueB.map(i => `${i.name}${i.level}`).join());
}

function staffComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
    return 0; //Skip sorting when the data type of provided values are not array.
  }

  return valueA.map(i => `${i.name}${i.utilization}`).join().localeCompare(
    valueB.map(i => `${i.name}${i.utilization}`).join());
}

function noteComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
    return 0;
  }

  return valueA.map(i => i.text).join().localeCompare(
    valueB.map(i => i.text).join());
}

function stageComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if ( typeof valueA !== 'object' || typeof valueB !== 'object') {
    return 0;
  }

  if (valueA.name == null) {
    return -1;
  }

  if (valueB.name == null) {
    return 1;
  }

  return valueA.name.localeCompare(valueB.name);
}

function resourceComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
    return 0;
  }

  return valueA.map(i => `${i.name}${i.unit}`).join().localeCompare(
    valueB.map(i => `${i.name}${i.unit}`).join());
}

function rebateComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
    return 0;
  }

  return valueA.map(i => `${i.name}${i.rebate}`).join().localeCompare(
    valueB.map(i => `${i.name}${i.rebate}`).join());
}

function constraintComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null || valueA.type == null) {
    return -1;
  }
  if (valueB == null || valueB.type == null) {
    return 1;
  }

  if ( typeof valueA !== 'object' || typeof valueB !== 'object') {
    return 0;
  }

  if (valueA.type == null) {
    return -1;
  }

  if (valueB.type == null) {
    return 1;
  }

  if (valueA.type != valueB.type) {
    return valueA.type.localeCompare(valueB.type);
  }
  if (valueA.time == null) {
    return -1;
  }
  if (valueB.time == null) {
    return 1;
  }
  return valueA.time - valueB.time;
}

function departmentComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
    return 0; //Skip sorting when the data type of provided values are not array.
  }

  return valueA.map(i => i.name).join().localeCompare(
    valueB.map(i => i.name).join());
}

function companyComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
    return 0; //Skip sorting when the data type of provided values are not array.
  }

  return valueA.map(i => i.name).join().localeCompare(
    valueB.map(i => i.name).join());
}

function locationComparator(valueA, valueB) {
  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return -1;
  }
  if (valueB == null) {
    return 1;
  }

  if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
    return 0; //Skip sorting when the data type of provided values are not array.
  }

  return valueA.map(i => i.name).join().localeCompare(
    valueB.map(i => i.name).join());
}