var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ag-cell-label-container ag-header-cell-sorted-none",
      attrs: { role: "presentation" },
      on: { click: _vm.headerClicked },
    },
    [
      _c(
        "div",
        {
          ref: "eLabel",
          staticClass: "ag-header-cell-label",
          attrs: { id: "CELL_ADD_" + _vm.groupId, role: "presentation" },
        },
        [
          _c(
            "span",
            {
              ref: "eText",
              staticClass: "ag-header-cell-text",
              staticStyle: { "margin-left": "auto", "margin-right": "auto" },
            },
            [
              _c("font-awesome-icon", {
                style: { color: "var(--grid-toolbar-button)" },
                attrs: { icon: "fa-regular fa-plus" },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-popover",
        {
          attrs: {
            target: "CELL_ADD_" + _vm.groupId,
            triggers: "hover",
            placement: "top",
            boundary: "viewport",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("task_compact.button.add_task")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }