
const countryCodes = [
{text: 'AFGHANISTAN', value: 'AF'},
{text: 'ALBANIA', value: 'AL'},
{text: 'ALGERIA', value: 'DZ'},
{text: 'AMERICAN SAMOA', value: 'AS'},
{text: 'ANDORRA', value: 'AD'},
{text: 'ANGOLA', value: 'AO'},
{text: 'ANGUILLA', value: 'AI'},
{text: 'ANTARCTICA', value: 'AQ'},
{text: 'ANTIGUA AND BARBUDA', value: 'AG'},
{text: 'ARGENTINA', value: 'AR'},
{text: 'ARMENIA', value: 'AM'}, 
{text: 'ARUBA', value: 'AW'},
{text: 'AUSTRALIA', value: 'AU'},
{text: 'AUSTRIA', value: 'AT'},
{text: 'AZERBAIJAN', value: 'AZ'}, 
{text: 'BAHAMAS', value: 'BS'},
{text: 'BAHRAIN', value: 'BH'},
{text: 'BANGLADESH', value: 'BD'},
{text: 'BARBADOS', value: 'BB'},
{text: 'BELARUS', value: 'BY'}, 
{text: 'BELGIUM', value: 'BE'},
{text: 'BELIZE', value: 'BZ'},
{text: 'BENIN', value: 'BJ'},
{text: 'BERMUDA', value: 'BM'},
{text: 'BHUTAN', value: 'BT'},
{text: 'BOLIVIA', value: 'BO'},
{text: 'BOSNIA AND HERZEGOWINA', value: 'BA'},
{text: 'BOTSWANA', value: 'BW'},
{text: 'BOUVET ISLAND', value: 'BV'},
{text: 'BRAZIL', value: 'BR'},
{text: 'BRITISH INDIAN OCEAN TERRITORY', value: 'IO'},
{text: 'BRUNEI DARUSSALAM', value: 'BN'},
{text: 'BULGARIA', value: 'BG'},
{text: 'BURKINA FASO', value: 'BF'},
{text: 'BURUNDI', value: 'BI'},
{text: 'CAMBODIA', value: 'KH'},
{text: 'CAMEROON', value: 'CM'},
{text: 'CANADA', value: 'CA'},
{text: 'CAPE VERDE', value: 'CV'},
{text: 'CAYMAN ISLANDS', value: 'KY'},
{text: 'CENTRAL AFRICAN REPUBLIC', value: 'CF'},
{text: 'CHAD', value: 'TD'},
{text: 'CHILE', value: 'CL'},
{text: 'CHINA', value: 'CN'},
{text: 'CHRISTMAS ISLAND', value: 'CX'},
{text: 'COCOS (KEELING) ISLANDS', value: 'CC'},
{text: 'COLOMBIA', value: 'CO'},
{text: 'COMOROS', value: 'KM'},
{text: 'CONGO', value: 'CG'},
{text: 'COOK ISLANDS', value: 'CK'},
{text: 'COSTA RICA', value: 'CR'},
{text: 'COTE D\'IVOIRE', value: 'CI'},
{text: 'CROATIA (local name: Hrvatska)', value: 'HR'},
{text: 'CUBA', value: 'CU'},
{text: 'CYPRUS', value: 'CY'},
{text: 'CZECH REPUBLIC', value: 'CZ'}, 
{text: 'DENMARK', value: 'DK'},
{text: 'DJIBOUTI', value: 'DJ'},
{text: 'DOMINICA', value: 'DM'},
{text: 'DOMINICAN REPUBLIC', value: 'DO'},
{text: 'EAST TIMOR', value: 'TP'},
{text: 'ECUADOR', value: 'EC'},
{text: 'EGYPT', value: 'EG'},
{text: 'EL SALVADOR', value: 'SV'},
{text: 'EQUATORIAL GUINEA', value: 'GQ'},
{text: 'ERITREA', value: 'ER'},
{text: 'ESTONIA', value: 'EE'}, 
{text: 'ETHIOPIA', value: 'ET'},
{text: 'FALKLAND ISLANDS (MALVINAS)', value: 'FK'},
{text: 'FAROE ISLANDS', value: 'FO'},
{text: 'FIJI', value: 'FJ'},
{text: 'FINLAND', value: 'FI'},
{text: 'FRANCE', value: 'FR'},
{text: 'FRANCE, METROPOLITAN', value: 'FX'}, 
{text: 'FRENCH GUIANA', value: 'GF'},
{text: 'FRENCH POLYNESIA', value: 'PF'},
{text: 'FRENCH SOUTHERN TERRITORIES', value: 'TF'},
{text: 'GABON', value: 'GA'},
{text: 'GAMBIA', value: 'GM'},
{text: 'GEORGIA', value: 'GE'}, 
{text: 'GERMANY', value: 'DE'},
{text: 'GHANA', value: 'GH'},
{text: 'GIBRALTAR', value: 'GI'},
{text: 'GREECE', value: 'GR'},
{text: 'GREENLAND', value: 'GL'},
{text: 'GRENADA', value: 'GD'},
{text: 'GUADELOUPE', value: 'GP'},
{text: 'GUAM', value: 'GU'},
{text: 'GUATEMALA', value: 'GT'},
{text: 'GUINEA', value: 'GN'},
{text: 'GUINEA-BISSAU', value: 'GW'},
{text: 'GUYANA', value: 'GY'},
{text: 'HAITI', value: 'HT'},
{text: 'HEARD AND MC DONALD ISLANDS', value: 'HM'},
{text: 'HOLY SEE (VATICAN CITY STATE)', value: 'VA'},
{text: 'HONDURAS', value: 'HN'},
{text: 'HONG KONG', value: 'HK'},
{text: 'HUNGARY', value: 'HU'},
{text: 'ICELAND', value: 'IS'},
{text: 'INDIA', value: 'IN'},
{text: 'INDONESIA', value: 'ID'},
{text: 'IRAN (ISLAMIC REPUBLIC OF)', value: 'IR'},
{text: 'IRAQ', value: 'IQ'},
{text: 'IRELAND', value: 'IE'},
{text: 'ISRAEL', value: 'IL'},
{text: 'ITALY', value: 'IT'},
{text: 'JAMAICA', value: 'JM'},
{text: 'JAPAN', value: 'JP'},
{text: 'JORDAN', value: 'JO'},
{text: 'KAZAKHSTAN', value: 'KZ'},
{text: 'KENYA', value: 'KE'},
{text: 'KIRIBATI', value: 'KI'},
{text: 'KOREA, DEMOCRATIC PEOPLE\'S REPUBLIC OF', value: 'KP'},
{text: 'KOREA, REPUBLIC OF', value: 'KR'},
{text: 'KUWAIT', value: 'KW'},
{text: 'KYRGYZSTAN', value: 'KG'}, 
{text: 'LAO PEOPLE\'S DEMOCRATIC REPUBLIC', value: 'LA'},
{text: 'LATVIA', value: 'LV'}, 
{text: 'LEBANON', value: 'LB'},
{text: 'LESOTHO', value: 'LS'},
{text: 'LIBERIA', value: 'LR'},
{text: 'LIBYAN ARAB JAMAHIRIYA', value: 'LY'},
{text: 'LIECHTENSTEIN', value: 'LI'},
{text: 'LITHUANIA', value: 'LT'}, 
{text: 'LUXEMBOURG', value: 'LU'},
{text: 'MACAU', value: 'MO'},
{text: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF', value: 'MK'},
{text: 'MADAGASCAR', value: 'MG'},
{text: 'MALAWI', value: 'MW'},
{text: 'MALAYSIA', value: 'MY'},
{text: 'MALDIVES', value: 'MV'},
{text: 'MALI', value: 'ML'},
{text: 'MALTA', value: 'MT'},
{text: 'MARSHALL ISLANDS', value: 'MH'},
{text: 'MARTINIQUE', value: 'MQ'},
{text: 'MAURITANIA', value: 'MR'},
{text: 'MAURITIUS', value: 'MU'},
{text: 'MAYOTTE', value: 'YT'}, 
{text: 'MEXICO', value: 'MX'},
{text: 'MICRONESIA, FEDERATED STATES OF', value: 'FM'},
{text: 'MOLDOVA, REPUBLIC OF', value: 'MD'}, 
{text: 'MONACO', value: 'MC'},
{text: 'MONGOLIA', value: 'MN'},
{text: 'MONTSERRAT', value: 'MS'},
{text: 'MOROCCO', value: 'MA'},
{text: 'MOZAMBIQUE', value: 'MZ'},
{text: 'MYANMAR', value: 'MM'},
{text: 'NAMIBIA', value: 'NA'},
{text: 'NAURU', value: 'NR'},
{text: 'NEPAL', value: 'NP'},
{text: 'NETHERLANDS', value: 'NL'},
{text: 'NETHERLANDS ANTILLES', value: 'AN'},
{text: 'NEW CALEDONIA', value: 'NC'},
{text: 'NEW ZEALAND', value: 'NZ'},
{text: 'NICARAGUA', value: 'NI'},
{text: 'NIGER', value: 'NE'},
{text: 'NIGERIA', value: 'NG'},
{text: 'NIUE', value: 'NU'},
{text: 'NORFOLK ISLAND', value: 'NF'},
{text: 'NORTHERN MARIANA ISLANDS', value: 'MP'},
{text: 'NORWAY', value: 'NO'},
{text: 'OMAN', value: 'OM'},
{text: 'PAKISTAN', value: 'PK'},
{text: 'PALAU', value: 'PW'},
{text: 'PANAMA', value: 'PA'},
{text: 'PAPUA NEW GUINEA', value: 'PG'},
{text: 'PARAGUAY', value: 'PY'},
{text: 'PERU', value: 'PE'},
{text: 'PHILIPPINES', value: 'PH'},
{text: 'PITCAIRN', value: 'PN'},
{text: 'POLAND', value: 'PL'},
{text: 'PORTUGAL', value: 'PT'},
{text: 'PUERTO RICO', value: 'PR'},
{text: 'QATAR', value: 'QA'},
{text: 'REUNION', value: 'RE'},
{text: 'ROMANIA', value: 'RO'},
{text: 'RUSSIAN FEDERATION', value: 'RU'},
{text: 'RWANDA', value: 'RW'},
{text: 'SAINT KITTS AND NEVIS', value: 'KN'},
{text: 'SAINT LUCIA', value: 'LC'},
{text: 'SAINT VINCENT AND THE GRENADINES', value: 'VC'},
{text: 'SAMOA', value: 'WS'},
{text: 'SAN MARINO', value: 'SM'},
{text: 'SAO TOME AND PRINCIPE', value: 'ST'},
{text: 'SAUDI ARABIA', value: 'SA'},
{text: 'SENEGAL', value: 'SN'},
{text: 'SEYCHELLES', value: 'SC'},
{text: 'SIERRA LEONE', value: 'SL'},
{text: 'SINGAPORE', value: 'SG'},
{text: 'SLOVAKIA (Slovak Republic)', value: 'SK'}, 
{text: 'SLOVENIA', value: 'SI'}, 
{text: 'SOLOMON ISLANDS', value: 'SB'},
{text: 'SOMALIA', value: 'SO'},
{text: 'SOUTH AFRICA', value: 'ZA'},
{text: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS', value: 'GS'},
{text: 'SPAIN', value: 'ES'},
{text: 'SRI LANKA', value: 'LK'},
{text: 'ST. HELENA', value: 'SH'},
{text: 'ST. PIERRE AND MIQUELON', value: 'PM'},
{text: 'SUDAN', value: 'SD'},
{text: 'SURINAME', value: 'SR'},
{text: 'SVALBARD AND JAN MAYEN ISLANDS', value: 'SJ'},
{text: 'SWAZILAND', value: 'SZ'},
{text: 'SWEDEN', value: 'SE'},
{text: 'SWITZERLAND', value: 'CH'},
{text: 'SYRIAN ARAB REPUBLIC', value: 'SY'},
{text: 'TAIWAN, PROVINCE OF CHINA', value: 'TW'},
{text: 'TAJIKISTAN', value: 'TJ'}, 
{text: 'TANZANIA, UNITED REPUBLIC OF', value: 'TZ'},
{text: 'THAILAND', value: 'TH'},
{text: 'TOGO', value: 'TG'},
{text: 'TOKELAU', value: 'TK'},
{text: 'TONGA', value: 'TO'},
{text: 'TRINIDAD AND TOBAGO', value: 'TT'},
{text: 'TUNISIA', value: 'TN'},
{text: 'TURKEY', value: 'TR'},
{text: 'TURKMENISTAN', value: 'TM'}, 
{text: 'TURKS AND CAICOS ISLANDS', value: 'TC'},
{text: 'TUVALU', value: 'TV'},
{text: 'UGANDA', value: 'UG'},
{text: 'UKRAINE', value: 'UA'},
{text: 'UNITED ARAB EMIRATES', value: 'AE'},
{text: 'UNITED KINGDOM', value: 'GB'},
{text: 'UNITED STATES', value: 'US'},
{text: 'UNITED STATES MINOR OUTLYING ISLANDS', value: 'UM'},
{text: 'URUGUAY', value: 'UY'},
{text: 'UZBEKISTAN', value: 'UZ'}, 
{text: 'VANUATU', value: 'VU'},
{text: 'VENEZUELA', value: 'VE'},
{text: 'VIET NAM', value: 'VN'},
{text: 'VIRGIN ISLANDS (BRITISH)', value: 'VG'},
{text: 'VIRGIN ISLANDS (U.S.)', value: 'VI'},
{text: 'WALLIS AND FUTUNA ISLANDS', value: 'WF'},
{text: 'WESTERN SAHARA', value: 'EH'},
{text: 'YEMEN', value: 'YE'},
{text: 'YUGOSLAVIA', value: 'YU'},
{text: 'ZAIRE', value: 'ZR'},
{text: 'ZAMBIA', value: 'ZM'},
{text: 'ZIMBABWE', value: 'ZW'}
];

export default countryCodes;