var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("StaffBadgeGroupModal", {
    attrs: {
      show: _vm.modalShow,
      companies: _vm.companies,
      staffList: _vm.staffList,
      taskUuId: _vm.taskId,
      hideUtilization: _vm.params.hideUtilization,
    },
    on: {
      "update:show": function ($event) {
        _vm.modalShow = $event
      },
      ok: _vm.staffSelectorOk,
      cancel: _vm.staffSelectorCancel,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }