<template>
  <router-link v-if="actions.length === 0"
    :to="url" 
    custom
    v-slot="{ isActive }"
  >
    <li class="nav-item nav-dropdown" :class="[keepopen ? 'open' : '', isActive && 'side-link-active']" disabled>
      <div @click="handleClick">
        <router-link :to="url" :class="classList" v-bind="attributes">
          <font-awesome-icon :icon="icon" class="nav-icon"/> <span>{{name}}</span>
          <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
        </router-link>
      </div>
      <ul class="nav-dropdown-items" v-if="showSubmenus()">
        <slot :id="currentId"></slot>
      </ul>
    </li>
  </router-link>
  <li class="nav-item nav-dropdown" :class="keepopen ? 'bg-open open' : 'closed'" @mouseleave="onMenuLeave" v-else>
    <div class="toggle-expand d-inline-block nav-link" @click="toggleExpand">
     <font-awesome-icon :id="`COLLAPSE_EXPAND_${name.replace(/\W/g,'_')}`" :icon="keepopen ? ['far', 'chevron-down'] : ['far', 'chevron-right']" class="toggle-icon nav-icon"/>
       <b-popover
        :target="`COLLAPSE_EXPAND_${name.replace(/\W/g,'_')}`"
        placement="top"
        triggers="hover"
        :content="keepopen ? $t('button.collapse_list') : $t('button.expand_list')">
      </b-popover> 
      <span>{{name}}</span>
      <span class="action-container-class">
        <div class="action-class" v-for="(action, index) in actions" v-bind:key="index">
          <div v-if="action.emitevent" @click.stop="handleActionClick(action)">
            <font-awesome-icon v-if="!action.permission || hasPermission(action.permission)" :id="`ACTION_${action.id}`" class="text-white" :icon="action.icon"/>
            <b-popover
              :target="`ACTION_${action.id}`"
              placement="top"
              triggers="hover"
              :content="$t(action.name)">
            </b-popover>
          </div>
          <b-dropdown ref="menu" variant="link" class="action-menu" no-caret v-else-if="!action.permission || hasPermission(action.permission)">
            <template slot="button-content">
              <div class="text">
                <font-awesome-icon :icon="action.icon"/>
              </div>
            </template>
        
            <template>
              <b-dropdown-item v-for="(item, itemindex) in action.items" v-bind:key="itemindex" @click.stop="handleActionClick(item)">{{ $t(item.name) }}</b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
      </span>
    </div>
    
    <ul class="nav-dropdown-items nav-dropdown-items-togglelist" v-if="showSubmenus()">
      <slot :id="currentId"></slot>
    </ul>
  </li>
</template>

<script>
import { EventBus, loadViewProfile, updateViewProfile } from '@/helpers';

export default {
  name: 'sidebar-nav-link-dropdown',
  props: {
    profileid: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    icon: {
      type: [String, Array],
      default: ''
    },
    badge: {
      type: Object,
      default: () => {}
    },
    variant: {
      type: String,
      default: ''
    },
    classes: {
      type: [String, Array, Object],
      default: ''
    },
    attributes: {
      type: Object,
      default: () => { return Object.create(null) }
    },
    regex: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default: () => { return [] }
    }
  },
  data() {
    return {
      keepopen: false,
      userId: null,
      settings: {}
    }
  },
  beforeMount() {
    this.restoreState();
  },
  computed: {
    classList () {
      return [
        'nav-link',
        this.linkVariant,
        ...this.disabledClasses,
        ...this.attrClasses,
        ...this.itemClasses
      ]
    },
    // classIcon () {
    //   return [
    //     'nav-icon',
    //     this.icon
    //   ]
    // },
    linkVariant () {
      return this.variant ? `nav-link-${this.variant}` : ''
    },
    itemClasses () {
      return this.getClassArray(this.classes)
    },
    attrClasses () {
      return this.getClassArray(this.attributes.class)
    },
    disabledClasses () {
      return this.isDisabled ? 'disabled'.split(' ') : []
    },
    isDisabled () {
      return Boolean(this.attributes.disabled)
    },
    isExternalLink () {
      return Boolean(this.url.substring(0, 4) === 'http')
    },
    currentId() {
      if (this.regex !== '') {
        const path = this.$router.currentRoute.path;
        const reg = new RegExp('^\\/.+\\/(?:resource|staff|gantt|tasks|kanban|summary)\\/([0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12})$', 'i');
        const matched = path.match(reg);
        return matched? matched[matched.length-1] : null;
      }
      return null;
    }
  },
  methods: {
    onMenuLeave() {
      if (this.$refs.menu) {
        this.$refs.menu[0].hide();
      }
    },
    async restoreState() {
      const self = this;
      if (self.profileid) {
        self.userId = self.$store.state.authentication.user.uuId;
        self.settings = await loadViewProfile(this.settings, self.userId);
        if (typeof this.settings[self.profileid] !== 'undefined') {
          setTimeout(() => {
            self.keepopen = self.settings[self.profileid].keepopen;
          }, 100);
        }
        else {
          self.keepopen = true;
        }
      }
    },
    handleClick (e) {
      e.preventDefault();
      // only collapse the menu if a regex is defined, otherwise show the menus
      if (this.regex !== '') {
        var elem = e.target;
        while (elem !== null && elem.tagName !== 'LI') {
          elem = elem.parentElement;
        }
        elem.classList.remove('open');
      }
    },
    handleActionClick(action) {
      const current = this.$router.currentRoute;
      if (current !== this.url &&
          action.url !== null) {
        this.$router.push(action.url);
      }
      
      if (action.emitevent) {
        EventBus.$emit(action.emitevent, action.params);
      }
    },
    getClassArray(classes) {
      return !classes ? [] : typeof classes === 'string' || classes instanceof String ? classes.split(' ') : Array.isArray(classes) ? classes : Object.keys(classes).filter(i=>classes[i])
    },
    showSubmenus() {
      const path = this.$router.currentRoute.path;
      const reg = new RegExp(this.regex, "i");
      return path.match(reg) !== null;
    },
    toggleExpand() {
      this.keepopen = !this.keepopen;
      this.settings[this.profileid] = { keepopen: this.keepopen };
      updateViewProfile(this.settings, this.userId);
    }
  }
}
</script>

<style scoped lang="css">

  /* ::v-deep .side-link-active {
  
  } */
  
  ::v-deep .sidebar .side-link-active {
      position: relative;
  }
  
  ::v-deep .nav-dropdown-items-hidden {
    max-height: 0 !important;
  }
  
  .bg-open {
    background-color: transparent !important;
  }
  
  .action-container-class {
    position: absolute;
    right: 5px;
    display: inline-block;
  }
  
  .action-class {
    position: relative;
    cursor: pointer;
    min-width: 12px;
    margin-right: 5px;
    display: inline-block;
    text-align: center;
  }
  
  .sidebar-minimized .action-class {
    margin-right: 3px;
  }
  
  .toggle-expand {
    padding-left: 20px;
    cursor: pointer;
  }
  
  .toggle-icon {
    position: relative;
    top: 2px;
  }
  
  
  @media (min-width: 992px) {
    .sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items-togglelist {
      top: 45px;
      color: var(--sidebar-active) !important;
      background-color: var(--sidebar-active-bg) !important;
    }

    .sidebar-minimized .sidebar .nav > .nav-dropdown.open:hover {
      color: var(--sidebar-active) !important;
      background-color: var(--sidebar-active-bg) !important;
    }
    .sidebar-minimized .nav-dropdown.closed .nav-dropdown-items {
      max-height: 0px !important;
      overflow: auto;
    }
    .sidebar-minimized .sidebar .nav-link {
      width: 250px;
    }
  }
</style>
