import { cloneDeep } from 'lodash';
import { companyProfileService } from '@/services';

export async function updateCompanies(holderEntityId, holderEntityService, oldCompanies, newCompanies) {
  const result = {
    hasError: false,
    errorCodes: []
  }
  const company = cloneDeep(newCompanies);
  const originCompany = oldCompanies
  const toAdd = [];
  const unchangedIds = [];
  for(let i = 0, len = company.length; i < len; i++) {
    const comp = company[i];
    const index = originCompany.findIndex(j => j.uuId === comp.uuId);
    if(index == -1) {
      toAdd.push(comp);
    } else {
      unchangedIds.push(comp.uuId);
    }
  }

  const toAddIds = toAdd.map(i => i.uuId);
  const toRemove = originCompany.filter(i => !toAddIds.includes(i.uuId) && !unchangedIds.includes(i.uuId));

  //To add new company if there is any.
  if(toAdd.length > 0) {
    let { hasError=false, errorCode=null } = await holderEntityService.create(holderEntityId, toAdd[0].uuId)
    .then(response => {
      if(207 == response.status) {
        const list = response.data[response.data.jobCase];
        const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
        return { hasError: failIds.length > 0, errorCode: 207 };
      }
      return {}
    })
    .catch(e => {
      if(e.response && 422 == e.response.status) {
        const list = e.response.data[e.response.data.jobCase];
        const existingIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
        return { hasError: toAdd.filter(i => !existingIds.includes(i.uuId)).length > 0, errorCode: 422 };
      } else {
        return { hasError: true, errorCode: e != null && e.response != null? e.response.status: null }
      }
    });
    if(hasError) {
      result.hasError = true;
      result.errorCodes.push(errorCode);
    }
  }

  
  //Try remove company which user wants to remove if there is any
  if(toRemove.length > 0) {
    for (const companyId of toRemove) {
      const clues = ['OK', 'Unknown_relation'];
      let { hasError=false, errorCode=null } = await holderEntityService.remove(holderEntityId, companyId.uuId)
      .then(response => {
        if(207 == response.status) {
          const list = response.data[response.data.jobCase];
          const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
          return { hasError: toRemove.filter(i => failIds.includes(i.uuId)).length > 0, errorCode: 207 };
        }
        return {};
      })
      .catch(e => {
        if(e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
          return { hasError: toRemove.filter(i => failIds.includes(i.uuId)).length > 0, errorCode: 422 };
        } else {
          return { hasError: true, errorCode: e != null && e.response != null? e.response.status: null }
        }
      });
      if(hasError) {
        result.hasError = true;
        result.errorCodes.push(errorCode);
      }
    }
  }
  return result;
}

export function getIds(data) {
  const ids = [];
  for (const c of data) {
    ids.push(c.uuId);
    if (c.companyList) {
      ids.unshift(...getIds(c.companyList));
    }
  }
  return ids;
}

export function buildCompanyFilter(uuId, data) {
  const ids = [uuId, ...getIds(data)];
  return ids;
}


export async function getSelectedCompany(userId) {
  const uuId = await companyProfileService.list(userId).then((response) => {  
    const profileData = response.data[response.data.jobCase];
    if (profileData.length === 0) {
      return null;
    }
    else {
      return profileData[0].company.uuId;
    }
  })
  .catch((e) => {
    console.error(e); // eslint-disable-line no-console
    return null;
  });
  return uuId;
}
