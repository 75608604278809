var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "company" }, [
        _vm.avatarUrl !== null &&
        _vm.canView("STORAGE_FILE") &&
        _vm.canView("COMPANY", ["STORAGE_FILE"])
          ? _c("img", {
              staticClass: "img-avatar",
              attrs: { src: _vm.avatarUrl },
            })
          : _vm._e(),
        _c("span", { staticClass: "d-md-inline" }, [
          _vm._v(" " + _vm._s(_vm.name) + " "),
        ]),
        _c(
          "div",
          {
            staticClass: "edit",
            class: _vm.avatarUrl !== null ? "has-image" : "",
          },
          [
            _c(
              "div",
              {
                staticClass: "text",
                attrs: { id: "changecompanybtn" },
                on: { click: _vm.selector },
              },
              [
                _c("font-awesome-icon", { attrs: { icon: ["far", "pen"] } }),
                _c("b-popover", {
                  attrs: {
                    target: "changecompanybtn",
                    placement: "top",
                    triggers: "hover",
                    content: _vm.$t("button.change_company"),
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm.selectorShow
        ? _c("CompanySelectorModalForAdmin", {
            attrs: {
              show: _vm.selectorShow,
              preselected:
                _vm.settings != null && _vm.settings.company != null
                  ? _vm.settings.company.uuId
                  : null,
              nonAdmin: "",
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.selectorShow = $event
              },
              ok: _vm.selectorSuccess,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }