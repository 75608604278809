<template>
  <span class="position">
    <template v-if="autoScheduling === true">
      <font-awesome-icon :icon="['far','calendar-check']" class="scheduling-icon"/>
    </template>
    <template v-if="autoScheduling === false">
      <font-awesome-icon :icon="['far','thumbtack']" class="scheduling-icon"/>
    </template>
  </span>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'TaskAutoSchedulingCellRenderer',
  data() {
    return {
      autoScheduling: null,
    }
  },
  created() {
    if (this.params.data.taskType == 'Project') {
      this.autoScheduling = null;
    } else {
      this.autoScheduling = this.params.value == true? this.params.value : false;
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
  },
  methods: {
  }
});
</script>

<style lang="scss">
.scheduling-icon {
  font-size: 14px;
  position: relative;
  top: 3px;
}
</style>