

<template>
  <div ref="select-header-container" class="select-header-container">
    <span v-if="label" class="mr-1">{{ label }}</span>
    <div ref="list-multiselect-wrapper" class="list-cell-header-multiselect-wrapper">
      <multiselect ref="list-multiselect" v-model="value" class="custom-dropdown-options list-cell-header enable-option-icon fit-label-fix fit-content-fix"
        :max-height="300"
        :options="options.map(i => i.value)"
        :custom-label="getOptionLabel"
        :placeholder="''"
        :searchable="false" 
        :allow-empty="false"
        :showLabels="false"
        @input="onChange"
        @open="onSelectOpened">
        <template slot="option" slot-scope="props">
          <font-awesome-icon class="selected-option-icon" v-if="value == props.option" :icon="['far', 'check']" />
          <span class="option__title">{{ getOptionLabel(props.option) }}</span>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Multiselect from 'vue-multiselect';

export default Vue.extend({
  name: 'ListHeaderComponent',
  components: {
    Multiselect
  },
  data() {
    return {
      value: null,
      options: [],
      label: null,
      selectLeft: 0,
      selectTop: 0,
      selectZIndex: 0,
      menuOpened: false,
    }
  },
  beforeMount() {
    if (this.params !== null) {
      this.options = this.params.options;
      this.value = this.params.value;
      this.label = this.params.label ? this.params.label : null;
    }
  },
  mounted() {
    
  },
  beforeDestroy() {
    
  },
  computed: {
    
  },
  methods: {
    onChange() {
      this.params.context.componentParent.listChange(this.value);
    },
    getOptionLabel(value) {
      return this.options.find(i => i.value === value)?.text || value;
    },
    onSelectOpened() {
      const ms = this.$refs['list-multiselect'];
      ms.$refs.list.style.left = `${this.$refs['list-multiselect-wrapper'].getBoundingClientRect().left}px`;
      ms.$refs.list.style.position = 'fixed';
    },
  }
})
</script>

<style lang="scss" scoped>
.select-header-container {
  display: flex;
  align-items: center;
}

.list-cell-header-multiselect-wrapper {
  height: 28px; //ref the multiselect's height
}
</style>
