import { profileService } from '@/services';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

const categoryKey = 'taskgroup';
const nodeKey = 'user';
const publicNodeKey = 'company';

export const taskGroupProfileService = {
  create,
  list,
  update,
  remove,
  share,
  unshare,
  sharePublic,
  unsharePublic,
  get,
  setting_setDefaultTaskGroupId,
  setting_set,
  setting_get,
  getDefault,
};

/**
 * Create a new taskgroupProfile 
 * by passing necessary information
 * @param {Array} data 
 * e.g [{  ..}]
 */
function create(data, userId) {
  return profileService.create(data, categoryKey, nodeKey, userId);
}

/**
 * Retrieving a concise list of taskgroupProfile info
 */
 function list(companyId, userId) {
  return profileService.listAll(categoryKey, [{ key: publicNodeKey, value: companyId }, { key: nodeKey, value: userId }]);
}

/**
 * Update taskgroupProfile details 
 * by passing  necessary information
 * @param {Array} data 
 * e.g [{  ..}]
 */
 function update(data, userId) {
  return profileService.update(data, categoryKey, nodeKey, userId);
}

/**
 * Delete a list of data view Profiles
 * by passing in an array list of uuIds
 * @param {Array} data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
 function remove(data, userId) {
  return profileService.remove(data, categoryKey, nodeKey, userId, true);
}

/**
 * Share a of profile
 * by passing in a uuId
 * @param {String} profileId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 * @param {String} shareToId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function share(profileId, shareToId) {
  return profileService.share(categoryKey, nodeKey, profileId, shareToId);
}

/**
 * Unshare a of profile
 * by passing in a uuId
 * @param {String} profileId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 * @param {String} shareToId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function unshare(profileId, shareToId) {
  return profileService.unshare(categoryKey, nodeKey, profileId, shareToId);
}


/**
 * Share a of profile
 * by passing in a uuId
 * @param {String} profileId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 * @param {String} shareToId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
 function sharePublic(profileId, shareToId) {
  return profileService.share(categoryKey, publicNodeKey, profileId, shareToId);
}

/**
 * Unshare a of profile
 * by passing in a uuId
 * @param {String} profileId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 * @param {String} shareToId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function unsharePublic(profileId, shareToId) {
  return profileService.unshare(categoryKey, publicNodeKey, profileId, shareToId);
}

/**
 * Get specific profile
 * by passing in a profile uuId
 * @param {String} profileId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 * @returns profile object
 */
function get(profileId) {
  return profileService.get(profileId);
}

const settingCatKey = 'taskgroupsetting';

async function setting_setDefaultTaskGroupId(userId, defaultTaskGroupId, { createIfNone=false }) {
  let setting = await profileService.list(settingCatKey, nodeKey, userId)
  .then(response => {
    const data = response.data;
    const list = data[data.jobCase];
    if (list.length > 0) {
      return list[0];
    }
    return null;
  });

  if (setting == null) {
    if (createIfNone != true) {
      return Promise.reject(new Error('taskgroup_setting_not_found'));
    }    
    return profileService.create([{ defaultTaskGroupId: defaultTaskGroupId }], settingCatKey, nodeKey, userId);
  } else {
    setting.defaultTaskGroupId = defaultTaskGroupId;
    return profileService.update([setting], settingCatKey, nodeKey, userId);
  }
}

function setting_set(data, userId) {
  if (data == null) {
    return Promise.reject(new Error('missing_data'));
  }

  if (data.uuId == null) {
    return Promise.reject(new Error('missing_uuid'));
  }

  return profileService.update([data], settingCatKey, nodeKey, userId);
}

async function setting_get(userId, { createIfNone=false, defaultSetting=null }) {
  let setting = await profileService.list(settingCatKey, nodeKey, userId)
  .then(response => {
    const data = response.data;
    const list = data[data.jobCase];
    if (list.length > 0) {
      return list[0];
    }
    return null;
  })
  .catch(() => {
    return null;
  });

  if (setting == null && createIfNone != true) {
    return Promise.resolve(null);
  }

  setting = defaultSetting != null? defaultSetting : { defaultTaskGroupId: null };
  setting.uuId = await profileService.create([setting], settingCatKey, nodeKey, userId)
  .then(response => {
    const data = response.data;
    const list = data[data.jobCase];
    if (list.length > 0) {
      return list[0].uuId;
    }
    return null;
  })
  .catch(() => {
    return null;
  });
    
  if (setting.uuId == null) {
    throw new Error('fail_to_create_new_taskgroup_setting');
  }

  return Promise.resolve(setting);
}

//Get default taskgroup. 
//1. Check taskgroup setting for defaultTaskGroupId. If no setting, create one if createIfNone is true. Otherwise return null.
//2. If defaultTaskGroupId is null, get the first taskgroup from available task group list.
//3. If no available task group, create one if createIfNone is true. Otherwise return null.
//4. Remember to update taskgroup setting if defaultTasKGroupId is changed.
async function getDefault(companyId, userId, { createIfNone=false } = {}) {
  let setting = await profileService.list(settingCatKey, nodeKey, userId).then(response => {
    const data = response.data;
    const list = data[data.jobCase];
    if (list.length > 0) {
      return list[0];
    }
    return null;
  }).catch(() => {
    return null;
  });

  if (setting == null && createIfNone != true) {
    return Promise.resolve(null);
  }

  if (setting == null) {
    setting = { defaultTaskGroupId: null };
    setting.uuId = await profileService.create([setting], settingCatKey, nodeKey, userId)
    .then(response => {
      const data = response.data;
      const list = data[data.jobCase];
      if (list.length > 0) {
        return list[0].uuId;
      }
      return null;
    })
    .catch(() => {
      return null;
    });
  }

  if (setting.uuId == null) {
    throw new Error('fail_to_create_taskgroup_setting');
  }

  if (setting.defaultTaskGroupId == null && createIfNone != true) {
    return Promise.resolve(null);
  }

  let availableTaskGroupList = null;
  let _defaultTaskGroup = null;
  if (setting.defaultTaskGroupId == null) {
    _defaultTaskGroup = await list(companyId, userId)
    .then(response => {
      const _list = response.list;
      if (_list.length > 0) {
        availableTaskGroupList = _list;
        return _list[0];
      }
      return null;
    })
    .catch(() => {
      return null;
    });
  } else {
    _defaultTaskGroup = await get(setting.defaultTaskGroupId)
    .then(response => {
      const data = response.data;
      const list = data[data.jobCase];
      if (list.length > 0) {
        return list[0];
      }
      return null;
    })
    .catch(() => {
      return null;
    });
  }

  if (_defaultTaskGroup == null && createIfNone != true) {
    return Promise.resolve(null);
  }

  if (_defaultTaskGroup == null) {
    if (availableTaskGroupList == null) {
      availableTaskGroupList = await list(companyId, userId)
      .then(response => {
        const _list = response.list;
        return _list;
      })
      .catch(() => {
        return null;
      });
    }

    if (availableTaskGroupList == null) {
      throw new Error('fail_to_list_taskgroup');
    }
    
    if (availableTaskGroupList.length > 0) {
      _defaultTaskGroup = availableTaskGroupList[0];
    } else {
      _defaultTaskGroup = __taskGroupTemplate(userId);
      _defaultTaskGroup.uuId = await create([_defaultTaskGroup], userId)
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      })
      .catch(() => {
        return null;
      });
      
      if (_defaultTaskGroup.uuId == null) {
        throw new Error ('fail_to_create_default_taskgroup');
      }
    }
  }
  
  if (setting.defaultTaskGroupId != _defaultTaskGroup.uuId) {
    setting.defaultTaskGroupId = _defaultTaskGroup.uuId;
    await setting_set(setting, userId);
  }
  
  return Promise.resolve(_defaultTaskGroup);
}

function __taskGroupTemplate(userId) {
  return {
    uuId: null,
    headerName: 'Tasks',
    description: '',
    property: 'estimatedDuration',
    children: [],
    sharedVisibility: 'public',
    sharingMembers: [userId],
    editors: [userId],
    lastModified: moment.utc().valueOf()
  }
  
}