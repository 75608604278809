<template>
  <div ref="resource-aggrid-scheduler-container" class="position-relative">
    <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertError? 'danger':'success'" @resetAlert="resetAlert" @offsetHeight="updateGridHeight"/>
    <div v-if="!isWidget" class="resource-action-bar border-part">
      <PriorityNavigation class="grid-toolbar">
        <li>
          <span class="d-flex ml-2 mr-2">
            <label class="mr-1" for="dates">{{ $t('staff.dates') }}</label>
            <multiselect v-model="datesStr" class="custom-dropdown-options planner-action-bar enable-option-icon fit-content-fix"
              :max-height="300"
              :options="dateOptions.map(i => i.value)"
              :custom-label="getDateOptionLabel"
              :placeholder="''"
              :searchable="false" 
              :allow-empty="false"
              :showLabels="false"
              :option-height="25"
              @input="rangeSelected">
              <template slot="option" slot-scope="props">
                <font-awesome-icon class="selected-option-icon" v-if="datesStr == props.option" :icon="['far', 'check']" />
                <span class="option__title">{{ getDateOptionLabel(props.option) }}</span>
              </template>
            </multiselect>
          </span>
        </li>
        <li>
          <span class="d-flex mr-1 date">
            <label class="mr-1 align-self-baseline" for="startDate">{{ $t('staff.from') }}</label>
            <b-form-datepicker id="resourceUsageStartDate" v-model="startDate" class="date-picker d-flex" @input="dateChanged"
                today-button
                reset-button
                close-button
                hide-header
                :label-today-button="$t('date.today')"
                :label-reset-button="$t('date.reset')"
                :label-close-button="$t('date.close')"
                today-button-variant="primary"
                reset-button-variant="danger" 
                close-button-variant="secondary"
                size="sm"
              >
                <template v-slot:button-content="{ }">
                  <font-awesome-icon :icon="['far', 'calendar-days']" />
                </template>
              </b-form-datepicker>
          </span>
        </li>
        <li>
          <span class="d-flex mr-1 date">
            <label class="mr-1 align-self-baseline" for="endDate">{{ $t('staff.to') }}</label>
            <b-form-datepicker id="resourceUsageEndDate" v-model="endDate" class="date-picker d-flex" @input="dateChanged"
                today-button
                reset-button
                close-button
                hide-header
                :label-today-button="$t('date.today')"
                :label-reset-button="$t('date.reset')"
                :label-close-button="$t('date.close')"
                today-button-variant="primary"
                reset-button-variant="danger" 
                close-button-variant="secondary"
                size="sm"
              >
                <template v-slot:button-content="{ }">
                  <font-awesome-icon :icon="['far', 'calendar-days']" />
                </template>
              </b-form-datepicker>
          </span>
        </li>
        <li>
          <b-btn :id="`BTN_REFRESH_${id}`" class="ml-1" @click="daySelected" :pressed.sync="highlightRefresh">
            <font-awesome-icon :icon="['far', 'arrows-rotate']"/>
            <b-popover
              :target="`BTN_REFRESH_${id}`"
              placement="top"
              boundary="viewport"
              triggers="hover"
              :content="$t('button.refresh')">
            </b-popover>
          </b-btn>
        </li>
        <li>
          <span class="d-flex mr-1">
            <label class="mr-1" for="timescale">{{ $t('staff.timescale') }}</label>
            <multiselect v-model="span" class="custom-dropdown-options planner-action-bar enable-option-icon fit-content-fix"
              :max-height="300"
              :options="spanOptions.map(i => i.value)"
              :custom-label="getSpanOptionLabel"
              :placeholder="''"
              :searchable="false" 
              :allow-empty="false"
              :showLabels="false"
              :option-height="25"
              >
              <template slot="option" slot-scope="props">
                <font-awesome-icon class="selected-option-icon" v-if="span == props.option" :icon="['far', 'check']" />
                <span class="option__title">{{ getSpanOptionLabel(props.option) }}</span>
              </template>
            </multiselect>
          </span>
        </li>
        <li @[groupingMouseEnterEvent]="onGroupingOver" @mouseleave="onGroupingLeave">
          <b-dropdown :id="`BTN_GROUPING_${id}`" ref="grouping" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'list-tree']"/>
            </template>
            <b-dropdown-group :header="$t('groupby')">
              <b-dropdown-item @click="grouping.company = !grouping.company; onGroupChange(grouping.company, true)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'building']"/>{{ $t('staff.group.company') }}</span><font-awesome-icon class="active-check" v-if="grouping.company" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="grouping.location = !grouping.location; onGroupChange(grouping.location, true)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'map-marked-alt']"/>{{ $t('staff.group.location') }}</span><font-awesome-icon class="active-check" v-if="grouping.location" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="grouping.department = !grouping.department; onGroupChange(grouping.department, true)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'sitemap']"/>{{ $t('staff.group.department') }}</span><font-awesome-icon class="active-check" v-if="grouping.department" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="grouping.skills = !grouping.skills; onGroupChange(grouping.skills, true)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'head-side-brain']"/>{{ $t('staff.group.skill') }}</span><font-awesome-icon class="active-check" v-if="grouping.skills" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-group :header="$t('stafftype')">
              <b-dropdown-item @click="grouping.staff = !grouping.staff; onGroupChange(grouping.staff, false)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'user-hard-hat']"/>{{ $t('staff.group.staff') }}</span><font-awesome-icon class="active-check" v-if="grouping.staff" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="showGeneric = !showGeneric; onGroupChange(showGeneric, false)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'users']"/>{{ $t('staff.generic_staff') }}</span><font-awesome-icon class="active-check" v-if="showGeneric" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </li>
        <li @[allocMouseEnterEvent]="onAllocOver" @mouseleave="onAllocLeave">
          <b-dropdown :id="`BTN_ALLOC_${id}`" ref="alloc" class="alloc-dropdown action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'clock']"/>
            </template>
            <b-dropdown-group :header="$t('show')">
              <b-dropdown-item @click="onShowChange('usage')" href="#">
                <span class="action-item-label">{{ $t('staff.usage') }}</span><font-awesome-icon class="active-check" v-if="show.usage" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item v-if="canView('BOOKING')" @click="onShowChange('booking')" href="#">
                <span class="action-item-label">{{ $t('booking.title') }}</span><font-awesome-icon class="active-check" v-if="show.booking" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item v-if="canView('ACTIVITY')" @click="onShowChange('activity')" href="#">
                <span class="action-item-label">{{ $t('activity.title') }}</span><font-awesome-icon class="active-check" v-if="show.activity" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item v-if="canView('TASK')" @click="onShowChange('task')" href="#">
                <span class="action-item-label">{{ $t('task.title') }}</span><font-awesome-icon class="active-check" v-if="show.task" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-group :header="$t('units')">
              <b-dropdown-divider/>
              <li data-v-05ab69f6="" role="presentation" @[costMouseEnterEvent]="onCostOver" @mouseleave="onCostLeave" @click="onCostClick">
                <a role="menuitem" href="#" target="_self" class="dropdown-item">
                  <span class="action-item-label">{{ $t('button.cost') }}</span><font-awesome-icon class="menu-chevron" :icon="['far', 'chevron-right']"/>
                  <div ref="cost" class="sub-menu-dropdown">
                    <b-dropdown-item v-if="canView('TASK', ['fixedCost'])" @click="resourceAlloc = 'fixedcost'" href="#">
                      <span class="action-item-label">{{ $t('button.fixed_cost') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'fixedcost'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['fixedCostNet'])" @click="resourceAlloc = 'fixedcostnet'" href="#">
                      <span class="action-item-label">{{ $t('button.fixed_cost_net') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'fixedcostnet'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('STAFF', ['payAmount', 'payFrequency', 'payCurrency'])" @click="resourceAlloc = 'estcost'" href="#">
                      <span class="action-item-label">{{ $t('button.estcost') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'estcost'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('STAFF', ['payAmount', 'payFrequency', 'payCurrency']) && canView('REBATE')" @click="resourceAlloc = 'estcostnet'" href="#">
                      <span class="action-item-label">{{ $t('button.estcostnet') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'estcostnet'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['actualCost'])" @click="resourceAlloc = 'actualcost'" href="#">
                      <span class="action-item-label">{{ $t('button.actual_cost') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'actualcost'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['actualCostNet'])" @click="resourceAlloc = 'actualcostnet'" href="#">
                      <span class="action-item-label">{{ $t('button.actual_cost_net') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'actualcostnet'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                  </div>
                </a>
              </li>
              <li data-v-05ab69f6="" role="presentation" @[durationMouseEnterEvent]="onDurationOver" @mouseleave="onDurationLeave" @click="onDurationClick">
                <a role="menuitem" href="#" target="_self" class="dropdown-item">
                  <span class="action-item-label">{{ $t('button.duration') }}</span><font-awesome-icon class="menu-chevron" :icon="['far', 'chevron-right']"/>
                  <div ref="duration" class="sub-menu-dropdown">
                    <b-dropdown-item v-if="canView('TASK', ['fixedDuration'])" @click="resourceAlloc = 'fixeddurationhours'" href="#">
                      <span class="action-item-label">{{ $t('button.fixeddurationhours') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'fixeddurationhours'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['fixedDuration'])" @click="resourceAlloc = 'fixeddurationdays'" href="#">
                      <span class="action-item-label">{{ $t('button.fixeddurationdays') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'fixeddurationdays'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['estimatedDuration'])" @click="resourceAlloc = 'hours'" href="#">
                      <span class="action-item-label">{{ $t('button.estimateddurationhours') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'hours'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['estimatedDuration'])" @click="resourceAlloc = 'days'" href="#">
                      <span class="action-item-label">{{ $t('button.estimateddurationdays') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'days'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['actualDuration'])" @click="resourceAlloc = 'actualdurationhours'" href="#">
                      <span class="action-item-label">{{ $t('button.actualdurationhours') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'actualdurationhours'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['actualDuration'])" @click="resourceAlloc = 'actualdurationdays'" href="#">
                      <span class="action-item-label">{{ $t('button.actualdurationdays') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'actualdurationdays'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                  </div>
                </a>
              </li>
              <b-dropdown-item @click="resourceAlloc = 'percent'" href="#">
                <span class="action-item-label">{{ $t('button.percent') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'percent'" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="resourceAlloc = 'headcount'" href="#">
                <span class="min-width-units action-item-label">{{ $t('button.num_units') }}</span><font-awesome-icon class="active-check" v-if="resourceAlloc === 'headcount'" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-group :header="$t('filter')">
              <b-dropdown-item @click="onUnders" href="#">
                <span class="action-item-label">{{ $t('button.unders') }}</span><font-awesome-icon class="active-check" v-if="showUnders" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onOptimal" href="#">
                <span class="action-item-label">{{ $t('button.optimal') }}</span><font-awesome-icon class="active-check" v-if="showOptimal" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onOvers" href="#">
                <span class="action-item-label">{{ $t('button.overs') }}</span><font-awesome-icon class="active-check" v-if="showOvers" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </li>
        <li v-if="id !== null">
          <span class="d-flex">
            <span>
              <b-popover
                target="btn_usageproject"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="staffUsageProject ? $t('staff.staff_usage_project') : $t('staff.staff_usage_all_projects')">
              </b-popover>
              <b-btn id="btn_usageproject" @click="allProjects(null)" :pressed.sync="staffUsageProject" class="tool-button" ><font-awesome-icon :icon="['far', 'chart-network']"/></b-btn>
            </span>
          </span>
        </li>
        <li class="divider">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li>
          <span :id="`SPAN_COLLAPSE_${id}`">
              <b-popover
                :target="`SPAN_COLLAPSE_${id}`"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="$t('staff.button.minus')">
              </b-popover>
            <b-btn :disabled="expandLevel === 0" :id="`BTN_COLLAPSE_${id}`" @click="collapse"><font-awesome-icon :icon="['far', 'search-minus']"/></b-btn>
          </span>
        </li>
        <li>
          <span :id="`SPAN_EXPAND_${id}`">
              <b-popover
                :target="`SPAN_EXPAND_${id}`"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="$t('staff.button.plus')">
              </b-popover>
            <b-btn :disabled="expandLevel === maxLevel" :id="`BTN_EXPAND_${id}`" @click="expand"><font-awesome-icon :icon="['far', 'search-plus']"/></b-btn>
          </span>
        </li>
        <li>
          <div class="planner-locker">
            <span class="ml-1 mr-1">{{ $t('lock') }}</span>
            <b-form-checkbox switch data-vv-name="lockPlanner" v-model="lockPlanner" @change="lockChange"/>
          </div>
        </li>
      </PriorityNavigation>
      <div class="menu-toggler">
        <b-dropdown id="ddown-offset" class="settings-dropdown" offset="25" no-caret>
          <template slot="button-content">
            <div class="text">
              <font-awesome-icon :icon="['far', 'ellipsis-vertical']"/>
            </div>
          </template>
      
          <template>
            <b-dropdown-item @click="showSettings">{{ $t('button.settings') }}</b-dropdown-item>
          </template>
        </b-dropdown>
        <b-popover
          target="ddown-offset"
          placement="top"
          boundary="viewport"
          triggers="hover"
          :content="$t('dataview.more')">
        </b-popover>
      </div>
    </div>

    <SchedulerToolbar 
      :scheduler="scheduler"
      :selected="selected"
      :filter="show"
      :showDeleteConfirmation.sync="showDeleteConfirmation"
      resourceField="resourceUuid"
      @copyEvent="copyEvent"
      @pasteEvent="pasteEvent"
      @addEvent="addEvent"
      @editEvent="editEvent"
      @deleteEvent="deleteEvent"
      @import="fileImport"
      @exportToFile="fileExport"
      @schedulerToolbarCreated="schedulerToolbarCreated"
      :showAdd="canAdd('BOOKING') || canAdd('ACTIVITY') || canAdd('TASK') || canAdd('CALENDAR')"

      resourceMode
      :profileEntryNameTemplate="profileEntryNameTemplate"
      :id="id"
      :userId="userId"
      :coloring="coloring"
      :views="resourceViews"
      :filterText="filterText"
      :badgeFilters="badgeFilters"
      :badgeFilterFields="badgeFilterFields"
      :badgeFilterFieldValues="badgeFilterFieldValues"
      :searchPinned="searchPinned"
      :filterPinned="filterPinned"
      :showInfo="showInfo"
      @colorChange="onColorChange"
      @savePreset="savePreset"
      @loadViewSettings="loadViewSettings"
      @copyColumnSettings="copyColumnSettings"
      @shareColumnSettings="shareColumnSettings"
      @updateColumnSettings="updateColumnSettings"
      @removeColumnSettings="removeColumnSettings"
      @infoOver="onInfoOver"
      @infoLeave="onInfoLeave"
      @badgeFilterFetchOptions="onBadgeFilterFetchOptions"
      @badgeFilterModified="onBadgeFilterModified"
      @filterTextInput="onFilterTextInput"
      @filterSubmit="onFilterSubmit"
      @filterClear="onFilterClear"
      @pinSearch="onPinSearch"
      @unPinSearch="onUnPinSearch"
      @pinFilter="onPinFilter"
      @unPinFilter="onUnPinFilter"
    />

    <div v-if="showLoadingOverlay" class="overlay" :style="isWidget ? `height: ${height}px` : `height: calc(100vh - ${heightOffset}px)`">
      <span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>{{ $t('resource.grid.planner_loading') }}</span>
    </div>
    <div v-else>

      <div class="splitter-container" ref="splitter-container" :style="splitterStyle">
        <div class="lhs-grid" ref="lhs-grid">
            <ag-grid-vue v-if="reload" :style="lhsGridStyle" class="ag-theme-balham resourceplanner-grid-height" id="pt-grid"
              :gridOptions="gridOptions"
              @grid-ready="onGridReady"
              :columnDefs="columnDefs"
              :autoGroupColumnDef="autoGroupColumnDef"
              :context="context"
              :defaultColDef="defaultColDef"
              noRowsOverlayComponent="noRowsOverlay"
              :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
              :overlayLoadingTemplate="overlayLoadingTemplate"
              
              treeData
              :getDataPath="data => data.path"
              :getRowId="params => params.data.key"   
              :rowMultiSelectWithClick="false"
              rowSelection="multiple"
              :serverSideInfiniteScroll="false"
              :headerHeight="40"
              :sideBar="false"
              :processCellForClipboard="processCellForClipboard"
              alwaysShowHorizontalScroll
              suppressCopyRowsToClipboard
              suppressDragLeaveHidesColumns
              suppressContextMenu
              :suppressMultiSort="false"
              :singleClickEdit="false"
              rowModelType="clientSide"
              suppressGroupRowsSticky
              >
            </ag-grid-vue>
            <!-- :cacheBlockSize="10000" -->
        </div>
        

        <div class="resizer" ref="resizer" id="grid-gantt-resizer" @mousedown="mouseDownHandler">
          <div class="resizer-overlay" ref="resizer-overlay" id="resizer-overlay">
            <font-awesome-icon class="resizer-icon" :icon="['far', 'arrows-left-right']"/>
          </div>
        </div>

        <!-- Scheduler -->
        <div class="rhs-chart" style="overflow: hidden;" ref="rhs-chart">
          <Scheduler 
            :collapseId="collapseId"
            :coloring="coloring"
            :customFields="customFields"
            :companyCustomFields="companyCustomFields"
            :locationCustomFields="locationCustomFields"
            :departmentCustomFields="departmentCustomFields"
            :skillCustomFields="skillCustomFields"
            :data="schedulerTasks" 
            :deleteEventId="deleteEventId"
            :disableCreate="!canAdd('BOOKING') && !canAdd('ACTIVITY') && !canAdd('TASK') && !canAdd('CALENDAR')"
            :endDate="schedulerEndDate" 
            :expandId="expandId"
            :expandLevel="expandLevel"
            :exportToFile="exportToFile"
            :fields="fields"
            :companyFields="companyFields"
            :locationFields="locationFields"
            :departmentFields="departmentFields"
            :skillFields="skillFields"
            :filter="show"
            :filterObject="badgeFilters"
            :grouping="grouping"
            :height="isWidget ? `${height}px` : schedulerHeight > -1? `${schedulerHeight}px` : `calc(100vh - ${heightOffset}px)`"
            :noRowsMessage="$t('resource.grid.no_data')"
            :permission="permissionName"
            :projectId="staffUsageProject ? id : '000000000000-0000-0000-0000-00000000'"
            :redraw="redrawScheduler"
            resourceField="resourceUuid"
            :showAdd="canAdd('BOOKING') || canAdd('ACTIVITY') || canAdd('TASK')"
            :showNoRowsOverlay="showNoRowsOverlay"
            :showOvers="showOvers"
            :showOptimal="showOptimal"
            :showUnders="showUnders"
            :skipProcessNodes.sync="skipProcessNodes"
            :holdUpdateUntilTreeDataChanged.sync="holdUpdateUntilTreeDataChanged"
            :span="schedulerSpan" 
            :staffAlloc="resourceAlloc" 
            :startDate="schedulerStartDate" 
            :style="{ height: isWidget ? `${height}px` : `calc(100vh - ${heightOffset}px)` }"
            :width="schedulerWidth"
            :lockPlanner="lockPlanner"
            
            @addEvent="addEvent"
            @clickItem="schedulerClickItem"
            @collapsedId="onCollapsedId"
            @copyEvent="copyEvent"
            @dateChange="dateChange"
            @deleteEvent="showDeleteConfirmation=true"
            @editEvent="editEvent"
            @expandedId="onExpandedId"
            @exportEnd="exportEnd"
            @exportStart="exportStart"
            @folderToggle="folderToggle"
            @pasteEvent="pasteEvent"
            @redrawn="schedulerRedrawn"
            @schedulerCreated="schedulerCreated"
            @schedulerDataRender="schedulerDataRender"
            @schedulerScroll="ganttScrollHandler"
            @selectionChanged="onSelectionChanged"
            @showtasks="onCellClicked"
            @gotoday="gotoDaily"
            @updated="eventUpdated">
          </Scheduler>
        </div>
      </div>
    </div>
      
    <ActivityModal :readOnly="activityEdit.readonly" :show.sync="activityEditShow" mode="resource" @success="modalSuccess" :id.sync="activityEdit.uuId" />
    <BookingModal :readOnly="bookingEdit.readonly" :show.sync="bookingEditShow" mode="resource" @success="modalSuccess" :id.sync="bookingEdit.uuId" />
    <TaskModal :readOnly="taskEdit.readonly" :show.sync="taskEditShow" @success="modalSuccess" :id.sync="taskEdit.uuId" 
        :projectId="taskEdit.projectUuid" />
    <SkillModal v-if="skillShow" :id="skillId" :show.sync="skillShow" @success="modalSuccess" :title="$t('skill.title_detail')"/>
    <LocationModal :id="locationId" :show.sync="locationShow" @success="modalSuccess" :title="$t('location.title_detail')"/>
    <DepartmentModal v-if="allowManage && departmentShow" :id="departmentId" :show.sync="departmentShow" @success="modalSuccess" :title="$t('department.title_detail')" :queryParent="true"/>
    <CompanyModal v-if="allowManage && companyShow" :id="companyId" :show.sync="companyShow" @success="modalSuccess" :title="$t('company.title_detail')"/>
    <StaffModal v-if="staffShow" :id="staffId" :show.sync="staffShow" @success="modalSuccess" :title="$t('staff.title_detail')" :isGeneric="isGeneric"/>
    <ResourceModal v-if="resourceEditShow" :id="resourceId" :show.sync="resourceEditShow" @success="modalSuccess" :title="$t('resource.title_detail')"/>
    <ResourceUnitModal :show.sync="resourceUnitEditShow" :showDelete="true" :uuId="resourceUnitEdit.uuId" :name="resourceUnitEdit.name" :unit="resourceUnitEdit.unit" :utilization="parseFloat(resourceUnitEdit.utilization)" @ok="resourceUnitOk" @remove="resourceUnitRemove"/>
    <SaveViewModal :show.sync="promptSaveShow" :name="saveName" :title="$t('task.confirmation.save')" :profile="saveProfile" @ok="confirmSaveOk"/>
    <SaveViewModal :show.sync="promptShareShow" :name="saveName" :title="$t('task.confirmation.share')" :sharing="true" :profile="saveProfile" @ok="confirmSaveOk"/> 
    <TaskSelectorModal :title="$t('staff.select_task_title')"
        :show.sync="taskSelectShow"
        :data="taskSelectData"
        :ok-title="$t('button.select')"
        :ok-disabled="taskEdit.uuId === null"
        @ok="taskSelectOk"
        />
   <!-- <ProjectModal v-if="projectShow" :id="projectEditId" :show.sync="projectShow" @success="modalSuccess" :title="$t('project.title_detail')"/> -->
   
   
   <PlannerSettingsModal :show.sync="settingsShow" plannerType="resource" :settings="show" :showMetrics="false" :showNonWork="false" :title="$t('staff.planner_settings.title')" @success="plannerSettingsSuccess"/>
   
   <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable" @cancel="progressCancel"/>
      
    <TaskDateTimeDurationCalculation :show.sync="durationCalculationShow" 
      :defaultActionForNonWorkPrompt="durationCalculation.defaultActionForNonWorkPrompt"
      :trigger="durationCalculation.trigger"
      :startDateStr="durationCalculation.startDateStr"
      :startTimeStr="durationCalculation.startTimeStr"
      :closeDateStr="durationCalculation.closeDateStr"
      :closeTimeStr="durationCalculation.closeTimeStr"
      :durationDisplay="durationCalculation.durationDisplay"
      :calendar.sync="durationCalculation.calendar"
      :projectScheduleFromStart="durationCalculation.projScheduleFromStart"
      :bookingAutoScheduleMode="durationCalculation.bookingAutoScheduleMode"
      :constraintType="durationCalculation.constraintType"
      :constraintDateStr="durationCalculation.constraintDateStr"
      :lockDuration="durationCalculation.lockDuration"
      :oldDateStr="durationCalculation.oldDateStr"
      :oldTimeStr="durationCalculation.oldTimeStr"
      :oldConstraintType="durationCalculation.oldConstraintType"
      :oldConstraintDateStr="durationCalculation.oldConstraintDateStr"
      :skipOutOfProjectDateCheck="durationCalculation.skipOutOfProjectDateCheck"
      :resizeMode="durationCalculation.resizeMode"
      :durationConversionOpts="durationConversionOpts"
      @success="durationCalculationOk"
      @cancel="durationCalculationCancel"
      @skip="durationCalculationCancel"
    />
    
   <b-modal :title="$t('activity.confirmation.show')"
        v-model="promptShowActivity"
        @ok="showActivityOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('activity.confirmation.show_events') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
    
   <b-modal :title="$t('booking.confirmation.show')"
        v-model="promptShowBooking"
        @ok="showBookingOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('booking.confirmation.show_events') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
    
   <b-modal :title="$t('task.confirmation.show')"
        v-model="promptShowTask"
        @ok="showTaskOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.show_events') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
    
   <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteViewShow"
        @ok="confirmDeleteViewOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.delete_view') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
   
   <EventTypeModal :title="$t('staff.event_type')"
        :show.sync="promptType"
        :event="newEvent"
        :profileValues="eventTypeValues"
        mode="resource"
        @ok="onPromptTypeOk"
        @cancel="onPromptTypeCancel"
        >
    </EventTypeModal>
    
    <SelectImportType :title="$t('staff.select_import')"
        @ok="onSelectImportTypeOk"
        :show.sync="selectImportShow"
        :hideNonWork="true">
    </SelectImportType>
    
    <!--Gantt Import Dialog -->
    <GanttImportDialog :properties="importProperties" :mode="importType" :show="docImportShow"
      @modal-ok="docImportOk"
      @modal-cancel="docImportCancel" />
  </div>
  
</template>

<script>
import Vue from 'vue';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { 
  formatDate, 
  getNextWorkingDay, 
  invertColor, 
  filterCheck,
  strRandom,
  convertDate,
  processCalendar,
  transformCalendar,
  getUsage,
  costFormat,
  costFormatAdv,
  msToTime
} from '@/helpers';
import { buildCompanyFilter, getSelectedCompany } from '@/helpers/company';
import { cloneDeep, debounce } from 'lodash';
import { 
  activityLinkStageService,
  bookingLinkStageService,
  taskLinkStageService,
  calendarService,
  resourceService, 
  layoutProfileService, 
  viewProfileService, 
  companyService, 
  activityService, 
  activityLinkResourceService, 
  bookingService, 
  staffLinkResourceService,
  taskLinkResourceService,
  taskService,
  tagService,
  compositeService,
  profileService,
  locationService,
  skillService,
  departmentService
} from '@/services';

import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation';
import Scheduler from '@/components/Scheduler/Scheduler';
import SchedulerToolbar from '@/components/Scheduler/SchedulerToolbar';
import { DEFAULT_CALENDAR, TRIGGERS
  , convertDisplayToDuration, convertDurationToDisplay, isWorkingDay, calcDateTimeDurationv2
  , extractDurationConversionOpts
} from '@/helpers/task-duration-process';
import { TaskTemplateDataUtil } from '@/components/Task/script/task.template.util';

import { getCustomFieldInfo
  , prepareCustomFieldColumnDef
} from '@/helpers/custom-fields';
import { filterOutViewDenyProperties, columnDefSortFunc } from '@/views/management/script/common'
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import DateOnlyCellRenderer from '@/components/Aggrid/CellRenderer/DateOnly';
import GenericEntityArrayCellRenderer from '@/components/Aggrid/CellRenderer/GenericEntityArray';
import GenericCellRenderer from '@/components/Aggrid/CellRenderer/Generic';
import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';
import StringEditor from '@/components/Aggrid/CellEditor/String';
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost';
import EnumCellRenderer from '@/components/Aggrid/CellRenderer/Enum';
import BooleanCellRenderer from '@/components/Aggrid/CellRenderer/Boolean';
import WorkingHoursCellRenderer from '@/components/Aggrid/CellRenderer/WorkingHours';
import Multiselect from 'vue-multiselect';
import currencies from '@/views/management/script/currencies';

const UUID_CHECK = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
let resourceData = [];
let companies = {};
let locations = {};
let departments = {};
let departmentUuIds = {};
let skills = {};
let activities = {};
let staffs = {};
let bookings = {};
let resourceEvents = {};
let tasks = {};
let staffData = {};
let resourceSections = {};
let entityList = {};
let resources = [];

const getColumnDefs = (c) => {
  return {
    colId: c.colId
    , width: c.actualWidth
    , sort: c.sort != null? c.sort : null
    , sortIndex: c.sortIndex != null? c.sortIndex : null
  }
}

function addEmptyGroup(self, name, staff) {
  if (!staff[`${name}List`] || staff[`${name}List`].length === 0) {
    staff[`${name}List`] = [{
      name: self.$t(`staff.no_${name}`),
      uuId: self.$t(`staff.no_${name}`)
    }];
    staff[`${name}`] = self.$t(`staff.no_${name}`);
    staff[`${name}UuId`] = self.$t(`staff.no_${name}`);
  }
  return staff;
}

function addEmptyGroups(self, staff) {
  addEmptyGroup(self, 'company', staff);
  addEmptyGroup(self, 'location', staff);
  addEmptyGroup(self, 'department', staff);
  addEmptyGroup(self, 'skill', staff);
  return staff;
}

function calcDuration(start_date, end_date, calendar, durationConversionOpts={}) {
  let trigger = TRIGGERS.START_DATE;
  let projectScheduleFromStart = true;
  
  const startTime = start_date;
  const closeTime = end_date;
  const payload = {
    trigger: trigger
    , startDateStr: startTime.format('YYYY-MM-DD')
    , startTimeStr: startTime.format('HH:mm')
    , closeDateStr: closeTime.format('YYYY-MM-DD')
    , closeTimeStr: closeTime.format('HH:mm')
    , durationDisplay: null
    , calendar: calendar
    , projScheduleFromStart: projectScheduleFromStart
    , taskAutoScheduleMode: false
    , lockDuration: false
    , autoMoveForNonWorkingDay: false
    , skipOutOfProjectDateCheck: true
    , durationConversionOpts
  }
  const result = calcDateTimeDurationv2(payload);
  let dDisplay = convertDisplayToDuration(result.durationDisplay, durationConversionOpts).value; //Note: unit of resultDisplay is always 'D'.
  
  return dDisplay;
}

function httpAjaxError(e, self) {
  const response = e.response;
  self.alertError = true;
  if (response && 403 === response.status) {
    self.alertMsg = self.$t('error.authorize_action');
  } else if (response.data.jobCase) {
    const feedback = response.data[response.data.jobCase][0];
    if(feedback.spot) {
      self.alertMsg = self.$t(`error.${feedback.clue}`, feedback.args);     
    } 
  }
  else {
    self.alertMsg = self.$t('error.internal_server');     
  }
}

function hasStaff(staff, properties, property, uuId) {
  if (!staff) {
    return false;
  }
  
  if (property === 'skill') {
    const resStaff = staff.filter(s => s.companyList.findIndex(c => c.uuId === properties.companyUuId) !== -1 &&
                                       s.locationList.findIndex(l => l.uuId === properties.locationUuId) !== -1 &&
                                       s.departmentList && s.departmentList.findIndex(r => r.uuId === properties.departmentUuId) !== -1 &&
                                       s.skillList && s.skillList.findIndex(s => s.uuId === uuId) !== -1);
    return resStaff.length !== 0;
  }
  else if (property === 'department') {
    const resStaff = staff.filter(s => s.companyList.findIndex(c => c.uuId === properties.companyUuId) !== -1 &&
                                       s.locationList.findIndex(l => l.uuId === properties.locationUuId) !== -1 &&
                                       s.departmentList && s.departmentList.findIndex(r => r.uuId === uuId && r.companyUuid === properties.companyUuId) !== -1);
    return resStaff.length !== 0;
  }
  return true;
}

function buildTreeProperty(data, obj, property, properties, subproperties, parentKey, entityList, staff) {
  if (typeof data[`${property}List`] !== 'undefined') {
    for (let i = 0; i < data[`${property}List`].length; i++) {
      const uuId = data[`${property}List`][i].uuId;
      const name = entityList[uuId] ? entityList[uuId].name : uuId;
      const key = `${parentKey}${uuId}`;
      if (!(key in obj) && (property === 'location' || hasStaff(staff, properties, property, uuId))) {
        obj[key] = { 
          name: name,
          type: `${property}`,
          ...properties
        };
        obj[key][`${property}UuId`] = uuId;
      }
      
      // process the next property
      if (subproperties.length !== 0) {
        const subp = {
          ...properties
        };
        subp[`${property}UuId`] = uuId;
        subp[`${property}Name`] = name;
        buildTreeProperty(data, subproperties[0].obj, subproperties[0].name, subp, subproperties.slice(1), key, entityList, staff);
      }
    }
  }
  else if (subproperties.length !== 0) {
    // skip it and go to the next property
    buildTreeProperty(data, subproperties[0].obj, subproperties[0].name, properties, subproperties.slice(1), '', entityList, staff);
  }
}

function buildTreeData(data, staff, entityList) {
  const properties = [
    { name: 'location', obj: locations },
    { name: 'department', obj: departments },
    { name: 'skill', obj: skills }
  ];
  
  // add companies
  buildTreeProperty(data, companies, 'company', {}, properties, '', entityList, staff);  
}

function getPerhourCost(cost, span, self) {
  if (span === 'Daily') {
    return cost / this.durationConversionOpts.hourPerDay;
  }
  else if (span === 'Weekly') {
    return cost / self.durationConversionOpts.hourPerWeek;
  }
  else if (span === 'Monthly') {
    return cost / (self.durationConversionOpts.dayPerMonth / self.durationConversionOpts.hourPerDay);
  }
  else if (span === 'Annually') {
    return cost / (self.durationConversionOpts.dayPerYear / self.durationConversionOpts.hourPerDay);
  }
  return cost;
}

async function loadData(self) {
  resourceEvents = {};
  resourceData = [];
  companies = {};
  locations = {};
  departments = {};
  skills = {};
  activities = {};
  staffs = {};
  bookings = {};
  resourceEvents = {};
  tasks = {};
  resourceSections = {};
  entityList = {};
  resources = [];

  self.resourceDataLoaded = {};
  self.companyDataLoaded = {};
  self.locationDataLoaded = {};
  self.departmentDataLoaded = {};
  self.skillDataLoaded = {};
  self.resourceFieldDataLoaded = [];
  
  const tagMap = self.canView('TAG') ? await tagService.list_with_filters({ start: 0, limit: -1 }).then(tagResponse => {
    const data = tagResponse.data;
    return Object.assign({}, ...data.map(s => ({[s.uuId]: s}))); // create a map from the entries
  }) : {};
  
  let companyFilter = null;
  // set the dates in the scheduler
  self.schedulerStartDate = self.startDate;
  self.schedulerEndDate = self.endDate;
  
  if (self.$store.state.company && self.$store.state.company.type !== 'Primary') {
    if (self.$store.state.company.filterIds) {
      companyFilter = self.$store.state.company.filterIds;
    }
    else {
      let uuId = self.$store.state.company.uuId;
      if (uuId === null) {
        uuId = await getSelectedCompany(self.$store.state.authentication.user.uuId);
      }
      
      // the filter is not ready yet, create it
      companyFilter = await companyService.tree(false, self.$store.state.company.uuId).then((response) => {
        return buildCompanyFilter(self.$store.state.company.uuId, response.data[response.data.jobCase]);
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
        return null;
      });
    }
  }
  
  self.resourcePay = await resourceService.plannerList({ start: 0, limit: -1 }).then((response) => {
    return response.data.reduce(function(acc, cur) {
      acc[cur.uuId] = cur;
      return acc;
    }, {});
  });
  
  let hasError = false;
  const resourceUsageResponse = await resourceService.usage({ 
      start: 0, 
      limit: -1, 
      begin: self.startDate, 
      until: self.endDate
  }, false, null)
  .then(response => {
    return response;
  })
  .catch(e => {
    hasError = true;
    console.error(e); //eslint-disable-line no-console
    httpAjaxError(e, self);
  });

  if (hasError) {
    self.loading = false;
    self.showLoadingOverlay = false;
    self.inProgressShow = false;
    return;
  }

  const data = resourceUsageResponse.data[resourceUsageResponse.data.jobCase];
  entityList = resourceUsageResponse.data['entityList'];
  
  for (const resource of data) {
    resourceSections[resource.uuId] = resource;
    resource.type = 'resource';
    resource.departmentList = [];
    resource.companyList = [];
    resource.companyList = [];
    resource.skillList = [];
    resource.calendars = [ self.baseCalendar ];
    
    // setup the pay data
    if (resource.uuId in self.resourcePay) {
      const cost = self.resourcePay[resource.uuId].payAmount;
      const rate = self.resourcePay[resource.uuId].payFrequency;
      const currency = self.resourcePay[resource.uuId].payCurrency ? self.resourcePay[resource.uuId].payCurrency : 'USD';
      
      resource.perhourCost = getPerhourCost(cost, rate, self);
      resource.currency = currency;
    }
    
    if (resource.tagList &&
        resource.tagList.length > 0) {
      for (const tag of resource.tagList) {
        tag.name = tagMap[tag.uuId].name;
        resource.tags = resource.tags ? `${resource.tags}, ${tagMap[tag.uuId].name}` : tagMap[tag.uuId].name;
      }
    }
    
    if (resource.staffList) {
      for (const staff of resource.staffList) {
        const staffEntity = cloneDeep(entityList[staff.uuId]);
        if (companyFilter === null ||
          (staffEntity.companyList.length > 0 && companyFilter.includes(staffEntity.companyList[0].uuId))) {
          staffEntity.label = staffEntity.name;
          staffEntity.uuId = staff.uuId;
          staffEntity.perhourCost = resource.perhourCost;
          staffEntity.currency = resource.currency;
          staffEntity.utilization = staff.utilization;
          staffEntity.quantity = staff.quantity || staff.quanity;
          staffEntity.staffType = staffEntity.type;
          staffEntity.company = staffEntity.companyList.length > 0 && entityList[staffEntity.companyList[0].uuId] ? entityList[staffEntity.companyList[0].uuId].name : null;
          staffEntity.location = staffEntity.locationList.length > 0 && entityList[staffEntity.locationList[0].uuId] ? entityList[staffEntity.locationList[0].uuId].name : null;
          staffEntity.department = staffEntity.departmentList && staffEntity.departmentList.length > 0 && entityList[staffEntity.departmentList[0].uuId] ? entityList[staffEntity.departmentList[0].uuId].name : null;
          if (staffEntity.departmentList) {
            // save the names in the skillList
            for (const dept of staffEntity.departmentList) {
              dept.name = entityList[dept.uuId].name;
            }
          }  
          if (staffEntity.skillList) {
            // save the names in the skillList
            for (const skill of staffEntity.skillList) {
              skill.name = entityList[skill.uuId].name;
            }
          }  
          staffEntity.calendars = [ staffEntity.calendarList, self.baseCalendar ];
          staffEntity.type = 'staff';
          staffEntity.resourceUuid = resource.uuId;
          staffEntity.resourceName = resource.name;
          
          if (staffEntity.companyList) {
            for (const company of staffEntity.companyList) {
              if (resource.companyList.findIndex(c => c.uuId === company.uuId) === -1) {
                resource.companyList.push(company);
                if (!resource.companyUuId) {
                  resource.companyUuId = company.uuId;
                  resource.company = company.name;
                }
              }
            }
          }
          
          if (staffEntity.locationList) {           
            for (const location of staffEntity.locationList) {
              if (resource.locationList.findIndex(c => c.uuId === location.uuId) === -1) {
                resource.locationList.push(location);
              }
              if (!resource.locationUuId) {
                resource.locationUuId = resource.locationList[0].uuId;
                resource.location = resource.locationList[0].name;
              }
            }
          }
          
          if (staffEntity.departmentList) {  
            for (const department of staffEntity.departmentList) {
              if (resource.departmentList.findIndex(c => c.uuId === department.uuId) === -1) {
                department.companyUuid = staffEntity.companyList.length > 0 && staffEntity.companyList[0].uuId ? staffEntity.companyList[0].uuId : null;
                resource.departmentList.push(department);
              }
              if (!resource.departmentUuId) {
                resource.departmentUuId = department.uuId;
                resource.department = department.name;
              }
            }
          }
          
          if (staffEntity.skillList) {   
            for (const skill of staffEntity.skillList) {
              if (resource.skillList.findIndex(c => c.uuId === skill.uuId) === -1) {
                resource.skillList.push(skill);
              }
              if (!resource.skillUuId) {
                resource.skillUuId = skill.uuId;
                resource.skill = skill.name;
              }
            }
          }
                    
          if (!(staff.uuId in staffData)) {
            staffData[staff.uuId] = staffEntity;
          }
          
          if (resource.uuId in staffs) {
            staffs[resource.uuId].push(staffEntity);
          }
          else {
            staffs[resource.uuId] = [staffEntity];
          }
        }
      }
    }
    
    //resource.company = resource.companyList && resource.companyList.length > 0 ? entityList[resource.companyList[0].uuId].name : null;
    //resource.companyUuId = resource.companyList && resource.companyList.length > 0 ? resource.companyList[0].uuId : null;
    resource.location = resource.locationList && resource.locationList.length > 0 ? entityList[resource.locationList[0].uuId].name : null;
    resource.locationUuId = resource.locationList && resource.locationList.length > 0 ? resource.locationList[0].uuId : null;
    //resource.department = resource.departmentList && resource.departmentList.length > 0 ? entityList[resource.departmentList[0].uuId].name : null;
    //resource.departmentUuId = resource.departmentList && resource.departmentList.length > 0 ? resource.departmentList[0].uuId : null;
    //resource.skill = resource.skillList && resource.skillList.length > 0 ? entityList[resource.skillList[0].uuId].name : null;
    //resource.skillUuId = resource.skillList && resource.skillList.length > 0 ? resource.skillList[0].uuId : null;
    
    // save the names in the skillList
    /*for (const skill of resource.skillList) {
      skill.name = entityList[skill.uuId] ? entityList[skill.uuId].name : '';
    }*/
    
    addEmptyGroups(self, resource);
    buildTreeData(resource, resource.uuId in staffs ? staffs[resource.uuId] : [], entityList);
    
    if (resource.taskList) {
      for (const refTask of resource.taskList) {
        const task = entityList[refTask.uuId];
        if (task.begin &&
            task.until) {
          task.uuId = refTask.uuId;
          refTask.name = task.name;
          refTask.project = task.project;
          refTask.begin = task.begin;
          refTask.until = task.until;
          refTask.te = task.duration;
          refTask.tp = task.progress;
          refTask.path = task.path;
          refTask.fixedCost = task.fixedCost;
          refTask.actualCost = task.actualCost;
          refTask.fixedDuration = task.fixedDuration;
          refTask.actualDuration = task.actualDuration;
          refTask.pn = entityList[task.project].name;
          refTask.projectColor = entityList[task.project].color;
          refTask.type = 'task';
          refTask.rebate = task.rebate;
          task.projectColor = entityList[task.project].color;
          let stageColor = null;
          if (task.stage) {
            stageColor = entityList[task.stage].color;
            task.stageName = entityList[task.stage].name;
            refTask.stageName = task.stageName;
          }
          
          // if the task is not yet added
          if (!tasks[task.uuId]) {
            task.resourceUuid = [resource.uuId];
            tasks[task.uuId] = task;
          }
          else {
            // add the resource
            tasks[task.uuId].resourceUuid.push(resource.uuId);
          }
          
          task.pn = entityList[task.project].name;
          
          const start = moment(task.begin).format('YYYY-MM-DD H:mm');
          const end = moment(task.until).format('YYYY-MM-DD H:mm');
          let event = { 
            type: 'task',
            id: task.uuId,
            eventColor: task.color,
            resourceColor: resource.color,
            projectColor: task.projectColor,
            stageColor: stageColor,
            lockDuration: task.lockDuration,
            te: task.duration / 60000 / 60, 
            tp: task.progress,
            asch: typeof task.asch === 'undefined' ? true : false,
            start_date: start, 
            end_date: end, 
            pu: task.project,
            pn: entityList[task.project].name,
            name: task.name,
            stageName: task.stageName ? task.stageName : null,
            stage: task.stage ? task.stage : null,
            text: task.path.replace(/\n/g, ' / '), 
            resourceUuid: resource.uuId,
            readonly: !self.canEdit('TASK', ['startTime', 'closeTime', 'duration']) || task.RO
          };
          
          if (event.resourceUuid in resourceEvents) {
            resourceEvents[resource.uuId].push(event);
          }
          else {
            resourceEvents[resource.uuId] = [event];
          }
        
          if (self.id && self.id === task.project &&
              !resources.includes(resource.uuId)) {
            resources.push(resource.uuId);
          }
        }
      }
    }
    
    if (resource.activityList) {
      for (const refActivity of resource.activityList) {
        refActivity.type = 'activity';
        const activity = entityList[refActivity.uuId];
        refActivity.begin = activity.begin;
        refActivity.until = activity.until;
        refActivity.name = activity.name;
        refActivity.rebate = activity.rebate;
        refActivity.fixedCost = activity.fixedCost;
        refActivity.actualCost = activity.actualCost;
        refActivity.fixedDuration = activity.fixedDuration;
        refActivity.actualDuration = activity.actualDuration;
        refActivity.duration = activity.duration;
        activity.uuId = refActivity.uuId;
        // let stageColor = null;
        if (activity.stage) {
          // stageColor = entityList[activity.stage].color;
          activity.stageName = entityList[activity.stage].name;
        }
        
        activities[activity.uuId] = activity;
        if (activity.begin &&
            activity.until) {
          const start = moment(activity.begin).format('YYYY-MM-DD H:mm');
          const end = moment(activity.until).format('YYYY-MM-DD H:mm');
          const event = { 
            type: 'activity',
            id: activity.uuId,
            eventColor: activity.color,
            resourceColor: resource.color,
            te: activity.duration / 60000 / 60, 
            tp: activity.progress,
            start_date: start, 
            end_date: end, 
            pu: activity.projectRef,
            name: activity.name,
            stageName: activity.stageName ? activity.stageName : null,
            stage: activity.stage ? activity.stage : null,
            text: activity.name, 
            //section_id: activity.resourceUuid, 
            resourceUuid: resource.uuId,
            readonly: !self.canEdit('ACTIVITY', ['startTime', 'closeTime', 'duration']) || activity.RO
          };
          
          if (event.resourceUuid in resourceEvents) {
            resourceEvents[resource.uuId].push(event);
          }
          else {
            resourceEvents[resource.uuId] = [event];
          }
        }
      }
    }
    
    if (resource.bookingList) {  
      for (const booking of resource.bookingList) {
        booking.type = 'booking';
        bookings[booking.uuId] = booking;
        booking.pn = entityList[booking.bookedList.filter(b => b.label === 'PROJECT')[0].uuId].name;
        booking.project = booking.bookedList.filter(b => b.label === 'PROJECT')[0].uuId;
        booking.projectColor = entityList[booking.bookedList.filter(b => b.label === 'PROJECT')[0].uuId].color;
        let stageColor = null;
        if (booking.stage) {
          stageColor = entityList[booking.stage].color;
          booking.stageName = entityList[booking.stage].name;
        }
        
        if (booking.begin &&
            booking.until) {
          const start = moment(booking.begin).format('YYYY-MM-DD H:mm');
          const end = moment(booking.until).format('YYYY-MM-DD H:mm');
          const event = { 
            type: 'booking',
            id: booking.uuId,
            eventColor: booking.color,
            projectColor: booking.projectColor,
            stageColor: stageColor,
            resourceColor: resource.color,
            te: booking.duration / 60000 / 60, 
            start_date: start, 
            end_date: end, 
            project: booking.bookedList.filter(b => b.label === 'PROJECT')[0].uuId,
            pn: entityList[booking.bookedList.filter(b => b.label === 'PROJECT')[0].uuId].name,
            projectName: booking.name,
            text: booking.name, 
            stageName: booking.stageName ? booking.stageName : null,
            stage: booking.stage ? booking.stage : null,
            //section_id: booking.resourceUuid, 
            resourceUuid: resource.uuId,
            readonly: !self.canEdit('BOOKING', ['beginDate', 'untilDate', 'duration']) || booking.RO
          };
          
          if (event.resourceUuid in resourceEvents) {
            resourceEvents[resource.uuId].push(event);
          }
          else {
            resourceEvents[resource.uuId] = [event];
          }
        }
      
        if (self.id && self.id === booking.project &&
            !resources.includes(resource.uuId)) {
          resources.push(resource.uuId);
        }
      }
    }
  }
  
  resourceData = data;
  
  //Get additional data for sorted column.
  if (Array.isArray(resourceData) && resourceData.length > 0) {
    //Get only the chosen customFields in badgeFilters. Other chosen badgeFilters do not need data fetching.
    const allowedList = self.customFields.filter(i => i.type == 'String' || i.type == 'Enum<String>').map(i => i.name);
    const payloadFields = self.badgeFilters.filter(i => allowedList.includes(i.field)).map(i => i.field);
    let found = null;
    if (self.layoutProfile[self.getProfileEntryName('list')] != null) {
      found = self.layoutProfile[self.getProfileEntryName('list')].find(i => i.colId != self.COLUMN_AGGRID_AUTOCOLUMN && i.sort != null);
      if (found) {
        payloadFields.push(found);
      }
    }
    if (payloadFields.length > 0) {
      await self.fetchDataForSortedColumn(resourceData, payloadFields.map(c => {return c.colId}));
      if (found) {
        self.resourceFieldDataLoaded.push(found.colId);
      }
    }
  }
  
  self.assignData();
  self.datesChanging = false;

  // self.loading = false;
  self.showLoadingOverlay = false;
  // self.inProgressShow = false;
}

async function ServerSideDatasource(self) {
  if (self.startDate === null) {
    const b = this.getToday();
    b.setMonth(b.getMonth() - 3);
    self.startDate = formatDate(b);
  }
  
  if (self.endDate === null) {
    const u = this.getToday();
    u.setMonth(u.getMonth() + 3);
    self.endDate = formatDate(u);
  }
  
  if (self.id !== null) {
    
    self.resource = [];
  }
  loadData(self);

}

export default {
  name: 'PlannerResource',
  components: {
    'ag-grid-vue': AgGridVue,
    ActivityModal: () => import('@/components/modal/ActivityModal'),
    BookingModal: () => import('@/components/modal/BookingModal'),
    TaskModal: () => import('@/components/modal/TaskModal'),
    StaffModal: () => import('@/components/modal/StaffModal'),
    AlertFeedback: () => import('@/components/AlertFeedback'),
    ResourceModal: () => import('@/components/modal/ResourceModal'),
    ResourceUnitModal: () => import('@/components/modal/ResourceUnitModal'),
    SaveViewModal: () => import('@/components/modal/SaveViewModal.vue'),
    DepartmentModal: () => import('@/components/modal/DepartmentModal.vue'),
    CompanyModal: () => import('@/components/modal/CompanyModal.vue'),
    LocationModal: () => import('@/components/modal/LocationModal.vue'),
    SkillModal: () => import('@/components/modal/SkillModal.vue'),
    TaskSelectorModal: () => import('@/components/modal/TaskSelectorModal.vue'),
    TaskDateTimeDurationCalculation: () => import('@/components/Task/TaskDateTimeDurationCalculation'),
    // TaskViewBadgeFilter: () => import('@/components/Filter/TaskViewBadgeFilter.vue'),
    Scheduler,
    SchedulerToolbar,
    EventTypeModal: () => import('@/components/modal/EventTypeModal.vue'),
    PriorityNavigation,
    InProgressModal: () => import('@/components/modal/InProgressModal'),
    PlannerSettingsModal: () => import('@/components/modal/PlannerSettingsModal'),
    GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog'),
    SelectImportType: () => import('@/components/modal/SelectImportType'),
    Multiselect
    
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    , detailLinkCellRenderer: DetailLinkCellRenderer
    , dateOnlyCellRenderer: DateOnlyCellRenderer
    , genericEntityArrayCellRenderer: GenericEntityArrayCellRenderer
    , genericCellRenderer: GenericCellRenderer
    , stringEditor: StringEditor
    , costCellRenderer: CostCellRenderer
    , workingHoursCellRenderer: WorkingHoursCellRenderer
    , enumCellRenderer: EnumCellRenderer
    , 'booleanCellRenderer': BooleanCellRenderer
    //Overlay
    , noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    mode: {
      type: String,
      default: 'BOTH'
    },
    projectId: {
      type: String,
      default: null
    },
    resourceIds: {
      type: Array,
      default: null
    },
    heightOffset: {
      type: Number,
      default: 240
    },
    isWidget: {
      type: Boolean,
      default: false
    },
    widgetOwner: {
      type: String,
      default: null
    },
    dataviewId: {
      type: String,
      default: null
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      id: null,
      permissionName: 'ACTIVITY',
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      project: null,
      isDataView: false,
      alertError: true,
      alertMsg: null,
      alertMsgDetails: [],
      
      span: "Daily",
      spanOptions: [
        { text: "Day", value: "Daily" },
        { text: "Week", value: "Weekly" },
        { text: "Month", value: "Monthly" },
        { text: "Year", value: "Yearly" }
      ],
      dates: "this-year",
      datesStr: 'this-year', //a workaround for multiselect component which can't accept null value.
      startDate: null,
      endDate: null,
      schedulerStartDate: null,
      schedulerEndDate: null,
      min: null,
      max: null,
      total_records: 0,
      cachedData: {
        data: null,
        startRow: null,
        endRow: null
      },
      exportData: false,
      taskEdit: {
        uuId: null,
        projectUuid: null,
        readonly: false
      },
      activityEdit: {
        uuId: null,
        readonly: false
      },
      bookingEdit: {
        uuId: null,
        readonly: false
      },
      activityEditShow: false,
      bookingEditShow: false,
      taskEditShow: false,
      taskSelectShow: false,
      resourceEditShow: false,
      staffUsageProject: true,
      taskOptions: [],
      resource: [],
      resourceAlloc: 'hours',

      promptShowActivity: false,
      promptShowBooking: false,
      promptShowTask: false,
      
      layoutProfile: {},
      resourceViews: [],
      datesChanging: true, // avoid triggering setting save at bad times
      grouping: {
        company: false,
        location: false,
        department: false,
        skills: false,
        staff: false,
        resource: true
      },
      
      show: {
        activity: true,
        booking: true,
        task: true,
        vacation: true,
        required: true,
        available: false,
        alertBookings: false,
        alertTaskBookings: false,
        alertActivities: false,
        alertTasks: false,
        alertNonWork: false,
        showOnlyWarnings: false,
        unallocated: false,
        fullBarColor: false,
        usageBookings: true,
        usageActivities: true,
        usageTasks: true,
        staff_metrics: true,
        displayFilteredMetrics: false,
        work_hours: { first_hour: 9, last_hour: 17 },
        hideWeekends: false,
        hideNonWorking: true,
        weekNumbers: false,
        combineLike: false
      },
      
      showGeneric: true,
      
      
      resourceId: null,
      resourceShow: false,
      staffId: null,
      staffShow: false,
      isGeneric: false,
      genericStaff: {},
      companyId: null,
      companyShow: false,
      locationId: null,
      locationShow: false,
      departmentId: null,
      departmentShow: false,
      skillId: null,
      skillShow: false,
      projectShow: false,
      projectEditId: null,
      
      previousScrollPosTop: -1,
      previousScrollPosLeft: -1,
      
      expandLevel: 0,
      maxLevel: 0,
      filterText: '',
      promptSaveShow: false,
      promptShareShow: false,
      saveName: null,
      saveProfile: null,
      saveIndex: -1,
      confirmDeleteViewShow: false,
      deleteViewIndex: -1,
      showUnders: true,
      showOptimal: true,
      showOvers: true,
      filterValue: '',
      showColumn: null,
      taskSelectData: null,
      schedulerTasks: [],
      filterFieldValues: {},
      filter: [],
      
      coloring: {
        none: true,
        resource: false,    
        event: false,
        stage: false,
        project: false
      },
      promptType: false,
      type: 'activity',
      newName: null,
      deleteEventId: null,
      folderState: {},
      
      durationCalculationShow: false,
      durationCalculation: {
        trigger: TRIGGERS.START_DATE
        , startDateStr: null
        , startTimeStr: null
        , closeDateStr: null
        , closeTimeStr: null
        , durationDisplay: null
        , calendar: DEFAULT_CALENDAR
        , projScheduleFromStart: true
        , bookingAutoScheduleMode: true
        , constraintType: null
        , constraintDateStr: null
        , oldDateStr: null
        , oldTimeStr: null
        , lockDuration: false
        , skipOutOfProjectDateCheck: false
        , resizeMode: false
        , defaultActionForNonWorkPrompt: null
      },
      
      updateEvent: null,
      newEvent: {
        description: null,
        projectBooking: [],
        projectTask: []
      },
      
      loading: false,
      showLoadingOverlay: true,
      showNoRowsOverlay: false,
      
      eventTypeValues: {
        activityName: null,
        taskName: null,
        vacationName: null
      },
      resourceUnitEditShow: false,
      resourceUnitEdit: {
        uuId: null,
        name: null,
        unit: null,
        utilization: null,
        staffId: null
      },
      highlightRefresh: false,
      settingsShow: false,
      docImportShow: false,
      selectImportShow: false,
      importType: 'BOOKING',
      redrawScheduler: false,
      loadingView: false,

      //grid related
      gridOptions: null,
      gridApi: null,
      columnDefs: [],
      autoGroupColumnDef: null,
      context: null,
      defaultColDef: null,
      rowData: null,
      lastOpenColumnMenuParams: null,
      noRowsMessage: null,
      noRowsOverlayComponentParams: null,
      // multiple: true,
      
      lhsGridStyle: {},
      splitterStyle: {},

      //Start Scroll state
      scrollState: {
        left: -1
        , top: -1
        , triggeredByLHS: false
        , triggeredByRHS: false
      },
      //End Scroll state

      splitterEventState: {
        isMouseDown: false
        , x: 0
        , y: 0
        , leftWidth: 0
      },

      collapseId: null, 
      expandId: null,
      selected: [],
      exportToFile: false,

      schedulerWidth: null,
      scheduler: null,
      schedulerWrapper: null,
      schedulerToolbarWrapper: null,
      markedSpans: null,

      customFields: [],
      companyCustomFields: [],
      locationCustomFields: [],
      departmentCustomFields: [],
      skillCustomFields: [],
      optionCurrency: [],
      payFrequencyOptions: [],
      typeOptions: [],
      fields: [],
      companyFields: [],
      locationFields: [],
      departmentFields: [],
      skillFields: [],
      showDeleteConfirmation: false,
      skipProcessNodes: false,
      schedulerHeight: -1

      //BadgeFilter related
      , badgeFilters: [
        // { field: 'taskName', name: 'Task Name: Task #1, Task #2', value: [{ checked: true, text: 'Task #1'}, { checked: true, text: 'Task #2'}] }
        // , { field: 'skillName', name: 'Skill Name: Rigging, Animation', value: [{ checked: true, text: 'Rigging'}, { checked: true, text: 'Animation'}] }
      ]
      , badgeFilterFields: [
        // { value: 'complexity', text: 'Complexity' }
        // , { value: 'identifier', text: 'Identifier' }
      ]
      , badgeFilterFieldValues: {
        // taskName: [
        //   { text: 'Task #1' }
        //   , { text: 'Task #2' }
        // ],
        // skillName: [
        //   { text: 'Rigging' },
        //   { text: 'Layout' },
        //   { text: 'Animation' }
        // ]
      }
      , holdUpdateUntilTreeDataChanged: false
      , showInfo: []
      , lockPlanner: false
      , durationConversionOpts: {}
      , alertOffsetHeight: 0
      , searchPinned: false
      , filterPinned: false
      , reload: true
    }
  },
  watch: {
    '$store.state.company.filterIds': function(/**newValue, oldValue*/) {
      // only update if the planner is loaded
      if (this.schedulerStartDate) {
        this.updateGrid();
      }
    },
    resourceIds(newValue) {
      this.resource = newValue;
      if (this.projectId === null) {
        this.staffUsageProject = false;
        this.dismissAlert();
        const options = this.dateOptions.filter(d => d.value === 'project-start-to-end' || d.value === 'project-schedule');
        if (options.length > 0) {
          options[0].disabled = true;
          options[1].disabled = true;
        }
      }
      
      if (!this.dates) {
        this.dates = this.datesStr = "this-month";
      }
      this.datesChanged();
      this.updateGrid();
    },
    projectId(newValue) {
      this.id = newValue;
    },
    'startDate': function(newValue) {
      if (this.datesChanging) {
        return;
      }
      if(this.layoutProfile['resourceStartDate'] != newValue) {
        // Update all date values together because:
        // 1 - Avoids multiple requests when they change together
        // 2 - Need to lock in new start/end when going from range->custom date.
        //   - In those cases, the field doesn't trigger an update but we are
        //   - now interested in storing the (previously calculated) value.
        this.layoutProfile['resourceStartDate'] = newValue;
        this.layoutProfile['resourceEndDate'] = this.endDate;
        this.layoutProfile['resourceDates'] = this.dates;
        this.updateLayoutProfile();
      }
    },
    'endDate': function(newValue) {
      if (this.datesChanging) {
        return;
      }
      if(this.layoutProfile['resourceEndDate'] != newValue) {
        this.layoutProfile['resourceEndDate'] = newValue;
        this.layoutProfile['resourceStartDate'] = this.startDate;
        this.layoutProfile['resourceDates'] = this.dates;
        this.updateLayoutProfile();
      }
    },
    'dates': function(newValue) {
      if(this.layoutProfile['resourceDates'] != newValue) {
        this.layoutProfile['resourceDates'] = newValue;
        if (!this.loadingView) {
          this.updateLayoutProfile();
        }
      }
    },
    'span': function(newValue) {
      if(this.layoutProfile['resourceTimescale'] != newValue) {
        this.layoutProfile['resourceTimescale'] = newValue;
        this.updateLayoutProfile();
      }
    },
    'showGeneric': function(newValue) {
      if(this.layoutProfile['showGeneric'] != newValue) {
        this.layoutProfile['showGeneric'] = newValue;
        this.updateLayoutProfile();
      }
    },
    'resourceAlloc': function(newValue) {
      if(this.layoutProfile['resourceAlloc'] != newValue) {
        this.layoutProfile['resourceAlloc'] = newValue;
        this.updateLayoutProfile();
      }
    },
    showLoadingOverlay(newValue) {
      if (!newValue && this.isResizerTouchEventRegistered != true) {
        this.isResizerTouchEventRegistered = true;
        setTimeout(() => {
          if (this.$refs['resizer-overlay'] != null) {
            if(this.isTouchDevice()) {
              this.$refs['resizer-overlay'].addEventListener('touchstart', this.touchStartHandler);
            } else {
              this.$refs['resizer-overlay'].style.display = 'none';
            }
          }
        }, 0)
      }
    },
    alertMsg: function(nVal, oVal) {
      if (oVal != nVal && nVal == null) {
        this.updateGridHeight();
      }
    }
  },
  mounted() {
    this.chartResizeHandler();
    window.addEventListener('resize', this.chartResizeHandler);
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    this.prepareData();

    const self = this;
    const profileKey = this.getProfileEntryName('list');

    this.gridOptions = {
      processUnpinnedColumns(params) {
        if (params.api.isDestroyed()) {
          return;
        }
        params.api.setColumnsPinned([self.COLUMN_AGGRID_AUTOCOLUMN], null);
        self.enforcePinnedColumnOrders(params.api);
      },
      onNewColumnsLoaded: function(params) {
        if (params.source == 'api' && params.type == 'newColumnsLoaded') {
          self.enforcePinnedColumnOrders(params.api);
        }
      },
      getRowHeight: params => {return self.getRowHeight(params)},
      onRowGroupOpened: function(event) {
        
        //The following logic is for action: user clicks 
        // on the expand/collapse icon in the autoGroupColumn column.
        if(event.node.expanded) {
          self.expandId = event.data.key;
        }
        else {
          self.collapseId = event.data.key;
        }
      },
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)))
      },
      onRowClicked: function(event) {
        if (event.data.scheduleStart &&
            event.data.scheduleFinish) {
          if (moment(self.control.startDate).unix() * 1000 > event.data.scheduleStart) {
            self.control.startDate = moment(event.data.scheduleStart).format('YYYY-MM-DD');
          }
          
          if (moment(self.control.endDate).unix() * 1000 < event.data.scheduleFinish) {
            self.control.endDate = moment(event.data.scheduleFinish).format('YYYY-MM-DD');
          }
        }
      },
      
      onBodyScroll: function(event) {
        self.scrollState.triggeredByLHS = false;
        self.scrollState.triggeredByRHS = false;
        if(self.scrollState.top != event.top) {
          self.scrollState.triggeredByLHS = true;
          if (event.top == 0) {
            //dhtmlx Scheduler does not scroll when value is 0.
            //Workaround: set to 1 to let it scroll close to 0.
            self.scrollState.top = 1;  
          } else {
            self.scrollState.top = event.top;
          }
          //Call scrollTo() of scheduler instance directly instead of relying vue reactivity of data variable (this.scrollState.top).
          //It improves the rendering performance (at least visually if not actual improvement).
          if (self.scheduler != null) {
            self.scheduler.getView().scrollTo({ top: self.scrollState.top, left: self.scrollState.left })
          }
        }
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.api
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.api.moveColumns([colKey], columnMenuColumnIndex + 1);
        }
        const cols = params.api.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.api.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.api.moveColumns([c.colId], index);
        }

        const columns = params.api.getAllDisplayedColumns();
        self.layoutProfile[profileKey] = columns.map(c => getColumnDefs(c));
          
        // Save the new layout after applying it
        self.updateLayoutProfile();
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onSortChanged: function(event) {
        const columns = event.api.getAllDisplayedColumns();
        const curSortColumns = columns.filter(i => i.sort != null);
        self.layoutProfile[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateLayoutProfile();

        let noDataFetchRequired = curSortColumns.length == 0;
        if (!noDataFetchRequired) {
          noDataFetchRequired = true; //assume true, and prove it false
          for (const c of curSortColumns) {
            if (!self.resourceFieldDataLoaded.includes(c.colId)) {
              noDataFetchRequired = false;
              break;
            }
          }
        }

        if (noDataFetchRequired) {
          self.updateSchedulerDataAfterSortedAndFiltered(event.api);
        } else {
          self.fetchRowDataForSortedColumn(event.api, curSortColumns.map(i => i.colId));
        }
      },
      onDragStopped: function(event) {
        const columns = event.api.getAllDisplayedColumns();
        self.layoutProfile[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateLayoutProfile();
      },
      onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null;
          self.gridApi.sizeColumnsToFit();
          self.$nextTick(() => {
            const columns = event.api.getAllDisplayedColumns();
            self.layoutProfile[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateLayoutProfile();
          })
        }

        //When data first load, trigger fetchRowDataForSortedColumn when there is sorted column
        const sortedColumns = self.columnDefs.filter(i => i.sort != null);
        if (sortedColumns.length > 0) {
          self.fetchRowDataForSortedColumn(event.api, sortedColumns.map(i => i.field));
        }
      },
      onRowDataUpdated: function(event) {
        self.needSetActualRowHeights = true;

        if (self.needRedrawViewport == true) {
          self.needRedrawViewport == false;
          const firstRow = event.api.getFirstDisplayedRowIndex();
          const lastRow = event.api.getLastDisplayedRowIndex();
          if (lastRow !== -1) {
            self.redrawViewport(event, firstRow, lastRow);
          }
        }

        //Apply expand level to rowData
        event.api.forEachNodeAfterFilterAndSort((rowNode) => {
          if (rowNode.data != null && ((Object.hasOwn(self.folderState, rowNode.data.key) && self.folderState[rowNode.data.key] == true) || (!self.ignoreExpandLevel && rowNode.data.path.length <= self.expandLevel))) {
            rowNode.expanded = true;
          } else {
            rowNode.expanded = false;
          }
        });

        event.api.onGroupExpandedOrCollapsed();
        self.updateSchedulerDataAfterSortedAndFiltered(event.api);
        self.ignoreExpandLevel = false;
      },
      onViewportChanged: debounce(function(params)  {
        const firstRow = params.firstRow;
        const lastRow = params.lastRow;
        if (lastRow !== -1) {
          self.redrawViewport(params, firstRow, lastRow);
        }
      }, 250),
    };

    this.setColumnDefs();
  },
  created() {
    this.getModelInfo('RESOURCE');
    this.getDurationConversionOpts();
    this.COLUMN_AGGRID_AUTOCOLUMN = 'ag-Grid-AutoColumn';
    this.resourceDataLoaded = {}; //keep track of resource loaded
    this.companyDataLoaded = {}; //keep track of company loaded
    this.locationDataLoaded = {}; //keep track of location loaded
    this.departmentDataLoaded = {}; //keep track of department loaded
    this.skillDataLoaded = {}; //keep track of skill loaded
    this.resourceFieldDataLoaded = []; //keep track of resource field data loaded (due to sorting)
    this.ignoreExpandLevel = false;
    this.spanOptions[0].text = this.$t('timescale.day');
    this.spanOptions[1].text = this.$t('timescale.week');
    this.spanOptions[2].text = this.$t('timescale.month');
    this.spanOptions[3].text = this.$t('timescale.year');
    
    this.entityId = 'resource_planner';
    this.userId = this.$store.state.authentication.user.uuId;

    this.isDataView = this.isWidget || this.$router.currentRoute.path.indexOf('/dataview/') === 0;
    this.id = this.isDataView ? this.projectId : this.$route.params.id ? this.$route.params.id : null;
    if (this.id === null) {
      this.staffUsageProject = false;
      let optionsIndex = this.dateOptions.findIndex(d => d.value === 'project-start-to-end');
      if (optionsIndex) {
        this.dateOptions.splice(optionsIndex, 1);
      }
      optionsIndex = this.dateOptions.findIndex(d => d.value === 'project-schedule');
      if (optionsIndex) {
        this.dateOptions.splice(optionsIndex, 1);
      }
    }
    this.nodeChildrenList = null;
    this.nodeId = null;

    if (this.resourceIds !== null) {
      resources = this.resourceIds;
      if (this.projectId === null) {
        this.dateOptions.splice(1, 2);
      }
      this.dates = this.datesStr = "this-month";
      this.datesChanged();
      this.updateGrid();
    }
            
    this.loadUserProfile(); // User profile holds Staff views  
    this.loadPublicProfile(); // Public profile holds public Staff views

    //Declare properties that do not need value changing observer.
    this.resizerWidth = 5;
    this.touchEvent = {
      isTouchStart: false
      , x: 0
      , leftWidth: 0
    }
    this.debouncedTouchMoveHandler = debounce(this.touchMoveHandler, 10);

    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
    

    this.badgeFilterFields = [
      { value: 'activityName', text: this.$t('filter_component.activity_name'), filterStaff: true }
      , { value: 'availability', text: this.$t('filter_component.availability')}
      , { value: 'bookingName', text: this.$t('filter_component.booking_name'), filterStaff: true }
      , { value: 'projectName', text: this.$t('filter_component.project_name'), filterStaff: true }
      , { value: 'resourceName', text: this.$t('filter_component.resource_name')}
      , { value: 'staffName', text: this.$t('filter_component.staff_name')}
      , { value: 'stageName', text: this.$t('filter_component.stage_name'), filterStaff: true }
      , { value: 'staffType', text: this.$t('filter_component.staff_type')}
      , { value: 'tag', text: this.$t('filter_component.tag') }
      , { value: 'taskName', text: this.$t('filter_component.task_name'), filterStaff: true }
      , { value: 'usage', text: this.$t('filter_component.usage')}
    ]
  },
  beforeDestroy() {
    if(this.splitterEventState.isMouseDown) {
      this.mouseUpHandler();
    }
    window.removeEventListener('resize', this.chartResizeHandler);
    if (this.$refs['resizer-overlay'] != null) {
      this.$refs['resizer-overlay'].removeEventListener('touchstart', this.touchStartHandler);
      this.$refs['resizer-overlay'].removeEventListener('touchmove', this.debouncedTouchMoveHandler);
      this.$refs['resizer-overlay'].removeEventListener('touchcancel', this.touchCancelHandler);
      this.$refs['resizer-overlay'].removeEventListener('touchend', this.touchEndHandler);
    }
    this.nodeChildrenList = null;
    this.nodeId = null;
    this.initialColDefs = null;
    this.cleanup();
    this.resourceDataLoaded = null;
    this.staffFieldDataLoaded = null;
    this.gridApi = null;
  },
  computed: {  
    
    dateOptions() {
      const options = [
        // { text: this.$t('view_dates.custom'),  value: null},
        { text: this.$t('view_dates.custom'),  value: 'null'},
        // - means the project's start date and end date attributes (if exist).  
        // If both don't exist, then error msg "Project start date or end date 
        // not defined." and From/To dates don't change.
        { text: this.$t('view_dates.project_start_to_end'), value: "project-start-to-end" },
        // - means the earliest task start date to the latest tasks finish date 
        // in the project (hopefully from Chris' macro?)
        { text: this.$t(this.isTemplate? 'view_dates.template_schedule' : 'view_dates.project_schedule'), value: "project-schedule" },
        // - means this calendar week Mon-Sun (01/06/2020 - 07/06/2020)
        { text: this.$t('view_dates.this_week'), value: "this-week" },
        // - means this calendar week Mon-Sun  to today's date (01/06/2020 - 03/06/2020)  
        { text: this.$t('view_dates.this_week_to_date'), value: "this-week-to-date" },  
        // - means this calendar month (01/06/2020 - 30/06/2020)
        { text: this.$t('view_dates.this_month'), value: "this-month" },  
        // - means this calendar month to today's date (01/06/2020 - 03/06/2020)
        { text: this.$t('view_dates.this_month_to_date'), value: "this-month-to-date" },
        // - means this calendar quarter (01/04/2020 - 30/06/2020)  
        { text: this.$t('view_dates.this_quarter'), value: "this-quarter" },  
        // - means this calendar quarter to today's date (01/04/2020 - 03/06/2020)
        { text: this.$t('view_dates.this_quarter_to_date'), value: "this-quarter-to-date" },
        // - means this calendar year (01/01/2020 -> 31/12/2020)  
        { text: this.$t('view_dates.this_year'), value: "this-year" },  
        // - means this calendar year to today's date (01/01/2020 -> 03/06/2020)
        { text: this.$t('view_dates.this_year_to_date'), value: "this-year-to-date" },
        // - means last Mon-Sun block (week) (25/05/2020 - 31/05/2020)  
        { text: this.$t('view_dates.last_week'), value: "last-week" },  
        // - means last Mon-Sun block (week) to today's date (25/05/2020 - 03/06/2020)
        { text: this.$t('view_dates.last_week_to_date'), value: "last-week-to-date" },
        // - means last calendar month (01/05/2020 - 31/05/2020)  
        { text: this.$t('view_dates.last_month'), value: "last-month" },  
        // - means last calendar month to today's date (01/05/2020 - 03/06/2020)
        { text: this.$t('view_dates.last_month_to_date'), value: "last-month-to-date" },
        // - means last calendar quarter (01/01/2020 - 31/03/2020)  
        { text: this.$t('view_dates.last_quarter'), value: "last-quarter" },  
        // - means last calendar quarter (01/01/2020 - 31/03/2020)
        { text: this.$t('view_dates.last_quarter_to_date'), value: "last-quarter-to-date" },
        // - means last calendar year (01/01/2019 -> 31/12/2019)  
        { text: this.$t('view_dates.last_year'), value: "last-year" },  
        // - means next Mon-Sun blocks (week) from today (08/06/2020 - 14/06/2020)
        { text: this.$t('view_dates.next_week'), value: "next-week" },  
        // - means next 4 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_4_weeks'), value: "next-4-weeks" },
        // - means next 8 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_8_weeks'), value: "next-8-weeks" },
        // - means next 12 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_12_weeks'), value: "next-12-weeks" },
        // - means next 4 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_24_weeks'), value: "next-24-weeks" },
        // - means next calendar month (01/07/2020 - 31/07/2020)  
        { text: this.$t('view_dates.next_month'), value: "next-month" },  
        // - means next calendar quarter (01/10/2020 - 31/12/2020)
        { text: this.$t('view_dates.next_quarter'), value: "next-quarter" },
        // - means next calendar year (01/01/2021 -> 31/12/2021)  
        { text: this.$t('view_dates.next_year'), value: "next-year" } 
      ]

      if(this.id === null) {
        const idx = options.findIndex(i => i.value === 'project-start-to-end');
        options.splice(idx, 1); //Remove 'project-start-to-end' option as template project does not have start/end date.
      }
      if(this.id === null) {
        const idx = options.findIndex(i => i.value === 'project-schedule');
        options.splice(idx, 1); //Remove 'project-schedule' option as template project does not have start/end date.
      }
      
      return options;
    },     
    infoMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    importProperties() {
      if (this.importType === 'BOOKING') {
        const docImportProperties = [
          { value: 'color', text: this.$t('field.color') }, 
          { value: 'identifier', text: this.$t('field.identifier') }, 
          { value: 'name', text: this.$t('booking.field.name') }, 
          { value: 'resources', text: this.$t('task.field.resources') }, 
          { value: 'startdate', text: this.$t('booking.field.beginDate') }, 
          { value: 'enddate', text: this.$t('booking.field.closeTime') }, 
          { value: 'project', text: this.$t('booking.field.project') }, 
          { value: 'stages', text: this.$t('booking.field.stage') }
        ];
        
        // make sure the custom fields are selectable for import
        if (this.customFields) {
          for (const cfield of this.customFields) {
            const found = docImportProperties.find(v => v.value === cfield.name);
            if (!found) {
              docImportProperties.push({ value: cfield.name, text: cfield.displayName });
            }
          }
        }
        return docImportProperties;
      }
      else if (this.importType === 'ACTIVITY') {
        const docImportProperties = [
          { value: 'color', text: this.$t('field.color') }, 
          { value: 'identifier', text: this.$t('field.identifier') }, 
          { value: 'name', text: this.$t('activity.field.name') }, 
          { value: 'resources', text: this.$t('task.field.resources') }, 
          { value: 'startdate', text: this.$t('activity.field.startTime') }, 
          { value: 'enddate', text: this.$t('activity.field.closeTime') },  
          { value: 'stages', text: this.$t('activity.field.stage') }
        ];
        
        // make sure the custom fields are selectable for import
        if (this.customFields) {
          for (const cfield of this.customFields) {
            const found = docImportProperties.find(v => v.value === cfield.name);
            if (!found) {
              docImportProperties.push({ value: cfield.name, text: cfield.displayName });
            }
          }
        }
        return docImportProperties;
      }
      else if (this.importType === 'TASKS') {
        return null;
      }
      return null;
    },
    costMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    durationMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    schedulerSpan() {
      if (this.span === 'Daily') {
        return 'day';
      }
      else if (this.span === 'Weekly') {
        return 'week';
      }
      else if (this.span === 'Monthly') {
        return 'month';
      }
      else if (this.span === 'Yearly') {
        return 'year';
      }
      return 'day';
    },
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('staff.grid.no_data') }</span>`
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('resource.grid.planner_loading') }</span>`;
    },
    disableEdit() {
      return this.selected.length != 1;
    },
    allocMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    groupingMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    profileEntryNameTemplate() {
      return this.getProfileEntryName('{value}');
    }
  },
  methods: {
    async loadUserProfile() {
      const self = this;
      const userProfile = await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {
        return value.length > 0 ? value[0] : {};
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      // move the views from the old array in a single profile to their own profiles
      if (Object.prototype.hasOwnProperty.call(userProfile, 'resourceviews')) {
        const list = [];
        for (const profile of userProfile.resourceviews) {
          profile.type = 'resource';
          profile.editingPermissions = self.userId;
          profile.sharingMembers = cloneDeep(self.userId);
          profile.sharedVisibility = 'private';
          await viewProfileService.createPreset([profile], this.userId).then((response) => {
            const data = response.data[response.data.jobCase];
            profile.uuId = data[0].uuId;
          })
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
          list.push(profile);
        }
        delete userProfile.resourceviews;
        await viewProfileService.update([userProfile], self.userId).then(() => {
          //
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
        });
        self.resourceViews = list;
      }
      else {
        const views = await this.$store.dispatch('data/presetviewProfileList', self.userId).then((value) => {
          return value;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
        });
        
        this.addViews(views);
      }
    },
    async loadPublicProfile() {
      if (!localStorage.companyId) {
        const data = await companyService.list({limit: -1, start: 0}).then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
          return null;
        });

        if (data != null) {
          const company = data.filter(d => d.type === 'Primary');
          if (company.length > 0) {
            localStorage.companyId = company[0].uuId;
          }
        }
      }

      const views = await this.$store.dispatch('data/viewProfileListPublic', localStorage.companyId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    },
    addViews(views) {
      for (const view of views) {
        // if not in the list, add it
        if (view.type === 'resource' &&
            this.resourceViews.findIndex((i) => i.uuId === view.uuId) === -1) {
          this.showInfo.push(false);
          this.resourceViews.push(view);
          if (this.useDefault && view.defaultView) {
            this.loadViewSettings(view);
          }
        }
      }
      
      this.resourceViews.sort(function( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ){
          return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
      });
    },
    changeFilter(value) {
      this.filterValue = value;
      this.layoutProfile.resourceFilterText = value;
      this.updateLayoutProfile();
      if (!this.loadingView) {
        this.inProgressShow = true;
        this.inProgressLabel = this.$t('staff.progress.filtering');
        setTimeout(() => {
          this.assignData();
        }, 500);
      }
    },
    onFilterClear() {
      this.filterText = '';
      this.changeFilter(this.filterText);
    },
    loadViewSettings(view) {
      this.loadingView = true;
      this.holdUpdateUntilTreeDataChanged = true; //signal scheduler not to trigger updateData() repeatedly when settings changed. Only trigger once when treeData changed
      if (typeof view.expandLevel !== 'undefined') {
        this.layoutProfile.resourceExpandLevel = this.expandLevel = view.expandLevel;
      }

      this.loadColors(view.coloring);
      this.layoutProfile.resourceplanner_view_coloring = this.coloring;
      this.layoutProfile.lockPlanner = this.lockPlanner = typeof view.lockPlanner !== 'undefined' ? view.lockPlanner : false;
      
      if (view.filter) {
        for (const f of view.filter) {
          if (f.name.indexOf(']:') === -1) {
            const idx = f.name.indexOf(':');
            if (idx !== -1) {
              f.name = f.name.substr(0, idx) + ' [Is]' + f.name.substr(idx, f.name.length);
            }
          }
        }
      }
      this.badgeFilters = view.filter;
      this.layoutProfile.resourceplanner_view_filter = cloneDeep(this.badgeFilters);
      
      this.grouping = cloneDeep(view.grouping);
      this.showGeneric = cloneDeep(view.showGeneric);
      
      this.filterText = view.filterText ? view.filterText : "";
      this.filterValue = this.filterText;
      this.layoutProfile.resourceFilterText = this.filterText;

      this.searchPinned = view.searchPinned;
      this.filterPinned = view.filterPinned;
      
      //this.eventTypeValues = view.typeValues;
      this.layoutProfile.resourceplanner_view_typevalues = this.eventTypeValues;
      
      if(Object.prototype.hasOwnProperty.call(view, 'show') &&
         typeof view.show !== 'undefined') {
        if (typeof view.show.usage === 'undefined') {
          view.show.usage = false;
        }
        if (typeof view.show.hideNonWorking === 'undefined') {
          view.show.hideNonWorking = true;
        }
        if (typeof view.show.weekNumbers === 'undefined') {
          view.show.weekNumbers = true;
        }
        if (typeof view.show.work_hours === 'undefined') {
          const startHour = Math.floor(this.durationConversionOpts.startHour / 1000 / 60 / 60);
          const closeHour = Math.floor(this.durationConversionOpts.closeHour / 1000 / 60 / 60);
          view.show.work_hours = { first_hour: startHour, last_hour: closeHour };
        }
        if (typeof view.show.required === 'undefined') {
          view.show.required = true;
        }
        if (typeof view.show.available === 'undefined') {
          view.show.available = false;
        }
        this.$set(this, 'show', cloneDeep(view['show'])); 
        this.layoutProfile[this.getProfileEntryName('show')] = cloneDeep(view.show);
      }
      
      if(Object.prototype.hasOwnProperty.call(view, 'resourceDates')) {
        this.$set(this, 'dates', view['resourceDates']);
        this.$set(this, 'datesStr', this.dates == null? 'null' : this.dates);
        this.layoutProfile.dates = view.resourceDates;
        if (view.resourceDates == null) {
          // Null means custom date. Use recorded range.
          try {
            this.$set(this, 'startDate', view['resourceStartDate']);
            this.layoutProfile.startDate = view.resourceStartDate;
            this.$set(this, 'endDate', view['resourceEndDate']);
            this.layoutProfile.endDate = view.resourceEndDate;
          } catch (e) {
            console.log("Invalid date range on restore. Using default"); // eslint-disable-line no-console
            this.datesChanged();
          }
        } else {
          // Range is calculated
          this.datesChanged();
        }
      }
      if(Object.prototype.hasOwnProperty.call(view, 'resourceTimescale')) {
        this.$set(this, 'span', view['resourceTimescale']);
        this.layoutProfile.span = view.resourceTimescale;
      }
      if(Object.prototype.hasOwnProperty.call(view, 'resourceAlloc')) {
        this.$set(this, 'resourceAlloc', view['resourceAlloc']); 
        this.layoutProfile.resourceAlloc = view.resourceAlloc;
      }

      if(Object.prototype.hasOwnProperty.call(view, 'showUnders')) {
        this.$set(this, 'showUnders', view['showUnders']); 
        this.layoutProfile.showUnders = view.showUnders;
      }
      if(Object.prototype.hasOwnProperty.call(view, 'showOptimal')) {
        this.$set(this, 'showOptimal', view['showOptimal']); 
        this.layoutProfile.showOptimal = view.showOptimal;
      }
      if(Object.prototype.hasOwnProperty.call(view, 'showOvers')) {
        this.$set(this, 'showOvers', view['showOvers']); 
        this.layoutProfile.showOvers = view.showOvers;
      }

      if (view.list != null) {
        this.layoutProfile[this.getProfileEntryName('list')] = cloneDeep(view.list);
        this.loadColumnSettings(this, this.layoutProfile[this.getProfileEntryName('list')], this.columnDefs);
      } else if (this.gridApi) {
        this.gridApi.setGridOption('columnDefs', []);
        this.gridApi.setGridOption('columnDefs', JSON.parse(JSON.stringify(this.initialColDefs)));
        this.needRedrawViewport = true;
      }

      if (view.projectGanttLHSGrid != null && this.$refs['lhs-grid']) {
        this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')] = cloneDeep(view.projectGanttLHSGrid);
        this.$refs['lhs-grid'].style.width = this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')].width;
      }
      if (view.projectGanttRHSChart != null && this.$refs['rhs-chart']) {
        this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')] = cloneDeep(view.projectGanttRHSChart);
        this.$refs['rhs-chart'].style.width = this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')].width;
        //Expecting the width value from the profile is percetage value in string
        //Use parseFloat to get the number and remove '%' suffix.
        const widthFloat = parseFloat(this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')].width);
        this.schedulerWidth = `${parseInt(this.$refs['rhs-chart'].parentNode.getBoundingClientRect().width * widthFloat / 100) - 2}px`;
      }
       
      // save the view name in the profile
      this.layoutProfile.resourceplanner_view_viewName = view.name;
           
      // prevent a delay when the user clicks to load a view by loading the columns on the next tick
      setTimeout(() => {
        //Reset folderState as current folderState may not valid for loaded view settings
        this.folderState = {}
        // Save the new layout after applying it
        this.updateLayoutProfile({ clearViewName: false });
        this.needRedrawViewport = true;
        this.updateGrid();
        this.loadingView = false;
        
        this.$store.dispatch("breadcrumb/updateView", view.name, { root: true });
      }, 0);
    },
    expandLevels() {
      if (typeof this.layoutProfile.resourceExpandLevel !== 'undefined') {
        this.expandLevel = this.layoutProfile.resourceExpandLevel;
      }
    },
    savePreset() {
      this.saveName = null;
      this.saveIndex = -1;
      this.saveProfile = { 
        type: 'resource',
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        resourceDates: cloneDeep(this.dates), 
        resourceStartDate: cloneDeep(this.startDate), 
        resourceEndDate: cloneDeep(this.endDate), 
        resourceTimescale: cloneDeep(this.span),
        resourceAlloc: cloneDeep(this.resourceAlloc),
        grouping: cloneDeep(this.grouping),
        showGeneric: cloneDeep(this.showGeneric),
        expandLevel: cloneDeep(this.expandLevel),
        showUnders: cloneDeep(this.showUnders),
        showOptimal: cloneDeep(this.showOptimal),
        showOvers: cloneDeep(this.showOvers),
        show: cloneDeep(this.show), 
        coloring: cloneDeep(this.coloring),
        filter: cloneDeep(this.badgeFilters),
        typeValues: cloneDeep(this.eventTypeValues),
        filterText: cloneDeep(this.filterText),
        lockPlanner: cloneDeep(this.lockPlanner),
        filterPinned: this.filterPinned,
        searchPinned: this.searchPinned
      };
      if (this.layoutProfile[this.getProfileEntryName('list')] != null) {
        this.saveProfile.list = cloneDeep(this.layoutProfile[this.getProfileEntryName('list')])
      }
      if (this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')] != null) {
        this.saveProfile.projectGanttLHSGrid = cloneDeep(this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')]);
      }
      if (this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')] != null) {
        this.saveProfile.projectGanttRHSChart = cloneDeep(this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')]);
      }
      this.promptSaveShow = true;
    },
    async updateUsers(profile, updateUsers, service) {
      if (updateUsers) {
        const users = updateUsers.split(',');
        for (const user of users) {
          const profileData = await service.list(this.entityId, user)
           .then(response => {
             return response.data[response.data.jobCase];
          });
         
          if (profileData.length > 0) {
            profileData[0][this.getProfileEntryName('coloring')] = profile.coloring;
            profileData[0][this.getProfileEntryName('filter')] = profile.filter;
            profileData[0]['resourceFilterText'] = profile.filterText;
            profileData[0][this.getProfileEntryName('typevalues')] = profile.typeValues;
            profileData[0]['resourceDates'] = profile.resourceDates;
            profileData[0]['resourceStartDate'] = profile.resourceStartDate;
            profileData[0]['resourceEndDate'] = profile.resourceEndDate;
            profileData[0]['span'] = profile.resourceTimescale;
            profileData[0]['resourceUsageProject'] = profile.resourceUsageProject;
            profileData[0]['resourceAlloc'] = profile.resourceAlloc;
            profileData[0]['grouping'] = profile.grouping;
            profileData[0]['showGeneric'] = profile.showGeneric;
            profileData[0]['showUnders'] = profile.showUnders;
            profileData[0]['showOptimal'] = profile.showOptimal;
            profileData[0]['showOvers'] = profile.showOvers;
            profileData[0][this.getProfileEntryName('show')] = profile.show;
            profileData[0][this.getProfileEntryName('projectGanttLHSGrid')] = profile.projectGanttLHSGrid;
            profileData[0][this.getProfileEntryName('projectGanttRHSChart')] = profile.projectGanttRHSChart;
            profileData[0][this.getProfileEntryName('viewName')] = profile.name;
            profileData[0][this.getProfileEntryName('list')] = profile.list;
            profileData[0][this.getProfileEntryName('filterPinned')] = profile.filterPinned;
            profileData[0][this.getProfileEntryName('searchPinned')] = profile.searchPinned;
            await service.update(profileData, this.entityId, user)
          }
        }
      }
    },
    confirmSaveOk({ profile, newDefault, updateUsers, sharing }) {      
      if (newDefault) {
        // find the existing default view and turn it off
        const defaultView = this.resourceViews.find(v => v.defaultView);
        if (defaultView) {
          defaultView.defaultView = false;
          viewProfileService.updatePreset([defaultView], this.userId)
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
        }
      }
      
      this.updateUsers(profile, updateUsers, layoutProfileService);
      
      if (this.saveIndex !== -1) {
        this.resourceViews.splice(this.saveIndex, 1, profile);
      }
      else {
        this.addViews([profile]);
      }
      
      if (!sharing) {
        // save the view name in the profile
        this.layoutProfile.resourceplanner_view_viewName = profile.name;
        this.$store.dispatch("breadcrumb/updateView", profile.name, { root: true });
        this.updateLayoutProfile({ clearViewName: false });
      }
    },
    updateColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = { 
        name: profile.name,
        uuId: profile.uuId,
        type: 'resource',
        defaultView: profile.defaultView,
        sharedVisibility: cloneDeep(profile.sharedVisibility),
        sharingMembers: cloneDeep(profile.sharingMembers),
        editingPermissions: cloneDeep(profile.editingPermissions),
        resourceDates: cloneDeep(this.dates), 
        resourceStartDate: cloneDeep(this.startDate), 
        resourceEndDate: cloneDeep(this.endDate), 
        resourceTimescale: cloneDeep(this.span),
        resourceAlloc: cloneDeep(this.resourceAlloc),
        grouping: cloneDeep(this.grouping),
        showGeneric: cloneDeep(this.showGeneric),
        expandLevel: cloneDeep(this.expandLevel),
        showUnders: cloneDeep(this.showUnders),
        showOptimal: cloneDeep(this.showOptimal),
        showOvers: cloneDeep(this.showOvers),
        show: cloneDeep(this.show),  
        coloring: cloneDeep(this.coloring),
        filter: cloneDeep(this.badgeFilters),
        typeValues: cloneDeep(this.eventTypeValues),
        filterText: cloneDeep(this.filterText),
        lockPlanner: cloneDeep(this.lockPlanner),
        searchPinned: this.searchPinned,
        filterPinned: this.filterPinned
      };
      if (this.layoutProfile[this.getProfileEntryName('list')] != null) {
        this.saveProfile.list = cloneDeep(this.layoutProfile[this.getProfileEntryName('list')])
      }
      if (this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')] != null) {
        this.saveProfile.projectGanttLHSGrid = cloneDeep(this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')]);
      }
      if (this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')] != null) {
        this.saveProfile.projectGanttRHSChart = cloneDeep(this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')]);
      }
      this.saveIndex = index;
      this.promptSaveShow = true;
    },
    copyColumnSettings(name, profile) {
      this.saveName = `${name} ${this.$t('dataview.copy_text')}`;
      this.saveProfile = { 
        name: `${name} ${this.$t('dataview.copy_text')}`,
        uuId: null,
        type: 'resource',
        sharedVisibility: 'private',
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        resourceDates: cloneDeep(profile.resourceDates), 
        resourceStartDate: cloneDeep(profile.resourceStartDate), 
        resourceEndDate: cloneDeep(profile.resourceEndDate), 
        resourceTimescale: cloneDeep(profile.resourceTimescale),
        resourceAlloc: cloneDeep(profile.resourceAlloc),
        grouping: cloneDeep(profile.grouping),
        showGeneric: cloneDeep(profile.showGeneric),
        expandLevel: cloneDeep(profile.expandLevel),
        showUnders: cloneDeep(profile.showUnders),
        showOptimal: cloneDeep(profile.showOptimal),
        showOvers: cloneDeep(profile.showOvers),
        show: cloneDeep(profile.show),  
        coloring: cloneDeep(profile.coloring),
        filter: cloneDeep(profile.filter),
        typeValues: cloneDeep(profile.eventTypeValues),
        filterText: cloneDeep(profile.filterText),
        lockPlanner: cloneDeep(profile.lockPlanner),
        searchPinned: profile.searchPinned,
        filterPinned: profile.filterPinned
      };
      if (profile.list != null) {
        this.saveProfile.list = cloneDeep(profile.list)
      }
      if (profile.projectGanttLHSGrid != null) {
        this.saveProfile.projectGanttLHSGrid = cloneDeep(profile.projectGanttLHSGrid);
      }
      if (profile.projectGanttRHSChart != null) {
        this.saveProfile.projectGanttRHSChart = cloneDeep(profile.projectGanttRHSChart);
      }
      this.saveIndex = -1;
      this.promptSaveShow = true;
    },
    shareColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = profile;
      this.saveIndex = index;
      this.promptShareShow = true;
    },
    removeColumnSettings(index) {
      this.confirmDeleteViewShow = true;
      this.deleteViewIndex = index;
    },
    confirmDeleteViewOk() {
      const toRemove = this.resourceViews.splice(this.deleteViewIndex, 1);
      
      viewProfileService.remove([{ uuId: toRemove[0].uuId }],
                        this.userId).then(() => {  
                        //
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    getData(id) {
      if (id in locations) {
        return locations[id];
      }
      else if (id in skills) {
        return skills[id];
      }
      else if (id in companies) {
        return companies[id];
      }
      else if (id in departments) {
        return departments[id];
      }
      return null;
    },
    detailLinkTag(params) {
      if (params.data && params.data.type) {
        return this.$t(`staff.group.${params.data.type}`);
      }
      else if (!params.data) {
        const id = params.node.key.slice(params.node.key.length - 36);
        const data = this.getData(id);
        if (data && data.type) {
          return this.$t(`staff.group.${data.type}`);
        }
      }
      else if (params.data && params.data.generic) {
        return this.$t('staff.group.generic');
      }
      return this.$t('staff.group.staff');
    },
    detailLinkTagClass(params) {
      let data = params.data;
      
      if (!data) {
        const id = params.node.key.slice(params.node.key.length - 36);
        data = this.getData(id);
      }
      
      if (data && data.type) {
        if (data.type === 'company') {
          return 'tag-purple';
        }
        else if (data.type === 'location') {
          return 'tag-teal';
        }
        else if (data.type === 'department') {
          return 'tag-indigo';
        }
        else if (data.type === 'skill') {
          return 'tag-red';
        }
        else if (data.type === 'task') {
          return 'tag-yellow';
        }
        else if (data.type === 'resource' ||
                 data.type === 'resourceUnit') {
          return 'tag-resource';
        }
        else if (data && data.generic) {
          return 'tag-blue';
        }
      }
      else if (data && data.generic) {
        return 'tag-blue';
      }
      
      return 'tag-pumpkin';
    },
    detailLinkTooltip(params) {
      if (params.data && params.data.type === 'task') {
        return true;
      }
      return false;
    },
    detailLinkLabel(params) {
      if (params.data) {
        return params.data.name;
      }
      else {
        const id = params.node.key.slice(params.node.key.length - 36);
        const data = this.getData(id);
        if (data) {
          return data.name;
        }
      }
      // If a node is not found we return N/A, this should not happen
      console.warn(`node not found ${params.node.key}`); // eslint-disable-line no-console
      return "N/A";
    },
    resourceUnitOpen(selectedId, name, quantity, utilization, staffId) {
    
      const edit = this.resourceUnitEdit;
      edit.uuId = selectedId;
      edit.name = name;
      edit.unit = quantity;
      edit.utilization = utilization;
      edit.staffId = staffId;
      this.resourceUnitEditShow = true;
    },
    allProjects() {
      this.assignData();
    },
    taskOpen(selectedId, projectUuid, readonly) {
      this.taskEdit.uuId = selectedId;
      this.taskEdit.projectUuid = projectUuid;
      this.taskEdit.readonly = readonly;
      this.taskEditShow = true;
    },
    activityOpen(selectedId, readonly) {
      this.activityEdit.uuId = selectedId;
      this.activityEdit.readonly = readonly;
      this.activityEditShow = true;
    },
    bookingOpen(selectedId, readonly) {
      this.bookingEdit.uuId = selectedId;
      this.bookingEdit.readonly = readonly;
      this.bookingEditShow = true;
    },
    modalSuccess() {
      if (!this.isDataView) {
        // the resource list may have changed
        this.resource.splice(0, this.resource.length);
      }
      this.needRedrawViewport = true;
      this.updateGrid();
    },
    /**
     * This method is not in use due to the modalSuccessNoReload doesn't exist here. 
     * All modals' success callback event expects a data reload and reset folder state.
     */
    rememberFolderState(ignoreExpandLevel=false) {
      this.ignoreExpandLevel = ignoreExpandLevel;
      this.folderState = {} //reset to empty
      if (this.gridApi != null) {
        this.gridApi.forEachNode(node => {
          if (node.data?.key != null) {
            this.folderState[node.data.key] = node.expanded == true;
          }
        })
      }
    },
    rangeSelected() {
      this.dates = this.datesStr == 'null'? null : this.datesStr;
      this.holdUpdateUntilTreeDataChanged = true;
      this.datesChanged();
      this.updateGrid();
    },
    daySelected() {
      this.holdUpdateUntilTreeDataChanged = true;
      this.highlightRefresh = false;
      this.dates = null;
      this.datesStr = 'null';
      this.updateGrid();
    },
    checkDates() {
      if (this.startDate === null && this.endDate === null) {
        this.datesChanged();
        return true;
      }
      
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
      const diff = end - start;
      
      if (end < start || this.startDate === '' || this.endDate === '' ||
          !this.startDate || !this.endDate) {
       this.alertMsg = this.$t('staff.error.invalid_date_range');
        this.alertError = true;
        return false;
      }
      else if (this.span === 'Monthly' &&
               diff / (1000 * 3600 * 24) < 32) {
        this.span = 'Weekly';
      }
      return true;
    },
    updateGrid() {
      if (!this.checkDates()) {
        return;
      }
      
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('resource.grid.loading_list');
      this.alertMsg = null;
      this.needRedrawViewport = true; // we need to redraw the rows when the new data is loaded
      ServerSideDatasource(this);
    },
    processCalendar(calendar) {
      // convert type to v_CalendarType
      for (const entry of calendar[0]) {
        if (entry.type === 'Sunday') {
          entry.v_CalendarType = [1];
        }
        else if (entry.type === 'Sunday') {
          entry.v_CalendarType = [1];
        }
        else if (entry.type === 'Monday') {
          entry.v_CalendarType = [2];
        }
        else if (entry.type === 'Tuesday') {
          entry.v_CalendarType = [3];
        }
        else if (entry.type === 'Wednesday') {
          entry.v_CalendarType = [4];
        }
        else if (entry.type === 'Thursday') {
          entry.v_CalendarType = [5];
        }
        else if (entry.type === 'Friday') {
          entry.v_CalendarType = [6];
        }
        else if (entry.type === 'Saturday') {
          entry.v_CalendarType = [7];
        }
        
        if (entry.endHour) {
          entry.v_EndHour = [entry.endHour];
        }
        if (entry.startHour) {
          entry.v_StartHour = [entry.startHour];
        }
      }
      return calendar[0];
    },
    async systemLocationCalendar() {
      let data = await calendarService.get([{ uuId: '00000000-0000-0000-0000-000000000000'}])
      .then(response => {
        return (response && response.data? response.data : []) || [];
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      })
      if (data.jobCase) {
        this.baseCalendar = this.processCalendar(data[data.jobCase]);
      }
    },
    prepareData() {
      const self = this;
      const updateData = async () => {
        await self.systemLocationCalendar();
        await self.loadLayoutProfile();
        if (!self.checkDates()) {
          return;
        }
        ServerSideDatasource(self);
      };
  
      
      updateData();
    },
    dismissAlert() {
      this.resetAlert();
    },
    resetAlert() {
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.alertError = false;
    },
    isEditable() {
      return true;
    },
    detailLinkId(params) {
      if (params.data) {
        const type = params.data.type;
        if (type === 'company') {
          return params.data.companyUuId;
        }
        else if (type === 'location') {
          return params.data.locationUuId;
        }
        else if (type === 'department') {
          return params.data.departmentUuId;
        }
        else if (type === 'skill') {
          return params.data.skillUuId;
        }
        else if (type === 'project') {
          return params.data.projectUuId;
        }
        else if (type === 'task') {
          return params.data.tu;
        }
        else {
          return params.data.uuId;
        }
      }
      else {
        const id = params.node.key.slice(params.node.key.length - 36);
        return id;
      }
    },
    openDetail(id, params) {
      const data = params.data ? params.data : this.getData(id);
      if (data) {
        const type = data.type;
        if (type === 'company') {
          this.companyId = id;
          this.companyShow = true;
        }
        else if (type === 'location') {
          this.locationId = id;
          this.locationShow = true;
        }
        else if (type === 'department') {
          this.departmentId = id;
          this.departmentShow = true;
        }
        else if (type === 'skill') {
          this.skillId = id;
          this.skillShow = true;
        }
        else if (type === 'task') {
          this.taskOpen(id, data.pu, data.readonly);
        }
        else if (type === 'resourceUnit') {
          if (this.canEdit('STAFF')) {
            this.resourceUnitOpen(id, data.name, data.quantity, data.utilization, data.staffUuid);
          }
        }
        else if (type === 'activity') {
          this.activityOpen(id, data.readonly);
        }
        else if (type === 'booking') {
          this.bookingOpen(id, data.readonly);
        }
        // else if (type === 'project') {
        //   this.projectEditId = id;
        //   this.projectShow = true;
        // }
        else if (type === 'staff') {
          this.staffId = id;
          this.isGeneric = data.generic;
          this.staffShow = true;
        }
        else {
          this.resourceId = data.uuId;
          this.resourceEditShow = true;
        }
      }
    },
    httpAjaxError(e) {
      const response = e.response;
      this.alertError = true;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
      } else {
        this.alertMsg = this.$t('error.internal_server');
      }
    },
    getToday() {
      if (this.$store.state.epoch.value) {
        return new Date(this.$store.state.epoch.value);
      }
      return new Date();
    },
    datesChanged() {
      const self = this;
      this.datesChanging = true;
      if (this.dates === "project-start-to-end") {
        if (this.project === null || typeof this.project.scheduleStart === 'undefined' || typeof this.project.scheduleFinish === 'undefined') {
          this.dates = null;
          this.datesStr = 'null';
          let today = this.getToday();
          this.startDate = formatDate(today);
          today.setDate(today.getDate() + 7);
          this.endDate = formatDate(today);
        }
        else {
          this.startDate = formatDate(new Date(this.project.scheduleStart));
          this.endDate = formatDate(new Date(this.project.scheduleFinish));
        }
      } 
      else if (this.dates === "project-schedule") {
        if (this.min === 0 || this.max === 0 ||
            this.min === null || this.max === null ||
            this.min === 'NaN' || this.max === 'NaN') {
          this.dates = null;
          this.datesStr = 'null';
          let today = this.getToday();
          this.startDate = formatDate(today);
          today.setDate(today.getDate() + 7);
          this.endDate = formatDate(today);
        }
        else {
          // make sure the span is less than 3 years
          const diff = this.max - this.min;
          if (diff > 94670778000) {
            this.max = this.min + (94670778000 - 86400000);
          }
          this.startDate = formatDate(new Date(this.min));
          this.endDate = formatDate(new Date(this.max));
        }
      } 
      else if (this.dates === "this-week") {
        let today = getNextWorkingDay(this.getToday());
        const day = today.getDay();
        if (day !== 1) {
          if (day === 0) {
            today.setDate(today.getDate() + 1); // Monday
          }
          else {
            today.setDate(today.getDate() - (day - 1)); // Monday
          }
        }
        this.startDate = formatDate(today);
        
        // Sunday
        today.setDate(today.getDate() + 6); 
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "this-week-to-date") {
        let today = getNextWorkingDay(this.getToday());
        this.endDate = formatDate(today);
        
        const day = today.getDay();
        if (day !== 1) {
          if (day === 0) {
            today.setDate(today.getDate() + 1); // Monday
          }
          else {
            today.setDate(today.getDate() - (day - 1)); // Monday
          }
        }
        this.startDate = formatDate(today);
      } 
      else if (this.dates === "this-month") {
        let today = this.getToday();
        this.endDate = formatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        
        this.startDate = formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
      } 
      else if (this.dates === "this-month-to-date") {
        let today = this.getToday();
        this.endDate = formatDate(today);
        
        this.startDate = formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
      } 
      else if (this.dates === "this-quarter") {
        let today = this.getToday();
        let quarter = Math.floor((today.getMonth()) / 3);
        
        let thisq;
        if (quarter == 4) {
            thisq = new Date (today.getFullYear(), 9, 1); // start in October
        } else {
            thisq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(thisq);
        const lastday = new Date(thisq.getFullYear(), thisq.getMonth() + 3, 0);
        this.endDate = formatDate(lastday);
      } 
      else if (this.dates === "this-quarter-to-date") {
        let today = this.getToday();
        let quarter = Math.floor((today.getMonth()) / 3);
        
        let thisq;
        if (quarter == 4) {
            thisq = new Date (today.getFullYear(), 9, 1); // start in October
        } else {
            thisq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(thisq);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "this-year") {
        let today = this.getToday();
        const year = today.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
        this.endDate = [year, '12', '31'].join('-');
      } 
      else if (this.dates === "this-year-to-date") {
        let today = this.getToday();
        this.endDate = formatDate(today);
        const year = today.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
      } 
      else if (this.dates === "last-week") {
        let today = this.getToday();
             
        const day = today.getDay();
        today.setDate(today.getDate() - day); // Sunday
        this.endDate = formatDate(today);
        
        today.setDate(today.getDate() - 6); // Monday
        this.startDate = formatDate(today);
      } 
      else if (this.dates === "last-week-to-date") {
        let today = this.getToday();
        this.endDate = formatDate(today);
        const day = today.getDay();
        today.setDate(today.getDate() - day); // Sunday
        today.setDate(today.getDate() - 6); // Monday
        this.startDate = formatDate(today);
      } 
      else if (this.dates === "last-month") {
        let today = this.getToday();
        this.endDate = formatDate(new Date (today.getFullYear(), today.getMonth(), 0));        
        this.startDate = formatDate(new Date (today.getFullYear(), today.getMonth() - 1, 1));
      } 
      else if (this.dates === "last-month-to-date") {
        let today = this.getToday();
        this.endDate = formatDate(today);
            
        this.startDate = formatDate(new Date (today.getFullYear(), today.getMonth() - 1, 1));
      } 
      else if (this.dates === "last-quarter") {
        let today = this.getToday();
        let quarter = Math.floor((today.getMonth()) / 3);
        quarter > 0 ? quarter-- : quarter = 4;
        
        let lastq;
        if (quarter == 4) {
            lastq = new Date (today.getFullYear() - 1, 9, 1); // start in October
        } else {
            lastq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(lastq);
        this.endDate = formatDate(new Date (today.getFullYear(), lastq.getMonth() + 3, 0));
      } 
      else if (this.dates === "last-quarter-to-date") {
        let today = this.getToday();
        let quarter = Math.floor((today.getMonth()) / 3);
        quarter > 0 ? quarter-- : quarter = 4;
        
        let lastq;
        if (quarter == 4) {
            lastq = new Date (today.getFullYear() - 1, 9, 1); // start in October
        } else {
            lastq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(lastq);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "last-year") {
        const b = this.getToday();
        b.setFullYear(b.getFullYear() - 1);
        const year = b.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
        this.endDate = [year, '12', '31'].join('-');
      } 
      else if (this.dates === "next-week") {
        let today = getNextWorkingDay(this.getToday());
        const day = today.getDay();
        
        today.setDate(today.getDate() + (8 - day)); // Monday
        this.startDate = formatDate(today);
        today.setDate(today.getDate() + 6); // Sunday
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-4-weeks") {
        let today = getNextWorkingDay(this.getToday());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        this.startDate = formatDate(today);
        today.setDate((today.getDate() + 4 * 7) - 1);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-8-weeks") {
        let today = getNextWorkingDay(this.getToday());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        this.startDate = formatDate(today);
        today.setDate((today.getDate() + 8 * 7) - 1);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-12-weeks") {
        let today = getNextWorkingDay(this.getToday());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        this.startDate = formatDate(today);
        today.setDate((today.getDate() + 12 * 7) - 1);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-24-weeks") {
        let today = getNextWorkingDay(this.getToday());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        this.startDate = formatDate(today);
        today.setDate((today.getDate() + 24 * 7) - 1);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-month") {
        let today = this.getToday();
        const nextm = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        this.startDate = formatDate(nextm);
        const lastday = new Date(nextm.getFullYear(), nextm.getMonth() + 1, 0);
        this.endDate = formatDate(lastday);
      } 
      else if (this.dates === "next-quarter") {
        let today = this.getToday();
        let quarter = Math.floor((today.getMonth() + 3) / 3);
        var nextq;
        if (quarter == 4) {
            nextq = new Date (today.getFullYear() + 1, 1, 1);
        } else {
            nextq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(nextq);
        const lastday = new Date(nextq.getFullYear(), nextq.getMonth() + 3, 0);
        this.endDate = formatDate(lastday);
      } 
      else if (this.dates === "next-year") {
        const b = this.getToday();
        b.setFullYear(b.getFullYear() + 1);
        const year = b.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
        this.endDate = [year, '12', '31'].join('-');
      }
      Vue.nextTick(() => {
        self.datesChanging = false;
      });
    },
    shouldExport(value, data) {
      if (data && data.type === 'task') {
        return true;
      }
      
      let workingHours = value.w ? value.w : 0;
      const availableHours = value && value.a ? value.a : 0;
      
      if (availableHours === -1) {
        return true;
      }
      
      const percent = Math.trunc(workingHours / availableHours * 100);
      if (this.showUnders && percent <= 75) {
        return true;
      }
      else if (this.showOptimal && percent >= 76 && percent <= 100) {
        return true;
      }
      else if (this.showOvers && percent > 100) {
        return true;
      }
      return false;
    },
    exportValues() {
      // get all data
      const self = this;
      const keys = this.gridApi
          .getAllDisplayedColumns()
          .map(column => column.getColId());
      keys.splice(1, 0, 'type');
      
      const params = {
        processCellCallback: function(cell) {
          if (cell.column.colId === 'ag-Grid-AutoColumn' &&
              cell.node.data.type === 'task') {
            return cell.node.data.td;
          }
          else if (cell.column.colId.indexOf('resourceUuid') !== -1) {
            return cell.node.data.lastName;
          }
          else if (cell.column.colId.indexOf('w') !== -1) {
            if (!self.shouldExport(cell.value, cell.node.data)) {
              return null;
            }
            
            if (cell.node.data.type === 'task') {
              if (cell.value.t) {
                return cell.node.data.name;
              }
              else {
                return null;
              }
            }
            else if (self.resourceAlloc === 'percent') {
              const workingHours = cell.value && cell.value.w ? cell.value.w : 0;
              const availableHours = cell.value ? cell.value.a : 0;
              const value = (workingHours % 1 !== 0 ? parseFloat(workingHours).toFixed(1) : workingHours);
              return value !== 0 ? Math.trunc(value / availableHours * 100) + '%' : '';
            }
            else if (self.resourceAlloc === 'hours') {
              const workingHours = cell.value && cell.value.w ? cell.value.w : 0;
              return `${workingHours}h`;
            }
            else if (self.resourceAlloc === 'days') {
              const daysworkingHours = cell.value && cell.value.w ? cell.value.w : 0;
              return `${parseFloat(daysworkingHours / 8.0).toFixed(1).replace(/\.?0+$/, '')}D`;
            }
            else if (self.resourceAlloc === 'headcount') {
              const workingHours = cell.value && cell.value.w ? cell.value.w : 0;
              if (self.span === 'Daily') {
                return Math.ceil(parseFloat(workingHours / 8.0).toFixed(1).replace(/\.?0+$/, ''));
              }
              else if (self.span === 'Weekly') {
                return Math.ceil(parseFloat(workingHours / 40.0).toFixed(1).replace(/\.?0+$/, ''));
              }
              else if (self.span === 'Monthly') {
                return Math.ceil(parseFloat(workingHours / 168.0).toFixed(1).replace(/\.?0+$/, ''));
              }
              else if (self.span === 'Yearly') {
                return Math.ceil(parseFloat(workingHours / 2080.0).toFixed(1).replace(/\.?0+$/, ''));
              }
            }
          }
          else if (cell.column.colId.indexOf('totalDuration') !== -1) {
              const totalDuration = cell.value && cell.value.w ? cell.value.w : 0;
            if (self.resourceAlloc === 'hours') {
              return totalDuration;
            }
            else {
              return `${parseFloat(totalDuration / 8.0).toFixed(1).replace(/\.?0+$/, '')}D`;
            }
          }
          else if (cell.column.colId === 'type') {
            if (cell.value) {
              return self.$t(`staff.group.${cell.value}`);
            }
            else {
              return self.$t(`staff.group.staff`);
            }
          }
          return cell.value;
        },
        fileName: 'resource-usage',
        sheetName: 'resource-usage',
        rowHeight: 20,
        columnKeys: keys
      };
      
      this.gridApi.exportDataAsExcel(params);
    },
    initializeLayoutProfile() {
      if (!Object.prototype.hasOwnProperty.call(this.layoutProfile, 'resourceColumns')) {
        this.layoutProfile.resourceColumns = [];
      }
    },
    async createLayoutProfile() {
      this.initializeLayoutProfile();
      await layoutProfileService.create([this.layoutProfile], this.entityId, this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.layoutProfile.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
    },
    updateLayoutProfile({ clearViewName=true } = {}) {
      if (!Object.prototype.hasOwnProperty.call(this.layoutProfile, 'uuId')) {
        // Dataviews are triggering the watchers when opening the
        // relevant tab and trying to save. Ignore those since nothing
        // has loaded yet.
        return;
      }
      
      // clear the view name from the breadcrumb
      if (clearViewName) {
        this.layoutProfile.resourceplanner_view_viewName = null;
        this.$store.dispatch("breadcrumb/clearView");
      }
      
      layoutProfileService.update([this.layoutProfile], this.entityId, this.userId)
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      })
    },
    loadColors(profile) {
      const self = this;
      self.coloring.none = profile ? profile.none : true;
      self.coloring.resource = profile ? profile.resource : false;
      self.coloring.event = profile ? profile.event : false;
      self.coloring.stage = profile ? profile.stage : false;
      self.coloring.project = profile ? profile.project : false;
    },
    async loadLayoutProfile() {
      const self = this;
      const userId = this.isWidget && this.widgetOwner ? this.widgetOwner : this.userId;
      const profileData = await layoutProfileService.list(this.entityId, userId).then((response) => {
        return response.data[response.data.jobCase];
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
      
      if (profileData.length === 0) {
        await self.createLayoutProfile();
        self.useDefault = true; // load the default view, if the views are not loaded yet
        const defaultView = this.resourceViews.find(view => view.defaultView);
        if (defaultView) {
          this.loadViewSettings(defaultView);
        }
      } else {
        self.layoutProfile = profileData[0];
        self.initializeLayoutProfile();
        self.loadColors(self.layoutProfile.resourceplanner_view_coloring);
        self.expandLevels();

        // Handle upgrading the displayed filter so that it shows [Is] by default
        const filter = self.layoutProfile.resourceplanner_view_filter ? self.layoutProfile.resourceplanner_view_filter : [];    
        if (filter) {
          for (const f of filter) {
            if (f.name.indexOf(']:') === -1) {
              const idx = f.name.indexOf(':');
              if (idx !== -1) {
                f.name = f.name.substr(0, idx) + ' [Is]' + f.name.substr(idx, f.name.length);
              }
            }
          }
        }
        self.badgeFilters = filter;
        self.lockPlanner = typeof this.layoutProfile.lockPlanner !== 'undefined' ? this.layoutProfile.lockPlanner : false;
        self.searchPinned = typeof self.layoutProfile[self.getProfileEntryName('searchPinned')] !== 'undefined' ? self.layoutProfile[self.getProfileEntryName('searchPinned')] : false;
        self.filterPinned = typeof self.layoutProfile[self.getProfileEntryName('filterPinned')] !== 'undefined' ? self.layoutProfile[self.getProfileEntryName('filterPinned')] : false;
        
        self.filterText = this.layoutProfile.resourceFilterText ? this.layoutProfile.resourceFilterText : '';
        self.filterValue = self.filterText;
        const startHour = Math.floor(self.durationConversionOpts.startHour / 1000 / 60 / 60);
        const closeHour = Math.floor(self.durationConversionOpts.closeHour / 1000 / 60 / 60);
        self.show = self.layoutProfile.resourceplanner_view_show ? self.layoutProfile.resourceplanner_view_show : {
          activity: true,
          booking: true,
          task: true,
          vacation: true,
          required: true,
          available: false,
          usageTasks: true,
          usageBookings: true,
          usageActivities: true,
          staff_metrics: true,
          displayFilteredMetrics: false,
          work_hours: { first_hour: startHour, last_hour: closeHour },
          hideWeekends: false,
          hideNonWorking: true,
          weekNumbers: false,
          combineLike: false
        };
        
        self.showGeneric = self.layoutProfile.showGeneric;
        
        if (typeof self.show.required === 'undefined') {
          self.show.required = true;
        }
        if (typeof self.show.available === 'undefined') {
          self.show.available = false;
        }
        if (typeof self.show.staff_metrics === 'undefined') {
          self.show.staff_metrics = true;
        }
        if (typeof self.show.displayFilteredMetrics === 'undefined') {
          self.show.displayFilteredMetrics = false;
        }
        if (typeof self.show.usageTasks === 'undefined') {
          self.show.usageTasks = true;
        }
        if (typeof self.show.usageBookings === 'undefined') {
          self.show.usageBookings = true;
        }
        if (typeof self.show.usageActivities === 'undefined') {
          self.show.usageActivities = true;
        }
        if (!self.show.work_hours) {
          const startHour = Math.floor(self.durationConversionOpts.startHour / 1000 / 60 / 60);
          const closeHour = Math.floor(self.durationConversionOpts.closeHour / 1000 / 60 / 60);
          self.show.work_hours = { first_hour: startHour, last_hour: closeHour };
        }
        if (typeof self.show.hideWeekends === 'undefined') {
          self.show.hideWeekends = false;
        }
        if (typeof self.show.hideNonWorking === 'undefined') {
          self.show.hideNonWorking = true;
        }
        if (typeof self.show.weekNumbers === 'undefined') {
          self.show.weekNumbers = false;
        }
        if (typeof self.show.combineLike === 'undefined') {
          self.show.combineLike = false;
        }
        
        this.eventTypeValues = self.layoutProfile.resourceplanner_view_typevalues ? self.layoutProfile.resourceplanner_view_typevalues : {
          activityName: null,
          taskName: null,
          vacationName: null
        };
        
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'resourceDates')) {
          self.$set(self, 'dates', self.layoutProfile['resourceDates']);
          self.$set(self, 'datesStr', self.dates == null? 'null' : self.dates);
          if (self.layoutProfile.resourceDates == null) {
            // Null means custom date. Use recorded range.
            try {
              self.$set(self, 'startDate', self.layoutProfile['resourceStartDate']);
              self.$set(self, 'endDate', self.layoutProfile['resourceEndDate']);
            } catch (e) {
              console.log("Invalid date range on restore. Using default"); // eslint-disable-line no-console
              self.datesChanged();
            }
          } else {
            // Range is calculated
            self.datesChanged();
          }
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'resourceTimescale')) {
          self.$set(self, 'span', self.layoutProfile['resourceTimescale']);
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'resourceAlloc')) {
          self.$set(self, 'resourceAlloc', self.layoutProfile['resourceAlloc']); 
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'grouping')) {
          self.$set(self, 'grouping', self.layoutProfile['grouping']);
          this.setColumnDefs();
        }
        
        if (self.layoutProfile.resourceplanner_view_viewName) {
          this.$store.dispatch("breadcrumb/updateView", self.layoutProfile.resourceplanner_view_viewName, { root: true });
        }
      }
    },
    onAllocOver() {
      this.$refs.alloc.visible = true;
    },
    onAllocLeave() {
      this.$refs.alloc.visible = false;
    },
    onCostOver() {
      this.$refs.cost.style.visibility = 'visible';
    },
    onCostClick() {
      if (this.$refs.cost.style.visibility === 'hidden' ||
          this.$refs.cost.style.visibility === '') {
        this.$refs.cost.style.visibility = 'visible';
      }
      else {
        this.$refs.cost.style.visibility = 'hidden';
      }
    },
    onCostLeave() {
      this.$refs.cost.style.visibility = 'hidden';
    },
    onDurationOver() {
      this.$refs.duration.style.visibility = 'visible';
    },
    onDurationClick() {
      if (this.$refs.duration.style.visibility === 'hidden' ||
          this.$refs.duration.style.visibility === '') {
        this.$refs.duration.style.visibility = 'visible';
      }
      else {
        this.$refs.duration.style.visibility = 'hidden'
      }
    },
    onDurationLeave() {
      this.$refs.duration.style.visibility = 'hidden';
    },
    onGroupingOver() {
      this.$refs.grouping.visible = true;
    },
    onGroupingLeave() {
      this.$refs.grouping.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    onGroupChange(event, updateLevel) {
            
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('staff.progress.filtering');

      setTimeout(() => {
        this.holdUpdateUntilTreeDataChanged = true;
        if (updateLevel) {
          //Signal scheduler not to call processTreeNodes due to the change of expandLevel. 
          //processTreeNodes will be called once when treeData is changed.
          //To prevent duplicated call
          // this.skipProcessNodes = true; 
          
          if (event) {
            this.expandLevel++;
          }
          else {
            this.expandLevel--;
          }
        }
        this.layoutProfile['grouping'] = this.grouping;
        this.updateLayoutProfile();

        //When group change, reset folderState
        this.folderState = {}

        if (this.grouping.staff || this.showGeneric) {
          this.needRedrawViewport = true;
        }
        this.updateGrid();
      }, 500);
      this.setColumnDefs();
    }, 
    onShowChange(value, apply = true) {
      this.show[value] = !this.show[value];
      this.layoutProfile['resourceplanner_view_show'] = this.show;
      
      if (value === 'required') {
        this.show['available'] = !this.show[value];
      }
      
      if (value === 'available') {
        this.show['required'] = !this.show[value];
      }
      
 
      if (apply) {
  
        this.inProgressShow = true;
        this.inProgressLabel = this.$t('staff.progress.filtering');
        
        setTimeout(() => {
          this.updateLayoutProfile();
          this.assignData();
        }, 500);
      }
    },
    dateChange({date, initialized}) {
      if (!initialized ||
          this.loadingView) {
        return;
      }
      
      // const startDate = date.toISOString().split('T')[0];
      //Provided date is a local date time. So get the literal date value.
      const startDate = `${date.getFullYear()}-${date.getMonth() < 9? '0'+(date.getMonth()+1): (date.getMonth()+1)}-${date.getDate() < 10? '0'+date.getDate() : date.getDate()}`;
      if (this.startDate !== startDate) {
        // get the difference
        const start = moment(this.startDate);
        const end = moment(this.endDate);
        const diff = end.unix() - start.unix();
        const d = moment(startDate).utc();
        const newEnd = moment((d.unix() + diff) * 1000);
        // increment the end date by the difference
        this.endDate = newEnd.format("YYYY-MM-DD");
        this.startDate = startDate;
        
        
        const self = this;
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(()=> {
          // get the new data
          self.updateGrid();
          self.updateTimeout = null;
        }, 100);
      }
    },
    addStaffEntries(data, staff, resource) {
      const resStaff = staff.filter(s => (!this.grouping.company || s.companyList.findIndex(c => c.uuId === resource.companyUuId) !== -1) &&
                                         (!this.grouping.location || s.locationList.findIndex(l => l.uuId === resource.locationUuId) !== -1) &&
                                         (!this.grouping.department || (s.departmentList && s.departmentList.findIndex(r => r.uuId === resource.departmentUuId || (this.show.combineLike && resource.departmentName === r.name)) !== -1)) &&
                                         (!this.grouping.skill || (s.skillList.length === 0 && !resource.skillUuId) || s.skillList.findIndex(s => s.uuId === resource.skillUuId) !== -1) &&
                                         ((this.showGeneric && s.generic) || (this.grouping.staff && !s.generic)));
      for (const stf of resStaff) {
        const s = cloneDeep(stf);
        s.companyUuId = resource.companyUuId;
        s.locationUuId = resource.locationUuId;
        s.departmentName = resource.departmentName;
        s.departmentUuId = resource.departmentUuId;
        s.skillUuId = resource.skillUuId;
        s.resourceUuId = resource.uuId;
        data.push(s);
      }
      return data;
    },
    addStaffForResource(data, resource) {
      const staff = staffs[resource.uuId];
      if (staff) {
        data = this.addStaffEntries(data, staff, resource);
      }
      return data;
    },
    assignData() {
      const self = this;
      let data = [];
      this.maxLevel = 0;
      
      if (this.grouping.resource) {
        data.push(...resourceData.filter(r => (this.isDataView && this.resourceIds !== null && this.resourceIds.includes(r.uuId)) || !this.isDataView));
      }
      
      const resourceList = cloneDeep(resourceData);
      
      if (this.grouping.staff ||
          this.showGeneric) {
        // add the staff
        for (const resource of resourceList) {
          data = self.addStaffForResource(data, resource);
        }
      }
            
      if (this.grouping.company) {
        var count = data.length;
        const companyList = resourceList.map(s => { return s.companyUuId });
        for (const key of Object.keys(companies)) {
          if (companyList.includes(companies[key].companyUuId)) {
            delete companies[key].children;
            data.push(companies[key]);
          }
        }
        
        if (data.length !== count) {
          this.maxLevel++;
        }
      }
      
      if (this.grouping.location) {
        var countLocation = data.length;
        const locationList = resourceList.reduce((list, s) => { 
          if (s.locationList) {
            for (const loc of s.locationList) {
              list.push({ company: s.companyUuId, location: loc.uuId });
            }
          }
          return list;
        }, []);
        for (const key of Object.keys(locations)) {
          if (locationList.findIndex(l => l.location === locations[key].locationUuId && l.company === locations[key].companyUuId) !== -1 &&
                (this.grouping.company || data.findIndex(d => d.type === 'location' && d.locationUuId === locations[key].locationUuId) === -1)) {
            if (self.grouping.company) {
              delete locations[key].children;
              data.push(locations[key]);
            }
            else {
              let index = data.findIndex(loc => loc.locationUuId === locations[key].locationUuId &&
                                 loc.type === 'location');
              if (index === -1) {
                delete locations[key].children;
                data.push(locations[key]);
              }
              else {
                // clone the object and combine the values
                const cloned = self.cloneLocation(data[index]);
                data[index] = cloned;
              }
            }
          }
          
          for (const resource of resourceList) {
            // if the resource is in more than 1 location we will need to add them for each
            for (let i = 1; i < resource.locationList.length; i++) {
              const resourceLoc = self.cloneResource(resource);
              resourceLoc.locationUuId = resource.locationList[i].uuId;
              resourceLoc.locationName = resource.locationList[i].name;
              const index = data.findIndex(d => d.type === 'resource' && 
                                           d.uuId === resourceLoc.uuId &&
                                         (!self.grouping.company || d.companyUuId === resourceLoc.companyUuId) &&
                                         d.locationUuId === resourceLoc.locationUuId);
              if (index === -1) {
                data.push(resourceLoc);
                data = self.addStaffForResource(data, resourceLoc);
              }
            }
          }
        }
        if (data.length !== countLocation) {
          this.maxLevel++;
        }
      }
      
      if (this.grouping.department) {
        var count1 = data.length;
        const deptList = resourceList.reduce((list, s) => { 
          if (s.departmentList) {
            for (const dept of s.departmentList) {
              list.push({ company: s.companyUuId, department: dept.uuId, location: s.locationUuId });
            }
          }
          return list;
        }, []);
        for (const key of Object.keys(departments)) {
          const dept = departments[key];
          if (deptList.findIndex(stf => (!self.grouping.company || dept.companyUuId === stf.company) &&
                                 (!self.grouping.location || dept.locationUuId === stf.location) &&
                                 (!self.grouping.department || dept.departmentUuId === stf.department)) !== -1) {
            let index = data.findIndex(d => d.type === 'department' && 
                                       (d.departmentUuId === dept.departmentUuId ||
                                       (this.show.combineLike && d.name === dept.name)));
            if (index === -1) {
              delete dept.children;
              data.push(dept);
            }
            else {
              // clone the object and combine the values
              const cloned = self.cloneDept(data[index]);
              data[index] = cloned;
            }
          }
        }
      
        
        if (!this.grouping.skills && 
            (this.grouping.staff || this.showGeneric)) {
          for (const dresource of resourceList) {
            // if the resource is in more than 1 department we will need to add them for each
            if (dresource.departmentList) {
              for (let i = 1; i < dresource.departmentList.length; i++) {
                //if (dresource.companyUuId === dresource.departmentList[i].companyUuid) {
                  const resourceDept = self.cloneResource(dresource);
                  resourceDept.departmentUuId = this.show.combineLike ? this.getDepartmentUuId(dresource.departmentList[i].name, dresource.departmentList[i].uuId) : dresource.departmentList[i].uuId;
                  resourceDept.departmentName = dresource.departmentList[i].name;
                  const index = data.findIndex(d => d.type === 'resource' && 
                                             ((!self.grouping.skill || !this.show.combineLike) && d.skillUuId === resourceDept.skillUuId) && 
                                             (!self.grouping.company || d.companyUuId === resourceDept.companyUuId) &&
                                             (!self.grouping.location || d.locationUuId === resourceDept.locationUuId) &&
                                             d.departmentUuId === resourceDept.departmentUuId);
                  if (index === -1) {
                    data.push(resourceDept);
                    data = self.addStaffForResource(data, resourceDept);
                  }
                //}
              }
            }
          }
        }
        if (data.length !== count1) {
          this.maxLevel++;
        }
      }
      
      if (this.grouping.skills) {
        var count2 = data.length;
        let uniqueSkills = {};
        const skillList = resourceList.reduce((list, s) => { 
          if (s.departmentList && s.departmentList.length <= 1) {
            for (const skill of s.skillList) {
              list.push({ skillUuId: skill.uuId, companyUuId: s.companyUuId, departmentUuId: s.departmentUuId, locationUuId: s.locationUuId });
            }
          }
          else if (s.departmentList) {
            for (const dept of s.departmentList) {
              for (const skill of s.skillList) {
                list.push({ skillUuId: skill.uuId, companyUuId: s.companyUuId, departmentUuId: dept.uuId, locationUuId: s.locationUuId });
              }
            }
          }
          return list;
        }, []);
        for (const skillkey of Object.keys(skills)) {
          if (this.grouping.department) {
            if (skillList.findIndex(s => s.skillUuId === skills[skillkey].skillUuId && 
                                         s.companyUuId === skills[skillkey].companyUuId &&
                                         s.locationUuId === skills[skillkey].locationUuId &&
                                         s.departmentUuId === skills[skillkey].departmentUuId) !== -1) {
              if (this.grouping.location) {
                // we will use location to create a unique id
                const index = data.findIndex(d => d.type === 'skill' && d.skillUuId === skills[skillkey].skillUuId && 
                                           (!self.grouping.company || d.companyUuId === skills[skillkey].companyUuId) &&
                                           (!self.grouping.location || d.locationUuId === skills[skillkey].locationUuId) &&
                                           d.departmentUuId === skills[skillkey].departmentUuId);
                if (index === -1) {
                  delete skills[skillkey].children;
                  data.push(skills[skillkey]);
                }
                else {
                  // combine the values
                  const cloned = self.cloneSkill(data[index]);
                  data[index] = cloned;
                }
              }
              else {
                const index = data.findIndex(d => d.type === 'skill' && d.skillUuId === skills[skillkey].skillUuId && 
                                           (!self.grouping.company || d.companyUuId === skills[skillkey].companyUuId) &&
                                           d.departmentUuId === skills[skillkey].departmentUuId);
                if (index === -1) {
                  delete skills[skillkey].children;
                  data.push(skills[skillkey]);
                }
                else {
                  // combine the values
                  const cloned = self.cloneSkill(data[index]);
                  data[index] = cloned;
                }
              }
            }
          }
          else if (this.grouping.location) {
            if (skillList.findIndex(s => s.skillUuId === skills[skillkey].skillUuId && 
                                         s.companyUuId === skills[skillkey].companyUuId &&
                                         s.locationUuId === skills[skillkey].locationUuId) !== -1) {
              const index = data.findIndex(d => d.type === 'skill' && d.skillUuId === skills[skillkey].skillUuId && 
                                         d.companyUuId === skills[skillkey].companyUuId &&
                                         d.locationUuId === skills[skillkey].locationUuId);
              if (index === -1) {
                delete skills[skillkey].children;
                data.push(skills[skillkey]);
              }
              else {
                // combine the values
                const cloned = self.cloneSkill(data[index]);
                data[index] = cloned;
              }
            }
          }
          else if (skillList.findIndex(s => s.skillUuId === skills[skillkey].skillUuId &&
                                       (!self.grouping.company || skills[skillkey].companyUuId === s.companyUuId)) !== -1) {
            const id = this.getRowId({ data: skills[skillkey] });
            if (!(id in uniqueSkills)) {
              uniqueSkills[id] = self.cloneSkill(skills[skillkey]);
            }
          }
        }
        for (const key of Object.keys(uniqueSkills)) {
          delete uniqueSkills[key].children;
          data.push(uniqueSkills[key]);
        }
        
        if (this.grouping.resource || this.showGeneric) {
          for (const resource of resourceList) {
            // if the staff is in more than 1 department we will need to add them for each
            if (this.grouping.department &&
                resource.departmentList.length > 1) {
              for (let d = 1; d < resource.departmentList.length; d++) {
                for (let i = 0; i < resource.skillList.length; i++) {
                  const resourceSkill = self.cloneResource(resource);
                  resourceSkill.departmentUuId = resource.departmentList[d].uuId;
                  resourceSkill.departmentName = resource.departmentList[d].name;
                  resourceSkill.skillUuId = resource.skillList[i].uuId;
                  resourceSkill.skillsName = resource.skillList[i].name;
                  if (-1 === data.findIndex(d => d.uuId === resourceSkill.uuId &&
                                                   d.companyUuId === resourceSkill.companyUuId &&
                                                   d.locationUuId === resourceSkill.locationUuId &&
                                                   d.departmentUuId === resourceSkill.departmentUuId &&
                                                   d.skillUuId === resourceSkill.skillUuId)) {
                    data.push(resourceSkill);
                    data = self.addStaffForResource(data, resourceSkill);
                  }
                }
              }
            }
            
            // Add the resource's other skills for their first department
            for (let i = 1; i < resource.skillList.length; i++) {
              const resourceSkill = self.cloneResource(resource);
              resourceSkill.skillUuId = resource.skillList[i].uuId;
              resourceSkill.skillsName = resource.skillList[i].name;
              if (-1 === data.findIndex(d => d.uuId === resourceSkill.uuId &&
                                                   d.companyUuId === resourceSkill.companyUuId &&
                                                   d.locationUuId === resourceSkill.locationUuId &&
                                                   d.departmentUuId === resourceSkill.departmentUuId &&
                                                   d.skillUuId === resourceSkill.skillUuId)) {
                data.push(resourceSkill);
                data = self.addStaffForResource(data, resourceSkill);
              }
            }
          }
        }
        if (data.length !== count2) {
          this.maxLevel++;
        }
      }
      
      // Add one more level when either showGeneric or grouping staff is true
      if (this.showGeneric || this.grouping.staff) {
        this.maxLevel++
      }
      
      if (this.expandLevel > this.maxLevel) {
        this.expandLevel = this.maxLevel;
      } 
      
      this.setSchedulerData(data);
      this.setFilterFieldValues();
    },
    getDepartmentUuId(name, uuId) {
      if (name in departmentUuIds) {
        return departmentUuIds[name];
      }
      departmentUuIds[name] = uuId;
      return uuId;
    },
    cloneLocation(location) {
      return {
        company: location.company,
        companyUuId: location.companyUuId,
        locationUuId: location.locationUuId,
        name: location.name,
        type: location.type,
        totalDuration: location.totalDuration,
        w: location.w
      };
    },
    cloneDept(dept) {
      return {
        company: dept.company,
        companyUuId: dept.companyUuId,
        departmentUuId: dept.departmentUuId,
        location: dept.location,
        locationUuId: dept.locationUuId,
        name: dept.name,
        type: dept.type,
        totalDuration: dept.totalDuration,
        w: dept.w
      };
    },
    cloneSkill(skill) {
      return {
        company: skill.company,
        companyUuId: skill.companyUuId,
        departmentUuId: skill.departmentUuId,
        location: skill.location,
        locationUuId: skill.locationUuId,
        skill: skill.skill,
        skillUuId: skill.skillUuId,
        name: skill.name,
        type: skill.type,
        totalDuration: skill.totalDuration,
        w: skill.w
      };
    },
    cloneResource(resource) {
      const customFieldList = {};
      if (Array.isArray(this.customFields) && this.customFields.length > 0) {
        for (const c of this.customFields) {
          if (Object.hasOwn(resource, c.name)) {
            customFieldList[c.name] = resource[c.name];
          }
        }
      }
      return {
        name: resource.name,
        calendars: resource.calendars,
        company: resource.company,
        companyUuId: resource.companyUuId,
        generic: resource.generic,
        location: resource.location,
        locationUuId: resource.locationUuId,
        department: resource.department,
        departmentUuId: resource.departmentUuId,
        perhourCost: resource.perhourCost,
        skill: resource.skills,
        skillUuId: resource.skillUuId,
        taskList: resource.taskList,
        uuId: resource.uuId,
        type: resource.type,
        ...customFieldList
      };
    },
    processNodes() {
      this.maxLevel = 0;
      if (this.gridApi == null) {
        return;
      }
      //Fixed #897, use setTimeout to make sure grid node is refreshed.
      setTimeout(() => {
        this.isTaskExpandedByActionBar = true;
        if (this.gridApi != null) {
          this.gridApi.forEachNode(node => {
            if (node.level < this.expandLevel) {
              node.expanded = true;
            } else if (node.allChildrenCount && node.allChildrenCount > 0) {
              node.expanded = false;
            }
          
            
            // update the max level
            if (node.level > this.maxLevel) {
              this.maxLevel = node.level;
            }
          });
          this.gridApi.onGroupExpandedOrCollapsed();
        }
        
        if (this.maxLevel < this.expandLevel) {
          this.gridOptions.groupDefaultExpanded = this.expandLevel = this.maxLevel;
        }

        //setTimeout is used to wait the onRowGroupOpened events finish their process.
        setTimeout(() => {
          this.isTaskExpandedByActionBar = false;
        }, 300);

      }, 50)
    },
    collapse() {
      this.expandLevel--;
      this.processNodes();
      // save
      this.layoutProfile.resourceExpandLevel = this.expandLevel;
      this.updateLayoutProfile();
    },
    expand() {
      this.expandLevel++;
      this.processNodes();
      // save
      this.layoutProfile.resourceExpandLevel = this.expandLevel;
      this.updateLayoutProfile();

      //Issue: The vertical scroll position in RHS timeline is out of sync after performing expand, collapse and expand.
      //Set flag to set RHS timeline scroll position again in schedulerDataRender()
      this.needScroll = true;
    },
    onFilterSubmit() {
      this.changeFilter(this.filterText);
    },
    onUnders() {
      this.showUnders = !this.showUnders;
      this.layoutProfile['showUnders'] = this.showUnders;
      this.updateLayoutProfile();
    },
    onOptimal() {
      this.showOptimal = !this.showOptimal;
      this.layoutProfile['showOptimal'] = this.showOptimal;
      this.updateLayoutProfile();
    },
    onOvers() {
      this.showOvers = !this.showOvers;
      this.layoutProfile['showOvers'] = this.showOvers;
      this.updateLayoutProfile();
    },
    gotoDaily(value) {
      this.span = 'Daily';
      this.showColumn = value;
      this.updateGrid();
    },
    cleanup() {
      resourceData = [];
      companies = {};
      locations = {};
      departments = {};
      skills = {};
      activities = {};
      bookings = {};
    },
    taskSelectOk({taskUuid, projectUuid}) {
      this.taskEdit.uuId = taskUuid;
      this.taskEdit.projectUuid = projectUuid;
      this.taskEditShow = true;
    },
    getUuid(data) {
      if (data.type === 'task') {
        return data.tu;
      }
      else if (data.type === 'company') {
        return data.companyUuId;
      }
      else if (data.type === 'location') {
        return data.locationUuId;
      }
      else if (data.type === 'department') {
        return data.departmentUuId;
      }
      else if (data.type === 'skill') {
        return data.skillUuId;
      }
      return data.uuId;
    },
    async eventUpdated(evts) {
      const self = this;
      let trigger = TRIGGERS.START_TIME;
      
      for (const evt of evts) {
        const orig = evt.orig;
        const changed = evt.changed;
        const name = changed.text;
        const eventId = typeof changed.id === 'string' ? changed.id.slice(changed.id.length - 36) : changed.id;
        
        // We need UTC dates for the update
        let startHour = changed.start_date.getHours();
        let startMinutes = this.roundMinutes(changed.start_date.getMinutes());
        if (startMinutes >= 60) {
          startMinutes = 0;
          startHour++;
        }
        let endHour = changed.end_date.getHours();
        let endMinutes = this.roundMinutes(changed.end_date.getMinutes());
        if (endMinutes >= 60) {
          endMinutes = 0;
          endHour++;
        }
        const utcStart = Date.UTC(changed.start_date.getFullYear(), changed.start_date.getMonth(),
                  changed.start_date.getDate(), startHour,
                  startMinutes, changed.start_date.getSeconds());
        const utcEnd = Date.UTC(changed.end_date.getFullYear(), changed.end_date.getMonth(),
                  changed.end_date.getDate(), endHour,
                  endMinutes, changed.end_date.getSeconds());
        if (typeof orig.id !== 'number') { // our ids are strings, new events are numbers
          if ((orig.end_date.getTime() !== changed.end_date.getTime() ||
            orig.start_date.getTime() !== changed.start_date.getTime()) ||
            orig.resourceUuid !== changed.resourceUuid) {
            const d = moment(utcStart).utc();
            const end = moment(utcEnd).utc();
            if (orig.end_date.getTime() !== changed.end_date.getTime() &&
                (evt.mode === 'resize' || !isWorkingDay(DEFAULT_CALENDAR, moment(utcEnd)))) {
              trigger = TRIGGERS.CLOSE_TIME;
            }
            this.creatingEvent = false;
            if (!this.updateEvent) {
              this.updateEvent = [];
            }
            
          
            self.updateEvent.push({
              id: eventId,
              name: name,
              type: orig.type,
              startDate: d.format('YYYY-MM-DD'),
              startTime: d.format('HH:mm'),
              closeDate: end.format('YYYY-MM-DD'),
              closeTime: end.format('HH:mm'),
              resourceUuid: evt.changed.resourceUuid,
              origResourceUuid: evt.orig.resourceUuid,
              duration: evt.orig.te * 60,
              mode: evt.mode,
              asch: evt.orig.asch,
              trigger: trigger,
              lockDuration: evt.orig.lockDuration
            });
          }
        }
        else {
          this.creatingEvent = true;
          const d = moment(utcStart).utc();
          const end = moment(utcEnd).utc();
          self.newEvent = {
            id: eventId,
            name: self.newEvent.name ? self.newEvent.name : name,
            startDate: d.format('YYYY-MM-DD'),
            startTime: d.format('HH:mm'),
            closeDate: end.format('YYYY-MM-DD'),
            closeTime: end.format('HH:mm'),
            resourceUuid: evt.changed.resourceUuid,
            resourceName: resourceData.filter(s => s.uuId === evt.changed.resourceUuid)[0].name,
            description: null,
            projectBooking: self.newEvent.projectBooking, // for booking
            projectTask: self.newEvent.projectTask
          };
          this.newName = this.newEvent.name;
          this.promptType = true;
        }
      }
      
      if (!this.creatingEvent &&
          this.updateEvent.length > 0) {
        this.initDurationCalculation({ trigger: trigger });
      }
    },
    async createActivity(name, desc, startDate, startTime, endDate, endTime, duration, resourceUuid, color, stage, quantity, utilization=1, customFields) {
      const uuId = await activityService.create([{name: name, description: desc, startTime: convertDate(startDate, startTime), closeTime: convertDate(endDate, endTime), duration: duration, color: color, ...customFields }])
      .then((response) => {
        return response.data.feedbackList[0].uuId;
      });
      
      await activityLinkResourceService.create(uuId, [{ uuId: resourceUuid, utilization: utilization, quantity: quantity ? quantity : 1 }]);
      
      if (stage) {
        await activityLinkStageService.create(uuId, {uuId: stage});
      }
      return uuId;
    },
    async createTask(name, desc, startDate, startTime, endDate, endTime, duration, project, resourceUuid, color, stage, quantity, utilization=1, customFields) {
      const uuId = await taskService.create([{
        name: name, 
        description: desc, 
        startTime: convertDate(startDate, startTime), 
        closeTime: convertDate(endDate, endTime), 
        duration: duration, 
        taskType: 'Task', 
        constraintType: "As_soon_as_possible",
        autoScheduling: false,
        color: color !== "" ? color : null,
        ...customFields
      }], project)
      .then((response) => {
        return response.data.feedbackList[0].uuId;
      });
      await taskLinkResourceService.create(uuId, [{ uuId: resourceUuid, quantity: quantity ? quantity : 1, utilization: utilization }]);
      if (stage) {
        await taskLinkStageService.create(uuId, {uuId: stage});
      }
      return uuId;
    },
    async cloneTask(name, desc, startDate, startTime, endDate, endTime, duration, project, uuId, newResource, taskResource, quantity, utilization=1) {
      const data = {
        name: name, 
        description: desc, 
        startTime: convertDate(startDate, startTime), 
        closeTime: convertDate(endDate, endTime), 
        duration: duration,
        autoScheduling: false
      };
      
      const newUuid = await taskService.clone(
        data, 
        project, 
        uuId
      )
      .then((response) => {
        return response.data.jobClue.uuId;
      });
      
      data.uuId = newUuid;
      await taskService.update([data])
      .then(() => {
        return;
      })
      .catch(() => {
      
      });
      
      if (newResource !== taskResource) {
        await taskLinkResourceService.remove(newUuid, [{ uuId: taskResource }]);
        await taskLinkResourceService.create(newUuid, [{ uuId: newResource, quantity: quantity ? quantity : 1, utilization: utilization }]);
      }
      return newUuid;
    },
    async createBooking(name, desc, startDate, startTime, endDate, endTime, duration, resourceUuid, projectUuid, color, stage, quantity/**, utilization=1*/, customFields) {
      const uuId = await bookingService.create([{
        name: name, 
        color: color !== "" ? color : null,
        description: desc, 
        beginDate: convertDate(startDate, startTime),
        untilDate: convertDate(endDate, endTime),
        duration: duration,
        quantity: quantity,
        // utilization: utilization, //Disable this line due to /api/booking/add doesn't support this property.
        resource: {
          uuId: resourceUuid
        },
        project: {
          uuId: projectUuid
        },
        ...customFields
      }])
      .then((response) => {
        return response.data.feedbackList[0].uuId;
      });
      
      if (stage) {
        await bookingLinkStageService.create(uuId, {uuId: stage});
      }
      return uuId;
    },
    convertDate(date, time) {
      if (date != null) {
        const dateTime = moment.utc(date, 'YYYY-MM-DD');
        if (time != null) {
          const token = time.split(':');
          dateTime.hour(token[0]).minute(token[1]);
        }
        return dateTime.valueOf();
      }
    },
    updateTypeValues(event) {
      if (event.type === 'Activity') {
        this.eventTypeValues.activityName = this.newEvent.name;
        this.eventTypeValues.typeTab = 1;
      }
      else if (event.type === 'Task') {
        this.eventTypeValues.taskName = this.newEvent.name;
        this.eventTypeValues.typeTab = 2;
        if (event.projectTask) {
          this.eventTypeValues.projectTask = event.projectTask;
        }
      }
      else if (event.type === 'Vacation') {
        this.eventTypeValues.vacationName = this.newEvent.name;
        this.eventTypeValues.typeTab = 3;
      }
      else if (event.type === 'Booking') {
        this.eventTypeValues.typeTab = 0;
        if (event.projectBooking) {
          this.eventTypeValues.projectBooking = event.projectBooking;
        }
      }
      this.layoutProfile['resourceplanner_view_typevalues'] = this.eventTypeValues;
      this.updateLayoutProfile();
    },
    async onPromptTypeOk(event) {
      this.newEvent = event;
      let update = false;
      
      // save the names in the profile
      this.updateTypeValues(event);
      
      if (this.newEvent.type === 'Activity' ||
          this.newEvent.type === 'Booking' ||
          this.newEvent.type === 'Task') {
        this.initDurationCalculation({ trigger: TRIGGERS.CLOSE_TIME });
      }
      else if (this.newEvent.type === 'Vacation') {
        await this.createVacation(this.newEvent.name, 
                              this.newEvent.description,
                              this.newEvent.startDate, 
                              this.newEvent.startTime, 
                              this.newEvent.closeDate, 
                              this.newEvent.closeTime, 
                              this.newEvent.value, 
                              this.newEvent.staffUuid);
        update = true;
        this.callback = this.cleanupCreate;
      }
      
      if (update) {
        this.updateGrid();
      }
    },
    onPromptTypeCancel() {
      this.deleteEventId = this.newEvent.id;
    },
    checkSections(resource) {
      const events = resourceEvents[resource.uuId];
      if (events) {
        // make sure the section_id contains the resource key
        for (let i = 0; i < events.length; i++) {
          let arr = events[i].section_id ? events[i].section_id.split(',') : [];
          if (!arr.includes(resource.key)) {
            arr.push(resource.key);
            events[i].section_id = arr.join(',');
          }
        }
      }
    },
    getColor(obj) {
      if (this.coloring.resource &&
          obj.resourceColor) {
        return obj.resourceColor;
      }
      else if (this.coloring.event &&
          obj.eventColor) {
        return obj.eventColor;
      }
      else if (this.coloring.stage &&
          obj.stageColor) {
          return obj.stageColor;   
      }
      else if (this.coloring.project &&
          obj.projectColor) {
          return obj.projectColor;   
      }
      return obj.color;
    },
    getTextColor(obj) {
      if (this.coloring.resource &&
          obj.resourceColor) {
        return invertColor(obj.resourceColor, true);
      }
      else if (this.coloring.event &&
          obj.eventColor) {
        return invertColor(obj.eventColor, true);
      }
      else if (this.coloring.stage &&
          obj.stageColor) {
        return invertColor(obj.stageColor, true);
      }
      return obj.textColor;
    },
    getAllChildren(item, rowData) {
      let children = [];
      if (item.type === 'company') {
        children =  rowData.filter(f => f.companyUuId === item.uuId && f.uuId !== item.uuId && f.type === 'staff');
      }
      else if (item.type === 'location') {
        children =  rowData.filter(f => f.locationUuId === item.uuId && f.uuId !== item.uuId && f.type === 'staff');
      }
      else if (item.type === 'department') {
        children =  rowData.filter(f => f.departmentUuId === item.uuId && f.uuId !== item.uuId && f.type === 'staff');
      }
      else if (item.type === 'skill') {
        children =  rowData.filter(f => f.skillUuId === item.uuId && f.uuId !== item.uuId && f.type === 'staff');
      }
      else {
        children =  item.children;
      }
      // remove duplicates
      return children.filter((obj1, i, arr) => 
        arr.findIndex(obj2 => (obj2.uuId === obj1.uuId)) === i
      )
    },
    setSchedulerData(rowData) {
      const self = this;
      const arr = [];
      rowData.sort(function (a, b) {
        if (a.type !== 'resource' && b.type === 'resource') {
          return -1;
        }
        else if (a.type === 'resource' && b.type !== 'resource') {
          return 1;
        }
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      // Build the tree
      
      rowData.forEach(d => {

        delete d.children; // clear out the child items
        delete d.unfilteredChildren;
        d.uuId = self.getUuid(d);
        d.key = self.getRowId({data: d});
        d.path = self.getDataPath(d);
        d.folder = d.type !== 'staff' && d.type !== 'resource';
        d.label = d.name;
        d.open = self.folderState[d.key] || d.path.length <= self.layoutProfile.resourceExpandLevel;
        if (d.type === 'resource') {
          self.checkSections(d);
        }
        else if (d.type === 'staff') {
          const start = moment(d.begin).format('YYYY-MM-DD H:mm');
          const end = moment(d.until).format('YYYY-MM-DD H:mm');
          const staffEvent = { 
            type: 'resourceUnit',
            id: `${d.key}${d.resourceUuid}`,
            start_date: start, 
            end_date: end, 
            text: d.resourceName, 
            section_id: d.key, 
            readonly: true,
            utilization: d.utilization,
            quantity: d.quantity,
            staffUuid: d.uuId
          };
          staffEvent.dotcolor = self.getColor(staffEvent);
          staffEvent.textColor = self.getTextColor(staffEvent);
          arr.push(staffEvent);
        }
      });
      
      rowData.forEach(d => {
        if (d.type !== 'staff') { // staff have no children
          d.children = rowData.filter(
          r => r.path.length > 1 && r.path[r.path.length -2] === d.key
          )
          if (d.children.length === 0) {
            delete d.children;
          }
          else {
            d.unfilteredChildren = [...d.children];
            d.allChildren = this.getAllChildren(d, rowData);
            d.children.sort(function (a, b) {
              if (a.type !== 'resource' && b.type === 'resource') {
                return -1;
              }
              else if (a.type === 'resource' && b.type !== 'resource') {
                return 1;
              }
              return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
            });
          }
        }
      });
      
      const usagefilter = this.badgeFilters.find(f => f.field === 'usage');
      const availabilityfilter = this.badgeFilters.find(f => f.field === 'availability');
      for (let index = rowData.length - 1; index >= 0; index--) {
        const d = rowData[index];
        if (!filterCheck(d, this.badgeFilters.filter(f => f.field !== 'usage' && (typeof f.filterStaff === 'undefined' || f.filterStaff)), this.filterValue, resourceEvents, null) ||
            (usagefilter && !this.usageMatch(usagefilter, d)) ||
            (availabilityfilter &&  !this.usageMatch(availabilityfilter, d))) {
          rowData.splice(index, 1);
        }
      }
      
      for (let idx = rowData.length - 1; idx >= 0; idx--) {
        const d = rowData[idx];
        self.pruneChildren(idx, d, rowData);
      }

      // //Signal scheduler not to call processTreeNodes due to the change of expandLevel. 
      // //processTreeNodes will be called once when treeData is changed.
      // //To prevent duplicated call
      // this.skipProcessNodes = true; 
            
      // set the events for the scheduler
      for (const key of Object.keys(resourceEvents)) {
        const events = resourceEvents[key];
        for (const event of events) {
          if (event.section_id) {
            if (event.section_id.includes(',')) {
              // workaround for multisection event bug
              const sections = event.section_id.split(',');
              for (const section of sections) {
                const clonedEvent = { 
                  type: event.type,
                  id: `${section}${event.id}`,
                  te: event.te, 
                  pu: event.pu,
                  start_date: event.start_date, 
                  end_date: event.end_date, 
                  text: event.text, 
                  section_id: section, 
                  resourceUuid: event.resourceUuid,
                  dotcolor: self.getColor(event),
                  textColor: self.getTextColor(event),
                  readonly: event.readonly
                };
                if (clonedEvent.type === 'task') {
                  clonedEvent.lockDuration = event.lockDuration;
                }
                arr.push(clonedEvent);
              }
            }
            else {
              event.dotcolor = self.getColor(event);
              event.textColor = self.getTextColor(event);
              arr.push(event);
            }
          }
        }
      }
      
      if (this.expandLevel > this.maxLevel) {
        this.expandLevel = this.maxLevel;
      }
      else if (this.expandLevel === -1) {
        this.expandLevel = 0;
      }
      
      self.schedulerTasks = arr;
      this.pendingSetGridRowData = true;

      const staffList = rowData.filter(i => i.type === 'staff');
      
      if (staffList.length > 0) {
        //Generate parent path string for each staff
        for (const s of staffList) {
          s.parentPathStr = s.path.slice(0, s.path.length-1).join();
        }
        //Process staff and assign it to its respective resource as child.
        const resourceList = rowData.filter(i => i.type === 'resource');
        for (const r of resourceList) {
          if (!Array.isArray(r.staffList) || r.staffList.length == 0) {
            continue;
          }
          if (!Array.isArray(r.children)) {
            r.children = [];
          }
          const resourcePathString = r.path.slice(0, r.path.length-1).join();
          for (const sId of r.staffList) {
            const foundIdx = staffList.findIndex(i => i.uuId === sId && i.parentPathStr == resourcePathString);
            if (foundIdx > -1) {
              const found = staffList.splice(foundIdx, 1)[0];
              found.parentPathStr = null;
              r.children.push(found);
            }
          }
        }
        //Clean up (set null) parent path string property. 
        for (const s of staffList) {
          s.parentPathStr = null
        }
      }

      


      const filteredData = rowData.filter(f => f.path.length <= 1 && ((f.children && f.children.length !== 0) || f.type === 'resource' || f.type === 'staff')).sort(function (a, b) {
        if (a.type !== 'resource' && b.type === 'resource') {
          return -1;
        }
        else if (a.type === 'resource' && b.type !== 'resource') {
          return 1;
        }
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      rowData = [];
      const populateChildrenAsRow = (children, list) => {
        for (const c of children) {
          if (!list.find(l => l.key === c.key)) { //prevent duplicate nodes being added
            list.push(c);
            if (Array.isArray(c.children) && c.children.length > 0) {
              populateChildrenAsRow(c.children, list);
            }
          }
        }
      }
      populateChildrenAsRow(filteredData, rowData);
      
      this.rowData = rowData;
      if (this.gridReady === true) {
        this.gridApi.setGridOption('rowData', this.rowData);
        this.applySort();
      } else {
        this.rowDataReady = true;
      }
      
    },
    applySort() {
      const columns = this.gridApi.getAllDisplayedColumns();
      const curSortColumns = columns.filter(i => i.sort != null);
      let noDataFetchRequired = curSortColumns.length == 0;
      if (!noDataFetchRequired) {
        noDataFetchRequired = true; //assume true, and prove it false
        for (const c of curSortColumns) {
          if (!this.resourceFieldDataLoaded.includes(c.colId)) {
            noDataFetchRequired = false;
            break;
          }
        }
      }

      if (!noDataFetchRequired) {
        this.fetchRowDataForSortedColumn(this.gridApi, curSortColumns.map(i => i.colId));
      }
    },
    getEndJSDate(date) {
      if (this.schedulerSpan === 'day') {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
      }
      else if (this.schedulerSpan === 'week') {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 6, 23, 59, 59);
      }
      else if (this.schedulerSpan === 'month') {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }
      else if (this.schedulerSpan === 'year') {
        return new Date(date.getFullYear(), 11, 31);
      }
    },
    usageMatch(usagefilter, data) {
      var from = new Date(this.startDate),
        to = new Date(this.endDate);
      let ret = false;
      
      let runlen = 0;
      while(+from < +to){
        const projectId = this.grouping.project ? data.projectUuId : 
                        this.id;
        const quota = data.quota ? data.quota : 1;
        const w = this.calculateUsage(from, data, projectId);
        let workingHours = w && w.w ? w.w : 0;
        const availableHours = w && w.a ? w.a * quota : 0;
        if (availableHours - workingHours > 0) {
          runlen += (availableHours - workingHours) / 8;
        }
        else {
          runlen = 0;
        }
        const percent = Math.round(workingHours / availableHours * 100)
        if (Array.isArray(usagefilter.value) && usagefilter.value.find(v => v.text === this.$t('button.none')) && percent === 0) {
          ret = true;
          break;
        }
        else if (Array.isArray(usagefilter.value) && usagefilter.value.find(v => v.text === this.$t('button.unders')) && percent > 0 && percent <= 75) {
          ret = true;
          break;
        }
        else if (Array.isArray(usagefilter.value) && usagefilter.value.find(v => v.text === this.$t('button.optimal')) && percent >= 76 && percent <= 100) {
          ret = true;
          break;
        }
        else if (Array.isArray(usagefilter.value) && usagefilter.value.find(v => v.text === this.$t('button.overs')) && percent > 100) {
          ret = true;
          break;
        }
        else if (!Array.isArray(usagefilter.value) && data.type === 'resource' && parseInt(convertDisplayToDuration(usagefilter.value, this.durationConversionOpts).value/60/8) <= runlen) {
          const endDate = this.getEndJSDate(from);
          if ((data.begin - 86400000) > from.getTime() || // subtract 1 day so that the start date is shown as employed
                   data.until + 86400000 < endDate.getTime()) {
            // not employed        
          }
          else {
            ret = true;
          }
          break;
        }
        
        from = this.incrementDate(from);
      }
      
      // do not check usage on resource children
      if (data.children && data.type !== 'resource') {
        for (let c = data.children.length - 1; c >= 0; c--) {
          const child = data.children[c];
          if (this.usageMatch(usagefilter, child)) {
            ret = true;
          }
          else {
            data.children.splice(c, 1);
          }
        }
        
        if (data.children.length === 0) {
          delete data.children;
          ret = false;
        }
      }
      return ret;
    },
    incrementDate(date) {
      if (this.span === 'Daily') {
        date.setDate(date.getDate() + 1);
      }
      else if (this.span === 'Weekly') {
        date.setDate(date.getDate() + 7);
      }
      else if (this.span === 'Monthly') {
        date.setMonth(date.getMonth() + 1);
      }
      else if (this.span === 'Yearly') {
        date.setFullYear(date.getFullYear() + 1);
      }
      return date;
    },
    calculateUsage(date, section, projectId) {
      if (section.type === 'resource') {
        // We need UTC dates for the usage
        const utcDate = Date.UTC(date.getFullYear(), date.getMonth(),
                  date.getDate(), date.getHours(),
                  date.getMinutes(), date.getSeconds());
        const d = moment(utcDate).utc();
        const w = getUsage(section.calendars, 
                           d, 
                           this.schedulerSpan, 
                           this.getWorkList(section, projectId),
                           section.begin,
                           section.until,
                           section.quota,
                           this.staffAlloc,
                           section.perhourCost
                           )
        return w;
      }
      else if (section.unfilteredChildren) {
        const w = { a: 0, w: 0, h: 0, ha: 0, t: [] };
        for (const child of section.unfilteredChildren) {
          const result = this.calculateUsage(date, child, null);
          w.a += result.a;
          w.w += result.w;
          w.h += result.h;
          w.ha += result.ha;
          //w.t = this.combineTasks(w.t, result.t);
        }
        return w;
      }
      return { a: 0, w: 0, h: 0, ha: 0, t: [] };
    },
    getWorkList(section, projectId) {
      const ret = [];
      
      if (!projectId) {
        if (this.show.usageTasks && section.taskList) {
          if (this.grouping.project) {
            ret.push(...section.taskList.filter(t => section.key.includes(t.project)));
          }
          else {
            ret.push(...section.taskList);
          }
        }
        if (this.show.usageActivities && section.activityList) {
          ret.push(...section.activityList);
        }
        if (this.show.usageBookings && section.bookingList) {
          if (this.grouping.project) {
            ret.push(...section.bookingList.filter(b => section.key.includes(b.project)));
          }
          else {
            ret.push(...section.bookingList);
          }
        }
      }
      else {
        if (this.show.usageTasks) {
          ret.push(...section.taskList.filter(t => t.project === projectId));
        }
        if (this.show.usageActivities && section.activityList) {
          ret.push(...section.activityList);
        }
        if (this.show.usageBookings && section.bookingList) {
          ret.push(...section.bookingList.filter(t => t.project === projectId));
        }
      }
                              
      return ret;
    },
    addResource() {
      this.resourceId = `RESOURCE_NEW_${strRandom(5)}`;
      this.resourceShow = true;
    },
    getEndDate(d) {
      const date = d.clone();
      if (this.schedulerSpan === 'day') {
        const closeHour = Math.floor(this.durationConversionOpts.closeHour / 1000 / 60 / 60);
        date.set('hour', closeHour);
      }
      else if (this.schedulerSpan === 'week') {
        date.add(1, 'weeks');
      }
      else if (this.schedulerSpan === 'month') {
        date.add(1, 'months');
        date.subtract(1, 'days');
      }
      else if (this.schedulerSpan === 'year') {
        date.add(1, 'years');
      }
      return date;
    },
    addEvent() {
      this.creatingEvent = true;
      let section = null;
      let startDate = null;
      const selected = document.querySelector('.dhx_focus_slot');
      if (selected) {
        section = selected.getAttribute('data-section');
        if (resourceData.filter(s => s.uuId === section).length === 0) {
          // not a resource section
          section = null;
        }
        startDate = new Date(Date.parse(selected.getAttribute('data-start-date')));
      }
    
      const now = startDate ? moment(startDate) : moment();
      const end = this.getEndDate(now);
      this.newEvent = {
        name: this.newEvent.name ? this.newEvent.name : null,
        projectBooking: this.newEvent.projectBooking,
        projectTask: this.newEvent.projectTask,
        resourceUuid: section ? section : this.newEvent.resourceUuid ? this.newEvent.resourceUuid : null,
        resourceName: section ? resourceData.filter(s => s.uuId === section)[0].name : this.newEvent.resourceName ? this.newEvent.resourceName : null
      };
      const startTime = msToTime(this.durationConversionOpts.startHour);
      const closeTime = msToTime(this.durationConversionOpts.closeHour);
      this.newEvent.startDate = now.format('YYYY-MM-DD');
      this.newEvent.startTime = startTime;
      this.newEvent.closeDate = end.format('YYYY-MM-DD');
      this.newEvent.closeTime = closeTime;
      const { value: dValue } = convertDisplayToDuration('1D', this.durationConversionOpts);
      this.newEvent.value = dValue;
      this.promptType = true;
    },
    roundMinutes(minutes) {
      //focus on hour and minutes. 15 minutes as time interval.
      let delta = minutes % 15;
      if (delta < 8) {
        delta = 0;
      } else {
        delta = 1;
      }
      return (parseInt(minutes / 15) + delta) * 15;
    },
    editEvent(data) {
      this.schedulerClickItem({ id: data.uuId, data: data });
    },
    copyEvent(events) {
      this.copied = events;
    },
    async pasteEvent() {
      let section = null;
      let startDate = null;
      const selected = document.querySelector('.dhx_focus_slot');
      if (selected) {
        section = selected.getAttribute('data-section');
        const startHour = Math.floor(this.durationConversionOpts.startHour / 1000 / 60 / 60);
        const startMinute = this.durationConversionOpts.startHour / 1000 / 60 % 60;
        startDate = moment(selected.getAttribute('data-start-date')).set("hour", startHour).set("minute", startMinute);
      }
    
      for (const evtCopied of this.copied) {
        let utilization = 1;
        let quantity = 1;
        const evt = cloneDeep(evtCopied);
        const origUuid = evt.resourceUuid;
        if (section) {
          evt.resourceUuid = section.slice(section.length - 36);
        }
        
        let evtStart = evt.start_date;
        let evtEnd = evt.end_date;
        let uuId = null;
        
        // We need UTC dates for the creation
        if (startDate &&
            evtStart.getFullYear() !== 1970) {
          evtStart.setFullYear(startDate.year(), startDate.month(), startDate.date());
                  
          const convertDateToMoment = function(_date) {
            return moment.utc([
              _date.getFullYear()
              , _date.getMonth()
              , _date.getDate()
              , _date.getHours()
              , _date.getMinutes()
              , _date.getSeconds()
            ]);
          }
          
          /* eslint-disable no-undef */
          const schedulerSection = this.scheduler.getSection(section);
          /* eslint-enable no-undef */
          let calendar = null;
          if (schedulerSection.calendars) {
            const cals = [];
            const names = [ 'staff', 'location', 'base_calendar'];
            
            for (let i = schedulerSection.calendars.length - 1; i >= 0; i--) {
              const c = schedulerSection.calendars[i];
              cals.push({ name: names[i], calendarList: c });
            }
            calendar = transformCalendar(processCalendar(cals));
          }
          else {
            calendar = DEFAULT_CALENDAR;
          }
          
          let trigger = TRIGGERS.START_DATE;
          let projectScheduleFromStart = true;
          const startTime = convertDateToMoment(evtStart);
          const closeTime = convertDateToMoment(evtEnd);
          const payload = {
            trigger: trigger
            , startDateStr: startTime.format('YYYY-MM-DD')
            , startTimeStr: startTime.format('HH:mm')
            , closeDateStr: closeTime.format('YYYY-MM-DD')
            , closeTimeStr: closeTime.format('HH:mm')
            , durationDisplay: `${evt.te / 8}D`
            , calendar: calendar
            , projScheduleFromStart: projectScheduleFromStart
            , taskAutoScheduleMode: false
            , lockDuration: false
            , autoMoveForNonWorkingDay: false
            , skipOutOfProjectDateCheck: true
            , durationConversionOpts: this.durationConversionOpts
          }
          const result = calcDateTimeDurationv2(payload);
          evtEnd = convertDate(result.closeDateStr, result.closeTimeStr);
          evtEnd = new Date(evtEnd);
          // adjust for the timezone
          evtEnd = new Date(evtEnd.getTime() + (evtEnd.getTimezoneOffset() * 60000));
        }
        const utcStart = Date.UTC(evtStart.getFullYear(), evtStart.getMonth(),
                  evtStart.getDate(), evtStart.getHours(),
                  evtStart.getMinutes(), evtStart.getSeconds());
        const utcEnd = Date.UTC(evtEnd.getFullYear(), evtEnd.getMonth(),
                  evtEnd.getDate(), evtEnd.getHours(),
                  evtEnd.getMinutes(), evtEnd.getSeconds());
        const d = moment(utcStart).utc();
        const end = moment(utcEnd).utc();
        this.creatingEvent = true;
        const newEvent = {
          name: evt.name,
          projectBooking: this.newEvent.projectBooking,
          projectTask: this.newEvent.projectTask,
          startDate: d.format('YYYY-MM-DD'),
          startTime: d.format('HH:mm'),
          closeDate: end.format('YYYY-MM-DD'),
          closeTime: end.format('HH:mm')
        };
        
        if (evt.type === 'activity') {
          const activityId = evt.refId.slice(evt.refId.length - 36);
          const foundActivities = resourceSections[origUuid].activityList.filter(a => a.uuId === activityId);
          if (foundActivities.length > 0) {
            quantity = foundActivities[0].quantity;
            utilization = foundActivities[0].utilization;
          }
          uuId = await this.createActivity(evt.text, 
                              newEvent.description,
                              newEvent.startDate, 
                              newEvent.startTime, 
                              newEvent.closeDate, 
                              newEvent.closeTime, 
                              evt.te * 60, 
                              evt.resourceUuid,
                              evt.eventColor,
                              evt.stage,
                              quantity,
                              utilization,
                              {}/* customFields */);
          if (!this.show.activity) {
            this.promptShowActivity = true;
          }
        }
        else if (evt.type === 'booking') {
          const bookingId = evt.refId.slice(evt.refId.length - 36);
          const foundBookings = resourceSections[origUuid].bookingList.filter(a => a.uuId === bookingId);
          if (foundBookings.length > 0) {
            quantity = foundBookings[0].quantity;
            utilization = foundBookings[0].utilization;
          }
          uuId = await this.createBooking(evt.text, 
                              newEvent.description,
                              newEvent.startDate, 
                              newEvent.startTime, 
                              newEvent.closeDate, 
                              newEvent.closeTime, 
                              evt.te * 60, 
                              evt.resourceUuid,
                              evt.project,
                              evt.eventColor,
                              newEvent.stage,
                              quantity,
                              {}/* customFields */);
          if (!this.show.booking) {
            this.promptShowBooking = true;
          }
        }
        else if (evt.type === 'task') {
          const taskId = evt.refId.slice(evt.refId.length - 36);
          const foundTasks = resourceSections[origUuid].taskList.filter(a => a.uuId === taskId);
          if (foundTasks.length > 0) {
            quantity = foundTasks[0].quantity;
            utilization = foundTasks[0].utilization;
          }
          uuId = await this.cloneTask(newEvent.name, 
                              newEvent.description,
                              newEvent.startDate, 
                              newEvent.startTime, 
                              newEvent.closeDate, 
                              newEvent.closeTime, 
                              evt.te * 60, 
                              evt.pu,
                              taskId,
                              evt.resourceUuid,
                              evt.section_id.slice(evt.section_id.length - 36),
                              quantity,
                              utilization);
          if (!this.show.task) {
            this.promptShowTask = true;
          }
        }
        this.processAdd({
          uuId: uuId,
          name: evt.text,
          shortName: evt.name ? evt.name : evt.text,
          type: evt.type,
          startDate: newEvent.startDate,
          startTime: newEvent.startTime,
          closeDate: newEvent.closeDate,
          closeTime: newEvent.closeTime,
          resourceUuid: evt.resourceUuid,
          projectColor: evt.projectColor,
          projectTextColor: evt.projectTextColor,
          resourceColor: evt.resourceColor,
          resourceTextColor: evt.resourceTextColor,
          stageColor: evt.stageColor,
          stageTextColor: evt.stageTextColor,
          stageName: evt.stageName,
          stage: evt.stage,
          value: evt.te * 60,
          eventColor: evt.eventColor,
          projectTask: evt.type === 'task' ? [{ uuId: evt.pu, name: entityList[evt.pu].name }] : null,
          projectBooking: evt.type === 'booking' ? [{ uuId: evt.project, name: entityList[evt.project].name }] : null,
          calendar_type: evt.calendar_type,
          quantity: quantity
        });
      }
    },
    schedulerClickItem({id, data}) {
      const eventId = typeof id === 'string' ? id.slice(id.length - 36) : id;
      this.openDetail(eventId, { data: data });
    },
    setFilterFieldValues() {
            
      const resourceList = [];
      const tagList = [];
      for (const resourcekey of Object.keys(resourceData)) {
        if (!resourceList.includes(resourceData[resourcekey].name)) {
          resourceList.push({ text: resourceData[resourcekey].name });
        }
        if (resourceData[resourcekey].tagList) {
          for (const tag of resourceData[resourcekey].tagList) {
            if (-1 === tagList.findIndex(i => i.text === tag.name)) {
              tagList.push({ text: tag.name });
            }
          }
        }
      }
      
      const taskList = [];
      const stageList = [];
      const projectList = [];
      for (const tkey of Object.keys(tasks)) {
        if (-1 === taskList.findIndex(i => i.text === tasks[tkey].name)) {
          taskList.push({ text: tasks[tkey].name });
        }
        if (tasks[tkey].stageName && -1 === stageList.findIndex(i => i.text === tasks[tkey].stageName)) {
          stageList.push({ text: tasks[tkey].stageName });
        }
        
        if (-1 === projectList.findIndex(i => i.text === tasks[tkey].pn)) {
          projectList.push({ text: tasks[tkey].pn });
        }
      }
      
      const bookingList = [];
      for (const bkey of Object.keys(bookings)) {
        if (-1 === bookingList.findIndex(i => i.text === bookings[bkey].name)) {
          bookingList.push({ text: bookings[bkey].name });
        }
        if (bookings[bkey].stageName && -1 === stageList.findIndex(i => i.text === bookings[bkey].stageName)) {
          stageList.push({ text: bookings[bkey].stageName });
        }
        
        if (bookings[bkey].pn && -1 === projectList.findIndex(i => i.text === bookings[bkey].pn)) {
          projectList.push({ text: bookings[bkey].pn });
        }
      }
      
      const activityList = [];
      for (const akey of Object.keys(activities)) {
        if (-1 === activityList.findIndex(i => i.text === activities[akey].name)) {
          activityList.push({ text: activities[akey].name });
        }
        if (activities[akey].stageName && -1 === stageList.findIndex(i => i.text === activities[akey].stageName)) {
          stageList.push({ text: activities[akey].stageName });
        }
        
      }
      
      const staffList = [];
      const staffTypeList = [];
      for (const staffkey of Object.keys(staffData)) {
        if (-1 === staffList.findIndex(i => i.text === staffData[staffkey].name)) {
          staffList.push({ text: staffData[staffkey].name });
        }
        if (staffData[staffkey].staffType && -1 === staffTypeList.findIndex(i => i.text === staffData[staffkey].staffType)) {
          staffTypeList.push({ text: staffData[staffkey].staffType });
        }
      }
      
      activityList.sort(this.valuesSortFunc);
      bookingList.sort(this.valuesSortFunc);
      projectList.sort(this.valuesSortFunc);
      taskList.sort(this.valuesSortFunc);
      staffList.sort(this.valuesSortFunc);
      staffTypeList.sort(this.valuesSortFunc);
      resourceList.sort(this.valuesSortFunc);
      stageList.sort(this.valuesSortFunc);
        
      const usageList = [];
      usageList.push({ text: this.$t('button.none') });
      usageList.push({ text: this.$t('button.unders') });
      usageList.push({ text: this.$t('button.optimal') });
      usageList.push({ text: this.$t('button.overs') });
      
      this.badgeFilterFieldValues = {
        activityName: activityList,
        bookingName: bookingList,
        taskName: taskList,
        projectName: projectList,
        staffName: staffList,
        staffType: staffTypeList,
        stageName: stageList,
        resourceName: resourceList,
        tag: tagList,
        usage: usageList,
        availability: ''
      };
    },
    onColorChange(val, color_key) {
      const coloring = this.coloring;
      for (const key of Object.keys(coloring)) {
        coloring[key] = false;
      }
      coloring[val] = true;
      this.layoutProfile[color_key] = coloring;
      this.updateLayoutProfile();
      this.updateGrid();

    },
    getRowId(params) {
      const data = params.data;
      if (typeof data !== 'undefined') {
        if (data.type === 'company') {
          return data.companyUuId;
        }
        else if (data.type === 'location') {
          if (this.grouping.company) {
            return `${data.companyUuId}${data.locationUuId}`;
          }
          return data.locationUuId;
        }
        else if (data.type === 'department') {
          let deptId = '';
          if (this.grouping.location && data.locationUuId) {
            deptId += data.locationUuId;
          }
          if (this.show.combineLike) {
            let uuId = departmentUuIds[data.name];
            if (!uuId) {
              uuId = departmentUuIds[data.name] = data.departmentUuId;
            }
            deptId += uuId;
          }
          else {
            deptId += data.departmentUuId;
          }
          return deptId;
        }
        else if (data.type === 'skill') {
          let skillId = '';
          if (this.grouping.company && data.companyUuId) {
            skillId += data.companyUuId;
          }
          if (this.grouping.location && data.locationUuId) {
            skillId += data.locationUuId;
          }
          if (this.grouping.department && data.departmentUuId) {
            if (this.show.combineLike) {
              let uuId = departmentUuIds[data.departmentName];
              if (!uuId) {
                uuId = departmentUuIds[data.departmentName] = data.departmentUuId;
              }
              skillId += uuId;
            }
            else {
              skillId += data.departmentUuId;
            }
          }
          
          skillId += data.skillUuId;
          return skillId;
        }
        else if (data.type === 'task') {
          if ((this.grouping.staff || (this.showGeneric && data.staffUuid in this.genericStaff)) && 
              !(!this.showGeneric && data.staffUuid in this.genericStaff) && data.staffUuid) {
            if (this.grouping.department && data.departmentUuId &&
                this.grouping.skills && data.skillUuId) {
              return `${data.departmentUuId}${data.skillUuId}${data.staffUuid}${data.tu}`;
            }
            else if (this.grouping.department && data.departmentUuId) {
              return `${data.departmentUuId}${data.staffUuid}${data.tu}`;
            }
            else if (this.grouping.skills && data.skillUuId) {
              return `${data.skillUuId}${data.staffUuid}${data.tu}`;
            }
            return `${data.staffUuid}${data.tu}`;
          }
          return data.tu;
        }
        
        if (data.type === 'resource') {
          // for resource we need to make sure the id is unique when we clone them under department and skills
          let resourceId = '';
          if (this.grouping.company && data.companyUuId) {
            resourceId += data.companyUuId;
          }
          if (this.grouping.location && data.locationUuId) {
            resourceId += data.locationUuId;
          }
          if (this.grouping.department && data.departmentUuId) {
            if (this.show.combineLike) {
              let uuId = departmentUuIds[data.departmentName];
              if (!uuId) {
                uuId = departmentUuIds[data.departmentName] = data.departmentUuId;
              }
              resourceId += uuId;
            }
            else {
              resourceId += data.departmentUuId;
            }
          }
          if (this.grouping.skills && data.skillUuId) {
            resourceId += data.skillUuId;
          }
          resourceId += data.uuId;
          return resourceId;
        }
                
        // for staff we need to make sure the id is unique when we clone them under department and skills
        let staffId = '';
        if (this.grouping.department && data.departmentUuId) {
          if (this.show.combineLike) {
            let uuId = departmentUuIds[data.departmentName];
            if (!uuId) {
              uuId = departmentUuIds[data.departmentName] = data.departmentUuId;
            }
            staffId += uuId;
          }
          else {
            staffId += data.departmentUuId;
          }
        }
        if (this.grouping.skills && data.skillUuId) {
          staffId += data.skillUuId;
        }
        if (this.grouping.resource && data.resourceUuId) {
          staffId += data.resourceUuId;
        }
        staffId += data.uuId;
        return staffId;
      }
    },
    getDataPath(data) {
      const self = this;
      if (typeof data !== 'undefined') {
        const path = [self.getRowId({ data: data })];
        
        if ((self.grouping.staff || (self.showGeneric && data.staffUuid in self.genericStaff)) && 
            !(!self.showGeneric && data.staffUuid in self.genericStaff) &&
            data.type && data.staffUuid) { 
          let staffId = '';
          if (self.grouping.department && data.departmentUuId) {
            if (self.show.combineLike) {
              let uuId = departmentUuIds[data.departmentName];
              if (!uuId) {
                uuId = departmentUuIds[data.departmentName] = data.departmentUuId;
              }
              staffId += uuId;
            }
            else {
              staffId += data.departmentUuId;
            }
          }
          if (self.grouping.skills && data.skillUuId) {
            staffId += data.skillUuId;
          }
          staffId += data.staffUuid;
          path.unshift(staffId);
        }
        
        if (self.grouping.resource && data.type !== 'resource' && data.resourceUuId) { 
          let resourceId = '';
          if (self.grouping.company && data.companyUuId) {
            resourceId += data.companyUuId;
          }
          if (self.grouping.location && data.locationUuId) {
            resourceId += data.locationUuId;
          }
          if (self.grouping.department && data.departmentUuId) {
            if (self.show.combineLike) {
              let uuId = departmentUuIds[data.departmentName];
              if (!uuId) {
                uuId = departmentUuIds[data.departmentName] = data.departmentUuId;
              }
              resourceId += uuId;
            }
            else {
              resourceId += data.departmentUuId;
            }
          }
          if (self.grouping.skills && data.skillUuId) {
            resourceId += data.skillUuId;
          }
          resourceId += data.resourceUuId;
          path.unshift(resourceId);
        }
        
        if (self.grouping.skills && data.type !== 'skill' && data.skillUuId) {
          let skillId = '';
          if (self.grouping.company && data.companyUuId) {
            skillId += data.companyUuId;
          }
          if (self.grouping.location && data.locationUuId) {
            skillId += data.locationUuId;
          }
          if (self.grouping.department && data.departmentUuId) {
            if (self.show.combineLike) {
              let uuId = departmentUuIds[data.departmentName];
              if (!uuId) {
                uuId = departmentUuIds[data.departmentName] = data.departmentUuId;
              }
              skillId += uuId;
            }
            else {
              skillId += data.departmentUuId;
            }
          }
          
          skillId += data.skillUuId;
          path.unshift(skillId);
        }
        if (self.grouping.department && data.type !== 'department' && data.departmentUuId) {
          let deptId = '';
          if (self.grouping.location && data.locationUuId) {
            deptId += data.locationUuId;
          }
          if (self.show.combineLike) {
            let uuId = departmentUuIds[data.departmentName];
            if (!uuId) {
              uuId = departmentUuIds[data.departmentName] = data.departmentUuId;
            }
            deptId += uuId;
          }
          else {
            deptId += data.departmentUuId;
          }
          path.unshift(deptId);
        }
        
        if (self.grouping.location && data.type !== 'location' && data.locationUuId) {
          if (self.grouping.company) {
            path.unshift(`${data.companyUuId}${data.locationUuId}`);
          }
          else {
            path.unshift(data.locationUuId);
          }
        }
        // build the path hierarchy based upon the current selections
        if (self.grouping.company && data.type !== 'company' && data.companyUuId) {
          if (Array.isArray(data.companyUuId)) {
            path.unshift(data.companyUuId);
          }
          else {
            path.unshift(data.companyUuId);
          }
        }
        return path;
      }
      return [];
    },
    deleteEvent(toRemove) {
      const toRemoveActivities = toRemove.filter(r => r.type === 'activity');
      const toRemoveBookings = toRemove.filter(r => r.type === 'booking');
      const toRemoveStaff = toRemove.filter(r => r.type === 'staff');
      const toRemoveTasks = toRemove.filter(r => r.type === 'task');
      
      if (toRemoveStaff.length !== 0) {
        for (const evt of toRemoveStaff) {
          staffLinkResourceService.remove(evt.id, [{uuId: evt.section_id }]).then(() => {
            // this.alertMsg = this.$t('resource.deleted_staff');
            // this.alertError = false;
          });
        }
      }
      
      if (toRemoveActivities.length !== 0) {
        activityService.remove(toRemoveActivities).then(() => {
          // this.alertMsg = toRemove.length === 1 ? this.$t('activity.delete') : this.$t('activity.delete_plural');
          // this.alertError = false;
        });
      }
      
      if (toRemoveBookings.length !== 0) {
        bookingService.remove(toRemoveBookings).then(() => {
          // this.alertMsg = toRemove.length === 1 ? this.$t('booking.delete') : this.$t('booking.delete_plural');
          // this.alertError = false;
        });
      }
      
      if (toRemoveTasks.length !== 0) {
        taskService.remove(toRemoveTasks).then(() => {
          // this.alertMsg = toRemove.length === 1 ? this.$t('task.delete') : this.$t('task.delete_plural');
          // this.alertError = false;
        });
      }
      this.updateGrid();
    },
    folderToggle(state) {
      this.folderState[state.section.key] = state.isOpen;
    },
    async durationCalculationOk({ startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay }) {
      if (this.creatingEvent) {
        let update = false;
        this.newEvent.startDate = startDateStr;
        this.newEvent.startTime = startTimeStr;
        this.newEvent.closeDate = closeDateStr;
        this.newEvent.closeTime = closeTimeStr;
        const { value: dValue } = convertDisplayToDuration(durationDisplay, this.durationConversionOpts);
        this.newEvent.value = dValue;
        let uuId = null;
        
        if (this.newEvent.type === 'Activity') {
          uuId = await this.createActivity(
            this.newEvent.name, 
            this.newEvent.description, 
            this.newEvent.startDate, 
            this.newEvent.startTime, 
            this.newEvent.closeDate, 
            this.newEvent.closeTime, 
            this.newEvent.value, 
            this.newEvent.resourceUuid,
            null,// color
            this.newEvent.stage,
            this.newEvent.quantity,
            this.newEvent.utilization,
            this.newEvent.customFields
          );
          update = true;
          if (!this.show.activity) {
            this.promptShowActivity = true;
          }
        }
        else if (this.newEvent.type === 'Booking') {
          uuId = await this.createBooking(
            this.newEvent.projectBooking[0].name, 
            this.newEvent.description, 
            this.newEvent.startDate, 
            this.newEvent.startTime, 
            this.newEvent.closeDate, 
            this.newEvent.closeTime, 
            this.newEvent.value, 
            this.newEvent.resourceUuid, 
            this.newEvent.projectBooking[0].uuId,
            this.newEvent.projectBooking[0].color,
            this.newEvent.stage,
            this.newEvent.quantity,
            /*this.newEvent.utilization,*/
            this.newEvent.customFields
          );
          // set the event color to the project color
          this.newEvent.eventColor = this.newEvent.projectBooking[0].color;
          update = true;
          if (!this.show.booking) {
            this.promptShowBooking = true;
          }
        }
        else if (this.newEvent.type === 'Task') {
          uuId = await this.createTask(this.newEvent.name, 
                                this.newEvent.description,
                                this.newEvent.startDate, 
                                this.newEvent.startTime, 
                                this.newEvent.closeDate, 
                                this.newEvent.closeTime, 
                                this.newEvent.value, 
                                this.newEvent.projectTask[0].uuId, 
                                this.newEvent.resourceUuid,
                                this.newEvent.projectTask[0].color,
                                this.newEvent.stage,
                                this.newEvent.quantity,
                                this.newEvent.utilization,
                                this.newEvent.customFields);
          // set the event color to the project color
          this.newEvent.eventColor = this.newEvent.projectTask[0].color;
          update = true;
          if (!this.show.task) {
            this.promptShowTask = true;
          }
        }
        
        this.callback = this.cleanupCreate;
        this.newEvent.uuId = uuId;
        this.newEvent.shortName = this.newEvent.name;
        if (this.newEvent.type === 'Task') {
          this.newEvent.name = `${this.newEvent.projectTask[0].name} / ${this.newEvent.name}`;
        }
        this.processAdd(this.newEvent, null);
      }
      else {
        let update = false;
        this.updateEvent[0].startDate = startDateStr;
        this.updateEvent[0].startTime = startTimeStr;
        this.updateEvent[0].closeDate = closeDateStr;
        this.updateEvent[0].closeTime = closeTimeStr;
        const { value: dValue } = convertDisplayToDuration(durationDisplay, this.durationConversionOpts);
        this.updateEvent[0].duration = dValue;

        const eventId = typeof this.updateEvent[0].id === 'string' ? this.updateEvent[0].id.slice(this.updateEvent[0].id.length - 36) : this.updateEvent[0].id;
        if (this.updateEvent[0].type === 'activity') {
          await activityService.update([{
                                          name: this.updateEvent[0].name, 
                                          uuId: eventId, 
                                          duration: this.updateEvent[0].duration,
                                          startTime: convertDate(startDateStr, startTimeStr), 
                                          closeTime: convertDate(closeDateStr, closeTimeStr)
                                        }])
          .then(() => {
            update = true;
          });
          
          if (this.updateEvent[0].origResourceUuid !== this.updateEvent[0].resourceUuid) {
            // remove staff
            await activityLinkResourceService.remove(eventId, [{ uuId: this.updateEvent[0].origResourceUuid }]);
            // add staff
            await activityLinkResourceService.create(eventId, [{ uuId: this.updateEvent[0].resourceUuid, quantity: 1, utilization: 1 }]);
            update = true;
          }
        }
        else if (this.updateEvent[0].type === 'booking') {
          await bookingService.update([{
                                        name: this.updateEvent[0].name, 
                                        uuId: eventId, 
                                        duration: this.updateEvent[0].duration,
                                        beginDate: convertDate(startDateStr, startTimeStr), 
                                        untilDate: convertDate(closeDateStr, closeTimeStr), 
                                        resource: {
                                          uuId: this.updateEvent[0].resourceUuid
                                        }
                                      }])
          .then(() => {
            update = true;
          });
        }
        else if (this.updateEvent[0].type === 'task') {
                
          await taskService.update([{
                                     uuId: eventId, 
                                     duration: this.updateEvent[0].duration,
                                     startTime: convertDate(startDateStr, startTimeStr), 
                                     closeTime: convertDate(closeDateStr, closeTimeStr)
                                   }])
          .then(() => {
            update = true;
          });
          
          if (this.updateEvent[0].origResourceUuid !== this.updateEvent[0].resourceUuid) {
            // remove staff
            await taskLinkResourceService.remove(eventId, [{ uuId: this.updateEvent[0].origResourceUuid }]);
            // add staff
            await taskLinkResourceService.create(eventId, [{ uuId: this.updateEvent[0].resourceUuid, quantity: 1 }]);
          }
        }
        
        // update the event in the planner
        this.processUpdate(this.updateEvent[0]);
        
        this.updateEvent.splice(0, 1);
        if (this.updateEvent.length === 0) {
          delete this.updateEvent;
          if (update) {
            this.updateGrid(true);
          }
        }
        else {
          this.initDurationCalculation({ trigger: this.updateEvent[0].trigger });
        }
      }
    },
    durationCalculationCancel() {
      //Reset to previous value
      if (this.newEvent) {
        this.deleteEventId = this.newEvent.id;
      }
      
      // clear out the events
      this.updateEvent.splice(0, this.updateEvent.length);
      this.updateGrid(); // revert the change
    },
    async initDurationCalculation({ trigger=TRIGGERS.DURATION, useExistingValues=false, oldDateStr=null, oldTimeStr=null
                                    , oldConstraintType=null, oldConstraintDateStr=null, skipOutOfProjectDateCheck=null } = {}) {
      if (useExistingValues !== true) {
        this.durationCalculation.trigger = trigger;
        this.durationCalculation.startDateStr = this.creatingEvent ? this.newEvent.startDate : this.updateEvent[0].startDate;
        this.durationCalculation.startTimeStr = this.creatingEvent ? this.newEvent.startTime : this.updateEvent[0].startTime;
        this.durationCalculation.closeDateStr = this.creatingEvent ? this.newEvent.closeDate : this.updateEvent[0].closeDate;
        this.durationCalculation.closeTimeStr = this.creatingEvent ? this.newEvent.closeTime : this.updateEvent[0].closeTime;
        const durationDisplay = this.creatingEvent ? null : convertDurationToDisplay(this.updateEvent[0].duration, 'D', this.durationConversionOpts);
        this.durationCalculation.durationDisplay = durationDisplay && this.updateEvent[0].mode === 'move' ? durationDisplay : null;

        let calendar =  cloneDeep(DEFAULT_CALENDAR);
        this.creatingEvent ? this.newEvent.calendar = calendar : this.updateEvent[0].calendar = calendar;
        this.durationCalculation.calendar = calendar;
        this.durationCalculation.oldDateStr = oldDateStr;
        this.durationCalculation.oldTimeStr = oldTimeStr;
        this.durationCalculation.oldConstraintType = oldConstraintType;
        this.durationCalculation.oldConstraintDateStr = oldConstraintDateStr;
        this.durationCalculation.autoMoveForNonWorkingDay = true;
        this.durationCalculation.resizeMode = this.creatingEvent || this.updateEvent[0].mode != 'move';
        this.durationCalculation.defaultActionForNonWorkPrompt = 'move';
        this.durationCalculation.lockDuration = this.updateEvent && this.updateEvent.length > 0 && this.updateEvent[0].lockDuration;
      }
      //Used when calendarChange event is triggered. Pass along the 'session' skipOutOfProjectDateCheck state before resuming the session.
      if (skipOutOfProjectDateCheck != null) {
        this.durationCalculation.skipOutOfProjectDateCheck = skipOutOfProjectDateCheck;
      }
      this.durationCalculationShow = true; //start the calculation.
    },
    async resourceUnitOk({ id, unit, utilization }) {
      await staffLinkResourceService.update(this.resourceUnitEdit.staffId, [{ uuId: id, resourceUnit: unit, utilization: utilization }]);
      this.updateGrid();
    },
    async resourceUnitRemove({id}) {
      await staffLinkResourceService.remove(this.resourceUnitEdit.staffId, [{ uuId: id }]);
      this.updateGrid();
    },
    progressCancel() {
      this.$set(this.inProgressState, 'cancel', true);
      this.inProgressLabel = this.$t('task.progress.stopping');
    },
    dateChanged() {
      this.highlightRefresh = true;
    },
    onCellClicked(list) {
      if (!this.canView('TASK') ||
          !list) {
        return;
      }
      
      if (typeof list !== 'undefined') {
        var tasks = [];
        if (typeof list !== 'undefined') {
          for (var i = 0; i < list.length; i++) {
            if (!this.staffUsageProject ||
                (this.staffUsageProject &&
                list[i].project === this.id)) {
              const exists = tasks.filter(task => task.uuId === list[i].uuId);
              if (exists.length === 0) {
                tasks.push(cloneDeep(list[i]));
              }
              else {
                exists[0].w += list[i].w;
              }
            }
          }
        }
        
        if (tasks.length === 1) {
          const projectUuid = typeof list[0].project !== 'undefined' ? tasks[0].project : this.id;
          this.taskOpen(tasks[0].uuId, projectUuid, !this.canEdit('TASK'));
        }
        else if (tasks.length !== 0) {
          // prompt
          this.taskSelectData = [];
          this.taskEdit.uuId = null;
          this.taskEdit.projectUuid = null;
          for (var ii = 0; ii < tasks.length; ii++) {
            const exists = this.taskSelectData.filter(option => option.uuId === tasks[ii].tu);
            if (exists.length === 0) {
              const projectName = this.staffUsageProject && this.project !== null ? this.project.name : tasks[ii].pn;
              const projectUuid = this.staffUsageProject && this.project !== null ? this.project.uuId : tasks[ii].project;
              const name = tasks[ii].td ? list[ii].td : `${projectName} / ${tasks[ii].name}`
              this.taskSelectData.push({ name: name, 
                                         uuId: tasks[ii].uuId, 
                                         projectName: projectName,
                                         projectUuid: projectUuid, 
                                         progress: tasks[ii].tp,
                                         estimatedDuration: tasks[ii].te,
                                         path: `${tasks[ii].path.replace(/\n/g, ' / ')}`
                                      });
            }
          }
          
          if (this.taskOptions.length === 1) {
            const projectUuid = this.staffUsageProject && this.project !== null ? this.project.uuId : this.taskOptions[0].value.pu;
            this.taskOpen(this.taskOptions[0].value.uuId, projectUuid);
          }
          else {
            this.taskOptions.sort(function( a, b ) {
              if ( a.text.toLowerCase() < b.text.toLowerCase() ){
                return -1;
              }
              if ( a.text.toLowerCase() > b.text.toLowerCase() ){
                return 1;
              }
              return 0;
            });
            this.taskSelectShow = true;
          }
        }
      }
    },
    showSettings() {
      this.settingsShow = true;
    },
    plannerSettingsSuccess(settings) {
      let update = false;
      if (settings.alertBookings !== this.show.alertBookings) {
        this.onShowChange('alertBookings', false);
        update = true;
      }
      if (settings.alertActivities !== this.show.alertActivities) {
        this.onShowChange('alertActivities', false);
        update = true;
      }
      if (settings.alertTasks !== this.show.alertTasks) {
        this.onShowChange('alertTasks', false);
        update = true;
      }
     
      if (settings.work_hours !== null &&
          (this.show.work_hours === null || 
          this.show.work_hours.first_hour !== settings.work_hours.first_hour ||
          this.show.work_hours.last_hour !== settings.work_hours.last_hour)) {
        this.$set(this.show, 'work_hours', settings.work_hours);
        this.layoutProfile['resourceplanner_view_show'] = this.show;
        update = true;
      }
      else if (settings.work_hours === null &&
               this.show.work_hours !== null) {
        this.$set(this.show, 'work_hours', settings.work_hours);
        this.layoutProfile['resourceplanner_view_show'] = this.show;
        update = true;
      }
      
      if (settings.hideWeekends !== this.show.hideWeekends) {
        this.onShowChange('hideWeekends', false);
        update = true;
      }
      
      if (settings.hideNonWorking !== this.show.hideNonWorking) {
        this.onShowChange('hideNonWorking', false);
        update = true;
        // make sure the update() function is called in scheduler
        this.holdUpdateUntilTreeDataChanged = true;
      }
      
      if (settings.weekNumbers !== this.show.weekNumbers) {
        this.onShowChange('weekNumbers', false);
        update = true;
        // make sure the update() function is called in scheduler
        this.holdUpdateUntilTreeDataChanged = true;
      }
      
      if (settings.alertNonWork !== this.show.alertNonWork) {
        this.onShowChange('alertNonWork', false);
        update = true;
      }
      if (settings.alertTaskBookings !== this.show.alertTaskBookings) {
        this.onShowChange('alertTaskBookings', false);
        update = true;
      }
      
      if (settings.showOnlyWarnings !== this.show.showOnlyWarnings) {
        this.onShowChange('showOnlyWarnings', false);
        update = true;
      }
      
      if (settings.usageBookings !== this.show.usageBookings) {
        this.onShowChange('usageBookings', false);
        update = true;
      }
      
      if (settings.usageActivities !== this.show.usageActivities) {
        this.onShowChange('usageActivities', false);
        update = true;
      }
      
      if (settings.usageTasks !== this.show.usageTasks) {
        this.onShowChange('usageTasks', false);
        update = true;
      }
      
      if (settings.fullBarColor !== this.show.fullBarColor) {
        this.onShowChange('fullBarColor', false);
        update = true;
      }
      
      if (settings.required !== this.show.required) {
        this.onShowChange('required', false);
        update = true;
      }
      else if (settings.available !== this.show.available) {
        this.onShowChange('available', false);
        update = true;
      }
      
      if (settings.staff_metrics !== this.show.staff_metrics) {
        this.onShowChange('staff_metrics', false);
        update = true;
      }
      
      if (settings.displayFilteredMetrics !== this.show.displayFilteredMetrics) {
        this.onShowChange('displayFilteredMetrics', false);
        update = true;
      }
      
      if (settings.combineLike !== this.show.combineLike) {
        this.onShowChange('combineLike', false);
        update = true;
        this.forceReload();
      }
      
      if (update) {
        this.updateLayoutProfile();
        // update the data displayed
        this.inProgressShow = true;
        this.inProgressLabel = this.$t('staff.progress.filtering');
        setTimeout(() => {
          this.assignData();
        }, 500);
      }
    },
    fileImport() {
      this.selectImportShow = true;
    },
    async onSelectImportTypeOk(type) {
      await getCustomFieldInfo(this, type.toUpperCase()).catch(e => this.httpAjaxError(e));
      this.importType = type.toUpperCase();
      this.docImportShow = true;
    },
    async docImportOk({ items }) {
      this.docImportShow = false;
      if(items.length == 0) {
        return;
      }
      this.inProgressLabel = this.$t('task.progress.import_document');
      this.inProgressShow = true;
      
      // Tasks
      if (this.importType === 'TASKS') {
        let projectId = items[0].project.uuId;
        // const errors = 
        await TaskTemplateDataUtil.createTasksFromImportedDocument(items, projectId, null, false, this.inProgressState, this, this.durationConversionOpts);
      }
      else if (this.importType === 'BOOKING') {
        let idx = 0;
        let uuIds = null;
        const cmdList = [];
        for (const item of items) {
          for (const resource of item.resources) {
            idx++;
            item.duration = calcDuration(moment(item.startdate), moment(item.enddate), DEFAULT_CALENDAR, this.durationConversionOpts);
            const idName = `booking${idx}`;
            const data = {
              name: item.name, 
              color: item.color ? item.color : null,
              description: item.desc ? item.desc : null, 
              duration: item.duration,
              beginDate: moment.utc(item.startdate).valueOf(),
              untilDate: moment.utc(item.enddate).valueOf(),
              resource: {
                uuId: resource.uuId
              },
              project: {
                uuId: item.project.uuId
              }
            };
          
            if (this.customFields) {
              for (const cfield of this.customFields) {
                if (item[cfield.name]) {
                  data[cfield.name] = item[cfield.name];
                }
              }
            }
            
            cmdList.push({
              "invoke": `/api/booking/add`,
              "body": [data],
              "vars": [{"name": idName,"path": "$.feedbackList.uuId"}]
            });
            
            if (item.stages && item.stages.length > 0 && item.stages[0].uuId) {
              cmdList.push({
                "invoke": `/api/booking/link/stage/add`,
                "body": {
                  uuId: `@{${idName}}`, 
                  stage: {
                    uuId: item.stages[0].uuId
                  }
                }
              });
            }
          }
        }
                
        uuIds = await compositeService.exec(cmdList)
        .then((response) => {
          const result = [];
          for (const res of response.data.feedbackList) {
            result.push(res.uuId);
          }
          return result;
        });
      }
      
      else if (this.importType === 'ACTIVITY') {
        let idx = 0;
        let uuIds = null;
        const cmdList = [];
        for (const item of items) {
          for (const resource of item.resources) {
            idx++;
            item.duration = calcDuration(moment(item.startdate), moment(item.enddate), DEFAULT_CALENDAR, this.durationConversionOpts);
            const idName = `activity${idx}`;
            const data = {
              name: item.name, 
              color: item.color ? item.color : null,
              description: item.desc ? item.desc : null, 
              duration: item.duration,
              startTime: moment.utc(item.startdate).valueOf(),
              closeTime: moment.utc(item.enddate).valueOf(),
            };
          
            if (this.customFields) {
              for (const cfield of this.customFields) {
                if (item[cfield.name]) {
                  data[cfield.name] = item[cfield.name];
                }
              }
            }
            
            cmdList.push({
              "invoke": `/api/activity/add`,
              "body": [data],
              "vars": [{"name": idName,"path": "$.feedbackList.uuId"}]
            });
          
            cmdList.push({
              "invoke": '/api/activity/link/resource/add',
              "body": {
                uuId: `@{${idName}}`,
                resourceList: [{
                  uuId: resource.uuId, 
                  resourceLink: {
                    utilization: resource.resourceLink ? resource.resourceLink.utilization : resource.utilization
                  }
                }]
              }
            });
          
            if (item.stages && item.stages.length > 0 && item.stages[0].uuId) {
              cmdList.push({
                "invoke": `/api/activity/link/stage/add`,
                "body": {
                  uuId: `@{${idName}}`, 
                  stage: {
                    uuId: item.stages[0].uuId
                  }
                }
              });
            }
          }
        }
                
        uuIds = await compositeService.exec(cmdList)
        .then((response) => {
          const result = [];
          for (const res of response.data.feedbackList) {
            result.push(res.uuId);
          }
          return result;
        });
        
      }
      
      // restore the staff custom fields
      getCustomFieldInfo(this, 'STAFF').catch(e => this.httpAjaxError(e));
      
      // this.inProgressShow = false;
      // reload
      this.updateGrid();
    },
    docImportCancel() {
      this.docImportShow = false;
      // restore the staff custom fields
      getCustomFieldInfo(this, 'STAFF').catch(e => this.httpAjaxError(e));
    },
    exportStart() {
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('staff.progress.exporting');
    },
    exportEnd() {
      this.exportToFile = false;
      this.inProgressShow = false;
    },
    showBookingOk() {
      this.onShowChange('booking')
    },
    showTaskOk() {
      this.onShowChange('task')
    },
    showActivityOk() {
      this.onShowChange('activity')
    },
    processUpdate(event) {
      const ev = this.schedulerTasks.filter(e => e.id === event.id || e.id.slice(e.id.length - 36) === event.id);
      if (ev.length !== 0) {
        if (!event.startDate) {
          event.startDate = "1970-01-01";
        }
        
        if (!event.closeDate) {
          event.closeDate = "3000-12-31";
        }
        const startTime = convertDate(event.startDate, event.startTime);
        if (event.startDate === null) {
          event.startDate = "1970-01-01";
        }
        
        if (event.closeDate === null) {
          event.closeDate = "3000-12-31";
        }
        const endTime = convertDate(event.closeDate, event.closeTime)
        let start = new Date(startTime);
        let end = new Date(endTime);
        // adjust for the timezone
        start = new Date(start.getTime() + (start.getTimezoneOffset() * 60000));
        end = new Date(end.getTime() + (end.getTimezoneOffset() * 60000));
          
        // get the staff section so we can find the key
        let resourceSection = resourceSections[event.resourceUuid];
        let stfEvs = resourceEvents[event.resourceUuid];
        if (typeof stfEvs === 'undefined') {
          // the resource has no events yet, create the array
          stfEvs = resourceEvents[event.resourceUuid] = [];
        }
        
        if (event.type.toLowerCase() === 'vacation') {
          const nw = resourceSection.nonWorking.find(n => n.id === event.id);
          if (nw) {
            nw.start = start;
            nw.end = end;
          }
          
          if (resourceSection.calendarList) {
            const cal = resourceSection.calendarList.find(c => c.uuId === event.id);
            if (cal) {
              cal.startDate = start.getTime();
              cal.endDate = end.getTime();
            }
            this.redrawScheduler = true;
            const markedIndex = this.markedTimespans.findIndex(m => m.id === event.id);
            if (markedIndex !== -1) {
              const oldMarked = this.markedTimespans[markedIndex];
              oldMarked.start = start;
              oldMarked.end = end;
            }
          }                
        }
        
        // if the Resource has changed
        if (event.origResourceUuid && event.origResourceUuid !== event.resourceUuid) {
          const oresourceSection = resourceSections[event.origResourceUuid];
          const oindex = oresourceSection[`${event.type.toLowerCase()}List`].findIndex(t => t.uuId === event.id);
          if (oindex !== -1) {
            const olist = oresourceSection[`${event.type.toLowerCase()}List`].splice(oindex, 1);
            resourceSection[`${event.type.toLowerCase()}List`].push(...olist);
          }
          
          const origStfEvs = resourceEvents[event.origResourceUuid];
          const origIndex = origStfEvs.findIndex(e => e.id === event.id);
          if (origIndex !== -1) {
            const origEvent = origStfEvs.splice(origIndex, 1);
            origEvent[0].section_id = origEvent[0].resourceUuid = event.resourceUuid;
            stfEvs.push(...origEvent);
          }
        }
        
        // update the event in the map
        const stfEv = stfEvs.find(e => e.id === event.id);
        if (stfEv) {
          stfEv.text = event.name;
          stfEv.start_date = start;
          stfEv.end_date = end;
          if (event.type.toLowerCase() !== 'vacation') {
            stfEv.te = event.duration / 60;
          }
          if (event.eventColor) {
            stfEv.eventColor = event.eventColor;
          }
          if (event.stageName) {
            stfEv.stageName = event.stageName;
          }
          if (event.stage) {
            stfEv.stage = event.stage;
          }
          if (typeof event.stageColor !== 'undefined') {
            stfEv.stageColor = event.stageColor;
          }
        }
    
        const index = resourceSection[`${event.type.toLowerCase()}List`].findIndex(t => t.uuId === event.id);
        if (index !== -1) {
          const task = resourceSection[`${event.type.toLowerCase()}List`][index];
          task.name = event.name;
          task.begin = startTime;
          task.until = endTime;
          if (typeof event.utilization !== 'undefined') {
            task.utilization = event.utilization;
          }
        }
      
        // update the displayed event
        ev[0].text = event.name;
        ev[0].start_date = start;
        ev[0].end_date = end;
        if (event.type.toLowerCase() !== 'vacation') {
          ev[0].te = event.duration / 60;
        }
      
        ev[0].eventColor = event.eventColor;
        ev[0].stageColor = event.stageColor;
        ev[0].dotcolor = this.getColor(event);
        ev[0].textColor = this.getTextColor(event);
      
        // handle updating clones
        if (ev[0].clones) {
          for (const c of ev[0].clones) {
            const cindex = this.schedulerTasks.findIndex(t => t.id === c);
            this.schedulerTasks[cindex].start_date = start;
            this.schedulerTasks[cindex].end_date = end;
            this.schedulerTasks[cindex].te = event.duration / 60;
          }
        }
        
        if (this.badgeFilters.find(f => f.field === 'availability')) {
          this.assignData();
        }
        else {
          this.redrawScheduler = true;
        }
      }
    },
    isReadOnlyEvent(type) {
      if (type === 'activity') {
        return !this.canEdit('ACTIVITY', ['startTime', 'closeTime', 'duration']);
      }
      else if (type === 'booking') {
        return !this.canEdit('BOOKING', ['beginDate', 'duration', 'untilDate']);
      }
      else if (type === 'task') {
        return !this.canEdit('TASK', ['startTime', 'closeTime', 'duration']);
      }
      return false;
    },
    async processAdd(event, multiresource) {
      if (!event.startDate) {
        event.startDate = "1970-01-01";
      }
      
      if (!event.closeDate) {
        event.closeDate = "3000-12-31";
      }
      
      if (event.type.toLowerCase() === 'vacation') {
        event.startTime = '0:00';
        event.closeTime = '23:59';
      }
      let startTime = convertDate(event.startDate, event.startTime);
      let endTime = convertDate(event.closeDate, event.closeTime);
      const start = moment(new Date(startTime)).format('YYYY-MM-DD H:mm');
      const end = moment(new Date(endTime)).format('YYYY-MM-DD H:mm');
      let project = null;
      
      // get the resource section so we can find the key
      let resourceSection = resourceSections[event.resourceUuid];
      
      if (resourceSection) {
        const ev = { 
          type: event.type.toLowerCase(),
          id: event.uuId,
          te: event.value / 60, 
          start_date: start, 
          end_date: end, 
          text: event.name, 
          resourceUuid: event.resourceUuid,
          readonly: this.isReadOnlyEvent(event.type),
          eventColor: event.eventColor,
          projectColor: event.projectColor,
          projectTextColor: event.projectTextColor,
          resourceColor: event.resourceColor,
          resourceTextColor: event.resourceTextColor,
          stageColor: event.stageColor,
          stageTextColor: event.stageTextColor
        }
        
        if (event.stageName) {
          ev.stageName = event.stageName;
        }
        
        if (event.stage) {
          ev.stage = event.stage;
        }
        
        if (event.type === 'Task') {
          ev.tp = 0;
          ev.path = event.name;
          project = event.projectTask[0].uuId;
          
          // add the task to the resource to show usage
          if (!resourceSection.projectName && !resourceSection.projectUuId) {
            resourceSection.projectUuId = event.projectTask[0].uuId;
            resourceSection.projectName = event.projectTask[0].name;
            if (!(resourceSection.projectUuId in entityList)) {
              entityList[resourceSection.projectUuId] = {
                name: resourceSection.projectName
              }
            }
            resourceSection.stageUuId = event.projectTask[0].stage ? event.projectTask[0].stage.uuId : null;
            resourceSection.stageName = event.projectTask[0].stage ? event.projectTask[0].stage.name : null;
            if (!resourceSection.projectList) {
              resourceSection.projectList = [];
            }
            resourceSection.projectList.push({ uuId: resourceSection.projectUuId, name: resourceSection.projectName });
            if (!resourceSection.stageList) {
              resourceSection.stageList = [];
            }
            resourceSection.stageList.push({ uuId: resourceSection.stageUuId, name: resourceSection.stageName });
          }
          else {
            // The project may not be in the resource projectList
            const prjIdx = resourceSection.projectList.findIndex(p => p.uuId === event.projectTask[0].uuId);
            if (prjIdx === -1) {
              // add the project
              if (!(event.projectTask[0].uuId in entityList)) {
                entityList[event.projectTask[0].uuId] = { 
                  name: event.projectTask[0].name
                }
              }
              resourceSection.projectList.push({ uuId: event.projectTask[0].uuId, name: event.projectTask[0].name });
              resourceSection.stageList.push({ uuId: event.projectTask[0].stage ? event.projectTask[0].stage.uuId : null,
                                            name: event.projectTask[0].stage ? event.projectTask[0].stage.name : null });
            }
          }
          
        }
        
        if (event.projectBooking && event.projectBooking.length !== 0 &&
            ev.type === 'booking') {
          project = event.projectBooking[0].uuId;
          ev.project = event.projectBooking[0].uuId;
          if (event.projectBooking[0].color) {
            ev.projectColor = event.projectBooking[0].color;
          }
             
          if (!resourceSection.projectName && !resourceSection.projectUuId) {
            resourceSection.projectUuId = event.projectBooking[0].uuId;
            resourceSection.projectName = event.projectBooking[0].name;
            if (!(resourceSection.projectUuId in entityList)) {
              entityList[resourceSection.projectUuId] = {
                name: resourceSection.projectName
              }
            }
            resourceSection.stageUuId = event.projectBooking[0].stage ? event.projectBooking[0].stage.uuId : null;
            resourceSection.stageName = event.projectBooking[0].stage ? event.projectBooking[0].stage.name : null;
            if (!resourceSection.projectList) {
              resourceSection.projectList = [];
            }
            resourceSection.projectList.push({ uuId: resourceSection.projectUuId, name: resourceSection.projectName });
            if (!resourceSection.stageList) {
              resourceSection.stageList = [];
            }
            resourceSection.stageList.push({ uuId: resourceSection.stageUuId, name: resourceSection.stageName });
          }
          else {
            // The project may not be in the resource projectList
            const prjIdx = resourceSection.projectList.findIndex(p => p.uuId === event.projectBooking[0].uuId);
            if (prjIdx === -1) {
              // add the project
              if (!(event.projectBooking[0].uuId in entityList)) {
                entityList[event.projectBooking[0].uuId] = { 
                  name: event.projectBooking[0].name
                }
              }
              resourceSection.projectList.push({ uuId: event.projectBooking[0].uuId, name: event.projectBooking[0].name });
              resourceSection.stageList.push({ uuId: event.projectBooking[0].stage ? event.projectBooking[0].stage.uuId : null,
                                            name: event.projectBooking[0].stage ? event.projectBooking[0].stage.name : null });
            }
          }
        }
        else if (event.projectTask && event.projectTask.length !== 0 &&
            ev.type === 'task') {
          ev.pu = event.projectTask[0].uuId;
          if (event.projectTask[0].color) {
            ev.projectColor = event.projectTask[0].color;
          }
          
          const task = { 
            name: event.shortName,
            project: ev.pu,
            pn: event.projectTask[0].name
          };
          if (event.stage != null && event.stageName != null && event.stageName != '') {
            task.stage = event.stage;
            task.stageName = event.stageName;
          }
          tasks[event.uuId] = task;
        }
        
        if (event.type.toLowerCase() === 'task' ||
            event.type.toLowerCase() === 'booking' ||
            event.type.toLowerCase() === 'activity') {
            
          if (typeof resourceSection[`${event.type.toLowerCase()}List`] === 'undefined') {
            resourceSection[`${event.type.toLowerCase()}List`] = [];
          }
          
          resourceSection[`${event.type.toLowerCase()}List`].push({
            name: event.shortName,
            type: event.type.toLowerCase(),
            utilization: event.utilization ? event.utilization : 1.0,
            quantity: event.quantity ? event.quantity : 1.0,
            begin: convertDate(event.startDate, event.startTime),
            until: convertDate(event.closeDate, event.closeTime),
            uuId: event.uuId,
            path: event.type.toLowerCase() === 'task' ? `${event.projectTask[0].name} / ${event.shortName}` : event.shortName,
            tp: event.progress ? event.progress : 0,
            te: event.value * 60000,
            pn: event.projectTask && event.projectTask.length > 0 ? event.projectTask[0].name : '',
            project: project
          });
          if (event.type.toLowerCase() === 'activity') {
            //Register this new event to activities. Make it visible to badgeFilterFieldValues.
            const activity = {
              name: event.name
              , uuId: event.uuId
            }
            if (event.stage != null && event.stageName != null && event.stageName != '') {
              activity.stage = event.stage;
              activity.stageName = event.stageName;
            }
            activities[event.uuId] = activity;
          } else if (event.type.toLowerCase() === 'booking') {
            const booking = {
              name: event.name
              , uuId: event.uuId
            }
            if (Array.isArray(event.projectTask) && event.projectTask.length > 0) {
              booking.pn = event.projectTask[0].name;
              booking.project = event.projectTask[0].uuId;
              if (event.projectTask[0].color != null) {
                booking.projectColor = event.projectTask[0].color;
              }
              booking.bookedList = [{
                label: 'PROJECT'
                , uuId: event.projectTask[0].uuId
              }];
            }
            if (event.resourceUuId != null) {
              if (Array.isArray(booking.bookedList)) {
                booking.bookedList.push({
                  label: 'RESOURCE'
                  , uuId: event.resourceUuId
                });
              } else {
                booking.bookedList = [{
                  label: 'RESOURCE'
                  , uuId: event.resourceUuId
                }];
              }
            }
            if (event.stage != null && event.stageName != null && event.stageName != '') {
              booking.stage = event.stage;
              booking.stageName = event.stageName;
            }
            bookings[event.uuId] = booking;
          }
        }
        
        if (ev.type === 'vacation') {
          delete ev.te;
        }
        
        this.addResourceEvent(ev);
        
        // add any missing elements to the tree (a new project may be listed)
        buildTreeData(resourceSection, staffs[event.resourceUuid], entityList);
        
        if (multiresource) {
          for (const resource of multiresource) {
            if (resource.uuId !== ev.resourceUuid) {
              let clonedStaffSection = resourceSections[resource.uuId];
              if (clonedStaffSection) {
                const clonedEvent = {...ev};
                clonedEvent.resourceUuid = resource.uuId;
                this.addStaffEvent(clonedEvent);
                
                if (event.type === 'Task') {
                  clonedEvent.tp = 0;
                  // add the task to the resource to show usage
                  clonedStaffSection.taskList.push({
                    utilization: 1.0,
                    begin: convertDate(event.startDate, event.startTime),
                    until: convertDate(event.closeDate, event.closeTime),
                    uuId: event.uuId,
                    path: event.name
                  });
                }
              }
            }
          }
        }
      }
      // rebuild the tree and events
      this.assignData();
    },
    copyToChildren(data, field, value) {
      if (data.children) {
        for (const child of data.children) {
          child[field] = value;
          this.copyToChildren(child, field, value);
        }
      }
    }, 
    addResourceEvent(event) {
      if (event.resourceUuid in resourceEvents) {
        resourceEvents[event.resourceUuid].push(event);
      }
      else {
        resourceEvents[event.resourceUuid] = [event];
      }
    },
    getProfileEntryName(value) {
      if (this.id) {
        return `resource_usage_${value}`;
      }
      return `resourceplanner_view_${value}`;
    }
    , async getModelInfo(entity) {
      await this.$store.dispatch('data/info', {type: 'api', object: entity}).then(value => {
        const modelInfo = value[entity].properties
        const rawPayFrequencyOptions = modelInfo.find(f => f.field === 'payFrequency').options
        this.payFrequencyOptions.splice(0, this.payFrequencyOptions.length, ...rawPayFrequencyOptions.map(i => {
          return { value: i, text: this.$t(`payFrequency.${i}`) }
        }))
      })
      .catch(e => {
        this.httpAjaxError(e)
      })

      this.$store.dispatch('data/enumList').then(response => {
        if (response.jobCase != null && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          if (propertyList != null) {
            if (propertyList.StaffTypeEnum != null) {
              const obj = propertyList.StaffTypeEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                list.push({ value: c, text: c, num: obj[c] })
              }
              this.typeOptions.splice(0, this.typeOptions.length, ...list)
            }
            if (propertyList.CurrencyEnum != null) {
              const obj = propertyList.CurrencyEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                const found = currencies.find(i => i.code == c)
                const text = found != null && found.name != null? `${c} (${found.name})` : c
                list.push({ value: c, text, num: obj[c] })
              }
              this.optionCurrency.splice(0, this.optionCurrency.length, ...list)
            }
          } 
        }
      }).catch(e => {
        this.httpAjaxError(e);
      });
    }
    , processCellForClipboard(params) {
      const rowData = params.node.data;
      let srcColId = params.column.colId;
      if (srcColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
        srcColId = 'name';
      }

      const srcRowId = rowData.uuId;
      const srcRowData = params.api.getRowNode(srcRowId).data;
      
      if (Array.isArray(this.customFields) && this.customFields.length > 0) {
        const found = this.customFields.find(i => i.name == srcColId && i.type === 'Date');
        if (found) {
          return srcRowData[srcColId] != null && srcRowData[srcColId] != 0 && srcRowData[srcColId] != 32503680000000 && srcRowData[srcColId] != 32400000
            ? moment.utc(srcRowData[srcColId]).format('MM/DD/YYYY') 
            : ''
        }
      }

      if (srcColId == 'startDate' || srcColId == 'endDate') {
        return srcRowData[srcColId] != null && srcRowData[srcColId] != 0 && srcRowData[srcColId] != 32503680000000 && srcRowData[srcColId] != 32400000
          ? moment.utc(srcRowData[srcColId]).format('MM/DD/YYYY') 
          : ''
      } else  if (srcColId == 'payFrequency') {
        const found = this.payFrequencyOptions.find(i => i.value == srcRowData.payFrequency);
        return found? found.text : srcRowData.payFrequency;
      } else if (srcColId == 'payAmount') {
        const num = typeof srcRowData.payAmount == 'number'? srcRowData.payAmount : 0;
        return srcRowData.payCurrency != null
          ?  costFormatAdv(num, srcRowData.payCurrency)
          : costFormat(num)
      } else if (srcColId == 'payCurrency') {
        const found = this.optionCurrency.find(i => i.value == srcRowData.payCurrency);
        return found? found.text : srcRowData.payCurrency;
      // } else if (srcColId == 'resources') {
      //   if (srcRowData[srcColId] == null || !Array.isArray(srcRowData[srcColId])) {
      //     return '';
      //   }
      //   const value = [];
      //   for (const i of srcRowData[srcColId]) {
      //     if (i.name != null) {
      //       value.push(`${i.name}${i.unit != null? ' ('+i.level+')' : ''}`);
      //     }
      //   }
      //   return value.join(', ');
      } else if (srcColId == 'skills') {
        if (srcRowData[srcColId] == null || !Array.isArray(srcRowData[srcColId])) {
          return '';
        }
        const value = [];
        for (const i of srcRowData[srcColId]) {
          if (i.name != null) {
            value.push(`${i.name}${i.level != null? ' ('+i.level+')' : ''}`);
          }
        }
        return value.join(', ');
      } else if (srcColId == 'tag') {
        if (srcRowData[srcColId] == null || !Array.isArray(srcRowData[srcColId])) {
          return '';
        }
        return srcRowData[srcColId].join(', ');
      } else if (Array.isArray(srcRowData[srcColId])) {
        let value = [];
        for (const i of srcRowData[srcColId]) {
          if (i.name != null) {
            value.push(i.name);
          }
        }
        return value.join(', ');
      }
      return srcRowData[srcColId];
    }
    , onGridReady(params) {
      this.gridApi = params.api;
      this.gridReady = true;

      this.loadColumnSettings(this, this.layoutProfile[this.getProfileEntryName('list')], this.columnDefs);
      
      if (this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')] && this.$refs['lhs-grid']) {
        this.$refs['lhs-grid'].style.width = this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')].width;
      }
      if (this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')] && this.$refs['rhs-chart']) {
        this.$refs['rhs-chart'].style.width = this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')].width;
        //Expecting the width value from the profile is percetage value in string
        //Use parseFloat to get the number and remove '%' suffix.
        const widthFloat = parseFloat(this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')].width);
        this.schedulerWidth = `${parseInt(this.$refs['rhs-chart'].parentNode.getBoundingClientRect().width * widthFloat / 100) - 2}px`;
      }
      
      if (this.schedulerDataRenderReady === true) {
        params.api.setGridOption('rowData', this.rowData);
        return;
      }
      if (this.rowDataReady === true) {
        params.api.setGridOption('rowData', this.rowData);
        return;
      }
      
    }
    , loadColumnSettings(data, columns, columnDefs) {
      if (!columns) {
        return;
      }

      //Set autoGroupColumn
      const autoGroupSetting = columns.find(i => i.colId == data.COLUMN_AGGRID_AUTOCOLUMN);
      

      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          columnDefs.splice(idx++, 0, columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
          column.sort = null;
          column.sortIndex = null;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      columnDefs.sort(columnDefSortFunc);

      //Rearrange sort Index if necessary
      const columnsWithSortIndex = columnDefs.filter(i => i.sortIndex != null);
      if (autoGroupSetting  && autoGroupSetting.sort != null && autoGroupSetting.sortIndex != null) {
        columnsWithSortIndex.push(autoGroupSetting); 
      }
      if (columnsWithSortIndex.length > 0) {
        columnsWithSortIndex.sort((a, b) => {
          if (a.sortIndex < b.sortIndex) {
            return -1;
          } else if (a.sortIndex > b.sortIndex) {
            return 1;
          }
          return 0;
        })
        
        for (let i = 0, len = columnsWithSortIndex.length; i < len; i++) {
          columnsWithSortIndex[i].sortIndex = i;
        }
      }

      if (autoGroupSetting) {
        data.autoGroupColumnDef.width = autoGroupSetting.width;
        data.autoGroupColumnDef.sort = autoGroupSetting.sort;
        if (data.gridApi) {
          data.gridApi.setGridOption('autoGroupColumnDef', {
            ...data.autoGroupColumnDef
          })
        }
      }

      if (data.gridApi != null) {
        data.gridApi.setGridOption('columnDefs', []);
        data.gridApi.setGridOption('columnDefs', columnDefs);
      }

      //Fix the missing sort direction icon in cell header
      setTimeout(() => {
        if (data.gridApi != null) {
          data.gridApi.refreshHeader();
        }
      }, 500);
      return false;
    }
    , redrawViewport(params, firstRow, lastRow) {
      const ids = [];
      const companyIds = [];
      const locationIds = [];
      const stageIds = [];
      const departmentIds = [];
      const skillIds = [];
      const rowUuIdKeys = {} //Keep the uuId, [key] pair
      const nodes = [];
      const fields = [
        'identifier', 'payAmount', 'payCurrency'
        , 'payFrequency', 'tag', 'resourceQuota'
        
      ];
      
      const companyFields = [
        'name',
        'type',
        'identifier'
      ];
      const locationFields = [
        'name',
        'countryName',
        'region',
        'town',
        'identifier'
      ];
      
      const departmentFields = [
        'name',
        'identifier'
      ];
      
      const skillFields = [
        'name',
        'identifier'
      ];
      
      if (Array.isArray(this.customFields) && this.customFields.length > 0) {
        fields.push(...this.customFields.map(i => i.name));
      }
      
      if (Array.isArray(this.companyCustomFields) && this.companyCustomFields.length > 0) {
        companyFields.push(...this.companyCustomFields.map(i => i.name));
      }
      
      if (Array.isArray(this.locationCustomFields) && this.locationCustomFields.length > 0) {
        locationFields.push(...this.locationCustomFields.map(i => i.name));
      }
      
      if (Array.isArray(this.departmentCustomFields) && this.departmentCustomFields.length > 0) {
        departmentFields.push(...this.departmentCustomFields.map(i => i.name));
      }
      
      if (Array.isArray(this.skillCustomFields) && this.skillCustomFields.length > 0) {
        skillFields.push(...this.skillCustomFields.map(i => i.name));
      }
      
      for (let idx = firstRow; idx <= lastRow; idx++) {
        const row = params.api.getDisplayedRowAtIndex(idx);
        if (row && row.data && row.data.loaded != true && row.data.type == 'resource') {
          if (row.data.uuId in this.resourceDataLoaded) {
            const cached = this.resourceDataLoaded[row.data.uuId];
            for (const f of fields) {
              row.data.loaded = true;
              row.data[f] = cached[f];
            }
            nodes.push(row);
          } else {
            if (Array.isArray(rowUuIdKeys[row.data.uuId])) {
              rowUuIdKeys[row.data.uuId].push(row.data.key);
            } else {
              rowUuIdKeys[row.data.uuId] = [row.data.key];
            }
            ids.push(row.data.uuId);
          }
        }
        else if (row && row.data && row.data.loaded != true && row.data.type == 'company') {
          if (row.data.uuId in this.companyDataLoaded) {
            const cached = this.companyDataLoaded[row.data.uuId];
            for (const f of fields) {
              row.data.loaded = true;
              if (f === 'type') {
                row.data['companyType'] = cached[f];
              }
              else {
                row.data[f] = cached[f];
              }
            }
            nodes.push(row);
          } else {
            if (Array.isArray(rowUuIdKeys[row.data.uuId])) {
              rowUuIdKeys[row.data.uuId].push(row.data.key);
            } else {
              rowUuIdKeys[row.data.uuId] = [row.data.key];
            }

            if (UUID_CHECK.test(row.data.uuId)) {
              companyIds.push(row.data.uuId);
            }
          }
        }
        else if (row && row.data && row.data.loaded != true && row.data.type == 'location') {
          if (row.data.uuId in this.locationDataLoaded) {
            const cached = this.locationDataLoaded[row.data.uuId];
            for (const f of fields) {
              row.data.loaded = true;
              row.data[f] = cached[f];
            }
            nodes.push(row);
          } else {
            if (Array.isArray(rowUuIdKeys[row.data.uuId])) {
              rowUuIdKeys[row.data.uuId].push(row.data.key);
            } else {
              rowUuIdKeys[row.data.uuId] = [row.data.key];
            }

            if (UUID_CHECK.test(row.data.uuId)) {
              locationIds.push(row.data.uuId);
            }
          }
        }
        else if (row && row.data && row.data.loaded != true && row.data.type == 'stage') {
          if (row.data.uuId in this.stageDataLoaded) {
            const cached = this.stageDataLoaded[row.data.uuId];
            for (const f of fields) {
              row.data.loaded = true;
              row.data[f] = cached[f];
            }
            nodes.push(row);
          } else {
            if (Array.isArray(rowUuIdKeys[row.data.uuId])) {
              rowUuIdKeys[row.data.uuId].push(row.data.key);
            } else {
              rowUuIdKeys[row.data.uuId] = [row.data.key];
            }

            if (UUID_CHECK.test(row.data.uuId)) {
              stageIds.push(row.data.uuId);
            }
          }
        }
        else if (row && row.data && row.data.loaded != true && row.data.type == 'department') {
          if (row.data.uuId in this.departmentDataLoaded) {
            const cached = this.departmentDataLoaded[row.data.uuId];
            for (const f of fields) {
              row.data.loaded = true;
              row.data[f] = cached[f];
            }
            nodes.push(row);
          } else {
            if (Array.isArray(rowUuIdKeys[row.data.uuId])) {
              rowUuIdKeys[row.data.uuId].push(row.data.key);
            } else {
              rowUuIdKeys[row.data.uuId] = [row.data.key];
            }

            if (UUID_CHECK.test(row.data.uuId)) {
              departmentIds.push(row.data.uuId);
            }
          }
        }
        else if (row && row.data && row.data.loaded != true && row.data.type == 'skill') {
          if (row.data.uuId in this.skillDataLoaded) {
            const cached = this.skillDataLoaded[row.data.uuId];
            for (const f of fields) {
              row.data.loaded = true;
              row.data[f] = cached[f];
            }
            nodes.push(row);
          } else {
            if (Array.isArray(rowUuIdKeys[row.data.uuId])) {
              rowUuIdKeys[row.data.uuId].push(row.data.key);
            } else {
              rowUuIdKeys[row.data.uuId] = [row.data.key];
            }

            if (UUID_CHECK.test(row.data.uuId)) {
              skillIds.push(row.data.uuId);
            }
          }
        }
      }
      
      // save the fields for exporting
      this.fields = fields;
      this.companyFields = companyFields;
      this.locationFields = locationFields;
      this.departmentFields = departmentFields;
      this.skillFields = skillFields;
      
      const requests = [];
      if (ids.length > 0) {
        requests.push(resourceService.list({ 
          start: 0
          , limit: -1
          , holders: ids
          , fields: JSON.parse(JSON.stringify(fields))
        }, this.customFields));
      }

      if (companyIds.length > 0) {
        requests.push(companyService.listv2({ 
          start: 0
          , limit: -1
          , holders: companyIds
          , fields: JSON.parse(JSON.stringify(companyFields))
        }, this.companyCustomFields));
      }

      if (locationIds.length > 0) {
        requests.push(locationService.list({ 
          start: 0
          , limit: -1
          , holder: locationIds
          , fields: JSON.parse(JSON.stringify(locationFields))
        }, true, this.locationCustomFields));
      }

      if (departmentIds.length > 0) {
        requests.push(departmentService.list({ 
          start: 0
          , limit: -1
          , holders: departmentIds
          , fields: JSON.parse(JSON.stringify(departmentFields))
        }, this.departmentCustomFields));
      }

      if (skillIds.length > 0) {
        requests.push(skillService.list({ 
          start: 0
          , limit: -1
          , holders: skillIds
          , fields: JSON.parse(JSON.stringify(skillFields))
        }, null, { customFields: this.skillCustomFields }));
      }
      
      if (requests.length > 0) {
        Promise.allSettled(requests)
        .then(responses => {
          const filteredResponseList = responses.filter(i => i.status == 'fulfilled');
          for (const r of filteredResponseList) {
            const data = r.value?.data != null && Array.isArray(r.value.data)? r.value.data : [];
            for (const row of data) {
              const relatedRowKeys = rowUuIdKeys[row.uuId];
              if (!Array.isArray(relatedRowKeys) || relatedRowKeys.length == 0) {
                continue;
              }
              for (const key of relatedRowKeys) {
                const rowNode = params.api.getRowNode(key);
                if (rowNode) {
                  if (rowNode.data.type === 'resource') {
                    if (!(row.uuId in this.resourceDataLoaded)) {
                      this.resourceDataLoaded[row.uuId] = row;
                    }
                    for (const f of fields) {
                      rowNode.data.loaded = true;
                      rowNode.data[f] = row[f];
                    }
                  }
                  else if (rowNode.data.type === 'company') {
                    if (!(row.uuId in this.companyDataLoaded)) {
                      this.companyDataLoaded[row.uuId] = row;
                    }
                    for (const pf of companyFields) {
                      rowNode.data.loaded = true;
                      const companyFieldName = this.getFieldName('company', pf);
                      rowNode.data[companyFieldName] = row[pf];
                      this.copyToChildren(rowNode.data, companyFieldName, row[pf]);
                    }
                  }
                  else if (rowNode.data.type === 'location') {
                    if (!(row.uuId in this.locationDataLoaded)) {
                      this.locationDataLoaded[row.uuId] = row;
                    }
                    for (const pf of locationFields) {
                      rowNode.data.loaded = true;
                      const locationFieldName = this.getFieldName('location', pf);
                      rowNode.data[locationFieldName] = row[pf];
                      this.copyToChildren(rowNode.data, locationFieldName, row[pf]);

                    }
                  }
                  else if (rowNode.data.type === 'department') {
                    if (!(row.uuId in this.departmentDataLoaded)) {
                      this.departmentDataLoaded[row.uuId] = row;
                    }
                    for (const pf of departmentFields) {
                      rowNode.data.loaded = true;
                      const departmentFieldName = this.getFieldName('department', pf);
                      rowNode.data[departmentFieldName] = row[pf];
                      this.copyToChildren(rowNode.data, departmentFieldName, row[pf]);

                    }
                  }
                  else if (rowNode.data.type === 'skill') {
                    if (!(row.uuId in this.skillDataLoaded)) {
                      this.skillDataLoaded[row.uuId] = row;
                    }
                    for (const pf of skillFields) {
                      rowNode.data.loaded = true;
                      const skillFieldName = this.getFieldName('skill', pf);
                      rowNode.data[skillFieldName] = row[pf];
                      this.copyToChildren(rowNode.data, skillFieldName, row[pf]);
                    }
                  }
                  this.getTotalUsage(rowNode.data);
                  nodes.push(rowNode);
                }
              }
            }
          }
          params.api.redrawRows({rowNodes: nodes});
        });
      } else if (nodes.length > 0) {
        params.api.redrawRows({rowNodes: nodes});
      }
    }
    , getFieldName(group, field) {
      return `${group}${String(field).charAt(0).toUpperCase() + String(field).slice(1)}`;
    }
    , updateSchedulerDataAfterSortedAndFiltered(api) {
      const rowList = {}; //Keep row with children only. Allow easy access to the parent row for adding child row.
      const rData = [];
      api.forEachNodeAfterFilterAndSort((rowNode) => {
        if (rowNode.data == null) {
          return;
        }
        
        const d = JSON.parse(JSON.stringify(rowNode.data));
        //Remove non-data related properties
        delete d.$parent;
        delete d.__obj__;
        delete d.level;

        //re-sync folder open state
        d.open = rowNode.expanded;
        

        //For grouping: Clear children items. Will be re-filled with latest child data with right order
        //For staff: create children property to keep the staff items if resource has staffList and it is not empty.
        if (Array.isArray(d.children) || (d.type === 'resource' && Array.isArray(d.staffList) && d.staffList.length > 0)) {
          d.children = []; //clear it and the child rows will be added in proper sort order.
          rowList[d.path.join()] = d;
        }
        if (d.type == 'staff') {
          const parent = rowList[d.path.slice(0, d.path.length-1).join()];
          if (parent != null) {
            parent.children.push(d);
          } else {
            //Didn't expect this flow.
            //Skip adding the staff when it doesn't belong to any resource.
          } 
        } else if (d.path.length == 1) {
          rData.push(d); //Keep the top level rows only
        } else {
          const parent = rowList[d.path.slice(0, d.path.length-1).join()];
          if (parent != null) {
            parent.children.push(d);
          } //else do nothing
        }
      });
      
      //Update RHS timeline and event
      this.schedulerDataRenderBySort = true;
      this.schedulerTasks = cloneDeep(this.schedulerTasks);
      this.schedulerWrapper.processTreeNodes(rData);
      this.schedulerWrapper.setTreeData(rData);
      this.schedulerToolbarWrapper.setTreeData(rData);
      this.showNoRowsOverlay = rData.length === 0;
    }
    , async fetchDataForSortedColumn(resourceData, fields=[]) {
      //1. make batch call to avoid reaching the number limit of holder allowed (resource id) in one query call.

      if (!Array.isArray(fields) || fields.length == 0) {
        return;
      }

      const resourceIds = resourceData.map(i => i.uuId);
      
      const batchResourceIds = [];
      const requests = [];

      do {
        batchResourceIds.push(resourceIds.splice(0, resourceIds.length > 250? 250 : resourceIds.length));
      } while(resourceIds.length > 250);
      if (resourceIds.length > 0) {
        batchResourceIds.push(resourceIds);
      }
      
      for (const ids of batchResourceIds) {
        requests.push(resourceService.list({ 
          start: 0
          , limit: -1
          , holders: ids
          , fields: ['uuId', ...fields]
        }, this.customFields));
      }

      return Promise.allSettled(requests)
      .then(responses => {
        const filteredResponseList = responses.filter(i => i.status == 'fulfilled');
        for (const r of filteredResponseList) {
          const data = r.value?.data != null && Array.isArray(r.value.data)? r.value.data : [];
          for (const row of data) {
            const found = resourceData.find(i => i.uuId == row.uuId);
            if (found) {
              for (const f of fields) {
                found[f] = row[f];
              }
            }
          }
        }
      });
    }
    , async fetchRowDataForSortedColumn(api, fieldList=[]) {
      //1. make batch call to avoid reaching the number limit of holder allowed (resource id) in one query call.

      let resourceFields = [];
      let departmentFields = [];

      for (const f of fieldList) {
        if (f.startsWith('department')) {
          let property = f.substring('department'.length);
          departmentFields.push(String(property).charAt(0).toLowerCase() + String(property).slice(1));
        } else {
          resourceFields.push(f);
        }
      }
      
      let resourceIds = new Set();
      let departmentIds = new Set();
      const rowUuIdKeys = {};
      api.forEachNode((rowNode) => {
        if (resourceFields.length > 0 && rowNode.data?.type == 'resource') {
          resourceIds.add(rowNode.data.uuId);
          if (Array.isArray(rowUuIdKeys[rowNode.data.uuId])) {
            rowUuIdKeys[rowNode.data.uuId].push(rowNode.data.key);
          } else {
            rowUuIdKeys[rowNode.data.uuId] = [rowNode.data.key];
          }
        }
        if (departmentFields.length > 0 && rowNode.data?.type == 'department') {
          departmentIds.add(rowNode.data.uuId);
          if (Array.isArray(rowUuIdKeys[rowNode.data.uuId])) {
            rowUuIdKeys[rowNode.data.uuId].push(rowNode.data.key);
          } else {
            rowUuIdKeys[rowNode.data.uuId] = [rowNode.data.key];
          }
        }
      });
      resourceIds = Array.from(resourceIds);
      departmentIds = Array.from(departmentIds);
      const batchResourceIds = [];
      const batchDepartmentIds = [];
      const requests = [];
     
      do {
        batchResourceIds.push(resourceIds.splice(0, resourceIds.length > 250? 250 : resourceIds.length));
      } while(resourceIds.length > 250);
      if (resourceIds.length > 0) {
        batchResourceIds.push(resourceIds);
      }

      do {
        batchDepartmentIds.push(departmentIds.splice(0, departmentIds.length > 250? 250 : departmentIds.length));
      } while(departmentIds.length > 250);
      if (departmentIds.length > 0) {
        batchDepartmentIds.push(departmentIds);
      }

      for (const ids of batchResourceIds) {
        requests.push(resourceService.list({ 
          start: 0
          , limit: -1
          , holders: ids
          , fields: ['uuId', ...resourceFields]
        }, this.customFields));
      }

      for (const ids of batchDepartmentIds) {
        requests.push(departmentService.list({ 
          start: 0
          , limit: -1
          , holders: ids
          , fields: ['uuId', ...departmentFields]
        }, this.departmentCustomFields));
      }

      return Promise.allSettled(requests)
      .then(responses => {
        const filteredResponseList = responses.filter(i => i.status == 'fulfilled');
        const itemsToUpdate = [];
        for (const r of filteredResponseList) {
          const data = r.value?.data != null && Array.isArray(r.value.data)? r.value.data : [];
          for (const row of data) {
            const relatedRowKeys = rowUuIdKeys[row.uuId];
            if (!Array.isArray(relatedRowKeys) || relatedRowKeys.length == 0) {
              continue;
            }
            for (const key of relatedRowKeys) {
              const rowNode = api.getRowNode(key);
              if (rowNode) {
                if (rowNode.data.type == 'department') {
                  for (const f of departmentFields) {
                    rowNode.data[this.getFieldName('department', f)] = row[f];
                  }
                } else {
                  for (const f of resourceFields) {
                    rowNode.data[f] = row[f];
                  }
                }
                itemsToUpdate.push(rowNode.data);
              }
            }
          }
        }
        api.applyTransaction({ update: itemsToUpdate });
        for (const f of fieldList) {
          this.resourceFieldDataLoaded.push(f);
        }
        api.refreshClientSideRowModel('sort');
      });
    }
    , schedulerCreated(instance) {
      this.scheduler = instance.schedulerInstance;
      this.schedulerWrapper = instance;
    }
    , schedulerToolbarCreated(instance) {
      this.schedulerToolbarWrapper = instance;
    }
    , schedulerDataRender: debounce(function() {
      if (this.gridApi) {
        
        if (this.schedulerDataRenderBySort == true) {
          this.schedulerDataRenderBySort = false;
          this.gridApi.resetRowHeights();
        }
        
        if (this.needRefreshClientRowModel == true) {
          this.needRefreshClientRowModel = false;
          //setTimeout() is needed to let refreshClientSideRowModel() takes effect.
          setTimeout(() => {
            this.gridApi.refreshClientSideRowModel('sort');
          }, 100);
        }

        if (this.needSetActualRowHeights == true) {
          this.needSetActualRowHeights = false;
          this.setActualRowHeights();
        }

        if (this.needScroll == true) {
          this.needScroll = false;
          if (this.scheduler != null) {
            this.scrollState.triggeredByLHS = true;
            this.scrollState.triggeredByRHS = false;
            this.scheduler.getView().scrollTo({ top: this.scrollState.top, left: this.scrollState.left })
          }
        }
        
      } else {
        this.schedulerDataRenderReady = true;
      }
      
      //holdUpdateUntilTreeDataChanged here is used as an indicator that rendering process is complete when it is false.
      if (!this.holdUpdateUntilTreeDataChanged) {
        setTimeout(() => {
          this.loading = false;
          this.inProgressShow = false;
        }, 0)
      }
    }, 300)
    , setActualRowHeights() {
      this.gridApi.forEachNode(node => {
        let rowHeight = this.gridOptions.getRowHeight({ node });
        node.setRowHeight(rowHeight);
      });
      this.gridApi.onRowHeightChanged();
    }
    , onCollapsedId() {
      this.collapseId = null;
      if (this.gridApi) {
        this.gridApi.resetRowHeights();
      }
    }
    , onExpandedId() {
      this.expandId = null;
      if (this.gridApi) {
        this.gridApi.resetRowHeights();
      }
    }
    , onSelectionChanged(selected) {
      this.selected = selected;
    }
    , getRowHeight(params) {
      if (this.scheduler != null) {
        try {
          /* eslint-disable no-undef */
          let stats = this.scheduler._timeline_get_cur_row_stats(this.scheduler.getView(), params.node.rowIndex);
          stats = this.scheduler._timeline_get_fit_events_stats(this.scheduler.getView(), params.node.rowIndex, stats);
          /* eslint-enable no-undef */

          //Fix: Add 1px to first row height to make it same height to RHS scheduler's 1st row.
          if (params.node?.rowIndex === 0) {
            return stats.height + 1;
          }
          return stats.height;
        } catch(e) {
          //Error (usually) occurred when the specific row is not rendered in RHS timeline due to collapsed state.
          //Use fallback value: null
          //A resetRowHeight() will be called later to get actual row height in onExpandedId().
        }
      }
      return null
    }
    , chartResizeHandler: debounce(function(/** e */) {
      const windowHeight = (window.innerHeight > 0) ? window.innerHeight : screen.height;
      const windowWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      const aGContainerElem = this.$refs['resource-aggrid-scheduler-container'];
      const offsetTop = aGContainerElem != null? aGContainerElem.offsetTop : 0;
      const _heightOffset = -7;
      let availablePAGContainerHeight = windowHeight - offsetTop - 35 - _heightOffset;
      if (aGContainerElem != null) {
        aGContainerElem.style.height = `${availablePAGContainerHeight}px`;
      }
      
      let height = availablePAGContainerHeight - 70 - this.alertOffsetHeight;
      //Minimum height: 150
      if (height < 150) {
        height = 150;
      }
      this.$set(this.splitterStyle, 'height', `${height}px`);
      this.$set(this.lhsGridStyle, 'height', `${height}px`);
      this.schedulerHeight = height;
      if (this.$refs['resizer'] != null && this.$refs['lhs-grid'] != null) {
        const resizer = this.$refs['resizer'];
        const newLeftWidth = (this.$refs['lhs-grid'].getBoundingClientRect().width + this.resizerWidth) * 100 / resizer.parentNode.getBoundingClientRect().width;
        this.schedulerWidth = `${parseInt(resizer.parentNode.getBoundingClientRect().width * (100 - newLeftWidth) / 100) - 2}px`;
      }
    }, 100)
    , touchStartHandler(e) {
      if('resizer-overlay' == e.target.id) {
        
        e.preventDefault();
        this.$refs['resizer-overlay'].classList.add('pressed');
        this.$refs['resizer-overlay'].addEventListener('touchmove', this.debouncedTouchMoveHandler);
        this.$refs['resizer-overlay'].addEventListener('touchcancel', this.touchCancelHandler);
        this.$refs['resizer-overlay'].addEventListener('touchend', this.touchEndHandler);
        
        this.touchEvent.isTouchStart = true;
        this.touchEvent.x = e.touches[e.touches.length - 1].clientX;  
        this.touchEvent.leftWidth = this.$refs['lhs-grid'].getBoundingClientRect().width;
      }
    } 
    , touchMoveHandler(e) {
      if(this.touchEvent.isTouchStart) {
        e.preventDefault();
        const dx = e.touches[e.touches.length - 1].clientX - this.touchEvent.x;
        const resizer = this.$refs['resizer'];
        const leftSide = this.$refs['lhs-grid'];
        const rightSide = this.$refs['rhs-chart'];
        
        const newLeftWidth = (this.touchEvent.leftWidth + dx + this.resizerWidth) * 100 / resizer.parentNode.getBoundingClientRect().width;
        leftSide.style.width = `${newLeftWidth}%`;
        rightSide.style.width = `${100 - newLeftWidth}%`;
        this.schedulerWidth = `${parseInt(resizer.parentNode.getBoundingClientRect().width * (100 - newLeftWidth) / 100) - 2}px`;
        this.toggleSplitterResizeStyle(false);
      }
    }
    , toggleUserSelect(element, disableSelect) {
      if(disableSelect) {
        element.style.userSelect = 'none';
        element.style.pointerEvents = 'none';
      } else {
        element.style.removeProperty('user-select');
        element.style.removeProperty('pointer-events');
      }
    }
    , toggleSplitterResizeStyle(state) {
      const leftSide = this.$refs['lhs-grid'];
      const rightSide = this.$refs['rhs-chart'];
      if(state) {
        this.toggleUserSelect(leftSide, true);
        this.toggleUserSelect(rightSide, true);
      } else {
        this.toggleUserSelect(leftSide, false);
        this.toggleUserSelect(rightSide, false);
      }
      
      // save the updated splitter position in the settings
      this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')] = { width: this.$refs['lhs-grid'].style.width };
      this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')] = { width: this.$refs['rhs-chart'].style.width };
      if (this.updateLayoutProfileTimeout) {
        clearTimeout(this.updateLayoutProfileTimeout);
        this.updateLayoutProfileTimeout = null;
      }
      this.updateLayoutProfileTimeout = setTimeout(this.updateLayoutProfile, 1000);
    } 
    , touchEndHandler(e) {
      if(this.touchEvent.isTouchStart) {
        e.preventDefault();
        this.$refs['resizer-overlay'].classList.remove('pressed');
        this.touchEvent.isTouchStart = false;
        this.$refs['resizer-overlay'].removeEventListener('touchmove', this.debouncedTouchMoveHandler);
        this.$refs['resizer-overlay'].removeEventListener('touchcancel', this.touchCancelHandler);
        this.$refs['resizer-overlay'].removeEventListener('touchend', this.touchEndHandler);
        this.toggleSplitterResizeStyle(false);
      }
    } 
    , touchCancelHandler(/*e*/) {
      if(this.touchEvent.isTouchStart) {
        this.$refs['resizer-overlay'].classList.remove('pressed');
        this.touchEvent.isTouchStart = false;
        this.$refs['resizer-overlay'].removeEventListener('touchmove', this.debouncedTouchMoveHandler);
        this.$refs['resizer-overlay'].removeEventListener('touchcancel', this.touchCancelHandler);
        this.$refs['resizer-overlay'].removeEventListener('touchend', this.touchEndHandler);
        this.toggleSplitterResizeStyle(false);
      }
    }
    , mouseDownHandler(e) {
      e.preventDefault();
      this.splitterEventState.isMouseDown = true;
      // Get the current mouse position
      this.splitterEventState.x = e.clientX;
      this.splitterEventState.y = e.clientY;
      this.splitterEventState.leftWidth = this.$refs['lhs-grid'].getBoundingClientRect().width;

      // Attach the listeners to `document`
      document.addEventListener('mousemove', this.mouseMoveHandler);
      document.addEventListener('mouseup', this.mouseUpHandler);
    } 
    , mouseMoveHandler(e) {
      e.preventDefault();
      // How far the mouse has been moved
      const dx = e.clientX - this.splitterEventState.x;
      const resizer = this.$refs['resizer'];
      const leftSide = this.$refs['lhs-grid'];
      const rightSide = this.$refs['rhs-chart'];

      const newLeftWidth = (this.splitterEventState.leftWidth + dx + this.resizerWidth) * 100 / resizer.parentNode.getBoundingClientRect().width;
      leftSide.style.width = `${newLeftWidth}%`;
      rightSide.style.width = `${100 - newLeftWidth}%`;
      this.schedulerWidth = `${parseInt(resizer.parentNode.getBoundingClientRect().width * (100 - newLeftWidth) / 100) - 2}px`;

      if (this.schedulerRepaintTimeoutId != null) {
        clearTimeout(this.schedulerRepaintTimeoutId);
      }
      this.schedulerRepaintTimeoutId = setTimeout(() => {
        this.scheduler.updateView();
      }, 500)

      this.toggleSplitterResizeStyle(true);
    } 
    , mouseUpHandler(e) {
      e.preventDefault();
      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener('mousemove', this.mouseMoveHandler);
      document.removeEventListener('mouseup', this.mouseUpHandler);
      this.splitterEventState.isMouseDown = false;
      this.toggleSplitterResizeStyle(false);
    }
    , prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage;  
      }
      return this.$t('resource.grid.no_data');
    }
    , fileExport() {
      this.fields = this.gridApi.getAllDisplayedColumns().filter(f => f.colId !== 'ag-Grid-AutoColumn').map(c => c.colId);
      this.exportToFile = true;
    }
    , ganttScrollHandler: debounce(function({ left, top }) {
      this.scrollState.left = left; //Used in keeping previous x position after gantt data refresh
      if( this.scrollState.top != top && !this.scrollState.triggeredByLHS) {
        this.scrollState.triggeredByLHS = false;
        this.scrollState.triggeredByRHS = true;
        this.scrollState.top = top;
        if (this.gridApi && this.gridApi.gridBodyCtrl && this.gridApi.gridBodyCtrl.bodyScrollFeature) {
          this.gridApi.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(top);          
        }
      } else {
        this.scrollState.triggeredByLHS = false;
        this.scrollState.triggeredByRHS = false;
      }
    }, 5),
    pruneChildren(index, d, rowData) {
      if (!d.children && d.type !== 'resource' && d.type !== 'staff') {
        rowData.splice(index, 1);
      }
      else if (d.children) {
        for (let idx = d.children.length - 1; idx >= 0; idx--) {
          this.pruneChildren(idx, d.children[idx], d.children);
        }
      }
    }
    , onBadgeFilterModified(filter) {
      // this.badgeFilterFocus = true; //Pin the badgeFilter when a change is made.
      this.badgeFilters = filter;
      this.layoutProfile['resourceplanner_view_filter'] = cloneDeep(filter);
      this.updateLayoutProfile();
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('staff.progress.filtering');
      setTimeout(() => {
        this.needRefreshClientRowModel = true;
        this.assignData();
      }, 500);
    }
    , async onBadgeFilterFetchOptions(payload) {
      const field = payload.field;
      await this.fetchDataForSortedColumn(resourceData, [field]);
      let values = new Set();
      for (const r of resourceData) {
        values.add(r[field]);
      }

      values = Array.from(values).sort();

      const found = this.badgeFilters.find(i => i.field == field);
      if (found != null && Array.isArray(found.value) && found.value.length > 0) {
        this.$set(this.badgeFilterFieldValues, field, values.map(i => ({ 
          text: !i ? '(Empty)' : i
          , checked: found.value.find(j => j.text != null && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) != null
        })));
      } else {
        this.$set(this.badgeFilterFieldValues, field, values.map(i => ({ text: !i ? '(Empty)' : i, checked: false })));
      }
    }
    , valuesSortFunc(a, b) {
      return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
    }
    , onInfoOver(index) {
      profileService.nodeList(this.resourceViews[index].uuId).then((response) => {
        this.resourceViews[index].owner = response.data.resultList.filter(v => this.resourceViews[index].editingPermissions.includes(v.uuId)).map(r => { return r.name }).join(", ");
        this.$set(this.showInfo, index, true);
      });
    }
    , onInfoLeave(index) {
      this.$set(this.showInfo, index, false);
    }
    , getTotalUsage(data) {
      var state = this.scheduler.getState();
      /* eslint-enable no-undef */
      var from = new Date(state.min_date),
      to = new Date(state.max_date);
      let ret = false;
      data.totalFixedDuration = 0;
      data.totalEstimatedDuration = 0;
      data.totalActualDuration = 0;
      data.totalFixedCost = 0;
      data.totalEstimatedCost = 0;
      data.totalActualCost = 0;
      
      while(+from < +to){
        const projectId = this.grouping.project ? data.projectUuId : 
                        this.id;
        const quota = data.quota ? data.quota : 1;
        const w = this.calculateUsage(from, data, projectId);
        data.totalFixedDuration += w.wFixed;
        data.totalEstimatedDuration += w.w;
        data.totalActualDuration += w.wActual;
        data.totalFixedCost += w.fixedCost;
        data.totalEstimatedCost += w.cost;
        data.totalActualCost += w.actualCost;
        
        from = this.incrementDate(from);
      }
    }
    , getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    }
    , setColumnDefs() {
      const self = this;
      const columns = this.gridApi ? this.gridApi.getAllDisplayedColumns() : null;
      const previous = columns ? columns.map(c => getColumnDefs(c)) : null;
      
      const colDefs = [
        {
          headerName: `${this.$t('resource.title')} ${this.$t('resource.field.resourceQuota')}`
          , field: 'resourceQuota'
          , cellRenderer: 'genericCellRenderer'
          , minWidth: 100
          , hide: false
        }
        , {
          headerName: this.$t('field.identifier_full')
          , field: 'identifier'
          , cellRenderer: 'genericCellRenderer'
          , minWidth: 100
          , hide: true
        }
        , {
          headerName: `${this.$t('resource.title')} ${this.$t('resource.field.payAmount')}`
          , field: 'payAmount'
          , cellRenderer: 'costCellRenderer'
          , minWidth: 100
          , hide: true
        }
        , {
          headerName: `${this.$t('resource.title')} ${this.$t('resource.field.currency')}`
          , field: 'payCurrency'
          , cellEditor: 'listEditor'
          , cellRenderer: 'enumCellRenderer'
          , cellRendererParams: { options: this.optionCurrency }
          , hide: true
        }
        , {
          headerName: `${this.$t('resource.title')} ${this.$t('resource.field.payFrequency')}`
          , field: 'payFrequency'
          , cellEditor: 'listEditor'
          , cellRenderer: 'enumCellRenderer'
          , cellRendererParams: { options: this.payFrequencyOptions }
          , minWidth: 100
          , hide: true
          
        }
        , {
          headerName: this.$t('field.tag')
          , field: 'tag'
          , cellRenderer: 'genericCellRenderer'
          , minWidth: 100
          , hide: true
        }
        , {
          headerName: this.$t('staff.field.totalFixedDuration')
          , field: 'totalFixedDuration'
          , cellRenderer: 'workingHoursCellRenderer'
          , cellRendererParams: {
              alloc: 'resourceAlloc'
            }
          , minWidth: 100
          , hide: true
          , sortable: false
          
        }
        , {
          headerName: this.$t('staff.field.totalEstimatedDuration')
          , field: 'totalEstimatedDuration'
          , cellRenderer: 'workingHoursCellRenderer'
          , cellRendererParams: {
              alloc: 'resourceAlloc'
            }
          , minWidth: 100
          , hide: true
          , sortable: false
          
        }
        , {
          headerName: this.$t('staff.field.totalActualDuration')
          , field: 'totalActualDuration'
          , cellRenderer: 'workingHoursCellRenderer'
          , cellRendererParams: {
              alloc: 'resourceAlloc'
            }
          , minWidth: 100
          , hide: true
          , sortable: false
          
        }
        , {
          headerName: this.$t('staff.field.totalFixedCost')
          , field: 'totalFixedCost'
          , cellRenderer: 'costCellRenderer'
          , minWidth: 100
          , hide: true
          , sortable: false
          
        }
        , {
          headerName: this.$t('staff.field.totalEstimatedCost')
          , field: 'totalEstimatedCost'
          , cellRenderer: 'costCellRenderer'
          , minWidth: 100
          , hide: true
          , sortable: false
          
        }
        , {
          headerName: this.$t('staff.field.totalActualCost')
          , field: 'totalActualCost'
          , cellRenderer: 'costCellRenderer'
          , minWidth: 100
          , hide: true
          , sortable: false
          
        }
      ];
      
      const linkedEntities = [
        { selector: 'RESOURCE.TAG', field: 'tag', properties: ['name'] },
      ]
      
      // this.columnDefs = colDefs;
      this.autoGroupColumnDef = {
        headerName: 'Name',
        field: 'uuId',
        cellRendererParams: {
          suppressCount: true, 
          innerRenderer: 'detailLinkCellRenderer'
        },
        lockVisible: true,
        pinned: 'left',
        minWidth: 150,
        sort: 'asc',
        hide: false,
        menuTabs: ['columnsMenuTab'],
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if(nodeA.group == nodeB.group) {
            if (valueA === null && valueB === null) {
              return 0;
            }
            if (valueA === null || nodeA.data == null) {
              return -1;
            }
            if (valueB === null || nodeB.data == null) {
              return 1;
            }
            return nodeA.data.name.toLowerCase().localeCompare(nodeB.data.name.toLowerCase());
          } else if(nodeA.group) {
            return 1;
          } else if(nodeB.group) {
            return -1;
          }
        },
      };
  
      const requests = [
        this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
        , getCustomFieldInfo(this, 'RESOURCE').catch(e => this.httpAjaxError(e))
      ]
  
      if (this.grouping.company) {
        colDefs.push(
        {
          headerName: `${this.$t('staff.group.company')} ${this.$t('company.field.name')}`
          , field: 'companyName'
          , hide: true
        });
        
        colDefs.push(
        {
          headerName: `${this.$t('staff.group.company')} ${this.$t('field.identifier_full')}`
          , field: 'companyIdentifier'
          , cellRenderer: 'genericCellRenderer'
          , hide: true
        });
        
        colDefs.push(
        {
          headerName: this.$t('company.field.type')
          , field: 'companyType'
          , hide: true
        });
        
        requests.push(getCustomFieldInfo(this, 'COMPANY', null, { customFieldsPropName:'companyCustomFields' }).catch(e => this.httpAjaxError(e)));
      }
    
      if (this.grouping.location) {
        colDefs.push(
        {
          headerName: `${this.$t('staff.group.location')} ${this.$t('location.field.name')}`
          , field: 'locationName'
          , hide: true
        });
        
        colDefs.push(
        {
          headerName: `${this.$t('staff.group.location')} ${this.$t('location.field.country')}`
          , field: 'locationCountryName'
          , hide: true
        });
        colDefs.push(
        {
          headerName: `${this.$t('staff.group.location')} ${this.$t('location.field.region')}`
          , field: 'locationRegion'
          , hide: true
        });
        colDefs.push(
        {
          headerName: `${this.$t('staff.group.location')} ${this.$t('location.field.town')}`
          , field: 'locationTown'
          , hide: true
        });
        
        colDefs.push(
        {
          headerName: `${this.$t('staff.group.location')} ${this.$t('field.identifier_full')}`
          , field: 'locationIdentifier'
          , cellRenderer: 'genericCellRenderer'
          , hide: true
        });
        
        requests.push(getCustomFieldInfo(this, 'LOCATION', null, { customFieldsPropName:'locationCustomFields' }).catch(e => this.httpAjaxError(e)));
      }
      
      if (this.grouping.department) {
        colDefs.push(
        {
          headerName: `${this.$t('staff.group.department')} ${this.$t('department.field.name')}`
          , field: 'departmentName'
          , hide: true
        });
        
        colDefs.push(
        {
          headerName: `${this.$t('staff.group.department')} ${this.$t('field.identifier_full')}`
          , field: 'departmentIdentifier'
          , cellRenderer: 'genericCellRenderer'
          , hide: true
        });
        
        requests.push(getCustomFieldInfo(this, 'DEPARTMENT', null, { customFieldsPropName:'departmentCustomFields' }).catch(e => this.httpAjaxError(e)));
      }
      
    
      if (this.grouping.skill) {
        colDefs.push(
        {
          headerName: `${this.$t('staff.group.skill')} ${this.$t('skill.field.name')}`
          , field: 'skillName'
          , hide: true
        });
        
        colDefs.push(
        {
          headerName: `${this.$t('staff.group.skill')} ${this.$t('field.identifier_full')}`
          , field: 'skillIdentifier'
          , cellRenderer: 'genericCellRenderer'
          , hide: true
        });
        
        requests.push(getCustomFieldInfo(this, 'SKILL', null, { customFieldsPropName:'skillCustomFields' }).catch(e => this.httpAjaxError(e)));
      }
      
      Promise.allSettled(requests)
      .finally(() => {
        colDefs.push(...this.getCustomDefs(this.customFields));
        if (this.grouping.company) {
          colDefs.push(...this.getCustomDefs(this.companyCustomFields, 'company'));
        }
        if (this.grouping.location) {
          colDefs.push(...this.getCustomDefs(this.locationCustomFields, 'location'));
        }
        if (this.grouping.department) {
          colDefs.push(...this.getCustomDefs(this.departmentCustomFields, 'department'));
        }
        if (this.grouping.skill) {
          colDefs.push(...this.getCustomDefs(this.skillCustomFields, 'skill'));
        }
    
        //VIEW permission: Remove column from display list
        filterOutViewDenyProperties(colDefs, 'RESOURCE', linkedEntities);
  
        colDefs.sort(columnDefSortFunc);
        this.initialColDefs = JSON.parse(JSON.stringify(colDefs));
        
        if (previous) {
          this.loadColumnSettings(this, previous, colDefs);
          this.layoutProfile[this.getProfileEntryName('list')] = previous;
            
          // Save the new layout after applying it
          this.updateLayoutProfile();
        }
        else {
          this.columnDefs = colDefs;
        }
      });
  
      this.defaultColDef = {
        sortable: true,
        resizable: true,
        minWidth: 100,
        lockPinned: true,
        hide: true,
        menuTabs: ['columnsMenuTab']
      };
      this.context = {
        componentParent: self
      };
      
    }
    , getCustomDefs(customFields, group = 'resource') {
    const customDefs = []
      prepareCustomFieldColumnDef(customDefs, customFields, { self: this })

      if (Array.isArray(customFields) && customFields.length > 0) {
        for (const c of customFields) {
          if (c.type != 'String' && c.type != 'Enum<String>') {
            continue;
          }
        }
      }

      if (customDefs.length > 0) {
        for (const def of customDefs) {
          //set column to read only
          def.editable = false;
          const groupText = this.$t(`staff.group.${group}`);
          def.headerName = `${groupText} ${def.headerName}`;
          if (group !== 'resource') {
            def.field = `${group}${String(def.field).charAt(0).toUpperCase() + String(def.field).substr(1)}`;
          }
          
          //Reset enableReadonlyStyle to false if found. Readonly Style is not needed in staffPlanner.
          if (def.cellRendererParams?.enableReadonlyStyle === true) {
            def.cellRendererParams.enableReadonlyStyle = false;
          }
        }
        return customDefs;
      }
      return customDefs;
    },
    lockChange() {
      this.layoutProfile['lockPlanner'] = this.lockPlanner;
      this.updateLayoutProfile();
      this.redrawScheduler = true;
    },
    schedulerRedrawn() {
      this.redrawScheduler = false;
      this.gridApi.resetRowHeights();
      setTimeout(() => {
        document.getElementById("scheduler_here").focus();
      }, 100);
    },
    onPinFilter() {
      this.filterPinned = true;
      this.layoutProfile[this.getProfileEntryName('filterPinned')] = this.filterPinned
      this.updateLayoutProfile();
    },
    onUnPinFilter() {
      this.filterPinned = false;
      this.layoutProfile[this.getProfileEntryName('filterPinned')] = this.filterPinned
      this.updateLayoutProfile();
    },
    onPinSearch() {
      this.searchPinned = true;
      this.layoutProfile[this.getProfileEntryName('searchPinned')] = this.searchPinned
      this.updateLayoutProfile();
    },
    onUnPinSearch() {
      this.searchPinned = false;
      this.layoutProfile[this.getProfileEntryName('searchPinned')] = this.searchPinned
      this.updateLayoutProfile();
    },
    updateGridHeight(value=null) {
      this.alertOffsetHeight = value != null? value+16 : 0;
      this.chartResizeHandler();
    },
    onFilterTextInput(value) {
      this.filterText = value;
    },
    getDateOptionLabel(value) {
      return this.dateOptions.find(i => i.value === value)?.text || value;
    },
    getSpanOptionLabel(value) {
      return this.spanOptions.find(i => i.value === value)?.text || value;
    },
    enforcePinnedColumnOrders(api) {
      if (api == null || api.isDestroyed()) {
        return;
      }
      const columnState = api.getColumnState();
          
      let needUpdate = false;
      const rowSelectorIndex = columnState.findIndex(i => i.colId == 'rowSelector');
      if (rowSelectorIndex > -1) {
        if (rowSelectorIndex != 0) {
          columnState.splice(0, 0, columnState.splice(rowSelectorIndex, 1)[0]);
          needUpdate = true;
        }
      }

      const avatarRefIndex = columnState.findIndex(i => i.colId == 'avatarRef');
      if (avatarRefIndex > -1) {
        const expectedPosition = rowSelectorIndex == -1? 0 : 1;
        if (avatarRefIndex != expectedPosition) {
          columnState.splice(expectedPosition, 0, columnState.splice(avatarRefIndex, 1)[0]);
          needUpdate = true;
        }
      }

      const agGridAutoColumnIndex = columnState.findIndex(i => i.colId == self.COLUMN_AGGRID_AUTOCOLUMN);
      if (agGridAutoColumnIndex > -1) {
        let expectedPosition = 2;
        if (rowSelectorIndex == -1) {
          expectedPosition--;
        }
        if (avatarRefIndex == -1) {
          expectedPosition--;
        }
        if (agGridAutoColumnIndex != expectedPosition) {
          columnState.splice(expectedPosition, 0, columnState.splice(agGridAutoColumnIndex, 1)[0]);
          needUpdate = true;
        }
      }
      
      if (needUpdate) {
        api.applyColumnState({
          state: columnState,
          applyOrder: true
        })
      }
    },
    forceReload() {
      this.reload = false;
      this.gridReady = false;
      setTimeout(() => {
        this.reload = true;
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-toolbar {
  
  ul {
    margin: 0;
  }
}

.resource-action-bar {
  position: relative;
  
}

.resource-action-bar, .group-action-bar {
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  .d-flex {
    align-items: center;
  }
  li > span > label {
    margin: 12px 0;
  }
}

.tool-button {
  background: transparent;
  border: none;
  font-size: 1rem;  
}



.resource-grid-height {
  min-height: 300px;
  margin-bottom: 25px;
}

.mw-150 {
  max-width: 150px;
}

.minw-170 {
  min-width: 170px;
}

.task-list {
  overflow-x: auto;
}

::v-deep .ag-header-cell-text {
    overflow: visible;
    text-overflow: unset;
    white-space: pre;
    text-align: center;
}

.active-check {
  position: absolute;
  right: 10px;
}

.menu-chevron {
  position: absolute;
  right: 10px;
  top: 8px;
}

.group-action-bar span {
  padding: 5px;
}

.group-dropdown {
  margin: 0.5rem 0;
}

.grouping-icon {
  min-width: 18px;
}
</style>
<style>
.resource-action-bar #resourceUsageStartDate, .resource-action-bar #resourceUsageEndDate {
  font-size: 0.7rem !important;
}
#project-resource-grid .ag-root-wrapper {
  border-top: 0;
}

.search-input {
  padding-top: 2px;
  padding-bottom: 2px;
}

.overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.border-part {
  border-top: 1px solid var(--bs-border);
  border-left: 1px solid var(--bs-border);
  border-right: 1px solid var(--bs-border);
}

.settings-dropdown .dropdown-item {
  padding: 4px 20px;
}

.resource-action-bar .menu-toggler .btn-secondary {
  color: var(--grid-toolbar-button);
  background-color: transparent;
  border: none;
}

.resource-action-bar .dropdown.show > .dropdown-toggle {
  background-color: var(--grid-toolbar-dropdown-bg);
  color: var(--grid-toolbar-dropdown);
}

.alloc-dropdown .dropdown-menu {
  overflow: visible;
  max-height: 550px;
}

.search-append {
  line-height: 11px;
  font-size: 0.8rem !important;
}

.grid-toolbar .btn.btn-secondary.search-append-bg {
  margin: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: var(--form-control-addon-bg) !important;
}

.resource-action-bar .grid-toolbar .date-picker .btn-secondary {
  color: #212121;
  background-color: #E0E0E0;
}

.resource-action-bar .date-picker .btn-danger,
.resource-action-bar .date-picker .btn-primary { 
  margin: 8px 3px;
}

.min-width-units {
  min-width: 110px;
}

.sub-menu-dropdown {
  position: absolute;     
  top: -10px;       
  min-width: 10rem;
  visibility: hidden;
  left: 190px;
  padding: .5rem 0;
  margin: .125rem 0 0;
  color: var(--dropdown);
  text-align: left;
  list-style: none;
  background-color: var(--dropdown-bg);
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
}

@media only screen and (max-width: 1366px) {
  .sub-menu-dropdown {
      position: absolute;
      top: 30px;
      min-width: 10rem;
      visibility: hidden;
      left: -40px;
      padding: .5rem 0;
      margin: .125rem 0 0;
      color: var(--dropdown);
      text-align: left;
      list-style: none;
      background-color: var(--dropdown-bg);
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, .15);
      border-radius: .25rem;
      z-index: 10;
  }
}

.planner-locker {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
