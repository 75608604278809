
import { httpAjax } from '@/helpers';

export const bookingLinkStageService = {
  create,
  remove
};

/**
 * Create a new Link from booking to stage 
 * by passing necessary information
 * @param String bookingId, booking's UUID
 * @param Array stage, stage details. Data sample: { uuId }
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', stage: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx' } }
 */
function create(bookingId, stage) {
  const url = '/api/booking/link/stage/add';
  const config = {
    headers: getHeaders()
  };

  const data = {
    uuId: bookingId,
    stage
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from booking to stage.
 * by passing in an array list of links uuIds
 * @param String bookingId, booking's UUID
 * @param Array stageIds, list of stage's UUID 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', stage: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }
 */
function remove(bookingId, stage) {
  const url = '/api/booking/link/stage/delete';
  const config = {
    headers: getHeaders()
  };

  const data = {
    uuId: bookingId,
    stage
  }
  return httpAjax.post(url, data, config);
}


function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}