
import { httpAjax, urlParams } from '@/helpers';

export const compositeService = {
  exec
};

/**
 * Execute a composite request
 * by passing necessary information
 * @param Array data 
 * e.g [{
          "method": "GET",
          "invoke": "/api/task/get",
          "body": "[ { \"uuId\":\"@{var01}\" } ]",
          "vars": [
            {
              "name": "var02",
              "path": "$.objectList[0].uuId"
            }
          ],
            "note": "01 Get Task details",
            "skip": false
        }]
 */
function exec(data, fetch=false) {
  const url = `/api/composite?fetch=${fetch}`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}