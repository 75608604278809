<template>
  <div class="edit-cell">
      <input class='form-control' :ref="'input'" @focusout="onFocusOut" @keydown="onKeyDown($event)" v-model="value"/>
  </div>
</template>

<script>
import Vue from 'vue';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_ENTER = 'Enter';
const KEY_F2 = 113;
const KEYCODE_TAB = 9;
const KEYCODE_ENTER = 13;
const KEYCODE_LEFT = 37;
const KEYCODE_UP = 38;
const KEYCODE_RIGHT = 39;
const KEYCODE_DOWN = 40;


export default Vue.extend({
  name: 'NameEditor',
  data() {
    return {
      value: '',
      cancelBeforeStart: false,
      highlightAllOnFocus: true,
      isFirstKeyEnter: false,
      customProp: null
    };
  },
  methods: {
    getValue() {
      const originValue = (this.customProp != null)? this.params.data[this.customProp] : this.params.data.name;
      if (this.value !== originValue) {
        return this.value;
      }
      return originValue;
    },

    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },

    setInitialState(params) {
      let startValue;
      let highlightAllOnFocus = true;

      if (params.key === KEY_ENTER || params.charPress == null) {
        //Enter key pressed or click to edit
        this.isFirstKeyEnter = true;
      }

      if (params.keyPress === KEY_BACKSPACE || params.keyPress === KEY_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (params.charPress) {
        // if a letter was pressed, we start with the letter
        startValue = params.charPress;
        highlightAllOnFocus = false;
      } else {
        // otherwise we start with the current value
        if (this.customProp != null) {
          startValue = params.data[this.customProp];
        } else {
          startValue = params.data.name;
        }
        
        
        if (params.keyPress === KEY_F2) {
          highlightAllOnFocus = false;
        }
      }

      this.value = startValue;
      this.highlightAllOnFocus = highlightAllOnFocus;
    },

    onKeyDown(event) {
      const keyCode = this.getCharCodeFromEvent(event);
      const api = this.params.api;
      if (KEYCODE_ENTER == keyCode) {
        event.stopPropagation();
        event.preventDefault();
        if (event.shiftKey) {
          this.moveUp(api, this.params.rowIndex, this.params.column.colId);
        } else {
          this.moveDown(api, this.params.rowIndex, this.params.column.colId);
        }
      } else if (KEYCODE_TAB == keyCode) {
        event.stopPropagation();
        event.preventDefault();
        if (event.shiftKey) {
          api.tabToPreviousCell();
        } else {
          api.tabToNextCell();
        }
      } else if (this.isNavigationKey(event) && !this.isFirstKeyEnter) {
        event.stopPropagation();
        event.preventDefault();
        if (KEYCODE_LEFT == keyCode ) {
          api.tabToPreviousCell();
        }
        else if (KEYCODE_RIGHT == keyCode) {
          api.tabToNextCell();
        }
        else if (KEYCODE_UP == keyCode) {
          this.moveUp(api, this.params.rowIndex, this.params.column.colId);
        }
        else if (KEYCODE_DOWN == keyCode) {
          this.moveDown(api, this.params.rowIndex, this.params.column.colId);
        }
        return;
      }
      
      if (this.deleteOrBackspace(event)) {
        event.stopPropagation();
        return;
      }
    },

    getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    },

    deleteOrBackspace(event) {
      return (
        [KEY_DELETE, KEY_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) >
        -1
      );
    },

    isLeftOrRight(event) {
      return [37, 39].indexOf(this.getCharCodeFromEvent(event)) > -1;
    },

    isNavigationKey(event) {
      const keyCode = this.getCharCodeFromEvent(event);
      return keyCode >= 37 && keyCode <= 40;
    },

    onFocusOut() {
      this.params.api.stopEditing();
    },

    moveUp(api, pRowIndex, pColId) {
      setTimeout(() => {
        let rowIndex = pRowIndex - 1;
        const colId = pColId;
        if (rowIndex < 0) {
          rowIndex = 0;
        }
        api.clearRangeSelection();
        api.setFocusedCell(rowIndex, colId, null);
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        });
      })
    },

    moveDown(api, pRowIndex, pColId) {
      setTimeout(() => {
        let rowIndex = pRowIndex + 1;
        const colId = pColId;
        if (api.getDisplayedRowAtIndex(rowIndex) == null) {
          rowIndex = this.params.rowIndex;
        }
        api.clearRangeSelection();
        api.setFocusedCell(rowIndex, colId, null);
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        });
      })
    }
  },

  created() {
    if (Object.hasOwn(this.params, 'customProp')) {
      this.customProp = this.params.customProp;
    }
    this.setInitialState(this.params);
  },
  mounted() {
    Vue.nextTick(() => {
      // need to check if the input reference is still valid - if the edit was cancelled before it started there
      // wont be an editor component anymore
      if (this.$refs.input) {
        this.$refs.input.focus();
        if (this.highlightAllOnFocus) {
          this.$refs.input.select();

          this.highlightAllOnFocus = false;
        } else {
          // when we started editing, we want the caret at the end, not the start.
          // this comes into play in two scenarios: a) when user hits F2 and b)
          // when user hits a printable character, then on IE (and only IE) the caret
          // was placed after the first character, thus 'apply' would end up as 'pplea'
          const length = this.$refs.input.value
            ? this.$refs.input.value.length
            : 0;
          if (length > 0) {
            this.$refs.input.setSelectionRange(length, length);
          }
        }

        this.$refs.input.focus();
      }
    });
  }
})
</script>