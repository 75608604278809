
import { httpAjax, urlParams } from '@/helpers';
import { getPermissionDenyProperties } from '@/helpers/permission'
import { getKeysWithoutRedactedFields } from './common'
import { countryCodes } from "@/selectOptions";

export const locationService = {
  query,
  create,
  list,
  update,
  remove,
  calendar,
  calendarList,
  clone,
  listNames,
  listUniqueValuesOfProperty
};

/**
 * Retrieving a list of location details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
 function query(data, links = null) {
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(urlParams('/api/location/get', links), data, config);
}

/**
 * Create a new location 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', fullName: 'John Doe' ... }]
 */
function create(data) {
  const url = '/api/location/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function isActive(start, end) {
  const now = new Date();
  
  return (now.getTime() > start && now.getTime() < end);
}

/**
 * Retrieving a concise list of location info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='fullName', order: 'incr' }}
 */
function list(params, count = false, customFields=[]) {
  const fields = {
    uuId: 'LOCATION.uuId', 
    name: 'LOCATION.name', 
    countryCode: 'LOCATION.countryCode', 
    region: 'LOCATION.region',
    town: 'LOCATION.town',
    street: 'LOCATION.street',
    postcode: 'LOCATION.postcode',
    identifier: 'LOCATION.identifier',
    color: 'LOCATION.color',
    tag: 'LOCATION.TAG.name',
    rebateName: 'LOCATION.REBATE.name',
    rebateUuId: 'LOCATION.REBATE.uuId',
    rebateRebate: 'LOCATION.REBATE.rebate',
    staffList: "LOCATION.STAFF.uuId",
    genericStaff: "LOCATION.STAFF.genericStaff",
    quota: "LOCATION.STAFF.resourceQuota",
    startDate: "LOCATION.STAFF.startDate",
    endDate: "LOCATION.STAFF.endDate",
    readOnly: "LOCATION.readOnly"
  }
  
  if (count) {
    fields['count'] = ["=countChildren(A,B)", ["LOCATION"],["NODE(in)"] ];
  }

  if (Array.isArray(customFields) && customFields.length > 0) {
    for(const cField of customFields) {
      fields[cField.name] = `LOCATION.${cField.name}`;
    }
  }

  //When params.fields are provided, clean up the selectors which don't appear in the param.fields.
  if (Array.isArray(params.fields) && params.fields.length > 0) {
    const fieldProps = Object.keys(fields);
    for (const prop of fieldProps) {
      if (prop == 'uuId') {
        continue;
      }
      if (!params.fields.includes(prop)) {
        delete fields[prop];
      }
    }
  }
  
  let data = {
    'name'  : 'Location List'
    ,'type' : 'msql'
    ,'nominate': 'LOCATION'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if (params.holder) {
    data['holder'] = params.holder;
  }
  
  if(params.filter && params.filter.length > 0) {

    const matchColumns = [
      ['LOCATION.name', 'has', params.filter]
    ]

    const locationDeniedProperties = getPermissionDenyProperties('LOCATION','VIEW')
    if (!locationDeniedProperties.includes('region')) {
      matchColumns.push(['LOCATION.region', 'has', params.filter])
    }
    if (!locationDeniedProperties.includes('town')) {
      matchColumns.push(['LOCATION.town', 'has', params.filter])
    }
    if (!locationDeniedProperties.includes('identifier')) {
      matchColumns.push(['LOCATION.identifier', 'has', params.filter])
    }
    if (!locationDeniedProperties.includes('street')) {
      matchColumns.push(['LOCATION.street', 'has', params.filter])
    }
    if (!locationDeniedProperties.includes('postcode')) {
      matchColumns.push(['LOCATION.postcode', 'has', params.filter])
    }

    const tagDeniedProperties = getPermissionDenyProperties('TAG', 'VIEW')
    if (!locationDeniedProperties.includes('TAG') && 
        !locationDeniedProperties.includes('TAG.name') && 
        !tagDeniedProperties.includes('name') ) {
      matchColumns.push(['LOCATION.TAG.name', 'has', params.filter])
    }
    
    if (Array.isArray(customFields) && customFields.length > 0) {
      for (const c of customFields) {
        if ((c.type == 'String' || c.type == 'Enum<String>') && !locationDeniedProperties.includes(c.name)) {
          matchColumns.push([`LOCATION.${c.name}`, 'has', params.filter])
        }
      }
    }


    data['filter'] = [
      '_or_', matchColumns
    ]
  }

  //BadgeFilter related
  if (Array.isArray(params.badgeFilters) && params.badgeFilters.length > 0) {
    const badgeFilterList = [];
    for (const f of params.badgeFilters) {
      if (f.field == null || !Array.isArray(f.value) || f.value.length == 0) {
        continue;
      }
      
      let field = null;
      if (f.field == 'tagName') {
        field = 'LOCATION.TAG.name';
      } else if (f.field == 'rebateName') {
        field = 'LOCATION.REBATE.name';
      } else {
        field = `LOCATION.${f.field}`;
      } 

      const isEqual = !f.operator || f.operator === 'is';
      let valueList = [];
      const value = f.value;
      for (const v of value) {
        if (v.text != null && (typeof v.text === 'number' || (typeof v.text === 'string' && v.text&& v.text.length > 0 && v.text !== '(Empty)'))) {
          //use value when exists. Otherwise use text
          //Reason: When value property exists, it means the text is localized and can't be used in filter.
          valueList.push([field, 'eq', Object.hasOwn(v, 'value')? v.value : v.text]);
        }
        //Start processing (EMPTY) field value 
        else if (isEqual) {
          valueList.push('_not_', [[field]])
        } else {
          //Special handling: when is not empty, put it into badgeFilterList directly to act as 'field must exist'. 
          badgeFilterList.push([field])
        }
      }
      if (valueList.length > 0) {
        if (valueList.length > 1) {
          valueList = ['_or_', valueList];
        }
        if (!isEqual) {
          badgeFilterList.push('_not_', valueList);
        } else {
          badgeFilterList.push(...valueList);
        }
      }
    }
    if (badgeFilterList.length > 0) {
      if (Array.isArray(data.filter) && data.filter.length > 0) {
        data.filter = [...data.filter, ...badgeFilterList]
      } else {
        data.filter = [...badgeFilterList]
      }
    }
  }

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i], '', 'lowerCase']);
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order, '', 'lowerCase']);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.holder;
  delete params.badgeFilters;
  delete params.fields;
  
  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          if (keys[j] === 'countryCode') {
            result['countryName'] = i[j] !== '' ? countryCodes.filter(c => i[j] === c.value)[0].text : '';
          }
          result[keys[j]] = i[j];
        }

        // preprocess rebates
        result.rebates = [];
        if (Array.isArray(result.rebateName) && Array.isArray(result.rebateUuId)) {
          for (let i = 0; i < result.rebateName.length; i++) {
            result.rebates.push({
              name: result.rebateName[i],
              uuId: result.rebateUuId[i],
              rebate: result.rebateRebate[i]
            });
          }
        }
        
        result.staffCount = 0;
        result.genericStaffCount = 0;
        if (Array.isArray(result.genericStaff)) {
          for (let x = 0, len = result.genericStaff.length; x < len; x++) {
            if (result.genericStaff[x] !== true) {
              if (isActive(result.startDate[x], result.endDate[x])) {
                result.staffCount++;
              }
            }
            else {
              result.genericStaffCount += result.quota[x];
            }
          }
        }
        
        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

/**
 * Retrieving a list of staff calendars
 */
function calendar(uuId) {
  const url = `/api/location/${uuId}/calendar`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config);
}

/**
 * Retrieving a list of calendars by providing a list of locationId [{ uuId }]
 */
 function calendarList(data) {
  const url = `/api/location/calendar`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Update location details 
 * by passing  necessary information
 * @param Array data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', fullName: 'John Doe' ... }]
 */
function update(data) {
  const url = '/api/location/update';
  const config = {
    headers: getHeaders()
  }
  data['holder'] = data.uuId;
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of locations
 * by passing in an array list of uuIds
 * @param Array data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = '/api/location/delete';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}

/**
 * Clone a location with given new name
 * @param String refId Target location UuId
 * @param Object data Object contains name for cloned location.
 */
function clone(refId, data) {
  const url = '/api/location/clone';
  const config = {
    data: data,
    headers: getHeaders(),
    params: { reference: refId }
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of LOCATION info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
function listNames(params) {
  const fields = {
    uuId: 'LOCATION.uuId',
    name: 'LOCATION.name'
  }
  

  let data = {
    'name'  : 'LOCATION Name List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'LOCATION'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

function listUniqueValuesOfProperty(field) {
  const fieldSelector = [`LOCATION.${field}`]
  
  const data = {
    'name'  : `Unique LOCATION property: ${field}`
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'select': [
      fieldSelector
    ]
    , 'dedup': true
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    let rawData = response.data[listName] || [];
    rawData = rawData.map(i => i[0]);
    rawData.sort((a, b) => {
      if (typeof a === 'string') {
        return a.localeCompare(b, undefined, { sensitivity: 'base'})
      } else {
        if (a < b) {
          return -1
        } else if (a > b) {
          return 1
        }
        return 0
      }
    });
    return rawData;
  });
}
