var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.label &&
    typeof _vm.label === "string" &&
    (_vm.label.startsWith("https://") || _vm.label.startsWith("http://"))
      ? _c("a", { attrs: { href: _vm.label, target: "_blank" } }, [
          _vm._v(_vm._s(_vm.label)),
        ])
      : _c("span", [_vm._v(_vm._s(_vm.label))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }