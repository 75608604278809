import { fileService } from '@/services';

export function forceFileDownload(toDownload, self) {
  self.alertMsg = self.successMsg = null;
  self.downloadProgressShow = true;
  self.downloadPercentage = 0;
  self.downloadCancelled = false;
  self.downloadCancelTokenSource = fileService.getCancelTokenSource();
  if (toDownload.length === 1) {
    fileService.download(toDownload[0], function(progressEvent) {
      self.downloadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
    }, self.downloadCancelTokenSource.token).then((response) => { 
      const url = window.URL.createObjectURL(new Blob([response.data], {type: toDownload[0].type})); 
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', toDownload[0].name); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      self.downloadProgressShow = false;
      self.successMsg = self.$i18n.t('file.download');
    })
    .catch(() => {
      self.alertMsg = self.$i18n.t(self.downloadCancelled? 'file.download_cancelled': 'file.error.failed_to_download');
      self.downloadProgressShow = false;
    })
    .then(() => {
      self.downloadCancelTokenSource = null;
    });
  }
  else {
    // multidownload
    fileService.multidownload(toDownload, function(progressEvent) {
      const total = progressEvent.srcElement.getResponseHeader('x-contentSize') || 0;
      self.downloadPercentage = parseInt( Math.round( ( progressEvent.loaded / total ) * 100 ));
    }, self.downloadCancelTokenSource.token).then((response) => {
      self.downloadPercentage = 100;
      const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'})); 
      const link = document.createElement('a');
      link.href = url;
      const filename = typeof response.headers['content-disposition'] !== 'undefined' ? response.headers['content-disposition'] : 'archive.zip';
      link.setAttribute('download', filename); 
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      self.downloadProgressShow = false;
      self.successMsg = self.$i18n.t('file.download');
    })
    .catch(() => {
      self.alertMsg = self.$i18n.t(self.downloadCancelled? 'file.download_cancelled': 'file.error.failed_to_download');
      self.downloadProgressShow = false;
    })
    .then(() => {
      self.downloadCancelTokenSource = null;
    });
  }
  
}