<template>
  <span class='grid-overlay'>{{ params.msgFunc() }}</span>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'NoRowsOverlay',
  data() {
    return {}
  },
  beforeMount() {

  },
  methods: {
    refresh(params) {
      this.params = params;
    },
  }
})
</script>

<style>

</style>