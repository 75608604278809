function getTextWidth(text, font, self) {
  // re-use canvas object for better performance
  const canvas = self.canvas || (self.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

function getCssStyle(element, prop) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function getCanvasFont(el = document.body) {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';
  
  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

export function isEllipsisActive(text, self, id = null) {
  let elem = self.$el;
  if (id) {
    elem = document.getElementById(id);
  }
  
  if (elem) {
    const e = getTextWidth(text, getCanvasFont(elem), self);
  
    return e > 200;
  }
  return false;
}