import { profileService } from '@/services';

const categoryKey = 'color';
const folderKey = 'company';


export const colorProfileService = {
  create,
  list,
  update,
  remove,
};

function create(data, companyId) {
  return profileService.create(data, categoryKey, folderKey, companyId);
}

function list(companyId) {
  return profileService.list(categoryKey, folderKey, companyId);
}

function update(data, companyId) {
  return profileService.update(data, categoryKey, folderKey, companyId);
}

function remove(data, companyId) {
  return profileService.remove(data, categoryKey, folderKey, companyId);
}