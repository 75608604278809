
import { cloneDeep } from 'lodash';
import { companyService } from '@/services';
import { EventBus } from '@/helpers';
import store from '@/store'

function createExportJson(p) {
  const exportDataview = cloneDeep(p);
  delete exportDataview.uuId;
  exportDataview.sharedVisibility = 'private';
  delete exportDataview.sharingMembers;
  delete exportDataview.editingPermissions;
  var json = JSON.stringify(exportDataview);
  var blob = new Blob([json], {type: "application/json"});
  return window.URL.createObjectURL(blob);
}

export default {
  items: [
    {
      key: 'DASHBOARD',
      name: 'sidebar.home',
      url: '/dashboard',
      icon: ['far', 'house'],
    },
    {
      key: 'PROJECT',
      name: 'sidebar.projects',
      url: '/projects',
      icon: ['far', 'chart-network'],
      regex: '^\\/projects\\/(?:resource|staff|gantt|tasks|kanban|summary)\\/([0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12})$',
      attributes: { disabled: false },
      class: 'indent-menu sub-menu',
      children: [
        {
          key: 'PROJECT_SUMMARY',
          name: 'sidebar.dashboard',
          url: '/projects/summary',
          icon: ['far', 'chart-line'],
          attributes: { disabled: false }
        },
        {
          key: 'PROJECT_TASKS',
          name: 'sidebar.tasks',
          url: '/projects/tasks',
          icon: ['far', 'table-list'],
          permission: 'TASK__VIEW',
          attributes: { disabled: false }
        },
        {
          key: 'PROJECT_GANTT',
          name: 'sidebar.gantt',
          url: '/projects/gantt',
          icon: ['far', 'bars-staggered'],
          permission: 'TASK__VIEW',
          attributes: { disabled: false },
        },
        {
          key: 'PROJECT_STAFF',
          name: 'sidebar.staff_usage',
          url: '/projects/staff',
          icon: ['far', 'user-helmet-safety'],
          permission: 'STAFF__VIEW',
          attributes: { disabled: false },
        },
        /*{
          key: 'PROJECT_RESOURCE',
          name: 'sidebar.resource_usage',
          url: '/projects/resource',
          icon: ['far', 'cart-flatbed-boxes'],
          permission: 'RESOURCE__VIEW',
          attributes: { disabled: false },
        },*/
        {
          key: 'PROJECT_BOARD',
          name: 'sidebar.board',
          url: '/projects/kanban',
          icon: ['far', 'table-cells-large'],
          permission: 'TASK__VIEW',
          attributes: { disabled: false },
        },
      ]
    },
    {
      key: 'STAFF',
      name: 'sidebar.planner',
      url: '/planner/',
      icon: ['far', 'calendar-days'],
      attributes: { disabled: false },
      class: 'indent-menu sub-menu',
      children: [
        {
          key: 'STAFF_PLANNER',
          name: 'sidebar.staff',
          url: '/planner/',
          icon: ['far', 'user-helmet-safety'],
          permission: 'STAFF__VIEW',
          attributes: { disabled: false }
        },
        {
          key: 'RESOURCE_PLANNER',
          name: 'sidebar.resource',
          url: '/planner/resources',
          icon: ['far', 'cart-flatbed-boxes'],
          permission: 'RESOURCE__VIEW',
          attributes: { disabled: false }
        }
      ]
    },
    {
      key: 'ADMIN',
      name: 'sidebar.management',
      url: '/manage/',
      icon: ['far', 'gears'],
      regex: '^/manage/template/(?:staff|gantt|tasks)/([0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12})$',
      attributes: { disabled: false },
      class: 'indent-menu sub-menu',
      children: [
        {
          key: 'TEMPLATE_TASKS',
          name: 'sidebar.tasks',
          url: '/manage/template/tasks',
          icon: ['far', 'table-list'],
          permission: 'TEMPLATE__TASK__VIEW',
          attributes: { disabled: false }
        },
        {
          key: 'TEMPLATE_GANTT',
          name: 'sidebar.gantt',
          url: '/manage/template/gantt',
          icon: ['far', 'bars-staggered'],
          permission: 'TEMPLATE__TASK__VIEW',
          attributes: { disabled: false },
        }
      ]
    }
  ],
  dynamicItems: [
    {
      profileid: 'private_dataviews',
      key: 'DATAVIEW',
      name: 'sidebar.dataviews',
      url: '/dataview',
      icon: ['far', 'filter'],
      attributes: { disabled: false },
      class: 'indent-menu-text sub-menu',
      keepopen: true,
      order: 0,
      actions: [{ icon: ['far', 'plus'], id: 'ADD_PRIVATE_DATAVIEW', name: 'dataview.add', url: null, params: { isPublic: false }, emitevent: 'dataview-add', permission: 'DATAVIEW__ADD' },
        { 
          icon: ['far', 'ellipsis-vertical'], 
          id: 'IMPORT_PRIVATE_DATAVIEW', 
          items: 
          [
            {
              name: 'dataview.import', 
              url: null, 
              params: { isPublic: false }, 
              emitevent: 'dataview-import'
            },
            {
              name: 'dataview.create_folder', 
              url: null, params: { isPublic: false }, 
              emitevent: 'dataview-create-folder'
            },
            {
              name: 'dataview.manage_folders', 
              url: null, 
              params: { 
                isPublic: false
              }, 
              emitevent: 'dataview-manage-folders'
            }
          ], permission: 'DATAVIEW__ADD' }],
      children: [],
      childrenFunc: function (func) {
        const userId = store.state.authentication.user.uuId;
        store.dispatch('data/dataViewProfileList', userId).then((response) => {
          const profileData = response;
          const privateProfiles = profileData.filter(f => f.sharedVisibility !== 'public').sort(function (a, b) {
            if (a.type === 'folder' && b.type !== 'folder') {
              return -1;
            }
            else if (b.type === 'folder' && a.type !== 'folder') {
              return 1;
            }
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
          func(privateProfiles.map(p => 
          { 
            return p.type !== 'folder' ? { 
              key: 'DATAVIEW', 
              uuId: p.uuId, 
              name: p.name, 
              no_translate: true,
              icon: ['far','file-spreadsheet'], 
              url: `/dataview/${p.uuId}`, 
              parent: p.parent,
              actions: [
                { 
                  icon: ['far', 'ellipsis-vertical'], 
                  id: 'IMPORT_PRIVATE_DATAVIEW', 
                  items: [
                    {
                      name: 'dataview.edit', 
                      url: null, 
                      params: { 
                        isPublic: false,
                        profile: p
                      }, 
                      emitevent: 'dataview-edit'
                    },
                    {
                      name: 'dataview.copy', 
                      url: null, 
                      params: { 
                        isPublic: false,
                        profile: p
                      }, 
                      emitevent: 'dataview-copy'
                    },
                    {
                      name: 'dataview.delete', 
                      url: null, 
                      params: { 
                        isPublic: false,
                        uuId: p.uuId
                      }, 
                      emitevent: 'dataview-delete'
                    },
                    {
                      name: 'dataview.export', 
                      url: createExportJson(p), 
                      params: { 
                        isPublic: false,
                        download:`${p.name}.json`,
                        profile: p
                      }
                    }
                  ], 
                  permission: 'DATAVIEW__ADD' 
                }
              ]
            } : 
            { 
              key: 'DATAVIEW', 
              uuId: p.uuId, 
              name: p.name, 
              no_translate: true,
              icon: ['far','folder'], 
              params: {group: p.uuId}, 
              emitevent: 'dataview-set-group', 
              parent: p.parent,
              actions: [
                { 
                  icon: ['far', 'ellipsis-vertical'], 
                  id: 'EDIT_PRIVATE_FOLDER', 
                  items: [
                    {
                      name: 'dataview.edit_folder', 
                      url: null, 
                      params: { 
                        isPublic: false,
                        profile: p 
                      }, 
                      emitevent: 'dataview-edit-folder'
                    },
                    {
                      name: 'dataview.delete_folder', 
                      url: null, 
                      params: { 
                        isPublic: false,
                        uuId: p.uuId
                      }, 
                      emitevent: 'dataview-delete-folder'
                    },
                    {
                      name: 'dataview.manage_folders', 
                      url: null, 
                      params: { 
                        isPublic: false,
                        profile: p
                      }, 
                      emitevent: 'dataview-manage-folders'
                    }
                  ], 
                  permission: 'DATAVIEW__ADD' 
                }
              ],
            } 
          }), 
          'private');

          EventBus.$emit('profiles-loaded');
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
        });

        return [{ key: 'DATAVIEW', name: 'Loading...' }];
      }
    },
    {
      profileid: 'public_dataviews',
      key: 'DATAVIEW',
      name: 'sidebar.public_dataviews',
      url: '/dataview',
      icon: ['fas', 'filter'],
      attributes: { disabled: false },
      class: 'indent-menu-text sub-menu',
      keepopen: true,
      order: 1,
      actions: [
        { 
          icon: ['far', 'plus'], 
          id: 'ADD_PUBLIC_DATAVIEW', 
          name: 'dataview.add_public', 
          url: null, 
          params: { isPublic: true }, 
          emitevent: 'dataview-add', 
          permission: 'DATAVIEW__ADD' 
        },
        { 
          icon: ['far', 'ellipsis-vertical'], 
          id: 'IMPORT_PRIVATE_DATAVIEW', 
          items: 
          [
            {
              name: 'dataview.create_folder', 
              url: null, 
              params: { 
                isPublic: true
              }, 
              emitevent: 'dataview-create-folder'
            },
            {
              name: 'dataview.manage_folders', 
              url: null, 
              params: { 
                isPublic: true
              }, 
              emitevent: 'dataview-manage-folders'
            }
          ], 
          permission: 'DATAVIEW__ADD' 
        }
      ],
      children: [],
      childrenFunc: function (func) {
        if (!localStorage.companyId) {
          companyService.list({ limit: -1, start: 0 }).then((response) => {
            const data = response.data;
            const company = data.filter(d => d.type === 'Primary');
            if (company.length > 0) {
              localStorage.companyId = company[0].uuId;
              store.dispatch('data/dataViewProfileListPublic', company[0].uuId).then((response) => {
                const profileData = response.sort(function (a, b) {
                  if (a.type === 'folder' && b.type !== 'folder') {
                    return -1;
                  }
                  else if (b.type === 'folder' && a.type !== 'folder') {
                    return 1;
                  }
                  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                });
                func(profileData.map(p => { 
                  return p.type !== 'folder' ? 
                    typeof p.editingPermissions === 'undefined' ||
                    p.editingPermissions.includes(store.state.authentication.user.uuId) ? 
                    { 
                      key: 'DATAVIEW', 
                      uuId: p.uuId, 
                      name: p.name, 
                      no_translate: true,
                      icon: ['far','file-spreadsheet'], 
                      url: `/dataview/public/${p.uuId}`, 
                      parent: p.parent,
                      actions: [
                        { 
                          icon: ['far', 'ellipsis-vertical'], 
                          id: 'IMPORT_PRIVATE_DATAVIEW', 
                          items: [
                            {
                              name: 'dataview.edit', 
                              url: null, 
                              params: { 
                                isPublic: false,
                                profile: p
                              }, 
                              emitevent: 'dataview-edit'
                            },
                            {
                              name: 'dataview.copy', 
                              url: null, 
                              params: { 
                                isPublic: false,
                                profile: p
                              }, 
                              emitevent: 'dataview-copy'
                            },
                            {
                              name: 'dataview.delete', 
                              url: null, 
                              params: { 
                                isPublic: false,
                                uuId: p.uuId
                              }, 
                              emitevent: 'dataview-delete'
                            },
                            {
                              name: 'dataview.export', 
                              url: createExportJson(p), 
                              params: { 
                                isPublic: false,
                                download:`${p.name}.json`,
                                profile: p
                              }
                            }
                          ], 
                          permission: 'DATAVIEW__ADD' 
                        }
                      ]
                  } :
                  { 
                    key: 'DATAVIEW', 
                    uuId: p.uuId, 
                    name: p.name, 
                    no_translate: true,
                    icon: ['far','file-spreadsheet'], 
                    url: `/dataview/public/${p.uuId}`, 
                    parent: p.parent,
                    actions: [
                      { 
                        icon: ['far', 'ellipsis-vertical'], 
                        id: 'IMPORT_PRIVATE_DATAVIEW', 
                        items: [
                          {
                            name: 'dataview.copy', 
                            url: null, 
                            params: { 
                              isPublic: false,
                              profile: p
                            }, 
                            emitevent: 'dataview-copy'
                          },
                          {
                            name: 'dataview.export', 
                            url: createExportJson(p), 
                            params: { 
                              isPublic: false,
                              download:`${p.name}.json`,
                              profile: p
                            }
                          }
                        ], 
                        permission: 'DATAVIEW__ADD' 
                      }
                    ]
                  } : 
                  typeof p.editingPermissions === 'undefined' ||
                  p.editingPermissions.includes(store.state.authentication.user.uuId) ? 
                  { 
                    key: 'DATAVIEW', 
                    uuId: p.uuId, 
                    name: p.name, 
                    no_translate: true,
                    icon: ['far','folder'], 
                    params: {group: p.uuId}, 
                    emitevent: 'dataview-public-set-group', 
                    parent: p.parent,
                    actions: [
                      { 
                        icon: ['far', 'ellipsis-vertical'], 
                        id: 'EDIT_PUBLIC_FOLDER', 
                        items: [
                          {
                            name: 'dataview.edit_folder', 
                            url: null, 
                            params: { 
                              isPublic: true,
                              profile: p 
                            }, 
                            emitevent: 'dataview-edit-folder'
                          },
                          {
                            name: 'dataview.delete_folder', 
                            url: null, 
                            params: { 
                              isPublic: true,
                              uuId: p.uuId
                            }, 
                            emitevent: 'dataview-delete-folder'
                          },
                          {
                            name: 'dataview.manage_folders', 
                            url: null, 
                            params: { 
                              isPublic: true,
                              profile: p
                            }, 
                            emitevent: 'dataview-manage-folders'
                          }
                        ], 
                        permission: 'DATAVIEW__ADD' 
                      }
                    ]
                  } :
                  { 
                    key: 'DATAVIEW', 
                    uuId: p.uuId, 
                    name: p.name, 
                    no_translate: true,
                    icon: ['far','folder'], 
                    params: {group: p.uuId}, 
                    emitevent: 'dataview-public-set-group', 
                    parent: p.parent,
                    actions: [
                      { 
                        icon: ['far', 'ellipsis-vertical'], 
                        id: 'EDIT_PUBLIC_FOLDER', 
                        items: [
                          {
                            name: 'dataview.manage_folders', 
                            url: null, 
                            params: { 
                              isPublic: true,
                              profile: p
                            }, 
                            emitevent: 'dataview-manage-folders'
                          }
                        ], 
                        permission: 'DATAVIEW__ADD' 
                      }
                    ]
                  } 
                }), 'public');
                EventBus.$emit('profiles-loaded');
              })
              .catch((e) => {
                console.log(e); // eslint-disable-line no-console
              });
            }
          })
            .catch((e) => {
              console.log(e); // eslint-disable-line no-console
              return null;
            });
        }
        else {
          store.dispatch('data/dataViewProfileListPublic', localStorage.companyId).then((response) => {
            const profileData = response.sort(function (a, b) {
              if (a.type === 'folder' && b.type !== 'folder') {
                return -1;
              }
              else if (b.type === 'folder' && a.type !== 'folder') {
                return 1;
              }
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
            func(profileData.map(p => 
            { 
              return p.type !== 'folder' ? 
                typeof p.editingPermissions === 'undefined' ||
                p.editingPermissions.includes(store.state.authentication.user.uuId) ?
                { 
                  key: 'PROJECT', 
                  uuId: p.uuId, 
                  name: p.name, 
                  no_translate: true,
                  icon: ['far','file-spreadsheet'], 
                  url: `/dataview/public/${p.uuId}`, 
                  parent: p.parent,
                  actions: [
                    { 
                      icon: ['far', 'ellipsis-vertical'], 
                      id: 'IMPORT_PUBLIC_DATAVIEW', 
                      items: [
                        {
                          name: 'dataview.edit', 
                          url: null, 
                          params: { 
                            isPublic: true,
                            profile: p
                          }, 
                          emitevent: 'dataview-edit'
                        },
                        {
                          name: 'dataview.copy', 
                          url: null, 
                          params: { 
                            isPublic: true,
                            profile: p
                          }, 
                          emitevent: 'dataview-copy'
                        },
                        {
                          name: 'dataview.delete', 
                          url: null, 
                          params: { 
                            isPublic: true,
                            uuId: p.uuId
                          }, 
                          emitevent: 'dataview-delete'
                        },
                        {
                          name: 'dataview.export', 
                          url: createExportJson(p), 
                          params: { 
                            isPublic: true,
                            download:`${p.name}.json`,
                            profile: p
                          }
                        }
                      ], 
                      permission: 'DATAVIEW__ADD' 
                    }
                  ]
                } :
                { 
                  key: 'PROJECT', 
                  uuId: p.uuId, 
                  name: p.name, 
                  no_translate: true,
                  icon: ['far','file-spreadsheet'], 
                  url: `/dataview/public/${p.uuId}`, 
                  parent: p.parent,
                  actions: [
                    { 
                      icon: ['far', 'ellipsis-vertical'], 
                      id: 'IMPORT_PUBLIC_DATAVIEW', 
                      items: [
                        {
                          name: 'dataview.copy', 
                          url: null, 
                          params: { 
                            isPublic: true,
                            profile: p
                          }, 
                          emitevent: 'dataview-copy'
                        },
                        {
                          name: 'dataview.export', 
                          url: createExportJson(p), 
                          params: { 
                            isPublic: true,
                            download:`${p.name}.json`,
                            profile: p
                          }
                        }
                      ], 
                      permission: 'DATAVIEW__ADD' 
                    }
                  ]
                } : 
                typeof p.editingPermissions === 'undefined' ||
                p.editingPermissions.includes(store.state.authentication.user.uuId) ?  
                { 
                  key: 'DATAVIEW', 
                  uuId: p.uuId, 
                  name: p.name, 
                  no_translate: true,
                  icon: ['far','folder'], 
                  params: {group: p.uuId}, 
                  emitevent: 'dataview-public-set-group', 
                  parent: p.parent,
                  actions: [
                    { 
                      icon: ['far', 'ellipsis-vertical'], 
                      id: 'EDIT_PRIVATE_FOLDER', 
                      items: [
                        {
                          name: 'dataview.edit_folder', 
                          url: null, 
                          params: { 
                            isPublic: true,
                            profile: p 
                          }, 
                          emitevent: 'dataview-edit-folder'
                        },
                        {
                          name: 'dataview.delete_folder', 
                          url: null, 
                          params: { 
                            isPublic: true,
                            uuId: p.uuId
                          }, 
                          emitevent: 'dataview-delete-folder'
                        },
                        {
                          name: 'dataview.manage_folders', 
                          url: null, 
                          params: { 
                            isPublic: true,
                            profile: p
                          }, 
                          emitevent: 'dataview-manage-folders'
                        }
                      ], 
                      permission: 'DATAVIEW__ADD' 
                    }
                  ]
                } :
                { 
                  key: 'DATAVIEW', 
                  uuId: p.uuId, 
                  name: p.name, 
                  no_translate: true,
                  icon: ['far','folder'], 
                  params: {group: p.uuId}, 
                  emitevent: 'dataview-public-set-group', 
                  parent: p.parent,
                  actions: [
                    { 
                      icon: ['far', 'ellipsis-vertical'], 
                      id: 'EDIT_PRIVATE_FOLDER', 
                      items: [
                        {
                          name: 'dataview.manage_folders', 
                          url: null, 
                          params: { 
                            isPublic: true,
                            profile: p
                          }, 
                          emitevent: 'dataview-manage-folders'
                        }
                      ], 
                      permission: 'DATAVIEW__ADD' 
                    }
                  ]
                }
              }), 'public');
            EventBus.$emit('profiles-loaded');
          })
          .catch((e) => {
            localStorage.removeItem('companyId');
            console.log(e); // eslint-disable-line no-console
          });
        }
        return [{ key: 'PROJECT', name: 'Loading...' }];
      }
    }
  ]
}
