export function getKeysWithoutRedactedFields(fields, response) {
  let keys = [];
  const redacted = Array.isArray(response.data.redacted)? response.data.redacted : [];
  for (const [key, value] of Object.entries(fields)) {
    if (redacted.includes(Array.isArray(value)? (Array.isArray(value[0]) ? value[0][0] : value[0]) : value)) {
      continue;
    }
    keys.push(key);
  }
  return keys
}

export function getRedactedFields(fields, response) {
  let keys = [];
  const redacted = Array.isArray(response.data.redacted)? response.data.redacted : [];
  for (const [key, value] of Object.entries(fields)) {
    if (redacted.includes(Array.isArray(value)? value[0]: value)) {
      keys.push(key)
    }
  }
  return keys
}