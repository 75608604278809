export function setReadOnlyIfNotEditable(params) {
  if (params == null || params.colDef == null || params.enableReadonlyStyle != true) {
    return;
  }
  
  let isEditable = false;
  if (typeof params.colDef.editable == 'function') {
    isEditable = params.colDef.editable({
      node: params.node
      , data: params.data
      , column: params.column
      , colDef: params.colDef
      , api: params.api
      , columnApi: params.columnApi
      , context: params.context
    });
  } else {
    isEditable = params.colDef.editable == true? true : false;
  }
  
  if (isEditable) {
    return;
  }
  params.eGridCell.classList.add('read-only');
}