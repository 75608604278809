/* 
  import of DHTMLX Gantt is required.
*/
export const ganttConfigUtil = {
  create
}

function create(g, customConfigFunc) {
  g.config.server_utc = true;
  g.config.autosize = false;
  g.initial_scroll = true;
  g.config.cascade_delete = false;
  g.config.drag_project = true;
  g.config.fit_tasks = true;
  g.config.grid_resize = true;
  g.config.open_tree_initially = true;
  g.config.order_branch = true;
  g.config.order_branch_free = true;
  g.config.resource_property = 'staff';
  g.config.resource_store = 'resource';
  g.config.show_slack = false;
  g.config.sort = true;
  g.config.static_background = true;
  g.config.touch = true;
  // g.config.work_time = true; //Turn it off for rendering performance. We have our own logic to handle work time.
  g.config.xml_date = '%Y-%m-%d %H:%i:%s';
  g.config.types.task = 0;
  g.config.types.project = 1;
  g.config.types.milestone = 2;
  g.config.types.meeting = 3;
  g.config.duration_unit = 'minute';
  g.config.time_step = 15;
  g.config.skip_off_time = false;
  g.config.row_height = 28;
  g.config.smart_scales = true;
  
  _setScaleConfig(g, 'day');
  g.config.round_dnd_dates = false;
 
  g.config.show_tasks_outside_timescale = true;
  g.config.min_column_width = 100;

  const adjustEdgeTimelimeDisplayDate = function(g, task) {
    let min_date = g.getState().min_date;
    let max_date = g.getState().max_date;
    const scaleUnit = g.getState().scale_unit;
    let needRender = false;
    if (g.date.add(min_date, 1, scaleUnit) >= task.start_date) {
      g.config.start_date = min_date = g.date.add(min_date, -1, scaleUnit);
      needRender = true;
    }
    if (g.date.add(max_date, -1, scaleUnit) <= task.end_date) {
      g.config.end_date = max_date = g.date.add(max_date, 1, scaleUnit);
      needRender = true;
    }
    if(needRender) {
      g.render();
      return false;
    }
    return true;
  }

  g.attachEvent('onBeforeTaskDrag', function(id /** , mode, e*/){
    const task = g.getTask(id);



    return adjustEdgeTimelimeDisplayDate(g, task);
  });


  g.attachEvent('onTaskClick', function(id /**,e */){
    const task = g.getTask(id);
    return adjustEdgeTimelimeDisplayDate(g, task);
  });

  g.eachChild = function(callback, root){
    if(!this.isTaskExists(root))
      return;
   
    var children = g.getChildren(root);
    if(children){
      for(var i=0; i < children.length; i++){
        callback.call(this, this.getTask(children[i]));
   
          // iterate the whole branch, not only first-level dependencies
          this.eachChild(callback, children[i]);

      }
    }
  };
  
  g.attachEvent('onTaskDrag', function(id, mode, task , original/**, event */){
    var modes = g.config.drag_mode;
    if(mode == modes.move){
      var diff = task.start_date - original.start_date;
      g.eachChild(function(child){
        if (!child.orig_start_date) {
          child.orig_start_date = child.start_date;
        }
        if (!child.orig_end_date) {
          child.orig_end_date = child.end_date;
        }
        child.start_date = new Date(+child.start_date + diff);
        child.end_date = new Date(+child.end_date + diff);
        g.refreshTask(child.id, true);
      },id );
    }
    adjustEdgeTimelimeDisplayDate(g, task);
  });

  g.addTaskLayer(addSlack);
  //[START] Private scoped function of addSlack
  function addSlack(task) {
    if (!g.config.show_slack) {
      return null;
    }
    const slack = g.getFreeSlack(task);
    if (!slack) {
      return null;
    }
    const state = g.getState().drag_mode;
    if (state == "resize" || state == "move") {
      return null;
    }
    const slackStart = new Date(task.end_date);
    const slackEnd = g.calculateEndDate(slackStart, slack);
    const sizes = g.getTaskPosition(task, slackStart, slackEnd);
    const el = document.createElement("div");

    el.className = "slack";
    el.style.left = `${sizes.left}px`;
    el.style.top = `${sizes.top + 2}px`;
    el.style.width = `${sizes.width}px`;
    el.style.height = `${sizes.height}px`;

    return el;
  }
  //[END] Private scoped function of addSlack

  if(customConfigFunc && typeof customConfigFunc === 'function') {
    customConfigFunc(g);
  }
}




function _setScaleConfig(g, level) {
  g.config.subscales = [];
  g.config.min_column_width = 50;
  g.config.date_scale = '%Y';
  g.config.scale_unit = 'year';
  g.config.skip_off_time = false;

  switch (level) {
    case 'hour':
      g.config.skip_off_time = true;
      g.config.scale_unit = 'day';
      g.config.date_scale = '%d %M %Y';
      g.config.subscales.push({ unit: 'hour', step: 1, date: '%H:%i' });
      break;
    case 'day':
      g.config.scale_unit = 'month';
      g.config.date_scale = '%M %Y';
      g.config.subscales.push({ unit: 'day', step: 1, date: '%d' });
      break;
    case 'week': {
      const dateToStr = g.date.date_to_str('%d %M');
      const weekScaleTemplate = function(date) {
        const endDate = g.date.add(g.date.add(date, 1, 'week'), -1, 'day');
        return `${dateToStr(date)} - ${dateToStr(endDate)}`;
      };
      g.config.subscales.push({
        unit: 'week',
        step: 1,
        template: weekScaleTemplate
      });
      break;
    }
    case 'month':
      g.config.subscales.push({ unit: 'month', step: 1, date: '%F, %Y' });
      break;
  }
}