var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "edit-cell", on: { focusout: _vm.onFocusOut } },
    [
      _c(
        "b-input-group",
        { staticClass: "no-focus-shadow" },
        [
          _c(
            "b-input-group-prepend",
            [
              _c(
                "b-button",
                {
                  attrs: { size: "sm" },
                  on: {
                    focus: _vm.onFocus,
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.progressAddMinus(-1)
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "minus"] },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-form-input", {
            ref: "input",
            attrs: {
              id: "task-progress",
              size: "sm",
              min: "0",
              max: "100",
              type: "text",
            },
            on: {
              focus: _vm.onFocus,
              blur: _vm.progressFormat,
              keydown: function ($event) {
                return _vm.onKeyDown($event)
              },
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
          _c(
            "b-input-group-append",
            [
              _c(
                "b-button",
                {
                  attrs: { size: "sm" },
                  on: {
                    focus: _vm.onFocus,
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.progressAddMinus(1)
                    },
                  },
                },
                [_c("font-awesome-icon", { attrs: { icon: ["far", "plus"] } })],
                1
              ),
            ],
            1
          ),
          _c(
            "b-input-group-append",
            [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "success" },
                  on: {
                    focus: _vm.onFocus,
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.progressComplete()
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "check"] },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }