<template>
    <Dashboard />
</template>

<script>
export default {
  name: "DashBoard",
  components: {
    Dashboard: () => import('@/components/Dashboard/Dashboard')
  },
  data: function() {
    return {
      
    }
  },
  created() {

  },
  computed: {
    
  },
  methods: {
    
  }
}
</script>

