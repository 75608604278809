var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.taskId != null
    ? _c(
        "span",
        { staticClass: "position" },
        [
          _vm.autoScheduling
            ? [
                _c("font-awesome-icon", {
                  staticClass: "scheduling-icon",
                  attrs: { icon: ["far", "calendar-check"] },
                }),
              ]
            : _vm._e(),
          !_vm.autoScheduling
            ? [
                _c("font-awesome-icon", {
                  staticClass: "scheduling-icon",
                  attrs: { icon: ["far", "thumbtack"] },
                }),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }