<template>
  <div class="scheduler-toolbar grid-toolbar position-relative">
    <PriorityNavigation class="scheduler-actions pl-1"
      :dropDownStayOpened="badgeFilterModalOpened != 'close'" 
      :closeDropdown.sync="closePriorityNavDropdown" 
      @[priorityNavMouseOverEvent].native="onPriorityNavMouseOverOrTouchEnd"
      @[priorityNavTouchEndEvent].native="onPriorityNavMouseOverOrTouchEnd"
      :forceRefresh.sync="forcePriorityNavRefresh"
    >
      <li  v-if="showAdd">
        <b-popover
          :target="`BTN_ADD_SCHEDULER`"
          placement="top"
          triggers="hover"
          :content="$t('button.add')">
        </b-popover>
        <b-btn :id="`BTN_ADD_SCHEDULER`" class="add-event action-btn" @click="eventAdd"><font-awesome-icon :icon="['far', 'plus']"/></b-btn>  
      </li>
      <li  v-if="canEdit()">
        <b-popover
          :target="`BTN_EDIT_SCHEDULER`"
          placement="top"
          triggers="hover"
          :content="$t('button.edit')">
        </b-popover>
        <b-btn :disabled="disableEdit" :id="`BTN_EDIT_SCHEDULER`" class="edit-event action-btn" @click="eventEdit"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>  
      </li>
      <li  v-if="canDelete('ACTIVITY') || canDelete('BOOKING') || canDelete('TASK') || canDelete('CALENDAR')">
        <b-popover
          :target="`BTN_DELETE_SCHEDULER`"
          placement="top"
          triggers="hover"
          :content="$t('button.delete')">
        </b-popover>
        <b-btn :disabled="disableDelete" :id="`BTN_DELETE_SCHEDULER`" class="delete-event action-btn" @click="eventDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>  
      </li>
      <li>
        <b-popover
          :target="`BTN_COPY_SCHEDULER`"
          placement="top"
          triggers="hover"
          :content="$t('button.copy')">
        </b-popover>
        <b-btn :disabled="disableCopy" :id="`BTN_COPY_SCHEDULER`" class="copy-event action-btn" @click="eventCopy"><font-awesome-icon :icon="['far', 'copy']"/></b-btn>  
      </li>
      <li>
        <b-popover
          :target="`BTN_PASTE_SCHEDULER`"
          placement="top"
          triggers="hover"
          :content="$t('button.paste')">
        </b-popover>
        <b-btn :disabled="disablePaste" :id="`BTN_PASTE_SCHEDULER`" class="paste-event action-btn" @click="eventPaste"><font-awesome-icon :icon="['far', 'paste']"/></b-btn>  
      </li>
      <li>
        <b-btn :id="`BTN_IMPORT_SCHEDULER`" :disabled="!filter.usage && !filter.activity && !filter.booking && !filter.task && !filter.vacation" @click="fileImport" class="mr-1 export-event action-btn"><font-awesome-icon :icon="['far','inbox-in']"/></b-btn>
        <b-popover :target="`BTN_IMPORT_SCHEDULER`" triggers="hover" placement="top" boundary="viewport">
          {{ $t('task.button.import_document') }}
        </b-popover>
      </li>
      <li>
        <b-btn :id="`BTN_EXPORT_SCHEDULER`" :disabled="!filter.usage && !filter.activity && !filter.booking && !filter.task && !filter.vacation" @click="fileExport" class="mr-1 export-event action-btn"><font-awesome-icon :icon="['far','inbox-out']"/></b-btn>
        <b-popover :target="`BTN_EXPORT_SCHEDULER`" triggers="hover" placement="top" boundary="viewport">
          {{ $t('task.button.export_document') }}
        </b-popover>
      </li>
      <!-- <li class="divider">
        <span readonly class="scheduler-divider action-v-divider">|</span>
      </li>
      <li class="filter-flex-grow-container">
        <BadgeFilter :filters="filterObject" :historyList="filterHistory" :fields="filterFields" :fieldValues="filterFieldValues" @modified="onFilterModified" @historyModified="onHistoryModified"></BadgeFilter>
      </li> -->
      <li @[filterMouseEnterEvent]="onFilterOver" @mouseleave="onFilterLeave">
          <b-dropdown v-if="!searchPinned" :id="`BTN_FILTER_${id}`" ref="filter" 
            class="action-bar-dropdown text-filter" 
            toggle-class="text-decoration-none" no-caret
            @hide="onFilterTextDropdownHide"
          >
            <template #button-content>
              <font-awesome-icon :class="filterText !== '' ? 'active' : ''" :icon="['far','file-search']"/>
            </template>
            <b-dropdown-form @submit.stop.prevent class="filter-padding">
              <b-input-group class="search-input">
                <b-form-input :value="filterText" @input="onFilterTextInput" @focus="filterTextFocus = true" @blur="filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit"></b-form-input>
                <b-input-group-append>
                  <b-btn :id="`BTN_CLEAR_FILTER_${id}`" @click="onFilterClear" class="search-append search-append-bg" size="sm"><font-awesome-icon icon="times"/></b-btn>
                  <b-popover :target="`BTN_CLEAR_FILTER_${id}`" triggers="hover" placement="top">
                    {{ $t('button.clear') }}
                  </b-popover>
                </b-input-group-append>
                <b-input-group-append>
                  <b-btn :id="`BTN_SEARCH_FILTER_${id}`" @click="onFilterSubmit" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
                  <b-popover :target="`BTN_SEARCH_FILTER_${id}`" triggers="hover" placement="top">
                    {{ $t('button.search') }}
                  </b-popover>
                </b-input-group-append>
                <b-input-group-append>
                  <b-btn :id="`BTN_PIN_FILTER_${id}`" @click="onPinSearch" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'thumbtack']"/></b-btn>
                  <b-popover :target="`BTN_PIN_FILTER_${id}`" triggers="hover" placement="top">
                    {{ $t('button.pinOnToolbar') }}
                  </b-popover>
                </b-input-group-append>
              </b-input-group>
            </b-dropdown-form>
          </b-dropdown>
      </li>
      <li>
        <b-input-group class="search-input" v-if="searchPinned">
          <b-form-input :value="filterText" @input="onFilterTextInput" @focus="filterTextFocus = true" @blur="filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit"></b-form-input>
          <b-input-group-append>
            <b-btn :id="`BTN_CLEAR_FILTER_${id}`" @click="onFilterClear" class="search-append search-append-bg" size="sm"><font-awesome-icon icon="times"/></b-btn>
            <b-popover :target="`BTN_CLEAR_FILTER_${id}`" triggers="hover" placement="top">
              {{ $t('button.clear') }}
            </b-popover>
          </b-input-group-append>
          <b-input-group-append>
            <b-btn :id="`BTN_SEARCH_FILTER_${id}`" @click="onFilterSubmit" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
            <b-popover :target="`BTN_SEARCH_FILTER_${id}`" triggers="hover" placement="top">
              {{ $t('button.search') }}
            </b-popover>
          </b-input-group-append>
          <b-input-group-append>
            <b-btn :id="`BTN_UNPIN_FILTER_${id}`" @click="onUnPinSearch" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'thumbtack']"/></b-btn>
            <b-popover :target="`BTN_UNPIN_FILTER_${id}`" triggers="hover" placement="top">
              {{ $t('button.unpinFromToolbar') }}
            </b-popover>
          </b-input-group-append>
        </b-input-group>
      </li>
      <li @[badgeFilterMouseEnterEvent]="onBadgeFilterEnter" 
          @[badgeFilterMouseOverEvent]="onBadgeFilterOver" 
          @[badgeFilterMouseLeaveEvent]="onBadgeFilterLeave" 
          @[badgeFilterTouchEndEvent]="onBadgeFilterOver">
        <b-dropdown v-if="!filterPinned" :id="`BTN_BADGE_FILTER_${id}`" ref="badgeFilter"
          class="action-bar-dropdown text-filter" 
          toggle-class="text-decoration-none" no-caret
          @hide="onBadgeFilterDropdownHide"
        >
          <template #button-content>
            <font-awesome-icon :class="badgeFilters.length > 0 ? 'active' : ''" :icon="['far', 'filter']"/>
          </template>
          
          <b-dropdown-form @submit.stop.prevent class="filter-padding">
            <TaskViewBadgeFilter :filters="badgeFilters" 
              :fields="badgeFilterFields" 
              :fieldValues="badgeFilterFieldValues"
              :canPin="true" 
              :filterCheckboxText="resourceMode? 'resource.filter_resource' : 'staff.filter_staff'"
              @modified="onBadgeFilterModified" 
              @filterModalOpened="onBadgeFilterModalOpened"
              @filterModalClosed="onBadgeFilterModalClosed"
              @fetchFieldOptions="onBadgeFilterFetchOptions"
              @pin="onPinFilter"
              />
          </b-dropdown-form>
        </b-dropdown>
      </li>
      <li>
        <TaskViewBadgeFilter v-if="filterPinned" 
          :filters="badgeFilters" 
          :fields="badgeFilterFields" 
          :fieldValues="badgeFilterFieldValues" 
          :canPin="true" 
          pinned
          :filterCheckboxText="resourceMode? 'resource.filter_resource' : 'staff.filter_staff'"
          @modified="onBadgeFilterModified" 
          @filterModalOpened="onBadgeFilterModalOpened"
          @filterModalClosed="onBadgeFilterModalClosed"
          @fetchFieldOptions="onBadgeFilterFetchOptions"
          @pin="onUnPinFilter"
          />
      </li>
      <li class="view" @[viewMouseEnterEvent]="onViewOver" @mouseleave="onViewLeave">
        <b-dropdown :id="`BTN_VIEW_${id}`" ref="view" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <font-awesome-icon :icon="['far','desktop']"/>
          </template>
          <b-dropdown-item @click="savePreset" href="#">
            <span class="action-item-label">{{ $t(resourceMode? 'resource.button.save' : id ? 'staff.button.save' : 'staff.button.save_planner') }}</span>
          </b-dropdown-item>
          <b-dropdown-divider/>
          <template v-for="(item, index) in views">
            <b-dropdown-item class="action-item" @click="loadViewSettings(item)" href="#" :key="index">
              <span :id="`viewname${index}`" class="action-item-label-with-icon">{{ item.name }}</span>
              <b-popover
                v-if="isEllipsisActive(item.name)"
                :target="`viewname${index}`"
                placement="top"
                boundary="viewport"
                custom-class="popover-margin"
                triggers="hover"
                offset="-100"
                :content="item.name">
              </b-popover> 
              <span>
                <span class="action-item-icon" 
                    v-if="!editPermission(item)"
                    :id="`BTN_COPY_${index}`"
                    @click.stop.prevent="copyColumnSettings(item.name, item)">
                  <font-awesome-icon class="" :icon="['far','copy']"/>
                </span>
                <b-popover
                  v-if="!editPermission(item)"
                  :target="`BTN_COPY_${index}`"
                  placement="top"
                  boundary="viewport"
                  triggers="hover"
                  :content="$t(resourceMode? 'resource.button.copy' : id ? 'staff.button.copy' : 'staff.button.copy_planner')">
                </b-popover>
                <span class="action-item-icon position-third" 
                    v-if="!editPermission(item)"
                    @[infoMouseEnterEvent]="onInfoOver(index)" @mouseleave="onInfoLeave(index)"
                    :id="`BTN_INFO_${index}`">
                  <font-awesome-icon class="" :icon="['far','circle-info']"/>
                </span>
                <b-popover
                  v-if="!editPermission(item)"
                  :target="`BTN_INFO_${index}`"
                  :ref="`BTN_INFO_${index}`"
                  :show.sync="showInfo[index]"
                  placement="top"
                  boundary="viewport"
                  triggers="manual"
                  :content="$t('owner', [views[index].owner])">
                </b-popover>
                <span class="action-item-icon position-third" 
                    v-if="editPermission(item)"
                    :id="`BTN_SHARE_${index}`"
                    @click.stop.prevent="shareColumnSettings(index, item.name, item)">
                  <font-awesome-icon class="" :icon="[item.defaultView ? 'fas' : 'far','share-nodes']"/>
                </span>
                <b-popover
                  v-if="editPermission(item)"
                  :target="`BTN_SHARE_${index}`"
                  placement="top"
                  boundary="viewport"
                  triggers="hover"
                  :content="$t(resourceMode? 'resource.button.share' : id ? 'staff.button.share' : 'staff.button.share_planner')">
                </b-popover>
                <span class="action-item-icon position-second" 
                    v-if="editPermission(item)"
                    :id="`BTN_UPDATE_${index}`"
                    @click.stop.prevent="updateColumnSettings(index, item.name, item)">
                  <font-awesome-icon class="" :icon="['far','save']"/>
                </span>
                <b-popover
                  v-if="editPermission(item)"
                  :target="`BTN_UPDATE_${index}`"
                  placement="top"
                  boundary="viewport"
                  triggers="hover"
                  :content="$t(resourceMode ? 'resource.button.update' : id ? 'staff.button.update' : 'staff.button.update_planner')">
                </b-popover>
                <span class="action-item-icon"
                    v-if="editPermission(item)"
                    :id="`BTN_DELETE_${index}`"
                    @click.stop.prevent="removeColumnSettings(index)">
                  <font-awesome-icon class="" :icon="['far','trash-alt']"/>
                </span>
                <b-popover
                  v-if="editPermission(item)"
                  :target="`BTN_DELETE_${index}`"
                  placement="top"
                  boundary="viewport"
                  triggers="hover"
                  :content="$t(resourceMode ? 'resource.button.delete' : id ? 'staff.button.delete' : 'staff.button.delete_planner')">
                </b-popover>
              </span>
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </li>
      <li @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
        <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <font-awesome-icon :icon="['far', 'palette']"/>
          </template>
          <b-dropdown-group :header="$t('colorby')">
            <template v-if="resourceMode">
              <b-dropdown-item @click="onColorChange('none', 'resourceplanner_view_coloring')" href="#">
                <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('event', 'resourceplanner_view_coloring')" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.event') }}</span><font-awesome-icon class="active-check" v-if="coloring.event" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('resource', 'resourceplanner_view_coloring')" href="#">
                <span class="action-item-label">{{ $t('activity.coloring.resource') }}</span><font-awesome-icon class="active-check" v-if="coloring.resource" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('stage', 'resourceplanner_view_coloring')" href="#">
                <span class="action-item-label">{{ $t('activity.coloring.stage') }}</span><font-awesome-icon class="active-check" v-if="coloring.stage" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('project', 'resourceplanner_view_coloring')" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.project') }}</span><font-awesome-icon class="active-check" v-if="coloring.project" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </template>
            <template v-else>
              <b-dropdown-item @click="onColorChange('none', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('company', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.company') }}</span><font-awesome-icon class="active-check" v-if="coloring.company" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('location', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.location') }}</span><font-awesome-icon class="active-check" v-if="coloring.location" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('stage', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.stage') }}</span><font-awesome-icon class="active-check" v-if="coloring.stage" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('project', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.project') }}</span><font-awesome-icon class="active-check" v-if="coloring.project" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('department', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.department') }}</span><font-awesome-icon class="active-check" v-if="coloring.department" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('skill', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.skill') }}</span><font-awesome-icon class="active-check" v-if="coloring.skill" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('staff', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.staff') }}</span><font-awesome-icon class="active-check" v-if="coloring.staff" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('event', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.event') }}</span><font-awesome-icon class="active-check" v-if="coloring.event" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </template>
          </b-dropdown-group>
        </b-dropdown>
      </li>
    </PriorityNavigation>
    
    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteEventShow"
        @ok="confirmDeleteEventOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(selected.length === 1 ? 'activity.confirmation.delete' : 'activity.confirmation.delete_plural') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
  </div>
</template>

<script>

import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation';
import { isEllipsisActive } from '@/helpers';
import { cloneDeep } from 'lodash';

export default {
  name: 'SchedulerToolbar',
  components: {
    TaskViewBadgeFilter: () => import('@/components/Filter/TaskViewBadgeFilter.vue'), 
    PriorityNavigation,
  },
  props: {
    filterObject: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Object,
      default: () => { return {} }
    },
    showAdd: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Array,
      default: () => []
    },
    filterFields: {
      type: Array,
      default: () => []
    },
    filterHistory: {
      type: Array,
      default: () => []
    },
    filterFieldValues: {
      type: Object,
      default: () => {}
    },
    resourceField: {
      type: String,
      default: 'staffUuid'
    },
    scheduler: {
      type: Object,
      default: null
    },
    markedSpans: {
      type : Object,
      default: () => {}
    },
    showDeleteConfirmation: {
      type: Boolean,
      default: false
    },

    profileEntryNameTemplate: {
      type: String,
      default: null
    },
    coloring: {
      type: Object,
      default: () => {}
    },
    views: {
      type: Array,
      default: () => []
    },
    userId: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    badgeFilters: {
      type: Array,
      default: () => []
    },
    badgeFilterFields: {
      type: Array,
      default: () => []
    },
    badgeFilterFieldValues: {
      type: Object,
      default: () => {}
    },
    searchPinned: {
      type: Boolean,
      default: false
    },
    filterPinned: {
      type: Boolean,
      default: false
    },
    filterText: {
      type: String,
      default: null
    },
    showInfo: {
      type: Array,
      default:() => []
    },
    resourceMode: {
      type: Boolean,
      default: false
     }
  },
  data() {
    return {
      permissionName: "ACTIVITY",
      confirmDeleteEventShow: false,
      mSpans: {},
      copied: false,

      badgeFilterFocus: false,
      badgeFilterModalOpened: 'close',
      closePriorityNavDropdown: false,
      filterTextFocus: false,
      forcePriorityNavRefresh: false
    }
  },
  watch: {
    markedSpans(newValue) {
      this.mSpans = newValue;
    },
    showDeleteConfirmation(newValue) {
      if (newValue) {
        this.confirmDeleteEventShow = true;
        this.$emit('update:showDeleteConfirmation', false);
      }
    }
  },  
  computed: {
    disableEdit() {
      return this.selected.length !== 1;
    },
    disableCopy() {
      if (this.selected.length > 0) {
        if (this.scheduler == null) {
          return true;
        }
        /* eslint-disable no-undef */
        const event = this.scheduler.getEvent(this.selected[0]);
        /* eslint-enable no-undef */
        if (event) {
          if (event.type === 'task' && !this.canAdd('TASK', ["complexity",
          "identifier",
          "color",
          "fixedCost",
          "description",
          "constraintTime",
          "priority",
          "duration",
          "autoScheduling",
          "constraintType",
          "taskType",
          "closeTime",
          "name",
          "progress",
          "startTime",
          "currencyCode"])) {
            return true;
          }
          else if (event.type === 'activity' && !this.canAdd('ACTIVITY', ["duration",
          "identifier",
          "color",
          "fixedCost",
          "closeTime",
          "name",
          "description",
          "progress",
          "startTime",
          "websites",
          "currencyCode"])) {
            return true;
          }
          else if (event.type === 'booking' && !this.canAdd('BOOKING', ["duration",
          "beginDate",
          "identifier",
          "quantity",
          "color",
          "untilDate",
          "name",
          "description",
          "utilization"])) {
            return true;
          }
          else if (event.type === 'vacation' && !this.canAdd('CALENDAR')) {
            return true;
          }
          else if (event.type === 'resourceUnit' && !this.canEdit('STAFF')) {
            return true;
          }
        }
      }
      return this.selected.length === 0;
    },
    disablePaste() {
      return !this.copied;
    },
    disableDelete() {
      if (this.selected.length === 0 || this.scheduler == null) {
        return true;
      }
      
      for (const id of this.selected) {
        /* eslint-disable no-undef */
        const event = this.scheduler.getEvent(id);
        if (event == null) {
          return true;
        }
        /* eslint-enable no-undef */
        if (event.type === 'booking' &&
            !this.canDelete('BOOKING')) {
          return true;   
        }
        else if (event.type === 'activity' &&
            !this.canDelete('ACTIVITY')) {
          return true;   
        }
        else if (event.type === 'task' &&
            !this.canDelete('TASK')) {
          return true;   
        }
        else if (event.type === 'vacation' &&
            !this.canDelete('CALENDAR')) {
          return true;   
        }
      }
      return false;
    },
    filterMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    badgeFilterMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    badgeFilterMouseOverEvent() {
      return this.isTouchDevice()? null : 'mouseover';
    },
    badgeFilterMouseLeaveEvent() {
      return this.isTouchDevice()? null : 'mouseleave';
    },
    badgeFilterTouchEndEvent() {
      return this.isTouchDevice()? 'touchend' : null;
    },
    viewMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    infoMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    priorityNavMouseOverEvent() {
      return this.isTouchDevice()? null : 'mouseover';
    },
    priorityNavTouchEndEvent() {
      return this.isTouchDevice()? 'touchend' : null;
    },

  },
  mounted() {
    //BadgeFilter related
    document.addEventListener('click', this.toggleBadgeFilterFocus);
    document.addEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
  },
  created() {
    if (this.markedSpans != null) {
      this.mSpans = this.markedSpans;
    }
    this.$emit('schedulerToolbarCreated', this);
  },
  beforeDestroy() {
    //BadgeFilter related
    document.removeEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
    document.removeEventListener('click', this.toggleBadgeFilterFocus);
    this.badgeFilterFocus = false;
    this.badgeFilterModalOpened = 'close';
  },
  methods: {
    eventDelete() {
      this.confirmDeleteEventShow = true;
    },
    eventAdd(e) {
      // don't let keynav handle this event
      e.stopPropagation();
      e.preventDefault();
      this.$emit('addEvent');
    },
    eventCopy() {
      if (this.scheduler == null) {
        return;
      }
      /* eslint-disable no-undef */
      const toCopy = this.selected.map(i => { 
        const ev = cloneDeep(this.scheduler.getEvent(i));
        ev.refId = ev.id;
        ev.id = null;
        return ev;
      });
      /* eslint-enable no-undef */
      this.copied = true;
      this.$emit('copyEvent', toCopy);
    },
    eventPaste() {
      this.$emit('pasteEvent');
    },
    // eventStaff() {
    //   if (this.resourceField === 'staffUuid') {
    //     this.$emit('staffEvent');
    //   }
    //   else {
    //     this.$emit('resourceEvent');
    //   }
    // },
    eventEdit() {
      if (this.scheduler == null) {
        return;
      }
      /* eslint-disable no-undef */
      const toEdit = this.selected.map(i => { 
        const event = this.scheduler.getEvent(i);
        return { uuId: i, name: event.text, type: event.type, pu: event.pu ,
                 start_date: event.start_date, end_date: event.end_date,
                 calendar_type: event.calendar_type, readonly: event.readonly,
                 startHour: event.startHour, endHour: event.endHour
      }});

      this.$emit('editEvent', toEdit[0]);
    },
    confirmDeleteEventOk() {
      if (this.scheduler == null) {
        return;
      }
      const self = this;
      /* eslint-disable no-undef */
      const toRemove = this.selected.map(i => { 
        const event = this.scheduler.getEvent(i);
        
        // Remove the usage data
        if (event.type === 'task' ||
            event.type === 'booking' ||
            event.type === 'activity') {
          const staff = this.treeData.find(s => s.uuId === event.staffUuid);
          if (staff) {
            const task = staff[`${event.type}List`].findIndex(t => t.uuId === event.id);
            if (task !== -1) {
              staff[`${event.type}List`].splice(task, 1);
            }
          }
        }
        
        const uuId = i.slice(i.length - 36);
        
        if (uuId in self.mSpans) {
          for (const span of self.mSpans[uuId]) {
            this.scheduler.deleteMarkedTimespan(span.id);
          }
          delete self.mSpans[uuId];
        }
        return { uuId: uuId, type: event.type, section_id: event.section_id }
      });
      
      for (const r of this.selected) {
        const ev = this.scheduler.getEvent(r);
        if (ev && ev.clones) {
          // remove clones of the event
          for (const rClone of ev.clones) {
              
            this.scheduler.deleteEvent(rClone);
          }
        }
        this.scheduler.deleteEvent(r);
        /* eslint-enable no-undef */
      } 
      this.$emit('deleteEvent', toRemove);
    },
    onFilterModified(filter) {
      this.$emit('filterModified', filter);
    },
    onHistoryModified(history) {
      this.$emit('historyModified', history);
    },
    fileImport() {
      this.$emit('import');
    },
    fileExport() {
      this.$emit('exportToFile');
    },
    setTreeData(data) {
      this.treeData = data;
    },

    // ----------- development start ----
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    onColoringOver() {
      if (this.$refs.coloring != null) {
        this.$refs.coloring.visible = true;
      }
      
    },
    onColoringLeave() {
      if (this.$refs.coloring != null) {
        this.$refs.coloring.visible = false;
      }
    },
    onColorChange(val, color_key) {
      this.$emit('colorChange', val, color_key)
    },
    getProfileEntryName(value) {
      return this.profileEntryNameTemplate != null? this.profileEntryNameTemplate.replace("{value}", value) : value;
    },
    onViewOver() {
      this.$refs.view.visible = true;
    },
    onViewLeave(arg) {
      if (arg.toElement != null && typeof arg.toElement.className === 'string' &&
          arg.toElement.className !== 'arrow' &&
          arg.toElement.className && !arg.toElement.className.startsWith('popover')) {
        this.$refs.view.visible = false;
      }
    },
    savePreset() {
      this.$emit('savePreset');
    },
    loadViewSettings(item) {
      this.$emit('loadViewSettings', item);
    },
    isEllipsisActive(text) {
      isEllipsisActive(text, this);
    },
    editPermission(view) {
      if (typeof view.editingPermissions === 'undefined') {
        return true;    
      }
      return view.editingPermissions.includes(this.userId);
    },
    copyColumnSettings(itemName, item) {
      this.$emit('copyColumnSettings', itemName, item);
    },
    onInfoOver(index) {
      this.$emit('infoOver', index);
    },
    onInfoLeave(index) {
      this.$emit('inforLeave', index);
    },
    shareColumnSettings(index, itemName, item) {
      this.$emit('shareColumnSettings', index, itemName, item);
    },
    updateColumnSettings(index, itemName, item) {
      this.$emit('updateColumnSettings', index, itemName, item);
    },
    removeColumnSettings(index) {
      this.$emit('removeColumnSettings', index);
    },
    onBadgeFilterDropdownHide(bvEvent) {
      if (this.badgeFilterFocus || this.badgeFilterModalOpened != 'close') {
        bvEvent.preventDefault();
      }
    }
    , onBadgeFilterEnter() {
      this.$refs.badgeFilter.visible = true;
    }
    , onBadgeFilterOver(evt) {
      if (this.$refs.badgeFilter?.$el.id != null && evt.target.closest('.dropdown-toggle') != null && this.badgeFilterModalOpened != 'open' && this.badgeFilterFocus) {
        const id = evt.target.closest('.dropdown-toggle').id;
        if (id != null && id.startsWith(this.$refs.badgeFilter?.$el.id)) {
          this.badgeFilterFocus = false; 
        }
      }
    }
    , onBadgeFilterLeave() {
      if (!this.badgeFilterFocus && this.$refs?.badgeFilter != null) {
        this.$refs.badgeFilter.visible = false;
      }
    }
    , onBadgeFilterModalOpened() {
      this.badgeFilterModalOpened = 'open';
      this.badgeFilterFocus = true;
    }
    , onBadgeFilterModalClosed() {
      this.badgeFilterModalOpened = 'signaled-close';
    }
    , toggleBadgeFilterFocus(evt) {
      if (this.badgeFilterModalOpened == 'signaled-close') {
        this.badgeFilterModalOpened = 'close';
      } else if (this.badgeFilterFocus && this.badgeFilterModalOpened == 'close' && this.$refs.badgeFilter && (this.$refs.badgeFilter?.$el?.id == null || evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) == null)) {
        this.badgeFilterFocus = false;
      } else if (!this.badgeFilterFocus && this.$refs.badgeFilter && evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) != null) {
        this.badgeFilterFocus = true;
      }
    }
    , handleBadgeFilterEscapeKeyDown(e) {
      const evt = e || window.event;
      if (evt.keyCode === 27 && this.badgeFilterFocus) {
        this.badgeFilterFocus = false;
        this.badgeFilterModalOpened = 'close';
        this.closePriorityNavDropdown = true;
      }
    }
    , onBadgeFilterFetchOptions(payload) {
      this.$emit('badgeFilterFetchOptions', payload);
    }
    , onBadgeFilterModified(filter) {
      this.badgeFilterFocus = true; //Pin the badgeFilter when a change is made.
      this.$emit('badgeFilterModified', filter);
    }
    , onFilterOver() {
      this.$refs.filter.visible = true;
    }
    , onFilterLeave() {
      this.$refs.filter.visible = false;
    }
    , onFilterTextDropdownHide(bvEvent) {
      if(this.filterTextFocus){
        bvEvent.preventDefault();
      }
    }
    , onFilterTextInput(newValue) {
      this.$emit('filterTextInput', newValue);
    }
    , onFilterSubmit() {
      this.filterTextFocus = false;
      if (this.$refs.filter != null) {
        this.$refs.filter.visible = false;
      }
      this.closePriorityNavDropdown = true; //Signal priorityNavigation to close the dropdown.

      this.$emit('filterSubmit');
    }
    , onFilterClear() {
      this.$emit('filterClear');
    }
    , onPinSearch() {
      this.$emit('pinSearch');
    }
    , onUnPinSearch() {
      this.forcePriorityNavRefresh = true;
      this.$emit('unPinSearch');
    }
    , onPinFilter() {
      this.$emit('pinFilter');
    }
    , onUnPinFilter() {
      this.forcePriorityNavRefresh = true;
      this.$emit('unPinFilter');
    }
    , onPriorityNavMouseOverOrTouchEnd(evt) {
      if ((this.$refs.badgeFilter?.$el.id == null || evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) == null)) {
        this.badgeFilterFocus = false;
      }
    }

  }
}
</script>
<style>
  .dhx_matrix_cell div.load-marker{
    position: absolute;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 12px;
    line-height: 25px;
    height: 25px;
  }

  .load-marker-no{
    opacity: 0.5;
    background-color: var(--scheduler-cell-disabled);
  }
  .load-marker-low{
    color: var(--white);
     background-color: var(--green-200);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-med{
    color: var(--white);
     background-color: var(--green-400);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-high{
    color: var(--white);
     background-color: var(--green-600);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-full {
    color: var(--white);
     background-color: var(--green-800);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-over {
    background-color: var(--red-400);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-over2 {
    background-color: var(--red-600);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-over3 {
    background-color: var(--red-800);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-not-employed {
    opacity: 0.7;
    background-color: #000000;
    height: 100% !important;
  }
  .load-marker-bad-assign {
    opacity: 0.7;
    background-color: #000000;
     z-index: 2;
  }
  .load-marker-weekend {
    opacity: 0.5;
    background-color: var(--scheduler-cell-disabled);
  }
  
  .dhx_matrix_scell div {
    white-space: nowrap;
  }
  
  .dhx_matrix_now_time {
    border-left: 2px solid var(--gantt-start-end-marker-bg) !important;
  }

  .dhx_matrix_scell {
    font-size: 12px;
    font-weight: 400;
  }
  
  .dhx_scell_level7 {
    padding-left: 110px;
  }
  .dhx_scell_level6 {
    padding-left: 95px;
  }
  .dhx_scell_level5 {
    padding-left: 80px;
  }
  .dhx_scell_level4 {
    padding-left: 65px;
  }
  
  .dhx_cal_event_line .dhx_event_resize {
    z-index: 1;
    top: 3px;
    height: 14px !important;
  }
  
  .dhx_cal_event_line {
    color: var(--white);
    line-height: 22px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 300;
    border: none;
    margin-top: 1px;
    background: transparent;
  }
  
  .event_selected {
    box-shadow: 0 0 0 0.2rem var(--scheduler-focus-shadow);
  }
  
  .event_new {
    border-color: var(--gantt-task) !important;
    border: 1px solid var(--gantt-task);
  }
  
  .dhx_timeline_label_row {
    background-color: var(--ag-background-color);
  }
  
  .dhx_data_table.folder .dhx_matrix_cell, .dhx_matrix_scell.folder {
    background-color: var(--ag-background-color);
    border-right: 1px solid var(--ag-row-border-color);
  }
  
  .dhx_cal_header div.dhx_bottom_scale_container .dhx_scale_bar {
    border-left-color: var(--ag-row-border-color);
  }
  
  .dhx_cal_header {
    background: #cecece;
    border-top: none;
  }
  
  .dhx_timeline_scale_header.dhx_timeline_second_scale {
    border-right: none;
  }
  
  .dhx_scale_bar.dhx_second_scale_bar {
    border-bottom-color: var(--ag-row-border-color);
  }
    
  .dhx_cal_data .dhx_timeline_table_wrapper div {
    border-color: var(--ag-row-border-color);
  }

  .dhx_cal_data {
    border-top: none;
  }
  
  .dhx_timeline_label_wrapper, .dhx_timeline_scale_header,
  .dhx_cal_container {
    background-color: var(--bs-border);
  }
  
  .dhx_cal_navline .dhx_cal_date {
    color: var(--ag-data-color);
  }
  
  .dhx_matrix_scell.folder .dhx_scell_name {
    float: none;
    text-transform: none;
  }
  
  .dhx_cal_header,
  .dhx_cal_navline {
    z-index: 0;
  }
  
  .dhx_cal_navline {
    display: none;
  }
  
  .dhx_matrix_scell.folder .dhx_scell_expand {
    margin: 0 3px;
    display: none !important;
  }
  
  .dhx_matrix_cell div, .dhx_matrix_scell div {
    text-align: left;
  }
  
  .dhx_matrix_scell.folder.opened .dhx_scell_expand:after,
  .dhx_matrix_scell.folder.closed .dhx_scell_expand:after {
    background: none;
  }
  
  .dhx_matrix_scell, .dhx_timeline_scale_header {
    color: var(--ag-data-color);
  }
  
  .dhx_scale_bar .dhx_second_scale_bar {
    top: 1px;
    border-top: none;
  }
  
  .dhx_matrix_cell {
    background-color: var(--ag-odd-row-background-color);
    border-color: var(--ag-row-border-color);
  }
  
  .dhx_scale_bar,
  .dhx_timeline_scale_header {
    background-color: var(--gantt-scale-line2-cell-bg);
    color: var(--ag-header-foreground-color);
    font-size: 12px;
    font-weight: bold;
  }
  
  .dhx_focus_slot {
    background-color: var(--selection-background-color);
    border: 2px solid;
    border-color: var(--selection-border-color) !important;
    border-style: var(--selection-border-style);
    opacity: 1;
    z-index: 3;
  }
  
  .dhx_scale_bar.dhx_second_scale_bar {
    border-top-color: var(--gantt-scale-line2-cell-bg) !important;
  }
  .dhx_cal_header div .dhx_scale_bar:not(.dhx_second_scale_bar) {
    border-top: 1px solid var(--border-light) !important;
  }
  
  .dhx_matrix_cell .load-marker-weekend {
    height: 100% !important;
  }
  
  .dhx_data_table.folder .dhx_matrix_cell, .dhx_matrix_scell.folder {
    cursor: auto;
  }
  
  .dhx_cal_header div .dhx_scale_bar.dhx_second_scale_bar {
    border-bottom: 1px solid transparent !important;
  }
  
  .dhx_cal_container .dhx_cal_header.dhx_second_cal_header .dhx_scale_bar.dhx_second_scale_bar {
    border-left: 1px solid var(--ag-header-column-separator-color);
  }
  
  .dhx_cal_container .dhx_cal_header.dhx_second_cal_header .dhx_scale_bar {
    border-left: 1px solid var(--ag-header-column-separator-color);
    border-color: var(--ag-header-column-separator-color);
  }
  
  .tag {
    border-radius: 3px;
    vertical-align: text-bottom;
    display: inline-block;
    color: var(--white);
    font-size: 0.8em;
    padding: 0 5px 0 5px;
    height: 15px;
    line-height: 15px;
    margin-right: 3px;
    min-width: 16px;
  }

.tag-company {
  background-color: var(--deep-purple-400);
}

.tag-department {
  background-color: var(--blue-900);
}

.tag-skill {
  background-color: var(--deep-purple-300);
}

.tag-stage {
  background-color: var(--brown-400);
}

.tag-project {
  background-color: var(--pink-800);
}

.tag-staff-generic {
  background-color: var(--blue);
}

.tag-location {
  background-color: var(--teal);
}

.tag-staff {
  background-color: var(--pumpkin);
}

.tag-task {
  background-color: var(--cyan-600);
}

.tag-activity {
  background-color: var(--activity);
}

.tag-vacation {
  background-color: var(--blue-gray-300);
}

.tag-new {
  background-color: #777;
}

.tag-booking {
  background-color: var(--booking);
}

.tag-resource {
  background-color: var(--light-green-900);
}

.cell-tooltip,
.dhtmlXTooltip.tooltip[role=tooltip] {
  box-shadow: var(--surface-shadow);
  border-radius: 0.25rem;
  pointer-events: none;
  transition: opacity 1s;
  background-color: var(--popover-body-bg) !important;
  color: var(--popover) !important;
  font-size: 12px;
  z-index: 10000;
}

.dhtmlXTooltip.tooltip[role=tooltip] {
  border: none;
  padding: 0;
}

.cell-tooltip p,
.task-tooltip-body p {
  margin: 5px;
  white-space: nowrap;
}

.task-tooltip-body {
  padding-left: 3px;
  padding-right: 3px;
}

.header {
  vertical-align: center;
  margin-right: 3px;
}

.header-box {
  background-color: var(--gray-500);
  color: var(--white);
  border-bottom: 1px solid var(--border-medium);
  display: flex;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.add-event.btn-secondary,
.edit-event.btn-secondary,
.delete-event.btn-secondary,
.copy-event.btn-secondary,
.paste-event.btn-secondary,
.staff-event.btn-secondary,
.export-event.btn-secondary {
  background-color: transparent;
  border-color: transparent;
  color: var(--grid-toolbar-button);
  padding: 2px 6px;
  font-size: 1rem;
}

.add-event.btn-secondary.disabled,
.edit-event.btn-secondary.disabled,
.delete-event.btn-secondary.disabled,
.copy-event.btn-secondary.disabled,
.paste-event.btn-secondary.disabled,
.staff-event.btn-secondary.disabled,
.export-event.btn-secondary.disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--grid-toolbar-button);
  opacity: 0.4;
}

.add-event.btn-secondary:focus,
.edit-event.btn-secondary:focus,
.delete-event.btn-secondary:focus,
.copy-event.btn-secondary:focus,
.paste-event.btn-secondary:focus,
.staff-event.btn-secondary:focus,
.export-event.btn-secondary:focus {
  box-shadow: none;
}

.add-event.btn-secondary:hover,
.edit-event.btn-secondary:hover,
.delete-event.btn-secondary:hover,
.copy-event.btn-secondary:hover,
.paste-event.btn-secondary:hover,
.staff-event.btn-secondary:hover,
.export-event.btn-secondary:hover {
  color: var(--grid-toolbar-button);
  background-color: transparent;
  border-color: transparent;
}

.dhx_matrix_cell div.today,
.dhx_scale_bar.today {
  background-color: var(--today);
  height: 100%;
  z-index: 0;
}

.partial-start .dhx_event_resize_start {
  display: none !important;
}

.partial-end .dhx_event_resize_end {
  display: none !important;
}

.scheduler-actions {
  background: var(--surface-bg);
  border-bottom: 1px solid var(--bs-border);
  border-left: 1px solid var(--bs-border);
  border-right: 1px solid var(--bs-border);
  display: flex;
}

@media only screen and (max-width: 600px) {
  .scheduler-actions {
    display: block;
  }
  .dhx_matrix_scell{
    display:none !important;
  }
}

.filter-flex-grow-container {
  flex: 1;
  margin-top: -2px;
}

.event-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  margin-left: 12px;
  margin-right: 12px;
  position: relative;
  top: 0;
}

.readonly-icon {
  margin-right: 5px;
}

.staff-tooltip-skills {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: 200px;
}

::-webkit-scrollbar-corner {
  background: var(--surface-bg);
}

.img-avatar.smaller {
  margin: 4px !important;
  position: relative;
  height: 35px !important;
}

.avatar-align {
  vertical-align: top;
}

.tree-icon {
  width: 10px;
  height: 10px;
  color: var(--ag-secondary-foreground-color);
  margin: auto;
  margin-right: 5px;
  cursor: pointer;
}

.hidden-tree-icon {
  display: none !important;
}

.scheduler-odd-row {
  background-color: var(--ag-odd-row-background-color) !important;
  height: 100% !important;
}

.scheduler-even-row {
  background-color: var(--ag-background-color) !important;
  height: 100% !important;
}

.scheduler-border-part {
    border-bottom: 1px solid var(--bs-border);
    border-left: 1px solid var(--bs-border);
    border-right: 1px solid var(--bs-border);
}

.scheduler-divider {
  padding-top: 4px;
  font-size: 14px;
  display: inline-block;
}

.noimg-avatar {
  display:inline-block;
  font-size:1em;
  width:30px;
  height:30px;
  line-height:2.5em;
  text-align:center !important;
  border-radius:50%;
  vertical-align:middle;
  margin-right:4px;
  color:white;
  }
  
  .scheduler-tag-link {
    color: var(--link-text);
  }
  
  .scheduler-tag-nolink {
    color: var(--link-text);
    display: inline;
  }
  
  .tree-minimizer {
    background-color: transparent;
    position: absolute;
    top: 10px;
    left: 0;
    height: 25px;
    z-index: 10;
    border: none;
  }
  
  .tree-minimizer-expanded {
    left: 270px;
  }
  
  .tree-minimizer:focus {
    outline-style: none;
  }
  
  .tree-minimizer-icon {
    color: var(--ag-header-foreground-color);
  }
  
  .pointer-events-none {
    pointer-events: none;
  }
  
  .section-expand {
    display: inline-block;
  }
    
  .no-rows-overlay {
    position: absolute;
    display: flex;
    height: 100%;
    z-index: 3;
    width: 100%;
    pointer-events: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .type-color {
    padding-left: 0;
    padding-right: 0;
    height: 16px;
    top: 1px;
    position: relative;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .warning-icon {
    margin-right: 5px;
    margin-left: 1px;
  }
  
  .dhx_cal_event_line.readonly {
    cursor: default;
  }

  .scheduler-toolbar ul {
    list-style-type: none;
  }

  .scheduler-toolbar.grid-toolbar .action-btn.btn.btn-secondary {
    margin-top: 5px;
    margin-bottom: 5px;
  }
</style>
