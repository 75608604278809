<template>
  <div class="edit-cell">
    <b-modal v-model="modalShow" size="md" :title="$t('task.image_editor')" footer-class="footerClass"
        no-close-on-backdrop  content-class="shadow" 
        @ok="changed" @hidden="closePicker">
      <b-form-group>
        <label class="ml-1 mt-1 mr-1">{{ $t(`task.field.image`) }}</label>
        <button id="IMAGE_ADD" class="ml-1 btn-action" @click="imageSelectorToggle" v-if="avatarUrl === null"><font-awesome-icon :icon="['far', 'plus']"/>
          <b-popover
            target="IMAGE_ADD"
            placement="top"
            triggers="hover"
            :content="$t('task.button.image_add')">
          </b-popover>
        </button>
        <button id="IMAGE_DELETE" class="btn-action" @click="imageRemove" v-if="avatarUrl !== null"><font-awesome-icon :icon="['far', 'trash-can']"/>
          <b-popover
            target="IMAGE_DELETE"
            placement="top"
            triggers="hover"
            :content="$t('task.button.image_remove')">
          </b-popover>
        </button>
        <div class="image-preview-container ml-2 mb-2">
          <b-img-lazy id="image-preview" class="image-preview" style="object-fit:cover" :src="avatarUrl" v-if="avatarUrl != null" @error.native="handleImgError"/>
          <div class="no-image-text" v-else>{{ $t('task.no_image') }}</div>
        </div>
      </b-form-group>
      
      <template v-slot:modal-footer="{  }">
        <template>
          <b-button size="sm" variant="success" @click="changed()">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="closePicker()">{{ $i18n.t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    <FileSelectorModal v-if="fileSelectorShow" :show.sync="fileSelectorShow" @ok="fileSelectorOk" :multiple="fileMultiple" :allowedMimeType="fileMimetypes"/>
  </div>
</template>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { fileService } from '@/services';

export default Vue.extend({
  name: 'ImageEditor',
  components: {
    FileSelectorModal: () => import('@/components/modal/FileSelectorModal.vue')
  },
  data() {
    return {
      value: '', 
      fileSelectorShow: false, 
      compactMode: false, 
      hasChange: false,
      modalShow: false,
    };
  },
  computed: {
    avatarUrl() {
      return this.value && this.value !== "00000000-0000-0000-0000-000000000000" ? (typeof this.value === 'string' && this.value.startsWith('data:image/') ? this.value : `${process.env.BASE_URL}api/file/${this.value}`) : null;
    },
    fileMimetypes() {
      return ['image/'];
    },
    fileMultiple() {
      return false;
    },
  },
  methods: {
    handleImgError(/* evt */) {
      this.value = "00000000-0000-0000-0000-000000000000"; // avatar image could not be loaded
    },
    async fileAccessUpdate(uuId) {
      const result = {
        hasError: false,
        msg: null
      }
      const form = new FormData();
      form.append('uuId', uuId);
      form.append('accessLevel', 'Public');
      const self = this;
      await fileService.update(form)
      .catch(() => {
        result.hasError = true;
        result.msg = self.$i18n.t('avatar_banner.error.failed_to_set', [self.$i18n.t('avatar_banner.field.avatar')]);
      });
      return result;
    },
    getValue() {
      if (this.hasChange) {
        return this.value;
      } 
      return this.params.value;
    }, 
    isPopup() {
      return true;
    }, 
    setInitialState(params) {
      let isSingleSelected = true;
      if (params.api != null) {
        isSingleSelected = params.api.getSelectedNodes().length < 2;
      }
      this.showList = isSingleSelected;

      let value = this.compactMode? params.value.single : params.value;
      if (value == null) {
        value = [];
      }
      if (isSingleSelected && value.length > 0) {
        this.value = value[0];
      }
      this.value = value;
    }, 
    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    }, 
    changed() {
      this.hasChange = true;
      
      this.closePicker();
    },
    imageSelectorToggle() {
      this.selectingImage = true;
      this.fileSelectorShow = true;
    },
    imageRemove() {
      this.value = "00000000-0000-0000-0000-000000000000";
    },
    fileSelectorOk(details) {
      const imageFile = details[0];
      this.fileAccessUpdate(imageFile.uuId);
      this.value = imageFile.uuId;
      
    }
  },
  created() {
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    this.setInitialState(this.params);
    this.modalShow = true;
  },
  mounted() {
    
  }
})
</script>
<style scoped>

.image-preview-container {
  width: 100%;
  border: 1px var(--form-control-border) solid;
  padding: 5px;
  margin-bottom: 10px;
  min-width: 150px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 150px;
}

.no-image-text {
  
}
</style>
