<template>
  <button
    id="sidebar_minimize"
    class="sidebar-minimizer"
    type="button"
    @click="onClick()">
    <b-popover
      target="sidebar_minimize"
      placement="top"
      triggers="hover"
      :content="isMinimized ? $t('button.expand_sidebar') : $t('button.collapse_sidebar')">
    </b-popover>
  </button>
</template>
<script>
import { togglePs } from '../../mixins/togglePs'
import { EventBus } from '@/helpers';

export default {
  name: 'sidebar-minimizer',
  data() {
    return {
      isMinimized: false
    }
  },
  mixins: [ togglePs ],
  mounted: function () {
    this.isMinimized = document.body.classList.contains('sidebar-minimized')
    this.togglePs(!this.isMinimized)
  },
  methods: {
    onClick () {
      this.sidebarMinimize()
      this.brandMinimize()
      EventBus.$emit('toggle-sidebar');
    },
    sidebarMinimize () {
      this.isMinimized = document.body.classList.toggle('sidebar-minimized')
      this.$emit('cui-sidebar-minimize', this.isMinimized)
      this.togglePs(!this.isMinimized)
    },
    brandMinimize () {
      document.body.classList.toggle('brand-minimized')
    }
  }
}
</script>
