
import { httpAjax } from '@/helpers';
import { getPermissionDenyProperties } from '@/helpers/permission'
import { getKeysWithoutRedactedFields } from './common';

export const tagService = {
  query,
  create,
  list,
  update,
  remove,
  clone,
  list_with_filters,
  listLinks,
  listNames,
  listUniqueValuesOfProperty,
};

/**
 * Retrieving a list of tag details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
 function query(data) {
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post('/api/tag/get', data, config);
}

/**
 * Create a new tag 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', fullName: 'John Doe' ... }]
 */
function create(data) {
  const url = '/api/tag/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of tag info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filters: [{ value:'john', regex: false}], ksort='fullName', order: 'incr' }}
 */
 function list_with_filters(params) {
  const fields = {
    uuId: 'TAG.uuId', 
    name: 'TAG.name', 
    description: 'TAG.description',
    identifier: 'TAG.identifier'
  }
  

  let data = {
    'name'  : 'Tag List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if (params.holder) {
    data['holder'] = params.holder;
  }
  
  if (params.filters != null && params.filters.length > 0) {
    const filters = params.filters;
    const filterList = [];
    for (const filter of filters) {
      filterList.push(['TAG.name', filter.regex ? 'regex' : 'is', filter.value])
    }
    data['filter'] = ['_or_', filterList]
  }
  
  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i], '', 'lowerCase']);
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order, '', 'lowerCase']);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filters;
  
  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

/**
 * Retrieving a concise list of tag info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='fullName', order: 'incr' }}
 */
function list(params, regex=false, customFields=[]) {
  const fields = {
    uuId: 'TAG.uuId', 
    name: 'TAG.name', 
    description: 'TAG.description',
    identifier: 'TAG.identifier',
    count: ["=countLinks(A,B)", ["TAG"],["NODE(in)"] ],
    readOnly: 'TAG.readOnly'
  }
  if (Array.isArray(customFields) && customFields.length > 0) {
    for(const cField of customFields) {
      fields[cField.name] = `TAG.${cField.name}`;
    }
  }
  

  let data = {
    'name'  : 'Tag List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if (params.holder) {
    data['holder'] = params.holder;
  }
  
  if (params.filter && params.filter.length > 0) {
    if (typeof params.filter === 'string') {
      const matchColumns = [
        ['TAG.name', regex ? 'has' : 'is', params.filter]
      ]
      const tagDeniedProperties = getPermissionDenyProperties('TAG', 'VIEW')
      if (Array.isArray(customFields) && customFields.length > 0) {
        for (const c of customFields) {
          if ((c.type == 'String' || c.type == 'Enum<String>') && !tagDeniedProperties.includes(c.name)) {
            matchColumns.push([`TAG.${c.name}`, 'has', params.filter])
          }
        }
      }

      data['filter'] = [
        '_or_', matchColumns
      ]
    }
    else {
      data['filter'] = params.filter;
    }
  }

  //BadgeFilter related
  if (Array.isArray(params.badgeFilters) && params.badgeFilters.length > 0) {
    const badgeFilterList = [];
    for (const f of params.badgeFilters) {
      if (f.field == null || !Array.isArray(f.value) || f.value.length == 0) {
        continue;
      }
      
      let field = `TAG.${f.field}`
      
      const isEqual = !f.operator || f.operator === 'is';
      let valueList = [];
      const value = f.value;
      for (const v of value) {
        if (v.text != null && (typeof v.text === 'number' || (typeof v.text === 'string' && v.text&& v.text.length > 0 && v.text !== '(Empty)'))) {
          //use value when exists. Otherwise use text
          //Reason: When value property exists, it means the text is localized and can't be used in filter.
          valueList.push([field, 'eq', Object.hasOwn(v, 'value')? v.value : v.text]);
        }
        //Start processing (EMPTY) field value 
        else if (isEqual) {
          valueList.push('_not_', [[field]])
        } else {
          //Special handling: when is not empty, put it into badgeFilterList directly to act as 'field must exist'. 
          badgeFilterList.push([field])
        }
      }
      if (valueList.length > 0) {
        if (valueList.length > 1) {
          valueList = ['_or_', valueList];
        }
        if (!isEqual) {
          badgeFilterList.push('_not_', valueList);
        } else {
          badgeFilterList.push(...valueList);
        }
      }
    }
    if (badgeFilterList.length > 0) {
      if (Array.isArray(data.filter) && data.filter.length > 0) {
        data.filter = [...data.filter, ...badgeFilterList]
      } else {
        data.filter = [...badgeFilterList]
      }
    }
  }
  
  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i], '', 'lowerCase']);
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order, '', 'lowerCase']);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;
  delete params.badgeFilters;
  
  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      }),
      redacted: response.data.redacted != null? response.data.redacted : null
    }
  });
}

/**
 * Retrieving a list of tag links by 
 * passing in the uuId of a tag
 * @param Object params 
 * e.g. {params: { filter: '{00000000-0000-0000-00000000}' }}
 */
function listLinks(params) {
  const fields = {
    company: "TAG.COMPANY.uuId",
    department: "TAG.DEPARTMENT.uuId",
    staff: "TAG.STAFF.uuId",
    customer: "TAG.CUSTOMER.uuId",
    project: "TAG.PROJECT.uuId",
    stage: "TAG.STAGE.uuId",
    projectTemplate: "TAG.PROJECT_TEMPLATE.uuId",
    taskTemplate: "TAG.TASK_TEMPLATE.uuId",
    skill: "TAG.SKILL.uuId",
    resource: "TAG.RESOURCE.uuId",
    location: "TAG.LOCATION.uuId",
    rebate: "TAG.REBATE.uuId",
    user: "TAG.USER.uuId",
    folder: "TAG.STORAGE_FOLDER.uuId",
    file: "TAG.STORAGE_FILE.uuId"
  }
  

  let data = {
    'name'  : 'Tag List Links'
    ,'type' : 'msql'
    ,'nominate': 'TAG'
    ,'start' : 0
    ,'limit' : 1
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if (params.holder) {
    data['holder'] = params.holder;
  }
  
  if (params.filter && params.filter.length > 0) {
    if (typeof params.filter === 'string') {
      data['filter'] = [
        '_or_', [
          ['TAG.uuId', 'eq', params.filter]
        ]
      ]
    }
    else {
      data['filter'] = params.filter;
    }
  }
  
  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i], '', 'lowerCase']);
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order, '', 'lowerCase']);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;
  
  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}


/**
 * Update tag details 
 * by passing  necessary information
 * @param Array data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', fullName: 'John Doe' ... }]
 */
function update(data) {
  const url = '/api/tag/update';
  const config = {
    headers: getHeaders()
  }
  data['holder'] = data.uuId;
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of tags
 * by passing in an array list of uuIds
 * @param Array data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = '/api/tag/delete';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}

/**
 * Clone a skill with given new name
 * @param String refId Target skill UuId
 * @param Object data Object contains name for cloned skill.
 */
function clone(refId, data) {
  const url = '/api/tag/clone';
  const config = {
    data: data,
    headers: getHeaders(),
    params: { reference: refId }
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of TAG info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
function listNames(params) {
  const fields = {
    uuId: 'TAG.uuId',
    name: 'TAG.name'
  }
  

  let data = {
    'name'  : 'TAG Name List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'TAG'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

function listUniqueValuesOfProperty(field) {
  const fieldSelector = [`TAG.${field}`]
  
  const data = {
    'name'  : `Unique TAG property: ${field}`
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'select': [
      fieldSelector
    ]
    , 'dedup': true
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    let rawData = response.data[listName] || [];
    rawData = rawData.map(i => i[0]);
    rawData.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base'}));
    return rawData;
  });
}