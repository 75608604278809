var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "paged-aggrid-gantt-container", staticClass: "position-relative" },
    [
      !_vm.isWidget
        ? _c("GanttActionBar", {
            attrs: {
              readOnly: _vm.isAccessDenied,
              allowManage: _vm.allowManage,
              actionProcessing: _vm.actionProcessing,
              disableEdit: _vm.disableEdit,
              disableDelete: _vm.disableDelete,
              hasAutoAssignTasks: _vm.hasAutoAssignTasks,
              dates: _vm.control.dates,
              startDate: _vm.control.startDate,
              endDate: _vm.control.endDate,
              timescale: _vm.control.timescale,
              criticalPath: _vm.control.criticalPath,
              freeFloat: _vm.control.freeFloat,
              hideOptProjectStartToEnd: "",
              hideOptProjectSchedule: "",
              hideOptTaskSchedule: false,
              isTemplate: false,
              hideMasterCheckBox: "",
              hideTaskOutdent: "",
              hideTaskIndent: "",
              hideTaskCopy: "",
              hideTaskPaste: "",
              hideTemplateApply: "",
              hideTemplateSave: "",
              hideFileImport: "",
              hideScheduleMenu: "",
              hideCriticalPath: "",
              hideFreeFloat: "",
              hideTaskCollapse: "",
              hideTaskExpand: "",
              hideSearch: "",
              hideScheduling: "",
              hideTaskAdd: !_vm.canList("PROJECT"),
              views: _vm.ganttViews,
              coloring: _vm.coloring,
            },
            on: {
              "task-new-open": _vm.taskNewHandler,
              "task-edit-open": _vm.taskEditHandler,
              "row-delete": _vm.rowDeleteHandler,
              "file-export": _vm.fileExportHandler,
              "auto-assign-staff": _vm.autoAssignStaffHandler,
              "update:timescale": function ($event) {
                return _vm.$set(_vm.control, "timescale", $event)
              },
              "update:criticalPath": function ($event) {
                return _vm.$set(_vm.control, "criticalPath", $event)
              },
              "update:critical-path": function ($event) {
                return _vm.$set(_vm.control, "criticalPath", $event)
              },
              "update:freeFloat": function ($event) {
                return _vm.$set(_vm.control, "freeFloat", $event)
              },
              "update:free-float": function ($event) {
                return _vm.$set(_vm.control, "freeFloat", $event)
              },
              datesChanged: _vm.handleControlDates,
              "load-view-settings": _vm.loadViewSettings,
              "copy-columns": _vm.copyColumnSettings,
              "share-columns": _vm.shareColumnSettings,
              "update-columns": _vm.updateColumnSettings,
              "remove-columns": _vm.removeColumnSettings,
              "save-columns": _vm.savePreset,
              "all-columns": _vm.showAllColumns,
              "no-columns": _vm.showNoColumns,
              "color-change": _vm.onColorChange,
              showSettings: _vm.showSettings,
              startDateChanged: function ($event) {
                return _vm.actionBarDateChanged($event, { isStartDate: true })
              },
              endDateChanged: function ($event) {
                return _vm.actionBarDateChanged($event, { isStartDate: false })
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          ref: "splitter-container",
          staticClass: "splitter-container",
          style: _vm.splitterStyle,
        },
        [
          _c(
            "div",
            { ref: "lhs-grid", staticClass: "lhs-grid" },
            [
              _c("ag-grid-vue", {
                staticClass: "ag-theme-balham task-grid-height",
                style: _vm.lhsGridStyle,
                attrs: {
                  id: "paged-grid",
                  gridOptions: _vm.gridOptions,
                  animateRows: "",
                  autoGroupColumnDef: _vm.autoGroupColumnDef,
                  columnDefs: _vm.columnDefs,
                  context: _vm.context,
                  defaultColDef: _vm.defaultColDef,
                  getMainMenuItems: _vm.getMainMenuItems,
                  noRowsOverlayComponent: "noRowsOverlay",
                  noRowsOverlayComponentParams:
                    _vm.noRowsOverlayComponentParams,
                  overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                  getRowId: (params) => params.data.uuId,
                  headerHeight: 34,
                  rowMultiSelectWithClick: "false",
                  rowSelection: "multiple",
                  sideBar: false,
                  suppressContextMenu: "",
                  suppressDragLeaveHidesColumns: "",
                  suppressCellFocus: false,
                  singleClickEdit: false,
                  enableRangeSelection: true,
                  enableFillHandle: true,
                  fillOperation: _vm.fillOperation,
                  fillHandleDirection: "xy",
                  processCellForClipboard: _vm.processCellForClipboard,
                  processCellFromClipboard: _vm.processCellFromClipboard,
                  navigateToNextCell: _vm.navigateToNextCell,
                  tabToNextCell: _vm.tabToNextCell,
                  suppressMultiSort: false,
                  suppressScrollOnNewData: "",
                  suppressRowClickSelection: "",
                  suppressRowDrag: "",
                  suppressClipboardApi: "",
                  rowModelType: "serverSide",
                  pagination: "",
                  paginationPageSizeSelector: false,
                  paginationPageSize: _vm.paginationPageSize,
                  cacheBlockSize: _vm.cacheBlockSize,
                  maxBlocksInCache: _vm.maxBlocksInCache,
                  serverSideInfiniteScroll: true,
                  enableCellEditingOnBackspace: "",
                },
                on: {
                  "grid-ready": _vm.onGridReady,
                  "cell-key-down": _vm.onCellKeyDown,
                  "paste-start": _vm.onPasteStart,
                  "paste-end": _vm.onPasteEnd,
                  "cell-focused": _vm.cellFocused,
                  "pagination-changed": _vm.onPaginationChanged,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "resizer",
              staticClass: "resizer",
              attrs: { id: "grid-gantt-resizer" },
              on: { mousedown: _vm.mouseDownHandler },
            },
            [
              _c(
                "div",
                {
                  ref: "resizer-overlay",
                  staticClass: "resizer-overlay",
                  attrs: { id: "resizer-overlay" },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "resizer-icon",
                    attrs: { icon: ["far", "arrows-left-right"] },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { ref: "rhs-chart", staticClass: "rhs-chart" },
            [
              _c("DhtmlxGantt", {
                attrs: {
                  scrollY: _vm.scrollState.top,
                  scrollX: _vm.scrollState.left,
                  height: _vm.ganttHeight,
                  router: _vm.routerObj,
                  ganttData: _vm.ganttData,
                  startDate: _vm.control.startDate,
                  endDate: _vm.control.endDate,
                  timescale: _vm.control.timescale,
                  criticalPath: _vm.control.criticalPath,
                  freeFloat: _vm.control.freeFloat,
                  defaultColoring: _vm.coloring.none,
                  pendingRerender: _vm.pendingRerender,
                  enableProjectColumn: true,
                  enableTaskPathColumn: true,
                  hideWeekends: _vm.taskGanttSettings.hideWeekends,
                  weekNumbers: _vm.taskGanttSettings.weekNumbers,
                  weekBase: _vm.taskGanttSettings.weekBase,
                  collapseId: _vm.collapseId,
                  expandId: _vm.expandId,
                  selectedTasks: _vm.taskSelectionIds,
                  readOnly:
                    !_vm.canEdit("TASK") ||
                    _vm.ganttReadOnly ||
                    _vm.ganttInsufficientEditPermission,
                  calendar: _vm.calendar,
                  locationCalendarMap: _vm.locationCalendarMap,
                  toDeleteTaskIds: _vm.ganttDeleteTaskIds,
                },
                on: {
                  "update:scrollX": function ($event) {
                    return _vm.$set(_vm.scrollState, "left", $event)
                  },
                  "update:scroll-x": function ($event) {
                    return _vm.$set(_vm.scrollState, "left", $event)
                  },
                  "update:pendingRerender": function ($event) {
                    _vm.pendingRerender = $event
                  },
                  "update:pending-rerender": function ($event) {
                    _vm.pendingRerender = $event
                  },
                  taskNew: _vm.taskNewHandler,
                  taskEdit: _vm.taskEditHandler,
                  taskLinkEdit: _vm.linkEditHandler,
                  ganttScroll: _vm.ganttScrollHandler,
                  taskUpdated: _vm.taskUpdatedHandler,
                  taskLinkUpdated: _vm.taskLinkUpdatedHandler,
                  taskLinkCreated: _vm.taskLinkUpdatedHandler,
                  taskLinkUpdateError: _vm.refreshData,
                  taskLinkCreateError: _vm.refreshData,
                  taskUpdateError: _vm.refreshData,
                  taskSelectionChanged: _vm.taskSelectionChangedHandler,
                  taskClicked: _vm.taskClickedHandler,
                  cellFocused: _vm.taskCellFocusedHandler,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("TaskModal", {
        attrs: {
          mode: "BOTH",
          id: _vm.taskEdit.uuId,
          isTemplate: false,
          parentId: _vm.taskEdit.parentId,
          projectId: _vm.taskEdit.projectId,
          show: _vm.state.taskShow,
        },
        on: {
          "update:id": function ($event) {
            return _vm.$set(_vm.taskEdit, "uuId", $event)
          },
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "taskShow", $event)
          },
          success: _vm.taskEditSuccess,
        },
      }),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
          cancel: _vm.progressCancel,
        },
      }),
      _c("TaskLinkModal", {
        attrs: {
          show: _vm.state.taskLinkEditShow,
          taskId: _vm.taskLinkEdit.taskId,
          predecessorId: _vm.taskLinkEdit.predecessorId,
          isTemplate: false,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "taskLinkEditShow", $event)
          },
          success: _vm.taskLinkEditSuccess,
        },
      }),
      _vm.state.projectSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.projectSelectorShow,
              entityService: _vm.projectUtil,
              entity: "PROJECT",
              nonAdmin: "",
              preselected: _vm.projectSelectorEdit.uuId,
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "projectSelectorShow", $event)
              },
              ok: _vm.projectSelectorOk,
            },
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.auto_assign.summary"),
            "ok-only": "",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.autoAssignSummaryOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.autoAssignSummaryShow,
            callback: function ($$v) {
              _vm.autoAssignSummaryShow = $$v
            },
            expression: "autoAssignSummaryShow",
          },
        },
        [
          _vm.autoAssignSummary.length > 0
            ? _c(
                "ul",
                { staticClass: "task-summary-list" },
                _vm._l(_vm.autoAssignSummary, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "d-block task-summary-list-item",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "task-summary-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.getPathNames(_vm.taskNames[item.taskUUID])
                              )
                            ),
                          ]),
                          item.staffAssignmentList.length > 0
                            ? _vm._l(
                                item.staffAssignmentList,
                                function (staff, sIndex) {
                                  return _c(
                                    "div",
                                    { key: sIndex, staticClass: "d-block" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "task.auto_assign.assigned",
                                              [
                                                `${staff.firstName} ${staff.lastName}`,
                                              ]
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                }
                              )
                            : _vm._e(),
                          item.staffAssignmentList.length === 0
                            ? _c("div", { staticClass: "d-block" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("task.auto_assign.none_assigned")
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.autoAssignSummary.length === 0
            ? _c("div", { staticClass: "d-block" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("task.auto_assign.none_assigned")) + " "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("AutoAssignStaffModal", {
        attrs: {
          projectId: _vm.project ? _vm.project.uuId : null,
          tasks: _vm.autoAssignTasks(),
          show: _vm.autoAssignStaffShow,
        },
        on: {
          "update:show": function ($event) {
            _vm.autoAssignStaffShow = $event
          },
          success: _vm.autoAssignStaffSuccess,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_change_on_complete"),
            "content-class": "change-on-complete-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: { close: _vm.changeOnCompleteCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({}) {
                return [
                  _vm.processTaskMoveChangedList.length > 0
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.applyAllChangeOnComplete,
                            callback: function ($$v) {
                              _vm.applyAllChangeOnComplete = $$v
                            },
                            expression: "applyAllChangeOnComplete",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.changeOnCompleteOk()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.changeOnCompleteCancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.confirmChangeOnCompleteShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "confirmChangeOnCompleteShow", $$v)
            },
            expression: "state.confirmChangeOnCompleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("task.confirmation.change_on_complete")) + " "
            ),
          ]),
        ]
      ),
      _c("TaskDateTimeDurationCalculation", {
        attrs: {
          show: _vm.durationCalculationShow,
          taskName: _vm.durationCalculation.taskName,
          defaultActionForNonWorkPrompt:
            _vm.durationCalculation.defaultActionForNonWorkPrompt,
          skipOutOfProjectDateCheck:
            _vm.durationCalculation.skipOutOfProjectDateCheck,
          enableManualScheduleSuggestion:
            _vm.durationCalculation.enableManualScheduleSuggestion,
          trigger: _vm.durationCalculation.trigger,
          startDateStr: _vm.durationCalculation.startDateStr,
          startTimeStr: _vm.durationCalculation.startTimeStr,
          closeDateStr: _vm.durationCalculation.closeDateStr,
          closeTimeStr: _vm.durationCalculation.closeTimeStr,
          durationDisplay: _vm.durationCalculation.durationDisplay,
          calendar: _vm.durationCalculation.calendar,
          projectScheduleFromStart:
            _vm.durationCalculation.projectScheduleFromStart,
          taskAutoScheduleMode: _vm.durationCalculation.taskAutoScheduleMode,
          constraintType: _vm.durationCalculation.constraintType,
          constraintDateStr: _vm.durationCalculation.constraintDateStr,
          lockDuration: _vm.durationCalculation.lockDuration,
          oldDateStr: _vm.durationCalculation.oldDateStr,
          oldTimeStr: _vm.durationCalculation.oldTimeStr,
          projectStartDateStr: _vm.durationCalculation.projectStartDateStr,
          projectCloseDateStr: _vm.durationCalculation.projectCloseDateStr,
          resizeMode: _vm.durationCalculation.resizeMode,
          oldConstraintType: _vm.durationCalculation.oldConstraintType,
          oldConstraintDateStr: _vm.durationCalculation.oldConstraintDateStr,
          clearPreviousChoice: _vm.dcClearPreviousChoice,
          showApplyAllCheckbox: _vm.dcShowApplyAllCheckbox,
          durationConversionOpts: _vm.durationConversionOpts,
        },
        on: {
          "update:show": function ($event) {
            _vm.durationCalculationShow = $event
          },
          "update:calendar": function ($event) {
            return _vm.$set(_vm.durationCalculation, "calendar", $event)
          },
          "update:clearPreviousChoice": function ($event) {
            _vm.dcClearPreviousChoice = $event
          },
          "update:clear-previous-choice": function ($event) {
            _vm.dcClearPreviousChoice = $event
          },
          success: _vm.durationCalculationOk,
          skip: function ($event) {
            return _vm.durationCalculationOk({ skip: true })
          },
          cancel: _vm.durationCalculationCancel,
          calendarChange: _vm.durationCalculationCalendarChange,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteViewOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteViewShow,
            callback: function ($$v) {
              _vm.confirmDeleteViewShow = $$v
            },
            expression: "confirmDeleteViewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("task.confirmation.delete_view")) + " "),
          ]),
        ]
      ),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptSaveShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.save"),
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptSaveShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptShareShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.share"),
          sharing: true,
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptShareShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "task-delete-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.tcConfirmDeleteShow = false
            },
            ok: _vm.tcConfirmDeleteOk,
            cancel: _vm.tcConfirmDeleteCancel,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _vm.tcShowApplyAllCheckbox
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.taskCol.applyAll,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskCol, "applyAll", $$v)
                            },
                            expression: "taskCol.applyAll",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tcConfirmDeleteShow,
            callback: function ($$v) {
              _vm.tcConfirmDeleteShow = $$v
            },
            expression: "tcConfirmDeleteShow",
          },
        },
        [_c("p", [_vm._v(_vm._s(_vm.tcConfirmDeleteStatement))])]
      ),
      _c("WorkEffortRemovalWarning", {
        attrs: {
          show: _vm.workEffortRemovalShow,
          enableApplyAll: "",
          taskId: _vm.workEffortRemoval.taskId,
          taskName: _vm.workEffortRemoval.taskName,
        },
        on: {
          "update:show": function ($event) {
            _vm.workEffortRemovalShow = $event
          },
          ok: _vm.workEffortRemovalOk,
          cancel: _vm.workEffortRemovalCancel,
        },
      }),
      _c("TaskGanttSettingsModal", {
        attrs: {
          show: _vm.settingsShow,
          settings: _vm.taskGanttSettings,
          hideWeekbase: true,
        },
        on: {
          "update:show": function ($event) {
            _vm.settingsShow = $event
          },
          ok: _vm.taskGanttSettingsOk,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }