<template>
  <div ref="taskCompact" class="task-compact ag-header-group-cell-label">
    <div ref="menuButton" class="ag-cell-label-container ag-header-cell-sorted-none">
      <div class="ag-header-cell-label">
        <div class="g-header-cell-menu" @[clickEvent]="onMenuClicked($event)">
          <font-awesome-icon icon="fa-regular fa-gear"/> 
        </div>
        <div style="display:inline-block; margin-left: 20px">
          <font-awesome-icon v-if="totalMode" style="margin-right: 6px; vertical-align: -3px;" icon="fa-regular fa-sigma"/>
          <span class="ag-header-cell-text">
            <span class="align-middle">
              {{ groupHeaderLabel }}
            </span>
          </span>
        </div>
        <div ref="task-compact-multiselect-wrapper" class="ml-1 task-compact-multiselect-wrapper" > 
          <multiselect ref="task-compact-multiselect" v-model="property" class="custom-dropdown-options task-compact-multiselect-cell-header enable-option-icon fit-label-fix fit-content-fix"
            :class="multiselectId"
            :max-height="1000"
            :options="propertyOpts.map(i => i.value)"
            :custom-label="getPropertyOptionLabel"
            :placeholder="''"
            :searchable="false" 
            :allow-empty="false"
            :showLabels="false"
            @open="onSelectOpened">
            <template slot="option" slot-scope="props">
              <font-awesome-icon class="selected-option-icon" v-if="property == props.option" :icon="['far', 'check']" />
              <span class="option__title">{{ getPropertyOptionLabel(props.option) }}</span>
            </template>
          </multiselect>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { strRandom } from '@/helpers';
import { debounce } from 'lodash';
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
export default Vue.extend({
  name: 'TaskCompactHeaderGroup',
  components: {
    Multiselect
  },
  data() {
    return {
      totalMode: false,
      property: null,
      groupId: null,
      clickOutsideEventHandler: null,
      hasDifferences: false,
      propertyOpts: [],
    }
  },
  created() {
    this.multiselectId = `task-compact-multiselect-${strRandom(8)}`;
  },
  beforeMount() {
    if (this.params) {
      if (this.params.totalMode) {
        this.totalMode = this.params.totalMode;
      }
      this.property = this.params.property;
      this.hasDifferences = this.params.hasDifferences == true? true : false;
      if (this.params.columnGroup != null) {
        this.groupId = this.params.columnGroup.groupId;
      }
      if (this.params.propertyOpts != null && Array.isArray(this.params.propertyOpts)) {
        this.propertyOpts = this.params.propertyOpts;
      }
    }
  },
  mounted() {
    const self = this;
  },
  beforeDestroy() {
    this.cleanupMenu();
    this.multiselectId = null;
  },
  watch: {
    property(newValue) {
      if (newValue && newValue != this.params.property) {
        //Fixed #1898 by adding setTimeout statement.
        //Suspect the UI is forced re-rendered while click/press action is not complete. Hence, the mouse drag UI is rendered.
        setTimeout(() => {
          this.params.context.componentParent.taskGroupPropertyChanged({
            groupId: this.groupId
            , property: newValue
          });
        }, 100);
      }
    }
  },
  computed: {
    taskGroupOptionHeaderLabel() {
      return  `${this.$t('label_task_group')}: ${this.params.displayName}:`;
    },
    groupHeaderLabel() {
      return `${this.params.displayName}`;
    },
    selectedPropertyLabel() {
      return this.propertyOpts.find(i => i.value == this.property)?.text ?? '';
    },
    taskPropertyClickEvent() {
      return this.isTouchDevice()? 'touchstart' : null;
    },
    clickEvent() {
      return this.isTouchDevice()? 'touchstart' : 'click';
    }
  },
  methods: {
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
      const mq = function (query) {
          return window.matchMedia(query).matches
      }
      if ('ontouchstart' in window) {
          return true
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
      return mq(query)
    },
    cleanupMenu() {
      if (this.clickOutsideEventHandler != null) {
        this.clickOutsideEventHandler({ target: null });
        document.removeEventListener('mousedown', this.clickOutsideEventHandler);
        this.clickOutsideEventHandler = null;
      }
    },
    onMenuClicked(event) {
      //Defensive code: Remove popup element and related listener if the clickOutsideEventHandler is not null.
      this.cleanupMenu();

      const headerGroupCellLeft = event.target.closest('.ag-root-wrapper-body').getBoundingClientRect().left;
      let toggler = event.target;
      if (!toggler.classList.contains('g-header-cell-menu')) {
        toggler = event.target.closest('.g-header-cell-menu');
      }
      const left = toggler.getBoundingClientRect().left - headerGroupCellLeft;
      this.getColumnMenu({ left });
    },
    menuOptionUpdate() {
      return this.getMenuOptionTemplate('update', this.$t('task_compact.reset_to_global_setting'), { icon: 'fa-rotate' });
    },
    menuOptionTotalMode() {
      return this.getMenuOptionTemplate('total', this.totalMode == true? this.$t('task_compact.hide_totals') : this.$t('task_compact.show_totals'), { icon: 'fa-sigma' });
    },
    menuOptionManageTaskGroups() {
      return this.getMenuOptionTemplate('manage-task-groups', this.$t('task.button.manage_task_groups'), { icon: 'fa-table-cells' });
    },
    menuOptionSaveAs() {
      return this.getMenuOptionTemplate('save-as', this.$t('task.button.save_as_new_task_group'), { icon: 'fa-floppy-disk' });
    },
    menuOptionUploadToGlobal() {
      return this.getMenuOptionTemplate('upload-to-global', this.$t('task_compact.upload_to_global_setting'), { icon: 'fa-arrow-up-from-bracket' });
    },
    getColumnMenu({ left } = {}) {
      const htmlContentStr = `
        <div class="ag-tabs ag-menu ag-focus-managed ag-ltr ag-popup-child" role="dialog" aria-label="Column Menu" style="visibility: hidden">
          <div role="tablist" class="ag-tabs-header ag-menu-header">
              <span role="tab" tabindex="-1" class="ag-tab ag-tab-selected" aria-label="general">
                  <span class="ag-icon ag-icon-menu" unselectable="on" role="presentation"></span>
              </span>
          </div>
          <div role="presentation" class="ag-tabs-body ag-menu-body">
              <div class="ag-menu-list ag-focus-managed" role="tree">
                  <div class="ag-tab-guard ag-tab-guard-top" role="presentation" tabindex="0"></div>
                  ${ this.getMenuOptionHeaderTemplate(this.taskGroupOptionHeaderLabel) }
                  <div class="ag-menu-option" tabindex="-1" role="treeitem" aria-level="1" data-action="edit">
                      <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation">
                        <i class="fa-regular fa-pen-to-square"></i>
                      </span>
                      <span ref="eName" class="ag-menu-option-part ag-menu-option-text">${this.$t('task_compact.edit')}</span>
                      <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
                      <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
                  </div>
                  ${ this.menuOptionTotalMode() }
                  ${ this.getMenuOptionHeaderTemplate(this.$t('label_saved_task_groups')) }
                  ${ this.hasDifferences? this.menuOptionUploadToGlobal() : '' }
                  ${ this.hasDifferences? this.menuOptionUpdate() : '' }
                  ${ this.menuOptionSaveAs() }
                  ${ this.menuOptionManageTaskGroups() }
                  <div class="ag-tab-guard ag-tab-guard-bottom" role="presentation" tabindex="0"></div>
              </div>
          </div>
      </div>
      `;
      const columnMenu = this.htmlToElement(htmlContentStr.trim().replaceAll(/>\n[\s]+/g, '>'));
      columnMenu.style.left = `${left - 12}px`;
      columnMenu.style.top = '1px';
      const popupElements = document.querySelectorAll('.ag-root-wrapper > .ag-popup');
      for (let i = 0, len = popupElements.length; i < len; i++) {
        popupElements[i].remove();
      }
      
      const popupElement = document.createElement('div');
      popupElement.classList.add('ag-theme-balham')
      popupElement.classList.add('ag-popup');
      popupElement.classList.add('header-group-column-popup');
      popupElement.classList.add('task-compact-setting-menu');
      popupElement.appendChild(columnMenu);
      document.querySelector('.ag-root-wrapper').appendChild(popupElement);

      //Register event for menu header
      const menuHeader = columnMenu.querySelector('.ag-menu-header');
      menuHeader.addEventListener('click', this.handleMenuHeaderClick);



      //Register events for menu options      
      const menuOptions = columnMenu.querySelectorAll('.ag-menu-option');
      if (menuOptions != null) {
        for (let i = 0, len = menuOptions.length; i < len; i++) {
          //'Click'
          menuOptions[i].addEventListener('click', this.handleMenuOptionClick);
          //'Mouseenter' and 'Mouseleave'
          menuOptions[i].addEventListener('mouseenter', this.addMenuOptionHighlight);
          menuOptions[i].addEventListener('mouseleave', this.removeMenuOptionHighlight);
        }
      }

      //Adjust left position if not enough room for the menu width
      const viewportWidth = document.documentElement.clientWidth;
      const rect = columnMenu.getBoundingClientRect();
      const availableWidth = viewportWidth - rect.left;
      const requiredWidth = rect.right - rect.left;
      if (requiredWidth > availableWidth) {
        let adjustedLeft = left - (requiredWidth - availableWidth) - 25;
        columnMenu.style.left = `${adjustedLeft}px`;
      }
      columnMenu.style.visibility = 'visible';
      
      //Register event for hiding menu when clicking outside of menu
      this.clickOutsideEventHandler = function(e) {
        const popupElem = document.querySelector('.header-group-column-popup.task-compact-setting-menu');
        if (popupElem != null) {
          if (!popupElem.contains(e.target)) {
            popupElem.remove();
          }
        }
      }
      document.addEventListener('mousedown', this.clickOutsideEventHandler);
    },
    handleMenuOptionClick(event) {
      const menuOption = event.target.closest('.ag-menu-option');
      const action = menuOption.dataset.action;
      if (action == 'edit') {
        this.params.context.componentParent.taskGroupEdit(this.groupId);
      } else if (action == 'total') {
        this.params.context.componentParent.taskGroupTotalModeToggled(this.groupId);
      } else if (action == 'save-as') {
        this.params.context.componentParent.taskGroupSaveAs(this.groupId);
      } else if (action == 'manage-task-groups') {
        this.params.context.componentParent.taskGroupManageTaskGroups();
      } else if (action == 'update') {
        this.params.context.componentParent.taskGroupSync(this.groupId);
      } else if (action == 'upload-to-global') {
        this.params.context.componentParent.taskGroupUpload(this.groupId);
      }
      this.cleanupMenu();
    },
    addMenuOptionHighlight(event) {
      const menuOption = event.target.closest('.ag-menu-option');
      const activeClass = 'ag-menu-option-active';
      if (!menuOption.classList.contains(activeClass)) {
        menuOption.classList.add(activeClass);
      }
    },
    removeMenuOptionHighlight(event) {
      const menuOption = event.target.closest('.ag-menu-option');
      const activeClass = 'ag-menu-option-active';
      if (menuOption.classList.contains(activeClass)) {
        menuOption.classList.remove(activeClass);
      }
    },
    htmlToElement(html) {
      var template = document.createElement('template');
      html = html.trim(); // Never return a text node of whitespace as the result
      template.innerHTML = html;
      return template.content.firstChild;
    },
    handleMenuHeaderClick(/* event */) {
      this.cleanupMenu();
    },
    refresh(params) {
      this.params = params;
      if (this.params && this.params.totalMode != null) {
        this.totalMode = this.params.totalMode;
      }
      this.hasDifferences = this.params.hasDifferences == true? true : false;
    },
    getMenuOptionTemplate(action, label, { icon=null }={}) {
      return `
        <div class="ag-menu-option" tabindex="-1" role="treeitem" aria-level="1" data-action="${action}">
          <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation">
            <i class="fa-regular ${icon}"></i>
          </span>
          <span ref="eName" class="ag-menu-option-part ag-menu-option-text">${label}</span>
          <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
          <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
        </div>`;
    },
    menuOptiondivider() {
      return `
        <div class="ag-menu-separator" aria-hidden="true">
          <div class="ag-menu-separator-part"></div>
          <div class="ag-menu-separator-part"></div>
          <div class="ag-menu-separator-part"></div>
          <div class="ag-menu-separator-part"></div>
        </div>`;
    },
    getMenuOptionHeaderTemplate(label, { icon=null }={}) {
      return `
        <div class="ag-menu-option ag-menu-header" tabindex="-1" role="treeitem" aria-level="1">
          <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation">
            <i class="fa-regular ${icon}"></i>
          </span>
          <span ref="eName" class="ag-menu-option-part ag-menu-option-text">${label}</span>
          <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
          <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
        </div>`;
    },

    //Multiselect related methods
    getOptionLabel(value) {
      return this.options.find(i => i.value === value)?.text || value;
    },
    onSelectOpened() {
      const ms = this.$refs['task-compact-multiselect'];
      ms.$refs.list.style.left = `${this.$refs['task-compact-multiselect-wrapper'].getBoundingClientRect().left}px`;
      ms.$refs.list.style.position = 'fixed';
    },
    getPropertyOptionLabel(value) {
      return this.propertyOpts.find(i => i.value === value)?.text || value;
    }
  }
})
</script>

<style lang="scss" scoped>
.task-compact {
  .ag-header-group-cell-label.ag-header-active .ag-header-cell-menu-button {
    opacity: 1;
  }
  .ag-header-group-cell-label .ag-header-cell-menu-button:hover {
    cursor: pointer;
  }
  .g-header-cell-menu {
    position: absolute;
    padding: 8px 3px;
    cursor: pointer;
    top: 0px;
    left: 12px;
  }
}

.task-compact .ag-cell-label-container {
  padding: 0;
}

.task-compact {
  .task-compact-select {
    position: relative;
  }

  select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
        
    background-color: transparent;
    background-image: var(--taskcompact-select-arrow);
    background-position: right 5px bottom 50%;
    background-repeat: no-repeat;
    background-size: 10px;

    border: 1px solid var(--ag-border-color);
    cursor: pointer;

    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 600;
        
    color: var(--ag-header-foreground-color);
    margin-left: 8px;
    padding: 5px 10px 5px 5px;
    vertical-align: middle;
    width: 100%;
    
    overflow-y: auto;
    option {
      overflow-y: scroll;
      font-family: 'Roboto';
      background-color: var(--ag-background-color);
    }

    &:focus-visible {
      outline: none;
    }
  }

  .task-compact-selectv2 {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
        
    background-color: transparent;
    background-image: var(--taskcompact-select-arrow);
    background-position: right 5px bottom 50%;
    background-repeat: no-repeat;
    background-size: 10px;

    border: 1px solid var(--ag-border-color);
    cursor: pointer;

    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 600;
        
    color: var(--ag-header-foreground-color);
    margin-left: 8px;
    padding: 5px 10px 5px 5px;
    vertical-align: middle;
    width: 100%;

    &:focus-visible {
      outline: none;
    }

    min-width: 150px;
  }
}
</style>