

<template>
  <div class="select-header-container">
    <span v-if="label" class="mr-1">{{label }}</span>
    <b-form-select v-model="value" class="select-header" :options="options" @change="onChange"></b-form-select>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'ListHeaderComponent',
  data() {
    return {
      value: null,
      options: [],
      label: null
    }
  },
  beforeMount() {
    if (this.params !== null) {
      this.options = this.params.options;
      this.value = this.params.value;
      this.label = this.params.label ? this.params.label : null;
    }
  },
  computed: {
    
  },
  methods: {
    onChange() {
      this.params.context.componentParent.listChange(this.value);
    }
  }
})
</script>

<style lang="scss" scoped>
.select-header {
  font-family: var(--ag-font-family);
  font-size: var(--ag-font-size);
  height: 25px;
  width: auto;
  padding: 2px 6px;
  flex: 1;
}

.select-header-container {
  display: flex;
  align-items: center;
}
</style>
