<template>
  <div class="position-relative">
    <div class='scheduler-actions pl-1'>
      <span  v-if="showAdd">
        <b-popover
          :target="`BTN_ADD_SCHEDULER`"
          placement="top"
          triggers="hover"
          :content="$t('button.add')">
        </b-popover>
        <b-btn :id="`BTN_ADD_SCHEDULER`" class="add-event" @click="eventAdd"><font-awesome-icon :icon="['far', 'plus']"/></b-btn>  
      </span>
      <span  v-if="canEdit()">
        <b-popover
          :target="`BTN_EDIT_SCHEDULER`"
          placement="top"
          triggers="hover"
          :content="$t('button.edit')">
        </b-popover>
        <b-btn :disabled="disableEdit" :id="`BTN_EDIT_SCHEDULER`" class="edit-event" @click="eventEdit"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>  
      </span>
      <span  v-if="canDelete('ACTIVITY') || canDelete('BOOKING') || canDelete('TASK') || canDelete('CALENDAR')">
        <b-popover
          :target="`BTN_DELETE_SCHEDULER`"
          placement="top"
          triggers="hover"
          :content="$t('button.delete')">
        </b-popover>
        <b-btn :disabled="disableDelete" :id="`BTN_DELETE_SCHEDULER`" class="delete-event" @click="eventDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>  
      </span>
      <span>
        <b-popover
          :target="`BTN_COPY_SCHEDULER`"
          placement="top"
          triggers="hover"
          :content="$t('button.copy')">
        </b-popover>
        <b-btn :disabled="disableCopy" :id="`BTN_COPY_SCHEDULER`" class="copy-event" @click="eventCopy"><font-awesome-icon :icon="['far', 'copy']"/></b-btn>  
      </span>
      <span>
        <b-popover
          :target="`BTN_PASTE_SCHEDULER`"
          placement="top"
          triggers="hover"
          :content="$t('button.paste')">
        </b-popover>
        <b-btn :disabled="disablePaste" :id="`BTN_PASTE_SCHEDULER`" class="paste-event" @click="eventPaste"><font-awesome-icon :icon="['far', 'paste']"/></b-btn>  
      </span>
      <span>
        <span :id="`BTN_IMPORT_SCHEDULER`">
          <b-btn :disabled="!filter.usage && !filter.activity && !filter.booking && !filter.task && !filter.vacation" @click="fileImport" class="mr-1 export-event"><font-awesome-icon :icon="['far','inbox-in']"/></b-btn>
        </span>
        <b-popover :target="`BTN_IMPORT_SCHEDULER`" triggers="hover" placement="top" boundary="viewport">
          {{ $t('task.button.import_document') }}
        </b-popover>
      </span>
      <span>
        <span :id="`BTN_EXPORT_SCHEDULER`">
          <b-btn :disabled="!filter.usage && !filter.activity && !filter.booking && !filter.task && !filter.vacation" @click="fileExport" class="mr-1 export-event"><font-awesome-icon :icon="['far','inbox-out']"/></b-btn>
        </span>
        <b-popover :target="`BTN_EXPORT_SCHEDULER`" triggers="hover" placement="top" boundary="viewport">
          {{ $t('task.button.export_document') }}
        </b-popover>
      </span>
      <!-- <span class="divider">
        <span readonly class="scheduler-divider action-v-divider">|</span>
      </span>
      <span class="filter-flex-grow-container">
        <BadgeFilter :filters="filterObject" :historyList="filterHistory" :fields="filterFields" :fieldValues="filterFieldValues" @modified="onFilterModified" @historyModified="onHistoryModified"></BadgeFilter>
      </span> -->
    </div>
    
    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteEventShow"
        @ok="confirmDeleteEventOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(selected.length === 1 ? 'activity.confirmation.delete' : 'activity.confirmation.delete_plural') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
  </div>
</template>

<script>

// import BadgeFilter from '@/components/Filter/BadgeFilter';

import { cloneDeep } from 'lodash';

export default {
  name: 'SchedulerToolbar',
  components: {
    // BadgeFilter
  },
  props: {
    filterObject: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Object,
      default: () => { return {} }
    },
    showAdd: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Array,
      default: () => []
    },
    filterFields: {
      type: Array,
      default: () => []
    },
    filterHistory: {
      type: Array,
      default: () => []
    },
    filterFieldValues: {
      type: Object,
      default: () => {}
    },
    resourceField: {
      type: String,
      default: 'staffUuid'
    },
    scheduler: {
      type: Object,
      default: null
    },
    markedSpans: {
      type : Object,
      default: () => {}
    },
    showDeleteConfirmation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      permissionName: "ACTIVITY",
      confirmDeleteEventShow: false,
      mSpans: {},
      copied: false
    }
  },
  watch: {
    markedSpans(newValue) {
      this.mSpans = newValue;
    },
    showDeleteConfirmation(newValue) {
      if (newValue) {
        this.confirmDeleteEventShow = true;
        this.$emit('update:showDeleteConfirmation', false);
      }
    }
  },  
  computed: {
    disableEdit() {
      return this.selected.length !== 1;
    },
    disableCopy() {
      if (this.selected.length > 0) {
        if (this.scheduler == null) {
          return true;
        }
        /* eslint-disable no-undef */
        const event = this.scheduler.getEvent(this.selected[0]);
        /* eslint-enable no-undef */
        if (event) {
          if (event.type === 'task' && !this.canAdd('TASK', ["complexity",
          "identifier",
          "color",
          "fixedCost",
          "description",
          "constraintTime",
          "priority",
          "duration",
          "autoScheduling",
          "constraintType",
          "taskType",
          "closeTime",
          "name",
          "progress",
          "startTime",
          "currencyCode"])) {
            return true;
          }
          else if (event.type === 'activity' && !this.canAdd('ACTIVITY', ["duration",
          "identifier",
          "color",
          "fixedCost",
          "closeTime",
          "name",
          "description",
          "progress",
          "startTime",
          "websites",
          "currencyCode"])) {
            return true;
          }
          else if (event.type === 'booking' && !this.canAdd('BOOKING', ["duration",
          "beginDate",
          "identifier",
          "quantity",
          "color",
          "untilDate",
          "name",
          "description",
          "utilization"])) {
            return true;
          }
          else if (event.type === 'vacation' && !this.canAdd('CALENDAR')) {
            return true;
          }
          else if (event.type === 'resourceUnit' && !this.canEdit('STAFF')) {
            return true;
          }
        }
      }
      return this.selected.length === 0;
    },
    disablePaste() {
      return !this.copied;
    },
    disableDelete() {
      if (this.selected.length === 0 || this.scheduler == null) {
        return true;
      }
      
      for (const id of this.selected) {
        /* eslint-disable no-undef */
        const event = this.scheduler.getEvent(id);
        if (event == null) {
          return true;
        }
        /* eslint-enable no-undef */
        if (event.type === 'booking' &&
            !this.canDelete('BOOKING')) {
          return true;   
        }
        else if (event.type === 'activity' &&
            !this.canDelete('ACTIVITY')) {
          return true;   
        }
        else if (event.type === 'task' &&
            !this.canDelete('TASK')) {
          return true;   
        }
        else if (event.type === 'vacation' &&
            !this.canDelete('CALENDAR')) {
          return true;   
        }
      }
      return false;
    }
  },
  mounted() {
    
  },
  created() {
    if (this.markedSpans != null) {
      this.mSpans = this.markedSpans;
    }
    this.$emit('schedulerToolbarCreated', this);
  },
  beforeDestroy() {
    
  },
  methods: {
    eventDelete() {
      this.confirmDeleteEventShow = true;
    },
    eventAdd(e) {
      // don't let keynav handle this event
      e.stopPropagation();
      e.preventDefault();
      this.$emit('addEvent');
    },
    eventCopy() {
      if (this.scheduler == null) {
        return;
      }
      /* eslint-disable no-undef */
      const toCopy = this.selected.map(i => { 
        const ev = cloneDeep(this.scheduler.getEvent(i));
        ev.refId = ev.id;
        ev.id = null;
        return ev;
      });
      /* eslint-enable no-undef */
      this.copied = true;
      this.$emit('copyEvent', toCopy);
    },
    eventPaste() {
      this.$emit('pasteEvent');
    },
    // eventStaff() {
    //   if (this.resourceField === 'staffUuid') {
    //     this.$emit('staffEvent');
    //   }
    //   else {
    //     this.$emit('resourceEvent');
    //   }
    // },
    eventEdit() {
      if (this.scheduler == null) {
        return;
      }
      /* eslint-disable no-undef */
      const toEdit = this.selected.map(i => { 
        const event = this.scheduler.getEvent(i);
        return { uuId: i, name: event.text, type: event.type, pu: event.pu ,
                 start_date: event.start_date, end_date: event.end_date,
                 calendar_type: event.calendar_type, readonly: event.readonly,
                 startHour: event.startHour, endHour: event.endHour
      }});

      this.$emit('editEvent', toEdit[0]);
    },
    confirmDeleteEventOk() {
      if (this.scheduler == null) {
        return;
      }
      const self = this;
      /* eslint-disable no-undef */
      const toRemove = this.selected.map(i => { 
        const event = this.scheduler.getEvent(i);
        
        // Remove the usage data
        if (event.type === 'task' ||
            event.type === 'booking' ||
            event.type === 'activity') {
          const staff = this.treeData.find(s => s.uuId === event.staffUuid);
          if (staff) {
            const task = staff[`${event.type}List`].findIndex(t => t.uuId === event.id);
            if (task !== -1) {
              staff[`${event.type}List`].splice(task, 1);
            }
          }
        }
        
        const uuId = i.slice(i.length - 36);
        
        if (uuId in self.mSpans) {
          for (const span of self.mSpans[uuId]) {
            this.scheduler.deleteMarkedTimespan(span.id);
          }
          delete self.mSpans[uuId];
        }
        return { uuId: uuId, type: event.type, section_id: event.section_id }
      });
      
      for (const r of this.selected) {
        const ev = this.scheduler.getEvent(r);
        if (ev && ev.clones) {
          // remove clones of the event
          for (const rClone of ev.clones) {
              
            this.scheduler.deleteEvent(rClone);
          }
        }
        this.scheduler.deleteEvent(r);
        /* eslint-enable no-undef */
      } 
      this.$emit('deleteEvent', toRemove);
    },
    onFilterModified(filter) {
      this.$emit('filterModified', filter);
    },
    onHistoryModified(history) {
      this.$emit('historyModified', history);
    },
    fileImport() {
      this.$emit('import');
    },
    fileExport() {
      this.$emit('exportToFile');
    },
    setTreeData(data) {
      this.treeData = data;
    }
  }
}
</script>
<style>
  .dhx_matrix_cell div.load-marker{
    position: absolute;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 12px;
    line-height: 25px;
    height: 25px;
  }

  .load-marker-no{
    opacity: 0.5;
    background-color: var(--scheduler-cell-disabled);
  }
  .load-marker-low{
    color: var(--white);
     background-color: var(--green-200);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-med{
    color: var(--white);
     background-color: var(--green-400);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-high{
    color: var(--white);
     background-color: var(--green-600);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-full {
    color: var(--white);
     background-color: var(--green-800);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-over {
    background-color: var(--red-400);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-over2 {
    background-color: var(--red-600);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-over3 {
    background-color: var(--red-800);
     cursor: pointer;
     z-index: 2;
  }
  .load-marker-not-employed {
    opacity: 0.7;
    background-color: #000000;
    height: 100% !important;
  }
  .load-marker-bad-assign {
    opacity: 0.7;
    background-color: #000000;
     z-index: 2;
  }
  .load-marker-weekend {
    opacity: 0.5;
    background-color: var(--scheduler-cell-disabled);
  }
  
  .dhx_matrix_scell div {
    white-space: nowrap;
  }
  
  .dhx_matrix_now_time {
    border-left: 2px solid var(--gantt-start-end-marker-bg) !important;
  }

  .dhx_matrix_scell {
    font-size: 12px;
    font-weight: 400;
  }
  
  .dhx_scell_level7 {
    padding-left: 110px;
  }
  .dhx_scell_level6 {
    padding-left: 95px;
  }
  .dhx_scell_level5 {
    padding-left: 80px;
  }
  .dhx_scell_level4 {
    padding-left: 65px;
  }
  
  .dhx_cal_event_line .dhx_event_resize {
    z-index: 1;
    top: 3px;
    height: 14px !important;
  }
  
  .dhx_cal_event_line {
    color: var(--white);
    line-height: 22px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 300;
    border: none;
    margin-top: 1px;
    background: transparent;
  }
  
  .event_selected {
    box-shadow: 0 0 0 0.2rem var(--scheduler-focus-shadow);
  }
  
  .event_new {
    border-color: var(--gantt-task) !important;
    border: 1px solid var(--gantt-task);
  }
  
  .dhx_timeline_label_row {
    background-color: var(--ag-background-color);
  }
  
  .dhx_data_table.folder .dhx_matrix_cell, .dhx_matrix_scell.folder {
    background-color: var(--ag-background-color);
    border-right: 1px solid var(--ag-row-border-color);
  }
  
  .dhx_cal_header div.dhx_bottom_scale_container .dhx_scale_bar {
    border-left-color: var(--ag-row-border-color);
  }
  
  .dhx_cal_header {
    background: #cecece;
    border-top: none;
  }
  
  .dhx_timeline_scale_header.dhx_timeline_second_scale {
    border-right: none;
  }
  
  .dhx_scale_bar.dhx_second_scale_bar {
    border-bottom-color: var(--ag-row-border-color);
  }
    
  .dhx_cal_data .dhx_timeline_table_wrapper div {
    border-color: var(--ag-row-border-color);
  }

  .dhx_cal_data {
    border-top: none;
  }
  
  .dhx_timeline_label_wrapper, .dhx_timeline_scale_header,
  .dhx_cal_container {
    background-color: var(--bs-border);
  }
  
  .dhx_cal_navline .dhx_cal_date {
    color: var(--ag-data-color);
  }
  
  .dhx_matrix_scell.folder .dhx_scell_name {
    float: none;
    text-transform: none;
  }
  
  .dhx_cal_header,
  .dhx_cal_navline {
    z-index: 0;
  }
  
  .dhx_cal_navline {
    display: none;
  }
  
  .dhx_matrix_scell.folder .dhx_scell_expand {
    margin: 0 3px;
    display: none !important;
  }
  
  .dhx_matrix_cell div, .dhx_matrix_scell div {
    text-align: left;
  }
  
  .dhx_matrix_scell.folder.opened .dhx_scell_expand:after,
  .dhx_matrix_scell.folder.closed .dhx_scell_expand:after {
    background: none;
  }
  
  .dhx_matrix_scell, .dhx_timeline_scale_header {
    color: var(--ag-data-color);
  }
  
  .dhx_scale_bar .dhx_second_scale_bar {
    top: 1px;
    border-top: none;
  }
  
  .dhx_matrix_cell {
    background-color: var(--ag-odd-row-background-color);
    border-color: var(--ag-row-border-color);
  }
  
  .dhx_scale_bar,
  .dhx_timeline_scale_header {
    background-color: var(--gantt-scale-line2-cell-bg);
    color: var(--ag-header-foreground-color);
    font-size: 12px;
    font-weight: bold;
  }
  
  .dhx_focus_slot {
    background-color: var(--selection-background-color);
    border: 2px solid;
    border-color: var(--selection-border-color) !important;
    border-style: var(--selection-border-style);
    opacity: 1;
    z-index: 3;
  }
  
  .dhx_scale_bar.dhx_second_scale_bar {
    border-top-color: var(--gantt-scale-line2-cell-bg) !important;
  }
  .dhx_cal_header div .dhx_scale_bar:not(.dhx_second_scale_bar) {
    border-top: 1px solid var(--border-light) !important;
  }
  
  .dhx_matrix_cell .load-marker-weekend {
    height: 100% !important;
  }
  
  .dhx_data_table.folder .dhx_matrix_cell, .dhx_matrix_scell.folder {
    cursor: auto;
  }
  
  .dhx_cal_header div .dhx_scale_bar.dhx_second_scale_bar {
    border-bottom: 1px solid transparent !important;
  }
  
  .dhx_cal_container .dhx_cal_header.dhx_second_cal_header .dhx_scale_bar.dhx_second_scale_bar {
    border-left: 1px solid var(--ag-header-column-separator-color);
  }
  
  .dhx_cal_container .dhx_cal_header.dhx_second_cal_header .dhx_scale_bar {
    border-left: 1px solid var(--ag-header-column-separator-color);
    border-color: var(--ag-header-column-separator-color);
  }
  
  .tag {
    border-radius: 3px;
    vertical-align: text-bottom;
    display: inline-block;
    color: var(--white);
    font-size: 0.8em;
    padding: 0 5px 0 5px;
    height: 15px;
    line-height: 15px;
    margin-right: 3px;
    min-width: 16px;
  }

.tag-company {
  background-color: var(--deep-purple-400);
}

.tag-department {
  background-color: var(--blue-900);
}

.tag-skill {
  background-color: var(--deep-purple-300);
}

.tag-stage {
  background-color: var(--brown-400);
}

.tag-project {
  background-color: var(--pink-800);
}

.tag-staff-generic {
  background-color: var(--blue);
}

.tag-location {
  background-color: var(--teal);
}

.tag-staff {
  background-color: var(--pumpkin);
}

.tag-task {
  background-color: var(--cyan-600);
}

.tag-activity {
  background-color: var(--activity);
}

.tag-vacation {
  background-color: var(--blue-gray-300);
}

.tag-new {
  background-color: #777;
}

.tag-booking {
  background-color: var(--booking);
}

.tag-resource {
  background-color: var(--light-green-900);
}

.cell-tooltip,
.dhtmlXTooltip.tooltip[role=tooltip] {
  box-shadow: var(--surface-shadow);
  border-radius: 0.25rem;
  pointer-events: none;
  transition: opacity 1s;
  background-color: var(--popover-body-bg) !important;
  color: var(--popover) !important;
  font-size: 12px;
  z-index: 10000;
}

.dhtmlXTooltip.tooltip[role=tooltip] {
  border: none;
  padding: 0;
}

.cell-tooltip p,
.task-tooltip-body p {
  margin: 5px;
  white-space: nowrap;
}

.task-tooltip-body {
  padding-left: 3px;
  padding-right: 3px;
}

.header {
  vertical-align: center;
  margin-right: 3px;
}

.header-box {
  background-color: var(--gray-500);
  color: var(--white);
  border-bottom: 1px solid var(--border-medium);
  display: flex;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.add-event.btn-secondary,
.edit-event.btn-secondary,
.delete-event.btn-secondary,
.copy-event.btn-secondary,
.paste-event.btn-secondary,
.staff-event.btn-secondary,
.export-event.btn-secondary {
  background-color: transparent;
  border-color: transparent;
  color: var(--grid-toolbar-button);
  padding: 2px 6px;
  font-size: 1rem;
}

.add-event.btn-secondary.disabled,
.edit-event.btn-secondary.disabled,
.delete-event.btn-secondary.disabled,
.copy-event.btn-secondary.disabled,
.paste-event.btn-secondary.disabled,
.staff-event.btn-secondary.disabled,
.export-event.btn-secondary.disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--grid-toolbar-button);
  opacity: 0.4;
}

.add-event.btn-secondary:focus,
.edit-event.btn-secondary:focus,
.delete-event.btn-secondary:focus,
.copy-event.btn-secondary:focus,
.paste-event.btn-secondary:focus,
.staff-event.btn-secondary:focus,
.export-event.btn-secondary:focus {
  box-shadow: none;
}

.add-event.btn-secondary:hover,
.edit-event.btn-secondary:hover,
.delete-event.btn-secondary:hover,
.copy-event.btn-secondary:hover,
.paste-event.btn-secondary:hover,
.staff-event.btn-secondary:hover,
.export-event.btn-secondary:hover {
  color: var(--grid-toolbar-button);
  background-color: transparent;
  border-color: transparent;
}

.dhx_matrix_cell div.today,
.dhx_scale_bar.today {
  background-color: var(--today);
  height: 100%;
  z-index: 0;
}

.partial-start .dhx_event_resize_start {
  display: none !important;
}

.partial-end .dhx_event_resize_end {
  display: none !important;
}

.scheduler-actions {
  background: var(--surface-bg);
  border-bottom: 1px solid var(--bs-border);
  border-left: 1px solid var(--bs-border);
  border-right: 1px solid var(--bs-border);
  display: flex;
}

@media only screen and (max-width: 600px) {
  .scheduler-actions {
    display: block;
  }
  .dhx_matrix_scell{
    display:none !important;
  }
}

.filter-flex-grow-container {
  flex: 1;
  margin-top: -2px;
}

.event-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  margin-left: 12px;
  margin-right: 12px;
  position: relative;
  top: 0;
}

.readonly-icon {
  margin-right: 5px;
}

.staff-tooltip-skills {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: 200px;
}

::-webkit-scrollbar-corner {
  background: var(--surface-bg);
}

.img-avatar.smaller {
  margin: 4px !important;
  position: relative;
  height: 35px !important;
}

.avatar-align {
  vertical-align: top;
}

.tree-icon {
  width: 10px;
  height: 10px;
  color: var(--ag-secondary-foreground-color);
  margin: auto;
  margin-right: 5px;
  cursor: pointer;
}

.hidden-tree-icon {
  display: none !important;
}

.scheduler-odd-row {
  background-color: var(--ag-odd-row-background-color) !important;
  height: 100% !important;
}

.scheduler-even-row {
  background-color: var(--ag-background-color) !important;
  height: 100% !important;
}

.scheduler-border-part {
    border-bottom: 1px solid var(--bs-border);
    border-left: 1px solid var(--bs-border);
    border-right: 1px solid var(--bs-border);
}

.scheduler-divider {
  padding-top: 4px;
  font-size: 14px;
  display: inline-block;
}

.noimg-avatar {
  display:inline-block;
  font-size:1em;
  width:30px;
  height:30px;
  line-height:2.5em;
  text-align:center !important;
  border-radius:50%;
  vertical-align:middle;
  margin-right:4px;
  color:white;
  }
  
  .scheduler-tag-link {
    color: var(--link-text);
  }
  
  .scheduler-tag-nolink {
    color: var(--link-text);
    display: inline;
  }
  
  .tree-minimizer {
    background-color: transparent;
    position: absolute;
    top: 10px;
    left: 0;
    height: 25px;
    z-index: 10;
    border: none;
  }
  
  .tree-minimizer-expanded {
    left: 270px;
  }
  
  .tree-minimizer:focus {
    outline-style: none;
  }
  
  .tree-minimizer-icon {
    color: var(--ag-header-foreground-color);
  }
  
  .pointer-events-none {
    pointer-events: none;
  }
  
  .section-expand {
    display: inline-block;
  }
    
  .no-rows-overlay {
    position: absolute;
    display: flex;
    height: 100%;
    z-index: 3;
    width: 100%;
    pointer-events: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .type-color {
    padding-left: 0;
    padding-right: 0;
    height: 16px;
    top: 1px;
    position: relative;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .warning-icon {
    margin-right: 5px;
    margin-left: 1px;
  }
  
  .dhx_cal_event_line.readonly {
    cursor: default;
  }
</style>
