var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "HeaderSidebarContainer",
    [
      _c("HeaderCompany", {
        attrs: { slot: "headerCompany" },
        slot: "headerCompany",
      }),
      _c("HeaderDropdownAccount", {
        attrs: { slot: "headerDropdownAccount" },
        slot: "headerDropdownAccount",
      }),
      _vm.languagesLoaded ? _c("router-view") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }