var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "edit-cell" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.$t("task.image_editor"),
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: { ok: _vm.changed, hidden: _vm.closePicker },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({}) {
                return [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.changed()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.closePicker()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c("b-form-group", [
            _c("label", { staticClass: "ml-1 mt-1 mr-1" }, [
              _vm._v(_vm._s(_vm.$t(`task.field.image`))),
            ]),
            _vm.avatarUrl === null
              ? _c(
                  "button",
                  {
                    staticClass: "ml-1 btn-action",
                    attrs: { id: "IMAGE_ADD" },
                    on: { click: _vm.imageSelectorToggle },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "plus"] },
                    }),
                    _c("b-popover", {
                      attrs: {
                        target: "IMAGE_ADD",
                        placement: "top",
                        triggers: "hover",
                        content: _vm.$t("task.button.image_add"),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.avatarUrl !== null
              ? _c(
                  "button",
                  {
                    staticClass: "btn-action",
                    attrs: { id: "IMAGE_DELETE" },
                    on: { click: _vm.imageRemove },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "trash-can"] },
                    }),
                    _c("b-popover", {
                      attrs: {
                        target: "IMAGE_DELETE",
                        placement: "top",
                        triggers: "hover",
                        content: _vm.$t("task.button.image_remove"),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "image-preview-container ml-2 mb-2" },
              [
                _vm.avatarUrl != null
                  ? _c("b-img-lazy", {
                      staticClass: "image-preview",
                      staticStyle: { "object-fit": "cover" },
                      attrs: { id: "image-preview", src: _vm.avatarUrl },
                      nativeOn: {
                        error: function ($event) {
                          return _vm.handleImgError.apply(null, arguments)
                        },
                      },
                    })
                  : _c("div", { staticClass: "no-image-text" }, [
                      _vm._v(_vm._s(_vm.$t("task.no_image"))),
                    ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.fileSelectorShow
        ? _c("FileSelectorModal", {
            attrs: {
              show: _vm.fileSelectorShow,
              multiple: _vm.fileMultiple,
              allowedMimeType: _vm.fileMimetypes,
            },
            on: {
              "update:show": function ($event) {
                _vm.fileSelectorShow = $event
              },
              ok: _vm.fileSelectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }