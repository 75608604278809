import { httpAjax, urlParams } from '@/helpers';
import { cloneDeep } from 'lodash';
import { getPermissionDenyProperties } from '@/helpers/permission'
import { getKeysWithoutRedactedFields } from './common'
export const userService = {
  get,
  create,
  list,
  update,
  remove,
  sendRegistrationEmail,
  getDetails,
  getByPolicy,
  addPermission,
  updatePermission,
  removePermission,
  clone,
  getNameByUuIds,
  syncLdap,
  getPermissionIds,
  listv2,
  listUniqueValuesOfProperty,
  switchUser
};


/**
 * Retrieve logged-on user detail using cookie only.
 * 
 */
function getDetails() {
  const config = {
    headers: getHeaders()
  }

  return httpAjax.get(`/api/user/details`, config);
}

/**
 * send registration email
 * by passing user uuId
 * @param {String} uuId
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function sendRegistrationEmail(uuId) {
  const url = `/api/user/registration/${uuId}`;
  const config = {
    headers: getHeaders()
  }
  return httpAjax.post(url, null, config);
}

/**
 * switch user
 * by passing user uuId
 * @param {String} uuId
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function switchUser(uuId) {
  const url = `/api/user/switch?alias=${uuId}`;
  const config = {
    headers: getHeaders()
  }
  return httpAjax.post(url, null, config);
}

/**
 * send registration email
 * by passing user uuId
 * @param {String} uuId
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function syncLdap() {
  const url = `/api/ldap/sync`;
  const config = {
    headers: getHeaders()
  }
  return httpAjax.post(url, null, config);
}


/**
 * Create a new user 
 * by passing necessary information
 * @param {Array} data 
 * e.g [{uuId: 'null', fullName: 'John' ... }]
 */
function create(data) {
  const url = '/api/user/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of user info by 
 * passing in pagination, sorting and filtering parameters
 * @param {Object} params 
 * e.g. {params: { posStart: 0, count: 25, filter: 'john', orderBy='fullName', order: 'incr' }}
 */
function list(params, { includeAccessPolicy=false, includeDataRules=false } = {}) {
  const fields = {
    uuId: 'USER.uuId', 
    firstName: 'USER.firstName',
    lastName: 'USER.lastName',
    nickName: 'USER.nickName',
    email: 'USER.email',
    superUser: 'USER.superUser',
    identifier: 'USER.identifier',
    color: 'USER.color',
    tag: 'USER.TAG.name',
    ldap: 'USER.ldapLogin',
    mobile: 'USER.mobile',
    enabled: 'USER.enabled',
    readOnly: 'USER.readOnly'
  }
  if (includeAccessPolicy == true) {
    fields.accessPolicyId = 'USER.ACCESS_POLICY.uuId';
    fields.accessPolicyName = 'USER.ACCESS_POLICY.name';
  }

  if (includeDataRules) {
    delete fields['tag'];
    fields.dataRules = "USER.USER-PERMISSION.dataRules";
    fields.permUuId = "USER.PERMISSION.uuId";
  }
  
  let data = {
    'name'  : 'User List'
    ,'type' : 'msql'
    ,'nominate': 'USER'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if (includeDataRules) {
    delete data['nominate'];
    data['group'] = 'USER';
    data['filter'] = [[
        "USER.PERMISSION.name",
        "eq",
        `${params.entity}__VIEW`
    ]];
  }
  
  if(params.filter && params.filter.length > 0) {
    if (typeof params.filter !== 'string') {
      data['filter'] = params.filter;
    }
    else {
      data['filter'] = [
        '_or_', [
          ['USER.firstName', 'has', params.filter],
          ['USER.lastName', 'has', params.filter],
          ['USER.nickName', 'has', params.filter],
          ['USER.email', 'has', params.filter],
          ['USER.identifier', 'has', params.filter],
          ['USER.TAG.name', 'has', params.filter],
          ['USER.mobile', 'has', params.filter],
          ['USER.enabled', 'has', params.filter]
        ]
      ]
    }
  }

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        if(params.ksort[i] === 'uuId') {
          data['sort'].push([fields['lastName'], params.order[i], '', 'lowerCase']);
        } else {
          const sortKey = params.ksort[i];
          data['sort'].push([fields[sortKey], params.order[i], '', 'lowerCase']);
        }
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order]);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        if (includeAccessPolicy) {
          result.accessPolicy = { uuId: null, name: '' };
          if (Array.isArray(result.accessPolicyId)
                && Array.isArray(result.accessPolicyName)
                && result.accessPolicyId.length > 0 
                && result.accessPolicyName.length > 0) {
            result.accessPolicy.uuId = result.accessPolicyId[0];
            result.accessPolicy.name = result.accessPolicyName[0];
          }
          delete result.accessPolicyId;
          delete result.accessPolicyName;
        }

        //Prepare for DetailLinkCellRenderer
        result.label = `${result.lastName}`;
        return result;
      })
    }
  });
}

/**
 * Update user details 
 * by passing  necessary information (Property uuId is mandatory for buiding request URL)
 * @param {Array} data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', fullName: 'John Doe' ... }]
 */
function update(data) {
  const url = `/api/user/update`;
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of projects
 * by passing in an array list of uuIds
 * @param {String} uuId 
 * 
 */
function remove(data) {
  const url = `/api/user/delete`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieve specific user detail using uuId.
 * 
 * @param {Array} data  array of UUID. eg.g [{ uuId: 'xxxxx..' }, { uuId: 'yyyy..'}]
 */
 function get(data, links=null) {
  const config = {
    headers: getHeaders()
  }
  const url = urlParams('/api/user/get', links);
  return httpAjax.post(url, data, config);
}

// Private Methods
function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}


/**
 * Returns a list of users that have a policy assigned to them
 */
function getByPolicy(policyId) {
  const fields = {
    userId: ['USER.uuId'],
    permissionId: ['USER.PERMISSION.uuId'],
    denyRules: ["USER.USER-PERMISSION.denyRules","[]"],
    dataRules: ["USER.USER-PERMISSION.dataRules","[]"]
  }

  let data = {
    'name'    : 'Get user permissions by access policy'
    ,'type'   : 'msql'
    ,'start'  : 0
    ,'limit'  : -1
    ,'select': Object.keys(fields).map(i => fields[i])
  }
  
  data['filter'] = [['USER.ACCESS_POLICY.uuId', 'eq', policyId]];

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const obj = {}
        for(let j = 0, len = i.length; j < len; j++) {
          obj[keys[j]] = i[j];
        }
        return obj;
      })
    }
  });
}

/**
 * Update permissions of multiple users
 * @param {Array} data 
 * e.g [
 *  {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', permissionList: [uuId: '', uuID: '']},
 *  {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', permissionList: [uuId: '', uuID: '']},
 * ]
 */
function addPermission(data) {
  const url = '/api/user/permission/add';
  const config = {
    headers: getHeaders()
  };

  return httpAjax.post(url, data, config);
}

/**
 * Update permissions of multiple users
 * @param {Array} data 
 * e.g [
 *  {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', permissionList: [uuId: '', uuID: '']},
 *  {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', permissionList: [uuId: '', uuID: '']},
 * ]
 */
function updatePermission(data) {
  const url = '/api/user/permission/update';
  const config = {
    headers: getHeaders()
  };

  return httpAjax.post(url, data, config);
}

function removePermission(data) {
  const url = '/api/user/permission/delete';
  const config = {
    headers: getHeaders()
  };

  return httpAjax.post(url, data, config);
}

/**
 * Clone a user with given new name
 * @param String refId Target user UuId
 * @param Object data Object contains name for cloned user.
 */
function clone(refId, data) {
  const url = '/api/user/clone';
  const config = {
    data: data,
    headers: getHeaders(),
    params: { reference: refId }
  };
  return httpAjax.post(url, data, config);
}

function getNameByUuIds(uuIds) {
  const fields = {
    uuId: 'USER.uuId', 
    name: 'USER.name',
    firstName: 'USER.firstName',
    lastName: 'USER.lastName',
    nickName: 'USER.nickName',
    email: 'USER.email',
    superUser: 'USER.superUser',
    identifier: 'USER.identifier'
  }

  const dataTemplate = {
    'name'  : 'User List'
    ,'type' : 'msql'
    ,'nominate': 'USER'
    ,'start' : 0
    ,'limit' : -1
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  const chunkSize = 250;
  const requests = [];
  for (let i = 0, len = uuIds.length; i < len; i += chunkSize) {
    const data = cloneDeep(dataTemplate);
    data.holder = uuIds.slice(i, i + chunkSize);
    requests.push(httpAjax.post('/api/query/match', data, {}));
  }
  
  return Promise.allSettled(requests).then(results => {
    const rData = [];
    for (const result of results) {
      if (result.status == 'fulfilled') {
        const response = result.value;
        const listName = response.data.jobCase;
        const rawData = response.data[listName] || [];
        const keys = Object.keys(fields);
        rData.push(...rawData.map(i => {
          const obj = {}
          for(let j = 0, len = i.length; j < len; j++) {
            obj[keys[j]] = i[j];
          }
          return obj;
        }));
      }
    }
    return rData;
  });
}

function getPermissionIds(userId) {
  const fields = {
    userId: ['USER.uuId'],
    permissionIds: ['USER.PERMISSION.uuId'],
  }

  let data = {
    'name'    : 'Get user permissions by userId as holder'
    ,'type'   : 'msql'
    ,'start'  : 0
    ,'limit'  : -1
    ,'nominate' : 'USER'
    ,'holder': [userId]
    ,'select': Object.keys(fields).map(i => fields[i])
  }
  const url = '/api/query/match';
  return httpAjax.post(url, data, {}).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    
    return rawData.map(i => {
      const obj = {}
      for(let j = 0, len = i.length; j < len; j++) {
        obj[keys[j]] = i[j];
      }
      return obj;
    })
  });
}

/**
 * Retrieving a concise list of user info by 
 * passing in pagination, sorting and filtering parameters
 * @param {Object} params 
 * e.g. {params: { posStart: 0, count: 25, filter: 'john', orderBy='fullName', order: 'incr' }}
 */
function listv2(params, { includeAccessPolicy=false, customFields=[] } = {}) {
  const fields = {
    uuId: 'USER.uuId', 
    firstName: 'USER.firstName',
    lastName: 'USER.lastName',
    nickName: 'USER.nickName',
    email: 'USER.email',
    superUser: 'USER.superUser',
    identifier: 'USER.identifier',
    color: 'USER.color',
    tag: 'USER.TAG.name',
    ldap: 'USER.ldapLogin',
    mobile: 'USER.mobile',
    enabled: 'USER.enabled',
    readOnly: 'USER.readOnly'
  }
  if (includeAccessPolicy == true) {
    fields.accessPolicyId = 'USER.ACCESS_POLICY.uuId';
    fields.accessPolicyName = 'USER.ACCESS_POLICY.name';
  }
  if (Array.isArray(customFields) && customFields.length > 0) {
    for(const cField of customFields) {
      fields[cField.name] = `USER.${cField.name}`;
    }
  }

  let data = {
    'name'  : 'User List'
    ,'type' : 'msql'
    ,'nominate': 'USER'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if(params.filter && params.filter.length > 0) {
    if (typeof params.filter !== 'string') {
      data['filter'] = params.filter;
    }
    else {
      const filterList = [
        ['USER.firstName', 'has', params.filter],
        ['USER.lastName', 'has', params.filter]
      ]

      const userDeniedProperties = getPermissionDenyProperties('USER','VIEW')
      if (!userDeniedProperties.includes('nickName')) {
        filterList.push(['USER.nickName', 'has', params.filter])
      }
      if (!userDeniedProperties.includes('email')) {
        filterList.push(['USER.email', 'has', params.filter])
      }
      if (!userDeniedProperties.includes('identifier')) {
        filterList.push(['USER.identifier', 'has', params.filter])
      }
      if (!userDeniedProperties.includes('mobile')) {
        filterList.push(['USER.mobile', 'has', params.filter])
      }
      if (!userDeniedProperties.includes('enabled')) {
        filterList.push(['USER.enabled', 'has', params.filter])
      }
      if (!userDeniedProperties.includes('color')) {
        filterList.push(['USER.color', 'has', params.filter])
      }
      if (Array.isArray(customFields) && customFields.length > 0) {
        for (const c of customFields) {
          if ((c.type == 'String' || c.type == 'Enum<String>') && !userDeniedProperties.includes(c.name)) {
            filterList.push([`USER.${c.name}`, 'has', params.filter])
          }
        }
      }

      const tagDeniedProperties = getPermissionDenyProperties('TAG', 'VIEW')
      if (!userDeniedProperties.includes('TAG') && 
          !userDeniedProperties.includes('TAG.name') && 
          !tagDeniedProperties.includes('name') ) {
            filterList.push(['USER.TAG.name', 'has', params.filter])
      }

      // if (redacted.length == 0 || !redacted.includes('tag')) {
      //   filterList.push(['USER.TAG.name', 'has', params.filter])
      // }
      data['filter'] = [
        '_or_', 
        filterList
      ]
    }
  }

  //BadgeFilter related
  if (Array.isArray(params.badgeFilters) && params.badgeFilters.length > 0) {
    const badgeFilterList = [];
    for (const f of params.badgeFilters) {
      if (f.field == null || !Array.isArray(f.value) || f.value.length == 0) {
        continue;
      }
      
      let field = null;
      if (f.field == 'tagName') {
        field = 'USER.TAG.name';
      } else if (f.field == 'accessPolicyName') {
        field = 'USER.ACCESS_POLICY.name';
      } else {
        field = `USER.${f.field}`;
      } 

      const isEqual = !f.operator || f.operator === 'is';
      let valueList = [];
      const value = f.value;
      for (const v of value) {
        if (v.text != null && (typeof v.text === 'number' || (typeof v.text === 'string' && v.text&& v.text.length > 0 && v.text !== '(Empty)'))) {
          //use value when exists. Otherwise use text
          //Reason: When value property exists, it means the text is localized and can't be used in filter.
          valueList.push([field, 'eq', Object.hasOwn(v, 'value')? v.value : v.text]);
        }
        //Start processing (EMPTY) field value 
        else if (isEqual) {
          valueList.push('_not_', [[field]])
        } else {
          //Special handling: when is not empty, put it into badgeFilterList directly to act as 'field must exist'. 
          badgeFilterList.push([field])
        }
      }
      if (valueList.length > 0) {
        if (valueList.length > 1) {
          valueList = ['_or_', valueList];
        }
        if (!isEqual) {
          badgeFilterList.push('_not_', valueList);
        } else {
          badgeFilterList.push(...valueList);
        }
      }
    }
    if (badgeFilterList.length > 0) {
      if (Array.isArray(data.filter) && data.filter.length > 0) {
        data.filter = [...data.filter, ...badgeFilterList]
      } else {
        data.filter = [...badgeFilterList]
      }
    }
  }

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        if(params.ksort[i] === 'uuId') {
          data['sort'].push([fields['lastName'], params.order[i], '', 'lowerCase']);
        } else {
          const sortKey = params.ksort[i];
          data['sort'].push([fields[sortKey], params.order[i], '', 'lowerCase']);
        }
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order]);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;
  delete params.badgeFilters;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        if (includeAccessPolicy) {
          result.accessPolicy = { uuId: null, name: '' };
          if (Array.isArray(result.accessPolicyId)
                && Array.isArray(result.accessPolicyName)
                && result.accessPolicyId.length > 0 
                && result.accessPolicyName.length > 0) {
            result.accessPolicy.uuId = result.accessPolicyId[0];
            result.accessPolicy.name = result.accessPolicyName[0];
          }
          delete result.accessPolicyId;
          delete result.accessPolicyName;
        }

        //Prepare for DetailLinkCellRenderer
        result.label = `${result.lastName}`;
        return result;
      })
    }
  });
}

function listUniqueValuesOfProperty(field) {
  const fieldSelector = [`USER.${field}`]
  
  const data = {
    'name'  : `Unique USER property: ${field}`
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'select': [
      fieldSelector
    ]
    , 'dedup': true
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    let rawData = response.data[listName] || [];
    rawData = rawData.map(i => i[0]);
    rawData.sort((a, b) => {
      if (typeof a === 'string') {
        return a.localeCompare(b, undefined, { sensitivity: 'base'})
      } else {
        if (a < b) {
          return -1
        } else if (a > b) {
          return 1
        }
        return 0
      }
    });
    return rawData;
  });
}