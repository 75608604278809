
import { httpAjax } from '@/helpers';

export const permissionService = {
  list
};

/**
 * Retrieving a concise list of permission info by 
 * passing in pagination, sorting and filtering parameters
 * @param {Object} params 
 * e.g. {params: { start: 0, limit: 25, filter: 'CONTACT_ALL', orderBy='permissionName', order: 'incr' }}
 */
function list(params) {
  const url = '/api/permission/list';
  const config = {
    params: params,
    headers: getHeaders()
  }
  return httpAjax.get(url, config);
}


// Private Methods
function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}