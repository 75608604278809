var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "edit-cell",
      on: { focusout: _vm.onFocusOut, keydown: _vm.onKeyDown },
    },
    [
      _c("b-form-select", {
        staticClass: "form-control",
        class: { show: _vm.isOpen },
        attrs: { autofocus: "", size: "sm", options: _vm.optionStages },
        on: { change: _vm.onChange },
        model: {
          value: _vm.value.uuId,
          callback: function ($$v) {
            _vm.$set(_vm.value, "uuId", $$v)
          },
          expression: "value.uuId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }