
import { httpAjax } from '@/helpers';

export const taskLinkFileService = {
  create,
  remove,
};

/**
 * Create a new Link from task to file 
 * by passing necessary information
 * @param String taskId, Task's UUID
 * @param Array files, file details. Data sample: [{ uuId }]
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', storageFileList: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx' }] }
 */
function create(taskId, files) {
  const url = '/api/task/link/file/add';
  const config = {
    headers: getHeaders()
  };

  const storageFileList = []
  files.forEach(i => {
    storageFileList.push({ uuId: i.uuId });
  });

  const data = {
    uuId: taskId,
    storageFileList: storageFileList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from task to file.
 * by passing in an array list of links uuIds
 * @param String taskId, Task's UUID
 * @param Array fileIds, list of file's UUID 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', storageFileList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }
 */
function remove(taskId, files) {
  const url = '/api/task/link/file/delete';
  const config = {
    headers: getHeaders()
  };

  const storageFileList = []
  files.forEach(i => {
    storageFileList.push({ uuId: i.uuId });
  });

  const data = {
    uuId: taskId,
    storageFileList: storageFileList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}