<template>
  <BlankCenterContainer>
    <b-col md="10">
      <div class="d-flex flex-column justify-content-center flex-md-row align-items-center">
        <h1 class="float-left display-1 mr-md-4">404</h1>
        <div class="text-center text-md-left">
          <h4 class="pt-3">Oops! You're lost.</h4>
          <p class="text-muted mb-0">The page you are looking for was not found.</p>
          <p class="text-muted">Back to <router-link to="/">Dashboard</router-link></p>
        </div>
      </div>
    </b-col>
  </BlankCenterContainer>
</template>

<script>
import BlankCenterContainer from '@/views/layout/BlankCenterContainer'

export default {
  name: 'PageNotFound',
  components: {
    BlankCenterContainer,
  }
}
</script>

