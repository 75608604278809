import { managementService } from '@/services';
import { processRegExp } from '@/helpers';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

export function compareKeys(a, b) {
  for (const key of Object.keys(a)) {
    if (a[key] !== b[key]) {
      return true;
    }
  }
  return false;
}

export async function getCustomFieldInfo(self, object, entity = null, { customFieldsPropName='customFields' }={}) {
  const propName = customFieldsPropName != null? customFieldsPropName : 'customFields';
  
  // set self.customFields to an empty array for when custom fields are deleted
  // and the entity no longer has custom fields
  self[propName] = [];
  const inUse = await managementService.info({type: 'field', opts: 'use'})
  .then(response => {
    return Object.keys(response.data.propertyList).map((key) => response.data.propertyList[key]);
  })
  .catch(e => {
    return null
  });
  if (inUse !== null && inUse.find(u => u.model === object)) {
    const customFields = await managementService.info({type: 'field', object: object, entity: entity, opts: 'all'})
    .then(response => {
      return Object.keys(response.data.propertyList).map((key) => response.data.propertyList[key]);
    })
    .catch(e => {
      if (e != null && e.response != null && e.response.status == 403) {
        return null
      }
      if (e.response.status !== 404) {
        // this.httpAjaxError(e)
        console.error(e) //eslint-disable-line no-console
      }
      
      return null
    });
    
    if (customFields) {
      // get the data from the profile
      for (const field of customFields) {
        if (field.profile) {
          field.displayName = field.profile.displayName;
          field.description = field.profile.description;
        }
        field.showError = false;
      }
      self[propName] = customFields;
    } 
  }
}

function enforceMatch(reg) {
  if (!reg.startsWith('^') && !reg.endsWith('$')) {
    reg = `^(${reg})$`;
  }
  return reg;
}

function matchList(list, value) {
  const values = list.split('|');
  for (const v of values) {
    if (v === value) {
      return true;
    }
  }
  return false;
}
// checks to see if a value passes the custom fields rules and the type matches
export function customFieldValidateAndType(value, colId, customFields) {
  const field = customFields.find(c => c.name === colId);
  if (!field) {
    return false; // not a custom field, no error
  }
  
  if ((field.type === 'Long' ||
      field.type === 'Integer' ||
      field.type === 'Byte') &&
      !/^\d+$/.test(value)) {
    return true; // error
  }
  else if ((field.type === 'Double' ||
      field.type === 'Float') &&
      !/^[+-]?\d+(\.\d+)?$/.test(value)) {
    return true; // error
  }
  else if (field.type === 'Boolean' &&
           !/^true|false$/.test(value)) {
    return true; // error
  }
  else if (field.type.startsWith("Enum") &&
      field.regex && !matchList(field.regex, value)) {
    return true;
  }
  else if (field.type === 'Date' &&
           !/^\d\d\d\d-\d\d-\d\d$/.test(value)) {
    return true;
  }
  return !customFieldValidate(field, value);
}

// checks to see if a value passes the custom fields rules
export function customFieldValidate(field, data) {
  if ((field.notNull &&
      data === null) ||
      (!field.type.startsWith('Enum') && 
      field.regex && !RegExp(processRegExp(field.regex)).test(data)) ||
      ((field.type === 'Integer' || field.type === 'Long' || field.type === 'Byte') && parseInt(data) < field.min) ||
      ((field.type === 'Integer' || field.type === 'Long' || field.type === 'Byte') && parseInt(data) > field.max) ||
      ((field.type === 'Double' || field.type === 'Float') && parseFloat(data) < field.min) ||
      ((field.type === 'Double' || field.type === 'Float') && parseFloat(data) > field.max) ||
      (field.type === 'String' && typeof data !== 'undefined' && data && data.length < field.min) ||
      (field.type === 'String' && typeof data !== 'undefined' && data && data.length > field.max) ||
      (field.type === 'Date' && typeof data !== 'undefined' && data && moment(data).unix() * 1000 > field.max) ||
      (field.type === 'Date' && typeof data !== 'undefined' && data && moment(data).unix() * 1000 < field.min)) {
    return false;
  }
  return true;
}

export function filterCustomFields(data, toRemove) {
  const fields = {};
  for (const key of Object.keys(data)) {
    if (!toRemove.includes(key)) {
      fields[key] = data[key];
    }
  }
  return fields;
}

export function prepareCustomFieldColumnDef(columnDefs, customFields, { self=null }={}) {
  if (!Array.isArray(columnDefs) || !Array.isArray(customFields)) {
   return; 
  }
  for (const field of customFields) {
    if (field.type == 'String') {
      const cellEditorParams = {}
      if (Object.hasOwn(field, 'max')) {
        cellEditorParams.max = field.max;
      }
      columnDefs.push({
        headerName: field.displayName
        , field: field.name
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , cellEditorParams
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      });
    } else if (field.type == 'Integer' || field.type == 'Long' || field.type === 'Byte') {
      const cellEditorParams = {}
      if (Object.hasOwn(field, 'max')) {
        cellEditorParams.maxValue = field.max;
      }
      if (Object.hasOwn(field, 'min')) {
        cellEditorParams.minValue = field.min;
      }
      columnDefs.push({
        headerName: field.displayName
        , field: field.name
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'integerNumericEditor'
        , cellEditorParams
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      });
    } else if (field.type == 'Float' || field.type == 'Double') {
      const cellEditorParams = {}
      if (Object.hasOwn(field, 'max')) {
        cellEditorParams.maxValue = field.max;
      }
      if (Object.hasOwn(field, 'min')) {
        cellEditorParams.minValue = field.min;
      }
      columnDefs.push({
        headerName: field.displayName
        , field: field.name
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'floatNumericEditor'
        , cellEditorParams
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      });
    } else if (field.type == 'Enum<String>' 
        || field.type == 'Enum<Integer>' || field.type == 'Enum<Long>' || field.type == 'Enum<Byte>'
        || field.type == 'Enum<Float>' || field.type == 'Enum<Double>') {
      const cellEditorParams = {}
      if (Object.hasOwn(field, 'regex')) {
        cellEditorParams.options = Array.from(field.regex.split('|')).map(i => ({ value: i, text: i }));
      } else {
        cellEditorParams.options = []; 
      }
      columnDefs.push({
        headerName: field.displayName
        , field: field.name
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'listEditor'
        , cellEditorParams
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      });
    } else if (field.type == 'Boolean') {
      const cellEditorParams = {
        options: [
          { value: true, text: self != null? self.$t('boolean_true') : 'TRUE' },
          { value: false, text: self != null? self.$t('boolean_false') : 'FALSE' }
        ]
      }
      columnDefs.push({
        headerName: field.displayName
        , field: field.name
        , cellRenderer: 'booleanCellRenderer'
        , cellEditor: 'listEditor'
        , cellEditorParams
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
      });
    } else if (field.type == 'Date') {
      const cellEditorParams = {
        editorMode: 1,
        displayMode: 'date',
        treatAsNull: [0, null],
        optional: false //always false
      }
      if (Object.hasOwn(field, 'max')) {
        cellEditorParams.max = field.max;
      }
      if (Object.hasOwn(field, 'min')) {
        cellEditorParams.min = field.min;
      }
      if (Object.hasOwn(field, 'displayName') && field.displayName != null && field.displayName.trim().length > 0) {
        cellEditorParams.labelDate =  field.displayName;
      }
      columnDefs.push({
        headerName: field.displayName
        , field: field.name
        , cellRenderer: 'dateOnlyCellRenderer'
        , cellRendererParams: {
          enableReadonlyStyle: true
        }
        , cellEditor: 'dateTimeEditor'
        , cellEditorParams
        , minWidth: 100
        , hide: true
        , editable: params => params.data.readOnly != true
        , getQuickFilterText: function(params) {
          return toDateTime(params.value);
        }
      });
    }
  } 
}

export function handleCustomFieldError(feedbackList, columnDefs, self) {
  if (!Array.isArray(feedbackList) || feedbackList.length == 0) {
    return null;
  }
  
  const clues = [
    'Validation_rule'
    , 'Number_limit_under', 'Number_limit_exceeded'
    , 'String_limit_under', 'String_limit_exceeded'
    , 'Date_limit_under', 'Date_limit_exceeded'
  ];
  const found = feedbackList.find(i => i && clues.includes(i.clue));
  if (found == null) {
    return null;
  }
  if (found.clue == 'Validation_rule') {
    if (Array.isArray(found.args) && found.args.length > 0) {
      const colId = found.args[found.args.length - 1];
      const colDef = columnDefs.find(i => i.field == colId);
      if (colDef) {
        return self.$t('error.invalid_value_with_arg', [colDef.headerName]);
      }
    }
    return null;
  } else if (found.clue == 'Number_limit_under') {
    if (Array.isArray(found.args) && found.args.length > 1) {
      const colId = found.args[0];
      const colDef = columnDefs.find(i => i.field == colId);
      if (colDef) {
        return self.$t('error.validation_number_limit_under', [colDef.headerName, found.args[1]]);
      }
    }
    return null;
  } else if (found.clue == 'Number_limit_exceeded') {
    if (Array.isArray(found.args) && found.args.length > 1) {
      const colId = found.args[0];
      const colDef = columnDefs.find(i => i.field == colId);
      if (colDef) {
        return self.$t('error.validation_number_limit_exceeded', [colDef.headerName, found.args[1]]);
      }
    }
    return null;
  } else if (found.clue == 'String_limit_under') {
    if (Array.isArray(found.args) && found.args.length > 1) {
      const colId = found.args[0];
      const colDef = columnDefs.find(i => i.field == colId);
      if (colDef) {
        return self.$t('error.validation_string_limit_under', [colDef.headerName, found.args[1]]);
      }
    }
    return null;
  } else if (found.clue == 'String_limit_exceeded') {
    if (Array.isArray(found.args) && found.args.length > 1) {
      const colId = found.args[0];
      const colDef = columnDefs.find(i => i.field == colId);
      if (colDef) {
        return self.$t('error.validation_string_limit_exceeded', [colDef.headerName, found.args[1]]);
      }
    }
    return null;
  } else if (found.clue == 'Date_limit_under') {
    if (Array.isArray(found.args) && found.args.length > 1) {
      const colId = found.args[0];
      const colDef = columnDefs.find(i => i.field == colId);
      if (colDef) {
        return self.$t('error.validation_date_limit_under', [colDef.headerName, moment.utc(found.args[1]).format('MM/DD/YYYY')]);
      }
    }
    return null;
  } else if (found.clue == 'Date_limit_exceeded') {
    if (Array.isArray(found.args) && found.args.length > 1) {
      const colId = found.args[0];
      const colDef = columnDefs.find(i => i.field == colId);
      if (colDef) {
        return self.$t('error.validation_date_limit_exceeded', [colDef.headerName, moment.utc(found.args[1]).format('MM/DD/YYYY')]);
      }
    }
    return null;
  }
  return null;
}

export function getCustomFieldExportDataPropertyHandler(customFields) {
  if (!Array.isArray(customFields) || customFields.length == 0) {
    return {}; 
   }
 
  const returnObj = {};
  for (const f of customFields) {
    if (f.type == 'Date') {
      returnObj[f.name] = (params) => {
        const d = params.node.data[f.name];
        if (d === 253402214400000 ||
            d === 32503680000000 ||
            d === 0) {
          return ''
        }
        return moment(d).format('YYYY-MM-DD')
      }
    }
  }
  return returnObj;
}

function toDateTime(value) {
  let rawValue = value ? value : null;
  if (rawValue == 0 || rawValue == '' || rawValue == 9223372036854776000) {
    rawValue = null;
  }
  return rawValue != null? moment.utc(rawValue).format('YYYY-MM-DD hh:mm A') : null;
}