var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-cell" },
    [
      _vm.staffLength > 0
        ? _c(
            "div",
            [
              _c("StaffWorkEffortModal", {
                attrs: {
                  childTaskEfforts: _vm.childTasksActualDuration,
                  workEfforts: _vm.staff,
                  show: _vm.staffWorkEffortShow,
                  startValue: _vm.startValue,
                  highlightAllOnFocus: _vm.highlightAllOnFocus,
                },
                on: {
                  "update:show": function ($event) {
                    _vm.staffWorkEffortShow = $event
                  },
                  cancel: _vm.staffWorkEffortCancel,
                  ok: _vm.staffWorkEffortOk,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task_alert.status.no_staff"),
            "ok-only": "",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.onAlertOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showAlert,
            callback: function ($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert",
          },
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("task.alert.cannot_edit_work_effort"))),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }