import { profileService } from '@/services';

const categoryKey = 'dataview';
const folderKey = 'user';
const publicFolderKey = 'company';

export const dataviewProfileService = {
  create,
  list,
  listPublic,
  update,
  remove,
  share,
  unshare
};

/**
 * Create a new dataviewProfile 
 * by passing necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function create(data, userId) {
  return profileService.create(data, categoryKey, folderKey, userId);
}

/**
 * Retrieving a concise list of dataviewProfile info
 */
function list(userId) {
  return profileService.list(categoryKey, folderKey, userId);
}

/**
 * Retrieving a concise list of dataviewProfile info
 */
function listPublic(companyId) {
  return profileService.list(categoryKey, publicFolderKey, companyId);
}

/**
 * Update dataviewProfile details 
 * by passing  necessary information
 * @param {Array} data 
 * e.g [{ enable: true, x:0, y:0, w:2, h:5, i:"0", type:"gauge", ..}]
 */
function update(data, userId) {
  return profileService.update(data, categoryKey, folderKey, userId);
}

/**
 * Delete a list of data view Profiles
 * by passing in an array list of uuIds
 * @param {Array} data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data, userId) {
  return profileService.remove(data, categoryKey, folderKey, userId);
}

/**
 * Share a of profile
 * by passing in a uuId
 * @param {String} profileId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 * @param {String} shareToId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function share(profileId, shareToId, isPublic) {
  return profileService.share(categoryKey, isPublic ? publicFolderKey : folderKey, profileId, shareToId);
}

/**
 * Unshare a of profile
 * by passing in a uuId
 * @param {String} profileId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 * @param {String} shareToId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function unshare(profileId, shareToId, isPublic) {
  return profileService.unshare(categoryKey, isPublic ? publicFolderKey : folderKey, profileId, shareToId);
}