import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import { userService } from '@/services';


//Authentication
import Login from '@/views/auths/Login';
import ForgotPassword from '@/views/auths/ForgotPassword';
import ResetPassword from '@/views/auths/ResetPassword';
import CompleteRegistration from '@/views/auths/CompleteRegistration';

//Error Pages
import PageNotFound from '@/views/errors/404';

import DashView from '@/views/DashView';
import Dashboard from '@/views/Dashboard';

//Project Pages
import ProjectGantt from '@/views/projects/ProjectGantt';
import ProjectTasks from '@/views/projects/ProjectTasks';
import ProjectTiles from '@/views/projects/ProjectTiles';
import ProjectKanban from '@/views/projects/ProjectKanban';
import ProjectSummary from '@/views/projects/ProjectSummary';

//Planner Pages
import PlannerStaff from '@/views/planner/PlannerStaff';
import PlannerResource from '@/views/planner/PlannerResource';

//Template Pages
import TemplateGantt from '@/views/templates/TemplateGantt';
import TemplateTasks from '@/views/templates/TemplateTasks';

//Management Page
import Management from '@/views/management/Management';

// Dataview
import Dataview from '@/views/dataview/Dataview';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'open active',
  scrollBehavior: (to, from) => { 
    // By changing queries we are still in the same component, 
    // so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
    if (from.path === to.path) {
      return {}
    }
    return { y: 0 }
    
  },
  routes: [
    {
      path: '/',
      component: DashView,
      name: 'sidebar.home',
      children: [
        {
          path: 'dashboard',
          alias: '',
          component: Dashboard,
          name: '',
          meta: {
            description: 'Overview of system',
            requiresAuth: true,
          }
        },
        {
          path: 'projects',
          name: 'sidebar.projects',
          component: {
            render(c) { return c('router-view') }
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: 'tiles',
              alias: '',
              component: ProjectTiles,
              name: 'Tiles',
              meta: {
                description: 'Project Tiles',
                permission: 'PROJECT__VIEW',
                label: ''
              }
            },
            {
              path: 'summary/:id',
              component: ProjectSummary,
              name: 'sidebar.dashboard',
              meta: { 
                description: 'Board',
                permission: 'TASK__VIEW'
              }
            },
            {
              path: 'tasks/:id',
              alias: ':id',
              component: ProjectTasks,
              props: true,
              name: 'sidebar.tasks',
              meta: { 
                description: 'Project Tasks',
                permission: 'TASK__VIEW'
              }
            },
            {
              path: 'staff/:id',
              component: PlannerStaff,
              name: 'sidebar.staff_usage',
              meta: {
                description: 'Project Staff',
                permission: 'STAFF__VIEW'
              }
            },
            {
              path: 'resource/:id',
              component: PlannerResource,
              name: 'sidebar.resource',
              meta: {
                description: 'Project Resource',
                permission: 'TASK__VIEW'
              }
            },
            {
              path: 'gantt/:id',
              component: ProjectGantt,
              name: 'sidebar.gantt',
              meta: {
                description: 'Schedule',
                permission: 'TASK__VIEW'
              }
            },
            {
              path: 'kanban/:id',
              component: ProjectKanban,
              name: 'sidebar.board',
              meta: { 
                description: 'Kanban Board',
                permission: 'TASK__VIEW'
              }
            },
          ]
        },
        {
          path: 'planner',
          component: {
            render(c) { return c('router-view') }
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: '',
              alias: '/',
              component: PlannerStaff,
              name: 'sidebar.planner',
              meta: { 
                description: 'Staff',
                permission: 'STAFF__VIEW'
              }
            },
            {
              path: 'resources',
              component: PlannerResource,
              name: 'sidebar.resource_planner',
              meta: { 
                description: 'Resources',
                permission: 'RESOURCE__VIEW'
              }
            }
          ]
        },
        {
          path: '/manage',
          component: {
            render(c) { return c('router-view') }
          },
          meta: { 
            requiresAuth: true,
            label: 'sidebar.management'
          },
          children: [
            {
              path: '',
              name: 'sidebar.management',
              component: Management,
              meta: { 
                label: '',
                permission: 'ADMIN__APPLICATION_MANAGEMENT'
              },
            },
            {
              path: 'template',
              component: {
                render(c) { return c('router-view') }
              },
              meta: { 
                requiresAuth: true, 
                label: 'Task Templates',
                // enableParamsAsQueries: true
              },
              children: [
                {
                  path: '', redirect: (/*to*/) => ({
                    path: '/manage',
                    query: { modal: 'templateTask' },
                    // query: { modal: 'templateTask', ...to.query },
                  })
                },
                {
                  path: 'tasks/:id',
                  component: TemplateTasks,
                  props: true,
                  name: 'Template Tasks',
                  meta: { 
                    description: 'Template Tasks',
                    permission: 'TEMPLATE__TASK__VIEW'
                  }
                },
                {
                  path: 'gantt/:id',
                  component: TemplateGantt,
                  name: 'Template Schedule',
                  meta: {
                    description: 'Schedule',
                    permission: 'TEMPLATE__TASK__VIEW'
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'dataview',
          component: {
            render(c) { return c('router-view') }
          },
          meta: {
            requiresAuth: true,
            permission: 'DATAVIEW__VIEW'
          },
          children: [
            {
              path: 'dataviews',
              name: 'sidebar.dataviews',
              component: Dataview
            },
            {
              path: 'add',
              name: 'Add',
              alias: '',
              component: Dataview,
              meta: { 
                label: '' 
              },
            },
            {
              path: ':id',
              name: 'DataviewItem',
              component: Dataview
            },
            {
              path: 'public/add',
              name: 'Add Public',
              alias: '',
              component: Dataview,
              meta: { 
                label: '' 
              },
            },
            {
              path: 'public/:id',
              name: 'DataviewPublicItem',
              component: Dataview
            }
          ]
        }
      ],
    },
    {
      path: '/register/:registrationToken',
      name: 'CompleteRegistration',
      component: CompleteRegistration
    },
    {
      path: '/forgot/password',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/reset/password/:resetToken',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '*',
      name: '404',
      component: PageNotFound
    },
  ]
});

router.beforeEach((to, from, next) => {
  let userPermissions = getUserPermissionsFromStore();
  // Let request pass thru if it matches login, register, forgot-password and reset-password page
  if(to.fullPath.match(/^\/login/g) || to.fullPath.match(/^\/register/g)
      || to.fullPath.match(/^\/forgot\/password/g) || to.fullPath.match(/^\/reset\/password/g)) { 
    next();
  } else if (userPermissions) {           // All good, continue to navigate()
    navigate(to, from, next);
  } else if (!userPermissions) {          // Fetch user permission when it is empty, and continue to navigate()
    userService.getDetails()
    .then(response => response.data)
    .then(user => {
        store.commit("authentication/updateUser", user);
        navigate(to, from, next);
    })
    .catch(e => {
      handleHttpAjaxError(e);
    });
  } else {
    next({ path: '/login', query: { RelayState: to.fullPath } }); // Default action: redirect to login page
  }
  
  function navigate(to, from, next) {
    const matchedList = to.matched;
    const userPermissionsFromStore = getUserPermissionsFromStore([]);
    const givenPermissions = userPermissionsFromStore? userPermissionsFromStore: [];
    const givenPermissionNames = givenPermissions.map(p => p.name);
    const isRestrictedPage = matchedList.some(record => record.meta.requiresAuth);
    if (isRestrictedPage) {
      const hasRequiredPermissionMet = checkPermission(matchedList[matchedList.length-1].meta.permission, givenPermissionNames);
      if(hasRequiredPermissionMet) {
        next();
      } else {
        // Redirect to home page if insufficient permission for this page
        next({ path: '/' });
      }
    } else {
      next();
    }
  }
});


function getUserPermissionsFromStore(defaultValue = null) {
  return store.state.authentication.user? store.state.authentication.user.permissionList : defaultValue;
}

function handleHttpAjaxError(e) {
  if (e.response && 403 === e.response.status) {
    router.push({ path: '/login' });
  } else {
    // eslint-disable-next-line
    console.log(e);
  }
}

function checkPermission(requiredPermission, givenPermissions) {
  if(requiredPermission && requiredPermission.length > 0) {
    return givenPermissions.includes(requiredPermission);
  }
  return true;
}

export default router;
