<template>
  <div class="d-flex">
    <div v-for="(staff, index) in value" v-bind:key="staff.uuId">
      <span v-if="index !== 0">, </span>
      <template v-if="hasError(staff)">
        <font-awesome-icon :id="`CELL_ERROR_${index}_${params.eGridCell.getAttribute('comp-id')}`" class="mr-1" style="color: red;font-size: 1.125em;" :icon="['fas', 'triangle-exclamation']"/>
        <b-popover
          :target="`CELL_ERROR_${index}_${params.eGridCell.getAttribute('comp-id')}`"
          placement="top"
          triggers="hover"
          :content="errorMessage[staff.name]">
        </b-popover>
      </template>
      <span>{{ staff.label }}</span>
    </div>
    <template v-if="hasEmptyError()">
      <font-awesome-icon :id="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`" class="ml-1"  style="margin-top: 5px; color: red;font-size: 1.125em;" :icon="['fas', 'triangle-exclamation']"/>
      <b-popover
        :target="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`"
        placement="top"
        triggers="hover"
        :content="emptyErrorString">
      </b-popover>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'TaskStaffCellRenderer',
  data() {
    return {
      value: null,
      errorMessage: {}
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    const list = Array.isArray(this.params?.value)? this.params.value: [];
    const staffs = [];
    for(const i of list) {
      let staffEdgeProperties = '';
      if (i.unit != null && i.unit > 1) {
        staffEdgeProperties += `${i.unit > 1? i.unit + ' x ': ''}`;
      }
      if (staffEdgeProperties.length > 0 || (i.utilization != null && i.utilization < 1)) {
        staffEdgeProperties += `${Math.round(i.utilization * 100)}%`;
      }
      staffs.push({ label: staffEdgeProperties.length > 0? `${i.name} (${staffEdgeProperties})`: i.name, name: i.name });
      const staffUsage = this.params.context.componentParent.staffUsage;
      if (staffUsage) {
        // resourceAllocationList[0].c - overallocated
        // resourceAllocationList[0].a == -1 not working
        if (i.name in staffUsage &&
            typeof staffUsage[i.name] === 'object') {
          if (this.params.data.uuId in staffUsage[i.name]) {
            if (i.utilization === 1) {
              this.errorMessage[i.name] = staffUsage[i.name][this.params.data.uuId].message || null;
            }
            else {
              const obj = staffUsage[i.name][this.params.data.uuId];
              if (typeof obj === 'string') {
                this.errorMessage[i.name] = obj;
              }
              else {
                if (((obj.w / ((i.utilization * obj.te) / 60)) * 100) <= 90) {
                  this.errorMessage[i.name] = staffUsage[i.name][this.params.data.uuId].message
                }
              }
            }
          }
        }
        else {
          this.errorMessage[i.name] = staffUsage[i.name] || null;
        }
      }
      
    }
    
    this.value = staffs;
  },
  computed: {
    label() {
      return this.value && this.value.length > 0? this.value : '';
    },
    emptyErrorString() {
      return this.params?.value != null && this.params.value.length > 0 && this.params.value[0].uuId == null ?
          this.$t('staff.not_exist') :
          this.$t(this.params.errorMessage);
    }
  },
  methods: {
    hasError(staff) {
      return this.errorMessage[staff.name];
    },
    hasEmptyError() {
      return (!this.value || this.value.length === 0 || this.params?.value == null || !Array.isArray(this.params.value) || this.params.value.length == 0 || this.params.value[0].uuId === null) && this.params.required;
    }
  }
})
</script>
