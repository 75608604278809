import { cloneDeep } from 'lodash';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

export async function updateDepartments(holderEntityId, holderEntityService, oldDepartments, newDepartments) {
  const result = {
    hasError: false,
    errorCodes: []
  }
  
  const deps = cloneDeep(newDepartments);
  const originDeps = oldDepartments;
  const depToAdd = [];
  const depToRemove = [];

  deps.forEach(i => {
    if (originDeps.findIndex(j => j.uuId === i.uuId) == -1) {
      depToAdd.push(i);
    }
  });

  originDeps.forEach(i => {
    if (deps.findIndex(j => j.uuId === i.uuId) == -1) {
      depToRemove.push(i);
    }
  });

  //Try to add link of department to staff
  for (let i = 0, len = depToAdd.length; i < len; i++) {
    const depId = depToAdd[i].uuId;
    let { hasError=false, errorCode=null } = await holderEntityService.create(depId, [holderEntityId])
      .then(response => {
        if (207 == response.status) {
          const list = response.data[response.data.jobCase];
          const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
          return { hasError: failIds.length > 0, errorCode: 207 };
        }
        return {}
      })
      .catch(e => {
        if (e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          const failIds = list.filter(i => i.clue !== 'Already_have_edge').map(i => i.args[0]);
          return { hasError: failIds.length > 0, errorCode: 422 };
        } else {
          return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
        }
      });
    if (hasError) {
      result.hasError = true;
      result.errorCodes.push(errorCode);
    }
  }

  //Remove link of department to staff
  for (let i = 0, len = depToRemove.length; i < len; i++) {
    const depId = depToRemove[i].uuId;
    const clues = ['OK', 'Unknown_relation'];
    let { hasError=false, errorCode=null } = await holderEntityService.remove(depId, [holderEntityId])
      .then(response => {
        if (207 == response.status) {
          const list = response.data[response.data.jobCase];
          const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
          return { hasError: failIds.length > 0, errorCode: 207 };
        }
        return {};
      })
      .catch(e => {
        if (e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
          return { hasError: failIds.length > 0, errorCode: 422 };
        } else {
          return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
        }
      });
    if (hasError) {
      result.hasError = true;
      result.errorCodes.push(errorCode);
    }
  }

  return result;
}

export function prepareDepartmentTreeRequest(self, staff, { deptCustomFields=[], staffCustomFields=[], singleContracts=false }={}) {
  const cmdList = [];

  const companyFields = {
    uuId: ["COMPANY.uuId"],
    name: ["COMPANY.name"],
    childUuId:["COMPANY.COMPANY.uuId"],
    childName: ["COMPANY.COMPANY.name"]
  };

  if (self.canView('COMPANY', ['readOnly'])) {
    companyFields['readOnly'] = ["COMPANY.readOnly"];
    companyFields['childReadOnly'] = ["COMPANY.COMPANY.readOnly"];
  }
  
  if (self.canView('COMPANY', ['type'])) {
    companyFields['type'] = ["COMPANY.type"];
    companyFields['childType'] = ["COMPANY.COMPANY.type"];
  }
  if (self.canView('COMPANY', ['color'])) {
    companyFields['color'] = ["COMPANY.color"];
    companyFields['childColor'] = ["COMPANY.COMPANY.color"];
  }
  if (self.canView('TAG', ['name']) && self.canView('COMPANY', ['TAG'])) {
    companyFields['tag'] = ["COMPANY.TAG.name"];
  }
  companyFields['linkedCompanyParent'] = ["COMPANY.COMPANY.PARENT_COMPANY.uuId"];
  companyFields['path'] = ['COMPANY.COMPANY(all,in).uuId', 'n/a','','']

  const departmentFields = {
    uuId: ["DEPARTMENT.uuId"],
    name: ["DEPARTMENT.name"],
    readOnly: ['DEPARTMENT.readOnly']
  };
  
  if (self.canView('COMPANY')) {
    departmentFields['companyUuId'] = ["DEPARTMENT.COMPANY(one).uuId"];
    departmentFields['companyName'] = ["DEPARTMENT.COMPANY(one).name"];
  }
  departmentFields['linkedDepartmentUuId'] = ["DEPARTMENT.DEPARTMENT.uuId"];
  departmentFields['linkedDepartmentName'] = ["DEPARTMENT.DEPARTMENT.name"];
  departmentFields['linkedDepartmentReadOnly'] = ["DEPARTMENT.DEPARTMENT.readOnly"];
  
  if (self.canView('STAFF')) {
    departmentFields['staffUuId'] = ["DEPARTMENT.STAFF.uuId"];
    departmentFields['staffName'] = ["DEPARTMENT.STAFF.name"];
    departmentFields['genericStaff'] = ["DEPARTMENT.STAFF.genericStaff"];
    departmentFields['resourceQuota'] = ["DEPARTMENT.STAFF.resourceQuota"];
    departmentFields['startDate'] = ["DEPARTMENT.STAFF.startDate"];
    departmentFields['endDate'] = ["DEPARTMENT.STAFF.endDate"];
    departmentFields['position'] = ["DEPARTMENT.STAFF.position"];
    departmentFields['staffIdentifier'] = ["DEPARTMENT.STAFF.identifier"];
    departmentFields['staffReadOnly'] = ["DEPARTMENT.STAFF.readOnly"];

    if (self != null && Array.isArray(staffCustomFields) && staffCustomFields.length > 0) {
      for(const cField of staffCustomFields) {
        if (self.canView('STAFF', [cField.name])) {
          departmentFields[`staff_${cField.name}`] = [`DEPARTMENT.STAFF.${cField.name}`];
        }
      }
    }
  }
  
  if (self.canView('DEPARTMENT', ['color'])) {
    departmentFields['color'] = ["DEPARTMENT.color"];
  }

  if (self.canView('TAG')) {
    departmentFields['tag'] = ["DEPARTMENT.TAG.name"];
  }
  
  if (self.canView('DEPARTMENT', ['identifier'])) {
    departmentFields['identifier'] = ["DEPARTMENT.identifier"];
  }

  if (self.canView('COMPANY', ['color'])) {
    departmentFields['companyColor'] = ["DEPARTMENT.COMPANY.color"];
  }
  
  departmentFields['linkedDepartmentParent'] = ["DEPARTMENT.DEPARTMENT.PARENT_DEPARTMENT.uuId"];
  

  if (self != null && Array.isArray(deptCustomFields) && deptCustomFields.length > 0) {
    for(const cField of deptCustomFields) {
      if (self.canView('DEPARTMENT', [cField.name])) {
        departmentFields[cField.name] = [`DEPARTMENT.${cField.name}`];
        departmentFields[`linkedDepartment_${cField.name}`] = [`DEPARTMENT.DEPARTMENT.${cField.name}`];
      }
    }
  }

  const staffFields = {
    uuId: ["STAFF.uuId"],
    name: ["STAFF.name"],
    readOnly: ['STAFF.readOnly']
  };
  
  if (self.canView('STAFF', ['genericStaff'])) {
    staffFields['genericStaff'] = ["STAFF.genericStaff"];
  }
  if (self.canView('STAFF', ['resourceQuota'])) {
    staffFields['resourceQuota'] = ["STAFF.resourceQuota"];
  }
  if (self.canView('STAFF', ['startDate'])) {
    staffFields['startDate'] = ["STAFF.startDate"];
  }
  if (self.canView('STAFF', ['endDate'])) {
    staffFields['endDate'] = ["STAFF.endDate"];
  }
  if (self.canView('STAFF', ['position'])) {
    staffFields['position'] = ["STAFF.position"];
  }
  if (self.canView('STAFF', ['identifier'])) {
    staffFields['identifier'] = ["STAFF.identifier"];
  }

  if (singleContracts) {
    staffFields['staffContractId'] = ["STAFF.CONTRACT.uuId","<RANDFLOAT>"];
  }
  
  staffFields['companyId'] = ["STAFF.COMPANY.uuId"];
  staffFields['companyName'] = ["STAFF.COMPANY.name"];

  const staffContractFields = {
    uuId: ["STAFF.uuId"],
    name: ["STAFF.CONTRACT.uuId"]
  };
  
  cmdList.push({
    "note": "List of companies to build a tree",
    "invoke" : "/api/query/match",
    "body": {
      "type": "msql",
      "start": 0,
      "limit": -1,
      "nominate": "COMPANY",
      "select": Object.keys(companyFields).map(i => companyFields[i]),
      "filter": self.companies && self.companies.length !== 0 ? [['COMPANY.uuId', 'eq', self.companies[0].uuId]] : []
    }
  });
  cmdList.push({
    "note": "List of departments to build a tree",
    "invoke" : "/api/query/match",
    "body": {
      "type": "msql",
      "start": 0,
      "limit": -1,
      "nominate": "DEPARTMENT",
      "select": Object.keys(departmentFields).map(i => departmentFields[i])
    }

  });
  
  if (staff) {
    cmdList.push({
      "note": "List of staff with no department",
      "invoke" : "/api/query/match",
      "body": {
        "type": "msql",
        "start": 0,
        "limit": -1,
        "nominate": "STAFF",
        "dedup": singleContracts ? "STAFF.CONTRACT.uuId" : false,
        "select": Object.keys(staffFields).map(i => staffFields[i]),
        "filter": [
          "_not_",
          [
            [
              "STAFF.DEPARTMENT.uuId"
            ]
          ]
        ]
      }
  
    });
    
    cmdList.push({
      "note": "List of staff contract uuIds",
      "invoke" : "/api/query/match",
      "body": {
        "type": "msql",
        "start": 0,
        "limit": -1,
        "nominate": "STAFF",
        "select": Object.keys(staffContractFields).map(i => staffContractFields[i]),
      }
  
    });
  }
  return { staffContractFields, staffFields, departmentFields, companyFields, cmdList };
}

export function getCompanyLinkedData(companyIds=[], self) {
  if (companyIds == null || companyIds.length == 0 || self == null) {
    return { fields: [], cmd: null } //return predefined (empty) value
  }
  const fields = {
    'uuId': ['COMPANY.uuId']
  }
  if (self.canView('TAG', ['name']) && self.canView('COMPANY', ['TAG'])) {
    fields['tag'] = ['COMPANY.TAG.name'];
  }
  if (Object.keys(fields).length < 2) {
    return { fields: [], cmd: null }  //return predefined (empty) value
  }
  return { 
    fields: Object.keys(fields)
    , cmd: {
      "type": "msql",
      "start": 0,
      "limit": -1,
      "nominate": "COMPANY",
      "holder": companyIds,
      "select": Object.keys(fields).map(i => fields[i])
    }
  }
}

export function collectCompanyIds(companies) {
  const ids = new Set()
  if (companies == null || companies.length == 0) {
    return ids;
  }
  for (const c of companies) {
    if (c.uuId == null) {
      continue;
    }
    if (Array.isArray(c.childUuId)) {
      for (const id of c.childUuId) {
        ids.add(id);
      }
    }
  }
  return Array.from(ids);
}

export function getDepartmentLinkedData(departmentIds=[], self) {
  if (departmentIds == null || departmentIds.length == 0 || self == null) {
    return { fields: [], cmd: null } //return predefined (empty) value
  }
  const fields = {
    'uuId': ['DEPARTMENT.uuId']
  }
  if (self.canView('TAG', ['name']) && self.canView('DEPARTMENT', ['TAG'])) {
    fields['tag'] = ['DEPARTMENT.TAG.name'];
  }
  if (Object.keys(fields).length < 2) {
    return { fields: [], cmd: null }  //return predefined (empty) value
  }
  return { 
    fields: Object.keys(fields)
    , cmd: {
      "type": "msql",
      "start": 0,
      "limit": -1,
      "nominate": "DEPARTMENT",
      "holder": departmentIds,
      "select": Object.keys(fields).map(i => fields[i])
    }
  }
}

export function collectDepartmentIds(departments) {
  const ids = new Set()
  if (departments == null || departments.length == 0) {
    return ids;
  }
  for (const c of departments) {
    if (c.uuId == null) {
      continue;
    }
    if (Array.isArray(c.linkedDepartmentUuId)) {
      for (const id of c.linkedDepartmentUuId) {
        ids.add(id);
      }
    }
  }
  return Array.from(ids);
}

/**
 * 
 * property 'noSubCompany' takes effect when companyObj is not null.
 * when 'noSubCompany' is true and companyObj is not null, Only list the tree starts from companyObj and it's departments only. No subordinate/child company.
 */
export function makeTree(companies, departments, staffNoDep, inactiveStaff, activeStaff, companyObj, { deptCustomFields=[], staffCustomFields=[], startDate=null, endDate=null, staffContracts={}, singleContracts=false, noSubCompany=false }={}, filterStaffIds=null) {
  const rowData = [];
  const companyMap = {};
  const departmentMap = {};
  const subdepartmentMap = {};
  let primary = null;
  const startDateStamp = moment(startDate).unix() * 1000;
  const endDateStamp = moment(endDate).unix() * 1000;
  const addedStaff = {};
  
  for (const company of companies) {
    let comp = { 
      uuId: company.uuId,
      name: company.name,
      companyList: [],
      departmentList: [],
      staffList: [],
      type: company.type,
      color: company.color !== "" ? company.color : null,
      tag: company.tag,
      companyPath: company.path,
      readOnly: company.readOnly == true
    };
    
    // set the tags from the main list
    if (companyMap[comp.uuId]) {
      // getting tags for sub-companies does not get them right
      companyMap[comp.uuId].tag = comp.tag;
    }
    
    for (let i = 0; i < company.childUuId.length; i++) {
      const subcomp = { 
        uuId: company.childUuId[i],
        name: company.childName[i],
        companyList: [],
        departmentList: [],
        staffList: [],
        type: company.childType[i],
        color: company.childColor[i] !== "" ? company.childColor[i] : null,
        tag: company.childTag != null? company.childTag[i] : [], //There is scenario that childTag is null.
        readOnly: company.childReadOnly[i],
      };
      
      // look for the company in the primary
      if (company.type !== 'Primary' &&
          primary) {
        const idx = primary.companyList.findIndex(c => c.uuId === subcomp.uuId);
        if (idx !== -1) {
          primary.companyList.splice(idx, 1); // remove it from the primary
        }
      }
      
      if (company.linkedCompanyParent && company.linkedCompanyParent[i] === comp.uuId && !(companyObj && noSubCompany)) {
        comp.companyList.push(subcomp);
      }
      companyMap[subcomp.uuId] = subcomp;
    }

    if ((!companyObj && company.type === 'Primary') 
        // ||
        //   (companyObj 
        //     && ((!Array.isArray(companyObj) && comp.uuId === companyObj.uuId) 
        //           || (Array.isArray(companyObj) && companyObj.find(c => c.uuId === comp.uuId)))) //) {
        || rowData.length == 0) {
      primary = comp;
      rowData.push(comp);
      companyMap[comp.uuId] = comp;
    } 
    
    if (companyMap[comp.uuId]) {
      companyMap[comp.uuId].companyList = comp.companyList;
    }
  }
  
  for (const staff of staffNoDep) {
    let stf = { 
      staff: true,
      uuId: staff.uuId,
      name: staff.name,
      genericStaff: staff.genericStaff,
      companyList: [],
      startDate: staff.startDate,
      endDate: staff.endDate,
      position: staff.position,
      identifier: staff.identifier,
      status: isActive(staff.startDate, staff.endDate) ? "Active" : "Inactive",
      readOnly: staff.readOnly == true
    };
    if (Array.isArray(staffCustomFields) && staffCustomFields.length > 0) {
      for(const cField of staffCustomFields) {
        stf[cField.name] = staff[cField.name] ?? null;
      }
    }
    
    if ((filterStaffIds === null || staff.uuId in filterStaffIds) &&
        (inactiveStaff && stf.status === "Inactive" || activeStaff && stf.status === "Active")) {
      if (staff.companies && staff.companies.length > 0 
          && companyMap[staff.companies[0].uuId] != null 
          && companyMap[staff.companies[0].uuId].staffList != null) {
        companyMap[staff.companies[0].uuId].staffList.push(stf);
      } else if (companies.length === 0 ||
                 (staff.linkedCompanyParent && staff.linkedCompanyParent.length > 0 && staff.linkedCompanyParent[0] === companies[0].uuId)){
        rowData.push(stf); 
      }
    }
  }
  
  for (const department of departments) {
    let dept = { 
      uuId: department.uuId,
      name: department.name,
      company: null,
      departmentList: [],
      staffList: [],
      color: department.color,
      tag: department.tag, //Fixed #1356, expectinf array type instead of joined string
      identifier: department.identifier,
      readOnly: department.readOnly == true
    };

    if (Array.isArray(deptCustomFields) && deptCustomFields.length > 0) {
      for(const cField of deptCustomFields) {
        dept[cField.name] = Object.hasOwn(department, cField.name) 
          ? department[cField.name] 
          : null;
      }
    }
    
    for (let i = 0; i < department.companyUuId.length; i++) {
      dept.company = 
      { 
        uuId: department.companyUuId[i],
        name: department.companyName[i],
        // tag: department.companyTag[i],
        color: department.companyColor,
      };
    }
    
    for (let i = 0; i < department.linkedDepartmentUuId.length; i++) {
      let subdept = { 
        uuId: department.linkedDepartmentUuId[i],
        name: department.linkedDepartmentName[i],
        readOnly: department.linkedDepartmentReadOnly[i],
        tag: department.linkedDepartmentTag != null? department.linkedDepartmentTag[i] : [], //There is scenario that department.linkedDepartmentTag is null.
        companyColor: department.companyColor
      };
      if (Array.isArray(deptCustomFields) && deptCustomFields.length > 0) {
        for(const cField of deptCustomFields) {
          const cFieldName = `linkedDepartment_${cField.name}`;
          subdept[cField.name] = Array.isArray(department[cFieldName]) && department[cFieldName].length > i
            ? department[cFieldName][i] 
            : null;
        }
      }
      
      if (department.linkedDepartmentParent[i] === dept.uuId) {
        dept.departmentList.push(subdept);
      }
      
      subdepartmentMap[subdept.uuId] = subdept; // save in a map so we can update the staff
      if (subdept.uuId in departmentMap) {
        subdept.staffList = departmentMap[subdept.uuId];
      }
    }
    
    if (department.staffUuId) {
      for (let i = 0; i < department.staffUuId.length; i++) {
        const staff = { 
          staff: true,
          uuId: department.staffUuId[i],
          name: department.staffName[i],
          genericStaff: department.genericStaff[i] ? department.genericStaff[i] : null,
          startDate: department.startDate[i] ? department.startDate[i] : null,
          endDate: department.endDate[i] ? department.endDate[i] : null,
          position: department.position[i] ? department.position[i] : null,
          identifier: department.staffIdentifier[i] ? department.staffIdentifier[i] : null,
          status: isActive(department.startDate[i], department.endDate[i]) ? "Active" : "Inactive",
          resourceQuota: department.resourceQuota[i],
          readOnly: department.staffReadOnly[i]
        };
        
        if (singleContracts ||
            (!singleContracts && (!staffContracts[staff.uuId] || !(staffContracts[staff.uuId] in addedStaff)))) {
          if (staffContracts[staff.uuId]) {
            // save the staff contract uuId so that we don't add it twice when singleContracts is on
            addedStaff[staffContracts[staff.uuId]] = true;
          }
          if (Array.isArray(staffCustomFields) && staffCustomFields.length > 0) {
            for(const cField of staffCustomFields) {
              const cFieldName = `staff_${cField.name}`;
              staff[cField.name] = Array.isArray(department[cFieldName]) && department[cFieldName].length > i
                ? department[cFieldName][i] 
                : null;
            }
          }
          
          if ((filterStaffIds === null || staff.uuId in filterStaffIds) &&
              (startDate !== null && endDate !== null && (
               (staff.endDate <= endDateStamp &&
               staff.endDate >= startDateStamp) ||
               (staff.startDate <= endDateStamp &&
                staff.startDate >= startDateStamp) ||
               (staff.startDate <= endDateStamp &&
                staff.endDate >= endDateStamp))) ||
              (startDate === null && endDate === null && (inactiveStaff && staff.status === "Inactive" ||
              activeStaff && staff.status === "Active"))) {
            dept.staffList.push(staff);
          }
        }
      }
    }
    
    if (dept.uuId in subdepartmentMap) {
      subdepartmentMap[dept.uuId].staffList = dept.staffList;
      subdepartmentMap[dept.uuId].departmentList = dept.departmentList;
    }
    if (dept.company !== null 
        && companyMap[dept.company.uuId] != null 
        && companyMap[dept.company.uuId].departmentList != null) {
      companyMap[dept.company.uuId].departmentList.push(dept);
    }
  }
  
  return rowData;
}

function isActive(start, end) {
  const now = new Date();
  
  return (now.getTime() > start && now.getTime() < end);
}