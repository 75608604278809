var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "task-compact ag-header-group-cell-label" }, [
    _c(
      "div",
      {
        ref: "menuButton",
        staticClass: "ag-cell-label-container ag-header-cell-sorted-none",
      },
      [
        _c("div", { staticClass: "ag-header-cell-label" }, [
          _c(
            "div",
            {
              staticClass: "g-header-cell-menu",
              on: {
                click: function ($event) {
                  return _vm.onMenuClicked($event)
                },
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "fa-regular fa-gear" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block", "margin-left": "20px" } },
            [
              _vm.totalMode
                ? _c("font-awesome-icon", {
                    staticStyle: {
                      "margin-right": "6px",
                      "vertical-align": "-3px",
                    },
                    attrs: { icon: "fa-regular fa-sigma" },
                  })
                : _vm._e(),
              _c("span", { staticClass: "ag-header-cell-text" }, [
                _c("span", { staticClass: "align-middle" }, [
                  _vm._v(" " + _vm._s(_vm.groupHeaderLabel) + " "),
                ]),
                _c("div", { staticClass: "d-inline-block" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.property,
                          expression: "property",
                        },
                      ],
                      ref: "selectElem",
                      staticClass: "task-compact-select",
                      on: _vm._d(
                        {
                          mouseenter: _vm.onMouseEnter,
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.property = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                        [_vm.taskPropertyClickEvent, _vm.onSelectClick]
                      ),
                    },
                    [
                      _c(
                        "option",
                        { attrs: { disabled: "" }, domProps: { value: null } },
                        [_vm._v(_vm._s(_vm.$t("task.group.select_property")))]
                      ),
                      _vm._l(_vm.propertyOpts, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item.value } },
                          [_vm._v(_vm._s(item.text))]
                        )
                      }),
                    ],
                    2
                  ),
                  _c("span"),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }