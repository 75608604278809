var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _vm._l(_vm.value, function (staff, index) {
        return _c(
          "div",
          { key: staff.uuId },
          [
            index !== 0 ? _c("span", [_vm._v(", ")]) : _vm._e(),
            _vm.hasError(staff)
              ? [
                  _c("font-awesome-icon", {
                    staticClass: "mr-1",
                    staticStyle: { color: "red", "font-size": "1.125em" },
                    attrs: {
                      id: `CELL_ERROR_${index}_${_vm.params.eGridCell.getAttribute(
                        "comp-id"
                      )}`,
                      icon: ["fas", "triangle-exclamation"],
                    },
                  }),
                  _c("b-popover", {
                    attrs: {
                      target: `CELL_ERROR_${index}_${_vm.params.eGridCell.getAttribute(
                        "comp-id"
                      )}`,
                      placement: "top",
                      triggers: "hover",
                      content: _vm.errorMessage[staff.name],
                    },
                  }),
                ]
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(staff.label))]),
          ],
          2
        )
      }),
      _vm.hasEmptyError()
        ? [
            _c("font-awesome-icon", {
              staticClass: "ml-1",
              staticStyle: {
                "margin-top": "5px",
                color: "red",
                "font-size": "1.125em",
              },
              attrs: {
                id: `CELL_ERROR_${_vm.params.eGridCell.getAttribute(
                  "comp-id"
                )}`,
                icon: ["fas", "triangle-exclamation"],
              },
            }),
            _c("b-popover", {
              attrs: {
                target: `CELL_ERROR_${_vm.params.eGridCell.getAttribute(
                  "comp-id"
                )}`,
                placement: "top",
                triggers: "hover",
                content: _vm.emptyErrorString,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }