import { httpAjax } from '@/helpers';
import * as moment from 'moment-timezone';
import { convertDurationToDisplay, toFixed } from '../helpers/task-duration-process';
moment.tz.setDefault('Etc/UTC');

const propertiesProcessorMap = new Map();
const connectionProcessorMap = new Map();

export const historyService = {
  history,
  fieldHistory
};

/**
 * Get history list
 * @param {*} holder entity uuId 
 * @param {*} option { start, limit }
 */
function history(entityType, parentComponent, holder, { start=0, limit=-1 } = {}, links = 'NONE') {
  const entity = entityType.toLowerCase();
  const url = `/api/${entity}/history?links=${links}&holder=${holder}&start=${start}&limit=${limit}&order=desc`;
  return httpAjax.get(url, {}).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    
    return {
      arg_total: response.data.arg_total != null? response.data.arg_total : 0,
      data: _preprocessHistoryData(entityType, parentComponent, rawData)
    }
  });
}

/**
 * Get history list
 * @param {*} holder entity uuId 
 * @param {*} option { start, limit }
 */
function fieldHistory(parentComponent, object, { start=0, limit=-1 } = {}) {
  const url = `/api/system/history?type=field&object=${object}&start=${start}&limit=${limit}&order=desc`;
  return httpAjax.get(url, {}).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    
    return {
      arg_total: response.data.arg_total != null? response.data.arg_total : 0,
      data: _preprocessFieldHistoryData(object, parentComponent, rawData)
    }
  });
}

/**
 * 
 * @param {*} entityType 
 * @param {*} parentComponent 
 * @param {*} rawData 
 * 
 * Sample result data:
 * [{
 *   id: "1610089115706"
 *   , epoch: 1610089115706
 *   , timestamp: 1610089115706
 *   , events: ["",""]
 *   , person: {
 *     uuId: 7015d3fa-cf52-4ee7-852f-ce91dc80ee25
 *     , firtName: "John"
 *     , lastName: "Kelly"
 *     , avatarRef: "d82505a6-6c11-4bd4-906b-b4810df8a76a"
 *     , name: "John Kelly"
 *   }
 * }]
 */
function _preprocessHistoryData(entityType, parentComponent, rawData) {
  if (rawData == null) {
    return [];
  }
  let rootType = entityType.replace('-', '_');
  rootType = rootType.replace('/', '_');
  if('TEMPLATE_TASK' == rootType) {
    rootType = 'TASK';
  }
  
  const keyMap = new Map();
  const data = [];
  let rawItem = null;
  for (let i = 0, len = rawData.length; i < len; i++) {
    rawItem = rawData[i];
    if(rawItem.epoch == null) {
      continue;
    }

    const person = rawItem.user || {};
    person.name = `${person.firstName? person.firstName+' ':''}${person.lastName? person.lastName:''}`;

    const historyItem = {
      epoch: rawItem.epoch
      , person
      , timestamp: moment(rawItem.epoch).valueOf()
      , events: []
    }

    try {
      //Handle connection case
      if (rawItem.connections != null) {
        _processConnectionData(rootType, rawItem.connections, historyItem, parentComponent);
      } else if (rawItem.properties != null) { //Handle properties case
        _processPropertiesData(rootType, rawItem.properties, historyItem, parentComponent);
      } else { //Handle default case
        let action = rawItem.action;
        if ('CREATE' == rawItem.action) {
          action = parentComponent.$t('history.action.created');
        } else if ('UPDATE' == rawItem.action) {
          action = parentComponent.$t('history.action.updated');
        } else if ('DELETE' == rawItem.action) {
          action = parentComponent.$t('history.action.deleted');
        }
        const type = parentComponent.$t(`entityType.${rootType}`)
        if (rawItem.name != null) {
          historyItem.events.push(parentComponent.$t('history.action_with_name', [ type, action, rawItem.name ]));
        } else {
          historyItem.events.push(parentComponent.$t('history.action_default', [ type, action ]));
        }
        
      }
    } catch(e) {
      console.error(e); //eslint-disable-line no-console
      historyItem.events.push(parentComponent.$t('history.action_error'));
    }

    //To create unique key (string) for duplicated epoch value
    const getNewKey = function(value, dMap) {
      if(dMap.has(value)) {
        let index = value.indexOf('_');
        if (index == -1) {
          return getNewKey(`${value}_1`, dMap);
        }
        const number = parseInt(value.substring(index+1));
        if(isNaN(number)) {
          return getNewKey(`${value.substring(0, index)}_1`, dMap);
        }
        return getNewKey(`${value.substring(0, index)}_${number+1}`, dMap);
      }
      return value;
    }
    const key = getNewKey('' + historyItem.epoch, keyMap);// Convert historyItem.epoch to string
    historyItem.id = key;
    keyMap.set(key, historyItem);
    data.push(historyItem);
  }
  return { core: data };
}

function _processConnectionData(rootType, connections, historyItem, parentComponent) {
  let conItem = null;
  
  for(let j = 0, jLen = connections.length; j < jLen; j++) {
    conItem = connections[j];
    
    if(conItem.type != null) {
      if(connectionProcessorMap.has(conItem.type)) {
        const specificProcessor = connectionProcessorMap.get(conItem.type);
        specificProcessor(rootType, conItem, historyItem, parentComponent);
      } else if(conItem.entity != null) {
        let propText = null;
        if(conItem.entity.fullPath && conItem.entity.fullPath.trim().length > 0) {
          propText = conItem.entity.fullPath.trim().split('/').join(' / ');
        } else if (conItem.entity.name) {
          propText = conItem.entity.name;
        } else if (conItem.entity.firstName || conItem.entity.lastName) {
          propText = `${conItem.entity.firstName? conItem.entity.firstName+' ':''}${conItem.entity.lastName? conItem.entity.lastName:''}`;
        }
        let action = '';
        if ('CREATE' == conItem.action) {
          action = parentComponent.$t('history.action.added');
        } else if ('UPDATE' == conItem.action) {
          action = parentComponent.$t('history.action.updated');
        } else if ('DELETE' == conItem.action) {
          action = parentComponent.$t('history.action.deleted');
        }
        const type = parentComponent.$t(`entityType.${conItem.type}`);
        historyItem.events.push(parentComponent.$t('history.action_with_proptext', [ type, action, propText ]));
      } else {
        const entityType = parentComponent.$t(`entityType.${conItem.type}`);
        const action = parentComponent.$t(`history.action_noun.${conItem.action.toLowerCase()}`)
        historyItem.events.push(parentComponent.$t('history.action_with_proptext_insufficient_info', [ entityType, action ]));
      }
    }
  }
}

function _processPropertiesData(rootType, properties, historyItem, parentComponent) {
  let propItem = null;
  for (let j = 0, jLen = properties.length; j < jLen; j++) {
    propItem = properties[j];
    if(propertiesProcessorMap.has(propItem.property)) {
      const specificProcessor = propertiesProcessorMap.get(propItem.property);
      specificProcessor(rootType, propItem, historyItem, parentComponent);
    } else {
      let propOld = propItem.oldValue;
      let propNew = propItem.newValue;
      // for custom fields
      let displayName = null;
      
      // custom fields handling
      const customFields = parentComponent.customFields;
      if (customFields) {
        let property = propItem.property
        if (property !== null) {
          for (const field of customFields) {
            if (field.name.toLowerCase() === property.toLowerCase()) {
              displayName = field.displayName;
              if (field.type === 'Date') {
                propOld = propOld !== "null" ? moment(propOld).format('YYYY-MM-DD') : "null";
                propNew = propNew !== "null" ? moment(propNew).format('YYYY-MM-DD') : "null";
              }
            }
          }
        }
      }
      const propName = displayName ? displayName : parentComponent.$te(`${rootType.toLowerCase()}.field.${propItem.property}`) ?
          parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`) :
            propItem.property.match(/[A-Z].+/);
          
      push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
    }
  }
}

//--- Start of connection processor declaration ---
//Connection Processor: CALENDAR 
connectionProcessorMap.set('CALENDAR', function(rootType, conItem, historyItem, parentComponent) {
  const entity = conItem.entity;
  let weekdayHour = null;
  let action = conItem.action;
  if ('CREATE' == conItem.action) {
    action = parentComponent.$t('history.action.added');
  } else if ('UPDATE' == conItem.action) {
    action = parentComponent.$t('history.action.updated');
  } else if ('DELETE' == conItem.action) {
    action = parentComponent.$t('history.action.deleted');
  }
  if(entity != null) {
    if(entity.type == 'Working') {
      let startHour = null;
      let endHour = null;
      if(entity.startHour != null) {
        startHour = moment.utc(entity.startHour).format('hh:mm A');
      }
      if(entity.endHour != null) {
        endHour = moment.utc(entity.endHour).format('hh:mm A');
      }
      const dateRange = parentComponent.$t('date.fromto', { start: `${entity.startDate} ${startHour}`, end: `${entity.endDate} ${endHour}` } )
      const workingDateRange = `${entity.name} (${dateRange})`;
      historyItem.events.push(parentComponent.$t('history.action_with_proptext_calendar_working', [ action, workingDateRange ]));
    } else if(entity.type == 'Leave') {
      const dateRange = parentComponent.$t('date.fromto', { start: `${entity.startDate}`, end: `${entity.endDate}` } )
      const leaveDateRange = `${entity.name} (${dateRange})`;
      historyItem.events.push(parentComponent.$t('history.action_with_proptext_calendar_leave', [ action, leaveDateRange ]));
    } else { //Workday
      const weekday = parentComponent.$t(`weekday.${conItem.entity.type.toLowerCase()}`);
      let startHour = null;
      let endHour = null;
      if(entity.startHour != null) {
        startHour = moment.utc(entity.startHour).format('HH:mm');
      }
      if(entity.endHour != null) {
        endHour = moment.utc(entity.endHour).format('HH:mm');
      }
      let hourRange = null;
      if(startHour != null || endHour != null) {
        hourRange = `, ${startHour != null? startHour:'N/A'} - ${endHour != null? endHour:'N/A'}`
      } else {
        hourRange = '';
      }
      weekdayHour = `${weekday}${hourRange}`;
      let action = '';
      if ('CREATE' == conItem.action) {
        action = parentComponent.$t('history.action.added');
      } else if ('UPDATE' == conItem.action) {
        action = parentComponent.$t('history.action.updated');
      } else if ('DELETE' == conItem.action) {
        action = parentComponent.$t('history.action.deleted');
      }
      historyItem.events.push(parentComponent.$t('history.action_with_proptext_calendar_weekday', [ action, weekdayHour ]));
    }
  } else {
    const action = parentComponent.$t(`history.action_noun.${conItem.action.toLowerCase()}`)
    historyItem.events.push(parentComponent.$t('history.action_with_proptext_calendar_weekday_insufficient_info', [ action ]));
  }
});
//Connection Processor: TASK
connectionProcessorMap.set('TASK', function(rootType, conItem, historyItem, parentComponent) {
  const entityType = parentComponent.$t(`entityType.${conItem.type}`);
  const entity = conItem.entity;
  if(entity != null) {
    let taskName = null;
    if(entity.fullPath != null && entity.fullPath.trim().length > 0) {
      taskName = entity.fullPath.trim().split('/').join(' / ');
    } else if(entity.name != null) {
      taskName = entity.name;
    }    
    
    if('STAFF' == rootType) {
      let staffDescription = null;
      if('CREATE' == conItem.action) {
        staffDescription = parentComponent.$t('history.action_with_proptext_staff_task_create', [ taskName ]);
      } else if('DELETE' == conItem.action) {
        staffDescription = parentComponent.$t('history.action_with_proptext_staff_task_delete', [ taskName ]);
      } else { //default: UPDATE action
        staffDescription = parentComponent.$t('history.action_with_proptext_staff_task_update', [ taskName ]);
      }
      historyItem.events.push(staffDescription);
    } else {
      let action = '';
      if ('CREATE' == conItem.action) {
        action = parentComponent.$t('history.action.added');
      } else if ('UPDATE' == conItem.action) {
        action = parentComponent.$t('history.action.updated');
      } else if ('DELETE' == conItem.action) {
        action = parentComponent.$t('history.action.deleted');
      }
      historyItem.events.push(parentComponent.$t('history.action_with_proptext', { type: entityType, action, propText: taskName }));
    }
  } else {
    const action = parentComponent.$t(`history.action_noun.${conItem.action.toLowerCase()}`)
    historyItem.events.push(parentComponent.$t('history.action_with_proptext_insufficient_info', [ entityType, action ]));
  }
});

//Connection Processor: STAFF
connectionProcessorMap.set('STAFF', function(rootType, conItem, historyItem, parentComponent) {
  const entityType = parentComponent.$t(`entityType.${conItem.type}`);
  const entity = conItem.entity;
  if(entity != null) {
    let staffName = null;
    if(entity.name != null) {
      staffName = entity.name;
    }    
    
    if('TASK' == rootType) {
      let taskDescription = null;
      if('CREATE' == conItem.action) {
        taskDescription = parentComponent.$t('history.action_with_proptext_task_staff_create', [ staffName ]);
        historyItem.events.push(taskDescription);
      } else if('DELETE' == conItem.action) {
        taskDescription = parentComponent.$t('history.action_with_proptext_task_staff_delete', [ staffName ]);
        historyItem.events.push(taskDescription);
      } else { //default: UPDATE action
        if (conItem.properties == null || conItem.properties.length < 1) {
          taskDescription = parentComponent.$t('history.action_with_proptext_task_staff_update_without_value', [ staffName ]);
        } else {
          const properties = conItem.properties;
          for (let i = 0, len = properties.length; i < len; i++) {
            const oldValue = properties[i].oldValue;
            const newValue = properties[i].newValue;
            const property = properties[i].property;
            if (property == 'utilization') {
              taskDescription = parentComponent.$t('history.action_with_proptext_task_staff_update_utilization', [ staffName, oldValue, newValue ]);  
            } else {
              taskDescription = parentComponent.$t('history.action_with_proptext_task_staff_update', [ staffName, property, oldValue, newValue ]);  
            }
            historyItem.events.push(taskDescription);
          }
        }
      }
    } else {
      let action = '';
      if ('CREATE' == conItem.action) {
        action = parentComponent.$t('history.action.added');
      } else if ('UPDATE' == conItem.action) {
        action = parentComponent.$t('history.action.updated');
      } else if ('DELETE' == conItem.action) {
        action = parentComponent.$t('history.action.deleted');
      }
      historyItem.events.push(parentComponent.$t('history.action_with_proptext', { type: entityType, action, propText: staffName }));
    }
  } else {
    const action = parentComponent.$t(`history.action_noun.${conItem.action.toLowerCase()}`)
    historyItem.events.push(parentComponent.$t('history.action_with_proptext_insufficient_info', [ entityType, action ]));
  }
});

//Connection Processor: SUB_TASK
connectionProcessorMap.set('SUB_TASK', function(rootType, conItem, historyItem, parentComponent) {
  const entity = conItem.entity;
  if(entity != null) {
    let taskName = null;
    if(entity.fullPath != null && entity.fullPath.trim().length > 0) {
      taskName = entity.fullPath.trim().split('/').join(' / ');
    } else if(entity.name != null) {
      taskName = entity.name;
    }
    historyItem.events.push(parentComponent.$t('history.action_with_proptext_sub_task', [ taskName ]));
   
  } else {
    const entityType = parentComponent.$t(`entityType.${conItem.type}`);
    const action = parentComponent.$t(`history.action_noun.${conItem.action.toLowerCase()}`)
    historyItem.events.push(parentComponent.$t('history.action_with_proptext_insufficient_info', [ entityType, action ]));
  }
});

//Connection Processor: PERMISSION
connectionProcessorMap.set('PERMISSION', function(rootType, conItem, historyItem, parentComponent) {
  const entity = conItem.entity;
  if(entity != null) {
    let action = conItem.action;
    if ('CREATE' == conItem.action) {
      action = parentComponent.$t('history.action.created');
    } else if ('UPDATE' == conItem.action) {
      action = parentComponent.$t('history.action.updated');
    } else if ('DELETE' == conItem.action) {
      action = parentComponent.$t('history.action.deleted');
    }
    const name = entity.name;
    if (name) {
      const tokens = name.split('__');
      const entityLabel = tokens.length > 1 ? parentComponent.$t(`entityType.${tokens[tokens.length-2]}`) : '';
      const permission = parentComponent.$t(`permission.${tokens[tokens.length-1].toLowerCase()}`);
      historyItem.events.push(parentComponent.$t('history.action_with_connection_permission', [ permission, entityLabel, action ]));
    }
  } else {
    const entityType = parentComponent.$t(`entityType.${conItem.type}`);
    const action = parentComponent.$t(`history.action_noun.${conItem.action.toLowerCase()}`)
    historyItem.events.push(parentComponent.$t('history.action_with_proptext_insufficient_info', [ entityType, action ]));
  }
});

//Connection Processor: NOTE
connectionProcessorMap.set('NOTE', function(rootType, conItem, historyItem, parentComponent) {
  const entity = conItem.entity;
  if(entity != null) {
    let action = conItem.action;
    if ('CREATE' == conItem.action) {
      action = parentComponent.$t('history.action.created');
      historyItem.events.push(parentComponent.$t('history.action_with_connection_note', [
        action, conItem.entity.text]));
    } else if ('DELETE' == conItem.action) {
      action = parentComponent.$t('history.action.deleted');
      historyItem.events.push(parentComponent.$t('history.action_with_connection_note', {
        action: action, message: conItem.entity.text}));
    } else if ('UPDATE' == conItem.action) {
      var propName = parentComponent.$t('entityType.NOTE');
      if (conItem.properties.length > 1) {
        var propOld = conItem.properties[1].oldValue;
        var propNew = conItem.properties[1].newValue;
        push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
      }
    }
  } else {
    const entityType = parentComponent.$t(`entityType.${conItem.type}`);
    const action = parentComponent.$t(`history.action_noun.${conItem.action.toLowerCase()}`)
    historyItem.events.push(parentComponent.$t('history.action_with_proptext_insufficient_info', [ entityType, action ]));
  }
});
//--- End of connection processor declaration ---


//--- Start of property processor declaration ---
//Property Processor: avatarRef
propertiesProcessorMap.set('avatarRef', function(rootType, propItem, historyItem, parentComponent) {
  const propName = propItem.property;
  let t =  `${parentComponent.$t(propName)}`;
  if(rootType == 'TASK') {
    t =  `${parentComponent.$t('task.field.image')}`;
  }
  if(propItem.newValue == '00000000-0000-0000-0000-000000000000') {
    historyItem.events.push(parentComponent.$t('history.action_default', [ t,  parentComponent.$t('history.action.deleted') ]));
  } else {
    historyItem.events.push(parentComponent.$t('history.action_default', [ t, parentComponent.$t('history.action.updated')]));
  }
});

//Property Processor: bannerRef
propertiesProcessorMap.set('bannerRef', function(rootType, propItem, historyItem, parentComponent) {
  const propName = propItem.property;
  let t =  `${parentComponent.$t(propName)}`;
  if(propItem.propNew == '00000000-0000-0000-0000-000000000000') {
    historyItem.events.push(parentComponent.$t('history.action_default', [ t,  parentComponent.$t('history.action.deleted') ]));  
  } else {
    historyItem.events.push(parentComponent.$t('history.action_default', [ t,  parentComponent.$t('history.action.updated') ]));
  }
});

//Property Processor: phones
propertiesProcessorMap.set('phones', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;

  const consolidatePhonePot = (str, i) => {
    const newItem = i.kind && i.data? `${i.kind}:${i.data}` : null; 
    if(newItem != null) {
      if(str != null && str.length > 0) {
        return `${str}, ${newItem}`;
      } else {
        return `${newItem}`;
      }
    }
  }
  propOld = propOld != null? propOld.reduce(consolidatePhonePot, '') : '';
  propNew = propNew != null? propNew.reduce(consolidatePhonePot, '') : '';
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: levelsPot
propertiesProcessorMap.set('skillLevels', function(rootType, propItem, historyItem, parentComponent) {
let propOld = propItem.oldValue;
let propNew = propItem.newValue;

const consolidatePhonePot = (str, i) => {
  const newItem = i.kind && i.data? `${i.kind}:${i.data}` : null; 
  if(newItem != null) {
    if(str != null && str.length > 0) {
      return `${str}, ${newItem}`;
    } else {
      return `${newItem}`;
    }
  }
}
propOld = propOld != null? propOld.reduce(consolidatePhonePot, '') : '';
propNew = propNew != null? propNew.reduce(consolidatePhonePot, '') : '';
const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: socials
propertiesProcessorMap.set('socials', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;

  const consolidatePhonePot = (str, i) => {
    const newItem = i.kind && i.data? `${i.kind}:${i.data}` : null; 
    if(newItem != null) {
      if(str != null && str.length > 0) {
        return `${str}, ${newItem}`;
      } else {
        return `${newItem}`;
      }
    }
  }
  propOld = propOld != null? propOld.reduce(consolidatePhonePot, '') : '';
  propNew = propNew != null? propNew.reduce(consolidatePhonePot, '') : '';
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: websites
propertiesProcessorMap.set('websites', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  propOld = propOld != null? propOld.join(', ') : '';
  propNew = propNew != null? propNew.join(', ') : '';
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

function dateTimeProcessor(rootType, propItem, historyItem, parentComponent, { dateOnly=false } = {}) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  const formatPattern = dateOnly? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mmA';
  propOld = propOld == 'null' || propOld == null || propOld == 0 || propOld > 253370725199000? parentComponent.$t('history.none') : moment.utc(propOld).format(formatPattern);
  propNew = propNew == 'null' || propNew == null || propNew == 0 || propNew > 253370725199000? parentComponent.$t('history.none') : moment.utc(propNew).format(formatPattern);
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
}
//Property Processor: startDate, endDate, startTime, closeTime
propertiesProcessorMap.set('scheduleStart', dateTimeProcessor);
propertiesProcessorMap.set('scheduleFinish', dateTimeProcessor);
propertiesProcessorMap.set('startDate', dateTimeProcessor);
propertiesProcessorMap.set('endDate', dateTimeProcessor);
propertiesProcessorMap.set('startTime', dateTimeProcessor);
propertiesProcessorMap.set('closeTime', dateTimeProcessor);
propertiesProcessorMap.set('untilDate', dateTimeProcessor);
propertiesProcessorMap.set('fromDate', dateTimeProcessor);
propertiesProcessorMap.set('beginDate', dateTimeProcessor);
propertiesProcessorMap.set('constraintTime', (rootType, propItem, historyItem, parentComponent) => dateTimeProcessor(rootType, propItem, historyItem, parentComponent, { dateOnly: true }));

function durationProcessor(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  propOld = _convertDuration(propOld, parentComponent).reverse().join(' ');
  propNew = _convertDuration(propNew, parentComponent).reverse().join(' ');
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
}

//Property Processor: duration, estimatedTimeToComplete, actualDuration, estimatedDuration
propertiesProcessorMap.set('duration', durationProcessor);
propertiesProcessorMap.set('estimatedTimeToComplete', durationProcessor);
propertiesProcessorMap.set('actualDuration', durationProcessor);
propertiesProcessorMap.set('estimatedDuration', durationProcessor);
propertiesProcessorMap.set('fixedDuration', durationProcessor);
propertiesProcessorMap.set('fixedDurationTotal', durationProcessor);

//Property Processor: durationAUM
propertiesProcessorMap.set('durationAUM', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  //Omit durationAUM when there is other event besides durationAUM.
  if (historyItem.events.length > 0) {
    return;
  }
  propOld = _convertDuration(propOld, parentComponent).reverse().join(' ');
  propNew = _convertDuration(propNew, parentComponent).reverse().join(' ');
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: progress
propertiesProcessorMap.set('progress', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  
  propOld = propOld * 100;
  if(isNaN(propOld)) {
    propOld = 0;
  }
  propOld = toFixed(propOld, 0)
  propNew = propNew * 100;
  if(isNaN(propNew)) {
    propNew = 0;
  }
  propNew = toFixed(propNew, 0)
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: rebate
propertiesProcessorMap.set('rebate', function(rootType, propItem, historyItem, parentComponent) {
let propOld = propItem.oldValue;
let propNew = propItem.newValue;

propOld = propOld * 100;
if(isNaN(propOld)) {
  propOld = 0;
}
propOld = toFixed(propOld, 2)
propNew = propNew * 100;
if(isNaN(propNew)) {
  propNew = 0;
}
propNew = toFixed(propNew, 2)
const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: companyType
propertiesProcessorMap.set('companyType', function(rootType, propItem, historyItem, parentComponent) {
let propOld = propItem.oldValue;
let propNew = propItem.newValue;

propOld = propOld? parentComponent.$t(`companyType.${propOld}`) : '';
propNew = propNew? parentComponent.$t(`companyType.${propNew}`) : '';
const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: priority
propertiesProcessorMap.set('priority', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  
  propOld = propOld? parentComponent.$t(`priority.${propOld}`) : '';
  propNew = propNew? parentComponent.$t(`priority.${propNew}`) : '';
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: payFrequency
propertiesProcessorMap.set('payFrequency', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  
  propOld = propOld? parentComponent.$t(`payFrequency.${propOld}`) : '';
  propNew = propNew? parentComponent.$t(`payFrequency.${propNew}`) : '';
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: constraintType
propertiesProcessorMap.set('constraintType', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  
  propOld = propOld? parentComponent.$t(`constraint_type.${propOld}`) : '';
  propNew = propNew? parentComponent.$t(`constraint_type.${propNew}`) : '';
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: taskType
propertiesProcessorMap.set('taskType', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  
  propOld = propOld? parentComponent.$t(`task_type.${propOld}`) : '';
  propNew = propNew? parentComponent.$t(`task_type.${propNew}`) : '';
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${propItem.property}`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: fullName
propertiesProcessorMap.set('fullName', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  let rawPropName = propItem.property;
  if('CUSTOMER' == rootType) {
    rawPropName = 'name';
  }
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${rawPropName}`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: name
propertiesProcessorMap.set('name', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  let rawPropName = propItem.property;
  if('USER' == rootType) {
    rawPropName = 'nickName';
  }
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.${rawPropName}`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: uUID
propertiesProcessorMap.set('uUID', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.uuid`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: identifier
propertiesProcessorMap.set('identifier', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  const propName = parentComponent.$t(`field.identifier`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: resourceCost
propertiesProcessorMap.set('resourceCost', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.cost`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: resourceCurrency
propertiesProcessorMap.set('resourceCurrency', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.currency`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: resourceFrequency
propertiesProcessorMap.set('resourceFrequency', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.frequency`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: resourceFrequency
propertiesProcessorMap.set('resourceFrequency', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.frequency`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: order
propertiesProcessorMap.set('order', function(rootType, propItem, historyItem, parentComponent) {
  historyItem.events.push(parentComponent.$t('history.action_with_proptext_task_order'));
});

//Property Processor: fileType
propertiesProcessorMap.set('fileType', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.type`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: fileSize
propertiesProcessorMap.set('fileSize', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.size`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: filePath
propertiesProcessorMap.set('filePath', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.path`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: modified
propertiesProcessorMap.set('modified', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  propOld = propOld == 'null' || propOld == null || propOld == 0 || propOld > 253370725199000? parentComponent.$t('history.none') : moment.utc(propOld).format('YYYY-MM-DD HH:mmA');
  propNew = propNew == 'null' || propNew == null || propNew == 0 || propNew > 253370725199000? parentComponent.$t('history.none') : moment.utc(propNew).format('YYYY-MM-DD HH:mmA');
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.modifiedDate`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: created
propertiesProcessorMap.set('created', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  propOld = propOld == 'null' || propOld == null || propOld == 0 || propOld > 253370725199000? parentComponent.$t('history.none') : moment.utc(propOld).format('YYYY-MM-DD HH:mmA');
  propNew = propNew == 'null' || propNew == null || propNew == 0 || propNew > 253370725199000? parentComponent.$t('history.none') : moment.utc(propNew).format('YYYY-MM-DD HH:mmA');
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.createdDate`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: scheduleMode
propertiesProcessorMap.set('scheduleMode', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  function getLocalizationString(key) {
    if (key != null) {
      return parentComponent.$t(`scheduleMode.${key}`);
    } else {
      return '';
    }
  }
  propOld = getLocalizationString(propOld);
  propNew = getLocalizationString(propNew);
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.scheduleMode`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: autoScheduling
propertiesProcessorMap.set('autoScheduling', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  function getLocalizationString(state) {
    if (state) {
      return parentComponent.$t(`project.autoschedule.auto`);
    } else {
      return parentComponent.$t(`project.autoschedule.manual`);
    }
  }
  propOld = getLocalizationString(propOld);
  propNew = getLocalizationString(propNew);
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.autoScheduling`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: staffType
propertiesProcessorMap.set('staffType', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.type`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: (currency) code
propertiesProcessorMap.set('code', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  const propName = parentComponent.$t(`${rootType.toLowerCase()}.field.currencyCode`);
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});

//Property Processor: only (a.k.a Lock)
propertiesProcessorMap.set('readOnly', function(rootType, propItem, historyItem, parentComponent) {
  let propOld = propItem.oldValue;
  let propNew = propItem.newValue;
  const propName = parentComponent.$t('lock');
  push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent);
});
//--- End of property processor declaration ---



//--- Private function ---
//Convert timestamp in number to localized date time string.
function _convertDuration(value, parentComponent) {
  const list = [];
  //Defensive code: return the original value if it is "null"
  if (value === "null") {
    list.push(value);
    return list;
  }
  if (value < 1) {
    list.push(parentComponent.$t('duration.day', [ '0' ]));
    return list;
  }

  let durationInDays = convertDurationToDisplay(value/60000, 'D', parentComponent.durationConversionOpts != null? parentComponent.durationConversionOpts : {});
  durationInDays = Number.parseFloat(durationInDays.substring(0, durationInDays.length-1));
  list.push(parentComponent.$t(durationInDays > 1?'duration.days':'duration.day', [ durationInDays ]));
  return list;
}

//Common function used by property processors
function push2HistoryActivities(propName, propOld, propNew, historyItem, parentComponent) {
  propOld = propOld != null && propOld != 'null'? propOld : '';
  propNew = propNew != null && propNew != 'null'? propNew : '';
  const maxCharLimit = 300;
  const individualMaxCharLimit = parseInt(maxCharLimit / 2);
  const remainderMaxCharLimit = maxCharLimit % 2;
  const propOldLengthLimit = individualMaxCharLimit;
  const propNewLengthLimit = individualMaxCharLimit + remainderMaxCharLimit;
  const propOldLength = propOld.length;
  const propNewLength = propNew.length;
  const totalChars = propOldLength + propNewLength;
  const excessChars = totalChars > maxCharLimit? totalChars - maxCharLimit: 0;
  if (excessChars > 0) {
    if(propOldLength > propOldLengthLimit && propNewLength > propNewLengthLimit) {
      propOld = propOld.substring(0, propOldLengthLimit - 3) + '...';
      propNew = propNew.substring(0, propNewLengthLimit - 3) + '...';
    } else if (propOldLength > propOldLengthLimit) {
      propOld = propOld.substring(0, maxCharLimit - propNewLength - 3) + '...';
    } else if (propNewLength > propNewLengthLimit) {
      propNew = propNew.substring(0, maxCharLimit - propOldLength - 3) + '...';
    }
  }
  historyItem.events.push(parentComponent.$t('history.action_replace_prop', [ propName, propOld, propNew ]));
}

// field history

function _preprocessFieldHistoryData(entityType, parentComponent, rawData) {
  if (rawData == null) {
    return [];
  }

  const keyMap = new Map();
  const data = [];
  let rawItem = null;
  for (let i = 0, len = rawData.length; i < len; i++) {
    rawItem = rawData[i];
    if(rawItem.epoch == null) {
      continue;
    }

    const person = rawItem.user || {};
    person.name = `${person.firstName? person.firstName+' ':''}${person.lastName? person.lastName:''}`;

    const historyItem = {
      epoch: rawItem.epoch
      , person
      , timestamp: moment(rawItem.epoch).valueOf()
      , events: []
    }
    try {
      if (rawItem.action !== 'CREATE' &&
          rawItem.properties != null) { //Handle properties case
        _processFieldPropertiesData(entityType, rawItem.properties, historyItem, parentComponent);
      } else { //Handle default case
        let action = rawItem.action;
        if ('CREATE' == rawItem.action) {
          action = parentComponent.$t('history.action.created');
        } else if ('UPDATE' == rawItem.action) {
          action = parentComponent.$t('history.action.updated');
        } else if ('DELETE' == rawItem.action) {
          action = parentComponent.$t('history.action.deleted');
        }
        const type = parentComponent.$t('fields.type')
        if (rawItem.name != null) {
          historyItem.events.push(parentComponent.$t('history.action_with_name', [ type, action, rawItem.name ]));
        } else {
          historyItem.events.push(parentComponent.$t('history.action_default', [ type, action ]));
        }
        
      }
    } catch(e) {
      console.error(e); //eslint-disable-line no-console
      historyItem.events.push(parentComponent.$t('history.action_error'));
    }

    //To create unique key (string) for duplicated epoch value
    const getNewKey = function(value, dMap) {
      if(dMap.has(value)) {
        let index = value.indexOf('_');
        if (index == -1) {
          return getNewKey(`${value}_1`, dMap);
        }
        const number = parseInt(value.substring(index+1));
        if(isNaN(number)) {
          return getNewKey(`${value.substring(0, index)}_1`, dMap);
        }
        return getNewKey(`${value.substring(0, index)}_${number+1}`, dMap);
      }
      return value;
    }
    const key = getNewKey('' + historyItem.epoch, keyMap);// Convert historyItem.epoch to string
    historyItem.id = key;
    keyMap.set(key, historyItem);
    data.push(historyItem);
  }
  return { core: data };
}

function _processFieldPropertiesData(entityType, properties, historyItem, parentComponent) {
  let propItem = null;
  for (let j = 0, jLen = properties.length; j < jLen; j++) {
    propItem = properties[j];
    
    let propOld = propItem.oldValue;
    let propNew = propItem.newValue;
    const propOldKey = Object.keys(propOld)[0];
    const propNewKey = Object.keys(propNew)[0];
    
    propOld = propOld != null && propOld != 'null'? propOld : null;
    propNew = propNew != null && propNew != 'null'? propNew : null;

    let oldKeys, newKeys
    if (propOld != null && propOld[propOldKey] != null && propOld[propOldKey] != 'undefined') {
      oldKeys = Object.keys(propOld[propOldKey])
    } else {
      oldKeys = [] //set empty list
    }

    if (propNew != null && propNew[propNewKey] != null) {
      newKeys = Object.keys(propNew[propNewKey])
    } else {
      newKeys = [] //set empty list
    }

    const oldList = []
    const newList = []
    if (oldKeys.length !== 0) {
      for (const k of oldKeys) {
        let v = propOld[propOldKey][k];
        let v2 = propNew[propNewKey][k];
        
        if (parentComponent.fieldType === 'Date' &&
            (k === 'min' || k === 'max' || k === 'def')) {
          v = moment(v).format('YYYY-MM-DD');
          v2 = moment(v2).format('YYYY-MM-DD');
        }
        
        if (v !== v2) {
          const str = `fields.field.${k}`;
          oldList.push(`${parentComponent.$t(str)}: ${v}`);
          newList.push(`${parentComponent.$t(str)}: ${v2}`);
        }
      }
    }
    else {
      for (const k of newKeys) {
        let v2 = propNew[propNewKey][k];
        if (parentComponent.fieldType === 'Date' &&
            (k === 'min' || k === 'max' || k === 'def')) {
          v2 = moment(v2).format('YYYY-MM-DD');
        }
        const str = `fields.field.${k}`;
        newList.push(`${parentComponent.$t(str)}: ${v2}`);
      }
    }
    let fromStr = '<div><ul>'
    fromStr += oldList.map(i => `<li>${i}</li>`).join('')
    fromStr += '</ul></div>'

    let toStr = '<div><ul>'
    toStr += newList.map(i => `<li>${i}</li>`).join('')
    toStr += '</ul></div>'


    let htmlContentStr = parentComponent.$t('fields.history_action_change_from_prop')
    htmlContentStr += fromStr
    htmlContentStr += parentComponent.$t('fields.history_action_change_to_prop')
    htmlContentStr += toStr


    historyItem.events.push(htmlContentStr);
  }
}