import { tagService } from '@/services';

export  async function addTags(uuId, tags, linkTagService) {
  const toAdd = [];
  for (let i = 0; i < tags.length; i++) {
    if (!tags[i].uuId) {
      // get the uuId
      tags[i].uuId = await tagService.list({filter: tags[i].name}).then((response) => {
        if (response.data.length !== 0) {
          return response.data[0].uuId;
        }
        return null;
      });
      
      if (tags[i].uuId === null) {
        tags[i].uuId = await tagService.create([{name: tags[i].name}]).then((response) => {
          if (response.data[response.data.jobCase].length !== 0) {
            return response.data[response.data.jobCase][0].uuId;
          }
          return null;
        });
      }
    }
    
    if (tags[i].uuId !== null) {
      toAdd.push({uuId: tags[i].uuId});
    }
  }
  if (toAdd.length > 0) {
    await linkTagService.create(uuId, toAdd);
  }
}