<template>
  <div class="edit-cell" @focusout="onFocusOut" @keydown="onKeyDown">
    <select class="custom-select form-control" v-model="value" @change="onChange">
      <template v-for="(opt, index) in params.options">
        <option :value="opt.value" :key="index" :style="{ display: (isEnumType && opt.num < 0)? 'none': 'block' }">{{ opt.text }}</option>
      </template>
    </select>
  </div>
</template>

<script>
import Vue from 'vue';
import { objectClone } from '@/helpers';

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEYCODE_ENTER = 13;
const KEYCODE_TAB = 9;
const KEYCODE_ESCAPE = 27;

export default Vue.extend({
  name: 'ListEditor',
  data() {
    return {
      value: '',
      cancelBeforeStart: false,
      compactMode: false,
      isEnumType: false,
      customProp: null
    };
  },
  methods: {
    getValue() {
      if (this.compactMode) {
        if (this.params.value == null) {
          return this.params.value;
        }
        if (this.value == this.params.value.single) {
          return this.params.value;
        }
        const updatedValue = objectClone(this.params.value);
        updatedValue.single = this.value;
        return updatedValue;
      } else {
        return this.value;
      }
    },
    isPopup() {
      return false;
    },
    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },

    setInitialState(params) {
      let startValue;
      if (params.key === KEY_BACKSPACE || params.key === KEY_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = null;
      } else if (params.charPress) {
        // if a letter was pressed, we start with the letter
        if (params.options == null || params.options.length == 0) {
          startValue = null;
        } else {
          const found = params.options.find(i => i.text.toLowerCase().startsWith(params.charPress.toLowerCase()));
          if (found) {
            startValue = found.value;
          } else {
            startValue = params.options[0].value;
          }
        }
        
      } else {
        // otherwise we start with the current value
        startValue = this.compactMode
          ? params.value.single
          : this.customProp == null
          ? params.value
          : params.data[this.customProp];
      }

      this.value = startValue;
    },

    // will reject the value if condition met
    isCancelAfterEnd() {
      return false;
    },

    onKeyDown(event) {
      const keyCode = this.getCharCodeFromEvent(event);
      const api = this.params.api;
      if (KEYCODE_ENTER == keyCode) {
        event.stopPropagation();
        event.preventDefault();
        if (event.shiftKey) {
          this.moveUp(api, this.params.rowIndex, this.params.column.colId);
        } else {
          this.moveDown(api, this.params.rowIndex, this.params.column.colId);
        }
        return;
      } else if (KEYCODE_TAB == keyCode) {
        event.stopPropagation();
        event.preventDefault();
        if (event.shiftKey) {
          api.tabToPreviousCell();
        } else {
          api.tabToNextCell();
        }
        return;
      }

      if (keyCode == KEYCODE_ESCAPE || keyCode == KEYCODE_ENTER) {
        if (keyCode == KEYCODE_ESCAPE) {
          this.value = this.params.value;
        }
        setTimeout(() => {
          this.params.stopEditing();
        }, 100);
      }
    },

    getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    },

    isCharNumeric(charStr) {
      return /\d/.test(charStr);
    },

    isKeyPressedNumeric(event) {
      const charStr = event.key;
      return this.isCharNumeric(charStr);
    },

    finishedEditingPressed(event) {
      const charCode = this.getCharCodeFromEvent(event);
      return charCode === KEYCODE_ENTER || charCode === KEYCODE_TAB;
    },

    deleteOrBackspace(event) {
      return (
        [KEY_DELETE, KEY_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) >
        -1
      );
    },

    isLeftOrRight(event) {
      return [37, 39].indexOf(this.getCharCodeFromEvent(event)) > -1;
    },
    
    onChange(val) {
      if (this.params.setLabel) {
        const item = this.params.options.filter(o => o.value === val);
        this.params.data.label = item.length !== 0 ? item[0].text : null;
      }
      
      if (this.params.onChange) {
        this.params.onChange(val, this.params);
      }
      this.params.api.stopEditing();
    },

    onFocusOut() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 300)
    },

    moveUp(api, pRowIndex, pColId) {
      setTimeout(() => {
        let rowIndex = pRowIndex - 1;
        const colId = pColId;
        if (rowIndex < 0) {
          rowIndex = 0;
        }
        api.clearRangeSelection();
        api.setFocusedCell(rowIndex, colId, null);
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        });
      })
    },

    moveDown(api, pRowIndex, pColId) {
      setTimeout(() => {
        let rowIndex = pRowIndex + 1;
        const colId = pColId;
        if (api.getDisplayedRowAtIndex(rowIndex) == null) {
          rowIndex = this.params.rowIndex;
        }
        api.clearRangeSelection();
        api.setFocusedCell(rowIndex, colId, null);
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        });
      })
    }
  },

  created() {
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    if (this.params.isEnumType != null && typeof this.params.isEnumType === 'boolean') {
      this.isEnumType = this.params.isEnumType;
    }
    if (this.params.customProp != null) {
      this.customProp = this.params.customProp;
    }
    this.setInitialState(this.params);
  },
  mounted() {
    
  }
})
</script>
