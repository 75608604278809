<template>
  <div>
    <template v-if="errMsg">
      <font-awesome-icon :id="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`" class="mb-2" style="vertical-align: bottom;color: red;font-size: 1.125em;" :icon="['fas', 'triangle-exclamation']"/>
      <b-popover
        :target="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`"
        placement="top"
        triggers="hover"
        :content="errMsg">
      </b-popover>
    </template>
    
    <span :class="errMsg ? 'limitWidth datetime-cell-renderer' : 'datetime-cell-renderer'">{{ label }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'DateTimeCellRenderer',
  data() {
    return {
      value: null,
      errMsg: null
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    if (!this.params.node.rowPinned) {
      // this.value = this.params && this.params.value && this.params.value !== '' && this.params.value !== 9223372036854776000 ? moment(this.params.value).format('L hh:mm A') : null;
      let rawValue = this.params? this.params.value : null;
      // eslint-disable-next-line
      if (rawValue == 0 || rawValue == '' || rawValue == 9223372036854776000 || rawValue === 253402174800000 || rawValue === 253402214400000 || rawValue === 32503680000000) {
        rawValue = null;
      }      
      if (rawValue && Array.isArray(rawValue)) {
        let display = [];
        for (const val of rawValue) {
          display.push(moment.utc(val).format('L hh:mm A'));
        }
        this.value = display.join(', ');
        return;
      }
      this.value = rawValue != null? moment.utc(rawValue).format('L hh:mm A') : null;
    }
    
    if (this.params.validation) {
      this.errMsg = this.params.validation(this.params, this.value);
    }
  },
  computed: {
    label() {
      return this.value? this.value : '';
    }
  }
})
</script>
<style lang="scss" scoped>
  .limitWidth {
    min-height: 26px;
    display: inline-block;
    white-space: nowrap;
    vertical-align: bottom;
    margin-left: 2px;
  }
</style>
