var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    { staticClass: "position" },
    [
      _vm.autoScheduling === true
        ? [
            _c("font-awesome-icon", {
              staticClass: "scheduling-icon",
              attrs: { icon: ["far", "calendar-check"] },
            }),
          ]
        : _vm._e(),
      _vm.autoScheduling === false
        ? [
            _c("font-awesome-icon", {
              staticClass: "scheduling-icon",
              attrs: { icon: ["far", "thumbtack"] },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }