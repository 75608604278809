export function getProgressColor(progress) {
    var progressColor = '#000000';
    
    //  1 - 25:   #67A9CF
    if (progress <= 25) {
      progressColor = "#fe9929";
    }
    //  26 - 50:  #3690C0
    else if (progress <= 50) {
      progressColor = "#41b6c4";
    }
    //  51 - 75:  #02818A
    else if (progress <= 75) {
      progressColor = "#3690c0";
    }
    //  75 - 99:  #016C59
    else if (progress <= 99) {
      progressColor = "#78c679";
    }
    //  100:      #014636
    else if (progress === 100) {
      progressColor = "#238443";
    }
    return progressColor;
  }

export function getFirstColor(colors) {
  if (colors && Array.isArray(colors)) {
    for (const color of colors) {
      if (color !== '') {
        return color;
      }
    }
    return null;
  }

  return colors;
  
}

// adjust the color to be positive so that we don't get values like -9 which create colors like
// #-95643
function colorAdj(col) {
  if (col < 0) {
    return Math.abs(col);
  }
  return col;
}

export function toComplimentary(color) {
  return '#' +
  ("0" + (colorAdj(32 - parseInt(color.substring(1, 3), 16))).toString(16)).slice(-2) +
  ("0" + (colorAdj(32 - parseInt(color.substring(3, 5), 16))).toString(16)).slice(-2) +
  ("0" + (colorAdj(32 - parseInt(color.substring(5, 7), 16))).toString(16)).slice(-2);
}

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export function invertColor(hex, bw) {
  if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
  }
  var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
      // https://stackoverflow.com/a/3943023/112731
      return (r * 0.299 + g * 0.587 + b * 0.114) > 186
          ? '#000000'
          : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

export function stringToColour(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var ii = 0; ii < 3; ii++) {
    var value = (hash >> (ii * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}