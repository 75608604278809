
import { httpAjax } from '@/helpers';

export const activityLinkStaffService = {
  create,
  update,
  remove,
};

/**
 * Create a new Link from activity to staff 
 * by passing necessary information
 * @param String activityId, Task's UUID
 * @param Array staffs, Staff details. Data sample: [{ uuId, level }]
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', staffList: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', staffLink: {level: 'Junior'} }] }
 */
function create(activityId, staffs) {
  const url = '/api/activity/link/staff/add';
  const config = {
    headers: getHeaders()
  };

  const staffList = []
  staffs.forEach(i => {    
    const custom = { ...i };
    delete custom['name'];
    delete custom['uuId'];
    delete custom['genericStaff'];
    delete custom['utilization'];
    delete custom['quantity'];

    staffList.push( {
      uuId: i.uuId, 
      resourceLink: {
        quantity: i.quantity,
        utilization: i.utilization, 
        duration: i.duration, 
        durationAUM: i.durationAUM,
        ...custom
      }
    });
  });

  const data = {
    uuId: activityId,
    staffList: staffList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Update existing link details of activity to staff.
 * by passing  necessary information
 * @param String activityId, Task's UUID
 * @param Array staffs, Staff details. Data sample: [{ uuId, level }] 
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', staffList: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', staffLink: {level: 'Junior'} }] }
 */
function update(activityId, staffs) {
  const url = '/api/activity/link/staff/update';
  const config = {
    headers: getHeaders()
  }

  const staffList = []
  staffs.forEach(i => {
    const custom = { ...i };
    delete custom['name'];
    delete custom['uuId'];
    delete custom['genericStaff'];
    delete custom['utilization'];
    delete custom['quantity'];

    staffList.push( {
      uuId: i.uuId, 
      resourceLink: {
        quantity: i.quantity,
        utilization: i.utilization, 
        duration: i.duration, 
        durationAUM: i.durationAUM,
        ...custom
      }
    });
  });

  const data = {
    uuId: activityId,
    staffList: staffList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from activity to staff.
 * by passing in an array list of links uuIds
 * @param String activityId, Task's UUID
 * @param Array staffIds, list of Staff's UUID 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', staffList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }
 */
function remove(activityId, staffs) {
  const url = '/api/activity/link/staff/delete';
  const config = {
    headers: getHeaders()
  };

  const staffList = []
  staffs.forEach(i => {
    staffList.push( {
      uuId: i.uuId,
      // staffLink: {
      //   utilization: i.utilization, 
      //   duration: i.duration, 
      //   durationAUM: i.durationAUM
      // }
    });
  });

  const data = {
    uuId: activityId,
    staffList: staffList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}