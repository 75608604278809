/* 
  import of DHTMLX Gantt is required.
*/

import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

export const ganttEventUtil = {
  highlightTask: _highLightTask
}

function _highLightTask(g, vComponent, tdOpen=false) {
  let taskId = vComponent.$route.query.tId;
  if(taskId) {
    g.attachEvent("onDataRender", function(){
      if(g.isTaskExists(taskId)) {
        g.selectTask(taskId);
        const t = g.getTask(taskId);
        taskId = null; //Set to null to avoid recursive call triggered by g.showLightbox() method
        const p = g.getTaskPosition(t, moment(t.start_date).subtract(1, "d").toDate());
        setTimeout(function() {
          g.scrollTo(p.left, p.top);
            if(tdOpen) {
              g.showLightbox(t.id);
            }
        }, 100);
      }
    });
  }
}

