import Vue from 'vue';
import VeeValidate from 'vee-validate';
import i18n from '@/i18n';

export default function() 
{
  Vue.use(VeeValidate, {
    validity: true,
    fieldsBagName: 'veeFields',
    i18n
  });
}
