<template>
  <div class="edit-cell">
    <input class="form-control" :ref="'input'" @focusout="onFocusOut" @keypress="onKeyPress($event)" @keydown="onKeyDown($event)" @keyup="onKeyUp($event)" v-model="value"/>
  </div>
</template>

<script>
import Vue from 'vue';
import { onDurationKeyDown, onDurationKeyUp, getCharCodeFromEvent, objectClone } from '@/helpers';
const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_ENTER = 'Enter';
const KEY_F2 = 'F2';
const KEYCODE_ENTER = 13;
const KEYCODE_TAB = 9;
const KEYCODE_ESCAPE = 27;
const KEYCODE_LEFT = 37;
const KEYCODE_UP = 38;
const KEYCODE_RIGHT = 39;
const KEYCODE_DOWN = 40;


export default Vue.extend({
  name: 'DurationEditor',
  data() {
    return {
      value: '',
      cancelBeforeStart: true,
      highlightAllOnFocus: false,
      compactMode: false,
      isFirstKeyEnter: false,
    };
  },
  methods: {
    getValue() {
      if (this.compactMode) {
        let value = this.value;
        if (value != null) {
          if (this.isCharNumeric(value[value.length - 1])) {
            value = `${value}D`;
          }
        }
        if (this.value == this.params.value.single) {
          return this.params.value;
        }
        const updatedValue = objectClone(this.params.value);
        updatedValue.single = value;
        return updatedValue;
      } else {
        if (this.value !== null && typeof this.value !== 'undefined') {
          if (!(this.isCharNumeric(this.value[this.value.length - 1]))) {
            return this.value;
          }
          else {
            return `${this.value}D`;
          }
        }
        return this.value;
      }
    },

    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },

    setInitialState(params) {
      let startValue;
      let highlightAllOnFocus = false;

      if (params.key === KEY_ENTER) {
        this.isFirstKeyEnter = true;
      }

      if (params.key === KEY_BACKSPACE || params.key === KEY_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (params.charPress) {
        // if a letter was pressed, we start with the letter
        startValue = params.charPress;
      } else if (params.key == '.') {
        startValue = '.'
      } else {
        // otherwise we start with the current value
        startValue = this.compactMode? params.value.single: params.value;
        
        if (params.key === KEY_F2) {
          highlightAllOnFocus = true;
        }
      }

      this.value = startValue;
      this.highlightAllOnFocus = highlightAllOnFocus;
    },

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    isCancelAfterEnd() {
      return this.value > 1000000;
    },

    onKeyPress(event) {
      //A fix to the scenario: Press '.' to trigger the start of cell editing and keyPress event is triggered unexpectedly. 
      //The value ends up having double dot ('..')
      //This issue cant be reproduced consistently.
      //Please revisit this code when upgrade to Ag-grid v30.
      if (event.charCode == 46 
          && typeof this.value === 'string' 
          && this.value.length > 0 
          && this.value.indexOf('.') != -1) {
        event.stopPropagation();
        event.preventDefault();
        return false;
      }
    },

    onKeyDown(event) {
      const keyCode = this.getCharCodeFromEvent(event);
      const api = this.params.api;
      if (KEYCODE_ENTER == keyCode) {
        event.stopPropagation();
        event.preventDefault();
        this.params.api.stopEditing();
        if (event.shiftKey) {
          this.moveUp(api, this.params.rowIndex, this.params.column.colId);
        } else {
          this.moveDown(api, this.params.rowIndex, this.params.column.colId);
        }
      } else if (KEYCODE_TAB == keyCode) {
        event.stopPropagation();
        event.preventDefault();
        if (event.shiftKey) {
          api.tabToPreviousCell();
        } else {
          api.tabToNextCell();
        }
      } else if (this.isNavigationKey(event) && !this.isFirstKeyEnter) {
        if (KEYCODE_LEFT == keyCode || KEYCODE_RIGHT == keyCode) {
          return;
        }
        
        event.stopPropagation();
        event.preventDefault();
        if (KEYCODE_UP == keyCode) {
          this.moveUp(api, this.params.rowIndex, this.params.column.colId);
        }
        else if (KEYCODE_DOWN == keyCode) {
          this.moveDown(api, this.params.rowIndex, this.params.column.colId);
        }
        return;
      }

      if (keyCode == KEYCODE_ESCAPE) {
        this.value = this.params.value;
        setTimeout(() => {
          this.params.api.stopEditing();
        }, 100);
      } else {
        onDurationKeyDown(event, this.finishedEditingPressed(event));
      }
    },

    onKeyUp(event) {
      const charCode = getCharCodeFromEvent(event);
      if (charCode != KEYCODE_ESCAPE) {
        this.value = onDurationKeyUp(event, this.value);
      }
    },
    
    getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    },

    isCharNumeric(charStr) {
      return /\d/.test(charStr);
    },

    finishedEditingPressed(event) {
      const charCode = this.getCharCodeFromEvent(event);
      return charCode === KEYCODE_ENTER || charCode === KEYCODE_TAB;
    },

    isNavigationKey(event) {
      const keyCode = this.getCharCodeFromEvent(event);
      return keyCode >= 37 && keyCode <= 40;
    },

    onFocusOut() {
      this.params.api.stopEditing();
    },

    moveUp(api, pRowIndex, pColId) {
      setTimeout(() => {
        let rowIndex = pRowIndex - 1;
        const colId = pColId;
        if (rowIndex < 0) {
          rowIndex = 0;
        }
        api.clearRangeSelection();
        api.setFocusedCell(rowIndex, colId, null);
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        });
      })
    },

    moveDown(api, pRowIndex, pColId) {
      setTimeout(() => {
        let rowIndex = pRowIndex + 1;
        const colId = pColId;
        if (api.getDisplayedRowAtIndex(rowIndex) == null) {
          rowIndex = this.params.rowIndex;
        }
        api.clearRangeSelection();
        api.setFocusedCell(rowIndex, colId, null);
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        });
      })
    }
  },

  created() {
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    } 
    this.setInitialState(this.params);
    

    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart =
      this.params.charPress && '.1234567890'.indexOf(this.params.charPress) < 0;
  },
  mounted() {
    Vue.nextTick(() => {
      // need to check if the input reference is still valid - if the edit was cancelled before it started there
      // wont be an editor component anymore
      if (this.$refs.input) {
        this.$refs.input.focus();
        if (this.highlightAllOnFocus) {
          this.$refs.input.select();

          this.highlightAllOnFocus = false;
        } else {
          // when we started editing, we want the caret at the end, not the start.
          // this comes into play in two scenarios: a) when user hits F2 and b)
          // when user hits a printable character, then on IE (and only IE) the caret
          // was placed after the first character, thus 'apply' would end up as 'pplea'
          const length = this.$refs.input.value
            ? this.$refs.input.value.length
            : 0;
          if (length > 0) {
            this.$refs.input.setSelectionRange(length, length);
          }
        }

        this.$refs.input.focus();
      }
    });
  }
})
</script>