
import { httpAjax } from '@/helpers';

export const resourceLinkFileService = {
  create,
  remove,
};

/**
 * Create a new Link from resource to file 
 * by passing necessary information
 * @param String resourceId, Project's UUID
 * @param Array files, file details. Data sample: [{ uuId }]
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', storageFileList: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx' }] }
 */
function create(resourceId, files) {
  const url = '/api/resource/link/file/add';
  const config = {
    headers: getHeaders()
  };

  const storageFileList = []
  files.forEach(i => {
    storageFileList.push( {uuId: i.uuId} );
  });

  const data = {
    uuId: resourceId,
    storageFileList: storageFileList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from resource to file.
 * by passing in an array list of links uuIds
 * @param String resourceId, Project's UUID
 * @param Array fileIds, list of file's UUID 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', storageFileList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }
 */
function remove(resourceId, files) {
  const url = '/api/resource/link/file/delete';
  const config = {
    headers: getHeaders()
  };

  const storageFileList = []
  files.forEach(i => {
   storageFileList.push( {uuId: i.uuId} );
  });

  const data = {
    uuId: resourceId,
    storageFileList: storageFileList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}