<template>
  <ResourceBadgeGroupModal 
    :show.sync="modalShow" 
    :resourceList="resourceList"
    :edgeName="edgeName"
    @ok="resourceSelectorOk" 
    @cancel="resourceSelectorCancel" 
    @resourceDeleted="resourceSelectorDeleted"/>
</template>

<script>
import Vue from 'vue'
import { objectClone } from '@/helpers';
export default Vue.extend({
  name: 'ResourcesEditor',
  components: {
    ResourceBadgeGroupModal: () => import('@/components/modal/ResourceBadgeGroupModal')
  },
  data() {
    return {
      value: ''
      , modalShow: false
      , resourceList: []
      , edgeName: null
      , hasChanged: false
    }
  },
  computed: {
  },
  methods: {
    getValue() {
      if (this.compactMode) {
        const orgValue = this.params.value.single;
        const curValue = this.value;
        if (orgValue == null && curValue == null) {
          return this.params.value;
        } else if ((orgValue == null && curValue != null && curValue.length > 0) || 
                    (orgValue != null && (orgValue.length != curValue.length))) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }

        if (this.hasChanged) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        return this.params.value;
      } else {
        return this.value;
      }
    },
    isPopup() {
      return true
    },
    isCancelBeforeStart() {
      return false
    },

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing()
      }, 100)
    },
    resourceSelectorOk({ value, customFields }) {
      const modifiedResourceList = value;
      //Check array size
      if (!this.params.value ||
          modifiedResourceList.length != this.params.value.length) {
        this.hasChanged = true
        this.value = modifiedResourceList
        this.closePicker()
        return
      }

      //Check properties
      const originResources = this.params.value
      let hasChanged = false
      for(let i = 0, len = modifiedResourceList.length; i < len; i++) {
        const modified = modifiedResourceList[i]
        const found = originResources.find(i => i.uuId == modified.uuId)
        if (found == null) {
          hasChanged = true
          break
        }

        if (found.unit != modified.unit || found.utilization != modified.utilization) {
          hasChanged = true
          break
        }

        for (const f of customFields) {
          if (Object.hasOwn(found, f.name) && Object.hasOwn(modified, f.name)) {
           
            if ((f.type == 'String' || f.type == 'Enum<String>') && found[f.name].localeCompare(modified[f.name], undefined, { sensitivity: 'base' }) != 0) {
              hasChanged = true
              break
            } else if ((f.type != 'String' && f.type != 'Enum<String>') && found[f.name] != modified[f.name]) {
              hasChanged = true
              break
            }
          } else if (Object.hasOwn(found, f.name) || Object.hasOwn(modified, f.name)) {
            hasChanged = true
            break
          }
        }
      }
      
      if (hasChanged) {
        this.hasChanged = true
        this.value = modifiedResourceList
      }
      this.closePicker
    },
    resourceSelectorCancel({ modifiedList=[]}={}) {
      if (modifiedList.length > 0) {
        this.hasChanged = true
        const deletedIds = this.modifiedList.filter(i => i.action =='DELETE').map(i => i.uuId)
        const updatedList = this.modifiedList.filter(i => i.action == 'UPDATE')
        const remaining = this.value.filter(i => !deletedIds.includes(i.uuId))
        for (const item of remaining) {
          const obj = updatedList.find(i => i.uuId == item.uuId)
          if (obj == null) {
            continue
          }
          item.name = obj.name
        }
        this.value = remaining
      }
      this.closePicker()
    },
    prepareResourceList() {
      if(this.value != null) {
        for (const resource of this.value) {
          this.resourceList.push(JSON.parse(JSON.stringify(resource)));
        }
      }
    },
    resourceSelectorDeleted(/**{ removedList=[]}={}**/) {
    }
    
  },

  created() {
    if (this.params.edgeName != null) {
      this.edgeName = this.params.edgeName;
    }
    this.modalShow = true
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    this.value = this.compactMode? objectClone(this.params.value.single) : this.params.value ? this.params.value : [];
    this.prepareResourceList()
    
  }
})
</script>