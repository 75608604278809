

<template>
  <div class="d-flex">
    <div class="d-flex select-button">
      <div ref="menuButton" class="customHeaderMenuButton" @click="onSelectToggle($event)">
        <font-awesome-icon class="selection-icon" :icon="selectedAll ? ['far', 'square-check'] : selectedSome ? ['far', 'square-minus'] : ['far','square']"/>
      </div>
      <div v-if="params.enableMenu" ref="menuButton" class="select-menu ml-1 customHeaderMenuButton" @click="onMenuClicked($event)">
        <font-awesome-icon class="selection-icon" :icon="['far','caret-down']"/>
      </div>
    </div>
    
    <span class="ml-1 display-name">{{ params.displayName }}</span>
    <span class="ag-sort-indicator-container paged-range-select-sort-indicator" ref="eSortIndicator">
      <span ref="eSortOrder" v-if="params.enableSorting" class="ag-sort-indicator-icon ag-sort-order" :class="{ 'ag-hidden': sortIndexDisplay === null }" aria-hidden="true">{{ sortIndexDisplay }}</span>
      <span ref="eSortAsc" v-if="params.enableSorting" class="ag-sort-indicator-icon ag-sort-ascending-icon" :class="{ 'ag-hidden': ascSort !== true }" aria-hidden="true"><span class="ag-icon ag-icon-asc" unselectable="on" role="presentation"></span></span>
      <span ref="eSortDesc" v-if="params.enableSorting" class="ag-sort-indicator-icon ag-sort-descending-icon" :class="{ 'ag-hidden': descSort !== true }" aria-hidden="true"><span class="ag-icon ag-icon-desc" unselectable="on" role="presentation"></span></span>
    </span>
  </div>
</template>

<script>
import Vue from 'vue';
import { fieldOptions } from '@/selectOptions';

export default Vue.extend({
  name: 'SelectionHeaderComponent',
  data() {
    return {
      value: null,
      options: fieldOptions,
      sortIndexDisplay: null,
      ascSort: false,
      descSort: false,
      selectedAll: false,
      selectedSome: false,
      // selectedLocalAll: false,
      columnName: 'name',
      eHeaderCompWrapper: null 
    }
  },
  beforeMount() {
    this.prepareData();
  },
  mounted() {
    if (this.params.eGridHeader?.children != null) {
      const eHeaderCompWrapper = this.params.eGridHeader.querySelector('[ref=eHeaderCompWrapper]');
      if (eHeaderCompWrapper != null) {
        this.eHeaderCompWrapper = eHeaderCompWrapper;
        this.eHeaderCompWrapper.addEventListener('click', this.onSortRequested);
      }
    }
    
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.onSortChanged();
  },
  beforeDestroy() {
    if (this.eHeaderCompWrapper != null) {
      this.eHeaderCompWrapper.removeEventListener('click', this.onSortRequested)
    }
    this.params.column.removeEventListener('sortChanged', this.onSortChanged);
  },
  computed: {

  },
  methods: {
    prepareData() {
      this.$nextTick(() => {
        const selectionStatus = this.getSelectionStatus();
        this.selectedAll = selectionStatus == 'all';
        this.selectedSome = selectionStatus == 'some';
      });
      if (this.params.targetColId != null && this.params.targetColId.trim().length > 0) {
        this.columnName = this.params.targetColId;
      }
    },
    refresh(params) {
      this.params = params;
      this.prepareData();
      this.onSortChanged();
    },
    getSelectionStatus() {
      const api = this.params.api;
      if (api == null || api.getDisplayedRowCount() == 0) {
        return 'none';
      }

      //Get the current page status only
      const lastGridIdx = api.getDisplayedRowCount() - 1;
      const currentPage = api.paginationGetCurrentPage();
      const pageSize = api.paginationGetPageSize();
      const startPageIdx = currentPage * pageSize;
      let endPageIdx = ((currentPage + 1) * pageSize) - 1;
      if (endPageIdx > lastGridIdx) {
        endPageIdx = lastGridIdx;
      }

      let validRowCount = 0;
      let selectedCount = 0;
      for (let i = startPageIdx; i <= endPageIdx; i++) {
        const rowNode = api.getDisplayedRowAtIndex(i);
        validRowCount += 1;
        if (rowNode.selected == true) {
          selectedCount += 1;
        }
      }

      if (selectedCount == 0) {
        return 'none';
      } else if (validRowCount == selectedCount) {
        return 'all';
      } else {
        return 'some';
      }
    },
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },
    onSelectToggle() {
      const api = this.params.api;
      if (api == null) {
        return;
      }

      const toUnselectAll = this.selectedAll;
      //Update selectedAll with next state
      this.selectedAll = !toUnselectAll;
      
      const lastGridIdx = api.getDisplayedRowCount() - 1;
      const currentPage = api.paginationGetCurrentPage();
      const pageSize = api.paginationGetPageSize();
      const startPageIdx = currentPage * pageSize;
      let endPageIdx = ((currentPage + 1) * pageSize) - 1;
      if (endPageIdx > lastGridIdx) {
        endPageIdx = lastGridIdx;
      }

      if (toUnselectAll) {
        api.deselectAll();
        api.clearRangeSelection();
      }
      else {
        const rowCount = api.getDisplayedRowCount();
        if (rowCount == 0) {
          return;
        }

        api.clearRangeSelection();
        api.addCellRange({
          rowStartIndex: startPageIdx
          , rowEndIndex: endPageIdx
          , columns: ['name']
        });

        //Make sure there is a focused cell in the grid.
        //Without focused cell, cell navigation, delete key interaction will not work.
        if(api.getFocusedCell() == null) {
          api.setFocusedCell(startPageIdx, 'name');
        }
      }
    },
    onSortRequested(event) {
      if (event.target.classList.contains('.select-button') || event.target.closest('.select-button') != null) {
        return; //do nothing
      }
      this.params.progressSort(event.shiftKey);
    },
    onSortChanged(event) {
      this.ascSort = this.descSort = false;
      this.sortIndexDisplay = this.params.column.sortIndex != null && 
        (this.params.column.sortIndex > 0 || this.params.columnApi.getColumns().filter(i => i.sortIndex != null).length > 1)
        ? this.params.column.sortIndex+1 
        : null;
      
      if (this.params.column.isSortAscending()) {
        this.ascSort = true;
      } else if (this.params.column.isSortDescending()) {
        this.descSort = true;
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.selection-icon {
  font-size: 18px;
  margin-top: 1px;
  color: var(--gray-500);
}

.select-menu {
  padding: 0 2px;
}

.select-button {
  padding: 3px;
  width: fit-content;
  border: 1px solid transparent;
}

.display-name {
  margin-top: 6px;
}

.select-button:focus,
.select-button:active,
.select-button:hover {
  background: var(--backdrop);
  border-radius: 3px;
  border: 1px solid var(--border-dark);
}

.paged-range-select-sort-indicator {
  margin-top: 5px;
}
</style>
