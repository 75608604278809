import Vue from 'vue';
import { authenticationService } from "@/services";
import router from "@/router";
import i18n  from '@/i18n';

export const authentication = {
  namespaced: true,
  state: { status: {}, user: null },
  actions: {
    login({ dispatch, commit }, { username, password, nextUrl }) {
      commit("loginRequest", { email: username });
      authenticationService
        .login(username, password)
        .then(response => {

          // If the response was okay and we now have a header.payload cookie,
          // we were logged in successfully. Future requests will authenticate
          // with the cookies now set in the browser.
          if (response.status == 200) {
            if(nextUrl) {
              router.push(nextUrl);
            } else {
              router.push("/");
            }
          }
          return;
        })
        .catch(e => {
          commit("loginFailure");
          console.error(e); // eslint-disable-line no-console
          let errMsg = e? e.message: 'e is null';
          if (e && e.response && (401 === e.response.status || 429 === e.response.status)) {
            errMsg = typeof e.response.headers['x-rate-limit-remaining'] !== 'undefined' ? i18n.t('login.error.invalid_username_password_retries', [parseInt(e.response.headers['x-rate-limit-remaining']) + 1]) : i18n.t('login.error.invalid_username_password');
            if (e.response.data.jobClue) {
              if (e.response.data.jobClue.hint === 'user.licence.limit') {
                errMsg = i18n.t('login.error.license_limit_exceeded');
              } else if (e.response.data.jobClue.hint === 'user.vital.invalid') { 
                errMsg = i18n.t('login.error.invalid_license');
              } else if (e.response.data.jobClue.hint === 'token.provider.vital') {
                errMsg = i18n.t('login.error.license_server');
              } else if (e.response.data.jobClue.hint === 'user.disabled') {
                errMsg = i18n.t('login.error.user_disabled');
              } else if (e.response.data.jobClue.clue === 'Too_many_requests') {
                errMsg = i18n.t('login.error.too_many_request', [e.response.headers['x-rate-limit-retry-after-seconds']]);
              }
            }
          } else {
            errMsg = i18n.t('login.error.failure');
          }
          dispatch("alert/error", errMsg, { root: true });
        });
    },
    logout({ commit }) {
      commit("logout");
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      Vue.set(state, 'user', user);
    },
    loginSuccess(state, { user }) {
      state.status = { loggedIn: true };
      Vue.set(state, 'user', user);
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
    updateUser(state, user) {
      // Add missing values from API. Needed for auto-refresh to work in profile account dropdown and such
      if (!("nickName" in user)) {
        user.nickName = "";
      }
      if (!("mobile" in user)) {
        user.mobile = "";
      }
      Vue.set(state, 'user', user);
    },
  }
};
