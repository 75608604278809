export const fieldValidateUtil = {
  hasError: _hasError,
  stateValidate: _fieldStateValidate,
  invalidFeedback: _fieldInvalidFeedback,
  stateValidate2
}

function _hasError(errors, key) {
  return errors.first(key) && errors.first(key).length? true: false;
}
// Form field validation
function _fieldStateValidate(readOnly, veeFields, errors, ref) {
  if (!readOnly) {
    if (veeFields[ref] && (veeFields[ref].dirty || veeFields[ref].validated)) {
      return !errors.has(ref)? null : false;
    }
  }
  return null;
}

function _fieldInvalidFeedback(errors, ref) {
  const matchedErrors = this.errors.items.filter(i => i.field == ref);
  if(matchedErrors.length > 0) {
    return matchedErrors[0].msg;
  } else {
    return "";
  }
}

// Form field validation
function stateValidate2(veeFields, errors, ref, ignoreIfTrue) {
  if (!ignoreIfTrue) {
    if (veeFields[ref] && (veeFields[ref].dirty || veeFields[ref].validated)) {
      return !errors.has(ref)? null : false;
    }
  }
  return null;
}