<template>
  <div v-if="allowManage && isEditable">
    <a href="#" :class="{ 'grid-cell-summary': isSummaryTask }" :style="labelStyle" @click.stop.prevent="invokeParentMethod">{{ label }}</a>
  </div>
  <div v-else>
    <span :style="isSummaryTask ? 'font-weight: 500' : ''">{{ label }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import { invertColor } from '@/helpers';

export default Vue.extend({
  name: 'ViewDetailLinkCellRenderer',
  data() {
    return {}
  },
  computed: {
    labelStyle() {
      const componentParent = this.params.context.componentParent;
      const rowColor = componentParent.getRowColor ? componentParent.getRowColor(this.params.data, this.params) : null;
      const style = [];
      if (this.isCompany) {
        style.push('font-weight: bold');
      }
      
      if (rowColor) {
        style.push(`color: ${invertColor(rowColor, true)}`);
      }
      return style.join(';');
    },
    label() {
      if(!this.params) {
        return 'N/A';
      }
      const labelFunc = this.params.context.componentParent.detailLinkLabel;
      if(labelFunc) {
        return labelFunc(this.params);
      }
      
      let label = this.params && this.params.label? this.params.node.data[this.params.label]: this.params.node.data.label;
      if(label) {
        return label;
      }
      return 'N/A';
    },
    allowManage() {
      var manage = this.params.context.componentParent.allowManage;
      var can = true;
      if (this.params.context.componentParent.permissionName) {
        can = this.params.context.componentParent.canView();
      }
      return manage && can;
    },
    isEditable() {
      //Used in ProjectTask compact view to make Project Cell read only.
      if (this.params.data && this.params.data.uuId == 'ROOT') {
        return false;
      }
      if (typeof this.params.context.componentParent.isEditable === 'undefined') {
        return true;
      }
      return this.params.context.componentParent.isEditable(this.params);
    },
    isSummaryTask() {
      const type = this.params && this.params.node && this.params.node.data && this.params.node.data.taskType ? this.params.node.data.taskType : null;
      return type !== null && (type === 'Project');
    },
  },
  methods: {
    invokeParentMethod() {
      const idFunc = this.params.context.componentParent.detailLinkId;
      this.params.context.componentParent.openDetail(idFunc? idFunc(this.params) : this.params.value, this.params, { isGeneric: false });
    }
  }
});
</script>
