<template>
  <div>
    <AppHeaderDropdown right no-caret>
      <template slot="header">
        <span class="d-none d-md-inline">
        {{ fullName }}
        </span>
        <img
          :src="avatarUrl"
          @error="avatarImageError"
          class="img-avatar" />
      </template>\
      <template slot="dropdown">
        <template v-if="canView('USER')">
          <b-dropdown-item @click="profile"><font-awesome-icon :icon="['far', 'user']" />{{ $t('header_dropdown.profile') }}</b-dropdown-item>
        </template>
        <span class="dropdown-item dropdown-checkbox" @click="toggleTheme">
          <font-awesome-icon :icon="['far', 'moon-stars']" />{{ $t('header_dropdown.dark_theme') }}
          <b-form-checkbox readonly disabled switch class="d-inline-block ml-2" id="theme-toggler" v-model="nightMode"/>
        </span>
        <b-dropdown-divider />
        <b-dropdown-item v-if="hasPermission('ADMIN__ACCOUNT_MANAGEMENT')" target="_blank" href="https://accounts.projectal.com/"><font-awesome-icon :icon="['far', 'users-gear']" />{{ $t('header_dropdown.account_management') }}</b-dropdown-item>
        <b-dropdown-item @click="about"><font-awesome-icon :icon="['far', 'circle-question']" />{{ $t('header_dropdown.about') }}</b-dropdown-item>
        <b-dropdown-item @click="help"><font-awesome-icon :icon="['far', 'circle-question']" />{{ $t('header_dropdown.help') }}</b-dropdown-item>
        <b-dropdown-item @click="logout"><font-awesome-icon :icon="['far', 'right-from-bracket']" />{{ $t('header_dropdown.logout') }}</b-dropdown-item>

      </template>
    </AppHeaderDropdown>
    <ProfileModal :id="userId" :show.sync="profileShow" :title="profileTitle"/>
    <AboutModal :show.sync="aboutShow" :title="aboutTitle"/>
  </div>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
import { EventBus } from '@/helpers';
import { companyService } from '@/services';

export default {
  name: 'HeaderDropdownAccount',
  components: {
    AppHeaderDropdown,
    ProfileModal: () => import('@/components/modal/ProfileModal'),
    AboutModal: () => import('@/components/modal/AboutModal')
  },
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      itemsCount: 42,
      profileShow: false,
      aboutShow: false,
      userId: null,
      nightMode: null
    }
  },
  created() {
    this.userId = this.$store.state.authentication.user.uuId;
    this.nightMode = window.localStorage.getItem('theme') == 'dark';
    
    const freshworks = document.getElementById('freshworks-script');
    if (freshworks === null) {
      var head = document.getElementsByTagName('head')[0];
      var js = document.createElement("script");
      var js2 = document.createElement("script");
      js.type = "text/javascript";
      js.id = 'freshworks-script';
      
      if (!this.nightMode)
      {
          js.src = 'https://aus-widget.freshworks.com/widgets/51000002522.js';
      }
      else
      {
          js.src = 'https://aus-widget.freshworks.com/widgets/51000002658.js';
      }
      
      head.appendChild(js);
      js2.text = '!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n;FreshworksWidget("hide", "launcher");}}()';
      head.appendChild(js2);
      if (this.nightMode) {
         window.fwSettings={
         'widget_id':51000002658
         };
       }
       else {
         window.fwSettings={
             'widget_id':51000002522
             };
       }
     }
  },
  watch: {
    nightMode(newValue, oldValue) {
      if (oldValue != null) {
        this.changeTheme();
      }
    }
  },
  methods: {
    profile() {
      this.profileShow = true;
    },
    about() {
      this.aboutShow = true;
    },
    async help() {
      const companyName = this.$store.state.company.type === 'Primary' ?
        this.$store.state.company.name :
        await companyService.list({ limit: -1, start: 0 }).then((response) => {
            const data = response.data;
            const company = data.filter(d => d.type === 'Primary');
            return company[0].name;
        });
      // eslint-disable-next-line
      FreshworksWidget('identify', 'ticketForm', {
        name: this.$store.state.authentication.user.name,
        email: this.$store.state.authentication.user.email,
        custom_fields: {
          cf_company: companyName
        }
      });
      // eslint-disable-next-line
      FreshworksWidget('open');
    },
    logout() {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = '/api/user/logout';
      document.body.appendChild(form);
      form.submit();
    },
    avatarImageError(event) {
      if (event.target.src != this.defaultAvatarUrl) {
        event.target.src = this.defaultAvatarUrl;
      }
    },
    toggleTheme() {
      this.nightMode = !this.nightMode;
    },
    changeTheme() {
      if (this.nightMode) {
        document.getElementById('theme-colors').href = process.env.BASE_URL + "css/theme-dark.css"; 
        window.localStorage.setItem('theme', 'dark');
      } else {
        document.getElementById('theme-colors').href = process.env.BASE_URL + "css/theme-light.css";
        window.localStorage.setItem('theme', 'light');
      }
      EventBus.$emit('theme-change');
    }
  },
  computed: {
    fullName() {
      const user = this.$store.state.authentication.user;
      return  user? (user.nickName? user.nickName: (user.firstName + " " + user.lastName)) : '';
    },
    avatarUrl() {
      if (!this.canView('STORAGE_FILE')) {
        return this.defaultAvatarUrl;
      }
      const user = this.$store.state.authentication.user;
      return user? (user.avatarRef? `/api/file/${user.avatarRef}`: this.defaultAvatarUrl) : this.defaultAvatarUrl;
    },
    defaultAvatarUrl() {
      return `${this.baseUrl}img/avatars/male.png`;
    },
    profileTitle() {
      return this.$t('user.profile_edit');
    },
    aboutTitle() {
      return this.$t('label_about');
    },
  }
}
</script>

<style>
.img-avatar {
  height: 35px;
  width: 35px;
  margin: 0 10px;
  object-fit: cover;
}
.app-header .dropdown-item {
    min-width: unset !important;
}

.dropdown-checkbox {
  display: flex !important;
  align-items: center;
  cursor: pointer;
}

.dropdown-menu.dropdown-menu-right {
  width: 190px !important;
}

/* Make the disabled style appear normal */ 
#theme-toggler {
  display: none;
}
#theme-toggler ~ .custom-control-label {
  cursor: pointer;
}
.custom-switch #theme-toggler:disabled:checked ~ .custom-control-label::before {
    background-color: var(--projectal-orange);
}
</style>

<style lang="scss" scoped>

.dropdown-item svg {
  width: 22px;
}
</style>