export function msToTime(duration) {
  if (typeof duration === 'undefined' ||
      duration === null) {
    return null;
  }
  if (typeof duration === 'string') {
    return duration;
  }

  let hour = Math.trunc(duration / 1000 / 60 / 60);
  if (hour < 10) {
    hour = '0' + hour;
  }
  let minute = duration / 1000 / 60 % 60;
  if (minute < 10) {
    minute = '0' + minute;
  }
  return `${hour}:${minute}`;
}

export function timeToMs(value) {
  const matches = /^(\d+):(\d+).*?$/g.exec(value);
  var val = 0;
  if (matches !== null) {
    val = ((parseInt(matches[1]) * 60 * 60) + (parseInt(matches[2]) * 60)) * 1000;
  }
  return val;
}

export function convertMsToDays(ms, { hourPerDay=-1 }={}) {
  let seconds = (ms / 1000) | 0;
  ms -= seconds * 1000;

  if (seconds === 0) {
    return 0;
  }

  let minutes = (seconds / 60) | 0;
  seconds -= minutes * 60;

  if (minutes === 0) {
    return 0;
  }

  let hours = (minutes / 60) | 0;
  minutes -= hours * 60;

  if (hours === 0) {
    return 0;
  }

  let days = (hours / 8) | 0;
  hours -= days * (hourPerDay > -1? hourPerDay : 8); // Fallback: 8 working hours in a day

  return days;
}

export function convertMinutesToDays(minutes, { hourPerDay=-1 }={}) {
  return minutes / 60 / (hourPerDay >- 1? hourPerDay : 8);
}

export function incrementDuration(dur, delta) {
  if (dur === 0) {
    return dur;
  }

  var durUnit = dur[dur.length - 1];
  if (durUnit.match(/[a-z]/i)) {
    dur = parseInt(dur.substring(0, dur.length - 1));
  }
  else {
    dur = parseInt(dur);
    durUnit = 'D';
  }
  dur += delta;

  if (dur < 0) {
    dur = 0;
  }
  
  return dur + durUnit;
}