
import { staffService, tagService } from '@/services';
import { filterOutViewDenyProperties, columnDefSortFunc } from '@/views/management/script/common';

import { invertColor } from '@/helpers';
function sortFunc(a, b) {
  if (a.staff && !b.staff) {
    return 1;
  }
  else if (b.staff && !a.staff) {
    return -1;
  }
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
}

export function extractRowsFromData(self, path, data, listStaff) {
  const rows = [];
  if (data !== null) {
    // sort the data by name
    data.sort(sortFunc);
    if (self.exclude !== null && typeof data !== 'undefined') {
      for (var i = 0; i < data.length; i++) {
        if (data[i].uuId === self.exclude) {
          data.splice(i, 1); // do not list the department itself or its children
          break;
        }
      }
    }
    
    for (let i = 0; i < data.length; i++) {
      // copy tags
      if (self.departmentMap && data[i].uuId in self.departmentMap) {
        data[i].tag = self.departmentMap[data[i].uuId].tag;
        data[i].companyColor = self.departmentMap[data[i].uuId].companyColor;
      }
      
      const deptPath = path === '' ? data[i].uuId : `${path}, ${data[i].uuId}`;
      data[i].path = deptPath;
      rows.push(data[i]);
      if (listStaff && typeof data[i].staffList !== 'undefined') {
        data[i].staffList.sort(sortFunc);
        for (let j = 0; j < data[i].staffList.length; j++) {
          const staffPath = `${deptPath}, ${data[i].staffList[j].uuId}`;
          rows.push({
            name: data[i].staffList[j].name,
            uuId: data[i].staffList[j].uuId,
            position: data[i].staffList[j].position,
            identifier: data[i].staffList[j].identifier,
            status: data[i].staffList[j].status,
            path: staffPath,
            staff: true,
            genericStaff: data[i].staffList[j].genericStaff,
            staffFirstName: data[i].staffList[j].firstName, //use different property name to avoid a conflict in detailLinkLabel()
            staffLastName: data[i].staffList[j].lastName, //use different property name to avoid a conflict in detailLinkLabel()
          });
        }
      }
      
      if (typeof data[i].departmentList !== 'undefined') {
        rows.unshift(...extractRowsFromData(self, deptPath, data[i].departmentList, listStaff));
      }
      
      if (typeof data[i].companyList !== 'undefined') {
        rows.unshift(...extractRowsFromData(self, deptPath, data[i].companyList, listStaff));
      }
    }
  }
  return rows;
}

export function DepartmentBeforeMount(self, data, title) {
  data.gridOptions = {
    // groupDefaultExpanded: -1,   //Commented out due to 'groupDefaultExpanded' is not supported on the Server-side Row Model
    suppressRowClickSelection: true,

    onColumnVisible: function (event) {
      event.api.sizeColumnsToFit();
    },
    onSelectionChanged: function (event) {
      data.selected.splice(0, data.selected.length, ...(event.api.getSelectedNodes().map(i => i.data)));
      if (event.api.getSelectedNodes().length !== 0) {
        data.selectedParent = event.api.getSelectedNodes()[0].parent.data;
      }
      else {
        data.selectedParent = null;
      }

      data.disableEdit = data.disableDuplicate = data.selected.length != 1;
      if (typeof self.checkDisableButtons !== "undefined") {
        self.checkDisableButtons(data);
      }
      else {
        data.disableOk = data.disableDelete = data.selected.length < 1;
      }
    },      
    getDataPath: function(data) {
      if (typeof data !== 'undefined') {
        const path = data.path.split(', ');
        return path;
      }
      return [];
    },
    onFirstDataRendered: function(/**params */) {
      setTimeout(() => {
        self.processNodes();
      }, 100);
    },
    getRowId: params => {
      if (typeof params.data.path !== 'undefined') {
        return params.data.path;
      }
      return params.data.uuId;
    }
  };
  const colDefs = [
    {
      headerName: self.$t('department.field.headCount'),
      field: 'staffList.length',
      cellRenderer: 'staffCountCellRenderer',
      cellRendererParams: {
        realStaff: true
      }, 
      hide: self.hideStaffCount,
      minWidth: 100
    },
    {
      headerName: self.$t('field.tag'),
      field: 'tag',
      minWidth: 100,
      hide: true
    },
    {
      headerName: self.$t('field.color'),
      field: 'color',
      cellRenderer: 'colorCellRenderer',
      minWidth: 100,
      hide: true
    },
    {
      headerName: self.$t('field.identifier_full'),
      field: 'identifier',
      minWidth: 100,
      hide: true
    },
    {
      field: 'path',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true
    }
  ];
  const linkedEntities = [
    { selector: 'DEPARTMENT.TAG', field: 'tag', properties: ['name'] }
  ]
  self.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
  .finally(() => {
    filterOutViewDenyProperties(colDefs, 'DEPARTMENT', linkedEntities);
    colDefs.sort(columnDefSortFunc);
    data.columnDefs = colDefs;
  });

  data.defaultColDef = {
    sortable: false,
    resizable: true,
    lockPinned: true,
    minWidth: 400,
    hide: true,
    menuTabs: ['columnsMenuTab']
  };
  data.autoGroupColumnDef = {
    headerName: title,
    field: 'uuId',
    pinned: 'left',
    cellRendererParams: {
      checkbox: (params) => {
        const type = params.data ? params.data.type : null;
        return !((self.listStaff || !self.selectCompany) && type);
      },
      suppressCount: true,
      label: 'name',
      decorateCompany: true,
      innerRenderer: 'detailLinkCellRenderer'
    },
    cellStyle: (params) => {
      const companyColor = params.data.companyColor &&
                           params.data.companyColor.length > 0 &&
                           params.data.companyColor[0] !== '' ? 
                           params.data.companyColor[0] : 
                           self.getParentCompanyColor(params);
          
      if (params.data &&
          params.data.color &&
          self.coloring.department) {
          return { background: params.node.data.color, color: invertColor(params.node.data.color, true) };
      }
      else if (params.data &&
          companyColor &&
          self.coloring.company) {
          return { background: companyColor, color: invertColor(companyColor, true) };
      }
    }
  };
  data.context = {
    componentParent: self
  };
}

export function pruneTree(self, data, searchFilter) {
  if (searchFilter === '') {
    return data;
  }

  for (var i = data.length - 1; i >= 0; i--) {
    // copy tags
    if (self.departmentMap && data[i].uuId in self.departmentMap) {
      data[i].tag = self.departmentMap[data[i].uuId].tag;
      data[i].companyColor = self.departmentMap[data[i].uuId].companyColor;
    }
    
    const deptMatch = (
      (typeof data[i].name !== 'undefined' && data[i].name.toLowerCase().includes(searchFilter.toLowerCase())) ||
      (data[i].identifier && data[i].identifier.toLowerCase().includes(searchFilter.toLowerCase())) ||
      (typeof data[i].tag !== 'undefined' && !Array.isArray(data[i].tag) && data[i].tag.toLowerCase().includes(searchFilter.toLowerCase())) ||
      (typeof data[i].tag !== 'undefined' && Array.isArray(data[i].tag) && data[i].tag.join(',').toLowerCase().includes(searchFilter.toLowerCase()))
    );

    if (typeof data[i].departmentList !== 'undefined') {
      data[i].departmentList = pruneTree(self, data[i].departmentList, searchFilter);
    }
    if (typeof data[i].staffList !== 'undefined' && !deptMatch) {
      if (self.matchStaff) {
        data[i].staffList = pruneTree(self, data[i].staffList, searchFilter);
      }
      else {
        delete data[i].staffList;
      }
    }
    if (typeof data[i].companyList !== 'undefined' && !deptMatch) {
      data[i].companyList = pruneTree(self, data[i].companyList, searchFilter);
    }


    if ((typeof data[i].departmentList === 'undefined' || data[i].departmentList.length === 0) &&
      (typeof data[i].staffList === 'undefined' || data[i].staffList.length === 0) &&
      (typeof data[i].companyList === 'undefined' || data[i].companyList.length === 0) &&
      ((typeof data[i].name !== 'undefined' && !data[i].name.toLowerCase().includes(searchFilter.toLowerCase())) ||
        (typeof data[i].firstName !== 'undefined' && !data[i].toLowerCase().includes(searchFilter.toLowerCase()) &&
          typeof data[i].lastName !== 'undefined' && !data[i].lastName.toLowerCase().includes(searchFilter.toLowerCase())))) {
      data.splice(i, 1);
    }
  }
  return data;
}

export function validateEmail(email) {
  const re = /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/; // eslint-disable-line no-control-regex, no-useless-escape
  return re.test(String(email).toLowerCase());
}

export async function getStaffEmailInUse(staffs) {
  let ret = [];
  const emails = [];
  for (const staff of staffs) {
    if (staff.email) {
      emails.push(staff.email.toLowerCase());
    }
  }
  
  if (emails.length !== 0) {

    for (let i = 0; i < emails.length; i+=100) {
      const emailbatch = emails.slice(i, i + 100);
        
      const filter = [
        '_and_', [
            ['STAFF.email', 'within', emailbatch.join('|')]
        ]
      ];
      ret.push(...await staffService.list({ limit: -1, filter: filter }).then(response => {
        return response.data.length > 0 ? response.data : [];
      })
      .catch(e => {
        console.log(e); // eslint-disable-line no-console
        return [];
      }));
    }
  }
  return ret;
}

export async function getTagInUse(tags) {
  let ret = [];
  const names = [];
  for (const tag of tags) {
    if (tag.name) {
      names.push(tag.name.toLowerCase().trim());
    }
  }
  
  if (names.length !== 0) {
    const tagList = await tagService.list({ limit: -1 }).then(response => {
      return response.data.length > 0 ? response.data.map(t => { return t.name }) : [];
    })
    .catch(e => {
      console.log(e); // eslint-disable-line no-console
      return [];
    });
    for (let i = 0; i < names.length; i++) {
      if (tagList.findIndex(t => t.toLowerCase() === names[i].toLowerCase()) !== -1) {
        ret.push({ name: names[i] });
      }
    }
  }
  return ret;
}
