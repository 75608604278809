var linksBuilder = require("../../core/relations/links_builder")(gantt);
var helpers = require("../../utils/helpers");

module.exports = function(gantt) {
	var _private = {
		_freeSlack: {},
		_totalSlack: {},
		_slackNeedCalculate: true,
		_linkedTasksById: {},
		_calculateTotalSlack: function() {
			var linksByTaskId = this._linkedTasksById;

			helpers.forEach(linksBuilder.getLinkedTasks(), function(entry) {
				var task = gantt.getTask(entry.target);
				var slack = gantt.getFreeSlack(task);

				if (!linksByTaskId[entry.source]) {
					linksByTaskId[entry.source] = {
						minSlack: {
							target: entry.target,
							slack: slack
						},
						linked: []
					};
				} else {
					if (slack < linksByTaskId[entry.source].minSlack.slack) {
						linksByTaskId[entry.source].minSlack = {
							target: entry.target,
							slack: slack
						};
					}
				}
				linksByTaskId[entry.source].linked.push({
					target: entry.target,
					slack: slack
				});
			});

			var totalSlackByTaskId = {};

			gantt.eachTask(function(entry) {
				if (gantt.isSummaryTask(entry)) {
					return;
				}

				if (totalSlackByTaskId[entry.id] === undefined) {
					totalSlackByTaskId[entry.id] = 0;
				}
				totalSlackByTaskId[entry.id] += _private._chainSlackCount(entry);
			});

			gantt._slacksChanged = false;
			this._slackNeedCalculate = false;
			this._totalSlack = totalSlackByTaskId;

			return totalSlackByTaskId;
		},
		_chainSlackCount: function(entry, additional) {
			additional = additional || 0;
			switch (true) {
			case (!this._linkedTasksById[entry.id]):
				return gantt.calculateDuration(entry.end_date, gantt.getSubtaskDates().end_date) + additional;
			case (this._linkedTasksById[entry.id].linked.length === 1):
				return this._chainSlackCount(gantt.getTask(this._linkedTasksById[entry.id].linked[0].target), gantt.getFreeSlack(entry)) + additional;
			case (this._linkedTasksById[entry.id].linked.length > 1):
				var targetWithMinimalSlack = this._getTargetWithMinimalSlack(this._linkedTasksById[entry.id].linked);

				return this._chainSlackCount(gantt.getTask(targetWithMinimalSlack.target), gantt.getFreeSlack(entry)) + additional;
			}
		},

		_getTargetWithMinimalSlack: function(linked) {
			var result;
			helpers.forEach(linked, function(entry) {
				if (result === undefined || entry.slack < result.slack) {
					result = entry;
				}
			});
			return result;
		},

		_calculateTaskSlack: function(task) {
			var slack;

			if (task.$source && task.$source.length) {
				slack = this._calculateRelationSlack(task);
			} else {
				slack = this._calculateHierarchySlack(task);
			}

			return slack;
		},

		_calculateRelationSlack: function(task) {
			var minSlack = 0,
				slack,
				links = task.$source;

			for (var i = 0; i < links.length; i++) {
				slack = this._calculateLinkSlack(links[i]);
				if (minSlack > slack || i === 0) {
					minSlack = slack;
				}
			}
			return minSlack;
		},

		_calculateLinkSlack: function(linkId) {
			var link = gantt.getLink(linkId);
			var slack = 0;
			if (gantt.isTaskExists(link.source) && gantt.isTaskExists(link.target)) {
				slack = gantt.getSlack(gantt.getTask(link.source), gantt.getTask(link.target));
			}
			return slack;
		},

		_calculateHierarchySlack: function(task) {
			var slack = 0;
			var from;
			var to = gantt.getSubtaskDates().end_date;
			if (gantt.isTaskExists(task.parent)) {
				from = gantt.getSubtaskDates(task.id).end_date || task.end_date;
			} else {
				from = task.end_date;
			}
			slack = Math.max(gantt.calculateDuration(from, to), 0);
			return slack;
		},

		_resetTotalSlackCache: function() {
			this._slackNeedCalculate = true;
		},

		_shouldCalculateTotalSlack: function() {
			return this._slackNeedCalculate;
		},

		getFreeSlack: function(task) {
			if (!gantt.isTaskExists(task.id)) {
				return 0;
			}
			if (!this._freeSlack[task.id]) {
				if (gantt.isSummaryTask(task)) {
					this._freeSlack[task.id] = undefined;
				} else {
					this._freeSlack[task.id] =  this._calculateTaskSlack(task);
				}
			}
			return this._freeSlack[task.id];
		},

		getTotalSlack: function(task) {
			if (this._shouldCalculateTotalSlack()) {
				this._calculateTotalSlack();
			}
			if (task === undefined) {
				return this._totalSlack;
			}
			if (task.id !== undefined) {
				return this._totalSlack[task.id];
			}
			return this._totalSlack[task] || 0;
		},

		dropCachedFreeSlack: function() {
			this._linkedTasksById = {};
			this._freeSlack =  {};
			this._resetTotalSlackCache();
		},

		init: function(){
			function slackHandler(){
				_private.dropCachedFreeSlack();
			}

			gantt.attachEvent("onAfterLinkAdd", slackHandler);
			gantt.attachEvent("onTaskIdChange", slackHandler);
			gantt.attachEvent("onAfterLinkUpdate", slackHandler);
			gantt.attachEvent("onAfterLinkDelete", slackHandler);
			gantt.attachEvent("onAfterTaskAdd", slackHandler);
			gantt.attachEvent("onAfterTaskUpdate", slackHandler);
			gantt.attachEvent("onAfterTaskDelete", slackHandler);
			gantt.attachEvent("onRowDragEnd", slackHandler);
			gantt.attachEvent("onAfterTaskMove", slackHandler);
		}
	};

	return _private;
};