

<template>
  <div class="header-text">{{ label }} <font-awesome-icon v-if="partial" :id="`HEADER_${params.column.colId}`" :icon="['far', 'circle-info']"/>
    <b-popover
      v-if="partial"
      :target="`HEADER_${params.column.colId}`"
      placement="top"
      triggers="hover"
      :content="$t('staff.partial')">
    </b-popover>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'DateHeaderComponent',
  data() {
    return {
      value: null
    }
  },
  beforeMount() {
    this.value = this.params? this.params.value : null;
  },
  computed: {

    label() {
      return this.params.displayName;
    },
    partial() {
      if (!this.params.displayName ||
          this.params.context.componentParent.span === 'Daily') {
        return false;
      }
      
      if (this.params.nopartial) {
        return false;
      }
      
      var parsedDate = this.params.column.colId.substr(2);
      if (this.params.context.componentParent.span === 'Monthly') {
        const matches = parsedDate.match(/(.+?)\/(.+?$)/);
        if (matches !== null) {
          const month = matches[1];
          const year = matches[2];
          parsedDate = `${year}-${month}-01`; 
        }
      }
      
      const startDate = new Date(this.params.context.componentParent.startDate);
      const endDate = new Date(this.params.context.componentParent.endDate);
      const thisDate = new Date(parsedDate);
      if (this.params.context.componentParent.span === 'Weekly') {
        endDate.setDate(endDate.getDate()-6);
      }
      return thisDate < startDate || thisDate > endDate || 
             (this.params.context.componentParent.span === 'Monthly' &&
             thisDate.getFullYear() === endDate.getFullYear() && thisDate.getMonth() === endDate.getMonth() &&
             !this.isLastDay(endDate)) || 
             (this.params.context.componentParent.span === 'Yearly' &&
             thisDate.getFullYear() === endDate.getFullYear() && (11 !== endDate.getMonth() ||
             !this.isLastDay(endDate)));
    }
  },
  methods: {
    isLastDay(dt) {
        var test = new Date(dt.getTime()),
            month = test.getMonth();
    
        test.setDate(test.getDate() + 1);
        return test.getMonth() !== month;
    }
  }
})
</script>

<style lang="scss" scoped>
.normal {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  color: var(--white);
  margin: auto;
}

.overallocated {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  color: var(--white);
  margin: auto;
}

.header-text {
  text-align: center;
  width: 100%;
}
</style>