var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.hasError
        ? [
            _c("font-awesome-icon", {
              staticClass: "mb-2",
              staticStyle: {
                "vertical-align": "bottom",
                color: "red",
                "font-size": "1.125em",
              },
              attrs: {
                id: `CELL_ERROR_${_vm.params.eGridCell.getAttribute(
                  "comp-id"
                )}`,
                icon: ["fas", "triangle-exclamation"],
              },
            }),
            _c("b-popover", {
              attrs: {
                target: `CELL_ERROR_${_vm.params.eGridCell.getAttribute(
                  "comp-id"
                )}`,
                placement: "top",
                triggers: "hover",
                content: _vm.hasErrorContent,
              },
            }),
          ]
        : _vm._e(),
      _c("span", { class: { "grid-cell-summary": _vm.isSummaryTask } }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }