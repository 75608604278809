<template>
  <div class="edit-cell">
    <span>{{ label }}</span>
    <DateTimeModal :show.sync="show" :dateTime="value" :labelDate="labelDate" :labelTime="labelTime" :editMode="editorMode" :isOptional="optional" @ok="ok"/>
  </div>
</template>

<script>
import Vue from 'vue';
import * as moment from 'moment-timezone';
import { objectClone } from '@/helpers';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);

export default Vue.extend({
  name: 'DateTimeEditor',
  components: {
    DateTimeModal: () => import('@/components/modal/DateTimeModal')
  },
  data() {
    return {
      show: true,
      value: null,
      labelDate: null,
      labelTime: null,
      editorMode: 0,
      compactMode: false,
      treatAsNull: null
    };
  },
  watch: {
    show(newValue) {
      if(!newValue) {
        this.closePicker();
      }
    }
  },
  methods: {
    /** Reserved methods START */
    getValue() {
      if (this.compactMode) {
        const orgValue = this.params.value.single;
        if ((orgValue == null && this.value == null) || orgValue == this.value || 
            (this.value == null && this.treatAsNull != null && this.isNullKeywordsMatched(orgValue, this.treatAsNull))) {
          return this.params.value;
        }
        const updatedValue = objectClone(this.params.value);
        updatedValue.single = this.getNullWhenNullKeywordsMatched(this.value, this.treatAsNull);
        return updatedValue;
      } else {
        if (this.value == null && this.treatAsNull != null && this.isNullKeywordsMatched(this.params.value, this.treatAsNull)) {
          return this.params.value;
        }
        return this.getNullWhenNullKeywordsMatched(this.value, this.treatAsNull);
      }
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },
    /** Reserved methods END */

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    ok({ value: newValue }) {
      if (this.params.validation) {
        if (this.params.validation(this.params, newValue)) {
          return;
        }
      }
      
      if(this.value != newValue) {
        this.value = newValue;
      }
      this.closePicker();
    },
    prepareValue() {
      const value = this.params.value;
      if (this.compactMode) {
        const rawValue = value != null && typeof value.single === 'string' && value.single !== '' ? moment.utc(value).valueOf() : value.single;
        this.value = rawValue != null && rawValue != 0 && rawValue != ''
          ? (this.isNullKeywordsMatched(rawValue, this.treatAsNull) ? null : rawValue)
          : null;
      } else {
        const rawValue = typeof value === 'string' && value !== '' ? moment.utc(value).valueOf() : value;
        this.value = rawValue != null && rawValue != 0 && rawValue != ''
          ? (this.isNullKeywordsMatched(rawValue, this.treatAsNull)? null : rawValue)
          : null;
      }
    },
    isNullKeywordsMatched(value, nullKeywords) {
      //nullKeywords can be an array or a string
      return nullKeywords != null
        ? (Array.isArray(nullKeywords) ? nullKeywords.includes(value) : nullKeywords == value)
        : false;
    },
    getNullWhenNullKeywordsMatched(value, nullKeywords) {
      //if nullKeywords is an array and value match any of item in array, always return the 1st item.
      return value == null && nullKeywords != null
        ? (Array.isArray(nullKeywords) ? (nullKeywords.length > 0 ? nullKeywords[0] : value) : nullKeywords)
        : value;
    }
  },
  created() {
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    if (this.params.treatAsNull != null) {
      this.treatAsNull = this.params.treatAsNull
    }
    this.prepareValue();
    if (this.params.editorMode) {
      this.editorMode = this.params.editorMode || 0;
    }
    if (this.params.labelDate) {
      this.labelDate = this.params.labelDate || null;
    }
    if (this.params.labelTime) {
      this.labelTime = this.params.labelTime || null;
    }
  },
  mounted() {

  },
  computed: {
    label() {
      const displayMode = this.params.displayMode != null? this.params.displayMode : 'datetime';
      let rawValue = this.value;
      if (rawValue == null || rawValue == 0 || rawValue == '' || rawValue == 9223372036854776000) {
        return '';
      }
      if (displayMode == 'date') {
        return moment(rawValue).format('L');
      } else if (displayMode == 'time') {
        return moment(rawValue).format('hh:mm A');
      } else {
        return moment(rawValue).format('L hh:mm A');
      }
    },
    optional() {
      return !!this.params.optional;
    }
  }
})
</script>
