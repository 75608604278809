<template>
  <div>
    <template v-if="hasError">
      <font-awesome-icon :id="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`" class="mb-2" style="vertical-align: bottom;color: red;font-size: 1.125em;" :icon="['fas', 'triangle-exclamation']"/>
      <b-popover
        :target="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`"
        placement="top"
        triggers="hover"
        :content="hasErrorContent">
      </b-popover>
    </template>
    <span :class="{ 'grid-cell-summary': isSummaryTask }">{{ label }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'TaskResourceCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    const list = Array.isArray(this.params.value)? this.params.value: [];
    const resources = [];
    for(const i of list) {
      resources.push(i.unit != null && i.unit != 1? `${i.name} (${i.unit})`: i.name);
    }
    this.value = resources.join(', ');
  },
  computed: {
    hasErrorContent() {
      const msg = this.params.data[this.params.errorMessage] ? this.params.data[this.params.errorMessage] : typeof this.hasError === 'string' ? this.hasError : this.params.message;
      if (this.$te(msg)) {
        return this.$t(msg);
      }
      return msg;
    },
    label() {
      return this.value && this.value.length > 0? this.value : '';
    },
    isSummaryTask() {
      const type = this.params && this.params.node && this.params.node.data && this.params.node.data.taskType ? this.params.node.data.taskType : null;
      return type !== null && (type === 'Project');
    },
    hasError() {
      if (this.params.hasError) {
        const result = this.params.hasError(this.params);
        if (typeof result === 'string') {
           return result;
        }
        else if (!result) {
          return result;
        }
        return this.params.message;
      }
      
      return false;
    }
  }
})
</script>
