<template>
    <Dashboard :projectId="projectId"/>
</template>



<script>

import Dashboard from '@/components/Dashboard/Dashboard';

export default {
  name: "ProjectSummary",
  components: {
    Dashboard
  },
  data() {
    return {
      
    }
  },
  computed: {
  },
  created() {
    // let user = this.$store.state.authentication.user;
    this.projectId = this.$route.params.id;
  },
  methods: {

  },
}
</script>
