var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-cell" },
    [
      _c("span", [_vm._v(_vm._s(_vm.label))]),
      _vm.selectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.selectorShow,
              entityService: _vm.templateTaskUtil,
              preselected: _vm.selectedIds,
              entity: "TEMPLATE__PROJECT",
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.selectorShow = $event
              },
              ok: _vm.selectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }