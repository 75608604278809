import { viewProfileService } from '@/services';
import store from '@/store';

export function updateViewProfile(settings, userId) {
  viewProfileService.update([settings], userId)
  .catch((e) => {
    console.log(e); // eslint-disable-line no-console
  });
}

export async function createViewProfile(settings, userId) {
  const uuId = await viewProfileService.create([settings],
                    userId).then((response) => {  
    const data = response.data[response.data.jobCase];
    return settings.uuId = data[0].uuId;
  })
  .catch((e) => {
    console.log(e); // eslint-disable-line no-console
  });
  
  settings.uuId = uuId;
  return settings;
}

export async function loadViewProfile(settings, userId) {
  const profile = await store.dispatch('data/viewProfileList', userId).then((value) => {  
    const profileData = value;
    if (profileData.length === 0) {
      return null;
    }
    else {
      return profileData[0];
    }
  })
  .catch((e) => {
    console.log(e); // eslint-disable-line no-console
    return null;
  });
  
  if (profile === null) {
    return createViewProfile(settings, userId);
  }
  return profile;
}