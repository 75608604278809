import Vue from 'vue';
import vueJquery from 'vue-jquery';
import { BootstrapVue } from 'bootstrap-vue';

import App from '@/App.vue';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
import { httpAjax, byteFormat } from '@/helpers';

import VueSanitize from 'vue-sanitize';
const defaultOptions = {
  allowedTags: ['strong']
};
Vue.use(VueSanitize, defaultOptions);

import {LicenseManager} from "ag-grid-enterprise";
// LicenseManager.setLicenseKey('CompanyName=CONCEIVA PTY LTD,LicensedGroup=Conceiva Pty. Ltd.,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-038333,SupportServicesEnd=19_April_2024_[v2]_MTcxMzQ4MTIwMDAwMA==1cfabe8dd714a8e16429df5a223fb73a');
LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-055281}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{CONCEIVA_PTY_LTD}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{19_April_2025}____[v3]_[01]_MTc0NTAxNzIwMDAwMA==992951c11bb5c95c288f906a132348e7');

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

Vue.config.productionTip = false;

import validationConfig from '@/config/validate';
validationConfig();

import '@/config/fontawesome';

Vue.use(vueJquery);
Vue.use(BootstrapVue);
Vue.filter('byteFormat', byteFormat);


Vue.prototype.$http = httpAjax;

const popoverListeners = {};

// TODO: move this to mixins directory. Had trouble with webpack, so putting here for now.
var permissions = Vue.mixin({
  /**
   * Use these methods globally to determine if the current user has a permission. You can
   * use these within templates or in component methods. You have 3 options:
   * - hasPermission('ENTITY__ACTION'): directly specify the full permission name
   * - canList('ENTITY'): shorthand for ENTITY__VIEW
   * - canList(): shorthand for ENTITY__VIEW, but the name of the entity is automatically
   *              retrieved from the calling component data's "permissionName" attribute.
   *              Perfer this option for templates inside entity-specific components (like modals)
   * Shorthand forms are available for LIST, VIEW, ADD, EDIT, DELETE
   */
  methods: {
    hasPermission(name, properties=[], id=null) {
      return this.hasPermission2(name, properties, id).val;
    },
    hasPermission2(name, properties=[], id=null) {
      if (store.state.authentication.user.permissionList) {
        const found = store.state.authentication.user.permissionList.find(p => p.name == name);
        if (found != null) {
          if (found.permissionLink &&
              Array.isArray(found.permissionLink.dataRules) &&
              found.permissionLink.dataRules.length !== 0) {
            
            const dataRules = found.permissionLink.dataRules;
            if (id && dataRules && dataRules.length !== 0 &&
                ((dataRules.find(s => s.includes('within')) && !dataRules.find(s => s.includes(id))) ||
                 (dataRules.find(s => s.includes('without')) && dataRules.find(s => s.includes(id))) ||
                 (dataRules.find(s => s.includes(' eq ')) && !dataRules.find(s => s.includes(id))) ||
                 (dataRules.find(s => s.includes(' neq ')) && dataRules.find(s => s.includes(id))))) {
              // return false;
              return { val: false };
            }
          }
          
          if (!Array.isArray(properties) || properties.length == 0
              || found.permissionLink == null 
              || !Array.isArray(found.permissionLink.denyRules) || found.permissionLink.denyRules.length == 0) {
            // also check the access policy to see if there is an inherited rule
            const foundPolicy = store.state.authentication.user.accessPolicyList[0].permissionList.find(p => p.name == name);
            if (foundPolicy != null) {
              if (!Array.isArray(properties) || properties.length == 0
                  || foundPolicy.permissionLink == null 
                  || !Array.isArray(foundPolicy.permissionLink.denyRules) || foundPolicy.permissionLink.denyRules.length == 0) {
                // return true;
                return { val: true };
              }
              const denyRules = foundPolicy.permissionLink.denyRules;
              let isDenied = false;
              
              let restrictedProp = null;
              for (const prop of properties) {
                if (denyRules.includes(prop)) {
                  isDenied = true;
                  restrictedProp = prop;
                  break;
                }
              }
              // return !isDenied;
              return { val: !isDenied, restrictedProp };
            }
            // return true;
            return { val: true };
          }
          const denyRules = found.permissionLink.denyRules;

          let isDenied = false;
          let restrictedProp = null;
          for (const prop of properties) {
            if (denyRules.includes(prop)) {
              isDenied = true;
              restrictedProp = prop
              break;
            }
          }
          return { val: !isDenied, restrictedProp };
        }
      }
      return { val: false };
    },
    canList(name = null, properties=[]) {
      name = name || this._getPermissionName();
      if (name != null && name.startsWith('ADMIN__')) {
        return this.hasPermission(name, properties);
      }
      return this.hasPermission(name + "__VIEW", properties);
    },
    canView(name = null, properties=[]) {
      name = name || this._getPermissionName();
      return this.hasPermission(name + "__VIEW", properties);
    },
    canAdd(name = null, properties=[]) {
      if ((store.state.epoch.value !== null && name !== 'LABEL') ||
          (store.state.sandbox.value && !store.state.sandbox.canEdit)) {
        return false; // cannot edit past data
      }
      name = name || this._getPermissionName();
      return this.hasPermission(name + "__ADD", properties);
    },
    canEdit(name = null, properties=[], id=null) {
      if ((store.state.epoch.value !== null && name !== 'LABEL') ||
          (store.state.sandbox.value && !store.state.sandbox.canEdit)) {
        return false; // cannot edit past data
      }
      name = name || this._getPermissionName();
      return this.hasPermission(name + "__EDIT", properties, id);
    },
    canDelete(name = null) {
      if ((store.state.epoch.value !== null && name !== 'LABEL') ||
          (store.state.sandbox.value && !store.state.sandbox.canEdit)) {
        return false; // cannot edit past data
      }
      name = name || this._getPermissionName();
      return this.hasPermission(name + "__REMOVE");
    },
    canSyncLdap(name = null) {
      name = name || this._getPermissionName();
      return this.hasPermission(name + "__LDAP_SYNC");
    },
    canView2(name = null, properties=[]) {
      name = name || this._getPermissionName();
      return this.hasPermission2(name + "__VIEW", properties);
    },
    canAdd2(name = null, properties=[]) {
      name = name || this._getPermissionName();
      return this.hasPermission2(name + "__ADD", properties);
    },
    canEdit2(name = null, properties=[], id=null) {
      name = name || this._getPermissionName();
      return this.hasPermission2(name + "__EDIT", properties, id);
    },
    _getPermissionName() {
      const name = this.permissionName;
      if (!name) {
        console.error("Tried shorthand permission check without name. Hint: set permissionName data attribute on calling component."); // eslint-disable-line no-console
      }
      return name;
    }
  }
});


window.App = new Vue({
  router,
  i18n,
  store,
  mixins: [permissions],
  render: h => h(App)
}).$mount('#app');

// Workaround for tooltips showing and staying on top when modals are opened on mobile
window.App.$root.$on('bv::modal::show', (bvEvent, modalId) => {
  setTimeout(() => {
    window.App.$root.$emit('bv::hide::popover');
  }, 50);
})

// TTL for popovers
window.App.$root.$on('bv::popover::shown', (bvEvent) => {
  popoverListeners[bvEvent.componentId] = setTimeout(() => {
    window.App.$root.$emit('bv::hide::popover', bvEvent.componentId);
  }, bvEvent.target.id.startsWith('timeline') ? 4000 : 2000);
})

// TTL for popovers
window.App.$root.$on('bv::popover::hidden', (bvEvent) => {
  clearTimeout(popoverListeners[bvEvent.componentId]);
})

// This is for buttons that don't spawn modals. We use this event instead of
// popover::show because we still want the popover to show on long-tap, which
// ::show removes as well.
window.App.$root.$on('bv::collapse::sync::state', (bvEvent, modalId) => {
  setTimeout(() => {
    window.App.$root.$emit('bv::hide::popover');
  }, 50);
})

/**
 * Make only the highest modal backdrop have an opacity. This lets us
 * have many nested modals without the background turning black.
 * 
 * Modals are added to the body, but only after the container is added.
 * Thus, we have to observe the body element *and* its subtree to detect
 * the addition or removal of a backdrop. This function runs *a lot*, so
 * we keep it as efficient as possible. Ignore anything that:
 * 1 - Is not a change from a modal add/remove
 * 2 - Does not modify the number of backdrops from last time we checked
 */  
var backdropCount = 0;
var whenChange = new MutationObserver(function (mutations) {
  let isModal = false;
  for (var i = 0; i < mutations.length; i++) {
    if (mutations[i].target.classList.contains('modal-dialog')) {
      isModal = true;
      break;
    }
  }
  let backdrops = document.getElementsByClassName('modal-backdrop');
  if (backdropCount == backdrops.length) {
    return;
  }
  
  let n = backdrops.length;
  backdropCount = n;
  for (i = 0; i < n; i++) {
    let b = backdrops[i];
    b.style.opacity = 0;
    if (i == n-1) {
      b.style.opacity = .5;
    }
  }
});
whenChange.observe(document.querySelector('body'), {childList: true, subtree: true});
