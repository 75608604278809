
import { httpAjax, urlParams } from '@/helpers';

export const templateTaskService = {
  create,
  list,
  get,
  update,
  remove,
  optionConstraint,
  optionPriority,
  optionType,
  optionLinkType,
  predecessorList,
  listTree,
  parent,
  updateParent,
  updateParentnOrder,
  listSpecificTaskTree,
  specificPredecessor
};

/**
 * Create a new template task 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', name: 'Paramount' ... }]
 */
function create(data, projectId, orderAt=null, orderAs=true) {
  let url = `/api/template/task/add?holder=${projectId}`;
  if(orderAt != null) {
    url = `${url}&order-at=${orderAt}&order-as=${orderAs}`;
  }
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of template task info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'Paramount', ksort='name', order: 'incr' }}
 */
function list(params) {
  const fields = {
    uuId: 'PROJECT_TEMPLATE.TASK_TEMPLATE.uuId', 
    name: 'PROJECT_TEMPLATE.TASK_TEMPLATE.name',
    startTime: 'PROJECT_TEMPLATE.TASK_TEMPLATE.startTime',
    endTime: 'PROJECT_TEMPLATE.TASK_TEMPLATE.closeTime',
    duration: 'PROJECT_TEMPLATE.TASK_TEMPLATE.duration',
    progress: 'PROJECT_TEMPLATE.TASK_TEMPLATE.progress',
    priority: 'PROJECT_TEMPLATE.TASK_TEMPLATE.priority',
    stage: 'PROJECT_TEMPLATE.TASK_TEMPLATE.stage',
    complexity: 'PROJECT_TEMPLATE.TASK_TEMPLATE.complexity',
    constraintType: 'PROJECT_TEMPLATE.TASK_TEMPLATE.constraintType',
    constraintTime: 'PROJECT_TEMPLATE.TASK_TEMPLATE.constraintTime',
    taskType: 'PROJECT_TEMPLATE.TASK_TEMPLATE.taskType',
    projectId: 'PROJECT_TEMPLATE.uuId',
    projectName: 'PROJECT_TEMPLATE.name',
    parentTaskId: 'PROJECT_TEMPLATE.TASK_TEMPLATE.PARENT_TASK_TEMPLATE.uuId',
    parentTaskName: 'PROJECT_TEMPLATE.TASK_TEMPLATE.PARENT_TASK_TEMPLATE.name',
    color: 'PROJECT_TEMPLATE.color',
    tag: "PROJECT_TEMPLATE.TAG.name"
  }
  

  let data = {
    'name'  : 'Task Template List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if(params.filter && params.filter.length > 0) {
	if (typeof params.filter === 'string') {
      data['filter'] = [
        '_or_', [
          ['PROJECT_TEMPLATE.TASK_TEMPLATE.name', 'has', params.filter],
          ['PROJECT_TEMPLATE.TAG.name', 'has', params.filter]
        ]
      ]
	}
	else {
		data['filter'] = params.filter;
	}
  }

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i], '', 'lowerCase']);
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order, '', 'lowerCase']);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

/**
 * Retrieving a list of template task details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
 function get(data, links=null) {
  const url = urlParams('/api/template/task/get', links, true);
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Update template task details 
 * by passing  necessary information
 * @param Array data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', name: 'Paramount' ... }]
 */
function update(data) {
  const url = `/api/template/task/update`;
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of tasks
 * by passing in an array list of uuIds
 * @param Array data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = `/api/template/task/delete`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}

function optionConstraint() {
  const url = '/api/project/enum?collection=opts_constraint'
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config)
  .then(response => {
    let constraints = response && response.data? response.data[response.data.jobCase].opts_constraint:[];
    constraints.sort((a, b) => {
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    });
    return constraints;
  });
}

function optionPriority() {
  const url = '/api/project/enum?collection=opts_priority'
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config)
  .then(response => {
    return response && response.data? response.data[response.data.jobCase].opts_priority:[];
  });
}

function optionType() {
  const url = '/api/project/enum?collection=opts_type'
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config)
  .then(response => {
    return response && response.data? response.data[response.data.jobCase].opts_type:[];
  });
}

function optionLinkType() {
  const url = '/api/project/enum?collection=opts_link'
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config)
  .then(response => {
    return response && response.data? response.data[response.data.jobCase].opts_link:[];
  });
}

/**
 * Retrieving a concise list of template task predecessor info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'Paramount', ksort='name', order: 'incr' }}
 */
function predecessorList(params, uuId) {
  const fields = {
    tUuId: ['TASK_TEMPLATE.uuId'], 
    tName: ['TASK_TEMPLATE.name'],
    type:  ['TASK_TEMPLATE.TASK_TEMPLATE-PREDECESSOR_TASK_TEMPLATE.type'],
    lag:   ['TASK_TEMPLATE.TASK_TEMPLATE-PREDECESSOR_TASK_TEMPLATE.lag', 0, 'days'],
    name:  ['TASK_TEMPLATE.PREDECESSOR_TASK_TEMPLATE.name'],
    uuId:  ['TASK_TEMPLATE.PREDECESSOR_TASK_TEMPLATE.uuId']
  }
  

  let data = {
    'name'  : 'Task Template Predecessor List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'select': Object.keys(fields).map(i => fields[i])
  }

  data['holder'] = uuId || "";

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i]]);
      }
    } else {
      const sortKey = params.ksort === 'uuId'? 'name': params.ksort;
      data['sort'].push([fields[sortKey], params.order]);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

function listTree(params, projectId, showFullDetails=false) {
  const fields = {
    uuId:  ['PROJECT_TEMPLATE.TASK_TEMPLATE.uuId'],
    name:  ['PROJECT_TEMPLATE.TASK_TEMPLATE.name'],
    pUuId: ['PROJECT_TEMPLATE.TASK_TEMPLATE.PARENT_TASK_TEMPLATE.uuId'],
    pName: ['PROJECT_TEMPLATE.TASK_TEMPLATE.PARENT_TASK_TEMPLATE.name'],
    startTime: ['PROJECT_TEMPLATE.TASK_TEMPLATE.startTime'],
    closeTime: ['PROJECT_TEMPLATE.TASK_TEMPLATE.closeTime'],
    avatarRef: ['PROJECT_TEMPLATE.TASK_TEMPLATE.avatarRef'],
    taskType: ['PROJECT_TEMPLATE.TASK_TEMPLATE.taskType']
  }
  if(showFullDetails) {
    fields['lockDuration']        = ['PROJECT_TEMPLATE.TASK_TEMPLATE.lockDuration'];
    fields['duration']            = ['PROJECT_TEMPLATE.TASK_TEMPLATE.duration', 0, 'Days'];
    fields['durationAUM']         = ['PROJECT_TEMPLATE.TASK_TEMPLATE.durationAUM'];
    fields['progress']            = ['PROJECT_TEMPLATE.TASK_TEMPLATE.progress'];
    fields['priority']            = ['PROJECT_TEMPLATE.TASK_TEMPLATE.priority'];
    fields['complexity']          = ['PROJECT_TEMPLATE.TASK_TEMPLATE.complexity'];
    fields['description']         = ['PROJECT_TEMPLATE.TASK_TEMPLATE.description'],
    fields['constraintType']      = ['PROJECT_TEMPLATE.TASK_TEMPLATE.constraintType'];
    fields['constraintTime']      = ['PROJECT_TEMPLATE.TASK_TEMPLATE.constraintTime'];
    fields['autoScheduling']      = ['PROJECT_TEMPLATE.TASK_TEMPLATE.autoScheduling'];
    fields['estimatedCost']     = ['PROJECT_TEMPLATE.TASK_TEMPLATE.estimatedCost'];
    fields['estimatedCostNet']  = ['PROJECT_TEMPLATE.TASK_TEMPLATE.estimatedCostNet'];
    fields['estimatedDuration'] = ['PROJECT_TEMPLATE.TASK_TEMPLATE.estimatedDuration', 0, "Days"];

    fields['staffIds']             = ['PROJECT_TEMPLATE.TASK_TEMPLATE.STAFF.uuId'];
    fields['staffFirstNames']      = ['PROJECT_TEMPLATE.TASK_TEMPLATE.STAFF.firstName'];
    fields['staffLastNames']       = ['PROJECT_TEMPLATE.TASK_TEMPLATE.STAFF.lastName'];
    fields['staffUtilizations']    = ['PROJECT_TEMPLATE.TASK_TEMPLATE.TASK_TEMPLATE-STAFF.utilization'];
    fields['skillIds']             = ['PROJECT_TEMPLATE.TASK_TEMPLATE.SKILL.uuId'];
    fields['skillNames']           = ['PROJECT_TEMPLATE.TASK_TEMPLATE.SKILL.name'];
    fields['skillLevels']          = ['PROJECT_TEMPLATE.TASK_TEMPLATE.TASK_TEMPLATE-SKILL.level'];
    fields['resourceIds']          = ['PROJECT_TEMPLATE.TASK_TEMPLATE.RESOURCE.uuId'];
    fields['resourceNames']        = ['PROJECT_TEMPLATE.TASK_TEMPLATE.RESOURCE.name'];
    fields['resourceUnits']        = ['PROJECT_TEMPLATE.TASK_TEMPLATE.TASK_TEMPLATE-RESOURCE.quantity'];
    fields['taskPath']             = ['PROJECT_TEMPLATE.TASK_TEMPLATE.fullPath'];
    fields['noteId']               = ['PROJECT_TEMPLATE.TASK_TEMPLATE.NOTE.uuId'];
    fields['noteText']             = ['PROJECT_TEMPLATE.TASK_TEMPLATE.NOTE.text'];
    fields['noteModified']         = ['PROJECT_TEMPLATE.TASK_TEMPLATE.NOTE.modified'];
    fields['noteFirstName']        = ['PROJECT_TEMPLATE.TASK_TEMPLATE.NOTE.USER.firstName'];
    fields['noteLastName']         = ['PROJECT_TEMPLATE.TASK_TEMPLATE.NOTE.USER.lastName'];
    fields['noteAuthorRef']        = ['PROJECT_TEMPLATE.TASK_TEMPLATE.NOTE.USER.uuId'];
    fields['rebateUuid']           = ['PROJECT_TEMPLATE.TASK_TEMPLATE.REBATE.uuId'];
    fields['rebateName']           = ['PROJECT_TEMPLATE.TASK_TEMPLATE.REBATE.name'];
    fields['rebateRebate']         = ['PROJECT_TEMPLATE.TASK_TEMPLATE.REBATE.rebate'];
  }

  let data = {
    'name'    : 'Task Template Tree List'
    ,'type'   : 'msql'
    ,'sep_array': '/'
    ,'start'  : params.start
    ,'limit'  : params.limit
    ,'nominate': 'PROJECT_TEMPLATE.TASK_TEMPLATE'
    ,'dedup': false
    ,'select' : Object.keys(fields).map(i => fields[i])
  }

  data['filter'] = [
    ['PROJECT_TEMPLATE.uuId', 'eq', projectId]
  ]

  // Sort TASK in custom order.
  data['sort'] = [
    ['PROJECT_TEMPLATE.TASK_TEMPLATE.order', 'incr']
  ]

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Preprocess Parent details
        result.pUuId = !result.pUuId || result.pUuId.length == 0 || result.pUuId == projectId? 'ROOT': result.pUuId[0];
        result.pName = !result.pName || result.pName.length == 0? '': result.pName[0];

        //Preprocess constraint type and constraint time
        if (result.constraintType && result.taskType != 'Project') {
          result.constraint = {
            type: result.constraintType,
            time: result.constraintTime || 0
          }
        } else {
          result.constraint = { type: null, time: 0 }
        }
        delete result.constraintType;
        delete result.constraintTime;

        //Preprocess Skill details
        result.skills = [];
        if(result.skillIds && result.skillIds.length > 0) {
          for(let i = 0, len = result.skillIds.length; i < len; i++) {
            result.skills.push({
              id: result.skillIds[i],
              name: result.skillNames[i],
              level: result.skillLevels[i]
            })
          }
        }
        delete result.skillIds;
        delete result.skillNames;
        delete result.skillLevels;

        //Preprocess note details
        if (result.noteId) {
          result.notes = [];
          for (let i = 0; i < result.noteId.length; i++) {
            const author = result.noteFirstName.length > i ? `${result.noteFirstName[i]} ${result.noteLastName[i]}` : null;
            result.notes.push({ uuId: result.noteId[i], text: result.noteText[i], modified: result.noteModified[i], author: author, authorRef: result.noteAuthorRef[i]});
          }
          result.notes.sort((a, b) => {
            return b.modified - a.modified;
          });
        }

        // Preprocess rebates
        result.rebates = [];
        if (result.rebateUuid) {
          for (let i = 0; i < result.rebateUuid.length; i++) {
            result.rebates.push({
              uuId: result.rebateUuid[i],
              name: result.rebateName[i],
              rebate: result.rebateRebate[i],
            });
          }
        }

        //Preprocess Staff details
        result.staffs = [];
        if(result.staffIds && result.staffIds.length > 0) {
          for(let i = 0, len = result.staffIds.length; i < len; i++) {
            const firstName = result.staffFirstNames[i];
            const lastName = result.staffLastNames[i];
            result.staffs.push({
              uuId: result.staffIds[i],
              name: `${firstName || ''}${firstName && firstName.length > 0?' ':''}${lastName || ''}`,
              utilization: result.staffUtilizations[i]
            })
          }
        }
        delete result.staffIds;
        delete result.staffNames;
        delete result.staffUtilizations;

        //Preprocess Resources details
        result.resources = [];
        if(result.resourceIds && result.resourceIds.length > 0) {
          for(let i = 0, len = result.resourceIds.length; i < len; i++) {
            result.resources.push({
              id: result.resourceIds[i],
              name: result.resourceNames[i],
              unit: result.resourceUnits[i]
            })
          }
        }
        delete result.resourceIds;
        delete result.resourceNames;
        delete result.resourceUnits;

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

function parent(projectId, taskId) {
  const params = {
    start: 0,
    limit: 1
  }
  const fields = {
    uuId: 'PROJECT_TEMPLATE.TASK_TEMPLATE.uuId', 
    name: 'PROJECT_TEMPLATE.TASK_TEMPLATE.name',
    pUuId: 'PROJECT_TEMPLATE.TASK_TEMPLATE.PARENT_TASK_TEMPLATE.uuId',
    pName: 'PROJECT_TEMPLATE.TASK_TEMPLATE.PARENT_TASK_TEMPLATE.name',
  }

  let data = {
    'name'  : 'Parent Task Template List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  data['filter'] = [
    ['PROJECT_TEMPLATE.uuId', 'eq', projectId],
    ['PROJECT_TEMPLATE.TASK_TEMPLATE.uuId', 'eq', taskId]
  ]

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        
        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

/**
 * 
 * @param {*} data Format: [ { uuId, parent } ]
 * uuId: template task uuId; parent: parent uuId
 */
function updateParent(data) {
  const url = `/api/template/task/update`;
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * 
 * @param {*} data Format: [ { uuId: template task's uuId, parent: parent's uuId } ]
 * Note: Parent prop can be omitted if no re-parent is required.
 * @param {String} orderAt - target Task UuId.
 * @param {Boolean} insertAfter - Insert selected template task after target template task if true; insert before if false.
 */
function updateParentnOrder(data, orderAt, insertAfter=false) {
  const url = '/api/template/task/update';
  const config = {
    headers: getHeaders(),
    params: {
      'order-at': orderAt,
      'order-as': insertAfter
    }
  }
  return httpAjax.put(url, data, config);
}

function listSpecificTaskTree(taskId) {
  const fields = {
    tUuId: ['PROJECT_TEMPLATE.TASK_TEMPLATE.uuId'],
    tName: ['PROJECT_TEMPLATE.TASK_TEMPLATE.name'],
    uuId:  ['PROJECT_TEMPLATE.TASK_TEMPLATE.CHILD_ALL_TASK_TEMPLATE.uuId'],
    name:  ['PROJECT_TEMPLATE.TASK_TEMPLATE.CHILD_ALL_TASK_TEMPLATE.name'],
    pUuId: ['PROJECT_TEMPLATE.TASK_TEMPLATE.CHILD_ALL_TASK_TEMPLATE.PARENT_TASK_TEMPLATE.uuId'],
    pName: ['PROJECT_TEMPLATE.TASK_TEMPLATE.CHILD_ALL_TASK_TEMPLATE.PARENT_TASK_TEMPLATE.name'],
  }
  
  let data = {
    'name'    : 'Specific Task Template Tree List'
    ,'type'   : 'msql'
    ,'start'  : 0
    ,'limit'  : -1
    ,'leftjoin': 'PROJECT_TEMPLATE.TASK_TEMPLATE'
    ,'select' : Object.keys(fields).map(i => fields[i])
  }
  
  data['filter'] = [
    ['PROJECT_TEMPLATE.TASK_TEMPLATE.uuId', 'eq', taskId]
  ]

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Preprocess Child details
        result.uuId = result.uuId && result.uuId.length > 0? result.uuId : null;

        //Preprocess Parent details
        result.pUuId = result.pUuId && result.pUuId.length > 0? result.pUuId : null;
        

        return result;
      })
    }
  });
}

function specificPredecessor(uuId, predecessorId) {
  const params = {
    start: 0,
    limit: 1
  }
  const fields = {
    tUuId: ['TASK_TEMPLATE.uuId'],
    tName: ['TASK_TEMPLATE.name'],
    type:  ['TASK_TEMPLATE.TASK_TEMPLATE-PREDECESSOR_TASK_TEMPLATE.type'],
    lag: ['TASK_TEMPLATE.TASK_TEMPLATE-PREDECESSOR_TASK_TEMPLATE.lag', 0, 'days'],
    pName: ['TASK_TEMPLATE.PREDECESSOR_TASK_TEMPLATE.name'],
    pUuId: ['TASK_TEMPLATE.PREDECESSOR_TASK_TEMPLATE.uuId']
  }
  

  let data = {
    'name'  : 'Specific Task Template Predecessor List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'holder': uuId
    ,'select': Object.keys(fields).map(i => fields[i])
  }

  data['filter'] = [
    ['TASK_TEMPLATE.PREDECESSOR_TASK_TEMPLATE.uuId', 'eq', predecessorId]
  ]

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}
