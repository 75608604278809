
import { httpAjax } from '@/helpers';

export const projectLinkCustomerService = {
  create,
  remove,
};

/**
 * Create a new Link from project to customer 
 * by passing necessary information
 * @param String projectId, Project's UUID
 * @param Array customers, customer details. Data sample: [{ uuId }]
 * request body format sample: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', customerList: [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx' }] }
 */
function create(projectId, customers) {
  const url = '/api/project/link/customer/add';
  const config = {
    headers: getHeaders()
  };

  const customerList = []
  customers.forEach(i => {
    customerList.push( {uuId: i.uuId} );
  });

  const data = {
    uuId: projectId,
    customerList: customerList
  }

  return httpAjax.post(url, data, config);
}

/**
 * Delete a list of links from project to customer.
 * by passing in an array list of links uuIds
 * @param String projectId, Project's UUID
 * @param Array customerIds, list of customer's UUID 
 * request body format sample: {uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', customerList: { uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'} }
 */
function remove(projectId, customers) {
  const url = '/api/project/link/customer/delete';
  const config = {
    headers: getHeaders()
  };

  const customerList = []
  customers.forEach(i => {
    customerList.push( {uuId: i.uuId} );
  });

  const data = {
    uuId: projectId,
    customerList: customerList
  }
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}