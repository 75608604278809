<template>
  <div>
    <AlertFeedback v-if="alertMsg != null && alertState != 'success'" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert" @offsetHeight="updateGridHeight"/>
    <PriorityNavigation class="grid-toolbar border" v-if="allowManage" :closeDropdown="closePriorityNavDropdown">
        <li v-if="canAdd()">
          <span :id="`BTN_ADD_${id}`">
            <b-btn :disabled="actionProcessing" @click="taskEditOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          </span>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.add') }}
          </b-popover>
        </li>
        <li v-if="canView()">
          <span :id="`BTN_EDIT_${id}`">
            <b-btn :disabled="disableEdit || actionProcessing" @click="taskEditOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ canEdit()? $t('button.edit_task') : $t('button.view_task') }}
          </b-popover>
        </li>
        <li v-if="canDelete()">
          <span :id="`BTN_DELETE_${id}`">
            <b-btn :disabled="disableDelete || actionProcessing" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
          </span>
          <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.delete') }}
          </b-popover>
        </li>
        <li class="divider" v-if="(canAdd() || canView() || canDelete()) && canEdit()">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="canEdit()">
          <span :id="`BTN_OUTDENT_${id}`">
            <b-btn :disabled="disableOutdent || actionProcessing" :id="`BTN_OUTDENT_${id}`" @click="taskOutdent"><font-awesome-icon :icon="['far','outdent']"/></b-btn>
          </span>
          <b-popover :target="`BTN_OUTDENT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.outdent') }}
          </b-popover>
        </li>
        <li v-if="canEdit()">
          <span :id="`BTN_INDENT_${id}`">
            <b-btn :disabled="disableIndent || actionProcessing" @click="taskIndent"><font-awesome-icon :icon="['far','indent']"/></b-btn>
          </span>
          <b-popover :target="`BTN_INDENT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.indent') }}
          </b-popover>
        </li>
        <li class="divider" v-if="(canView() || canDelete() || canEdit()) && canAdd()">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="canAdd()">
           <span :id="`BTN_COPY_${id}`">
            <b-btn :disabled="disableTaskCopy || actionProcessing" @click="taskCopy"><font-awesome-icon :icon="['far','copy']"/></b-btn>
          </span>
          <b-popover :target="`BTN_COPY_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.copy') }}
          </b-popover>
        </li>
        <li v-if="canAdd()">
          <span :id="`BTN_PASTE_${id}`">
            <b-btn :disabled="disableTaskPaste || actionProcessing" @click="taskPaste"><font-awesome-icon :icon="['far','paste']"/></b-btn>
          </span>
          <b-popover :target="`BTN_PASTE_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.paste') }}
          </b-popover>
        </li>
        <li class="divider">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li>
          <span :id="`BTN_EXPORT_DOCUMENT_${id}`">
            <b-btn :disabled="actionProcessing" @click="fileExport"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EXPORT_DOCUMENT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.export_document') }}
          </b-popover>
        </li>
        <li class="divider" v-if="canEdit('TEMPLATE__PROJECT') || canEdit('TEMPLATE__TASK')">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="canEdit('TEMPLATE__PROJECT')">
          <template v-if="disableManualScheduleProject">
            <span :id="`BTN_MANUAL_SCHEDULE_PROJECT_${id}`">
              <b-btn :disabled="actionProcessing" @click="autoScheduleProjectHandler"><font-awesome-icon :icon="['far','thumbtack']"/></b-btn>
            </span>
            <b-popover :target="`BTN_MANUAL_SCHEDULE_PROJECT_${id}`" triggers="hover" placement="top" boundary="viewport">
              {{ $t('task.button.manual_scheduled') }}
            </b-popover>
          </template>
          <template v-if="disableAutoScheduleProject">
            <span :id="`BTN_AUTO_SCHEDULE_PROJECT_${id}`">
              <b-btn :disabled="actionProcessing" @click="manualScheduleProjectHandler"><font-awesome-icon :icon="['far','calendar-check']"/></b-btn>
            </span>
            <b-popover :target="`BTN_AUTO_SCHEDULE_PROJECT_${id}`" triggers="hover" placement="top" boundary="viewport">
              {{ $t('task.button.auto_scheduled') }}
            </b-popover>
          </template>
        </li>
        <li v-if="canEdit('TEMPLATE__ZTASK')" class="scheduler" @[schedulerMouseEnterEvent]="onSchedulerOver" @mouseleave="onSchedulerLeave">
          <b-dropdown :id="`BTN_SCHEDULE_${id}`" ref="scheduler" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'calendar-days']"/>
            </template>
            <b-dropdown-item :disabled="disableManualScheduleTask || actionProcessing" @click="manualScheduleTaskHandler" href="#">
              <font-awesome-icon class="item-icon" :icon="['far','thumbtack']"/><span class="item-label">{{ $t('task.button.manual_schedule_task') }}</span>
            </b-dropdown-item>
            <b-dropdown-item :disabled="disableAutoScheduleTask ||  actionProcessing" @click="autoScheduleTaskHandler" href="#">
              <font-awesome-icon class="item-icon" :icon="['far','calendar-check']"/><span class="item-label">{{ $t('task.button.auto_schedule_task') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </li>
        <li>
          <span :id="`BTN_COLLAPSE_${id}`">
            <b-btn :disabled="inProgressShow || expandLevel === 0" @click="collapse"><font-awesome-icon :icon="['far', 'magnifying-glass-minus']"/></b-btn>
          </span>
          <b-popover :target="`BTN_COLLAPSE_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.minus') }}
          </b-popover>
        </li>
        <li>
          <span :id="`BTN_EXPAND_${id}`">
            <b-btn :disabled="inProgressShow || expandLevel === maxLevel" @click="expand"><font-awesome-icon :icon="['far', 'magnifying-glass-plus']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EXPAND_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.plus') }}
          </b-popover>
        </li>
        <li @[filterMouseEnterEvent]="onFilterOver" @mouseleave="onFilterLeave">
          <b-dropdown :id="`BTN_FILTER_${id}`" ref="filter" 
            class="action-bar-dropdown text-filter" 
            toggle-class="text-decoration-none" no-caret
            @hide="onFilterTextDropdownHide"
          >
            <template #button-content>
              <font-awesome-icon :class="filterText !== '' ? 'active' : ''" :icon="['far', 'file-magnifying-glass']"/>
            </template>
            <b-dropdown-form @submit.stop.prevent class="filter-padding">
              <b-input-group class="search-input">
                <b-form-input v-model="filterText" @focus="filterTextFocus = true" @blur="filterTextFocus = false" :placeholder="$t('task.filter')" @keypress.enter="onFilterSubmit"></b-form-input>
                <b-input-group-append>
                  <b-btn @click="filterText = ''" size="sm" variant="danger"><font-awesome-icon class="search-clear" :icon="['far', 'xmark']"/></b-btn>
                </b-input-group-append>
              </b-input-group>
            </b-dropdown-form>
          </b-dropdown>
        </li>
        <li class="view" @[viewMouseEnterEvent]="onViewOver" @mouseleave="onViewLeave">
          <b-dropdown :id="`BTN_VIEW_${id}`" ref="view" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far','desktop']"/>
            </template>
            <b-dropdown-item @click="showAllColumns" href="#">
              <span class="action-item-label">{{ $t('task.button.all_columns') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="showNoColumns" href="#">
              <span class="action-item-label">{{ $t('task.button.no_columns') }}</span>
            </b-dropdown-item>
            <b-dropdown-divider/>
            <b-dropdown-item @click="savePreset" href="#">
              <span class="action-item-label">{{ $t('task.button.save') }}</span>
            </b-dropdown-item>
            <b-dropdown-divider/>
            <template v-for="(item, index) in userProfile.views">
              <b-dropdown-item class="action-item" :disabled="actionProcessing" @click="loadViewSettings(item)" href="#" :key="index">
                <span :id="`viewname${index}`" class="action-item-label-with-icon">{{ item.name }}</span>
                <b-popover
                  v-if="isEllipsisActive(item.name)"
                  :target="`viewname${index}`"
                  placement="top"
                  boundary="viewport"
                  custom-class="popover-margin"
                  triggers="hover"
                  offset="-100"
                  :content="item.name">
                </b-popover> 
                <span class="action-item-icon position-second" 
                    :id="`UPDATE_COLUMN_${index}`"
                    @click.stop.prevent="updateColumnSettings(index, item.name)" >
                  <font-awesome-icon class="" :icon="['far', 'floppy-disk']"/>
                </span>
                <b-popover :target="`UPDATE_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                  {{ $t('task.button.update') }}
                </b-popover>
                <span class="action-item-icon"
                    :id="`REMOVE_COLUMN_${index}`"
                    @click.stop.prevent="removeColumnSettings(index)" >
                  <font-awesome-icon class="" :icon="['far', 'trash-can']"/>
                </span>
                <b-popover :target="`REMOVE_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                  {{ $t('task.button.delete') }}
                </b-popover>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </li>
    </PriorityNavigation>
    <div v-if="this.id === null">
        No valid project id specified in the URL.
    </div>
    <div v-else>
      <ag-grid-vue style="width: 100%;" :style="{ height: gridHeight }" class="ag-theme-balham" id="project-tasks-grid"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          animateRows
          :autoGroupColumnDef="autoGroupColumnDef"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          :getMainMenuItems="getMainMenuItems"

          noRowsOverlayComponent="noRowsOverlay"
          :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
          
          :overlayLoadingTemplate="overlayLoadingTemplate"
          :getDataPath="data => data.path"
          :getRowId="params => params.data.uuId"
          :groupDefaultExpanded="-1"
          :rowData="rowData"
          :rowMultiSelectWithClick="false"
          rowSelection="multiple"
          :sideBar="false"
          suppressContextMenu
          suppressDragLeaveHidesColumns
          :suppressCellFocus="false"
          :suppressMultiSort="false"
          suppressClipboardApi
          :singleClickEdit="false"
          :enableRangeSelection="true"
          :enableFillHandle="true"
          :fillOperation="fillOperation"
          fillHandleDirection="xy"
          :processCellForClipboard="processCellForClipboard"
          :processCellFromClipboard="processCellFromClipboard"
          :navigateToNextCell="navigateToNextCell"
          :tabToNextCell="tabToNextCell"
          suppressScrollOnNewData
          treeData
          @row-drag-enter="onRowDragEnter"
          @row-drag-end="onRowDragEnd"
          @row-drag-move="onRowDragMove"
          @row-drag-leave="onRowDragLeave"
          @cell-key-down="onCellKeyDown"
          @paste-start="onPasteStart"
          @paste-end="onPasteEnd"
          @cell-focused="cellFocused"
          :suppressRowDrag="!canEdit()"
          enableCellEditingOnBackspace
          >
      </ag-grid-vue>
    </div>

    <TaskModal :show.sync="taskEditShow" :mode="mode" @success="taskEditSuccess" :id.sync="taskEdit.uuId" 
      :projectId="id" :parentId="taskEdit.parentId" isTemplate/>

    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable" @cancel="progressCancel"/>

    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteViewShow"
        @ok="confirmDeleteViewOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.delete_view') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('task.confirmation.save')"
        v-model="promptSaveShow"
        @ok="confirmSaveOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-form-input
        v-model="saveName"
        maxlength="25"
        trim>
      </b-form-input>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button :disabled="saveName === null || saveName.trim().length == 0" size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <TaskDateTimeDurationCalculation :show.sync="durationCalculationShow" 
      :taskName="durationCalculation.taskName"
      defaultActionForNonWorkPrompt="move"
      :skipOutOfProjectDateCheck="durationCalculation.skipOutOfProjectDateCheck"
      showApplyAllCheckbox
      :trigger="durationCalculation.trigger"
      :startDateStr="durationCalculation.startDateStr"
      :startTimeStr="durationCalculation.startTimeStr"
      :closeDateStr="durationCalculation.closeDateStr"
      :closeTimeStr="durationCalculation.closeTimeStr"
      :durationDisplay="durationCalculation.durationDisplay"
      :calendar.sync="durationCalculation.calendar"
      :projectScheduleFromStart="durationCalculation.projectScheduleFromStart"
      :taskAutoScheduleMode="durationCalculation.taskAutoScheduleMode"
      :constraintType="durationCalculation.constraintType"
      :constraintDateStr="durationCalculation.constraintDateStr"
      :lockDuration="durationCalculation.lockDuration"
      :oldDateStr="durationCalculation.oldDateStr"
      :oldTimeStr="durationCalculation.oldTimeStr"
      :oldConstraintType="durationCalculation.oldConstraintType"
      :oldConstraintDateStr="durationCalculation.oldConstraintDateStr"
      :durationConversionOpts="durationConversionOpts"
      @success="durationCalculationOk"
      @skip="durationCalculationOk({ skip: true })"
      @cancel="durationCalculationCancel"
    />

    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="tcConfirmDeleteShow"
        @hidden="tcConfirmDeleteShow=false"
        @ok="tcConfirmDeleteOk"
        @cancel="tcConfirmDeleteCancel"
        content-class="task-delete-modal shadow"
        no-close-on-backdrop
        >
      
      <p>{{ tcConfirmDeleteStatement }}</p>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-form-checkbox v-if="tcShowApplyAllCheckbox" class="apply-to-all" v-model="taskCol.applyAll">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import { strRandom, msToTime, objectClone, isEllipsisActive } from '@/helpers';
import { cloneDeep, debounce } from 'lodash';
import { taskViewService, templateTaskService, templateProjectService, layoutProfileService
  , calendarService, 
  tagService, viewProfileService, compositeService
} from '@/services';
import { getCustomFieldInfo, prepareCustomFieldColumnDef, handleCustomFieldError } from '@/helpers/custom-fields';
import currencies from '@/views/management/script/currencies';
import { columnDefSortFunc } from '@/views/management/script/common';
import RowSelectorCellRenderer from '@/components/Aggrid/CellRenderer/RowSelector';
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/ViewDetailLink';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import DurationCellRenderer from '@/components/Aggrid/CellRenderer/Duration';
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage';
import TaskResourceCellRenderer from '@/components/Aggrid/CellRenderer/TaskResource';
import TaskSkillCellRenderer from '@/components/Aggrid/CellRenderer/TaskSkill';
import TaskStaffCellRenderer from '@/components/Aggrid/CellRenderer/TaskStaff';
import TaskTypeCellRenderer from '@/components/Aggrid/CellRenderer/TaskType';
import ImageCellRenderer from '@/components/Aggrid/CellRenderer/Image';
import NoteCellRenderer from '@/components/Aggrid/CellRenderer/Note';
import TaskAutoSchedulingCellRenderer from '@/components/Aggrid/CellRenderer/TaskAutoScheduling';
import RebateCellRenderer from '@/components/Aggrid/CellRenderer/Rebate';
import ConstraintCellRenderer from '@/components/Aggrid/CellRenderer/Constraint';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';
import GenericCellRenderer from '@/components/Aggrid/CellRenderer/Generic';
import EnumCellRenderer from '@/components/Aggrid/CellRenderer/Enum';
import DateOnlyCellRenderer from '@/components/Aggrid/CellRenderer/DateOnly';
import BooleanCellRenderer from '@/components/Aggrid/CellRenderer/Boolean';

import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';

import SelectionHeaderComponent from '@/components/Aggrid/CellHeader/RangeSelection';
import { TaskTemplateDataUtil } from '@/components/Task/script/task.template.util';
import { TaskViewRequestGenerator } from '@/components/Task/script/task.view.request.generator';
import { TaskViewPropertyUtil } from '@/components/Task/script/task.view.property.util';

import ColorEditor from '@/components/Aggrid/CellEditor/Color';
import DurationEditor from '@/components/Aggrid/CellEditor/Duration';
import DateTimeEditor from '@/components/Aggrid/CellEditor/DateTime';
import CostEditor from '@/components/Aggrid/CellEditor/Cost';
import PercentageEditor from '@/components/Aggrid/CellEditor/Percentage';
import NumericEditor from '@/components/Aggrid/CellEditor/Numeric';
import ListEditor from '@/components/Aggrid/CellEditor/List';
import StaffEditor from '@/components/Aggrid/CellEditor/Staff';
import ResourceEditor from '@/components/Aggrid/CellEditor/Resources';
import SkillEditor from '@/components/Aggrid/CellEditor/Skills';
import ImageEditor from '@/components/Aggrid/CellEditor/Image';
import StringEditor from '@/components/Aggrid/CellEditor/String';
import TagEditor from '@/components/Aggrid/CellEditor/Tag';
import NameEditor from '@/components/Aggrid/CellEditor/Name';
import WorkEffortEditor from '@/components/Aggrid/CellEditor/WorkEffort';
import MultilineEditor from '@/components/Aggrid/CellEditor/Multiline';
import CommentEditor from '@/components/Aggrid/CellEditor/Note3';
import RebateEditor from '@/components/Aggrid/CellEditor/Rebates';
import FloatNumericEditor from '@/components/Aggrid/CellEditor/FloatNumeric';
import IntegerNumericEditor from '@/components/Aggrid/CellEditor/IntegerNumeric';
import ConstraintEditor from '@/components/Aggrid/CellEditor/Constraint';
import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation';


import { DEFAULT_CALENDAR, TRIGGERS, convertDisplayToDuration, analyzeDurationAUM, convertDurationToDisplay
, isValidDateStrFormat, extractDurationConversionOpts
} from '@/helpers/task-duration-process';

import {
  durationComparator,
  numberComparator,
  booleanComparator,
  skillComparator,
  staffComparator,
  noteComparator,
  resourceComparator,
  rebateComparator,
  constraintComparator
} from '@/helpers/task-column-comparator';

export default {
  name: 'TemplateTasks',
  components: {
    'ag-grid-vue': AgGridVue,
    TaskModal: () => import('@/components/modal/TaskModal'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),
    TaskDateTimeDurationCalculation: () => import('@/components/Task/TaskDateTimeDurationCalculation'),
    AlertFeedback: () => import('@/components/AlertFeedback'),
    PriorityNavigation,

    //aggrid cell renderer/editor/header/Overlay component
    /* eslint-disable vue/no-unused-components */
    'costCellRenderer': CostCellRenderer,
    'detailLinkCellRenderer': DetailLinkCellRenderer,
    'dateTimeCellRenderer': DateTimeCellRenderer,
    'durationCellRenderer': DurationCellRenderer,
    'percentageCellRenderer': PercentageCellRenderer,
    'taskResourceCellRenderer': TaskResourceCellRenderer,
    'taskSkillCellRenderer': TaskSkillCellRenderer,
    'taskStaffCellRenderer': TaskStaffCellRenderer,
    'taskTypeCellRenderer': TaskTypeCellRenderer,
    'taskAutoSchedulingCellRenderer': TaskAutoSchedulingCellRenderer,
    'imageCellRenderer': ImageCellRenderer,
    'noteCellRenderer': NoteCellRenderer,
    'rebateCellRenderer': RebateCellRenderer,
    'colorCellRenderer': ColorCellRenderer,
    'genericCellRenderer': GenericCellRenderer,
    'constraintCellRenderer': ConstraintCellRenderer,
    'enumCellRenderer': EnumCellRenderer,
    'dateOnlyCellRenderer': DateOnlyCellRenderer,
    'booleanCellRenderer': BooleanCellRenderer,
    'colorEditor': ColorEditor,
    'durationEditor': DurationEditor,
    'dateTimeEditor': DateTimeEditor,
    'percentageEditor': PercentageEditor,
    'costEditor': CostEditor,
    'listEditor': ListEditor,
    'staffEditor': StaffEditor,
    'resourceEditor': ResourceEditor,
    'skillEditor': SkillEditor,
    'imageEditor': ImageEditor,
    'stringEditor': StringEditor,
    'tagEditor': TagEditor,
    'numericEditor': NumericEditor,
    'nameEditor': NameEditor,
    'workEffortEditor': WorkEffortEditor,
    'multilineEditor': MultilineEditor,
    'commentEditor': CommentEditor,
    'rebateEditor': RebateEditor,
    'constraintEditor': ConstraintEditor,
    'floatNumericEditor': FloatNumericEditor,
    'integerNumericEditor': IntegerNumericEditor,
    'selectionHeaderComponent': SelectionHeaderComponent,
    'rowSelectorCellRenderer': RowSelectorCellRenderer,
    'noRowsOverlay': NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    id: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: 'BOTH'
    }
  },
  data() {
    return {
      userId: null,
      permissionName: 'TEMPLATE__TASK',
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,

      gridOptions: null,
      gridApi: null,
      autoGroupColumnDef: null,
      columnDefs: [],
      columnApi: null,
      context: null,
      defaultColDef: null,
      rowData: null, //default to null. With null value, ag-grid will show loading overlay.
      expandLevel: 0,
      maxLevel: 0,
      filterText: '',
      filterTextFocus: false,
      
      selected: [],
      parentRootId: null,

      taskEdit: {
        uuId: null,
        parentId: null
      },
      taskEditShow: false,
      confirmDeleteShow: false,
      actionProcessing: false,

      taskCopyIds: [],
      taskNames: {},

      project: { autoScheduling: false },

      optionConstraint: [],
      optionPriority: [],
      optionComplexity: [],
      optionCurrency: [],

      constraintMap: {},
      priorityMap: {},
      promptSaveShow: false,
      saveName: null,
      saveIndex: -1,
      confirmDeleteViewShow: false,
      deleteViewIndex: -1,
      calendar: null,
      previousScrollPosLeft: -1,
      previousScrollPosTop: -1,
      columnCache: [],

      durationCalculationShow: false, 
      durationCalculationTasks: [],
      durationCalculationPendingUpdateTasks: [],
      durationCalculation: {
        taskName: null
        , trigger: TRIGGERS.START_DATE
        , startDateStr: null
        , startTimeStr: null
        , closeDateStr: null
        , closeTimeStr: null
        , durationDisplay: null
        , calendar: DEFAULT_CALENDAR
        , projectScheduleFromStart: true
        , taskAutoScheduleMode: true
        , constraintType: null
        , constraintDateStr: null
        , oldDateStr: null
        , oldTimeStr: null
        , lockDuration: false
        , defaultActionForNonWorkPrompt: null
        , skipOutOfProjectDateCheck: false
        , oldConstraintType: null
        , oldConstraintDateStr: null
        , taskId: null
      },

      layoutProfile: {},
      userProfile: {},

      closePriorityNavDropdown: false,

      tcConfirmDeleteShow: false,
      tcConfirmDeleteTasks: [],
      taskCol: {
        taskName: null,
        parentName: null,
        taskId: null,
        parentId: null,
        colId: null,
        applyAll: false
      },

      pendingListByFillOperation: [],
      triggeredByFillOperation: false,
      processValueChangedList: [],
      processDateValueChangedList: [],
      pendingProcessRequestList: [],
      pendingRequestBatchList: [],
      pendingDeleteCells: [],
      modelInfo: null,
      enumList: {},
      
      rowSelectorClicked_allColsSelectedRowIndexList: [],
      rangeSelection: [],
      taskSelection: [],

      noRowsMessage: null,
      noRowsOverlayComponentParams: null,
      lastOpenColumnMenuParams: null,

      customFields: []
      , skillCustomFields: []
      , resourceCustomFields: []

      , durationConversionOpts: {}
      , gridHeight: 'calc(100vh - 195px)'
    }
  },
  beforeMount() {
    this.$store.dispatch('data/enumList')
    .then(response => {
      if (response != null) {
        if (response.jobCase != null && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          const keys = Object.keys(propertyList);
          for (const k of keys) {
            const obj = propertyList[k]
            const codes = Object.keys(obj)
            const list = []
            for (const c of codes) {
              list.push({ value: c, text: c, num: obj[c] })
            }
            this.$set(this.enumList, k, list)
          }
        }

        //Fill up currency name property
        if (Array.isArray(this.enumList.CurrencyEnum)) {
          const currencyList = this.enumList.CurrencyEnum
          for (const item of currencyList)   {
            const found = currencies.find(i => i.code === item.value)
            if (found != null && found.name != null) {
              item.text = `${found.code} (${found.name})`
            }
          }
        }

        this.optionComplexity.splice(0, this.optionComplexity.length, ...this.enumList.GanttComplexityEnum)
        this.optionPriority.splice(0, this.optionPriority.length, ...this.enumList.GanttPriorityEnum)
        this.optionCurrency.splice(0, this.optionCurrency.length, ...this.enumList.CurrencyEnum)
      }
    })
    .catch(e => {
      this.httpAjaxError(e)
    })

    const self = this;
    const canEdit = this.canEdit();

    function saveColumnState() {
      const columns = self.gridOptions.columnApi.getAllDisplayedColumns();
      self.layoutProfile.taskColumns = columns.map(c => { return { colId: c.colId, width: c.actualWidth, sort: c.sort, sortIndex: c.sortIndex }});
      self.layoutProfile.taskExpandLevel = self.expandLevel;
      self.updateLayoutProfile();
    }

    this.gridOptions = {
      getRowHeight: params => self.hasImage(params) ? 170 : 25,
      suppressRowClickSelection: true,
      onRowDataChanged: debounce(function() {
        setTimeout(() => {
          self.resetPreviousScrollPosition(self.previousScrollPosLeft, self.previousScrollPosTop);
        }, 300);
      }, 300),
      onCellValueChanged: function(event) {
        let colId = event.column.colId;
        const rowIndex = event.rowIndex;
        let newValue = event.newValue;
        let oldValue = event.oldValue;
        const rowNode = event.api.getDisplayedRowAtIndex(rowIndex);
        if (event.column.colId == self.COLUMN_AGGRID_AUTOCOLUMN) {
          colId = 'name';
          newValue = rowNode.data.name;
          oldValue = rowNode.data.oldName; //oldName is added in autoGroupColumnDef.valueSetter.
        }
        
        const payload = {
          colId
          , data: objectClone(rowNode.data)
          , newValue
          , oldValue
          , property: colId
          , taskId: rowNode.data.uuId
          , parentId: rowNode.data.pUuId
          , taskName: rowNode.data.name
          , taskType: rowNode.data.taskType
          , color: event.colDef.color //Default task Color. Used when creating new task.
        }
        let found = self.originalRowDataList.find(i => i.uuId == rowNode.data.uuId);
        if (found == null) {
          found = JSON.parse(JSON.stringify(rowNode.data));
          found[colId] = JSON.parse(JSON.stringify(oldValue));
          self.originalRowDataList.push(found)
        } else {
          found[colId] = JSON.parse(JSON.stringify(oldValue));
        }
        
        if (colId === self.COLUMN_AGGRID_AUTOCOLUMN || payload.newValue == payload.oldValue) {
          //do nothing
        } else if (self.triggeredByFillOperation) {
          self.pendingListByFillOperation.push(payload);
        } else if (self.isPasteInProgress) {
          self.processValueChangedList.push(payload);
        } else {
          self.processValueChangedList.push(payload);
          self.inProgressLabel = self.$t('task.progress.updating_tasks');
          self.processValueChanged(event.api);
        }
      },
      onColumnRowGroupChanged: function(/** params */) {
        self.maxLevel = 0;
        self.gridApi.forEachNode((node/**, b */) => {
          if (node.level > self.maxLevel) {
            self.maxLevel = node.level;
          }
        });
      },
      onFirstDataRendered: function(/**params*/) {
        self.maxLevel = 0;
        self.gridApi.forEachNode((node /** , b*/) => {
          if (node.level > self.maxLevel) {
            self.maxLevel = node.level;
          }
        });
        if (self.layoutProfile.taskExpandLevel == null) {
          self.expandLevel = self.maxLevel;
        }
      
        self.processNodes();
        self.gridApi.resetRowHeights();
      },
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.columnApi
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
        }
        const cols = params.columnApi.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.columnApi.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.columnApi.moveColumn(c.colId, index);
        }

        params.api.resetRowHeights();
        saveColumnState();

        const shownColumns = params.columns.filter(i => i.visible);
        if (shownColumns.some(i => !self.columnCache.includes(i.colId))) {
          self.loadTasks(self.id, () =>{
            if(self.gridApi) {
              if(self.rowData.length < 1) {
                self.gridApi.showNoRowsOverlay();
              } else {
                self.gridApi.hideOverlay();
              }
            }
          });
        }
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onSortChanged: function(/**event*/) {
        saveColumnState();
        const values = self.gridOptions.columnApi.getColumnState().filter(s => s.sort != null)
        if (values.length > 0) {
          self.sort = [];
          for (const value of values) {
            self.sort.push({ colId: value.colId === 'ag-Grid-AutoColumn' ? 'name' : value.colId, direction: value.sort });
          }
        }
        else {
          self.sort = [];
        }
      },
      onDragStopped: function(/**event*/) {
        saveColumnState();
      },
      onGridSizeChanged: function() {
        if (self.gridApi && self.gridApi.gridBodyCtrl) {
          const width = self.gridApi.gridBodyCtrl.eBodyViewport.clientWidth;
          self.autoGroupColumnDef.pinned = width < 800 ? '' : 'left';
          self.gridApi.setAutoGroupColumnDef(self.autoGroupColumnDef);
        }
      },
      onRowDataUpdated: function() {
        self.resetPreviousScrollPosition(self.previousScrollPosLeft, self.previousScrollPosTop);
        self.actionProcessing = false;
        self.inProgressShow = false;
      },
      onRangeSelectionChanged: function(event) {
        if (event.finished == true) {
          if (self.triggeredByFillOperation) {
            const pendingList = self.pendingListByFillOperation.splice(0, self.pendingListByFillOperation.length);
            self.processValueChangedList.push(...pendingList);
            self.consolidateChangedCellValues(self.processValueChangedList);
            self.triggeredByFillOperation = false;
            self.inProgressLabel = self.$t('task.progress.updating_tasks');
            self.processValueChanged(event.api);
          }
          const cellRanges = event.api.getCellRanges();
          const originalRanges = cellRanges.map(i => {
            return {
              rowStartIndex: i.startRow.rowIndex,
              rowEndIndex: i.endRow.rowIndex,
              columns: i.columns.map(j => j.colId)
            }
          });
          if (cellRanges.length > 0) {
            const lastRange = (() => {
                const tmp = cellRanges[cellRanges.length-1];
                const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex;
                return {
                  rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
                  rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
                  columns: tmp.columns.map(i => i.colId),
                  startColumn: tmp.startColumn.colId,
                  orgRowStartIndex: tmp.startRow.rowIndex,
                  orgRowEndIndex: tmp.endRow.rowIndex
                }
              })();
            const lastColumnId = lastRange.startColumn == lastRange.columns[0]? lastRange.columns[lastRange.columns.length-1] : lastRange.columns[0]; 
            //Handle drag selection which starts (mouse click press) or finishes (mouse click release) on rowSelector column. 
            //Brief: Include all cells of the selected rows into the cell ranges as if user clicks on rowSelector columns
            if (lastRange.columns.length > 1 && (lastColumnId == 'rowSelector' || lastRange.startColumn == 'rowSelector')) {
              self.rowSelectorChanged({
                cellRanges,
                originalRanges,
                lastRange,
                api: event.api, 
                columnApi: event.columnApi 
              })
            } else {
              //Brief: Reshape previous ranges to avoid clashing with last/latest range.
              const lastRangeColumns = lastRange.columns;
              const isLastRangeAsc = lastRange.rowStartIndex <= lastRange.rowEndIndex;
              const lastRangeStartIndex = isLastRangeAsc? lastRange.rowStartIndex : lastRange.rowEndIndex;
              const lastRangeEndIndex = isLastRangeAsc? lastRange.rowEndIndex : lastRange.rowStartIndex;

              const cRanges = cellRanges.map(i => {
                return {
                  rowStartIndex: i.startRow.rowIndex,
                  rowEndIndex: i.endRow.rowIndex,
                  columns: i.columns.map(j => j.colId)
                }
              })
              cRanges.splice(cRanges.length - 1, 1);
              const newCellRanges = [];
              let hasChanges = false;

              //Before reshape, check if last range's start cell clashes with previous cell range.
              //If yes, do nothing (and expect aggrid will do its job to remove cell ranges)
              const startColumnId = lastRange.startColumn;
              const startCell = {
                rowStartIndex: lastRange.orgRowStartIndex,
                rowEndIndex: lastRange.orgRowStartIndex
              }
              let isStartCellClashed = false;
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex;
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex;
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex;
                const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex;
                const columnClashed = range.columns.filter(i => i == startColumnId).length > 0;
                if (isRowClashed && columnClashed) {
                  isStartCellClashed = true;
                  break;
                }
              }
              if (isStartCellClashed) {
                return;
              }

              //Reshape previous ranges to avoid clashing with last/latest range.
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex;
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex;
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex;
                const isRowClashed = (lastRangeStartIndex >= cellRangeStartIndex && lastRangeStartIndex <= cellRangeEndIndex) ||
                                     (cellRangeStartIndex >= lastRangeStartIndex && cellRangeStartIndex <= lastRangeEndIndex);
                const clashedColumns = range.columns.filter(i => lastRangeColumns.includes(i));                
                if (!isRowClashed || clashedColumns.length == 0) {
                  newCellRanges.push(range);
                  continue;
                }
                
                //Reshape cell range if it clash with lastRange.
                const splitTopNeeded = cellRangeStartIndex < lastRangeStartIndex;
                const splitBottomNeeded = cellRangeEndIndex > lastRangeEndIndex;
                const firstClashedCol = clashedColumns[0];
                const splitLeftNeeded = range.columns.findIndex(i => i == firstClashedCol) != 0;
                const lastClashedCol = clashedColumns[clashedColumns.length-1];
                const splitRightNeeded = range.columns.findIndex(i => i == lastClashedCol) != range.columns.length-1;

                if (splitTopNeeded) {
                  newCellRanges.push({
                    rowStartIndex: cellRangeStartIndex,
                    rowEndIndex: lastRangeStartIndex-1,
                    columns: objectClone(range.columns)
                  });
                  hasChanges = true;
                }

                if (splitRightNeeded) {
                  const startColIdx = range.columns.findIndex(i => i == lastClashedCol)+1;
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(startColIdx)
                  });
                  hasChanges = true;
                }

                if (splitBottomNeeded) {
                  newCellRanges.push({
                    rowStartIndex: lastRangeEndIndex+1,
                    rowEndIndex: cellRangeEndIndex,
                    columns: objectClone(range.columns)
                  });
                  hasChanges = true;
                }

                if (splitLeftNeeded) {
                  const sliceLen = range.columns.findIndex(i => i == firstClashedCol);
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(0, sliceLen)
                  });
                  hasChanges = true;
                }
              }
              
              //Merge last range to any existing range when condition met.
              //Conditions: 1) Matched rows and column(s) in sequence, or 
              //            2) Matched columns and row(s) in sequence.
              let hasRangeMerged = false;
              if (newCellRanges.length > 0) {
                const allColumns = event.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId);
                const lastRowStartColIndex = allColumns.findIndex(i => i == lastRangeColumns[0]);
                const lastRowEndColIndex = allColumns.findIndex(i => i == lastRangeColumns[lastRangeColumns.length - 1]);
                const cloned = objectClone(newCellRanges);
                const newRanges = [];
                for (const cRange of cloned) {
                  const startColIndex = allColumns.findIndex(i => i == cRange.columns[0]);
                  const endColIndex = allColumns.findIndex(i => i == cRange.columns[cRange.columns.length - 1]);
                  const isRowIndexMatched = cRange.rowStartIndex == lastRangeStartIndex && cRange.rowEndIndex == lastRangeEndIndex;
                  const isColumnMatched = startColIndex == lastRowStartColIndex && endColIndex == lastRowEndColIndex;
                  const isRowInSequence = cRange.rowStartIndex -1 == lastRange.rowEndIndex || cRange.rowEndIndex + 1 == lastRange.rowStartIndex;
                  const isColumnInSequence = startColIndex - 1 == lastRowEndColIndex || endColIndex + 1 == lastRowStartColIndex;
                  if (isRowIndexMatched && isColumnInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex
                      , rowEndIndex: lastRangeEndIndex
                      , columns: lastRowStartColIndex < startColIndex? [...lastRangeColumns, ...cRange.columns] : [...cRange.columns, ...lastRangeColumns]
                    });
                    hasRangeMerged = true;
                    continue;
                  } else if (isColumnMatched && isRowInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex < cRange.rowStartIndex? lastRangeStartIndex : cRange.rowStartIndex
                      , rowEndIndex: lastRangeEndIndex > cRange.rowEndIndex? lastRangeEndIndex : cRange.rowEndIndex
                      , columns: lastRangeColumns
                    })
                    hasRangeMerged = true;
                    continue;
                  }
                  newRanges.push(cRange);
                }
                if (hasRangeMerged) {
                  newCellRanges.splice(0, newCellRanges.length, ...newRanges);
                }
              }
              
              //hasChanges flag is important to avoid infinite loop. 
              //any addCellRange() call will trigger onRangeSelectionChange() event.
              //Don't call addCellRange() when no change is required.
              if (hasChanges || hasRangeMerged) {
                //Adding last range when hasRangeMerged is false. 
                //Details: If hasRangeMerged is true, the last range has been merged to one of the previous range.
                if (!hasRangeMerged) {
                  newCellRanges.push({
                    rowStartIndex: lastRange.rowStartIndex,
                    rowEndIndex: lastRange.rowEndIndex,
                    columns: lastRange.columns
                  });
                }
                event.api.clearRangeSelection();
                for (const ncRange of newCellRanges) {
                  event.api.addCellRange(ncRange);
                }
              } else {
                //When thing settles down, update taskSelection variable.
                let selectedTasks = [];
                for (const oRange of originalRanges) {
                  const startRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowEndIndex : oRange.rowStartIndex;
                  const endRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowStartIndex : oRange.rowEndIndex;
                  const columns = oRange.columns;
                  const taskCols = columns.filter(i => i.startsWith(self.taskColPrefix));
                  for (let i = startRowIdx; i <= endRowIdx; i++) {
                    const rowNode = event.api.getDisplayedRowAtIndex(i);
                    
                    if (rowNode == null) {
                      continue;
                    }

                    //Handle non task Cols.
                    if ((columns.length - taskCols.length) > 0) {
                      //Treat any non task cell selection is ag-Grid-AutoColumn cell selection.
                      selectedTasks.push({ 
                         uuId: rowNode.data.uuId
                        , name: rowNode.data.name
                        , parent: rowNode.data.pUuId
                        , parentName: rowNode.data.pName
                        , colId: self.COLUMN_AGGRID_AUTOCOLUMN
                        , rowIndex: rowNode.rowIndex
                      });
                    }

                    //Handle task cols (which exist in compact view)
                    if (taskCols.length > 0) {
                      for(const tCol of taskCols) {
                        if (rowNode.data[tCol].uuId != null) {
                          selectedTasks.push({ 
                            uuId: rowNode.data[tCol].uuId
                            , name: rowNode.data[tCol].data.name
                            , parent: rowNode.data.uuId
                            , parentName: rowNode.data.name
                            , colId: tCol
                            , rowIndex: rowNode.rowIndex
                          });
                        }
                      }
                    }
                  }
                }
                
                //Remove any selected taskcol items if the 'ag-Grid-AutoColumn' of the same row is selected.
                if (self.isCompactView) {// Only applicable in compact view.
                  const summaryTasks = selectedTasks.filter(i => i.colId == self.COLUMN_AGGRID_AUTOCOLUMN);
                  for (const sTask of summaryTasks) {
                    selectedTasks = selectedTasks.filter(i => i.parent != sTask.uuId);
                  }
                }

                //Rearrange order of the tasks.
                //Tasks without rowIndex will be pushed to the bottom of the list. Theorectically, all tasks should have rowIndex property.
                //The first task/summary task will be target parent for new task creation.
                selectedTasks.sort(function( a, b ) {
                  if (a.rowIndex == null && b.rowIndex == null) {
                    return 0;
                  }
                  if (b.rowIndex == null || a.rowIndex < b.rowIndex){
                    return -1;
                  }
                  if (a.rowIndex == null || a.rowIndex > b.rowIndex){
                    return 1;
                  }
                  return 0;
                });
                self.taskSelection.splice(0, self.taskSelection.length, ...selectedTasks);
              }
            }
          } else {
            //Clean up taskSelection when range selection is empty.
            self.taskSelection.splice(0, self.taskSelection.length);
          }
          event.api.refreshHeader();
        }
      },
      onRowGroupOpened(event) {
        //Reset row selection and range selection when clicking row group icon to expand/collapse.
        if (event.api.getCellRanges().length > 0) {
          event.api.deselectAllFiltered();
          event.api.clearRangeSelection();
        }

        if (self.isTaskExpandedByActionBar == false) {
          //The following logic is for action: user clicks on the expand/collapse icon in the autoGroupColumn column.
          let rowUuId = event.data.uuId;
          if(event.node.expanded) {
            self.manualExpandedSet.add(rowUuId);
            self.manualCollapsedSet.delete(rowUuId);
          } else {
            self.manualExpandedSet.delete(rowUuId);
            self.manualCollapsedSet.add(rowUuId);
          }
        }
      }
    };
    const defaultCellClass = (params) => { return params.data.taskType === 'Project' ? ['grid-cell-summary']: [] };

    this.autoGroupColumnDef = {
      headerName: 'Tasks',
      pinned: 'left',
      minWidth: 150,
      width: 200,
      menuTabs: ['columnsMenuTab'],
      editable: canEdit? (params) => { return params.data && params.data.taskPath != null && params.data.readOnly != true } : false,
      cellStyle: {
        'height': '100%',
        'display': 'flex ',
        'align-items': 'center',
        'overflow': 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap'
      },
      cellClass: (params) => {
        if (params == null || params.colDef == null) {
          return [];
        }
        let isEditable = false;
        if (typeof params.colDef.editable == 'function') {
          isEditable = params.colDef.editable(params);
        } else {
          isEditable = params.colDef.editable == true? true : false;
        }
        
        if (!isEditable) {
          return ['read-only'];
        }
        return [];
      },
      cellEditor: 'nameEditor',
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: 'detailLinkCellRenderer',
        enableReadonlyStyle: true
      },
      comparator: (valueA, valueB, nodeA, nodeB) => {
        if(nodeA.group == nodeB.group) {
          if (valueA === null && valueB === null) {
            return 0;
          }
          if (valueA === null) {
            return -1;
          }
          if (valueB === null) {
            return 1;
          }
          return nodeA.data.label.toLowerCase().localeCompare(nodeB.data.label.toLowerCase());
        } else if(nodeA.group) {
          return 1;
        } else if(nodeB.group) {
          return -1;
        }
      },
      valueSetter: function(params) {
        const newValue = params.newValue.trim();
        const oldValue = objectClone(params.data.name);
        if (newValue !== '' && newValue != oldValue) {
          self.$set(params.data, 'oldName', oldValue);
          params.data.name = newValue;
          return true;
        }
        return false;
      },
      getQuickFilterText: function(params) {
          return params.data ? params.data.name : '';
      }
    };
    
    const colDefs = [
      // this.getRowSelectorColumn(),
      {
        headerName: this.$t('task.field.taskType'),
        field: 'taskType',
        minWidth: 150,
        hide: false,
        cellRenderer: 'taskTypeCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        getQuickFilterText: function(params) {
          return params.value === 'Project' ? 'Summary Task' : params.value;
        }
      },
      {
        headerName: this.$t('task.field.estimatedDuration'),
        field: 'estimatedDuration',
        hide: false,
        minWidth: 150,
        cellEditor: 'durationEditor',
        cellStyle: {
          'height': '100%',
          'display': 'flex ',
          'align-items': 'center '
        },
        editable: canEdit? (params) => { 
            return params.data.taskType == 'Task' && params.data.readOnly != true 
          } : false,
        comparator: durationComparator
      },
      {
        headerName: this.$t('task.field.startTime'),
        field: 'startTime',
        minWidth: 150,
        hide: false,
        cellRenderer: 'dateTimeCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'dateTimeEditor',
        cellEditorParams: { 
          labelDate: this.$t('task.field.startTime'),
          optional: true
        },
        editable: canEdit? (params) => { 
            return params.data.taskType !== 'Project' && params.data.readOnly != true
          } : false,
        getQuickFilterText: function(params) {
          return self.toDateTime(params.value);
        },
        comparator: numberComparator
      },
      {
        headerName: this.$t('task.field.closeTime'),
        field: 'closeTime',
        minWidth: 150,
        hide: false,
        cellRenderer: 'dateTimeCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'dateTimeEditor',
        cellEditorParams: { 
          labelDate: this.$t('task.field.closeTime')
        },
        editable: canEdit? (params) => { 
            return params.data.taskType == 'Task' && params.data.readOnly != true
          } : false,
        getQuickFilterText: function(params) {
          return self.toDateTime(params.value);
        },
        comparator: numberComparator
      },
      {
        headerName: this.$t('task.field.skills'),
        field: 'skills',
        minWidth: 150,
        hide: false,
        cellRenderer: 'taskSkillCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'skillEditor',
        cellEditorParams: {
          edgeName: `TASK_TEMPLATE-SKILL`
        },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        comparator: skillComparator,
        cellClass: (params) => {
          const classes = defaultCellClass(params);
          if (params.data == null || params.data.uuId == 'ROOT') {
            classes.push('cell-disabled');
          }
          return classes;
        }
      },
      {
        headerName: this.$t('task.field.staffs'),
        field: 'staffs',
        minWidth: 150,
        hide: false,
        cellRenderer: 'taskStaffCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'staffEditor',
        cellEditorParams: { 
          companyList: () => this.project ? this.project.companyList : null
        },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        comparator: staffComparator,
        cellClass: (params) => {
          const classes = defaultCellClass(params);
          if (params.data == null || params.data.uuId == 'ROOT') {
            classes.push('cell-disabled');
          }
          return classes;
        }
      },
      {
        headerName: this.$t('task.field.estimatedCost'),
        field: 'estimatedCost',
        hide: false,
        minWidth: 150,
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
          , customCurrencyProp: 'currencyCode'
        },
        comparator: numberComparator
      },
      {
        headerName: this.$t('task.field.fixedCost'),
        field: 'fixedCost',
        hide: true,
        minWidth: 150,
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
          , customCurrencyProp: 'currencyCode'
        },
        cellEditor: 'costEditor',
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        comparator: numberComparator
      },
      {
        headerName: this.$t('task.field.fixedCostNet'),
        field: 'fixedCostNet',
        hide: true,
        minWidth: 150,
        cellStyle: {
          'border-width': '1px',
          'height': '100%',
          'display': 'flex ',
          'align-items': 'center '
        },
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
          , customCurrencyProp: 'currencyCode'
        },
        comparator: numberComparator,
      },
      {
        headerName: this.$t('task.field.currencyCode'),
        field: 'currencyCode',
        cellEditor: 'listEditor',
        cellRenderer: 'enumCellRenderer',
        cellRendererParams: { options: this.optionCurrency, enableReadonlyStyle: true },
        cellEditorParams: { options: this.optionCurrency, isEnumType: true },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        hide: true
      },
      {
        headerName: this.$t('task.field.notes'),
        field: 'notes',
        minWidth: 150,
        hide: false,
        cellRenderer: 'noteCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'commentEditor',
        cellEditorParams: { 
          entityName: 'TASK_TEMPLATE'
        },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        comparator: noteComparator
      },
      // Hidden fields below
      {
        headerName: this.$t('task.field.priority'),
        field: 'priority',
        minWidth: 150,
        hide: true,
        cellEditor: 'listEditor',
        cellEditorParams: { options: this.optionPriority, isEnumType: true },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false
      },
      {
        headerName: this.$t('task.field.complexity'),
        field: 'complexity',
        minWidth: 150,
        hide: true,
        cellEditor: 'listEditor',
        cellEditorParams: { options: this.optionComplexity, isEnumType: true },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        cellClass: (params) => {
          const classes = defaultCellClass(params);
          if (params.data == null || params.data.uuId == 'ROOT') {
            classes.push('cell-disabled');
          }
          return classes;
        }
      },

      {
        headerName: this.$t('task.field.resources'),
        field: 'resources',
        minWidth: 150,
        hide: true,
        cellRenderer: 'taskResourceCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'resourceEditor',
        cellEditorParams: {
          edgeName: `TASK_TEMPLATE-RESOURCE`
        },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        comparator: resourceComparator,
        cellClass: (params) => {
          const classes = defaultCellClass(params);
          if (params.data == null || params.data.uuId == 'ROOT') {
            classes.push('cell-disabled');
          }
          return classes;
        }
      },

      {
        headerName: this.$t('task.field.estimatedCostNet'),
        field: 'estimatedCostNet',
        hide: true,
        minWidth: 150,
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
          , customCurrencyProp: 'currencyCode'
        },
        comparator: numberComparator
      },

      {
        headerName: this.$t('task.field.rebates'),
        field: 'rebates',
        minWidth: 150,
        hide: true,
        cellRenderer: 'rebateCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellEditor: 'rebateEditor',
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        comparator: rebateComparator
      },
      {
        headerName: this.$t('task.field.constraint'),
        colId: 'constraint',
        field: 'constraint',
        minWidth: 205,
        hide: true,
        cellEditor: 'constraintEditor',
        cellEditorParams: { field: 'type', options: this.optionConstraint },
        cellRenderer: 'constraintCellRenderer',
        cellRendererParams: { field: 'type', enableReadonlyStyle: true },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.taskType !== 'Project' && params.data.readOnly != true
          } : false,
        cellClass: (params) => {
          const classes = defaultCellClass(params);
          if (params.data == null || params.data.taskType === 'Project' || params.data.uuId == 'ROOT') {
            classes.push('cell-disabled');
          }
          return classes;
        },
        comparator: constraintComparator
      },
      {
        headerName: this.$t('task.field.autoScheduling'),
        field: 'autoScheduling',
        minWidth: 100,
        hide: true,
        cellEditor: 'listEditor',
        cellEditorParams: { 
          options: [
            { value: true, text: this.$t('task.autoschedule.auto') },
            { value: false, text: this.$t('task.autoschedule.manual') }
          ]
        },
        cellRenderer: 'taskAutoSchedulingCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.taskType !== 'Project' && params.data.readOnly != true
          } : false,
        cellClass: (params) => {
          const classes = defaultCellClass(params);
          if (params.data == null || params.data.taskType === 'Project') {
            classes.push('cell-disabled');
          }
          return classes;
        },
        comparator: booleanComparator
      },
      {
        headerName: this.$t('task.field.description'),
        field: 'description',
        minWidth: 150,
        hide: true,
        cellEditor: 'multilineEditor',
        cellEditorParams: { title: this.$t('task.edit_description') },
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
      },
      {
        headerName: this.$t('task.field.taskpath'),
        field: 'taskPath',
        minWidth: 150,
        hide: true,
        cellClass: (params) => {
          const classes = defaultCellClass(params);
          if (params.data == null || params.data.uuId == 'ROOT') {
            classes.push('cell-disabled');
          }
          return classes;
        }
      },
      {
        headerName: this.$t('task.field.image'),
        field: 'avatarRef',
        minWidth: 150,
        hide: true,
        cellRenderer: 'imageCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        cellClass: (params) => {
          const classes = defaultCellClass(params);
          if (params.data == null || params.data.uuId == 'ROOT') {
            classes.push('cell-disabled');
          }
          return classes;
        },
        cellEditor: 'imageEditor',
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false
      },
      {
        headerName: this.$t('field.tag'),
        field: 'tag',
        minWidth: 150,
        hide: true,
        cellEditor: 'tagEditor',
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false
      },
      {
        headerName: this.$t('field.color'),
        field: 'color',
        cellRenderer: 'colorCellRenderer',
        hide: true,
        cellEditor: 'colorEditor',
        editable: canEdit? (params) => { 
            return params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false
      },
      {
        headerName: this.$t('field.identifier_full'),
        field: 'identifier',
        minWidth: 150,
        hide: true,
        editable: canEdit? (params) => { 
            return params.data != null && params.data.uuId !== 'ROOT' && params.data.readOnly != true
          } : false,
        cellEditor: 'stringEditor'
      }
    ];

    const requests = [
      getCustomFieldInfo(this, 'TASK', 'TASK_TEMPLATE').catch(e => this.httpAjaxError(e))
      , getCustomFieldInfo(this, 'SKILL_LINK', null, { customFieldsPropName: 'skillCustomFields' }).catch(e => this.httpAjaxError(e))
      , getCustomFieldInfo(this, 'RESOURCE_LINK', null, { customFieldsPropName: 'resourceCustomFields' }).catch(e => this.httpAjaxError(e))
    ]

    
    Promise.allSettled(requests)
    .finally(() => {
      // Setup custom field column definition
      prepareCustomFieldColumnDef(colDefs, this.customFields, { self: this })
      if (!this.canEdit) {
        for (const col of colDefs) {
          col.editable = false;
        }
      }
      colDefs.sort(columnDefSortFunc);
      colDefs.unshift(this.getRowSelectorColumn());
      
      this.columnDefs = colDefs;

      if (this.isGridReady == true) {
        // continue loading layout profile.
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
        this.loadLayoutProfile()
        .finally(() => {
          const invalidList = ['tasks', 'gantt', 'staff'];
          if(this.id && !invalidList.includes(this.id)) {
            this.gridApi.showLoadingOverlay();
          } else {
            this.gridApi.showNoRowsOverlay();
          }
        });
        this.isGridReady = false;
      }
      this.isCustomFieldsReady = true;
    });

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      hide: false,
      editable: false,
      cellRenderer: 'genericCellRenderer',
      cellRendererParams: {
        enableReadonlyStyle: true
      },
      menuTabs: ['columnsMenuTab'],
      columnsMenuParams: {
        contractColumnSelection: true
      },
      lockPinned: true,
      cellStyle: params => {
        if (params.column.colId !== this.COLUMN_AGGRID_AUTOCOLUMN) {
          return {
            'height': '100%',
            'display': 'flex ',
            'align-items': 'center ',
          }
        }
        else {
          if (self.hasImage(params)) {
            return {
              'height': '100%'
            }
          }
          else {
            return {
              'height': '25px'
            }
          }
        }
      },
      cellClass: defaultCellClass,
      suppressKeyboardEvent: (params) => {
        if (params.event.keyCode === 46 
            || params.event.keyCode === 35 || params.event.keyCode == 36 
            || (params.event.keyCode == 37 && params.event.ctrlKey)) {
          return true;
        } else if (params.event.keyCode == 13) {
          if (params.event.ctrlKey) {
            return true;
          }
          if ((typeof params.colDef.editable == 'boolean' && !params.colDef.editable) || 
              (typeof params.colDef.editable == 'function' &&  !params.colDef.editable({ data: params.data }))) {
            return true;
          }
        } else if (params.event.keyCode == 68 && params.event.ctrlKey) { //'D'
          return true;
        }
        return false;
      }
    };
    
    this.context = {
      componentParent: self
    };
    this.populateTaskConstraint();
  },
  created() {
    this.isGridReady = false;
    this.isCustomFieldsReady = false;
    this.getModelInfo();
    this.getDurationConversionOpts();
    this.rowDragOffsetY = -7;
    this.rowDragPrevOverNodeIds = [];
    this.isDateCalcInProgress = false;
    this.isPasteInProgress = false;
    this.cellCopyPrefix = 'PRJTL_COPIED_OBJ=';
    this.COLUMN_AGGRID_AUTOCOLUMN = 'ag-Grid-AutoColumn';
    this.entityId = this.$route.params.id;
    this.userId = this.$store.state.authentication.user.uuId;
    this.nodeChildrenList = null;
    this.nodeId = null;
    this.manualExpandedSet = new Set();
    this.manualCollapsedSet = new Set();
    this.isTaskExpandedByActionBar = false;
    this.invalidList = ['tasks', 'gantt', 'staff'];
    this.originalRowDataList = [];
    this.sort = [];
    if(this.id == null || this.invalidList.includes(this.id)) {
      this.resetAlert({ msg: this.$t('error.no_active_project_selected'), alertState: alertStateEnum.ERROR });
      this.actionProcessing = true;
    }
    this.prepareProjectDetails();
    this.loadUserProfile(); // User profile holds Task views
    this.systemLocationCalendar();

    this.taskDialogOpen = this.$route.query.tdOpen;
    this.taskDialogId = this.$route.query.tId;

    this.lastFocusedCell = null;
    document.addEventListener('keydown', this.keyDownHandler);
    
    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
  },
  mounted() {
    document.addEventListener('click', this.stopEditing);
    const viewport = document.querySelector(".ag-center-cols-viewport");
    if (viewport) {
      viewport.addEventListener('click', this.onClickGrid);
    }
    document.addEventListener('keyup', this.loseCellFocusOnEscapeKey);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.stopEditing);
    const viewport = document.querySelector(".ag-center-cols-viewport");
    if (viewport) {
      viewport.removeEventListener('click', this.onClickGrid);
    }
    document.removeEventListener('keyup', this.loseCellFocusOnEscapeKey);
    document.removeEventListener('keydown', this.keyDownHandler);
    
    this.keyDownListenerId = null;
    this.nodeChildrenList = null;
    this.nodeId = null;
    this.taskDialogOpen = null;
    this.taskDialogId = null;
    this.invalidList = null;
    this.rowDragPrevOverNodeIds = null;
    this.manualExpandedSet = null;
    this.manualCollapsedSet = null;
    this.originalRowDataList = null;
    this.lastFocusedCell = null;
    this.isGridReady = false;
    this.sort = null;
  },
  watch: {
    filterText(newValue) {
      this.changeFilter(newValue);
    }
    , alertMsg: function(nVal, oVal) {
      if (oVal != nVal && nVal == null) {
        this.updateGridHeight();
      }
    }
  },
  computed: {
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'>${ this.$t('task.grid.loading') }</span>`;
    },
    disableEdit() {
      return this.taskSelection.length != 1 || this.taskSelection.find(i => i.uuId == 'ROOT') != null;
    },
    disableDelete() {
      return this.taskSelection.length < 1 || this.taskSelection.find(i => i.uuId == 'ROOT') != null;
    },
    disableOutdent() {
      if (this.taskSelection.find(i => i.uuId == 'ROOT') != null) {
        return true;
      }
      const level = this.isCompactView? 1: 0;
      const filtered = this.taskSelection
        .filter(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN)
        .filter(i => {
          const rowNode = this.gridOptions.api.getRowNode(i.uuId);
          return rowNode != null && rowNode.level > level;
        });
      return this.taskSelection.length < 1 || filtered.length == 0;
    },
    disableIndent() {
      if (this.taskSelection.find(i => i.uuId == 'ROOT') != null) {
        return true;
      }
      const hasNoQualifiedParent = (tSelection) => {
        const filtered = tSelection
        .filter(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN)
        .filter(i => {
          const rowNode = this.gridOptions.api.getRowNode(i.uuId);
          if (rowNode != null) {
            for (let idx = rowNode.rowIndex - 1; idx >= 0; idx--) {
              const prev = this.gridOptions.api.getDisplayedRowAtIndex(idx);
              if (prev == null || prev.level < rowNode.level) {
                break;
              } else if (prev.level > rowNode.level) {
                continue;
              } else if (prev != null && prev.level === rowNode.level && prev.data.taskType == 'Project') {
                return true;
              }
            }
          }
          return false;
        });
        return filtered.length === 0;
      }
      return this.taskSelection.length < 1 || hasNoQualifiedParent(this.taskSelection);
    },
    disableTaskCopy() {
      return this.taskSelection.length  < 1 || this.taskSelection.find(i => i.uuId == 'ROOT') != null;
    },
    disableTaskPaste() {
      return this.taskCopyIds.length < 1 || this.taskSelection.length > 1;
    },
    disableManualScheduleProject() {
      return !this.project.autoScheduling;
    },
    disableAutoScheduleProject() {
      return this.project.autoScheduling;
    },
    disableManualScheduleTask() {
      if ( this.taskSelection.find(i => i.uuId == 'ROOT') != null) {
        return true;
      }
      return this.taskSelection
      .filter(i => {
        const rowNode = this.gridOptions.api.getRowNode(i.uuId);
        return rowNode.data.taskType != 'Project' && rowNode.data.autoScheduling;
      }).length < 1;
      
    },
    disableAutoScheduleTask() {
      if ( this.taskSelection.find(i => i.uuId == 'ROOT') != null) {
        return true;
      }
      return this.taskSelection
      .filter(i => {
        const rowNode = this.gridOptions.api.getRowNode(i.uuId);
        return rowNode.data.taskType != 'Project' && rowNode.data.autoScheduling != true;
      }).length < 1;
    },
    schedulerMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    viewMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    filterMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    tcShowApplyAllCheckbox() {
      return this.tcConfirmDeleteTasks.length > 0;
    },
    tcConfirmDeleteStatement() {
      const key = this.taskCol.parentName == null? 'task.confirmation.taskcol_delete_from_project' : 'task.confirmation.taskcol_delete';
      return this.$t(key, [this.taskCol.taskName, this.taskCol.parentName, this.project ? this.project.name : 'Project']);
    },
    maxNameLength() {
      var values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === 'name';
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === 'identifier';
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxDescriptionLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === 'description';
      });
      return values.length !== 0 ? values[0].max : 10000;
    },
  },
  methods: {
    async onGridReady(/*params*/) {
      //Set up a flag when grid is ready before customFields data. 
      //The flag will be used by beforeMount() to continue load layout profile.
      if (!this.isCustomFieldsReady) {
        this.isGridReady = true
      } else {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
        await this.loadLayoutProfile();

        const invalidList = ['tasks', 'gantt', 'staff'];
        if(this.id && !invalidList.includes(this.id)) {
          this.gridApi.showLoadingOverlay();
        } else {
          this.gridApi.showNoRowsOverlay();
        }
      }
    },
    async taskTreeList(projectId, fields) {
      let data = await taskViewService.listTree({ start: 0, limit: -1, ksort: this.sort }, projectId, fields, true, this.customFields
                                                , { skillCustomFields: this.skillCustomFields, resourceCustomFields: this.resourceCustomFields })
      .then(response => {
        return response.data || [];
      })
      .catch(e => {
        // console.error(e); // eslint-disable-line no-console
        if (e != null && e.response != null && 
            e.response.data != null && e.response.data.jobClue != null) {
          if (e.response.data.jobClue.clue == 'Forbidden_entity') {
            this.noRowsMessage = this.$t('entity_selector.error.insufficient_permission_to_show_data');
          } else {
            this.noRowsMessage = null;
          }
        }
        return [];
      });
      return data;
      
    },
    async loadTasks(projectId, callback) {
      if(this.gridApi) {
        //Register current scroll position before refresh data
        const { top } = this.gridApi.getVerticalPixelRange();
        const { left } = this.gridApi.getHorizontalPixelRange();
        this.previousScrollPosTop = top;
        this.previousScrollPosLeft = left;
      }
      if(this.gridColumnApi) {
        this.columnCache = this.gridColumnApi.getAllDisplayedColumns().filter(i => i.colId !== 'ag-Grid-AutoColumn').map(i => i.colId);

        //Make sure taskPath is in the columnCache. TaskPath is important to determine if the autoGroupColumn link is being rendered.
        if (!this.columnCache.includes('taskPath')) {
          this.columnCache.push('taskPath');
        } 
      } else {
        this.columnCache = [];
      }

      //Clear/reset the list whenever data is reloaded
      if (this.originalRowDataList.length > 0) {
        this.originalRowDataList.splice(0, this.originalRowDataList.length)
      }
      
      const list = await this.taskTreeList(projectId, this.columnCache);
      list.sort((a, b) => {
        return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0; 
      });

      list.forEach(i => {
        const filtered = list.filter(j => j.pUuId == i.uuId);
        if(filtered.length > 0) {
          i.children = filtered;
        }
        this.taskNames[i.uuId] = {name: i.name};
      });

      const populatePath = (path, children) => {
        if(children) {
          for(const child of children) {
            child.path = [...path, child.uuId];
            this.taskNames[child.uuId].path = child.path;
            populatePath(child.path, child.children);
          }
        }
      }
      populatePath([], list.filter(i => i.pUuId == 'ROOT'));
     
      const removeChildrenProperties = (list) => {
        if(list) {
          for(const item of list) {
            delete item.children;
          }
        }
      }
      removeChildrenProperties(list);

      this.rowData = list;
      if(this.gridApi) {
        this.gridApi.showLoadingOverlay();
        this.gridApi.setRowData(this.rowData);
        if(this.rowData.length < 1) {
          this.gridApi.showNoRowsOverlay();
        } else {
          this.gridApi.hideOverlay();
        }
      }

      if(callback && typeof callback === 'function') {
        callback();
      }

      if (this.taskDialogOpen) {
        this.taskDialogOpen = false;
        this.openDetail(this.taskDialogId);
        this.taskDialogId = null;
      }
    
      this.$nextTick(() => {
        // expand the tasks
        this.expandLevels();
      });
      
    },
    openDetail(id) {
      this.taskEdit.uuId = id;
      this.taskEdit.parentId = null;
      this.taskEditShow = true;
      this.resetAlert();
    },
    detailLinkLabel(params) {
      return params.data.name;
    },
    detailLinkId(params) {
      return params.data.uuId;
    },
    ok() {
      const details = this.gridApi.getSelectedNodes().map(i => { return {uuId: i.data.uuId, name: i.data.name} });
      this.$emit('ok', { ids: [...this.selected], details: details });
    },
    taskEditSuccess(payload) {
      this.loadTasks(this.id, () => {
        // Make sure the new task node is expanded and visible.
        if (this.taskEdit.uuId.startsWith('TASK_NEW_')) {
          if (this.taskEdit.parentId == null || this.taskEdit.parentId == this.id) {
            return;
          }

          this.manualExpandedSet.add(this.taskEdit.parentId);
          setTimeout(() => {
            this.processNodes();
          }, 0);
        }
      });
      this.resetAlert({ msg: payload.msg });
    },
    taskEditOpen(isNew) {
      this.taskEdit.parentId = null;//Reset to default
      if(isNew || this.taskSelection.length == 0) {
        const api = this.gridOptions.api;
        this.taskEdit.uuId = `TASK_NEW_${strRandom(5)}`;
        let selectedTask = this.taskSelection.length > 0? this.taskSelection[0] : null;
        //Handle special case: Click add task button when user select an empty task Col cell.
        //Find the row node where the selected column resides and set it as parent.
        if (selectedTask == null && this.isCompactView) {
          const cellRanges = api.getCellRanges();
          if (cellRanges.length > 0) {
            const range = cellRanges[0];
            const rowIndex = range.startRow.rowIndex > range.endRow.rowIndex? range.endRow.rowIndex : range.startRow.rowIndex;
            const rowNode = api.getDisplayedRowAtIndex(rowIndex);
            if (rowNode != null && range.startColumn.colId.startsWith(this.taskColPrefix)) {
              this.taskEdit.parentId = rowNode.data.uuId;
            }
          }
        } else {
          let parentId = selectedTask != null? (selectedTask.colId.startsWith(this.taskColPrefix)? this.taskSelection[0].parent : this.taskSelection[0].uuId) : null;
          if (parentId != null) {
            //Find the grand parent which taskType is 'Project' if current parent's task type is not 'Project'
            let parentNode = api.getRowNode(parentId);
            if (parentNode != null && parentNode.data != null && parentNode.data.taskType != 'Project') {
              do {
                parentNode = parentNode.parent;
              } while (parentNode.data != null && parentNode.data.taskType != 'Project');
              if (parentNode.data != null) {
                parentId = parentNode.data.uuId;
              } else {
                parentId = null;
              }
            }
          }
          this.taskEdit.parentId = parentId;
        }
      } else {
        const selectedId = this.taskSelection[0].uuId;
        this.taskEdit.uuId = selectedId;
      }
      this.taskEditShow = true;
      this.resetAlert();
    },
    rowDelete() {
      this.resetAlert();
      if (this.taskSelection.length == 0) {
        return;
      }
      const taskColTasks = this.getUpperTaskNodesFromSelection();//objectClone(this.taskSelection);
      //Prepare data for taskcol delete confirmation dialog
      this.tcConfirmDeleteTasks = taskColTasks;
      this.prepareTaskColConfirmDeleteDialog();
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      } else if (422 === response?.status) {
        const feedbackList = response.data[response.data.jobCase];
        const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
        if (msg != null) {
          alertMsg = msg;
        }
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    async taskIndent() {
      this.resetAlert();
      this.actionProcessing = true;
      //Assuming the order of taskSelection items matches the grid display order.
      const toIndentTasks = this.taskSelection.filter(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN);
      const processedTasks = [];
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('task.progress.updating_tasks');
      while (toIndentTasks.length > 0) {
        //Collect all tasks with same parent
        const processingTasks = toIndentTasks.filter(i => i.parent == toIndentTasks[0].parent);
        for (const t of processingTasks) {
          const idx = toIndentTasks.findIndex(i => i.uuId == t.uuId);
          if (idx == -1) {
            continue;
          }
          toIndentTasks.splice(idx, 1);
        }
        
        //Move to next iteration when the list is empty
        if (processingTasks.length == 0) {
          continue;
        }

        const localProcessedTasks = [];
        for (const t of processingTasks) {
          
          const rowNode = this.gridOptions.api.getRowNode(t.uuId);
          if (rowNode == null) {
            continue;
          }

          //Find new parent
          let newParentId = null;
          for (let idx = rowNode.rowIndex - 1; idx >= 0; idx--) {
            const prev = this.gridOptions.api.getDisplayedRowAtIndex(idx);
            if (prev == null || prev.level < rowNode.level) {
              break;
            } else if (prev.level > rowNode.level) {
              continue;
            } else if (prev != null 
                && prev.level === rowNode.level 
                && prev.data.taskType == 'Project' 
                && localProcessedTasks.find(i => i.uuId == prev.id) == null) {
              newParentId = prev.id;
              break;
            }
          }

          //Skip current tasks and move to next iteration when new parent can't be found.
          if (newParentId == null) {
            continue;
          }

          const newParentNode = this.gridOptions.api.getRowNode(newParentId);
          let orderRef = null;
          if (newParentNode.data != null && newParentNode.data.taskType == 'Project' && newParentNode.allLeafChildren.length > 0) {
            const level = newParentNode.allLeafChildren[0].level + 1;
            const leafChildren = newParentNode.allLeafChildren.slice(1);
            const lastChild = leafChildren.find(i => i.lastChild == true && i.level == level);
            if (lastChild != null) {
              orderRef = `next:${lastChild.data.uuId}`;
            }
          }

          localProcessedTasks.push({
            uuId: t.uuId
            , name: t.name
            , parent: newParentId
            , orderRef
          });
        }

        processedTasks.push(...localProcessedTasks.splice(0, localProcessedTasks.length));
      }

      //Call backend api to update accordingly.
      const errors = [];
      if(processedTasks.length > 0) {
        const { failed, isUnexpectedError } = await templateTaskService.updateParent(processedTasks.map(i => { return { uuId: i.uuId, parent: i.parent, orderRef: i.orderRef } }))
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'OK').map(i => i.args[0]);
            const failTasks = processedTasks.filter(i => failIds.includes(i.uuId));
            return { failed: failTasks };
          }
          return { failed: [] };
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
          if(e.response && 422 == e.response.status) {
            const data = e.response.data;
            const list = data[data.jobCase];
            const failTasks = [];
            for (let i = 0, len = list.length; i < len; i++) {
              if (list[i].clue !== 'OK') {
                failTasks.push(processedTasks[i]);
              }
            }
            return { failed: failTasks };
          } else {
            return { isUnexpectedError: true }
          }
        });
        if(isUnexpectedError) {
          if(errors.length > 0) {
            errors.push(this.$t('task.error.failed_to_indent_rest_tasks'))
          } else {
            errors.push(this.$t('task.error.failed_to_indent_tasks'));
          }
        } else {
          if(failed && failed.length > 0) {
            for(let i = 0, len = failed.length; i < len; i++) {
              errors.push(this.$t('task.error.failed_to_indent_task', [failed[i].name]));
            }
          }
        }
      }

      if(errors.length === 1) {
        this.resetAlert({ msg: errors[0], alertState: alertStateEnum.ERROR });
      } else if(errors.length > 1) {
        this.resetAlert({ msg: this.$t('task.indent_partial'), alertState: alertStateEnum.WARNING, details: errors });
      }

      this.reloadTasks(() => {
        this.$nextTick(() => {
          const api = this.gridOptions != null && this.gridOptions.api != null? this.gridOptions.api : null;
          api.clearFocusedCell();
          api.clearRangeSelection();
          this.inProgressShow = false;
        });
      });
    },
    async taskOutdent() {
      this.resetAlert();
      this.actionProcessing = true;
      const api = this.gridOptions.api;
      if (api == null) {
        return;
      }
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('task.progress.updating_tasks');
      //   Before         After
      //  --------       ------
      //  ST               ST
      //   <-ST            ST
      //     <-T   ==>     T
      //     <-T           T
      const toOutdentTasks = this.taskSelection.filter(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN);

      //Sort by rowIndex. The row order plays an important role in outdent process.
      for (const pTask of toOutdentTasks) {
        const rowData = api.getRowNode(pTask.uuId);
        if (rowData == null) {
          continue;
        }
        pTask.rowIndex = rowData.rowIndex;
      }
      toOutdentTasks.sort((a, b) => {
        if (a.rowIndex == null && b.rowIndex == null) {
          return 0;
        }
        if (a.rowIndex == null || a.rowIndex < b.rowIndex) {
          return -1;
        }
        if (b.rowIndex == null  || a.rowIndex > b.rowIndex) {
          return 1;
        }
        return 0;
      });
      
      const processedTasks = [];
      while (toOutdentTasks.length > 0) {
        //Collect all tasks with same parent
        let processingTasks = toOutdentTasks.filter(i => i.parent == toOutdentTasks[0].parent);
        for (const t of processingTasks) {
          const idx = toOutdentTasks.findIndex(i => i.uuId == t.uuId);
          if (idx == -1) {
            continue;
          }
          toOutdentTasks.splice(idx, 1);
        }
        
        //Move to next iteration when the list is empty
        if (processingTasks.length == 0) {
          continue;
        }

        //'ROOT' is an agreed value to represent root of task tree.
        //Move to next iteration when parent is ROOT, there is no point to do outdentation.
        if (processingTasks[0].parent == 'ROOT') {
          continue;
        }
        
        const parentNode = api.getRowNode(processingTasks[0].parent);
        if (parentNode == null|| parentNode.data == null || parentNode.data.pUuId == null) {
          continue;
        }
        
        //1) Find the grandparent.
        let newParentId = parentNode.data.pUuId == 'ROOT'? this.id : parentNode.data.pUuId;
        //2) If the parent is part of the processedTasks, use the new assigned parent of the parent instead.
        const found = processedTasks.find(i => i.uuId == parentNode.data.uuId);
        if (found != null) {
          newParentId = found.parent;
        }

        const localProcessedTasks = [];
        for (const t of processingTasks) {
          
          const rowNode = api.getRowNode(t.uuId);
          if (rowNode == null) {
            continue;
          }

          localProcessedTasks.push({
            uuId: t.uuId
            , name: t.name
            , parent: newParentId
            , oldParent: parentNode.data.uuId
          });
        }

        //Prepare orderAt and orderAs properties. They are needed for keeping the task order.
        for (let i = 0, len = localProcessedTasks.length; i < len; i++) {
          const t = localProcessedTasks[i];
          if (i == 0) {
            t.orderAt = t.oldParent;
            t.orderAs = true;
          } else {
            t.orderAt = localProcessedTasks[i-1].uuId;
            t.orderAs = true;
          }
        }

        processedTasks.push(...localProcessedTasks.splice(0, localProcessedTasks.length));
      }

      
      if(processedTasks.length > 0) {
        //Group task by parent
        const parentNTasksMap = new Map();
        for (const t of processedTasks) {
          let list = null;
          if (parentNTasksMap.has(t.parent)) {
            list = parentNTasksMap.get(t.parent);
          } else {
            list = [];
            parentNTasksMap.set(t.parent, list);
          }
          list.push(t);
        }
        
        const requests = [];
        for (const value of parentNTasksMap.values()) {
          const r = TaskViewRequestGenerator.outdentTask(this.id, value, { isTemplate: true });
          if (r.length > 0) {
            requests.push(...r);
          }
        }

        if (requests.length > 0) {
          //Keep the last focused cell info and reapply it after data refresh
          const resetFocus = (api, cell) => {
            api.clearRangeSelection();
            if (cell != null && cell.rowIndex != null && cell.colId != null) {
              api.setFocusedCell(cell.rowIndex, cell.colId, null);
              api.addCellRange({
                rowStartIndex: cell.rowIndex
                , rowEndIndex: cell.rowIndex
                , columnStart: cell.colId
                , columnEnd: cell.colId
              });
            }
          }

          //As recommended by Chrism, turn off project.autoScheduling during the data change. It will improve performance.
          if (this.project.autoScheduling && requests.length > 1) {
            requests.unshift({
              'note': 'Disable macros',
              'invoke': 'PUT /api/system/features?entity=macros&action=DISABLE'
            });
            requests.unshift({
              'note': 'Disable project scheduling',
              'invoke': 'PUT /api/system/features?entity=scheduling&action=DISABLE'
            });
          }
          
          await compositeService.exec(requests).then(response => {
            const feedbackList = response.data[response.data.jobCase];
            const hasNotOk = feedbackList.some(i => i.clue != 'OK');
            if (hasNotOk) {
              this.resetAlert({ msg: this.$t('task.error.failed_to_outdent_tasks'), alertState: alertStateEnum.ERROR });
            }
            this.reloadTasks(() => {
              setTimeout(() => {
              resetFocus(api, this.lastFocusedCell);
            }, 0);
              this.inProgressShow = false;
            });
          }).catch(() => {
            this.resetAlert({ msg: this.$t('task.error.failed_to_outdent_tasks'), alertState: alertStateEnum.ERROR });
            this.reloadTasks(() => {
              setTimeout(() => {
                resetFocus(api, this.lastFocusedCell);
              }, 0);
              this.inProgressShow = false;
            });
          });
        }
      }
    },
    onRowDragEnter(event) {
      this.resetAlert();
      event.api.deselectAllFiltered();
      event.api.clearRangeSelection();
      this.rowDragClearPrevNodeStyle();
      
      //Disable drag feature when dragged node is ROOT.
      if (event.node.key == 'ROOT') {
        event.api.setSuppressRowDrag(true);
        return;
      }
    },
    async onRowDragEnd(e) {
      this.rowDragClearPrevNodeStyle();
      this.draggedOverNodeHandler(null, null, true);
      //Reenable drag feature when dragged node is ROOT.
      if (e.node.key == 'ROOT') {
        e.api.setSuppressRowDrag(false);
        return;
      }
      //No reordering when drag over ROOT node
      if (e.overNode && e.overNode.key == 'ROOT') {
        return;
      }
      if(e.overNode && e.node.rowIndex != e.overIndex) {
        const node = e.node;
        const overNode = e.overNode;
        const currentY = e.y;
        const newData = cloneDeep(this.rowData);

        //0.3 equals 30% of row Height. Decide task to be inserted before or after.
        const insertAfter = Math.round(overNode.rowHeight * 0.3) < (currentY - overNode.rowTop + this.rowDragOffsetY);
        const srcIndex = newData.findIndex(i => i.uuId === node.data.uuId);
        const srcRow = newData[srcIndex];
        const targetIndex = newData.findIndex(i => i.uuId === overNode.data.uuId);
        const targetRow = newData[targetIndex];
        const targetParent = targetRow.pUuId;
        this.inProgressShow = true;
        this.inProgressLabel = this.$t('task.progress.updating_tasks');
        const result = await this.reOrderTask(srcRow.uuId, 'ROOT'===targetParent?this.id:targetParent, targetRow.uuId, insertAfter);
        if(result) {
          this.loadTasks(this.id, {
            callback: () => {
              this.$nextTick(() => {
                this.processNodes();
                this.inProgressShow = false;
              });
            }
          });
        }
      }
    },
    onRowDragMove(e) {
      if (e.overNode) {
        const overNode = e.overNode;
        let rowNodeChildren = document.querySelectorAll(
          `[row-id="${overNode.id}"] > div`
        );
        this.draggedOverNodeHandler([...rowNodeChildren], overNode.id);

        const prevOverNodeId = this.rowDragPrevOverNodeIds.pop();
        if (prevOverNodeId != null) {
          const prevOverNodeElems = document.querySelectorAll(`.ag-row[row-id="${prevOverNodeId}"]`);
          for (const el of prevOverNodeElems) {
            el.classList.remove('drag-to-bottom', 'drag-to-top');
          }
        }
        
        const node = e.node;
        if (node.id == e.overNode.id) {
          return; 
        }
        if (!this.rowDragPrevOverNodeIds.includes(overNode.id)) {
          this.rowDragPrevOverNodeIds.push(overNode.id);
        }
        
        const currentY = e.y;
        //0.3 equals 30% of row Height. Decide task to be inserted before or after.
        const insertAfter = Math.round(overNode.rowHeight * 0.3) < (currentY - overNode.rowTop) + this.rowDragOffsetY;
        const rowElems = document.querySelectorAll(`.ag-row[row-id="${overNode.id}"]`);
        for (const el of rowElems) {
          if (insertAfter) {
            el.classList.add('drag-to-bottom');
          } else {
            el.classList.add('drag-to-top');
          }
        }
      }
    },
    onRowDragLeave(/*e*/) {
      this.rowDragClearPrevNodeStyle();
      this.draggedOverNodeHandler(null, null, true);
    },
    rowDragClearPrevNodeStyle() {
      for (const id of this.rowDragPrevOverNodeIds) {
        const prevOverNodeElems = document.querySelectorAll(`.ag-row[row-id="${id}"]`);
        for (const el of prevOverNodeElems) {
          el.classList.remove('drag-to-bottom', 'drag-to-top');
        }
      }
    },
    draggedOverNodeHandler(newNodeChildren, newNodeId, dragHasEnded = false) {
      if (dragHasEnded) {
        if(this.nodeChildrenList) {
          this.nodeChildrenList.forEach(child => {
            child.classList.remove('row-drag-hover-over');
          });
        }
        this.nodeChildrenList = null;
        this.nodeId = null;
        return;
      }

      if (this.nodeId === newNodeId) {
        return; //Do nothing if hovering over same thing
      }

      if (this.nodeId === null) {
        this.nodeId = newNodeId;
        this.nodeChildrenList = newNodeChildren;
        this.nodeChildrenList.forEach(child => {
          child.classList.add('row-drag-hover-over');
        });
      }

      if (this.nodeId !== newNodeId) {
        this.nodeChildrenList.forEach(child => {
          child.classList.remove('row-drag-hover-over');
        });
        this.nodeChildrenList = newNodeChildren;
        this.nodeId = newNodeId;
        this.nodeChildrenList.forEach(child => {
          child.classList.add('row-drag-hover-over');
        });
      }
    },
    async reOrderTask(selectId, targetParent, targetId, insertAfter) {
      let result = await templateTaskService.updateParentnOrder([{
        uuId: selectId, parent: targetParent
      }], targetId, insertAfter)
      .then(() => {
        return true;
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
        if (e.response.data.feedbackList[0].clue === 'Forbidden_relation') {
          this.resetAlert({ msg: this.$t('task.grid.error.failed_permission'), alertState: alertStateEnum.ERROR });
        }
        else {
          this.resetAlert({ msg: this.$t('task.grid.error.failed_to_reorder'), alertState: alertStateEnum.ERROR });
        }
        this.inProgressShow = false;
        return false;
      });
      return result;
    },
    taskCopy() {
      this.resetAlert();
      this.taskCopyIds.splice(0, this.taskCopyIds.length, ...this.getTaskIdForCopyOrTemplateSave());
      this.gridOptions.api.clearRangeSelection();
      this.gridOptions.api.clearFocusedCell();
    },
    async taskPaste() {
      this.actionProcessing = true;
      this.resetAlert();
      let selectedId = null;
      if (this.taskSelection.length > 0) {
        selectedId = this.taskSelection[0].uuId;
      } else {
        const focusedCell = this.gridOptions.api.getFocusedCell();
        if (focusedCell == null) {
          selectedId = null //set to null, assuming the parent will be template project
        } 
        else {
          const rowNode = this.gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
          if (rowNode == null) {
            this.actionProcessing = false;
            return;
          }
          selectedId = rowNode.data.uuId;
        }
      }
      const ids = cloneDeep(this.taskCopyIds);

      if(ids.length < 1) {
        if (this.gridOptions != null && this.gridOptions.api != null) {
          this.gridOptions.api.clearRangeSelection();
          this.gridOptions.api.hideOverlay();
        }
        this.resetAlert({ msg: this.$t('task.paste_nothing') });
        this.actionProcessing = false;
        return;
      }
      this.showInProgress(this.$t('task.progress.paste_tasks'));
      const projectId = this.id;
      await TaskTemplateDataUtil.createTemplateTasksFromTemplateTasksAdv(ids.map(i => { return { uuId: i }}), { uuId: projectId }, { uuId: projectId }, selectedId != null? { uuId: selectedId } : { uuId: projectId })
      .then(result => {
        if (result.hasError == true) {
          this.resetAlert({ msg: this.$t('error_failed_to_paste_task'), alertState: alertStateEnum.ERROR });
        }
      });

      this.reloadTasks(() => {
        this.inProgressShow = false;
      });
    },
    async getTasks(taskIds, projectId) {
      let list = await templateTaskService.listTree({ start: 0, limit: -1 }, projectId)
      .then(response => {
        return response.data || [];
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
        return [];
      });

      list.forEach(i => {
        const filtered = list.filter(j => j.pUuId == i.uuId);
        if(filtered.length > 0) {
          i.children = list.filter(j => j.pUuId == i.uuId);
        }
      });
      return list.filter(i => taskIds.includes(i.uuId));
    },
    fileExport() {
      this.resetAlert();
      const keys = this.gridOptions.columnApi
          .getAllDisplayedColumns()
          .filter(i => i.colId != 'rowSelector')
          .map(column => column.getColId());
      if (!keys.includes('taskPath')) {
        keys.push('taskPath');
      }
      if (!keys.includes('taskType')) {
        keys.push('taskType');
      }
      
      const self = this;
      this.gridOptions.api.exportDataAsExcel({ 
        fileName: 'Tasks'
        , sheetName: 'Tasks'
        , rowHeight: 20
        , columnKeys: keys
        , processCellCallback: TaskTemplateDataUtil.processCellCallback(self)
      });
    },
    autoSchedule() {
      this.resetAlert();
    },
    progressCancel() {
      this.$set(this.inProgressState, 'cancel', true);
    },
    showInProgress(label=null, isStoppable=false) {
      this.inProgressState.cancel = false;
      this.inProgressShow = true;
      this.inProgressLabel = label;
      this.inProgressStoppable = isStoppable;
    }
    , async scheduleProjectHandler(isAuto) {
      this.resetAlert();
      this.actionProcessing = true;
      const selectedTaskIds = this.gridApi.getSelectedNodes().map(i => { return i.data.uuId });
      const data = [{
        uuId: this.id
        , autoScheduling: isAuto
      }];
      await templateProjectService.update(data).then(() => {
        this.project.autoScheduling = isAuto;
        this.loadTasks(this.id, () => {
          this.$nextTick(() => {
            selectedTaskIds.forEach(s => {
              let node = this.gridApi.getRowNode(s);
              node.setSelected(true);
            });
            this.actionProcessing = false;
          });
        }); 
      });
      
    },
    async scheduleTaskHandler(isAuto) {
      const api = this.gridOptions != null? this.gridOptions.api : null;
      if (api == null) {
        return;
      }
      this.resetAlert();
      this.actionProcessing = true;

      let filtered = null;
      if (this.isCompactView) {
        //Get the task uuId from the selected summary task 
        const tasks = this.taskSelection.filter(i => i.uuId != 'ROOT' && i.colId.startsWith(this.taskColPrefix));
        filtered = [];
        for (const t of tasks) {
          const rowData = api.getRowNode(t.parent).data;
          const colObj = rowData[t.colId];
          if (colObj.uuId != null && colObj.data != null && colObj.data.autoScheduling !== isAuto) {
            filtered.push({ uuId: t.uuId, autoScheduling: isAuto });
          }
        }
      } else {
        // From the selected rows, only get the ones that are currently on auto
        filtered = this.taskSelection
                    .filter(i => {
                      if (i.uuId == 'ROOT') {
                        return false;
                      }
                      const rowNode = api.getRowNode(i.uuId);
                      if (rowNode.data.taskType != 'Project' && rowNode.data.autoScheduling === !isAuto) {
                        return true;
                      }
                      return false;
                    })
                    .map(i => { return {uuId: i.uuId, autoScheduling: isAuto} });
      }
      if (filtered.length > 0) {
        const cellRanges = api.getCellRanges().map(i => {
          return {
            rowStartIndex: i.startRow.rowIndex
            , rowEndIndex: i.endRow.rowIndex
            , columns: i.columns.map(j => j.colId)
          }
          
        });
        await templateTaskService.update(filtered).then(() => {
          this.reloadTasks(() => {
            api.clearRangeSelection();
            for (const cRange of cellRanges) {
              api.addCellRange(cRange);
            }
            if (this.lastFocusedCell != null && this.lastFocusedCell.rowIndex != null && this.lastFocusedCell.colId != null) {
              api.setFocusedCell(this.lastFocusedCell.rowIndex, this.lastFocusedCell.colId);
            }
          });
        });
      } else {
        this.actionProcessing = false;
      }
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    prepareProjectDetails() {
      templateProjectService.get([{ uuId: this.id }])
      .then(response => {
          const listName = response.data.jobCase;
          const _project = response.data[listName][0];
          this.project.name = _project.name;
          if (_project.autoScheduling) {
            this.project.autoScheduling = _project.autoScheduling;
          }
          
          this.$store.dispatch("breadcrumb/update", _project.name, { root: true });
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    manualScheduleProjectHandler(event) {
      event.preventDefault();
      if(!this.disableManualScheduleProject) {
        this.scheduleProjectHandler(false);
      }
    },
    autoScheduleProjectHandler(event) {
      event.preventDefault();
      if(!this.disableAutoScheduleProject) {
        this.scheduleProjectHandler(true);
      }
    },
    manualScheduleTaskHandler(event) {
      event.preventDefault();
      if(!this.disableManualScheduleTask) {
        this.scheduleTaskHandler(false);
      }
    },
    autoScheduleTaskHandler(event) {
      event.preventDefault();
      if(!this.disableAutoScheduleTask) {
        this.scheduleTaskHandler(true);
      }
    },
    onSchedulerOver() {
      this.$refs.scheduler.visible = true;
    },
    onSchedulerLeave() {
      this.$refs.scheduler.visible = false;
    },
    onViewOver() {
      this.$refs.view.visible = true;
    },
    onViewLeave() {
      this.$refs.view.visible = false;
    },
    onFilterOver() {
      this.$refs.filter.visible = true;
    },
    onFilterLeave() {
      this.$refs.filter.visible = false;
    },
    onFilterSubmit() {
      this.filterTextFocus = false;
      this.$refs.filter.visible = false;
      this.closePriorityNavDropdown = true; //Signal priorityNavigation to close the dropdown.
    },
    savePreset() {
      this.saveName = null;
      this.saveIndex = -1;
      this.promptSaveShow = true;
    },
    confirmSaveOk() {
      const columns = this.gridOptions.columnApi.getAllDisplayedColumns();      
      if (this.saveIndex !== -1) {
        const view = { name: this.saveName, expandLevel: this.expandLevel, columns: columns.map(c => { return { colId: c.colId, width: c.actualWidth, sort: c.sort }}) };
        this.userProfile.views.splice(this.saveIndex, 1, view);
      }
      else {
        this.userProfile.views.push({ name: this.saveName, expandLevel: this.expandLevel, columns: columns.map(c => { return { colId: c.colId, width: c.actualWidth, sort: c.sort }}) });
      }
      this.updateUserProfile();
    },
    toggleCurrentColumnVisibility(toHide) {
      const columnDefs = this.gridOptions.api.getColumnDefs();
      for (const cDef of columnDefs) {
        if (cDef.colId == 'rowSelector') {
          cDef.hide = false; //rowSelector is always visible.
          continue;
        }
        if (cDef.groupId != null && cDef.groupId.startsWith(this.taskGroupPrefix)) {
          const children = cDef.children;
          if (children == null || children.length == 0) {
            continue;
          }
          for (const child of children) {
            child.hide = toHide;
          }
        } else {
          cDef.hide = toHide;
        }
      }
      this.gridOptions.api.setColumnDefs(columnDefs);
    },
    showAllColumns() {
      this.toggleCurrentColumnVisibility(false);
    },
    showNoColumns() {
      this.toggleCurrentColumnVisibility(true);
    },
    updateColumnSettings(index, name) {
      this.promptSaveShow = true;
      this.saveName = name;
      this.saveIndex = index;
    },
    removeColumnSettings(index) {
      this.confirmDeleteViewShow = true;
      this.deleteViewIndex = index;
    },
    confirmDeleteViewOk() {
      this.userProfile.views.splice(this.deleteViewIndex, 1);
      this.updateUserProfile();
    },
    initializeLayoutProfile() {
      if (!Object.prototype.hasOwnProperty.call(this.layoutProfile, 'taskColumns')) {
        this.layoutProfile.taskColumns = [];
      }
    },
    createLayoutProfile() {
      this.initializeLayoutProfile();
      layoutProfileService.create([this.layoutProfile], this.entityId, this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.layoutProfile.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    updateLayoutProfile() {
      if (!Object.prototype.hasOwnProperty.call(this.layoutProfile, 'uuId')) {
        // Dataviews are triggering the watchers when opening the
        // relevant tab and trying to save. Ignore those since nothing
        // has loaded yet.
        return;
      }
      layoutProfileService.update([this.layoutProfile], this.entityId, this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadLayoutProfile() {
      const self = this;
      await layoutProfileService.list(this.entityId, this.userId).then((response) => {
        const profileData = response.data[response.data.jobCase];
        if (profileData.length === 0) {
          self.createLayoutProfile();
        } else {
          self.layoutProfile = profileData[0];
          self.initializeLayoutProfile();
          self.loadColumnSettings();
          self.expandLevels();
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      })
      .finally(() =>{
        if(this.id && !this.invalidList.includes(this.id)) {
          const self = this;
          this.loadTasks(this.id, () => {
            if(self.gridApi) {
              if(self.rowData.length < 1) {
                self.gridApi.showNoRowsOverlay();
              } else {
                self.gridApi.hideOverlay();
              }
              self.processNodes();
            }
          });
        }
      });
    },
    initializeUserProfile() {
      if (!Object.prototype.hasOwnProperty.call(this.userProfile, 'views')) {
        this.userProfile.views = [];
      }
    },
    createUserProfile() {
      this.initializeUserProfile();
      viewProfileService.create(this.userProfile, this.userId).then((response) => {
        const data = response.data[response.data.jobCase];
        this.userProfile.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    updateUserProfile() {
      viewProfileService.update([this.userProfile], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadUserProfile() {
      const self = this;
      this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {
        const profileData = value;
        if (profileData.length === 0) {
          self.createUserProfile();
        } else {
          self.userProfile = profileData[0];
          self.initializeUserProfile();
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      })
    },
    loadColumnSettings() {
      const api = this.gridOptions.api;
      if (api == null) {
        return;
      }
      const self = this;
      const columns = this.layoutProfile.taskColumns;
      if (columns.length == 0) {
        // The user doesn't have custom columns yet, so use defaults
        return;
      }
      
      // order the columns based upon the order in 'columns'
      let idx = 0;

      //Set autoGroupColumn
      const autoGroupSetting = columns.find(i => i.colId == 'ag-Grid-AutoColumn');
      this.autoGroupColumnDef.width = autoGroupSetting.width;
      this.autoGroupColumnDef.sort = autoGroupSetting.sort;
      this.autoGroupColumnDef.sortIndex = autoGroupSetting.sortIndex;
      api.setAutoGroupColumnDef({
        ...this.autoGroupColumnDef
      })

      columns.forEach(function(col) {
        const index = self.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          self.columnDefs.splice(idx++, 0, self.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of this.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field || c.colId === column.colId);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }

      const index = this.columnDefs.findIndex(i => i.field == 'taskType');
      if (index != -1) {
        this.columnDefs[index].cached = true;
      }

      api.setColumnDefs([]);
      api.setColumnDefs(this.columnDefs);
      api.resetRowHeights();
      
      //Fix ag-grid glitch: AutoGroupColumn Sort indicator is not rendered on load even though the sort property is set
      setTimeout(() => {
        if (this.gridOptions?.api != null) {
          this.gridOptions.api.refreshHeader();
        }
      }, 500);
      return false;
    },
    expandLevels() {
      if (typeof this.layoutProfile.taskExpandLevel !== 'undefined') {
        this.expandLevel = this.layoutProfile.taskExpandLevel;
        this.processNodes();
      }
    },
    processNodes() {
      this.maxLevel = 0;

      if (this.gridOptions == null || this.gridOptions.api == null) {
        return;
      }
      //Fixed #897, use setTimeout to make sure grid node is refreshed.
      setTimeout(() => {
        this.isTaskExpandedByActionBar = true;
        const mExpandedSet = this.manualExpandedSet;
        const mCollpsedSet = this.manualCollapsedSet;
        if (this.gridOptions != null && this.gridOptions.api != null) {
          this.gridOptions.api.forEachNode(node => {
            if (mExpandedSet.has(node.data.uuId)) {
              node.expanded = true;
            } else if (mCollpsedSet.has(node.data.uuId)) {
              node.expanded = false;
            } else {
              if (node.level < this.expandLevel) {
                node.expanded = true;
              } else if (node.allChildrenCount && node.allChildrenCount > 0) {
                node.expanded = false;
              }
            }
            
            // update the max level
            if (node.level > this.maxLevel) {
              this.maxLevel = node.level;
            }
          });
          this.gridOptions.api.onGroupExpandedOrCollapsed();
        }
        
        if (this.maxLevel < this.expandLevel) {
          this.expandLevel = this.maxLevel;
        }

        //setTimeout is used to wait the onRowGroupOpened events finish their process.
        setTimeout(() => {
          this.isTaskExpandedByActionBar = false;
        }, 300);

      }, 50)
    },
    collapse() {
      this.expandLevel--;
      this.processNodes();
      
      // save
      this.layoutProfile.taskExpandLevel = this.expandLevel;
      this.updateLayoutProfile();
    },
    expand() {
      this.expandLevel++;
      this.processNodes();
      
      // save
      this.layoutProfile.taskExpandLevel = this.expandLevel;
      this.updateLayoutProfile();
    },
    async populateTaskConstraint() {
      if (this.optionConstraint.length !== 0) {
        return;
      }
      
      const service = templateTaskService;
      let list = await service.optionConstraint()
      .then(response => {
        return response;
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      });
      this.constraintMap = {};
      const self = this;
      this.optionConstraint.splice(0, this.optionConstraint.length, ...list.map(i => { 
        self.constraintMap[this.$t(`constraint_type.${i.label}`)] = i.label;
        return { value: i.label, text: this.$t(`constraint_type.${i.label}`)} 
      }));
    },
    async updateProject(data, pService) {
      const projectData = cloneDeep(data);
      delete projectData['taskList'];
      
      await pService.update(projectData)
      .then(response => {
        const list = response.data[response.data.jobCase];
        return list && list.length > 0? list[0] : null;
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
        return null;
      });
    },
    async updateTags(taskId, oldTagList, newTagList) {
      const oldTagNames = objectClone(oldTagList);
      const oldTagFilters = [];
      for(const t of oldTagNames) {
        if (t == null || t.trim().length == 0) {
          continue;
        }
        oldTagFilters.push({ value: t.trim(), regex: false });
      }      
      let oldTags;
      if (oldTagFilters.length > 0) {
        oldTags = await tagService.list_with_filters({filters: oldTagFilters}).then((response) => {
          return response.data.filter(i => i.uuId != null);
        });
      } else {
        oldTags = [];
      }

      const newTagNames = objectClone(newTagList);
      const newTagFilters = [];
      for(const t of newTagNames) {
        if (t == null || t.trim().length == 0) {
          continue;
        }
        newTagFilters.push({ value: t.trim(), regex: false });
      }      
      let newTags;
      if (newTagFilters.length > 0) {
        newTags = await tagService.list_with_filters({filters: newTagFilters}).then((response) => {
          const data = response.data;
          const list = [];
          for (const f of newTagFilters) {
            const found = data.find(i => i.name.localeCompare(f.value, undefined, { sensitivity: 'base' }) === 0);
            if (found != null) {
              list.push(found);
            }else {
              list.push({ uuId: null, name: f.value });
            }
          }
          return list;
        });
      } else {
        newTags = [];
      }
      return TaskViewRequestGenerator.updateTag(taskId, oldTags, newTags, { isTemplate: true });
    },
    async systemLocationCalendar() {
      let data = await calendarService.get([{ uuId: '00000000-0000-0000-0000-000000000000'}])
      .then(response => {
        return (response && response.data? response.data : []) || [];
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      })
      if (data.length > 0) {
        this.digestCalendarResponse(data, ['base_calendar']);
      } else {
        this.calendar = DEFAULT_CALENDAR; // Fallback to default calendar.
      }
    },
    digestCalendarResponse(data, calendarOrder=['location','base_calendar']) {
      const calendar = {};
      const existingTypes = [];
      for(const order of calendarOrder) {
        const calObj = data.find(i => i.name === order); 
        if(!calObj) {
          continue;
        }
        const calendarList = calObj.calendarList;
        
        for(const type of calendarList) {
          if (!type || type.length < 1 || (calendar[type.type] && calendar[type.type][0].calendar !== order)) {
            continue;
          }
          if(!calendar[type.type]) {
            existingTypes.push(type.type);
            calendar[type.type] = [];
          }
          const cloned = cloneDeep(type);
          cloned.calendar = order;
          if(cloned.startHour) {
            cloned.startHour = msToTime(cloned.startHour);
          }
          if(cloned.endHour) {
            cloned.endHour = msToTime(cloned.endHour);
          }
          calendar[type.type].push(cloned);
        }
      }
      
      const nonDayOfWeek = ['Leave', 'Working']
      const types = Object.keys(calendar);
      for(const type of types) {
        const typeObj = calendar[type];
        if(!nonDayOfWeek.includes(type) || typeObj.isWorking) {
          //typeObj.startHour = msToTime(typeObj.startHour);
          //typeObj.endHour = msToTime(typeObj.endHour);
        }
      }
      this.$set(this, 'calendar', calendar);
    },
    resetPreviousScrollPosition(left, top) {
      // Reset value
      this.$nextTick(() => {
        if (left > -1 && this.gridApi && this.gridApi.gridBodyCtrl && this.gridApi.gridBodyCtrl.bodyScrollFeature) {
          this.gridApi.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(top);
        }
        if (top > -1 && this.gridApi && this.gridApi.gridBodyCtrl && this.gridApi.gridBodyCtrl.bodyScrollFeature) {
          this.gridApi.gridBodyCtrl.bodyScrollFeature.setHorizontalScrollPosition(left);
        }
        //After use, reset to -1
        this.previousScrollPosTop = -1;
        this.previousScrollPosLeft = -1;
      });
    },
    hasImage(params) {
      return this.gridOptions.columnApi.getColumn("avatarRef") !== null && 
             this.gridOptions.columnApi.getColumn("avatarRef").visible && 
             params.data.avatarRef && 
             params.data.avatarRef !== "00000000-0000-0000-0000-000000000000";
    },
    onClickGrid(event) {
      const src = event.target;
      const viewport = document.querySelector(".ag-center-cols-viewport");
      if (viewport) {
        if (viewport === src) {
          this.gridApi.stopEditing();
        }
      }
    },
    stopEditing(event) {
      const src = event.target;
      var parentElement = src.parentElement;
      var isGrid = src.classList.contains('ag-cell-wrapper');
      var isEmptyArea = src.classList.contains('ag-body-viewport');
      var element = document.getElementById('project-tasks-grid');
      var rect = element.getBoundingClientRect();
      
      if (isEmptyArea &&
          this.gridApi !== null) {
        // user clicked on the empty area in the grid where no rows exist
        this.gridApi.stopEditing();
        return;
      }
      
      // Some Span elements do not get detected by element.contains so
      // the client coordinates must be used
      if ((src !== element && element.contains(src)) ||
          (event.clientX > rect.left && event.clientX < rect.right &&
           event.clientY > rect.top && event.clientY < rect.bottom)) {
        isGrid = true;
      }
  
      var isModal = src.firstChild &&
                    typeof src.firstChild.classList !== 'undefined' && 
                    src.firstChild.classList.contains('modal-dialog');
      while (parentElement) {
        if (parentElement.id === 'project-tasks-grid') {
          isGrid = true;
          break;
        }
        else if (parentElement.classList.contains('modal-content')) {
          isModal = true;
          break;
        }
        parentElement = parentElement.parentElement;
      }
      
      if (!isGrid && !isModal &&
          this.gridApi !== null) {
        this.gridApi.stopEditing();
      }
    },
    async updateTask(method, data, projectId) {
      const result = {
        hasError: false,
        msg: this.$t(`task.${method}`)
      }
      const service = templateTaskService;
      let taskId = await service[method](data, projectId)
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      }).catch(e => {
        result.hasError = true;
        result.msg = this.$t(`task.error.failed_to_${method}_task`);
        if(e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          let hasFieldError = false;
          const clues = ['missing_argument', 'invalid_value', 'column_limit_exceeded']
          for(let i = 0, len = list.length; i < len; i++) {
            if(clues.includes(list[i].clue)) {
              let spot = list[i].spot || [];
              spot = spot.length > 0? spot[0] : '';
              spot = spot.length == 1? spot.toLowerCase() : spot.length > 1? spot.charAt(0).toLowerCase() + spot.substring(1) : spot;
              this.errors.add({
                field: `task.${spot}`,
                msg: this.$t(`error.${list[i].clue}`, [this.$t(`task.field.${spot}`)])
              });
              hasFieldError = true;
            }
          }
          if(hasFieldError) {
            result.msg = this.$t(`error.attention_required`);
          }
        }
      });
      result.taskId = taskId;
      return result;
    },
    loadViewSettings(view) {
      if (typeof view.expandLevel !== 'undefined') {
        this.layoutProfile.taskExpandLevel = this.expandLevel = view.expandLevel;
        this.processNodes();
      }
      this.layoutProfile.taskColumns = view.columns;
      this.loadColumnSettings();
      // Save the new layout after applying it
      this.updateLayoutProfile();

      const shownColumns = view.columns? view.columns.map(i => i.colId): [];
      if (shownColumns.some(i => !this.columnCache.includes(i))) {
        this.loadTasks(this.id, () =>{
          if(this.gridApi) {
            if(this.rowData.length < 1) {
              this.gridApi.showNoRowsOverlay();
            } else {
              this.gridApi.hideOverlay();
            }
          }
        });
      }
    },
    dateTimeDurationValueChanged(taskId, property, newValue, rowData, { skipOutOfProjectDateCheck=false, defaultActionForNonWorkPrompt=null }={}) {
      //Change lock state to true to prevent other thread calling dateTimeDurationValueChanged.
      //Reason: The TaskDateTimeDurationCalculation modal can only be called one at a time.
      this.isDateCalcInProgress = true;

      let trigger = TRIGGERS.DURATION;
      if (property == 'startTime') {
        trigger = TRIGGERS.START_DATE;
      } else if (property == 'closeTime') {
        trigger = TRIGGERS.CLOSE_DATE;
      } else if (property == 'constraint') {
        trigger = TRIGGERS.CONSTRAINT_TYPE;
      } else if (property == 'autoScheduling') {
        trigger = TRIGGERS.TASK_SCHEDULE_MODE;
      }

      const nodeData = rowData;
      let newDateStr = null;
      let newTimeStr = null;
      let newConstraintType = null;
      let newConstraintDateStr = null;
      let newAutoScheduling = null;
      if (TRIGGERS.START_DATE == trigger || TRIGGERS.CLOSE_DATE == trigger) {
        const newDateTime = newValue != null? moment.utc(newValue) : null;
        if (newDateTime != null) {
          newDateStr = newDateTime.format('YYYY-MM-DD');
          newTimeStr = newDateTime.format('HH:mm');
        }
      } else if (TRIGGERS.CONSTRAINT_TYPE == trigger || TRIGGERS.CONSTRAINT_DATE == trigger) {
        if (newValue != null) {
          newConstraintType = newValue.type;
          newConstraintDateStr = newValue.time != null? moment.utc(newValue.time).format('YYYY-MM-DD') : null;
        }
      } else if (TRIGGERS.TASK_SCHEDULE_MODE == trigger) {
        if (newValue != null) {
          newAutoScheduling = newValue;
        }
      }
      const startDateTime = nodeData.startTime != null? moment.utc(nodeData.startTime) : null;
      const closeDateTime = nodeData.closeTime != null? moment.utc(nodeData.closeTime) : null;
      const constraintDateTime = nodeData.constraint.time != null? moment.utc(nodeData.constraint.time) : null;
      
      const startDateStr = startDateTime != null? startDateTime.format('YYYY-MM-DD') : null;
      const startTimeStr = startDateTime != null? startDateTime.format('HH:mm') : null;
      const closeDateStr = closeDateTime != null? closeDateTime.format('YYYY-MM-DD') : null;
      const closeTimeStr = closeDateTime != null? closeDateTime.format('HH:mm') : null;
      const constraintType = nodeData.constraint.type != null? nodeData.constraint.type : null;
      const constraintDateStr = constraintDateTime != null? constraintDateTime.format('YYYY-MM-DD') : null;

      const dc = this.durationCalculation;
      dc.taskId = taskId;
      dc.trigger = trigger;
      dc.taskName = nodeData.name;
      // dc.calendar = this.project != null && this.project.locationId != null? this.calendar : null;
      dc.oldDateStr = null;
      dc.oldTimeStr = null;
      if (TRIGGERS.START_DATE == trigger || TRIGGERS.START_TIME == trigger) {
        dc.startDateStr = newDateStr;
        dc.startTimeStr = newTimeStr;
        dc.oldDateStr = startDateStr;
        dc.oldTimeStr = startTimeStr;
      } else {
        dc.startDateStr = startDateStr;
        dc.startTimeStr = startTimeStr;
      }
      if (TRIGGERS.CLOSE_DATE == trigger || TRIGGERS.CLOSE_TIME == trigger) {
        dc.closeDateStr = newDateStr;
        dc.closeTimeStr = newTimeStr;
        dc.oldDateStr = closeDateStr;
        dc.oldTimeStr = closeTimeStr;
      } else {
        dc.closeDateStr = closeDateStr;
        dc.closeTimeStr = closeTimeStr;
      }
      //Make sure duration value is in string format
      let rawDuration = nodeData.duration;
      if (rawDuration != null && typeof rawDuration != 'string') {
        rawDuration = convertDurationToDisplay(rawDuration, nodeData.durationAUM != null? nodeData.durationAUM: 'D', this.durationConversionOpts);
      }
      dc.durationDisplay = TRIGGERS.DURATION == trigger? newValue : rawDuration;
      dc.lockDuration = nodeData.lockDuration != null? nodeData.lockDuration : false;
      
      if (TRIGGERS.CONSTRAINT_TYPE == trigger || TRIGGERS.CONSTRAINT_DATE == trigger) {
        dc.constraintType = newConstraintType;
        dc.constraintDateStr = newConstraintDateStr;
        dc.oldConstraintType = constraintType;
        dc.oldConstraintDateStr = constraintDateStr;
      } else {
        dc.constraintType = constraintType;
        dc.constraintDateStr = constraintDateStr;
      }
      
      dc.skipOutOfProjectDateCheck = skipOutOfProjectDateCheck == true;
      dc.defaultActionForNonWorkPrompt = defaultActionForNonWorkPrompt != null? defaultActionForNonWorkPrompt : null;

      dc.taskAutoScheduleMode = newAutoScheduling != null? newAutoScheduling : (nodeData.autoScheduling != null? nodeData.autoScheduling : true);
      dc.projectScheduleFromStart = true;
      dc.projectStartDateStr = null;
      dc.projectCloseDateStr = null;
      dc.calendar = null;
      
      //Start calculation
      //$nextTick is required to wait for the on-going ui rendering to be done.
      this.$nextTick(() => {
        this.durationCalculationShow = true;
      });
    },
    async durationCalculationOk({ skip=false, startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay, constraintType, constraintDateStr, taskAutoScheduleMode=null }) {
      this.isDateCalcInProgress = false;
      if (skip) {
        //Call processValueChanged() for next iteration.
        this.inProgressLabel = this.$t('task.progress.updating_tasks');
        this.processValueChanged(this.gridOptions.api);
        return;
      }

      const task = { uuId: this.durationCalculation.taskId }
      if (startDateStr != null) {
        const startDateTime = moment.utc(startDateStr, 'YYYY-MM-DD');
        if (startTimeStr != null) {
          const token = startTimeStr.split(':');
          startDateTime.hour(token[0]).minute(token[1]);
        }
        task.startTime = startDateTime.valueOf();
      } else {
        task.startTime = null;
      }

      if (closeDateStr != null) {
        const closeDateTime = moment.utc(closeDateStr, 'YYYY-MM-DD');
        if (closeTimeStr != null) {
          const token = closeTimeStr.split(':');
          closeDateTime.hour(token[0]).minute(token[1]);
        }
        task.closeTime = closeDateTime.valueOf();
      } else {
        task.closeTime = null;
      }

      if (taskAutoScheduleMode != null) {
        task.autoScheduling = taskAutoScheduleMode;
      } else if (TRIGGERS.TASK_SCHEDULE_MODE === this.durationCalculation.trigger) {
        task.autoScheduling = this.durationCalculation.taskAutoScheduleMode;
      }

      if (durationDisplay != null) {
        const { value } = convertDisplayToDuration(durationDisplay, this.durationConversionOpts);
        const { unit } = analyzeDurationAUM(durationDisplay);
        task.durationAUM = unit;
        task.duration = value;
      } else if (task.autoScheduling == true) {
        task.durationAUM = 'D';
        task.duration = this.durationConversionOpts.hourPerDay * 60; // 1 day = 480 minutes
      } else {
        task.duration = null; 
      }

      task.constraintType = constraintType;
      if (constraintDateStr != null) {
        task.constraintTime = moment.utc(constraintDateStr, 'YYYY-MM-DD').valueOf();
      } else {
        task.constraintTime = null;
      }

      this.pendingProcessRequestList.push({
        method: 'PUT',
        invoke: `/api/template/task/update`,
        body: [task],
        vars: [],
        note: `templateTaskUpdate__${task.uuId}`
      });

      //Last, call processValueChanged() for next iteration
      this.inProgressLabel = this.$t('task.progress.updating_tasks');
      this.processValueChanged(this.gridOptions.api);
    },
    durationCalculationCancel() {
      this.isDateCalcInProgress = false;
      //As agreed with Paul, when user chose to abort the operation, cancel all the requests including the requests being processed/generated previously.
      this.pendingProcessRequestList.splice(0, this.pendingProcessRequestList.length);
      this.processDateValueChangedList.splice(0, this.processDateValueChangedList.length);
      this.processValueChangedList.splice(0, this.processValueChangedList.length);
      
      //Revert the affected row data to original value
      if (this.originalRowDataList.length > 0) {
        const api = this.gridOptions.api;
        const oldData = JSON.parse(JSON.stringify(this.originalRowDataList));
        api.applyTransaction({ update: oldData });
        api.refreshCells({ force: true, rowNodes: oldData });
        this.originalRowDataList.splice(0, this.originalRowDataList.length);
      }
      this.inProgressShow = false;
      return;
    },
    
    onFilterTextDropdownHide(bvEvent) {
      if(this.filterTextFocus){
        bvEvent.preventDefault();
      }
    },
    changeFilter: debounce(function(value) {
      this.gridApi.setQuickFilter(value);
    }, 100),
    toDateTime(value) {
      let rawValue = value ? value : null;
      if (rawValue == 0 || rawValue == '' || rawValue == 9223372036854776000) {
        rawValue = null;
      }
      return rawValue != null? moment.utc(rawValue).format('YYYY-MM-DD hh:mm A') : null;
    },
    getUpperTaskNodesFromSelection() {
      const api = this.gridOptions.api;
      const processedTasks = [];
      const processingTasks = [];
      //Brief: Remove the selected child tasks if their parent or grand parent is also a part of the selection.
      //1) Collect the task's path. It is needed to identify the relationship of parent and child.
      for (const t of this.taskSelection) {
        if (t.colId == this.COLUMN_AGGRID_AUTOCOLUMN) {
          const cloned = objectClone(t);
          const rowNode = api.getRowNode(t.uuId);
          if (rowNode == null) {
            continue;
          }
          cloned.path = rowNode.data.path;
          cloned.pathString = rowNode.data.path.join(',');
          processingTasks.push(cloned);
        } else {
          const cloned = objectClone(t);
          const rowNode = api.getRowNode(t.parent);
          if (rowNode == null) {
            continue;
          }
          const path = objectClone(rowNode.data.path);
          path.push(t.uuId);
          cloned.path = path;
          cloned.pathString = path.join(',');
          processingTasks.push(cloned);
        }
      }

      processingTasks.sort((a, b) => {
        if(a.path.length > b.path.length) return 1;
        if(a.path.length < b.path.length) return -1;
        return 0;
      })

      //2) Remove unneeded tasks
      while (processingTasks.length > 0) {
        const task = processingTasks.splice(0, 1)[0];
        const matchPattern = [];
        for (let i = 0, len = task.path.length; i < len; i++) {
          matchPattern.push(task.path.slice(0, i+1).join(','));
        }
        
        let found = false;
        for (const mPattern of matchPattern) {
          if (processedTasks.find( i => i.pathString == mPattern) != null) {
            found = true;
            break;
          }
        }
        
        if (!found) {
          processedTasks.push(task);
        }
      }
      return processedTasks;
    },
    getTaskIdForCopyOrTemplateSave() {
      return this.getUpperTaskNodesFromSelection().map(i => i.uuId);
    },
    loseCellFocusOnEscapeKey(event) {
      if (event.keyCode !== 27) {
        return;
      }
      
      let api = this.gridOptions != null && this.gridOptions.api != null? this.gridOptions.api : null;
      if (api == null || this.taskEditShow) { //skip when cell editing is in progress
        return;
      }
      api.deselectAllFiltered();
      api.clearRangeSelection();
      api.clearFocusedCell();
    },
    fillOperation(params) {
      //Skip when the target cell is not editable or is rowSelector.
      const payload = { 
        node: params.rowNode
        , data: params.rowNode.data
        , column: params.column
        , colDef: params.column.colDef
        , api: params.api
        , columnApi: params.columnApi
        , context: params.context
      }
      if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
          !params.column.colDef.editable || params.column.colId == 'rowSelector') {
        return params.currentCellValue;
      }

      //Skip when the target cell is blacklisted
      const blackListed = ['totalActualDuration'];
      if (blackListed.includes(params.column.colId)) {
        return params.currentCellValue;
      }

      //Used to signal batch operation in onCellValueChanged event.
      this.triggeredByFillOperation = true;

      //Find the source Column, source rowNode based on the available details in params object.
      const tgtRowNode = params.rowNode;
      const targetColId = params.column.colId;
      let srcRowNode = null; 
      let sourceColId = null;
      
      if (params.direction == 'left' || params.direction == 'right') {
        srcRowNode = tgtRowNode; //Default: Same row as target.
        const columns = params.columnApi.getColumnState().filter(i => i.hide != true).map(i => i.colId);
        const targetColIdx = columns.findIndex(i => i == targetColId)
        let sourceColIdx = targetColIdx - params.currentIndex - 1;
        if (params.direction == 'left') {
          sourceColIdx = targetColIdx + params.currentIndex + 1;
        }
        sourceColId = columns[sourceColIdx];
      } else {// direction: up or down
        let srcRowIndex = tgtRowNode.rowIndex - params.currentIndex - 1;
        if (params.direction == 'up') {
          srcRowIndex = tgtRowNode.rowIndex + params.currentIndex + 1;
        }
        srcRowNode = params.api.getDisplayedRowAtIndex(srcRowIndex);
        sourceColId = targetColId; //Default: Same column as source.
      }

      let isCompatible = false;
      let srcProperty = sourceColId;
      let tgtProperty = targetColId;
      //Check if the source and target's property value type are compatible
      if (sourceColId == targetColId) {
        isCompatible = true;
      } else {
        if (sourceColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
          srcProperty = 'name';
        }
        if (targetColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
          tgtProperty = 'name';
        }
        isCompatible = TaskViewPropertyUtil.isCompatible(srcProperty, tgtProperty);
      }

      if (!isCompatible) {
        return params.currentCellValue;
      }

      let srcValue = srcRowNode.data[sourceColId == this.COLUMN_AGGRID_AUTOCOLUMN? 'name' : sourceColId];

      if ('staffs' == srcProperty && 'staffs' == tgtProperty) {
        const tgtValue = objectClone(tgtRowNode.data[targetColId]);
        let tgtStaffs = tgtValue;
        
        if (srcValue.length == 0 && tgtStaffs.length == 0) {
          return params.currentCellValue; //No change when both lists are empty.
        }

        //Remove all staff from tgtStaffs
        if (srcValue.length == 0) {
          tgtStaffs.splice(0, tgtStaffs.length);
          return tgtValue;
        }

        const updatedStaffs = [];
        for (const srcStaff of srcValue) {
          const found = tgtStaffs.find(i => i.uuId == srcStaff.uuId);
          if (found != null) {
            //Update tgtStaff with srcStaff's utilization. Keep the rest.
            found.utilization = srcStaff.utilization;
            updatedStaffs.push(found);
          } else {
            //Clean up workEffort (duration, durationAUM) when add to new list.
            delete srcStaff.duration;
            delete srcStaff.durationAUM;
            updatedStaffs.push(srcStaff);
          }
        }
        tgtStaffs.splice(0, tgtStaffs.length, ...updatedStaffs);
        return tgtValue;
      }

      if ('notes' == srcProperty && 'notes' == tgtProperty) {
        const tgtValue = objectClone(tgtRowNode.data[targetColId]);
        let tgtNotes = tgtValue;
        if (srcValue.length == 0 && tgtNotes.length == 0) {
          return params.currentCellValue; //No change when both lists are empty.
        }

        //Remove the top item from the tgtNotes when src is empty
        if (srcValue.length == 0) {
          tgtNotes.shift();
          return tgtValue;
        }

        const newNote = objectClone(srcValue[0]);
        if (newNote.identifier == null || newNote.identifier.trim().length == 0) {
          delete newNote.identifier;
        }
        delete newNote.uuId;
        tgtNotes.unshift(newNote);
        return tgtValue;
      }

      if ('name' === tgtProperty || 'description' === tgtProperty || 'identifier' === tgtProperty) {
        srcValue = this.truncatePropValue(tgtProperty, srcValue);
      }
      
      return srcValue;
    },
    getRowSelectorColumn() {
      return {
        headerName: '',
        colId: 'rowSelector',
        field: 'taskType',
        width: 67,
        minWidth: 67,
        maxWidth: 67,
        hide: false,
        cellRenderer: 'rowSelectorCellRenderer',
        cellRendererParams: {
          isReadOnly: !this.canEdit(),
          enableReadonlyStyle: true
        },
        pinned: 'left',
        lockPosition: 'left',
        lockVisible: true,
        suppressColumnsToolPanel: true,

        menuTabs: ['generalMenuTab'],
        resizable: false,
        headerComponent: 'selectionHeaderComponent',
        suppressFillHandle: true ,
        rowDrag: true,
        rowDragText: function(params) {
          return params.rowNode && params.rowNode.data? params.rowNode.data.name : params.defaultTextValue;
        },
      }
    },
    //Referenced in RowSelector.vue
    rowSelectorMouseDown(rowIndex=null) {
      if (rowIndex == null) {
        return;
      }
      
      //Consolidate all ranges's row and column details into rowColumnMap 
      const rowColumnMap = new Map();
      const cellRanges = this.gridOptions.api.getCellRanges();
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex;
        const rowEndIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex;
        const columns = cRange.columns.map(i => i.colId);
        if (rowStartIndex == rowEndIndex) {
          if (!rowColumnMap.has(rowStartIndex)) {
            rowColumnMap.set(rowStartIndex, new Set());
          }
          const rCol = rowColumnMap.get(rowStartIndex);
          for (const col of columns) {
            if (col == 'rowSelector') {
              continue;
            }
            rCol.add(col);
          }
          continue;
        }

        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          if (!rowColumnMap.has(i)) {
            rowColumnMap.set(i, new Set());
          }
          const rCol = rowColumnMap.get(i);
          for (const col of columns) {
            rCol.add(col);
          }
        }
      }

      const maxColumnsLength = this.gridOptions.columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').length;
      //Reset list
      this.rowSelectorClicked_allColsSelectedRowIndexList.splice(0, this.rowSelectorClicked_allColsSelectedRowIndexList.length);
      //Check which row has full set of columns in range selection.
      //When the row has full set, add it to tobeUnselected list
      for (let [key, value] of rowColumnMap) {
        if (value.size == maxColumnsLength) {
          this.rowSelectorClicked_allColsSelectedRowIndexList.push(key);
        }
      }
    },
    //Referenced in RowSelector.vue
    rowSelectorMouseUp({ ctrlKey=false, shiftKey=false, rowIndex=null } = {}) {
      const cellRanges = this.gridOptions.api.getCellRanges();
      const originalRanges = cellRanges.map(i => {
        return {
          rowStartIndex: i.startRow.rowIndex,
          rowEndIndex: i.endRow.rowIndex,
          columns: i.columns.map(j => j.colId)
        }
      });

      //Handle case when shift key is pressed and click on row selector
      if (shiftKey == true && rowIndex != null) {
        const focusedCell = this.gridOptions.api.getFocusedCell();
        const focusedCellRowIndex = focusedCell.rowIndex;
        const focusedCellColId = focusedCell.column.colId;

        let cellRanges = this.gridOptions.api.getCellRanges();
        cellRanges = cellRanges.map(i => {
          return {
            rowStartIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.endRow.rowIndex : i.startRow.rowIndex,
            rowEndIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.startRow.rowIndex : i.endRow.rowIndex,
            columns: i.columns.map(i => i.colId)
          }
        })
        const newRanges = [];
        
        let isDirectionUp = rowIndex < focusedCellRowIndex;
        let newStartRowIndex = rowIndex > focusedCellRowIndex? focusedCellRowIndex : rowIndex;
        let newEndRowIndex = rowIndex > focusedCellRowIndex? rowIndex : focusedCellRowIndex;
        
        //Handle case when both shift key and ctrl key are pressed
        if (ctrlKey == true) {
          //Remove last range if there is any. New range will be created as replacement later.
          if (cellRanges.length > 0) {
            cellRanges.splice(cellRanges.length-1 , 1);
          }

          //Reshape previous ranges to avoid new range created between the row which last focused cell resides and the row which user click
          for (const cRange of cellRanges) {
            const isClashed = (newStartRowIndex >= cRange.rowStartIndex && newStartRowIndex <= cRange.rowEndIndex) ||
                                (cRange.rowStartIndex >= newStartRowIndex && cRange.rowStartIndex <= newEndRowIndex);
            
            if (!isClashed) {
              //Transfer the range to newCellRanges when there is no row clashed with last range.
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
              continue;
            }

            //split existing range to exclude the last range's selected rows.
            if (cRange.rowStartIndex < newStartRowIndex && cRange.rowEndIndex >= newStartRowIndex) {
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: newStartRowIndex-1,
                columns: cRange.columns
              })
            }
            if (cRange.rowEndIndex > newEndRowIndex && cRange.rowStartIndex <= newEndRowIndex) {
              newRanges.push({
                rowStartIndex: newEndRowIndex+1,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
            }
          }
        }

        //New range replacing last range if there is any
        newRanges.push({
          rowStartIndex: isDirectionUp? newEndRowIndex: newStartRowIndex,
          rowEndIndex: isDirectionUp? newStartRowIndex : newEndRowIndex,
          columns: this.gridOptions.columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        });

        this.gridOptions.api.clearRangeSelection();
        for (const nRange of newRanges) {
          this.gridOptions.api.addCellRange(nRange);
        }
        this.gridOptions.api.setFocusedCell(focusedCellRowIndex, focusedCellColId);
        return;
      }

      //Handle edge case: shift click rowSelector when there is no existing range selection.
      if (cellRanges.length == 0) {
        const curFocusedCell = this.gridOptions.api.getFocusedCell();
        if (curFocusedCell == null) {
          this.gridOptions.api.clearFocusedCell();
          return;
        }
        const rowIndex = this.gridOptions.api.getFocusedCell().rowIndex;
        this.gridOptions.api.addCellRange({
          rowStartIndex: rowIndex,
          rowEndIndex: rowIndex,
          columns: this.gridOptions.columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        })
        this.gridOptions.api.setFocusedCell(rowIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null);
        return;
      }

      const lastRange = (() => {
        const tmp = cellRanges[cellRanges.length-1];
        const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex;
        return {
          rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
          rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
          columns: tmp.columns.map(i => i.colId),
          startColumn: tmp.startColumn.colId,
          orgRowStartIndex: tmp.startRow.rowIndex,
          orgRowEndIndex: tmp.endRow.rowIndex
        }
      })();

      this.rowSelectorChanged({ 
        cellRanges,
        originalRanges,
        lastRange,
        api: this.gridOptions.api, 
        columnApi: this.gridOptions.columnApi 
      });
    },
    rowSelectorChanged({ cellRanges, originalRanges, lastRange, api, columnApi }) {
      //Brief: Depends on user interaction, add/remove cell range selection.
      //General Specification:
      //If startCell clashes with previous ranges, remove all cell in the new range.
      //If startCell does not clash with previous ranges, select all cells in the new range. (reshape cell in previous ranges to avoid new range).
      //If only rowSelector column in the new range,
      //   - Clash or not clash becomes irrelavant
      //   - Select all cell if not all cells are selected in the row. Otherwise, remove selected cell from any ranges.
      //   - If row count is more than one, focus on 1st row (starting row)

      if (cellRanges.length > 0) {
        const startColumnId = lastRange.startColumn;
        const previousRanges = cellRanges.slice(0, cellRanges.length-1).map(i => {
          const isAsc = i.startRow.rowIndex <= i.endRow.rowIndex;
          return {
            rowStartIndex: isAsc? i.startRow.rowIndex : i.endRow.rowIndex,
            rowEndIndex: isAsc? i.endRow.rowIndex : i.startRow.rowIndex,
            columns: i.columns.map(j => j.colId)
          }
        })

        // //Check is last range is single row or multiple row selection
        // const isSingleRow = lastRange.rowEndIndex - lastRange.rowStartIndex == 0;
        
        //Check if last range's start cell clashes with previous cell ranges.
        const startCell = {
          rowStartIndex: lastRange.orgRowStartIndex,
          rowEndIndex: lastRange.orgRowStartIndex
        }
        let isStartCellClashed = false;
        for (const range of previousRanges) {
          const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex;
          const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex;
          const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex;
          const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex;
          const columnClashed = range.columns.filter(i => i == startColumnId).length > 0;
          if (isRowClashed && columnClashed) {
            isStartCellClashed = true;
            break;
          }
        }

        //Determine to unselect or select row
        let toUnselect = this.rowSelectorClicked_allColsSelectedRowIndexList.includes(startCell.rowStartIndex);

        //Prepare new cell ranges for previous ranges
        const newCellRanges = [];
        for (const range of previousRanges) {

          const isClashedWithLastRange = (lastRange.rowStartIndex >= range.rowStartIndex && lastRange.rowStartIndex <= range.rowEndIndex) ||
                                          (range.rowStartIndex >= lastRange.rowStartIndex && range.rowStartIndex <= lastRange.rowEndIndex);

          if (!isClashedWithLastRange) {
            //Transfer the range to newCellRanges when there is no row clashed with last range.
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
            continue;
          }

          //split existing range to exclude the last range's selected rows.
          if (range.rowStartIndex < lastRange.rowStartIndex && range.rowEndIndex >= lastRange.rowStartIndex) {
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: lastRange.rowStartIndex-1,
              columns: range.columns
            })
          }
          if (range.rowEndIndex > lastRange.rowEndIndex && range.rowStartIndex <= lastRange.rowEndIndex) {
            newCellRanges.push({
              rowStartIndex: lastRange.rowEndIndex+1,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
          }
        }

        if (!isStartCellClashed && !toUnselect) {
          //Prepare new cell range for last range
          newCellRanges.push({
            rowStartIndex: lastRange.rowStartIndex,
            rowEndIndex: lastRange.rowEndIndex,
            columns: columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
          })
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null);
          }
        }

        if (toUnselect) {
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null);
          }
        }
        
        //Check against original ranges. Don't update cell ranges if there is no changes. It helps to avoid infinite loop.
        if (JSON.stringify(originalRanges) != JSON.stringify(newCellRanges)) {
          api.clearRangeSelection();
          for (const newRange of newCellRanges) {
            api.addCellRange(newRange);
          }
        } else {
          const rowNodes = api.rowModel.rowsToDisplay;
          const selection = new Set();
          for (const range of originalRanges) {
            let startIdx = range.rowStartIndex;
            let endIdx = range.rowEndIndex;
            if (startIdx > endIdx) {
              startIdx = range.rowEndIndex;
              endIdx = range.rowStartIndex;
            }

            for (let i = startIdx; i <= endIdx; i++) {
              selection.add(rowNodes[i].data.uuId);
            }
          }
        }
        
      }
    },
    async processValueChanged(api) {
      this.inProgressLabel = this.$t('task.progress.updating_tasks');
      this.inProgressShow = true;
      
      if (this.pendingProcessRequestList.length > 250) {
        do {
          this.pendingRequestBatchList.push(this.pendingProcessRequestList.splice(0, 250));
        } while(this.pendingProcessRequestList.length > 250);
      }

      //Prepare to call compose api request and end the session
      if (this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {

        //Keep the last focused cell info and reapply it after data refresh
        const resetFocus = (api, cell) => {
          api.clearRangeSelection();
          if (cell != null && cell.rowIndex != null && cell.colId != null) {
            api.setFocusedCell(cell.rowIndex, cell.colId, null);
            api.addCellRange({
              rowStartIndex: cell.rowIndex
              , rowEndIndex: cell.rowIndex
              , columnStart: cell.colId
              , columnEnd: cell.colId
            });
          }
        }

        //Add the requests as a new batch to batch list.
        if (this.pendingProcessRequestList.length > 0) {
          const requests = this.pendingProcessRequestList.splice(0, this.pendingProcessRequestList.length);
          this.pendingRequestBatchList.push(requests);
        }

        //Process the request batch
        if (this.pendingRequestBatchList.length > 0) {
          const bList = this.pendingRequestBatchList.splice(0, this.pendingRequestBatchList.length);
          let hasError = false;
          let errorObj = null;
          let alertMsg = null;
          for (const b of bList) {
            //Turn off project autoScheduling, webhook and macro during batch CRUD operation
            // to improve performance.
            b.unshift({
              'note': 'Disable macros',
              'invoke': 'PUT /api/system/features?entity=macros&action=DISABLE'
            });
            b.unshift({
              'note': 'Disable project scheduling',
              'invoke': 'PUT /api/system/features?entity=scheduling&action=DISABLE'
            });

            await compositeService.exec(b)
            .then(response => {
              if (response.status == 207) {
                const feedbackList = response.data[response.data.jobCase];
                const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
                if (msg != null) {
                  hasError = true;
                  alertMsg = msg;
                }
              }
            })
            .catch(e => {
              hasError = true;
              errorObj = e;
            });
            
            if (hasError) {
              break;
            }
          }

          if (!hasError) {
            await templateProjectService.schedule([{ uuId: this.id }])
            .catch(e => {
              hasError = true;
              errorObj = e;
            });
          }

          if (hasError) {
            if (errorObj != null && alertMsg == null) {
              alertMsg = this.$t('error_failed_to_complete_operation');
              const response = errorObj.response;
              if (response) {
                if (403 === response.status) {
                  alertMsg = this.$t('error.authorize_action')
                } else if (422 === response.status) {
                  const feedbackList = response.data[response.data.jobCase];
                  const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
                  if (msg != null) {
                    alertMsg = msg;
                  }
                }
              }
            }
            this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
          } else {
            this.resetAlert();
          }

          this.reloadTasks(() => {
            setTimeout(() => {
              resetFocus(api, this.lastFocusedCell);
            }, 0);
            this.inProgressShow = false;
          });
        } else {
          this.inProgressShow = false;
        }
        
        //Reset session properties
        this.resetDurationCalculationState = true;
        // this.inProgressShow = false;
        return;
      }

      let isPendingOtherProcess = false;
      do {
        let currentItem = null;
        if (this.processDateValueChangedList.length > 0) {
          currentItem = this.processDateValueChangedList.shift();
        } else {
          currentItem = this.processValueChangedList.shift();
        }

        if (currentItem == null) {
            //Defensive code: move to next iteration when it is null
            continue;
          }

        const colId = currentItem.colId;
        const property = currentItem.property;
        const oldValue = currentItem.oldValue;
        let newValue = currentItem.newValue;
        const rowData = currentItem.data;
        let taskId = currentItem.taskId;
        const parentId = currentItem.parentId;
        // const taskName = currentItem.taskName;
        const taskType = currentItem.taskType;
        // const color = currentItem.color;
        
        const skippedWhenTypeSummaryTask = [
          'startTime', 'closeTime', 'estimatedDuration'
          , 'progress', 'autoScheduling'
          , 'constraint', 'estimatedTimeToComplete'
        ];
        if (taskType == 'Project' && skippedWhenTypeSummaryTask.includes(property)) {
          continue;
        }

        const skippedWhenTypeMilestone = [
          'closeTime', 'estimatedDuration', 'estimatedTimeToComplete'
        ];
        if (taskType == 'Milestone' && skippedWhenTypeMilestone.includes(property)) {
           continue;
        }
        
        if ('avatarRef' === property) {
          setTimeout(() => {
            api.resetRowHeights();
          }, 10);
        }

        if ('tag' === property) {
          const request = await this.updateTags(taskId, oldValue, newValue);
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('totalActualDuration' === property) {
          //This flow only for action of click and edit 'totalActualDuration' cell directly. No fill operation. No copy and paste.
          const oldStaff = rowData.oldStaffs; //This property is added in WorkEffortEditor.
          const newStaff = rowData.staffs;
          const request = TaskViewRequestGenerator.updateStaff(taskId, oldStaff, newStaff, { isTemplate: true });
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('notes' === property) {
          const request = TaskViewRequestGenerator.updateNote(taskId, oldValue != null? oldValue : [], newValue, { isTemplate: true });
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('estimatedTimeToComplete' === property) {
          if (rowData == null) { //When target is non-existing (/empty) task
            const unit = analyzeDurationAUM(newValue).unit;
            const durationInMinutes = convertDisplayToDuration(newValue, this.durationConversionOpts).value;
            const task = { uuId: taskId, duration: durationInMinutes, durationAUM: unit }
            
            this.pendingProcessRequestList.push({
              method: 'PUT',
              invoke: '/api/template/task/update',
              body: [task],
              vars: [],
              note: `templateTaskUpdate__${colId}`
            })
            
          } else {
            if (this.isDateCalcInProgress) {
              this.processDateValueChangedList.push(currentItem);
              break;
            }
            let totalWorkEffort = 0;
            if (rowData.staffs != null) {
              for (const staff of rowData.staffs) {
                totalWorkEffort += staff.duration;
              }
            }
            const etcInMinutes = convertDisplayToDuration(newValue, this.durationConversionOpts).value;
            let estimatedDuration = totalWorkEffort + etcInMinutes;
            estimatedDuration = convertDurationToDisplay(estimatedDuration, this.durationConversionOpts);
            this.dateTimeDurationValueChanged(taskId, 'estimatedDuration', estimatedDuration, rowData);
            isPendingOtherProcess = true;
            break;
          }
        } else if (['startTime', 'closeTime', 'estimatedDuration', 'constraint', 'autoScheduling'].includes(property)) {
          if (rowData == null) { //When target is non-existing (/empty) task
            const task = { uuId: taskId }
            if ('estimatedDuration' == property) {
              const unit = analyzeDurationAUM(newValue).unit;
              const durationInMinutes = convertDisplayToDuration(newValue, this.durationConversionOpts).value;
              task.duration = durationInMinutes;
              task.durationAUM = unit;
            } else if ('constraint' == property) {
              task.constraintType = newValue.type;
              task.constraintTime = newValue.time;
            } else {
              task[property] = newValue;
            }
            
            this.pendingProcessRequestList.push({
              method: 'PUT',
              invoke: '/api/template/task/update',
              body: [task],
              vars: [],
              note: `templateTaskUpdate__${colId}`
            })
          } else {
            //Fallback value for startTime or closeTime when newValue is empty (null or 0) and task is autoScheduled
            if ((property == 'startTime' || property == 'closeTime') && (newValue == null || newValue == 0) && rowData.autoScheduling == true && rowData.taskType == 'Task') {
              let parentDateStr = null;
              let parentTimeStr = null;
              
              const projectScheduleFromStart = this.project.scheduleMode != null? this.project.scheduleMode == 'ASAP' : true;
              if (parentId === 'ROOT' || parentId == null) {
                currentItem.parentId = this.projectId;
                if (projectScheduleFromStart) {
                  if (isValidDateStrFormat(this.project.startDateStr)) {
                    parentDateStr = this.project.startDateStr;
                  }
                } else {
                  if (isValidDateStrFormat(this.project.closeDateStr)) {
                    parentDateStr = this.project.closeDateStr;
                  }
                }
              } else {
                let foundParent = api.getRowNode(currentItem.parentId);
                if (foundParent != null) {
                  foundParent = foundParent.data;
                }
                if (foundParent != null) {
                  if (projectScheduleFromStart) {
                    if (foundParent.startTime != null && foundParent.startTime != 0) {
                      const parentDateTime = moment.utc(foundParent.startTime);
                      parentDateStr = parentDateTime.format('YYYY-MM-DD');
                      parentTimeStr = parentDateTime.format('HH:mm');
                    }
                  } else {
                    if (foundParent.closeTime != null && foundParent.closeTime != 0) {
                      const parentDateTime = moment.utc(foundParent.closeTime);
                      parentDateStr = parentDateTime.format('YYYY-MM-DD');
                      parentTimeStr = parentDateTime.format('HH:mm');
                    }
                  }
                }
                //Fallback to project (start|close) date
                if (parentDateStr == null) {
                  if (projectScheduleFromStart) {
                    if (isValidDateStrFormat(this.project.startDateStr)) {
                      parentDateStr = this.project.startDateStr;
                    }
                  } else {
                    if (isValidDateStrFormat(this.project.closeDateStr)) {
                      parentDateStr = this.project.closeDateStr;
                    }
                  }
                }
                //Fallback to today date
                if (parentDateStr == null) {
                  parentDateStr = moment.utc().format('YYYY-MM-DD');
                }
              }
              if (parentTimeStr != null) {
                newValue = currentItem.newValue = moment.utc(`${parentDateStr} ${parentTimeStr}`, 'YYYY-MM-DD HH:mm').valueOf();
              } else {
                newValue = currentItem.newValue = moment.utc(parentDateStr, 'YYYY-MM-DD').valueOf();
              }
              currentItem.defaultActionForNonWorkPrompt = 'move'
              currentItem.skipOutOfProjectDateCheck = true
            }
            if (this.isDateCalcInProgress) {
              this.processDateValueChangedList.push(currentItem);
              break;
            }
            const opt = {};
            if (Object.hasOwn(currentItem, 'defaultActionForNonWorkPrompt')) {
              opt.defaultActionForNonWorkPrompt = currentItem.defaultActionForNonWorkPrompt;
            }
            if (Object.hasOwn(currentItem, 'skipOutOfProjectDateCheck')) {
              opt.skipOutOfProjectDateCheck = currentItem.skipOutOfProjectDateCheck;
            }

            //0 is usually set by delete action. For calculation to work properly for deletion, set newValue to null.
            this.dateTimeDurationValueChanged(taskId, property, newValue == 0? null: newValue, rowData, opt);
            isPendingOtherProcess = true;
            break;
          }
        } else if ('staffs' === property) {
          const request = TaskViewRequestGenerator.updateStaff(taskId, oldValue, newValue, { isTemplate: true });
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('resources' === property) {
          const request = TaskViewRequestGenerator.updateResource(taskId, oldValue, newValue, { isTemplate: true, customFields: this.skillCustomFields });
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('rebates' === property) {
          const request = TaskViewRequestGenerator.updateRebate(taskId, oldValue, newValue, { isTemplate: true });
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('skills' === property) {
          const request = TaskViewRequestGenerator.updateSkill(taskId, oldValue, newValue, { isTemplate: true, customFields: this.skillCustomFields });
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('stage' === property) {
          const oldStage = oldValue != null && oldValue.uuId != null? oldValue : null;
          const newStage = newValue;
          const request = TaskViewRequestGenerator.updateStage(taskId, oldStage, newStage, { isTemplate: true });
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else { // update task
          const task = { uuId: taskId }
          const blankToNullList = ['currencyCode', 'complexity', 'durationAUM', 'priority'];
          if (typeof newValue == 'string' && newValue.trim().length == 0 && blankToNullList.includes(property)) {
            task[property] = null;
          } else {
            task[property] = newValue;
          }
          
          this.pendingProcessRequestList.push({
            method: 'PUT',
            invoke: '/api/template/task/update',
            body: [task],
            vars: [],
            note: `templateTaskUpdate__${property}`
          })
        }
        
      } while(this.processValueChangedList.length > 0);

      if(!isPendingOtherProcess && this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {
        this.inProgressShow = false;
        //Last, call itself again to begin next iteration
        this.processValueChanged(api);
      }
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "TASK"}).then(value => {
        self.modelInfo = value.TASK.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    truncatePropValue(property, value) {
      if ('name' === property && value != null && value.length > this.maxNameLength) {
        return value.substring(0, this.maxNameLength);
      }
      if ('description' === property && value != null && value.length > this.maxDescriptionLength) {
        return value.substring(0, this.maxDescriptionLength);
      }
      if ('identifier' === property && value != null && value.length > this.maxIdentifierLength) {
        return value.substring(0, this.maxIdentifierLength);
      }
      return value;
    },
    simpleHashCode(str) {
      var hash = 0, i, chr;
      if (str.length === 0) return hash;
      for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    processCellForClipboard(params) {
      const rowData = params.node.data;
      let srcColId = params.column.colId;
      if (srcColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
        srcColId = 'name';
      }

      const srcRowId = rowData.uuId;
      const srcRowData = params.api.getRowNode(srcRowId).data;
      
      const source = {
        colId: srcColId
        // , data: objectClone(srcRowData)
        , value: srcRowData[srcColId]
        , property: srcColId
        , taskId:   srcRowData.uuId
        // , parentId: srcRowData.pUuId
        // , taskName: srcRowData.name
        // , taskType: srcRowData.taskType
      }

      return this.cellCopyPrefix+JSON.stringify(source);
    },
    processCellFromClipboard(params) {
      let rowData = params.node.data;
      //Skip when the target cell is not editable or is rowSelector.
      const payload = { 
        node: params.node
        , data: params.node.data
        , column: params.column
        , colDef: params.column.colDef
        , api: params.api
        , columnApi: params.columnApi
        , context: params.context
      }
      if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
          !params.column.colDef.editable || params.column.colId == 'rowSelector') {
        return rowData[params.column.colId];
      }

      let colId = params.column.colId;
      if (colId == this.COLUMN_AGGRID_AUTOCOLUMN) {
        colId = 'name';
      }
      
      //Skip when the target cell is blacklisted
      const blackListed = ['totalActualDuration'];
      if (blackListed.includes(colId)) {
        return rowData[colId];
      }

      let source = params.value;
      //Defensive code: return original value when the value is not from aggrid cell copy.
      if (typeof source !== 'string' || !source.startsWith(this.cellCopyPrefix)) {
        return rowData[colId];
      }

      //Restore value to JSON object.
      source = source.substring(this.cellCopyPrefix.length);
      source = JSON.parse(source);
      
      const target = {
        colId: colId
        , data: objectClone(rowData)
        , value: rowData[colId]
        , property: colId
        , taskId:   rowData.uuId
        , parentId: rowData.pUuId
        , taskName: rowData.name
        , taskType: rowData.taskType
      }

      let isCompatible = false;
      if (source.property == target.property) {
        isCompatible = true;
      } else {
        isCompatible = TaskViewPropertyUtil.isCompatible(source.property, target.property);
      }

      if (!isCompatible) {
        return rowData[colId];
      }

      let srcValue = source.value;
      if ('staffs' == source.property && 'staffs' == target.property) {
        const tgtValue = objectClone(rowData[colId]);
        let tgtStaffs = tgtValue;
        if (srcValue.length == 0 && tgtStaffs.length == 0) {
          return rowData[colId]; //No change when both lists are empty.
        }

        //Remove all staff from tgtStaffs
        if (srcValue.length == 0) {
          tgtStaffs.splice(0, tgtStaffs.length);
          return tgtValue;
        }

        const updatedStaffs = [];
        for (const srcStaff of srcValue) {
          const found = tgtStaffs.find(i => i.uuId == srcStaff.uuId);
          if (found != null) {
            //Update tgtStaff with srcStaff's utilization. Keep the rest.
            found.utilization = srcStaff.utilization;
            updatedStaffs.push(found);
          } else {
            //Clean up workEffort (duration, durationAUM) when add to new list.
            delete srcStaff.duration;
            delete srcStaff.durationAUM;
            updatedStaffs.push(srcStaff);
          }
        }
        tgtStaffs.splice(0, tgtStaffs.length, ...updatedStaffs);
        return tgtValue;
      }

      if ('notes' == source.property && 'notes' == target.property) {
        const tgtValue = objectClone(rowData[colId]);
        let tgtNotes = tgtValue;
        if (srcValue.length == 0 && tgtNotes.length == 0) {
          return rowData[colId]; //No change when both lists are empty.
        }

        //Remove the top item from the tgtNotes when src is empty
        if (srcValue.length == 0) {
          tgtNotes.shift();
          return tgtValue;
        }

        const newNote = objectClone(srcValue[0]);
        if (newNote.identifier == null || newNote.identifier.trim().length == 0) {
          delete newNote.identifier;
        }
        delete newNote.uuId;
        tgtNotes.unshift(newNote);
        return tgtValue;
      }

      if ('name' === target.property || 'description' === target.property || 'identifier' === target.property) {
        srcValue = this.truncatePropValue(target.property, srcValue);
      }

      return srcValue;
    },
    prepareTargetCellData(colId, rowData, { color=null }={}) {
      const target = {
        colId: colId
        , data: objectClone(rowData)
        , oldValue: rowData[colId]
        , property: colId
        , taskId:   rowData.uuId
        , parentId: rowData.pUuId
        , taskName: rowData.name
        , taskType: rowData.taskType
        , color: color
      }

      const isTgtTaskCol = colId.startsWith(this.taskColPrefix);

      if (isTgtTaskCol) {
        const valueObj = target.oldValue;
        target.data = valueObj.data;
        target.oldValue = valueObj.single; 
        target.property = valueObj.property;
        target.taskId = valueObj.uuId != null? valueObj.uuId : null;
        target.parentId = rowData.uuId;
        target.taskType = 'Task'; //colId starts with taskColPrefix must be 'Task'

        let taskName = '<undefined>';
        const regex = new RegExp(`^${this.taskColPrefix}([A-Za-z0-9-]+)_([0-9][0-9])_(.*)$`);
        const r = colId.match(regex);
        if (r != null) {
          taskName = r[3];
        }
        target.taskName = taskName;
      }

      return target;
    },
    ctrlEnterFillCellPropCheck_note(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix);
      let tgtNotes = tgtValue;
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = [];
        }
        tgtNotes = tgtValue.single;
      }
      if (srcValue.length == 0 && tgtNotes.length == 0) {
        // return rowData[colId]; //No change when both lists are empty.
        return { action: 'continue' }
      }

      //Remove the top item from the tgtNotes when src is empty
      if (srcValue.length == 0) {
        tgtNotes.shift();
        return { action: 'add', newValue: tgtNotes }
      }

      const newNote = objectClone(srcValue[0]);
      if (newNote.identifier == null || newNote.identifier.trim().length == 0) {
        delete newNote.identifier;
      }
      delete newNote.uuId;
      tgtNotes.unshift(newNote);
      return { action: 'add', newValue: tgtNotes }
    },
    ctrlEnterFillCellPropCheck_staff(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix);
      let tgtStaffs = tgtValue;
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = [];
        }
        tgtStaffs = tgtValue.single;
      }
      if (srcValue.length == 0 && tgtStaffs.length == 0) {
        //No change when both lists are empty.
        return { action: 'continue' }
      }

      let newValue = [];
      if (srcValue.length > 0) {
        const updatedStaffs = [];
        for (const srcStaff of srcValue) {
          const found = tgtStaffs.find(i => i.uuId == srcStaff.uuId);
          if (found != null) {
            //Update tgtStaff with srcStaff's utilization. Keep the rest.
            found.utilization = srcStaff.utilization;
            updatedStaffs.push(found);
          } else {
            //Clean up workEffort (duration, durationAUM) when add to new list.
            delete srcStaff.duration;
            delete srcStaff.durationAUM;
            updatedStaffs.push(srcStaff);
          }
        }
        newValue = updatedStaffs;
      }
      return { action: 'add', newValue }
    },
    ctrlEnterFillCell(api, rowIndex, srcColId) {
      const srcRowNode = api.getDisplayedRowAtIndex(rowIndex);
      if (srcRowNode == null) {
        return;
      }

      //Prepare source (property) value
      const srcRowData = srcRowNode.data;
      const isSrcTaskCol = srcColId.startsWith(this.taskColPrefix);

      const source = {
        colId: srcColId
        , value: srcRowData[srcColId]
        , property: srcColId
        , taskId:   srcRowData.uuId
      }

      if (isSrcTaskCol) {
        const valueObj = source.value;
        source.value = valueObj.single; 
        source.property = valueObj.property;
        source.taskId = valueObj.uuId != null? valueObj.uuId : null;
      }

      //Skip when source is taskCol and has no valid task
      if (isSrcTaskCol && source.taskId == null) {
        return;
      }

      const pendingFilled = [];
      const cellRanges = api.getCellRanges();
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex;
        const rowEndIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex;
        const columns = cRange.columns.map(i => { return { colId: i.colId, color: i.userProvidedColDef.color }});
        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          const curRowNode = api.getDisplayedRowAtIndex(i);
          if (curRowNode == null) {
            continue;
          }

          const tgtRowData = curRowNode.data;
          for (const col of columns) {
            if (col.colId === srcColId && rowIndex == i) {
              continue; //Skip when current cell (rowIndex & colId) is source cell
            }
            const target = this.prepareTargetCellData(col.colId, tgtRowData, { color: col.color });
            let isCompatible = false;
            if (source.property == target.property) {
              isCompatible = true;
            } else {
              isCompatible = TaskViewPropertyUtil.isCompatible(source.property, target.property);
            }
            if (!isCompatible) {
              continue;
            }

            let srcValue = source.value;
            if ('staffs' == source.property && 'staffs' == target.property) {
              const result = this.ctrlEnterFillCellPropCheck_staff(srcValue, target);
              if (result.action == 'continue') {
                continue;
              }
              target.newValue = result.newValue;
              pendingFilled.push(target);
              continue;
            }

            if ('notes' == source.property && 'notes' == target.property) {
              const result = this.ctrlEnterFillCellPropCheck_note(srcValue, target);
              if (result.action == 'continue') {
                continue;
              }
              target.newValue = result.newValue;
              pendingFilled.push(target);
              continue;
            }

            if ('name' === target.property || 'description' === target.property || 'identifier' === target.property) {
              srcValue = this.truncatePropValue(target.property, srcValue);
            }

            const isTgtTaskCol = target.colId.startsWith(this.taskColPrefix);
            if (isTgtTaskCol) {
              //Skip if lack of required permission
              if (target.taskId == null && !this.canAdd(this.permissionName)) {
                continue;
              }
              if (target.taskId != null && !this.canEdit(this.permissionName)) {
                continue;
              }
              const tgtValue = objectClone(target.oldValue); //clone and return new object to trigger onCellValueChanged() event
              tgtValue.single = srcValue;
              target.newValue = tgtValue;
              pendingFilled.push(target);
              continue;
            }

            target.newValue = srcValue;
            pendingFilled.push(target);
          }
        }
      }

      if (pendingFilled.length > 0) {
        this.processValueChangedList.push(...pendingFilled);
        this.inProgressLabel = this.$t('task.progress.updating_tasks');
        this.processValueChanged(api);
      }
    },
    onCellKeyDown(params) {
      if (params.event.key == 'Delete' && this.canDelete(this.permissionName)) {
        const cellRanges = params.api.getCellRanges();
        //Prepare cell information
        const taskColTasks = [];
        const processedCells = []; //Used to elimate duplicate records.
        for (const cRange of cellRanges) {
          const startRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex;
          const lastRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex; 
          
          const columns = cRange.columns;
          for (let idx = startRowIdx; idx <= lastRowIdx; idx++) {
            const rowNode = params.api.getDisplayedRowAtIndex(idx);
            
            for (const column of columns) {
              const colId = column.colId;
              const rowId = rowNode.data.uuId;
              const found = processedCells.find(i => rowId == i.rowId && (this.COLUMN_AGGRID_AUTOCOLUMN == i.colId || colId == i.colId));
              if (found != null) {
                continue; //Duplicated cell is found. Process to next iteration.
              }
              processedCells.push({ rowId, colId });

              //Handle 'ag-Grid-AutoColumn' cell
              //Brief: Delete ag-Grid-AutoColumn cell means remove the summary task (whole row) and thus delete all it's children.
              //Remove previously handled cells of the same row before current `ag-Grid-AutoColumn`.
              if (this.COLUMN_AGGRID_AUTOCOLUMN == colId) {
                //Remove redundant cells in taskColTasks
                let redundantTaskColCells = [];
                taskColTasks.forEach((i, idx) => {
                  if (i.parent == rowId) {
                    redundantTaskColCells.push(idx);
                  }
                });
                if (redundantTaskColCells.length > 0) {
                  redundantTaskColCells = redundantTaskColCells.reverse();
                  for (const idx of redundantTaskColCells) {
                    taskColTasks.splice(idx, 1);
                  }
                }
                
                //Remove redundant cells in (non taskCol) pendingDeleteCells
                let redundantPendingDeleteCells = [];
                this.pendingDeleteCells.forEach((i, idx) => {
                  if (i.taskId == rowId) {
                    redundantPendingDeleteCells.push(idx);
                  }
                });
                if (redundantPendingDeleteCells.length > 0) {
                  redundantPendingDeleteCells = redundantPendingDeleteCells.reverse();
                  for (const idx of redundantPendingDeleteCells) {
                    this.pendingDeleteCells.splice(idx, 1);
                  }
                }

                taskColTasks.push({
                   uuId: rowNode.data.uuId
                  , name: rowNode.data.name
                  , parent: rowNode.data.pUuId
                  , parentName: rowNode.data.pName
                  , colId
                });
                continue;
              }

              if (rowNode.data[colId] == null) {
                continue; //Skip when the property value is null.
              }

              //Handle non taskCol cell
              //Skip when the cell is not editable
              let isEditable;
              if (typeof this.defaultColDef.editable === 'function') {
                isEditable = this.defaultColDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }});
              } else if (typeof this.defaultColDef.editable === 'boolean') {
                isEditable = this.defaultColDef.editable;
              }
              const colDef = this.columnDefs.find(i => i.colId == colId || i.field == colId);
              if (typeof colDef.editable === 'function') {
                isEditable = colDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }});
              } else if (typeof colDef.editable === 'boolean') {
                isEditable = colDef.editable;
              }
              if (!isEditable) {
                continue;
              }

              //Special handling non taskCol  cell (startTime and closeTime)
              if ((colId == 'startTime' || colId == 'closeTime') && rowNode.data.autoScheduling == true) {
                const payload = {
                  colId
                  , data: objectClone(rowNode.data)
                  , newValue: null
                  , oldValue: null
                  , property: colId
                  , taskId: rowNode.data.uuId
                  , parentId: rowNode.data.pUuId
                  , taskName: rowNode.data.name
                  , taskType: rowNode.data.taskType
                  , color: '' //Not applicable
                }
                this.processValueChangedList.push(payload);
                continue
              }
              
              this.pendingDeleteCells.push({
                colId
                , data: objectClone(rowNode.data)
                , property: colId
                , taskId: rowId
                , parenId: rowNode.data.pUuId
                , taskName: rowNode.data.name
                , taskType: rowNode.data.taskType
                , isTaskCol: false
                , value: rowNode.data[colId]
              });
            }
          }
        }

        if (this.processValueChangedList.length > 0) {
          this.inProgressLabel = this.$t('task.progress.updating_tasks');
          this.processValueChanged(params.api);
        }

        if (taskColTasks.length > 0) {
          //Prepare data for taskcol delete confirmation dialog
          this.tcConfirmDeleteTasks = taskColTasks;
          this.prepareTaskColConfirmDeleteDialog();
        } else if (this.pendingDeleteCells.length > 0) {
          this.deleteCell();
        }
      } else if ((params.event.keyCode == 13 || params.event.keyCode == 68) && params.event.ctrlKey) {
        const api = params.api;
        if (api == null) {
          return;
        }

        const focusedCell = api.getFocusedCell();
        if (focusedCell == null) {
          return;
        }

        const cellRanges = api.getCellRanges();
        if (cellRanges.length == 0 || (cellRanges.length > 1 && params.event.keyCode == 68)) { //Ctrl+D supports only single range
          return;
        }

        const fRange = cellRanges.find(i => {
          if (i.startRow != null && i.endRow != null && i.columns.find(j => j.colId == focusedCell.column.colId) != null) {
            return i.startRow.rowIndex <= focusedCell.rowIndex && focusedCell.rowIndex <= i.endRow.rowIndex;
          }
          return false;
        })
        if (fRange == null) {
          return;
        }
        
        //Stop operation when range is a single cell selection because nothing to copy and fill.
        if (fRange.startRow.rowIndex == fRange.endRow.rowIndex && fRange.columns.length == 1) {
          return;
        }

        const rowIndex = fRange.startRow.rowIndex < fRange.endRow.rowIndex? fRange.startRow.rowIndex : fRange.endRow.rowIndex;

        this.ctrlEnterFillCell(api, rowIndex, fRange.columns[0].colId);
        return;
      } else if (params.event.keyCode == 13) {
        //Navigate to next cell when user press enter on the read-only cell.
        let editable = false;
        const api = params.api;
        if (api == null) {
          return;
        }
        const fRowIndex = params.rowIndex;
        const column = params.column;
        if (typeof column.colDef.editable == 'boolean') {
          editable = column.colDef.editable;
        } else if (typeof column.colDef.editable == 'function') {
          const data = params.data;
          editable = column.colDef.editable({ data });
        }
        if (!editable) {
          let rowIndex;
          if (params.event.shiftKey) {
            rowIndex = fRowIndex - 1;
            if (rowIndex < 0) {
              rowIndex = 0;
            }
          } else {
            rowIndex = fRowIndex + 1;
            const lastRowIndex = api.getDisplayedRowCount() - 1;
            if (rowIndex > lastRowIndex) {
              rowIndex = lastRowIndex;
            }
          }
          //Navigate to next cell below when user press enter on ready only cell.
          if (api.getDisplayedRowAtIndex(rowIndex) != null) {
            this.navigateCellTo(api, rowIndex, column.colId, null);
          }
        }
      }
    },
    async deleteCell() {
      //deleteCell() expects only non-taskcol cell.
      const pendingItems = [];
      const cells = this.pendingDeleteCells.splice(0, this.pendingDeleteCells.length);
      for (const cell of cells) {
        cell.oldValue = cell.value;
        //Some models may update the property in data. Hence, it is important that cell.data is passed as reference to keep the change and will be used later.
        cell.newValue = TaskViewPropertyUtil.getEmptyDataModel(cell.property, cell.data).value; 
        delete cell.value;
        pendingItems.push(cell);
      }
      
      if (pendingItems.length > 0) {
        const data = [];
        for (const item of pendingItems) {
          const rowData = item.data; //use item.data for applyTransaction because some delete actions may update other property values in the data. (e.g totalActualDuration)
          rowData[item.colId] = item.newValue;
          data.push(rowData);
        }
        this.gridOptions.api.applyTransaction({ update: data });
        this.processValueChangedList.push(...pendingItems);
      }
      
      this.inProgressLabel = this.$t('task.progress.deleting');
      this.processValueChanged(this.gridOptions.api);
    },
    onPasteStart(/** params */) {
      this.isPasteInProgress = true;
    },
    onPasteEnd(params) {
      this.isPasteInProgress = false;

      this.consolidateChangedCellValues(this.processValueChangedList);
      if (this.processValueChangedList.length > 0) {
        this.inProgressLabel = this.$t('task.progress.paste_tasks');
        this.processValueChanged(params.api);  
      }
    },
    consolidateChangedCellValues(valueChangedlist) {
      //Handle edge case: Copy & paste multiple duration related cells may trigger multiple requests which send incorrect data to backend.
      //Solution: Consolidate duration related cell value changes into one if they belong to the same task.
      const durationGroup = ['startTime', 'closeTime', 'estimatedDuration', 'constraint', 'autoScheduling']
      const ids = valueChangedlist.filter(i => durationGroup.includes(i.property)).map(i => i.taskId);
      for (const id of ids) {
        const len = valueChangedlist.filter(i => i.taskId == id && durationGroup.includes((i.property))).length;
        if (len < 1) {
          continue;
        }
        const list = [];
        for (let i = 0; i < len; i++) {
          const idx = valueChangedlist.findIndex(j => j.taskId == id && durationGroup.includes(j.property));
          list.push(valueChangedlist.splice(idx, 1)[0]);
        }

        //Sort out the trigger value
        const triggerOrder = ['estimatedDuration','startTime','closeTime','autoScheduling','constraint'];
        const property = list.reduce((r, c) => {
          if (r == null) {
            return c.property;
          }
          if (triggerOrder.findIndex(i => i == c.property) < triggerOrder.findIndex(i => i == r)) {
            return c.property;
          }
          return r;
        }, null);

        //Find out the cell value change with sorted (trigger) property
        const foundIdx = list.findIndex(i => i.property === property);
        const consolidatedItem = list.splice(foundIdx, 1)[0];
        //Update the consolidated item with all other values
        for (const l of list) {
          if (l.property == 'estimatedDuration') {
            consolidatedItem.data.duration = l.newValue;
          } else {
            consolidatedItem.data[l.property] = l.newValue;
          }
        }

        valueChangedlist.push(consolidatedItem);
      }

      return valueChangedlist;
    },
    navigateToNextCell(params) {
      const previousCellPosition = params.previousCellPosition;
      const nextCellPosition = params.nextCellPosition;
      if (nextCellPosition == null) {
        return previousCellPosition;
      }
      //Clear range selection when move focus from cell to header.
      if (nextCellPosition.rowIndex < 0 && previousCellPosition.rowIndex > -1) {
        params.api.clearRangeSelection();
      }
      // Stay in previousCell when next Cell belonged to rowSelector column.
      if (nextCellPosition.column.colId === 'rowSelector') {
        return previousCellPosition;
      }
      return nextCellPosition;
    },
    tabToNextCell(params) {
      //Fix for the bug: Multiple tabToNextCell events are fired when tab in cell editing.
      const curColId = params.previousCellPosition.column.colId;
      const columns = params.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector');
      let rowIndex = params.previousCellPosition.rowIndex;
      let index = columns.findIndex(i => i.colId == curColId);
      let nextColIdx;
      if (index == 0 && params.backwards) {
        rowIndex -= 1;
        nextColIdx = columns.length - 1;
      } else if (index == columns.length-1 && !params.backwards) {
        rowIndex += 1;
        nextColIdx = 0;
      } else if (params.backwards) {
        nextColIdx = index - 1;
      } else {
        nextColIdx = index + 1;
      }
      const column = columns[nextColIdx];

      if  (this.tabTimeoutId == null) {
        this.tabRowIndex = rowIndex;
        this.tabColumn = column;
        this.tabTimeoutId = setTimeout(() => {
          this.tabRowIndex = null;
          this.tabColumn = null;
          this.tabTimeoutId = null;
        }, 0);
        setTimeout(() => {
          params.api.clearRangeSelection();
          params.api.setFocusedCell(rowIndex, column.colId);
          params.api.addCellRange({
            rowStartIndex: rowIndex
            , rowEndIndex: rowIndex
            , columns: [column.colId]
          })
          params.api.ensureIndexVisible(rowIndex, null);
          params.api.ensureColumnVisible(column.colId, 'auto');
        }, 0);
        return params.previousCellPosition;
      }
      //Skip cell navigation. 
      //Pro: to break infinite loop
      //Cons: will cause cell focus appear in the top left html element (e.g. Company logo)
      //Hence, a really short timeout duration is used to minimise the appearance of the cell focus.
      return null;
    },
    getMainMenuItems(params) {
      const colId = params.column.colId;
      const headerName = params.column.colDef.headerName;
      if (colId != null && colId.startsWith(this.taskColPrefix)) {
        const menuItems = [];
        menuItems.push({
          name: 'Edit',
          action: () => {
            this.updateTaskColName(colId, params.column.parent.groupId, headerName, params.column.colDef.color);
          },
          icon: '<i class="fa-regular fa-pen-to-square"/>'
        })
        return menuItems;
      } 
      else if (colId != null && colId === 'rowSelector') {
        
        const menuItems = [];
        menuItems.push({
          name: this.$t('button.selectall'),
          action: () => {
            const api = params.api;
            const rows = api.rowModel.rowsToDisplay.filter(i => i.key != 'ROOT');//Row with 'ROOT' key is a virtual row.
            if (rows.length > 0) {
              const range = {
                rowStartIndex: rows[0].rowIndex,
                rowEndIndex: rows[rows.length-1].rowIndex,
                columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
              }
              api.clearRangeSelection();
              api.addCellRange(range);

              //Make sure there is a focused cell in the grid.
              //Without focused cell, cell navigation, delete key interaction will not work.
              if(api.getFocusedCell() == null) {
                api.setFocusedCell(range.rowStartIndex, range.columns[0]);
              }
            }
          },
        });
        menuItems.push({
          name: this.$t('button.selectnone'),
          action: () => {
            params.api.deselectAllFiltered();
            params.api.clearRangeSelection();
            params.api.clearFocusedCell();
          },
        });

        const selectCellRangeByType = (api, columnApi, type) => {
          const newCellRanges = [];
          const rows = api.rowModel.rowsToDisplay;
          let startIndex = -1;

          if (this.isCompactView) {
            for (const row of rows) {
              if (row.data.taskType == type && row.key != 'ROOT') {//Row with 'ROOT' key is a virtual row.
                
                if (startIndex == -1) {
                  startIndex = row.rowIndex;
                }
                continue; //Move to next item as the whole row is included.
              } else if (startIndex != -1) {
                //Commit the current new range and reset the startIndex for next new range.
                newCellRanges.push({
                  rowStartIndex: startIndex,
                  rowEndIndex: row.rowIndex,
                  columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
                })
                startIndex = -1;
              }

              //Handle taskCol cells
              if (row.data.taskType == 'Project' && type == 'Task') {
                const data = row.data;
                const taskColNames = Object.getOwnPropertyNames(data).filter(i => i.startsWith(this.taskColPrefix));
                const includedColumns = [];
                for (const name of taskColNames) {
                  if (data[name] == null || data[name].uuId == null) {
                    continue; //Move to next item if it has no valid task uuId.
                  }
                  includedColumns.push(name);
                }
                if (includedColumns.length > 0) {
                  newCellRanges.push({
                    rowStartIndex: row.rowIndex,
                    rowEndIndex: row.rowIndex,
                    columns: includedColumns
                  })
                }
              }
            }
          } else { // normal view
            for (const row of rows) {
              if (row.data.taskType == type) {
                if (startIndex == -1) {
                  startIndex = row.rowIndex;
                }
                continue;
              } else if (startIndex != -1) {
                newCellRanges.push({
                  rowStartIndex: startIndex,
                  rowEndIndex: row.rowIndex - 1,
                  columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
                })
                startIndex = -1;
                continue;
              }
            }
          }

          if (startIndex != -1 && rows.length > 0) {
            const lastRowIndex = rows[rows.length -1].rowIndex;
            newCellRanges.push({
              rowStartIndex: startIndex,
              rowEndIndex: lastRowIndex,
              columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
            })
          }

          api.clearRangeSelection();
          for (const cellRange of newCellRanges) {
            api.addCellRange(cellRange);
          }

          //Make sure there is a focused cell in the grid.
          //Without focused cell, cell navigation, delete key interaction will not work.
          if(newCellRanges.length > 0 && api.getFocusedCell() == null) {
            api.setFocusedCell(newCellRanges[0].rowStartIndex, newCellRanges[0].columns[0]);
          }
        }
        
        menuItems.push({
          name: this.$t('task.select_summary'),
          action: () => {
            selectCellRangeByType(params.api, params.columnApi, 'Project');
          },
        });
        
        menuItems.push({
          name: this.$t('task.select_task'),
          action: () => {
            selectCellRangeByType(params.api, params.columnApi, 'Task');
          },
        });
        
        if (!this.isCompactView) {
          menuItems.push({
            name: this.$t('task.select_milestone'),
            action: () => {
              selectCellRangeByType(params.api, params.columnApi, 'Milestone');
            },
          });
        }

        const selectCellRangeByLevel = (api, columnApi, level) => {
          const newCellRanges = [];
          const rows = api.rowModel.rowsToDisplay;
          let startIndex = -1;

          if (this.isCompactView) {
            for (const row of rows) {
              //Handle Ag-Grid-AutoColumn cell
              if (row.level == level+1) {
                if (startIndex == -1) {
                  startIndex = row.rowIndex;
                }
              } else if (startIndex != -1) {
                newCellRanges.push({
                  rowStartIndex: startIndex,
                  rowEndIndex: row.rowIndex - 1,
                  columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
                })
                startIndex = -1;
              }

              //Handle task col cell
              if (row.level == level) {
                const data = row.data;
                const taskColNames = Object.getOwnPropertyNames(data).filter(i => i.startsWith(this.taskColPrefix));
                const includedColumns = [];
                for (const name of taskColNames) {
                  if (data[name] == null || data[name].uuId == null) {
                    continue; //Move to next item if it has no valid task uuId.
                  }
                  includedColumns.push(name);
                }
                if (includedColumns.length > 0) {
                  newCellRanges.push({
                    rowStartIndex: row.rowIndex,
                    rowEndIndex: row.rowIndex,
                    columns: includedColumns
                  })
                }
              }
            }
            
            if (startIndex != -1) {
              newCellRanges.push({
                rowStartIndex: startIndex,
                rowEndIndex: rows[rows.length -1].rowIndex,
                columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
              })
            }
            
            api.clearRangeSelection();
            for (const cellRange of newCellRanges) {
              api.addCellRange(cellRange);
            }

            //Make sure there is a focused cell in the grid.
            //Without focused cell, cell navigation, delete key interaction will not work.
            if(newCellRanges.length > 0 && api.getFocusedCell() == null) {
              api.setFocusedCell(newCellRanges[0].rowStartIndex, newCellRanges[0].columns[0]);
            }
          } else {
            for (const row of rows) {
              if (row.level == level) {
                if (startIndex == -1) {
                  startIndex = row.rowIndex;
                }
                continue;
              } else if (startIndex != -1) {
                newCellRanges.push({
                  rowStartIndex: startIndex,
                  rowEndIndex: row.rowIndex - 1,
                  columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
                })
                startIndex = -1;
                continue;
              }
            }
            if (startIndex != -1) {
              newCellRanges.push({
                rowStartIndex: startIndex,
                rowEndIndex: rows[rows.length -1].rowIndex,
                columns: [this.COLUMN_AGGRID_AUTOCOLUMN]
              })
            }
            api.clearRangeSelection();
            for (const cellRange of newCellRanges) {
              api.addCellRange(cellRange);
            }
          }
        }
        
        const levelMenu = [];
        for (let lvl = 0; lvl <= this.maxLevel; lvl++) {
         levelMenu.push({
            name: lvl + 1,
            action: () => {
              selectCellRangeByLevel(params.api, params.columnApi, lvl);
            },
          });
        }
        
        menuItems.push({
          name: this.$t('task.select_level'),
          subMenu: levelMenu,
        });
        return menuItems;
      }
      return params.defaultItems;
    },
    tcConfirmDeleteOk() {
      const tasks = [{ 
        taskId: this.taskCol.taskId
        , parentId: this.taskCol.parentId 
        , colId: this.taskCol.colId
      }];
      if (this.taskCol.applyAll == true) {
        tasks.push(...this.tcConfirmDeleteTasks.map(i => {
          return {
            taskId: i.uuId
            , parentId: i.parent
            , colId: i.colId
          }
        }));
        this.tcConfirmDeleteTasks.splice(0, this.tcConfirmDeleteTasks.length);
      }
      
      const deleteTaskReqTemplate = (taskId) => {
        return {
          method: 'POST',
          invoke: `/api/template/task/delete`,
          body: [{
            uuId: taskId,
          }],
          vars: [],
          note: `templateTaskDelete__${taskId}`
        }
      }
      const toBeUpdated = [];
      const toBeRemoved = [];
      const leafChildrenToBeRemoved = []; //Used to remove child rows of deleted summary task in grid for better visual.
      const toBeRemovedRowIndexes = [];
      for(const task of tasks) {
        this.pendingProcessRequestList.push(deleteTaskReqTemplate(task.taskId));
        
        if (task.colId == this.COLUMN_AGGRID_AUTOCOLUMN) {
          const rowNode = this.gridOptions.api.getRowNode(task.taskId);
          const rowData = objectClone(rowNode.data);
          toBeRemoved.push(rowData);
          toBeRemovedRowIndexes.push(rowNode.rowIndex);
          //Collect child row data and will be used in grid applytransaction to remove rows for better visual.
          if (rowData.taskType == 'Project') {
            if (rowNode.allLeafChildren == null || rowNode.allLeafChildren.length < 2) {
              continue;
            }
            const rowNodeChildren = rowNode.allLeafChildren;
            for (let i = 1, len = rowNodeChildren.length; i < len; i++) {
              leafChildrenToBeRemoved.push(objectClone(rowNodeChildren[i].data));
            }
          }
          
        } else {
          const rowData = objectClone(this.gridOptions.api.getRowNode(task.parentId).data);
          rowData[task.colId].single = TaskViewPropertyUtil.getEmptyDataModel(rowData[task.colId].property, rowData[task.colId].data).value;
          delete rowData[task.colId].uuId;
          toBeUpdated.push(rowData);
        }
      }

      // //Adjust focused cell position to cell above (if possible) when the row which focused cell belongs to is removed.
      let suggestedRowIndex = -1;
      if (toBeRemovedRowIndexes.length > 0) {
        const currentFocusedCell = this.gridOptions.api.getFocusedCell();
        if (currentFocusedCell != null) {
          const rowIndex = currentFocusedCell.rowIndex;
          const colId = currentFocusedCell.column.colId;
          const rowPinned = currentFocusedCell.rowPinned;
          const isFocusedCellAffected = toBeRemovedRowIndexes.includes(rowIndex);
          if (isFocusedCellAffected) {
            suggestedRowIndex = rowIndex - 1;
            while (toBeRemovedRowIndexes.includes(suggestedRowIndex)) {
              suggestedRowIndex = suggestedRowIndex - 1;
            }
          }
          
          this.gridOptions.api.clearRangeSelection();
          if (suggestedRowIndex > -1) {
            this.gridOptions.api.setFocusedCell(suggestedRowIndex, colId, rowPinned);
          }
        }
      }

      if (toBeUpdated.length > 0 || toBeRemoved.length > 0) {
        if (leafChildrenToBeRemoved.length > 0) {
          toBeRemoved.push(...leafChildrenToBeRemoved);
        }
        this.gridOptions.api.applyTransaction({ update: toBeUpdated.length > 0? toBeUpdated : null, remove: toBeRemoved.length > 0? toBeRemoved : null });
        
      }
      
      setTimeout(() => {
        this.prepareTaskColConfirmDeleteDialog();
      }, 300);
    },
    prepareTaskColConfirmDeleteDialog() {
      if (this.tcConfirmDeleteTasks == null || this.tcConfirmDeleteTasks.length == 0) {
        this.deleteCell();
        return;
      }
      
      const task = this.tcConfirmDeleteTasks.shift();
      this.taskCol.taskId = task.uuId;
      this.taskCol.taskName = task.name;
      this.taskCol.parentName = task.parent == null || task.parent == 'ROOT'? null : task.parentName;
      this.taskCol.applyAll = false;
      this.taskCol.parentId = task.parent;
      this.taskCol.colId = task.colId;
      this.inProgressShow = false;
      this.tcConfirmDeleteShow = true;
    },
    tcConfirmDeleteCancel() {
      if (this.pendingProcessRequestList.length > 0) {
        this.deleteCell();
      }
    },
    reloadTasks(callbackFunc=null, { hideOverlay=true } = {}) {
      if (this.gridOptions != null && this.gridOptions.api != null) {
        if (hideOverlay !== false) {
          this.gridOptions.api.hideOverlay();
        }
        this.actionProcessing = false;
        this.loadTasks(this.id, {
          callback: () => {
            this.$nextTick(() => {
              if (callbackFunc != null) {
                callbackFunc();
              }
            });
          }
        });
      }
    },
    cellFocused(event) {
      if (event.rowIndex != null && event.column != null) {
        this.lastFocusedCell = { rowIndex: event.rowIndex, colId: event.column.colId }  
      }
    },
    keyDownHandler(e) {
      if (!e.target.classList.contains('ag-cell') && e.target.tagName != 'BODY' || e.target.classList.contains('modal-open')) {
        return;
      }
      
      if (e.key == 'Delete' && e.target.closest('.lhs-grid') == null 
          && this.gridOptions.api.getCellRanges() != null && this.gridOptions.api.getCellRanges().length > 0) {
        if (!e.target.classList.contains('ag-cell-value')) { //Skip if the target is ag grid cell, as onCellKeyDown will be triggered by ag grid.
          //Construct the necessary payload value and call the cellKeyDown method 
          this.onCellKeyDown({ api: this.gridOptions.api, event: { keyCode: e.keyCode, key: e.key } });
        }
      } else if (e.keyCode == 36 || e.keyCode == 35) {//Home & End Key
        const api = this.gridOptions.api;
        if (api == null) {
          return;
        }
        e.stopPropagation();
        e.preventDefault();
        if (e.shiftKey) {
          return;
        }
        const rowCount = api.getDisplayedRowCount();
        if (rowCount == 0) {
          return;
        }

        const firstRowIndex = 0;
        const lastRowIndex = rowCount - 1;
        const columns = this.gridOptions.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId);
        const startColumn = columns[0];
        const lastColumn = columns[columns.length - 1];

        const rowIndex = e.keyCode == 36? firstRowIndex : lastRowIndex;
        const colId = e.keyCode == 36? startColumn : lastColumn;
        const vPosition = e.keyCode == 36? 'top' : 'bottom';

        if (e.ctrlKey) {
          this.navigateCellTo(api, rowIndex, colId, vPosition);
        } else {
          const focusedCell = api.getFocusedCell();
          if (focusedCell != null) {
            this.navigateCellTo(api, focusedCell.rowIndex, colId, null);
          }
        }
        return;
      } else if (e.keyCode == 37) { //ArrowLeft Key
        if (e.ctrlKey != true) {
          return 
        }
        e.stopPropagation();
        e.preventDefault();
        const api = this.gridOptions.api;
        const focusedCell = api.getFocusedCell();
        if (focusedCell != null) {
          const columns = this.gridOptions.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId);
          this.navigateCellTo(api, focusedCell.rowIndex, columns[0], null);
        }
      } else if (e.ctrlKey && e.keyCode == 65) {
        e.stopPropagation();
        e.preventDefault();
        const api = this.gridOptions.api;
        const totalCount = api.getDisplayedRowCount();
        if (totalCount == 0) {
          return;
        }
        const columns = this.gridOptions.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId);

        api.clearRangeSelection();
        api.addCellRange({
          rowStartIndex: 0,
          rowEndIndex: totalCount - 1,
          columns
        });
        //Set a focus cell if there is none
        const focusCell = api.getFocusedCell();
        if (focusCell != null) {
          return;
        }
        api.setFocusedCell(0, columns[0], null);
      } else if (e.ctrlKey && e.keyCode == 68) {//'D'
        e.stopPropagation();
        e.preventDefault();
      }
    },
    navigateCellTo(api, pRowIndex, pColId, vPosition=null) {
      setTimeout(() => {
        let rowIndex = pRowIndex;
        const colId = pColId;
        api.clearRangeSelection();
        api.setFocusedCell(rowIndex, colId, null);
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        });
        api.ensureIndexVisible(rowIndex, vPosition);
        api.ensureColumnVisible(colId, 'auto');
      })
    },
    prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage;  
      }
      return this.$t('task.grid.no_data');
    },
    getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    updateGridHeight(value=null) {
      this.gridHeight = `calc(100vh - 195px - ${value != null? value+16 : 0}px)`;
    },
    isEllipsisActive(text) {
      return isEllipsisActive(text, this);
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-toolbar {
  .btn {
    font-size: 1rem;
  }
  ul {
    margin: 0;
  }
}

.action-v-divider {
  cursor: default;
  margin: 0 6px;
}

.task-summary-list {
  list-style-type: none;
  max-height: 300px;
  overflow-y: auto;
}

.task-summary-list-item {
  margin-bottom: 10px;
}

.task-summary-title {
  font-weight: bold;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.search-input {
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>