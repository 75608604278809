<template>
  <nav class="priority-nav" :class="[hasDropdown? 'priority-nav-has-dropdown' : '']">
    <ul :class="[ulClassName? ulClassName: '']">
      <slot></slot>
    </ul>
    <span class="nav__dropdown-wrapper priority-nav__wrapper" :aria-haspopup="hasDropdown">
      <button aria-controls="menu" type="button" 
        class="nav__dropdown-toggle priority-nav__dropdown-toggle" 
        :class="[isDropdownToggleVisible? 'priority-nav-is-visible' : 'priority-nav-is-hidden']"
        @click="navDropdownToggleClicked">{{ dropdownBtnLabel }}</button>
      <ul :aria-hidden="!isOpened" class="nav__dropdown priority-nav__dropdown" :class="[isOpened? 'show':'']">
        
      </ul>
    </span>
  </nav>
</template>

<script>
import { debounce } from 'lodash';
export default {
  name: 'PriorityNavigation',
  props: {
    ulClassName: {
      type: String,
      default: null
    },
    dropdownLabel: {
      type: String,
      default: '≡'
    },
    dropdownBreakpointLabel: {
      type: String,
      default: '≡'
    },
    breakPoint: {
      type: Number,
      default: 300
    },
    throttleDelay: {
      type: Number,
      default: 50,
    },
    offsetPixels: {
      type: Number,
      default: 20
    },
    closeDropdown: {
      type: Boolean,
      defualt: false
    },
    dropDownStayOpened: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allItems: [],
      displayItem: [],
      shyItems: [],
      totalWidth: 0,
      restWidth: 0,
      dropDownWidth: 0,
      viewportWidth: 0,
      count: true,
      breaks: [],
      resizeEventHandler: null,
      clickOutsideEventHandler: null,
      keyDownEventHandler: null,
      dropdownBtnLabel: '≡',
      isDropdownToggleVisible: false,
      hasDropdown: false,
      isOpened: false,
      isBusy: false,
    }
  },
  created() {
    this.navDropdownSelector = 'ul.nav__dropdown';
    this.mainSelector = 'ul';
    this.navDropdownToggleSelector = '.nav__dropdown-toggle';
    this.navDropdownWrapperSelector = '.nav__dropdown-wrapper';
  },
  mounted() {
    const self = this;

    //Register 'resize' event listener
    this.resizeEventHandler = debounce(function() {
      self.doesItFit();
    }, this.throttleDelay);

    
    //Register 'clickOutside' event listener
    this.clickOutsideEventHandler = function(e) {
      const navDropdownSelector = self.navDropdownSelector;
      const navDropdownToggle = self.$el.querySelector(self.navDropdownToggleSelector);
      if (e.target.closest(navDropdownSelector) == null && e.target !== navDropdownToggle && !self.dropDownStayOpened) {
        self.isOpened = false;
      }
    }    

    //Register 'keyDown' event listener for escape key to close dropdown
    this.keyDownEventHandler = function(e) {
      const evt = e || window.event;
      if (evt.keyCode === 27 && !self.dropDownStayOpened) {
          self.isOpened = false;
      }
    }

    window.addEventListener('resize', this.resizeEventHandler);
    document.addEventListener('click', this.clickOutsideEventHandler);
    document.addEventListener('keydown', this.keyDownEventHandler);

    this.doesItFit();
  },
  beforeDestroy() {
    //Deregister resize event listener
    if (this.resizeEventHandler != null) {
      window.removeEventListener('resize', this.resizeEventHandler);
      this.resizeEventHandler = null;
    }

    //Deregister 'clickOutside' event listener
    if (this.clickOutsideEventHandler != null) {
      document.removeEventListener('click', this.clickOutsideEventHandler);
      this.clickOutsideEventHandler = null;
    }

    //Deregister 'keydown' event listener
    if (this.keyDownEventHandler != null) {
      document.removeEventListener('keydown', this.keyDownEventHandler);
      this.keyDownEventHandler = null;
    }

    this.navDropdownSelector = null;
    this.mainSelector = null;
    this.navDropdownToggleSelector = null;
    this.navDropdownWrapperSelector = null;
  },
  updated() {
    // //This event will be triggered when there is any component change in provided slot content.
    setTimeout(() => {
      this.doesItFit();
    }, 300);
  },
  watch: {
    closeDropdown(newValue) {
      if (newValue) {
        if (!this.dropDownStayOpened) {
          this.isOpened = false;
        }
        this.$emit('update:closeDropdown', false);
      }
    }
  },
  computed: {

  },
  methods: {
    calculateWidths() {
      const navEl = this.$el;
      this.totalWidth = this.getElementContentWidth(navEl);
      const navDropdown = this.navDropdownSelector;

      if (navDropdown == null) {
        return; //Stop proceed further as beforeDestroyed() is being called.
      }
      //Check if parent is the navwrapper before calculating its width      
      if (navEl.querySelector(navDropdown).parentNode === navEl) {
          this.dropDownWidth = navEl.querySelector(navDropdown).offsetWidth;
      } else {
          this.dropDownWidth = 0;
      }
      const btnWidth = navEl.querySelector(this.navDropdownToggleSelector).offsetWidth;
      this.restWidth = this.getChildrenWidth(navEl.querySelector(this.mainSelector)) + this.offsetPixels + btnWidth;
      this.viewportWidth = this.viewportSize().width;
    },
    getElementContentWidth(element) {
      var styles = window.getComputedStyle(element);
      var padding = parseFloat(styles.paddingLeft) +
          parseFloat(styles.paddingRight);

      return element.clientWidth - padding;
    },
    viewportSize() {
      var doc = document, w = window;
      var docEl = (doc.compatMode && doc.compatMode === 'CSS1Compat')?
          doc.documentElement: doc.body;

      var width = docEl.clientWidth;
      var height = docEl.clientHeight;

      // mobile zoomed in?
      if ( w.innerWidth && width > w.innerWidth ) {
          width = w.innerWidth;
          height = w.innerHeight;
      }
      return {width: width, height: height};
    },
    getChildrenWidth(e) {
      var children = e.childNodes;
      var sum = 0;
      for (var i = 0; i < children.length; i++) {
          if (children[i].nodeType !== 3) {
              if(!isNaN(children[i].offsetWidth)){
                  sum += children[i].offsetWidth;
              }

          }
      }
      return sum;
    },
    doesItFit() {
      if (this.isBusy) {
        return; 
      }
      
      this.isBusy = true;
      const navEl = this.$el;
      this.calculateWidths();
      const mainNavElem = navEl.querySelector(this.mainSelector);
      const dropDownElem = navEl.querySelector(this.navDropdownSelector);
      /**
       * Keep executing until all menu items that are overflowing are moved
       */
      while (mainNavElem != null && (this.totalWidth <= this.restWidth  && mainNavElem.children.length > 0 || this.viewportWidth < this.breakPoint && mainNavElem.children.length > 0)) {
        //move item to dropdown
        this.toDropdown();

        //recalculate widths
        this.calculateWidths();
        //update dropdownToggle label
        if(this.viewportWidth < this.breakPoint) {
          this.updateLabel(this.dropdownBreakpointLabel);
        }
      }

      if (this.viewportWidth < this.breakPoint) {
        this.adjustDropdownPosition();
      }

      /**
       * Keep executing until all menu items that are able to move back are moved
       */
      let lastBreak = 0;
      if (this.breaks.length > 0) {
        lastBreak = this.breaks[this.breaks.length - 1];
      }
      
      while (dropDownElem != null && this.totalWidth >= lastBreak && dropDownElem.children.length > 0 && this.viewportWidth > this.breakPoint) {
        //move item to menu
        this.toMenu();
        if (this.breaks.length > 0) {
          lastBreak = this.breaks[this.breaks.length - 1];
        } else {
          lastBreak = 0;
        }
        //update dropdownToggle label
        if(this.viewportWidth > this.breakPoint) {
          this.updateLabel(this.dropdownLabel);
        }
      }

      /**
       * If there are no items in dropdown hide dropdown
       */
      if (this.navDropdownSelector != null && this.breaks.length < 1) {
          navEl.querySelector(this.navDropdownSelector).classList.remove('show');
          //show navDropdownLabel
          this.updateLabel(this.dropdownLabel);
      }

      /**
       * If there are no items in menu
       */
      if (this.mainSelector != null && navEl.querySelector(this.mainSelector).children.length < 1) {
        //show navDropdownBreakpointLabel
        this.updateLabel(this.dropdownBreakpointLabel);
      }

      /**
       * Check if we need to show toggle menu button
       */
      this.showToggle();
      this.isBusy = false;

    },
    toDropdown() {
      const navEl = this.$el;
      const navDropdown = this.navDropdownSelector;
      const mainNav = this.mainSelector;
      /**
       * move last child of navigation menu to dropdown
       */
      if (navEl.querySelector(navDropdown).firstChild && navEl.querySelector(mainNav).children.length > 0) {
        let elA = navEl.querySelector(mainNav).lastElementChild;
        let elB = navEl.querySelector(navDropdown).firstChild;
        
        while (elA.dataset.index != null && elB != null && elB.dataset.index != null && elA.dataset.index > elB.dataset.index) {
          elB = elB.nextSibling;
        }

        if (elB != null) {
          navEl.querySelector(navDropdown).insertBefore(elA, elB);
        } else {
          navEl.querySelector(navDropdown).appendChild(elA);
        }
        
      } else if (navEl.querySelector(mainNav).children.length > 0) {
        navEl.querySelector(navDropdown).appendChild(navEl.querySelector(mainNav).lastElementChild);
      }

      /**
       * store breakpoints
       */
      this.breaks.push(this.restWidth);

      /**
       * check if we need to show toggle menu button
       */
      this.showToggle();

      /**
       * If item has been moved to dropdown trigger the callback
       */
      // this.$emit('moved');
    },
    showToggle() {
      if (this.breaks.length < 1) {
        this.isDropdownToggleVisible = false;
        this.hasDropdown = false;
      } else {
        this.isDropdownToggleVisible = true;
        this.hasDropdown = true;
      }
    },
    updateLabel(label){
      this.dropdownBtnLabel = label;
    },
    navDropdownToggleClicked(/** event */) {
      const navEl = this.$el;
      const navDropdown = this.navDropdownSelector;
      this.isOpened = !this.isOpened;
      
      //Align the dropdown container to the right of main nav when the container can't fit in the window screen.
      this.adjustDropdownPosition();

      if (!this.isOpened && !this.dropDownStayOpened) {
        navEl.querySelector(navDropdown).blur();
      }
    },
    adjustDropdownPosition() {
      if (!this.isOpened) {
        return;
      }
      const navEl = this.$el;
      const navDropdown = this.navDropdownSelector;
      const dropDownWidth = navEl.querySelector(navDropdown).offsetWidth;
      const togglerLeftPosition = navEl.querySelector(this.navDropdownToggleSelector).getBoundingClientRect().left;
      const mainNavRightPosition = navEl.getBoundingClientRect().right;
      const availableSpace = mainNavRightPosition - togglerLeftPosition;
      
      if(availableSpace < dropDownWidth) {
          navEl.querySelector(navDropdown).style.left = `${availableSpace - dropDownWidth}px`;
      } else {
          navEl.querySelector(navDropdown).style.removeProperty('left');
      }
    },
    toggleClass(el, className) {
      el.classList.toggle(className);
    },
    toMenu() {
      const navEl = this.$el;
      const navDropdown = this.navDropdownSelector;
      const mainNav = this.mainSelector;
      /**
       * move last child of navigation menu to dropdown
       */
      if (navEl.querySelector(navDropdown).children.length > 0) {
        navEl.querySelector(mainNav).appendChild(navEl.querySelector(navDropdown).firstElementChild);
      }

      /**
       * remove last breakpoint
       */
      this.breaks.pop();

      /**
       * Check if we need to show toggle menu button
       */
      this.showToggle();

      /**
       * If item has been moved back to the main menu trigger the callback
       */
      // this.$emit('back');
    }
  }
}
</script>

<style lang="scss" scoped>
.priority-nav {
  position: relative;
  white-space: nowrap;
  /*
  * Makes sure the menu's are inline-block so they don't take up
  * the entire width of its parent. This will break the plugin.
  */
}

.priority-nav > ul {
  display: inline-block;
  padding: 0;
}

.priority-nav > ul > li {
  display: inline-block;
}

.priority-nav-has-dropdown .priority-nav__dropdown-toggle {
  position: relative;
  background: none;
  border: none;
  font-size: 24px;
  vertical-align: middle;
  color: var(--text-medium);
}

.priority-nav-has-dropdown .priority-nav__dropdown-toggle:focus {
  border: none;
  outline: none;
}

.priority-nav__wrapper {
  position: relative;
}

.priority-nav__dropdown {
  right: 0;
  position: absolute;
  visibility: hidden;
}

.priority-nav__dropdown.show {
  visibility: visible;
  z-index: 10;
  background: var(--white);
  border: 2px solid var(--grey);
}

ul.nav__dropdown.priority-nav__dropdown {
  list-style: none;
  padding: 10px;
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--surface-bg);
  border: 1px solid var(--surface-border);
}

ul.nav__dropdown.priority-nav__dropdown.show li {
  margin: 5px;
}

/* Long items can be given their own row */
ul.nav__dropdown.priority-nav__dropdown.show li.single-row {
  flex: 0 0 100%;
}

/* Dividers will be hidden and force a new line */
ul.nav__dropdown.priority-nav__dropdown.show li.divider {
  line-height: 0;
  width: 100%;
  font-size: 0px;
  margin: 0;
}

.priority-nav__dropdown-toggle {
  display: none;
  position: absolute;
}

.priority-nav-is-visible {
  display: inline-block;
}

.priority-nav-is-hidden {
  display: none;
}

@media screen and ( max-width: 535px) {
  .priority-nav__dropdown {
    right: unset;
  }
}

</style>