import { profileService } from '@/services';

const folderKey = 'user';

export const layoutProfileService = {
  create,
  list,
  update,
  remove,
};

function create(data, entityId, userId) {
  return profileService.create(data, entityId, folderKey, userId);
}

function list(entityId, userId) {
  return profileService.list(entityId, folderKey, userId);
}

function update(data, entityId, userId) {
  return profileService.update(data, entityId, folderKey, userId);
}

function remove(data, entityId, userId) {
  return profileService.remove(data, entityId, folderKey, userId);
}