const state = {
  type: null,
  message: null,
};

const actions = {
  success({ commit }, message) {
    commit('success', message);
  },
  error({ commit }, message) {
    commit('error', message);
  },
  clear({ commit }) {
    commit('clear');
  },
};

const mutations = {
  success(_state, message) {
    _state.type = 'success';
    _state.message = message;
  },
  error(_state, message) {
    _state.type = 'danger';
    _state.message = message;
  },
  clear(_state) {
    _state.type = null;
    _state.message = null;
  },
};

const alert = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default alert;
